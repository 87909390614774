import React, { Component } from "react";
import PropTypes from "prop-types";
import { Multiselect } from "react-widgets";
import {
    Form,
    FormGroup,
    FormControl,
    ControlLabel,
    Button,
    Row,
    Col
} from "react-bootstrap";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import pickBy from "lodash/pickBy";

import * as funnelActions from "../../../funnels/actions";
import { NotificationEmitter, NOTIFICATION_EVENT_NAME } from "../../../commons/notification";
import ToolTip from "../../ToolTip";

import { numberOnly } from "../../utilities";
import { createCampaign, updateCountries, getCountriesList, getCurrencyList, updateCampaign, updateFunnelDetails } from "../../utilities/api";

import "./NewCampaign.css";
import CRMButton from "../../CRMButton";
import CheckBox from "../../CheckBox";
import { setActiveFunnelTitle } from "../../../core/app-settings/actions";

class CreateEditCampaign extends Component {
    constructor(props) {
        super(props);
        this.state = {
            hasError: false,
            isLoading: false,
            campaign: Object.assign({}, this.getCampaignInitialState(this.props.pageType !== "STEP_WIZARD").campaign),
            countries: [],
            currencies: [],
            campaignDetails: []
        };
    }

    componentDidMount() {
        typeof this.props.handleSkipButton === "function" && this.props.handleSkipButton(false);
        this.getCountriesAndCurrencies();
    }

    getCampaignInitialState(value) {
        if (!value) {
            return {
                campaign: {
                    campaignName: "",
                    campaignType: "LANDER",
                    campaignCategoryName: "FunnelKonnekt",
                    currency: "USD",
                    bundleFulfillment: 0,
                    blockChargebackBlacklist: 0,
                    requireQA: 0,
                    qaPercent: null,
                    requireTOS: 0,
                    campaignTOS: "",
                    eSignature: 0,
                    preauthOnly: false,
                    pinlessDebitRoute: 0,
                    prPercent: null,
                    codOrders: 0,
                    blockPrepaid: 0,
                    blockDebit: 0,
                    blockMastercard: 0,
                    disableExternalPayments: 0,
                    captureOnShipment: 0,
                    retailOrders: 0,
                    couponMax: null,
                    selectedCountries: null
                }
            };
        } else {
            return {
                campaign: {
                    ...this.props.funnelDetails
                }
            };
        }
    }

    getCountriesAndCurrencies = () => {
        let countryList = new Promise((resolve) => {
            getCountriesList().then(res => {
                resolve(res.message);
            });
        });
        let currencyList = new Promise((resolve) => {
            getCurrencyList().then(res => {
                resolve(res.message);
            });
        });
        Promise.all([countryList, currencyList]).then((res) => {
            this.setState({
                countries: res[0],
                currencies: res[1]
            });
        });
    }

    nextStep = () => {
        this.props.moveStep("NEXT");
        NotificationEmitter.emit(NOTIFICATION_EVENT_NAME, { type: "success", message: "Campaign created successfully" });
    }

    handleUpdate = (e) => {
        e.preventDefault();
        const {
            campaign,
            selectedCountries
        } = this.state;

        const data = {
            campaignId: campaign.campaignId,
            campaignName: campaign.campaignName,
            currency: campaign.currency,
            requireQA: campaign.requireQA,
            qaPercent: campaign.qaPercent,
            requireTOS: campaign.requireTOS,
            campaignTOS: campaign.campaignTOS,
            eSignature: campaign.eSignature,
            couponMax: campaign.couponMax,
            pinlessDebitRoute: campaign.pinlessDebitRoute,
            prPercent: campaign.prPercent,
            codOrders: campaign.codOrders,
            blockPrepaid: campaign.blockPrepaid,
            blockDebit: campaign.blockDebit,
            blockMastercard: campaign.blockMastercard,
            disableExternalPayments: campaign.disableExternalPayments,
            bundleFulfillment: campaign.bundleFulfillment,
            captureOnShipment: campaign.captureOnShipment,
            blockChargebackBlacklist: campaign.blockChargebackBlacklist
        };
        if (campaign.campaignName) {
            this.setState({
                isLoading: true
            });
            updateCampaign(data).then(res => {
                if (selectedCountries) {
                    updateCountries({
                        campaignId: campaign.campaignId,
                        countries: selectedCountries
                    }).then(res => {
                        NotificationEmitter.emit(NOTIFICATION_EVENT_NAME, { type: "success", message: "Campaign Updated successfully" });
                        this.setState({
                            isLoading: false
                        }, () => this.props.closeModal(e));
                        if (this.props.fromEditFunnel) {
                            this.props.refreshData();
                        }
                    }).catch(err => {
                        NotificationEmitter.emit(NOTIFICATION_EVENT_NAME, { type: "error", message: err.response.data.message });
                        this.setState({
                            isLoading: false
                        });
                    });
                } else {
                    NotificationEmitter.emit(NOTIFICATION_EVENT_NAME, { type: "success", message: "Campaign Updated successfully" });
                    if (this.props.fromEditFunnel) {
                        this.props.refreshData();
                    }
                    this.setState({
                        isLoading: false
                    }, () => this.props.closeModal(e));
                }
            }).catch(err => {
                NotificationEmitter.emit(NOTIFICATION_EVENT_NAME, { type: "error", message: err.response.data.message });
                this.setState({
                    isLoading: false
                });
            });
        }
    }

    handleSubmit = (e) => {
        const {
            campaign,
            selectedCountries
        } = this.state;
        const { activeFunnelName, getFunnelDetails, setActiveFunnelTitle, updateFunnelInfo } = this.props;
        let funnelData = {
            // Title: "Custom Funnel",
            author: "",
            campaign: null,
            campaignId: "",
            funnelType: "",
            offer: [],
            offerId: "",
            status: "",
            title: activeFunnelName ? activeFunnelName : "Custom Funnel",
            upsale: [],
            upsaleId: "",
        };
        e.preventDefault();
        if (campaign.campaignName) {
            this.setState({
                isLoading: true
            });
            createCampaign(campaign).then(res => {
                this.props.setCampaignId(res.data.message.campaignId);
                funnelData = {
                    ...funnelData,
                    campaign: res.data.message.campaignId
                };
                if (this.props.isInsideTheVisualizer) {
                    const data = this.props.funnelData;
                    // let funnel = {
                    //     Title: data.title,
                    //     Offer: JSON.stringify([""]),
                    //     Upsale: JSON.stringify([""]),
                    //     Campaign: funnelData.campaign,
                    //     Status: data.status,
                    //     VisualizerJson: data.visualizerJson,
                    //     page: data.page,
                    // };
                    let funnel = {
                        referenceId: data.referenceId,
                        title: data.title,
                        offer: JSON.stringify([""]),
                        upsale: JSON.stringify([""]),
                        campaign: funnelData.campaign,
                        status: data.status,
                        VisualizerJson: data.visualizerJson,
                        page: data.page,
                    };
                    const filteredPage = funnel.page && funnel.page.map(data => pickBy(data, v => v !== null && v !== undefined));
                    funnel = {
                        ...funnel,
                        ["page"]: filteredPage
                    };
                    // updateFunnelDetails(funnel, data.referenceId)
                    //     .then(res => {
                    //         NotificationEmitter.emit(NOTIFICATION_EVENT_NAME, { type: "success", message: "Funnel updated successfully" });
                    //         this.setState({
                    //             isLoading: false
                    //         });
                    //         this.props.getFunnelData(data.referenceId);
                    //     })
                    //     .catch(err => {
                    //         NotificationEmitter.emit(NOTIFICATION_EVENT_NAME, { type: "error", message: err.response.data.message });
                    //         this.setState({
                    //             isLoading: false
                    //         });
                    //     });
                    updateFunnelInfo({ ...funnel })
                        .then(res => {
                            NotificationEmitter.emit(NOTIFICATION_EVENT_NAME, { type: "success", message: "Funnel updated successfully" });
                            this.setState({
                                isLoading: false
                            });
                        })
                        .catch(err => {
                            NotificationEmitter.emit(NOTIFICATION_EVENT_NAME, { type: "error", message: err.response.data.message });
                            this.setState({
                                isLoading: false
                            });
                        });
                }
                if (selectedCountries) {
                    updateCountries({
                        campaignId: res.data.message.campaignId,
                        countries: selectedCountries
                    }).then(res => {
                        this.nextStep();
                    }).catch(err => {
                        NotificationEmitter.emit(NOTIFICATION_EVENT_NAME, { type: "error", message: err.response.data.message });
                    });
                } else {
                    this.nextStep();
                }
                if (!this.props.isInsideTheVisualizer) {
                    this.props.actions.createFunnel(funnelData)
                        .then((funnelData) => {
                            setActiveFunnelTitle && setActiveFunnelTitle(null);
                            getFunnelDetails(funnelData);
                            NotificationEmitter.emit(NOTIFICATION_EVENT_NAME, { type: "success", message: "Funnel created successfully" });
                        })
                        .catch(err => {
                            NotificationEmitter.emit(NOTIFICATION_EVENT_NAME, { type: "error", message: err.response.data.message });
                        });
                }
            }).catch(err => {
                NotificationEmitter.emit(NOTIFICATION_EVENT_NAME, { type: "error", message: err.response.data.message });
                this.setState({
                    isLoading: false
                });
            });
        } else {
            this.setState({
                hasError: true
            }, () => this.refs.campaign.scrollIntoView({ behavior: "smooth" }));
        }
    }

    handleCountries = (countries) => {
        let selectedCountries = countries.map(country => country.value).toString();
        this.setState({
            selectedCountries
        });
    }

    handleCurrency = (e) => {
        this.setState({ campaign: { ...this.state.campaign, currency: e.target.value } });
        let selectedCurrency = Object.assign({}, ...this.state.currencies.filter(campaign => campaign.currencyCode === e.target.value));
        if (!this.props.fromEditFunnel) {
            this.props.setCurrencySymbol(selectedCurrency.currencySymbol);
        }
    }

    handleChange = (e, type) => {
        this.setState({
            campaign: { ...this.state.campaign, [e.target.name]: type === "checkbox" ? e.target.checked ? 1 : 0 : e.target.value }
        });
    }

    render() {
        const {
            hasError,
            countries,
            currencies,
            isLoading,
            campaign: {
                bundleFulfillment,
                currency,
                blockPrepaid,
                blockChargebackBlacklist,
                requireQA,
                requireTOS,
                eSignature,
                pinlessDebitRoute,
                codOrders,
                blockDebit,
                blockMastercard,
                disableExternalPayments,
                captureOnShipment,
                retailOrders,
                campaignName,
                couponMax,
                qaPercent,
                prPercent
            }
        } = this.state;
        const { selectTemplate, pageType, closeModal } = this.props;
        const countryOptions = countries && countries.map((country) => {
            return { value: country.countryCode, text: country.countryName };
        });
        const defaultCountries = this.state.campaign.countries && this.state.campaign.countries.map((country) => {
            return { value: country.countryCode, text: country.countryName };
        });
        const multiSelectRender = <Multiselect
            data={countryOptions}
            onChange={(value) => this.handleCountries(value)}
            textField='text'
            className="custom-multiselect"
            defaultValue={defaultCountries}
        />;

        return (
            <div className="new_product_container">
                <div className="container-fluid page">
                    <div ref="campaign"></div>
                    {pageType === "STEP_WIZARD" ? (
                        <h4 className="campaign-heading">
                            Create New Campaign
                        </h4>
                    ) : null
                    }
                    <form>
                        <Row>
                            <Col md={9}>
                                <Form>
                                    <FormGroup>
                                        <ControlLabel className="custom-form-label">Campaign Name</ControlLabel>
                                        <FormControl
                                            type="text"
                                            className={`${hasError ? "select_error" : ""}`}
                                            name="campaignName"
                                            onChange={e => this.handleChange(e)}
                                            defaultValue={campaignName}
                                        />
                                        <div className={`error_box ${hasError ? "erractive" : ""}`}>
                                            <span>Campaign Name is required</span>
                                        </div>
                                    </FormGroup>
                                </Form>
                            </Col>
                            <Col md={3}>
                                <FormGroup controlId="formControlsSelect">
                                    <ControlLabel className="custom-form-label">Currency</ControlLabel>
                                    <FormControl componentClass="select" placeholder="Currency" className="primary-black hover-primary-black" onChange={(e) => this.handleCurrency(e)}>
                                        {(currencies || []).map((currencyData, i) => <option key={i} value={currencyData.currencyCode} selected={currencyData.currencyCode === `${currency}`}>{currencyData.currencyName}</option>)}
                                    </FormControl>
                                </FormGroup>
                            </Col>
                        </Row>

                        <Row>
                            <Col md={4} className="check-box-whole-area">
                                <h5 className="check-box-heading ">Basic Options</h5>
                                <Row className="pb-31 pt-31 vertical-center pl-72">
                                    <CheckBox label="Quality Assurance" name="requireQA" fieldName={requireQA} handleChange={this.handleChange}/>
                                    <ToolTip
                                        title="Quality Assurance"
                                        content="All new orders will be pushed into Konnektive's Quality Assurance system by percentage entered for secondary review. These Cards will be authorized at the time of sale but not captured until the order is specifically approved through the QA system. Set desired % for QA. Default is 100%"
                                        placement="right"
                                    />
                                </Row>
                                {requireQA === 1 ? (
                                    <Row className="pb-31 hidden-input pb-4">
                                        <div className="pr-0 content">QA % Rate</div>
                                        <div className="pl-0">
                                            <FormControl defaultValue={qaPercent} className="input" type="text" name="qaPercent" onChange={e => {numberOnly(e, false); this.handleChange(e);}} />
                                        </div>
                                    </Row>
                                ) : null}
                                <Row className="pb-31 vertical-center pl-72">
                                    <CheckBox label="Must Agree to ToS" name="requireTOS" fieldName={requireTOS} handleChange={this.handleChange}/>
                                    <ToolTip
                                        title="Must Agree to ToS"
                                        content="This option specifies that the customer must specifically agree to the merchant's terms of service. This is done by checking a box either on a web form or in sales order entry."
                                        placement="right"
                                    />
                                </Row>
                                <Row className="pb-31 vertical-center pl-72">
                                    <CheckBox label="Require eSignature" name="eSignature" fieldName={eSignature} handleChange={this.handleChange}/>
                                    <ToolTip
                                        title="Require Electronic Signature"
                                        content="This Campaign will require an eSignature for terms of service. You can set the campaign terms of service in the Terms of Service panel towards the bottom of this page. Terms of service will be combined in a pdf with the eSignature and attached to any order confirmation emails sent to the customer and stored as a file on the customer details page."
                                        placement="right"
                                    />
                                </Row>
                            </Col>
                            <Col md={4} className="check-box-whole-area">
                                <h5 className="check-box-heading ">Billing Options</h5>
                                <Row className="pb-31 pt-31 vertical-center pl-72">
                                    <CheckBox label="Route to Pinless Debit" name="pinlessDebitRoute" fieldName={pinlessDebitRoute} handleChange={this.handleChange}/>
                                    <ToolTip
                                        title="Route to pinless debit"
                                        content="For a Pinless Debit merchant account please contact your Account Manager"
                                        placement="right"
                                    />
                                </Row>
                                {pinlessDebitRoute === 1 ? (
                                    <Row className="pb-31 hidden-input pb-4">
                                        <div className="pr-0 content">Pinless Routing Rate %</div>
                                        <div className="pl-0">
                                            <FormControl defaultValue={prPercent} className="input" type="text" name="prPercent" onChange={e => {numberOnly(e, false); this.handleChange(e);}} />
                                        </div>
                                    </Row>
                                ) : null}
                                <Row className="pb-31 vertical-center pl-72">
                                    <CheckBox label="Accept Cash on Delivery Orders" name="codOrders" fieldName={codOrders} handleChange={this.handleChange}/>
                                    <ToolTip
                                        title="COD Orders"
                                        content="Orders will be approved for shipment, and payment will be collected on delivery."
                                        placement="right"
                                    />
                                </Row>
                                {/* <Row className="pb-31 vertical-center pl-72">
                                    <div className="checkbox checkbox-success checkbox-md custom-checkbox">
                                        <label className="custom-checkbox">
                                            <div className={`check-box-label ${retailOrders ? "text-color" : ""}`}>Retail Orders</div>
                                            <input defaultChecked={retailOrders === 1} type="checkbox" onChange={(e) => this.setState({ campaign: { ...this.state.campaign, retailOrders: e.target.checked ? 1 : 0 } })} />
                                            <span className="check-box"></span>
                                        </label>
                                    </div>
                                    <ToolTip
                                        title="Retail Orders"
                                        content="Orders will be approved for shipment according to retail terms set on the campaign. Generally, the retailer is given a certain amount of time to complete payment or the order is placed in a deliquent status."
                                        placement="right"
                                    />
                                </Row> */}
                            </Col>
                            <Col md={4} className="check-box-whole-area">
                                <Row className="pb-31 pt-31 vertical-center pl-72" style={{ paddingTop: 60 }}>
                                    <CheckBox label="Block Prepaid Cards" name="blockPrepaid" fieldName={blockPrepaid} handleChange={this.handleChange}/>
                                    <ToolTip
                                        title="Block Prepaid Cards"
                                        content="Reject any sales using a Prepaid Credit or Prepaid Debit Card."
                                        placement="top"
                                    />
                                </Row>
                                <Row className="pb-31 vertical-center pl-72">
                                    <CheckBox label="Block Debit Cards" name="blockDebit" fieldName={blockDebit} handleChange={this.handleChange}/>
                                    <ToolTip
                                        title="Block Debit Cards"
                                        content="Reject any sales using a debit card."
                                        placement="top"
                                    />
                                </Row>
                                <Row className="pb-31 vertical-center pl-72">
                                    <CheckBox label="Block Mastercard" name="blockMastercard" fieldName={blockMastercard} handleChange={this.handleChange}/>
                                    <ToolTip
                                        title="Block Mastercard"
                                        content="Reject any sales using a Mastercard credit card."
                                        placement="top"
                                    />
                                </Row>
                                <Row className="pb-31 vertical-center pl-72">
                                    <CheckBox label="Disable External Payments" name="disableExternalPayments" fieldName={disableExternalPayments} handleChange={this.handleChange}/>
                                    <ToolTip
                                        title="Disable External Payments"
                                        content="This disables the &quot;Prepaid&quot; Order Entry payment option, which records payments taken outside the CRM system, such as mailed-in checks."
                                        placement="top"
                                    />
                                </Row>
                            </Col>
                        </Row>

                        <Row>
                            <Col md={4} className="check-box-whole-area">
                                <h5 className="check-box-heading ">Shipping Options</h5>
                                <Row className="pb-31 pt-31 vertical-center pl-72">
                                    <CheckBox label="Capture on Shipment" name="captureOnShipment" fieldName={captureOnShipment} handleChange={this.handleChange}/>
                                    <ToolTip
                                        title="Capture on Shipment"
                                        content="Charges will be authorized at the time of the initial sale but only captured once the fulfillment has been updated with a tracking number. Note: Cannot be used if Chargeback Protection is checked."
                                        placement="right"
                                    />
                                </Row>
                                <Row className="pb-31 vertical-center pl-72">
                                    <CheckBox label="Bundle Fulfillment" name="bundleFulfillment" fieldName={bundleFulfillment} handleChange={this.handleChange}/>
                                    <ToolTip
                                        title="Bundle Fulfillment"
                                        content="This option will bundle fulfillments that are within 96 hours of each other when they are shipped to the same customer. The main purpose of this option is to save money on number of fulfillments exported to a fulfillment house."
                                        placement="right"
                                    />
                                </Row>
                            </Col>
                            <Col md={4} className="check-box-whole-area">
                                <h5 className="check-box-heading ">Other Options</h5>
                                <Row className="pt-31 pb-31 vertical-center pl-72">
                                    <Col md={9} className="pl-0">
                                        <Row>
                                            <Col md={8} style={{ padding: 0 }}>
                                                <div className="pr-0 pl-0 check-box-label content">Maximum Coupons</div>
                                            </Col>
                                            <Col md={4} style={{ padding: 0 }}>
                                                <ToolTip
                                                    title="Maximum Coupons"
                                                    content="The maximum number of coupons allowed per order."
                                                    placement="right"
                                                />
                                            </Col>
                                        </Row>
                                    </Col>
                                    <Col md={3} className="pl-0">
                                        <div>
                                            <FormControl defaultValue={couponMax} className="input" type="text" name="couponMax" onChange={e => {numberOnly(e); this.handleChange(e);}} />
                                        </div>
                                    </Col>
                                </Row>
                                <Row className="pb-31 vertical-center pl-72">
                                    <CheckBox label="Chargeback Blacklist" name="blockChargebackBlacklist" fieldName={blockChargebackBlacklist} handleChange={this.handleChange}/>
                                    <ToolTip
                                        title="Chargeback Blacklist"
                                        content="Enabling this will cause the transaction info to be checked against our global blacklist. Our global blacklist blocks any transaction from a client that has ever charged back on any of our clients."
                                        placement="right"
                                    />
                                </Row>
                            </Col>
                        </Row>

                        <Row style={{ padding: "10px 20px" }}>
                            <span className="countries">Countries</span>
                            <Col md={12} className="countries-container" style={{ marginTop: 18 }}>
                                {multiSelectRender}
                            </Col>
                        </Row>

                        <Row className="footer">
                            {pageType === "STEP_WIZARD" ? (
                                <div className="pr-0">
                                    <Button className="cancelButton" onClick={() => selectTemplate(null)}>Cancel</Button>
                                </div>
                            ) : (
                                <div className="pr-0">
                                    <Button className="cancelButton" onClick={closeModal}>Cancel</Button>
                                </div>
                            )
                            }
                            <div className="pl-0">
                                <CRMButton isLoading={isLoading} handleClick={(e) => pageType === "STEP_WIZARD" ? this.handleSubmit(e) : this.handleUpdate(e)} text="Save & Continue"/>
                            </div>
                        </Row>
                    </form>
                </div>
            </div>
        );
    }
}

function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators(funnelActions, dispatch),
        setActiveFunnelTitle: (value) => dispatch(setActiveFunnelTitle(value)),
    };
}

export default connect(null, mapDispatchToProps)(CreateEditCampaign);

CreateEditCampaign.propTypes = {
    selectTemplate: PropTypes.func,
    moveStep: PropTypes.func,
    handleSkipButton: PropTypes.func,
    setCampaignId: PropTypes.func,
    actions: PropTypes.object.isRequired,
    pageType: PropTypes.string,
    closeModal: PropTypes.func,
    funnelDetails: PropTypes.object,
    refreshData: PropTypes.func,
    fromEditFunnel: PropTypes.bool,
    setCurrencySymbol: PropTypes.func,
    isInsideTheVisualizer: PropTypes.bool,
    funnelData: PropTypes.object,
    getFunnelData: PropTypes.func,
    activeFunnelName: PropTypes.string,
    getFunnelDetails: PropTypes.func,
    setActiveFunnelTitle: PropTypes.func,
    updateFunnelInfo: PropTypes.func
};
