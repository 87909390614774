import { ManageFunnel as ManageFunnelView } from "./manage-funnel";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import * as actions from "./actions";
import { LocalStorageHelper } from "../../utilities";
// import { getFunnels } from "./../selectors";
import { manageAuthorState } from "./../../author/selectors";
import { selectors as templateSelectors } from "../../templates";
import * as selectors from "./selectors";
import * as campaignSelectors from "../../campaign/selectors";
import { manageCampaignState } from "./../../campaign/selectors";
import * as funnelSelectors from "../selectors";
import * as funnelActions from "../actions";
import * as visualizerActions from "../create-funnel-visualizer/actions";
import * as templateActions from "./../../templates/actions";
import * as saltSelectors from "../../login/selectors";

/* TODO - Refactor selectors */
function mapStateToProps(state, ownProps) {
    const authorsFormattedForDropdown = manageAuthorState(state).map(author => {
        return {
            value: author.id,
            text: author.firstName + " " + author.lastName
        };
    });
    const campaignsFormattedForDropdown = manageCampaignState(state).map(campaign => {
        return {
            value: campaign.campaignId,
            text: campaign.campaignName,
            products: campaign.products
        };

    });
    const oldfunnel = ownProps.location.state === "Oldfunnel" ? true : false;
    const localStorage = new LocalStorageHelper();
    localStorage.store("funnelReferenceId", ownProps.match.params.referenceId);
    const funnelType = [{ value: "1", text: "Single Product" }, { value: "2", text: "Multi Product" }];
    const pages = selectors.getPages(state);
    const currentPageReferenceId = selectors.getCurrentPageId(state);
    const settingsData = saltSelectors.getSettingsData(state);
    const currentPage = pages.find((p) => p.referenceId === (currentPageReferenceId));
    const templates = templateSelectors.getTemplates(state);
    const splitPages = selectors.getSplitPage(state);
    const splitValue = selectors.getSplitValue(state);
    const campaigns = campaignSelectors.manageCampaignState(state);
    const funnels = funnelSelectors.getFunnels(state);
    let currentTemplate = {}, selectedCampaign = null;
    if (currentPage && templates.length) {
        currentTemplate = currentPage.pageView[0] ?
            templateSelectors.getTemplates(state).find((t) => t.templateId === currentPage.pageView[0].fTemplateId)
            : null;
    }
    if (currentPage && campaigns.length && funnels.length) {
        const currentFunnel = funnelSelectors.getFunnel(state, ownProps.match.params.referenceId);
        selectedCampaign = campaignSelectors.getCampaign(state, currentFunnel.campaignId);
    }
    let offersFormattedForDropdown = [];
    let upsalesFormattedForDropdown = [];
    if (selectedCampaign) {
        offersFormattedForDropdown = selectedCampaign.products.filter(product =>
            product.productType === "OFFER"
        ).map((o) => ({
            value: o.campaignProductId,
            text: o.productName,
            type: o.productType
        }));

        upsalesFormattedForDropdown = selectedCampaign.products.filter(product =>
            product.productType === "UPSALE"
        ).map((u) => ({
            value: u.campaignProductId,
            text: u.productName,
            type: u.productType
        }));
    }
    const splitValueChanged = state.MANAGEFUNNEL.splitViewPage;
    return {
        funnelReferenceId: ownProps.match.params.referenceId,
        funnel: selectors.getFunnelData(state),
        authors: authorsFormattedForDropdown,
        upsales: upsalesFormattedForDropdown,
        offers: offersFormattedForDropdown,
        campaigns: campaignsFormattedForDropdown,
        currentTemplate,
        splitPages,
        pages: selectors.getPages(state),
        funnelType: funnelType,
        templates: templateSelectors.getTemplates(state),
        currentPage,
        currentPageReferenceId,
        selectedCampaign,
        isError: state.FUNNEL.isError,
        splitValue,
        splitValueChanged,
        isCreated: selectors.getCreated(state),
        isLoading: selectors.getLoader(state),
        salt: settingsData ? settingsData.salt : "",
        oldfunnel
    };
}

function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators(actions, dispatch),
        getFunnelData: (params) => dispatch(actions.loadFunnel(params)),
        loadFunnels: () => dispatch(funnelActions.loadFunnels()),
        clearJsonData: () => dispatch(visualizerActions.clearJsonData()),
        editFunnel: (funnel) => dispatch(actions.updateFunnel(funnel)),
        createPageView: (params) => dispatch(actions.duplicatePageView(params)),
        applySplitView: (params) => dispatch(actions.splitViewPut(params)),
        loadTemplates: () => dispatch(templateActions.getTemplates()),
        deleteRunner: (params) => dispatch(actions.deleteRunner(params)),
        manageSavePageView: (params) => dispatch(actions.savePageView(params)),
        getPage: (pageId) => dispatch(actions.getPage(pageId))
    };
}

export const ManageFunnel = connect(mapStateToProps, mapDispatchToProps)(ManageFunnelView);
