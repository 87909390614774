import React, { useContext } from "react";
import PropTypes from "prop-types";
import {
    LiveUrl,
    FontsList,
    SharePage,
    EditPageName,
    SetDefaultPage
} from ".";
import {
    getPageShareId,
    getPageType,
    getTemplateTypeId,
    getPageTextName,
    formatSelectedFonts,
    formatSavedFonts,
} from "../../utilities/helpers";
import { VisualizerContext } from "../.././";

const RightPanelCommonComponents = ({
    fontList,
    currentPage,
    selectedFonts,
    fontLoader
}) => {
    const VisualizerContextData = useContext(VisualizerContext);
    const {
        funnelData,
        saveUpdatedPageName,
        defaultPage,
        changeDefaultPageStatus,
        importShareIdPageOnCurrentPage,
        saveFonts,
        domainList
    } = VisualizerContextData;
    /*
     *
     *share page variables
     * */
    const shareId = getPageShareId(funnelData.page, currentPage);
    const isCurrentPageEdited = currentPage.get("isEdited");
    const pageViewReferenceId = currentPage.get("pageViewReferenceId");
    const importSharedIdPage = (shareIdToBeImported) => importShareIdPageOnCurrentPage({ shareId: shareIdToBeImported, pageViewReferenceId, currentPage });

    /*
     *
     * Edit page name Variables
     */
    const currentPageTemplateTypeId = getTemplateTypeId(currentPage);
    const pageTypeLabel = getPageType(currentPageTemplateTypeId);
    const initialPageTitle = getPageTextName(currentPage);
    const pageReferenceId = currentPage.get("pageReferenceId");
    const savePageName = (currentPageTitle) => {
        return saveUpdatedPageName({ pageReferenceId, currentPageTitle, currentPage });
    };

    /*
     *
     * DefaultPage Variables
     */
    const isCurrentPageDefaultPage = currentPage.get("id") === defaultPage;
    const isSplitEnabled = currentPage.get("isSplitEnabled");
    const updateDefaultPageStatus = () => changeDefaultPageStatus({ pageViewReferenceId, isSplitEnabled, currentPage });

    /**
     * Fonts variables
     */
    const getSelectedFonts = formatSelectedFonts(selectedFonts);
    const updateFonts = (savedFonts) => {
        const fontUrlArray = formatSavedFonts(savedFonts);
        const params = {
            pageReferenceId,
            fontUrlArray
        };
        saveFonts(params);
    };
    return (
        <>
            <SharePage
                shareId={shareId}
                isCurrentPageEdited={isCurrentPageEdited}
                pageViewReferenceId={pageViewReferenceId}
                importSharedIdPage={importSharedIdPage}
            />
            <EditPageName
                pageTypeLabel={pageTypeLabel}
                initialPageTitle={initialPageTitle}
                savePageName={savePageName}
            />
            {funnelData.status && isCurrentPageEdited ?
                <LiveUrl
                    domainList = {domainList}
                    currentPage = {currentPage}
                />
                : null
            }
            <FontsList
                fontList={fontList}
                selectedFonts={getSelectedFonts}
                isFontLoading={fontLoader}
                saveFontsCall={updateFonts}
            />
            <SetDefaultPage
                updateDefaultPageStatus={updateDefaultPageStatus}
                isCurrentPageDefaultPage={isCurrentPageDefaultPage}
            />
        </>

    );
};

RightPanelCommonComponents.propTypes = {
    fontList: PropTypes.array,
    currentPage: PropTypes.object,
    selectedFonts: PropTypes.array,
    fontLoader: PropTypes.bool,
    isIconPage: PropTypes.bool
};

export default RightPanelCommonComponents;