import React from "react";
import { TemplateTypeSelectDropdown } from "./template-type-select-dropdown";
import PropTypes from "prop-types";
import {
    FormGroup,
    Form,
    Col,
    ControlLabel,
    Checkbox,
} from "react-bootstrap";
import NumberFormat from "react-number-format";
import isNumber from "lodash/isNumber";

import UserDataInjector from "../core/user/user-data";

export class TemplateSettings extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            pagetemplateStatus: props.template.active,
            selectedTemplateType: props.template.templateTypeId,
            price: props.template.price,
            errorMessage: ""
        };
        this.changeStatus = this.changeStatus.bind(this);
        this.update = this.update.bind(this);
        this.onDropdownChange = this.onDropdownChange.bind(this);
        this.priceInput = null;
    }

    changeStatus(e) {
        this.setState({ pagetemplateStatus: e.target.checked });
    }

    onDropdownChange(selectedTemplateType) {
        this.setState({ selectedTemplateType });
    }

    componentWillReceiveProps(nextProps) {
        this.setState({
            pagetemplateStatus: nextProps.template.active,
            selectedTemplateType: nextProps.template.templateTypeId,
            price: nextProps.template.price,
        });
    }

    update() {
        const {
            template,
        } = this.props;
        const {
            pagetemplateStatus,
            selectedTemplateType,
            price,
        } = this.state;
        const templateUpdateValues = {
            referenceId: template.referenceId,
            active: pagetemplateStatus,
            templateType: selectedTemplateType,
        };
        if (isNumber(price)) {
            templateUpdateValues.price = price;
        }
        this.props.updateStatus(templateUpdateValues);
    }

    render() {
        const {
            closeAddNewTemplate,
            template,
            templateTypeMap,
            initialTemplateTypeId
        } = this.props;
        return (
            <div className="page-template-settings-section rightPanel clearfix active" id="editrightPanel">
                <div className="pointer text-right page-template-close-icon" onClick={closeAddNewTemplate}>
                    <i className="fa fa-close" aria-hidden="true" />
                </div>
                <div className="col-xs-12 panel-content p-md">
                    <h2 className="m-b-md">Edit Template</h2>
                    <Form horizontal>
                        <FormGroup controlId="title">
                            <Col componentClass={ControlLabel} sm={4}>
                                Title
                            </Col>
                            <Col sm={8}>
                                <div
                                    id="template-title" className="template-title btn-group"
                                >
                                    {template.title}
                                </div>
                            </Col>
                        </FormGroup>
                        <FormGroup controlId="formHorizontalEmail">
                            <Col componentClass={ControlLabel} sm={4}>
                                Active
                            </Col>
                            <Col sm={8}>
                                <Checkbox id="activepageTemplate"
                                    checked={this.state.pagetemplateStatus}
                                    onChange={this.changeStatus}
                                />
                            </Col>
                        </FormGroup>
                        <UserDataInjector>
                            {
                                (userData) => {
                                    if (userData.isDesignerUser) {
                                        return (
                                            <FormGroup controlId="formHorizontalEmail">
                                                <Col componentClass={ControlLabel} sm={4}>
                                                    Price
                                                </Col>
                                                <Col sm={8}>
                                                    <NumberFormat
                                                        type="text"
                                                        getInputRef = {(el) => this.priceInput = el}
                                                        className="form-control"
                                                        prefix={"$"}
                                                        defaultValue={template.price}
                                                        decimalScale={2}
                                                        fixedDecimalScale
                                                        onValueChange={(values) => {
                                                            const { value } = values;
                                                            this.setState({ price: +value });
                                                        }}
                                                    />
                                                </Col>
                                            </FormGroup>
                                        );
                                    }
                                    return null;
                                }
                            }
                        </UserDataInjector>
                        <FormGroup controlId="formHorizontalEmail">
                            <Col componentClass={ControlLabel} sm={4}>
                                Funnel Type
                            </Col>
                            <Col sm={6}>
                                <TemplateTypeSelectDropdown
                                    initialTemplateTypeId={initialTemplateTypeId}
                                    onDropdownChange={this.onDropdownChange}
                                    templateTypeMap={templateTypeMap}
                                />
                            </Col>
                        </FormGroup>
                    </Form>
                    <div className="text-right m-b-md">
                        <button
                            className="btn btn-primary"
                            type="button"
                            onClick={this.update}>Save</button>
                    </div>
                </div>
            </div>
        );
    }
}

TemplateSettings.propTypes = {
    templateTypeMap: PropTypes.array,
    closeAddNewTemplate: PropTypes.func,
    template: PropTypes.object,
    updateStatus: PropTypes.func,
    initialTemplateTypeId: PropTypes.number,
};