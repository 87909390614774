import React from "react";
import PropTypes from "prop-types";
import Slider from "react-slick";

import WalkthroughTile from "./walkthrough-tile";

const sliderSettings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 3,
};

const WalkthroughSlider = (props) => {
    const {
        items,
        onTileClick,
    } = props;
    const tiles = items.map((tile = {}) => (
        <WalkthroughTile
            key={tile.title}
            tile={tile}
            onTileClick={onTileClick}
        />
    ));
    return (
        <>
            {
                (items.length > sliderSettings.slidesToShow) && (
                    <Slider {...sliderSettings} className="walkthrough-slider">
                        {
                            tiles
                        }
                    </Slider>
                )
            }
            {
                (items.length <= sliderSettings.slidesToShow) && (
                    <div className="walkthrough-no-slide clearfix">
                        { tiles }
                    </div>
                )
            }
        </>
    );
};

WalkthroughSlider.propTypes = {
    items: PropTypes.array,
    onTileClick: PropTypes.func,
};


export default WalkthroughSlider;
