import { BaseStoreModel } from "../store/model";

export class FunnelReducer extends BaseStoreModel {
    constructor(
        funnelData = new Funnel({}),
        funnel = new Funnel({}),
        currentPageReferenceId = null,
        pages = [],
        pageData = {}
    ) {
        super();
        this.pages = pages;
        this.currentPageReferenceId = currentPageReferenceId;
        this.funnelData = funnelData;
        this.funnel = funnelData;
        this.pageData = pageData;
        this.metaUrl = funnelData.metaJsonUrl;
    }
}

export class FunnelsReducer extends BaseStoreModel {
    constructor(
        funnels = [],
        campaigns = []
    ) {
        super();
        this.funnels = funnels;
        this.campaigns = campaigns;
    }
}
export class Funnel {
    constructor(funnelObj) {
        this.author = funnelObj.author;
        this.campaignId = funnelObj.campaign;
        this.category = funnelObj.category;
        this.dateCreated = funnelObj.createdOn;
        this.dateUpdated = funnelObj.updatedOn;
        this.pages = funnelObj.page;
        this.active = funnelObj.active;
        this.fListFunnelTypeId = funnelObj.fListFunnelTypeId;
        this.metaJson = funnelObj.metaJson;
        this.offer = funnelObj.offer || [];
        this.publicMetaJson = funnelObj.publicMetaJson;
        this.referenceId = funnelObj.referenceId;
        this.isVisualizer = funnelObj.isVisualizer;
        this.status = funnelObj.status;
        this.title = funnelObj.title;
        this.upsale = funnelObj.upsale || [];
        this.thumbnailUrl = funnelObj.thumbnailUrl || null;
        this.userName = funnelObj.userName || "";
    }
}
