import { getDomWrapper } from "../../utils/component";

const addPopUpComponentToPage = (editor) => {
    const wrapper = getDomWrapper(editor);
    if (wrapper.view.$el.find("#customizable_popup").length === 0) {
        editor.DomComponents.addComponent({
            tagName: "div",
            type: "popup",
            removable: false,
            draggable: false,
            droppable: false,
            badgable: true,
            copyable: false,
            layerable: true,
            style: { "background-color": "#0e0d0d91", "height": "auto", "position": "fixed", "top": "0", "left": "0", "z-index": "999999", "bottom": "0", "min-height": "300px", "right": "0", "display": "none" },
            attributes: { title: "Popup", name: "Popup" , style: "display:none" , id: "customizable_popup" },
            content: `
                <style>
                .backgroundCover{
                    background-size: cover !important;
                    -webkit-background-size: cover !important;
                    background-attachment: fixed !important;
                    background-repeat: repeat repeat !important;
                    background-position: center center;
                }
                .backgroundW100 {
                    background-size: 100% auto !important;
                    -webkit-background-size: 100% auto !important;
                    background-repeat: no-repeat !important;
                }
                .backgroundWH100 {
                    background-size: 100% 100% !important;
                    -webkit-background-size: 100% 100% !important;
                    background-repeat: no-repeat !important;
                }
                .bgNoRepeat {
                    background-repeat: no-repeat !important;
                }
                .bgRepeat {
                    background-repeat: repeat !important;
                }
                .bgRepeatX {
                    background-repeat: repeat-x !important;
                }
                .bgRepeatY {
                    background-repeat: repeat-y !important;
                }
                .bgRepeatXTop {
                    background-repeat: repeat-x !important;
                    background-position: top !important;
                }
                .bgRepeatXBottom {
                    background-repeat: repeat-x !important;
                    background-position: bottom !important;
                }    
                .borderRadiusTop {
                    border-bottom-left-radius: 0 !important;
                    border-bottom-right-radius: 0 !important;
                }   
                .borderRadiusBottom {
                    border-top-left-radius: 0 !important;
                    border-top-right-radius: 0 !important;
                }     
                .widthSmall {
                    width:550px ;
                    max-width:100%;
                }
                .widthMedium {
                    width:720px;
                    max-width:100%;
                }
                .widthLarge {
                    width:960px;
                    max-width:100%;
                }
                .popup-content{
                    color: red;
                    height: auto;
                    position: fixed;
                    left: 0;
                    right: 0;
                    z-index: 999999;
                    width: 100%;
                    top: 0;
                    bottom: 0px;
                }
                .popup_content{
                  
                    margin-left : auto;
                    margin-right : auto;
                    padding:5px;
                    
                }
                .close_popup{
                    position: relative;
                    top: -10px;
                    background: #000;
                    color: #fff;
                    padding: 5px 6px;
                    border-radius: 50%;
                    right: -8px;
                    border: 2px solid #fff;
                }
                .inputIcon{
                    position: absolute;
                    bottom: 12px;
                    right: 20px;
                }
                .backgroundMargin{
                    margin-top:10%;
                    background-color:#fff;
                }
                </style>
            `,
            components: `<div class="popup_content widthMedium backgroundMargin" style="margin-top:10%;background-color: #fff;" data-gjs-copyable="false" data-gjs-editable="false" data-gjs-draggable="false" data-gjs-removable="false" data-gjs-type="popup-body" id="popup_content"><span class="close_popup fa fa-times float-right" onclick="closePopup('customizable_popup','id')"></span><h1 class="text-center my-3 px-3">Sign Up To Get The Full Course Right Now!</h1>
            <div class="d-flex flex-wrap">
                <div class="form-group col-12">
                    <label >Enter First Name</label>
                    <input type="text" class="form-control" id="text" placeholder="Enter First Name" name="firstName">
                    <span class="fa fa-user inputIcon"></span>
                </div>
                <div class="form-group col-12">
                    <label >Enter Last Name</label>
                    <input type="text" class="form-control" id="text" placeholder="Enter Last Name" name="lastName">
                    <span class="fa fa-user inputIcon"></span>
                </div>
                <div class="form-group col-12">
                    <label >Enter email:</label>
                    <input type="email" class="form-control" id="email" placeholder="Enter email" name="emailAddress">
                    <span class="fa fa-envelope inputIcon"></span>
                </div>
            </div>
            <div class="text-center">
            <button type="submit" onclick="route(event)" class="btn btn-primary py-2 col-12">Yes, I am ready to get started today!</button>
        </div>
        <p class="col-12 text-center">* we will not spam, rent, or sell your information... *</p></div>`
        });

    }
};

const customPopup = (editor) => {
    const wrapper = getDomWrapper(editor);
    wrapper.view.$el.find("#customizable_popup").length === 0 ? addPopUpComponentToPage(editor) : "";
    wrapper.view.$el.find("#stickyBarCustom")[0] ? wrapper.view.$el.find("#stickyBarCustom")[0].setAttribute("style", "display:none") : "";
    let ele = wrapper.view.$el.find("#customizable_popup")[0];
    if (ele.offsetWidth > 0 && ele.offsetHeight > 0) {
        wrapper.view.$el.find("#customizable_popup")[0].setAttribute("style", "display:none");
    } else {
        wrapper.view.$el.find("#customizable_popup")[0].setAttribute("style", "display:block");
        document.getElementById("setting_acordion").setAttribute("style", "display:block");
        editor.select(wrapper.view.$el.find("#popup_content")[0]);
    }
};

export default customPopup;