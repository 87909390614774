const active = "active";
const portEnable = "port-enable";

const removeClassFromCurrentPage = (cell, classToBeRemoved) => {
    cell.unhighlight(null, {
        highlighter: {
            name: "addClass",
            options: {
                className: classToBeRemoved
            }
        }
    });
};
const removeClassFromAllPages = (StencilPaper, classToBeRemoved) => {
    StencilPaper
        .findViewsInArea(StencilPaper.getArea())
        .forEach(cell => {
            removeClassFromCurrentPage(cell, classToBeRemoved);
        });
};
const addClassToCurrentPage = (cell, classToBeAdded) => {
    cell.highlight(null , {
        highlighter: {
            name: "addClass",
            options: {
                className: classToBeAdded
            }
        }
    });
};
const addClassToAllPages = (StencilPaper, classToBeAdded) => {
    StencilPaper
        .findViewsInArea(StencilPaper.getArea())
        .forEach(cell => {
            addClassToCurrentPage(cell, classToBeAdded);
        });
};
export const pointerDownOnBlank = (StencilPaper) => {
    removeClassFromAllPages(StencilPaper, active);
    removeClassFromAllPages(StencilPaper, portEnable);
};

export const pointerDownOnPage = (StencilPaper, cellView) => {
    removeClassFromAllPages(StencilPaper, active);
    addClassToAllPages(StencilPaper, portEnable);
    addClassToCurrentPage(cellView, active);
    removeClassFromCurrentPage(cellView, portEnable);
};