import { TEMPLATES_REDUCER_KEY } from "./reducer";

export function getTemplates(state) {
    return state[TEMPLATES_REDUCER_KEY].templates;
}

export function getUserTemplates(state) {
    return state[TEMPLATES_REDUCER_KEY].userTemplates;
}

export function getPremiumTemplates(state) {
    return state[TEMPLATES_REDUCER_KEY].premiumTemplates;
}