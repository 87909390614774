import {
    generatePageModel
} from "./generate-page-model";
import {
    generateLinkModel
} from "./generate-arrow-model";
import {
    generateTextModel
} from "./generate-text-model";
import {
    generateDottedLinkModel
} from "./generate-dotted-link-model";
import {
    generatePropertyMapFromFunnelPages
} from "./json-builder-helper";

const componentTypes = {
    page: "devs.MyImageModel",
    link: "link",
    text: "html.Element",
    dottedLink: "dottedLink"
};

export const generateVisualizerModel = (components, funnelPages) => {
    const visualizerData = { cells: [] };
    const pageToPropertyMap = generatePropertyMapFromFunnelPages(funnelPages);
    components.map((component, index) => {
        const cell = JSON.parse(component.componentValue);
        const cellType = cell.type;
        switch (cellType) {
            case componentTypes["page"]:
                return visualizerData.cells.push(generatePageModel(cell, pageToPropertyMap));
            case componentTypes["link"]:
                return visualizerData.cells.push(generateLinkModel(cell));
            case componentTypes["text"]:
                return visualizerData.cells.push(generateTextModel(cell, component.componentId));
            case componentTypes["dottedLink"]:
                return visualizerData.cells.push(generateDottedLinkModel(cell));
        }
    });
    return visualizerData;
};