import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { Modal } from "react-bootstrap";
import isEmpty from "lodash/isEmpty";
import { FunnelList } from "./funnels-list/funnels-list";
import { funnelConstants } from "./constants";
import "./styles.css";


export class Funnels extends React.Component {
    constructor(props, context) {
        super(props, context);
        // this.redirectToAddCoursePage = this.redirectToAddCoursePage.bind(this);
        this.deleteRow = this.deleteRow.bind(this);
        this.handleDeleteRow = this.handleDeleteRow.bind(this);
        this.state = {
            funnels: Object.assign({}, props.funnels),
            search: "",
            field: "title",
            modalShow: false,
            deleteFunnelId: "",
            walkthroughModalTiles: [],
        };
        this.props.loadFunnels().catch(() => {});
        this.props.loadCampaigns().catch(() => {});
        this.props.loadCompany().catch(() => {});
        this.createNewFunnelFlow = this.createNewFunnelFlow.bind(this);
    }
    componentDidMount() {
    }
    componentWillReceiveProps(nextProps) {
        if (this.props.funnels !== nextProps.funnels) {
            this.setState({ funnels: Object.assign({}, nextProps.funnels) });
        }
    }

    // redirectToAddCoursePage() {
    //     this.props.history.push(funnelConstants.funnelPath);
    // }

    deleteRow(funnelReferenceId) {
        this.setState({ modalShow: true, deleteFunnelId: funnelReferenceId });
    }
    handleDeleteRow() {
        this.props.deleteFunnel(this.state.deleteFunnelId);
        this.setState({ modalShow: false });
    }
    handleModal= () => {
        this.setState({ modalShow: false });
    }
    funnelDetails= (funnelReferenceId) => {
        const { editFunnelPath, manageFunnelPath } = funnelConstants;
        var found = this.props.funnels.find(function (element) {
            return element.referenceId === funnelReferenceId;
        });
        var isOldFunnel = found.isVisualizer;
        if (!found) {
            this.props.history.push("/404");
        } else if (!isOldFunnel) {
            this.props.history.push({ pathname: `${manageFunnelPath}${funnelReferenceId}`, state: "Oldfunnel" });
        } else {
            this.props.history.push({ pathname: `${editFunnelPath}${funnelReferenceId}` });
        }
    }
    createNewFunnelFlow() {
        const {
            history,
            walkthroughModalTiles,
            openWalkthroughModal,
        } = this.props;
        if (!isEmpty(walkthroughModalTiles)) {
            openWalkthroughModal();
        } else {
            history.push({
                pathname: "/funnel",
            });
        }
    }
    render() {
        const field = this.state.field;
        const { isLoading } = this.props;
        const { funnelLabel, home, deleteFunnel, confirmation, yes, no } = funnelConstants;
        const funnels = this.props.funnels.filter(
            (funnel) => {
                return String(funnel[field]).toLowerCase().indexOf(String(this.state.search).toLowerCase()) !== -1;
            }
        );
        const page = funnels && <FunnelList isLoading={isLoading} funnelDetails={this.funnelDetails} funnels={funnels} deleteRow ={this.deleteRow} createFunnel={this.createNewFunnelFlow} />;
        return (
            <>
                <div className="row wrapper border-bottom white-bg page-heading" style={{ paddingTop: "1%", paddingBottom: "1%", }}>
                    <div className="col-sm-6 col-xs-12">
                        <h2>{funnelLabel}</h2>
                        <ol className="breadcrumb">
                            <li>
                                <Link to="/dashboard">{home}</Link>
                            </li>
                            <li className="active">
                                <a>{funnelLabel}</a>
                            </li>
                        </ol>
                    </div>
                </div>
                {page}
                <Modal show={this.state.modalShow} backdrop = "static" onHide={this.handleClose} bsSize="small" aria-labelledby="contained-modal-title-lg">
                    <Modal.Header >
                        <Modal.Title><h3 className="text-white">{confirmation}</h3></Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <h3 className="text-center">{deleteFunnel}</h3>
                    </Modal.Body>
                    <Modal.Footer>
                        <a className="btn btn-primary" onClick = {this.handleDeleteRow}>{yes}</a>
                        <a className="btn btn-default" onClick = {this.handleModal}>{no}</a>
                    </Modal.Footer>
                </Modal>
            </>
        );
    }
}
Funnels.propTypes = {
    funnels: PropTypes.array.isRequired,
    actions: PropTypes.object.isRequired,
    loadFunnels: PropTypes.func,
    deleteRow: PropTypes.func,
    deleteFunnel: PropTypes.func,
    loadCampaigns: PropTypes.func,
    isLoading: PropTypes.bool,
    loadCompany: PropTypes.func,
    history: PropTypes.object,
    openWalkthroughModal: PropTypes.func,
    walkthroughModalTiles: PropTypes.array,
};
