import React, { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import isEmpty from "lodash/isEmpty";
import Elements from "../Elements";
import Graph from "../JointjsConfig/graph";
import Paper from "../JointjsConfig/paper";
import {
    getCellViewModel
} from "../../../../utilities/jointjs-helper";

const ToolbarHeight = 3800;

const makeElementProps = ({
    label,
    templateTypeId,
    thumbnailSvg,
    isFolder,
    children,
}, posY) => ({
    posY,
    label,
    imageSvg: thumbnailSvg,
    templateTypeId,
    isFolder,
    children,
});

const LeftPanel = ({
    pageTypes,
    onLeftPanelCreated,
}) => {
    const toolBarRef = useRef(null);
    let isExpanded = false;
    const generateLeftPanel = (toolBarGraph, pageTypesToShow) => {
        let posY = 0;
        const leftpanelData = [];
        pageTypesToShow.forEach((page, index) => {
            posY = (index * 140 + 60);
            leftpanelData.push(Elements(makeElementProps(page, posY)));
        });
        toolBarGraph.addCell([leftpanelData]);
    };
    useEffect(() => {
        const toolbarGraph = Graph();
        const toolbarPaper = Paper(toolBarRef.current, toolbarGraph, 160 , ToolbarHeight, 0, false);
        generateLeftPanel(toolbarGraph, pageTypes);
        toolbarPaper.on("element:pointerclick", (cell) => {
            const cellModel = getCellViewModel(cell);
            const cellMetadata = cellModel.get("elementMetaData");
            const templateTypeId = cellMetadata.templateTypeId;
            if (cellModel.get("isFolder")) {
                if (isExpanded) {
                    toolbarGraph.clear();
                    generateLeftPanel(toolbarGraph, pageTypes);
                    isExpanded = false;
                } else {
                    const children = cellModel.get("children");
                    if (!isEmpty(children)) {
                        const selectedPageIndex = pageTypes.findIndex((page) => page.templateTypeId === templateTypeId);
                        const updatedPageTypes = [
                            ...pageTypes.slice(0, selectedPageIndex + 1),
                            ...children,
                            ...pageTypes.slice(selectedPageIndex + 1, pageTypes.length)
                        ];
                        toolbarGraph.clear();
                        generateLeftPanel(toolbarGraph, updatedPageTypes);
                        isExpanded = true;
                    }
                }
            }
        });
        onLeftPanelCreated({
            toolbarGraph,
            toolbarPaper,
        });
    }, []);
    return (
        <div className="visualizer-left-panel">
            <div className="pageList">
                <div ref={toolBarRef}></div>
            </div>
        </div>
    );
};

LeftPanel.propTypes = {
    pageTypes: PropTypes.array,
    droppableBoundingBox: PropTypes.object,
    onLeftPanelCreated: PropTypes.function,
    handlePageDropOnVisualizer: PropTypes.func,
    emitter: PropTypes.object,
};

export default LeftPanel;