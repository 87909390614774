import { APIHelper } from "../utilities";
// import moment from "moment";
import { rootUrl } from "./urls";

export function editPage(pageId, params) {
    return APIHelper.baseRequest(APIHelper.PUT, `${rootUrl}/Page/${pageId}`, {
        requestBody: params,
        headers: APIHelper.getGenericHeaders()
    });
}

export function deletePage(pageId) {
    return APIHelper.baseRequest(APIHelper.DELETE, `${rootUrl}/Page/${pageId}`, {
        headers: APIHelper.getGenericHeaders()
    });
}

export function getPage(pageId) {
    return APIHelper.baseRequest(APIHelper.GET, `${rootUrl}/Page/${pageId}`, {
        headers: APIHelper.getGenericHeaders()
    });
}
export function getAllPageTypes() {
    return APIHelper.baseRequest(APIHelper.GET, `${rootUrl}/pagetype`, {
        headers: APIHelper.getGenericHeaders()
    });
}