import React from "react";
import PropTypes from "prop-types";
import { TextInput } from "../../../commons";

export const SharePage = ({ openShare, shareClass, copyMessage, isTemplateId, isEdited, currentPage, copyShareId, importShareId, handleImportShareIdChange, isShareIdError, isShareidSame, importSharePage }) => (
    <>
        <div className="share-section">
            <button className= "btn btn-primary" onClick={openShare}><i class="fa fa-share-square-o m-r-xs" aria-hidden="true"></i> Share</button>
        </div>
        {
            <div className={shareClass}>
                <div className="col-xs-12 no-padding">
                    <span className="pointer pull-right" onClick={openShare}><i class="fa fa-close" aria-hidden="true"></i></span>
                    <div className={copyMessage}>Share Id Copied</div>
                    {isTemplateId && isEdited ?
                        [<TextInput
                            name="shareId"
                            label={"Share Id "}
                            value={currentPage && currentPage.pageView && currentPage.pageView[0].shareId}
                            id="shareId"
                            btnText="Copy"
                            note={"Give this Share Id to other FunnelKonnekt users to share this page"}
                        />,
                        <div className="text-right"><button className="btn btn-primary" onClick={copyShareId}><i class="fa fa-clone m-r-xs" aria-hidden="true"></i> Copy</button></div>]
                        : <h3>Please edit the page to share</h3>}
                    <hr className="m-b-xs" />
                    <hr className="m-t-xs" />
                    <TextInput
                        name="importId"
                        label={"Import A Page"}
                        value={importShareId}
                        placeholder={"Enter Share Id here"}
                        onChange={handleImportShareIdChange}
                        id="importId"
                        btnText="Import"
                        error={isShareIdError ? true : false}
                        errorMessage={isShareidSame ? "Please enter different Share Id" : "Please enter valid Share Id"}
                    />
                    <div className="text-right"><button className="btn btn-primary" onClick={importSharePage}><i class="fa fa-download m-r-xs" aria-hidden="true"></i> Import</button></div>
                </div>
            </div>
        }
    </>
);
SharePage.propTypes = {
    openShare: PropTypes.func,
    shareClass: PropTypes.string,
    copyMessage: PropTypes.string,
    isTemplateId: PropTypes.bool,
    isEdited: PropTypes.bool,
    currentPage: PropTypes.object,
    copyShareId: PropTypes.func,
    importShareId: PropTypes.string,
    handleImportShareIdChange: PropTypes.func,
    isShareIdError: PropTypes.bool,
    isShareidSame: PropTypes.bool,
    importSharePage: PropTypes.func,
};

export default SharePage;