import React, { Component } from "react";
import PropTypes from "prop-types";
import {
    Modal,
} from "react-bootstrap";
import isEmpty from "lodash/isEmpty";
import isNumber from "lodash/isNumber";
import isFunction from "lodash/isFunction";
import isArray from "lodash/isArray";
import memoize from "lodash/memoize";
import constants from "../utilities/constants";
import { NotificationEmitter, NOTIFICATION_EVENT_NAME } from "../../../commons/notification";

const sortTemplates = memoize((templates = []) => {
    const arrayToSort = isArray(templates) ? templates.slice() : [];
    return arrayToSort.sort((a , b) => {
        return b.templateTypeId < constants.loginTemplateTypeId && a.templateTypeId < constants.loginTemplateTypeId ?
            b.templateTypeId - a.templateTypeId
            : a.templateTypeId - b.templateTypeId;
    });
});

export default class ChooseTemplates extends Component {
    constructor(props) {
        super(props);
        this.state = {
            itemsToDisplay: [],
            activeTab: props.initialTab,
            isLoadingTemplates: false,
            purchasedTemplates: [],
            onBeforeShowStatus: null,
        };
        this.getFilteredItemsToDisplay = this.getFilteredItemsToDisplay.bind(this);
        this.getActiveTemplateConfig = this.getActiveTemplateConfig.bind(this);
        this.setLoadingTemplates = this.setLoadingTemplates.bind(this);
        this.addDataFetchedForTab = this.addDataFetchedForTab.bind(this);
        this.setActiveTab = this.setActiveTab.bind(this);
        this.onThumbnailSelected = this.onThumbnailSelected.bind(this);
        this.dataApiFetched = [];
        this.onBeforeShowExecuting = false;
    }

    setLoadingTemplates(isLoadingTemplates) {
        this.setState({
            isLoadingTemplates,
        });
    }

    getFilteredItemsToDisplay(items = []) {
        const {
            templateTypeIdToFilter
        } = this.props;
        return sortTemplates(items).filter(
            (item) => {
                return (
                    (item.templateTypeId === 6 && templateTypeIdToFilter) ||
                    (item.templateTypeId && item.templateTypeId === templateTypeIdToFilter)
                );
            }
        );
    }

    addDataFetchedForTab(tabName) {
        this.dataApiFetched.push(tabName);
    }

    setActiveTab(tabIndex) {
        this.setState({ activeTab: tabIndex, onBeforeShowStatus: null });
    }

    getActiveTemplateConfig() {
        const {
            templateSelectionConfig,
        } = this.props;
        const {
            activeTab,
        } = this.state;
        return (isNumber(activeTab) ? templateSelectionConfig[activeTab] : null);
    }

    onThumbnailSelected(template) {
        const {
            onClose,
        } = this.props;
        const selectedTemplateConfig = this.getActiveTemplateConfig();
        if (selectedTemplateConfig) {
            selectedTemplateConfig.onThumbnailSelected(template, onClose);
        }
    }

    renderTabContent() {
        const {
            isLoadingTemplates,
            purchasedTemplates,
            onBeforeShowStatus,
        } = this.state;
        const selectedTemplateConfig = this.getActiveTemplateConfig();
        if (selectedTemplateConfig) {
            const itemsToDisplay = selectedTemplateConfig.thumbnails();
            let filteredItemsToDisplay = [];
            const ThumbnailComponent = selectedTemplateConfig.thumbnailComponent;
            if (!isEmpty(itemsToDisplay)) {
                /*
                 * [TODO - Refactor]
                 * This logic doesn't belong in this component.
                 * This onBeforeShow has to be moved out to the parent component.
                 */
                if (selectedTemplateConfig.onBeforeShow && isEmpty(purchasedTemplates) && !onBeforeShowStatus) {
                    if (!this.onBeforeShowExecuting) {
                        this.onBeforeShowExecuting = true;
                        this.setState({ isLoadingTemplates: true, });
                        selectedTemplateConfig.onBeforeShow()
                            .then(({ result }) => {
                                this.setState({ purchasedTemplates: result, isLoadingTemplates: false, onBeforeShowStatus: "success" });
                                this.onBeforeShowExecuting = false;
                            })
                            .catch(() => {
                                NotificationEmitter.emit(NOTIFICATION_EVENT_NAME, { type: "error", message: "There is a problem getting purchased templates.", closeButton: true });
                                this.setState({ isLoadingTemplates: false, onBeforeShowStatus: "failed" });
                                this.onBeforeShowExecuting = false;
                            });
                    }
                }
                if (
                    (isFunction(selectedTemplateConfig.onBeforeShow) && onBeforeShowStatus === "success") ||
                    (!isFunction(selectedTemplateConfig.onBeforeShow))
                ) {
                    filteredItemsToDisplay = this.getFilteredItemsToDisplay(itemsToDisplay);
                    if (selectedTemplateConfig.showPrice) {
                        filteredItemsToDisplay = filteredItemsToDisplay.map((item) => {
                            if (!isEmpty(purchasedTemplates)) {
                                const isPurchasedTemplate = purchasedTemplates.find((purchasedTemplate) => item.referenceId === purchasedTemplate.templateReferenceId);
                                return {
                                    ...item,
                                    isPurchased: (isPurchasedTemplate) ? true : false,
                                };
                            }
                            return item;
                        });
                    }
                }
            } else {
                if (this.dataApiFetched.indexOf(selectedTemplateConfig.name) === -1 && isFunction(selectedTemplateConfig.fetchData)) {
                    this.addDataFetchedForTab(selectedTemplateConfig.name);
                    this.setLoadingTemplates(true);
                    selectedTemplateConfig.fetchData()
                        .then(() => {this.setLoadingTemplates(false);})
                        .catch(() => {this.setLoadingTemplates(false);});
                }
            }
            return (
                <div className="row page-heading p-h-sm wrapper">
                    {
                        isLoadingTemplates && (
                            <div className="api-fetch-loader p-sm">
                                <i className="fa fa-spinner fa-pulse fa-lg m-r-xs" />
                                Loading Templates
                            </div>
                        )
                    }
                    {
                        !isLoadingTemplates && isEmpty(filteredItemsToDisplay) && (
                            <div className="alert alert-danger">
                                No templates found.
                            </div>
                        )
                    }
                    <div className="centerList col-xs-12 page-template-card">
                        <div className="row">
                            {
                                filteredItemsToDisplay.map((item) => {
                                    const thumbnailUrl = item.thumbnailUrl + "?" + new Date().getTime();
                                    return (
                                        <ThumbnailComponent
                                            template={item}
                                            onTemplateSelected={this.onThumbnailSelected}
                                            thumbnailUrl={thumbnailUrl}
                                            showPrice={selectedTemplateConfig.showPrice}
                                            classNames=""
                                        />
                                    );
                                })
                            }
                        </div>
                    </div>
                </div>
            );
        }
    }

    render() {
        const {
            onClose,
            children,
            templateSelectionConfig,
        } = this.props;
        const {
            activeTab,
        } = this.state;
        return (
            <Modal
                show={true}
                backdrop="static"
                onHide={onClose}
                bsSize=""
                className="existing-funnels-modal"
                aria-labelledby="contained-modal-title-lg"
            >
                <Modal.Header closeButton>
                    <Modal.Title>
                        Choose Templates
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body className="thumbnail-card-container">
                    <div className="modal-right-options">
                        {
                            children
                        }
                    </div>
                    <ul className="nav nav-tabs">
                        {
                            templateSelectionConfig.map((config, index) => (
                                <li className={`${activeTab === index ? "active" : ""}`} key={config.name}>
                                    <a href="#" onClick={() => this.setActiveTab(index)}> { config.name } </a>
                                </li>
                            ))
                        }
                    </ul>
                    <div className="tab-content">
                        {
                            this.renderTabContent()
                        }
                    </div>
                </Modal.Body>
            </Modal>
        );
    }
}

ChooseTemplates.propTypes = {
    onClose: PropTypes.func,
    show: PropTypes.bool,
    initialTab: PropTypes.number,
    templateSelectionConfig: PropTypes.array,
    templateTypeIdToFilter: PropTypes.number,
    children: PropTypes.element,
};

ChooseTemplates.defaultProps = {
    show: false,
    initialTab: 0,
};