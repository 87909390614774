import React from "react";
import PropTypes from "prop-types";
import FunnelForm from "../funnels-form";
import Stepzilla from "react-stepzilla";
import "../../../node_modules/react-stepzilla/src/css/main.css";
import { Modal } from "react-bootstrap";
import { Step1 } from "./steps/Step1";
import { Step2 } from "./steps/Step2";
import { Step3 } from "./steps/Step3";
import { NotificationEmitter, NOTIFICATION_EVENT_NAME } from "../../commons/notification";



export class CreateFunnel extends React.Component {
    constructor(props, context) {
        super(props, context);
        this.state = {
            count: 0,
            show: false,
            allOffers: props.offers,
            allUpsales: props.upsales,
            products: Object.assign({
                productId: "",
                productName: "",
                productSku: "",
                billerId: "",
                useOfferMid: 0,
                billingCycleType: "ONE_TIME",
                finalBillingCycle: "",
                minOrderPrice: "",
                maxOrderPrice: "",
                maxOrderQty: "",
                trialEnabled: 0,
                trialType: "",
                trialAuthType: "",
                showToAffiliates: 0,
                cycle: [
                    {
                        price: "",
                        isShippable: 0,
                        billDelay: 30,
                        productId: "",
                        combinationId: ""
                    },
                    {
                        price: "",
                        isShippable: 0,
                        billDelay: 30,
                        productId: "",
                        combinationId: ""
                    },
                    {
                        price: "",
                        isShippable: 0,
                        billDelay: 30,
                        productId: "",
                        combinationId: ""
                    }
                ],
                acceleratedTrialMinutes: ""
            }, props.products),
            funnel: Object.assign({
                title: "",
                author: "",
                campaignId: "",
                funnelType: "",
                offer: [""],
                upsale: [""],
                status: "incomplete"
            }, props.funnel),
            error: "",
            errors: {},
            isSuccess: false,
            campaign: Object.assign({}, this.getCampaignInitialState().campaign),
            upsells: this.getCampaignInitialState().upsells.slice(),
            offers: this.getCampaignInitialState().offers.slice()
        };
        this.history = props.history;
        this.validateCampaign = this.validateCampaign.bind(this);
        this.updateFunnelState = this.updateFunnelState.bind(this);
        this.saveFunnel = this.saveFunnel.bind(this);
        this.onOfferChange = this.onOfferChange.bind(this);
        this.onOfferChangeCampaign = this.onOfferChangeCampaign.bind(this);
        this.onUpsaleChangeCampaign = this.onUpsaleChangeCampaign.bind(this);
        this.onUpsaleChange = this.onUpsaleChange.bind(this);
        this.removeOfferField = this.removeOfferField.bind(this);
        this.removeOfferFieldCampaign = this.removeOfferFieldCampaign.bind(this);
        this.editUpsell = this.editUpsell.bind(this);
        this.getCampaignInitialState = this.getCampaignInitialState.bind(this);
        this.removeUpsaleField = this.removeUpsaleField.bind(this);
        this.removeUpsaleFieldCampaign = this.removeUpsaleFieldCampaign.bind(this);
        this.onCampaignChange = this.onCampaignChange.bind(this);
        this.cancel = this.cancel.bind(this);
        this.validate = this.validate.bind(this);
        this.campaignReroute = this.campaignReroute.bind(this);
        this.handleClose = this.handleClose.bind(this);
        this.updateNewCampaignState = this.updateNewCampaignState.bind(this);
        this.saveCampaign = this.saveCampaign.bind(this);
        this.editOffer = this.editOffer.bind(this);
        this.editPrice = this.editPrice.bind(this);
        this.editPriceUpsell = this.editPriceUpsell.bind(this);
        this.props.loadCampaigns().catch(() => {});
        this.props.loadProducts().catch(() => {});
        this.props.loadMerchants().catch(() => {});
    }
    componentWillReceiveProps(nextProps) {
        if (this.props.products !== nextProps.products) {
            this.setState({ products: nextProps.products });
        }
        if (this.props.error !== nextProps.error) {
            this.setState({ error: nextProps.error });
        }
        if (!nextProps.isLoading && !nextProps.isError) {
            //this.history.push("/funnels");
        }
    }
    campaignReroute(e) {
        e.preventDefault();
        this.setState({ show: true });
    }
    validate(event) {
        const funnel = this.state.funnel;
        const offers = this.state.allOffers;
        (!funnel.title) ? this.setState({ errors: { title: true } }) :
            // (!funnel.author) ? this.setState({ errors: { author: true } }) :
            (!funnel.campaign) ? this.setState({ errors: { campaignId: true } }) :
                // (!funnel.fListFunnelTypeId) ? this.setState({ errors: { funnelType: true } }) :
                (funnel.offer[0] === "") ? this.setState({ errors: { offerId: true } }) :
                    //(funnel.upsale[0] === "") ? this.setState({ errors: { upsaleId: true } }) : if we need upsale validation on future
                    this.setState({ isSuccess: true }, this.saveFunnel(event));
        return this.state.isSuccess;
    }
    updateNewCampaignState(event) {
        const field = event.target.name;
        const campaign = Object.assign({}, this.state.campaign);
        // if (this.state.funnel.title) {
        //     campaign.campaignName = this.state.funnel.title.slice();
        // }
        if (field === "bundleFulfillment" || field === "blockPrepaid" || field === "blockChargebackBlacklist") {
            campaign[field] = this.state.campaign[field] === 0 ? 1 : 0;
        } else {
            campaign[field] = event.target.value;
        }
        return this.setState({ campaign });
    }
    updateFunnelState(event) {
        const field = event.target.name;
        const funnel = this.state.funnel;
        funnel[field] = event.target.value;
        if (event.target.value.length >= 200) {
            alert("Funnel title should not exceed 200 characters");
        }
        return this.setState({ funnel: funnel });
    }
    getCampaignInitialState() {
        return {
            campaign: {
                campaignName: "",
                campaignType: "LANDER",
                currency: "USD",
                campaignCategoryName: "FunnelKonnekt",
                bundleFulfillment: 0,
                blockPrepaid: 0,
                blockChargebackBlacklist: 0,
            },
            offers: [{
                productId: "choose",
                productName: "",
                productSku: "",
                billerId: "",
                useOfferMid: 0,
                billingCycleType: "ONE_TIME",
                finalBillingCycle: "",
                minOrderPrice: "",
                maxOrderPrice: "",
                maxOrderQty: "",
                trialEnabled: 0,
                productCategoryName: "",
                trialType: "STANDARD",
                trialAuthType: "",
                showToAffiliates: 0,
                cycle: [
                    {
                        price: "",
                        isShippable: 0,
                        billDelay: 30,
                        productId: "",
                        combinationId: ""
                    },
                    {
                        price: "",
                        isShippable: 0,
                        billDelay: 30,
                        productId: "",
                        combinationId: ""
                    },
                    {
                        price: "",
                        isShippable: 0,
                        billDelay: 30,
                        productId: "",
                        combinationId: ""
                    }
                ],
                acceleratedTrialMinutes: ""
            }],
            upsells: [{
                productId: "choose",
                productName: "",
                productSku: "",
                billerId: "",
                billingCycleType: "ONE_TIME",
                finalBillingCycle: "",
                minOrderPrice: "",
                maxOrderPrice: "",
                maxOrderQty: "",
                productCategoryName: "",
                trialEnabled: 0,
                useOfferMid: 0,
                trialType: "STANDARD",
                trialAuthType: "",
                showToAffiliates: 0,
                cycle: [
                    {
                        price: "",
                        isShippable: 0,
                        billDelay: 30,
                        productId: "",
                        combinationId: ""
                    },
                    {
                        price: "",
                        isShippable: 0,
                        billDelay: 30,
                        productId: "",
                        combinationId: ""
                    },
                    {
                        price: "",
                        isShippable: 0,
                        billDelay: 30,
                        productId: "",
                        combinationId: ""
                    }
                ],
                acceleratedTrialMinutes: ""
            }],
        };
    }
    onCampaignChange(event) {
        const field = event.target.name;
        const funnel = this.state.funnel;
        const { offer, upsale } = funnel;
        funnel[field] = event.target.value;
        let offersList = [];
        let upsaleList = [];
        const selectedCampaign = this.props.campaigns.find ((c) => event.target.value === `${c.value}`);
        if (selectedCampaign) {
            const productOffer = selectedCampaign.products.filter(p => p.productType === "OFFER");
            const productUpsell = selectedCampaign.products.filter(p => p.productType === "UPSALE");
            offersList = productOffer.map(product => {
                return {
                    value: product.campaignProductId,
                    text: product.productName,
                    type: product.productType
                };
            });
            upsaleList = productUpsell.map(product => {
                return {
                    value: product.campaignProductId,
                    text: product.productName,
                    type: product.productType
                };
            });
        }
        return this.setState({ funnel: { ...funnel, offer: [""], upsale: [""] }, allOffers: offersList, allUpsales: upsaleList });
    }
    onOfferChange(event, index) {
        const value = event.target.value;
        const offers = this.state.allOffers.find(o => `${o.value}` === value);
        const { funnel } = this.state;
        const { offer } = funnel;
        if (value === "all") {
            const offers = this.state.allOffers;
            offer.splice(index, 1);
            offers.forEach(oneOffer => {
                if (oneOffer.value !== "all" && oneOffer !== "") {
                    offer.splice(0, 0, oneOffer);
                }
            });
        } else {
            offer.splice(index, 1, offers);
        }
        return this.setState({ funnel: {
            ...funnel,
            offer
        } });
    }
    validateCampaign(event, func, value) {
        let result;
        if (event)
            event.preventDefault();
        const { campaign, offers, upsells } = this.state;
        if (campaign.campaignName.trim() === "") {
            alert("Campaign Name is a required Field");
        } else if (offers[0].productId === "choose" && value === 1) {
            alert("Must Choose An Offer");
        } else if (campaign.campaignName.length > 50) {
            alert("Campaign Name can not exceed 50 characters");
        } else if (offers[0] && value === 2 || value === 4) {
            const count = offers.length;
            let offersCount = 0;
            offers.forEach((offer, i) => {
                if (offer.productName.trim() === "") {
                    alert(`Product Name is a required Field in offer ${i + 1}`);
                } else if (offer.productName.length > 50) {
                    alert(`Product Name can not exceed 50 characters in offer ${i + 1}`);
                } else if (offer.productSku && offer.productSku.length > 50) {
                    alert(`Product Sku can not exceed 50 characters in offer ${i + 1}`);
                } else if (offer.finalBillingCycle && !Number.isInteger(parseInt(offer.finalBillingCycle))) {
                    alert(`Final Billing Cycle Must Be A Number in Offer ${i + 1}`);
                } else if (offer.billerId === "") {
                    alert(`Choose A Merchant Account in Offer ${i + 1}`);
                } else if (offer.acceleratedTrialMinutes.toString().length > 5) {
                    alert(`Accelerated Trial Minutes is too big in Offer ${i + 1}`);
                } else if (offer.acceleratedTrialMinutes && !Number.isInteger(parseInt(offer.acceleratedTrialMinutes))) {
                    alert(`Accelerated Trial Minutes Must Be A Number in Offer ${i + 1}`);
                } else if (offer.finalBillingCycle.toString().length > 5) {
                    alert(`Final Billing Cycle Can Not Exceed 5 Digits In Offer ${i + 1}`);
                } else if (offer.billingCycleType === "") {
                    alert(`Billing Cycle is a required Field in offer ${i + 1}`);
                } else if (offer.cycle[0].billDelay && !Number.isInteger(parseInt(offer.cycle[0].billDelay))) {
                    alert(`Billing Delay Must be a Number in Offer ${i + 1}`);
                } else if (offer.cycle[0].price === "" || !Number.isInteger(parseInt(offer.cycle[0].price))) {
                    alert("Price is a mandatory field in billing cycle 1");
                } else {
                    offersCount++;
                }
                if (offersCount === count) {
                    if (value === 4)
                        result = true;
                    else
                        func(value);
                }
            });
        } else if (upsells[0] && upsells[0].productId !== "choose" && value === 5) {
            const count = upsells.length;
            let upsellCount = 0;

            upsells.forEach((upsell, i) => {
                if (upsell.productName === "" && upsell.productId !== "choose") {
                    alert(`Product Name is a required Field in upsell ${i + 1}`);
                } else if (upsell.productName.length > 50 && upsell.productId !== "choose") {
                    alert(`Product Name can not exceed 50 characters in upsell ${i + 1}`);
                } else if (upsell.productSku && upsell.productSku.length > 50 && upsell.productId !== "choose") {
                    alert(`Product Sku can not exceed 50 characters in upsell ${i + 1}`);
                } else if (upsell.finalBillingCycle.toString().length > 5) {
                    alert(`Final Billing Cycle is too big in Upsell ${i + 1}`);
                } else if (upsell.acceleratedTrialMinutes.toString().length > 5) {
                    alert(`Accelerated Trial Minutes is too big in Upsell ${i + 1}`);
                } else if (upsell.acceleratedTrialMinutes && !Number.isInteger(parseInt(upsell.acceleratedTrialMinutes))) {
                    alert(`Accelerated Trial Minutes Must Be A Number in Upsell ${i + 1}`);
                } else if (upsell.finalBillingCycle && !Number.isInteger(parseInt(upsell.finalBillingCycle))) {
                    alert(`Final Billing Cycle Must Be A Number in Upsell ${i + 1}`);
                } else if (upsell.billingCycleType === "" && upsell.productId !== "choose") {
                    alert(`Billing Cycle is a required Field in upsell ${i + 1}`);
                } else if (upsell.billerId === "" && upsell.useOfferMid === 0 && upsell.productId !== "choose") {
                    alert(`Choose A Merchant Account in upsell ${i + 1}`);
                } else if (upsell.cycle[0].billDelay && !Number.isInteger(parseInt(upsell.cycle[0].billDelay))) {
                    alert(`Billing Delay Must be a Number in Upsell ${i + 1}`);
                } else if (upsell.productId !== "choose" && upsell.cycle[0].price === "" || !Number.isInteger(parseInt(upsell.cycle[0].price))) {
                    alert(`Price is a mandatory field in billing cycle 1 in upsell ${i + 1}`);
                } else if (upsell.productId === "choose") {
                    upsellCount++;
                } else {
                    upsellCount++;
                }
                if (upsellCount === count) {
                    if (value === 5)
                        result = true;
                    else
                        func(value);
                }
            });
        } else {
            if (value === 4 || value === 5)
                result = true;
            else
                func(value);
        }
        return result;
    }
    onOfferChangeCampaign(event, index) {
        const value = event.target.value;
        let products = this.state.products.find(o => `${o.productId}` === value);
        // const { campaign } = this.state;
        if (products) {
            products = {
                productId: products.productId,
                productName: products.productName,
                productSku: products.productSku,
                billerId: "",
                billingCycleType: "ONE_TIME",
                finalBillingCycle: "",
                minOrderPrice: "",
                maxOrderPrice: "",
                maxOrderQty: "",
                trialEnabled: 0,
                trialType: "STANDARD",
                trialAuthType: "",
                showToAffiliates: 0,
                cycle: [
                    {
                        price: "",
                        isShippable: 0,
                        billDelay: 30,
                        productId: "",
                        combinationId: ""
                    },
                    {
                        price: "",
                        isShippable: 0,
                        billDelay: 30,
                        productId: "",
                        combinationId: ""
                    },
                    {
                        price: "",
                        isShippable: 0,
                        billDelay: 30,
                        productId: "",
                        combinationId: ""
                    }
                ],
                acceleratedTrialMinutes: ""
            };
        } else {
            products = {
                productId: value,
                productName: "",
                productSku: "",
                billerId: "",
                productCategoryName: "FunnelKonnekt",
                useOfferMid: 0,
                billingCycleType: "ONE_TIME",
                finalBillingCycle: "",
                minOrderPrice: "",
                maxOrderPrice: "",
                maxOrderQty: "",
                trialEnabled: 0,
                trialType: "STANDARD",
                trialAuthType: "",
                showToAffiliates: 0,
                cycle: [
                    {
                        price: "",
                        isShippable: 0,
                        billDelay: 30,
                        productId: "",
                        combinationId: ""
                    },
                    {
                        price: "",
                        isShippable: 0,
                        billDelay: 30,
                        productId: "",
                        combinationId: ""
                    },
                    {
                        price: "",
                        isShippable: 0,
                        billDelay: 30,
                        productId: "",
                        combinationId: ""
                    }
                ],
                acceleratedTrialMinutes: ""
            };
        }
        const { offers } = this.state;
        offers.splice(index, 1, products);
        return this.setState({
            ...offers,
            offers
        });
    }
    editOffer(event, value, index) {
        const field = event.target.name;
        const { offers } = this.state;
        const newOffer = offers[value];
        if (field === "trialEnabled") {
            newOffer[field] = newOffer[field] === 0 ? newOffer[field] = 1 : newOffer[field] = 0;
        } else {
            newOffer[field] = event.target.value;
        }
        if (newOffer.billingCycleType === "ONE_TIME") {
            newOffer.trialEnabled = 0;
            newOffer.finalBillingCycle = "";
        }
        return this.setState({
            ...offers,
            newOffer
        });
    }
    editPrice(event, value, index) {
        const field = event.target.name;
        const { offers } = this.state;
        const newOffer = offers[value];
        const cycle = newOffer.cycle[index];
        if (field === "isShippable") {
            cycle[field] = cycle[field] === 0 ? 1 : 0;
        } else {
            cycle[field] = event.target.value;
        }
        return this.setState({
            ...offers,
            newOffer
        });
    }
    editPriceUpsell(event, value, index) {
        const field = event.target.name;
        const { upsells } = this.state;
        const newUpsell = upsells[value];
        const cycle = newUpsell.cycle[index];
        if (field === "isShippable") {
            cycle[field] = cycle[field] === 0 ? 1 : 0;
        } else {
            cycle[field] = event.target.value;
        }
        return this.setState({
            ...upsells,
            newUpsell
        });
    }
    editUpsell(event, value) {
        const field = event.target.name;
        const { upsells } = this.state;
        const newUpsell = upsells[value];
        if (field === "trialEnabled" || field === "showToAffiliates" || field === "useOfferMid") {
            newUpsell[field] = newUpsell[field] === 0 ? 1 : 0;
            if (newUpsell.useOfferMid === 1) {
                newUpsell.billerId = "";
            }
        } else {
            newUpsell[field] = event.target.value;
        }
        if (newUpsell.billingCycleType === "ONE_TIME") {
            newUpsell.trialEnabled = 0;
            newUpsell.finalBillingCycle = "";
        }
        return this.setState({
            ...upsells,
            newUpsell
        });
    }
    onUpsaleChangeCampaign(event, index) {
        const value = event.target.value;
        let products = this.state.products.find(o => `${o.productId}` === value);
        const { upsells } = this.state;

        if (products) {
            products = {
                productId: products.productId ? products.productId : "",
                productName: products.productName ? products.productName : "",
                productSku: products.productSku ? products.productSku : "",
                billerId: "",
                billingCycleType: "ONE_TIME",
                finalBillingCycle: "",
                minOrderPrice: "",
                maxOrderPrice: "",
                useOfferMid: 0,
                maxOrderQty: "",
                trialEnabled: 0,
                trialType: "STANDARD",
                trialAuthType: "",
                showToAffiliates: 0,
                cycle: [
                    {
                        price: "",
                        isShippable: 0,
                        billDelay: 30,
                        productId: "",
                        combinationId: ""
                    },
                    {
                        price: "",
                        isShippable: 0,
                        billDelay: 30,
                        productId: "",
                        combinationId: ""
                    },
                    {
                        price: "",
                        isShippable: 0,
                        billDelay: 30,
                        productId: "",
                        combinationId: ""
                    }
                ],
                acceleratedTrialMinutes: ""
            };
        } else {
            products = {
                productId: value,
                productName: "",
                productSku: "",
                billerId: "",
                productCategoryName: "FunnelKonnekt",
                useOfferMid: 0,
                billingCycleType: "ONE_TIME",
                finalBillingCycle: "",
                minOrderPrice: "",
                maxOrderPrice: "",
                maxOrderQty: "",
                trialEnabled: 0,
                trialType: "STANDARD",
                trialAuthType: "",
                showToAffiliates: 0,
                cycle: [
                    {
                        price: "",
                        isShippable: 0,
                        billDelay: 30,
                        productId: "",
                        combinationId: ""
                    },
                    {
                        price: "",
                        isShippable: 0,
                        billDelay: 30,
                        productId: "",
                        combinationId: ""
                    },
                    {
                        price: "",
                        isShippable: 0,
                        billDelay: 30,
                        productId: "",
                        combinationId: ""
                    }
                ],
                acceleratedTrialMinutes: ""
            };
        }
        upsells.splice(index, 1, products);
        return this.setState({
            ...upsells,
            upsells
        });
    }
    onUpsaleChange(event, index) {
        const value = event.target.value;
        const upsell = this.state.allUpsales.find(o => `${o.value}` === value);
        const { funnel } = this.state;
        const { upsale } = funnel;
        upsale.splice(index, 1);

        if (value === "all") {
            const upsells = this.state.allUpsales;
            upsale.splice(index, 1);
            upsells.forEach(oneUpsell => {
                if (oneUpsell.value !== "all" && oneUpsell !== "") {
                    upsale.splice(0, 0, oneUpsell);
                }
            });
        } else {
            upsale.splice(index, 1, upsell);
        }
        if (funnel.upsale[0] !== "") {
            this.setState({ isSuccess: true, errors: { upsaleId: false } });
        } else {this.setState({ isSuccess: false });}
        return this.setState({ funnel: {
            ...funnel,
            upsale
        } });
    }
    saveFunnel(event) {
        event.preventDefault();
        const {
            nextPageNavigation,
        } = this.props;
        this.props.actions.createFunnel(this.state.funnel)
            .then((funnelData) => {
                let urlToNavigate = nextPageNavigation.url;
                if (nextPageNavigation.isNavigateToVisualizer) {
                    urlToNavigate += funnelData.referenceId;
                }
                this.history.push({
                    pathname: urlToNavigate,
                    state: {
                        openFunnelTemplatesOnLoad: nextPageNavigation.openFunnelTemplatesOnLoad,
                        funnelTemplateTypeId: nextPageNavigation.funnelTemplateTypeId,
                    }
                });
            })
            .catch(err => {
                NotificationEmitter.emit(NOTIFICATION_EVENT_NAME, { type: "error", message: err.message });
            });
    }
    saveCampaign(event, func, value) {
        event.preventDefault();
        let { upsells, offers, campaign } = this.state;
        const result = this.validateCampaign(null, func, value);
        //eslint-disable-next-line
        if (result) {
            this.props.createCampaign(campaign, upsells, offers).then(() => {
                campaign = Object.assign({}, this.getCampaignInitialState().campaign);
                upsells = this.getCampaignInitialState().upsells.slice();
                offers = this.getCampaignInitialState().offers.slice();
                this.setState({ show: false, campaign, upsells, offers });
                this.history.push("/funnels");
            }).catch(() => {});
        }
    }
    cancel(event) {
        event.preventDefault();
        this.history.push("/funnels");
    }
    addOfferField = () => {
        const { funnel } = this.state;
        const { offer } = funnel;
        offer.push({});
        this.setState({
            funnel: {
                ...funnel,
                offer
            }
        });
    }
    addOfferFieldCampaign = () => {
        const { offers } = this.state;
        // let count = this.state.count++;
        // this.setState({ count });
        // this.checkProductsAndUpsells(count);
        offers.push({
            productId: "",
            productName: "",
            productSku: "",
            billerId: "",
            billingCycleType: "ONE_TIME",
            finalBillingCycle: "",
            minOrderPrice: "",
            maxOrderPrice: "",
            useOfferMid: 0,
            maxOrderQty: "",
            trialEnabled: 0,
            trialType: "",
            trialAuthType: "",
            billDelay: 30,
            showToAffiliates: 0,
            cycle: [
                {
                    price: "",
                    isShippable: 0,
                    billDelay: 30,
                    productId: "",
                    combinationId: ""
                },
                {
                    price: "",
                    isShippable: 0,
                    billDelay: 30,
                    productId: "",
                    combinationId: ""
                },
                {
                    price: "",
                    isShippable: 0,
                    billDelay: 30,
                    productId: "",
                    combinationId: ""
                }
            ],
            acceleratedTrialMinutes: ""
        });
        this.setState({
            offers
        });
    };
    addUpsaleField = () => {
        const { funnel } = this.state;
        const { upsale } = funnel;
        upsale.push({});
        this.setState({
            funnel: {
                ...funnel,
                upsale
            }
        });
    }
    addUpsaleFieldCampaign = () => {
        const { upsells } = this.state;
        // const { upsale } = campaign;
        upsells.push({
            productId: "choose",
            productName: "",
            productSku: "",
            billerId: "",
            billingCycleType: "ONE_TIME",
            finalBillingCycle: "",
            minOrderPrice: "",
            maxOrderPrice: "",
            useOfferMid: 0,
            maxOrderQty: "",
            trialEnabled: 0,
            trialType: "",
            trialAuthType: "",
            billDelay: 30,
            showToAffiliates: 0,
            cycle: [
                {
                    price: "",
                    isShippable: 0,
                    billDelay: 30,
                    productId: "",
                    combinationId: ""
                },
                {
                    price: "",
                    isShippable: 0,
                    billDelay: 30,
                    productId: "",
                    combinationId: ""
                },
                {
                    price: "",
                    isShippable: 0,
                    billDelay: 30,
                    productId: "",
                    combinationId: ""
                }
            ],
            acceleratedTrialMinutes: ""
        });
        this.setState({
            upsells
        });
    };
    removeOfferField = (index) => {
        const { funnel } = this.state;
        const { offer } = funnel;
        offer.splice(index,1);
        this.setState({
            funnel: {
                ...funnel,
                offer
            }
        });
    };
    removeOfferFieldCampaign = (index) => {
        const { offers } = this.state;
        // const { offer } = campaign;
        offers.splice(index,1);
        this.setState({
            offers
        });
    };
    handleClose() {
        const campaign = Object.assign({}, this.getCampaignInitialState().campaign);
        const upsells = this.getCampaignInitialState().upsells.slice();
        const offers = this.getCampaignInitialState().offers.slice();
        const error = "";
        this.setState({ show: false, campaign, upsells, offers, error });
    }
    removeUpsaleField = (index) => {
        const { funnel } = this.state;
        const { upsale } = funnel;
        upsale.splice(index,1);
        this.setState({
            funnel: {
                ...funnel,
                upsale
            }
        });
    }
    removeUpsaleFieldCampaign = (index) => {
        const { upsells } = this.state;
        upsells.splice(index,1);
        this.setState({
            upsells
        });
    }
    render() {
        let { isLoading, isError, error, merchants } = this.props;
        const steps = [
            {
                name: "Campaign",
                component: <Step1
                    removeOfferFieldCampaign={this.removeOfferFieldCampaign}
                    error={this.state.error}
                    removeUpsaleFieldCampaign={this.removeUpsaleFieldCampaign}
                    addOfferFieldCampaign={this.addOfferFieldCampaign}
                    onUpsaleChangeCampaign={this.onUpsaleChangeCampaign}
                    validateCampaign={this.validateCampaign}
                    campaign={this.state.campaign}
                    products={this.props.products}
                    funnel={this.state.funnel}
                    upsell={this.state.upsells}
                    offer={this.state.offers}
                    updateNewCampaignState={this.updateNewCampaignState}
                    onOfferChangeCampaign={this.onOfferChangeCampaign}
                    addUpsaleFieldCampaign={this.addUpsaleFieldCampaign}/>
            },
            {
                name: "Offer",
                component: <Step2
                    removeOfferFieldCampaign={this.removeOfferFieldCampaign}
                    removeUpsaleFieldCampaign={this.removeUpsaleFieldCampaign}
                    addOfferFieldCampaign={this.addOfferFieldCampaign}
                    validateCampaign={this.validateCampaign}
                    editPrice={this.editPrice}
                    editOffer={this.editOffer}
                    error={this.state.error}
                    offer={this.state.offers}
                    products={this.props.products}
                    upsells={this.state.upsells}
                    campaign={this.state.campaign}
                    saveCampaign={this.saveCampaign}
                    merchants={merchants}
                    updateNewCampaignState={this.updateNewCampaignState}
                    onOfferChangeCampaign={this.onOfferChangeCampaign}
                />
            },
            {
                name: "Upsell",
                component: <Step3
                    removeOfferFieldCampaign={this.removeOfferFieldCampaign}
                    removeUpsaleFieldCampaign={this.removeUpsaleFieldCampaign}
                    addOfferFieldCampaign={this.addOfferFieldCampaign}
                    validateCampaign={this.validateCampaign}
                    upsell={this.state.upsells}
                    products={this.props.products}
                    offer={this.state.offers}
                    editPriceUpsell={this.editPriceUpsell}
                    error={this.state.error}
                    campaign={this.state.campaign}
                    saveCampaign={this.saveCampaign}
                    merchants={merchants}
                    editUpsell={this.editUpsell}
                    updateNewCampaignState={this.updateNewCampaignState}
                    onUpsaleChangeCampaign={this.onUpsaleChangeCampaign}
                    addUpsaleFieldCampaign={this.addUpsaleFieldCampaign}/>
            }
        ];
        return (
            <div className="row wrapper border-bottom page-heading ">
                <div className="col-lg-12 m-t-md">
                    <div className="ibox">
                        <div className="ibox-content">
                            <h1 className="text-center" style={{ letterSpacing: "2px" }}>CREATE A FUNNEL</h1>
                            <div className="container-fluid">
                                { isError && <div className="alert alert-danger"><i className="fa fa-close fa-lg m-r-xs"/> Something Went Wrong... </div> }
                                { isLoading && <div className="alert alert-info"><i className="fa fa-spinner fa-pulse fa-lg m-r-xs"/> Please wait...</div> }
                                <FunnelForm funnel={this.state.funnel}
                                    onChange={this.updateFunnelState}
                                    onSave={this.validate}
                                    onCancel={this.cancel}
                                    allAuthors={this.props.authors}
                                    allCampaigns={this.props.campaigns}
                                    funnelType={this.props.funnelType}
                                    errors={this.state.errors}
                                    allOffers={this.state.allOffers}
                                    allUpsales={this.state.allUpsales}
                                    addOfferField={this.addOfferField}
                                    onOfferChange={this.onOfferChange}
                                    addUpsaleField={this.addUpsaleField}
                                    onUpsaleChange={this.onUpsaleChange}
                                    removeOfferField={this.removeOfferField}
                                    removeUpsaleField={this.removeUpsaleField}
                                    onCampaignChange={this.onCampaignChange}
                                    campaignReroute={this.campaignReroute}
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="animated fadeIn">
                    <Modal show={this.state.show} backdrop = "static" onHide={this.handleClose} bsSize="large" aria-labelledby="contained-modal-title-lg">
                        <Modal.Header closeButton style={{ backgroundColor: "rgb(47, 64, 77)", color: "white" }} >
                            <h1 className="text-center" style={{ fontWeight: "bold", letterSpacing: "2px", textTransform: "uppercase" }}>Create A Campaign</h1>
                        </Modal.Header>
                        <Modal.Body>
                            <div className="step-progress">
                                <Stepzilla
                                    stepsNavigation={false}
                                    steps={steps}
                                    showNavigation={false}
                                    prevBtnOnLastStep={false} />
                            </div>
                        </Modal.Body>
                    </Modal>
                </div>
            </div>
        );
    }
}

CreateFunnel.propTypes = {
    funnel: PropTypes.object.isRequired,
    authors: PropTypes.array.isRequired,
    campaigns: PropTypes.array.isRequired,
    funnelType: PropTypes.array.isRequired,
    offers: PropTypes.array.isRequired,
    actions: PropTypes.object.isRequired,
    isError: PropTypes.bool,
    isLoading: PropTypes.bool,
    history: PropTypes.object,
    upsales: PropTypes.array.isRequired,
    productType: PropTypes.string,
    allCampaigns: PropTypes.array,
    funnels: PropTypes.array,
    loadCampaigns: PropTypes.func,
    loadProducts: PropTypes.func,
    products: PropTypes.array,
    loadMerchants: PropTypes.array,
    merchants: PropTypes.array,
    createCampaign: PropTypes.func,
    jumpToStep: PropTypes.func,
    error: PropTypes.object,
    nextPageNavigation: PropTypes.object,
};
CreateFunnel.contextTypes = {
    router: PropTypes.object
};