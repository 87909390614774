import React, { Component } from "react";
import PropTypes from "prop-types";
import "../../../../../node_modules/jointjs/dist/joint.css";

export class VisualizerHeader extends Component {
    static propTypes = {
        editFunnel: PropTypes.func,
        funnel: PropTypes.object,
        visualizerData: PropTypes.object,
        updateVisualizerData: PropTypes.func,
        children: PropTypes.oneOfType([
            PropTypes.arrayOf(PropTypes.node),
            PropTypes.node
        ]),
    }
    constructor() {
        super();

    }

    dottedLine = () => {
        const { isDotted } = this.props.visualizerData;
        this.props.updateVisualizerData({
            ...this.props.visualizerData,
            isDotted: !isDotted,
            isText: false
        });
    }
    addText = () => {
        const { isText } = this.props.visualizerData;
        this.props.updateVisualizerData({
            ...this.props.visualizerData,
            isText: !isText,
            isDotted: false
        });
    }

    render() {
        const { isDotted, isText } = this.props.visualizerData;
        const dottedLine = isDotted ? "m-l-xs m-r-xs pull-right dottedLine active" : "m-l-xs m-r-xs pull-right dottedLine";
        const textelement = isText ? "m-l-xs pull-right text-element active" : "m-l-xs pull-right text-element";
        return (
            <div className="save-funnel">
                <h3 className="funnel-title"><span>{this.props.funnel.title}</span> <i onClick={this.props.editFunnel} className="fa fa-cog fa-spin m-l-sm pointer" /></h3>
                <div className="col-xs-10">
                    {this.props.children}
                    <span onClick={this.dottedLine} className={dottedLine}></span>
                    <span onClick={this.addText} className={textelement}>A</span>
                </div>
            </div>
        );
    }
}