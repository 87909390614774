import { showElement } from "./helper";

const handleStickyBarPositionUpdate = (editor, component) => {
    if (component.changed.stickyBarPosition === "top") {
        const sm = editor.SelectorManager;
        const posTop = sm.add("postionTheElement");
        let rule = editor.CssComposer.add([posTop]);
        rule.set("style", {
            "top": 0,
            "bottom": "inherit"
        });
        component.addClass("postionTheElement");
    } else if (component.changed.stickyBarPosition === "bottom" || component.changed.stickyBarPosition === "default") {
        const sm = editor.SelectorManager;
        const posTop = sm.add("postionTheElement");
        const rule = editor.CssComposer.add([posTop]);
        rule.set("style", {
            "top": "inherit",
            "bottom": 0
        });
        component.addClass("postionTheElement");
    } else if (component.changed.stickyBarPosition === "center") {
        const sm = editor.SelectorManager;
        const posTop = sm.add("postionTheElement");
        const rule = editor.CssComposer.add([posTop]);
        rule.set("style", {
            "top": "30%",
            "bottom": "inherit"
        });
        component.removeClass("placeBottom");
        component.addClass("postionTheElement");
    } else if (component.changed.stickyBarPosition === "left") {
        component.removeClass("placeRight");
        component.addClass("placeLeft");
    } else if (component.changed.stickyBarPosition === "right") {
        component.removeClass("placeLeft");
        component.addClass("placeRight");
    }
    showElement(editor, "stickyBarCustom","stickybar",component);
};

export default handleStickyBarPositionUpdate;