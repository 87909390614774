import { APIHelper } from "../utilities";
import { rootUrl, funnelTemplateRootUrl } from "./urls";
import { WebpageFormat } from "../webpage-creator";
import { JavascriptTemplate } from "../webpage-creator/js-template/template";
import { generateWebBuilderElements } from "./helpers";

export function savePage({
    html,
    css,
    pageViewReferenceId,
    component_json,
    styles_json,
    pageName = "index",
    templateId,
    pageData,
    buttonMetaData = null,
    generateThumbnail = false,
    isEdited = false,
    funnelReferenceId
}, { headers = {} }) {
    let funnelFormData = new FormData();
    funnelFormData.append("meta_json", new Blob([JSON.stringify(buttonMetaData ? buttonMetaData : "")], { type: "application/json" }), "meta.json");
    funnelFormData.append("name", "TestPage");
    funnelFormData.append("templateId", templateId);
    funnelFormData.append("generateThumbnail", generateThumbnail);
    funnelFormData.append("isEdited", isEdited);
    const wbf = new WebpageFormat(html, css, pageData);
    funnelFormData.append("html", new Blob([wbf.getFormattedWebPage()], { type: "text/html" }), `${pageName}.html`);
    funnelFormData.append("component_json", new Blob([component_json], { type: "text/html" }), `${pageViewReferenceId}_component.json`);
    funnelFormData.append("styles_json", new Blob([styles_json], { type: "text/css" }), `${pageViewReferenceId}_styles.json`);
    return APIHelper.baseRequest(APIHelper.PUT, `${funnelTemplateRootUrl}/PageViewTemplate/UpdatePageViewTemplate/${funnelReferenceId}/${pageViewReferenceId}`, {
        contentType: APIHelper.getMultipartReqHeader(),
        requestBody: funnelFormData,
        headers
    });
}

export function saveJSTemplate(params) {
    const funnelFormData = new FormData();
    funnelFormData.append("name", "JavascriptFile");
    funnelFormData.append("templateId", params.templateId);
    funnelFormData.append("isEdited", params.isEdited ? params.isEdited : false);
    funnelFormData.append("js", new Blob([JavascriptTemplate.getFileContent()], { type: "text/html" }), "index.js");
    return APIHelper.baseRequest(APIHelper.PUT, `${funnelTemplateRootUrl}/PageViewTemplate/UpdatePageViewTemplate/${params.funnelReferenceId}/${params.pageViewReferenceId}`, {
        contentType: APIHelper.getMultipartReqHeader(),
        requestBody: funnelFormData,
        headers: APIHelper.getGenericHeaders()
    });
}

export function updateWebBuilderElements({
    buttonMetaData,
    funnelTemplateReferenceId,
    pageViewReferenceId,
}) {
    const requestBody = generateWebBuilderElements(buttonMetaData);
    return APIHelper.baseRequest(APIHelper.PUT, `${rootUrl}/WebBuilderComponent/UpdateWebBuilderComponent/FunnelTemplate/${funnelTemplateReferenceId}/PageView/${pageViewReferenceId}`, {
        requestBody,
        headers: APIHelper.getGenericHeaders()
    });
}