import React, { createRef } from "react";
import PropTypes from "prop-types";
import GrapesJS from "grapesjs";
import { connect } from "react-redux";
import isEmpty from "lodash/isEmpty";
import isFunction from "lodash/isFunction";
import isArray from "lodash/isArray";

import GrapesJSComponent from "./grapesjs-component";
import FullPageLoader from "../commons/full-page-loader";

import { AssetEvents } from "./events/asset";
import { AssetManagerHelper } from "../utilities";

import * as webBuilderAction from "./actions";
import * as assetSelectors from "./selectors";

import "./preset";

import { onLoadBlocks } from "./blocks/events";

import "grapesjs/dist/css/grapes.min.css";
import "grapick/dist/grapick.min.css";
import "./../styles/grapesjs-preset-webpage.min.css";
import "./legacy/webpage-creator.css";

const editorId = "grapesjs-react-editor";

const cleanUpGrapesJSEditor = (editor) => {
    if (editor) {
        editor.destroy();
    }
    GrapesJS.editors = GrapesJS.editors.filter((e) => e !== editor);
};

class BaseWebBuilder extends React.Component {

    constructor() {
        super();

        this.initializeEditor = this.initializeEditor.bind(this);
        this.setEditor = this.setEditor.bind(this);
        this.getEditor = this.getEditor.bind(this);
        this.setEditorComponents = this.setEditorComponents.bind(this);
        this.setEditorCommands = this.setEditorCommands.bind(this);
        this.openCloseModal = this.openCloseModal.bind(this);
        this.assetReceive = this.assetReceive.bind(this);
        this.initializeComponents = this.initializeComponents.bind(this);
        this.editorInitEvents = this.editorInitEvents.bind(this);
        this.addAlignbox = this.addAlignbox.bind(this);
        this.toggleLoading = this.toggleLoading.bind(this);
        this.addFonts = this.addFonts.bind(this);
        this.destroyPanelButtons = this.destroyPanelButtons.bind(this);
        this.buttonMetaData = {
            buttons: [],
            links: [],
            jsPageData: {}
        };
        this.editor = null;
        this.isTraitChanged = false;
        this.state = {
            ModalComponent: null,
            showLoading: false,
            pageSaveSuccess: false,
            pageSaveError: null,
        };
        this.grapesRef = createRef();
    }

    async componentDidMount() {
        const {
            loadAsset,
            assetDataFromStore,
        } = this.props;
        cleanUpGrapesJSEditor();
        this.initializeEditor();
        const editor = this.getEditor();
        let assetData = (!isEmpty(assetDataFromStore) ? assetDataFromStore : await loadAsset());
        this.assetReceive(editor.AssetManager, assetData);
        window.CKEDITOR.dtd.$editable.a = 1;
    }

    componentWillUnmount() {
        const { editor } = this;
        this.destroyPanelButtons();
        cleanUpGrapesJSEditor(editor);
    }

    shouldComponentUpdate(nextProps, nextState) {
        /*
         * Render the web builder only when the Modal is being displayed
         */
        return (
            (nextState.ModalComponent !== this.state.ModalComponent) ||
            (nextState.showLoading !== this.state.showLoading)
        );
    }

    initializeEditor() {
        const {
            storageManager,
            blockManager,
            blocks,
            plugins,
            pluginsOpts,
            isLoading,
            panelButtons,
            id = editorId,
            additionalConfig,
            pageData,
            commands,
        } = this.props;
        const { editor } = this;
        if (!editor) {
            const editor = GrapesJS.init({
                allowScripts: 1,
                multipleSelection: 0,
                fromElement: false,
                forceClass: false,
                avoidInlineStyle: true,
                // container: `#${id}`,
                container: this.grapesRef.current,
                plugins,
                pluginsOpts,
                storageManager: storageManager,
                blockManager: blockManager,
                isLoading,
                ...additionalConfig,
            });
            editor.container = this;

            this.initializeComponents(editor);
            Object.keys(panelButtons).forEach(menuCategory => {
                const menuItemsForCategory = panelButtons[menuCategory];
                menuItemsForCategory.forEach((menuItem) => {
                    editor.Panels.addButton(menuCategory, menuItem);
                });
            });
            if (isArray(blocks)) {
                blocks.forEach((block) => {
                    editor.BlockManager.add(block.id, block);
                });
            }
            this.setEditorComponents(pageData, editor);
            this.setEditorCommands(commands, editor);
            this.setEditor(editor);
            this.addAlignbox(editor);
            this.attachEventListeners(editor);
            this.addFonts(editor);
            onLoadBlocks(editor);
            editor.on("load", this.editorInitEvents);
        } else {
            if (document) {
                document.getElementById(id).append(editor.render());
            }
        }
    }

    setEditor(editor) {
        this.editor = editor;
    }

    getEditor() {
        return this.editor;
    }

    toggleLoading(status) {
        this.setState({ showLoading: status });
    }

    setEditorComponents(pageData, editor = this.editor) {
        if (pageData) {
            const { setcomponentData, setstylesData, htmlString } = pageData;
            if (setcomponentData) {
                editor.setComponents(setcomponentData);
            } else if (htmlString) {
                editor.setComponents(htmlString);
            }
            if (setstylesData) {
                editor.setStyle(setstylesData);
            }
        }
    }

    setEditorCommands(commands, editor = this.editor) {
        if (commands) {
            Object.keys(commands).forEach((commandName) => {
                editor.Commands.add(commandName, { run: commands[commandName] });
            });
        }
    }

    initializeComponents(editor = this.editor) {
        const {
            components,
            traits,
            additionalComponentData,
        } = this.props;
        if (components) {
            components.forEach((componentInvoker) => {
                if (typeof componentInvoker === "function") {
                    componentInvoker(editor, traits, (additionalComponentData || {}));
                }
            });
        }
    }

    addAlignbox(editor) {
        var domComps = editor.DomComponents;
        var dType = domComps.getType("default");
        var _initialize = dType.model.prototype.initialize;
        dType.model.prototype.initialize = function () {
            _initialize.apply(this, arguments);
            let isNotAdded = true;
            this.get("traits").each((trait) => {
                if (trait.attributes.name === "alignBox") {
                    isNotAdded = false;
                }
            });
            if (isNotAdded) {
                this.get("traits").add({
                    type: "select",
                    label: "Align",
                    name: "alignBox",
                    options: [
                        { name: "Left",value: "left" },
                        { name: "Center",value: "center" },
                        { name: "Right",value: "right" }
                    ]
                });
            }
        };
    }

    editorInitEvents() {
        const {
            onEditorLoad,
            deleteAsset,
        } = this.props;
        /*
         * [Todo - Refactor This]
         */
        const $ = window.jQuery;
        const pn = this.editor.Panels;
        // Load and show settings and style manager
        var openTmBtn = pn.getButton("views", "open-tm");
        openTmBtn && openTmBtn.set("active", 1);
        var openSm = pn.getButton("views", "open-sm");
        openSm && openSm.set("active", 1);
        // Add Settings Sector
        var traitsSector = $("<div class='gjs-sm-sector no-select'>" +
            "<div class='gjs-sm-title'><span class='icon-settings fa fa-cog m-r-10'></span>Settings</div>" +
            "<div class='gjs-sm-properties' id='setting_acordion' style='display: none;'></div></div>");
        var traitsProps = traitsSector.find(".gjs-sm-properties");
        traitsProps.append($(".gjs-trt-traits"));
        $(".gjs-sm-sectors").before(traitsSector);
        traitsSector.find(".gjs-sm-title").on("click", function () {
            var traitStyle = traitsProps.get(0).style;
            //eslint-disable-next-line
            var hidden = traitStyle.display == "none";
            if (hidden) {
                traitStyle.display = "block";
            } else {
                traitStyle.display = "none";
            }
        });
        this.assetEvents = new AssetEvents(this.editor, { deleteAsset });
        onEditorLoad(this.editor);
    }

    attachEventListeners(editor) {
        const {
            events,
        } = this.props;
        editor.on("component:update", (component) => {
            const componentChanged = component.changed;
            if (componentChanged && componentChanged.status && typeof componentChanged.status !== "string") {
                this.isTraitChanged = true;
            }
        });
        if (events) {
            Object.keys(events).forEach((eventName) => {
                editor.on(eventName, (...args) => {
                    events[eventName](editor, args);
                });
            });
        }
    }

    openCloseModal = (ModalComponent) => {
        this.setState({
            ModalComponent,
        });
    }

    navigateBack = () => {
        const {
            previousUrl,
            history,
        } = this.props;
        setTimeout(() => {
            history.push({ pathname: previousUrl });
        }, 100);
    }

    assetReceive(assetManager, updatedAssetList) {
        const editor = this.getEditor();
        const _am = new AssetManagerHelper(editor);
        _am.getAll().then((assets) => {
            const models = [...assets.models];
            if (models.length === 0) {
                assetManager.add(updatedAssetList);
            } else {
                const assetData = updatedAssetList && Array.isArray(updatedAssetList) ? updatedAssetList : null;
                const isNew = assetData && assetData.reduce((acc, val) => {
                    const isImage = models.findIndex(m => {
                        return m.id === val;
                    });
                    if (isImage === -1) {
                        acc.push(val);
                    }
                    return acc; // models.indexOf(m.id) === -1;
                }, []);
                if (isNew && isNew.length) {
                    assetManager.add(isNew);
                }
            }
        });
    }

    updateSavedFonts = () => {
        const { fonts } = this.props;
        const updatedList = fonts && fonts.map(item => {
            return {
                value: `'${item.fontFamily}',sans-serif`,
                name: `${item.fontName}`
            };
        });
        return updatedList;
    }

    addFonts = (editor = this.editor) => {
        let styleManager = editor.StyleManager;
        let typographySector = styleManager.getSector("Font");
        let fontProperty = styleManager.getProperty("Font", "font-family");
        let list = fontProperty.get("list");
        const updatedFontList = this.updateSavedFonts();
        if (updatedFontList) {
            const fontList = [...list, ...updatedFontList];
            fontProperty.set("list", fontList ? fontList : list);
        }
        styleManager.render();
    }

    /*
     * Clean up Functions
     */
    destroyPanelButtons() {
        const {
            panelButtons,
        } = this.props;
        Object.keys(panelButtons).forEach(category => {
            const menuItemsForCategory = panelButtons[category];
            if (isArray(menuItemsForCategory)) {
                menuItemsForCategory.forEach((menuItem) => {
                    if (menuItem && isFunction(menuItem.destroy)) {
                        menuItem.destroy();
                    }
                });
            }
        });
    }

    render() {
        const {
            id = editorId,
        } = this.props;
        const {
            ModalComponent,
            showLoading,
        } = this.state;
        const editor = this.getEditor();
        return (
            <>
                <FullPageLoader showLoading={showLoading} />
                <GrapesJSComponent
                    id={id}
                    ref={this.grapesRef}
                />
                {
                    ModalComponent && (
                        <ModalComponent
                            {... this.props}
                            editor={editor}
                            navigateBack={this.navigateBack}
                            closeModal= {() => this.openCloseModal()}
                        />
                    )
                }
            </>
        );
    }
}

BaseWebBuilder.propTypes = {
    id: PropTypes.string,
    // Preset and plugin options
    webpage: PropTypes.bool,
    plugins: PropTypes.array,
    // Components
    // components: Array<GComponent>,
    components: PropTypes.array,
    blocks: PropTypes.array,
    // Editor configurations
    storageManager: PropTypes.object,
    blockManager: PropTypes.object,
    panelButtons: PropTypes.object,
    additionalConfig: PropTypes.object,
    isLoading: PropTypes.bool,
    pageData: PropTypes.object,
    onEditorLoad: PropTypes.func,

    previousUrl: PropTypes.string,
    history: PropTypes.object,
    commands: PropTypes.object,

    assetDataFromStore: PropTypes.array,
    traits: PropTypes.object,
    pluginsOpts: PropTypes.object,
    events: PropTypes.object,
    basePage: PropTypes.object,
    funnelReferenceId: PropTypes.string,
    pageViewReferenceId: PropTypes.string,
    pageName: PropTypes.string,
    templateTypeId: PropTypes.string,
    savePageView: PropTypes.func,
    fonts: PropTypes.array,
    loadAsset: PropTypes.func,
    deleteAsset: PropTypes.func,
    additionalComponentData: PropTypes.object,
};

BaseWebBuilder.defaultProps = {
    webpage: false,
    plugins: [],
    components: [],
    blocks: [],
    storageManager: {},
    blockManager: {},
    panelButtons: {},
    pluginsOpts: {},
    fonts: [],
};

const mapStateToProps = (state) => {
    return {
        assetDataFromStore: assetSelectors.getAssetData(state),
    };
};


const mapDispatchToProps = (dispatch) => ({
    addAsset: (params) => dispatch(webBuilderAction.addAsset(params)),
    deleteAsset: (params) => dispatch(webBuilderAction.deleteAsset(params)),
    loadAsset: () => dispatch(webBuilderAction.loadAsset()),
});

BaseWebBuilder.defaultProps = {
    onEditorLoad: () => {},
};
export const BaseWebpageCreator = connect(mapStateToProps, mapDispatchToProps)(BaseWebBuilder);

