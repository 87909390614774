import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import * as selectors from "./selectors";
import * as actions from "./actions";

import { Templates as TemplatesView } from "./templates";

function mapStateToProps(state) {
    return {
        templates: selectors.getTemplates(state)
    };
}

function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators(actions, dispatch),
        loadTemplates: () => dispatch(actions.getTemplates()),
    };
}

export const Templates = connect(mapStateToProps, mapDispatchToProps)(TemplatesView);