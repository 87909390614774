import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import { connect } from "react-redux";
import PropTypes from "prop-types";

import "./step.css";
import Campaign from "./Campaign";
import Merchant from "./Merchant";
import Products from "./Products";
import Affiliates from "./Affiliates";
import StepWizard from "./step-wizard";
import EditFunnel from "./EditFunnel";
import { checkWalkthroughModalDisplay, getCRMModalState, getActiveFunnelName, getCRMModalStateInsideVisualizer } from "../core/app-settings/selectors";
import { toggleCRMEntryModal, toggleCRMEntryModalInsideVisualizer } from "../core/app-settings/actions";

const steps = [
    {
        title: "Campaign",
        component: Campaign
    },
    {
        title: "Merchant",
        component: Merchant
    },
    {
        title: "Products",
        component: Products
    },
    {
        title: "Affiliates",
        component: Affiliates
    }
];

function CRMEntry(props) {
    const [isVisible, setVisible] = useState(true);
    const [funnelDetails, setFunnelDetails] = useState(null);
    const offerCount = props.funnelData.page && props.funnelData.page.filter(page => page.title === "Checkout").length;
    const upsellCount = props.funnelData.page && props.funnelData.page.filter(page => page.title === "Upsell").length;
    const setFunnelData = (value) => {
        setFunnelDetails(value);
    };
    const isModalVisible = props.isInsideTheVisualizer ? props.isModalVisibleInsideVisualizer : props.isModalVisible;

    return (
        <>
            {
                isModalVisible &&
                    <Modal
                        show
                        bsSize="lg"
                        className="wizard_modal"
                        aria-labelledby="contained-modal-title-vcenter"
                        // onHide={() => props.toggleCRMEntryModal(false)}
                    >
                        <Modal.Body>
                            <StepWizard {...props} getFunnelDetails={setFunnelData} wholeFunnelDetails={funnelDetails} offerCount={offerCount} upsellCount={upsellCount} steps={steps} closeWizard={() => props.isInsideTheVisualizer ? props.toggleCRMEntryModalInsideVisualizer(false) : props.toggleCRMEntryModal(false)} />
                            <div className="close_button" onClick={() => props.isInsideTheVisualizer ? props.toggleCRMEntryModalInsideVisualizer(false) : props.toggleCRMEntryModal(false)}>
                                <span>&times;</span>
                            </div>
                        </Modal.Body>
                    </Modal>
            }
        </>
    );
}

function mapStateToProps(state) {
    return {
        isModalVisible: getCRMModalState(state),
        isModalVisibleInsideVisualizer: getCRMModalStateInsideVisualizer(state),
        activeFunnelName: getActiveFunnelName(state)
    };
}
function mapDispatchToProps(dispatch) {
    return {
        toggleCRMEntryModal: (status) => dispatch(toggleCRMEntryModal(status)),
        toggleCRMEntryModalInsideVisualizer: (status) => dispatch(toggleCRMEntryModalInsideVisualizer(status))
    };
}

CRMEntry.propTypes = {
    toggleCRMEntryModal: PropTypes.func,
    toggleCRMEntryModalInsideVisualizer: PropTypes.func,
    isModalVisible: PropTypes.bool,
    isModalVisibleInsideVisualizer: PropTypes.bool,
    funnelData: PropTypes.object,
    activeFunnelName: PropTypes.string,
    isInsideTheVisualizer: PropTypes.bool
};

export default connect(mapStateToProps, mapDispatchToProps)(CRMEntry);
