import React from "react";
import PropTypes from "prop-types";
import isEmpty from "lodash/isEmpty";
import {
    Dropdown,
    MenuItem,
} from "react-bootstrap";
import { PageTypes } from "../constants/template-types";

const allTemplates = {
    templateTypeId: 0,
    label: "All",
    name: "",
};

const selectTemplates = {
    templateTypeId: null,
    label: "Select Type",
    name: "",
};

export class TemplateTypeSelectDropdown extends React.Component {
    constructor(props) {
        super(props);
        this.getTemplateFromId = this.getTemplateFromId.bind(this);
        this.onDropdownChange = this.onDropdownChange.bind(this);
        this.state = {
            templateTypeMap: [],
            selectedTemplateType: {},
        };
    }

    async componentDidMount() {
        const {
            templateTypeMap,
        } = this.state;
        const {
            initialTemplateTypeId,
            onDropdownChange,
            hasAllOption,
        } = this.props;
        if (isEmpty(templateTypeMap)) {
            try {
                const templateTypes = this.getTemplatesWithoutIcons(await PageTypes.getAllPageTypes());
                const defaultSelectOption = (hasAllOption) ? allTemplates : selectTemplates;
                const templateTypeMap = [
                    defaultSelectOption,
                    ...templateTypes
                ];
                const selectedTemplateType = this.getTemplateFromId(initialTemplateTypeId, templateTypeMap);
                // eslint-disable-next-line react/no-did-mount-set-state
                this.setState({
                    selectedTemplateType,
                    templateTypeMap,
                });
                onDropdownChange(selectedTemplateType);
            // eslint-disable-next-line no-empty
            } catch (err) {}
        }
    }

    getTemplateFromId(templateTypeId, templateTypeMap) {
        let matchedTemplate = null;
        if (templateTypeId) {
            matchedTemplate = templateTypeMap.find((templateType) => templateType.templateTypeId === templateTypeId);
        }
        if (!matchedTemplate) {
            matchedTemplate = templateTypeMap[0];
        }
        return matchedTemplate;
    }

    getTemplatesWithoutIcons(templateTypes) {
        return templateTypes.filter((template) => template.isEnabled);
    }

    onDropdownChange(eventKey) {
        this.setState({
            selectedTemplateType: eventKey,
        });
        this.props.onDropdownChange(eventKey);
    }

    render() {
        const {
            label,
            className,
        } = this.props;
        const {
            selectedTemplateType,
            templateTypeMap,
        } = this.state;
        return (
            <div className={`m-b-md ${className}`}>
                {
                    label && (
                        <label className="control-label m-r-sm"> { label } </label>
                    )
                }
                <Dropdown id="template-dropdown">
                    <Dropdown.Toggle>
                        {selectedTemplateType.label}
                    </Dropdown.Toggle>
                    <Dropdown.Menu className="template-dropdown-menu" onSelect={this.onDropdownChange}>
                        {
                            templateTypeMap.map((templateType) => (
                                <MenuItem key={templateType.label} eventKey={templateType}> {templateType.label} </MenuItem>
                            ))
                        }
                    </Dropdown.Menu>
                </Dropdown>
            </div>
        );
    }
}

TemplateTypeSelectDropdown.propTypes = {
    onDropdownChange: PropTypes.func,
    label: PropTypes.string,
    initialTemplateTypeId: PropTypes.number,
    className: PropTypes.string,
    hasAllOption: PropTypes.bool,
};