import { createStore, applyMiddleware } from "redux";
import { rootReducer } from "./root-reducer";
import reduxImmutableStateInvariant from "redux-immutable-state-invariant";
import thunk from "redux-thunk";
import { createLogger } from "redux-logger";

const logger = createLogger({ collapsed: true });
let middleware = [thunk, reduxImmutableStateInvariant({
    ignore: [
        "VISUALIZER",
    ]
})];//to do: Ignoring Visualizer since it has complex structure which throws RangeError for tracking Immuation in properties, Needs to be refactored.
if (process.env.NODE_ENV !== "production") {
    middleware = [...middleware, logger];
}

export default function configureStore(initialState) {
    return createStore(
        rootReducer,
        initialState,
        applyMiddleware(...middleware)
    );
}
