import {
    triggerNofiticationSuccess,
} from "../../../commons/notification";

function bootstrapMenuCommand(editor, senderBtn) {
    const domComps = editor.DomComponents;
    let c = {};
    let defaults = {
        addExportBtn: 1,
        btnLabel: "Save",
        preHtml: "<meta charSet='utf-8'>",
        // "                            <script src=\"https://cdnjs.cloudflare.com/ajax/libs/jquery/3.2.1/jquery.min.js\"></script>\n" +
        //     // "<link rel='stylesheet' href='https://cdnjs.cloudflare.com/ajax/libs/twitter-bootstrap/4.1.1/css/bootstrap-reboot.min.css'><" +
        //     // "link rel='stylesheet' href='https://cdnjs.cloudflare.com/ajax/libs/OwlCarousel2/2.3.4/assets/owl.carousel.min.css'>" +
        //     // "<link rel='stylesheet' href='https://cdnjs.cloudflare.com/ajax/libs/twitter-bootstrap/4.1.1/css/bootstrap-grid.min.css'>" +
        //     // "<link rel=\"stylesheet\" href=\"https://maxcdn.bootstrapcdn.com/bootstrap/4.0.0/css/bootstrap.min.css\" integrity=\"sha384-Gn5384xqQ1aoWXA+058RXPxPg6fy4IWvTNh0E263XmFcJlSAwiGgFAW/dAiS6JXm\" crossorigin=\"anonymous\">" +
        //     "<link rel='stylesheet' href='https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.css'/>",
        //     // "<link rel='stylesheet' href='./css/style.css'>",
        postHtml: "",
        // "<script src='https://cdnjs.cloudflare.com/ajax/libs/popper.js/1.14.3/umd/popper.min.js'></script>\n" +
        // "<script src=\"https://maxcdn.bootstrapcdn.com/bootstrap/4.0.0/js/bootstrap.min.js\" integrity=\"sha384-JZR6Spejh4U02d8jOt6vLEHfe/JQGiRRSQQxSfFWpi1MquVdAyjUar5+76PVCmYl\" crossorigin=\"anonymous\"></script>" +
        // "<script src='https://cdnjs.cloudflare.com/ajax/libs/OwlCarousel2/2.3.4/owl.carousel.js'></script>" +
        // "<script src=\"https://cdn.jsdelivr.net/npm/gijgo@1.9.3/combined/js/gijgo.min.js\" type=\"text/javascript\"></script>\n" +
        // "<link href=\"https://cdn.jsdelivr.net/npm/gijgo@1.9.3/combined/css/gijgo.min.css\" rel=\"stylesheet\" type=\"text/css\" />",
        // preCss: "",
        // postCss: ""
    };
    for (let name in defaults) {
        if (!(name in c))
            c[name] = defaults[name];
    }
    const canvas = editor.Canvas.getConfig().styles;
    if (senderBtn.attributes.attributes["data-active"] === false) {
        senderBtn.set("active", true);
        senderBtn.attributes.attributes["data-active"] = true;
        let arr = defaults.preHtml;
        arr = arr.split("<");
        arr.forEach((value, index) => {
            if (value === "link rel='stylesheet' href='https://maxcdn.bootstrapcdn.com/bootstrap/4.0.0/css/bootstrap.min.css'/>" || value === "<link rel='stylesheet' href='https://maxcdn.bootstrapcdn.com/bootstrap/4.0.0/css/bootstrap.min.css'/>") {
                arr.splice(index, 1);
            }
        });

        arr = arr.join("");
        defaults.preHtml = "<" + arr;

        const linkTags = domComps.getWrapper().find("link").forEach(comp => {
            if (comp.getAttributes()["href"] === "https://maxcdn.bootstrapcdn.com/bootstrap/4.0.0/css/bootstrap.min.css" || comp.getAttributes()["href"] === "https://stackpath.bootstrapcdn.com/bootstrap/4.1.3/css/bootstrap.min.css") {
                comp.remove();
            }
        });


        const bootstrap = canvas.findIndex((element) => {
            return element === "https://maxcdn.bootstrapcdn.com/bootstrap/4.0.0/css/bootstrap.min.css";
        });
        canvas.splice(bootstrap, 1);
        senderBtn.set("active", false);
    } else {
        senderBtn.attributes.attributes["data-active"] = false;
        editor.getComponents().add("<link rel='stylesheet' href='https://maxcdn.bootstrapcdn.com/bootstrap/4.0.0/css/bootstrap.min.css'/>");
        defaults.preHtml = defaults.preHtml + "<link rel='stylesheet' href='https://maxcdn.bootstrapcdn.com/bootstrap/4.0.0/css/bootstrap.min.css'/>";
        triggerNofiticationSuccess({ message: "Bootstrap is Active" });
    }
}

export default bootstrapMenuCommand;