import React from "react";
import PropTypes from "prop-types";

export const TemplateActionCard = (props) => {
    const {
        onSelect,
        icon,
        text,
    } = props;

    const handleClick = (e) => {
        e.preventDefault();
        onSelect();
    };

    return (
        <div className="display-flex page-template-card-container">
            <div className="ibox page-template-card">
                <div
                    className="ibox-content page-template-card-content centerItem"
                    onClick={handleClick}
                >
                    <a
                        href="#"
                        className="text-center"
                    >
                        <span className="show"> { icon } </span>
                        {
                            text
                        }
                    </a>
                </div>
            </div>
        </div>
    );
};

TemplateActionCard.propTypes = {
    onSelect: PropTypes.func,
    text: PropTypes.string,
    icon: PropTypes.element,
};

TemplateActionCard.defaultProps = {
    icon: <i className="fa fa-plus-circle" aria-hidden="true" />,
    onSelect: () => {},
};