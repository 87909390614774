import React from "react";
import PropTypes from "prop-types";

export const SplitPageCardView = ({
    thumbnailUrl,
    alphabet,
    clickSplitSettings,
    onDeclareWinner
}) => {
    return (
        <div className= {"col-md-6 col-xs-12" }>
            <h2 className="text-center font-bold">{alphabet}</h2>
            <div className="ibox">
                <div className="ibox-content template-content template-list">
                    <div className="template-img"
                        style={{ backgroundImage: `url(${thumbnailUrl})` }}
                    >
                        <a
                            className="preview-template"
                            onClick= {() => clickSplitSettings(alphabet)}
                        >
                            <i
                                className="fa fa-cog">
                            </i>
                        </a>
                        <a
                            className="choose"
                            onClick= {
                                () => onDeclareWinner(alphabet)
                            }
                        >
                            <div
                                className="choose-hand split-winner">
                            </div>
                            <div>
                                Declare Winner
                            </div>
                        </a>
                    </div>
                </div>
            </div>
        </div>
    );
};
SplitPageCardView.propTypes = {
    thumbnailUrl: PropTypes.string,
    page: PropTypes.object,
    alphabet: PropTypes.string,
    // splitTemplates: PropTypes.object,
    // thumbnailUrl: PropTypes.object,
    onDeclareWinner: PropTypes.func,
    clickSplitSettings: PropTypes.func,
    //showSplitSetting: PropTypes.bool,
    // oldfunnel: PropTypes.string,
    // pageIndex: PropTypes.string,
};