import joint from "jointjs";
import constants from "../utilities/constants";

const Elements = (imageSvg, posX, posY, label, templateTypeId, isIcon, parent) => {
    let markupValue = "";
    if (parent === "funnel") {
        markupValue = `${constants.funnelIconsMarkUp}`;
    } else if (parent === "funnelTemplate") {
        markupValue = `${constants.funnelTemplateIconsMarkUp}`;
    }
    joint.shapes.devs.MyImageModel = joint.shapes.devs.Model.extend({
        markup: markupValue,
        portMarkup: "<g><rect class=\"port-body\"></rect><text x=\"-10\" y=\"-100\" class=\"linkicon\">" + `${constants.link}` + "<title>Move</title></text></g>",
        defaults: joint.util.deepSupplement({
            type: "devs.MyImageModel",
            position: {
                x: posX,
                y: posY
            },
            attrs: {
                ".settings": {
                    event: "element:settings"
                },
                ".edit": {
                    event: "element:edit"
                },
                ".trash": {
                    event: "element:delete"
                },
                ".preview": {
                    event: "element:preview"
                },
                ".copy": {
                    event: "element:copy"
                },
                ".split": {
                    event: "element:split"
                },
            },
            outPorts: [""]
        }, joint.shapes.devs.Model.prototype.defaults)
    });
    // Usage:
    var generalModel = new joint.shapes.devs.MyImageModel({
        size: {
            width: 60,
            height: 70,
        },
        attrs: {
            image: {
                width: isIcon ? 60 : 60,
                height: isIcon ? 60 : 70,
                "xlink:href": "data:image/svg+xml;utf8," + encodeURIComponent(imageSvg),
                preserveAspectRatio: "none"
            },
            ".label": {
                text: label,
                y: -30,
                x: 0
            }
        },
        position: {
            x: posX,
            y: posY
        },
        elementMetaData: {
            templateTypeId
        },
        pageLabel: {
            label
        },
        ports: {
            groups: {
                "out": {
                    attrs: {
                        ".port-body": { fill: "#ffffff", width: 120, height: 160, stroke: "#ffffff00", "stroke-width": 1, x: -120, y: -80, magnet: true },
                        ".linkicon": { x: -10, y: -100 },
                    }
                },
                ".icons": {
                    fill: "#1ab394",
                    magnet: true

                }
            }
        },
        outPorts: [""],
        icon: isIcon ? isIcon : false
    });
    return (
        generalModel
    );
};
export default Elements;