import React from "react";
import PropTypes from "prop-types";
import { Modal } from "react-bootstrap";

const WarningModal = ({ showWarningModal, editDomainStateMessage, closeDeleteDomainWarningModal, domainStatus,
    warningTitle, domainStillActiveMessage, howToChangeDomainStateMessage, domainRecentlyUpdatedMessage,
    setCnameRecordsMessage, okLabel }) => {
    return (
        <Modal show={showWarningModal || editDomainStateMessage === false} backdrop = "static" onHide={closeDeleteDomainWarningModal} bsSize="small" aria-labelledby="contained-modal-title-lg">
            <Modal.Header >
                <Modal.Title><h3 className="text-white">{warningTitle}</h3></Modal.Title>
            </Modal.Header>
            <Modal.Body>
                { domainStatus !== "InProgress" && showWarningModal &&
                    <div>
                        <h5 className="text-center">{domainStillActiveMessage}</h5>
                        <h5 className="text-center">{howToChangeDomainStateMessage}<b>disabled</b></h5>
                    </div>
                }
                { domainStatus === "InProgress" && showWarningModal &&
                    <div>
                        <h5 className="text-center">{domainRecentlyUpdatedMessage}</h5>
                    </div>
                }
                { editDomainStateMessage === false &&
                    <div>
                        <h5 className="text-center">{setCnameRecordsMessage}</h5>
                    </div>

                }
            </Modal.Body>
            <Modal.Footer>
                <a className="btn btn-primary" onClick = {closeDeleteDomainWarningModal}>{okLabel}</a>
            </Modal.Footer>
        </Modal>
    );
};

WarningModal.propTypes = {
    showWarningModal: PropTypes.bool,
    editDomainStateMessage: PropTypes.bool,
    domainStatus: PropTypes.string,
    closeDeleteDomainWarningModal: PropTypes.func,
    warningTitle: PropTypes.string,
    domainStillActiveMessage: PropTypes.string,
    howToChangeDomainStateMessage: PropTypes.string,
    domainRecentlyUpdatedMessage: PropTypes.string,
    setCnameRecordsMessage: PropTypes.string,
    okLabel: PropTypes.string
};

export default WarningModal;