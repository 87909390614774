import {
    textSize
} from "./default-element-properties";
import {
    getTextAttributes
} from "./json-builder-helper";

export const generateTextModel = (cell, id) => {
    return {
        angle: 0,
        attrs: getTextAttributes(cell.text),
        id: id,
        position: cell.position,
        size: textSize,
        type: cell.type,
        z: cell.z,
        input: cell.text,
    };
};