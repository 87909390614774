const handleModalWidthChange = (editor, component) => {
    var ele = editor.DomComponents.getWrapper();
    if (ele.view.$el.find("#customizable_popup").length > 0) {
        if (component.changed.modal_width === "small") {
            editor.getSelected().addClass("widthSmall");
            editor.getSelected().removeClass("widthMedium");
            editor.getSelected().removeClass("widthLarge");
        } else if (component.changed.modal_width === "medium") {
            editor.getSelected().removeClass("widthSmall");
            editor.getSelected().addClass("widthMedium");
            editor.getSelected().removeClass("widthLarge");
        } else if (component.changed.modal_width === "large") {
            editor.getSelected().removeClass("widthSmall");
            editor.getSelected().removeClass("widthMedium");
            editor.getSelected().addClass("widthLarge");

        } else if (component.changed.modal_width === "default") {
            editor.getSelected().removeClass("widthSmall");
            editor.getSelected().removeClass("widthMedium");
            editor.getSelected().removeClass("widthLarge");
        }
    }
};

export default handleModalWidthChange;