export const linkTraits = [
    {
        label: "Title",
        name: "title",
        placeholder: "eg: Text Here",
        changeProp: 1
    },
    {
        label: "Href",
        name: "href",
        placeholder: "eg: https://google.com",
        changeProp: 1
    },
    {
        type: "select",
        label: "Action",
        name: "action",
        changeProp: 1,
        options: [
            { value: "", name: "Choose Value" },
            { value: "none", name: "Default" },
            { value: "route", name: "Route to Next Page" },
            { value: "logout", name: "Logout" },
            { value: "cancel", name: "Cancel" },
            { value: "submit", name: "Submit + Route" },
        ]
    },
    {
        type: "select",
        label: "Target",
        name: "target",
        changeProp: 1,
        options: [
            { value: "", name: "This Window" },
            { value: "_blank", name: "New Window" },
        ],
    },
    {
        type: "select",
        label: "Animate",
        name: "animate",
        changeProp: 1,
        options: [
            { value: "none", name: "None" },
            { value: "bounce", name: "bounce" },
            { value: "flash", name: "flash" },
            { value: "pulse", name: "pulse" },
            { value: "rubberband", name: "rubberband" },
            { value: "shake", name: "shake" },
            { value: "swing", name: "swing" },
            { value: "tada", name: "tada" },
            { value: "wobble", name: "wobble" },
            { value: "jello", name: "jello" },
            { value: "bounceIn", name: "bounceIn" },
            { value: "bounceInLeft", name: "bounceInLeft" },
            { value: "bounceInRight", name: "bounceInRight" },
            { value: "bounceInUp", name: "bounceInUp" },
            { value: "bounceOut", name: "bounceOut" },
            { value: "bounceOutDown", name: "bounceOutDown" },
            { value: "bounceOutLeft", name: "bounceOutLeft" },
            { value: "bounceOutRight", name: "bounceOutRight" },
            { value: "bounceOutUp", name: "bounceOutUp " },
            { value: "fadeIn", name: "fadeIn" },
            { value: "fadeInDown", name: "fadeInDown" },
            { value: "fadeInDownBig", name: "fadeInDownBig" },
            { value: "fadeInLeft", name: "fadeInLeft" },
            { value: "fadeInLeftBig", name: "fadeInLeftBig" },
            { value: "fadeInRight", name: "fadeInRight" },
            { value: "fadeInRightBig", name: "fadeInRightBig" },
            { value: "fadeInUp", name: "fadeInUp" },
            { value: "fadeInUpBig", name: "fadeInUpBig" },
            { value: "fadeOut", name: "fadeOut" },
            { value: "fadeOutDown", name: "fadeOutDown" },
            { value: "fadeOutDownBig", name: "fadeOutDownBig" },
            { value: "fadeOutLeft", name: "fadeOutLeft" },
            { value: "fadeOutLeftBig", name: "fadeOutLeftBig" },
            { value: "fadeOutRight", name: "fadeOutRight" },
            { value: "fadeOutRightBig", name: "fadeOutRightBig" },
            { value: "fadeOutUp", name: "fadeOutUp" },
            { value: "flipInX", name: "flipInX" },
            { value: "flipInY", name: "flipInY" },
            { value: "lightSpeedIn", name: "lightSpeedIn" },
            { value: "lightSpeedOut", name: "lightSpeedOut" },
            { value: "rotateIn", name: "rotateIn" },
            { value: "rotateOut", name: "rotateOut" },
            { value: "rollIn", name: "rollIn" },
            { value: "rollOut", name: "rollOut" },
            { value: "zoomIn", name: "zoomIn" },
            { value: "zoomOut", name: "zoomOut" },
            { value: "slideInDown", name: "slideInDown" },
            { value: "zoomOut", name: "zoomOut" },
        ],

    },{
        type: "select",
        label: "Delay",
        name: "delay",
        changeProp: 1,
        options: [
            { value: "none", name: "None" },
            { value: "delay-2s", name: "2 Second" },
            { value: "delay-3s", name: "3 Second" },
            { value: "delay-4s", name: "4 Second" },
            { value: "delay-5s", name: "5 Second" },
        ],
    },{
        type: "select",
        label: "Speed",
        name: "speed",
        changeProp: 1,
        options: [
            { value: "none", name: "None" },
            { value: "slower", name: "slower" },
            { value: "slow", name: "slow" },
            { value: "fast", name: "fast" },
            { value: "faster", name: "faster" },
        ],
    },{
        type: "select",
        label: "Loop",
        name: "loop",
        changeProp: 1,
        options: [
            { value: "none", name: "No Loop" },
            { value: "infinite", name: "Infinite" },
        ],
    }, {
        type: "select",
        label: "OFFER",
        name: "OFFER",
        changeProp: 1,
        visibleOn: ["Checkout Page"],
    }, {
        type: "select",
        label: "UPSELL",
        name: "UPSELL",
        changeProp: 1,
        visibleOn: ["Upsell Page"],
    }
];