import * as type from "../actions/type";
import { FunnelTemplateReducer } from "../../models";

export const FUNNEL_TEMPLATE_REDUCER_KEY = "FUNNEL_TEMPLATE";

const initialState = new FunnelTemplateReducer();

export function reducer(state = initialState, action) {
    switch (action.type) {
        case type.IS_LOADING:
            return {
                ...state,
                isLoading: action.status
            };
        case type.IS_FONT_LOADING:
            return {
                ...state,
                isFontLoading: action.status
            };
        case type.SAVE_PAGE_VIEW_SUCCESS:
            return {
                ...state,
                isSaveLoading: action.status
            };
        case type.GET_FUNNEL_SUCCESS: {
            return {
                ...state,
                funnelData: action.payload.funnelData,
                funnel: action.payload.funnelData,
                importedFunnel: null
            };
        }
        case type.UPDATE_FUNNEL_SUCCESS: {
            return {
                ...state,
                funnelData: (state.funnelData.referenceId === action.funnel.referenceId) ? action.funnel : {}
            };
        }
        case type.UPDATE_PAGE_SUCCESS: {
            return {
                ...state,
                funnelData: (state.funnelData.referenceId === action.funnel.referenceId) ? action.funnel : {}
            };
        }
        case type.LOAD_GOOGLE_FONT_SUCCESS: {
            return {
                ...state,
                googleFonts: action.payload ? action.payload : ""
            };
        }
        case type.LOAD_METAJSON_SUCCESS: {
            return {
                ...state,
                metaUrl: action.payload ? action.payload : ""
            };
        }
        case type.LOAD_BUTTONS_SUCCESS: {
            return {
                ...state,
                pageData: {
                    ...state.pageData,
                    buttonData: action.payload.buttons,
                    linkData: action.payload.links,
                    jsPageData: action.payload.jsPageData ? action.payload.jsPageData : []
                }
            };
        }
        case type.CLEAR_PAGE_DATA: {
            return {
                ...state,
                pageData: {}
            };
        }
        case type.CLEAR_FUNNEL_SUCCESS: {
            return {
                ...state,
                metaUrl: null
            };
        }
        case type.CLEAR_JSON_DATA: {
            return {
                ...state,
                jsonData: ""
            };
        }
        case type.LOAD_FUNNEL_SUCCESS: {
            return {
                ...state,
                funnelData: action.payload ? action.payload : "",
            };
        }
        case type.SET_CURRENT_PAGE_ID: {
            const { currentPageId } = action;
            const newFunnelData = new FunnelTemplateReducer([...state.pages], currentPageId, state.funnelData);
            return newFunnelData;
        }
        case type.EDIT_FUNNEL_SUCCESS: {
            return {
                ...state,
                funnelData: action.funnel
            };
        }
        case type.IMPORT_PAGE_SUCCESS: {
            return {
                ...state,
                importedPage: action.payload
            };
        }
        case type.GET_META_URL_SUCCESS: {
            return {
                ...state,
                metaUrl: action.payload
            };
        }
        case type.EDIT_FUNNEL_ERROR: {
            return {
                ...state,
                isError: action.isError
            };
        }
        case type.CREATE_PAGE_ERROR: {
            return {
                ...state,
                pageError: action.pageError
            };
        }
        case type.IMPORT_PAGE_ERROR: {
            return {
                ...state,
                importError: action.importError
            };
        }
        case type.GET_TEMPLATE_SUCCESS: {
            return {
                ...state,
                htmlPage: action.htmlPage
            };
        }
        case type.LOAD_PAGE_VIEW_SUCCESS: {
            return {
                ...state,
                pageData: action.pageData,
            };
        }
        case type.GET_JSON_SUCCESS: {
            return {
                ...state,
                jsonData: action.payload
            };
        }
        case type.GET_PAGE_SUCCESS: {
            return {
                ...state,
                pages: action.payload.pages
            };
        }
        case type.GET_FONTS_SUCCESS: {
            return {
                ...state,
                fonts: action.payload
            };
        }
        case type.IMPORT_FUNNEL_SUCCESS: {
            return {
                ...state,
                importedFunnel: action.payload
            };
        }
        case type.IMPORT_FUNNEL_ERROR: {
            return {
                ...state,
                importFunnelError: action.importFunnelError
            };
        }
        case type.IS_IMPORT_FUNNEL_LOADING: {
            return {
                ...state,
                isImportFunnelLoading: action.status
            };
        }

        default:
            return state;
    }
}