import { getEditorContainer } from "../utils/component";
import { saveNewTemplate, saveExistingTemplate } from "../../templates/actions";
import { getEditorHtml } from "../utils/editor";
import {
    triggerNofiticationSuccess,
    triggerNofiticationError,
} from "../../commons/notification";

const savePageTemplate = (editor, options) => {
    const container = getEditorContainer(editor);
    const containerProps = container && container.props;
    const templateData = (containerProps && containerProps.templateData) || {};
    let saveApiPromise;
    const { templateTypeId, title, referenceId } = templateData;
    if (templateData && templateData.referenceId) {
        saveApiPromise = saveExistingTemplate({
            templateTypeId,
            title,
            referenceId,
            ...getEditorHtml(editor),
        });
    } else {
        saveApiPromise = saveNewTemplate({
            templateTypeId,
            templateFile: templateData.templateFile,
            title,
            ...getEditorHtml(editor),
        }).then(({ result }) => {
            if (result && result.templateTypeId && containerProps.setTemplateData) {
                containerProps.setTemplateData(result);
            }
        });
    }
    if (saveApiPromise) {
        container.toggleLoading(true);
        saveApiPromise.then(() => {
            triggerNofiticationSuccess({ message: "Page saved successfully" });
            editor.UndoManager.clear();
            container.toggleLoading(false);
            if (options.navigateBack) {
                container.navigateBack();
            }
        }).catch((err = {}) => {
            triggerNofiticationError({ message: err.message });
            container.toggleLoading(false);
        });
    }
};

export default {
    "save-database": savePageTemplate,
};