import * as type from "./type";
import { Visualizer } from "../../models/visualiser";

export function updateVisualizerData(payload) {
    return { type: type.UPDATE_VISUALIZER_DATA, payload: { ...payload } };
}
export function initializeVisualizerData() {
    const visulaizer = new Visualizer();
    return { type: type.INITIALIZE_VISUALIZER_DATA, visulaizer };
}


export function removeCallBackEvent() {
    return { type: type.REMOVE_CALLBACK_EVENT, payload: { callbackEvent: "" } };
}



export function setCellData(data) {
    return {
        type: type.UPDATE_VISUALIZER_DATA,
        payload: {
            ...data,
            isRightPanelActive: false,
            isArrowSelected: false,
            targetViewId: null,
            sourceViewId: null,
            linkViewId: null,
            sourcePageLabel: null,
            linkButtonData: null,
            linkData: null,
            linkOfferData: null,
            linkUpsellData: null,
            linkCurrentPage: null,
            importShareId: null,
            isShareOpen: false,
            selectedFonts: [],
            callbackEvent: ""
        }
    };
}

export function closeRightPanel(data = {}) {

    return {
        type: type.UPDATE_VISUALIZER_DATA,
        payload: {
            isRightPanelActive: false,
            isArrowSelected: false,
            targetViewId: null,
            sourceViewId: null,
            linkViewId: null,
            sourcePageLabel: null,
            linkButtonData: null,
            linkData: null,
            linkOfferData: null,
            linkUpsellData: null,
            linkCurrentPage: null,
            importShareId: null,
            isShareOpen: false,
            selectedFonts: [],
            callbackEvent: "closeRightPanel",
            ...data
        }
    };
}

