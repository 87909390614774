import { UserData, UserSetting } from "./models";
import * as actions from "./actions";
import { getCompanyIdAccessForTemplates, getCompanyIdAccessForProdTemplates } from "../api/urls";
export const LOGIN_REDUCER_KEY = "LOGIN";

export function reducer(state = { userData: new UserData() }, action) {
    switch (action.type) {
        case actions.LOGIN_SUCCESS: {
            return {
                ...state,
                isAuthentcated: true,
                isLoginError: false
            };
        }
        case actions.LOGIN_ERROR:
            return {
                ...state,
                isLoginError: true,
                isAuthentcated: true
            };
        case actions.IS_LOADING: {
            return {
                ...state,
                isLoading: action.status
            };
        }
        case actions.LOGOUT: {
            return {
                userData: new UserData()
            };
        }
        case actions.ACCOUNTLOAD: {
            return {
                ...state,
                userData: new UserData(action.payload.userCrm, action.payload.active, action.payload.userSetting, action.payload.isFirstTime, action.payload.affiliateId, action.payload.isDesignerUser),
                userSetting: new UserSetting (action.payload.userSetting)
            };
        }
        case actions.ACCOUNTERROR: {
            return {
                ...state,
                isAccountError: action.isAccountError
            };
        }
        case actions.SETTINGSLOAD: {
            return {
                ...state,
                settingsData: new UserSetting(action.payload),
            };
        }
        case actions.MAINTENANCE: {
            return {
                ...state,
                maintenanceData: action.payload
            };
        }
        case actions.FUNNELPAGECOUNTLOAD: {
            return {
                ...state,
                funnelPageCount: action.payload
            };
        }
        case actions.LOAD_DOMAINS_SUCCESS: {
            return {
                ...state,
                domains: action.domains
            };
        }
        case actions.GET_DOMAIN_SUCCESS: {
            return {
                ...state,
                domain: action.payload
            };
        }
        case actions.UPDATE_DOMAIN_SUCCESS: {
            return {
                ...state,
                response: action.response === "Success" ? true : false
            };
        }
        case actions.CLOSE_WARNING_MODAL_SUCCESS: {
            return {
                ...state,
                response: true
            };
        }
        case actions.CREATE_DOMAIN_ERROR: {
            return {
                ...state,
                isCreateDomainError: action.isError
            };
        }
        case actions.CLOSE_CREATE_DOMAIN_ERROR_MODAL: {
            return {
                ...state,
                isCreateDomainError: false
            };
        }
        case actions.IS_LOADING_DOMAIN: {
            return {
                ...state,
                isLoadingDomain: action.status
            };
        }
        case actions.LOAD_COMPANY_SUCCESS: {
            /*
             * This LOAD_COMPANY_SUCCESS will be triggered for only Konnective Users
             * Based on this, we add an flag to determine the user as Admin
             * so that they have access to Page Templates.
             */
            return {
                ...state,
                companyDetails: action.payload,
                isAdminUser: true,
            };
        }
        case actions.LOAD_COMPANY_ERROR: {
            return {
                ...state,
                companyDetailsError: action.loadCompanyError,
                isAdminUser: false,
            };
        }
        /*case actions.SET_COMPANY_SUCCESS: {
            return {
                ...state,
                companyDetails: action.payload
            };
        }*/
        case actions.SET_COMPANY_ERROR: {
            return {
                ...state,
                setCompanyDetailsError: action.setCompanyError
            };
        }
        case actions.CHECK_COMPANY_ID: {
            return {
                ...state,
                canUserAccessTemplates: (action.companyId === getCompanyIdAccessForTemplates || action.isDesignerUser || action.companyId === getCompanyIdAccessForProdTemplates)
            };
        }
        case actions.GET_LATEST_ADDED_DOMAIN: {
            return {
                ...state,
                getLatestAddedDomain: action.savedDomain
            };
        }
        default:
            return state;
    }
}
