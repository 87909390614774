import moment from "moment";
import { APIHelper, LocalStorageHelper } from "../utilities";
import { rootUrl, googleFontsUrl, importPageUrl, saveFontsUrl, domainFunnelUrl, importFunnelUrl, funnelTemplateRootUrl, funnelThumbnailUrl } from "./urls";

const FunnelTemplate = "FunnelTemplate";
export function getAllFunnels({ headers = {} }) {
    return APIHelper.baseRequest(APIHelper.GET, `${rootUrl}/Funnel`, { headers });
}

export function getPurchasedFunnels({ headers = {} }) {
    return APIHelper.baseRequest(APIHelper.GET, `${rootUrl}/Funnel/GetPurchasedFunnels`, { headers });
}

export function getPremiumFunnelTemplates() {
    return APIHelper.baseRequest(APIHelper.GET, `${funnelTemplateRootUrl}/FunnelTemplate/GetPremiumFunnelTemplates`, { contentType: APIHelper.getMultipartReqHeader(), headers: APIHelper.getGenericHeaders() });
}

export function deleteFunnel(funnelId, { headers = {} }) {
    return APIHelper.baseRequest(APIHelper.DELETE, `${rootUrl}/Funnel/${funnelId}`, { headers });
}

export function deletePageView(pageViewId, { headers = {} }) {
    return APIHelper.baseRequest(APIHelper.DELETE, `${rootUrl}/PageView/${pageViewId}`, { headers });
}

export function retrieveFunnel(funnelReferenceId, { headers = {} }) {
    return APIHelper.baseRequest(APIHelper.GET, `${rootUrl}/Funnel/${funnelReferenceId}`, { headers });
}
export function publishFunnel(funnelReferenceId, { headers = {} }) {
    return APIHelper.baseRequest(APIHelper.GET, `${rootUrl}/Funnel/Publish/${funnelReferenceId}`, { headers });
}
export function loadGoogleFonts({ headers = {} }) {
    return APIHelper.baseRequest(APIHelper.GET, `${googleFontsUrl}`, { headers });
}
export function getFunnelWebBuilderElements({ funnelReferenceId, pageViewReferenceId }, { headers = {} }) {
    return APIHelper.baseRequest(APIHelper.GET, `${rootUrl}/WebBuilderComponent/GetWebBuilderComponent/Funnel/${funnelReferenceId}/PageView/${pageViewReferenceId}`, { headers });
}

export function createPage(fFunnelId, title, { headers = {} }) {
    return APIHelper.baseRequest(APIHelper.POST, `${rootUrl}/Page`, {
        headers,
        requestBody: {
            title,
            FFunnel: {
                referenceId: fFunnelId
            }
        }
    });
}

export function getPages({ headers = {} }) {
    return APIHelper.baseRequest(APIHelper.GET, `${rootUrl}/Page`, {
        headers
    });
}

export function clonePage(params, { headers = {} }) {
    return APIHelper.baseRequest(APIHelper.GET, `${rootUrl}/Page/ClonePage/Funnel/${params.funnelReferenceId}/Page/${params.pageReferenceId}/CellViewId/${params.clonedPageCellviewId}`, {
        headers
    });
}
export function saveFonts(params, { headers = {}, requestBody = {} }) {
    const fontData = params.fontUrlArray.map(item => {
        return ({ "fontName": item.font.family, "fontFamily": item.font.family,"fontUrl": item.fontUrl });
    });
    return APIHelper.baseRequest(APIHelper.POST, `${saveFontsUrl}/SaveFonts/${params.funnelReferenceId}/${params.pageReferenceId}`, {
        headers,
        requestBody: fontData
    });
}
export function getFonts(params, { headers = {} }) {
    return APIHelper.baseRequest(APIHelper.GET, `${saveFontsUrl}/GetFonts/${params.funnelReferenceId}/${params.pageReferenceId}`, {
        headers
    });
}

export function createFunnel({ headers = {}, requestBody = {} }) {
    let offer = requestBody.offer.filter((item, index) => requestBody.offer.indexOf(item) === index);
    let upsell = requestBody.upsale.filter((item, index) => requestBody.upsale.indexOf(item) === index);

    const localStorageHelper = new LocalStorageHelper();
    const tenantId = localStorageHelper.load(LocalStorageHelper.keys.TENANT_ID);
    return APIHelper.baseRequest(APIHelper.POST, `${rootUrl}/Funnel/DefaultFunnel`, {
        headers,
        requestBody: {
            "FTenantId": tenantId,
            "Title": requestBody.title || "",
            "FListFunnelTypeId": 1,
            "Offer": JSON.stringify(offer),
            "Upsale": JSON.stringify(upsell),
            "Campaign": requestBody.campaign || "",
            "Status": false,
            "Created On": requestBody.createdOn,
            "Updated On": requestBody.updatedOn,
        }
    });
}


export function editFunnel(params, { headers = {}, requestBody = {} }) {
    const zoomValue = { zoomValue: `${params.zoomValue ? params.zoomValue : 1 }`, layoutZoom: `${params.layoutZoom ? params.layoutZoom : 25.5 }`, graphZoom: `${params.graphZoom ? params.graphZoom : 25 }` };
    const localStorageHelper = new LocalStorageHelper();
    const tenantId = localStorageHelper.load(LocalStorageHelper.keys.TENANT_ID);
    return APIHelper.baseRequest(APIHelper.PUT, `${rootUrl}/Funnel/Visualizer/${params.funnel.referenceId}`, {
        headers,
        requestBody: {
            "FTenantId": tenantId,
            "Title": requestBody.title || "",
            "Offer": Array.isArray(requestBody.offer) ? JSON.stringify(requestBody.offer) : requestBody.offer,
            "Upsale": Array.isArray(requestBody.upsale) ? JSON.stringify(requestBody.upsale) : requestBody.upsale,
            "Campaign": requestBody.campaign || "",
            "Status": requestBody.status || "",
            "Updated On": requestBody.updatedOn,
            "DefaultPageViewReferenceId": requestBody.defaultPageViewReferenceId || "",
            "page": [
                {
                    "title": `${params.cellViewDetail.cellViewDetail.label}`,
                    "cellViewId": `${params.cellViewDetail.cellViewDetail.cellViewId}`,
                    "pageView": [
                        {
                            "referenceId": "",
                            "title": `${params.cellViewDetail.cellViewDetail.label}_PageView`,
                        }
                    ]
                }
            ],
            "visualizerJson": params.funnel.visualizerJson ? `${params.funnel.visualizerJson}` : JSON.stringify(zoomValue)
        }
    });
}
export function updatePage(params, { headers = {}, requestBody = {} }) {
    return APIHelper.baseRequest(APIHelper.PUT, `${rootUrl}/Funnel/Visualizer/${params.funnel.referenceId}`, {
        headers,
        requestBody: {
            "Title": requestBody.title || "",
            "Offer": Array.isArray(requestBody.offer) ? JSON.stringify(requestBody.offer) : requestBody.offer,
            "Upsale": Array.isArray(requestBody.upsale) ? JSON.stringify(requestBody.upsale) : requestBody.upsale,
            "Campaign": requestBody.campaign || "",
            "Status": requestBody.status || "",
            "Updated On": requestBody.updatedOn,
            "page": [
                {
                    "referenceId": `${params.currentPage.referenceId}`,
                    "cellViewId": `${params.currentPage.cellViewId}`,
                    "title": `${params.pageTitle}`,
                    "modified": true,
                    "splitRatio": `${params.currentPage.splitRatio}`,
                }
            ],
            "visualizerJson": `${params.funnel.visualizerJson}`
        }
    });
}
export function splitDuplicatePage(params, { headers = {}, requestBody = {} }) {
    const zoomValue = { zoomValue: `${params.zoomValue ? params.zoomValue : 1 }`, layoutZoom: `${params.layoutZoom ? params.layoutZoom : 25.5 }`, graphZoom: `${params.graphZoom ? params.graphZoom : 25 }` };
    return APIHelper.baseRequest(APIHelper.PUT, `${rootUrl}/Funnel/Visualizer/${params.funnel.referenceId}`, {
        headers,
        requestBody: {
            "Title": requestBody.title || "",
            "Offer": Array.isArray(requestBody.offer) ? JSON.stringify(requestBody.offer) : requestBody.offer,
            "Upsale": Array.isArray(requestBody.upsale) ? JSON.stringify(requestBody.upsale) : requestBody.upsale,
            "Campaign": requestBody.campaign || "",
            "Status": requestBody.status || "",
            "DefaultPageViewReferenceId": "",
            "page": [
                {
                    "referenceId": `${params.currentPage.referenceId}`,
                    "cellViewId": `${params.currentPage.cellViewId}`,
                    "SplitEnabled": true,
                    "SplitNewTemplate": false,
                    "title": `${params.currentPage.title}`,
                    "pageView": [
                        {
                            "referenceId": `${params.currentPage.pageView[0].referenceId}`,
                            "title": `${params.currentPage.title}_PageView`
                        }
                    ]
                }
            ],
            "visualizerJson": params.funnel.visualizerJson ? `${params.funnel.visualizerJson}` : JSON.stringify(zoomValue)
        }
    });
}
export function declareWinner(params, { headers = {}, requestBody = {} }) {
    const zoomValue = { zoomValue: `${params.zoomValue ? params.zoomValue : 1 }`, layoutZoom: `${params.layoutZoom ? params.layoutZoom : 25.5 }`, graphZoom: `${params.graphZoom ? params.graphZoom : 25 }` };
    return APIHelper.baseRequest(APIHelper.PUT, `${rootUrl}/Funnel/Visualizer/${params.funnel.referenceId}`, {
        headers,
        requestBody: {
            "Title": requestBody.title || "",
            "Offer": Array.isArray(requestBody.offer) ? JSON.stringify(requestBody.offer) : requestBody.offer,
            "Upsale": Array.isArray(requestBody.upsale) ? JSON.stringify(requestBody.upsale) : requestBody.upsale,
            "Campaign": requestBody.campaign || "",
            "Status": requestBody.status || "",
            "DefaultPageViewReferenceId": "",
            "page": [
                {
                    "referenceId": `${params.currentPage.referenceId}`,
                    "cellViewId": `${params.currentPage.cellViewId}`,
                    "splitWinner": true,
                    "title": `${params.currentPage.title}`,
                    "thumbnailUrl": `${params.currentPage.thumbnailUrl}`,
                    "pageView": [
                        {
                            "referenceId": `${params.currentPage.pageView[0].referenceId}`,
                            "title": `${params.currentPage.title}_PageView`
                        }
                    ]
                }
            ],
            "visualizerJson": params.funnel.visualizerJson ? `${params.funnel.visualizerJson}` : JSON.stringify(zoomValue)
        }
    });
}
export function splitChooseTemplate(params, { headers = {}, requestBody = {} }) {
    const zoomValue = { zoomValue: `${params.zoomValue ? params.zoomValue : 1 }`, layoutZoom: `${params.layoutZoom ? params.layoutZoom : 25.5 }`, graphZoom: `${params.graphZoom ? params.graphZoom : 25 }` };
    return APIHelper.baseRequest(APIHelper.PUT, `${rootUrl}/Funnel/Visualizer/${params.funnel.referenceId}`, {
        headers,
        requestBody: {
            "Title": requestBody.title || "",
            "Offer": Array.isArray(requestBody.offer) ? JSON.stringify(requestBody.offer) : requestBody.offer,
            "Upsale": Array.isArray(requestBody.upsale) ? JSON.stringify(requestBody.upsale) : requestBody.upsale,
            "Campaign": requestBody.campaign || "",
            "Status": requestBody.status || "",
            "DefaultPageViewReferenceId": "",
            "page": [
                {
                    "referenceId": `${params.currentPage.referenceId}`,
                    "cellViewId": `${params.currentPage.cellViewId}`,
                    "SplitEnabled": true,
                    "SplitNewTemplate": true,
                    "title": `${params.currentPage.title}`,
                    "pageView": [
                        {
                            "referenceId": `${params.currentPage.pageView[0].referenceId}`,
                            "title": `${params.currentPage.title}_PageView`
                        }
                    ]
                }
            ],
            "visualizerJson": params.funnel.visualizerJson ? `${params.funnel.visualizerJson}` : JSON.stringify(zoomValue)
        }
    });
}
export function setSplitPercent(params, { headers = {}, requestBody = {} }) {
    const zoomValue = { zoomValue: `${params.zoomValue ? params.zoomValue : 1 }`, layoutZoom: `${params.layoutZoom ? params.layoutZoom : 25.5 }`, graphZoom: `${params.graphZoom ? params.graphZoom : 25 }` };
    return APIHelper.baseRequest(APIHelper.PUT, `${rootUrl}/Funnel/Visualizer/${params.funnel.referenceId}`, {
        headers,
        requestBody: {
            "Title": requestBody.title || "",
            "Offer": Array.isArray(requestBody.offer) ? JSON.stringify(requestBody.offer) : requestBody.offer,
            "Upsale": Array.isArray(requestBody.upsale) ? JSON.stringify(requestBody.upsale) : requestBody.upsale,
            "Campaign": requestBody.campaign || "",
            "Status": requestBody.status || "",
            "DefaultPageViewReferenceId": "",
            "page": [
                {
                    "referenceId": `${params.firstPage.referenceId}`,
                    "cellViewId": `${params.firstPage.cellViewId}`,
                    "splitRatio": `${params.pageValue1}`,
                    "modified": true,
                    "title": `${params.firstPage.title}`,
                    "pageView": [
                        {
                            "referenceId": `${params.firstPage.pageView[0].referenceId}`,
                            "title": `${params.firstPage.title}_PageView`
                        }
                    ]
                },
                {
                    "referenceId": `${params.secondPage.referenceId}`,
                    "cellViewId": `${params.secondPage.cellViewId}`,
                    "splitRatio": `${params.pageValue2}`,
                    "modified": true,
                    "title": `${params.secondPage.title}`,
                    "pageView": [
                        {
                            "referenceId": `${params.secondPage.pageView[0].referenceId}`,
                            "title": `${params.secondPage.title}_PageView`
                        }
                    ]
                }
            ],
            "visualizerJson": params.funnel.visualizerJson ? `${params.funnel.visualizerJson}` : JSON.stringify(zoomValue)
        }
    });
}

export function updateFunnel(params, { headers = {}, requestBody = {} }) {
    const zoomValue = { zoomValue: `${params.zoomValue ? params.zoomValue : 1 }`, layoutZoom: `${params.layoutZoom ? params.layoutZoom : 25.5 }`, graphZoom: `${params.graphZoom ? params.graphZoom : 25 }` };
    const localStorageHelper = new LocalStorageHelper();
    const isSplitEnabled = params.isSplitEnabled;
    const currentDefaultPageViewReferenceId = params.currentDefaultPageViewReferenceId;
    const defaultPage = isSplitEnabled ? "DefaultSplitViewReferenceId" : "DefaultPageViewReferenceId";
    const tenantId = localStorageHelper.load(LocalStorageHelper.keys.TENANT_ID);
    const offerBody = typeof(requestBody.offer) === "string" ? JSON.parse(requestBody.offer) : requestBody.offer;
    const upsaleBody = typeof(requestBody.upsale) === "string" ? JSON.parse(requestBody.upsale) : requestBody.upsale;
    let offer = offerBody && offerBody.filter((item, index) => offerBody.indexOf(item) === index);
    let upsell = upsaleBody && upsaleBody[0] !== "[" ? upsaleBody.filter((item, index) => upsaleBody.indexOf(item) === index) : upsaleBody;
    const initialRequestBody = {
        "FTenantId": tenantId,
        "Title": requestBody.title || "",
        "FListFunnelTypeId": 1,
        "Offer": Array.isArray(offer) ? JSON.stringify(offer) : offer,
        "Upsale": Array.isArray(upsell) ? JSON.stringify(upsell) : upsell,
        "Campaign": requestBody.campaign || "",
        "Status": requestBody.status || "",
        "Updated On": requestBody.updatedOn,
        "visualizerJson": JSON.stringify(zoomValue)
    };
    const finalRequestBody = isSplitEnabled ? { ...initialRequestBody, "DefaultSplitViewReferenceId": currentDefaultPageViewReferenceId }
        : { ...initialRequestBody, "DefaultPageViewReferenceId": currentDefaultPageViewReferenceId };
    return APIHelper.baseRequest(APIHelper.PUT, `${rootUrl}/Funnel/Visualizer/${params.funnel.referenceId}`, {
        headers,
        requestBody: finalRequestBody
    });
}
export function manageEditFunnel(params, { headers = {}, requestBody = {} }) {
    const localStorageHelper = new LocalStorageHelper();
    const tenantId = localStorageHelper.load(LocalStorageHelper.keys.TENANT_ID);
    return APIHelper.baseRequest(APIHelper.PUT, `${rootUrl}/Funnel/${params}`, {
        headers,
        requestBody: {
            "FTenantId": tenantId,
            "Title": requestBody.title || "",
            "FListFunnelTypeId": 1,
            "Offer": Array.isArray(requestBody.offer) ? JSON.stringify(requestBody.offer) : requestBody.offer,
            "Upsale": Array.isArray(requestBody.upsale) ? JSON.stringify(requestBody.upsale) : requestBody.upsale,
            "Campaign": requestBody.campaign || "",
            "Status": requestBody.status || "",
            "Updated On": requestBody.updatedOn,
        }
    });
}

export function deletePage(params, { headers = {}, requestBody = {} }) {
    const zoomValue = { zoomValue: `${params.zoomValue ? params.zoomValue : 1 }`, layoutZoom: `${params.layoutZoom ? params.layoutZoom : 25.5 }`, graphZoom: `${params.graphZoom ? params.graphZoom : 25 }` };
    const localStorageHelper = new LocalStorageHelper();
    const tenantId = localStorageHelper.load(LocalStorageHelper.keys.TENANT_ID);
    return APIHelper.baseRequest(APIHelper.PUT, `${rootUrl}/Funnel/Visualizer/${params.funnel.referenceId}`, {
        headers,
        requestBody: {
            "Title": requestBody.title || "",
            "Offer": Array.isArray(requestBody.offer) ? JSON.stringify(requestBody.offer) : requestBody.offer,
            "Upsale": Array.isArray(requestBody.upsale) ? JSON.stringify(requestBody.upsale) : requestBody.upsale,
            "Campaign": requestBody.campaign || "",
            "Status": requestBody.status || "",
            "page": [
                {
                    "referenceId": `${params.pageReferenceId}`,
                    "deleted": true
                }
            ],
            "visualizerJson": params.funnel.visualizerJson ? `${params.funnel.visualizerJson}` : JSON.stringify(zoomValue)
        }
    });
}

export function deletePages(params, { headers = {}, requestBody = {} }) {
    const zoomValue = { zoomValue: `${params.zoomValue ? params.zoomValue : 1 }`, layoutZoom: `${params.layoutZoom ? params.layoutZoom : 25.5 }`, graphZoom: `${params.graphZoom ? params.graphZoom : 25 }` };
    const localStorageHelper = new LocalStorageHelper();
    let apiRequestBody = {
        "Title": requestBody.title || "",
        "Offer": Array.isArray(requestBody.offer) ? JSON.stringify(requestBody.offer) : requestBody.offer,
        "Upsale": Array.isArray(requestBody.upsale) ? JSON.stringify(requestBody.upsale) : requestBody.upsale,
        "Campaign": requestBody.campaign || "",
        "Status": requestBody.status || "",
        "page": [],
        "visualizerJson": params.funnel.visualizerJson ? `${params.funnel.visualizerJson}` : JSON.stringify(zoomValue)
    };
    params.pageReferenceIds.map(item => apiRequestBody.page.push({
        "referenceId": item,
        "deleted": true
    }));
    return APIHelper.baseRequest(APIHelper.PUT, `${rootUrl}/Funnel/Visualizer/${params.funnel.referenceId}`, {
        headers,
        requestBody: apiRequestBody
    });
}

export function importPage(params, { headers = {}, requestBody = {} }) {
    const localStorageHelper = new LocalStorageHelper();
    const tenantId = localStorageHelper.load(LocalStorageHelper.keys.TENANT_ID);
    return APIHelper.baseRequest(APIHelper.POST, `${importPageUrl}/PageImport/importPage`, {
        headers,
        requestBody: {
            "PageShareId": params.shareId,
            "CurrentPageFunnelId": params.funnelReferenceId,
            "CurrentPageViewReferenceId": params.pageViewReferenceId,
            "TenantId": tenantId,
        }
    });
}

export function savefunnel(params, { headers = {} }) {
    var savedfile;
    var jsonStirng = JSON.stringify(params.jsonFile);
    var savedblob = new Blob([jsonStirng], { type: "application/json" });
    // Added the fix for Edge browser issue
    if (/Edge/.test(navigator.userAgent)) {
        savedfile = new Blob([savedblob], `${params.funnelReferenceId}_meta.json`);
    } else {
        savedfile = new File([savedblob], `${params.funnelReferenceId}_meta.json`);
    }
    let funnelFormData = new FormData();
    if (/Edge/.test(navigator.userAgent)) {
        funnelFormData.append("meta_json", new Blob([savedblob], `${params.funnelReferenceId}_meta.json`));
    } else {
        funnelFormData.append("meta_json", new File([savedblob], `${params.funnelReferenceId}_meta.json`));
    }
    var options = { content: funnelFormData };
    return APIHelper.baseRequest(APIHelper.PUT, `${rootUrl}/Funnel/VisualizerJson/${params.funnelReferenceId}`, {
        contentType: APIHelper.getMultipartReqHeader(),
        requestBody: funnelFormData,
        headers
    });
}

export function getTemplate(params,{ headers = {}, requestBody = {} }) {
    const templateUrl = params.selectedTemplate ? params.selectedTemplate.htmlUrl : params.template.htmlUrl;
    let fetchData = {
        method: "GET",
        headers: {
            "Cache-Control": "no-cache",
            "mode": "cors",
            "Access-Control-Allow-Origin": "*"
        },
    };
    return fetch(templateUrl, fetchData).
        then((resp) => {
            return resp.text();
        }).
        then((htmlPage) => {
            return htmlPage;
        });
}
export function getMetaUrl(params,{ headers = {}, requestBody = {} }) {
    const templateUrl = params.metaJson;
    let fetchData = {
        method: "GET",
        headers: {
            "Cache-Control": "no-cache",
            "mode": "cors",
            "Access-Control-Allow-Origin": "*"
        },
    };
    return fetch(templateUrl).
        then((resp) => {
            return resp.text();
        });
}

export function getHtmlPage(htmlUrl,{ headers = {}, requestBody = {} }) {
    let fetchData = {
        method: "GET",
        headers: {
            "Cache-Control": "no-cache",
            "mode": "cors",
            "Access-Control-Allow-Origin": "*"
        },
    };
    return fetch(htmlUrl, fetchData).
        then((resp) => {
            return resp.text();
        }).
        then((htmlPage) => {
            return htmlPage;
        });
}

const getFetchDataHeaders = () => ({
    method: "GET",
    headers: {
        "Cache-Control": "no-cache",
        "mode": "cors",
        "Access-Control-Allow-Origin": "*"
    },
});

export function getJsonData(params,{ headers = {}, requestBody = {} }) {
    const fetchData = getFetchDataHeaders();
    const componentJsonUrl = params.componentJsonUrl;
    const stylesJsonUrl = params.stylesJsonUrl;
    const htmlUrl = params.htmlUrl;
    let setcomponentData;
    let setstylesData;
    let htmlPage;
    return fetch(componentJsonUrl, fetchData).
        then((response) => {
            if (response.status === 200) {
                return response.text();
            }
        }).
        then((componentData) => {
            if (componentData !== "undefined") {
                return fetch(stylesJsonUrl, fetchData).
                    then((response) => {
                        if (response.status === 200) {
                            return response.text();
                        }
                    }).
                    then((stylesData) => {
                        if (stylesData !== "undefined") {
                            setcomponentData = componentData;
                            setstylesData = stylesData;
                            return {
                                setcomponentData,
                                setstylesData
                            };
                        }

                    });
            } else {
                return fetch(htmlUrl, fetchData).
                    then((resp) => {
                        return resp.text();
                    }).then((htmldata) => {
                        return htmlPage = htmldata;
                    });
            }
        });
}

export function updateFunnels({ funnelId, headers = {} }) {
    return APIHelper.baseRequest(APIHelper.PUT, `${rootUrl}/Funnel/${funnelId}`, { headers });
}

export function funnelMetaUrl(metaUrl) {
    let fetchData = {
        method: "GET",
        headers: {
            "Cache-Control": "no-cache",
            "Access-Control-Allow-Origin": "*"
        },
    };
    return fetch(metaUrl, fetchData).
        then((resp) => {
            if (resp.status === 200 && resp.type === "cors") {
                return resp.text();
            }
            return "{}";
        }).
        then((metaJson) => {
            return metaJson;
        });
}

export function getDomainListForUser(funnelReferenceId, { headers = {} }) {
    return APIHelper.baseRequest(APIHelper.GET, `${rootUrl}/Domain/funnel/${funnelReferenceId}`, { headers });
}

export function setDomainForFunnel(selectedDomainAndFunnelId, { headers = {} }) {
    return APIHelper.baseRequest(APIHelper.PUT, `${domainFunnelUrl}`,{
        headers,
        requestBody: {
            "DomainReferenceId": selectedDomainAndFunnelId.domainReferenceId,
            "FunnelReferenceId": selectedDomainAndFunnelId.funnelReferenceId !== null ? selectedDomainAndFunnelId.funnelReferenceId : null
        }
    });
}

export function addAndSetDomainForFunnel(domainData, { headers = {} }) {
    const apiMethod = domainData.domainReferenceId ? APIHelper.PUT : APIHelper.POST;
    let requestBody = {
        "FunnelReferenceId": domainData.funnelReferenceId !== null ? domainData.funnelReferenceId : null
    };
    if (domainData.domainReferenceId) {
        requestBody = {
            ...requestBody,
            DomainReferenceId: domainData.domainReferenceId,
        };
    } else {
        requestBody = {
            ...requestBody,
            Name: domainData.domainName,
        };
    }
    return APIHelper.baseRequest(apiMethod, `${domainFunnelUrl}`,{
        headers,
        requestBody,
    });
}

export function importFunnel(params, { headers = {}, requestBody = {} }) {
    return APIHelper.baseRequest(APIHelper.POST, `${importPageUrl}/FunnelImport/importFunnel`, {
        headers,
        requestBody: {
            "currentReferenceId": params.referenceId,
            "importReferenceId": params.importId || "",
            "isTemplate": params.isFunnelTemplate,
            "isPremium": params.isPremium || false
        }
    });
}
export function getDefaultFunnels() {
    return APIHelper.baseRequest(APIHelper.GET, `${funnelTemplateRootUrl}/${FunnelTemplate}/GetActiveFunnelTemplates`, { headers: APIHelper.getGenericHeaders() });
}

export function importFunnelFromShareId(params, { headers = {}, requestBody = {} }) {
    return APIHelper.baseRequest(APIHelper.POST, `${importPageUrl}/FunnelImport/shareFunnel/${params.referenceId}/${params.importId}`, {
        headers,
        requestBody: {
        }
    });
}

export function getThumbnailForFunnel(funnelVisualizerReferenceId, { headers = {} }) {
    return APIHelper.baseRequest(APIHelper.GET, `${funnelThumbnailUrl}/visualizer?funnelVisualizerReferenceId=${funnelVisualizerReferenceId}`, { headers });
}

/*
 * Move this to helper
 */
const dateFormatString = "YYYY-MM-DD";
const toQueryString = (paramsObject) => {
    return Object
        .keys(paramsObject)
        .map(key => `${encodeURIComponent(key)}=${encodeURIComponent(paramsObject[key])}`)
        .join("&")
    ;
};
export function getAnalyticsData(params, { headers = {} }) {
    const paramsToPass = {
        ...params,
        startDate: (params.startDate) ? moment(params.startDate).format(dateFormatString) : moment().subtract(3, "months").format(dateFormatString),
        endDate: (params.endDate) ? moment(params.endDate).format(dateFormatString) : moment().format(dateFormatString),
    };
    const url = `${rootUrl}/SplitTest/pageanalytics?${toQueryString(paramsToPass)}`;
    return APIHelper.baseRequest(APIHelper.GET, url, {
        headers
    });
}

export function createNewPage(params, { headers = {}, requestBody = {} }) {
    const pageData = JSON.stringify(params.page);
    const setRequestBody = {
        "changesetId": params.changeSetId,
        "funnelReferenceId": params.funnelReferenceId,
        "componentModel":
        {
            "actionType": 0,
            "componentType": 0,
            "componentId": params.page.id,
            "componentValue": pageData,
            "pageModel":
                        {
                            "pageReferenceId": params.page.pageReferenceId && params.page.pageReferenceId,
                            "pageTitle": params.page.pageLabel.label,
                            "pageViewReferenceId": params.page.pageViewReferenceId && params.page.pageViewReferenceId,
                            "pageViewTitle": params.page.pageLabel.label,
                        }
        }
    };
    return APIHelper.baseRequest(APIHelper.POST, `${rootUrl}/Changeset/Create`, {
        headers,
        requestBody: setRequestBody
    });
}

export function deletePageApi(params, { headers = {}, requestBody = {} }) {
    const pageData = JSON.stringify(params.page);
    const setRequestBody = {
        "changesetId": params.changeSetId,
        "funnelReferenceId": params.funnelReferenceId,
        "componentModel":
        {
            "actionType": 2,
            "componentType": 0,
            "componentId": params.page.id,
            "pageModel":
                        {
                            "pageReferenceId": params.page.pageReferenceId && params.page.pageReferenceId,
                            "pageViewReferenceId": params.page.pageViewReferenceId && params.page.pageViewReferenceId,
                            "hasLinks": params.hasLinks
                        }
        }
    };
    return APIHelper.baseRequest(APIHelper.POST, `${rootUrl}/Changeset/Create`, {
        headers,
        requestBody: setRequestBody
    });
}

export function updatePageApi(params, { headers = {}, requestBody = {} }) {
    const pageData = JSON.stringify(params.page);
    const setRequestBody = {
        "changesetId": params.changeSetId,
        "funnelReferenceId": params.funnelReferenceId,
        "componentModel":
        {
            "actionType": 1,
            "componentType": 0,
            "componentId": params.page.id,
            "componentValue": pageData,
            "pageModel":
                        {
                            "pageReferenceId": params.page.pageReferenceId && params.page.pageReferenceId,
                            "pageTitle": params.page.pageLabel.label,
                            "pageViewReferenceId": params.page.pageViewReferenceId && params.page.pageViewReferenceId,
                            "pageViewTitle": params.page.pageLabel.label
                        }
        }
    };
    return APIHelper.baseRequest(APIHelper.POST, `${rootUrl}/Changeset/Create`, {
        headers,
        requestBody: setRequestBody
    });
}
export function updatePageTemplateApi(params, { headers = {}, requestBody = {} }) {
    const pageData = JSON.stringify(params.page.attributes);
    const setRequestBody = {
        "changesetId": params.changeSetId,
        "funnelReferenceId": params.funnelReferenceId,
        "componentModel":
        {
            "actionType": 7,
            "componentType": 0,
            "componentId": params.page.id,
            "componentValue": pageData,
            "pageModel":
                        {
                            "pageReferenceId": params.pageReferenceId,
                            "pageViewReferenceId": params.pageViewReferenceId,
                            "templateId": params.templateId
                        }
        }
    };
    return APIHelper.baseRequest(APIHelper.POST, `${rootUrl}/Changeset/Create`, {
        headers,
        requestBody: setRequestBody
    });
}

export function createNewLink(params, { headers = {}, requestBody = {} }) {
    const linkData = JSON.stringify(params.link);
    const setRequestBody = {
        "changesetId": params.changeSetId,
        "funnelReferenceId": params.funnelReferenceId,
        "componentModel":
        {
            "actionType": 0,
            "componentType": 1,
            "componentId": params.link.id,
            "componentValue": linkData,
            "linkViewModel":
                {
                    "targetPageCellViewId": params.link.target.id,
                    "sourcePageCellViewId": params.link.source.id
                }
        }
    };
    return APIHelper.baseRequest(APIHelper.POST, `${rootUrl}/Changeset/Create`, {
        headers,
        requestBody: setRequestBody
    });
}

export function getButtonsAndLinksApi(params, { headers = {} }) {
    const {
        sourcePageviewReferenceId,
        funnelReferenceId,
        linkId,
        visualizerType
    } = params;
    return APIHelper.baseRequest(
        APIHelper.GET,
        `${rootUrl}/LinkViewDetails/GetLinkViewDetailAsync/${visualizerType}/${funnelReferenceId}/PageView/${sourcePageviewReferenceId}/LinkId/${linkId}?`,
        {
            headers
        }
    );
}

export function saveButtonsAndLinkDetails(params, visualizerType , { headers = {}, requestBody = {} }) {
    return APIHelper.baseRequest(APIHelper.PUT, `${rootUrl}/LinkViewDetails/${visualizerType}/UpdateLinkViewDetailAsync?`, {
        headers,
        requestBody: params
    });
}

export function saveUpdatedSplitRatio(params, { headers = {}, requestBody = {} }) {
    const {
        requestParams,
        stringParams
    } = params;
    const {
        funnelReferenceId,
        splitReferenceId
    } = stringParams;
    return APIHelper.baseRequest(APIHelper.POST, `${rootUrl}/Page/UpdateSplitRatio/${funnelReferenceId}/${splitReferenceId}`, {
        headers,
        requestBody: requestParams
    });
}
export function updateZoomValue(params, { headers = {}, requestBody = {} }) {
    const {
        zoomParams,
        funnelReferenceId
    } = params;
    const stringifiedParams = JSON.stringify(zoomParams);
    return APIHelper.baseRequest(APIHelper.POST, `${rootUrl}/Funnel/VisualiserZoom/${funnelReferenceId}`, {
        headers,
        requestBody: stringifiedParams
    });
}

export function deleteClickedLink(params, { headers = {}, requestBody = {} }) {
    const setRequestBody = {
        "changesetId": params.changeSetId,
        "funnelReferenceId": params.funnelReferenceId,
        "componentModel": {
            "actionType": 2,
            "componentType": 1,
            "componentId": params.linkId
        }

    };
    return APIHelper.baseRequest(APIHelper.POST, `${rootUrl}/Changeset/Create`, {
        headers,
        requestBody: setRequestBody
    });
}

export function saveUpdatedPageNameApi(params, { headers = {} }) {
    const {
        funnelReferenceId,
        pageReferenceId,
        currentPageTitle
    } = params;
    return APIHelper.baseRequest(APIHelper.POST, `${rootUrl}/Page/UpdatePageTitle/${funnelReferenceId}/${pageReferenceId}?title=${currentPageTitle}`,{
        headers,
        requestBody: {}
    });
}

export function changeDefaultPageStatusApi(params, { headers = {} }) {
    const {
        pageViewReferenceId,
        isSplitEnabled,
        funnelReferenceId
    } = params;
    return APIHelper.baseRequest(APIHelper.POST, `${rootUrl}/PageView/SetDefaultPage/${funnelReferenceId}?defaultReferenceId=${pageViewReferenceId}&splitEnabled=${isSplitEnabled}`,{
        headers,
        requestBody: {}
    });
}

export function updateCampaign(params, { headers = {} }) {
    const {
        funnelReferenceId
    } = params;
    return APIHelper.baseRequest(APIHelper.PUT, `${rootUrl}/PageData/Funnel/UpdateCampaign/${funnelReferenceId}`, {
        headers,
        requestBody: {}
    });
}