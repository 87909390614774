import React, { Component } from "react";
import joint from "jointjs";
import "../../../node_modules/jointjs/dist/joint.css";
import Elements from "./elements";
import constants from "../utilities/constants";
import {
    getFunnelTemplate,
} from "../../funnels/visualizer/actions/funnelTemplateActions";
import { generateVisualizerModel } from "../../funnels/visualizer/utilities/generateModel/generate-visualizer-model";

export default class ScreenshotTemplate extends Component {
    constructor() {
        super();
        this.stencil = new joint.dia.Graph();
        this.toolBar = new joint.dia.Graph();
        this.stencilPaper = new joint.dia.Paper();
        this.state = {
            presellPage: [constants.presellPageSvg].join(""),
            checkoutPage: [constants.checkoutPageSvg].join(""),
            upsellPage: [constants.upsellPageSvg].join(""),
            thankyouPage: [constants.thankyouPageSvg].join(""),
            blankPage: [constants.blankPageSvg].join(""),
            optInPage: [constants.optInPageSvg].join(""),
            downloadPage: [constants.downloadPageSvg].join(""),
            salesVideoPage: [constants.salesVideoPageSvg].join(""),
            calendarPage: [constants.calendarPageSvg].join(""),
            surveyPage: [constants.surveyPageSvg].join(""),
            webinarPage: [constants.webinarPageSvg].join(""),
            webinarReplayPage: [constants.webinarReplayPageSvg].join(""),
            blogPostPage: [constants.blogPostPageSvg].join(""),
            membersAreaPage: [constants.membersAreaPageSvg].join(""),
            popUpPage: [constants.popUpPageSvg].join(""),
            emailPage: [constants.emailPageSvg].join(""),
            textPage: [constants.textPageSvg].join(""),
            genericPage: [constants.membersAreaPageSvg].join(""),
            paperScale: 1,
        };
        const pathName = window.location.pathname;
        const referenceId = pathName.split("/").slice(-1)[0];
        getFunnelTemplate(referenceId).then((response) => {
            const components = response.components;
            const funnelPages = response.page;
            return this.getGraph(
                generateVisualizerModel(
                    components,
                    funnelPages
                )
            );
        });
    }

    componentDidMount() {
        this.visualizerInitialize();
    }

    visualizerInitialize = () => {
        const { leadPage, presellPage, checkoutPage, upsellPage, thankyouPage, blankPage, optInPage, downloadPage, salesVideoPage, calendarPage, surveyPage, webinarPage, webinarReplayPage, blogPostPage, membersAreaPage, popUpPage, emailPage, textPage, genericPage } = this.state;
        this.stencilPaper = new joint.dia.Paper({
            el: this.refs.visualizer,
            model: this.stencil,
            width: window.innerWidth,
            height: window.innerHeight,
            background: {
                color: "#fff"
            },
            interactive: false
        });
        const parent = "funnelTemplate";

        const presell = Elements(presellPage, constants.presellPagePositionX, constants.presellPagePositionY, constants.presellPageLabel, 1, false, parent);
        const lead = Elements(leadPage, constants.leadPagePositionX, constants.leadPagePositionY, constants.leadPageLabel, 2, false, parent);
        const checkout = Elements(checkoutPage, constants.checkoutPagePositionX, constants.checkoutPagePositionY, constants.checkoutPageLabel, 4, false, parent);
        const upsell = Elements(upsellPage, constants.upsellPagePositionX, constants.upsellPagePositionY, constants.upsellPageLabel, 3, false, parent);
        const thankyou = Elements(thankyouPage, constants.thankyouPagePositionX, constants.thankyouPagePositionY, constants.thankyouPageLabel, 5, false, parent);
        const blank = Elements(blankPage, constants.blankPagePositionX, constants.blankPagePositionY, constants.blankPageLabel, null, false, parent);
        const optIn = Elements(optInPage, constants.optInPagePositionX, constants.optInPagePositionY, constants.optInPageLabel, 15, false, parent);
        const download = Elements(downloadPage, constants.downloadPagePositionX, constants.downloadPagePositionY, constants.downloadPageLabel, 16, false, parent);
        const salesVideo = Elements(salesVideoPage, constants.salesVideoPagePositionX, constants.salesVideoPagePositionY, constants.salesVideoPageLabel, 17, false, parent);
        const calendar = Elements(calendarPage, constants.calendarPagePositionX, constants.calendarPagePositionY, constants.calendarPageLabel, 18, false, parent);
        const survey = Elements(surveyPage, constants.surveyPagePositionX, constants.surveyPagePositionY, constants.surveyPageLabel, 19, false, parent);
        const webinar = Elements(webinarPage, constants.webinarPagePositionX, constants.webinarPagePositionY, constants.webinarPageLabel, 20, false, parent);
        const webinarReplay = Elements(webinarReplayPage, constants.webinarReplayPagePositionX, constants.webinarReplayPagePositionY, constants.webinarReplayPageLabel, 21, false, parent);
        const blogPost = Elements(blogPostPage, constants.blogPostPagePositionX, constants.blogPostPagePositionY, constants.blogPostPageLabel, 22, false, parent);
        const membersArea = Elements(membersAreaPage, constants.membersAreaPagePositionX, constants.membersAreaPagePositionY, constants.membersAreaPageLabel, 9, false, parent);
        const popUp = Elements(popUpPage, constants.popUpPagePositionX, constants.popUpPagePositionY, constants.popUpPageLabel, 23, false, parent);
        const email = Elements(emailPage, constants.emailPagePositionX, constants.emailPagePositionY, constants.emailPageLabel, 24, true, parent);
        const text = Elements(textPage, constants.textPagePositionX, constants.textPagePositionY, constants.textPageLabel, 25, true, parent);
        const generic = Elements(genericPage, constants.genericPagePositionX, constants.genericPagePositionY, constants.genericPageLabel, 14, true, parent);
        this.toolBar.addCell([
            presell,
            lead,
            checkout,
            upsell,
            thankyou,
            blank,
            optIn,
            download,
            salesVideo,
            calendar,
            survey,
            webinar,
            webinarReplay,
            blogPost,
            membersArea,
            popUp,
            email,
            text,
            generic,
        ]);
    }

    getGraph = (metaJsonVisualizer) => {
        this.stencil.fromJSON(metaJsonVisualizer);
        this.stencilPaper.scaleContentToFit({ padding: 32 });
    }
    render() {
        return (
            <>
                <div ref="visualizer"
                    style={{
                        width: window.innerWidth,
                        height: window.innerHeight
                    }}
                />
            </>
        );
    }
}