import React from "react";
import PropTypes from "prop-types";
import moment from "moment";
import "./list.css";
import { getTemplateConfig } from "../constants/funnel-template-types";

export const List = ({ list, details, isLoading, deleteRow, createItem, constants, openSetting, parent = "", userData }) => {
    const { chooseItem, deleteItem, addItem } = constants;
    list = list.sort((a, b) => Date.parse(b.dateCreated) - Date.parse(a.dateCreated));
    return (
        <div className="row page-wrapper page-heading">
            {!isLoading &&
                <div className="centerList">
                    <a className="add-funnel-thumbnail centerItem" onClick={createItem}><i className="fa fa-plus-circle" aria-hidden="true"></i><div>{addItem}</div></a>
                    {(list).map((item, index) => {
                        const { referenceId, title, dateCreated, userName, active, fListFunnelTypeId, price } = item;
                        const thumbnailUrl = item.thumbnailUrl + "?" + new Date().getTime();
                        const funnelType = getTemplateConfig(fListFunnelTypeId);
                        const Price = price ? `$${price}` : "Free";
                        return (
                            <div className={`${parent}`} key={index}>
                                <div className={"status-indicator " + (active ? "active-template" : "")}></div>
                                {
                                    funnelType && (
                                        <div className="page-template-type">
                                            {funnelType.label}
                                        </div>
                                    )
                                }
                                <div className="funnel-thumbnail">
                                    {userData.isDesignerUser && <div class="ribbon-price">{Price}</div>}
                                    <div className="thumbnail-img" style={{ backgroundImage: `url(${thumbnailUrl})` }}>
                                        <a className="choose" onClick={() => details(referenceId)}><div className="choose-hand"></div><div>{chooseItem}</div></a>
                                        <a className="delete" onClick={() => deleteRow(referenceId)}><div>{deleteItem}</div></a>
                                    </div>
                                    <div className="thumbnail-container">
                                        <div className="thumbnail-content" onClick={() => details(referenceId)}>
                                            <abbr title={title} className="abbrtitle"><div className="thumbnailTitle" >{title}</div></abbr>
                                            <div>{moment(dateCreated).format("MM-DD-YYYY")}</div>
                                            <div>{userName ? userName : "John Doe"}</div>
                                        </div>
                                        <div className="settings-icon-container">
                                            <span
                                                onClick={() => openSetting(true, item)}
                                            >
                                                <i className="fa fa-cog fa-lg" />
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        );
                    }
                    )}
                </div>
            }
        </div>
    );
};

List.propTypes = {
    list: PropTypes.array.isRequired,
    deleteRow: PropTypes.func,
    details: PropTypes.func,
    createItem: PropTypes.func,
    isLoading: PropTypes.bool,
    constants: PropTypes.object,
    openSetting: PropTypes.func,
    parent: PropTypes.string,
    userData: PropTypes.object
};