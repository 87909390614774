import React from "react";
import PropTypes from "prop-types";
import { Templates } from "./../../../templates";

export class FunnelDescription extends React.Component {
    constructor(props, context) {
        super(props, context);
    }
    render() {
        return (
            <div className="wrapper wrapper-content animated fadeInRight container" style={{ backgroundColor: "white", padding: "0 5% 5% 5%", boxShadow: "0px 2px 2px 2px lightGrey" }}>
                <div className="row">
                    <div className="text-center">
                        <div className="page-header">
                            <h1 style={{ fontWeight: "bold" }}>Funnel Description</h1>
                        </div>
                        <div className="row">
                            <div className="col-md-4">
                                <img style={{ width: "10%" }} src={require("../../../img/icons/calendar.svg")} alt=""/>
                                <p>Type</p>
                                <h3>Event</h3>
                            </div>
                            <div className="col-md-4">
                                <img style={{ width: "10%" }} src={require("../../../img/icons/clipboard.svg")} alt=""/>
                                <p>Pages</p>
                                <h3>5</h3>
                            </div>
                            <div className="col-md-4">
                                <img style={{ width: "10%" }} src={require("../../../img/icons/alarm-clock.svg")} alt=""/>
                                <p>Time</p>
                                <h3>1h 15m</h3>
                            </div>
                        </div>
                        <hr/>
                        <div className="row">
                            <div className="col-md-6">
                                <iframe style={{ width: "100%", height: "350px" }} src="https://www.youtube.com/embed/_AhE8tqO53c"
                                    frameBorder="0" allow="autoplay; encrypted-media" allowFullScreen/>
                            </div>
                            <div className="col-md-4 text-left">
                                <h3>Directions</h3>
                                <p>The Auto Webinar Funnel is used after an entrepreneur has proven their offer
                                    several times previously in the Webinar Funnel. The major difference in this
                                    funnel is that the historically best converting webinar is uploaded online
                                    (YouTube, Vimeo, etc) and is embedded in its own “Webinar Broadcast Page.”
                                    Auto webinars can play every hour around the clock selling your products
                                    and services automatically for you. Someone registers for the webinar
                                    on the registration page, then they are taken to a confirmation page
                                    where they can see the date and time for their event. They are then
                                    emailed links to indoctrination pages where you can get them excited
                                    about the training they either are about to get on, or just completed.
                                    After their presentation time has passed, they are then emailed links
                                    to a replay room where they have one last chance to watch the webinar.</p>
                            </div>
                            <div className="col-md-2 text-left">
                                <h3>Pages</h3>
                                <p>Webinar Registration Page</p>
                                <hr style={{ padding: 0, margin: 0 }}/>
                                <p>Webinar Confirmation Page</p>
                                <hr style={{ padding: 0, margin: 0 }}/>
                                <p>Webinar Broadcast Page</p>
                                <hr style={{ padding: 0, margin: 0 }}/>
                                <p>Replay Page</p>
                                <hr style={{ padding: 0, margin: 0 }}/>
                                <p>Indoctrination Page</p>
                            </div>
                        </div>
                    </div>
                </div>
                <hr/>
                <div>
                    <h3 className="text-center">Step 2 of 2</h3>
                    <Templates pageType="checkoutPage"/>
                </div>
            </div>
        );
    }
}

FunnelDescription.propTypes = {
    templates: PropTypes.array
};