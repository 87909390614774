export class Trait {
    constructor({ editor, traitName, traitTypeAttribute = {}, callbacks }) {
        this._am = editor.TraitManager;
        this._traitName = traitName;
        this._cb = callbacks;
        this._traitTypeAttribute = new TraitTypeAttribute({ ...traitTypeAttribute });
    }

    get traitTypeAttribute() {
        return this._traitTypeAttribute;
    }
    set traitTypeAttribute(attr) {
        this._traitTypeAttribute = attr;
    }
    get traitName() {
        return this._traitName;
    }
    set traitName(attr) {
        this._traitName = attr;
    }
}

export class TraitTypeAttribute {
    constructor({ events, getInputEl, onValueChange }) {
        this.events = events;
        this.getInputEl = getInputEl;
        this.onValueChange = onValueChange;
    }
}