import React, { useState } from "react";
import PropTypes from "prop-types";
import { Modal } from "react-bootstrap";

const CreateDomainModal = ({
    showAddNewDomainPopUp,
    closeNewDomainPopup,
    saveNewDomain,
    addYourOwnDomain,
    domainLabel,
    wwwLabel,
    domainNamePlaceholder,
    invalidDomainName,
    addDomainLabel,
    domainList,
}) => {
    const [domainName, setDomainName] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const [validationError, setValidationError] = useState(false);
    const onSaveDomain = (e) => {
        e.preventDefault();
        if (domainName) {
            const fullDomainName = `www.${domainName}`;
            const isDomainNameAlreadyExist = domainList &&
                !!domainList
                    .filter((domain) => domain.name === fullDomainName)
                    .length;
            if (domainName.indexOf(".") !== -1 && !/\s/.test(domainName) && !isDomainNameAlreadyExist) {
                setValidationError(null);
                setIsLoading(true);
                saveNewDomain(fullDomainName)
                    .then(() => {
                        setIsLoading(false);
                    })
                    .catch(() => {
                        setIsLoading(false);
                    });
            } else {
                if (isDomainNameAlreadyExist) {
                    setValidationError("Domain name already exists");
                } else {
                    setValidationError(invalidDomainName);
                }
            }
        } else {
            setValidationError("Domain name is required");
        }
    };

    return (
        <Modal show={showAddNewDomainPopUp} backdrop = "static" onHide={closeNewDomainPopup} bsSize="large" aria-labelledby="contained-modal-title-lg">
            <form className="form-horizontal">
                <Modal.Header closeButton style={{ backgroundColor: "rgb(47, 64, 77)" }}>
                    <Modal.Title><h3 className="text-white">{addYourOwnDomain}</h3></Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="form-group">
                        <label className="col-sm-2 control-label">{domainLabel}</label>
                        <div className="col-sm-10 input-group">
                            <span className="input-group-addon">{wwwLabel}</span>
                            <input
                                type="text"
                                maxLength="70"
                                onChange={(e) => setDomainName(e.target.value)}
                                value={domainName ? domainName : ""}
                                className="form-control"
                                placeholder={domainNamePlaceholder}
                            />
                        </div>
                    </div>
                    { validationError && <div className="form-group">
                        <div className="col-sm-2"></div>
                        <div className="col-sm-10 text-danger">{validationError}</div>
                    </div>}
                </Modal.Body>
                <Modal.Footer>
                    <button
                        type="submit"
                        className={`btn btn-primary ${isLoading ? "disabled" : ""}`}
                        onClick={onSaveDomain}
                    >
                        {
                            isLoading && (
                                <span className="m-r-sm">
                                    <i class="fa fa-cog fa-spin m-l-sm pointer" />
                                </span>
                            )
                        }
                        {addDomainLabel}
                    </button>
                </Modal.Footer>
            </form>
        </Modal>
    );
};

CreateDomainModal.propTypes = {
    showAddNewDomainPopUp: PropTypes.bool,
    closeNewDomainPopup: PropTypes.func,
    domainName: PropTypes.string,
    updateDomainNameState: PropTypes.func,
    saveNewDomain: PropTypes.func,
    showValidationError: PropTypes.bool,
    addYourOwnDomain: PropTypes.string,
    domainLabel: PropTypes.string,
    wwwLabel: PropTypes.string,
    domainNamePlaceholder: PropTypes.string,
    invalidDomainName: PropTypes.string,
    addDomainLabel: PropTypes.string,
    domainList: PropTypes.array,
};

export default CreateDomainModal;