
export const DESIGNERS_REDUCER_KEY = "DESIGNERS";

export function reducer(state = [], action) {
    switch (action.type) {
        case "LOAD_DESIGNERS_SUCCESS":
            return action.designers;
        default:
            return state;
    }
}