import { APIHelper, LocalStorageHelper } from "../utilities";
import { funnelTemplateRootUrl, googleFontsUrl, importPageUrl, saveFontsUrl, funnelThumbnailUrl,funnelTemplate } from "./urls";
const FunnelTemplate = "FunnelTemplate";
export function getAllFunnelTemplates({ headers = {} }) {
    let route = "GetAllFunnelTemplates";
    return APIHelper.baseRequest(APIHelper.GET, `${funnelTemplateRootUrl}/${FunnelTemplate}/${route}`, { headers });
}
export function getActiveFunnelTemplates({ headers = {} }) {
    let route = "GetActiveFunnelTemplates";
    return APIHelper.baseRequest(APIHelper.GET, `${funnelTemplateRootUrl}/${FunnelTemplate}/${route}`, { headers });
}

export function retrieveFunnelTemplate(funnelReferenceId, { headers = {} }) {
    let route = "GetFunnelTemplateByGuid";
    return APIHelper.baseRequest(APIHelper.GET, `${funnelTemplateRootUrl}/${FunnelTemplate}/${route}/${funnelReferenceId}`, { headers });
}

export function loadGoogleFonts({ headers = {} }) {
    return APIHelper.baseRequest(APIHelper.GET, `${googleFontsUrl}`, { headers });
}

export function clonePage(params, { headers = {} }) {
    return APIHelper.baseRequest(APIHelper.GET, `${funnelTemplateRootUrl}/Page/ClonePage/Funnel/${params.funnelReferenceId}/Page/${params.pageReferenceId}/CellViewId/${params.clonedPageCellviewId}`, {
        headers
    });
}
export function saveFonts(params, { headers = {}, requestBody = {} }) {
    const fontData = params.fontUrlArray.map(item => {
        return ({ "fontName": item.item.family, "fontFamily": item.item.family, "fontUrl": item.fontUrl });
    });
    return APIHelper.baseRequest(APIHelper.POST, `${saveFontsUrl}/SaveFonts/${params.funnelReferenceId}/${params.currentPage.referenceId}`, {
        headers,
        requestBody: fontData
    });
}
export function getFonts(params, { headers = {} }) {
    return APIHelper.baseRequest(APIHelper.GET, `${saveFontsUrl}/GetFonts/${params.funnelReferenceId}/${params.pageReferenceId}`, {
        headers
    });
}

export function createFunnelTemplate({ headers = {}, requestBody = {} }) {
    let route = "CreateDefaultFunnelTemplate";

    return APIHelper.baseRequest(APIHelper.POST, `${funnelTemplateRootUrl}/${FunnelTemplate}/${route}`, {
        headers,
        requestBody: {
            "Title": requestBody.title || "",
            "FListFunnelTypeId": requestBody.fListFunnelTypeId || 0,
            "VisualizerJson": requestBody.visualizerJson || "{\"zoomValue\":\"1\",\"layoutZoom\":\"25.5\",\"graphZoom\":\"25\"}",
            "ImportFunnelRefenceId": requestBody.ImportFunnelRefenceId || "",
            "IsFunnelTemplate": "true",
        }
    });
}
export function saveFunnelTemplate(params, { headers = {} }) {
    let route = "FunnelTemplateVisualizerJson";
    var jsonStirng = JSON.stringify(params.jsonFile);
    var savedblob = new Blob([jsonStirng], { type: "application/json" });
    // Added the fix for Edge browser issue
    // if (/Edge/.test(navigator.userAgent)) {
    //     savedfile = new Blob([savedblob], `${params.funnelReferenceId}_meta.json`);
    // } else {
    //     savedfile = new File([savedblob], `${params.funnelReferenceId}_meta.json`);
    // }
    let funnelFormData = new FormData();
    if (/Edge/.test(navigator.userAgent)) {
        funnelFormData.append("meta_json", new Blob([savedblob], `${params.funnelReferenceId}_meta.json`));
    } else {
        funnelFormData.append("meta_json", new File([savedblob], `${params.funnelReferenceId}_meta.json`));
    }
    return APIHelper.baseRequest(APIHelper.PUT, `${funnelTemplateRootUrl}/${FunnelTemplate}/${route}/${params.funnelReferenceId}`, {
        contentType: APIHelper.getMultipartReqHeader(),
        requestBody: funnelFormData,
        headers
    });
}
export function deleteFunnelTemplate(TemplateReferenceId) {
    let route = "DeleteFunnelTemplate";
    return APIHelper.baseRequest(APIHelper.PUT, `${funnelTemplateRootUrl}/${FunnelTemplate}/${route}/${TemplateReferenceId}`, { headers: APIHelper.getGenericHeaders() });
}
export function editFunnelTemplate(params, { headers = {}, requestBody = {} }) {
    let route = "ManageFunnelTemplate";
    const zoomValue = { zoomValue: `${params.zoomValue ? params.zoomValue : 1}`, layoutZoom: `${params.layoutZoom ? params.layoutZoom : 25.5}`, graphZoom: `${params.graphZoom ? params.graphZoom : 25}` };
    return APIHelper.baseRequest(APIHelper.PUT, `${funnelTemplateRootUrl}/${FunnelTemplate}/${route}/${params.funnel.referenceId}`, {
        headers,
        requestBody: {
            "Title": requestBody.title || "",
            "VisualizerJson": params.funnel.visualizerJson ? `${params.funnel.visualizerJson}` : JSON.stringify(zoomValue),
            "DefaultPageViewReferenceId": requestBody.defaultPageViewReferenceId || "",
            "Page": [
                {
                    "referenceId": "",
                    "title": `${params.cellViewDetail.cellViewDetail.label}`,
                    "cellViewId": `${params.cellViewDetail.cellViewDetail.cellViewId}`,
                    "active": true,
                    "PageView": [
                        {
                            "referenceId": "",
                            "title": `${params.cellViewDetail.cellViewDetail.label}_PageView`,
                            "fTemplateId": "",
                            "active": true,
                        }
                    ]
                }
            ]
        }
    });
}
export function updatePage(params, { headers = {}, requestBody = {} }) {
    return APIHelper.baseRequest(APIHelper.PUT, `${funnelTemplateRootUrl}/FunnelTemplate/ManageFunnelTemplate/${params.funnel.referenceId}`, {
        headers,
        requestBody: {
            "Title": requestBody.title || "",
            "Status": requestBody.status || "",
            "Updated On": requestBody.updatedOn,
            "page": [
                {
                    "referenceId": `${params.currentPage.referenceId}`,
                    "cellViewId": `${params.currentPage.cellViewId}`,
                    "title": `${params.pageTitle}`,
                    "modified": true,
                }
            ],
            "visualizerJson": `${params.funnel.visualizerJson}`
        }
    });
}

export function updateFunnelTemplateDetails(params, { headers = {}, requestBody = {} }) {
    let templateFormData = new FormData();
    let data = { ...requestBody };
    templateFormData.append("active", data.active);
    templateFormData.append("templateType", parseInt(data.fListFunnelTypeId) || 1);
    templateFormData.append("price", parseFloat(data.price) || 0);
    return APIHelper.baseRequest(APIHelper.PUT, `${funnelTemplateRootUrl}/FunnelTemplate/UpdateFunnelTemplateActiveFlag/${params.funnel.referenceId}`, {
        headers,
        requestBody: templateFormData,
        contentType: APIHelper.getMultipartReqHeader(),
    });
}

export function updateFunnelTemplate(params, { headers = {}, requestBody = {} }) {
    const zoomValue = { zoomValue: `${params.zoomValue ? params.zoomValue : 1}`, layoutZoom: `${params.layoutZoom ? params.layoutZoom : 25.5}`, graphZoom: `${params.graphZoom ? params.graphZoom : 25}` };
    const localStorageHelper = new LocalStorageHelper();
    const currentDefaultPageViewReferenceId = params.currentDefaultPageViewReferenceId;
    const tenantId = localStorageHelper.load(LocalStorageHelper.keys.TENANT_ID);
    let requestBodyData = {
        "FTenantId": tenantId,
        "Title": requestBody.title || "",
        "FListFunnelTypeId": requestBody.fListFunnelTypeId || "1",
        "Status": requestBody.status || "",
        "Updated On": requestBody.updatedOn,
        "visualizerJson": JSON.stringify(zoomValue),
        "DefaultPageViewReferenceId": currentDefaultPageViewReferenceId || "",
    };
    params.currentPage && (requestBodyData["page"] = [
        {
            "referenceId": `${params.currentPage.referenceId}`,
            "cellViewId": `${params.currentPage.cellViewId}`,
            "active": true,
            "pageView": [
                {
                    "referenceId": `${params.currentPage.pageView[0].referenceId}`,
                    "title": `${params.currentPage.pageView[0].title}`,
                    "fTemplateId": `${params.currentPage.pageView[0].fTemplateId}`,
                    "active": true,
                }
            ]
        }
    ]);
    return APIHelper.baseRequest(APIHelper.PUT, `${funnelTemplateRootUrl}/FunnelTemplate/ManageFunnelTemplate/${params.funnel.referenceId}`, {
        headers,
        requestBody: requestBodyData
    });
}


export function deletePage(params, { headers = {}, requestBody = {} }) {
    return APIHelper.baseRequest(APIHelper.PUT, `${funnelTemplateRootUrl}/FunnelTemplate/ManageFunnelTemplate/${params.funnel.referenceId}`, {
        headers,
        requestBody: {
            "Title": requestBody.title || "",
            "Status": requestBody.status || "",
            "page": [
                {
                    "referenceId": `${params.pageReferenceId}`,
                    "deleted": true,
                    "active": false,
                    "cellViewId": `${params.currentPage.cellViewId}`,
                    "pageView": [
                        {
                            "referenceId": `${params.currentPage.pageView[0].referenceId}`,
                            "title": "",
                            "fTemplateId": "",
                            "active": false,
                        }
                    ]
                }
            ],
            "visualizerJson": `${params.funnel.visualizerJson}`
        }
    });
}
export function importPage(params, { headers = {}, requestBody = {} }) {
    const localStorageHelper = new LocalStorageHelper();
    const tenantId = localStorageHelper.load(LocalStorageHelper.keys.TENANT_ID);
    return APIHelper.baseRequest(APIHelper.POST, `${importPageUrl}/PageImport/importPage`, {
        headers,
        requestBody: {
            "PageShareId": params.importId || "",
            "CurrentPageFunnelId": params.funnelReferenceId,
            "CurrentPageViewReferenceId": params.currentPageView,
            "TenantId": tenantId,
        }
    });
}

export function getTemplate(params, { headers = {}, requestBody = {} }) {
    const templateUrl = params.selectedTemplate ? params.selectedTemplate.htmlUrl : params.template.htmlUrl;
    let fetchData = {
        method: "GET",
        headers: {
            "Cache-Control": "no-cache",
            "mode": "cors",
            "Access-Control-Allow-Origin": "*"
        },
    };
    return fetch(templateUrl, fetchData).
        then((resp) => {
            return resp.text();
        }).
        then((htmlPage) => {
            return htmlPage;
        });
}
export function getMetaUrl(params, { headers = {}, requestBody = {} }) {
    const templateUrl = params.metaJson;
    return fetch(templateUrl).
        then((resp) => {
            return resp.text();
        });
}

export function getHtmlPage(htmlUrl, { headers = {}, requestBody = {} }) {
    let fetchData = {
        method: "GET",
        headers: {
            "Cache-Control": "no-cache",
            "mode": "cors",
            "Access-Control-Allow-Origin": "*"
        },
    };
    return fetch(htmlUrl, fetchData).
        then((resp) => {
            return resp.text();
        }).
        then((htmlPage) => {
            return htmlPage;
        });
}

const getFetchDataHeaders = () => ({
    method: "GET",
    headers: {
        "Cache-Control": "no-cache",
        "mode": "cors",
        "Access-Control-Allow-Origin": "*"
    },
});

export function getJsonData(params, { headers = {}, requestBody = {} }) {
    const fetchData = getFetchDataHeaders();
    const componentJsonUrl = params.componentJsonUrl;
    const stylesJsonUrl = params.stylesJsonUrl;
    const htmlUrl = params.htmlUrl;
    let setcomponentData;
    let setstylesData;
    let htmlPage;
    if (!params.isEdited) {
        return fetch(htmlUrl, fetchData).
            then((resp) => {
                return resp.text();
            });
    } else {
        return fetch(componentJsonUrl, fetchData).
            then((response) => {
                if (response.status === 200) {
                    return response.text();
                }
            }).
            then((componentData) => {
                if (componentData !== "undefined") {
                    return fetch(stylesJsonUrl, fetchData).
                        then((response) => {
                            if (response.status === 200) {
                                return response.text();
                            }
                        }).
                        then((stylesData) => {
                            if (stylesData !== "undefined") {
                                setcomponentData = componentData;
                                setstylesData = stylesData;
                                return {
                                    setcomponentData,
                                    setstylesData
                                };
                            }

                        });
                } else {
                    return fetch(htmlUrl, fetchData).
                        then((resp) => {
                            return resp.text();
                        }).then((htmldata) => {
                            return htmlPage = htmldata;
                        });
                }
            });
    }
}

export function funnelMetaUrl(metaUrl) {
    let fetchData = {
        method: "GET",
        headers: {
            "Cache-Control": "no-cache",
            "Access-Control-Allow-Origin": "*"
        },
    };
    return fetch(metaUrl, fetchData).
        then((resp) => {
            if (resp.status === 200 && resp.type === "cors") {
                return resp.text();
            }
            return "{}";
        }).
        then((metaJson) => {
            return metaJson;
        });
}

export function importFunnelTemplate(params, { headers = {}, requestBody = {} }) {
    return APIHelper.baseRequest(APIHelper.POST, `${importPageUrl}/FunnelImport/importFunnelTemplate`, {
        headers,
        requestBody: {
            "currentReferenceId": params.referenceId,
            "importReferenceId": params.importId || "",
            "isFunnel ": false,
        }
    });
}

export function getThumbnailForFunnel(funnelVisualizerReferenceId, { headers = {} }) {
    return APIHelper.baseRequest(APIHelper.GET, `${funnelThumbnailUrl}/funnelTemplates?funnelVisualizerReferenceId=${funnelVisualizerReferenceId}`, { headers });
}

export function changeDefaultPageStatusApi(params, { headers = {} }) {
    const {
        pageViewReferenceId,
        funnelReferenceId
    } = params;
    return APIHelper.baseRequest(APIHelper.POST, `${funnelTemplate}/SetDefaultPage/${funnelReferenceId}/${pageViewReferenceId}`,{
        headers,
        requestBody: {}
    });
}