export const numberOnly = (e, isDecimal = true) => {
    let regEx = isDecimal ? /[^0-9.]/g : /[^0-9]/g;
    let number = event.target.value.replace(regEx, "");
    return event.target.value = number;
};

export const modifyLabelText = (labelText) => {
    if (labelText === "Products" || labelText === "Affiliates") {
        return labelText.slice(0, -1);
    }
    return labelText;
};