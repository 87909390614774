import React from "react";
import PropTypes from "prop-types";
import "../index.css";
// import { funnelOfferError, funnelUpsaleError, SelectInput } from "../../../commons";


export class Step1 extends React.Component {
    constructor(context, props) {
        super(context, props);
        this.state = {
            campaign: Object.assign({}, props.campaign)
        };
    }
    render() {
        const { campaign, products, offer, upsell } = this.props;
        let myoffer = offer;
        let myupsales = upsell;
        if (offer && !Array.isArray(offer)) {
            myoffer = JSON.parse(offer);
        }
        if (upsell && !Array.isArray(upsell)) {
            myupsales = JSON.parse(upsell);
        }
        const offerFields = myoffer.map((value, index) =>
            <div className="offerBox" key={index}>
                { index === 0 ?
                    <div className="add_btn"><a onClick={() => this.props.addOfferFieldCampaign()} className="btn btn-primary"><i className="fa fa-plus" /></a></div> :
                    <div className="add_btn"><a onClick={() => this.props.removeOfferFieldCampaign(index)} className="btn btn-danger"><i className="fa fa-close" /></a></div>
                }
                <select className="form-control" value={value ? value.productId : null} onChange={(e) => this.props.onOfferChangeCampaign(e, index)} name="offer" id="">
                    <option value="choose">Choose Offer</option>
                    <option value="">Add New Product</option>
                    {
                        products.map(product => (
                            <option key={product.productId} value={product.productId}>{product.productName}</option>
                        ))
                    }
                </select>
            </div>
        );
        const upsaleFields = myupsales.map((value, index) =>
            <div className="offerBox" key={index}>
                { index === 0 ?
                    <div className="add_btn"><a onClick={() => this.props.addUpsaleFieldCampaign()} className="btn btn-primary"><i className="fa fa-plus" /></a></div> :
                    <div className="add_btn"><a onClick={() => this.props.removeUpsaleFieldCampaign(index)} className="btn btn-danger"><i className="fa fa-close" /></a></div>}
                <select className="form-control" name="upsell" value={value ? value.productId : null} onChange={(e) => this.props.onUpsaleChangeCampaign(e, index)}>
                    <option value="choose">Choose Upsell</option>
                    <option value="">Add New Product</option>
                    {
                        products.map(product => (
                            <option key={product.productId} value={product.productId}>{product.productName}</option>
                        ))
                    }
                </select>
            </div>
        );
        return (
            <div className="ibox">
                <div className="ibox-content">
                    <div>
                        <h2 className="text-center">Create Campaign</h2>
                        <div>
                            <form action="">
                                <div className="form-group">
                                    <label data-toggle="tooltip" data-placement="left" title="Tooltip on left">Campaign Name</label>
                                    <input name="campaignName" value={campaign ? campaign.campaignName : null} onChange={this.props.updateNewCampaignState} className="form-control" type="text"/>
                                </div>
                                <div className="checkbox">
                                    <label>
                                        <input checked={campaign.bundleFulfillment === 1 ? "checked" : null} onChange={this.props.updateNewCampaignState} name="bundleFulfillment" type="checkbox"/>
                                        Bundle Fulfillment
                                    </label>
                                </div>
                                <div className="checkbox">
                                    <label>
                                        <input checked={campaign.blockPrepaid === 1 ? "checked" : null} onChange={this.props.updateNewCampaignState} name="blockPrepaid" type="checkbox"/>
                                        Block Prepaid Card
                                    </label>
                                </div>
                                <div className="checkbox">
                                    <label>
                                        <input checked={campaign.blockChargebackBlacklist === 1 ? "checked" : null} onChange={this.props.updateNewCampaignState} name="blockChargebackBlacklist" type="checkbox"/>
                                        Chargeback Blacklist
                                    </label>
                                </div>
                                <label>Offers</label>
                                {offerFields}
                                <label>Upsells</label>
                                {upsaleFields}
                                <div style={{ marginTop: "2%", float: "right" }}>
                                    <button className="btn btn-primary" onClick={(e) => this.props.validateCampaign(e, this.props.jumpToStep, 1)}>Next</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
Step1.propTypes = {
    addOfferFieldCampaign: PropTypes.func,
    addUpsaleFieldCampaign: PropTypes.func,
    jumpToStep: PropTypes.func,
    upsell: PropTypes.array,
    offer: PropTypes.array,
    products: PropTypes.array,
    onOfferChangeCampaign: PropTypes.func,
    removeOfferFieldCampaign: PropTypes.func,
    removeUpsaleFieldCampaign: PropTypes.func,
    campaign: PropTypes.object,
    updateNewCampaignState: PropTypes.func,
    onUpsaleChangeCampaign: PropTypes.func,
    validateCampaign: PropTypes.func,
    funnel: PropTypes.array
};