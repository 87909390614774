import React, { Component } from "react";
import PropTypes from "prop-types";

export class Step1 extends Component {
    constructor(props) {
        super(props);

        this.state = {
            firstName: props.getStore().firstName,
            lastName: props.getStore().lastName,
            company: props.getStore().company,
            email: props.getStore().email,
        };

        this._validateOnDemand = true; // this flag enables onBlur validation as user fills forms
        this.validationCheck = this.validationCheck.bind(this);
        this.isValidated = this.isValidated.bind(this);
    }

    componentDidMount() {}

    componentWillUnmount() {}

    isValidated() {
        const userInput = this._grabUserInput(); // grab user entered vals
        const validateNewInput = this._validateData(userInput); // run the new input against the validator
        let isDataValid = false;

        // if full validation passes then save to store and pass as valid
        if (Object.keys(validateNewInput).every((k) => {return validateNewInput[k] === true;})) {
            if (this.props.getStore().email !== userInput.email || this.props.getStore().gender !== userInput.gender) { // only update store of something changed
                this.props.updateStore({
                    ...userInput,
                    savedToCloud: false // use this to notify step4 that some changes took place and prompt the user to save again
                }); // Update store here (this is just an example, in reality you will do it via redux or flux)
            }

            isDataValid = true;
        } else {
        // if anything fails then update the UI validation state but NOT the UI Data State
            this.setState(Object.assign(userInput, validateNewInput, this._validationErrors(validateNewInput)));
        }

        return isDataValid;
    }

    validationCheck() {
        if (!this._validateOnDemand)
            return;

        const userInput = this._grabUserInput(); // grab user entered vals
        const validateNewInput = this._validateData(userInput); // run the new input against the validator

        this.setState(Object.assign(userInput, validateNewInput, this._validationErrors(validateNewInput)));
    }

    _validateData(data) {
        return {
            firstNameVal: /^[a-zA-Z[^0-9.!#$%&'*+/=?^_`{|}~-]+/.test(data.firstName), // required: regex w3c uses in html5
            lastNameVal: /^[a-zA-Z[^0-9.!#$%&'*+/=?^_`{|}~-]+/.test(data.lastName), // required: regex w3c uses in html5
            companyVal: /^[a-zA-Z[^0-9.!#$%&'*+/=?^_`{|}~-]+/.test(data.company), // required: regex w3c uses in html5
            emailVal: /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/.test(data.email), // required: regex w3c uses in html5
        };
    }

    _validationErrors(val) {
        const errMsgs = {
            firstNameValMsg: val.firstNameVal ? "" : "Enter your first name",
            lastNameValMsg: val.lastNameVal ? "" : "Enter your last name",
            companyValMsg: val.companyVal ? "" : "Enter company name",
            emailValMsg: val.emailVal ? "" : "Enter valid email"
        };
        return errMsgs;
    }

    _grabUserInput() {
        return {
            firstName: this.refs.firstName.value,
            lastName: this.refs.lastName.value,
            company: this.refs.company.value,
            email: this.refs.email.value
        };
    }

    render() {
        // explicit class assigning based on validation
        let notValidClasses = {};

        if (typeof this.state.firstNameVal === "undefined" || this.state.firstNameVal) {
            notValidClasses.firstNameCls = "no-error col-md-8";
        } else {
            notValidClasses.firstNameCls = "has-error col-md-8";
            notValidClasses.firstNameValGrpCls = "val-err-tooltip";
        }

        if (typeof this.state.lastNameVal === "undefined" || this.state.lastNameVal) {
            notValidClasses.lastNameCls = "no-error col-md-8";
        } else {
            notValidClasses.lastNameCls = "has-error col-md-8";
            notValidClasses.lastNameValGrpCls = "val-err-tooltip";
        }

        if (typeof this.state.companyVal === "undefined" || this.state.companyVal) {
            notValidClasses.companyCls = "no-error col-md-8";
        } else {
            notValidClasses.companyCls = "has-error col-md-8";
            notValidClasses.companyValGrpCls = "val-err-tooltip";
        }

        if (typeof this.state.emailVal === "undefined" || this.state.emailVal) {
            notValidClasses.emailCls = "no-error col-md-8";
        } else {
            notValidClasses.emailCls = "has-error col-md-8";
            notValidClasses.emailValGrpCls = "val-err-tooltip";
        }
        return (
            <div>
                <fieldset>
                    <div className="row">
                        <div className="col-lg-8 col-md-12">
                            <form id="Form" className="form-horizontal">
                                <div className="form-group">
                                    <label className="col-md-12">
                                        <h1>Step 1: Account Information</h1>
                                    </label>
                                </div>
                                <div className="form-group col-md-12 content form-block-holder">
                                    <label className="control-label col-md-2 col-lg-4">
                                    First Name*
                                    </label>
                                    <div className={notValidClasses.firstNameCls}>
                                        <input
                                            ref="firstName"
                                            type="text"
                                            placeholder="john"
                                            className="form-control"
                                            required
                                            defaultValue={this.state.firstName}
                                            onBlur={this.validationCheck} />
                                        <div className={notValidClasses.firstNameValGrpCls}>{this.state.firstNameValMsg}</div>
                                    </div>
                                </div>
                                <div className="form-group col-md-12 content form-block-holder">
                                    <label className="control-label col-md-2 col-lg-4">
                                    Last Name*
                                    </label>
                                    <div className={notValidClasses.lastNameCls}>
                                        <input
                                            ref="lastName"
                                            type="text"
                                            placeholder="smith"
                                            className="form-control"
                                            required
                                            defaultValue={this.state.lastName}
                                            onBlur={this.validationCheck} />
                                        <div className={notValidClasses.lastNameValGrpCls}>{this.state.lastNameValMsg}</div>
                                    </div>
                                </div>
                                <div className="form-group col-md-12 content form-block-holder">
                                    <label className="control-label col-md-2 col-lg-4">
                                        Company*
                                    </label>
                                    <div className={notValidClasses.companyCls}>
                                        <input
                                            ref="company"
                                            type="text"
                                            placeholder="Company name"
                                            className="form-control"
                                            required
                                            defaultValue={this.state.company}
                                            onBlur={this.validationCheck} />
                                        <div className={notValidClasses.companyValGrpCls}>{this.state.companyValMsg}</div>
                                    </div>
                                </div>
                                <div className="form-group col-md-12 content form-block-holder">
                                    <label className="control-label col-md-2 col-lg-4">
                                        Email*
                                    </label>
                                    <div className={notValidClasses.emailCls}>
                                        <input
                                            ref="email"
                                            autoComplete="off"
                                            type="email"
                                            placeholder="john.smith@example.com"
                                            className="form-control"
                                            required
                                            defaultValue={this.state.email}
                                            onBlur={this.validationCheck} />
                                        <div className={notValidClasses.emailValGrpCls}>{this.state.emailValMsg}</div>
                                    </div>
                                </div>
                            </form>
                        </div>
                        <div className="col-lg-4 col-md-12">
                            <div className="text-center">
                                <div style={{ marginTop: "20px" }}>
                                    <i className="fa fa-sign-in" style={{ fontSize: "180px", color: "#e5e5e5" }}/>
                                </div>
                            </div>
                        </div>
                    </div>
                </fieldset>
            </div>
        );
    }
}
Step1.propTypes = {
    updateStore: PropTypes.Object,
    getStore: PropTypes.func,
};