import { customElements } from "../legacy/custom-blocks";
import { getTemplateConfig } from "../../constants/template-types";

const getBackgroundImage = (imageName) => {
    const {
        REACT_APP_S3_ASSETS_BUCKET,
        REACT_APP_AWS_REGION,
    } = process.env;
    return `background-image: url(http://${REACT_APP_S3_ASSETS_BUCKET}.s3.${REACT_APP_AWS_REGION}/snippets/webbuilder-elements/${imageName}); background-repeat: no-repeat;background-position: bottom;background-size: cover;`;
};
const logOutEle = {
    id: "logOutBtn",
    category: "Forms",
    label: "Logout Button",
    attributes: { style: getBackgroundImage("logout-image.png") },
    content: customElements.logOutBtn
};
const customBlocks = {
    lead: [
        {
            id: "leadElementUserDetails",
            category: "Forms",
            label: "Lead Information - Basic",
            attributes: { style: getBackgroundImage("lead-basic.png") },
            content: customElements.leadElementUserDetails
        },
        {
            id: "leadElement1",
            category: "Forms",
            label: "Lead Information - with label",
            attributes: { style: getBackgroundImage("lead-element-1.png") },
            content: customElements.leadElement1
        },
        {
            id: "leadElement2",
            category: "Forms",
            label: "Lead Information - with placeholder",
            attributes: { style: getBackgroundImage("lead-element-2.png") },
            content: customElements.leadElement2
        }
    ],
    checkout: [
        {
            id: "2StepCheckout",
            category: "Forms",
            label: "Two step checkout",
            attributes: { style: getBackgroundImage("twostepcheckout.png") },
            content: customElements.twoStepCheckout
        },
        {
            id: "checkoutForm",
            category: "Forms",
            label: "Checkout Form",
            attributes: { style: getBackgroundImage("checkoutfull.png") },
            content: customElements.fullCheckoutElement
        },
        {
            id: "onlyPaymentElement",
            category: "Forms",
            label: "Payment Details - Card details",
            attributes: { style: getBackgroundImage("payment-basic.png") },
            content: customElements.onlyPaymentElement
        },
        {
            id: "paymentElement",
            category: "Forms",
            label: "Payment Details - with address",
            attributes: { style: getBackgroundImage("payment-checkout.png") },
            content: customElements.paymentElement
        },
        {
            id: "shopifyElement",
            category: "Forms",
            label: "Shopify Checkout",
            attributes: { style: getBackgroundImage("shopify-checkout.png") },
            content: customElements.shopifyElement
        },
        {
            id: "multiProductVariableImages",
            category: "Products",
            label: "Multiproduct Checkout",
            attributes: { style: getBackgroundImage("product-image.png") },
            content: customElements.multiProductVariableImages
        },
        {
            id: "totalAmmountCalc",
            category: "Forms",
            label: "Total Price Calculation",
            attributes: { style: getBackgroundImage("total-price-calc.png") },
            content: customElements.totalAmmountCalc
        },
        {
            id: "shippingPriceCalc",
            category: "Forms",
            label: "Shipping Price Calculation",
            attributes: { style: getBackgroundImage("shipping-price.png") },
            content: customElements.shippingPriceCalc
        },
    ],
    thankyou: [
        {
            id: "orderCostDetails",
            category: "Forms",
            label: "Invoice",
            attributes: { style: getBackgroundImage("invoice.png") },
            content: customElements.orderCostDetails
        },
        {
            id: "billingDetails",
            category: "Forms",
            label: "Billing Information",
            attributes: { style: getBackgroundImage("billing-information.png") },
            content: customElements.billingDetails
        },
        {
            id: "shippingDetails",
            category: "Forms",
            label: "Shipping Information",
            attributes: { style: getBackgroundImage("shipping-info") },
            content: customElements.shippingDetails
        }
    ],
    login: [
        {
            id: "memberLoginForm",
            category: "Forms",
            label: "Member Login",
            attributes: { style: getBackgroundImage("member-login.png") },
            content: customElements.memberLoginForm
        },
        {
            id: "resetPassword",
            category: "Forms",
            label: "Reset Password",
            attributes: { style: getBackgroundImage("reset-password.png") },
            content: customElements.resetPassword
        }
    ],
    profile: [
        {
            id: "memberProfile",
            category: "Forms",
            label: "Member Profile",
            attributes: { style: getBackgroundImage("member-profile.png") },
            content: customElements.memberProfile
        },
        {
            id: "passwordElement",
            category: "Forms",
            label: "Update Password",
            attributes: { style: getBackgroundImage("update-password.png") },
            content: customElements.passwordElement
        }
    ],
    changeproduct: [
        {
            id: "multiProductVariableImages",
            category: "Products",
            label: "Multiproduct Checkout",
            attributes: { style: getBackgroundImage("product-image.png") },
            content: customElements.multiProductVariableImages
        },
        logOutEle,
    ],
    memberarea: [
        {
            id: "previewURL",
            category: "Forms",
            label: "Preview URL",
            attributes: { style: getBackgroundImage("preview-url.png") },
            content: customElements.previewURL
        },
        {
            id: "downloadURL",
            category: "Forms",
            label: "Download URL",
            attributes: { style: getBackgroundImage("download-url.png") },
            content: customElements.downloadURL
        },
    ],
    cancelmembership: [
        logOutEle
    ],
    restartmembership: [
        logOutEle
    ],
    updatecard: [
        logOutEle
    ],
};

export const getCustomBlocks = (templateTypeId) => {
    const templateConfig = getTemplateConfig(templateTypeId);
    if (templateConfig) {
        return customBlocks[templateConfig.name];
    }
    return [];
};
