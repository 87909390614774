/* eslint-disable */
export default (editor, config) => {
    const bm = editor.BlockManager;
    const toAdd = name => config.blocks.indexOf(name) >= 0;
    toAdd('section') && bm.add('section', {
        category: "Basic",
        label: "Section",
        attributes: { class: "gjs-fonts gjs-f-b1" },
        content: `<style>
.container {
    margin: 0 auto;
    padding: 25px 0;
}
.fk-section {
    padding: 50px 0;
}
</style>
<section data-gjs-type="section" class="fk-section">
<div class="container" data-gjs-type="container">
    <div class="fk-cell" data-gjs-resizable="true">
    
</div>
</div>
</section>`
    });
    toAdd('footer') && bm.add('footer', {
        category: 'Section',
        label: 'Footer',
        attributes: { class: 'fa fa-desktop' },
        content: `
<style>
section {
    padding: 60px 0;
}

section .section-title {
    text-align: center;
    color: #007b5e;
    margin-bottom: 50px;
    text-transform: uppercase;
}
#footer {
    background: #007b5e !important;
}
#footer h5{
	padding-left: 10px;
    border-left: 3px solid #eeeeee;
    padding-bottom: 6px;
    margin-bottom: 20px;
    color:#ffffff;
}
#footer a {
    color: #ffffff;
    text-decoration: none !important;
    background-color: transparent;
    -webkit-text-decoration-skip: objects;
}
#footer ul.social li{
	padding: 3px 0;
}
#footer ul.social li a i {
    margin-right: 5px;
	font-size:25px;
	-webkit-transition: .5s all ease;
	-moz-transition: .5s all ease;
	transition: .5s all ease;
}
#footer ul.social li:hover a i {
	font-size:30px;
	margin-top:-10px;
}
#footer ul.social li a,
#footer ul.quick-links li a{
	color:#ffffff;
}
#footer ul.social li a:hover{
	color:#eeeeee;
}
#footer ul.quick-links li{
	padding: 3px 0;
	-webkit-transition: .5s all ease;
	-moz-transition: .5s all ease;
	transition: .5s all ease;
}
#footer ul.quick-links li:hover{
	padding: 3px 0;
	margin-left:5px;
	font-weight:700;
}
#footer ul.quick-links li a i{
	margin-right: 5px;
}
#footer ul.quick-links li:hover a i {
    font-weight: 700;
}

@media (max-width:767px){
	#footer h5 {
    padding-left: 0;
    border-left: transparent;
    padding-bottom: 0px;
    margin-bottom: 10px;
}
}

</style>

<!-- Footer -->
	<section id="footer">
		<div class="container">
			<div class="row text-center text-xs-center text-sm-left text-md-left">
				<div class="col-xs-12 col-sm-4 col-md-4">
					<h5>Quick links</h5>
					<ul class="list-unstyled quick-links">
						<li><a href="javascript:void();"><i class="fa fa-angle-double-right"></i>Home</a></li>
						<li><a href="javascript:void();"><i class="fa fa-angle-double-right"></i>About</a></li>
						<li><a href="javascript:void();"><i class="fa fa-angle-double-right"></i>FAQ</a></li>
						<li><a href="javascript:void();"><i class="fa fa-angle-double-right"></i>Get Started</a></li>
						<li><a href="javascript:void();"><i class="fa fa-angle-double-right"></i>Videos</a></li>
					</ul>
				</div>
				<div class="col-xs-12 col-sm-4 col-md-4">
					<h5>Quick links</h5>
					<ul class="list-unstyled quick-links">
						<li><a href="javascript:void();"><i class="fa fa-angle-double-right"></i>Home</a></li>
						<li><a href="javascript:void();"><i class="fa fa-angle-double-right"></i>About</a></li>
						<li><a href="javascript:void();"><i class="fa fa-angle-double-right"></i>FAQ</a></li>
						<li><a href="javascript:void();"><i class="fa fa-angle-double-right"></i>Get Started</a></li>
						<li><a href="javascript:void();"><i class="fa fa-angle-double-right"></i>Videos</a></li>
					</ul>
				</div>
				<div class="col-xs-12 col-sm-4 col-md-4">
					<h5>Quick links</h5>
					<ul class="list-unstyled quick-links">
						<li><a href="javascript:void();"><i class="fa fa-angle-double-right"></i>Home</a></li>
						<li><a href="javascript:void();"><i class="fa fa-angle-double-right"></i>About</a></li>
						<li><a href="javascript:void();"><i class="fa fa-angle-double-right"></i>FAQ</a></li>
						<li><a href="javascript:void();"><i class="fa fa-angle-double-right"></i>Get Started</a></li>
						<li><a href="https://www.funnelkonnekt.com" title="Design and developed by"><i class="fa fa-angle-double-right"></i>FunnelKonnekt</a></li>
					</ul>
				</div>
			</div>
			<div class="row">
				<div class="col-xs-12 col-sm-12 col-md-12 mt-2 mt-sm-5">
					<ul class="list-unstyled list-inline social text-center">
						<li class="list-inline-item"><a href="javascript:void();"><i class="fa fa-facebook"></i></a></li>
						<li class="list-inline-item"><a href="javascript:void();"><i class="fa fa-twitter"></i></a></li>
						<li class="list-inline-item"><a href="javascript:void();"><i class="fa fa-instagram"></i></a></li>
						<li class="list-inline-item"><a href="javascript:void();"><i class="fa fa-google-plus"></i></a></li>
						<li class="list-inline-item"><a href="javascript:void();" target="_blank"><i class="fa fa-envelope"></i></a></li>
					</ul>
				</div>
				</hr>
			</div>	
			<div class="row">
				<div class="col-xs-12 col-sm-12 col-md-12 mt-2 mt-sm-2 text-center text-white">
					<p><u><a href="https://www.nationaltransaction.com/">National Transaction Corporation</a></u> is a Registered MSP/ISO of Elavon, Inc. Georgia [a wholly owned subsidiary of U.S. Bancorp, Minneapolis, MN]</p>
					<p class="h6">&copy All right Reversed.<a class="text-green ml-2" href="https://www.funnelkonnekt.com" target="_blank">FunnelKonnekt</a></p>
				</div>
				</hr>
			</div>	
		</div>
	</section>`
    });

    toAdd('b1') && bm.add('b1', {
        category: 'Grid',
        label: '1 block',
        attributes: { class: 'fa fa-reorder' },
        content: `<div class="row" data-gjs-droppable=".cell" data-gjs-custom-name="Row">
                <div class="col col-md-12" data-gjs-draggable=".row" data-gjs-custom-name="col12"></div>
              </div><style>.col:empty{display:table-cell;height:75px}</style>`
    });
    toAdd('b2') && bm.add('b2', {
        category: 'Grid',
        label: '2 block',
        attributes: { class: 'fa fa-th-large' },
        content: `<div class="row" data-gjs-droppable=".cell" data-gjs-custom-name="Row">
                <div class="col col-sm-6" data-gjs-draggable=".row" data-gjs-custom-name="sm6"></div>
                <div class="col col-sm-6" data-gjs-draggable=".row" data-gjs-custom-name="sm6"></div>
              </div><style>.col:empty{display:table-cell;height:75px}</style>`
    });
    toAdd('b3') && bm.add('b3', {
        category: 'Grid',
        label: '3 block',
        attributes: { class: 'fa fa-th' },
        content: '<div class="row" data-gjs-droppable=".cell" data-gjs-custom-name="Row">'
            + '<div class="col col-sm-4" data-gjs-draggable=".row" data-gjs-custom-name="sm4"></div>'
            + '<div class="col col-sm-4" data-gjs-draggable=".row" data-gjs-custom-name="sm4"></div>'
            + '<div class="col col-sm-4" data-gjs-draggable=".row" data-gjs-custom-name="sm4"></div>'
            + '</div><style>.col:empty{display:table-cell;height:75px}</style>',
    });
    toAdd('b4') && bm.add('b4', {
        category: 'Grid',
        label: '4 blocks',
        attributes: { class: 'fa fa-calendar' },
        content: '<div class="row" data-gjs-droppable=".cell" data-gjs-custom-name="Row">'
            + '<div class="col col-sm-3" data-gjs-draggable=".row" data-gjs-custom-name="sm3"></div>'
            + '<div class="col col-sm-3" data-gjs-draggable=".row" data-gjs-custom-name="sm3"></div>'
            + '<div class="col col-sm-3" data-gjs-draggable=".row" data-gjs-custom-name="sm3"></div>'
            + '<div class="col col-sm-3" data-gjs-draggable=".row" data-gjs-custom-name="sm3"></div>'
            + '</div><style>.col:empty{display:table-cell;height:75px}</style>',
    });
    toAdd('b5') && bm.add('b5', {
        category: 'Grid',
        label: '4/8 block',
        attributes: { class: 'fa fa-th-list' },
        content: '<div class="row" data-gjs-droppable=".cell" data-gjs-custom-name="Row">'
            + '<div class="col col-sm-4" data-gjs-draggable=".row" data-gjs-custom-name="sm4"></div>'
            + '<div class="col col-sm-8" data-gjs-draggable=".row" data-gjs-custom-name="sm8"></div>'
            + '</div><style>.col:empty{display:table-cell;height:75px}</style>',
    });
    toAdd('b6') && bm.add('b6', {
        category: 'Grid',
        label: '5/7 Block',
        attributes: { class: 'fa fa-dedent' },
        content: '<div class="row" data-gjs-droppable=".cell" data-gjs-custom-name="Row">'
            + '<div class="col col-sm-5" data-gjs-draggable=".row" data-gjs-custom-name="m5"></div>'
            + '<div class="col col-sm-7" data-gjs-draggable=".row" data-gjs-custom-name="m7"></div>'
            + '</div><style>.col:empty{display:table-cell;height:75px}</style>',
    });
    toAdd('b7') && bm.add('b7', {
        category: 'Grid',
        label: '3/9 Block',
        attributes: { class: 'fa fa-list' },
        content: '<div class="row" data-gjs-droppable=".cell" data-gjs-custom-name="Row">'
            + '<div class="col col-sm-3" data-gjs-draggable=".row" data-gjs-custom-name="m3"></div>'
            + '<div class="col col-sm-9" data-gjs-draggable=".row" data-gjs-custom-name="m9"></div>'
            + '</div><style>.col:empty{display:table-cell;height:75px}</style>',
    });
    toAdd('b8') && bm.add('b8', {
        category: 'Grid',
        label: 'Center Block',
        attributes: { class: 'fa  fa-minus-square-o' },
        content: '<div class="row" data-gjs-droppable=".cell" data-gjs-custom-name="Row">'
            + '<div class="center-box" data-gjs-draggable=".row"></div>'
            + '</div><style>.center-box {margin: 0 auto;max-width: 500px;}.center-box:empty {height: 75px;}</style>',
    });

    toAdd('list2') && bm.add('list2', {
        label: 'List Two',
        category: 'Basic',
        attributes: {
            class: 'fa fa-bars'
        },
        content: '<ul class="collection">'
            + '<li class="collection-item avatar">'
            + '<img src="https://randomuser.me/api/portraits/women/83.jpg" alt="" class="circle">'
            + '<span class="title">Title</span>'
            + '<p>First Line <br> Second Line </p>'
            + '<a href="#!" class="secondary-content"><i class="material-icons">grade</i></a>'
            + '</li>'
            + '<li class="collection-item avatar">'
            + '<i class="material-icons circle">folder</i>'
            + '<span class="title">Title</span>'
            + '<p>First Line <br>Second Line</p>'
            + '<a href="#!" class="secondary-content"><i class="material-icons">grade</i></a>'
            + '</li>'
            + '<li class="collection-item avatar">'
            + '<i class="material-icons circle green">insert_chart</i>'
            + '<span class="title">Title</span>'
            + '<p>First Line <br>Second Line</p>'
            + '<a href="#!" class="secondary-content"><i class="material-icons">grade</i></a>'
            + '</li>'
            + '<li class="collection-item avatar">'
            + '<i class="material-icons circle red">play_arrow</i>'
            + '<span class="title">Title</span>'
            + '<p>First Line <br>Second Line</p>'
            + '<a href="#!" class="secondary-content"><i class="material-icons">grade</i></a>'
            + '</li>'
            + '</ul>',
    });

    toAdd('table') && bm.add('table', {
        label: 'Table',
        category: 'Basic',
        attributes: {
            class: 'fa fa-table'
        },
        content: '<table class="responsive-table centered striped highlight bordered">'
            + '<thead>'
            + '<tr>'
            + '<th>Name</th>'
            + '<th>Item Name</th>'
            + '<th>Item Price</th>'
            + '</tr>'
            + '</thead>'
            + '<tbody>'
            + '<tr>'
            + '<td>Alvin</td>'
            + '<td>Eclair</td>'
            + '<td>$0.87</td>'
            + '</tr>'
            + '<tr>'
            + '<td>Alan</td>'
            + '<td>Jellybean</td>'
            + '<td>$3.76</td>'
            + '</tr>'
            + '<tr>'
            + '<td>Jonathan</td>'
            + '<td>Lollipop</td>'
            + '<td>$7.00</td>'
            + '</tr>'
            + '</tbody>'
            + '</table>',
    });


    toAdd('dropdown') && bm.add('dropdown', {
        label: 'dropdown',
        category: 'Button',
        content: '<div class="dropdown">\n' +
            '  <button class="btn btn-default dropdown-toggle" type="button" id="dropdownMenu1" data-toggle="dropdown" aria-haspopup="true" aria-expanded="true">\n' +
            '    Dropdown\n' +
            '    <span class="caret"></span>\n' +
            '  </button>\n' +
            '  <ul class="dropdown-menu" aria-labelledby="dropdownMenu1">\n' +
            '    <li><a href="#">Action</a></li>\n' +
            '    <li><a href="#">Another action</a></li>\n' +
            '    <li><a href="#">Something else here</a></li>\n' +
            '    <li role="separator" class="divider"></li>\n' +
            '    <li><a href="#">Separated link</a></li>\n' +
            '  </ul>\n' +
            '</div>',
        attributes: {
            class: 'fa fa-list'
        }
    });
    toAdd('modal') && bm.add('modal', {
        label: 'modal',
        category: 'Button',
        content: '<button type="button" class="btn btn-primary" data-toggle="modal" data-target="#exampleModal">\n' +
            '  Launch demo modal\n' +
            '</button>\n' +
            '\n' +
            '<!-- Modal -->\n' +
            '<div class="modal fade" id="exampleModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">\n' +
            '  <div class="modal-dialog" role="document">\n' +
            '    <div class="modal-content">\n' +
            '      <div class="modal-header">\n' +
            '        <h5 class="modal-title" id="exampleModalLabel">Modal title</h5>\n' +
            '        <button type="button" class="close" data-dismiss="modal" aria-label="Close">\n' +
            '          <span aria-hidden="true">&times;</span>\n' +
            '        </button>\n' +
            '      </div>\n' +
            '      <div class="modal-body">\n' +
            '        ...\n' +
            '      </div>\n' +
            '      <div class="modal-footer">\n' +
            '        <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>\n' +
            '        <button type="button" class="btn btn-primary">Save changes</button>\n' +
            '      </div>\n' +
            '    </div>\n' +
            '  </div>\n' +
            '</div>',
        attributes: {
            class: 'fa fa-object-ungroup'
        }
    });
    toAdd('slider') && bm.add('slider', {
        label: 'Slider',
        category: 'Section',
        content: '<div id="carouselExampleIndicators" class="carousel slide" data-ride="carousel">\n' +
            '  <ol class="carousel-indicators">\n' +
            '    <li data-target="#carouselExampleIndicators" data-slide-to="0" class="active"></li>\n' +
            '    <li data-target="#carouselExampleIndicators" data-slide-to="1"></li>\n' +
            '    <li data-target="#carouselExampleIndicators" data-slide-to="2"></li>\n' +
            '  </ol>\n' +
            '  <div class="carousel-inner">\n' +
            '    <div class="carousel-item active">\n' +
            '      <img class="d-block w-100" src="https://s3.us-east-2.amazonaws.com/konnektfunnels-assets/Templates/demo/ara-3601194_1920.jpg" alt="First slide">\n' +
            '    </div>\n' +
            '    <div class="carousel-item">\n' +
            '      <img class="d-block w-100" src="https://s3.us-east-2.amazonaws.com/konnektfunnels-assets/Templates/demo/berlin-cathedral-3592874_1920.jpg" alt="Second slide">\n' +
            '    </div>\n' +
            '    <div class="carousel-item">\n' +
            '      <img class="d-block w-100" src="https://s3.us-east-2.amazonaws.com/konnektfunnels-assets/Templates/demo/people-2557396_1920.jpg" alt="Third slide">\n' +
            '    </div>\n' +
            '  </div>\n' +
            '  <a class="carousel-control-prev" href="#carouselExampleIndicators" role="button" data-slide="prev">\n' +
            '    <span class="carousel-control-prev-icon" aria-hidden="true"></span>\n' +
            '    <span class="sr-only">Previous</span>\n' +
            '  </a>\n' +
            '  <a class="carousel-control-next" href="#carouselExampleIndicators" role="button" data-slide="next">\n' +
            '    <span class="carousel-control-next-icon" aria-hidden="true"></span>\n' +
            '    <span class="sr-only">Next</span>\n' +
            '  </a>\n' +
            '</div>',
        attributes: {
            class: 'fa fa-credit-card'
        }
    });

    toAdd('formtag') && bm.add('formtag', {
        label: 'Form Tag',
        category: 'Forms',
        attributes: {
            class: 'fa fa-file-text'
        },
        content: '<form id="myForm" action="#" method="POST">' +
            '<div class="row" data-gjs-droppable=".cell" data-gjs-custom-name="Row">' +
            '<div class="col col-sm-12" data-gjs-draggable=".row" data-gjs-custom-name="col12"></div></div>' +
            '</form>' +
            '<style>#myForm .input-field [type=checkbox]+label,#myForm .input-field [type=radio]:checked+label,#myForm .input-field [type=radio]:not(:checked)+label{pointer-events:auto}input:not([type]):focus:not([readonly]),input[type=date]:not(.browser-default):focus:not([readonly]),#myForm input[type=datetime-local]:not(.browser-default):focus:not([readonly]),#myForm input[type=datetime]:not(.browser-default):focus:not([readonly]),#myForm input[type=email]:not(.browser-default):focus:not([readonly]),#myForm input[type=number]:not(.browser-default):focus:not([readonly]),#myForm input[type=password]:not(.browser-default):focus:not([readonly]),#myForm input[type=search]:not(.browser-default):focus:not([readonly]),#myForm input[type=tel]:not(.browser-default):focus:not([readonly]),#myForm input[type=text]:not(.browser-default):focus:not([readonly]),#myForm input[type=time]:not(.browser-default):focus:not([readonly]),#myForm input[type=url]:not(.browser-default):focus:not([readonly]),#myForm textarea.materialize-textarea:focus:not([readonly]){border-bottom:1px solid orange;-webkit-box-shadow:0 1px 0 0 orange;-moz-box-shadow:0 1px 0 0 orange;box-shadow:0 1px 0 0 orange}#myForm .col:empty,.col:empty{display:table-cell;height:75px}#myForm .col:empty{position:relative}#myForm .col:empty:after{content:"Add form elements here";color:#888;position:absolute;left:10px;top:10px;display:block;width:400px}</style>',
    });

    toAdd('iconinput') && bm.add('iconinput', {
        label: 'Input',
        category: 'Forms',
        attributes: {
            class: 'fa fa-minus-square'
        },
        content: '<div class="row">'
            + '<div class="input-field col col-sm-6">'
            + '<i class="fa fa-user-circle"/>'
            + '<input name="firstName" id="firstName" type="text" class="validate form-control">'
            + '<label for="firstName">First Name</label>'
            + '</div>'
            + '<div class="input-field col col-sm-6">'
            + '<i class="fa fa-phone"/>'
            + '<input name="phoneNumber" id="phoneNumber" type="tel" class="validate form-control">'
            + '<label for="phoneNumber">Telephone</label>'
            + '</div>'
            + '</div>',
    });
    toAdd('textarea') && bm.add('textarea', {
        label: 'Textarea',
        category: 'Forms',
        attributes: {
            class: 'fa fa-sticky-note-o'
        },
        content: '<div class="row">'
            + '<div class="input-field col col-sm-12">'
            + '<textarea id="textarea1" class="form-control"></textarea>'
            + '<label for="textarea1">Textarea</label>'
            + '</div>'
            + '</div>',
    });
    toAdd('select') && bm.add('select', {
        label: 'Select',
        category: 'Forms',
        attributes: {
            class: 'fa fa-caret-square-o-down'
        },
        content: '<div class="input-field col col-sm-12">'
            + '<select class="form-control">'
            + '<option value="" disabled selected>Choose your option</option>'
            + '<option value="1">Option 1</option>'
            + '<option value="2">Option 2</option>'
            + '<option value="3">Option 3</option>'
            + '</select>'
            + '<label>Select</label>'
            + '</div>'
    });
    toAdd('radio') && bm.add('radio', {
        label: 'Radio',
        category: 'Forms',
        attributes: {
            class: 'fa fa-dot-circle-o'
        },
        content: `<div class="row">
        <div class="input-field col col-sm-12">
    <div class="radio">
      <input name="group1" type="radio" id="test1"/>
      <label for="test1">Red</label>
    </div>
    <div class="radio">
      <input name="group1" type="radio" id="test2"/>
      <label for="test2">Yellow</label>
    </div>
    <div class="radio">
      <input class="with-gap" name="group1" type="radio" id="test3" />
      <label for="test3">Green</label>
    </div>
    <div class="radio">
      <input name="group1" type="radio" id="test4" disabled="disabled"/>
      <label for="test4">Brown</label>
    </div>
    </div>
    </div>`
    });
    toAdd('checkbox') && bm.add('checkbox', {
        label: 'Checkbox',
        category: 'Forms',
        attributes: {
            class: 'fa fa-check-square-o'
        },
        content: `<div class="row">
        <div class="input-field col col-sm-12">
    <div>
      <input class="checkbox-inline" type="checkbox" id="test5" />
      <label for="test5">Red</label>
    </div>
    <div class="checkbox-inline">
      <input type="checkbox" id="test6" checked="checked" />
      <label for="test6">Yellow</label>
    </div>
    </div>
    </div>`
    });
    toAdd('sidenav') && bm.add('sidenav', {
        label: 'SideNav',
        category: 'Extra',
        content: `<style>

.sidenav {
    height: 100%;
    width: 0;
    position: fixed;
    z-index: 1;
    top: 0;
    left: 0;
    background-color: #111;
    overflow-x: hidden;
    transition: 0.5s;
    padding-top: 60px;
}

.sidenav a {
    padding: 8px 8px 8px 32px;
    text-decoration: none;
    font-size: 25px;
    color: #818181;
    display: block;
    transition: 0.3s;
}

.sidenav a:hover {
    color: #f1f1f1;
}

.sidenav .closebtn {
    position: absolute;
    top: 0;
    right: 25px;
    font-size: 36px;
    margin-left: 50px;
}

@media screen and (max-height: 450px) {
  .sidenav {padding-top: 15px;}
  .sidenav a {font-size: 18px;}
}
</style>
</head>
<body>

<div id="mySidenav" class="sidenav">
  <a href="javascript:void(0)" class="closebtn" onclick="closeNav()">&times;</a>
  <a href="#">About</a>
  <a href="#">Services</a>
  <a href="#">Clients</a>
  <a href="#">Contact</a>
</div>

<h2>Animated Sidenav Example</h2>
<p>Click on the element below to open the side navigation menu.</p>
<span style="font-size:30px;cursor:pointer" onclick="openNav()">&#9776; open</span>

<script>
function openNav() {
    document.getElementById("mySidenav").style.width = "250px";
}

function closeNav() {
    document.getElementById("mySidenav").style.width = "0";
}
</script>`,
        attributes: {
            class: 'fa fa-indent'
        }
    });

    toAdd('accor') && bm.add('accor', {
        label: 'Accordian',
        category: 'Extra',
        attributes: {
            class: 'fa fa-reorder'
        },
        content: `
<div>
<style>
.accordion {
    background-color: #eee;
    color: #444;
    cursor: pointer;
    padding: 18px;
    width: 100%;
    border: none;
    text-align: left;
    outline: none;
    font-size: 15px;
    transition: 0.4s;
}

.active, .accordion:hover {
    background-color: #ccc; 
}

.panel {
    padding: 0 18px;
    display: none;
    background-color: white;
    overflow: hidden;
}
</style>
</head>
<div>

<h2>Accordion</h2>

<button class="accordion">Section 1</button>
<div class="panel">
  <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.</p>
</div>

<button class="accordion">Section 2</button>
<div class="panel">
  <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.</p>
</div>

<button class="accordion">Section 3</button>
<div class="panel">
  <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.</p>
</div>

<script>
var acc = document.getElementsByClassName("accordion");
var i;

for (i = 0; i < acc.length; i++) {
    acc[i].addEventListener("click", function() {
        this.classList.toggle("active");
        var panel = this.nextElementSibling;
        if (panel.style.display === "block") {
            panel.style.display = "none";
        } else {
            panel.style.display = "block";
        }
    });
}
</script>
</div>`,
    });

    toAdd('tabs') && bm.add('tabs', {
        label: 'Tabs',
        category: 'Extra',
        attributes: {
            class: 'fa fa-ellipsis-h'
        },
        content: `
<div>
<style>
body {font-family: Arial;}

/* Style the tab */
.tab {
    overflow: hidden;
    border: 1px solid #ccc;
    background-color: #f1f1f1;
}

/* Style the buttons inside the tab */
.tab button {
    background-color: inherit;
    float: left;
    border: none;
    outline: none;
    cursor: pointer;
    padding: 14px 16px;
    transition: 0.3s;
    font-size: 17px;
}

/* Change background color of buttons on hover */
.tab button:hover {
    background-color: #ddd;
}

/* Create an active/current tablink class */
.tab button.active {
    background-color: #ccc;
}

/* Style the tab content */
.tabcontent {
    display: none;
    padding: 6px 12px;
    border: 1px solid #ccc;
    border-top: none;
}
</style>
</head>
<div>

<h2>Tabs</h2>
<p>Click on the buttons inside the tabbed menu:</p>

<div class="tab">
  <span class="btn tablinks" onclick="openCity(event, 'London')">London</span>
  <span class="btn tablinks" onclick="openCity(event, 'Paris')">Paris</span>
  <span class="btn tablinks" onclick="openCity(event, 'Tokyo')">Tokyo</span>
</div>

<div id="London" class="tabcontent">
  <h3>London</h3>
  <p>London is the capital city of England.</p>
</div>

<div id="Paris" class="tabcontent">
  <h3>Paris</h3>
  <p>Paris is the capital of France.</p> 
</div>

<div id="Tokyo" class="tabcontent">
  <h3>Tokyo</h3>
  <p>Tokyo is the capital of Japan.</p>
</div>

<script>
function openCity(evt, cityName) {
    var i, tabcontent, tablinks;
    tabcontent = document.getElementsByClassName("tabcontent");
    for (i = 0; i < tabcontent.length; i++) {
        tabcontent[i].style.display = "none";
    }
    tablinks = document.getElementsByClassName("tablinks");
    for (i = 0; i < tablinks.length; i++) {
        tablinks[i].className = tablinks[i].className.replace(" active", "");
    }
    document.getElementById(cityName).style.display = "block";
    evt.currentTarget.className += " active";
}
</script></div>`,
    });



    //start of basic blocks

    toAdd('link-block') && bm.add('link-block', {
        category: 'Basic',
        label: 'Link Block',
        attributes: { class: 'fa fa-link' },
        resizable: true,
        content: {
            type: 'link-block',
            editable: false,
            droppable: true,
            style: {
                display: 'inline-block',
                padding: '5px',
                'min-height': '50px',
                'min-width': '50px'
            },
            components: [{
                type: "image",
                tagName: "img",
                style: { width: "40px", height: "40px" },
                removable: true,
                copyable: false,
            }]
        }
    });

    toAdd('cProducts') && bm.add('cProducts', {
        category: 'Basic',
        label: 'Products',
        attributes: { class: 'fa fa-product-hunt' },
        content: `<div class="col-md-3" >
            <img class="img-responsive" src="http://via.placeholder.com/250x250">
            <div>
                <h3 class="text-center">Product Name</h3>
                <button class="btn btn-primary">SELECT</button>
            </div>
      </div>`
    });
    toAdd('lead-form') && bm.add('lead-form', {
        label: 'Lead Form',
        category: 'Forms',
        attributes: { class: 'fa fa-wpforms' },
        resizable: true,
        content: `
<div class="row">
    <div class="col-sm-4">
      <form>
          <div class="form-group">
             <label for="email">Email address</label>
             <input type="email" class="form-control" id="email" placeholder="Enter email">
             <small id="emailHelp" class="form-text text-muted">We'll never share your email with anyone else.</small>
           </div>
           <div class="form-group">
             <label for="firstName">First Name</label>
             <input type="text" class="form-control" id="firstName" placeholder="Enter First Name">
           </div>
           <div class="form-group">
             <label for="lastName">Last Name</label>
             <input type="text" class="form-control" id="lastName" placeholder="Enter Last Name">
           </div>
           <div class="form-group">
             <label for="address1">Street Address</label>
             <input type="text" class="form-control" id="address1" placeholder="Enter Street Address">
           </div>
           <div class="form-group">
             <label for="address2">Street Address 2</label>
             <input type="text" class="form-control" id="address2" placeholder="Enter Street Address 2">
           </div>
           <div class="form-group">
             <label for="city">City</label>
             <input type="text" class="form-control" id="city" placeholder="Enter City">
           </div>
           <div class="form-group">
             <label for="state">State</label>
             <input type="text" class="form-control" id="state" placeholder="Enter State">
           </div>
           <div class="form-group">
             <label for="postalCode">Postal Code</label>
             <input type="text" class="form-control" id="postalCode" placeholder="Enter Postal Code">
           </div>
      </form>
    </div>
</div>`
    });
    toAdd('payment-form') && bm.add('payment-form', {
        label: 'Payment Form',
        category: 'Forms',
        attributes: { class: 'fa fa-credit-card' },
        content: `
<div class="container">
  <div class="row">
    <div class="span12">
      <form class="form-horizontal span6">
        <fieldset>
          <legend>Payment</legend>
       
          <div class="control-group">
            <label class="control-label">Card First Name</label>
            <div class="controls">
              <input name="firstName" type="text" class="input-block-level" pattern="\\w+ \\w+.*" title="Fill your first name" required>
            </div>
          </div>
          
          <div class="control-group">
            <label class="control-label">Card Last Name</label>
            <div class="controls">
              <input name="lastName" type="text" class="input-block-level" pattern="\\w+ \\w+.*" title="Fill your last name" required>
            </div>
          </div>
       
          <div class="control-group">
            <label class="control-label">Card Number</label>
            <div class="controls">
              <div class="row-fluid">
                <div>
                  <input name="cardNumber" type="text" class="input-block-level" autocomplete="off" maxlength="16" pattern="\\d{16}" title="Card Number" required>
                </div>
              </div>
            </div>
          </div>
          <div>
            <label>Card Expiry Date</label>
            <div>
              <div class="row">
            
                  <select>
                    <option>January</option>
                    <option>February</option>
                    <option>March</option>
                    <option>April</option>
                    <option>May</option>
                    <option>June</option>
                    <option>July</option>
                    <option>August</option>
                    <option>September</option>
                    <option>October</option>
                    <option>November</option>
                    <option>December</option>
                  </select>

                  <select>
                    <option>2018</option>
                    <option>2019</option>
                    <option>2020</option>
                    <option>2021</option>
                    <option>2022</option>
                    <option>2023</option>
                    <option>2024</option>
                  </select>
                
              </div>
            </div>
          </div>
       
          <div class="control-group">
            <label class="control-label">Card CVV</label>
            <div class="controls">
              <div class="row-fluid">
                <div class="span3">
                  <input type="text" class="input-block-level" autocomplete="off" maxlength="4" pattern="\\d{4}" title="CVV on the back of your card" required>
                </div>
                <div class="span8">
               
                </div>
              </div>
            </div>
          </div>
       
          <div class="form-actions">
            <button type="submit" class="btn btn-primary">Submit</button>
            <button type="button" class="btn">Cancel</button>
          </div>
        </fieldset>
      </form>
    </div>
  </div>
</div>`
    });
    toAdd('bullet-list') && bm.add('bullet-list', {
        label: "Bullet List",
        category: "Basic",
        attributes: { class: "fa fa-list" },
        content: `<ul>
    <li>This is a list</li>
    <li>This is a list</li>
    <li>This is a list</li>
    <li>This is a list</li>
    </ul>`
    });
    toAdd("numbered-list") && bm.add("numbered-list", {
        label: "Numbered List",
        category: "Basic",
        attributes: { class: "fa fa-list-ol" },
        content: `<ol>
    <li>This is a list</li>
    <li>This is a list</li>
    <li>This is a list</li>
    <li>This is a list</li>
  </ol>`
    })
    toAdd('divider') && bm.add('divider', {
        label: "Divider",
        category: "Basic",
        attributes: { class: 'fa fa-minus' },
        content: `<hr/>`
    });
    toAdd('quote') && bm.add('quote', {
        label: 'Quote',
        category: 'Basic',
        attributes: { class: 'fa fa-quote-right' },
        content: `<blockquote class="quote">
        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore ipsum dolor sit
      </blockquote>`
    });

    toAdd('text-basic') && bm.add('text-basic', {
        category: 'Basic',
        label: 'Text section',
        attributes: { class: 'gjs-fonts gjs-f-h1p' },
        content: `<section class="bdg-sect">
      <h1 class="heading">Insert title here</h1>
      <p class="paragraph">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua</p>
      </section>`
    });

    //CUSTOM BUILT IN BLOCKS

    //ADD TO CART
    toAdd("add_to_cart1") && bm.add("add_to_cart1", {
        category: "Carts",
        label: "Add To Cart 1",
        attributes: { style: "background-image: url('https://s3.us-east-2.amazonaws.com/konnektfunnels-assets/snippets/addToCart/cart1.png'); background-repeat: no-repeat;background-position: center center;background-size: 250px 100px; " },
        content: `<style>
        body {
            padding: 0;
            margin: 0;
            font-family: sans-serif;
        }

        img {
            max-width: 100%;
        }
        .cart1 {
            padding: 40px 0px;
        }
        .add_to_cart1 {
            font-family: 'Oswald', Arial, sans-serif;
            position: relative;
            margin: 10px;
            min-width: 230px;
            max-width: 315px;
            width: 100%;
            background: #ffffff;
            text-align: center;
            color: #000000;
            box-shadow: 0 0 5px rgba(0, 0, 0, 0.15);
            font-size: 16px;
            padding: 15px;
            margin-top:20px;
            -webkit-box-sizing: border-box;
            box-sizing: border-box;
            -webkit-transition: all 0.2s ease-out;
            transition: all 0.2s ease-out;
        }

        .add_to_cart1 * {
            -webkit-box-sizing: padding-box;
            box-sizing: padding-box;
            -webkit-transition: all 0.2s ease-out;
            transition: all 0.2s ease-out;
        }

        .add_to_cart1 img {
            max-width: 100%;
            vertical-align: top;
            position: relative;
        }

        .add_to_cart1  .product_description {
            padding: 20px 15px;
        }

        .add_to_cart1 h3,
        .add_to_cart1 p {
            margin: 0;
        }

        .add_to_cart1 h3 {
            font-size: 1.3em;
            font-weight: 400;
            margin-bottom: 5px;
            text-transform: uppercase;
        }

        .add_to_cart1 p {
            font-size: 0.9em;
            letter-spacing: 1px;
            font-weight: 300;
        }

        .add_to_cart1 .price {
            font-weight: 500;
            font-size: 1.4em;
            line-height: 48px;
            letter-spacing: 1px;
        }

        .add_to_cart1 .price s {
            margin-right: 5px;
            opacity: 0.5;
            font-size: 0.9em;
        }

        .add_to_cart1 i {
            position: absolute;
            bottom: 0;
            left: 50%;
            -webkit-transform: translate(-50%, 50%);
            transform: translate(-50%, 50%);
            width: 60px;
            height: 60px;
            line-height: 55px;
            text-align: center;
            border-radius: 50%;
            background-color: #666666;
            color: #ffffff;
            font-size: 1.6em;
            border: 4px solid #ffffff;
        }

        .add_to_cart1 a {
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            z-index: 1;
        }

        .add_to_cart1:hover,
        .add_to_cart1.hover {
            -webkit-transform: translateY(-5px);
            transform: translateY(-5px);
        }

        .add_to_cart1:hover i,
        .add_to_cart1.hover i {
            background-color: #2980b9;
        }
    </style>
    <section class="cart1">
        <div class="conatiner">
            <div class="row justify-content-center m-0" data-gjs-resizable='{"tl":0,"tc":0,"tr":0,"cl":0,"cr":0,"bl":0,"br":0}'>
                <div class="add_to_cart1 colxs-12" data-gjs-resizable='{"tl":0,"tc":0,"tr":0,"cl":0,"bl":0,"br":0,"bc": 0, "keyWidth": "flex-basis", "currentUnit": 1, "minDim": 1, "maxDim": 100, "step": 0.2}'>
                    <img src="https://s3.us-east-2.amazonaws.com/konnektfunnels-assets/snippets/addToCart/sample57.jpg" alt="sample57" />
                    <div class="product_description">
                        <h3>New Item</h3>
                        <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Debitis accusamus dolor magni dicta voluptate id fuga.</p>
                        <div class="price">
                            <s>$24.00</s>$19.00
                        </div>
                    </div><i class="fa fa-shopping-cart"></i>
                    <a href="#"></a>
                </div>
                <div class="add_to_cart1 colxs-12" data-gjs-resizable='{"tl":0,"tc":0,"tr":0,"cl":0,"bl":0,"br":0,"bc": 0, "keyWidth": "flex-basis", "currentUnit": 1, "minDim": 1, "maxDim": 100, "step": 0.2}'><img src="https://s3.us-east-2.amazonaws.com/konnektfunnels-assets/snippets/addToCart/sample98.jpg"
                        alt="sample98" />
                    <div class="product_description">
                            <h3>New Item</h3>
                            <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Debitis accusamus dolor magni dicta voluptate id fuga.</p>
                        <div class="price">
                            <s>$29.00</s>$39.00
                        </div>
                    </div><i class="fa fa-shopping-cart"></i>
                    <a href="#"></a>
                </div>
                <div class="add_to_cart1 colxs-12" data-gjs-resizable='{"tl":0,"tc":0,"tr":0,"cl":0,"bl":0,"br":0,"bc": 0, "keyWidth": "flex-basis", "currentUnit": 1, "minDim": 1, "maxDim": 100, "step": 0.2}'><img src="https://s3.us-east-2.amazonaws.com/konnektfunnels-assets/snippets/addToCart/sample90.jpg"
                        alt="sample90" />
                    <div class="product_description">
                            <h3>New Item</h3>
                            <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Debitis accusamus dolor magni dicta voluptate id fuga.</p>
                        <div class="price">
                            <s>$35.00</s>$45.00
                        </div>
                    </div><i class="fa fa-shopping-cart"></i>
                    <a href="#"></a>
                </div>
            </div>
        </div>
    </section>
`
    });

    toAdd("add_to_cart2") && bm.add("add_to_cart2", {
        category: "Carts",
        label: "Add To Cart 2",
        attributes: { style: "background-image: url('https://s3.us-east-2.amazonaws.com/konnektfunnels-assets/snippets/addToCart/cart2.png'); background-repeat: no-repeat;background-position: center center;background-size: 250px 100px; " },
        content: `<style>
      
        img {
            max-width: 100%;
        }
        .cart2{
            padding: 30px 0px;
        }
        .cart_sec_2 {
            font-family: 'Raleway', Arial, sans-serif;
            position: relative;
            overflow: hidden;
            margin: 10px;
            min-width: 220px;
            max-width: 310px;
            width: 100%;
            color: #333333;
            text-align: center;
            background-color: #ffffff;
            line-height: 1.6em;
        }

        .cart_sec_2 * {
            -webkit-box-sizing: border-box;
            box-sizing: border-box;
            -webkit-transition: all 0.6s ease;
            transition: all 0.6s ease;
        }

        .cart_sec_2 .image {
            position: relative;
        }

        .cart_sec_2 img {
            max-width: 100%;
            vertical-align: top;
            -webkit-transition: opacity 0.35s;
            transition: opacity 0.35s;
        }

        .cart_sec_2 .icons,
        .cart_sec_2 .add-to-cart {
            position: absolute;
            left: 20px;
            right: 20px;
            opacity: 0;
        }

        .cart_sec_2 .icons {
            -webkit-transform: translateY(-100%);
            transform: translateY(-100%);
            top: 20px;
            display: flex;
            justify-content: space-between;
        }

        .cart_sec_2 .icons a {
            width: 32.5%;
            background: #ffffff;
        }

        .cart_sec_2 .icons a:hover {
            background: #000000;
        }

        .cart_sec_2 .icons a:hover i {
            color: #ffffff;
            opacity: 1;
        }

        .cart_sec_2 .icons i {
            line-height: 46px;
            font-size: 20px;
            color: #000000;
            text-align: center;
            opacity: 0.7;
            margin: 0;
        }

        .cart_sec_2 .add-to-cart {
            position: absolute;
            bottom: 20px;
            -webkit-transform: translateY(100%);
            transform: translateY(100%);
            font-size: 0.8em;
            color: #000000;
            line-height: 46px;
            letter-spacing: 1.5px;
            background-color: #ffffff;
            font-weight: 700;
            text-decoration: none;
            text-transform: uppercase;
        }

        .cart_sec_2 .add-to-cart:hover {
            background: #000000;
            color: #ffffff;
        }

        .cart_sec_2 figcaption {
            padding: 20px 20px 30px;
        }

        .cart_sec_2 h2,
        .cart_sec_2 p {
            margin: 0;
            text-align: left;
        }

        .cart_sec_2 h2 {
            margin-bottom: 10px;
            font-weight: 700;
        }

        .cart_sec_2 p {
            margin-bottom: 15px;
            font-size: 0.85em;
            font-weight: 500;
        }

        .cart_sec_2 .price {
            font-size: 1.3em;
            opacity: 0.5;
            font-weight: 700;
            text-align: right;
        }

        .cart_sec_2:hover img,
        .cart_sec_2.hover img {
            opacity: 0.8;
        }

        .cart_sec_2:hover .icons,
        .cart_sec_2.hover .icons,
        .cart_sec_2:hover .add-to-cart,
        .cart_sec_2.hover .add-to-cart {
            -webkit-transform: translateY(0);
            transform: translateY(0);
            opacity: 1;
        }
    </style>
    <section class="cart2">
        <div class="container">
            <div class="row justify-content-center" data-gjs-resizable='{"tl":0,"tc":0,"tr":0,"cl":0,"cr":0,"bl":0,"br":0}'>
                    <div class="cart_sec_2 col-xs-12" data-gjs-resizable='{"tl":0,"tc":0,"tr":0,"cl":0,"bl":0,"br":0,"bc": 0, "keyWidth": "flex-basis", "currentUnit": 1, "minDim": 1, "maxDim": 100, "step": 0.2}'>
                            <div class="image">
                                <img src="https://s3.us-east-2.amazonaws.com/konnektfunnels-assets/snippets/addToCart/sample57.jpg" alt="sq-sample4" />
                                <div class="icons">
                                    <a href="#"><i class="fa fa-star"></i></a>
                                    <a href="#"> <i class="fa fa-share"></i></a>
                                    <a href="#"> <i class="fa fa-search"></i></a>
                                </div>
                                <a href="#" class="add-to-cart">Add to Cart</a>
                            </div>
                            <figcaption>
                                    <h3>Item Name</h3>
                                    <p>Lorem ipsum, dolor sit amet consectetur adipisicing elit. Illo ex sequi harum ea perspiciatis </p>
                                <div class="price">$65.00 </div>
                            </figcaption>
                        </div>
                        <div class="cart_sec_2 col-xs-12" data-gjs-resizable='{"tl":0,"tc":0,"tr":0,"cl":0,"bl":0,"br":0,"bc": 0, "keyWidth": "flex-basis", "currentUnit": 1, "minDim": 1, "maxDim": 100, "step": 0.2}'>
                            <div class="image">
                                <img src="https://s3.us-east-2.amazonaws.com/konnektfunnels-assets/snippets/addToCart/sample90.jpg" alt="sq-sample17" />
                                <div class="icons">
                                    <a href="#"><i class="fa fa-star"></i></a>
                                    <a href="#"> <i class="fa fa-share"></i></a>
                                    <a href="#"> <i class="fa fa-search"></i></a>
                                </div>
                                <a href="#" class="add-to-cart">Add to Cart</a>
                            </div>
                            <figcaption>
                                    <h3>Item Name</h3>
                                    <p>Lorem ipsum, dolor sit amet consectetur adipisicing elit. Illo ex sequi harum ea perspiciatis </p>
                                <div class="price">$75.00 </div>
                            </figcaption>
                        </div>
                        <div class="cart_sec_2 col-xs-12" data-gjs-resizable='{"tl":0,"tc":0,"tr":0,"cl":0,"bl":0,"br":0,"bc": 0, "keyWidth": "flex-basis", "currentUnit": 1, "minDim": 1, "maxDim": 100, "step": 0.2}'>
                            <div class="image">
                                <img src="https://s3.us-east-2.amazonaws.com/konnektfunnels-assets/snippets/addToCart/sample98.jpg" alt="sq-sample15" />
                                <div class="icons">
                                    <a href="#"><i class="fa fa-star"></i></a>
                                    <a href="#"> <i class="fa fa-share"></i></a>
                                    <a href="#"> <i class="fa fa-search"></i></a>
                                </div>
                                <a href="#" class="add-to-cart">Add to Cart</a>
                            </div>
                            <figcaption>
                                    <h3>Item Name</h3>
                                    <p>Lorem ipsum, dolor sit amet consectetur adipisicing elit. Illo ex sequi harum ea perspiciatis </p>
                                <div class="price">$195.00 </div>
                            </figcaption>
                        </div>
                        
            </div>
        </div>
       
    </section>
`
    })

    toAdd("add_to_cart3") && bm.add("add_to_cart3", {
        category: "Carts",
        label: "Add To Cart 3",
        attributes: { style: "background-image: url('https://s3.us-east-2.amazonaws.com/konnektfunnels-assets/snippets/addToCart/cart3.png'); background-repeat: no-repeat;background-position: center center;background-size: 250px 100px; " },
        content: `
        <style>

        img {
            max-width: 100%;
        }
        .cart3{
            padding: 30px 0px;
        }
        .cart_sec_3 {
            font-family: 'Raleway', Arial, sans-serif;
            position: relative;
            overflow: hidden;
            margin: 10px;
            min-width: 220px;
            max-width: 310px;
            width: 100%;
            color: #333333;
            text-align: center;
            background-color: #ffffff;
            line-height: 1.6em;
        }

        .cart_sec_3 * {
            -webkit-box-sizing: border-box;
            box-sizing: border-box;
            -webkit-transition: all 0.6s ease;
            transition: all 0.6s ease;
        }

        .cart_sec_3 .image {
            position: relative;
        }

        .cart_sec_3 img {
            max-width: 100%;
            vertical-align: top;
            -webkit-transition: opacity 0.35s;
            transition: opacity 0.35s;
        }

        .cart_sec_3 .icons,
        .cart_sec_3 .add-to-cart {
            position: absolute;
            left: 20px;
            right: 20px;
            opacity: 0;
        }

        .cart_sec_3 .icons {
            -webkit-transform: translateY(-100%);
            transform: translateY(-100%);
            top: 20px;
            display: flex;
            justify-content: space-between;
        }

        .cart_sec_3 .icons a {
            width: 32.5%;
            background: #ffffff;
        }

        .cart_sec_3 .icons a:hover {
            background: #000000;
        }

        .cart_sec_3 .icons a:hover i {
            color: #ffffff;
            opacity: 1;
        }

        .cart_sec_3 .icons i {
            line-height: 46px;
            font-size: 20px;
            color: #000000;
            text-align: center;
            opacity: 0.7;
            margin: 0;
        }

        .cart_sec_3 .add-to-cart {
            position: absolute;
            bottom: 20px;
            -webkit-transform: translateY(100%);
            transform: translateY(100%);
            font-size: 0.8em;
            color: #000000;
            line-height: 46px;
            letter-spacing: 1.5px;
            background-color: #af9191;
            font-weight: 700;
            cursor: pointer;
            text-decoration: none;
            text-transform: uppercase;
        }

        .cart_sec_3 .add-to-cart:hover {
            background: #000000;
            color: #ffffff;
        }

        .cart_sec_3 figcaption {
            padding: 20px 20px 30px;
        }

        .cart_sec_3 h2,
        .cart_sec_3 p {
            margin: 0;
            text-align: left;
        }

        .cart_sec_3 h2 {
            margin-bottom: 10px;
            font-weight: 700;
        }

        .cart_sec_3 p {
            margin-bottom: 15px;
            font-size: 0.85em;
            font-weight: 500;
        }

        .cart_sec_3 .price {
            font-size: 1.3em;
            opacity: 0.5;
            font-weight: 700;
            text-align: right;
        }

        .cart_sec_3:hover img,
        .cart_sec_3.hover img {
            opacity: 0.8;
        }

        .cart_sec_3:hover .icons,
        .cart_sec_3.hover .icons,
        .cart_sec_3:hover .add-to-cart,
        .cart_sec_3.hover .add-to-cart {
            -webkit-transform: translateY(0);
            transform: translateY(0);
            opacity: 1;
        }
    </style>
     <section class="cart3">
        <div class="container">
            <div class="row justify-content-center" data-gjs-resizable='{"tl":0,"tc":0,"tr":0,"cl":0,"cr":0,"bl":0,"br":0}'>
                    <div class="cart_sec_3 col-xs-12" data-gjs-resizable='{"tl":0,"tc":0,"tr":0,"cl":0,"bl":0,"br":0,"bc": 0, "keyWidth": "flex-basis", "currentUnit": 1, "minDim": 1, "maxDim": 100, "step": 0.2}'><img src="https://s3.us-east-2.amazonaws.com/konnektfunnels-assets/snippets/addToCart/sample85.jpg" alt="sample85"/>
                        <div class="add-to-cart"> <i class="ion-android-add"></i><span>Add to Cart</span></div>
                        <figcaption>
                                <h3>Item Name</h3>
                                <p>Lorem ipsum, dolor sit amet consectetur adipisicing elit. Illo ex sequi harum ea perspiciatis </p>
                          <div class="price">
                            <s>$24.00</s>$19.00
                          </div>
                        </figcaption><a href="#"></a>
                      </div>
                      <div class="cart_sec_3 col-xs-12" data-gjs-resizable='{"tl":0,"tc":0,"tr":0,"cl":0,"bl":0,"br":0,"bc": 0, "keyWidth": "flex-basis", "currentUnit": 1, "minDim": 1, "maxDim": 100, "step": 0.2}'><img src="https://s3.us-east-2.amazonaws.com/konnektfunnels-assets/snippets/addToCart/sample96.jpg" alt="sample96"/>
                        <div class="add-to-cart"> <i class="ion-android-add"></i><span>Add to Cart</span></div>
                        <figcaption>
                                <h3>Item Name</h3>
                                <p>Lorem ipsum, dolor sit amet consectetur adipisicing elit. Illo ex sequi harum ea perspiciatis </p>
                          <div class="price">
                            <s>$24.00</s>$19.00
                          </div>
                        </figcaption><a href="#"></a>
                      </div>
                      <div class="cart_sec_3 col-xs-12" data-gjs-resizable='{"tl":0,"tc":0,"tr":0,"cl":0,"bl":0,"br":0,"bc": 0, "keyWidth": "flex-basis", "currentUnit": 1, "minDim": 1, "maxDim": 100, "step": 0.2}'><img src="https://s3.us-east-2.amazonaws.com/konnektfunnels-assets/snippets/addToCart/sample92.jpg" alt="sample92"/>
                        <div class="add-to-cart"> <i class="ion-android-add"></i><span>Add to Cart</span></div>
                        <figcaption>
                                <h3>Item Name</h3>
                                <p>Lorem ipsum, dolor sit amet consectetur adipisicing elit. Illo ex sequi harum ea perspiciatis </p>
                          <div class="price">
                            <s>$24.00</s>$19.00
                          </div>
                        </figcaption><a href="#"></a>
                      </div>
            </div>
        </div>
       
    </section>
        `
    })

    toAdd("add_to_cart4") && bm.add("add_to_cart4", {
        category: "Carts",
        label: "Add To Cart 4",
        attributes: { style: "background-image: url('https://s3.us-east-2.amazonaws.com/konnektfunnels-assets/snippets/addToCart/cart4.png'); background-repeat: no-repeat;background-position: center center;background-size: 250px 100px; " },
        content: `
        <style>
        body {
            padding: 0;
            margin: 0;
            font-family: sans-serif;
        }

        img {
            max-width: 100%;
        }

        .cart4 {
            padding: 40px 0px;
        }

        .cart_sec_4 {
            font-family: 'Raleway', Arial, sans-serif;
            position: relative;
            overflow: hidden;
            margin: 10px;
            min-width: 230px;
            max-width: 315px;
            width: 100%;
            background: #20638f;
            text-align: left;
            color: #ffffff;
            box-shadow: 0 0 5px rgba(0, 0, 0, 0.15);
            font-size: 16px;
            -webkit-transform: translateZ(0);
            transform: translateZ(0);
        }

        .cart_sec_4 * {
            -webkit-box-sizing: border-box;
            box-sizing: border-box;
            -webkit-transition: all 0.3s ease-out;
            transition: all 0.3s ease-out;
        }

        .cart_sec_4>img,
        .cart_sec_4 .image img {
            -webkit-transform: scale(1.05);
            transform: scale(1.05);
            max-width: 100%;
        }

        .cart_sec_4>img {
            vertical-align: top;
            position: relative;
            -webkit-filter: blur(5px);
            filter: blur(5px);
            opacity: 0.6;
        }

        .cart_sec_4 figcaption,
        .cart_sec_4 .image {
            -webkit-transition-delay: 0.2s;
            transition-delay: 0.2s;
        }

        .cart_sec_4 .image {
            position: absolute;
            top: 0;
            bottom: 45%;
            right: 0;
            left: 0;
            overflow: hidden;
            box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.1), 0px 1px 0 rgba(255, 255, 255, 0.2);
        }

        .cart_sec_4 .image img {
            position: absolute;
            top: 0;
        }

        .cart_sec_4 figcaption {
            position: absolute;
            top: 55%;
            bottom: 46px;
            left: 20px;
            right: 20px;
            border-bottom: 2px solid #ffffff;
            padding-top: 20px;
            z-index: 1;
        }

        .cart_sec_4 h3,
        .cart_sec_4 p {
            margin: 0;
        }

        .cart_sec_4 h3 {
            font-weight: 700;
            margin-bottom: 5px;
            text-transform: uppercase;
        }

        .cart_sec_4 p {
            font-size: 0.9em;
            letter-spacing: 1px;
            font-weight: 400;
        }

        .cart_sec_4 .price,
        .cart_sec_4 .add-to-cart {
            position: absolute;
            bottom: 0;
            padding: 0 20px;
            font-weight: 500;
            line-height: 48px;
            letter-spacing: 1px;
        }

        .cart_sec_4 .price {
            left: 0;
        }

        .cart_sec_4 .price s {
            margin-right: 5px;
            opacity: 0.5;
            font-size: 0.9em;
        }

        .cart_sec_4 .add-to-cart {
            -webkit-transform: translateX(-20px);
            transform: translateX(-20px);
            opacity: 0;
            color: #ffffff;
            text-transform: uppercase;
            text-decoration: none;
            font-size: 0.9em;
            right: 0;
        }

        .cart_sec_4 .add-to-cart i {
            margin-left: 5px;
            font-size: 1.1em;
        }

        .cart_sec_4:hover .add-to-cart,
        .cart_sec_4.hover .add-to-cart {
            -webkit-transform: translateX(0px);
            transform: translateX(0px);
            opacity: 1;
        }
    </style>
        <section class="cart4">

        <div class="row justify-content-center m-0">
            <div data-gjs-resizable='{"tl":0,"tc":0,"tr":0,"cl":0,"bl":0,"br":0,"bc": 0, "keyWidth": "flex-basis", "currentUnit": 1, "minDim": 1, "maxDim": 100, "step": 0.2}' class="cart_sec_4 ">
                <img src="https://s3.us-east-2.amazonaws.com/konnektfunnels-assets/snippets/addToCart/pr-sample13.jpg" alt="pr-sample13" />
                <div class="image"><img src="https://s3.us-east-2.amazonaws.com/konnektfunnels-assets/snippets/addToCart/pr-sample13.jpg" alt="pr-sample13" /></div>
                <figcaption>
                    <h3>Item Name</h3>
                    <p>Lorem ipsum, dolor sit amet consectetur adipisicing elit. Illo ex sequi harum ea perspiciatis
                    </p>
                </figcaption>
                <div class="price">
                    <s>$24.00</s>$19.00
                </div><a href="#" class="add-to-cart">
                    Add to Cart<i class="ion-android-checkbox-outline"></i></a>
            </div>
            <div data-gjs-resizable='{"tl":0,"tc":0,"tr":0,"cl":0,"bl":0,"br":0,"bc": 0, "keyWidth": "flex-basis", "currentUnit": 1, "minDim": 1, "maxDim": 100, "step": 0.2}' class="cart_sec_4 ">
                <img src="https://s3.us-east-2.amazonaws.com/konnektfunnels-assets/snippets/addToCart/pr-sample19.jpg" alt="pr-sample19" />
                <div class="image"><img src="https://s3.us-east-2.amazonaws.com/konnektfunnels-assets/snippets/addToCart/pr-sample19.jpg" alt="pr-sample13" /></div>
                <figcaption>
                    <h3>Item Name</h3>
                    <p>Lorem ipsum, dolor sit amet consectetur adipisicing elit. Illo ex sequi harum ea perspiciatis
                    </p>
                </figcaption>
                <div class="price">
                    <s>$24.00</s>$19.00
                </div><a href="#" class="add-to-cart">
                    Add to Cart<i class="ion-android-checkbox-outline"></i></a>
            </div>
            <div data-gjs-resizable='{"tl":0,"tc":0,"tr":0,"cl":0,"bl":0,"br":0,"bc": 0, "keyWidth": "flex-basis", "currentUnit": 1, "minDim": 1, "maxDim": 100, "step": 0.2}' class="cart_sec_4 ">
                <img src="https://s3.us-east-2.amazonaws.com/konnektfunnels-assets/snippets/addToCart/pr-sample13.jpg" alt="pr-sample13" />
                <div class="image"><img src="https://s3.us-east-2.amazonaws.com/konnektfunnels-assets/snippets/addToCart/pr-sample13.jpg" alt="pr-sample13" /></div>
                <figcaption>
                    <h3>Item Name</h3>
                    <p>Lorem ipsum, dolor sit amet consectetur adipisicing elit. Illo ex sequi harum ea perspiciatis
                    </p>
                </figcaption>
                <div class="price">
                    <s>$24.00</s>$19.00
                </div><a href="#" class="add-to-cart">
                    Add to Cart<i class="ion-android-checkbox-outline"></i></a>
            </div>
            <div data-gjs-resizable='{"tl":0,"tc":0,"tr":0,"cl":0,"bl":0,"br":0,"bc": 0, "keyWidth": "flex-basis", "currentUnit": 1, "minDim": 1, "maxDim": 100, "step": 0.2}' class="cart_sec_4  "><img src="https://s3.us-east-2.amazonaws.com/konnektfunnels-assets/snippets/addToCart/pr-sample19.jpg" alt="pr-sample19" />
                <div class="image"><img src="https://s3.us-east-2.amazonaws.com/konnektfunnels-assets/snippets/addToCart/pr-sample19.jpg" alt="pr-sample19" /></div>
                <figcaption>
                    <h3>Item Name</h3>
                    <p>Lorem ipsum, dolor sit amet consectetur adipisicing elit. Illo ex sequi harum ea perspiciatis
                    </p>
                </figcaption>
                <div class="price">
                    <s>$24.00</s>$19.00
                </div><a href="#" class="add-to-cart">

                    Add to Cart<i class="ion-android-checkbox-outline"></i></a>
            </div>
            <div data-gjs-resizable='{"tl":0,"tc":0,"tr":0,"cl":0,"bl":0,"br":0,"bc": 0, "keyWidth": "flex-basis", "currentUnit": 1, "minDim": 1, "maxDim": 100, "step": 0.2}' class="cart_sec_4 "><img src="https://s3.us-east-2.amazonaws.com/konnektfunnels-assets/snippets/addToCart/pr-sample12.jpg" alt="pr-sample12" />
                <div class="image"><img src="https://s3.us-east-2.amazonaws.com/konnektfunnels-assets/snippets/addToCart/pr-sample12.jpg" alt="pr-sample12" /></div>
                <figcaption>
                    <h3>Item Name</h3>
                    <p>Lorem ipsum, dolor sit amet consectetur adipisicing elit. Illo ex sequi harum ea perspiciatis
                    </p>
                </figcaption>
                <div class="price">
                    <s>$24.00</s>$19.00
                </div><a href="#" class="add-to-cart">

                    Add to Cart<i class="ion-android-checkbox-outline"></i></a>
            </div>

        </div>
    </section>
        `,

    })

    toAdd("add_to_cart5") && bm.add("add_to_cart5", {
        category: "Carts",
        label: "Add To Cart 5",
        attributes: { style: "background-image: url('https://s3.us-east-2.amazonaws.com/konnektfunnels-assets/snippets/addToCart/cart5.png'); background-repeat: no-repeat;background-position: center center;background-size: 250px 100px; " },
        content: `
        <style>
        body {
            padding: 0;
            margin: 0;
            font-family: sans-serif;
        }

        img {
            max-width: 100%;
        }

        .cart5 {
            padding: 40px 0px;
        }

        .cart5 .cart_sec_5 {
            font-family: 'Raleway', Arial, sans-serif;
            position: relative;
            overflow: hidden;
            margin: 10px;
            min-width: 230px;
            max-width: 310px;
            width: 100%;
            background: #1a1a1a;
            color: #ffffff;
            text-align: center;
            border-radius: 8px;
            box-shadow: 0 0 5px rgba(0, 0, 0, 0.15);
        }

        .cart5 .cart_sec_5 * {
            -webkit-box-sizing: border-box;
            box-sizing: border-box;
            -webkit-transition: all 0.35s cubic-bezier(0.25, 0.5, 0.5, 0.9);
            transition: all 0.35s cubic-bezier(0.25, 0.5, 0.5, 0.9);
        }

        .cart5 .cart_sec_5 .image {
            background-color: #000000;
            width: 100%;
            overflow: hidden;
        }

        .cart5 .cart_sec_5 .image:after {
            background-color: rgba(0, 0, 0, 0.85);
            position: absolute;
            content: '';
            top: 0;
            right: 0;
            left: 50%;
            bottom: 0;
        }

        .cart5 .cart_sec_5 img {
            max-width: 100%;
            vertical-align: top;
        }

        .cart5 .cart_sec_5 figcaption {
            position: absolute;
            width: 50%;
            right: 0;
            top: 50%;
            -webkit-transform: translateY(-50%);
            transform: translateY(-50%);
            padding: 10px 25px;
        }

        .cart5 .cart_sec_5 h4,
        .cart5 .cart_sec_5 p,
        .cart5 .cart_sec_5 .price {
            margin: 0 0 8px;
        }

        .cart5 .cart_sec_5 h4 {
            position: absolute;
            width: 50%;
            top: 10px;
            text-transform: uppercase;
            font-weight: 400;
            color: #ffffff;
            letter-spacing: 1px;
            z-index: 1;
        }

        .cart5 .cart_sec_5 p {
            font-size: 0.7em;
            font-weight: 500;
            line-height: 1.6em;
        }

        .cart5 .cart_sec_5 .rating {
            position: absolute;
            width: 50%;
            line-height: 44px;
            color: #ffffff;
            left: 0;
            bottom: 0;
            background-color: rgba(0, 0, 0, 0.3);
        }

        .cart5 .cart_sec_5 .price {
            color: #ffffff;
            font-size: 1.3em;
            opacity: 0.8;
        }

        .cart5 .cart_sec_5 .price s {
            display: inline-block;
            padding: 0 8px 0 0;
            font-size: 0.85em;
            color: #f39c12;
        }

        .cart5 .cart_sec_5 .add-to-cart {
            text-decoration: none;
            position: absolute;
            bottom: 0;
            right: 0;
            font-weight: 600;
            width: 50%;
            background-color: rgba(0, 0, 0, 0.2);
            line-height: 44px;
            font-size: 0.75em;
            text-transform: uppercase;
            color: #ffffff;
        }

        .cart5 .cart_sec_5:hover img,
        .cart5 .cart_sec_5.hover img {
            opacity: 0.6;
            -webkit-transform: scale(1.1);
            transform: scale(1.1);
        }

        .cart5 .cart_sec_5:hover .add-to-cart,
        .cart5 .cart_sec_5.hover .add-to-cart {
            background-color: rgba(0, 0, 0, 0.5);
        }
    </style>
    <section class="cart5">
        <div class="container">
            <div class="row justify-content-center" data-gjs-resizable='{"tl":0,"tc":0,"tr":0,"cl":0,"cr":0,"bl":0,"br":0}'>


                <div class="cart_sec_5" data-gjs-resizable='{"tl":0,"tc":0,"tr":0,"cl":0,"bl":0,"br":0,"bc": 0, "keyWidth": "flex-basis", "currentUnit": 1, "minDim": 1, "maxDim": 100, "step": 0.2}'>
                    <h4>Product</h4>
                    <div class="image">
                        <img src="https://s3.us-east-2.amazonaws.com/konnektfunnels-assets/snippets/addToCart/pr-sample12.jpg" alt="sq-sample6" />
                    </div>
                    <div class="rating"><i class="fa fa-star"></i><i class="fa fa-star"></i><i class="fa fa-star"></i><i
                            class="fa fa-star"></i><i class="fa fa-star-outline"></i></div>
                    <figcaption>
                        <p>
                            Lorem ipsum dolor sit amet consectetur, adipisicing elit. Laboriosam in possimus reiciendis
                            dolorum cum.
                        </p>
                        <div class="price">
                            <s>$24.00</s>$19.00
                        </div>
                    </figcaption>
                    <a href="#" class="add-to-cart">Add to Cart</a>
                </div>
                <div class="cart_sec_5 " data-gjs-resizable='{"tl":0,"tc":0,"tr":0,"cl":0,"bl":0,"br":0,"bc": 0, "keyWidth": "flex-basis", "currentUnit": 1, "minDim": 1, "maxDim": 100, "step": 0.2}'>
                    <h4>Product</h4>
                    <div class="image">
                        <img src="https://s3.us-east-2.amazonaws.com/konnektfunnels-assets/snippets/addToCart/pr-sample13.jpg" alt="sq-sample17" />
                    </div>
                    <div class="rating"><i class="fa fa-star"></i><i class="fa fa-star"></i><i class="fa fa-star"></i><i
                            class="fa fa-star"></i><i class="fa fa-star-outline"></i></div>
                    <figcaption>
                        <p>
                            Lorem ipsum dolor sit amet consectetur, adipisicing elit. Laboriosam in possimus reiciendis
                            dolorum cum.
                        </p>
                        <div class="price">
                            <s>$29.00</s>$24.00
                        </div>
                    </figcaption>
                    <a href="#" class="add-to-cart">Add to Cart</a>
                </div>
                <div class="cart_sec_5 " data-gjs-resizable='{"tl":0,"tc":0,"tr":0,"cl":0,"bl":0,"br":0,"bc": 0, "keyWidth": "flex-basis", "currentUnit": 1, "minDim": 1, "maxDim": 100, "step": 0.2}'>
                    <h4>Product </h4>
                    <div class="image">
                        <img src="https://s3.us-east-2.amazonaws.com/konnektfunnels-assets/snippets/addToCart/pr-sample19.jpg" alt="sq-sample22" />
                    </div>
                    <div class="rating"><i class="fa fa-star"></i><i class="fa fa-star"></i><i class="fa fa-star"></i><i
                            class="fa fa-star-outline"></i><i class="fa fa-star-outline"></i></div>
                    <figcaption>
                        <p>
                            Lorem ipsum dolor sit amet consectetur, adipisicing elit. Laboriosam in possimus reiciendis
                            dolorum cum.
                        </p>
                        <div class="price">
                            <s>$32.00</s>$28.00
                        </div>
                    </figcaption>
                    <a href="#" class="add-to-cart">Add to Cart</a>
                </div>
            </div>
        </div>
    </section>
        `,
    })
    toAdd("add_to_cart6") && bm.add("add_to_cart6", {
        category: "Carts",
        label: "Add To Cart 6",
        attributes: { style: "background-image: url('https://s3.us-east-2.amazonaws.com/konnektfunnels-assets/snippets/addToCart/cart6.png'); background-repeat: no-repeat;background-position: center center;background-size: 250px 100px; " },
        content: `
        <style>
        body {
            padding: 0;
            margin: 0;
            font-family: sans-serif;
        }

        img {
            max-width: 100%;
        }

        .cart6 {
            padding: 40px 0px;
        }

        .add_cart_sec_6 {
            font-family: 'Oswald', Arial, sans-serif;
            position: relative;
            margin: 10px;
            min-width: 230px;
            max-width: 315px;
            width: 100%;
            background: #ffffff;
            text-align: center;
            color: #000000;
            box-shadow: 0 0 5px rgba(0, 0, 0, 0.15);
            font-size: 16px;
        }

        .add_cart_sec_6 * {
            -webkit-box-sizing: border-box;
            box-sizing: padding-box;
            -webkit-transition: all 0.2s ease-out;
            transition: all 0.2s ease-out;
        }

        .add_cart_sec_6 img {
            max-width: 100%;
            vertical-align: top;
            position: relative;
            background-color: #666666;
            padding-top: 40px;
        }

        .add_cart_sec_6 figcaption {
            padding: 20px;
        }

        .add_cart_sec_6 h3,
        .add_cart_sec_6 p {
            margin: 0;
        }

        .add_cart_sec_6 h3 {
            font-size: 1.3em;
            font-weight: 400;
            margin-bottom: 5px;
            text-transform: uppercase;
        }

        .add_cart_sec_6 p {
            font-size: 0.9em;
            letter-spacing: 1px;
            font-weight: 300;
        }

        .add_cart_sec_6 .price {
            font-weight: 500;
            font-size: 1.4em;
            line-height: 48px;
            letter-spacing: 1px;
        }

        .add_cart_sec_6 .price s {
            margin-right: 5px;
            opacity: 0.5;
            font-size: 0.9em;
        }

        .add_cart_sec_6 i {
            position: absolute;
            top: 0;
            left: 50%;
            -webkit-transform: translate(-50%, 0%);
            transform: translate(-50%, 0%);
            width: 56px;
            line-height: 56px;
            text-align: center;
            border-radius: 50%;
            background-color: #666666;
            color: #ffffff;
            font-size: 36px;
        }

        .add_cart_sec_6 a {
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            z-index: 1;
        }

        .add_cart_sec_6:hover i,
        .add_cart_sec_6.hover i,
        .add_cart_sec_6:hover img,
        .add_cart_sec_6.hover img {
            background-color: #2980b9;
        }

        .add_cart_sec_6:hover i,
        .add_cart_sec_6.hover i {
            -webkit-transform: translate(-50%, 5px);
            transform: translate(-50%, 5px);
        }
    </style>
    <section class="cart6">
        <div class="container">
            <div class="row justify-content-center" data-gjs-resizable='{"tl":0,"tc":0,"tr":0,"cl":0,"cr":0,"bl":0,"br":0}'>
                <div class="add_cart_sec_6 col-xs-12" data-gjs-resizable='{"tl":0,"tc":0,"tr":0,"cl":0,"bl":0,"br":0,"bc": 0, "keyWidth": "flex-basis", "currentUnit": 1, "minDim": 1, "maxDim": 100, "step": 0.2}'>
                    <img src="https://s3.us-east-2.amazonaws.com/konnektfunnels-assets/snippets/addToCart/sample85.jpg" alt="sample85" />
                    <figcaption>
                        <h3>Item Name</h3>
                        <p>Lorem ipsum, dolor sit amet consectetur adipisicing elit. Illo ex sequi harum ea
                            perspiciatis </p>
                        <div class="price">
                            <s>$24.00</s>$19.00
                        </div>
                    </figcaption><i class="fa fa-plus-circle"></i>
                    <a href="#"></a>
                </div>
                <div class="add_cart_sec_6 col-xs-12" data-gjs-resizable='{"tl":0,"tc":0,"tr":0,"cl":0,"bl":0,"br":0,"bc": 0, "keyWidth": "flex-basis", "currentUnit": 1, "minDim": 1, "maxDim": 100, "step": 0.2}'>
                    <img src="https://s3.us-east-2.amazonaws.com/konnektfunnels-assets/snippets/addToCart/sample60.jpg" alt="sample60" />
                    <figcaption>
                        <h3>Item Name</h3>
                        <p>Lorem ipsum, dolor sit amet consectetur adipisicing elit. Illo ex sequi harum ea
                            perspiciatis </p>
                        <div class="price">
                            <s>$29.00</s>$39.00
                        </div>
                    </figcaption><i class="fa fa-plus-circle"></i>
                    <a href="#"></a>
                </div>
                <div class="add_cart_sec_6 col-xs-12" data-gjs-resizable='{"tl":0,"tc":0,"tr":0,"cl":0,"bl":0,"br":0,"bc": 0, "keyWidth": "flex-basis", "currentUnit": 1, "minDim": 1, "maxDim": 100, "step": 0.2}'><img src="https://s3.us-east-2.amazonaws.com/konnektfunnels-assets/snippets/addToCart/sample74.jpg" alt="sample74" />
                    <figcaption>
                        <h3>Item Name</h3>
                        <p>Lorem ipsum, dolor sit amet consectetur adipisicing elit. Illo ex sequi harum ea
                            perspiciatis </p>
                        <div class="price">
                            <s>$35.00</s>$45.00
                        </div>
                    </figcaption><i class="fa fa-plus-circle"></i>
                    <a href="#"></a>
                </div>
            </div>
        </div>
    </section>
        `
    })
    //banners
    toAdd("banner1") && bm.add("banner1", {
        category: "Banners",
        label: "Banner1",
        attributes: { style: "background-image: url('https://s3.us-east-2.amazonaws.com/konnektfunnels-assets/snippets/banner/banner1.png'); background-repeat: no-repeat;background-position: center center;background-size: 250px 100px; " },
        content: `
        <style>
        body {
            padding: 0;
            margin: 0;
            font-family: sans-serif;
        }

        img {
            max-width: 100%;
        }

        .banner1 {
            background: url(https://s3.us-east-2.amazonaws.com/konnektfunnels-assets/snippets/banner/back_bg.png);
            min-height: 500px;
            display: flex;
            justify-content: center;
            align-items: center;
            align-items: center;
        }

        .banner1 .banner_content {
            display: flex;
            justify-content: center;
            align-items: center;
            flex-flow: column;
        }

        .banner1 .banner_content h1 {
            font-weight: 900;
            text-transform: uppercase;
        }

        .banner1 .banner_button {
            background: transparent;
            border-radius: 30px;
            color: #000;
            width: 300px;
            font-weight: 700;
            padding: 10px 20px;
            margin-bottom: 20px;
            margin-top: 10px;
            border: 3px solid #000;
        }

        .banner1 .banner_button:hover {
            background: #000;
            color: #fff;
        }
    </style>
    <section>
        <div class="banner1">
            <div class="container">
                <div class="col-xs-12 banner_content" data-gjs-resizable='{"tl":0,"tc":0,"tr":0,"cl":0,"bl":0,"br":0,"bc": 0, "keyWidth": "flex-basis", "currentUnit": 1, "minDim": 1, "maxDim": 100, "step": 0.2}'>
                    <h1>Add your own text here.</h1>
                    <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Vitae nostrum odit ratione quidem unde
                        neque harum ipsa enim ut eveniet alias rem vel perferendis, possimus corporis. Quae fuga labore
                        repellendus. Lorem ipsum dolor sit amet, consectetur adipisicing elit. Vitae nostrum odit
                        ratione quidem unde neque harum ipsa enim ut eveniet alias rem vel perferendis, possimus
                        corporis. Quae fuga labore repellendus. Lorem ipsum dolor sit amet, consectetur adipisicing
                        elit. Vitae nostrum odit ratione quidem unde neque harum ipsa enim ut eveniet alias rem vel
                        perferendis, possimus corporis. Quae fuga labore repellendus.</p>
                    <div class="banner_btn">
                        <button class="btn banner_button">Button</button>
                    </div>
                </div>
            </div>
        </div>
    </section>
        `
    })
    toAdd("banner2") && bm.add("banner2", {
        category: "Banners",
        label: "Banner2",
        attributes: { style: "background-image: url('https://s3.us-east-2.amazonaws.com/konnektfunnels-assets/snippets/banner/banner2.png'); background-repeat: no-repeat;background-position: center center;background-size: 250px 100px; " },
        content: `
            <style>
        body {
            padding: 0;
            margin: 0;
            font-family: sans-serif;
        }

        img {
            max-width: 100%;
        }

        .banner2 {
            background: url(https://s3.us-east-2.amazonaws.com/konnektfunnels-assets/snippets/banner/back_bg.png);
            min-height: 500px;
            display: flex;
            justify-content: center;
            align-items: center;
            align-items: center;
            /* padding: 30px 0px; */
        }

        .banner2 .banner_content {
            display: flex;
            justify-content: center;
            align-items: center;

        }

        .banner2 .banner_content h1 {
            font-weight: 900;
            text-transform: uppercase;
        }

        .banner2 .banner_button {
            background: transparent;
            border-radius: 30px;
            color: #000;
            width: 300px;
            font-weight: 700;
            padding: 10px 20px;
            margin-bottom: 20px;
            margin-top: 10px;
            border: 3px solid #000;
        }

        .banner2 .banner_button:hover {
            background: #000;
            color: #fff;
        }
    </style>
    <section>
        <div class="banner2">
            <div class="container">

                <div class="row banner_content" data-gjs-resizable='{"tl":0,"tc":0,"tr":0,"cl":0,"cr":0,"bl":0,"br":0}'>

                    <div class="col-md-6 col-xs-12" data-gjs-resizable='{"tl":0,"tc":0,"tr":0,"cl":0,"bl":0,"br":0,"bc": 0, "keyWidth": "flex-basis", "currentUnit": 1, "minDim": 1, "maxDim": 100, "step": 0.2}'>
                        <img src="https://s3.us-east-2.amazonaws.com/konnektfunnels-assets/snippets/banner/backgroung_bg.png" alt="Banner Image">
                    </div>
                    <div class="col-md-6 col-xs-12" data-gjs-resizable='{"tl":0,"tc":0,"tr":0,"cl":0,"bl":0,"br":0,"bc": 0, "keyWidth": "flex-basis", "currentUnit": 1, "minDim": 1, "maxDim": 100, "step": 0.2}'>
                        <h1>Add your own text here.</h1>
                        <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Vitae nostrum odit ratione quidem
                            unde neque harum ipsa enim ut eveniet alias rem vel perferendis, possimus corporis. Quae
                            fuga labore repellendus. Lorem ipsum dolor sit amet, consectetur adipisicing elit. Vitae
                            nostrum odit ratione quidem unde neque harum ipsa enim ut eveniet alias rem vel
                            perferendis, possimus corporis. Quae fuga labore repellendus. Lorem ipsum dolor sit amet,
                            consectetur adipisicing elit. Vitae nostrum odit ratione quidem unde neque harum ipsa enim
                            ut eveniet alias rem vel perferendis, possimus corporis. Quae fuga labore repellendus.</p>
                        <div class="banner_btn">
                            <button class="btn banner_button">Button</button>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    </section>
        `
    })
    toAdd("banner3") && bm.add("banner3", {
        category: "Banners",
        label: "Banner3",
        attributes: { style: "background-image: url('https://s3.us-east-2.amazonaws.com/konnektfunnels-assets/snippets/banner/banner3.png'); background-repeat: no-repeat;background-position: center center;background-size: 250px 100px; " },
        content: `
        <style>
        body {
            padding: 0;
            margin: 0;
            font-family: sans-serif;
        }

        img {
            max-width: 100%;
        }

        .banner3 {
            background: url(https://s3.us-east-2.amazonaws.com/konnektfunnels-assets/snippets/banner/banner_background.png);
            min-height: 500px;
            justify-content: center;
            align-items: center;
            align-items: center;
            padding: 50px 0px;
            background-size: cover;
            background-repeat: no-repeat;
        }

        .banner3 .banner_content {
            display: flex;
            justify-content: center;
            align-items: center;

        }

        .banner3 .banner_content h1 {
            font-weight: 900;
            text-transform: uppercase;
        }

        .banner3 .banner_button {
            background: transparent;
            border-radius: 30px;
            color: #000;
            width: 300px;
            font-weight: 700;
            padding: 10px 20px;
            margin-bottom: 20px;
            margin-top: 10px;
            border: 3px solid #000;
        }

        .banner3 .banner_button:hover {
            background: #000;
            color: #fff;
        }

        .banner3 .apps_link ul {
            width: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
        }

        .banner3 .apps_link ul li {
            float: left;
            width: 200px;
            margin: 10px;
            list-style: none;
        }
        .banner3 .registration_form{
            text-align: center;
            padding: 5% 5%;
            min-height: 300px;
            display: flex;
            justify-content: center;
            align-items: center;
           
        }
       .banner3 .form-group_div{
           margin: 10px 0px;
        }
        .banner3 .form-group_div input{
            width: 100%;
            height: 38px;
            border: 1px solid #dddddd;
            margin-right: 20px;
        }
        .banner3 .form-group_div label{
            display: block;
            text-align: left;
        }
        .banner3  .btnSubmit{
            width: 100%;
            margin-top: 20px;
            background: #d4a2a2;
            color: #fff;
            padding-left: 30px;
            padding-right: 30px;
        }
        .banner3 .registration_form{
            margin-bottom: 20px;
            text-align: left;
        }
        .banner3 .registration_form h2{
            color: #fff;
        }
        .social_link{
            cursor: pointer;
        }
        .label_name{
            color: #fff;
        }
    </style>
    <section>
        <div class="banner3">
            <div class="container">

                <div class="row banner_content" data-gjs-resizable='{"tl":0,"tc":0,"tr":0,"cl":0,"cr":0,"bl":0,"br":0}'>
                    <div class="col-md-6 col-xs-12" data-gjs-resizable='{"tl":0,"tc":0,"tr":0,"cl":0,"bl":0,"br":0,"bc": 0, "keyWidth": "flex-basis", "currentUnit": 1, "minDim": 1, "maxDim": 100, "step": 0.2}'>
                        <h1>Add your own text here.</h1>
                        <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Vitae nostrum odit ratione quidem
                            unde neque harum ipsa enim ut eveniet alias rem vel perferendis, possimus corporis. Quae
                            fuga labore repellendus. Lorem ipsum dolor sit amet, consectetur adipisicing elit. Vitae
                            nostrum odit ratione quidem unde neque harum ipsa enim ut eveniet alias rem vel
                            perferendis, possimus corporis. Quae fuga labore repellendus. Lorem ipsum dolor sit amet,
                            consectetur adipisicing elit. Vitae nostrum odit ratione quidem unde neque harum ipsa enim
                            ut eveniet alias rem vel perferendis, possimus corporis. Quae fuga labore repellendus.</p>
                        
                    </div>
                    <div class="col-md-6 col-xs-12" data-gjs-resizable='{"tl":0,"tc":0,"tr":0,"cl":0,"bl":0,"br":0,"bc": 0, "keyWidth": "flex-basis", "currentUnit": 1, "minDim": 1, "maxDim": 100, "step": 0.2}'>
                        <form class="form">
                            <div class="row registration_form justify-content-center" data-gjs-resizable='{"tl":0,"tc":0,"tr":0,"cl":0,"cr":0,"bl":0,"br":0}'>
                                <div class="">
                                    <h2>Registration</h2>
                                    <p>Lorem ipsum dolor sit, amet consectetur adipisicing elit. Modi repellat excepturi quaerat,</p>
                                    <div class="col-xs-12 form-group_div" data-gjs-resizable='{"tl":0,"tc":0,"tr":0,"cl":0,"bl":0,"br":0,"bc": 0, "keyWidth": "flex-basis", "currentUnit": 1, "minDim": 1, "maxDim": 100, "step": 0.2}'>
                                        <label class="label_name">Username</label>
                                        <input type="text" class="user_name" name="username">
                                    </div>
                                    <div class="col-xs-12 form-group_div" data-gjs-resizable='{"tl":0,"tc":0,"tr":0,"cl":0,"bl":0,"br":0,"bc": 0, "keyWidth": "flex-basis", "currentUnit": 1, "minDim": 1, "maxDim": 100, "step": 0.2}'>
                                        <label class="label_name">Phone Number</label>
                                        <input type="text" class="user_name" name="email">
                                    </div>
                                    <div class="col-xs-12 form-group_div" data-gjs-resizable='{"tl":0,"tc":0,"tr":0,"cl":0,"bl":0,"br":0,"bc": 0, "keyWidth": "flex-basis", "currentUnit": 1, "minDim": 1, "maxDim": 100, "step": 0.2}'>
                                        <label class="label_name">Email</label>
                                        <input type="text" class="user_name" name="email">
                                    </div>
                                    <div class="col-xs-12 submit_btn">
                                        <button class="btn btnSubmit">Register</button>
                                    </div>
                                </div>
                            </div>
                           
                        </form>
                    </div>
                </div>

            </div>
        </div>
    </section>

        `
    })
    toAdd("banner4") && bm.add("banner4", {
        category: "Banners",
        label: "Banner4",
        attributes: { style: "background-image: url('https://s3.us-east-2.amazonaws.com/konnektfunnels-assets/snippets/banner/banner4.png'); background-repeat: no-repeat;background-position: center center;background-size: 250px 100px; " },
        content: `
        <style>
        body {
            padding: 0;
            margin: 0;
            font-family: sans-serif;
        }

        img {
            max-width: 100%;
        }

        .banner4 {
            background: url(https://s3.us-east-2.amazonaws.com/konnektfunnels-assets/snippets/banner/banner_background.png);
            min-height: 500px;
            justify-content: center;
            align-items: center;
            align-items: center;
            padding: 50px 0px;
            background-size: cover;
            background-repeat: no-repeat;
            background-attachment: fixed;
        }

        .banner4 .banner_content {
            display: flex;
            justify-content: center;
            align-items: center;

        }

        .banner4 .banner_content h1 {
            font-weight: 900;
            text-transform: uppercase;
        }

        .banner4 .banner_button {
            background: transparent;
            border-radius: 30px;
            color: #000;
            width: 300px;
            font-weight: 700;
            padding: 10px 20px;
            margin-bottom: 20px;
            margin-top: 10px;
            border: 3px solid #000;
        }

        .banner4 .banner_button:hover {
            background: #000;
            color: #fff;
        }

        .banner4 .apps_link ul {
            width: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
        }

        .banner4 .apps_link ul li {
            float: left;
            width: 200px;
            margin: 10px;
            list-style: none;
        }
    </style>
    <section>
        <div class="banner4">
            <div class="container">
                <div class="row banner_content" data-gjs-resizable='{"tl":0,"tc":0,"tr":0,"cl":0,"cr":0,"bl":0,"br":0}'>
                    <div class="col-md-6 col-xs-12" data-gjs-resizable='{"tl":0,"tc":0,"tr":0,"cl":0,"bl":0,"br":0,"bc": 0, "keyWidth": "flex-basis", "currentUnit": 1, "minDim": 1, "maxDim": 100, "step": 0.2}'>
                        <h1>Add your own text here.</h1>
                        <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Vitae nostrum odit ratione quidem
                            unde neque harum ipsa enim ut eveniet alias rem vel perferendis, possimus corporis. Quae
                            fuga labore repellendus. Lorem ipsum dolor sit amet, consectetur adipisicing elit. Vitae
                            nostrum odit ratione quidem unde neque harum ipsa enim ut eveniet alias rem vel
                            perferendis, possimus corporis. Quae fuga labore repellendus. Lorem ipsum dolor sit amet,
                            consectetur adipisicing elit. Vitae nostrum odit ratione quidem unde neque harum ipsa enim
                            ut eveniet alias rem vel perferendis, possimus corporis. Quae fuga labore repellendus.</p>
                        <div class="apps_link">
                            <ul>
                                <li><a><img src="https://s3.us-east-2.amazonaws.com/konnektfunnels-assets/snippets/banner/google_play.png" title="App on Play store"></a></li>
                                <li><a><img src="https://s3.us-east-2.amazonaws.com/konnektfunnels-assets/snippets/banner/app_store.png" title="App on App store"></a></li>
                            </ul>

                        </div>
                    </div>
                    <div class="col-md-6 col-xs-12" data-gjs-resizable='{"tl":0,"tc":0,"tr":0,"cl":0,"bl":0,"br":0,"bc": 0, "keyWidth": "flex-basis", "currentUnit": 1, "minDim": 1, "maxDim": 100, "step": 0.2}'>
                        <img src="https://s3.us-east-2.amazonaws.com/konnektfunnels-assets/snippets/banner/banner_image.png" alt="Banner Image">
                    </div>
                </div>

            </div>
        </div>
    </section>
        `
    })
    toAdd("banner5") && bm.add("banner5", {
        category: "Banners",
        label: "Banner5",
        attributes: { style: "background-image: url('https://s3.us-east-2.amazonaws.com/konnektfunnels-assets/snippets/banner/banner5.png'); background-repeat: no-repeat;background-position: center center;background-size: 250px 100px; " },
        content: `
        <style>
        body {
            padding: 0;
            margin: 0;
            font-family: sans-serif;
        }

        img {
            max-width: 100%;
        }

        .banner5 {
            background: url(https://s3.us-east-2.amazonaws.com/konnektfunnels-assets/snippets/banner/banner_background.png);
            min-height: 500px;
            justify-content: center;
            align-items: center;
            align-items: center;
           
            background-size: cover;
            background-repeat: no-repeat;

        }

        .banner5 .carousel-item:nth-child(1) {
            background: url(https://s3.us-east-2.amazonaws.com/konnektfunnels-assets/snippets/banner/banner_background.png);
            min-height: 500px;
            justify-content: center;
            align-items: center;
            align-items: center;
            padding: 0px 50px;
            background-size: cover;
            background-repeat: no-repeat;
        }

        .banner5 .carousel-item:nth-child(2) {
            background: url(https://s3.us-east-2.amazonaws.com/konnektfunnels-assets/snippets/banner/back_bg.png);
            min-height: 500px;
            justify-content: center;
            align-items: center;
            align-items: center;
            padding: 0px 50px;
            background-size: cover;
            background-repeat: no-repeat;
        }

        .banner5 .banner_content {
            display: flex;
            justify-content: center;
            align-items: center;
            align-content: center;
            min-height: 662px;
            text-align: center;

        }

        .banner5 .banner_content h1 {
            font-weight: 900;
            text-transform: uppercase;
        }

        .banner5 .banner_button {
            background: transparent;
            border-radius: 30px;
            color: #000;
            width: 300px;
            font-weight: 700;
            padding: 10px 20px;
            margin-bottom: 20px;
            margin-top: 10px;
            border: 3px solid #000;
        }

        .banner5 .banner_button:hover {
            background: #000;
            color: #fff;
        }

        .banner5 .apps_link ul {
            width: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
        }

        .banner5 .apps_link ul li {
            float: left;
            width: 200px;
            margin: 10px;
            list-style: none;
        }
        .banner5 .registration_form{
            text-align: center;
            padding: 5% 5%;
            min-height: 300px;
            display: flex;
            justify-content: center;
            align-items: center;
           
        }
       .banner5 .form-group_div{
           margin: 10px 0px;
        }
        .banner5 .form-group_div input{
            width: 100%;
            height: 38px;
            border: 1px solid #dddddd;
            margin-right: 20px;
        }
        .banner5 .form-group_div label{
            display: block;
            text-align: left;
        }
        .banner5  .btnSubmit{
            width: 100%;
            margin-top: 20px;
            background: #d4a2a2;
            color: #fff;
            padding-left: 30px;
            padding-right: 30px;
        }
        .banner5 .registration_form{
            margin-bottom: 20px;
            text-align: left;
        }
        .banner5 .registration_form h2{
            color: #fff;
        }
        .social_link{
            cursor: pointer;
        }
        .label_name{
            color: #fff;
        }
        .banner5 .banner_button {
            background: transparent;
            border-radius: 30px;
            color: #000;
            width: 300px;
            font-weight: 700;
            padding: 10px 20px;
            margin-bottom: 20px;
            margin-top: 10px;
            border: 3px solid #000;
        }
        .banner5 .banner_button:hover {
            background: #000;
            color: #fff;
        }
    </style>
    <section>
        <div class="banner5">

            <div id="demo" class="carousel slide" data-ride="carousel">
                <ul class="carousel-indicators">
                    <li data-target="#demo" data-slide-to="0" class="active"></li>
                    <li data-target="#demo" data-slide-to="1"></li>
                    <li data-target="#demo" data-slide-to="2"></li>
                </ul>
                <div class="carousel-inner">
                    <div class="carousel-item active">
                        <div class="row banner_content" data-gjs-resizable='{"tl":0,"tc":0,"tr":0,"cl":0,"cr":0,"bl":0,"br":0}'>
                            <div class="col-md-6 col-xs-12" data-gjs-resizable='{"tl":0,"tc":0,"tr":0,"cl":0,"bl":0,"br":0,"bc": 0, "keyWidth": "flex-basis", "currentUnit": 1, "minDim": 1, "maxDim": 100, "step": 0.2}'>
                                <h1>Add your own text here.</h1>
                                <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Vitae nostrum odit ratione
                                    quidem
                                    unde neque harum ipsa enim ut eveniet alias rem vel perferendis, possimus corporis.
                                    Quae
                                    fuga labore repellendus. Lorem ipsum dolor sit amet, consectetur adipisicing elit.
                                    Vitae
                                    nostrum odit ratione quidem unde neque harum ipsa enim ut eveniet alias rem vel
                                    perferendis, possimus corporis. Quae fuga labore repellendus. Lorem ipsum dolor sit
                                    amet,
                                    consectetur adipisicing elit. Vitae nostrum odit ratione quidem unde neque harum
                                    ipsa enim
                                    ut eveniet alias rem vel perferendis, possimus corporis. Quae fuga labore
                                    repellendus.</p>
                                <div class="apps_link">
                                    <ul>
                                        <li><a><img src="https://s3.us-east-2.amazonaws.com/konnektfunnels-assets/snippets/banner/google_play.png" title="App on Play store"></a></li>
                                        <li><a><img src="https://s3.us-east-2.amazonaws.com/konnektfunnels-assets/snippets/banner/app_store.png" title="App on App store"></a></li>
                                    </ul>

                                </div>
                            </div>
                            <div class="col-md-6 col-xs-12" data-gjs-resizable='{"tl":0,"tc":0,"tr":0,"cl":0,"bl":0,"br":0,"bc": 0, "keyWidth": "flex-basis", "currentUnit": 1, "minDim": 1, "maxDim": 100, "step": 0.2}'>
                                <img src="https://s3.us-east-2.amazonaws.com/konnektfunnels-assets/snippets/banner/banner_image.png" alt="Banner Image">
                            </div>
                        </div>
                    </div>
                    <div class="carousel-item ">
                        <div class="row banner_content" data-gjs-resizable='{"tl":0,"tc":0,"tr":0,"cl":0,"cr":0,"bl":0,"br":0}'>
                            <div class="container">

                                <h1>Add your own text here.</h1>
                                <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Vitae nostrum odit ratione
                                    quidem
                                    unde neque harum ipsa enim ut eveniet alias rem vel perferendis, possimus corporis.
                                    Quae
                                    fuga labore repellendus. Lorem ipsum dolor sit amet, consectetur adipisicing elit.
                                    Vitae
                                    nostrum odit ratione quidem unde neque harum ipsa enim ut eveniet alias rem vel
                                    perferendis, possimus corporis. Quae fuga labore repellendus. Lorem ipsum dolor sit
                                    amet,
                                    consectetur adipisicing elit. Vitae nostrum odit ratione quidem unde neque harum
                                    ipsa enim
                                    ut eveniet alias rem vel perferendis, possimus corporis. Quae fuga labore
                                    repellendus.</p>
                                <div class="banner_btn">
                                    <button class="btn banner_button">Button</button>
                                </div>

                            </div>


                        </div>
                    </div>
                    <div class="carousel-item ">
                        <div class="row banner_content" data-gjs-resizable='{"tl":0,"tc":0,"tr":0,"cl":0,"cr":0,"bl":0,"br":0}'>
                            <div class="col-md-6 col-xs-12">
                                <h1>Add your own text here.</h1>
                                <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Vitae nostrum odit ratione
                                    quidem
                                    unde neque harum ipsa enim ut eveniet alias rem vel perferendis, possimus corporis.
                                    Quae
                                    fuga labore repellendus. Lorem ipsum dolor sit amet, consectetur adipisicing elit.
                                    Vitae
                                    nostrum odit ratione quidem unde neque harum ipsa enim ut eveniet alias rem vel
                                    perferendis, possimus corporis. Quae fuga labore repellendus. Lorem ipsum dolor sit
                                    amet,
                                    consectetur adipisicing elit. Vitae nostrum odit ratione quidem unde neque harum
                                    ipsa enim
                                    ut eveniet alias rem vel perferendis, possimus corporis. Quae fuga labore
                                    repellendus.</p>

                            </div>
                            <div class="col-md-6 col-xs-12" data-gjs-resizable='{"tl":0,"tc":0,"tr":0,"cl":0,"bl":0,"br":0,"bc": 0, "keyWidth": "flex-basis", "currentUnit": 1, "minDim": 1, "maxDim": 100, "step": 0.2}'>
                                <form class="form">
                                    <div class="row registration_form justify-content-center" data-gjs-resizable='{"tl":0,"tc":0,"tr":0,"cl":0,"cr":0,"bl":0,"br":0}'>
                                        <div class="">
                                            <h2>Registration</h2>
                                            <p>Lorem ipsum dolor sit, amet consectetur adipisicing elit. Modi repellat
                                                excepturi quaerat,</p>
                                            <div class="col-xs-12 form-group_div" data-gjs-resizable='{"tl":0,"tc":0,"tr":0,"cl":0,"bl":0,"br":0,"bc": 0, "keyWidth": "flex-basis", "currentUnit": 1, "minDim": 1, "maxDim": 100, "step": 0.2}'>
                                                <label class="label_name">Username</label>
                                                <input type="text" class="user_name" name="username">
                                            </div>
                                            <div class="col-xs-12 form-group_div" data-gjs-resizable='{"tl":0,"tc":0,"tr":0,"cl":0,"bl":0,"br":0,"bc": 0, "keyWidth": "flex-basis", "currentUnit": 1, "minDim": 1, "maxDim": 100, "step": 0.2}'>
                                                <label class="label_name">Phone Number</label>
                                                <input type="text" class="user_name" name="email">
                                            </div>
                                            <div class="col-xs-12 form-group_div" data-gjs-resizable='{"tl":0,"tc":0,"tr":0,"cl":0,"bl":0,"br":0,"bc": 0, "keyWidth": "flex-basis", "currentUnit": 1, "minDim": 1, "maxDim": 100, "step": 0.2}'>
                                                <label class="label_name">Email</label>
                                                <input type="text" class="user_name" name="email">
                                            </div>
                                            <div class="col-xs-12 submit_btn">
                                                <button class="btn btnSubmit">Register</button>
                                            </div>
                                        </div>
                                    </div>

                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    </section>
        `
    })
    toAdd("banner6") && bm.add("banner6", {
        category: "Banners",
        label: "Banner6",
        attributes: { style: "background-image: url('https://s3.us-east-2.amazonaws.com/konnektfunnels-assets/snippets/banner/banner6.png'); background-repeat: no-repeat;background-position: center center;background-size: 250px 100px; " },
        content: `
        <style>
        body {
            padding: 0;
            margin: 0;
            font-family: sans-serif;
        }

        img {
            max-width: 100%;
        }

        .banner6 {
            background: url(https://s3.us-east-2.amazonaws.com/konnektfunnels-assets/snippets/banner/banner_background.png);
            min-height: 500px;
            display: flex;
            justify-content: center;
            align-items: center;
            padding: 50px 0px;
            background-size: cover;
        }

        .banner6 .banner_content {
            display: flex;
            justify-content: center;
            align-items: center;
            flex-flow: column;
        }

        .banner6 .banner_content h1 {
            font-weight: 900;
            text-transform: uppercase;
        }

        .banner6 .banner_button {
            background: transparent;
            border-radius: 30px;
            color: #000;
            width: 300px;
            font-weight: 700;
            padding: 10px 20px;
            margin-bottom: 20px;
            margin-top: 10px;
            border: 3px solid #000;
        }

        .banner6 .banner_button:hover {
            background: #000;
            color: #fff;
        }

        .video_description_view {
            text-align: center;
            margin-top: 30px;
            margin-bottom: 30px;
        }
        .video_description_view iframe{
            max-width: 100%;
            max-height: 400px;
            width: 600px;
            height: 300px;
        }
    </style>
    <section>
        <div class="banner6">
            <div class="container">
                <div class="banner_content">
                    <h1>Add your own text here.</h1>
                    <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Vitae nostrum odit ratione quidem unde
                        neque harum ipsa enim ut eveniet alias rem vel perferendis, possimus corporis. Quae fuga labore
                        repellendus. Lorem ipsum dolor sit amet, consectetur adipisicing elit. Vitae nostrum odit
                        ratione quidem unde neque harum ipsa enim ut eveniet alias rem vel perferendis, possimus
                        corporis. Quae fuga labore repellendus. Lorem ipsum dolor sit amet, consectetur adipisicing
                        elit. Vitae nostrum odit ratione quidem unde neque harum ipsa enim ut eveniet alias rem vel
                        perferendis, possimus corporis. Quae fuga labore repellendus.</p>
                    <div class="video_description_view">
                        <iframe width="420" height="315" src="https://www.youtube.com/embed/tgbNymZ7vqY">
                        </iframe>

                    </div>
                    <div class="banner_btn">
                        <button class="btn banner_button">Button</button>
                    </div>
                </div>
            </div>
        </div>
    </section>
        `
    })
    toAdd("banner7") && bm.add("banner7", {
        category: "Banners",
        label: "Banner7",
        attributes: { style: "background-image: url('https://s3.us-east-2.amazonaws.com/konnektfunnels-assets/snippets/banner/banner7.png'); background-repeat: no-repeat;background-position: center center;background-size: 250px 100px; " },
        content: `
        <style>
        body {
            padding: 0;
            margin: 0;
            font-family: sans-serif;
        }

        img {
            max-width: 100%;
        }

        .banner7 {
            background: url(https://s3.us-east-2.amazonaws.com/konnektfunnels-assets/snippets/banner/banner_background.png);
            min-height: 500px;
            display: flex;
            justify-content: center;
            align-items: center;
            padding: 50px 0px;
            background-size: cover;
            background-position: center;
        }

        .banner7 .banner_content {
            display: flex;
            justify-content: center;
            align-items: center;

        }

        .banner7 .banner_content h1 {
            font-weight: 900;
            text-transform: uppercase;
        }

        .banner7 .banner_button {
            background: transparent;
            border-radius: 30px;
            color: #000;
            width: 300px;
            font-weight: 700;
            padding: 10px 20px;
            margin-bottom: 20px;
            margin-top: 10px;
            border: 3px solid #000;
        }

        .banner7 .banner_button:hover {
            background: #000;
            color: #fff;
        }

        .video_description_view {
            text-align: center;
            margin-top: 30px;
            margin-bottom: 30px;
        }

        .video_description_view iframe {
            max-width: 100%;
            max-height: 400px;
            width: 600px;
            height: 300px;
        }
    </style>
    <section>
        <div class="banner7">
            <div class="container">
                <div class="row banner_content" data-gjs-resizable='{"tl":0,"tc":0,"tr":0,"cl":0,"cr":0,"bl":0,"br":0}'>
                    <div class="col-lg-6 col-md-12 col-xs-12 col-sm-12 " data-gjs-resizable='{"tl":0,"tc":0,"tr":0,"cl":0,"bl":0,"br":0,"bc": 0, "keyWidth": "flex-basis", "currentUnit": 1, "minDim": 1, "maxDim": 100, "step": 0.2}'>
                        <h1>Add your own text here.</h1>
                        <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Vitae nostrum odit ratione quidem
                            unde
                            neque harum ipsa enim ut eveniet alias rem vel perferendis, possimus corporis. Quae fuga
                            labore
                            repellendus. Lorem ipsum dolor sit amet, consectetur adipisicing elit. Vitae nostrum odit
                            ratione quidem unde neque harum ipsa enim ut eveniet alias rem vel perferendis, possimus
                            corporis. Quae fuga labore repellendus. Lorem ipsum dolor sit amet, consectetur adipisicing
                            elit. Vitae nostrum odit ratione quidem unde neque harum ipsa enim ut eveniet alias rem vel
                            perferendis, possimus corporis. Quae fuga labore repellendus.</p>
                        <div class="banner_btn">
                            <button class="btn banner_button">Button</button>
                        </div>
                    </div>
                    <div class="col-lg-6 col-md-12 col-xs-12 col-sm-12 " data-gjs-resizable='{"tl":0,"tc":0,"tr":0,"cl":0,"bl":0,"br":0,"bc": 0, "keyWidth": "flex-basis", "currentUnit": 1, "minDim": 1, "maxDim": 100, "step": 0.2}'>
                        <div class="video_description_view">
                            <iframe width="420" height="315" src="https://www.youtube.com/embed/tgbNymZ7vqY">
                            </iframe>

                        </div>
                    </div>

                </div>
            </div>
        </div>
    </section>
        `
    })
    toAdd("banner8") && bm.add("banner8", {
        category: "Banners",
        label: "Banner8",
        attributes: { style: "background-image: url('https://s3.us-east-2.amazonaws.com/konnektfunnels-assets/snippets/banner/banner8.png'); background-repeat: no-repeat;background-position: center center;background-size: 250px 100px; " },
        content: `
        <style>
        body {
            padding: 0;
            margin: 0;
            font-family: sans-serif;
        }

        img {
            max-width: 100%;
        }

        .banner8 {
            background: url(https://s3.us-east-2.amazonaws.com/konnektfunnels-assets/snippets/banner/banner_background.png);
            min-height: 500px;
            display: flex;
            justify-content: center;
            align-items: center;
            padding: 50px 0px;
            background-size: cover;
            background-position: center;
        }

        .banner8 .banner_content {
            display: flex;
            justify-content: center;
            align-items: center;
           
        }

        .banner8 .banner_content h1 {
            font-weight: 900;
            text-transform: uppercase;
        }

        .banner8 .banner_button {
            background: transparent;
            border-radius: 30px;
            color: #000;
            width: 300px;
            font-weight: 700;
            padding: 10px 20px;
            margin-bottom: 20px;
            margin-top: 10px;
            border: 3px solid #000;
        }

        .banner8 .banner_button:hover {
            background: #000;
            color: #fff;
        }

        .video_description_view {
            text-align: center;
            margin-top: 30px;
            margin-bottom: 30px;
        }

        .video_description_view iframe {
            max-width: 100%;
            max-height: 400px;
            width: 600px;
            height: 300px;
        }
    </style>
    <section>
        <div class="banner8">
            <div class="container">
                <div class="row banner_content" data-gjs-resizable='{"tl":0,"tc":0,"tr":0,"cl":0,"cr":0,"bl":0,"br":0}'>
                    <div class="col-lg-6 col-md-12 col-xs-12 col-sm-12 " data-gjs-resizable='{"tl":0,"tc":0,"tr":0,"cl":0,"bl":0,"br":0,"bc": 0, "keyWidth": "flex-basis", "currentUnit": 1, "minDim": 1, "maxDim": 100, "step": 0.2}'>
                        <h1>Add your own text here.</h1>
                        <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Vitae nostrum odit ratione quidem
                            unde
                            neque harum ipsa enim ut eveniet alias rem vel perferendis, possimus corporis. Quae fuga
                            labore
                            repellendus. Lorem ipsum dolor sit amet, consectetur adipisicing elit. Vitae nostrum odit
                            ratione quidem unde neque harum ipsa enim ut eveniet alias rem vel perferendis, possimus
                            corporis. Quae fuga labore repellendus. Lorem ipsum dolor sit amet, consectetur adipisicing
                            elit. Vitae nostrum odit ratione quidem unde neque harum ipsa enim ut eveniet alias rem vel
                            perferendis, possimus corporis. Quae fuga labore repellendus.</p>

                        
                    </div>
                    <div class="col-lg-6 col-md-12 col-xs-12 col-sm-12 " data-gjs-resizable='{"tl":0,"tc":0,"tr":0,"cl":0,"bl":0,"br":0,"bc": 0, "keyWidth": "flex-basis", "currentUnit": 1, "minDim": 1, "maxDim": 100, "step": 0.2}'>
                        <div class="video_description_view">
                            <iframe width="420" height="315" src="https://www.youtube.com/embed/tgbNymZ7vqY">
                            </iframe>

                        </div>
                    </div>
                    <div class="banner_btn">
                            <button class="btn banner_button">Button</button>
                        </div>
                </div>
            </div>
        </div>
    </section>
        `
    })


    //buttons
    toAdd("button1") && bm.add("button1", {
        category: "Buttons",
        label: "Button1",
        attributes: { style: "background-image: url('https://s3.us-east-2.amazonaws.com/konnektfunnels-assets/snippets/buttons/button.png'); background-repeat: no-repeat;background-position: center center;background-size:100% 100%; " },
        content: `
            <style>
                    .btn {
                        /* display: block; */
                        display: inline-block;
                        font-weight: 400;
                        text-align: center;
                        white-space: nowrap;
                        vertical-align: middle;
                        -webkit-user-select: none;
                        -moz-user-select: none;
                        -ms-user-select: none;
                        user-select: none;
                        border: 1px solid transparent;
                        padding: .375rem .75rem;
                        font-size: 1rem;
                        line-height: 1.5;
                        border-radius: .25rem;
                        transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
                        margin: 20px 10px;
                    }
                    .btn-primary {
                        background: #007bff;
                        color: #fff;
                        border-color: #007bff;
                    }
            </style>
            <button class="btn btn-primary">Save/Submit</button>`
    });
    toAdd("button2") && bm.add("button2", {
        category: "Buttons",
        label: "Button2",
        attributes: { style: "background-image: url('https://s3.us-east-2.amazonaws.com/konnektfunnels-assets/snippets/buttons/roundButton.png'); background-repeat: no-repeat;background-position: center;background-size: 100% 100%; " },
        content: `
            <style>
                    .btn {
                        /* display: block; */
                        display: inline-block;
                        font-weight: 400;
                        text-align: center;
                        white-space: nowrap;
                        vertical-align: middle;
                        -webkit-user-select: none;
                        -moz-user-select: none;
                        -ms-user-select: none;
                        user-select: none;
                        border: 1px solid transparent;
                        padding: .375rem .75rem;
                        font-size: 1rem;
                        line-height: 1.5;
                        border-radius: .25rem;
                        transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
                        margin: 20px 10px;
                    }
                    .btn.btn-primary.btnStyle:hover {
                        background: #007bff;
                        color: #fff;
                        border-color: #007bff;
                    }

                    .btn.btn-primary.btnStyle {
                        background: #f4f4f4;
                        color: #007bff;
                        border-color: #007bff;
                        border-radius: 30px;
                        border-width: 3px;
                        padding: 6px 20px;
                    }

            </style>
            <button class="btn btn-primary btnStyle">Save/Submit</button>`
    });


    //FEATURES

    toAdd("feature1") && bm.add("feature1", {
        category: "Features",
        label: "Feature1",
        attributes: { style: "background-image: url('https://s3.us-east-2.amazonaws.com/konnektfunnels-assets/snippets/feature/features1.png'); background-repeat: no-repeat;background-position: center center;background-size: 250px 100px; " },
        content: `
        <style>
        body {
            padding: 0;
            margin: 0;
            font-family:sans-serif;
        }

        iframe {
            max-width: 100%;
            max-height: 400px;
            width: 600px;
            height: 300px;
        }
        img {
            max-width: 100%;
        }
        .feature1 .row.feature_content > div {
            padding: 5px;
        }
        .feature1 {
            background: url('https://s3.us-east-2.amazonaws.com/konnektfunnels-assets/snippets/feature/feature1.png') no-repeat;
            background-position: center;
            padding: 50px;
            text-align: center;
            background-size: cover;
          
        }
        .feature1 > .container > h1,.feature1  > .container  > p {
            color:#fff;
        }
        .feature1  .blocks img{
            max-height: 100px;
            min-height: 100px;
            width: 100px;
            margin: 20px 0px;
            border-radius: 50%;
        }
        .feature1  .blocks h2{
            font-weight:700;
        }
        .feature1  .blocks p{
            font-size:14px;
        }
        .feature1  .blocks{
            background: #fff;
            padding: 15px 0px;
            margin: 20px 0px;
            display: flex;
            flex-flow: column;
            justify-content: center;
            align-items: center;
        }
        .feature1  .blocks:hover{
            background: #231515ab;
            color: #fff;
            box-shadow: 0px 0px 52px -3px #000;
        }
        .feature1  .blocks button.button_style{
            background: lightseagreen;
            color: #fff;
            padding: 6px 40px;
            border-radius: 30px;
            border: 0;
        }
        .feature1  .blocks button.button_style:hover{
            box-shadow: 3px 3px #000;
            background: #f4f4f4;
            color: #000;
        }
        @media (max-width : 990px){
            .feature1{
                background-position:unset;
            }
        }
    </style>
    <section>
        <div class="feature1">
            <div class="container">
                <h1>ADD YOUR TEXT HERE</h1>
                <p>Lorem, ipsum dolor sit amet consectetur adipisicing elit. Facere assumenda nesciunt dicta earum, non, et quibusdam quis hic vel molestiae magnam necessitatibus consectetur veritatis voluptatibus cumque tempora, ullam nisi dolorum.</p>
                <div class="row feature_content" data-gjs-resizable='{"tl":0,"tc":0,"tr":0,"cl":0,"cr":0,"bl":0,"br":0}'>
                    <div class="col-lg-3 col-md-6  col-xs-12 " data-gjs-resizable='{"tl":0,"tc":0,"tr":0,"cl":0,"bl":0,"br":0,"bc": 0, "keyWidth": "flex-basis", "currentUnit": 1, "minDim": 1, "maxDim": 100, "step": 0.2}'>
                        <div class="block_1_content blocks">
                            <img src="https://s3.us-east-2.amazonaws.com/konnektfunnels-assets/snippets/feature/image1.png" class="">
                            <h2>Add your text here</h2>
                            <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Cupiditate porro enim molestiae
                                corrupti officia pariatur cumque deserunt officiis libero! Excepturi, perferendis
                               </p>
                            <button class="button_style">Button</button>
                        </div>
                    </div>

                    <div class="col-lg-3 col-md-6  col-xs-12 " data-gjs-resizable='{"tl":0,"tc":0,"tr":0,"cl":0,"bl":0,"br":0,"bc": 0, "keyWidth": "flex-basis", "currentUnit": 1, "minDim": 1, "maxDim": 100, "step": 0.2}'>
                        <div class="block_2_content blocks">
                            <img src="https://s3.us-east-2.amazonaws.com/konnektfunnels-assets/snippets/feature/image2.png" class="">
                            <h2>Add your text here</h2>
                            <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Cupiditate porro enim molestiae
                                corrupti officia pariatur cumque deserunt officiis libero! Excepturi, perferendis
                               </p>
                            <button class="button_style">Button</button>
                        </div>
                    </div>
                    <div class="col-lg-3 col-md-6  col-xs-12 " data-gjs-resizable='{"tl":0,"tc":0,"tr":0,"cl":0,"bl":0,"br":0,"bc": 0, "keyWidth": "flex-basis", "currentUnit": 1, "minDim": 1, "maxDim": 100, "step": 0.2}'>
                        <div class="block_3_content blocks">
                            <img src="https://s3.us-east-2.amazonaws.com/konnektfunnels-assets/snippets/feature/image3.png" class="">
                            <h2>Add your text here</h2>
                            <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Cupiditate porro enim molestiae
                                corrupti officia pariatur cumque deserunt officiis libero! Excepturi, perferendis
                               </p>
                            <button class="button_style">Button</button>
                        </div>
                    </div>
                    <div class="col-lg-3 col-md-6  col-xs-12 " data-gjs-resizable='{"tl":0,"tc":0,"tr":0,"cl":0,"bl":0,"br":0,"bc": 0, "keyWidth": "flex-basis", "currentUnit": 1, "minDim": 1, "maxDim": 100, "step": 0.2}'>
                            <div class="block_3_content blocks">
                                <img src="https://s3.us-east-2.amazonaws.com/konnektfunnels-assets/snippets/feature/image4.png" class="">
                                <h2>Add your text here</h2>
                                <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Cupiditate porro enim molestiae
                                    corrupti officia pariatur cumque deserunt officiis libero! Excepturi, perferendis
                                   </p>
                                <button class="button_style">Button</button>
                            </div>
                        </div>
                </div>
            </div>
        </div>
    </section>
        `
    })
    toAdd("feature2") && bm.add("feature2", {
        category: "Features",
        label: "Feature2",
        attributes: { style: "background-image: url('https://s3.us-east-2.amazonaws.com/konnektfunnels-assets/snippets/feature/features2.png'); background-repeat: no-repeat;background-position: center center;background-size: 250px 100px; " },
        content: `
        <style>
        body {
            padding: 0;
            margin: 0;
            font-family:sans-serif;
        }

        iframe {
            max-width: 100%;
            max-height: 400px;
            width: 600px;
            height: 300px;
        }

        img {
            max-width: 100%;
        }

        .feature2_bg_image {
            background: url('https://s3.us-east-2.amazonaws.com/konnektfunnels-assets/snippets/feature/background_image.png') no-repeat;
            background-position: bottom;
            padding: 50px;
            background-size: cover;
        }
        .feature2_bg_image  .blocks img{
            max-height: 120px;
            min-height: 120px;
            margin: 20px 0px;
        }
        .feature2_bg_image  .blocks h2{
            font-weight:700;
        }
        .feature2_bg_image  .blocks{
            background: #f4f4f4ab;
            padding: 15px;
            margin: 20px 0px;
            display: flex;
            flex-flow: column;
            justify-content: center;
            align-items: center;
        }
        .feature2_bg_image  .blocks:hover{
            background: #231515ab;
            color: #fff;
            box-shadow: 0px 0px 52px -3px #000;
        }
        .feature2_bg_image  .blocks button.button_style{
            background: lightseagreen;
            color: #fff;
            padding: 6px 40px;
            border-radius: 30px;
            border: 0;
        }
        .feature2_bg_image  .blocks button.button_style:hover{
            box-shadow: 3px 3px #000;
            background: #f4f4f4;
            color: #000;
        }
        @media (max-width : 990px){
            .feature2_bg_image{
                background-position:unset;
            }
        }
    </style>
    <section>
        <div class="feature2_bg_image">
            <div class="container">
                <div class="row feature_content" data-gjs-resizable='{"tl":0,"tc":0,"tr":0,"cl":0,"cr":0,"bl":0,"br":0}'>
                    <div class="col-lg-6 col-md-6 col-xs-12 " data-gjs-resizable='{"tl":0,"tc":0,"tr":0,"cl":0,"bl":0,"br":0,"bc": 0, "keyWidth": "flex-basis", "currentUnit": 1, "minDim": 1, "maxDim": 100, "step": 0.2}'>
                        <div class="block_1_content blocks">
                            <img src="https://s3.us-east-2.amazonaws.com/konnektfunnels-assets/snippets/feature/image1.png" class="">
                            <h2>Add your text here</h2>
                            <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Cupiditate porro enim molestiae
                                corrupti officia pariatur cumque deserunt officiis libero! Excepturi, perferendis
                                doloribus deserunt culpa dolorum id nulla eos possimus necessitatibus</p>
                            <button class="button_style">Button</button>
                        </div>
                    </div>

                    <div class="col-lg-6 col-md-6 col-xs-12 " data-gjs-resizable='{"tl":0,"tc":0,"tr":0,"cl":0,"bl":0,"br":0,"bc": 0, "keyWidth": "flex-basis", "currentUnit": 1, "minDim": 1, "maxDim": 100, "step": 0.2}'>
                        <div class="block_2_content blocks">
                            <img src="https://s3.us-east-2.amazonaws.com/konnektfunnels-assets/snippets/feature/image2.png" class="">
                            <h2>Add your text here</h2>
                            <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Cupiditate porro enim molestiae
                                corrupti officia pariatur cumque deserunt officiis libero! Excepturi, perferendis
                                doloribus deserunt culpa dolorum id nulla eos possimus necessitatibus</p>
                            <button class="button_style">Button</button>
                        </div>
                    </div>
                    <div class="col-lg-6 col-md-6 col-xs-12 " data-gjs-resizable='{"tl":0,"tc":0,"tr":0,"cl":0,"bl":0,"br":0,"bc": 0, "keyWidth": "flex-basis", "currentUnit": 1, "minDim": 1, "maxDim": 100, "step": 0.2}'>
                        <div class="block_3_content blocks">
                            <img src="https://s3.us-east-2.amazonaws.com/konnektfunnels-assets/snippets/feature/image3.png" class="">
                            <h2>Add your text here</h2>
                            <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Cupiditate porro enim molestiae
                                corrupti officia pariatur cumque deserunt officiis libero! Excepturi, perferendis
                                doloribus deserunt culpa dolorum id nulla eos possimus necessitatibus</p>
                            <button class="button_style">Button</button>
                        </div>
                    </div>
                    <div class="col-lg-6 col-md-6 col-xs-12 " data-gjs-resizable='{"tl":0,"tc":0,"tr":0,"cl":0,"bl":0,"br":0,"bc": 0, "keyWidth": "flex-basis", "currentUnit": 1, "minDim": 1, "maxDim": 100, "step": 0.2}'>
                            <div class="block_1_content blocks">
                                <img src="https://s3.us-east-2.amazonaws.com/konnektfunnels-assets/snippets/feature/image4.png" class="">
                                <h2>Add your text here</h2>
                                <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Cupiditate porro enim molestiae
                                    corrupti officia pariatur cumque deserunt officiis libero! Excepturi, perferendis
                                    doloribus deserunt culpa dolorum id nulla eos possimus necessitatibus</p>
                                <button class="button_style">Button</button>
                            </div>
                        </div>
                </div>
            </div>
        </div>
    </section>
        `
    })
    toAdd("feature3") && bm.add("feature3", {
        category: "Features",
        label: "Feature3",
        attributes: { style: "background-image: url('https://s3.us-east-2.amazonaws.com/konnektfunnels-assets/snippets/feature/features3.png'); background-repeat: no-repeat;background-position: center center;background-size: 250px 100px; " },
        content: `
        <style>
        body {
            padding: 0;
            margin: 0;
            font-family:sans-serif;
        }

        iframe {
            max-width: 100%;
            max-height: 400px;
            width: 600px;
            height: 300px;
        }
        img {
            max-width: 100%;
        }

        .feature3 {
            /* background: url('./images/background_image.png') no-repeat; */
            background-position: bottom;
            padding: 50px;
            text-align: center;
            background-size: cover;
          
        }
        .feature3  > h1,.feature3    > p {
            color:#363f4f;
        }
        .feature3  .blocks img{
            max-height: 150px;
            min-height: 150px;
            width: 150px;
            margin: 20px 0px;
            object-fit: cover;
            border-radius: 50%;
        }
        .feature3  .blocks h2{
            font-weight:700;
        }
        .feature3  .blocks p{
            font-size:14px;
        }
        .feature3  .blocks{
            background: #f4f4f4ab;
            padding: 15px;
            margin: 20px 0px;
            display: flex;
            flex-flow: column;
            justify-content: center;
            align-items: center;
            box-shadow: 10px -3px 10px 0px #e4d6d6;
        }
        .feature3  .blocks:hover{
            background: #231515ab;
            color: #fff;
            box-shadow: 0px 0px 52px -3px #000;
        }
        .feature3  .blocks button.button_style{
            background: #4a5569;
            color: #fff;
            padding: 6px 40px;
            border-radius: 30px;
            border: 2px solid #fff;
        }
        .feature3  .blocks button.button_style:hover{
            box-shadow: 3px 3px #000;
            background: #f4f4f4;
            color: #000;
        }
        @media (max-width : 990px){
            .feature3{
                background-position:unset;
            }
        }
    </style>
    <section>
        <div class="feature3">
            
                <h1>ADD YOUR TEXT HERE</h1>
                <p>Lorem, ipsum dolor sit amet consectetur adipisicing elit. Facere assumenda nesciunt dicta earum, non, et quibusdam quis hic vel molestiae magnam necessitatibus consectetur veritatis voluptatibus cumque tempora, ullam nisi dolorum.</p>
                <div class="row feature_content" data-gjs-resizable='{"tl":0,"tc":0,"tr":0,"cl":0,"cr":0,"bl":0,"br":0}'>
                    <div class="col-lg-3 col-md-6  col-xs-12 " data-gjs-resizable='{"tl":0,"tc":0,"tr":0,"cl":0,"bl":0,"br":0,"bc": 0, "keyWidth": "flex-basis", "currentUnit": 1, "minDim": 1, "maxDim": 100, "step": 0.2}'>
                        <div class="block_1_content blocks">
                            <img src="https://s3.us-east-2.amazonaws.com/konnektfunnels-assets/snippets/feature/image1.png" class="">
                            <h2>Add your text here</h2>
                            <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Cupiditate porro enim molestiae
                                corrupti officia pariatur cumque deserunt officiis libero! Excepturi, perferendis
                                doloribus deserunt culpa dolorum id nulla eos possimus necessitatibus</p>
                            <button class="button_style">Button</button>
                        </div>
                    </div>

                    <div class="col-lg-3 col-md-6  col-xs-12 " data-gjs-resizable='{"tl":0,"tc":0,"tr":0,"cl":0,"bl":0,"br":0,"bc": 0, "keyWidth": "flex-basis", "currentUnit": 1, "minDim": 1, "maxDim": 100, "step": 0.2}'>
                        <div class="block_2_content blocks">
                            <img src="https://s3.us-east-2.amazonaws.com/konnektfunnels-assets/snippets/feature/image2.png" class="">
                            <h2>Add your text here</h2>
                            <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Cupiditate porro enim molestiae
                                corrupti officia pariatur cumque deserunt officiis libero! Excepturi, perferendis
                                doloribus deserunt culpa dolorum id nulla eos possimus necessitatibus</p>
                            <button class="button_style">Button</button>
                        </div>
                    </div>
                    <div class="col-lg-3 col-md-6  col-xs-12 " data-gjs-resizable='{"tl":0,"tc":0,"tr":0,"cl":0,"bl":0,"br":0,"bc": 0, "keyWidth": "flex-basis", "currentUnit": 1, "minDim": 1, "maxDim": 100, "step": 0.2}'>
                        <div class="block_3_content blocks">
                            <img src="https://s3.us-east-2.amazonaws.com/konnektfunnels-assets/snippets/feature/image3.png" class="">
                            <h2>Add your text here</h2>
                            <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Cupiditate porro enim molestiae
                                corrupti officia pariatur cumque deserunt officiis libero! Excepturi, perferendis
                                doloribus deserunt culpa dolorum id nulla eos possimus necessitatibus</p>
                            <button class="button_style">Button</button>
                        </div>
                    </div>
                    <div class="col-lg-3 col-md-6  col-xs-12 " data-gjs-resizable='{"tl":0,"tc":0,"tr":0,"cl":0,"bl":0,"br":0,"bc": 0, "keyWidth": "flex-basis", "currentUnit": 1, "minDim": 1, "maxDim": 100, "step": 0.2}'>
                            <div class="block_3_content blocks">
                                <img src="https://s3.us-east-2.amazonaws.com/konnektfunnels-assets/snippets/feature/image4.png" class="">
                                <h2>Add your text here</h2>
                                <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Cupiditate porro enim molestiae
                                    corrupti officia pariatur cumque deserunt officiis libero! Excepturi, perferendis
                                    doloribus deserunt culpa dolorum id nulla eos possimus necessitatibus</p>
                                <button class="button_style">Button</button>
                            </div>
                        </div>
                </div>
            </div>
     
    </section>
    
        `
    })
    toAdd("feature4") && bm.add("feature4", {
        category: "Features",
        label: "Feature4",
        attributes: { style: "background-image: url('https://s3.us-east-2.amazonaws.com/konnektfunnels-assets/snippets/feature/features4.png'); background-repeat: no-repeat;background-position: center center;background-size: 250px 100px; " },
        content: `
            <style>
        body {
            padding: 0;
            margin: 0;
            font-family:sans-serif;
        }

        iframe {
            max-width: 100%;
            max-height: 400px;
            width: 600px;
            height: 300px;
        }
        img {
            max-width: 100%;
        }

        .feature4 {
            background: url(https://s3.us-east-2.amazonaws.com/konnektfunnels-assets/snippets/feature/bg_image.png) no-repeat;
            background-position: center;
            padding: 50px;
            text-align: center;
            background-size: cover;
          
        }
        .feature4 > .container > h1,.feature4  > .container  > p {
            color:#0d4773;
        }
        .feature4  .blocks img{
            max-height: 200px;
            min-height: 200px;
            width: 100%;
            margin: 20px 0px;
        }
        .feature4  .blocks h2{
            font-weight:700;
        }
        .feature4  .blocks p{
            font-size:14px;
        }
        .feature4  .blocks{
            background: #f4f4f4ab;
            padding: 15px;
            margin: 20px 0px;
            display: flex;
            flex-flow: column;
            justify-content: center;
            align-items: center;
        }
        .feature4  .blocks:hover{
            background: #231515ab;
            color: #fff;
            box-shadow: 0px 0px 52px -3px #000;
        }
        @media (max-width : 990px){
            .feature4{
                background-position:unset;
            }
        }
    </style>
    <section>
        <div class="feature4">
            <div class="container">
                <h1>ADD YOUR TEXT HERE</h1>
                <p>Lorem, ipsum dolor sit amet consectetur adipisicing elit. Facere assumenda nesciunt dicta earum, non, et quibusdam quis hic vel molestiae magnam necessitatibus consectetur veritatis voluptatibus cumque tempora, ullam nisi dolorum.</p>
                <div class="row feature_content" data-gjs-resizable='{"tl":0,"tc":0,"tr":0,"cl":0,"cr":0,"bl":0,"br":0}'>
                    <div class="col-lg-4 col-md-6  col-xs-12 " data-gjs-resizable='{"tl":0,"tc":0,"tr":0,"cl":0,"bl":0,"br":0,"bc": 0, "keyWidth": "flex-basis", "currentUnit": 1, "minDim": 1, "maxDim": 100, "step": 0.2}'>
                        <div class="block_1_content blocks">
                            <img src="https://s3.us-east-2.amazonaws.com/konnektfunnels-assets/snippets/feature/image1.png" class="">
                            <h2>Add your text here</h2>
                            <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Cupiditate porro enim molestiae
                                corrupti officia pariatur cumque deserunt officiis libero! Excepturi, perferendis
                                doloribus deserunt culpa dolorum id nulla eos possimus necessitatibus</p>
                        </div>
                    </div>

                    <div class="col-lg-4 col-md-6  col-xs-12 " data-gjs-resizable='{"tl":0,"tc":0,"tr":0,"cl":0,"bl":0,"br":0,"bc": 0, "keyWidth": "flex-basis", "currentUnit": 1, "minDim": 1, "maxDim": 100, "step": 0.2}'>
                        <div class="block_2_content blocks">
                            <img src="https://s3.us-east-2.amazonaws.com/konnektfunnels-assets/snippets/feature/image2.png" class="">
                            <h2>Add your text here</h2>
                            <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Cupiditate porro enim molestiae
                                corrupti officia pariatur cumque deserunt officiis libero! Excepturi, perferendis
                                doloribus deserunt culpa dolorum id nulla eos possimus necessitatibus</p>
                        </div>
                    </div>
                    <div class="col-lg-4 col-md-6  col-xs-12 " data-gjs-resizable='{"tl":0,"tc":0,"tr":0,"cl":0,"bl":0,"br":0,"bc": 0, "keyWidth": "flex-basis", "currentUnit": 1, "minDim": 1, "maxDim": 100, "step": 0.2}'>
                        <div class="block_3_content blocks">
                            <img src="https://s3.us-east-2.amazonaws.com/konnektfunnels-assets/snippets/feature/image3.png" class="">
                            <h2>Add your text here</h2>
                            <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Cupiditate porro enim molestiae
                                corrupti officia pariatur cumque deserunt officiis libero! Excepturi, perferendis
                                doloribus deserunt culpa dolorum id nulla eos possimus necessitatibus</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
        `
    })
    toAdd("feature5") && bm.add("feature5", {
        category: "Features",
        label: "Feature5",
        attributes: { style: "background-image: url('https://s3.us-east-2.amazonaws.com/konnektfunnels-assets/snippets/feature/features5.png'); background-repeat: no-repeat;background-position: center center;background-size: 250px 100px; " },
        content: `
        <style>
        body {
            padding: 0;
            margin: 0;
            font-family:sans-serif;
        }

        iframe {
            max-width: 100%;
            max-height: 400px;
            width: 600px;
            height: 300px;
        }
        img {
            max-width: 100%;
        }
        .feature5_bg{
            background: url(https://s3.us-east-2.amazonaws.com/konnektfunnels-assets/snippets/feature/feature5.png);
            background-position: center;
            background-size: cover;
        }
        .feature5{
            padding: 30px;
            background: #000000ab;
        }
        .feature5 > .container > h1,.feature5  > .container  > p {
            color:#fff;
            text-align: center;
        }
        .feature5  .blocks img{
            max-height: 200px;
            min-height: 200px;
            width: 100%;
            margin: 20px 0px;
        }
        .feature5  .blocks h2{
            font-weight:700;
        }
        .feature5  .blocks p{
            font-size:14px;
        }
        .feature5  .blocks{
            background: #f4f4f4ab;
            padding: 35px;
            margin: 20px 0px;
            display: flex;
            flex-flow: column;
            justify-content: center;
            align-items: center;
        }
      
        @media (max-width : 990px){
            .feature5{
                background-position:unset;
            }
        }
    </style>
    <section class="feature5_bg">
        <div class="feature5">
            <div class="container">
                <h1>ADD YOUR TEXT HERE</h1>
                <p>Lorem, ipsum dolor sit amet consectetur adipisicing elit. Facere assumenda nesciunt dicta earum, non, et quibusdam quis hic vel molestiae magnam necessitatibus consectetur veritatis voluptatibus cumque tempora, ullam nisi dolorum.</p>
                <div class="row feature_content" data-gjs-resizable='{"tl":0,"tc":0,"tr":0,"cl":0,"cr":0,"bl":0,"br":0}'>
                    <div class="col-lg-6 col-md-6  col-xs-12 " data-gjs-resizable='{"tl":0,"tc":0,"tr":0,"cl":0,"bl":0,"br":0,"bc": 0, "keyWidth": "flex-basis", "currentUnit": 1, "minDim": 1, "maxDim": 100, "step": 0.2}'>
                        <div class="block_1_content blocks">
                            <h2>FEATURE</h2>
                            <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Cupiditate porro enim molestiae
                                corrupti officia pariatur cumque deserunt officiis libero! Excepturi, perferendis
                                doloribus deserunt culpa dolorum id nulla eos possimus necessitatibus</p>
                        </div>
                    </div>

                    <div class="col-lg-6 col-md-6  col-xs-12 " data-gjs-resizable='{"tl":0,"tc":0,"tr":0,"cl":0,"bl":0,"br":0,"bc": 0, "keyWidth": "flex-basis", "currentUnit": 1, "minDim": 1, "maxDim": 100, "step": 0.2}'>
                        <div class="block_2_content blocks">
                            <h2>FEATURE</h2>
                            <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Cupiditate porro enim molestiae
                                corrupti officia pariatur cumque deserunt officiis libero! Excepturi, perferendis
                                doloribus deserunt culpa dolorum id nulla eos possimus necessitatibus</p>
                        </div>
                    </div>
                    <div class="col-lg-6 col-md-6  col-xs-12 " data-gjs-resizable='{"tl":0,"tc":0,"tr":0,"cl":0,"bl":0,"br":0,"bc": 0, "keyWidth": "flex-basis", "currentUnit": 1, "minDim": 1, "maxDim": 100, "step": 0.2}'>
                        <div class="block_3_content blocks">
                            <h2>FEATURE</h2>
                            <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Cupiditate porro enim molestiae
                                corrupti officia pariatur cumque deserunt officiis libero! Excepturi, perferendis
                                doloribus deserunt culpa dolorum id nulla eos possimus necessitatibus</p>
                        </div>
                    </div>
                    <div class="col-lg-6 col-md-6  col-xs-12 " data-gjs-resizable='{"tl":0,"tc":0,"tr":0,"cl":0,"bl":0,"br":0,"bc": 0, "keyWidth": "flex-basis", "currentUnit": 1, "minDim": 1, "maxDim": 100, "step": 0.2}'>
                            <div class="block_3_content blocks">
                                <h2>FEATURE</h2>
                                <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Cupiditate porro enim molestiae
                                    corrupti officia pariatur cumque deserunt officiis libero! Excepturi, perferendis
                                    doloribus deserunt culpa dolorum id nulla eos possimus necessitatibus</p>
                            </div>
                        </div>
                </div>
            </div>
        </div>
    </section>
        `
    })
    toAdd("feature6") && bm.add("feature6", {
        category: "Features",
        label: "Feature6",
        attributes: { style: "background-image: url('https://s3.us-east-2.amazonaws.com/konnektfunnels-assets/snippets/feature/features6.png'); background-repeat: no-repeat;background-position: center center;background-size: 250px 100px; " },
        content: `
        <style>
        body {
            padding: 0;
            margin: 0;
            font-family: sans-serif;
        }

        img {
            max-width: 100%;
        }

        .feature6 {
            background: #00774496;
            min-height: 500px;
            color: #fff;
            display: flex;
            justify-content: center;
            align-items: center;
            align-items: center;
            padding: 30px 0px;
        }

        .feature6 .banner_content {
            display: flex;
            justify-content: center;
            align-items: center;

        }

        .feature6 .banner_content h1 {
            font-weight: 900;
            text-transform: uppercase;
        }

        .feature6 .banner_button {
            background: transparent;
            border-radius: 30px;
            color: #fff;
            width: 300px;
            font-weight: 700;
            padding: 10px 20px;
            margin-bottom: 20px;
            margin-top: 10px;
            border: 3px solid #fff;
        }
        .feature6 .feeature_image{
                width: 500px;
                max-width: 100%;
                height: 500px;
                border: 1px solid pink;
                background-color: #ffffff87;;
        }
        .feature6 .banner_button:hover {
            background: #fff;
            color: #000;
        }
        .banner2 .banner_button {
            background: transparent;
            border-radius: 30px;
            color: #000;
            width: 300px;
            font-weight: 700;
            padding: 10px 20px;
            margin-bottom: 20px;
            margin-top: 10px;
            border: 3px solid #000;
        }

        .banner2 .banner_button:hover {
            background: #000;
            color: #fff;
        }
        .feature6_bg{
            background: url('https://s3.us-east-2.amazonaws.com/konnektfunnels-assets/snippets/feature/feature6.png');
            background-size: cover;
            background-position: center;
        }
    </style>
    <section class="feature6_bg">
        <div class="feature6">
            <div class="container">

                <div class="row banner_content">

                    <div class="col-md-6 col-xs-12" data-gjs-resizable='{"tl":0,"tc":0,"tr":0,"cl":0,"bl":0,"br":0,"bc": 0, "keyWidth": "flex-basis", "currentUnit": 1, "minDim": 1, "maxDim": 100, "step": 0.2}'>
                       <div class="feeature_image">

                       </div>
                    </div>
                    <div class="col-md-6 col-xs-12" data-gjs-resizable='{"tl":0,"tc":0,"tr":0,"cl":0,"bl":0,"br":0,"bc": 0, "keyWidth": "flex-basis", "currentUnit": 1, "minDim": 1, "maxDim": 100, "step": 0.2}'>
                        <h1>Add your own text here.</h1>
                        <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Vitae nostrum odit ratione quidem
                            unde neque harum ipsa enim ut eveniet alias rem vel perferendis, possimus corporis. Quae
                            fuga labore repellendus. Lorem ipsum dolor sit amet, consectetur adipisicing elit. Vitae
                            nostrum odit ratione quidem unde neque harum ipsa enim ut eveniet alias rem vel
                            perferendis, possimus corporis. Quae fuga labore repellendus. Lorem ipsum dolor sit amet,
                            consectetur adipisicing elit. Vitae nostrum odit ratione quidem unde neque harum ipsa enim
                            ut eveniet alias rem vel perferendis, possimus corporis. Quae fuga labore repellendus.</p>
                        <div class="banner_btn">
                            <button class="btn banner_button">Button</button>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    </section>
        `
    })

    toAdd("footer1") && bm.add("footer1", {
        category: "Footers",
        label: "Footer1",
        attributes: { style: "background-image: url('https://s3.us-east-2.amazonaws.com/konnektfunnels-assets/snippets/footers/footer1.png'); background-repeat: no-repeat;background-position: center center;background-size: cover; " },
        content: `
           <style>
        .footer1{
            padding: 30px 0px;
            background: #383636;
            color: #fff;
        }
         .footer1 .footer_div ul{
            padding: 0px;
        }
        .footer1 .footer_div ul li {
            list-style: none;
        }
        .footer1 .footer_div ul li ul.social_icons li{
            list-style: none;
            float: left;
            margin-left: 10px;
        }
        .footer1 .footer_div ul li a{
            color:#fff;
        }
        @media (max-width : 600px){
            .footer1 .footer_div ul,.footer1 .footer_div{
                text-align: center;
            }
        }
    </style>
    <footer>
        <div class="footer1">
            <div class="container">
                <div class="row" data-gjs-resizable='{"tl":0,"tc":0,"tr":0,"cl":0,"cr":0,"bl":0,"br":0}'>
                    <div class="col-lg-3 col-md-4 col-xs-12 col-sm-6 footer_div" data-gjs-resizable='{"tl":0,"tc":0,"tr":0,"cl":0,"bl":0,"br":0,"bc": 0, "keyWidth": "flex-basis", "currentUnit": 1, "minDim": 1, "maxDim": 100, "step": 0.2}'>
                        <h3>Services</h3>
                        <ul>
                            <li><a href="">Intregrations</a></li>
                            <li><a href="">Community</a></li>
                            <li><a href="">Download</a></li>
                            <li><a href="">Upgrade</a></li>
                            <li><a href="">FAQ</a></li>
                        </ul>
                    </div>
                    <div class="col-lg-3 col-md-4 col-xs-12 col-sm-6 footer_div" data-gjs-resizable='{"tl":0,"tc":0,"tr":0,"cl":0,"bl":0,"br":0,"bc": 0, "keyWidth": "flex-basis", "currentUnit": 1, "minDim": 1, "maxDim": 100, "step": 0.2}'>
                        <h3>Company</h3>
                        <ul>
                            <li><a href="">About us</a></li>
                            <li><a href="">Careers</a></li>
                            <li><a href="">Events</a></li>
                            <li><a href="">Blog</a></li>
                        </ul>
                    </div>
                    <div class="col-lg-3 col-md-4 col-xs-12 col-sm-6 footer_div" data-gjs-resizable='{"tl":0,"tc":0,"tr":0,"cl":0,"bl":0,"br":0,"bc": 0, "keyWidth": "flex-basis", "currentUnit": 1, "minDim": 1, "maxDim": 100, "step": 0.2}'>
                        <h3>Resources</h3>
                        <ul>
                            <li><a href="">Privacy Policy</a></li>
                            <li><a href="">Terms of services</a></li>
                            <li><a href="">Contact</a></li>

                        </ul>
                    </div>
                    <div class="col-lg-3 col-md-4 col-xs-12 col-sm-6 footer_div" data-gjs-resizable='{"tl":0,"tc":0,"tr":0,"cl":0,"bl":0,"br":0,"bc": 0, "keyWidth": "flex-basis", "currentUnit": 1, "minDim": 1, "maxDim": 100, "step": 0.2}'>
                        <h3>Follow Us</h3>
                        <ul>
                            <li>Company, 12 East 55th Street
                                San Fransisco, CA, 94105
                                1-234-567-8900
                                info(at)domain.ny</li>
                            <li>
                                <ul class="social_icons">
                                    <li><a href=""><i class="fa fa-facebook-square fa-1x"></i></a></li>
                                    <li><a href=""><i class="fa fa-linkedin-square fa-1x"></i></a></li>
                                    <li><a href=""><i class="fa fa-twitter-square fa-1x"></i></a></li>
                                </ul>
                            </li>


                        </ul>
                    </div>
                    <p style="text-align:center;width:100%;">2018 Company. All Rights Reserved</p>
                </div>
            </div>
        </div>

    </footer>
        `
    })
    toAdd("footer2") && bm.add("footer2", {
        category: "Footers",
        label: "Footer2",
        attributes: { style: "background-image: url('https://s3.us-east-2.amazonaws.com/konnektfunnels-assets/snippets/footers/footer2.png'); background-repeat: no-repeat;background-position: center center;background-size: cover; " },
        content: `
            <style>
        .footer2 {
            padding: 30px 0px;
            background: #361f3e;
            color: #fff;
        }
        .footer2 .footer_div{
            float: left;
        }
        .footer2 .footer_div ul {
            padding: 0px;
        }

        .footer2 .footer_div ul li {
            list-style: none;
        }

        .footer2 .footer_div ul.social_icons li {
            list-style: none;
            float: left;
            margin-left: 10px;
            font-size:20px;
        }

        .footer2 .footer_div ul li a {
            color: #fff;
        }
        .footer2 .form-group_div{
           margin: 20px 0px;
        }
        .footer2 .form-group_div input{
            width: 60%;
            height: 38px;
            border: 1px solid #dddddd;
            border-right: 0px;
        }
        .footer2 .form-group_div .btnSubmit{
            margin-left: -10px;
            margin-top: -6px;
            border-radius: 0px 4px 4px 0px;
            background: #d4a2a2;
            color: #fff;
        }
        @media (max-width : 600px) {    
            .footer2 .footer_div ul,
            .footer2 .footer_div {
                text-align: center;
            }
        }
    </style>
    <footer>
        <div class="footer2">
            <div class="container">
                <div class="row" data-gjs-resizable='{"tl":0,"tc":0,"tr":0,"cl":0,"cr":0,"bl":0,"br":0}'>
                    <div class="col-lg-6 col-md-12 col-xs-12 col-sm-12" data-gjs-resizable='{"tl":0,"tc":0,"tr":0,"cl":0,"bl":0,"br":0,"bc": 0, "keyWidth": "flex-basis", "currentUnit": 1, "minDim": 1, "maxDim": 100, "step": 0.2}'>
                        <div class="col-lg-12 col-md-12 col-xs-12 col-sm-6 footer_div" data-gjs-resizable='{"tl":0,"tc":0,"tr":0,"cl":0,"bl":0,"br":0,"bc": 0, "keyWidth": "flex-basis", "currentUnit": 1, "minDim": 1, "maxDim": 100, "step": 0.2}'>
                            <h3>Stay Connected </h3>
                            <ul>
                                <li>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Eligendi, magnam. Velit
                                    maxime vel.</li>
                                <li>
                                    <div class="form-group_div">
                                        <input type="text" class="email_field" name="email">
                                        <button class="btn btnSubmit">Submit</button>
                                    </div>
                                </li>


                            </ul>
                        </div>
                    </div>

                    <div class="col-lg-6 col-md-12 col-xs-12 col-sm-6" data-gjs-resizable='{"tl":0,"tc":0,"tr":0,"cl":0,"bl":0,"br":0,"bc": 0, "keyWidth": "flex-basis", "currentUnit": 1, "minDim": 1, "maxDim": 100, "step": 0.2}'>
                        <div class="col-lg-6 col-md-6 col-xs-12 col-sm-12 footer_div" data-gjs-resizable='{"tl":0,"tc":0,"tr":0,"cl":0,"bl":0,"br":0,"bc": 0, "keyWidth": "flex-basis", "currentUnit": 1, "minDim": 1, "maxDim": 100, "step": 0.2}'>
                            <h3>Company</h3>
                            <ul>
                                <li><a href="">About us</a></li>
                                <li><a href="">Careers</a></li>
                                <li><a href="">Events</a></li>
                                <li><a href="">Blog</a></li>
                            </ul>
                        </div>
                        <div class="col-lg-6 col-md-6 col-xs-12 col-sm-12 footer_div" data-gjs-resizable='{"tl":0,"tc":0,"tr":0,"cl":0,"bl":0,"br":0,"bc": 0, "keyWidth": "flex-basis", "currentUnit": 1, "minDim": 1, "maxDim": 100, "step": 0.2}'>
                            <h3>Follow us</h3>
                            <ul class="social_icons">
                                <li><a href=""><i class="fa fa-facebook-square fa-1x"></i></a></li>
                                <li><a href=""><i class="fa fa-linkedin-square fa-1x"></i></a></li>
                                <li><a href=""><i class="fa fa-twitter-square fa-1x"></i></a></li>
                            </ul>
                        </div>

                    </div>

                    <p style="text-align:center;width:100%;">2018 Company. All Rights Reserved</p>
                </div>
            </div>
        </div>

    </footer>
        `
    })
    toAdd("footer3") && bm.add("footer3", {
        category: "Footers",
        label: "Footer3",
        attributes: { style: "background-image: url('https://s3.us-east-2.amazonaws.com/konnektfunnels-assets/snippets/footers/footer3.png'); background-repeat: no-repeat;background-position: center center;background-size: cover; " },
        content: `
        <style>
        body {
            padding: 0;
            margin: 0;
            font-family:sans-serif;
        }
        img {
            max-width: 100%;
        }
        .footer3{
            text-align: center;
            padding: 30px 0px;
            min-height: 300px;
            display: flex;
            justify-content: center;
            align-items: center;
            background: #2b65af;
            color: #fff;
        }
       .footer3 .form-group_div{
           margin: 10px 0px;
        }
        .footer3 .form-group_div input{
            width: 100%;
            height: 38px;
            border: 1px solid #dddddd;
            margin-right: 20px;
        }
        .footer3 .form-group_div label{
            display: block;
            text-align: left;
        }
        .footer3  .btnSubmit{
            margin-top: 20px;
            background: #d4a2a2;
            color: #fff;
            padding-left: 30px;
            padding-right: 30px;
        }
        .footer3 .registration_form{
            margin-bottom: 20px;
        }
        .footer3 .form-group_div{
           margin: 20px 0px;
        }
        .footer3 .form-group_div input{
            width: 60%;
            height: 38px;
            border: 1px solid #dddddd;
            border-right: 0px;
        }
        .footer3 .form-group_div .btnSubmit{
            margin-left: -30px;
            margin-top: -5px;
            border-radius: 0px 4px 4px 0px;
            background: #d4a2a2;
            color: #fff;
        }
        .footer3 ul.social_icons{
            padding:0px;
        }
        .footer3 ul.social_icons li {
            list-style: none;
            float: left;
            margin-left: 10px;
            font-size:20px;
        }
        .footer3 ul.social_icons li a{
            color: #fff;
            font-size: 24px;

        }
    </style>
    <section class="footer3">
        <div class="container">
            <form class="form">
                <div class="row registration_form justify-content-center" data-gjs-resizable='{"tl":0,"tc":0,"tr":0,"cl":0,"cr":0,"bl":0,"br":0}'>
                    <div class="col-lg-4 col-xs-12 col-md-6 col-sm-12" data-gjs-resizable='{"tl":0,"tc":0,"tr":0,"cl":0,"bl":0,"br":0,"bc": 0, "keyWidth": "flex-basis", "currentUnit": 1, "minDim": 1, "maxDim": 100, "step": 0.2}'>
                       <h5>WE ARE HERE TO HELP YOU</h5>
                       <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Repudiandae magni cum natus iste voluptate tempore delectus voluptatem at.</p>
                       <div class="form-group_div">
                            <input type="text" class="email_field" name="email">
                            <button class="btn btnSubmit">Submit</button>
                        </div>
                        <div class="col-xs-12" data-gjs-resizable='{"tl":0,"tc":0,"tr":0,"cl":0,"bl":0,"br":0,"bc": 0, "keyWidth": "flex-basis", "currentUnit": 1, "minDim": 1, "maxDim": 100, "step": 0.2}'>
                                <ul class="social_icons d-flex justify-content-center">
                                        <li><a href=""><i class="fa fa-facebook-square fa-1x"></i></a></li>
                                        <li><a href=""><i class="fa fa-linkedin-square fa-1x"></i></a></li>
                                        <li><a href=""><i class="fa fa-twitter-square fa-1x"></i></a></li>
                                    </ul>
                        </div>
                    </div>
                </div>
            </form>
        </div>
    </section>
        `
    })
    toAdd("footer4") && bm.add("footer4", {
        category: "Footers",
        label: "Footer4",
        attributes: { style: "background-image: url('https://s3.us-east-2.amazonaws.com/konnektfunnels-assets/snippets/footers/footer4.png'); background-repeat: no-repeat;background-position: center center;background-size: cover; " },
        content: `
        <style>
        body {
            padding: 0;
            margin: 0;
            font-family:sans-serif;
        }
        img {
            max-width: 100%;
        }
        .footer4{
            text-align: center;
            padding: 30px 0px;
            min-height: 300px;
            display: flex;
            justify-content: center;
            align-items: center;
            background: #000;
            color: #fff;
        }
       
        .footer4 ul.social_icons{
            padding:0px;
        }
        .footer4 ul.social_icons li {
            list-style: none;
            float: left;
            margin-left: 10px;
            font-size:20px;
        }
        .footer4 ul.social_icons li a{
            color: #fff;
            font-size: 24px;

        }
    </style>
    <section class="footer4">
        <div class="container">
            <form class="form">
                <div class="row registration_form justify-content-center" data-gjs-resizable='{"tl":0,"tc":0,"tr":0,"cl":0,"cr":0,"bl":0,"br":0}'>
                    <div class="col-lg-4 col-xs-12 col-md-6 col-sm-12" data-gjs-resizable='{"tl":0,"tc":0,"tr":0,"cl":0,"bl":0,"br":0,"bc": 0, "keyWidth": "flex-basis", "currentUnit": 1, "minDim": 1, "maxDim": 100, "step": 0.2}'>
                       <h3>FOLLOW US</h3>
                       <p>You can find us everywhere</p>
                        <div class="col-xs-12" data-gjs-resizable='{"tl":0,"tc":0,"tr":0,"cl":0,"bl":0,"br":0,"bc": 0, "keyWidth": "flex-basis", "currentUnit": 1, "minDim": 1, "maxDim": 100, "step": 0.2}'>
                                <ul class="social_icons d-flex justify-content-center">
                                        <li><a href=""><i class="fa fa-facebook-square fa-1x"></i></a></li>
                                        <li><a href=""><i class="fa fa-linkedin-square fa-1x"></i></a></li>
                                        <li><a href=""><i class="fa fa-twitter-square fa-1x"></i></a></li>
                                        <li><a href=""><i class="fa fa-google-plus-square fa-1x"></i></a></li>
                                        <li><a href=""><i class="fa fa-tumblr-square fa-1x"></i></a></li>
                                        <li><a href=""><i class="fa fa-weixin fa-1x"></i></a></li>
                                        <li><a href=""><i class="fa fa-whatsapp fa-1x"></i></a></li>
                                    </ul>
                        </div>
                    </div>
                    <p style="text-align:center;width:100%;">2018 Company. All Rights Reserved</p>

                </div>
            </form>
        </div>
    </section>
        `
    })
    toAdd("footer5") && bm.add("footer5", {
        category: "Footers",
        label: "Footer5",
        attributes: { style: "background-image: url('https://s3.us-east-2.amazonaws.com/konnektfunnels-assets/snippets/footers/footer5.png'); background-repeat: no-repeat;background-position: center center;background-size: cover; " },
        content: `
           <style>
        .footer5{
            padding: 30px 0px;
            background: #383636;
            color: #fff;
        }
         .footer5 .footer_div ul{
            padding: 0px;
        }
        .footer5 .footer_div ul li {
            list-style: none;
        }
        .footer5 .footer_div ul li ul.social_icons li{
            list-style: none;
            float: left;
            margin-left: 10px;
        }
        .footer5 .footer_div ul li a{
            color:#fff;
        }
        @media (max-width : 600px){
            .footer5 .footer_div ul,.footer5 .footer_div{
                text-align: center;
            }
        }
        .logo_image{
            display: flex;
            justify-content: center;
            align-items: center;
        }
        .logo_image img{
            width: 200px;
        }
    </style>
    <footer>
        <div class="footer5">
            <div class="container">
                <div class="row" data-gjs-resizable='{"tl":0,"tc":0,"tr":0,"cl":0,"cr":0,"bl":0,"br":0}'>
                    <div class="col-lg-3 col-md-4 col-xs-12 col-sm-6 footer_div logo_image" data-gjs-resizable='{"tl":0,"tc":0,"tr":0,"cl":0,"bl":0,"br":0,"bc": 0, "keyWidth": "flex-basis", "currentUnit": 1, "minDim": 1, "maxDim": 100, "step": 0.2}'>
                        <img src="https://s3.us-east-2.amazonaws.com/konnektfunnels-assets/snippets/footers/logo.png">
                    </div>
                    <div class="col-lg-3 col-md-4 col-xs-12 col-sm-6 footer_div" data-gjs-resizable='{"tl":0,"tc":0,"tr":0,"cl":0,"bl":0,"br":0,"bc": 0, "keyWidth": "flex-basis", "currentUnit": 1, "minDim": 1, "maxDim": 100, "step": 0.2}'>
                        <h3>Services</h3>
                        <ul>
                            <li><a href="">Intregrations</a></li>
                            <li><a href="">Community</a></li>
                            <li><a href="">Download</a></li>
                            <li><a href="">Upgrade</a></li>
                            <li><a href="">FAQ</a></li>
                        </ul>
                    </div>
                    <div class="col-lg-3 col-md-4 col-xs-12 col-sm-6 footer_div" data-gjs-resizable='{"tl":0,"tc":0,"tr":0,"cl":0,"bl":0,"br":0,"bc": 0, "keyWidth": "flex-basis", "currentUnit": 1, "minDim": 1, "maxDim": 100, "step": 0.2}'>
                        <h3>Company</h3>
                        <ul>
                            <li><a href="">About us</a></li>
                            <li><a href="">Careers</a></li>
                            <li><a href="">Events</a></li>
                            <li><a href="">Blog</a></li>
                        </ul>
                    </div>
                    <div class="col-lg-3 col-md-4 col-xs-12 col-sm-6 footer_div" data-gjs-resizable='{"tl":0,"tc":0,"tr":0,"cl":0,"bl":0,"br":0,"bc": 0, "keyWidth": "flex-basis", "currentUnit": 1, "minDim": 1, "maxDim": 100, "step": 0.2}'>
                        <h3>Resources</h3>
                        <ul>
                            <li><a href="">Privacy Policy</a></li>
                            <li><a href="">Terms of services</a></li>
                            <li><a href="">Contact</a></li>
                            <li>
                                    <ul class="social_icons">
                                        <li><a href=""><i class="fa fa-facebook-square fa-1x"></i></a></li>
                                        <li><a href=""><i class="fa fa-linkedin-square fa-1x"></i></a></li>
                                        <li><a href=""><i class="fa fa-twitter-square fa-1x"></i></a></li>
                                    </ul>
                                </li>
                        </ul>
                    </div>
                   
                    <p style="text-align:center;width:100%;">2018 Company. All Rights Reserved</p>
                </div>
            </div>
        </div>

    </footer>
        `
    })
    toAdd("footer6") && bm.add("footer6", {
        category: "Footers",
        label: "Footer6",
        attributes: { style: "background-image: url('https://s3.us-east-2.amazonaws.com/konnektfunnels-assets/snippets/footers/footer6.png'); background-repeat: no-repeat;background-position: center center;background-size: cover; " },
        content: `
        <style>
        .footer6{
            padding: 30px 0px;
            background: #383636;
            color: #fff;
        }
        .logo_image{
            display: flex;
            justify-content: center;
            align-items: center;
        }
        .logo_image img{
            width: 300px;
        }
        
        .form-group_div{
           margin: 20px 0px;
        }
         .form-group_div input{
            width: 50%;
            height: 38px;
            border: 1px solid #dddddd;
            border-right: 0px;
        }
        .contact_us_form .form-group_div input{
                width: 100%;
        }
         .form-group_div .btnSubmit{
            margin-left: -10px;
            margin-top: -5px;
            border-radius: 0px 4px 4px 0px;
            background: #d4a2a2;
            color: #fff;
            padding-left: 30px;
            padding-right: 30px;
        }
        .social_link{
            cursor: pointer;
        }
        .form .contact_us .contact_us_block{
            background: #f4f4f473;
            padding: 20px;
            border-radius: 10px;
        }
        .social_icons li{
            list-style: none;
        }
        .social_icons li a{
            color: #fff;
             font-size: 30px;
        }
        .social_icons li i{
                margin-right: 10px;
        }
    </style>
    <footer>
        <div class="footer6">
            <div class="container">

                <div class="col-xs-12 logo_image">
                    <img src="https://s3.us-east-2.amazonaws.com/konnektfunnels-assets/snippets/footers/logo.png">
                </div>
                <div class="row " data-gjs-resizable='{"tl":0,"tc":0,"tr":0,"cl":0,"cr":0,"bl":0,"br":0}'>
                    <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6" data-gjs-resizable='{"tl":0,"tc":0,"tr":0,"cl":0,"bl":0,"br":0,"bc": 0, "keyWidth": "flex-basis", "currentUnit": 1, "minDim": 1, "maxDim": 100, "step": 0.2}'>

                        <form class="form">
                            <h2>Subscribe here</h2>
                            <div class="form-group_div">
                                <input type="text" class="email_field" name="email">
                                <button class="btn btnSubmit">Submit</button>
                            </div>
                            <ul class="social_icons">
                                    <li><a href=""><i class="fa fa-facebook fa-1x"></i>  Facebook</a></li>
                                    <li><a href=""><i class="fa fa-linkedin fa-1x"></i>Linkedin</a></li>
                                    <li><a href=""><i class="fa fa-twitter fa-1x"></i>Twitter</a></li>
                                </ul>
                            
                        </form>
                    </div>
                    <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6" data-gjs-resizable='{"tl":0,"tc":0,"tr":0,"cl":0,"bl":0,"br":0,"bc": 0, "keyWidth": "flex-basis", "currentUnit": 1, "minDim": 1, "maxDim": 100, "step": 0.2}'>
                        <form class="form contact_us_form">
                            <h2>Contact us</h2>
                            <div class="form-group_div">
                                    <input type="text" class="email_field" name="name" placeholder="Enter you name">
                                </div>
                            <div class="form-group_div">
                                   <textarea class="form-control" placeholder="Enter your message"></textarea>
                                </div>
                            <div class="form-group_div">
                                <input type="text" class="email_field" name="email" placeholder="Enter you email">
                            </div>
                            <div class="form-group_div">
                                    
                                    <button class="btn btnSubmit float-right">Submit</button>
                                </div>
                            <p>Find us on <a class="social_link"><b>Facebook</b></a> or <a class="social_link"><b>Linkedin</b></a></p>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </footer>
        `
    })

    //FORMS


    //NAVIGATIONS - HEADERS

    toAdd("navigation1") && bm.add("navigation1", {
        category: "Navigations",
        label: "Navigation1",
        attributes: { style: "background-image: url('https://s3.us-east-2.amazonaws.com/konnektfunnels-assets/snippets/header/header1.png'); background-repeat: no-repeat;background-position: center center;background-size: 200px 10px; " },
        content: `
         <style>
        body {
            padding: 0;
            margin: 0;
            font-family: sans-serif;
        }

        img {
            max-width: 100%;
        }

        .header1 {
            background: #383636;
            color: #fff;
            padding: 20px 0px;
        }

        .logo_sec {
            display: flex;
            justify-content: space-between;
            align-items: center;
        }

        .header1 .logo_image {
            width: 300px;
        }

        .header1 .toggle_bars {
            float: right;
        }

        .header1 .nav_section nav,
        .nav_section ul {
            margin: 0;
            padding: 0;

        }

        .header1 .nav_section ul li {
            float: left;
            list-style: none;
            margin-left: 10px;
            padding: 10px;
            border: 1px solid transparent;
        }

        .header1 .nav_section ul li a {
            color: #fff;
            text-decoration: none;

        }

        .header1 .nav_section ul li:hover,
        .header1 .social_icon ul li:hover {
            padding: 10px;
            border: 1px solid #fff;
        }

        @media (min-width : 990px) {
            .toggle_bars {
                display: none;
            }

            .nav_section {
                display: block;
            }

            .header1 .nav_section {
                display: flex;
                justify-content: flex-end;
                align-items: center;
            }

            .collapse:not(.show) {
                display: inherit !important;
            }
        }

        @media (max-width:990px) {
            .toggle_bars {
                display: block;
                margin-top: 10px;
            }

            .header1 .nav_section ul li {
                float: none;
                padding: 15px;
                border-bottom: 1px solid #fff;
            }
        }
    </style>
    <header class="header1">
        <div class="container">
            <div class="row" data-gjs-resizable='{"tl":0,"tc":0,"tr":0,"cl":0,"cr":0,"bl":0,"br":0}'>
                <div class="col-lg-5 col-xs-12 logo_sec" data-gjs-resizable='{"tl":0,"tc":0,"tr":0,"cl":0,"bl":0,"br":0,"bc": 0, "keyWidth": "flex-basis", "currentUnit": 1, "minDim": 1, "maxDim": 100, "step": 0.2}'>
                    <img src="https://s3.us-east-2.amazonaws.com/konnektfunnels-assets/snippets/header/logo.png" class="logo_image" alt="Logo">
                    <div class="toggle_bars">

                        <i class="fa fa-bars fa-2x" data-toggle="collapse" data-target="#navMenu"></i>
                    </div>
                </div>
                <div id="navMenu" class="col-xs-12 col-lg-7 collapse nav_section" data-gjs-resizable='{"tl":0,"tc":0,"tr":0,"cl":0,"bl":0,"br":0,"bc": 0, "keyWidth": "flex-basis", "currentUnit": 1, "minDim": 1, "maxDim": 100, "step": 0.2}'>
                    <nav>
                        <ul>
                            <li><a href="">Home</a></li>
                            <li><a href="">About us</a></li>
                            <li><a href="">Contact us</a></li>
                            <li><a href="">Portfolio</a></li>
                            <li><a href="">Product</a></li>
                            <li><a href="">Sigin / Signup</a></li>
                        </ul>
                    </nav>
                </div>
            </div>
        </div>
    </header>    
        `
    })
    toAdd("navigation2") && bm.add("navigation2", {
        category: "Navigations",
        label: "Navigation2",
        attributes: { style: "background-image: url('https://s3.us-east-2.amazonaws.com/konnektfunnels-assets/snippets/header/header2.png'); background-repeat: no-repeat;background-position: center center;background-size: 250px 25px; " },
        content: `
            <style>
        body {
            padding: 0;
            margin: 0;
            font-family: sans-serif;
        }

        img {
            max-width: 100%;
        }

        .header2 {
            background: #361f3e;
            color: #fff;
            padding: 20px 0px;
        }

        .logo_sec {
            display: flex;
            justify-content: space-between;
            align-items: center;
        }

        .header2 .logo_image {
            width: 300px;
        }

        .header2 .toggle_bars {
            float: right;
        }

        .header2 .nav_section nav,
        .nav_section ul,
        .header2 .social_icon nav,
        .header2 .social_icon ul {
            margin: 0;
            padding: 0;

        }

        .header2 .nav_section ul li,
        .header2 .social_icon ul li {
            float: left;
            list-style: none;
            margin-left: 10px;
            padding: 5px;
            border: 1px solid transparent;
        }

        .header2 .social_icon ul li {
            margin-left: 15px;
            font-size: 16px;

        }

        .header2 .nav_section ul li a,
        .header2 .social_icon ul li a {
            color: #fff;
            text-decoration: none;
            cursor: pointer;
        }

        .header2 .nav_section ul li:hover,
        .header2 .social_icon ul li:hover {
            padding: 5px;
            border: 1px solid #fff;
        }

        @media (min-width : 990px) {
            .toggle_bars {
                display: none;
            }

            .nav_section.social_icon {
                display: block;
            }

            .header2 .nav_section,
            .header2 .social_icon {
                display: flex;
                justify-content: flex-end;
                align-items: center;
            }

            .collapse {
                display: block !important;
            }

            #navMenu {
                display: flex !important;
                flex-flow: column;
                justify-content: center;
            }
        }

        .header2 .social_icon .text_social_icon {
            display: none;
        }

        @media (max-width:990px) {
            .toggle_bars {
                display: block;
                margin-top: 10px;
            }

            .header2 .social_icon .text_social_icon {
                display: block;
            }

            .header2 .nav_section ul li {
                float: none;
                padding: 15px;
                border-bottom: 1px solid #fff;
            }

            .header2 .social_icon {
                float: left;
                width: 100%;
                border-bottom: 1px solid #fff;
                padding: 10px;
                text-align: center;
                margin-bottom: 10px;
            }

            .header2 .social_icon nav {
                float: right;
            }

            .header2 .social_icon nav ul li {
                font-size: 18px;
            }
        }
    </style>
    <header class="header2">
        <div class="container">
            <div class="row" data-gjs-resizable='{"tl":0,"tc":0,"tr":0,"cl":0,"cr":0,"bl":0,"br":0}'>
                <div class="col-lg-5 col-xs-12 logo_sec" data-gjs-resizable='{"tl":0,"tc":0,"tr":0,"cl":0,"bl":0,"br":0,"bc": 0, "keyWidth": "flex-basis", "currentUnit": 1, "minDim": 1, "maxDim": 100, "step": 0.2}'>
                    <img src="https://s3.us-east-2.amazonaws.com/konnektfunnels-assets/snippets/header/logo.png" class="logo_image" alt="Logo">
                    <div class="toggle_bars">

                        <i class="fa fa-bars fa-2x" data-toggle="collapse" data-target="#navMenu"></i>
                    </div>
                </div>
                <div id="navMenu" class="col-xs-12 col-lg-7 collapse " data-gjs-resizable='{"tl":0,"tc":0,"tr":0,"cl":0,"bl":0,"br":0,"bc": 0, "keyWidth": "flex-basis", "currentUnit": 1, "minDim": 1, "maxDim": 100, "step": 0.2}'>
                    <div class="social_icon">
                        <nav>
                            <ul>
                                <li><a><i class="fa fa-linkedin"></i><span class="text_social_icon">Linkedin</span></a></li>
                                <li><a><i class="fa fa-twitter"></i><span class="text_social_icon">Twitter</span></a></li>
                                <li><a><i class="fa fa-facebook"></i><span class="text_social_icon">Facebook</span></a></li>
                                <li><a><i class="fa fa-youtube"></i><span class="text_social_icon">Youtube</span></a></li>
                            </ul>
                        </nav>
                    </div>
                    <div class="nav_section">
                        <nav>
                            <ul>
                                <li><a href="">Home</a></li>
                                <li><a href="">About us</a></li>
                                <li><a href="">Contact us</a></li>
                                <li><a href="">Portfolio</a></li>
                                <li><a href="">Product</a></li>
                                <li><a href="">Sigin / Signup</a></li>
                            </ul>
                        </nav>
                    </div>

                </div>
            </div>
        </div>
    </header>
            `
    })
    toAdd("navigation3") && bm.add("navigation3", {
        category: "Navigations",
        label: "Navigation3",
        attributes: { style: "background-image: url('https://s3.us-east-2.amazonaws.com/konnektfunnels-assets/snippets/header/header3.png'); background-repeat: no-repeat;background-position: center center;background-size: 250px 25px; " },
        content: `
                <style>
        body {
            padding: 0;
            margin: 0;
            font-family: sans-serif;
        }

        img {
            max-width: 100%;
        }

        .header3 {
            background: #2b65af;
            color: #fff;
            padding: 20px 0px;
        }

        .header3 .logo_image {
            width: 300px;
        }

        .header3 .toggle_bars {
            float: right;
        }

        .header3 .nav_section nav,
        .nav_section ul,
        .header3 .social_icon nav,
        .header3 .social_icon ul {
            margin: 0;
            padding: 0;

        }

        .header3 .nav_section ul li,
        .header3 .social_icon ul li {
            float: left;
            list-style: none;
            margin-left: 25px;
            padding: 5px;
            border: 1px solid transparent;
        }

        .header3 .social_icon ul li {
            margin-left: 15px;
            font-size: 16px;
        }

        .header3 .nav_section ul li a,
        .header3 .social_icon ul li a {
            color: #fff;
            text-decoration: none;
            cursor: pointer;
        }

        .header3 .text_social_icon {
            display: none;
        }

        .header3 .nav_section ul li:hover,
        .header3 .social_icon ul li:hover {
            padding: 5px;
            border: 1px solid #fff;
        }

        @media (min-width : 990px) {
            .toggle_bars {
                display: none;
            }

            .nav_section.social_icon {
                display: block;
            }

            .header3 .nav_section,
            .header3 .social_icon {
                display: flex;
                justify-content: flex-end;
                align-items: center;
            }

            .collapse {
                display: block !important;
                margin-top: 15px;
            }

            #navMenu {
                display: flex !important;
                flex-flow: column;
                justify-content: center;
            }
        }

        @media (max-width:990px) {
            .logo_image {
                display: inline;
            }

            .toggle_bars {
                display: block;
                margin-top: 10px;
            }

            .header3 .text_social_icon {
                display: block;
                margin-left: 25px;
            }

            .header3 i.fa {
                float: left;
            }

            .header3 .nav_section ul li {
                float: none;
                padding: 15px;
                border-bottom: 1px solid #fff;
            }

            .header3 .social_icon {
                float: left;
                width: 100%;
                border-bottom: 1px solid #fff;
                padding: 10px;
                text-align: center;
                margin-bottom: 10px;
            }

            .header3 .social_icon nav {
                float: right;
            }

            .header3 .social_icon nav ul li {
                font-size: 18px;
            }
        }
    </style>
    <header class="header3">
        <div class="container">
            <div class="row" data-gjs-resizable='{"tl":0,"tc":0,"tr":0,"cl":0,"cr":0,"bl":0,"br":0}'>
                <div class="col-lg-5 col-xs-12" data-gjs-resizable='{"tl":0,"tc":0,"tr":0,"cl":0,"bl":0,"br":0,"bc": 0, "keyWidth": "flex-basis", "currentUnit": 1, "minDim": 1, "maxDim": 100, "step": 0.2}'>
                    <img src="https://s3.us-east-2.amazonaws.com/konnektfunnels-assets/snippets/header/logo.png" class="logo_image" alt="Logo">
                    <div class="toggle_bars">

                        <i class="fa fa-bars fa-2x" data-toggle="collapse" data-target="#navMenu"></i>
                    </div>
                </div>
                <div id="navMenu" class="col-xs-12 col-lg-7 collapse " data-gjs-resizable='{"tl":0,"tc":0,"tr":0,"cl":0,"bl":0,"br":0,"bc": 0, "keyWidth": "flex-basis", "currentUnit": 1, "minDim": 1, "maxDim": 100, "step": 0.2}'>
                    <div class="nav_section">
                        <nav>
                            <ul>
                                <li><a href="">Home</a></li>
                                <li><a href="">About us</a></li>
                                <li><a href="">Contact us</a></li>
                                <li><a><i class="fa fa-twitter"></i><span class="text_social_icon">Twitter</span></a></li>
                                <li><a><i class="fa fa-linkedin"></i><span class="text_social_icon">Linkedin</span></a></li>
                                <li><a><i class="fa fa-facebook"></i><span class="text_social_icon">Facebook</span></a></li>
                                <li><a><i class="fa fa-youtube"></i><span class="text_social_icon">Youtube</span></a></li>
                                <li><a href="">Sigin / Signup</a></li>
                            </ul>
                        </nav>
                    </div>

                </div>
            </div>
        </div>
    </header>

            `
    })
    toAdd("navigation4") && bm.add("navigation4", {
        category: "Navigations",
        label: "Navigation4",
        attributes: { style: "background-image: url('https://s3.us-east-2.amazonaws.com/konnektfunnels-assets/snippets/header/header4.png'); background-repeat: no-repeat;background-position: center center;background-size: 250px 25px; " },
        content: `
            <style>
        body {
            padding: 0;
            margin: 0;
            font-family: sans-serif;
        }

        img {
            max-width: 100%;
        }

        .header4 {
            background: #000;
            color: #fff;
            padding: 20px 0px;
        }

        .header4 .logo_image {
            width: 300px;
        }

        .header4 .toggle_bars {
            float: right;
        }

        .header4 .nav_section nav,
        .nav_section ul,
        .header4 .social_icon nav,
        .header4 .social_icon ul {
            margin: 0;
            padding: 0;
            width: 100%;

        }

        .header4 .nav_section ul li {
            float: left;
            list-style: none;
            margin-right: 3%;
            margin-right: 4%;

        }

        .header4 .nav_section ul li,
        .header4 .social_icon ul li {
            float: left;
            list-style: none;
            padding: 10px;
            border: 1px solid #000;

        }

        .header4 .social_icon ul li {
            margin-left: 15px;
            font-size: 16px;
        }

        .header4 .nav_section ul li a,
        .header4 .social_icon ul li a {
            color: #fff;
            text-decoration: none;
            cursor: pointer;
        }

        .header4 .nav_section ul li:hover,
        .header4 .social_icon ul li:hover {
            padding: 10px;
            border: 1px solid #fff;

        }

        @media (min-width : 990px) {
            .toggle_bars {
                display: none;
            }

            .nav_section.social_icon {
                display: block;
            }

            .header4 .nav_section {
                display: flex;
                justify-content: flex-start;
                align-items: center;
            }

            .header4 .social_icon {
                float: right;
                margin-top: 5px;
            }

            .collapse {
                display: block !important;
            }

            #navMenu {
                display: flex !important;
                flex-flow: column;
                justify-content: center;
            }

            .logo_sec {
                display: flex;
                justify-content: space-between;
                align-items: center;
            }
        }

        .header4 .social_icon .text_social_icon {
            display: none;
        }

        @media (max-width:990px) {
            .social_icons_block {
                display: inline-block;

            }

            .toggle_bars {
                display: block;
                margin-top: 10px;
            }

            .header4 .social_icon .text_social_icon {
                display: block;
            }

            .header4 .nav_section ul li {
                float: none;
                padding: 15px;
                border-bottom: 1px solid #fff;
            }

            .header4 .social_icon {
                float: left;
                width: 100%;
                border-bottom: 1px solid #fff;
                padding: 10px;
                text-align: center;
                margin-bottom: 10px;
            }

            .header4 .social_icon nav {
                float: right;
            }

            .header4 .social_icon nav ul li {
                font-size: 18px;
            }
        }
    </style>
    <header class="header4">
        <div class="container">
            <div class="row" data-gjs-resizable='{"tl":0,"tc":0,"tr":0,"cl":0,"cr":0,"bl":0,"br":0}'>
                <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 social_icons_block logo_sec" data-gjs-resizable='{"tl":0,"tc":0,"tr":0,"cl":0,"bl":0,"br":0,"bc": 0, "keyWidth": "flex-basis", "currentUnit": 1, "minDim": 1, "maxDim": 100, "step": 0.2}'>
                    <img src="https://s3.us-east-2.amazonaws.com/konnektfunnels-assets/snippets/header/logo.png" class="logo_image" alt="Logo">

                    <div class="toggle_bars">
                        <i class="fa fa-bars fa-2x" data-toggle="collapse" data-target="#navMenu"></i>
                    </div>
                    <div class="social_icon">
                        <nav>
                            <ul>
                                <li><a><i class="fa fa-linkedin"></i><span class="text_social_icon">Linkedin</span></a></li>
                                <li><a><i class="fa fa-twitter"></i><span class="text_social_icon">Twitter</span></a></li>
                                <li><a><i class="fa fa-facebook"></i><span class="text_social_icon">Facebook</span></a></li>
                                <li><a><i class="fa fa-youtube"></i><span class="text_social_icon">Youtube</span></a></li>
                            </ul>
                        </nav>
                    </div>

                </div>
                <div id="navMenu" class="col-xs-12 col-md-12 col-sm-12 col-lg-12 collapse " data-gjs-resizable='{"tl":0,"tc":0,"tr":0,"cl":0,"bl":0,"br":0,"bc": 0, "keyWidth": "flex-basis", "currentUnit": 1, "minDim": 1, "maxDim": 100, "step": 0.2}'>

                    <div class="nav_section">
                        <nav>
                            <ul>
                                <li><a href="">Home</a></li>
                                <li><a href="">About us</a></li>
                                <li><a href="">Contact us</a></li>
                                <li><a href="">Career</a></li>
                                <li><a href="">Product</a></li>
                                <li><a href="">Portfolio</a></li>
                                <li><a href="">Login</a></li>
                                <li><a href="">Signup</a></li>
                            </ul>
                        </nav>
                    </div>

                </div>
            </div>
        </div>
    </header>
            `
    })
    toAdd("navigation5") && bm.add("navigation5", {
        category: "Navigations",
        label: "Navigation5",
        attributes: { style: "background-image: url('https://s3.us-east-2.amazonaws.com/konnektfunnels-assets/snippets/header/header5.png'); background-repeat: no-repeat;background-position: center center;background-size: 250px 25px; " },
        content: `
                <style>
        body {
            padding: 0;
            margin: 0;
            font-family: sans-serif;
        }

        img {
            max-width: 100%;
        }

        .header5 {
            background: #000;
            color: #fff;
            padding: 20px 0px;
        }

        .header5 .logo_image {
            width: 300px;
        }

        .header5 .toggle_bars {
            float: right;
        }

        .header5 .nav_section nav,
        .nav_section ul,
        .header5 .social_icon nav,
        .header5 .social_icon ul {
            margin: 0;
            padding: 0;
            width: 100%;

        }

        .header5 .nav_section ul li {
            float: left;
            list-style: none;

            margin-right: 2%;

        }

        .header5 .nav_section ul li,
        .header5 .social_icon ul li {
            float: left;
            list-style: none;
            padding: 10px;
            border: 1px solid #000;

        }

        .header5 .social_icon ul li {
            margin-left: 15px;
            font-size: 16px;
        }

        .header5 .nav_section ul li a,
        .header5 .social_icon ul li a {
            color: #fff;
            text-decoration: none;
            cursor: pointer;
        }

        .header5 .nav_section ul li:hover,
        .header5 .social_icon ul li:hover {
            padding: 10px;
            border: 1px solid #fff;

        }

        @media (min-width : 990px) {
            .toggle_bars {
                display: none;
            }

            .logo_sec {
                display: flex;
                justify-content: space-between;
                align-items: center;
            }

            .nav_section.social_icon {
                display: block;
            }

            .header5 .nav_section {
                display: flex;
                justify-content: flex-start;
                align-items: center;
            }

            .header5 .social_icon {
                float: right;
                margin-top: 5px;
            }

            .collapse {
                display: block !important;
            }
        }

        .header5 .dropdown-menu {
            background-color: #000 !important;
            width: 100%;

        }

        .header5 .dropdown-item:focus,
        .dropdown-item:hover {
            background: #000 !important;
            color: #fff !important;
        }

        .header5 .social_icon .text_social_icon {
            display: none;
        }

        @media (max-width:990px) {
            .toggle_bars {
                display: block;
                margin-top: 10px;
            }

            .social_icons_block {
                display: inline-block;

            }

            .header5 .nav_section ul li:hover,
            .header5 .social_icon ul li:hover {
                padding: 10px;
                border: 0px solid #fff;

            }

            .header5 .social_icon .text_social_icon {
                display: block;
            }

            .header5 .nav_section ul li {
                float: none;
                padding: 15px;
                border-bottom: 1px solid #fff;
            }

            .header5 .social_icon {
                float: left;
                width: 100%;
                border-bottom: 1px solid #fff;
                padding: 10px;
                text-align: center;
                margin-bottom: 10px;
            }

            .header5 .social_icon nav {
                float: right;
            }

            .header5 .social_icon nav ul li {
                font-size: 18px;
            }

            .header5 .dropdown-menu {
                background-color: #fff !important;
                width: 100%;
                position: relative !important;
                margin-top: 18px;
            }

            .header5 .dropdown-menu .dropdown-item {
                color: #000;
                border-bottom: 1px solid #000;
                padding: 5px 0px;
            }
        }
    </style>
     <header class="header5">
        <div class="container">
            <div class="row" data-gjs-resizable='{"tl":0,"tc":0,"tr":0,"cl":0,"cr":0,"bl":0,"br":0}'>
                <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 social_icons_block logo_sec" data-gjs-resizable='{"tl":0,"tc":0,"tr":0,"cl":0,"bl":0,"br":0,"bc": 0, "keyWidth": "flex-basis", "currentUnit": 1, "minDim": 1, "maxDim": 100, "step": 0.2}'>
                    <img src="https://s3.us-east-2.amazonaws.com/konnektfunnels-assets/snippets/header/logo.png" class="logo_image" alt="Logo">

                    <div class="toggle_bars">
                        <i class="fa fa-bars fa-2x" data-toggle="collapse" data-target="#navMenu"></i>
                    </div>
                    <div class="social_icon">
                        <nav>
                            <ul>
                                <li><a><i class="fa fa-linkedin"></i><span class="text_social_icon">Linkedin</span></a></li>
                                <li><a><i class="fa fa-twitter"></i><span class="text_social_icon">Twitter</span></a></li>
                                <li><a><i class="fa fa-facebook"></i><span class="text_social_icon">Facebook</span></a></li>
                                <li><a><i class="fa fa-youtube"></i><span class="text_social_icon">Youtube</span></a></li>
                            </ul>
                        </nav>
                    </div>

                </div>
                <div id="navMenu" class="col-xs-12 col-md-12 col-sm-12 col-lg-12 collapse " data-gjs-resizable='{"tl":0,"tc":0,"tr":0,"cl":0,"bl":0,"br":0,"bc": 0, "keyWidth": "flex-basis", "currentUnit": 1, "minDim": 1, "maxDim": 100, "step": 0.2}'>

                    <div class="nav_section">
                        <nav class="navbar navbar-expand-sm  navbar-dark">
                            <ul>
                                <li><a href="">Home</a></li>
                                <li><a href="">About us</a></li>
                                <li><a href="">Contact us</a></li>
                                <li><a href="">Career</a></li>
                                <li><a href="">Product</a></li>
                                <li><a href="">Portfolio</a></li>
                                <li><a href="">Login</a></li>
                                <li><a href="">Signup</a></li>
                                <li class="nav-item dropdown">
                                    <a class="dropdown-toggle" href="#" id="navbardrop" data-toggle="dropdown">
                                        Dropdown link
                                    </a>
                                    <div class="dropdown-menu">
                                        <a class="dropdown-item" href="#">Link 1</a>
                                        <a class="dropdown-item" href="#">Link 2</a>
                                        <a class="dropdown-item" href="#">Link 3</a>
                                    </div>
                                </li>
                            </ul>
                        </nav>
                    </div>

                </div>
            </div>
        </div>
    </header>
            `
    })

    toAdd("navigation6") && bm.add("navigation6", {
        category: "Navigations",
        label: "Fixed Navigation",
        attributes: { style: "background-image: url('https://s3.us-east-2.amazonaws.com/konnektfunnels-assets/snippets/header/fixed.png'); background-repeat: no-repeat;background-position: center center;background-size: 250px 25px; " },
        content: `
            <style>
        body {
            padding: 0;
            margin: 0;
            font-family: sans-serif;
            padding-top: 140px;
        }

        img {
            max-width: 100%;
        }

        .header6 {
            background: #000;
            color: #fff;
            padding: 20px 0px;
            position: fixed;
            width: 100%;
            top: 0;
        }

        .header6 .logo_image {
            width: 300px;
        }

        .header6 .toggle_bars {
            float: right;
        }

        .header6 .nav_section nav,
        .nav_section ul,
        .header6 .social_icon nav,
        .header6 .social_icon ul {
            margin: 0;
            padding: 0;
            width: 100%;

        }

        .header6 .nav_section ul li {
            float: left;
            list-style: none;
            margin-right: 3%;
            margin-right: 4%;

        }

        .header6 .nav_section ul li,
        .header6 .social_icon ul li {
            float: left;
            list-style: none;
            padding: 10px;
            border: 1px solid #000;

        }

        .header6 .social_icon ul li {
            margin-left: 15px;
            font-size: 16px;
        }

        .header6 .nav_section ul li a,
        .header6 .social_icon ul li a {
            color: #fff;
            text-decoration: none;
            cursor: pointer;
        }

        .header6 .nav_section ul li:hover,
        .header6 .social_icon ul li:hover {
            padding: 10px;
            border: 1px solid #fff;

        }

        @media (min-width : 990px) {
            .toggle_bars {
                display: none;
            }

            .nav_section.social_icon {
                display: block;
            }

            .logo_sec {
                display: flex;
                justify-content: space-between;
                align-items: center;
            }

            .header6 .nav_section {
                display: flex;
                justify-content: flex-start;
                align-items: center;
            }

            .header6 .social_icon {
                float: right;
                margin-top: 5px;
            }

            .collapse {
                display: block !important;
            }
        }

        .header6 .social_icon .text_social_icon {
            display: none;
        }

        @media (max-width:990px) {
            .toggle_bars {
                display: block;
                margin-top: 10px;
            }

            .social_icons_block {
                display: inline-block;

            }

            body {
                padding: 0 !important;
                margin: 0;
                font-family: sans-serif;

            }

            .header6 {
                background: #000;
                color: #fff;
                padding: 20px 0px;
                position: relative;
                width: 100%;
                top: 0;
            }

            .header6 .social_icon .text_social_icon {
                display: block;
            }

            .header6 .nav_section ul li {
                float: none;
                padding: 15px;
                border-bottom: 1px solid #fff;
            }

            .header6 .social_icon {
                float: left;
                width: 100%;
                border-bottom: 1px solid #fff;
                padding: 10px;
                text-align: center;
                margin-bottom: 10px;
            }

            .header6 .social_icon nav {
                float: right;
            }

            .header6 .social_icon nav ul li {
                font-size: 18px;
            }
        }
    </style>
    <header class="header6">
        <div class="container">
            <div class="row" data-gjs-resizable='{"tl":0,"tc":0,"tr":0,"cl":0,"cr":0,"bl":0,"br":0}'>
                <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 social_icons_block logo_sec" data-gjs-resizable='{"tl":0,"tc":0,"tr":0,"cl":0,"bl":0,"br":0,"bc": 0, "keyWidth": "flex-basis", "currentUnit": 1, "minDim": 1, "maxDim": 100, "step": 0.2}'>
                    <img src="https://s3.us-east-2.amazonaws.com/konnektfunnels-assets/snippets/header/logo.png" class="logo_image" alt="Logo">

                    <div class="toggle_bars">
                        <i class="fa fa-bars fa-2x" data-toggle="collapse" data-target="#navMenu"></i>
                    </div>
                    <div class="social_icon">
                        <nav>
                            <ul>
                                <li><a><i class="fa fa-linkedin"></i><span class="text_social_icon">Linkedin</span></a></li>
                                <li><a><i class="fa fa-twitter"></i><span class="text_social_icon">Twitter</span></a></li>
                                <li><a><i class="fa fa-facebook"></i><span class="text_social_icon">Facebook</span></a></li>
                                <li><a><i class="fa fa-youtube"></i><span class="text_social_icon">Youtube</span></a></li>
                            </ul>
                        </nav>
                    </div>

                </div>
                <div id="navMenu" class="col-xs-12 col-md-12 col-sm-12 col-lg-12 collapse " data-gjs-resizable='{"tl":0,"tc":0,"tr":0,"cl":0,"bl":0,"br":0,"bc": 0, "keyWidth": "flex-basis", "currentUnit": 1, "minDim": 1, "maxDim": 100, "step": 0.2}'>

                    <div class="nav_section">
                        <nav>
                            <ul>
                                <li><a href="">Home</a></li>
                                <li><a href="">About us</a></li>
                                <li><a href="">Contact us</a></li>
                                <li><a href="">Career</a></li>
                                <li><a href="">Product</a></li>
                                <li><a href="">Portfolio</a></li>
                                <li><a href="">Login</a></li>
                                <li><a href="">Signup</a></li>
                            </ul>
                        </nav>
                    </div>

                </div>
            </div>
        </div>
    </header>
        `
    })



    //PRICING

    toAdd("pricing1") && bm.add("pricing1", {
        category: "Pricing",
        label: "Pricing1",
        attributes: { style: "background-image: url('https://s3.us-east-2.amazonaws.com/konnektfunnels-assets/snippets/pricing/pricing1.png'); background-repeat: no-repeat;background-position: center center;background-size: 250px 100px; " },
        content: `
         <style>
        body {
            padding: 0;
            margin: 0;
            font-family:sans-serif;
        }
        img {
            max-width: 100%;
        }
        .pricing1{
            padding: 30px;
        }
        .pricing1 > .container > h1,.pricing1  > .container  > p {
            color:#000;
            text-align: center;
        }
        .pricing1  .blocks img{
            max-height: 200px;
            min-height: 200px;
            width: 100%;
            margin: 20px 0px;
        }
        .pricing1  .blocks h2{
            font-weight:700;
            color: #d06b6b;
        }
        .pricing1  .blocks p{
            font-size:14px;
        }
        .pricing1  .blocks{
            background: #e6d5d5ab;
            padding: 35px;
            margin: 20px 0px;
            display: flex;
            flex-flow: column;
            justify-content: center;
            align-items: center;
            border: 1px solid #d06b6b54;
        } 
        .block_1_content.blocks h1{
            color: blue;
        }  
        .pricing1 .subscribe_btn {
            background: transparent;
            border-radius: 30px;
            color: #000;
            width: 200px;
            font-weight: 700;
            padding: 10px 20px;
            margin-bottom: 20px;
            margin-top: 10px;
            border: 3px solid #d06b6b;
        }
        .features_list ul li{
            list-style: none;
        }
        .features_list ul li.can_avail::before{
            content: "\\f00c";
            font: normal normal normal 14px/1 FontAwesome;
            margin-right: 10px;
            color: green;
        }
        .features_list ul li.cant_avail::before{
            content: "\\f00d";
            font: normal normal normal 14px/1 FontAwesome;
            margin-right: 10px;
            color: red;
        }
        .pricing1 .subscribe_btn:hover {
            background: #d06b6b;
            color: #fff;
        }
        .pricing1 .blocks:hover{
            box-shadow:10px 10px 10px 0px #d06b6b;
        }
        .pricing1 .blocks:hover .banner_btn{
            width: 100%;
        }
        .pricing1 .blocks:hover .banner_btn .btn{
            width: 100% !important;
        }
        @media (max-width : 990px){
            .pricing1{
                background-position:unset;
            }
        }
    </style>
    <section>
        <div class="pricing1">
            <div class="container">
                <h1>ADD YOUR TEXT HERE</h1>
                <p>Lorem, ipsum dolor sit amet consectetur adipisicing elit. Facere assumenda nesciunt dicta earum, non, et quibusdam quis hic vel molestiae magnam necessitatibus consectetur veritatis voluptatibus cumque tempora, ullam nisi dolorum.</p>
                <div class="row feature_content" data-gjs-resizable='{"tl":0,"tc":0,"tr":0,"cl":0,"cr":0,"bl":0,"br":0}'>
                    <div class="col-lg-4 col-md-6  col-xs-12 " data-gjs-resizable='{"tl":0,"tc":0,"tr":0,"cl":0,"bl":0,"br":0,"bc": 0, "keyWidth": "flex-basis", "currentUnit": 1, "minDim": 1, "maxDim": 100, "step": 0.2}'>
                        <div class="block_1_content blocks">
                            <h2>Starter</h2>
                            <h1>$9/month</h1>
                            
                            <div class="features_list ">
                                    <ul>
                                            <li class="can_avail">5GB Linux Web Space</li>
                                            <li class="can_avail">5 MySQL Databases</li>
                                            <li class="can_avail">Unlimited Email</li>
                                            <li class="cant_avail">250Gb mo Transfer</li>
                                            <li class="cant_avail">24/7 Tech Support</li>
                                            <li class="cant_avail">Daily Backups</li>
                                        </ul>
                            </div>
                            <div class="banner_btn">
                                    <button class="btn subscribe_btn">Subscribe</button>
                                </div>
                        </div>
                    </div>

                    <div class="col-lg-4 col-md-6  col-xs-12 " data-gjs-resizable='{"tl":0,"tc":0,"tr":0,"cl":0,"bl":0,"br":0,"bc": 0, "keyWidth": "flex-basis", "currentUnit": 1, "minDim": 1, "maxDim": 100, "step": 0.2}'>
                            <div class="block_1_content blocks">
                                <h2>Professional</h2>
                                <h1>$19/month</h1>
                                
                                <div class="features_list ">
                                        <ul>
                                            <li class="can_avail">5GB Linux Web Space</li>
                                            <li class="can_avail">5 MySQL Databases</li>
                                            <li class="can_avail">Unlimited Email</li>
                                            <li class="can_avail">250Gb mo Transfer</li>
                                            <li class="cant_avail">24/7 Tech Support</li>
                                            <li class="cant_avail">Daily Backups</li>
                                            </ul>
                                </div>
                                <div class="banner_btn">
                                        <button class="btn subscribe_btn">Subscribe</button>
                                    </div>
                            </div>
                        </div>
                        <div class="col-lg-4 col-md-6  col-xs-12 " data-gjs-resizable='{"tl":0,"tc":0,"tr":0,"cl":0,"bl":0,"br":0,"bc": 0, "keyWidth": "flex-basis", "currentUnit": 1, "minDim": 1, "maxDim": 100, "step": 0.2}'>
                                <div class="block_1_content blocks">
                                    <h2>Business</h2>
                                    <h1>$29/month</h1>
                                    
                                    <div class="features_list ">
                                            <ul>
                                                    <li class="can_avail">5GB Linux Web Space</li>
                                                    <li class="can_avail">5 MySQL Databases</li>
                                                    <li class="can_avail">Unlimited Email</li>
                                                    <li class="can_avail">250Gb mo Transfer</li>
                                                    <li class="can_avail">24/7 Tech Support</li>
                                                    <li class="can_avail">Daily Backups</li>
                                                </ul>
                                    </div>
                                    <div class="banner_btn">
                                            <button class="btn subscribe_btn">Subscribe</button>
                                        </div>
                                </div>
                            </div>
                    
                </div>
            </div>
        </div>
    </section>
        `
    });
    toAdd("pricing2") && bm.add("pricing2", {
        category: "Pricing",
        label: "Pricing2",
        attributes: { style: "background-image: url('https://s3.us-east-2.amazonaws.com/konnektfunnels-assets/snippets/pricing/pricing2.png'); background-repeat: no-repeat;background-position: center center;background-size: 250px 100px; " },
        content: `
        <style>
        body {
            padding: 0;
            margin: 0;
            font-family:sans-serif;
        }
        img {
            max-width: 100%;
        }
       .pricing2 .card-pricing:hover{
                box-shadow: 0 .5rem 1rem rgba(0,0,0,.15)!important;
        }
        .pricing2 .card-pricing:hover .btn{
            color: #fff;
            background-color: #007bff;
            border-color: #007bff;
        }
        .pricing2 .list-unstyled{
            text-align: left;
        }
        .pricing2 ul li.can_avail::before{
            content: "\\f00c";
            font: normal normal normal 14px/1 FontAwesome;
            margin-right: 10px;
            color: green;
        }
        .pricing2 ul li.cant_avail::before{
            content: "\\f00d";
            font: normal normal normal 14px/1 FontAwesome;
            margin-right: 10px;
            color: red;
        }
    </style>
        <section class="pricing2">
        <div class="container mb-5 mt-5">
            <div class="pricing card-deck flex-column flex-md-row mb-3" data-gjs-resizable='{"tl":0,"tc":0,"tr":0,"cl":0,"cr":0,"bl":0,"br":0}'>
                <div class="card card-pricing text-center  mb-4" data-gjs-resizable='{"tl":0,"tc":0,"tr":0,"cl":0,"bl":0,"br":0,"bc": 0, "keyWidth": "flex-basis", "currentUnit": 1, "minDim": 1, "maxDim": 100, "step": 0.2}'>
                    <span class="h6 w-60 mt-2 px-4 py-1 rounded-bottom bg-primary text-white shadow-sm">Starter</span>
                    <div class="bg-transparent card-header pt-4 border-0">
                        <h1 class="h1 font-weight-normal text-primary text-center mb-0" data-pricing-value="15">$<span
                                class="price">3</span><span class="h6 text-muted ml-2">/ per month</span></h1>
                    </div>
                    <div class="card-body pt-0">
                        <ul class="list-unstyled mb-4">
                                <li class="can_avail">5GB Linux Web Space</li>
                                <li class="can_avail">5 MySQL Databases</li>
                                <li class="can_avail">Unlimited Email</li>
                                <li class="cant_avail">250Gb mo Transfer</li>
                                <li class="cant_avail">24/7 Tech Support</li>
                                <li class="cant_avail">Daily Backups</li>
                        </ul>
                        <button type="button" class="btn btn-outline-secondary mb-3">Select Now</button>
                    </div>
                </div>
                <div class="card card-pricing popular  text-center  mb-4" data-gjs-resizable='{"tl":0,"tc":0,"tr":0,"cl":0,"bl":0,"br":0,"bc": 0, "keyWidth": "flex-basis", "currentUnit": 1, "minDim": 1, "maxDim": 100, "step": 0.2}'>
                    <span class="h6 w-60 mt-2 px-4 py-1 rounded-bottom bg-primary text-white shadow-sm">Professional</span>
                    <div class="bg-transparent card-header pt-4 border-0">
                        <h1 class="h1 font-weight-normal text-primary text-center mb-0" data-pricing-value="30">$<span
                                class="price">6</span><span class="h6 text-muted ml-2">/ per month</span></h1>
                    </div>
                    <div class="card-body pt-0">
                        <ul class="list-unstyled mb-4">
                                <li class="can_avail">5GB Linux Web Space</li>
                                <li class="can_avail">5 MySQL Databases</li>
                                <li class="can_avail">Unlimited Email</li>
                                <li class="can_avail">250Gb mo Transfer</li>
                                <li class="cant_avail">24/7 Tech Support</li>
                                <li class="cant_avail">Daily Backups</li>
                        </ul>
                        <a href="https://www.totoprayogo.com" target="_blank" class="btn btn-outline-secondary mb-3">Select Now</a>
                    </div>
                </div>
                <div class="card card-pricing text-center  mb-4" data-gjs-resizable='{"tl":0,"tc":0,"tr":0,"cl":0,"bl":0,"br":0,"bc": 0, "keyWidth": "flex-basis", "currentUnit": 1, "minDim": 1, "maxDim": 100, "step": 0.2}'>
                    <span class="h6 w-60 mt-2 px-4 py-1 rounded-bottom bg-primary text-white shadow-sm">Business</span>
                    <div class="bg-transparent card-header pt-4 border-0">
                        <h1 class="h1 font-weight-normal text-primary text-center mb-0" data-pricing-value="45">$<span
                                class="price">9</span><span class="h6 text-muted ml-2">/ per month</span></h1>
                    </div>
                    <div class="card-body pt-0">
                        <ul class="list-unstyled mb-4">
                                <li class="can_avail">5GB Linux Web Space</li>
                                <li class="can_avail">5 MySQL Databases</li>
                                <li class="can_avail">Unlimited Email</li>
                                <li class="can_avail">250Gb mo Transfer</li>
                                <li class="cant_avail">24/7 Tech Support</li>
                                <li class="cant_avail">Daily Backups</li>
                        </ul>
                        <button type="button" class="btn btn-outline-secondary mb-3">Select Now</button>
                    </div>
                </div>
                <div class="card card-pricing text-center  mb-4" data-gjs-resizable='{"tl":0,"tc":0,"tr":0,"cl":0,"bl":0,"br":0,"bc": 0, "keyWidth": "flex-basis", "currentUnit": 1, "minDim": 1, "maxDim": 100, "step": 0.2}'>
                    <span class="h6 w-60 mt-2 px-4 py-1 rounded-bottom bg-primary text-white shadow-sm">Enterprise</span>
                    <div class="bg-transparent card-header pt-4 border-0">
                        <h1 class="h1 font-weight-normal text-primary text-center mb-0" data-pricing-value="60">$<span
                                class="price">12</span><span class="h6 text-muted ml-2">/ per month</span></h1>
                    </div>
                    <div class="card-body pt-0">
                        <ul class="list-unstyled mb-4">
                                <li class="can_avail">5GB Linux Web Space</li>
                                <li class="can_avail">5 MySQL Databases</li>
                                <li class="can_avail">Unlimited Email</li>
                                <li class="can_avail">250Gb mo Transfer</li>
                                <li class="can_avail">24/7 Tech Support</li>
                                <li class="can_avail">Daily Backups</li>
                        </ul>
                        <button type="button" class="btn btn-outline-secondary mb-3">Select Now</button>
                    </div>
                </div>
            </div>
        </div>
    </section>

        `
    });
    toAdd("pricing3") && bm.add("pricing3", {
        category: "Pricing",
        label: "Pricing3",
        attributes: { style: "background-image: url('https://s3.us-east-2.amazonaws.com/konnektfunnels-assets/snippets/pricing/pricing3.png'); background-repeat: no-repeat;background-position: center center;background-size: 250px 100px; " },
        content: `
         <style>
        body {
            padding: 0;
            margin: 0;
            font-family: sans-serif;
        }

        img {
            max-width: 100%;
        }
        .pricing3{
            padding:30px 0px;
            background: #f4f4f4;
        }
        .pricing3 .card-pricing:hover {
            box-shadow: 0 .5rem 1rem rgba(0, 0, 0, .15) !important;
        }
        .pricing {
            text-align: center;
        }
        .pricing_block .well{
            padding: 20px 10px;
            margin: 20px 0px;
        }
        .pricing_block:nth-child(1) .well{
            border: 1px solid #000;
            padding: 20px 10px;
        }
        .pricing_block:nth-child(2) .well{
            border: 1px solid #007bff;
        }
        .pricing_block:nth-child(3) .well{
            border: 1px solid #16a2b8;
        }
        .pricing_block:nth-child(4) .well{
            border:1px solid #28a745
        }
    </style>
        <section class="pricing3">
        <div class="container">
            <div class="row pricing" data-gjs-resizable='{"tl":0,"tc":0,"tr":0,"cl":0,"cr":0,"bl":0,"br":0}'>
                <div class="col-lg-3 col-md-6 col-xs-12 pricing_block" data-gjs-resizable='{"tl":0,"tc":0,"tr":0,"cl":0,"bl":0,"br":0,"bc": 0, "keyWidth": "flex-basis", "currentUnit": 1, "minDim": 1, "maxDim": 100, "step": 0.2}'>
                    <div class="well">
                        <h3><b>Plan 1</b></h3>
                        <hr>
                        <p>1 GB Datatransfer</p>
                        <hr>
                        <p>1 GB Storage</p>
                        <hr>
                        <p><b>€ 2,99 /PM</b></p>
                        <hr>
                        <a href="#" class="btn btn-default btn-block">Sign Up</a>
                    </div>
                </div>
                <div class="col-lg-3 col-md-6 col-xs-12 pricing_block" data-gjs-resizable='{"tl":0,"tc":0,"tr":0,"cl":0,"bl":0,"br":0,"bc": 0, "keyWidth": "flex-basis", "currentUnit": 1, "minDim": 1, "maxDim": 100, "step": 0.2}'>
                    <div class="well">
                        <h3><b>Plan 2</b></h3>
                        <hr>
                        <p>2 GB Datatransfer</p>
                        <hr>
                        <p>2 GB Storage</p>
                        <hr>
                        <p><b>€ 3,99 /PM</b></p>
                        <hr>
                        <a href="#" class="btn btn-primary btn-block">Sign Up</a>
                    </div>
                </div>
                <div class="col-lg-3 col-md-6 col-xs-12 pricing_block" data-gjs-resizable='{"tl":0,"tc":0,"tr":0,"cl":0,"bl":0,"br":0,"bc": 0, "keyWidth": "flex-basis", "currentUnit": 1, "minDim": 1, "maxDim": 100, "step": 0.2}'>
                    <div class="well">
                        <h3><b>Plan 3</b></h3>
                        <hr>
                        <p>3 GB Datatransfer</p>
                        <hr>
                        <p>3 GB Storage</p>
                        <hr>
                        <p><b>€ 4,99 /PM</b></p>
                        <hr>
                        <a href="#" class="btn btn-info btn-block">Sign Up</a>
                    </div>
                </div>
                <div class="col-lg-3 col-md-6 col-xs-12 pricing_block" data-gjs-resizable='{"tl":0,"tc":0,"tr":0,"cl":0,"bl":0,"br":0,"bc": 0, "keyWidth": "flex-basis", "currentUnit": 1, "minDim": 1, "maxDim": 100, "step": 0.2}'>
                    <div class="well">
                        <h3><b>Plan 4</b></h3>
                        <hr>
                        <p>4 GB Datatransfer</p>
                        <hr>
                        <p>4 GB Storage</p>
                        <hr>
                        <p><b>€ 5,99 /PM</b></p>
                        <hr>
                        <a href="#" class="btn btn-success btn-block">Sign Up</a>
                    </div>
                </div>


            </div>
        </div>
</section>
        `
    });
    toAdd("pricing4") && bm.add("pricing4", {
        category: "Pricing",
        label: "Pricing4",
        attributes: { style: "background-image: url('https://s3.us-east-2.amazonaws.com/konnektfunnels-assets/snippets/pricing/pricing4.png'); background-repeat: no-repeat;background-position: center center;background-size: 250px 100px; " },
        content: `
        <style>
        body {
            padding: 0;
            margin: 0;
            font-family: sans-serif;
        }

        img {
            max-width: 100%;
        }

        .pricing4 {
            font-family: 'Source Sans Pro', Arial, sans-serif;
            color: #ffffff;
            text-align: left;
            font-size: 16px;
            width: 100%;
            padding: 50px;
            background: #f4f4f4;
        }

        .pricing4 img {
            position: absolute;
            left: 0;
            top: 0;
            height: 100%;
            z-index: -1;
        }

        .pricing4 .plan {
            margin: 0;
            width: 25%;
            position: relative;
            float: left;
            overflow: hidden;
           
            box-shadow: 0 0 5px rgba(0, 0, 0, 0.3);
            background-color: #5f3047;
        }

        .pricing4 .plan:hover i,
        .pricing4 .plan.hover i {
            -webkit-transform: scale(1.2);
            transform: scale(1.2);
        }

        .pricing4 .plan:first-of-type {
            border-radius: 8px 0 0 8px;
        }

        .pricing4 .plan:last-of-type {
            border-radius: 0 8px 8px 0;
        }

        .pricing4 * {
            -webkit-box-sizing: border-box;
            box-sizing: border-box;
            -webkit-transition: all 0.25s ease-out;
            transition: all 0.25s ease-out;
        }

        .pricing4 header {
            background-color: #5f3047;
            color: #ffffff;
        }

        .pricing4 .plan-title {
            background-color: rgba(0, 0, 0, 0.5);
            position: relative;
            margin: 0;
            padding: 20px 20px;
            text-transform: uppercase;
            letter-spacing: 4px;
            text-align: center;
        }
        .pricing4 .plan-cost {
            padding: 40px 20px 10px;
            text-align: right;
        }

        .pricing4 .plan-price {
            font-weight: 600;
            font-size: 3em;
        }

        .pricing4 .plan-type {
            opacity: 0.8;
            font-size: 0.7em;
            text-transform: uppercase;
        }

        .pricing4 .plan-features {
            padding: 0 0 20px;
            background: #603047;
            margin: 0;
            list-style: outside none none;
        }

        .pricing4 .plan-features li {
            padding: 8px 5%;
        }

        .pricing4 .plan-features i {
            margin-right: 8px;
            color: rgba(0, 0, 0, 0.5);
        }

        .pricing4 .plan-select {
            border-top: 1px solid rgba(0, 0, 0, 0.2);
            padding: 20px;
            text-align: center;
            background: #603047;
        }

        .pricing4 .plan-select a {
            background-color: #442232;
            color: #ffffff;
            text-decoration: none;
            padding: 12px 20px;
            font-size: 0.75em;
            font-weight: 600;
            border-radius: 20px;
            text-transform: uppercase;
            letter-spacing: 4px;
            display: inline-block;
        }

        .pricing4 .plan-select a:hover {
            background-color: #552a3f;
        }
        .pricing4 .featured{
            margin: 20px 0px;
        }
        .pricing4 .featured:hover {
            margin-top: -10px;
            border-color: #331926;
            box-shadow: 0 0 25px rgba(0, 0, 0, 0.4);
            z-index: 1;
            border-radius: 8px;
        }

        .pricing4 .featured .plan-select {
            padding: 30px 20px;
        }
        .pricing4 ul li.can_avail::before{
            content: "\\f00c";
            font: normal normal normal 14px/1 FontAwesome;
            margin-right: 10px;
            color: green;
        }
        .pricing4 ul li.cant_avail::before{
            content: "\\f00d";
            font: normal normal normal 14px/1 FontAwesome;
            margin-right: 10px;
            color: red;
        }
    </style>
        <section class="row pricing4" data-gjs-resizable='{"tl":0,"tc":0,"tr":0,"cl":0,"cr":0,"bl":0,"br":0}'>
        <div class="col-xs-12 col-sm-12 col-md-6 col-lg-3 featured" data-gjs-resizable='{"tl":0,"tc":0,"tr":0,"cl":0,"bl":0,"br":0,"bc": 0, "keyWidth": "flex-basis", "currentUnit": 1, "minDim": 1, "maxDim": 100, "step": 0.2}'>
            <header>
                <h4 class="plan-title">
                    Starter
                </h4>
                <div class="plan-cost"><span class="plan-price">$19</span><span class="plan-type">/month</span></div>
            </header>
            <ul class="plan-features">
               <li class="can_avail">5GB Linux Web Space</li>
                <li class="can_avail">5 MySQL Databases</li>
                <li class="can_avail">Unlimited Email</li>
                <li class="cant_avail">250Gb mo Transfer</li>
                <li class="cant_avail">24/7 Tech Support</li>
                <li class="cant_avail">Daily Backups</li>
            </ul>
            <div class="plan-select"><a href="">Select Plan</a></div>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-6 col-lg-3 featured" data-gjs-resizable='{"tl":0,"tc":0,"tr":0,"cl":0,"bl":0,"br":0,"bc": 0, "keyWidth": "flex-basis", "currentUnit": 1, "minDim": 1, "maxDim": 100, "step": 0.2}'>
            <header>
                <h4 class="plan-title">
                    Basic
                </h4>
                <div class="plan-cost"><span class="plan-price">$29</span><span class="plan-type">/month</span></div>
            </header>
            <ul class="plan-features">
                    <li class="can_avail">5GB Linux Web Space</li>
                    <li class="can_avail">5 MySQL Databases</li>
                    <li class="can_avail">Unlimited Email</li>
                    <li class="cant_avail">250Gb mo Transfer</li>
                    <li class="cant_avail">24/7 Tech Support</li>
                    <li class="cant_avail">Daily Backups</li>
            </ul>
            <div class="plan-select"><a href="">Select Plan</a></div>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-6 col-lg-3 featured" data-gjs-resizable='{"tl":0,"tc":0,"tr":0,"cl":0,"bl":0,"br":0,"bc": 0, "keyWidth": "flex-basis", "currentUnit": 1, "minDim": 1, "maxDim": 100, "step": 0.2}'>
            <header>
                <h4 class="plan-title">

                    Professional
                </h4>
                <div class="plan-cost"><span class="plan-price">$49</span><span class="plan-type">/month</span></div>
            </header>
            <ul class="plan-features">
                    <li class="can_avail">5GB Linux Web Space</li>
                    <li class="can_avail">5 MySQL Databases</li>
                    <li class="can_avail">Unlimited Email</li>
                    <li class="can_avail">250Gb mo Transfer</li>
                    <li class="cant_avail">24/7 Tech Support</li>
                    <li class="cant_avail">Daily Backups</li>
            </ul>
            <div class="plan-select"><a href="">Select Plan</a></div>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-6 col-lg-3 featured" data-gjs-resizable='{"tl":0,"tc":0,"tr":0,"cl":0,"bl":0,"br":0,"bc": 0, "keyWidth": "flex-basis", "currentUnit": 1, "minDim": 1, "maxDim": 100, "step": 0.2}'>
            <header>
                <h4 class="plan-title">

                    Ultra
                </h4>
                <div class="plan-cost"><span class="plan-price">$99</span><span class="plan-type">/month</span></div>
            </header>
            <ul class="plan-features">
                    <li class="can_avail">5GB Linux Web Space</li>
                    <li class="can_avail">5 MySQL Databases</li>
                    <li class="can_avail">Unlimited Email</li>
                    <li class="can_avail">250Gb mo Transfer</li>
                    <li class="can_avail">24/7 Tech Support</li>
                    <li class="can_avail">Daily Backups</li>
            </ul>
            <div class="plan-select"><a href="">Select Plan</a></div>
        </div>
    </section>
        `
    });
    toAdd("pricing5") && bm.add("pricing5", {
        category: "Pricing",
        label: "Pricing5",
        attributes: { style: "background-image: url('https://s3.us-east-2.amazonaws.com/konnektfunnels-assets/snippets/pricing/pricing5.png'); background-repeat: no-repeat;background-position: center center;background-size: 250px 100px; " },
        content: `
            <style>
        body {
            padding: 0;
            margin: 0;
            font-family: sans-serif;
        }

        img {
            max-width: 100%;
        }
        .pricing5 {
            padding: 30px 0px;
        }
        .pricing5 .panel {
            background-color: #fff;
            border-radius: 10px;
            padding: 15px 25px;
            position: relative;
            width: 100%;
            z-index: 10;
        }

        .pricing5 .pricing-table {
            box-shadow: 0px 10px 13px -6px rgba(0, 0, 0, 0.08), 0px 20px 31px 3px rgba(0, 0, 0, 0.09), 0px 8px 20px 7px rgba(0, 0, 0, 0.02);
            display: flex;
            flex-direction: column;
        }

        @media (min-width: 900px) {
            .pricing5 .pricing-table {
                flex-direction: row;
            }
        }

       .pricing5 .pricing-table  {
            text-align: center;
            text-transform: uppercase;
        }

       .pricing5 .pricing-plan {
            border-bottom: 1px solid #e1f1ff;
            padding: 25px;
        }

        .pricing5 .pricing-plan:last-child {
            border-bottom: none;
        }

        @media (min-width: 990px) {
            .pricing5  .pricing-plan {
                border-bottom: none;
                border-right: 1px solid #e1f1ff;
                flex-basis: 100%;
                padding: 25px 50px;
            }

            .pricing5 .pricing-plan:last-child {
                border-right: none;
            }
        }

       .pricing5 .pricing-img {
            margin-bottom: 25px;
            max-width: 100%;
        }

       .pricing5 .pricing-header {
            color: #888;
            font-weight: 600;
            letter-spacing: 1px;
        }

        .pricing5 .pricing-features {
            color: #016FF9;
            font-weight: 400;
            text-align: left;
            letter-spacing: 1px;
            margin: 50px 0 25px;
        }

        .pricing5 .pricing-features-item {
            border-top: 1px solid #e1f1ff;
            font-size: 12px;
            line-height: 1.5;
            padding: 15px 0;
            list-style: none;
        }

        .pricing5 .pricing-features-item:last-child {
            border-bottom: 1px solid #e1f1ff;
        }

        .pricing5 .pricing-price {
            color: #016FF9;
            display: block;
            font-size: 32px;
            font-weight: 700;
        }

       .pricing5  .pricing-button {
            border: 1px solid #9dd1ff;
            border-radius: 10px;
            color: #348EFE;
            display: inline-block;
            margin: 25px 0;
            padding: 15px 35px;
            text-decoration: none;
            transition: all 150ms ease-in-out;
        }

        .pricing5.pricing-button:hover,
        .pricing5 .pricing-button:focus {
            background-color: #e1f1ff;
        }

        .pricing5 .pricing-button.is-featured {
            background-color: #48aaff;
            color: #fff;
        }

        .pricing5 .pricing-button.is-featured:hover,
        .pricing5 .pricing-button.is-featured:active {
            background-color: #269aff;
        }
    </style>
        <section class="row pricing5" data-gjs-resizable='{"tl":0,"tc":0,"tr":0,"cl":0,"cr":0,"bl":0,"br":0}'>
        <div class="container">
            <div class="panel pricing-table">

                <div class="pricing-plan" data-gjs-resizable='{"tl":0,"tc":0,"tr":0,"cl":0,"bl":0,"br":0,"bc": 0, "keyWidth": "flex-basis", "currentUnit": 1, "minDim": 1, "maxDim": 100, "step": 0.2}'>
                   
                    <h2 class="pricing-header">Basic</h2>
                    <ul class="pricing-features">
                            <li class="can_avail">5GB Linux Web Space</li>
                            <li class="can_avail">5 MySQL Databases</li>
                            <li class="can_avail">Unlimited Email</li>
                            <li class="cant_avail">250Gb mo Transfer</li>
                            <li class="cant_avail">24/7 Tech Support</li>
                            <li class="cant_avail">Daily Backups</li>
                    </ul>
                    <span class="pricing-price">Free</span>
                    <a href="#/" class="pricing-button">Sign up</a>
                </div>

                <div class="pricing-plan" data-gjs-resizable='{"tl":0,"tc":0,"tr":0,"cl":0,"bl":0,"br":0,"bc": 0, "keyWidth": "flex-basis", "currentUnit": 1, "minDim": 1, "maxDim": 100, "step": 0.2}'>
                   
                    <h2 class="pricing-header">Medium</h2>
                    <ul class="pricing-features">
                            <li class="can_avail">5GB Linux Web Space</li>
                            <li class="can_avail">5 MySQL Databases</li>
                            <li class="can_avail">Unlimited Email</li>
                            <li class="can_avail">250Gb mo Transfer</li>
                            <li class="can_avail">24/7 Tech Support</li>
                            <li class="cant_avail">Daily Backups</li>
                    </ul>
                    <span class="pricing-price">$150</span>
                    <a href="#/" class="pricing-button is-featured">Free trial</a>
                </div>

                <div class="pricing-plan" data-gjs-resizable='{"tl":0,"tc":0,"tr":0,"cl":0,"bl":0,"br":0,"bc": 0, "keyWidth": "flex-basis", "currentUnit": 1, "minDim": 1, "maxDim": 100, "step": 0.2}'>
                   
                    <h2 class="pricing-header">Enterprise</h2>
                    <ul class="pricing-features">
                            <li class="can_avail">5GB Linux Web Space</li>
                            <li class="can_avail">5 MySQL Databases</li>
                            <li class="can_avail">Unlimited Email</li>
                            <li class="can_avail">250Gb mo Transfer</li>
                            <li class="can_avail">24/7 Tech Support</li>
                            <li class="can_avail">Daily Backups</li>
                    </ul>
                    <span class="pricing-price">$400</span>
                    <a href="#/" class="pricing-button">Free trial</a>
                </div>

            </div>
        </div>
    </section>
        `
    });
    toAdd("pricing6") && bm.add("pricing6", {
        category: "Pricing",
        label: "Pricing6",
        attributes: { style: "background-image: url('https://s3.us-east-2.amazonaws.com/konnektfunnels-assets/snippets/pricing/pricing6.png'); background-repeat: no-repeat;background-position: center center;background-size: 250px 100px; " },
        content: `
           <style>
        body {
            padding: 0;
            margin: 0;
            font-family: sans-serif;
        }
        img {
            max-width: 100%;
        }
        .pricing6 .pricing_content {
            background-color: #fff;
        }
        .pricing6 .pricing_content .pricing_head {
            background-color: #f6f6f6;
        }
        .pricing6 .pricing_content .pricing_head .pricing_head_content .head_bg {
            border-color: #e4e4e4 rgba(0, 0, 0, 0) rgba(0, 0, 0, 0) #e4e4e4;
        }

        .pricing6 .pricing_content .pricing_head .pricing_head_content .head span {
            color: #525252;
        }

        .pricing6 .pricing_content .pricing_head .pricing_tag .price .sign {
            color: #414141;
        }

        .pricing6 .pricing_content .pricing_head .pricing_tag .price .currency {
            color: #414141;
        }

        .pricing6 .pricing_content .pricing_head .pricing_tag .price .cent {
            color: #414141;
        }

        .pricing6 .pricing_content .pricing_head .pricing_tag .month {
            color: #414141;
        }

        .pricing6 .pricing_content .pricing_feature_list ul li {
            color: #a7a7a7;
        }

        .pricing6 .pricing_content .pricing_feature_list ul li span {
            color: #414141;
        }

        .pricing6 .pricing_content .pricing_feature_list ul li:hover {
            background-color: #E4E4E4;
            border-left: 5px solid #2ECC71;
        }

        .pricing6 .pricing_content .generic_price_btn a {
            border: 1px solid #2ECC71;
            color: #2ECC71;
        }

        .pricing6 .pricing_content.active .pricing_head .pricing_head_content .head_bg,
        .pricing6 .pricing_content:hover .pricing_head .pricing_head_content .head_bg {
            border-color: #2ECC71 rgba(0, 0, 0, 0) rgba(0, 0, 0, 0) #2ECC71;
            color: #fff;
        }

        .pricing6 .pricing_content:hover .pricing_head .pricing_head_content .head span,
        .pricing6 .pricing_content.active .pricing_head .pricing_head_content .head span {
            color: #fff;
        }

        .pricing6 .pricing_content:hover .generic_price_btn a,
        .pricing6 .pricing_content.active .generic_price_btn a {
            background-color: #2ECC71;
            color: #fff;
        }

        .pricing6 {
            margin: 50px 0 50px 0;
            font-family: 'Raleway', sans-serif;
        }

        .row .table {
            padding: 28px 0;
        }

        /*PRICE BODY CODE START*/

        .pricing6 .pricing_content {
            overflow: hidden;
            position: relative;
            text-align: center;
        }

        .pricing6 .pricing_content .pricing_head {
            margin: 0 0 20px 0;
        }

        .pricing6 .pricing_content .pricing_head .pricing_head_content {
            margin: 0 0 50px 0;
        }

        .pricing6 .pricing_content .pricing_head .pricing_head_content .head_bg {
            border-style: solid;
            border-width: 90px 1411px 23px 399px;
            position: absolute;
        }

        .pricing6 .pricing_content .pricing_head .pricing_head_content .head {
            padding-top: 40px;
            position: relative;
            z-index: 1;
        }

        .pricing6 .pricing_content .pricing_head .pricing_head_content .head span {
            font-family: "Raleway", sans-serif;
            font-size: 28px;
            font-weight: 400;
            letter-spacing: 2px;
            margin: 0;
            padding: 0;
            text-transform: uppercase;
        }

        .pricing6 .pricing_content .pricing_head .pricing_tag {
            padding: 0 0 20px;
        }

        .pricing6 .pricing_content .pricing_head .pricing_tag .price {
            display: block;
        }

        .pricing6 .pricing_content .pricing_head .pricing_tag .price .sign {
            display: inline-block;
            font-family: "Lato", sans-serif;
            font-size: 28px;
            font-weight: 400;
            vertical-align: middle;
        }

        .pricing6 .pricing_content .pricing_head .pricing_tag .price .currency {
            font-family: "Lato", sans-serif;
            font-size: 60px;
            font-weight: 300;
            letter-spacing: -2px;
            line-height: 60px;
            padding: 0;
            vertical-align: middle;
        }

        .pricing6 .pricing_content .pricing_head .pricing_tag .price .cent {
            display: inline-block;
            font-family: "Lato", sans-serif;
            font-size: 24px;
            font-weight: 400;
            vertical-align: bottom;
        }

        .pricing6 .pricing_content .pricing_head .pricing_tag .month {
            font-family: "Lato", sans-serif;
            font-size: 18px;
            font-weight: 400;
            letter-spacing: 3px;
            vertical-align: bottom;
        }

        .pricing6 .pricing_content .pricing_feature_list ul {
            list-style: none;
            padding: 0;
            margin: 0;
        }

        .pricing6 .pricing_content .pricing_feature_list ul li {
            font-family: "Lato", sans-serif;
            font-size: 18px;
            padding: 15px 0;
            transition: all 0.3s ease-in-out 0s;
        }

        .pricing6 .pricing_content .pricing_feature_list ul li:hover {
            transition: all 0.3s ease-in-out 0s;
            -moz-transition: all 0.3s ease-in-out 0s;
            -ms-transition: all 0.3s ease-in-out 0s;
            -o-transition: all 0.3s ease-in-out 0s;
            -webkit-transition: all 0.3s ease-in-out 0s;

        }

        .pricing6 .pricing_content .pricing_feature_list ul li .fa {
            padding: 0 10px;
        }

        .pricing6 .pricing_content .generic_price_btn {
            margin: 20px 0 32px;
        }

        .pricing6 .pricing_content .generic_price_btn a {
            border-radius: 50px;
            -moz-border-radius: 50px;
            -ms-border-radius: 50px;
            -o-border-radius: 50px;
            -webkit-border-radius: 50px;
            display: inline-block;
            font-family: "Lato", sans-serif;
            font-size: 18px;
            outline: medium none;
            padding: 12px 30px;
            text-decoration: none;
            text-transform: uppercase;
        }

        .pricing6 .pricing_content,
        .pricing6 .pricing_content:hover,
        .pricing6 .pricing_content .pricing_head .pricing_head_content .head_bg,
        .pricing6 .pricing_content:hover .pricing_head .pricing_head_content .head_bg,
        .pricing6 .pricing_content .pricing_head .pricing_head_content .head h2,
        .pricing6 .pricing_content:hover .pricing_head .pricing_head_content .head h2,
        .pricing6 .pricing_content .price,
        .pricing6 .pricing_content:hover .price,
        .pricing6 .pricing_content .generic_price_btn a,
        .pricing6 .pricing_content:hover .generic_price_btn a {
            transition: all 0.3s ease-in-out 0s;
            -moz-transition: all 0.3s ease-in-out 0s;
            -ms-transition: all 0.3s ease-in-out 0s;
            -o-transition: all 0.3s ease-in-out 0s;
            -webkit-transition: all 0.3s ease-in-out 0s;
        }

        @media (max-width: 320px) {}

        @media (max-width: 767px) {
            .pricing6 .pricing_content {
                margin-bottom: 75px;
            }
        }

        @media (min-width: 768px) and (max-width: 991px) {
            .pricing6 .col-md-3 {
                float: left;
                width: 50%;
            }

            .pricing6 .col-md-4 {
                float: left;
                width: 50%;
            }

            .pricing6 .pricing_content {
                margin-bottom: 75px;
            }
        }

        @media (min-width: 992px) and (max-width: 1199px) {}

        @media (min-width: 1200px) {}

        .pricing6_home {
            font-family: 'Raleway', sans-serif;
        }

        .text-center h1,
        .text-center h1 a {
            color: #7885CB;
            font-size: 30px;
            font-weight: 300;
            text-decoration: none;
        }

        .demo-pic {
            margin: 0 auto;
        }

        .demo-pic:hover {
            opacity: 0.7;
        }

        .pricing6_home ul {
            margin: 0 auto;
            padding: 0;
            list-style: none;
            display: table;
        }

        .pricing6_home li {
            float: left;
        }

        .pricing6_home li+li {
            margin-left: 10px;
            padding-bottom: 10px;
        }

        .pricing6_home li a {
            display: block;
            width: 50px;
            height: 50px;
            font-size: 0px;
        }

        .pricing6_home .blue {
            background: #3498DB;
            transition: all 0.3s ease-in-out 0s;
        }

        .pricing6_home .emerald {
            background: #2ECC71;
            transition: all 0.3s ease-in-out 0s;
        }

        .pricing6_home .grey {
            background: #7F8C8D;
            transition: all 0.3s ease-in-out 0s;
        }

        .pricing6_home .midnight {
            background: #34495E;
            transition: all 0.3s ease-in-out 0s;
        }

        .pricing6_home .orange {
            background: #E67E22;
            transition: all 0.3s ease-in-out 0s;
        }

        .pricing6_home .purple {
            background: #9B59B6;
            transition: all 0.3s ease-in-out 0s;
        }

        .pricing6_home .red {
            background: #E74C3C;
            transition: all 0.3s ease-in-out 0s;
        }

        .pricing6_home .turquoise {
            background: #1ABC9C;
            transition: all 0.3s ease-in-out 0s;
        }

        .pricing6_home .blue:hover,
        .pricing6_home .emerald:hover,
        .pricing6_home .grey:hover,
        .pricing6_home .midnight:hover,
        .pricing6_home .orange:hover,
        .pricing6_home .purple:hover,
        .pricing6_home .red:hover,
        .pricing6_home .turquoise:hover {
            border-bottom-left-radius: 50px;
            border-bottom-right-radius: 50px;
            border-top-left-radius: 50px;
            border-top-right-radius: 50px;
            transition: all 0.3s ease-in-out 0s;
        }

        .pricing6_home .divider {
            border-bottom: 1px solid #ddd;
            margin-bottom: 20px;
            padding: 20px;
        }

        .pricing6_home .divider span {
            width: 100%;
            display: table;
            height: 2px;
            background: #ddd;
            margin: 50px auto;
            line-height: 2px;
        }

        .pricing6_home .itemname {
            text-align: center;
            font-size: 50px;
            padding: 50px 0 20px;
            border-bottom: 1px solid #ddd;
            margin-bottom: 40px;
            text-decoration: none;
            font-weight: 300;
        }

        .pricing6_home .itemnametext {
            text-align: center;
            font-size: 20px;
            padding-top: 5px;
            text-transform: uppercase;
            display: inline-block;
        }

        .pricing6_home .footer {
            padding: 40px 0;
        }

        .price-heading {
            text-align: center;
        }

        .price-heading h1 {
            color: #666;
            margin: 0;
            padding: 0 0 50px 0;
        }

        .demo-button {
            background-color: #333333;
            color: #ffffff;
            display: table;
            font-size: 20px;
            margin-left: auto;
            margin-right: auto;
            margin-top: 20px;
            margin-bottom: 50px;
            outline-color: -moz-use-text-color;
            outline-style: none;
            outline-width: medium;
            padding: 10px;
            text-align: center;
            text-transform: uppercase;
        }

        .bottom_btn {
            background-color: #333333;
            color: #ffffff;
            display: table;
            font-size: 28px;
            margin: 60px auto 20px;
            padding: 10px 25px;
            text-align: center;
            text-transform: uppercase;
        }

        .demo-button:hover {
            background-color: #666;
            color: #FFF;
            text-decoration: none;

        }

        .bottom_btn:hover {
            background-color: #666;
            color: #FFF;
            text-decoration: none;
        }
    </style>
            <section class="pricing6">
            <div class="container">
                <div class="row" data-gjs-resizable='{"tl":0,"tc":0,"tr":0,"cl":0,"cr":0,"bl":0,"br":0}'>
                    <div class="col-md-4" data-gjs-resizable='{"tl":0,"tc":0,"tr":0,"cl":0,"bl":0,"br":0,"bc": 0, "keyWidth": "flex-basis", "currentUnit": 1, "minDim": 1, "maxDim": 100, "step": 0.2}'>
                        <div class="pricing_content clearfix">
                            <div class="pricing_head clearfix">
                                <div class="pricing_head_content clearfix">
                                    <div class="head_bg"></div>
                                    <div class="head">
                                        <span>Basic</span>
                                    </div>
                                </div>
                                <div class="pricing_tag clearfix">
                                    <span class="price">
                                        <span class="sign">$</span>
                                        <span class="currency">99</span>
                                        <span class="cent">.99</span>
                                        <span class="month">/MON</span>
                                    </span>
                                </div>
                            </div>
                            <div class="pricing_feature_list">
                                <ul>
                                    <li><span>2GB</span> Bandwidth</li>
                                    <li><span>150GB</span> Storage</li>
                                    <li><span>12</span> Accounts</li>
                                    <li><span>7</span> Host Domain</li>
                                    <li><span>24/7</span> Support</li>
                                </ul>
                            </div>
                            <div class="generic_price_btn clearfix">
                                <a class="" href="">Sign up</a>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-4" data-gjs-resizable='{"tl":0,"tc":0,"tr":0,"cl":0,"bl":0,"br":0,"bc": 0, "keyWidth": "flex-basis", "currentUnit": 1, "minDim": 1, "maxDim": 100, "step": 0.2}'>
                        <div class="pricing_content active clearfix">
                            <div class="pricing_head clearfix">
                                <div class="pricing_head_content clearfix">
                                    <div class="head_bg"></div>
                                    <div class="head">
                                        <span>Standard</span>
                                    </div>
                                </div>
                                <div class="pricing_tag clearfix">
                                    <span class="price">
                                        <span class="sign">$</span>
                                        <span class="currency">199</span>
                                        <span class="cent">.99</span>
                                        <span class="month">/MON</span>
                                    </span>
                                </div>
                            </div>
                            <div class="pricing_feature_list">
                                <ul>
                                    <li><span>2GB</span> Bandwidth</li>
                                    <li><span>150GB</span> Storage</li>
                                    <li><span>12</span> Accounts</li>
                                    <li><span>7</span> Host Domain</li>
                                    <li><span>24/7</span> Support</li>
                                </ul>
                            </div>
                            <div class="generic_price_btn clearfix">
                                <a class="" href="">Sign up</a>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-4" data-gjs-resizable='{"tl":0,"tc":0,"tr":0,"cl":0,"bl":0,"br":0,"bc": 0, "keyWidth": "flex-basis", "currentUnit": 1, "minDim": 1, "maxDim": 100, "step": 0.2}'>
                        <div class="pricing_content clearfix">
                            <div class="pricing_head clearfix">
                                <div class="pricing_head_content clearfix">
                                    <div class="head_bg"></div>
                                    <div class="head">
                                        <span>Unlimited</span>
                                    </div>
                                </div>
                                <div class="pricing_tag clearfix">
                                    <span class="price">
                                        <span class="sign">$</span>
                                        <span class="currency">299</span>
                                        <span class="cent">.99</span>
                                        <span class="month">/MON</span>
                                    </span>
                                </div>
                            </div>
                            <div class="pricing_feature_list">
                                <ul>
                                    <li><span>2GB</span> Bandwidth</li>
                                    <li><span>150GB</span> Storage</li>
                                    <li><span>12</span> Accounts</li>
                                    <li><span>7</span> Host Domain</li>
                                    <li><span>24/7</span> Support</li>
                                </ul>
                            </div>
                            <div class="generic_price_btn clearfix">
                                <a class="" href="">Sign up</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        `
    });




    //TEAMS

    toAdd("team1") && bm.add("team1", {
        category: "Teams",
        label: "Team1",
        attributes: { style: "background-image: url('https://s3.us-east-2.amazonaws.com/konnektfunnels-assets/snippets/teams/team2.png'); background-repeat: no-repeat;background-position: center center;background-size: cover; " },
        content: `
<style>
        body {
            padding: 0;
            margin: 0;
            font-family:sans-serif;
        }

        iframe {
            max-width: 100%;
            max-height: 400px;
            width: 600px;
            height: 300px;
        }
        img {
            max-width: 100%;
        }
        .teams2_bg_image{
            background: url(https://s3.us-east-2.amazonaws.com/konnektfunnels-assets/snippets/teams/team.png);
            background-size: cover;
            padding: 3% 0px;
        }
        .teams2_bg_image > .container > h1,.teams2_bg_image  > .container  > p {
            text-align: center;
        }
        .teams2_bg_image  .blocks img{
            max-height: 150px;
            min-height: 150px;
            width: 150px;
            margin: 20px 0px;
            object-fit: cover;
            border-radius: 50%;
        }
        .teams2_bg_image  .blocks h2{
            font-weight:700;
            margin: 20px 0px;
        }
        .teams2_bg_image  .blocks p{
            font-size:14px;
        }
        .teams2_bg_image  .blocks{
            background: #f4f4f4ab;
            padding: 15px;
            margin: 20px 0px;
            display: flex;
            flex-flow: column;
            justify-content: center;
            align-items: center;
        }
        .teams2_bg_image .social_icon{
            width: 100%;
            float: left;
            padding: 0px;
        }
        .teams2_bg_image .social_icon li{
            float: left;
            list-style: none;
            margin-right: 10px;
            font-size:20px;
            cursor: pointer;
        }
        .teams2_bg_image  .blocks:hover{
            background: #231515ab;
            color: #fff;
            box-shadow: 0px 0px 52px -3px #000;
        }
        @media (max-width : 990px){
            .teams2_bg_image{
                background-position:unset;
            }
        }
    </style>
        <section>
        <div class="teams2_bg_image">
            <div class="container">
                <h1>Meet Our Team</h1>
                <div class="row feature_content" data-gjs-resizable='{"tl":0,"tc":0,"tr":0,"cl":0,"cr":0,"bl":0,"br":0}'>
                    <div class="col-lg-6 col-md-6  col-xs-12 " data-gjs-resizable='{"tl":0,"tc":0,"tr":0,"cl":0,"bl":0,"br":0,"bc": 0, "keyWidth": "flex-basis", "currentUnit": 1, "minDim": 1, "maxDim": 100, "step": 0.2}'>
                        <div class="block_1_content blocks">
                            <img src="https://s3.us-east-2.amazonaws.com/konnektfunnels-assets/snippets/teams/image3.png" class="">
                            <h2>Tim</h2>
                            <p>"Lorem ipsum dolor sit amet consectetur adipisicing elit."</p>
                            <div class="col-xs-12">
                                <div class="col-xs-12">
                                     <h4>Follow us on</h4>
                                </div>
                                <ul class="social_icon">
                                    <li><a><i class="fa fa-facebook"></i></a></li>
                                    <li><a><i class="fa fa-twitter"></i></a></li>
                                    <li><a><i class="fa fa-youtube"></i></a></li>
                                    <li><a><i class="fa fa-linkedin"></i></a></li>
                                </ul>
                            </div>
                        </div>
                    </div>

                    <div class="col-lg-6 col-md-6  col-xs-12 " data-gjs-resizable='{"tl":0,"tc":0,"tr":0,"cl":0,"bl":0,"br":0,"bc": 0, "keyWidth": "flex-basis", "currentUnit": 1, "minDim": 1, "maxDim": 100, "step": 0.2}'>
                        <div class="block_2_content blocks">
                            <img src="https://s3.us-east-2.amazonaws.com/konnektfunnels-assets/snippets/teams/image2.png" class="">
                            <h2>Tom</h2>
                            <p>"Lorem ipsum dolor sit amet consectetur adipisicing elit."</p>
                            <div class="col-xs-12">
                                <div class="col-xs-12">
                                     <h4>Follow us on</h4>
                                </div>
                                <ul class="social_icon">
                                    <li><a><i class="fa fa-facebook"></i></a></li>
                                    <li><a><i class="fa fa-twitter"></i></a></li>
                                    <li><a><i class="fa fa-youtube"></i></a></li>
                                    <li><a><i class="fa fa-linkedin"></i></a></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
        `
    })
    toAdd("team2") && bm.add("team2", {
        category: "Teams",
        label: "Team2",
        attributes: { style: "background-image: url('https://s3.us-east-2.amazonaws.com/konnektfunnels-assets/snippets/teams/team3.png'); background-repeat: no-repeat;background-position: center center;background-size: cover; " },
        content: `
<style>
        body {
            padding: 0;
            margin: 0;
            font-family: sans-serif;
        }

        img {
            max-width: 100%;
        }

        .team3 .team3_sec {
            font-family: 'Roboto', Arial, sans-serif;
            position: relative;
            overflow: hidden;
            margin: 10px;
            min-width: 230px;
            max-width: 315px;
            width: 100%;
            color: #000000;
            text-align: center;
            font-size: 16px;
            background-color: #2C3447;
            padding: 30px;
            background-image: linear-gradient(-25deg, rgba(0, 0, 0, 0.2) 0%, rgba(255, 255, 255, 0.1) 100%);
            -webkit-box-sizing: border-box;
            box-sizing: border-box;
        }

        .team3 .team3_sec *,
        .team3 .team3_sec *:before,
        .team3 .team3_sec *:after {
            -webkit-box-sizing: border-box;
            box-sizing: border-box;
            -webkit-transition: all 0.35s ease;
            transition: all 0.35s ease;
        }

        .team3 .team3_sec div {
            width: 100%;
        }

        .team3 .team3_sec h2,
        .team3 .team3_sec h4,
        .team3 .team3_sec blockquote {
            margin: 0;
        }

        .team3 .team3_sec h2,
        .team3 .team3_sec h4 {
            font-weight: 300;
        }

        .team3 .team3_sec h2 {
            color: #ffffff;
        }

        .team3 .team3_sec h4 {
            color: #a6a6a6;
        }

        .team3 .team3_sec blockquote {
            font-size: 1em;
            padding: 45px 20px 40px 50px;
            margin-top: 30px;
            background-color: #ffffff;
            border-radius: 5px;
            box-shadow: inset -1.4px -1.4px 2px rgba(0, 0, 0, 0.3);
            text-align: left;
            position: relative;
        }

        .team3 .team3_sec .profile {
            width: 100px;
            border-radius: 50%;
            display: inline-block;
            box-shadow: 3px 3px 20px rgba(0, 0, 0, 0.4);
            margin-bottom: 10px;
            border: solid 5px #A6A57A;
        }
    </style>
        <section class="team3">
        <div class="container">
            <div class="row justify-content-center" data-gjs-resizable='{"tl":0,"tc":0,"tr":0,"cl":0,"cr":0,"bl":0,"br":0}'>
                <div class="team3_sec col-xs-12" data-gjs-resizable='{"tl":0,"tc":0,"tr":0,"cl":0,"bl":0,"br":0,"bc": 0, "keyWidth": "flex-basis", "currentUnit": 1, "minDim": 1, "maxDim": 100, "step": 0.2}'>
                    <img src="https://s3.us-east-2.amazonaws.com/konnektfunnels-assets/snippets/teams/profile-sample3.jpg" alt="profile-sample3" class="profile" />
                    <div>
                        <h2>JHON</h2>
                        <h4>Software Engineer</h4>
                        <blockquote>Lorem ipsum dolor sit amet consectetur adipisicing elit. Architecto porro dolor molestiae. Quas aspernatur veniam architecto unde quod </blockquote>
                    </div>
                </div>
                <div class="team3_sec col-xs-12" data-gjs-resizable='{"tl":0,"tc":0,"tr":0,"cl":0,"bl":0,"br":0,"bc": 0, "keyWidth": "flex-basis", "currentUnit": 1, "minDim": 1, "maxDim": 100, "step": 0.2}'><img src="https://s3.us-east-2.amazonaws.com/konnektfunnels-assets/snippets/teams/profile-sample5.jpg" alt="profile-sample5" class="profile" />
                    <div>
                        <h2>JHON DOE</h2>
                        <h4>Tech Lead</h4>
                        <blockquote>Lorem ipsum dolor sit amet consectetur adipisicing elit. Architecto porro dolor molestiae. Quas aspernatur veniam architecto unde quod </blockquote>
                    </div>
                </div>
                <div class="team3_sec col-xs-12" data-gjs-resizable='{"tl":0,"tc":0,"tr":0,"cl":0,"bl":0,"br":0,"bc": 0, "keyWidth": "flex-basis", "currentUnit": 1, "minDim": 1, "maxDim": 100, "step": 0.2}'><img src="https://s3.us-east-2.amazonaws.com/konnektfunnels-assets/snippets/teams/profile-sample6.jpg" alt="profile-sample6" class="profile" />
                    <div>
                        <h2>JHON</h2>
                        <h4>Software Programmer</h4>
                        <blockquote>Lorem ipsum dolor sit amet consectetur adipisicing elit. Architecto porro dolor molestiae. Quas aspernatur veniam architecto unde quod </blockquote>
                    </div>
                </div>
            </div>
        </div>

    </section>
        `
    })
    toAdd("team3") && bm.add("team3", {
        category: "Teams",
        label: "Team3",
        attributes: { style: "background-image: url('https://s3.us-east-2.amazonaws.com/konnektfunnels-assets/snippets/teams/team4.png'); background-repeat: no-repeat;background-position: center center;background-size: cover; " },
        content: `
<style>
        body {
            padding: 0;
            margin: 0;
            font-family: sans-serif;
        }

        img {
            max-width: 100%;
        }
        .team4{
            padding: 30px 0px;
        }
        .team4 .our-team {
            text-align: center;
            position: relative;
        }

        .team4 .our-team .pic {
            width: 200px;
            height: 200px;
            border-radius: 50%;
            border: 8px solid #7a4b94;
            margin: 0 auto;
            overflow: hidden;
        }

        .team4 .our-team img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            max-width: 100%;
        }

        .team4 .our-team .social {
            list-style: none;
            padding: 0;
            margin: 0;
            position: absolute;
            top: 50px;
            left: 20px;
            transition: all 0.3s ease-out 0s;
        }

        .team4 .our-team .social li a {
            width: 30px;
            height: 30px;
            line-height: 30px;
            border-radius: 50%;
            background: #f28a31;
            font-size: 14px;
            color: #fff;
            text-align: center;
            margin-bottom: 5px;
            transform: scale(0);
            transition: all 0.2s ease 0s;
        }

        .team4 .our-team:hover .social li a {
            transform: scale(1);
        }

        .team4 .our-team:hover .social li a:hover {
            background: #7a4b94;
        }

        .team4 .our-team .team-content {
            padding: 15px 15px 20px;
            background: #7a4b94;
            border-radius: 40px 10px;
            margin-top: -20px;
            color: #fff;
        }

        .team4 .our-team .title {
            font-size: 30px;
            font-weight: bold;
            margin: 10px 0 5px 0;
        }

        .team4 .our-team .post {
            display: block;
            font-size: 14px;
            font-weight: 900;
            text-transform: uppercase;
        }

        .team4 .our-team .description {
            padding: 20px;
            background: #fff;
            border-radius: 20px;
            font-size: 16px;
            color: #666;
            line-height: 30px;
            margin-bottom: 0;
        }

        @media only screen and (max-width: 990px) {
            .team4 .our-team {
                margin-bottom: 30px;
            }
        }

        @media only screen and (max-width: 359px) {
            .team4 .our-team .pic {
                width: 150px;
                height: 150px;
            }

            .team4 .our-team .social {
                top: 20px;
            }
        }
    </style>
        <section class="team4">
        <div class="container">
            <div class="row justify-content-center">

                <div class="container">
                    <div class="row" data-gjs-resizable='{"tl":0,"tc":0,"tr":0,"cl":0,"cr":0,"bl":0,"br":0}'>
                        <div class="col-md-4 col-sm-6 col-lg-4 col-xs-12" data-gjs-resizable='{"tl":0,"tc":0,"tr":0,"cl":0,"bl":0,"br":0,"bc": 0, "keyWidth": "flex-basis", "currentUnit": 1, "minDim": 1, "maxDim": 100, "step": 0.2}'>
                            <div class="our-team">
                                <div class="pic">
                                    <img src="https://s3.us-east-2.amazonaws.com/konnektfunnels-assets/snippets/teams/image1.png" alt="">
                                </div>
                                <ul class="social">
                                    <li><a href="#" class="fa fa-facebook"></a></li>
                                    <li><a href="#" class="fa fa-google-plus"></a></li>
                                    <li><a href="#" class="fa fa-twitter"></a></li>
                                </ul>
                                <div class="team-content">
                                    <h3 class="title">Williamson</h3>
                                    <span class="post">Web Developer</span>
                                </div>
                                <p class="description">
                                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque ac eros lacus. Sed
                                    commodo fringilla pharetra. Proin sodales risus et nunc volutpat.
                                </p>
                            </div>
                        </div>
                        <div class="col-md-4 col-sm-6 col-lg-4 col-xs-12" data-gjs-resizable='{"tl":0,"tc":0,"tr":0,"cl":0,"bl":0,"br":0,"bc": 0, "keyWidth": "flex-basis", "currentUnit": 1, "minDim": 1, "maxDim": 100, "step": 0.2}'>
                            <div class="our-team">
                                <div class="pic">
                                    <img src="https://s3.us-east-2.amazonaws.com/konnektfunnels-assets/snippets/teams/image2.png" alt="">
                                </div>
                                <ul class="social">
                                    <li><a href="#" class="fa fa-facebook"></a></li>
                                    <li><a href="#" class="fa fa-google-plus"></a></li>
                                    <li><a href="#" class="fa fa-twitter"></a></li>
                                </ul>
                                <div class="team-content">
                                    <h3 class="title">Jhon</h3>
                                    <span class="post">Web Designer</span>
                                </div>
                                <p class="description">
                                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque ac eros lacus. Sed
                                    commodo fringilla pharetra. Proin sodales risus et nunc volutpat.
                                </p>
                            </div>
                        </div>
                        <div class="col-md-4 col-sm-6 col-lg-4 col-xs-12" data-gjs-resizable='{"tl":0,"tc":0,"tr":0,"cl":0,"bl":0,"br":0,"bc": 0, "keyWidth": "flex-basis", "currentUnit": 1, "minDim": 1, "maxDim": 100, "step": 0.2}'>
                            <div class="our-team">
                                <div class="pic">
                                    <img src="https://s3.us-east-2.amazonaws.com/konnektfunnels-assets/snippets/teams/image2.png" alt="">
                                </div>
                                <ul class="social">
                                    <li><a href="#" class="fa fa-facebook"></a></li>
                                    <li><a href="#" class="fa fa-google-plus"></a></li>
                                    <li><a href="#" class="fa fa-twitter"></a></li>
                                </ul>
                                <div class="team-content">
                                    <h3 class="title">Vijay</h3>
                                    <span class="post">Web Developer</span>
                                </div>
                                <p class="description">
                                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque ac eros lacus. Sed
                                    commodo fringilla pharetra. Proin sodales risus et nunc volutpat.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>

    </section>
        `
    })
    toAdd("team4") && bm.add("team4", {
        category: "Teams",
        label: "Team4",
        attributes: { style: "background-image: url('https://s3.us-east-2.amazonaws.com/konnektfunnels-assets/snippets/teams/team5.png'); background-repeat: no-repeat;background-position: center center;background-size: cover; " },
        content: `
        <style>
        body {
            padding: 0;
            margin: 0;
            font-family: sans-serif;
        }

        img {
            max-width: 100%;
        }

        .team5 {
            padding: 30px 0px;
        }

        .team5 .our-team {
            text-align: center;
            transition: all 0.5s ease 0s;
        }

        .team5 .our-team:hover {
            box-shadow: 0 15px 10px -10px rgba(0, 0, 0, 0.5), 0 1px 4px rgba(0, 0, 0, 0.3), 0 0 40px rgba(0, 0, 0, 0.1) inset;
        }

        .team5 .our-team .pic {
            overflow: hidden;
            position: relative;
        }

        .team5 .our-team .pic:before,
        .team5 .our-team .pic:after {
            content: "";
            width: 200%;
            height: 80%;
            background: rgba(38, 37, 37, 0.8);
            position: absolute;
            top: -100%;
            left: -4%;
            transform: rotate(45deg);
            transition: all 0.5s ease 0s;
        }

        .team5 .our-team .pic:after {
            background: rgba(8, 75, 77, 0.8);
            top: auto;
            left: auto;
            bottom: -100%;
            right: -4%;
        }

        .team5 .our-team:hover .pic:before {
            top: 0;
        }

        .team5 .our-team:hover .pic:after {
            bottom: 0;
        }

        .team5 .our-team .pic img {
            width: 100%;
            max-height: 230px;
            min-height: 230px;
        }

        .team5 .our-team .social {
            width: 100%;
            padding: 0;
            margin: 0;
            list-style: none;
            position: absolute;
            bottom: 45%;
            left: 0;
            opacity: 0;
            z-index: 2;
            transition: all 0.5s ease 0.3s;
        }

        .team5 .our-team:hover .social {
            opacity: 1;
        }

        .team5 .our-team .social li {
            display: inline-block;
        }

        .team5 .our-team .social li a {
            display: block;
            width: 40px;
            height: 40px;
            line-height: 40px;
            font-size: 20px;
            color: #fff;
            margin-right: 10px;
            position: relative;
            transition: all 0.3s ease 0s;
        }

        .team5 .our-team .social li a:after {
            content: "";
            width: 100%;
            height: 100%;
            background: #db162f;

            position: absolute;
            top: 0;
            left: 0;
            z-index: -1;
            transition: all 0.3s ease 0s;
        }

        .team5 .our-team .team-content {
            padding: 20px;
        }

        .team5 .our-team .title {
            font-size: 22px;
            font-weight: 700;
            letter-spacing: 2px;
            color: #047168;
            text-transform: uppercase;
            margin-bottom: 7px;
        }

        .team5 .our-team .post {
            display: block;
            font-size: 17px;
            font-weight: 600;
            color: #707070;
            text-transform: capitalize;
        }

        @media only screen and (max-width: 990px) {
            .team5 .our-team {
                margin-bottom: 30px;
            }
        }
    </style>
    <section class="team5">
        <div class="container">
            <div class="row justify-content-center">
                <div class="container">
                    <div class="row" data-gjs-resizable='{"tl":0,"tc":0,"tr":0,"cl":0,"cr":0,"bl":0,"br":0}'>
                        <div class="col-md-4 col-sm-6" data-gjs-resizable='{"tl":0,"tc":0,"tr":0,"cl":0,"bl":0,"br":0,"bc": 0, "keyWidth": "flex-basis", "currentUnit": 1, "minDim": 1, "maxDim": 100, "step": 0.2}'>
                            <div class="our-team">
                                <div class="pic">
                                    <img src="https://s3.us-east-2.amazonaws.com/konnektfunnels-assets/snippets/teams/image1.png">
                                    <ul class="social">
                                        <li><a href="#" class="fa fa-facebook"></a></li>
                                        <li><a href="#" class="fa fa-google-plus"></a></li>
                                        <li><a href="#" class="fa fa-instagram"></a></li>
                                        <li><a href="#" class="fa fa-linkedin"></a></li>
                                    </ul>
                                </div>
                                <div class="team-content">
                                    <h3 class="title">Sri</h3>
                                    <span class="post">Web Developer</span>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-4 col-sm-6" data-gjs-resizable='{"tl":0,"tc":0,"tr":0,"cl":0,"bl":0,"br":0,"bc": 0, "keyWidth": "flex-basis", "currentUnit": 1, "minDim": 1, "maxDim": 100, "step": 0.2}'>
                            <div class="our-team">
                                <div class="pic">
                                    <img src="https://s3.us-east-2.amazonaws.com/konnektfunnels-assets/snippets/teams/image2.png">
                                    <ul class="social">
                                        <li><a href="#" class="fa fa-facebook"></a></li>
                                        <li><a href="#" class="fa fa-google-plus"></a></li>
                                        <li><a href="#" class="fa fa-instagram"></a></li>
                                        <li><a href="#" class="fa fa-linkedin"></a></li>
                                    </ul>
                                </div>
                                <div class="team-content">
                                    <h3 class="title">Srivatsan</h3>
                                    <span class="post">Web Designer</span>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-4 col-sm-6" data-gjs-resizable='{"tl":0,"tc":0,"tr":0,"cl":0,"bl":0,"br":0,"bc": 0, "keyWidth": "flex-basis", "currentUnit": 1, "minDim": 1, "maxDim": 100, "step": 0.2}'>
                            <div class="our-team">
                                <div class="pic">
                                    <img src="https://s3.us-east-2.amazonaws.com/konnektfunnels-assets/snippets/teams/image3.png">
                                    <ul class="social">
                                        <li><a href="#" class="fa fa-facebook"></a></li>
                                        <li><a href="#" class="fa fa-google-plus"></a></li>
                                        <li><a href="#" class="fa fa-instagram"></a></li>
                                        <li><a href="#" class="fa fa-linkedin"></a></li>
                                    </ul>
                                </div>
                                <div class="team-content">
                                    <h3 class="title">Arun</h3>
                                    <span class="post">Web Developer</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
        `
    })
    toAdd("team5") && bm.add("team5", {
        category: "Teams",
        label: "Team5",
        attributes: { style: "background-image: url('https://s3.us-east-2.amazonaws.com/konnektfunnels-assets/snippets/teams/team6.png'); background-repeat: no-repeat;background-position: center center;background-size: cover; " },
        content: `
        <style>
        body {
            padding: 0;
            margin: 0;
            font-family: sans-serif;
        }

        img {
            max-width: 100%;
        }

        .team6 {
            background: #16bebb;
            padding: 30px 0px;
        }
        .demo{ background: #262526; }
.team6 .our-team{
    padding: 20px 15px 30px;
    background: #f4f4f4;
    border-radius: 15px;
    text-align: center;
}
.team6 .our-team .pic{
    display: inline-block;
    width: 100%;
    background: #17bebb;
    padding: 10px;
    margin-bottom: 25px;
    transition: all 0.5s ease 0s;
}
.team6 .our-team:hover .pic{
    background: #17bebb;
    border-radius: 50%;
}
.team6 .pic img{
    width: 100%;
    border-radius: 50%;
    max-height: 200px;
    min-height: 200px;
}
.team6 .our-team .title{
    display: block;
    font-size: 20px;
    font-weight: 600;
    color: #2e282a;
    text-transform: uppercase;
    margin: 0 0 7px 0;
}
.team6 .our-team .post{
    display: block;
    font-size: 15px;
    color: #17bebb;
    text-transform: capitalize;
    margin-bottom: 15px;
}
.team6 .our-team .social{
    padding: 0;
    margin: 0;
    list-style: none;
}
.team6 .our-team .social li{
    display: inline-block;
    margin-right: 5px;
}
.team6 .our-team .social li a{
    display: block;
    width: 30px;
    height: 30px;
    line-height: 30px;
    border-radius: 50%;
    font-size: 15px;
    color: #17bebb;
    border: 1px solid #17bebb;
    transition: all 0.5s ease 0s;
}
.team6 .our-team:hover .social li a{
    background: #17bebb;
    color: #fff;
}
@media only screen and (max-width: 990px){
    .team6 .our-team{ margin-bottom: 30px; }
}

    </style>
    <section class="team6">
        <div class="container">
            <div class="row justify-content-center" data-gjs-resizable='{"tl":0,"tc":0,"tr":0,"cl":0,"cr":0,"bl":0,"br":0}'>
                <div class="col-md-3 col-sm-6" data-gjs-resizable='{"tl":0,"tc":0,"tr":0,"cl":0,"bl":0,"br":0,"bc": 0, "keyWidth": "flex-basis", "currentUnit": 1, "minDim": 1, "maxDim": 100, "step": 0.2}'>
                    <div class="our-team">
                        <div class="pic">
                            <img src="https://s3.us-east-2.amazonaws.com/konnektfunnels-assets/snippets/teams/image1.png">
                        </div>
                        <h3 class="title">SRI</h3>
                        <span class="post">Web Developer</span>
                        <ul class="social">
                            <li><a href="#" class="fa fa-facebook"></a></li>
                            <li><a href="#" class="fa fa-twitter"></a></li>
                            <li><a href="#" class="fa fa-google-plus"></a></li>
                            <li><a href="#" class="fa fa-linkedin"></a></li>
                        </ul>
                    </div>
                </div>
                <div class="col-md-3 col-sm-6" data-gjs-resizable='{"tl":0,"tc":0,"tr":0,"cl":0,"bl":0,"br":0,"bc": 0, "keyWidth": "flex-basis", "currentUnit": 1, "minDim": 1, "maxDim": 100, "step": 0.2}'>
                    <div class="our-team">
                        <div class="pic">
                            <img src="https://s3.us-east-2.amazonaws.com/konnektfunnels-assets/snippets/teams/image2.png">
                        </div>
                        <h3 class="title">ARUN</h3>
                        <span class="post">Web Designer</span>
                        <ul class="social">
                            <li><a href="#" class="fa fa-facebook"></a></li>
                            <li><a href="#" class="fa fa-twitter"></a></li>
                            <li><a href="#" class="fa fa-google-plus"></a></li>
                            <li><a href="#" class="fa fa-linkedin"></a></li>
                        </ul>
                    </div>
                </div>
                <div class="col-md-3 col-sm-6" data-gjs-resizable='{"tl":0,"tc":0,"tr":0,"cl":0,"bl":0,"br":0,"bc": 0, "keyWidth": "flex-basis", "currentUnit": 1, "minDim": 1, "maxDim": 100, "step": 0.2}'>
                        <div class="our-team">
                            <div class="pic">
                                <img src="https://s3.us-east-2.amazonaws.com/konnektfunnels-assets/snippets/teams/image3.png">
                            </div>
                            <h3 class="title">DARIO</h3>
                            <span class="post">Web Designer</span>
                            <ul class="social">
                                <li><a href="#" class="fa fa-facebook"></a></li>
                                <li><a href="#" class="fa fa-twitter"></a></li>
                                <li><a href="#" class="fa fa-google-plus"></a></li>
                                <li><a href="#" class="fa fa-linkedin"></a></li>
                            </ul>
                        </div>
                    </div>
            </div>
        </div>
    </section>
        `
    })



    //TESTIMONIALS

    toAdd("testimonial1") && bm.add("testimonial1", {
        category: "Testimonials",
        label: "Testimonial1",
        attributes: { style: "background-image: url('https://s3.us-east-2.amazonaws.com/konnektfunnels-assets/snippets/testimonials/testimonial1.png'); background-repeat: no-repeat;background-position: center center;background-size: cover; " },
        content: `
            <style>
         body {
            padding: 0;
            margin: 0;
            font-family:sans-serif;
        }
        img {
            max-width: 100%;
        }
        .testimonials1{
            padding: 40px 0px;
            background: #f4f4f4;

        }
         .testimonials1 ul li{
             list-style: none;float: left;
         }
        .testimonials1 .testmonial_image img{
            width: 100px;
            object-fit: cover;
            border-radius: 100px;
            height: 100px;
            max-width: 100%;
        }
        .testimonials1 .testmonial_image{
            text-align: center;
        }
        .testimonials1 .testmonial_image,.testimonials1 .testmonial_text{
            float: left;
        }
    </style>
        <section class="testimonials1">
        <div class="container">
            <div class="row" data-gjs-resizable='{"tl":0,"tc":0,"tr":0,"cl":0,"cr":0,"bl":0,"br":0}'>
                <ul class="col-xs-12">
                    <li class="col-lg-6 col-md-6 col-sm-12 col-xs-12" data-gjs-resizable='{"tl":0,"tc":0,"tr":0,"cl":0,"bl":0,"br":0,"bc": 0, "keyWidth": "flex-basis", "currentUnit": 1, "minDim": 1, "maxDim": 100, "step": 0.2}'>
                        <div class="col-xs-12 col-md-3 col-lg-3 col-sm-12 testmonial_image">
                            <img src="https://s3.us-east-2.amazonaws.com/konnektfunnels-assets/snippets/testimonials/image1.png" >
                        </div>
                        <div class="col-xs-12 col-md-9 col-lg-9 col-sm-12 testmonial_text">
                                <h3>Add your own text here</h3>
                                <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Facere consequatur illo molestiae eum beatae suscipit doloribus, incidunt ratione quo maxime earum, impedit corrupti repellat repudiandae sequi cupiditate voluptatum sit animi!</p>
                                <p><b>Jhon Deo</b>- CEO</p>
                        </div>
                    </li>
                    <li class="col-lg-6 col-md-6 col-sm-12 col-xs-12" data-gjs-resizable='{"tl":0,"tc":0,"tr":0,"cl":0,"bl":0,"br":0,"bc": 0, "keyWidth": "flex-basis", "currentUnit": 1, "minDim": 1, "maxDim": 100, "step": 0.2}'>
                            <div class="col-xs-12 col-md-3 col-lg-3 col-sm-12 testmonial_image">
                                <img src="https://s3.us-east-2.amazonaws.com/konnektfunnels-assets/snippets/testimonials/image2.png" >
                            </div>
                            <div class="col-xs-12 col-md-9 col-lg-9 col-sm-12 testmonial_text">
                                    <h3>Add your own text here</h3>
                                    <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Facere consequatur illo molestiae eum beatae suscipit doloribus, incidunt ratione quo maxime earum, impedit corrupti repellat repudiandae sequi cupiditate voluptatum sit animi!</p>
                                    <p><b>Jhon </b>- CFO</p>
                            </div>
                        </li>
                </ul>

            </div>
        </div>
    </section>
        `
    });
    toAdd("testimonial2") && bm.add("testimonial2", {
        category: "Testimonials",
        label: "Testimonial2",
        attributes: { style: "background-image: url('https://s3.us-east-2.amazonaws.com/konnektfunnels-assets/snippets/testimonials/testimonial2.png'); background-repeat: no-repeat;background-position: center center;background-size: cover; " },
        content: `
            <style>
         body {
            padding: 0;
            margin: 0;
            font-family:sans-serif;
        }
        img {
            max-width: 100%;
        }
        .testimonials2 {
            padding: 40px 0px;
            background: #f4f4f4;

        }

        .testimonials2 ul li {
            list-style: none;
            list-style: none;
            float: left;
            background: #fff;
            margin: 10px;
            padding: 20px;
        }

        .testimonials2 .testmonial_image img {
            width: 100px;
            object-fit: cover;
            border-radius: 100px;
            height: 100px;
            max-width: 100%;
        }

        .testimonials2 .testmonial_image {
            text-align: center;
        }

        .testimonials2 .testmonial_image,
        .testimonials2 .testmonial_text {
            float: left;
        }
    </style>
        <section class="testimonials2">
        <div class="container">
            <div class="row" data-gjs-resizable='{"tl":0,"tc":0,"tr":0,"cl":0,"cr":0,"bl":0,"br":0}'>
                <ul class="col-xs-12" data-gjs-resizable='{"tl":0,"tc":0,"tr":0,"cl":0,"bl":0,"br":0,"bc": 0, "keyWidth": "flex-basis", "currentUnit": 1, "minDim": 1, "maxDim": 100, "step": 0.2}'>
                    <li class="col-xs-12" data-gjs-resizable='{"tl":0,"tc":0,"tr":0,"cl":0,"bl":0,"br":0,"bc": 0, "keyWidth": "flex-basis", "currentUnit": 1, "minDim": 1, "maxDim": 100, "step": 0.2}'>
                        <div class="col-xs-12 col-md-3 col-lg-3 col-sm-12 testmonial_image" >
                            <img src="https://s3.us-east-2.amazonaws.com/konnektfunnels-assets/snippets/testimonials/image1.png">
                        </div>
                        <div class="col-xs-12 col-md-9 col-lg-9 col-sm-12 testmonial_text">
                            <h3>Add your own text here</h3>
                            <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Facere consequatur illo
                                molestiae eum beatae suscipit doloribus, incidunt ratione quo maxime earum, impedit
                                corrupti repellat repudiandae sequi cupiditate voluptatum sit animi!</p>
                            <p><b>Jhon Deo</b>- CEO</p>
                        </div>
                    </li>
                    <li class="col-xs-12" data-gjs-resizable='{"tl":0,"tc":0,"tr":0,"cl":0,"bl":0,"br":0,"bc": 0, "keyWidth": "flex-basis", "currentUnit": 1, "minDim": 1, "maxDim": 100, "step": 0.2}'>
                        <div class="col-xs-12 col-md-3 col-lg-3 col-sm-12 testmonial_image">
                            <img src="https://s3.us-east-2.amazonaws.com/konnektfunnels-assets/snippets/testimonials/image2.png">
                        </div>
                        <div class="col-xs-12 col-md-9 col-lg-9 col-sm-12 testmonial_text">
                            <h3>Add your own text here</h3>
                            <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Facere consequatur illo
                                molestiae eum beatae suscipit doloribus, incidunt ratione quo maxime earum, impedit
                                corrupti repellat repudiandae sequi cupiditate voluptatum sit animi!</p>
                            <p><b>Jhon </b>- CFO</p>
                        </div>
                    </li>
                </ul>

            </div>
        </div>
    </section>
        `
    });
    toAdd("testimonial3") && bm.add("testimonial3", {
        category: "Testimonials",
        label: "Testimonial3",
        attributes: { style: "background-image: url('https://s3.us-east-2.amazonaws.com/konnektfunnels-assets/snippets/testimonials/testimonial3.png'); background-repeat: no-repeat;background-position: center center;background-size: cover; " },
        content: `
            <style>
        body {
            padding: 0;
            margin: 0;
            font-family: sans-serif;
        }

        img {
            max-width: 100%;
        }

        .testimonials3 {
            padding: 40px 0px;
            background: url(https://s3.us-east-2.amazonaws.com/konnektfunnels-assets/snippets/testimonials/building.png);
            background-size:cover;
            background-position: 100%;
        }

        .testimonials3 ul li {
            list-style: none;
            list-style: none;
            float: left;
            
        }

        .testimonials3 .testmonial_image img {
            width: 100px;
            object-fit: cover;
            border-radius: 100px;
            height: 100px;
            max-width: 100%;
            float: left;
        }

        .testimonials3 .testmonial_image {
            text-align: center;
        }

        .testimonials3 .testmonial_image,
        .testimonials3 .testmonial_text {
            width: 100%;
            display: inline-block;
            margin-top: 20px;
        }
        .testimonials3 .testmonial_block{
            background: #dfd3d2;
            padding: 20px;
            margin: 20px 0px;
        }
    </style>
        <section class="testimonials3">
        <div class="container">
            <div class="row" data-gjs-resizable='{"tl":0,"tc":0,"tr":0,"cl":0,"cr":0,"bl":0,"br":0}'>
                <ul class="col-xs-12">
                    <li class="col-xs-12 col-sm-12 col-md-4 col-lg-4" data-gjs-resizable='{"tl":0,"tc":0,"tr":0,"cl":0,"bl":0,"br":0,"bc": 0, "keyWidth": "flex-basis", "currentUnit": 1, "minDim": 1, "maxDim": 100, "step": 0.2}'>
                        <div class="testmonial_block">


                            <div class="col-xs-12 testmonial_image">
                                <img src="https://s3.us-east-2.amazonaws.com/konnektfunnels-assets/snippets/testimonials/image1.png">
                                <p><b>Jhon Deo</b>- CEO</p>
                                <p><small>"Lorem ipsum dolor sit amet consectetur adipisicing elit."</small></p>
                            </div>
                            <div class="col-xs-12 testmonial_text">
                                <h3>Add your own text here</h3>
                                <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Facere consequatur illo
                                    molestiae eum beatae suscipit doloribus, incidunt ratione quo maxime earum, impedit
                                    corrupti repellat repudiandae sequi cupiditate voluptatum sit animi!</p>

                            </div>
                        </div>
                    </li>
                    <li class="col-xs-12 col-sm-12 col-md-4 col-lg-4" data-gjs-resizable='{"tl":0,"tc":0,"tr":0,"cl":0,"bl":0,"br":0,"bc": 0, "keyWidth": "flex-basis", "currentUnit": 1, "minDim": 1, "maxDim": 100, "step": 0.2}'>
                        <div class="testmonial_block">


                            <div class="col-xs-12 testmonial_image">
                                <img src="https://s3.us-east-2.amazonaws.com/konnektfunnels-assets/snippets/testimonials/image2.png">
                                <p><b>Jhon</b>- CFO</p>
                                <p><small>"Lorem ipsum dolor sit amet consectetur adipisicing elit."</small></p>
                            </div>
                            <div class="col-xs-12 testmonial_text">
                                <h3>Add your own text here</h3>
                                <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Facere consequatur illo
                                    molestiae eum beatae suscipit doloribus, incidunt ratione quo maxime earum, impedit
                                    corrupti repellat repudiandae sequi cupiditate voluptatum sit animi!</p>

                            </div>
                        </div>
                    </li>
                    <li class="col-xs-12 col-sm-12 col-md-4 col-lg-4" data-gjs-resizable='{"tl":0,"tc":0,"tr":0,"cl":0,"bl":0,"br":0,"bc": 0, "keyWidth": "flex-basis", "currentUnit": 1, "minDim": 1, "maxDim": 100, "step": 0.2}'>
                        <div class="testmonial_block">


                            <div class="col-xs-12 testmonial_image">
                                <img src="https://s3.us-east-2.amazonaws.com/konnektfunnels-assets/snippets/testimonials/image3.png">
                                <p><b>Jhon</b>- CFO</p>
                                <p><small>"Lorem ipsum dolor sit amet consectetur adipisicing elit."</small></p>
                            </div>
                            <div class="col-xs-12 testmonial_text">
                                <h3>Add your own text here</h3>
                                <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Facere consequatur illo
                                    molestiae eum beatae suscipit doloribus, incidunt ratione quo maxime earum, impedit
                                    corrupti repellat repudiandae sequi cupiditate voluptatum sit animi!</p>

                            </div>
                        </div>
                    </li>
                </ul>

            </div>
        </div>
    </section>
        `
    });
    toAdd("testimonial4") && bm.add("testimonial4", {
        category: "Testimonials",
        label: "Testimonial4",
        attributes: { style: "background-image: url('https://s3.us-east-2.amazonaws.com/konnektfunnels-assets/snippets/testimonials/testimonial4.png'); background-repeat: no-repeat;background-position: center center;background-size: cover; " },
        content: `
            <style>
        body {
            padding: 0;
            margin: 0;
            font-family: sans-serif;
        }

        img {
            max-width: 100%;
        }
        .testimonial4{
            padding: 50px 0px;
        }
        .testimonial4 .testmonia_list {
            position: relative;
            overflow: hidden;
            margin: 10px;
            min-width: 220px;
            max-width: 310px;
            width: 100%;
            color: #333;
            text-align: left;
            box-shadow: none !important;
        }

        .testimonial4 .testmonia_list * {
            -webkit-box-sizing: border-box;
            box-sizing: border-box;
        }

        .testimonial4 .testmonia_list img {
            max-width: 100%;
            height: 100px;
            width: 100px;
            border-radius: 50%;
            margin-bottom: 15px;
            display: inline-block;
            z-index: 1;
            position: relative;
        }

        .testimonial4 .testmonia_list blockquote {
            margin: 0;
            display: block;
            border-radius: 8px;
            position: relative;
            background-color: #fafafa;
            padding: 30px 50px 65px 50px;
            font-size: 0.8em;
            font-weight: 500;
            margin: 0 0 -50px;
            line-height: 1.6em;
            box-shadow: 0 0 5px rgba(0, 0, 0, 0.15);
        }

        .testimonial4 .testmonia_list blockquote:before,
        .testimonial4 .testmonia_list blockquote:after {
            font-family: 'FontAwesome';
            content: "\\201C";
            position: absolute;
            font-size: 50px;
            opacity: 0.3;
            font-style: normal;
        }

        .testimonial4 .testmonia_list blockquote:before {
            top: 35px;
            left: 20px;
        }

        .testimonial4 .testmonia_list blockquote:after {
            content: "\\201D";
            right: 20px;
            bottom: 35px;
        }

        .testimonial4 .testmonia_list .author {
            margin: 0;
            text-transform: uppercase;
            text-align: center;
            color: #ffffff;
        }

        .testimonial4 .testmonia_list .author h5 {
            opacity: 0.8;
            margin: 0;
            font-weight: 800;
        }

        .testimonial4 .testmonia_list .author h5 span {
            font-weight: 400;
            text-transform: none;
            display: block;
        }


    </style>
        <section class="testimonial4">
        <div class="container">
            <div class="row justify-content-center" data-gjs-resizable='{"tl":0,"tc":0,"tr":0,"cl":0,"cr":0,"bl":0,"br":0}'>
                    <div class="col-xs-12 col-md-4 col-lg-4 col-sm-12 testmonia_list" data-gjs-resizable='{"tl":0,"tc":0,"tr":0,"cl":0,"bl":0,"br":0,"bc": 0, "keyWidth": "flex-basis", "currentUnit": 1, "minDim": 1, "maxDim": 100, "step": 0.2}'>
                            <blockquote>Lorem ipsum dolor sit amet consectetur adipisicing elit. Nesciunt ducimus laboriosam accusantium natus eum accusamus laborum magni veritatis est necessitatibus similique adipisci incidunt, voluptas enim eos, reprehenderit perferendis, recusandae dolorum?</blockquote>
                            <div class="author">
                                <img src="https://s3.us-east-2.amazonaws.com/konnektfunnels-assets/snippets/testimonials/sq-sample1.jpg" alt="sq-sample1" />
                                <h5>Pelican Steve <span> LittleSnippets</span></h5>
                            </div>
                        </div>
                        <div class="col-xs-12 col-md-4 col-lg-4 col-sm-12 testmonia_list" data-gjs-resizable='{"tl":0,"tc":0,"tr":0,"cl":0,"bl":0,"br":0,"bc": 0, "keyWidth": "flex-basis", "currentUnit": 1, "minDim": 1, "maxDim": 100, "step": 0.2}'>
                            <blockquote>Lorem ipsum dolor, sit amet consectetur adipisicing elit. Quia voluptate quos dolore iusto quisquam eligendi, necessitatibus voluptas voluptates tempora accusantium illum, repudiandae voluptatum recusandae. Eligendi, quam. Ducimus dolorum corporis consequuntur.</blockquote>
                            <div class="author">
                                <img src="https://s3.us-east-2.amazonaws.com/konnektfunnels-assets/snippets/testimonials/sq-sample24.jpg" alt="sq-sample24" />
                                <h5>Max Conversion<span> LittleSnippets</span></h5>
                            </div>
                        </div>
                        <div class="col-xs-12 col-md-4 col-lg-4 col-sm-12 testmonia_list" data-gjs-resizable='{"tl":0,"tc":0,"tr":0,"cl":0,"bl":0,"br":0,"bc": 0, "keyWidth": "flex-basis", "currentUnit": 1, "minDim": 1, "maxDim": 100, "step": 0.2}'>
                            <blockquote>Lorem ipsum dolor sit amet consectetur adipisicing elit. Nesciunt reiciendis cumque optio fuga exercitationem consequatur blanditiis perferendis iste voluptatem. Recusandae temporibus veritatis repellat, commodi repellendus adipisci aut doloribus cupiditate quisquam!</blockquote>
                            <div class="author">
                                <img src="https://s3.us-east-2.amazonaws.com/konnektfunnels-assets/snippets/testimonials/sq-sample29.jpg" alt="sq-sample29" />
                                <h5>Eleanor Faint<span> LittleSnippets</span></h5>
                            </div>
                        </div>
            </div>
        </div>
        
    </section>
        `
    });
    toAdd("testimonial5") && bm.add("testimonial5", {
        category: "Testimonials",
        label: "Testimonial5",
        attributes: { style: "background-image: url('https://s3.us-east-2.amazonaws.com/konnektfunnels-assets/snippets/testimonials/testimonial5.png'); background-repeat: no-repeat;background-position: center center;background-size: cover; " },
        content: `
            <style>
        body {
            padding: 0;
            margin: 0;
            font-family: sans-serif;
        }

        img {
            max-width: 100%;
        }

        .testimonial5 {
            padding: 50px 0px;
        }

        .testimonial5 .testmonial_sec {
           
            position: relative;
            margin: 10px;

            width: 100%;
            color: #333;
            text-align: left;
            box-shadow: 0 0 5px rgba(0, 0, 0, 0.15);
        }

        .testimonial5 .testmonial_sec * {
            -webkit-box-sizing: border-box;
            box-sizing: border-box;
        }

        .testimonial5 .testmonial_sec img {
            float: right;
            max-width: 40%;
            vertical-align: middle;
            border-radius: 0 8px 8px 0;
        }

        .testimonial5 .testmonial_sec .testmonial_text {
            top: 0;
            bottom: 0;
            left: 0;
            width: 60%;
            float: left;
            background-color: #ffffff;
            border-radius: 8px 0 0 8px;
        }

        .testimonial5 .testmonial_sec blockquote {
            position: relative;
            padding: 25px 50px 25px 50px;
            font-size: 0.8em;
            font-weight: 500;
            text-align: left;
            margin: 0;
            line-height: 1.6em;
            font-style: italic;
        }

        .testimonial5 .testmonial_sec blockquote:before,
        .testimonial5 .testmonial_sec blockquote:after {
            font-family: 'FontAwesome';
            content: "\\201C";
            position: absolute;
            font-size: 50px;
            opacity: 0.3;
            font-style: normal;
        }

        .testimonial5 .testmonial_sec blockquote:before {
            top: 25px;
            left: 20px;
        }

        .testimonial5 .testmonial_sec blockquote:after {
            content: "\\201D";
            right: 20px;
            bottom: 0px;
        }

        .testimonial5 .testmonial_sec .arrow {
            top: 30px;
            left: 100%;
            width: 0;
            height: 0;
            border-left: 0 solid transparent;
            border-right: 25px solid transparent;
            border-top: 25px solid #ffffff;
            margin: 0;
            position: absolute;
        }

        .testimonial5 .testmonial_sec .author {
            position: absolute;
            top: 100%;
            width: 60%;
            padding: 10px 15px;
            color: #ffffff;
            margin: 0;
            text-transform: uppercase;
        }

        .testimonial5 .testmonial_sec .author h5 {
            opacity: 0.8;
            margin: 0;
            font-weight: 800;
        }

        .testimonial5 .testmonial_sec .author h5 span {
            font-weight: 400;
            text-transform: none;
            padding-left: 5px;
        }

        .testimonial5 .testmonial_sec {
            display: flex;
            justify-content: center;
            align-items: center;
        }

        @media(max-width:990px) {
            .testimonial5 .testmonial_sec {
                display: block !important;
                justify-content: center;
                align-items: center;
            }

            .testimonial5 .testmonial_sec img {
                max-width: 100%;
            }

            .testimonial5 .testmonial_sec .testmonial_text {
                width: 100%;
            }
        }
    </style>
       <section class="testimonial5">
        <div class="container">
            <div class="row justify-content-center" data-gjs-resizable='{"tl":0,"tc":0,"tr":0,"cl":0,"cr":0,"bl":0,"br":0}'>
                <div class="testmonial_sec col-xs-12 col-sm-12 col-md-6 col-lg-4" data-gjs-resizable='{"tl":0,"tc":0,"tr":0,"cl":0,"bl":0,"br":0,"bc": 0, "keyWidth": "flex-basis", "currentUnit": 1, "minDim": 1, "maxDim": 100, "step": 0.2}'>
                    <div class="testmonial_text">
                        <blockquote>Lorem ipsum dolor sit amet consectetur adipisicing elit. Molestias harum et
                            officiis dolores sapiente hic sunt voluptatum accusamus </blockquote>
                    </div>
                    <img src="https://s3.us-east-2.amazonaws.com/konnektfunnels-assets/snippets/testimonials/sq-sample10.jpg" alt="sq-sample10" />
                    <div class="author">
                        <h5>Jhon <span>- CEO</span></h5>
                    </div>
                </div>
                <div class="testmonial_sec col-xs-12 col-sm-12 col-md-6 col-lg-4 " data-gjs-resizable='{"tl":0,"tc":0,"tr":0,"cl":0,"bl":0,"br":0,"bc": 0, "keyWidth": "flex-basis", "currentUnit": 1, "minDim": 1, "maxDim": 100, "step": 0.2}'>
                    <div class="testmonial_text">
                        <blockquote>Lorem ipsum dolor sit amet consectetur adipisicing elit. Molestias harum et
                            officiis dolores sapiente hic sunt voluptatum accusamus </blockquote>
                    </div>
                    <img src="https://s3.us-east-2.amazonaws.com/konnektfunnels-assets/snippets/testimonials/sq-sample33.jpg" alt="sq-sample33" />
                    <div class="author">
                        <h5>Jhon Doe<span>- CFo</span></h5>
                    </div>
                </div>
            </div>
        </div>

    </section>
        `
    });
    toAdd("testimonial6") && bm.add("testimonial6", {
        category: "Testimonials",
        label: "Testimonial6",
        attributes: { style: "background-image: url('https://s3.us-east-2.amazonaws.com/konnektfunnels-assets/snippets/testimonials/testimonial6.png'); background-repeat: no-repeat;background-position: center center;background-size: cover; " },
        content: `
            <style>
        body {
            padding: 0;
            margin: 0;
            font-family: sans-serif;
        }

        img {
            max-width: 100%;
        }

        .testimonial6_sec {
            position: relative;
            overflow: hidden;
            margin: 10px;
            min-width: 220px;
            max-width: 310px;
            width: 100%;
            color: #333;
            text-align: left;
            box-shadow: none !important;
        }

        .testimonial6_sec * {
            -webkit-box-sizing: border-box;
            box-sizing: border-box;
            -webkit-transition: all 0.35s cubic-bezier(0.25, 0.5, 0.5, 0.9);
            transition: all 0.35s cubic-bezier(0.25, 0.5, 0.5, 0.9);
        }

        .testimonial6_sec img {
            max-width: 100%;
            vertical-align: middle;
            height: 90px;
            width: 90px;
            border-radius: 50%;
            margin: 40px 0 0 10px;
        }

        .testimonial6_sec blockquote {
            display: block;
            border-radius: 8px;
            position: relative;
            background-color: #fafafa;
            padding: 25px 50px 30px 50px;
            font-size: 0.8em;
            font-weight: 500;
            margin: 0;
            line-height: 1.6em;
            font-style: italic;
        }

        .testimonial6_sec blockquote:before,
        .testimonial6_sec blockquote:after {
            font-family: 'FontAwesome';
            content: "\\201C";
            position: absolute;
            font-size: 50px;
            opacity: 0.3;
            font-style: normal;
        }

        .testimonial6_sec blockquote:before {
            top: 25px;
            left: 20px;
        }

        .testimonial6_sec blockquote:after {
            content: "\\201D";
            right: 20px;
            bottom: 0;
        }

        .testimonial6_sec .arrow {
            top: 100%;
            width: 0;
            height: 0;
            border-left: 0 solid transparent;
            border-right: 25px solid transparent;
            border-top: 25px solid #fafafa;
            margin: 0;
            position: absolute;
        }

        .testimonial6_sec .author {
            position: absolute;
            bottom: 45px;
            padding: 0 10px 0 120px;
            margin: 0;
            text-transform: uppercase;
            color: #ffffff;
            -webkit-transform: translateY(50%);
            transform: translateY(50%);
        }

        .testimonial6_sec .author h5 {
            opacity: 0.8;
            margin: 0;
            font-weight: 800;
        }

        .testimonial6_sec .author h5 span {
            font-weight: 400;
            text-transform: none;
            padding-left: 5px;
        }

        .testimonial6 {
            background-color: #212121;
            display: flex;
            justify-content: center;
            align-items: center;
            flex-flow: wrap;
            margin: 0;
            padding: 50px 0px;

        }
    </style>
        <section class="testimonial6">
        <div class="container">
            <div class="row justify-content-center" data-gjs-resizable='{"tl":0,"tc":0,"tr":0,"cl":0,"cr":0,"bl":0,"br":0}'>
                <div class="testimonial6_sec col-xs-12" data-gjs-resizable='{"tl":0,"tc":0,"tr":0,"cl":0,"bl":0,"br":0,"bc": 0, "keyWidth": "flex-basis", "currentUnit": 1, "minDim": 1, "maxDim": 100, "step": 0.2}'>
                    <blockquote>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Placeat, excepturi expedita
                        cumque consectetur necessitatibus harum cum architecto blanditiis ipsam, temporibus eum illo
                        recusandae? Cupiditate veritatis at eos similique sint quo.
                        <div class="arrow"></div>
                    </blockquote>
                    <img src="https://s3.us-east-2.amazonaws.com/konnektfunnels-assets/snippets/testimonials/sq-sample3.jpg" alt="sq-sample3" />
                    <div class="author">
                        <h5>John <span>- CEO</span></h5>
                    </div>
                </div>
                <div class="testimonial6_sec hover col-xs-12" data-gjs-resizable='{"tl":0,"tc":0,"tr":0,"cl":0,"bl":0,"br":0,"bc": 0, "keyWidth": "flex-basis", "currentUnit": 1, "minDim": 1, "maxDim": 100, "step": 0.2}'>
                    <blockquote>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Placeat, excepturi expedita
                        cumque consectetur necessitatibus harum cum architecto blanditiis ipsam, temporibus eum illo
                        recusandae? Cupiditate veritatis at eos similique sint quo.
                        <div class="arrow"></div>
                    </blockquote>
                    <img src="https://s3.us-east-2.amazonaws.com/konnektfunnels-assets/snippets/testimonials/sq-sample27.jpg" alt="sq-sample27" />
                    <div class="author">
                        <h5>John<span> - CFO</span></h5>
                    </div>
                </div>
                <div class="testimonial6_sec col-xs-12" data-gjs-resizable='{"tl":0,"tc":0,"tr":0,"cl":0,"bl":0,"br":0,"bc": 0, "keyWidth": "flex-basis", "currentUnit": 1, "minDim": 1, "maxDim": 100, "step": 0.2}'>
                    <blockquote>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Placeat, excepturi expedita
                        cumque consectetur necessitatibus harum cum architecto blanditiis ipsam, temporibus eum illo
                        recusandae? Cupiditate veritatis at eos similique sint quo.
                        <div class="arrow"></div>
                    </blockquote>
                    <img src="https://s3.us-east-2.amazonaws.com/konnektfunnels-assets/snippets/testimonials/sq-sample17.jpg" alt="sq-sample17" />
                    <div class="author">
                        <h5>John<span> - CFO</span></h5>
                    </div>
                </div>
            </div>
        </div>

    </section>
        `
    });
    toAdd("testimonial7") && bm.add("testimonial7", {
        category: "Testimonials",
        label: "Testimonial7",
        attributes: { style: "background-image: url('https://s3.us-east-2.amazonaws.com/konnektfunnels-assets/snippets/testimonials/testimonial7.png'); background-repeat: no-repeat;background-position: center center;background-size: cover; " },
        content: `
            <style>
        body {
            padding: 0;
            margin: 0;
            font-family: sans-serif;
        }

        img {
            max-width: 100%;
        }

        .testimonia7 .testmonial7_sec {
            font-family: 'Roboto', Arial, sans-serif;
            position: relative;
            overflow: hidden;
            margin: 10px;
            min-width: 230px;
            max-width: 315px;
            width: 100%;
            color: #000000;
            text-align: center;
            font-size: 16px;
            background-color: #2C3447;
            padding: 30px;
            background-image: linear-gradient(-25deg, rgba(0, 0, 0, 0.2) 0%, rgba(255, 255, 255, 0.1) 100%);
            -webkit-box-sizing: border-box;
            box-sizing: border-box;
        }

        .testimonia7 .testmonial7_sec *,
        .testimonia7 .testmonial7_sec *:before,
        .testimonia7 .testmonial7_sec *:after {
            -webkit-box-sizing: border-box;
            box-sizing: border-box;
            -webkit-transition: all 0.35s ease;
            transition: all 0.35s ease;
        }

        .testimonia7 .testmonial7_sec div {
            width: 100%;
        }

        .testimonia7 .testmonial7_sec h2,
        .testimonia7 .testmonial7_sec h4,
        .testimonia7 .testmonial7_sec blockquote {
            margin: 0;
        }

        .testimonia7 .testmonial7_sec h2,
        .testimonia7 .testmonial7_sec h4 {
            font-weight: 300;
        }

        .testimonia7 .testmonial7_sec h2 {
            color: #ffffff;
        }

        .testimonia7 .testmonial7_sec h4 {
            color: #a6a6a6;
        }

        .testimonia7 .testmonial7_sec blockquote {
            font-size: 1em;
            padding: 45px 20px 40px 50px;
            margin-top: 30px;
            background-color: #ffffff;
            border-radius: 5px;
            box-shadow: inset -1.4px -1.4px 2px rgba(0, 0, 0, 0.3);
            text-align: left;
            position: relative;
        }

        .testimonia7 .testmonial7_sec .profile {
            width: 100px;
            border-radius: 50%;
            display: inline-block;
            box-shadow: 3px 3px 20px rgba(0, 0, 0, 0.4);
            margin-bottom: 10px;
            border: solid 5px #A6A57A;
        }
    </style>
        <section class="testimonia7">
        <div class="container">
            <div class="row justify-content-center" data-gjs-resizable='{"tl":0,"tc":0,"tr":0,"cl":0,"cr":0,"bl":0,"br":0}'>
                <div class="testmonial7_sec col-xs-12" data-gjs-resizable='{"tl":0,"tc":0,"tr":0,"cl":0,"bl":0,"br":0,"bc": 0, "keyWidth": "flex-basis", "currentUnit": 1, "minDim": 1, "maxDim": 100, "step": 0.2}'>
                    <img src="https://s3.us-east-2.amazonaws.com/konnektfunnels-assets/snippets/testimonials/profile-sample3.jpg" alt="profile-sample3" class="profile" />
                    <div>
                        <h2>JHON</h2>
                        <h4>CEO</h4>
                        <blockquote>Lorem ipsum dolor sit amet consectetur adipisicing elit. Architecto porro dolor molestiae. Quas aspernatur veniam architecto unde quod </blockquote>
                    </div>
                </div>
                <div class="testmonial7_sec col-xs-12" data-gjs-resizable='{"tl":0,"tc":0,"tr":0,"cl":0,"bl":0,"br":0,"bc": 0, "keyWidth": "flex-basis", "currentUnit": 1, "minDim": 1, "maxDim": 100, "step": 0.2}'><img src="https://s3.us-east-2.amazonaws.com/konnektfunnels-assets/snippets/testimonials/profile-sample5.jpg" alt="profile-sample5" class="profile" />
                    <div>
                        <h2>JHON DOE</h2>
                        <h4>CFO</h4>
                        <blockquote>Lorem ipsum dolor sit amet consectetur adipisicing elit. Architecto porro dolor molestiae. Quas aspernatur veniam architecto unde quod </blockquote>
                    </div>
                </div>
                <div class="testmonial7_sec col-xs-12" data-gjs-resizable='{"tl":0,"tc":0,"tr":0,"cl":0,"bl":0,"br":0,"bc": 0, "keyWidth": "flex-basis", "currentUnit": 1, "minDim": 1, "maxDim": 100, "step": 0.2}'><img src="https://s3.us-east-2.amazonaws.com/konnektfunnels-assets/snippets/testimonials/profile-sample6.jpg" alt="profile-sample6" class="profile" />
                    <div>
                        <h2>JHON</h2>
                        <h4>CFO</h4>
                        <blockquote>Lorem ipsum dolor sit amet consectetur adipisicing elit. Architecto porro dolor molestiae. Quas aspernatur veniam architecto unde quod </blockquote>
                    </div>
                </div>
            </div>
        </div>

    </section>
</head>
        `
    });

    // Products category

    // To load DOM elements dynamically
    function loadDom(input) {
        switch (input) {
            case 'radio':
                    return Array.from(Array(3), ((value, index) => {
                        return `
                            <div class="form-check" data-gjs-type="product-elements">   
                                <input class="form-check-input" data-gjs-type="product-radio" type="radio" name="product" value="">
                                <label class="form-check-label" data-gjs-type="label">
                                    Product ${index + 1}
                                </label>
                            </div>
                        `;
                    })).join("");

            case 'checkbox':
                    return Array.from(Array(3), ((value, index) => {
                        return `
                            <div class="form-check" data-gjs-type="product-elements">
                                <input name="product" class="form-check-input" data-gjs-type="product-checkbox" type="checkbox" value="">
                                <label class="form-check-label" data-gjs-type="label" for="inlineCheckbox${index + 1}">Product ${index + 1}</label>
                            </div>
                    `;                
                })).join("");     
                           
            default: 
                break;
        }
    }
    
    // Radio button
    toAdd("productRadioButtons") && bm.add("productRadioButtons", {
        category: "Products",
        label: "Product Radio Buttons",
        attributes: { style: `background-image: url(${require('../../../img/icons/radio-button-group.svg')}); background-repeat: no-repeat;background-position: bottom;background-size: 50px 50px;` },
        content: `
        ${loadDom("radio")}
        `
    });
    
    // Checkbox
    toAdd("productCheckbox") && bm.add("productCheckbox", {
        category: "Products",
        label: "Product Checkbox",
        attributes: { style: `background-image: url(${require('../../../img/icons/checkbox-pen-outline.svg')}); background-repeat: no-repeat;background-position: bottom;background-size: 50px 50px;` },
        content: `
        ${loadDom("checkbox")}        
        `
    });

    toAdd("cart") && bm.add("cart", {
        category: "Products",
        label: "Cart",
        attributes: { style: `background-image: url(${require('../../../img/icons/shopping-cart.svg')}); background-repeat: no-repeat;background-position: bottom;background-size: 50px 50px;` },
        content: `
            <div>
            
            </div>
        `
    });
}
