import joint from "jointjs";
import constants from "../../utilities/constants";

const toolBarStartX = 15;
const toolBarMenuConfig = [
    {
        "name": "settings",
        "label": "Settings",
        "icon": "settings",
        "eventName": "element:settings"
    },
    {
        "name": "edit",
        "label": "Edit",
        "icon": "edit",
        "eventName": "element:edit"
    },
    {
        "name": "trash",
        "label": "Delete",
        "icon": "trash",
        "eventName": "element:delete"
    },
    // {
    //     "name": "split",
    //     "label": constants.split,
    //     "icon": "split",
    //     "eventName": "element:split"
    // },
    {
        "name": "plus",
        "label": "Duplicate this element",
        "icon": "plus",
        "eventName": "element:copy"
    },
    {
        "name": "preview",
        "label": "Preview",
        "icon": "preview",
        "eventName": "element:preview"
    },
];
const toolBarMenu = toolBarMenuConfig.map((menu = {}, index) => {
    const xPos = (30 * index) - toolBarStartX;
    return (
        `<text id="${menu.name}" class="icons ${menu.icon}" x="${xPos}" y="200">
            ${constants[menu.name]}
            <title>${menu.label}</title>
        </text>`
    );
}).join("");

const toolBarMenuAttrs = {};
toolBarMenuConfig.forEach(menu => {
    toolBarMenuAttrs[`.${menu.name}`] = {
        event: menu.eventName,
    };
});

joint.shapes.devs.MyImageModel = joint.shapes.devs.Model.extend({
    markup: `<g class="rotatable">
                <g class="scalable">
                    <rect class="body"/>
                </g>
                <image/>
                <rect class="borderBox"/>
                <text class="label"/>
                <rect x="-25" y="165" class="iconbar" width="170px" height="50px" />
                ${toolBarMenu}
            </g>`,
    // portMarkup: "<g><path class=\"port-body\"/><text class=\"port-label\"/></g>",
    portMarkup: "<g><rect class=\"port-body\"></rect><text x=\"-10\" y=\"-100\" class=\"linkicon\">" + `${constants.link}` + "<title>Move</title></text></g>",
    defaults: joint.util.deepSupplement({
        type: "devs.MyImageModel",
        attrs: {
            ...toolBarMenuAttrs
        },
        outPorts: [""]
    }, joint.shapes.devs.Model.prototype.defaults),
    setImage: function (image) {
        const attrs = this.get("attrs");
        const imageAttrs = {
            ...attrs.image,
            ...image,
        };
        this.set({
            attrs: {
                ...attrs,
                image: imageAttrs,
            }
        });
    },
    setProperty: function (property, value) {
        this.set(property, value);
    },
    setAttributes: function (newAttributes) {
        this.attributes = {
            ...newAttributes
        };
    }
});