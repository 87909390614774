import { FunnelTypes as FunnelTypesView } from "./funnel-types";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import * as funnelTypesActions from "./actions";
//import { getFunnels } from "./selectors";

function mapStateToProps(state) {
    return {
        funnelTypes: state.FUNNEL_TYPES.funnelTypes,
    };
}
function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators(funnelTypesActions, dispatch),
        loadFunnelTypes: () => dispatch(funnelTypesActions.loadFunnelTypes()),
        //deleteFunnel: (funnelId) => dispatch(funnelActions.deleteAction(funnelId))
    };
}
export const FunnelTypes = connect(mapStateToProps, mapDispatchToProps)(FunnelTypesView);