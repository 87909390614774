import { connect } from "react-redux";
import { getUserData } from "../../login/selectors";
/**
 * UserData Component injects user data through props
 *
 * Usage:
 *   <UserDataInjector>
 *     {userData => <span> {userData.firstName}  <span/>
 *   </UserDataInjector>
 *
 * Values Passed:
 *  active;
 *  fCrm;
 *  fCrmId;
 *  fTenant = obj;
 *  fTenantId;
 *  fUserId;
 *  userCrmId;
 *  userId;
 *  userSetting = obj;
 *  isFirstLogin = bool;
 *  affiliateId;
 *  isDesignerUser = bool;
 */
const UserDataInjector = props => props.children(props.userData);

export default connect(state => ({ userData: getUserData(state) }))(UserDataInjector);
