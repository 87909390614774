import * as type from "../actions/type";
import { Visualizer } from "../../models";
export const VISUALIZER_REDUCER_KEY = "VISUALIZER";

const initialState = new Visualizer();

export function reducer(state = initialState, action) {
    switch (action.type) {
        case type.INITIALIZE_VISUALIZER_DATA: {
            return {
                ...state,
                ...action.visulaizer
            };
        }
        case type.UPDATE_VISUALIZER_DATA: {
            return {
                ...state,
                ...action.payload
            };
        }
        case type.REMOVE_CALLBACK_EVENT: {
            return {
                ...state,
                ...action.payload
            };
        }
        case type.IMPORT_FUNNEL_SUCCESS_MESSAGE: {
            return {
                ...state,
                showModalWindow: action.payload.showModalWindow,
                modalToShow: action.payload.modalToShow
            };
        }
        case type.IMPORT_FUNNEL_TEMPLATE_SUCCESS_MESSAGE: {
            return {
                ...state,
                showImportFunnelTemplateSuccessMessage: action.payload.showImportFunnelTemplateSuccessMessage
            };
        }
        default:
            return state;
    }
}