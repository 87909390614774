const handleBorderRadiusChange = (editor, component) => {
    const sm = editor.SelectorManager;
    const borderRadius = sm.add("borderRadius");
    let rule = editor.CssComposer.add([borderRadius]);
    rule.set("style", {
        "border-radius": component.changed.borderRadius
    });
    editor.getSelected().addClass("borderRadius");
};

export default handleBorderRadiusChange;