import * as api from "../api/funnelTemplateApi";
import { APIHelper, LocalStorageHelper } from "../utilities";

const FUNNEL_TEMPLATES = "funnelTemplates/";
export const IS_LOADING = `${FUNNEL_TEMPLATES}IS_LOADING`;
export const LOAD_FUNNEL_TEMPLATES_SUCCESS = `${FUNNEL_TEMPLATES}LOAD_FUNNEL_TEMPLATES_SUCCESS`;
export const LOAD_ACTIVE_FUNNEL_TEMPLATES_SUCCESS = `${FUNNEL_TEMPLATES}LOAD_ACTIVE_FUNNEL_TEMPLATES_SUCCESS`;
export const CREATE_FUNNEL_TEMPLATE_SUCCESS = `${FUNNEL_TEMPLATES}CREATE_FUNNEL_TEMPLATE_SUCCESS`;
export const CREATE_FUNNEL_TEMPLATE_ERROR = `${FUNNEL_TEMPLATES}CREATE_FUNNEL_TEMPLATE_ERROR`;
export const EDIT_FUNNEL_TEMPLATE_SUCCESS = `${FUNNEL_TEMPLATES}EDIT_FUNNEL_TEMPLATE_SUCCESS`;
export const EDIT_FUNNEL_TEMPLATE_ERROR = `${FUNNEL_TEMPLATES}EDIT_FUNNEL_TEMPLATE_ERROR`;
export const UPDATE_FUNNEL_TEMPLATE_SUCCESS = `${FUNNEL_TEMPLATES}UPDATE_FUNNEL_TEMPLATE_SUCCESS`;
export const LOAD_PAGE_SUCCESS = `${FUNNEL_TEMPLATES}LOAD_PAGE_SUCCESS`;
export const UPDATE_FUNNEL_TEMPLATE_ERROR = `${FUNNEL_TEMPLATES}UPDATE_FUNNEL_TEMPLATE_ERROR`;
export function loadFunnelTemplatesSuccess(funnelTemplates) {
    return { type: LOAD_FUNNEL_TEMPLATES_SUCCESS, funnels: funnelTemplates };
}


export function createFunnelTemplateSuccess(funnelTemplate) {
    return { type: CREATE_FUNNEL_TEMPLATE_SUCCESS, funnel: funnelTemplate };
}
export function createFunnelTemplateError(isError = false) {
    return { type: CREATE_FUNNEL_TEMPLATE_ERROR, isError };
}
export function editFunnelTemplateSuccess(funnelTemplate) {
    return { type: EDIT_FUNNEL_TEMPLATE_SUCCESS, funnel: funnelTemplate };
}
export function editFunnelTemplateError(isError = false) {
    return { type: EDIT_FUNNEL_TEMPLATE_ERROR, isError };
}
export function updateFunnelTemplateSuccess(funnelTemplate) {
    return { type: UPDATE_FUNNEL_TEMPLATE_SUCCESS, funnel: funnelTemplate };
}

export function toggleLoading(status = false) {
    return { type: IS_LOADING, status };
}

export function deleteAction(funnelTemplateId) {
    return function (dispatch) {
        dispatch(toggleLoading(true));
        return api.deleteFunnelTemplate(funnelTemplateId, { headers: APIHelper.getGenericHeaders() });
    };
}

export function loadFunnelTemplates() {
    return function (dispatch) {
        const localstorageManager = new LocalStorageHelper();
        const accessToken = localstorageManager.load(LocalStorageHelper.keys.ACCESS_TOKEN);
        if (accessToken) {
            //dispatch(toggleLoading(true));
            return api.getAllFunnelTemplates({ headers: APIHelper.getGenericHeaders() }).then((response) => {
                dispatch(loadFunnelTemplatesSuccess([...response.result]));
                dispatch(toggleLoading(false));
            }).catch(error => {
                // TODO - Funnel Get should return empty array in case of 404
                dispatch(loadFunnelTemplatesSuccess([]));
                dispatch(toggleLoading(false));
                throw (error);
            });
        }
    };
}

export function loadFunnelTemplateItem(funnelReferenceId) {
    return api.retrieveFunnelTemplate(funnelReferenceId, { headers: APIHelper.getGenericHeaders() })
        .then(({ result }) => result)
        .catch(() => {});
}


export function createFunnelTemplate(newFunnelTemplate) {
    return function () {
        const localstorageManager = new LocalStorageHelper();
        const accessToken = localstorageManager.load(LocalStorageHelper.keys.ACCESS_TOKEN);
        if (accessToken) {
            return api.createFunnelTemplate({ headers: APIHelper.getGenericHeaders(), requestBody: newFunnelTemplate });
        }
    };
}

export function updateFunnelTemplate(params) {
    return function () {
        return api.updateFunnelTemplateDetails(params, { headers: APIHelper.getGenericHeaders(), requestBody: params.funnel });
    };
}


export function updateFunnelError(isError = false) {
    return { type: UPDATE_FUNNEL_TEMPLATE_ERROR, isError };
}

export const changeDefaultPageStatusApi = (params) => {
    return api.changeDefaultPageStatusApi(params, { headers: APIHelper.getGenericHeaders() });
};




