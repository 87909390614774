import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import isEmpty from "lodash/isEmpty";
import get from "lodash/get";
import isArray from "lodash/isArray";
import { BaseWebpageCreator } from "./base-webpage-creator";
import FullPageLoader from "../commons/full-page-loader";
import {
    triggerNofiticationError,
} from "../commons/notification";
import { funnelCommands } from "./commands";

import { ProductType } from "../constants/funnel-constants";

import { initButtonMetaData } from "./button-metadata";
import { ButtonTrait } from "./traits/button-trait";
import { buttonChange } from "./utils/component";
import { getSavedFonts } from "../visualizer/selectors/funnel-template-selectors";
import {
    getFunnelWebBuilderElements
} from "./actions";
import {
    getFunnel,
} from "../funnels/actions";

import { PageTypes } from "../constants/template-types";
import * as visualizerActions from "../funnels/create-funnel-visualizer/actions";
import * as campaignActions from "../campaign/action";
import { getTemplates } from "../templates/actions";
import { getFunnelTemplateJsonData } from "../visualizer/actions/funnel-template-actions";
import * as saltSelectors from "../login/selectors";
import {
    getFontsApi
} from "../funnels/visualizer/actions/visualizerActions";

import { getDefaults } from "./defaults";

const localStorageId = "gjs-funnel-";

class FunnelPageBuilder extends React.Component {
    constructor() {
        super();
        this.state = {
            pageData: null,
            isLoading: false,
            renderWebBuilder: false,
            templateData: null,
            metadata: {
                funnelReferenceId: null,
                funnelData: {},
            }
        };
        this.onEditorLoad = this.onEditorLoad.bind(this);
    }
    async componentDidMount() {
        const {
            templates,
            campaigns,
            loadTemplates,
            loadCampaigns,
            salt,
        } = this.props;
        const {
            funnelId: funnelReferenceId,
            pageId: pageReferenceId,
        } = this.props.match.params;
        if (funnelReferenceId) {
            const funnelData = await getFunnel(funnelReferenceId);
            const currentPage = funnelData.page.find((p) => p.referenceId === pageReferenceId);
            const currentPageView = get(currentPage, "pageView[0]");
            const currentPageTemplateId = get(currentPageView, "fTemplateId");
            if (currentPageView) {
                let newState = {};
                const fontParams = { funnelReferenceId, pageReferenceId };
                const params = {
                    isEdited: currentPage.isEdited,
                    componentJsonUrl: currentPageView.componentJson,
                    stylesJsonUrl: currentPageView.stylesJson,
                    htmlUrl: currentPageView.url,
                    metaJsonUrl: currentPageView.metaJson || "",
                };
                try {
                    await Promise.all([
                        !isEmpty(templates) ? Promise.resolve(templates) : loadTemplates(),
                        !isEmpty(campaigns) ? Promise.resolve(campaigns) : loadCampaigns(),
                        getFunnelTemplateJsonData(params, true),
                        getFontsApi(fontParams),
                        getFunnelWebBuilderElements({
                            funnelReferenceId,
                            pageViewReferenceId: currentPageView.referenceId,
                        }),
                        PageTypes.getAllPageTypes(),
                    ])
                        .then(([templates, campaigns, pageData, { result: fonts } , { result: buttonAndLinks }]) => {
                            const selectedTemplate = templates.find((template) => template.templateId === currentPageTemplateId);
                            if (pageData) {
                                const {
                                    templateTypeId,
                                    templateId,
                                } = selectedTemplate;
                                if (pageData.setcomponentData) {
                                    pageData = {
                                        ...pageData,
                                        setcomponentData: JSON.parse(pageData.setcomponentData),
                                        setstylesData: JSON.parse(pageData.setstylesData),
                                    };
                                }
                                const selectedCampaign = campaigns.find((campaign) => campaign.campaignId === +funnelData.campaign);
                                /*
                                 * [Todo - Check if the backend can send productType as 0 or 1]
                                 */
                                const webBuilderElements = buttonAndLinks.map((item) => {
                                    if (isArray(item.products)) {
                                        item.products = item.products.map(product => {
                                            const productTypeName = product.productType;
                                            product.productType = ProductType[productTypeName];
                                            return product;
                                        });
                                    }
                                    return item;
                                });
                                newState = {
                                    pageData,
                                    isLoading: false,
                                    templateTypeId,
                                    templateId,
                                    renderWebBuilder: true,
                                    templateData: selectedTemplate,
                                    selectedCampaign,
                                    webBuilderElements,
                                    fonts,
                                    metadata: {
                                        salt,
                                        funnelReferenceId,
                                        currentPage,
                                        funnelData,
                                    },
                                    pageViewReferenceId: currentPageView.referenceId,
                                };
                                // eslint-disable-next-line react/no-did-mount-set-state
                                this.setState(newState);
                            // eslint-disable-next-line no-empty
                            } else {
                            }
                        })
                        .catch(err => {
                            triggerNofiticationError({ message: err.message });
                        });
                } catch (err) {
                    triggerNofiticationError({ message: err.message });
                }
            }
        }
    }

    onEditorLoad(editor) {
        const {
            pageData,
            metadata,
            webBuilderElements,
        } = this.state;
        const {
            funnelData,
        } = metadata;
        const container = editor.container;
        initButtonMetaData(editor, webBuilderElements);

        const bt = new ButtonTrait(editor, "FKT_BUTTON", funnelData.page.map(p => ({ text: `${p.title} - ${p.id}`, value: p })), {
            onChangeCallback: (e, bid) => buttonChange(container, e, bid, "NEXT_PAGE")
        });
        const offerTrait = new ButtonTrait(editor, "FKT_OFFERS", funnelData.offer ? JSON.parse(funnelData.offer) : "", {
            onChangeCallback: (e, bid) => buttonChange(container, e, bid, "OFFER")
        });
        const upsellTrait = new ButtonTrait(editor, "FKT_UPSELL", funnelData.upsale ? JSON.parse(funnelData.upsale) : "", {
            onChangeCallback: (e, bid) => buttonChange(container, e, bid, "UPSELL")
        });
        editor.TraitManager.addType(bt.traitName, bt.traitTypeAttribute);
        editor.TraitManager.addType(offerTrait.traitName, offerTrait.traitTypeAttribute);
        editor.TraitManager.addType(upsellTrait.traitName, upsellTrait.traitTypeAttribute);
    }

    render() {
        const {
            history,
            savePageView,
        } = this.props;
        const {
            pageData,
            isLoading,
            templateTypeId,
            renderWebBuilder,
            templateData,
            selectedCampaign,
            metadata,
            webBuilderElements,
            fonts
        } = this.state;
        const {
            funnelReferenceId,
            funnelData,
        } = metadata;
        const offers = funnelData.offer ? JSON.parse(funnelData.offer) : "";
        const upsell = funnelData.upsale ? JSON.parse(funnelData.upsale) : "";
        const defaults = {
            localStorageId,
            templateTypeId,
            history,
            commands: funnelCommands,
        };
        const additionalComponentData = {
            offers,
            upsell,
            pages: funnelData.page,
            templateTypeId,
        };
        return (
            <>
                <FullPageLoader showLoading={!renderWebBuilder} />
                {
                    renderWebBuilder && (
                        <BaseWebpageCreator
                            pageData={pageData}
                            { ...getDefaults(defaults) }
                            fonts={fonts}
                            onEditorLoad={this.onEditorLoad}
                            isLoading={isLoading}
                            previousUrl={`/editfunnel/${funnelReferenceId}`}
                            templateData={templateData}
                            savePageView={savePageView}
                            metadata={metadata}
                            selectedCampaign={selectedCampaign}
                            webBuilderElements={webBuilderElements}
                            additionalComponentData={additionalComponentData}
                        />
                    )
                }
            </>
        );
    }
}

FunnelPageBuilder.propTypes = {
    onEditorLoad: PropTypes.func,
    history: PropTypes.object,
    currentPage: PropTypes.object,
    funnelReferenceId: PropTypes.string,
    savePageView: PropTypes.func,
    fonts: PropTypes.array,
    funnelData: PropTypes.object,
    salt: PropTypes.string,
    selectedTemplateDataFromStore: PropTypes.object,
    funnelTemplate: PropTypes.object,
    loadCampaigns: PropTypes.func,
    loadFunnel: PropTypes.func,
    campaigns: PropTypes.array,
    match: PropTypes.object,
    metadata: PropTypes.object,
    templates: PropTypes.array,
    loadTemplates: PropTypes.func,
};

const mapStateToProps = (state) => {
    const settingsData = saltSelectors.getSettingsData(state);
    const salt = settingsData ? settingsData.salt : "";
    return {
        campaigns: state.CAMPAIGNS,
        salt,
        templates: state.TEMPLATES.templates,
    };
};

const mapDispatchToProps = (dispatch) => ({
    savePageView: (params) => dispatch(visualizerActions.savePageView(params)),
    loadCampaigns: () => dispatch(campaignActions.loadCampaigns()),
    loadFunnel: (pageId) => dispatch(visualizerActions.loadFunnel(pageId)),
    loadTemplates: (templateType) => dispatch(getTemplates({ templateType })),
});

export const FunnelPageWebBuilder = connect(mapStateToProps, mapDispatchToProps)(FunnelPageBuilder);