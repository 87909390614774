/*
    Storage helper for grapejs
    Note:: This should not be used to store values for localstorage
*/
export class StorageHelper {
    static keys = {
        FUNNEL_FORM_DATA: "funnelFormData"
    }

    constructor(storageManager) {
        this.storageManager = storageManager;
    }

    store(key, data) {
        return new Promise((resolve, reject) => {
            try {
                this.storageManager.store({
                    key: data
                });
                resolve(data);
            } catch (error) {
                console.error("Storage Manager :: ", error);
                reject(error);
            }
        });
    }

    load(keys) {
        return new Promise((resolve, reject) => {
            try {
                this.storageManager.load([...keys], (res) => {
                    resolve(res);
                });
            } catch (error) {
                console.error("Storage Manager :: ", error);
                reject(error);
            }
        });
    }
}