import React from "react";
import PropTypes from "prop-types";
import moment from "moment";
import "./funnels-list.css";
import { funnelListConstants } from "../constants";
import { maximumFunnels,funnelLimitEnabled } from "../../api/urls";

export const FunnelList = ({ funnels, funnelDetails, isLoading, deleteRow, createFunnel }) => {
    const { chooseFunnel, deleteFunnel, DEV, defaultIcon, addFunnel, maximumReached } = funnelListConstants;
    funnels = funnels.sort((a, b) => Date.parse(b.dateCreated) - Date.parse(a.dateCreated));
    const envMode = (process.env.NODE_ENV === DEV) || false;
    return (
        <div className="row wrapper border-bottom page-heading">
            {!isLoading &&
                <div className="centerList">
                    <a className="funnelThumbnail centerItem" onClick={funnelLimitEnabled && funnels.length >= maximumFunnels ? null : () => createFunnel()}><i className="fa fa-plus-circle" aria-hidden="true"></i><div>{funnelLimitEnabled && funnels.length >= maximumFunnels ? maximumReached : addFunnel}</div></a>
                    {(funnels).map(funnel => {
                        const { referenceId, title, dateCreated, isVisualizer, userName } = funnel;
                        const thumbnailUrl = funnel.thumbnailUrl ? funnel.thumbnailUrl + "?" + new Date().getTime() : "";
                        return (
                            <div className="funnelThumbnail">
                                <div className="thumbnail-template-img" style={{ backgroundImage: `url(${!isVisualizer ? defaultIcon : thumbnailUrl})` }}>
                                    <a className="choose" onClick={() => funnelDetails(referenceId)}><div className="choose-hand"></div><div>{chooseFunnel}</div></a>
                                    {envMode && <a className="delete" onClick={() => deleteRow(referenceId)}><div>{deleteFunnel}</div></a>}
                                </div>
                                <div className="thumbnail-content" onClick={() => funnelDetails(referenceId)}>
                                    <abbr title={title} className="abbrtitle"><div className="thumbnailTitle" >{title}</div></abbr>
                                    <div>{moment(dateCreated).format("MM-DD-YYYY")}</div>
                                    <div>{userName ? userName : "John Doe"}</div>
                                </div>
                            </div>
                        );
                    }
                    )}
                </div>
            }
        </div>
    );
};

FunnelList.propTypes = {
    funnels: PropTypes.array.isRequired,
    deleteRow: PropTypes.func,
    funnelDetails: PropTypes.func,
    createFunnel: PropTypes.func,
    isLoading: PropTypes.bool,
    search: PropTypes.string
};