import joint from "jointjs";
import constants from "../utilities/constants";
import _ from "lodash";
import $ from "jquery";
const TextElement = (textChange, removeText, saveGraph) => {
    joint.shapes.html = {};
    joint.shapes.html.Element = joint.shapes.basic.Rect.extend({
        defaults: joint.util.deepSupplement({
            type: "html.Element",
            attrs: {
                rect: { fill: "#ffffff00", stroke: "none", "fill-opacity": 0 }
            }
        }, joint.shapes.basic.Rect.prototype.defaults)
    });
    joint.shapes.html.ElementView = joint.dia.ElementView.extend(
        {
            template: [
                "<div class=\"html-element\">",
                "<textarea class=\"text-view\" maxlength=\"120\" type=\"text\" value=\"\" />",
                "</div>"
            ].join(""),
            initialize: function () {
                _.bindAll(this, "updateBox");
                joint.dia.ElementView.prototype.initialize.apply(this, arguments);
                this.$box = $(_.template(this.template)());
                this.$box.find("textarea").bind("input propertychange", _.bind(function (evt) {
                    textChange($(evt.target).val());
                    saveGraph();
                }, this));
            },
            render: function () {
                joint.dia.ElementView.prototype.render.apply(this, arguments);
                this.paper.$el.prepend(this.$box);
                this.updateBox();
                return this;
            },
            updateBox: function () {
                // Set the position and dimension of the box so that it covers the JointJS element.
                var bbox = this.model.getBBox();
                // Example of updating the HTML with a data stored in the cell model.
                this.$box.css({
                    width: bbox.width,
                    height: bbox.height,
                    left: bbox.x,
                    top: bbox.y,
                    transform: "rotate(" + (this.model.get("angle") || 0) + "deg)"
                });
            }
        });
    return (
        joint.shapes.html
    );
};
export default TextElement;
