import React, { Component } from "react";
import PropTypes from "prop-types";
import {
    Dropdown,
    MenuItem,
} from "react-bootstrap";

export class TemplateTypeSelectDropdown extends Component {
    constructor(props) {
        super(props);
        this.getTemplateFromId = this.getTemplateFromId.bind(this);
        this.onDropdownChange = this.onDropdownChange.bind(this);
        this.setTemplateFromId = this.setTemplateFromId.bind(this);
        this.state = {
            selectedTemplateType: this.getTemplateFromId(props.initialTemplateTypeId),
        };
        props.onDropdownChange(this.state.selectedTemplateType);
    }
    getTemplateFromId(templateTypeId) {
        const {
            templateTypeMap
        } = this.props;
        let matchedTemplate = null;
        if (templateTypeId) {
            matchedTemplate = templateTypeMap.find((templateType) => templateType.templateTypeId === templateTypeId);
        }
        if (!matchedTemplate) {
            matchedTemplate = templateTypeMap[0];
        }
        return matchedTemplate;
    }

    setTemplateFromId(templateTypeId) {
        this.setState({
            selectedTemplateType: this.getTemplateFromId(templateTypeId),
        });
    }

    onDropdownChange(eventKey) {
        this.setState({
            selectedTemplateType: eventKey,
        });
        this.props.onDropdownChange(eventKey);
    }

    render() {
        const {
            label,
            className,
            templateTypeMap
        } = this.props;
        const {
            selectedTemplateType,
        } = this.state;
        return (
            <div className={`m-b-md ${className}`}>
                {
                    label && (
                        <label className="control-label m-r-sm"> {label} </label>
                    )
                }
                <Dropdown id="template-dropdown">
                    <Dropdown.Toggle>
                        {selectedTemplateType.label}
                    </Dropdown.Toggle>
                    <Dropdown.Menu className="template-dropdown-menu" onSelect={this.onDropdownChange}>
                        {
                            templateTypeMap.map((templateType) => (
                                <MenuItem key={templateType.label} eventKey={templateType}> {templateType.label} </MenuItem>
                            ))
                        }
                    </Dropdown.Menu>
                </Dropdown>
            </div>
        );
    }
}

TemplateTypeSelectDropdown.propTypes = {
    onDropdownChange: PropTypes.func,
    label: PropTypes.string,
    initialTemplateTypeId: PropTypes.number,
    className: PropTypes.string,
    templateTypeMap: PropTypes.array,
};