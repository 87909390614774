export const popUpBody = (editor, { addOnTraits = [] }) => {
    const domc = editor.DomComponents;
    var dType = domc.getType("default");
    var dModel = dType.model;
    var dView = dType.view;
    domc.addType("popup-body", {
        model: dModel.extend({
            defaults: Object.assign({}, dModel.prototype.defaults, {
                traits: [{
                    type: "input",
                    label: "Delay (Min)",
                    changeProp: 1,
                    name: "minutesDelay",
                },
                {
                    type: "input",
                    label: "Delay (Sec)",
                    changeProp: 1,
                    name: "secondsDelay",
                },
                {
                    type: "select",
                    label: "Trigger On",
                    name: "trigger_on",
                    changeProp: 1,
                    options: [
                        { value: "none", name: "None" },
                        { value: "trigger_on_load", name: "Trigger on Load" },
                        { value: "trigger_on_scroll", name: "Trigger on Scroll" },
                        { value: "trigger_on_exit", name: "Trigger on Exit" }
                    ]
                },
                {
                    type: "select",
                    label: "popup Width",
                    name: "modal_width",
                    changeProp: 1,
                    options: [{ value: "small", name: "Small" }, { value: "medium", name: "Medium" }, { value: "large", name: "Large" }]
                },
                ...addOnTraits],
                unstylable: ["width", "height", "margin-bottom", "margin-left", "margin-right"],
                modal_width: "medium"
            }),
        }, {
            isComponent: function (el) {
                //eslint-disable-next-line
                if (el.tagName == "div") {
                    return { type: "popup-body" };
                }
            },
        }),

        view: dView.extend({
            events: {
                "click": "handleClick"
            },
            init() {
                this.listenTo(this.model, "change:content", this.updateContent);
            },
            updateContent() {
                this.el.innerHTML = this.model.get("content");
            },
            handleClick(e) {
                e.preventDefault();
            },
        }),
        onValueChange: function () {
            this.target.set("content", this.model.get("value"));
        }
    });
};