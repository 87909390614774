import { APIHelper } from "./api-helper";
import { StorageHelper } from "./storage-helper";
import { AssetManagerHelper } from "./asset-manager-helper";
import { LocalStorageHelper } from "./local-storage-helper";

const localStorageUtilities = new LocalStorageHelper();

export {
    APIHelper,
    StorageHelper,
    AssetManagerHelper,
    LocalStorageHelper, //[TODO] Exporting this class has to be removed.Avoid creating instance everywhere
    localStorageUtilities,
};