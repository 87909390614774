import joint from "jointjs";
import "./ImageModel";

const Elements = ({
    imageSvg,
    posX = 24,
    posY,
    label,
    templateTypeId,
    isIcon = false,
    isFolder,
    children,
}) => {
    // Usage:
    return new joint.shapes.devs.MyImageModel({
        size: {
            width: 60,
            height: 70,
        },
        attrs: {
            image: {
                width: isIcon ? 60 : 60,
                height: isIcon ? 60 : 70,
                "xlink:href": "data:image/svg+xml;utf8," + encodeURIComponent(imageSvg),
                // "xlink:href": "https://s3.amazonaws.com/templates.funnelkonnekt.com/d05fc401-4108-414d-8fcd-330a620bb5d5/thumbnail_new.png",
                preserveAspectRatio: "none"
            },
            ".body": {
                stroke: "#ffffff"
            },
            ".label": {
                text: label,
                fontSize: "16",
                y: -30,
                x: 0
            },
            ".borderBox": {
                stroke: "red",
                rx: 6,
            }
        },
        position: {
            x: posX,
            y: posY
        },
        elementMetaData: {
            templateTypeId
        },
        pageLabel: {
            label
        },
        isDefaultPage: false,
        isSplitEnabled: false,
        isEdited: false,
        isTemplateSelected: false,
        premium: false,
        pageReferenceId: "",
        pageViewReferenceId: "",
        thumbnailUrl: null,
        isFolder,
        children,
        ports: {
            groups: {
                "out": {
                    attrs: {
                        ".port-body": { fill: "#ffffff", width: 120, height: 160, stroke: "#ffffff00", "stroke-width": 1, x: -120, y: -80, magnet: true },
                        ".linkicon": { x: -10, y: -100 },
                    }
                },
                ".icons": {
                    fill: "#1ab394",
                    magnet: true

                }
            }
        },
        outPorts: [""],
        icon: !!isIcon,
    });
};
export default Elements;