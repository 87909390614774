export const DASHBOARD_REDUCER_KEY = "DASHBOARD";

const intialState = {
    funnels: [],
    sales: {},
    pageViews: [],
    ads: []
};

export function reducer(state = intialState, action) {
    switch (action.type) {
        case "LOAD_FUNNELS_SUCCESS":
            return {
                ...state,
                funnels: action.funnels
            };
        case "LOAD_SALESSUMMARY_SUCCESS":
            return {
                ...state,
                sales: action.sales
            };
        case "LOAD_PAGEVIEWS_SUCCESS" :
            return {
                ...state,
                pageViews: action.pageViews
            };
        case "LOAD_ADS_SUCCESS" :
            return {
                ...state,
                ads: action.ads
            };
        default: return state;
    }
}