/*eslint-disable*/
import React from "react";
import PropTypes from "prop-types";
import $ from "jquery";
import "chart.js";
import { Line, Doughnut } from "react-chartjs-2";
import { Modal, Button } from "react-bootstrap";
import "./style.css";
import { Link, Redirect } from "react-router-dom";
import { LocalStorageHelper } from "../utilities";
import "../Semantic-UI-CSS-master/semantic.min.css";
import "../Semantic-UI-CSS-master/semantic.min";
import { maximumFunnels, maximumPagesPerTenant, funnelLimitEnabled } from "../api/urls";

import WalkthroughPeekaboo from "../commons/modals/walkthrough/peekaboo";
import UserDataInjector from "../core/user/user-data";

import "../styles/scss/pages/dashboard.scss";

export class Dashboard extends React.Component {
    constructor(props, context) {
        super(props, context);
        this.state = {
            sale: Object.assign({}, props.sales),
            pageViews: Object.assign({}, props.sales),
            settingsData: Object.assign({}, props.settingsData),
            userData: Object.assign({}, props.userData),
            show: false,
            funnels: Object.assign({}, props.funnels),
            funnelPageCount: Object.assign({}, props.funnelPageCount),
            ads: Object.assign({}, props.ads),
        };
        const localstorageManager = new LocalStorageHelper();
        const accessToken = localstorageManager.load(LocalStorageHelper.keys.ACCESS_TOKEN);
        this.redirectToFunnelType = this.redirectToFunnelType.bind(this);
        this.handleClose = this.handleClose.bind(this);
        this.handleShow = this.handleShow.bind(this);
        this.createFunnelButton = React.createRef();
        if (accessToken) {
            // [Fix-Todo:: Migration-Latest-React] - All Errors should be handled
            this.props.getSalesSummary().catch(err => {});
            this.props.getPageViews().catch(err => {});
            this.props.loadCampaigns().catch(err => {});
            this.props.loadFunnels().catch(err => {});
            // [Fix-Todo:: Migration-Latest-React] - Check why it is called twice
            // this.props.getAccountInfo();
            this.props.loadPageCounts().catch(err => {});
            this.props.getAds().catch(err => {});
            this.props.loadCompany().catch(err => {});
        }
    }
    componentDidMount() {

    }
    componentWillReceiveProps(nextProps) {
        if (this.props.sales !== nextProps.sales) {
            this.setState({ sales: Object.assign({}, nextProps.sales) });
        }
        if (this.props.ads !== nextProps.ads) {
            this.setState({ ads: Object.assign({}, nextProps.ads) });
        }
        if(this.props.funnels !== nextProps.funnel) {
            this.setState({funnels: Object.assign({}, nextProps.funnels)});
        }
        if(this.props.settingsData !== nextProps.settingsData)
            this.setState({ settingsData: Object.assign({}, nextProps.settingsData), userData: Object.assign({}, nextProps.userData)});
        if(this.props.funnelPageCount !== nextProps.funnelPageCount)
            this.setState({ funnelPageCount: Object.assign({}, nextProps.funnelPageCount) });
        this.handleShow(nextProps.funnelPageCount);
        this.forceUpdate();
    }
    getRandomColor() {
        let letters = "0123456789ABCDEF";
        let color = "#";
        for (let i = 0; i < 6; i++) {
            color += letters[Math.floor(Math.random() * 16)];
        }
        return color;
    }
    redirectToFunnelType(params = {}) {
        const {
            history,
        } = this.props;
        history.push({
            pathname: "/funnel",
            state: params,
        });
    }
    handleClose() {
        this.setState({ show: false })
    }
    handleShow(funnelPageCount) {
        if (funnelPageCount && funnelPageCount.funnelCount === 0 ) {
            setTimeout(() => {
                this.setState({ show: true })
            }, 1000)
        }
    }
    // shouldComponentUpdate() {
    //     return true;
    // }
    render() {
        const localstorageManager = new LocalStorageHelper();
        const accessToken = localstorageManager.load("access_token");
        const { sales, pageViews, settingsData, userData, funnels, funnelPageCount, ads, showWalkthroughPeekaboo, onWalkthroughPeekabooSeenByUser } = this.props;
        const upsells = sales.upsells;
        const labels = [];
        const data = [];
        
        if (upsells) {
            upsells.forEach(upsell => {
                labels.push(upsell.productName);
                data.push(upsell.upsellCnt);
            });
        } else {
            labels.push("null");
            data.push("0");
        }
        let colors = [];
        data.forEach(value => {
            colors.push(this.getRandomColor());
        });
        const doughnutData = {
            labels: labels,
            datasets: [{
                data: data ? data : [0,0,0,0,0],
                backgroundColor: colors
            }]
        };
        const transactions = sales.transactions;
        const months = [];
        const amount = [];
        let transactionsLen = null;
        if (transactions) {
            transactionsLen = transactions.length - 1;
            transactions.forEach(transaction => {
                months.push(transaction.Months);
                amount.push(transaction.total);
            });
        }
        const lineData = {
            labels: months,
            datasets: [
                {
                    label: "Revenue",
                    backgroundColor: "#1ab394",
                    borderColor: "#1ab394",
                    pointBackgroundColor: "#1ab394",
                    pointBorderColor: "#fff",
                    data: amount
                }
            ]
        };
        const isFunnelsLimitExceeded = funnelLimitEnabled && funnels && funnels.length > maximumFunnels;
        return (
            accessToken ?
                <div className="row dashboard-wrapper" style={{padding: "2%"}}>
                    <div id='dash-leftSide' className="col-md-2 animated slideInLeft">
                        <div className='ui fluid profile card'>
                            <div className='content'>
                                <div className="center-block" style={{ padding: "2%" }}>
                                    <Link className="image" to="/settings">
                                        <img style={{ width: "100%", height: "50%" }} className="img-responsive" alt=""
                                             src={ settingsData && settingsData.profilePicture ? settingsData.profilePicture + `?${Date.now()}` : null}/>
                                    </Link>
                                </div>
                                <a className='header text-center' href="">{settingsData && settingsData.firstName ? settingsData.firstName : "John"} { settingsData && settingsData.lastName ? settingsData.lastName : "Doe"}</a>
                                <div className="text-center">
                                    <span className='rank'>{settingsData ? `${settingsData.fBelt.name} belt` : null}</span>
                                </div>
                                {userData.affiliateId &&
                                <div className='affiliate-id text-center'>                                    
                                    <span>Affiliate ID: </span>
                                    <span>{userData.affiliateId}</span>
                                </div>
                                }
                                <div className='stats' style={{marginTop: "10%"}}>
                                    <button
                                        className={`btn center-block ${isFunnelsLimitExceeded ? 'btn-default' : 'btn-primary btn-outline'}`}
                                        //[TODO - FUN2-474] onClick={ funnels && funnels.length < maximumFunnels && this.redirectToFunnelType }
                                        onClick={this.props.openWalkthroughModal}
                                        disabled={isFunnelsLimitExceeded}
                                        ref={this.createFunnelButton}

                                    >
                                        Create A Funnel
                                    </button>
                                    {funnelLimitEnabled && funnels && funnels.length >= maximumFunnels &&
                                        <h5 className="text-center text-danger">User reached maximum funnels limit</h5>
                                    }
                                    <div className={`ui tiny green progress active ${funnelLimitEnabled ? 'limitEnabled ' : 'limitDisabled'}`}
                                        data-ratio-html='<span>78</span> OF <b>200</b>' data-total={maximumPagesPerTenant}
                                        data-value='78'>
                                        <span data-inverted='true' data-position='top left'
                                            data-tooltip='The total number of pages (and variations) associated with a funnel in your account.'>
                                            PAGES
                                    </span>
                                        <span className="progressData" style={funnelLimitEnabled ? (funnelPageCount && funnelPageCount.pageCount > maximumPagesPerTenant ? { color: "red !important" } : { fontSize: "120%"}):{fontSize: "120%"}}>{funnelPageCount ? funnelPageCount.pageCount : null} { funnelLimitEnabled && " OF "}
                                {funnelLimitEnabled && <b>{maximumPagesPerTenant}</b>}</span>
                                        {funnelLimitEnabled && <div className='bar' style={{ backgroundColor: funnelPageCount && funnelPageCount.pageCount >= maximumPagesPerTenant ? "red" : null, display: funnelPageCount && funnelPageCount.pageCount === 0 ? "none" : null, transitionDuration: "300ms", width: funnelPageCount ? 100 - ((maximumPagesPerTenant - funnelPageCount.pageCount) / maximumPagesPerTenant * 100) + "%" : null }}>
                                            <div className='progress' style={{ color: "black", backgroundColor: "transparent" }}>
                                            </div>
                                        </div>}
                                    </div>
                                    <div className={`ui tiny green progress active ${funnelLimitEnabled ? 'limitEnabled ' : 'limitDisabled'}`}
                                        data-ratio-html='<span>5</span> OF <b>20</b>' data-total={maximumFunnels} data-value='25'>
                                        <span data-inverted='true' data-position='top left' data-tooltip='The total number of funnels in your account.'>
                                            FUNNELS
                                    </span>
                                        <span className="progressData" data-inverted='true' data-position='top right'
                                        style={funnelLimitEnabled ? (funnelPageCount && funnelPageCount.funnelCount > maximumFunnels ? { color: "red !important" } : { fontSize: "120%"}):{fontSize: "120%",marginLeft:"25%"}}>
                                            {funnelPageCount ? funnelPageCount.funnelCount : null} { funnelLimitEnabled && " OF "}
                                        {funnelLimitEnabled && <b>{maximumFunnels}</b>}
                                        </span>
                                        {funnelLimitEnabled &&
                                        <div className='bar' style={{ transitionDuration: "300ms", backgroundColor: funnelPageCount && funnelPageCount.funnelCount >= maximumFunnels ? "red" : null, display: funnelPageCount && funnelPageCount.funnelCount === 0 ? "none" : null, width: funnelPageCount ? 100 - ((maximumFunnels - funnelPageCount.funnelCount) / maximumFunnels * 100) + "%" : null }}>
                                            <div className='progress' style={{ color: "black", backgroundColor: "transparent" }}>
                                            </div>
                                        </div>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className='ui styled fluid dashboard accordion'>
                            <div className='title active'>
                                <i className='dropdown icon'/>
                                MY BELTS
                            </div>
                            <div className='content active'>

                                <div className='ui no-margin game badge cards'>
                                        <div className='content center aligned image'>
                                            <div className="row">
                                                <div className="col-md-6">
                                                    <a className='toggle-mast-button toggle-game-button' data-position='top left'
                                                       data-tooltip={`${settingsData ? settingsData.fBelt.name : null} belt`} href='#'>
                                                        <img className="img-responsive" style={{width: "100%"}} src={settingsData ? settingsData.fBelt.url : null} alt=""/>
                                                    </a>
                                                </div>
                                            </div>
                                        </div>
                                </div>
                            </div>
                            <div className='title active'>
                                <i className='dropdown icon'/>
                                TRAINING
                            </div>
                            <div className='affiliate content active ui grid'>
                                <div className='row border bottom'>
                                    <div style={{paddingLeft: "10%", width: "100%"}} className='sixteen wide column header '>
                                        <a href="https://wiki.konnektive.com/_accounts/34895246244967629479970231333145994813185366877484448973689699392909399598966228283648868472737743263489524624496762947997023133314599481318536687748444897368969939290939959896622828364886847273774326/" target="_blank" className="btn btn-primary center-block">Documentation</a>
                                    </div>
                                    <div style={{paddingLeft: "10%", paddingTop: "10%", width: "100%"}}>
                                        <h3>Create A Campaign</h3>
                                        <video width="320" height="240" controls>
                                            <source src={require("../img/Funnel Konnekt - Google Chrome 11_14_2018 3_41_10 PM.mp4")} type="video/mp4"/>
                                                    Your browser does not support the video tag.
                                        </video>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className=" wrapper-content col-md-8" style={{ paddingTop: 0 }}>
                        <div className="row">
                            <div className="col-lg-4">
                                <div className="ibox float-e-margins">
                                    <div className="ibox-title">
                                        <span className="label label-success pull-right">Monthly</span>
                                        <h5>Visits</h5>
                                    </div>
                                    <div className="ibox-content" style={{ height: "100px"}}>
                                        <h1 className="no-margins">{ pageViews.length }</h1>
                                        <small>Total Views</small>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4">
                                <div className="ibox float-e-margins">
                                    <div className="ibox-title">
                                        <span className="label label-info pull-right">Monthly</span>
                                        <h5>Unique Views</h5>
                                    </div>
                                    <div className="ibox-content" style={{ height: "100px"}}>
                                        <h1 className="no-margins">{ pageViews.length}</h1>
                                        <small>Total Unique Visits</small>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4">
                                <div className="ibox float-e-margins">
                                    <div className="ibox-title">
                                        <span className="label label-primary pull-right">Monthly</span>
                                        <h5>Leads</h5>
                                    </div>
                                    <div className="ibox-content" style={{ height: "100px"}}>
                                        <h1 className="no-margins">{sales.partialCnt}</h1>
                                        <small>Total Leads This Month</small>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4">
                                <div className="ibox float-e-margins">
                                    <div className="ibox-title">
                                        <span className="label label-danger pull-right">Monthly</span>
                                        <h5>Conversion Rate</h5>
                                    </div>
                                    <div className="ibox-content" style={{ height: "100px"}}>
                                        <h1 className="no-margins">{sales.convRate}</h1>
                                        <small>conversion Rate This Month</small>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4">
                                <div className="ibox float-e-margins">
                                    <div className="ibox-title">
                                        <span className="label label-primary pull-right">Monthly</span>
                                        <h5>Revenue</h5>
                                    </div>
                                    <div className="ibox-content" style={{ height: "100px"}}>
                                        <h1 className="no-margins">${sales.revenue}</h1>
                                        <small>Revenue This Month</small>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4">
                                <div className="ibox float-e-margins">
                                    <div className="ibox-title">
                                        <span className="label label-warning pull-right">Monthly</span>
                                        <h5>Orders</h5>
                                    </div>
                                    <div className="ibox-content" style={{ height: "100px"}}>
                                        <h1 className="no-margins">{ sales.salesCnt }</h1>
                                        <small>Orders This Month</small>
                                    </div>
                                </div>
                            </div>


                        </div>
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="ibox float-e-margins">
                                    <div className="ibox-content">
                                        <div>
                                            <h3 className="font-bold no-margins">
                                                Half-year revenue margin
                                            </h3>
                                            <small>Sales marketing.</small>
                                        </div>

                                        <div className="m-t-sm">

                                            <div className="row">
                                                <div className="col-md-8">
                                                    <div>
                                                        <Line data={ lineData } options={{ responsive: true }} height={114}/>
                                                    </div>
                                                </div>
                                                <div className="col-md-4">
                                                    <ul className="stat-list m-t-lg">
                                                        <li>
                                                            <h2 className="no-margins">{typeof(transactions) === "object" && transactions.length > 0 ? transactions[transactionsLen].transactionCnt1 : 0 }</h2>
                                                            <small>Total transactions in period</small>
                                                            <div className="progress progress-mini">
                                                                <div className="progress-bar" style={{ width: "48%" }}/>
                                                            </div>
                                                        </li>
                                                        <li>
                                                            <h2 className="no-margins ">{typeof(transactions) === "object" && transactions.length > 1 ? transactions[transactionsLen - 1].transactionCnt1 : 0 }</h2>
                                                            <small>Transactions last month</small>
                                                            <div className="progress progress-mini">
                                                                <div className="progress-bar" style={{ width: "48%" }}/>
                                                            </div>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>

                                        </div>

                                        <div className="m-t-md">
                                            <small>
                                                <strong>Analysis of sales:</strong> These value are a calculation of all sales from the last 6 months.
                                            </small>
                                        </div>

                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <div className="ibox float-e-margins">
                                    <div className="ibox-title">
                                        <span className="label label-warning pull-right">Monthly</span>
                                        <h5>Sales Summary</h5>
                                    </div>
                                    <div className="ibox-content" >
                                        <div className="row">
                                            <div className="col-xs-4">
                                                <small className="stats-label">Clicks</small>
                                                <h4>{ sales.clickCnt }</h4>
                                                <h5>{ isNaN(sales.clickPercent) ? "-" : parseFloat(sales.clickPercent).toFixed(2) + "%"}</h5>
                                            </div>

                                            <div className="col-xs-4">
                                                <small className="stats-label">Bounce Rate</small>
                                                <h4>{ sales.bounceRate } %</h4>
                                            </div>
                                            <div className="col-xs-4">
                                                <small className="stats-label">Conversion Rate</small>
                                                <h4>{ sales.convRate }</h4>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="ibox-content">
                                        <div className="row">
                                            <div className="col-xs-4">
                                                <small className="stats-label">Partials</small>
                                                <h4>{ sales.leadCnt }</h4>
                                                <h5>{isNaN(sales.partialPercent) ? "-" : parseFloat(sales.partialPercent).toFixed(2) + "%"}</h5>
                                            </div>
                                            <div className="col-xs-4">
                                                <small className="stats-label">Sales</small>
                                                <h4>{ sales.saleCnt }</h4>
                                                <h5>{ isNaN(sales.salePercent) ? "-" : parseFloat(sales.salePercent).toFixed(2) + "%"}</h5>
                                            </div>
                                            <div className="col-xs-4">
                                                <small className="stats-label">Agent Sales</small>
                                                <h4>{ sales.agentSaleCnt }</h4>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="ibox-content">
                                        <div className="row">
                                            <div className="col-xs-4">
                                                <small className="stats-label">Rebills</small>
                                                <h4>{ sales.rebillCnt }</h4>
                                            </div>

                                            <div className="col-xs-4">
                                                <small className="stats-label">Declines</small>
                                                <h4>{ sales.declineCnt }</h4>
                                            </div>
                                            <div className="col-xs-4">
                                                <small className="stats-label">Cancels</small>
                                                <h4>{ sales.cancelCnt }</h4>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-6 ">
                                <div className="ibox float-e-margins">
                                    <div className="ibox-content">
                                        <div className="row">
                                            <h2>Upsells</h2>
                                            <small>This shows real time data of how your upsells are performing</small>
                                            <hr/>
                                            <div>
                                                <div className="col-lg-10 col-lg-offset-1" >
                                                    <Doughnut data={doughnutData} legend={{display:false}} />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div id='dash-rightSide' className="col-md-2" style={{ overflowWrap: "break-word"}}>
                        <div>
                            {
                                ads ? ads.map(ad => (
                                    <a target="_blank" href={ad.link}>
                                        <div key={ad.id}>
                                            <img className="img-responsive" src={ad.img} alt=""/>
                                            <div className='content animated fadeIn'>
                                                <div  className="center-block">
                                                    <h1 style={ ad.id === 2 ? {color: "#1ab394", textTransform: "uppercase"} : {color: "white", fontWeight: "bold"}}>{ad.name}</h1>
                                                    <h3 style={ ad.id === 2 ? { color: "#1ab394", fontWeight: "bold"} : { color: "red", fontWeight: "bold"}}>{ad.description}</h3>
                                                </div>
                                            </div>
                                        </div>
                                    </a>
                                )) : null
                            }
                        </div>
                        <div className='animated slideInRight ui fluid profile card'>
                            <div className='content animated fadeIn'>
                                <div  className="center-block text-center">
                                    {/*<img src={require("")} alt=""/>*/}
                                    <h2>NEWS</h2>
                                    <hr/>
                                    <div className="row">
                                        <div className="col-md-4">
                                            <img style={{width: "100%", height: "100%"}} src={require("../img/VISA-PCI-300x149.jpg")} alt=""/>
                                        </div>
                                        <div className="col-md-8">
                                            <p style={{fontWeight: "bold"}}>Konnektive is now PCI LEVEL 1 Compliant</p>
                                        </div>
                                    </div>
                                    <hr/>
                                    <div className="row">
                                        <div className="col-md-4">
                                            <img style={{width: "100%", height: "100%"}} src={require("../img/63da833-konnektive-logo-2.png")} alt=""/>
                                        </div>
                                        <div className="col-md-8">
                                            <p style={{fontWeight: "bold"}}>Parent / Child access controls is now live in Konnektive CRM</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="animated fadeIn">
                        <Modal show={this.state.show} onHide={this.handleClose}>
                            <Modal.Header closeButton />
                            <Modal.Body>
                                <div className="text-center">
                                    <h1 className="text-center">Welcome to Funnel Konnekt!</h1>
                                    <h3 className="text-center">Let's get started with building your top performing funnel!</h3>
                                    <div onClick={ this.redirectToFunnelType } className="createFunnel center-block blink_me">
                                        <i className="fa fa-plus fa-lg" style={{ fontSize: "500%", fontWeight: 300, color: "#2F404D" }}/>
                                        <div>
                                            Click to create a new Funnel
                                        </div>
                                    </div>
                                </div>
                            </Modal.Body>
                        </Modal>
                    </div>
                    <WalkthroughPeekaboo
                        show={showWalkthroughPeekaboo}
                        relativeElement={this.createFunnelButton.current}
                        onClose={onWalkthroughPeekabooSeenByUser}
                        delay={2000}
                    />
                </div> : <Redirect to='/login' />
        );
    }

}

Dashboard.propTypes = {
    funnels: PropTypes.array.isRequired,
    sales: PropTypes.object.isRequired,
    pageViews: PropTypes.array.isRequired,
    getSalesSummary: PropTypes.func,
    getPageViews: PropTypes.func,
    loadFunnels: PropTypes.func,
    loadCampaigns: PropTypes.func,
    actions: PropTypes.object.isRequired,
    loadCompany: PropTypes.func,
    getWalkthroughTilesFetchStatus: PropTypes.bool,
    walkthroughTiles: PropTypes.array,
    onWalkthroughPeekabooSeenByUser: PropTypes.func,
};
Dashboard.contextTypes = {
    router: PropTypes.object
};