import { APIHelper } from "../utilities";
import { rootUrl } from "./urls";
import { WebpageFormat } from "../webpage-creator";
import { JavascriptTemplate } from "../webpage-creator/js-template/template";
import { generateWebBuilderElements } from "./helpers";

export function savePage({
    html,
    css,
    pageViewReferenceId,
    component_json,
    styles_json,
    pageName = "index",
    templateId,
    generateThumbnail = false,
    isEdited = false,
    funnelReferenceId,
    isPremium = false,
}, { headers = {} }) {
    let funnelFormData = new FormData();
    // funnelFormData.append("meta_json", new Blob([JSON.stringify(buttonMetaData ? buttonMetaData : "")], { type: "application/json" }), "meta.json");
    funnelFormData.append("name", "TestPage");
    funnelFormData.append("templateId", templateId);
    funnelFormData.append("generateThumbnail", generateThumbnail);
    funnelFormData.append("isEdited", isEdited);
    funnelFormData.append("isPremium", isPremium);
    const wbf = new WebpageFormat(html, css, {
        pageViewReferenceId,
        funnelReferenceId,
    });
    funnelFormData.append("html", new Blob([wbf.getFormattedWebPage()], { type: "text/html" }), `${pageName}.html`);
    funnelFormData.append("component_json", new Blob([component_json], { type: "text/html" }),`${pageViewReferenceId}_component.json`);
    funnelFormData.append("styles_json", new Blob([styles_json], { type: "text/css" }),`${pageViewReferenceId}_styles.json`);
    return APIHelper.baseRequest(APIHelper.PUT, `${rootUrl}/PageView/${funnelReferenceId}/${pageViewReferenceId}`, {
        contentType: APIHelper.getMultipartReqHeader(),
        requestBody: funnelFormData,
        headers
    });
}

export function saveHtmlPage({
    html,
    css,
    pageViewReferenceId,
    pageName = "index",
    templateId,
    pageData,
    buttonMetaData = null,
    generateThumbnail = false,
    isEdited = false,
    funnelReferenceId
}, { headers = {} }) {
    let funnelFormData = new FormData();
    funnelFormData.append("meta_json", new Blob([JSON.stringify(buttonMetaData ? buttonMetaData : "")], { type: "application/json" }), "meta.json");
    funnelFormData.append("name", "TestPage");
    funnelFormData.append("templateId", templateId);
    funnelFormData.append("generateThumbnail", generateThumbnail);
    funnelFormData.append("isEdited", isEdited);
    const wbf = new WebpageFormat(html, css, pageData);
    funnelFormData.append("html", new Blob([wbf.getFormattedWebPage()], { type: "text/html" }), `${pageName}.html`);
    return APIHelper.baseRequest(APIHelper.PUT, `${rootUrl}/PageView/${funnelReferenceId}/${pageViewReferenceId}`, {
        contentType: APIHelper.getMultipartReqHeader(),
        requestBody: funnelFormData,
        headers
    });
}

export function publishPageView(referenceId, { headers = {} }) {
    return APIHelper.baseRequest(APIHelper.GET, `${rootUrl}/PageView/Publish/${referenceId}`, {
        headers
    });
}

export function createNewPageView(params) {
    return APIHelper.baseRequest(APIHelper.POST, `${rootUrl}/PageView`, {
        headers: APIHelper.getGenericHeaders(),
        requestBody: {
            "Title": params.title || "",
            "FTemplateId": 60,
            "PageReferenceId": params.referenceId
        }
    });
}

export function getClonedPage(clonedPageId) {
    return APIHelper.baseRequest(APIHelper.GET, `${rootUrl}/Page/${clonedPageId}`, {
        headers: APIHelper.getGenericHeaders()
    });
}

export function saveJSTemplate(params) {
    const funnelFormData = new FormData();
    const { isPremium = false } = params;
    funnelFormData.append("name", "JavascriptFile");
    funnelFormData.append("templateId", params.templateId);
    funnelFormData.append("isPremium", isPremium);
    funnelFormData.append("isEdited", params.isEdited ? params.isEdited : false);
    funnelFormData.append("js", new Blob([JavascriptTemplate.getFileContent()], { type: "text/html" }), "index.js");
    return APIHelper.baseRequest(APIHelper.PUT, `${rootUrl}/PageView/${params.funnelReferenceId}/${params.pageViewReferenceId}`, {
        contentType: APIHelper.getMultipartReqHeader(),
        requestBody: funnelFormData,
        headers: APIHelper.getGenericHeaders()
    });
}



export function updateWebBuilderElements({
    buttonMetaData,
    funnelReferenceId,
    pageViewReferenceId,
}) {
    const requestBody = generateWebBuilderElements(buttonMetaData);
    return APIHelper.baseRequest(APIHelper.PUT, `${rootUrl}/WebBuilderComponent/UpdateWebBuilderComponent/Funnel/${funnelReferenceId}/PageView/${pageViewReferenceId}`, {
        requestBody,
        headers: APIHelper.getGenericHeaders()
    });
}