import React from "react";
import PropTypes from "prop-types";
import Stepzilla from "react-stepzilla";
import { Modal } from "react-bootstrap";
import "../../../node_modules/react-stepzilla/src/css/main.css";
import { Step1 } from "./steps/Step1";
import { Step2 } from "./steps/Step2";
import { Step3 } from "./steps/Step3";
import "./styles.css";

export class SignUp extends React.Component {
    constructor(props, context) {
        super(props, context);
        this.child = React.createRef();
        this.state = {
            accounts: Object.assign({}, props.accounts),
            show: false,
            accountError: false,
        };
        this.accountStore = {
            email: "",
            firstName: "",
            lastName: "",
            company: "",
        };
        this.handleClose = this.handleClose.bind(this);
        this.handleShow = this.handleShow.bind(this);
        this.saveAccount = this.saveAccount.bind(this);
    }
    getStore() {
        return this.accountStore;
    }
    componentWillReceiveProps(nextProps) {
        if (!nextProps.isAccountError) {
            this.context.router.history.push("/");
        } else {
            this.setState({ accountError: true });
        }
    }
    updateStore(update) {
        this.accountStore = {
            ...this.accountStore,
            ...update,
        };
    }
    saveAccount() {
        this.props.putAccountInfo(this.accountStore);
    }
    handleClose() {
        this.setState({ show: false });
    }
    handleShow() {
        this.setState({ show: true });
    }
    render() {
        const step = [
            { name: "Account", component: <Step1 getStore={() => (this.getStore())} updateStore={(u) => {this.updateStore(u);}} onChange={this.updateAccountState} onSave={this.saveAccount} /> },
            { name: "Profile", component: <Step2 getStore={() => (this.getStore())} updateStore={(u) => {this.updateStore(u);}} onChange={this.updateAccountState} onSave={this.saveAccount} /> },
            { name: "API Credentials", component: <Step3 getStore={() => (this.getStore())} updateStore={(u) => {this.updateStore(u);}} onChange={this.updateAccountState} onSave={this.saveAccount} accountError ={this.state.accountError} /> }];
            // { name: "Finished", component: <Step4 getStore={() => (this.getStore())} updateStore={(u) => {this.updateStore(u);}} onChange={this.updateAccountState} onSave={this.saveAccount} accountError ={this.state.accountError}/> }];
        return (
            <Modal show={this.state.show} backdrop = "static" onHide={this.handleClose} bsSize="large" aria-labelledby="contained-modal-title-lg">
                <Modal.Header >
                    <Modal.Title>Lets Get Set Up</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="step-progress">
                        <Stepzilla steps={step} prevBtnOnLastStep={false} />
                    </div>
                </Modal.Body>
            </Modal>
        );
    }
}

SignUp.propTypes = {
    actions: PropTypes.object.isRequired,
    accounts: PropTypes.object,
    putAccountInfo: PropTypes.function,
    jumpToStep: PropTypes.function,
    isAccountError: PropTypes.bool,
    props: PropTypes.object
};
SignUp.contextTypes = {
    router: PropTypes.object
};