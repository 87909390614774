import { APIHelper } from "../utilities";
import { rootUrl } from "./urls";

import {
    componentTypesMap,
    splitActionTypes,
    funnelActionTypes,
    funnelTemplateActionTypes,
} from "../funnels/constants";

export default class ChangeSetApi {
    constructor(changeSetId, funnelId, visualizerType = "funnel", triggerChangeSetError = () => {}) {
        this._changeSetId = changeSetId;
        this.funnelId = funnelId;
        this.ajaxQueue = [];
        this.visualizerType = visualizerType;
        this.actionTypesMap = visualizerType === "funnelTemplate" ? funnelTemplateActionTypes : funnelActionTypes;
        this.apiUrl = this.getApiUrl(visualizerType);
        this.triggerChangeSetError = triggerChangeSetError;
    }

    get changeSetId() {
        return this._changeSetId;
    }

    set changeSetId(value) {
        this._changeSetId = value;
    }

    getApiUrl(type = "funnel") {
        if (type === "funnelTemplate") {
            return `${rootUrl}/Changeset/FunnelTemplates/Create`;
        }
        return `${rootUrl}/Changeset/Create`;
    }

    generateElementModel(componentData, componentType, action) {
        switch (componentType) {
            case "page":
                return this.generatePageModel(componentData, action);
            case "link":
                if (this.actionTypesMap[action] !== 1) return this.generateLinkModel(componentData);
                break;
            default:
                return {};
        }
    }

    generateComponentModel({
        action,
        componentType,
        componentId,
        values,
        componentData,
    }) {
        return {
            actionType: this.actionTypesMap[action],
            componentType: componentTypesMap[componentType],
            componentId,
            componentValue: JSON.stringify(values),
            ...this.generateElementModel(componentData, componentType, action)
        };
    }

    generatePageModel(
        componentData,
        action
    ) {
        if (!splitActionTypes.includes(this.actionTypesMap[action])) {
            const {
                pageReferenceId,
                pageLabel,
                pageViewReferenceId,
                addOns
            } = componentData;
            return {
                pageModel: {
                    pageReferenceId: pageReferenceId,
                    pageTitle: pageLabel,
                    pageViewReferenceId: pageViewReferenceId,
                    pageViewTitle: pageLabel,
                    ...addOns,
                }
            };
        } else {
            return {
                splitModel: componentData
            };
        }
    }

    generateLinkModel({
        targetPageCellViewId,
        sourcePageCellViewId

    }) {
        return {
            linkViewModel: {
                targetPageCellViewId: targetPageCellViewId,
                sourcePageCellViewId: sourcePageCellViewId
            }
        };
    }

    request({
        action,
        componentData,
        values,
        componentType,
        componentId,
    }) {
        const referenceKey = (this.visualizerType === "funnelTemplate") ? "funnelTemplateReferenceId" : "funnelReferenceId";
        const params = {
            [referenceKey]: this.funnelId,
            componentModel: this.generateComponentModel({
                action,
                values,
                componentData,
                componentType,
                componentId,
            }),
        };
        this.ajaxQueue.push(params);
        if (this.ajaxQueue.length === 1) {
            this.run();
        }
    }

    changeSetApiCall(data = {}) {
        const requestBody = {
            ...data,
            changeSetId: this.changeSetId,
        };
        return APIHelper.baseRequest(APIHelper.POST, this.apiUrl, {
            headers: APIHelper.getGenericHeaders(),
            requestBody,
        }).catch((err) => {
            this.triggerChangeSetError(err);
        });
    }

    run() {
        if (this.ajaxQueue.length) {
            this.changeSetApiCall(this.ajaxQueue[0])
                .then((changeSetId) => {
                    this.changeSetId = changeSetId;
                    this.ajaxQueue.shift();
                    if (this.ajaxQueue.length) {
                        this.run();
                    }
                })
                .catch((err) => {
                });
        }
    }

    destroy() {
        this.ajaxQueue = [];
    }
}