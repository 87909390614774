import React from "react";
import PropTypes from "prop-types";
import { Modal } from "react-bootstrap";

const InvalidDomainErrorModal = ({ isCreateDomainError, closeCreateDomainErrorModal, invalidDomainName, errorTitle }) => {
    return (
        <Modal show={isCreateDomainError} backdrop = "static" bsSize="small" aria-labelledby="contained-modal-title-lg">
            <Modal.Header>
                <Modal.Title><h3 className="text-white">{errorTitle}</h3></Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <h3 className="text-center">{invalidDomainName}</h3>
                <div className="pull-right">
                    <button className="btn btn-primary" onClick={closeCreateDomainErrorModal}>OK</button>
                </div>
            </Modal.Body>
        </Modal>
    );
};

InvalidDomainErrorModal.propTypes = {
    isCreateDomainError: PropTypes.bool,
    closeCreateDomainErrorModal: PropTypes.func,
    invalidDomainName: PropTypes.string,
    errorTitle: PropTypes.string
};

export default InvalidDomainErrorModal;