var commonCSS = `
    
body{
    line-height:1.5;
}
.container-custom{
    width: 1400px;
    max-width: 100%;
    margin: 0 auto;
    font-family:'sans-serif';
}
.form-control-custom{
    display: block;
    width: 100%;
    padding: .375rem .75rem;
    font-size: 1rem;
    line-height: 1.5;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-radius: .25rem;
    transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
}
.row-custom{
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    margin-right: -15px;
    margin-left: -15px;
}
.form-group_div {
    margin: 10px 0px;
    width:100%;
    padding:0px 15px;
}
.text-center{
    text-align:center;
}

.padding-3{
    padding:16px;
}
.submit-btn{
    color: #fff;
    background-color: #007bff;
    border-color: #007bff;
    display: inline-block;
    font-weight: 400;
    text-align: center;
    white-space: nowrap;
    vertical-align: middle;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    border: 1px solid transparent;
    padding: .375rem .75rem;
    font-size: 1rem;
    line-height: 1.5;
    border-radius: .25rem;
    transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
}
   `;
var commonBootstrap = `
   @media(min-width:991px){  
    .column-md-6{
        padding:0px 15px;
        width:50%;
    }
    .column-md-2{
        width:9.9%;
        padding:0px 15px;
    }
    .column-md-4{
        width:33.33%;
        padding:0px 15px;
    }
}
@media(min-width:768px) and (max-width:990px){
    .column-sm-6{
        padding:0px 15px;
        width:50%;
        
    }
}
@media(max-width:767px){
    .column-sm-6{
        padding:0px 15px;
        width:100%;
       
    }
}
.padding-x3{
    padding:0px 16px;
}
label{
    display: inline-block;
    margin-bottom: .5rem;
}`;
var columnMd3 = ` @media(min-width:991px){
    .column-md-3{
        padding:0px 15px;
        width:25%;
        
}
}`;

var invoiceStyles = `
.container-custom{
    width: 1400px;
    max-width: 100%;
    margin: 0 auto;
    font-family:'sans-serif';
}
.row-custom{
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    margin-right: -15px;
    margin-left: -15px;
}
@media(min-width:991px){  
    .column-md-4{
        width:33.33%;
        padding:0px;
    }
}
@media(min-width:768px) and (max-width:990px){
    .column-sm-6{
        padding:0px;
        width:50%;
        
    }
}
@media(max-width:767px){
    .column-sm-6{
        padding:0px;
        width:100%;
       
    }
}
.padding-x3{
    padding:0px 16px;
}
.form-group {
    margin-bottom: 1rem;
}
label {
    display: inline-block;
    margin-bottom: .5rem;
}

.label_name {
    display: block;
    font-weight: normal;
    font-size: 14px;
    margin: 0px;
}

.label_value {
    display: block;
    font-weight: 700;
    font-size: 16px;
}
`;
var btnStyle = `
.btn-warning {
    color: #212529;
    background-color: #ffc107;
    border-color: #ffc107;
}
.btn {
    display: inline-block;
    font-weight: 400;
    text-align: center;
    white-space: nowrap;
    vertical-align: middle;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    border: 1px solid transparent;
    padding: .375rem .75rem;
    font-size: 1rem;
    line-height: 1.5;
    border-radius: .25rem;
    transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
}
`;
export const customElements = {
    leadElementUserDetails: `
    <style>
    ${commonCSS}
    input.form-control-custom.form_group_field,select.form-control-custom.form_group_field{
        float: right;
        width: 240px;
    }

    .lead-element-basic input.form-control-custom,
    .lead-element-basic select.form-control-custom {
        border-radius: 0px;
        height: 32px;
    }
    .lead-element-basic {
        padding: 10px 0px;
        width: 450px;
        background:#f4f4f4;
        color:#333;
    }    
    label.label_name.mandatory {
        width: 120px;
        float: left;
    }
    .mandatory::after {
        color: red;
        content: "*";
        margin-left: 5px;
    }
    .heading{
        margin-bottom: .5rem;
        font-family: inherit;
        font-weight: 500;
        line-height: 1.2;
        color: inherit;
        font-size: 36px;
        margin-top:0px;
    }
    .no-margin{
        margin:0px !important;
    }
</style>
<section class="lead-element-basic">
<div class="container-custom">
<form class="row-custom padding-3 no-margin" style="border:1px solid #f4f4f4;">
    <div class="row-custom no-margin" data-gjs-resizable='{"tl":0,"tc":0,"tr":0,"cl":0,"cr":0,"bl":0,"br":0}'>
        <div class="text-center">
            <h1 class="heading">Signup for more information</h1>
        </div>
        <div class="form-group_div "
            data-gjs-resizable='{"tl":0,"tc":0,"tr":0,"cl":0,"bl":0,"br":0,"bc": 0, "keyWidth": "flex-basis", "currentUnit": 1, "minDim": 1, "maxDim": 100, "step": 0.2}'>
            <label class="label_name mandatory">First name</label>
            <input type="text" class="form-control-custom form_group_field" name="firstName">
        </div>
        <div class="form-group_div "
            data-gjs-resizable='{"tl":0,"tc":0,"tr":0,"cl":0,"bl":0,"br":0,"bc": 0, "keyWidth": "flex-basis", "currentUnit": 1, "minDim": 1, "maxDim": 100, "step": 0.2}'>
            <label class="label_name mandatory">Last Name</label>
            <input type="text" class="form-control-custom form_group_field" name="lastName">
        </div>
        <div class="form-group_div "
            data-gjs-resizable='{"tl":0,"tc":0,"tr":0,"cl":0,"bl":0,"br":0,"bc": 0, "keyWidth": "flex-basis", "currentUnit": 1, "minDim": 1, "maxDim": 100, "step": 0.2}'>
            <label class="label_name mandatory">Email Address</label>
            <input type="text" class="form-control-custom form_group_field" name="emailAddress">
        </div>
        <div class="form-group_div "
            data-gjs-resizable='{"tl":0,"tc":0,"tr":0,"cl":0,"bl":0,"br":0,"bc": 0, "keyWidth": "flex-basis", "currentUnit": 1, "minDim": 1, "maxDim": 100, "step": 0.2}'>
            <label class="label_name mandatory">Phone Number</label>
            <input type="text" class="form-control-custom form_group_field" name="phoneNumber">
        </div>
        
    </div>
</form>
<div class=" text-center">
    <button class="submit-btn">Submit</button>
</div>
</div>
</section>
`,
    leadElementInputGroup: `
    <style>
    ${commonCSS}
    .lead-element-one {
        padding: 10px 0px;
        width: 450px;
        background:#f4f4f4;
        color:#333;
    }
    label.label_name.mandatory {
        width: 120px;
        float: left;
    }
    .mandatory::after {
        color: red;
        content: "*";
        margin-left: 5px;
    }
    .heading{
        margin-bottom: .5rem;
        font-family: inherit;
        font-weight: 500;
        line-height: 1.2;
        color: inherit;
        font-size: 36px;
        margin-top:0px;
    }
    .no-margin{
        margin:0px !important;
    }
</style>
<section class="lead-element-one">
<div class="container-custom">
<form class="row-custom no-margin padding-3" style="border:1px solid #f4f4f4;">
    <div class="row-custom no-margin" data-gjs-resizable='{"tl":0,"tc":0,"tr":0,"cl":0,"cr":0,"bl":0,"br":0}'>
        <div class="text-center">
            <h1 class="heading">Signup for more information</h1>
        </div>
        <div class="form-group_div "
            data-gjs-resizable='{"tl":0,"tc":0,"tr":0,"cl":0,"bl":0,"br":0,"bc": 0, "keyWidth": "flex-basis", "currentUnit": 1, "minDim": 1, "maxDim": 100, "step": 0.2}'>
            <label class="label_name mandatory">First name</label>
            <input type="text" class="form-control-custom form_group_field" name="firstName">
        </div>
        <div class="form-group_div "
            data-gjs-resizable='{"tl":0,"tc":0,"tr":0,"cl":0,"bl":0,"br":0,"bc": 0, "keyWidth": "flex-basis", "currentUnit": 1, "minDim": 1, "maxDim": 100, "step": 0.2}'>
            <label class="label_name mandatory">Last Name</label>
            <input type="text" class="form-control-custom form_group_field" name="lastName">
        </div>
        <div class="form-group_div "
            data-gjs-resizable='{"tl":0,"tc":0,"tr":0,"cl":0,"bl":0,"br":0,"bc": 0, "keyWidth": "flex-basis", "currentUnit": 1, "minDim": 1, "maxDim": 100, "step": 0.2}'>
            <label class="label_name mandatory">Email Address</label>
            <input type="text" class="form-control-custom form_group_field" name="emailAddress">
        </div>
        <div class="form-group_div "
            data-gjs-resizable='{"tl":0,"tc":0,"tr":0,"cl":0,"bl":0,"br":0,"bc": 0, "keyWidth": "flex-basis", "currentUnit": 1, "minDim": 1, "maxDim": 100, "step": 0.2}'>
            <label class="label_name mandatory">Phone Number</label>
            <input type="text" class="form-control-custom form_group_field" name="phoneNumber">
        </div>
        <div class="form-group_div "
            data-gjs-resizable='{"tl":0,"tc":0,"tr":0,"cl":0,"bl":0,"br":0,"bc": 0, "keyWidth": "flex-basis", "currentUnit": 1, "minDim": 1, "maxDim": 100, "step": 0.2}'>
            <label class="label_name mandatory">Full Address</label>
            <input type="text" class="form-control-custom form_group_field" name="address1">
        </div>
        <div class="form-group_div "
            data-gjs-resizable='{"tl":0,"tc":0,"tr":0,"cl":0,"bl":0,"br":0,"bc": 0, "keyWidth": "flex-basis", "currentUnit": 1, "minDim": 1, "maxDim": 100, "step": 0.2}'>
            <label class="label_name">Address line 2</label>
            <input type="text" class="form-control-custom form_group_field" name="address2">
        </div>
        <div class="form-group_div "
            data-gjs-resizable='{"tl":0,"tc":0,"tr":0,"cl":0,"bl":0,"br":0,"bc": 0, "keyWidth": "flex-basis", "currentUnit": 1, "minDim": 1, "maxDim": 100, "step": 0.2}'>
            <label class="label_name mandatory">City</label>
            <input type="text" class="form-control-custom form_group_field" name="city">
        </div>
        <div class="form-group_div "
            data-gjs-resizable='{"tl":0,"tc":0,"tr":0,"cl":0,"bl":0,"br":0,"bc": 0, "keyWidth": "flex-basis", "currentUnit": 1, "minDim": 1, "maxDim": 100, "step": 0.2}'>
            <label class="label_name mandatory">State/Province</label>
                <select id="state" name="state" class="form-control-custom form_group_field">
            <option value="">Select State</option>
            </select>
        </div>
        <div class="form-group_div "
            data-gjs-resizable='{"tl":0,"tc":0,"tr":0,"cl":0,"bl":0,"br":0,"bc": 0, "keyWidth": "flex-basis", "currentUnit": 1, "minDim": 1, "maxDim": 100, "step": 0.2}'>
            <label class="label_name mandatory">ZIP Code</label>
            <input type="text" class="form-control-custom form_group_field" name="postalCode">
        </div>
        <div class="form-group_div "
            data-gjs-resizable='{"tl":0,"tc":0,"tr":0,"cl":0,"bl":0,"br":0,"bc": 0, "keyWidth": "flex-basis", "currentUnit": 1, "minDim": 1, "maxDim": 100, "step": 0.2}'>
            <label class="label_name mandatory">Country</label>
            <select onchange="changeCountry()" id="country" name="country"
                class="form-control-custom form_group_field">
                <option value="">Choose Country</option>
            </select>
        </div>
        <div class="w-100 float-left mt-2 mb-2" style="visibility:hidden;height:0px;" >
            <input type="checkbox" name="billShipSame" checked> Is Billing address same as Shipping address?
        </div>
    </div>
</form>
<div class=" text-center">
    <button class="submit-btn">Submit</button>
</div>
</div>
</section>
`,
    leadElementInfo: `
<style>
${commonCSS}
.lead-element-two {
    padding: 10px 0px;
    width: 400px;
    background:#f4f4f4;
    color:#333;
}
.heading{
    margin-bottom: .5rem;
    font-family: inherit;
    font-weight: 500;
    line-height: 1.2;
    color: inherit;
    font-size: 36px;
    margin-top:0px;
}
.no-margin{
    margin:0px !important;
}
</style>
<section class="lead-element-two">
<div class="container-custom">
<form class="row-custom no-margin padding-3" style="border:1px solid #f4f4f4;">
<div class="text-center">
        <h1 class="heading">Signup for more information</h1>
</div>
<div class="row-custom" data-gjs-resizable='{"tl":0,"tc":0,"tr":0,"cl":0,"cr":0,"bl":0,"br":0}'>
    <div class="form-group_div "
        data-gjs-resizable='{"tl":0,"tc":0,"tr":0,"cl":0,"bl":0,"br":0,"bc": 0, "keyWidth": "flex-basis", "currentUnit": 1, "minDim": 1, "maxDim": 100, "step": 0.2}'>
        <input type="text" class="form-control-custom" name="firstName" placeholder="First name *">
    </div>
    <div class="form-group_div "
        data-gjs-resizable='{"tl":0,"tc":0,"tr":0,"cl":0,"bl":0,"br":0,"bc": 0, "keyWidth": "flex-basis", "currentUnit": 1, "minDim": 1, "maxDim": 100, "step": 0.2}'>
        <input type="text" class="form-control-custom" name="lastName" placeholder="Last name *">
    </div>
    <div class="form-group_div "
        data-gjs-resizable='{"tl":0,"tc":0,"tr":0,"cl":0,"bl":0,"br":0,"bc": 0, "keyWidth": "flex-basis", "currentUnit": 1, "minDim": 1, "maxDim": 100, "step": 0.2}'>
        <input type="text" class="form-control-custom" name="emailAddress" placeholder="Email Address *">
    </div>
    <div class="form-group_div "
        data-gjs-resizable='{"tl":0,"tc":0,"tr":0,"cl":0,"bl":0,"br":0,"bc": 0, "keyWidth": "flex-basis", "currentUnit": 1, "minDim": 1, "maxDim": 100, "step": 0.2}'>
        <input type="text" class="form-control-custom" name="phoneNumber" placeholder="Phone number *">
    </div>
    <div class="form-group_div "
        data-gjs-resizable='{"tl":0,"tc":0,"tr":0,"cl":0,"bl":0,"br":0,"bc": 0, "keyWidth": "flex-basis", "currentUnit": 1, "minDim": 1, "maxDim": 100, "step": 0.2}'>
        <input type="text" class="form-control-custom" name="address1" placeholder="Full Address *">
    </div>
    <div class="form-group_div "
        data-gjs-resizable='{"tl":0,"tc":0,"tr":0,"cl":0,"bl":0,"br":0,"bc": 0, "keyWidth": "flex-basis", "currentUnit": 1, "minDim": 1, "maxDim": 100, "step": 0.2}'>
        <input type="text" class="form-control-custom" name="address2" placeholder="Address line 2">
    </div>
    <div class="form-group_div "
        data-gjs-resizable='{"tl":0,"tc":0,"tr":0,"cl":0,"bl":0,"br":0,"bc": 0, "keyWidth": "flex-basis", "currentUnit": 1, "minDim": 1, "maxDim": 100, "step": 0.2}'>
        <input type="text" class="form-control-custom" name="city" placeholder="City*">
    </div>
    <div class="form-group_div "
        data-gjs-resizable='{"tl":0,"tc":0,"tr":0,"cl":0,"bl":0,"br":0,"bc": 0, "keyWidth": "flex-basis", "currentUnit": 1, "minDim": 1, "maxDim": 100, "step": 0.2}'>
        <select id="state" name="state" class="form-control-custom">
        <option value="">Select State</option>
    </select>
    </div>
    <div class="form-group_div "
        data-gjs-resizable='{"tl":0,"tc":0,"tr":0,"cl":0,"bl":0,"br":0,"bc": 0, "keyWidth": "flex-basis", "currentUnit": 1, "minDim": 1, "maxDim": 100, "step": 0.2}'>
        <input type="text" class="form-control-custom" name="postalCode" placeholder="ZIP Code *">
    </div>
    <div class="form-group_div "
        data-gjs-resizable='{"tl":0,"tc":0,"tr":0,"cl":0,"bl":0,"br":0,"bc": 0, "keyWidth": "flex-basis", "currentUnit": 1, "minDim": 1, "maxDim": 100, "step": 0.2}'>
        <select onchange="changeCountry()" id="country" name="country" class="form-control-custom">
            <option value="">Choose Country *</option>
        </select>
    </div>
    <div class="w-100 float-left mt-2 mb-2" style="visibility:hidden;height:0px;" >
            <input type="checkbox" name="billShipSame" checked> Is Billing address same as Shipping address?
    </div>
</div>
</form>
<div class=" text-center">
<button class="submit-btn">Submit</button>
</div>
</div>
</section>
`,
    twoStepCheckout: `
<style>
    .checkout_form{
        border-radius: 10px;
        background: #fff;
        margin: 10px 0px;
    }
    .checkout_form .nav-tabs-custom .nav-item.show .nav-link-custom,.checkout_form .nav-tabs-custom .nav-link-custom.active{
        background: #6f7169;
        color: #fff !important;
        display: block;
        width:100%;
    }
    .checkout_form .nav-tabs-custom .nav-item.show .nav-link-custom:hover{
        margin-top: -10px;
        padding: 13px;
        background: #fff;
    }
    .checkout_form .tab-content-custom{
        background: #fff;
    }
    .checkout_form .nav-tabs-custom .nav-link-custom{
        color: #333;
    }
    .checkout_form .nav-tabs-custom .nav-link-custom .tab_number{
        width: auto;
        display: inline-block;
        font-size: 30px;
        position: relative;
        top: -10px;
    }
    .checkout_form .nav-tabs-custom .nav-item{
        width: 50%;
    }
    .checkout_form .nav-tabs-custom .nav-link-custom .tab_1_text{
        display: inline-block;
        font-weight: 500;
       
    }
    .checkout_form .nav-tabs-custom .nav-link-custom .tab_1_text .title{
        font-size: 25px;
    }
    .checkout_form .nav-tabs-custom .nav-link-custom .tab_1_text .where_to_text{
        font-size: 14px;
    }
    .bill-ship-address-same {
        font-size: 20px;
        color: #fff;
        background: #6f7169;
        padding: 5px 10px;
        margin-bottom: 10px;
    }
    .next-tab-button,.btn_submit{
        background: #6f7169;
        color: #fff;
        padding: 7px 40px !important;
        margin-right: 15px;
        margin-bottom: 15px;
        float:right;
        text-align:right;
        font-size:16px;
    }    
    label.mandatory::after {
        content: '*';
        color: red;
        font-size: 13px;
        margin-left: 3px;
    }
    .banner_right{
        width:450px;
        border: 1px solid #ccc;
    }
    .form-group {
        margin-bottom: 1rem;
    }
    .full-width{
        width:100%;
    }
    .width50{
        width:50%;
        padding: 15px;
        display:inline-block;
    }
    .no-pading{
        padding:0px;
    }
    .selected-li.active{
        background:#6f7169;
        color:#fff !important;
    }
    .selected-li.active  .nav-link-custom{
        color:#fff;
    }
    body{
        line-height:1.5;
    }
    .container-custom{
        width: 1400px;
        max-width: 100%;
        margin: 0 auto;
        font-family:'sans-serif';
    }
    .form-control-custom{
        display: block;
        width: 100%;
        padding: .375rem .75rem;
        font-size: 1rem;
        line-height: 1.5;
        color: #495057;
        background-color: #fff;
        background-clip: padding-box;
        border: 1px solid #ced4da;
        border-radius: .25rem;
        transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
    }
    .row-custom{
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -ms-flex-wrap: wrap;
        flex-wrap: wrap;
        margin-right: -15px;
        margin-left: -15px;
    }
    
    @media(min-width:991px){  
        .column-md-6{
            padding:0px 15px;
            width:50%;
        }       
    }
    .padding-x3{
        padding:0px 16px;
    }
    
</style>
<div class="container-custom">
<div class="row-custom banner_content" data-gjs-resizable='{"tl":0,"tc":0,"tr":0,"cl":0,"cr":0,"bl":0,"br":0}'>
<div class="banner_right" data-gjs-resizable='{"tl":0,"tc":0,"tr":0,"cl":0,"bl":0,"br":0,"bc": 0, "keyWidth": "flex-basis", "currentUnit": 1, "minDim": 1, "maxDim": 100, "step": 0.2}'>
    <div class="checkout_form">
        <div class="full-width padding-x3" data-gjs-resizable='{"tl":0,"tc":0,"tr":0,"cl":0,"bl":0,"br":0,"bc": 0, "keyWidth": "flex-basis", "currentUnit": 1, "minDim": 1, "maxDim": 100, "step": 0.2}'>
            <div class="full-width">
                <ul class="nav nav-tabs-custom no-pading" role="tablist">
                    <li class="width50 selected-li active " id="shippingTab" onclick="toggleTabs('#shippingTab','#shipping','#quantityTab','#payment')">
                        <a class="nav-link-custom" >
                            <div class="tab_number">1.</div>
                            <div class="tab_1_text">
                                <div class="title">Shipping</div>
                                <div class="where_to_text">Where should we ship?</div>
                            </div>

                        </a>
                    </li>
                    <li class="width50 selected-li " id="quantityTab" onclick="toggleTabs('#quantityTab','#payment','#shippingTab','#shipping')">
                        <a class="nav-link-custom"  >
                            <div class="tab_number">2.</div>
                            <div class="tab_1_text">
                                <div class="title">Quantity</div>
                                <div class="where_to_text">How many do you want</div>
                            </div>
                        </a>
                    </li>
                </ul>

                <!-- Tab panes -->
                <div class="tab-content-custom">
                    <div id="shipping" class="container-custom " style="display:block"><br>
                        <div class="row-custom">
                        <div class="form-group column-md-6 column-xs-12 " data-gjs-resizable='{"tl":0,"tc":0,"tr":0,"cl":0,"bl":0,"br":0,"bc": 0, "keyWidth": "flex-basis", "currentUnit": 1, "minDim": 1, "maxDim": 100, "step": 0.2}'>
                        <input type="text" name="firstName" class="form-control-custom" placeholder="First Name *">
                        </div>
                        <div class="form-group column-md-6 column-xs-12 " data-gjs-resizable='{"tl":0,"tc":0,"tr":0,"cl":0,"bl":0,"br":0,"bc": 0, "keyWidth": "flex-basis", "currentUnit": 1, "minDim": 1, "maxDim": 100, "step": 0.2}'>
                            <input type="text" name="lastName" class="form-control-custom" placeholder="Last Name *">
                        </div>
                        <div class="form-group column-md-6 column-xs-12 " data-gjs-resizable='{"tl":0,"tc":0,"tr":0,"cl":0,"bl":0,"br":0,"bc": 0, "keyWidth": "flex-basis", "currentUnit": 1, "minDim": 1, "maxDim": 100, "step": 0.2}'>
                            <input type="text" name="emailAddress" class="form-control-custom" placeholder="Email Address *">
                        </div>
                        <div class="form-group column-md-6 column-xs-12 " data-gjs-resizable='{"tl":0,"tc":0,"tr":0,"cl":0,"bl":0,"br":0,"bc": 0, "keyWidth": "flex-basis", "currentUnit": 1, "minDim": 1, "maxDim": 100, "step": 0.2}'>
                            <input type="text" name="phoneNumber" class="form-control-custom" placeholder="Mobile Number *">
                        </div>
                        </div>
                        <div class="bill-ship-address-same  col-xs-12 w-100">
                            Billing Address
                        </div>
                        <div class="row-custom  ">
                            <div class="form-group column-md-6 column-xs-12 " data-gjs-resizable='{"tl":0,"tc":0,"tr":0,"cl":0,"bl":0,"br":0,"bc": 0, "keyWidth": "flex-basis", "currentUnit": 1, "minDim": 1, "maxDim": 100, "step": 0.2}' >
                                <input type="text" name="address1" class="form-control-custom" placeholder="Full Address *">
                            </div>
                            <div class="form-group column-md-6 column-xs-12 " data-gjs-resizable='{"tl":0,"tc":0,"tr":0,"cl":0,"bl":0,"br":0,"bc": 0, "keyWidth": "flex-basis", "currentUnit": 1, "minDim": 1, "maxDim": 100, "step": 0.2}'>
                                <input type="text" name="address2" class="form-control-custom" placeholder="Address line 1...">
                            </div>
                            <div class="form-group column-md-6 column-xs-12 " data-gjs-resizable='{"tl":0,"tc":0,"tr":0,"cl":0,"bl":0,"br":0,"bc": 0, "keyWidth": "flex-basis", "currentUnit": 1, "minDim": 1, "maxDim": 100, "step": 0.2}'>
                                <input type="text" name="city" class="form-control-custom" placeholder="Enter City *">
                            </div>
                            <div class="form-group column-md-6 column-xs-12 ">
                                <select id="state" name="state" class="form-control-custom">
                                    <option value="">-- Select State --</option>
                                </select>
                            </div>
                            <div class="form-group column-md-6 column-xs-12 " data-gjs-resizable='{"tl":0,"tc":0,"tr":0,"cl":0,"bl":0,"br":0,"bc": 0, "keyWidth": "flex-basis", "currentUnit": 1, "minDim": 1, "maxDim": 100, "step": 0.2}'>
                                <input type="text" class="form-control-custom" name="postalCode"
                                    placeholder="Zip Code... *">
                            </div>
                            <div class="form-group column-md-6 column-xs-12 " data-gjs-resizable='{"tl":0,"tc":0,"tr":0,"cl":0,"bl":0,"br":0,"bc": 0, "keyWidth": "flex-basis", "currentUnit": 1, "minDim": 1, "maxDim": 100, "step": 0.2}'>
                                <select onchange="changeCountry()" id="country" name="country"
                                    class="form-control-custom">
                                    <option value="">-- Choose Country --</option>
                                </select>
                            </div>

                        </div>
                        <div class="w-100  mt-2 mb-2">
                            <input type="checkbox" name="billShipSame"> Is Billing address same as Shipping address?
                        </div>
                        <div class="bill-ship-address-same  col-xs-12 w-100">
                            Shipping Address
                        </div>
                        <div class="row-custom">
                            <div class="form-group column-md-6 column-xs-12 " data-gjs-resizable='{"tl":0,"tc":0,"tr":0,"cl":0,"bl":0,"br":0,"bc": 0, "keyWidth": "flex-basis", "currentUnit": 1, "minDim": 1, "maxDim": 100, "step": 0.2}'>
                                <input type="text" name="shipFirstName" class="form-control-custom"
                                    placeholder="First Name *">
                            </div>
                            <div class="form-group column-md-6 column-xs-12 " data-gjs-resizable='{"tl":0,"tc":0,"tr":0,"cl":0,"bl":0,"br":0,"bc": 0, "keyWidth": "flex-basis", "currentUnit": 1, "minDim": 1, "maxDim": 100, "step": 0.2}'>
                                <input type="text" name="shipLastName" class="form-control-custom"
                                    placeholder="Last Name *">
                            </div>
                            <div class="form-group column-md-6 column-xs-12 " data-gjs-resizable='{"tl":0,"tc":0,"tr":0,"cl":0,"bl":0,"br":0,"bc": 0, "keyWidth": "flex-basis", "currentUnit": 1, "minDim": 1, "maxDim": 100, "step": 0.2}'>
                                <input type="text" name="shipAddress1" class="form-control-custom"
                                    placeholder="Full Address *">
                            </div>
                            <div class="form-group column-md-6 column-xs-12 " data-gjs-resizable='{"tl":0,"tc":0,"tr":0,"cl":0,"bl":0,"br":0,"bc": 0, "keyWidth": "flex-basis", "currentUnit": 1, "minDim": 1, "maxDim": 100, "step": 0.2}'>
                                <input type="text" name="shipAddress2" class="form-control-custom"
                                    placeholder="Address line 1 ... *">
                            </div>
                            <div class="form-group column-md-6 column-xs-12 " data-gjs-resizable='{"tl":0,"tc":0,"tr":0,"cl":0,"bl":0,"br":0,"bc": 0, "keyWidth": "flex-basis", "currentUnit": 1, "minDim": 1, "maxDim": 100, "step": 0.2}'>
                                <input type="text" name="shipCity" class="form-control-custom" placeholder="Enter City">
                            </div>
                            <div class="form-group column-md-6 column-xs-12 " data-gjs-resizable='{"tl":0,"tc":0,"tr":0,"cl":0,"bl":0,"br":0,"bc": 0, "keyWidth": "flex-basis", "currentUnit": 1, "minDim": 1, "maxDim": 100, "step": 0.2}'>
                                <select id="shipState" name="shipState" class="form-control-custom">
                                    <option value="">Select State</option>
                                </select>
                            </div>
                            <div class="form-group column-md-6 column-xs-12 " data-gjs-resizable='{"tl":0,"tc":0,"tr":0,"cl":0,"bl":0,"br":0,"bc": 0, "keyWidth": "flex-basis", "currentUnit": 1, "minDim": 1, "maxDim": 100, "step": 0.2}'>
                                <input type="text" class="form-control-custom" name="shipPostalCode"
                                    placeholder="Zip Code...">
                            </div>
                            <div class="form-group column-md-6 column-xs-12 " data-gjs-resizable='{"tl":0,"tc":0,"tr":0,"cl":0,"bl":0,"br":0,"bc": 0, "keyWidth": "flex-basis", "currentUnit": 1, "minDim": 1, "maxDim": 100, "step": 0.2}'>
                                <select onchange="changeCountry2()" id="shipCountry" name="shipCountry"
                                    class="form-control-custom">
                                    <option value="">Choose Country</option>
                                </select>
                            </div>
                            <div class="full-width">
                                <button class="next-tab-button float-right" onclick="moveToNextTab()">Next</button>
                            </div>
                        </div>
                    </div>
                    <div id="payment" class="container-custom" style="display:none"><br>
                        <div class="bill-ship-address-same  w-100">
                                Order
                        </div>
                        <div class="form-group w-100">
                            <input type="text" name="cardNumber" class="form-control-custom" placeholder="Card Number">
                        </div>
                        <div class="form-group w-100">
                            <input type="text" name="cardSecurityCode" class="form-control-custom"
                                placeholder="CVV">
                        </div>
                        <div class="form-group w-100">
                            <select name="cardMonth" class="form-control-custom" id="cardMonth">
                                <option>-- Exp Month -- </option>
                                <option value="01">January</option>
                                <option value="02">Feb</option>
                                <option value="03">March</option>
                                <option value="04">April</option>
                                <option value="05">May</option>
                                <option value="06">June</option>
                                <option value="07">July</option>
                                <option value="08">August</option>
                                <option value="09">September</option>
                                <option value="10">October</option>
                                <option value="11">November</option>
                                <option value="12">December</option>
                            </select>
                        </div>
                        <div class="form-group w-100">
                            <select name="cardYear" id="cardYear" class="form-control-custom">
                                <option>-- Exp Year -- </option>
                                <option value="2018">2018</option>
                                <option value="2019">2019</option>
                                <option value="2020">2020</option>
                                <option value="2021">2021</option>
                                <option value="2022">2022</option>
                                <option value="2023">2023</option>
                                <option value="2024">2024</option>
                            </select>
                        </div>
                        <div class="col-xs-12 confirmation_box" >
                                <div class="col-xs-12 willYoutakeIt" >
                                    <input type="checkbox" name="willYouTakeIt"> Yes, I will take it
                                </div>
                                <p><a>ONE TIME OFFER - </a>Lorem ipsum dolor sit amet, consectetur adipiscing elit,
                                    sed
                                    do eiusmod tempor incididunt ut labore et
                                    dolore magna aliqua. Lorem ipsum dolor sit amet, consectetur adipiscing elit,
                                    sed
                                    do eiusmod tempor
                                    incididunt ut labore et dolore magna aliqua. Lorem ipsum dolor sit amet</p>
                        </div>
                        <div class="form-group full-width">
                            <button class="btn_submit float-right">Submit</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
</div>
</div><script>
var toggleTabs = function(toAdd,toEnable,toRemove,toDisable){
    $(toAdd).addClass('active');
    $(toRemove).removeClass('active');
    $(toEnable).show();
    $(toDisable).hide();    
}
var moveToNextTab = function(){
    $('#shipping').hide();
    $('#payment').show();
    $('#quantityTab').addClass('active');
    $('#shippingTab').removeClass('active');
}
</script>`,
    fullCheckoutElement: `
    <style>
    body {
        padding: 0;
        margin: 0;
        font-family:sans-serif;
    }
    img {
        max-width: 100%;
    }
    .form-group_div {
        margin: 10px 0px;
    }
    .signup-btn{
        margin: 15px 0px;
    }
    .ship_bill_heading{
        background: #ffe4e4;
        padding: 5px 10px;
    }
    .sign_up_section{
        background: #f4f4f4;
        padding: 10px 0px;
    }
    .mandatory::after{
        color:red;
        content: "*";
        margin-left: 5px;
    }
    .shipping_sec,.heading-main{
        margin-top:0px;
        margin-bottom: .5rem;
        font-family: inherit;
        font-weight: 500;
        line-height: 1.2;
        color: inherit;
        font-size: 30px;
    }
    .col-custom{
        position: relative;
        width: 100%;
        min-height: 1px;
        padding-right: 15px;
        padding-left: 15px;
    } 
    ${commonCSS}
    ${commonBootstrap}
    ${columnMd3}
    </style>
    <section class="sign_up_section">
    <div class="container-custom border">
        <h1 class="text-center heading-main">User Details</h1>
        <form class="col-custom">
            <div class="row-custom" data-gjs-resizable='{"tl":0,"tc":0,"tr":0,"cl":0,"cr":0,"bl":0,"br":0}'>
                <div class="form-group_div column-md-3 column-sm-6 column-xs-12" data-gjs-resizable='{"tl":0,"tc":0,"tr":0,"cl":0,"bl":0,"br":0,"bc": 0, "keyWidth": "flex-basis", "currentUnit": 1, "minDim": 1, "maxDim": 100, "step": 0.2}'>
                    <label class="mandatory">First name</label>
                    <input type="text" class="form-control-custom" name="firstName">
                </div>
                <div class="form-group_div column-md-3 column-sm-6 column-xs-12" data-gjs-resizable='{"tl":0,"tc":0,"tr":0,"cl":0,"bl":0,"br":0,"bc": 0, "keyWidth": "flex-basis", "currentUnit": 1, "minDim": 1, "maxDim": 100, "step": 0.2}'>
                    <label class="mandatory">Last Name</label>
                    <input type="text" class="form-control-custom" name="lastName">
                </div>
                <div class="form-group_div column-md-3 column-sm-6 column-xs-12" data-gjs-resizable='{"tl":0,"tc":0,"tr":0,"cl":0,"bl":0,"br":0,"bc": 0, "keyWidth": "flex-basis", "currentUnit": 1, "minDim": 1, "maxDim": 100, "step": 0.2}'>
                    <label class="mandatory">Email Address</label>
                    <input type="text" class="form-control-custom" name="emailAddress">
                </div>
                <div class="form-group_div column-md-3 column-sm-6 column-xs-12" data-gjs-resizable='{"tl":0,"tc":0,"tr":0,"cl":0,"bl":0,"br":0,"bc": 0, "keyWidth": "flex-basis", "currentUnit": 1, "minDim": 1, "maxDim": 100, "step": 0.2}'>
                    <label class="mandatory">Phone Number</label>
                    <input type="text" class="form-control-custom" name="phoneNumber">
                </div>
            </div>
            <div class="row-custom" data-gjs-resizable='{"tl":0,"tc":0,"tr":0,"cl":0,"cr":0,"bl":0,"br":0}'>
                <div class="ship_bill_heading full-width padding-x3">
                    <h3 class="shipping_sec">Billing</h3>
                </div>
                <div class="form-group_div column-md-6 column-sm-6 column-xs-12" data-gjs-resizable='{"tl":0,"tc":0,"tr":0,"cl":0,"bl":0,"br":0,"bc": 0, "keyWidth": "flex-basis", "currentUnit": 1, "minDim": 1, "maxDim": 100, "step": 0.2}'>
                    <label class="mandatory">Full Address</label>
                    <input type="text" class="form-control-custom" name="address1">
                </div>
                <div class="form-group_div column-md-6 column-sm-6 column-xs-12" data-gjs-resizable='{"tl":0,"tc":0,"tr":0,"cl":0,"bl":0,"br":0,"bc": 0, "keyWidth": "flex-basis", "currentUnit": 1, "minDim": 1, "maxDim": 100, "step": 0.2}'>
                    <label>Address line 2</label>
                    <input type="text" class="form-control-custom" name="address2">
                </div>
                <div class="form-group_div column-md-3 column-sm-6 column-xs-12" data-gjs-resizable='{"tl":0,"tc":0,"tr":0,"cl":0,"bl":0,"br":0,"bc": 0, "keyWidth": "flex-basis", "currentUnit": 1, "minDim": 1, "maxDim": 100, "step": 0.2}'>
                    <label class="mandatory">City</label>
                    <input type="text" class="form-control-custom" name="city">
                </div>
                <div class="form-group_div column-md-3 column-sm-6 column-xs-12" data-gjs-resizable='{"tl":0,"tc":0,"tr":0,"cl":0,"bl":0,"br":0,"bc": 0, "keyWidth": "flex-basis", "currentUnit": 1, "minDim": 1, "maxDim": 100, "step": 0.2}'>
                    <label class="mandatory">State/Province</label>
                    <select id="state" name="state" class="form-control-custom">
                    <option value="">-- State --</option>
                     </select>
                </div>
                <div class="form-group_div column-md-3 column-sm-6 column-xs-12" data-gjs-resizable='{"tl":0,"tc":0,"tr":0,"cl":0,"bl":0,"br":0,"bc": 0, "keyWidth": "flex-basis", "currentUnit": 1, "minDim": 1, "maxDim": 100, "step": 0.2}'>
                    <label class="mandatory">ZIP Code</label>
                    <input type="text" class="form-control-custom" name="postalCode">
                </div>
                <div class="form-group_div column-md-3 column-sm-6 column-xs-12" data-gjs-resizable='{"tl":0,"tc":0,"tr":0,"cl":0,"bl":0,"br":0,"bc": 0, "keyWidth": "flex-basis", "currentUnit": 1, "minDim": 1, "maxDim": 100, "step": 0.2}'>
                    <label class="mandatory">Country</label>
                    <select class="form-control-custom" name="country">
                        <option value="">--Country -- </option>
                        <option value="IN">India</option>
                        <option value="US">USA</option>
                    </select>
                </div>
                <div class="full-width padding-3" style="float:left;margin:10px;">
                    <div>
                        <input type="checkbox" name="billShipSame">
                        Is the Billing and Shipping address same?
                    </div>
                </div>
            </div>
            <div class="row-custom" data-gjs-resizable='{"tl":0,"tc":0,"tr":0,"cl":0,"cr":0,"bl":0,"br":0}'>
                <div class="ship_bill_heading full-width padding-x3">
                    <h3 class="shipping_sec">Shipping</h3>
                </div>
                <div class="form-group_div column-md-3 column-sm-6 column-xs-12" data-gjs-resizable='{"tl":0,"tc":0,"tr":0,"cl":0,"bl":0,"br":0,"bc": 0, "keyWidth": "flex-basis", "currentUnit": 1, "minDim": 1, "maxDim": 100, "step": 0.2}'>
                    <label class="mandatory">First name</label>
                    <input type="text" class="form-control-custom" name="shipFirstName">
                </div>
                <div class="form-group_div column-md-3 column-sm-6 column-xs-12" data-gjs-resizable='{"tl":0,"tc":0,"tr":0,"cl":0,"bl":0,"br":0,"bc": 0, "keyWidth": "flex-basis", "currentUnit": 1, "minDim": 1, "maxDim": 100, "step": 0.2}'>
                    <label class="mandatory">Last Name</label>
                    <input type="text" class="form-control-custom" name="shipLastName">
                </div>
                <div class="form-group_div column-xs-12 column-sm-6 column-md-6" data-gjs-resizable='{"tl":0,"tc":0,"tr":0,"cl":0,"bl":0,"br":0,"bc": 0, "keyWidth": "flex-basis", "currentUnit": 1, "minDim": 1, "maxDim": 100, "step": 0.2}'>
                    <label class="mandatory">Full Address</label>
                    <input type="text" class="form-control-custom" name="shipAddress1">
                </div>
                <div class="form-group_div column-xs-12 column-sm-6 column-md-6" data-gjs-resizable='{"tl":0,"tc":0,"tr":0,"cl":0,"bl":0,"br":0,"bc": 0, "keyWidth": "flex-basis", "currentUnit": 1, "minDim": 1, "maxDim": 100, "step": 0.2}'>
                    <label>Address line 2</label>
                    <input type="text" class="form-control-custom" name="shipAddress2">
                </div>
                <div class="form-group_div column-md-3 column-sm-6 column-xs-12" data-gjs-resizable='{"tl":0,"tc":0,"tr":0,"cl":0,"bl":0,"br":0,"bc": 0, "keyWidth": "flex-basis", "currentUnit": 1, "minDim": 1, "maxDim": 100, "step": 0.2}'>
                    <label class="mandatory">City</label>
                    <input type="text" class="form-control-custom" name="shipCity">
                </div>
                <div class="form-group_div column-md-3 column-sm-6 column-xs-12" data-gjs-resizable='{"tl":0,"tc":0,"tr":0,"cl":0,"bl":0,"br":0,"bc": 0, "keyWidth": "flex-basis", "currentUnit": 1, "minDim": 1, "maxDim": 100, "step": 0.2}'>
                    <label class="mandatory">State/Province</label>
                    <select id="shipState" name="shipState" class="form-control-custom">
                    <option value="">-- State --</option>
                </select>
                </div>
                <div class="form-group_div column-md-3 column-sm-6 column-xs-12" data-gjs-resizable='{"tl":0,"tc":0,"tr":0,"cl":0,"bl":0,"br":0,"bc": 0, "keyWidth": "flex-basis", "currentUnit": 1, "minDim": 1, "maxDim": 100, "step": 0.2}'>
                    <label class="mandatory">ZIP Code</label>
                    <input type="text" class="form-control-custom" name="shipPostalCode">
                </div>
                <div class="form-group_div column-md-3 column-sm-6 column-xs-12 " data-gjs-resizable='{"tl":0,"tc":0,"tr":0,"cl":0,"bl":0,"br":0,"bc": 0, "keyWidth": "flex-basis", "currentUnit": 1, "minDim": 1, "maxDim": 100, "step": 0.2}'>
                    <label class="mandatory">Country</label>
                    <select class="form-control-custom" name="shipCountry">
                        <option value="">-- Country -- </option>
                        <option value="IN">India</option>
                        <option value="US">USA</option>
                    </select>
                </div>
            </div>
            <div class="row-custom" data-gjs-resizable='{"tl":0,"tc":0,"tr":0,"cl":0,"cr":0,"bl":0,"br":0}'>
                <div class="ship_bill_heading full-width padding-x3">
                    <h3 class="shipping_sec">Order</h3>
                </div>
                <div class="form-group_div column-md-6 column-sm-6 column-xs-12 select">
                    <label class="mandatory">Card number</label>
                    <input type="text" class="form-control-custom" name="cardNumber">
                </div>
                <div class="form-group_div column-md-2 column-sm-6 column-xs-12">
                    <label class="mandatory">CVV</label>
                    <input type="text" class="form-control-custom" name="cardSecurityCode">
                </div>
                <div class="form-group_div column-xs-12 column-md-4 column-sm-6">
                            <label class="mandatory">Exp Month</label>
                            <select name="cardMonth" class="form-control-custom" id="cardMonth">
                                <option>Month</option>
                                <option value="01">January</option>
                                <option value="02">Feb</option>
                                <option value="03">March</option>
                                <option value="04">April</option>
                                <option value="05">May</option>
                                <option value="06">June</option>
                                <option value="07">July</option>
                                <option value="08">August</option>
                                <option value="09">September</option>
                                <option value="10">October</option>
                                <option value="11">November</option>
                                <option value="12">December</option>
                            </select>
                        </div>
                        <div class="form-group_div column-xs-12 column-md-4 column-sm-6">
                            <label class="mandatory">Exp Year</label>
                            <select name="cardYear" id="cardYear" class="form-control-custom">
                                <option>Year</option>
                                <option value="2018">2018</option>
                                <option value="2019">2019</option>
                                <option value="2020">2020</option>
                                <option value="2021">2021</option>
                                <option value="2022">2022</option>
                                <option value="2023">2023</option>
                                <option value="2024">2024</option>
                            </select>
                        </div>
            </div>
            <div class="full-width padding-x3 signup-btn text-center" data-gjs-resizable='{"tl":0,"tc":0,"tr":0,"cl":0,"bl":0,"br":0,"bc": 0, "keyWidth": "flex-basis", "currentUnit": 1, "minDim": 1, "maxDim": 100, "step": 0.2}'>
                <button class="submit-btn">SUBMIT</button>
            </div>
        </form>
    </div>
</section>`,
    paymentElement: `<style>
    body {
        padding: 0;
        margin: 0;
        font-family:sans-serif;
    }
    img {
        max-width: 100%;
    }
    .form-group_div {
        margin: 10px 0px;
    }
    .signup-btn{
        margin: 15px 0px;
    }
    .ship_bill_heading{
        background: #ffe4e4;
        padding: 5px 10px;
        margin-left:15px;
    }
    .sign_up_section{
        padding: 10px 0px;
    }
    .mandatory::after{
        color:red;
        content: "*";
        margin-left: 5px;
    }
    
    .shipping_sec,.heading-main{
        margin-top:0px;
        margin-bottom: .5rem;
        font-family: inherit;
        font-weight: 500;
        line-height: 1.2;
        color: inherit;
        font-size: 30px;
    }
    ${commonCSS}
    ${commonBootstrap}
    ${columnMd3}
    </style>
    <section class="sign_up_section">
    <div class="container-custom border">
        <h1 class="text-center heading-main">Payment Details</h1>
        <form class="full-width padding-3">
            <div class="row-custom" data-gjs-resizable='{"tl":0,"tc":0,"tr":0,"cl":0,"cr":0,"bl":0,"br":0}'>
                <div class="form-group_div column-md-3 column-sm-6 column-xs-12" data-gjs-resizable='{"tl":0,"tc":0,"tr":0,"cl":0,"bl":0,"br":0,"bc": 0, "keyWidth": "flex-basis", "currentUnit": 1, "minDim": 1, "maxDim": 100, "step": 0.2}'>
                    <label class="mandatory">First name</label>
                    <input type="text" class="form-control-custom" name="firstName">
                </div>
                <div class="form-group_div column-md-3 column-sm-6 column-xs-12" data-gjs-resizable='{"tl":0,"tc":0,"tr":0,"cl":0,"bl":0,"br":0,"bc": 0, "keyWidth": "flex-basis", "currentUnit": 1, "minDim": 1, "maxDim": 100, "step": 0.2}'>
                    <label class="mandatory">Last Name</label>
                    <input type="text" class="form-control-custom" name="lastName">
                </div>
                <div class="form-group_div column-md-3 column-sm-6 column-xs-12" data-gjs-resizable='{"tl":0,"tc":0,"tr":0,"cl":0,"bl":0,"br":0,"bc": 0, "keyWidth": "flex-basis", "currentUnit": 1, "minDim": 1, "maxDim": 100, "step": 0.2}'>
                    <label class="mandatory">Email Address</label>
                    <input type="text" class="form-control-custom" name="emailAddress">
                </div>
                <div class="form-group_div column-md-3 column-sm-6 column-xs-12" data-gjs-resizable='{"tl":0,"tc":0,"tr":0,"cl":0,"bl":0,"br":0,"bc": 0, "keyWidth": "flex-basis", "currentUnit": 1, "minDim": 1, "maxDim": 100, "step": 0.2}'>
                    <label class="mandatory">Phone Number</label>
                    <input type="text" class="form-control-custom" name="phoneNumber">
                </div>
            </div>
            <div class="row-custom" data-gjs-resizable='{"tl":0,"tc":0,"tr":0,"cl":0,"cr":0,"bl":0,"br":0}'>
                <div class="ship_bill_heading  padding-x3 full-width">
                    <h3 class="shipping_sec">Billing</h3>
                </div>
                <div class="form-group_div column-md-6 column-xs-12" data-gjs-resizable='{"tl":0,"tc":0,"tr":0,"cl":0,"bl":0,"br":0,"bc": 0, "keyWidth": "flex-basis", "currentUnit": 1, "minDim": 1, "maxDim": 100, "step": 0.2}'>
                    <label class="mandatory">Full Address</label>
                    <input type="text" class="form-control-custom" name="address1">
                </div>
                <div class="form-group_div column-md-6 column-xs-12" data-gjs-resizable='{"tl":0,"tc":0,"tr":0,"cl":0,"bl":0,"br":0,"bc": 0, "keyWidth": "flex-basis", "currentUnit": 1, "minDim": 1, "maxDim": 100, "step": 0.2}'>
                    <label>Address line 2</label>
                    <input type="text" class="form-control-custom" name="address2">
                </div>
                <div class="form-group_div column-md-3 column-sm-6 column-xs-12" data-gjs-resizable='{"tl":0,"tc":0,"tr":0,"cl":0,"bl":0,"br":0,"bc": 0, "keyWidth": "flex-basis", "currentUnit": 1, "minDim": 1, "maxDim": 100, "step": 0.2}'>
                    <label class="mandatory">City</label>
                    <input type="text" class="form-control-custom" name="city">
                </div>
                <div class="form-group_div column-md-3 column-sm-6 column-xs-12" data-gjs-resizable='{"tl":0,"tc":0,"tr":0,"cl":0,"bl":0,"br":0,"bc": 0, "keyWidth": "flex-basis", "currentUnit": 1, "minDim": 1, "maxDim": 100, "step": 0.2}'>
                    <label class="mandatory">State/Province</label>
                    <select id="state" name="state" class="form-control-custom">
                    <option value="">-- Select State --</option>
                     </select>
                </div>
                <div class="form-group_div column-md-3 column-sm-6 column-xs-12" data-gjs-resizable='{"tl":0,"tc":0,"tr":0,"cl":0,"bl":0,"br":0,"bc": 0, "keyWidth": "flex-basis", "currentUnit": 1, "minDim": 1, "maxDim": 100, "step": 0.2}'>
                    <label class="mandatory">ZIP Code</label>
                    <input type="text" class="form-control-custom" name="postalCode">
                </div>
                <div class="form-group_div column-md-3 column-sm-6 column-xs-12" data-gjs-resizable='{"tl":0,"tc":0,"tr":0,"cl":0,"bl":0,"br":0,"bc": 0, "keyWidth": "flex-basis", "currentUnit": 1, "minDim": 1, "maxDim": 100, "step": 0.2}'>
                    <label class="mandatory">Country</label>
                    <select onchange="changeCountry()" id="country" name="scountry"
                                    class="form-control-custom">
                                    <option value="">Choose Country</option>
                    </select>
                </div>
                <div class="col-xs-12 w-100" style="visibility:hidden;height:0;">
                    <div>
                        <input type="checkbox" name="billShipSame" checked>
                        Is the Billing and Shipping address same?
                    </div>
                </div>
            </div>
           
            <div class="row-custom" data-gjs-resizable='{"tl":0,"tc":0,"tr":0,"cl":0,"cr":0,"bl":0,"br":0}'>
                <div class="ship_bill_heading  padding-x3 full-width">
                    <h3 class="shipping_sec">Order</h3>
                </div>
                <div class="form-group_div column-md-6 column-xs-12 select">
                    <label class="mandatory">Card number</label>
                    <input type="text" class="form-control-custom" name="cardNumber">
                </div>
                <div class="form-group_div column-md-2 column-sm-6 column-xs-12">
                    <label class="mandatory">CVV</label>
                    <input type="text" class="form-control-custom" name="cardSecurityCode">
                </div>
                <div class="form-group_div column-md-4 column-sm-6 column-xs-12">
                            <label class="mandatory">Exp Month</label>
                            <select name="cardMonth" class="form-control-custom" id="cardMonth">
                                <option>-- Exp Month -- </option>
                                <option value="01">January</option>
                                <option value="02">Feb</option>
                                <option value="03">March</option>
                                <option value="04">April</option>
                                <option value="05">May</option>
                                <option value="06">June</option>
                                <option value="07">July</option>
                                <option value="08">August</option>
                                <option value="09">September</option>
                                <option value="10">October</option>
                                <option value="11">November</option>
                                <option value="12">December</option>
                            </select>
                        </div>
                        <div class="form-group_div column-md-4 column-sm-6 column-xs-12">
                            <label class="mandatory">Exp Year</label>
                            <select name="cardYear" id="cardYear" class="form-control-custom">
                                <option>-- Exp Year -- </option>
                                <option value="2018">2018</option>
                                <option value="2019">2019</option>
                                <option value="2020">2020</option>
                                <option value="2021">2021</option>
                                <option value="2022">2022</option>
                                <option value="2023">2023</option>
                                <option value="2024">2024</option>
                            </select>
                        </div>
            </div>
            <div class="col-xs-12  w-100 signup-btn text-center" data-gjs-resizable='{"tl":0,"tc":0,"tr":0,"cl":0,"bl":0,"br":0,"bc": 0, "keyWidth": "flex-basis", "currentUnit": 1, "minDim": 1, "maxDim": 100, "step": 0.2}'>
                <button class="submit-btn">SUBMIT</button>
            </div>
        </form>
    </div>
</section>`,
    onlyPaymentElement: `<style>
    body {
        padding: 0;
        margin: 0;
        font-family:sans-serif;
    }
    img {
        max-width: 100%;
    }
    .form-group_div {
        margin: 10px 0px;
    }
    .signup-btn{
        margin: 15px 0px;
    }
    .sign_up_section{
        background: #f4f4f4;
        padding: 10px 0px;
    }
    .mandatory::after{
        color:red;
        content: "*";
        margin-left: 5px;
    }
    .heading-main{
        margin-top:0px;
        margin-bottom: .5rem;
        font-family: inherit;
        font-weight: 500;
        line-height: 1.2;
        color: inherit;
        font-size: 30px;
    }
    ${commonCSS}
    ${commonBootstrap}
    </style>
    <section class="sign_up_section">
    <div class="container-custom border">
        <h1 class="text-center heading-main">Payment Details</h1>
        <form class="col-custom padding-x3">
            <div class="row-custom" data-gjs-resizable='{"tl":0,"tc":0,"tr":0,"cl":0,"cr":0,"bl":0,"br":0}'>
                <div class="form-group_div column-xs-12 column-md-6 column-sm-6 select">
                    <label class="mandatory">Card number</label>
                    <input type="text" class="form-control-custom" name="cardNumber">
                </div>
                <div class="form-group_div column-xs-12 column-md-2 column-sm-6">
                    <label class="mandatory">CVV</label>
                    <input type="text" class="form-control-custom" name="cardSecurityCode">
                </div>
                <div class="form-group_div column-xs-12 column-md-4 column-sm-6">
                            <label class="mandatory">Exp Month</label>
                            <select name="cardMonth" class="form-control-custom" id="cardMonth">
                                <option> Month  </option>
                                <option value="01">January</option>
                                <option value="02">Feb</option>
                                <option value="03">March</option>
                                <option value="04">April</option>
                                <option value="05">May</option>
                                <option value="06">June</option>
                                <option value="07">July</option>
                                <option value="08">August</option>
                                <option value="09">September</option>
                                <option value="10">October</option>
                                <option value="11">November</option>
                                <option value="12">December</option>
                            </select>
                        </div>
                        <div class="form-group_div column-xs-12 column-md-4 column-sm-6">
                            <label class="mandatory">Exp Year</label>
                            <select name="cardYear" id="cardYear" class="form-control-custom">
                                <option> Year  </option>
                                <option value="2018">2018</option>
                                <option value="2019">2019</option>
                                <option value="2020">2020</option>
                                <option value="2021">2021</option>
                                <option value="2022">2022</option>
                                <option value="2023">2023</option>
                                <option value="2024">2024</option>
                            </select>
                        </div>
            </div>
            <div class="full-width padding-3 padding-x3 signup-btn text-center" data-gjs-resizable='{"tl":0,"tc":0,"tr":0,"cl":0,"bl":0,"br":0,"bc": 0, "keyWidth": "flex-basis", "currentUnit": 1, "minDim": 1, "maxDim": 100, "step": 0.2}'>
                <button class="submit-btn">SUBMIT</button>
            </div>
        </form>
    </div>
</section>`,
    shopifyElement: `
    <style>
.shopify-form {
    width: 400px;
    margin: 0 auto;
    max-width: 100%;
}

.bill-ship-address-text {
    font-size: 30px;
    margin: 10px 0px;
    font-size: 500;
}
.checkout-btn{
    background-color: #76b559;
    font-size: 20px;
    color: #fff;
    padding: 5px 20px;
    box-shadow: 0px 4px 10px #76b559;
    text-shadow: 0px 1px 0px #fff;
    
}
.full-width{
    width:100%;
}
.no-margin{
    margin:0px !important;
}
.width50{
    width:50%;
    padding: 0px 15px;
    display:inline-block;
}
.no-padding-left{
    padding-left:0px;
}

.no-padding-right{
    padding-right:0px;
}
${commonCSS}
.no-padding-y{
    padding-top:0px;
    padding-bottom:0px;
}
</style>
<section class="shopify-form">
<div class="container-custom">
<div class="row-custom no-margin">
<div class="padding-3  bill-ship-address-text  full-width no-padding-y">
    Contact information
</div>
<div class="form-group_div full-width "
    data-gjs-resizable='{"tl":0,"tc":0,"tr":0,"cl":0,"bl":0,"br":0,"bc": 0, "keyWidth": "flex-basis", "currentUnit": 1, "minDim": 1, "maxDim": 100, "step": 0.2}'>
    <input type="text" name="emailAddress" class="form-control-custom" placeholder="Email *">
</div>
<div class="form-group_div full-width "
    data-gjs-resizable='{"tl":0,"tc":0,"tr":0,"cl":0,"bl":0,"br":0,"bc": 0, "keyWidth": "flex-basis", "currentUnit": 1, "minDim": 1, "maxDim": 100, "step": 0.2}'>
    <input type="text" name="phoneNumber" class="form-control-custom" placeholder="Phone *">
</div>
<div class="padding-3  bill-ship-address-text float-left full-width  no-padding-y">
    Shipping address
</div>
<div class="full-width float-left">
    <div class="form-group_div full-width "
        data-gjs-resizable='{"tl":0,"tc":0,"tr":0,"cl":0,"bl":0,"br":0,"bc": 0, "keyWidth": "flex-basis", "currentUnit": 1, "minDim": 1, "maxDim": 100, "step": 0.2}'>
        <input type="text" name="firstName" class="form-control-custom" placeholder="First Name *">
    </div>
    <div class="form-group_div full-width "
        data-gjs-resizable='{"tl":0,"tc":0,"tr":0,"cl":0,"bl":0,"br":0,"bc": 0, "keyWidth": "flex-basis", "currentUnit": 1, "minDim": 1, "maxDim": 100, "step": 0.2}'>
        <input type="text" name="lastName" class="form-control-custom" placeholder="Last Name *">
    </div>
    <div class="form-group_div full-width "
        data-gjs-resizable='{"tl":0,"tc":0,"tr":0,"cl":0,"bl":0,"br":0,"bc": 0, "keyWidth": "flex-basis", "currentUnit": 1, "minDim": 1, "maxDim": 100, "step": 0.2}'>
        <input type="text" name="address1" class="form-control-custom" placeholder="Address *">
    </div>
    <div class="form-group_div full-width "
        data-gjs-resizable='{"tl":0,"tc":0,"tr":0,"cl":0,"bl":0,"br":0,"bc": 0, "keyWidth": "flex-basis", "currentUnit": 1, "minDim": 1, "maxDim": 100, "step": 0.2}'>
        <input type="text" name="address2" class="form-control-custom" placeholder="Address 2...">
    </div>
    <div class="form-group_div full-width "
        data-gjs-resizable='{"tl":0,"tc":0,"tr":0,"cl":0,"bl":0,"br":0,"bc": 0, "keyWidth": "flex-basis", "currentUnit": 1, "minDim": 1, "maxDim": 100, "step": 0.2}'>
        <input type="text" name="city" class="form-control-custom" placeholder="City *">
    </div>
    <div class="form-group_div width50 no-padding-left">
        <select id="state" name="state" class="form-control-custom">
            <option value="">-- Choose State --</option>
        </select>
    </div>
    <div class="form-group_div width50 no-padding-right"
        data-gjs-resizable='{"tl":0,"tc":0,"tr":0,"cl":0,"bl":0,"br":0,"bc": 0, "keyWidth": "flex-basis", "currentUnit": 1, "minDim": 1, "maxDim": 100, "step": 0.2}'>
        <input type="text" class="form-control-custom" name="postalCode" placeholder="Zip *">
    </div>


</div>
<div class="w-100  mt-2 mb-2" style="visibility:hidden;height:0;">
    <input type="checkbox" checked name="billShipSame"> Is Billing address same as Shipping address?
</div>
<div class="padding-3  bill-ship-address-text full-width no-padding-y">
    Card Details
</div>
<div class="form-group_div full-width">
    <input type="text" name="cardNumber" class="form-control-custom full-width" placeholder="Card Number">
</div>

<div class="form-group_div width50 no-padding-left">
    <select name="cardMonth" class="form-control-custom" id="cardMonth">
        <option>Month</option>
        <option value="01">January</option>
        <option value="02">Feb</option>
        <option value="03">March</option>
        <option value="04">April</option>
        <option value="05">May</option>
        <option value="06">June</option>
        <option value="07">July</option>
        <option value="08">August</option>
        <option value="09">September</option>
        <option value="10">October</option>
        <option value="11">November</option>
        <option value="12">December</option>
    </select>
</div>
<div class="form-group_div width50 no-padding-right">
    <select name="cardYear" id="cardYear" class="form-control-custom">
        <option>Year</option>
        <option value="2018">2018</option>
        <option value="2019">2019</option>
        <option value="2020">2020</option>
        <option value="2021">2021</option>
        <option value="2022">2022</option>
        <option value="2023">2023</option>
        <option value="2024">2024</option>
    </select>
</div>
<div class="form-group_div width50">
        <input type="text" name="cardSecurityCode" class="form-control-custom" placeholder="CVV">
    </div>
<div class="full-width  no-padding-left text-center">
    <button class="submit-btn checkout-btn">Checkout</button>
</div>

</div>
</div>
</section>`,
    multiProductVariableImages: ` 
<style>    
.product-radio-list {
list-style: none;
}
.main_container {
padding: 10px 0px;
font-family:'-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol"';
}
.heading-of-item{
    float-left;
    width:100%;
    border-bottom:1px solid #ccc;
}
.container-custom{
    width: 1400px;
    max-width: 100%;
    margin: 0 auto;
}
.row-custom{
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    margin-right: -15px;
    margin-left: -15px;
}
.no-margin{
    margin:0px;
}
.text-right{
    text-align:right;
}
.d-block-custom{
    display:block;
}
.d-flex-custom{
    display:flex;
    align-items:center;
}
.d-inline-custom{
    display:inline;
}
.width50{
    width:50%;
    padding: 15px;
    display:inline-block;
}
.full-width{
    width:100%;
}
.padding-x3{
    padding:0px 16px;
}
.column-md-8{
    max-width:66.666%;
    position: relative;
    width: 100%;
    min-height: 1px;
    padding-right: 15px;
    padding-left: 15px;
}
.column-md-2{
    max-width:16.666%;
    position: relative;
    width: 100%;
    min-height: 1px;
    padding-right: 15px;
    padding-left: 15px;
}
.column-md-10{
    max-width:83.33%;
    position: relative;
    width: 100%;
    min-height: 1px;
    padding-right: 15px;
    padding-left: 15px;
}
.column-md-4{
    max-width:33.33%;
    position: relative;
    width: 100%;
    min-height: 1px;
    padding-right: 15px;
    padding-left: 15px;
}
.multi-product-variable-image{
    width:450px;
}
.no-padding{
    padding:0px;
}
.product-list{
    padding:10px 0px;
}
#productTitle {
    margin:0px;
    font-weight:normal;
    font-size: 2.5rem;
}
</style>
<section class="main_container ">
<div class="container-custom">
<div class="row-custom no-margin form_div">
    <div class="multi-product-variable-image">
        <div class="padding-x3 heading-of-item"><span class="width50 padding-x3">Item</span><span
                class="width50 padding-x3 text-right">price</span></div>
        <ul class="d-block-custom container-custom full-width no-padding product-radio-list">
            <li class="row-custom no-margin product-list" data-gjs-type="newLi" data-gjs-copyable="false"> 
              <div class="column-md-8">
                    <div class="d-flex-custom align-items-center">
                    <div class="column-md-2"  >
                    <div class="d-inline-custom" id="first-product" onclick="changeProduct('first-product')">
                    <input type="radio" id="first-product" data-gjs-type="radio" name="product" value="buy4-get3"/></div>
                    </div>
                        <div class="column-md-10">
                            <div>Best Deal</div>
                            <div>Buy 4 Keto & Get 3 Free
                            </div>
                        </div>
                    </div>
                </div>
                <div class="column-md-4  text-right">$39.99 each</div>
            </li>
            <li class="row-custom no-margin product-list" data-gjs-type="newLi" data-gjs-copyable="false"> 
                <div class="column-md-8">
                    <div class="d-flex-custom align-items-center">
                    <div class="column-md-2"  >
                    <div class="d-inline-custom" id="second-product" onclick="changeProduct('second-product')">
                    <input type="radio"  name="product" data-gjs-type="radio" id="second-product" value="buy3-get2"/></div>
                    </div>
                        <div class="column-md-10">
                            <div>Buy 3 Keto & Get 2 Free
                            </div>
                        </div>
                    </div>
                </div>
                 <div class="column-md-4  text-right">$39.99 each</div>
            </li>
            <li class="row-custom no-margin product-list" data-gjs-type="newLi" data-gjs-copyable="false"> 
                <div class="column-md-8">
                    <div class="d-flex-custom align-items-center">
                    <div class="column-md-2"  >
                    <div class="d-inline-custom" id="third-product" onclick="changeProduct('third-product')">
                    <input type="radio" name="product" data-gjs-type="radio" id="third-product" value="buy2-get1" /></div>
                    </div>   
                    <div class="column-md-10">
                            <div>Buy 2 Keto & Get 1 Free
                            </div>
                        </div>
                    </div>
                </div>
                 <div class="column-md-4  text-right">$39.99 each</div>
            </li>
            <li class="row-custom no-margin product-list" data-gjs-type="newLi" data-gjs-copyable="false"> 
                <div class="column-md-8">
                    <div class="d-flex-custom align-items-center">
                    <div class="column-md-2"  >
                    <div class="d-inline-custom" id="fourth-product" onclick="changeProduct('fourth-product')">
                    <input type="radio" name="product" data-gjs-type="radio" id="fourth-product" value="buy1-get0" /></div>
                    </div>
                        <div class="column-md-10">
                            <div>Buy 1
                            </div>
                        </div>
                    </div>
                </div>
                 <div class="column-md-4  text-right">$39.99 each</div>
            </li>
        </ul>
        <div class="productContainer" id="productContainer" data-gjs-type="productContainer">
            <div id="first-product-image" class="product-images-list" style="display:none">
                <h1 id="productTitle">Buy 4 Keto & Get 3 Free</h1>
                <img id="productImage" class="full-width" data-gjs-removable="false"
                    src="https://templates.funnelkonnekt.com/37a14ff8-1846-47e0-a72d-116fbf7fdcd0/product_image.png"
                    title="">
            </div>
            <div id="second-product-image" class="product-images-list" style="display:none">
                <h1 id="productTitle">Buy 3 Keto & Get 2 Free</h1>
                <img id="productImage" class="full-width" data-gjs-removable="false"
                    src="https://templates.funnelkonnekt.com/31a26ade-35f0-4c43-9b98-f730d58ad1c1/logo.png"
                    title="">
            </div>
            <div id="third-product-image" class="product-images-list" style="display:none">
                <h1 id="productTitle">Buy 2 Keto & Get 1 Free</h1>
                <img id="productImage" class="full-width" data-gjs-removable="false"
                    src="https://templates.funnelkonnekt.com/37a14ff8-1846-47e0-a72d-116fbf7fdcd0/product_image.png"
                    title="">
            </div>
            <div id="fourth-product-image" class="product-images-list" style="display:none">
                <h1 id="productTitle">Buy 1 Keto @ $20</h1>
                <img id="productImage" class="full-width" data-gjs-removable="false"
                    src="https://templates.funnelkonnekt.com/31a26ade-35f0-4c43-9b98-f730d58ad1c1/logo.png"
                    title="">
            </div>
        </div>
        
    </div>
</div>
</div>
</section>

<script>
$(document).ready(function () {
    $('.product-images-list').hide();
    const inputSelector = 'input[type="radio"][name="product"]:checked';
    if($(inputSelector).length){
        const activeId = $(inputSelector).parent('div').attr('id');
        $('#'+activeId+'-image').show();
    }else{
        $('#first-product input').attr('checked',true);
        $('#first-product-image').show();
    } 
    
});
function changeProduct(id){
    $('.product-images-list').hide();
    $('#'+id+'-image').show();
}
</script>
`,
    orderCostDetails: `
<style>
.container-custom{
    width: 1400px;
    max-width: 100%;
    margin: 0 auto;
    font-family:'sans-serif';
}
.text-center{
    text-align:center;
}
.list-of-items{
    padding:15px;
}
.full-width{
    width:100%;
}
</style>
<section>
<div class="container-custom">
    <div class="main-heading text-center">
        <h1>Thank you for your Order!!!!</h1>
        <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Dolores pariatur sint amet numquam, vitae
            nemo
            ipsum fuga blanditiis quis repudiandae ipsa, sequi rerum corrupti aspernatur, non itaque praesentium
            incidunt iure!</p>
    </div>
    <div class="items-ordered">
        <div class="list-of-items p-2" style="border:1px solid #277098;border-radius:10px;">
            <table class="full-width">
                <thead>
                    <tr style="background:#277098;color:#fff;">
                        <th colspan="3" style="padding:8px;">Items Ordered</th>
                    </tr>
                    <tr>
                        <th style="padding:10px 0px;">Item</th>
                        <th style="padding:10px 0px;">Quantity</th>
                        <th style="text-align:right;padding:10px 0px;">Price</th>
                    </tr>
                </thead>
                <tbody id="items-list">
                </tbody>
            </table>
        </div>
    </div>
    
</div>
</section>
`,
    billingDetails: `
    <style>
        .billing-info{
            border: 1px solid #277098;
            padding: 10px;
            border-radius: 10px;
            margin: 20px 0px;
        }

        .billing-info .heading{
            background: #277098;
            color: #fff;
            padding: 8px;
            margin-bottom: 20px;
        }
        ${invoiceStyles}
    </style>
    <section>
    <div class="container-custom">    
    <div class="billing-info">
        <div class="heading">Billing Information</div>
        <div class="row-custom padding-x3">
            <div class="form-group column-md-4 col-sm-6 col-xs-12">
                <label class="label_name">First Name</label>
                <label class="label_value" id="firstName"></label>
            </div>
            <div class="form-group column-md-4 col-sm-6 col-xs-12">
                <label class="label_name">Last Name</label>
                <label class="label_value" id="lastName"></label>
            </div>
            <div class="form-group column-md-4 col-sm-6 col-xs-12">
                <label class="label_name">Full Address</label>
                <label class="label_value" id="address1"></label>
            </div>
            <div class="form-group column-md-4 col-sm-6 col-xs-12">
                <label class="label_name">Address line 2</label>
                <label class="label_value" id="address2"></label>
            </div>
            <div class="form-group column-md-4 col-sm-6 col-xs-12">
                <label class="label_name">City</label>
                <label class="label_value" id="city"></label>
            </div>
            <div class="form-group column-md-4 col-sm-6 col-xs-12">
                <label class="label_name">ZIP Code</label>
                <label class="label_value " id="postalCode"></label>
            </div>
            <div class="form-group column-md-4 col-sm-6 col-xs-12">
                <label class="label_name">State/Province</label>
                <label class="label_value " id="state-field"></label>
            </div>
            <div class="form-group column-md-4 col-sm-6 col-xs-12">
                <label class="label_name">Country</label>
                <label class="label_value" id="country-field"></label>
            </div>
        </div>
    </div>
</div>
</section>
    `,
    shippingDetails: `
    <style>
   
    .shipping-info {
        border: 1px solid #277098;
        padding: 10px;
        border-radius: 10px;
        margin: 20px 0px;
    }
    .shipping-info .heading {
        background: #277098;
        color: #fff;
        padding: 8px;
        margin-bottom: 20px;
    }
    ${invoiceStyles}
    </style>
        <section>
        <div class="container-custom">
        <div class="shipping-info">
            <div class="heading">Shipping Information</div>
            <div class="row-custom padding-x3">
                <div class="form-group column-md-4 column-sm-6 column-xs-12">
                    <label class="label_name">First Name</label>
                    <label class="label_value" id="shipFirstName"></label>
                </div>
                <div class="form-group column-md-4 column-sm-6 column-xs-12">
                    <label class="label_name">Last Name</label>
                    <label class="label_value" id="shipLastName"></label>
                </div>
                <div class="form-group column-md-4 column-sm-6 column-xs-12">
                    <label class="label_name">Full Address</label>
                    <label class="label_value" id="shipAddress1"></label>
                </div>
                <div class="form-group column-md-4 column-sm-6 column-xs-12">
                    <label class="label_name">Address line 2</label>
                    <label class="label_value" id="shipAddress2"></label>
                </div>
                <div class="form-group column-md-4 column-sm-6 column-xs-12">
                    <label class="label_name">City</label>
                    <label class="label_value" id="shipCity"></label>
                </div>
                <div class="form-group column-md-4 column-sm-6 column-xs-12">
                    <label class="label_name">ZIP Code</label>
                    <label class="label_value" id="shipPostalCode"></label>
                </div>
                <div class="form-group column-md-4 column-sm-6 column-xs-12">
                    <label class="label_name">State/Province</label>
                    <label class="label_value " id="shipState-field"></label>
                </div>
                <div class="form-group column-md-4 column-sm-6 column-xs-12">
                    <label class="label_name">Country</label>
                    <label class="label_value " id="shipCountry-field"></label>
                </div>
            </div>
        </div>
        </div>
        </section>
        `,
    memberLoginForm: `
    <style>
    .login-form{
        font-family: '-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol"';
        width: 400px;
        max-width: 100%;
        margin: 0 auto;
        background: #f4f4f4;
        border-radius: 20px;
        box-shadow: 10px 10px 10px #2f404f5c;
        line-height: 1.5;
    }
    .label-name{
        font-weight: 500;
        font-size: 18px;
        display: inline-block;
        margin-bottom: .5rem;
    }
    .btn{
        display: inline-block;
        font-weight: 400;
        text-align: center;
        white-space: nowrap;
        vertical-align: middle;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
        border: 1px solid transparent;
        padding: .375rem .75rem;
        font-size: 1rem;
        line-height: 1.5;
        border-radius: .25rem;
        transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
    }
    .btn-login{
        background-color: #11b495;
        color: #fff;
    }
    .btn-signup{
        background-color: #2f404f;
        color: #fff;
    }
    .border-design{
        width: 100%;
        border-top: 1px solid #ccc;
        position: relative;
        top: -20px;
        z-index: 9;
    }
    .new-member{
        position: relative;
        z-index: 9999;
        background: #f4f4f4;
        padding: 0px 20px;
        font-size: 24px;
    }
    .header-page{
        padding: 30px;
        margin: 0 !important;
        background: #2f4050;
        color: #fff;
        font-size: 2rem;
        font-weight:700;
    }
    .login-details{
        padding: 40px;
    }
    .input-group-custom{
        position: relative;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: stretch;
        -ms-flex-align: stretch;
        align-items: stretch;
        width: 100%;
    }
    .form-group {
        margin-bottom: 1rem;
    }
    .form-control-custom{
        display: block;
        width: 100%;
        padding: .375rem .75rem;
        font-size: 1rem;
        line-height: 1.5;
        color: #495057;
        background-color: #fff;
        background-clip: padding-box;
        border: 1px solid #ced4da;
        border-radius: .25rem;
        transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
    }
    .full-width{
        width:100%;
    }
    .text-center{
        text-align:center;
    }
    .input-group-text {
        padding: .375rem .75rem;
        font-size: 1rem;
        line-height: 1.5;
        border: 1px solid #ced4da;
        border-radius: .25rem;
    }
    .float-right-custom{
        float:right;
    }
    .margin-b3{
        margin-bottom:15px;
    }
    .reset-password{
        cursor:pointer;
        color:blue !important;
    }
</style>
<section >       
    <div class="container-custom">
        <div class="login-form" data-gjs-removable="false">
            <h2 class="header-page">Club Name Here</h2>
            <div class="login-details" data-gjs-removable="false">
                <div class="form-group" data-gjs-removable="false" data-gjs-copyable="false">
                    <label class="label-name">User name</label>
                    <div class="input-group-custom margin-b3" data-gjs-removable="false" data-gjs-copyable="false">
                        <div class="input-group-prepend" data-gjs-removable="false" data-gjs-copyable="false">
                            <span class="input-group-text fa fa-user"></span>
                        </div>
                        <input type="text" data-gjs-removable="false" data-gjs-copyable="false" name="userName" class="form-control-custom" >
                    </div>
                </div>
                <div class="form-group" data-gjs-removable="false" data-gjs-copyable="false">
                        <label class="password_label full-width"><span class="label-name">Password</span><a data-gjs-type="reset-password" class="float-right-custom reset-password"> I forgot my password</a></label>
                    <div class="input-group-custom margin-b3" data-gjs-removable="false" data-gjs-copyable="false">
                        <div class="input-group-prepend" data-gjs-removable="false" data-gjs-copyable="false">
                            <span class="input-group-text fa fa-key"></span>
                        </div>
                        <input type="password" data-gjs-removable="false" data-gjs-copyable="false" name="password" class="form-control-custom" >
                    </div>
                </div>
                <div class="form-group">
                    <button class="btn btn-login text-center full-width" data-gjs-removable="false" data-gjs-copyable="false" >Login</button>
                </div>
                <div class="form-group text-center">
                    <label class="new-member">Not A Member?</label>
                    <div class="border-design"></div>
                </div>
                <div class="form-group">
                        <a class="btn btn-signup text-center full-width" style="color:#fff;">Create a new account</a>
                </div>
            </div>
        </div>
    </div>
</section>
    `,
    passwordElement: `
        <style>
        .border-design{
            width: 100%;
            border-top: 1px solid #ccc;
            position: relative;
            top: -20px;
            z-index: 9;
        }
        .error-message{
            color:red;
        }
        .form-control-custom{
            display: block;
            width: 100%;
            height:32px;
            padding: .375rem .75rem;
            font-size: 1rem;
            line-height: 1.5;
            color: #495057;
            background-color: #fff;
            background-clip: padding-box;
            border: 1px solid #ced4da;
            border-radius: .25rem;
            transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
        }
        .full-width{
            width:100%;
        }
        .form-group {
            margin-bottom: 1rem;
        }
        .container-custom{
            width: 1400px;
            max-width: 100%;
            margin: 0 auto;
            font-family:'sans-serif';
        }
        .row-custom{
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            -ms-flex-wrap: wrap;
            flex-wrap: wrap;
        }
        .text-center{
            text-align:center;
        }
        .update-details{
            position: relative;
            z-index: 9999;
            background: #f4f4f4;
            padding: 0px 20px;
            font-size: 20px;
        }
        .input-group-custom{
            position: relative;
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            -webkit-box-align: stretch;
            -ms-flex-align: stretch;
            align-items: stretch;
            width: 100%;
        }
        .input-group-text {
            padding: .375rem .75rem;
            font-size: 1rem;
            line-height: 1.5;
            border: 1px solid #ced4da;
            border-radius: .25rem;
        }
        .padding-x3{
            padding:0px 16px;
        }
        .margin-b3{
            margin-bottom:15px;
        }
        .btn-submit {
            background-color: #11b495;
            color: #fff;
            display: inline-block;
            font-weight: 400;
            text-align: center;
            white-space: nowrap;
            vertical-align: middle;
            -webkit-user-select: none;
            -moz-user-select: none;
            -ms-user-select: none;
            user-select: none;
            border: 1px solid transparent;
            padding: .375rem .75rem;
            font-size: 1rem;
            line-height: 1.5;
            border-radius: .25rem;
            transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
        }
        @media(min-width:991px){
            .column-lg-4, .column-md-4{
                width:33.333%;
                padding:0px 15px;
                -webkit-box-flex: 0;
                -ms-flex: 0 0 33.333333%;
                flex: 0 0 33.333333%;
                max-width: 33.333333%;
            }
            .column-lg-8, .column-md-8{
                width:66.666%;
                padding:0px 15px;
                -webkit-box-flex: 0;
                -ms-flex: 0 0 66.666%;
                flex: 0 0 66.666%;
                max-width: 66.666%;
            }
        }
        @media(max-width:990px){
            .column-xs-12, .column-sm-12{
                width:100%;
                padding:0px 15px;
            }
        }
        </style>
        <div class="form-group full-width text-center">
            <label class="update-details">Change Password</label>
            <div class="border-design padding-x3"></div>
        </div>
        <div class="container-custom">
        <div class="row-custom">
        <div class="form-group full-width no-padding-left">
            <div class="input-group-custom margin-b3 ">
                <label class="column-lg-4 column-md-4 column-xs-12 column-sm-12">New Password</label>
                <input type="password" name="newPassword"  class="form-control-custom col-lg-8 col-md-8 col-xs-12 col-sm-12" placeholder="">
            </div>
        </div>
        <div class="form-group full-width no-padding-left">
            <div class="input-group-custom margin-b3 ">
                <label class="column-lg-4 column-md-4 column-xs-12 column-sm-12">Confirm Password</label>
                <input type="password" name="confirmPassword" class="form-control-custom column-lg-8 column-md-8 column-xs-12 column-sm-12" placeholder="">
            </div>
        </div>
        <div class="full-width padding-x3 text-center" id="validationMessage">
                <p class="error-message">New password and Confirm Password should match</p>
        </div>
        <div class="form-group full-width">
            <button class="btn btn-submit text-center  full-width">Submit</button>
        </div>
    </div>
        </div>
        <script>
        $(document).ready(function () {
            $('#validationMessage').hide();
        });
    </script>
    `,
    memberProfile: `
    <style>
        .login-background {
            background-color: #11b495;
            padding-top: 3%;
            padding-bottom: 3%;
            position: absolute;
            top: 0;
            height:100%;
            bottom: 0;
            width: 100%;
            overflow: auto;
        }
        .login-form {
            width: 600px;
            max-width: 100%;
            margin: 0 auto;
            background: #f4f4f4;
            border-radius: 20px;
            box-shadow: 10px 10px 10px #2f404f5c;
        }
        .btn-submit {
            background-color: #11b495;
            color: #fff;
            display: inline-block;
            font-weight: 400;
            text-align: center;
            white-space: nowrap;
            vertical-align: middle;
            -webkit-user-select: none;
            -moz-user-select: none;
            -ms-user-select: none;
            user-select: none;
            border: 1px solid transparent;
            padding: .375rem .75rem;
            font-size: 1rem;
            line-height: 1.5;
            border-radius: .25rem;
            transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
        }
        .header-page{
            padding: 30px;
            margin: 0 !important;
            background: #2f4050;
            color: #fff;
        }
        .login-details{
            padding: 40px;
        }
        .border-design{
            width: 100%;
            border-top: 1px solid #ccc;
            position: relative;
            top: -20px;
            z-index: 9;
        }
        .update-details{
            position: relative;
            z-index: 9999;
            background: #f4f4f4;
            padding: 0px 20px;
            font-size: 20px;
        }                     
        .form-control-custom{
            display: block;
            width: 100%;
            height:32px;
            padding: .375rem .75rem;
            font-size: 1rem;
            line-height: 1.5;
            color: #495057;
            background-color: #fff;
            background-clip: padding-box;
            border: 1px solid #ced4da;
            border-radius: .25rem;
            transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
        }
        .full-width{
            width:100%;
        }
        .form-group {
            margin-bottom: 1rem;
        }
        .container-custom{
            width: 1400px;
            max-width: 100%;
            margin: 0 auto;
            font-family:'sans-serif';
        }
        .row-custom{
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            -ms-flex-wrap: wrap;
            flex-wrap: wrap;
        }
        .text-center{
            text-align:center;
        }
        .padding-x3{
            padding:0px 16px;
        }
        .input-group {
            position: relative;
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            -ms-flex-wrap: wrap;
            flex-wrap: wrap;
            -webkit-box-align: stretch;
            -ms-flex-align: stretch;
            align-items: stretch;
            width: 100%;
        }
        .margin-b3{
            margin-bottom:15px;
        }
        @media(min-width:991px){
            .column-lg-6, .column-md-6{
                width:50%;
                padding:0px 15px;
                -webkit-box-flex: 0;
                -ms-flex: 0 0 50%;
                flex: 0 0 50%;
                max-width: 50%;
            }
        }
        @media(max-width:990px){
            .column-xs-12{
                width:100%;
                padding:0px 15px;
            }
        }
    </style>
    <section class="login-background">
                    <div class="container-custom">
                        <div class="login-form">
                            <h2 class="header-page">Member Profile</h2>
                            <div class="row-custom flex-column login-details">
                                <div class="form-group full-width text-center">
                                    <label class="update-details">Update Details</label>
                                    <div class="border-design"></div>
                                </div>
                                <div class="row-custom">
                                    <div class="form-group column-lg-6 column-md-6 column-xs-12 no-padding-left">
                                        <div class="input-group margin-b3 ">
                                            <input type="text" class="form-control-custom" name="emailAddress" placeholder="Email">
                                        </div>
                                    </div> 
                                    <div class="form-group column-lg-6 column-md-6 column-xs-12 no-padding-left">
                                        <div class="input-group margin-b3 ">
                                            <input type="text" class="form-control-custom" name="phoneNumber"  placeholder="Phone">
                                        </div>
                                    </div>
                                </div>
                                
                                <div class="form-group full-width padding-x3">
                                    <div class="input-group margin-b3 ">
                                        <input type="text" class="form-control-custom" name="address1" placeholder="Address 1">
                                    </div>
                                </div>
                                <div class="form-group full-width padding-x3">
                                    <div class="input-group margin-b3 ">
                                        <input type="text" class="form-control-custom" name="address2" placeholder="Address 2">
                                    </div>
                                </div>
                                <div class="row-custom">
                                    <div class="form-group column-lg-6 column-md-6 column-xs-12 no-padding-left">
                                            <div class="input-group margin-b3 ">
                                                <input type="text" class="form-control-custom" name="city" placeholder="City">
                                            </div>
                                    </div>
                                    <div class="form-group column-lg-6 column-md-6 column-xs-12 no-padding-left">
                                            <div class="input-group margin-b3 ">
                                                <select id="state" name="state" class="form-control-custom">
                                                    <option value="">Select State</option>
                                                </select>
                                            </div>
                                    </div>
                                    <div class="form-group column-lg-6 column-md-6 column-xs-12 no-padding-left">
                                            <div class="input-group margin-b3 ">
                                                <select onchange="changeCountry()" id="country" name="country" class="form-control-custom">
                                                        <option value="">Choose Country</option>
                                                </select>
                                            </div>
                                    </div>
                                    <div class="form-group column-lg-6 column-md-6 column-xs-12 no-padding-left">
                                            <div class="input-group margin-b3 ">
                                                <input type="text" class="form-control-custom" name="postalCode" placeholder="Postal Code">
                                            </div>
                                    </div>
                                </div>
                                <div class="form-group full-width">
                                    <button class="btn btn-submit text-center  full-width">Submit</button>
                                </div>
                            </div>
                            
                        </div>
                    </div>
            </section>
    `,
    resetPassword: `
        <style>
        .align-right{
            float:right;
        }
        .reset-password{
            cursor:pointer;
            color:blue !important;
        }
        </style>        
        <a class="reset-password align-right" data-gjs-type="reset-password">Reset Password</a>        
    `,
    lightThemeCss: `
    #gjs-rte-toolbar, .gjs-bg-main, .gjs-clm-select option, .gjs-clm-tags .gjs-sm-colorp-c, .gjs-editor, .gjs-mdl-dialog, .gjs-nv-item .gjs-nv-title-c, .gjs-off-prv, .gjs-pn-panel, .gjs-select option, .gjs-sm-sector .gjs-sm-colorp-c, .gjs-sm-select option, .gjs-sm-unit option, .sp-container, .gjs-block {
        background-color: #fafafa;
    }
    #gjs-rte-toolbar .gjs-rte-btn, .gjs-btn-prim, .gjs-btnt, .gjs-clm-tags .gjs-sm-composite.gjs-clm-field, .gjs-clm-tags .gjs-sm-field.gjs-sm-composite, .gjs-clm-tags .gjs-sm-stack #gjs-sm-add, .gjs-color-main, .gjs-mdl-dialog, .gjs-off-prv, .gjs-pn-btn, .gjs-pn-panel, .gjs-sm-sector .gjs-sm-composite.gjs-clm-field, .gjs-sm-sector .gjs-sm-field.gjs-sm-composite, .gjs-sm-sector .gjs-sm-stack #gjs-sm-add,.gjs-two-color {
        color: #666;
    }
    .gjs-btnt.gjs-pn-active, .gjs-color-active, .gjs-pn-btn.gjs-pn-active, .gjs-pn-btn:active, .gjs-block:hover {
        color: #666;
    }
    .gjs-btnt.gjs-pn-active, .gjs-pn-btn.gjs-pn-active {
        background-color: rgba(0, 0, 0, 0.03);
    }
    .gjs-one-bg,body{
        background-color: #fafafa !important;
    }
    .gjs-clm-tags #gjs-clm-label, .gjs-clm-tags #gjs-clm-sel,.gjs-sm-label,.gjs-label,.gjs-layer-title,.gjs-layer,.gjs-block-categories{
        color: #666;
    }
    .gjs-pn-btn{
        color: #666;
    }
    .gjs-clm-tags #gjs-clm-tag-label{
        color:#fff;
    }
   `,
    totalAmmountCalc: `<div>
        <h1 >Total Amount : $<label id="totalAmountIs" >0.0</span</h1> 
    `,
    shippingPriceCalc: `<div>
    <h1 >Shipping Charges : $<label id="shippingCharges" >0.0</span</h1>
    `,
    downloadURL: `
        <style>
            ${btnStyle}
        </style>
        <div>
            <h1>Add Document links to Download</h1>
            <p>The below is the document link for more details about product, Click on the below button
                and download</p>
            <button class="btn btn-warning" data-gjs-type="download-url">Download</button>
        </div>
    `,
    previewURL: `
        <style>
            ${btnStyle}
        </style>
        <div>
        <h1>Add Document links to preview</h1>
        <p>The below is the document link for more details about product, Click on the below
            button and download</p>
        <button class="btn btn-warning" data-gjs-type="preview-url">Preview</button>
        </div>
    `,
    logOutBtn: `
        <style>
        .logout-btn{
            background: transparent;
            border: 0;
            color: #333;
        }
        </style>
        <button class="logout-btn" onclick="clubLogout()">Logout</button>
    `
};
