import React, { Component } from "react";
import PropTypes from "prop-types";
import { Multiselect } from "react-widgets";
import constants from "../utilities/constants";
export default class MembershipProductList extends Component {
    static propTypes = {
        funnel: PropTypes.object,
        pageData: PropTypes.object,
        savePageViewData: PropTypes.func,
        loadButtons: PropTypes.func,
        closeRightPanel: PropTypes.func,
        currentPage: PropTypes.object
    }
    constructor(props, context) {
        super(props, context);
        this.state = {
            muliSelectProductValue: [],
        };
    }
    componentWillReceiveProps(nextProps) {
        const { pageData } = this.props;
        if (pageData !== nextProps.pageData) {
            this.setState({ muliSelectProductValue: nextProps.pageData && nextProps.pageData.jsPageData && nextProps.pageData.jsPageData.muliSelectProductValueData });
        }
    }
    joinWhiteSpace = (item) => item.family.split(" ").join("+");
    onSelectButton = () => {
        const { muliSelectProductValue } = this.state;
        const { funnel, currentPage } = this.props;
        const muliSelectProductValueData = muliSelectProductValue.map((item) => {
            return item;
        });
        this.props.closeRightPanel();
        this.props.savePageViewData({
            muliSelectProductValueData,
            currentPage,
            funnel
        });
    }
    render() {
        const { funnel, pageData } = this.props;
        const { muliSelectProductValue } = this.state;
        let multiSelectRender;
        const offers = funnel.offer ? JSON.parse(funnel.offer) : "";
        const productOptions = offers && offers.map((product) => {
            return { value: product, text: product.text };
        });
        multiSelectRender = <Multiselect
            data={productOptions}
            textField='text'
            placeholder={constants.productsPlaceholder}
            value={muliSelectProductValue}
            onChange={value => this.setState({ muliSelectProductValue: value })}
        />;
        const isButtonEnabled = (this.state.muliSelectProductValue && this.state.muliSelectProductValue.length !== 0);
        const saveButtonClass = isButtonEnabled
            ? "btn btn-primary m-t-sm"
            : "btn btn-primary m-t-sm disabled";
        return (
            <>
                <div className="form-group font-list">
                    <label>{constants.productsLabel}</label>
                    <h6 className="share-note"><i>{constants.productsNote}</i></h6>
                    {multiSelectRender}
                    <div className="text-right">
                        {<button onClick={isButtonEnabled ? this.onSelectButton : () => null} className={saveButtonClass}>Save Products</button>}
                    </div>
                </div>
            </>
        );
    }
}