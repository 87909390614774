import { getDomWrapper } from "../../utils/component";

const addStickybarComponentToPage = (editor) => {
    let wrapper = getDomWrapper(editor);
    if (wrapper.view.$el.find("#stickyBarCustom").length === 0) {
        editor.DomComponents.addComponent({
            tagName: "div",
            type: "stickybar",
            removable: false,
            draggable: false,
            droppable: false,
            badgable: true,
            copyable: false,
            layerable: true,
            style: { "box-shadow": "0px 2px 20px 3px #333","width": "100%","background-color": "#3e5065","padding": "10px 0px","height": "auto","position": "fixed","z-index": "99999","display": "none" },
            attributes: { id: "stickyBarCustom",title: "Stickybar", name: "Stickybar", style: "display:none" },
            content: `
                <style>
                .placeTop{
                    top:0;
                    bottom:inherit;
                }
                .placeBottom{
                    top:inherit;
                    bottom:0;
                }
                .placeCenter{
                    top:30%;
                    bottom:inherit;
                }
                .placeLeft{
                    left:0;
                    right:inherit;
                }
                .placeRight{
                    right:0;
                    left:inherit;
                }
                .backgroundCover{
                    background-size: cover !important;
                    -webkit-background-size: cover !important;
                    background-attachment: fixed !important;
                    background-repeat: repeat repeat !important;
                    background-position: center center;
                }
                .backgroundW100 {
                    background-size: 100% auto !important;
                    -webkit-background-size: 100% auto !important;
                    background-repeat: no-repeat !important;
                }
                .backgroundWH100 {
                    background-size: 100% 100% !important;
                    -webkit-background-size: 100% 100% !important;
                    background-repeat: no-repeat !important;
                }
                .bgNoRepeat {
                    background-repeat: no-repeat !important;
                }
                .bgRepeat {
                    background-repeat: repeat !important;
                }
                .bgRepeatX {
                    background-repeat: repeat-x !important;
                }
                .bgRepeatY {
                    background-repeat: repeat-y !important;
                }
                .bgRepeatXTop {
                    background-repeat: repeat-x !important;
                    background-position: top !important;
                }
                .bgRepeatXBottom {
                    background-repeat: repeat-x !important;
                    background-position: bottom !important;
                }    
                .borderRadiusTop {
                    border-bottom-left-radius: 0 !important;
                    border-bottom-right-radius: 0 !important;
                }   
                .borderRadiusBottom {
                    border-top-left-radius: 0 !important;
                    border-top-right-radius: 0 !important;
                }     
              
                .stickybar_content{
                    margin-left : auto;
                    margin-right : auto;
                    padding:5px;
                }
                .sticky_popup{
                    position: relative;
                    top: -10px;
                    color: #fff;
                    padding: 5px 6px;
                    border: 2px solid #fff;
                    border-radius:50%;
                }
                </style>
            `,
            components: `<div class="stickybar_content" style="margin-top:0px;" data-gjs-copyable="false" data-gjs-editable="false" data-gjs-removable="false"  id="stickybar_content"><span class="sticky_popup fa fa-times float-right" onclick="closePopup('stickyBarCustom','id')"></span>
            <div class="container">
            <div class="row justify-content-center flex-wrap align-items-center">
                <div class="col-lg-4 col-md-4 col-sm-12 col-xs-12">
                  <img class="col-lg-4 col-md-4 col-sm-6 col-xs-12 float-left" style="width:100px;" src="https://templates.funnelkonnekt.com/7a5d9c91-cdbc-4b5c-b95a-99d176afe003/shoe_image.png"/> 
                  <div class="col-lg-8 col-md-8 col-sm-6 col-xs-12 float-left mt-3" style="white-space:nowrap">
                        <h3>Buy This Product</h3>
                        <p class="text-center">$1.00</p>
                  </div>
                </div>
                <div class="col-lg-4 col-md-4 col-sm-12 col-xs-12"><button class="btn btn-success">Add to cart</button></div>
            </div>
        </div>
          </div>`
        });
    }
};

const customStickyBar = (editor) => {
    const wrapper = getDomWrapper(editor);
    wrapper.view.$el.find("#stickyBarCustom").length === 0 ? addStickybarComponentToPage(editor) : "";
    wrapper.view.$el.find("#customizable_popup")[0] ? wrapper.view.$el.find("#customizable_popup")[0].setAttribute("style", "display:none") : "";
    let ele = wrapper.view.$el.find("#stickyBarCustom")[0];
    if (ele.offsetWidth > 0 && ele.offsetHeight > 0) {
        wrapper.view.$el.find("#stickyBarCustom")[0].setAttribute("style", "display:none");
    } else {
        wrapper.view.$el.find("#stickyBarCustom")[0].setAttribute("style", "display:block");
        document.getElementById("setting_acordion").setAttribute("style", "display:block");
        editor.select(wrapper.view.$el.find("#stickyBarCustom")[0]);
        editor.getSelected().addClass("placeBottom");
    }
};

export default customStickyBar;