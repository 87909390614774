import {
    checkIfPaperIsEmpty,
} from "../../../../utilities/jointjs-helper";

const isLinkCreated = (link) => link.get("source").id && link.get("target").id;

const triggerEventOnEmptyPage = (graph) => {
    if (checkIfPaperIsEmpty(graph)) {
        graph.emitter.emit("VISUALIZER:EMPTY");
    } else {
        graph.emitter.emit("VISUALIZER:HAS-ITEMS");
    }
};

const stencilEvents = {
    "change:source change:target": function (Stencil, link) {
        if (isLinkCreated(link)) {
            Stencil.emitter.emit("LINK:CREATE", link);
        }
    },
    "change:vertices": function (Stencil, cellView) {
        if (cellView.isLink()) {
            var newSource = cellView.get("vertices");
            cellView.source({
                x: newSource[0].x,
                y: newSource[0].y
            });
            cellView.target({
                x: newSource[1].x && newSource[1].x,
                y: newSource[1].y && newSource[1].y
            });
        }
    },
    remove: function (Stencil, element) {
        if (element.isLink() && isLinkCreated(element)) {
            Stencil.emitter.emit("LINK:DELETE", element);
        } else if (element.isLink() && element.get("subType") === "dottedLink") {
            Stencil.emitter.emit("DOTTED-LINE:DELETE", element);
        }
        triggerEventOnEmptyPage(Stencil);
    },
    add: triggerEventOnEmptyPage,
};

export default stencilEvents;