import UUID from "uuid-js";

export const initButtonMetaData = (editor) => {
    const domc = editor.DomComponents;
    const container = editor.container;

    domc.em.listenTo(domc.em, "component:add", (obj) => {
        addComponents(obj, container);
    });
    domc.em.listenTo(domc.em, "component:remove", (obj) => {
        handleRemove(editor, obj, container);
    });
    domc.em.listenTo(domc.em, "component:clone", (obj) => {
        handleClone(obj, container);
    });
};

const setButtonMetaData = (container, buttonMetaData) => {
    container.buttonMetaData = buttonMetaData;
};

const addComponents = (obj, container) => {
    if (obj.attributes.tagName === "button") {
        const button = obj.attributes.attributes["data-id"];
        const { buttonMetaData } = container;
        if (button) {
            const buttonId = obj.attributes.attributes["data-id"];
            const content = obj.attributes.content || "Save/Submit";
            const title = obj.attributes.title;
            const isButtonExists = buttonMetaData.buttons.findIndex((bm) => {
                return bm["data-id"] === buttonId;
            });

            if (isButtonExists === -1) {
                buttonMetaData.buttons.push({
                    "data-id": buttonId,
                    "name": content,
                    "title": title,
                });
            }
            setButtonMetaData(container, buttonMetaData);
        }
    } else if (obj.attributes.tagName === "a") {
        const element = obj.attributes.attributes;
        const link = obj.attributes.attributes["data-id"];
        const { buttonMetaData } = container;
        if (link) {
            const isLinkExists = buttonMetaData.links.findIndex((bm) => {
                return bm["data-id"] === link;
            });
            if (isLinkExists === -1 && element["title"]) {
                buttonMetaData.links.push({
                    "data-id": element["data-id"],
                    "title": element["title"],
                    "href": element["href"],
                    "type": "link",
                    "target": element["target"]
                });
            }
            setButtonMetaData(container, buttonMetaData);
        }
    }
};

const handleRemove = (editor, obj, container) => {
    const { buttonMetaData } = container;
    if (obj.attributes.tagName === "button") {
        const button = obj.attributes.attributes["data-id"];
        if (button) {
            const isButtonExists = buttonMetaData.buttons.findIndex((bm) => {
                return bm["data-id"] === button;
            });
            if (isButtonExists > -1) {
                buttonMetaData.buttons.splice(isButtonExists, 1);
            }
            setButtonMetaData(buttonMetaData);
        }
    } else if (obj.attributes.tagName === "a") {
        const link = obj.attributes.attributes["data-id"];
        if (link) {
            const isLinkExists = buttonMetaData.links.findIndex((bm) => {
                return bm["data-id"] === link;
            });
            if (isLinkExists > -1) {
                buttonMetaData.links.splice(isLinkExists, 1);
            }
            setButtonMetaData(buttonMetaData);
        }
    } else if (obj.attributes.type === "column") {
        // To remove the parent row when last column element gets deleted
        const allComponents = editor.getComponents();
        let componentsToBeRemoved = allComponents.filter(
            () => (
                obj.attributes.type === "column")
        );
        for (let comp of componentsToBeRemoved) {
            if (comp && comp.collection) {
                if (comp.attributes.components.length === 0)
                    comp.collection.remove(comp);
            }
        }
    }
};

const handleClone = (obj, container) => {
    const { buttonMetaData } = container;
    if (obj.attributes.tagName === "button") {
        const button = obj.attributes.attributes["data-id"];
        if (button) {
            const buttonId = UUID.create(4);
            const content = obj.attributes.content;
            buttonMetaData.buttons.push({
                "data-id": `fkt-button-${buttonId.toString()}`,
                "name": content,
                "target": ""
            });
            obj.set("attributes", {
                "data-id": `fkt-button-${buttonId.toString()}`,
                onclick: "callMethod(event)"
            });
            setButtonMetaData(buttonMetaData);
        }
    } else if (obj.attributes.tagName === "a") {
        const link = obj.attributes.attributes["data-id"];
        if (link) {
            const linkId = UUID.create(4);
            const title = obj.attributes.title ? obj.attributes.title : "Link";
            const href = obj.attributes.href && obj.attributes.href;
            buttonMetaData.links.push({
                "data-id": `fkt-link-${linkId.toString()}`,
                "title": title,
                "href": href,
                "target": "",
                "type": "link"
            });
            obj.set("attributes", {
                "data-id": `fkt-link-${linkId.toString()}`,
                onclick: "callMethod(event)"
            });
            setButtonMetaData(buttonMetaData);
        }
    }
};