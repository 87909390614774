import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import { BaseWebpageCreator } from "./base-webpage-creator";
import FullPageLoader from "../commons/full-page-loader";

import { pageTemplateCommands } from "./commands";

import * as webpageGeneratorActions from "./actions";

import { LocalStorageHelper } from "../utilities";

import { getDefaults } from "./defaults";

const localStorageId = "gjs-template-";
class WebpageCreatorComponent extends React.Component {
    constructor() {
        super();
        this.state = {
            pageData: null,
            templateData: null,
            isLoading: false,
            renderWebBuilder: false,
        };
        this.setTemplateData = this.setTemplateData.bind(this);
    }

    async componentDidMount() {
        const {
            selectedTemplateDataFromStore,
        } = this.props;
        let newState = {};
        let pageData;
        const localStorage = new LocalStorageHelper();
        if (selectedTemplateDataFromStore && selectedTemplateDataFromStore.htmlUrl) {
            // eslint-disable-next-line react/no-did-mount-set-state
            this.setState({ isLoading: true });
            pageData = await webpageGeneratorActions.getWebBuilderData({
                componentJsonUrl: selectedTemplateDataFromStore.htmlUrl,
                stylesJsonUrl: selectedTemplateDataFromStore.cssUrl,
            });
            if (pageData) {
                newState = {
                    pageData,
                    isLoading: false,
                    templateTypeId: selectedTemplateDataFromStore.templateTypeId,
                    renderWebBuilder: true,
                    templateData: selectedTemplateDataFromStore,
                };
                localStorage.store(`${localStorageId}store`, JSON.stringify(selectedTemplateDataFromStore));
            }
        } else {
            const templateDataLocalStorage = localStorage.load(`${localStorageId}store`);
            const parsedTemplateData = JSON.parse(templateDataLocalStorage);
            if (parsedTemplateData) {
                newState = {
                    templateTypeId: parseInt(parsedTemplateData.templateTypeId),
                    templateData: parsedTemplateData,
                };
            }
        }
        newState.renderWebBuilder = true;
        // eslint-disable-next-line react/no-did-mount-set-state
        this.setState(newState);
    }

    setTemplateData(templateData) {
        this.setState({ templateData });
    }

    render() {
        const {
            history,
        } = this.props;

        const {
            pageData,
            isLoading,
            templateTypeId,
            renderWebBuilder,
            templateData
        } = this.state;

        const defaults = {
            localStorageId,
            templateTypeId,
            history,
            commands: pageTemplateCommands,
        };
        return (
            <>
                <FullPageLoader showLoading={!renderWebBuilder} />
                {
                    renderWebBuilder && (
                        <BaseWebpageCreator
                            pageData={pageData}
                            { ...getDefaults(defaults) }
                            onEditorLoad={this.onEditorLoad}
                            previousUrl="/page-templates"
                            templateData={templateData}
                            setTemplateData={this.setTemplateData}
                            onEditorLoad={this.onEditorLoad}
                            isLoading={isLoading}
                        />
                    )
                }
            </>
        );
    }
}


WebpageCreatorComponent.propTypes = {
    selectedTemplateDataFromStore: PropTypes.object,
    history: PropTypes.object,
};

const mapStateToProps = (state) => {
    return {
        selectedTemplateDataFromStore: state.TEMPLATES.selectedPageTemplate,
    };
};

export const TemplateCreator = connect(mapStateToProps, null)(WebpageCreatorComponent);
