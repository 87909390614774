import React, { Component } from "react";
import PropTypes from "prop-types";

import CreateEditCampaign from "./CreateEditCampaign";
import SelectExisting from "../SelectExistingTemplate";
import ExistOrNew from "../SelectExistOrNew";
import { getCountriesList, getCurrencyList, getFunnels } from "../utilities/api";
import { getCampaigns } from "../../api/campaignsApi";

class Campaign extends Component {
  state = {
      isLoading: false,
      activeComponent: null,
      optionData: null,
      funnelList: null
  };

  componentDidMount() {
      this.getCampaignList();
      this.funnelList();
      this.props.handleSkipButton(true);
  }

  funnelList = () => {
      getFunnels()
          .then((res) => {
              this.setState({
                  funnelList: res.result
              });
          });
  }

  getCampaignList = () => {
      getCampaigns().then((res) => {
          this.setState({
              optionData: Object.values(res.message.data),
              isTemplateFetched: true
          });
      });
  }

  handleSave = () => {
      this.setState({ isLoading: true });
      setTimeout(() => {
          this.props.moveStep("NEXT");
      }, 1000);
  };

  renderTemplate = () => {
      const { activeComponent, optionData, funnelList } = this.state;
      const { moveStep, handleSkipButton,closeWizard, setCampaignId } = this.props;
      switch (activeComponent) {
          case "NEW":
              return <CreateEditCampaign {...this.props} setCampaignId={setCampaignId} selectTemplate={this.selectTemplate} moveStep={moveStep} handleSkipButton={handleSkipButton} pageType="STEP_WIZARD"/>;
          case "EXISTING":
              return <SelectExisting {...this.props} funnelList={funnelList} handleSkipButton={handleSkipButton} optionData={optionData} selectTemplate={this.selectTemplate} labelText="Campaign" moveStep={moveStep} closeWizard={closeWizard}/>;
      }
  };

  selectTemplate = value => {
      this.setState({
          activeComponent: value
      });
  };

  render() {
      const { activeComponent } = this.state;
      const { handleSkipButton } = this.props;

      return (
          <div
              className="template_Area_container"
          >
              {activeComponent ? (
                  <div className="template_Area">{this.renderTemplate()}</div>
              ) : (
                  <ExistOrNew handleSkipButton={handleSkipButton} labelText={"Campaign"} selectTemplate={this.selectTemplate}/>
              )}
          </div>
      );
  }
}

export default Campaign;

Campaign.propTypes = {
    moveStep: PropTypes.func,
    handleSkipButton: PropTypes.func,
    closeWizard: PropTypes.func,
    setCampaignId: PropTypes.func
};
