export const onLoadBlocks = (editor) => {
    if (editor) {
        const categories = editor.BlockManager.getCategories();
        /*
         * The GrapesJS uses backbone behind the scenes
         * The each function that is called is coming from backbone.
         */
        categories.each((category) => {
            category.set("open", false);
        });
        categories.on("change:open", (openedCategory) => {
            if (openedCategory.get("open")) {
                categories.each(category => {
                    if (category !== openedCategory) {
                        category.set("open", false);
                    }
                });
            }
        });
    }
};