import { getCampaigns } from "../api/campaignsApi";
import { LocalStorageHelper } from "../utilities";
import { Campaign } from "./model";

export const LOAD_CAMPAIGNS_SUCCESS = "LOAD_CAMPAIGNS_SUCCESS";

export const loadCampaignsSuccess = (campaigns) => ({ type: LOAD_CAMPAIGNS_SUCCESS, campaigns });

export const loadCampaigns = () => {
    return function (dispatch) {
        const localstorageManager = new LocalStorageHelper();
        const accessToken = localstorageManager.load(LocalStorageHelper.keys.ACCESS_TOKEN);
        if (accessToken) {
            return getCampaigns().then(campaigns => {
                campaigns = [campaigns.message.data];
                let newArr = [];

                campaigns.forEach(function (campaign) {
                    //let campaign = campaigns[i]
                    for (let prop in campaign) {
                        if (campaign.hasOwnProperty(prop)) {
                            let campaignInfo = {};
                            campaignInfo.campaignId = campaign[prop].campaignId;
                            campaignInfo.campaignName = campaign[prop].campaignName;
                            campaignInfo.campaignTOS = campaign[prop].campaignTOS;
                            campaignInfo.campaignType = campaign[prop].campaignType;
                            campaignInfo.captureOnShipment = campaign[prop].captureOnShipment;
                            campaignInfo.countries = campaign[prop].countries;
                            campaignInfo.coupons = campaign[prop].coupons;
                            campaignInfo.currency = campaign[prop].currency;
                            campaignInfo.eSignature = campaign[prop].eSignature;
                            campaignInfo.enableFraudPlugin = campaign[prop].enableFraudPlugin;
                            campaignInfo.externalCampaignId = campaign[prop].externalCampaignId;
                            campaignInfo.insureShipBillerId = campaign[prop].insureShipBillerId;
                            campaignInfo.insureShipLoadBalancerId = campaign[prop].insureShipLoadBalancerId;
                            campaignInfo.isLocked = campaign[prop].isLocked;
                            campaignInfo.listSalesEntry = campaign[prop].listSalesEntry;
                            campaignInfo.preauthOnly = campaign[prop].preauthOnly;
                            campaignInfo.products = campaign[prop].products;
                            campaignInfo.requireQA = campaign[prop].requireQA;
                            campaignInfo.requireTOS = campaign[prop].requireTOS;
                            campaignInfo.taxes = campaign[prop].taxes;
                            campaignInfo.visible = campaign[prop].visible;

                            newArr.push(new Campaign(campaignInfo));
                        }
                    }
                });
                dispatch(loadCampaignsSuccess(newArr));
                return newArr;
            }).catch(error => {
                throw (error);
            });
        }
    };
};
export const getAllCampaigns = async () => {
    const localstorageManager = new LocalStorageHelper();
    const accessToken = localstorageManager.load(LocalStorageHelper.keys.ACCESS_TOKEN);
    let newArr = [];
    if (accessToken) {
        await getCampaigns().then(campaigns => {
            campaigns = [campaigns.message.data];
            campaigns.forEach(function (campaign) {
                //let campaign = campaigns[i]
                for (let prop in campaign) {
                    if (campaign.hasOwnProperty(prop)) {
                        let campaignInfo = {};
                        campaignInfo.campaignId = campaign[prop].campaignId;
                        campaignInfo.campaignName = campaign[prop].campaignName;
                        campaignInfo.campaignTOS = campaign[prop].campaignTOS;
                        campaignInfo.campaignType = campaign[prop].campaignType;
                        campaignInfo.captureOnShipment = campaign[prop].captureOnShipment;
                        campaignInfo.countries = campaign[prop].countries;
                        campaignInfo.coupons = campaign[prop].coupons;
                        campaignInfo.currency = campaign[prop].currency;
                        campaignInfo.eSignature = campaign[prop].eSignature;
                        campaignInfo.enableFraudPlugin = campaign[prop].enableFraudPlugin;
                        campaignInfo.externalCampaignId = campaign[prop].externalCampaignId;
                        campaignInfo.insureShipBillerId = campaign[prop].insureShipBillerId;
                        campaignInfo.insureShipLoadBalancerId = campaign[prop].insureShipLoadBalancerId;
                        campaignInfo.isLocked = campaign[prop].isLocked;
                        campaignInfo.listSalesEntry = campaign[prop].listSalesEntry;
                        campaignInfo.preauthOnly = campaign[prop].preauthOnly;
                        campaignInfo.products = campaign[prop].products;
                        campaignInfo.requireQA = campaign[prop].requireQA;
                        campaignInfo.requireTOS = campaign[prop].requireTOS;
                        campaignInfo.taxes = campaign[prop].taxes;
                        campaignInfo.visible = campaign[prop].visible;
                        newArr.push(campaignInfo);
                    }
                }
            });
        }).catch(error => {
            throw (error);
        });
    }
    return newArr;
};