import { APIHelper } from "../utilities";
// import moment from "moment";
import { domainUrl, rootUrl, authenticationUrl, providerUrl } from "./urls";
import axios from "axios";
export function login(userName,passWord) {
    const req = {
        client_id: "funnelapp",
        client_secret: "secret",
        grant_type: "password",
        userName,
        passWord
    };
    //Encode the data
    const searchParams = Object.keys(req).map((key) => {
        return encodeURIComponent(key) + "=" + encodeURIComponent(req[key]);
    }).join("&");
    return APIHelper.baseRequest(APIHelper.POST, authenticationUrl, {
        requestBody: searchParams,
        contentType: "application/x-www-form-urlencoded"
    }).then((response) => {
        return new Promise((resolve, reject) => {
            const { access_token, expires_in, token_type } = response;
            APIHelper.baseRequest(APIHelper.GET, `${rootUrl}/Account`, { headers: {
                "Authorization": `${token_type} ${access_token}`
            } }).then((res) => {
                const tenantId = res.userCrm[0].fTenant.tenantId;
                const active = res.active;
                resolve({
                    access_token,
                    expires_in,
                    token_type,
                    tenantId,
                    userCrm: res.userCrm,
                    userSetting: res.userSetting,
                    active,
                    isFirstTime: res.isFirstTime,
                    affiliateId: res.affiliateId,
                    isDesignerUser: res.isDesignerUser,
                });
            }, (error) => {
                reject(error);
            });
        });
    });
}

export function loadAccountInfo() {
    return APIHelper.baseRequest(APIHelper.GET, `${rootUrl}/Account`, { headers: APIHelper.getGenericHeaders() });
}

export function loadFunnelPageCount() {
    return APIHelper.baseRequest(APIHelper.GET, `${rootUrl}/Account/FunnelPageCount`, { headers: APIHelper.getGenericHeaders() });
}

export function putAccountInfo(accountDetails) {
    var allowedFileTypes = ["image/png", "image/jpeg", "image/gif"];
    const fileAsBlob = new Blob([accountDetails.profilePicture], { type: allowedFileTypes });
    let formData = new FormData();
    formData.append("name", accountDetails.company);
    formData.append("addressLine1", accountDetails.address);
    formData.append("city", accountDetails.city);
    formData.append("state", accountDetails.state);
    formData.append("postalCode", accountDetails.postalCode);
    formData.append("phone", accountDetails.phone);
    formData.append("email", accountDetails.email);
    formData.append("firstName", accountDetails.firstName);
    formData.append("lastName", accountDetails.lastName);
    formData.append("profilePicture", accountDetails.profilePicture);
    return axios.put(`${rootUrl}/Account`, formData, { headers: APIHelper.getGenericHeaders(), contentType: "multipart/form-data" });
    // return APIHelper.baseRequest(APIHelper.PUT, `${rootUrl}/Account`, {
    //     headers: APIHelper.getGenericHeaders() ,
    //     contentType: "multipart/form-data",
    //     requestBody: formData
    // });
}

export function underMaintenance() {
    return APIHelper.baseRequest(APIHelper.GET, `${rootUrl}/AppSettings`, { headers: APIHelper.getGenericHeaders() });
}

export function createDomain(domainName) {
    return APIHelper.baseRequest(APIHelper.POST, `${domainUrl}`, {
        headers: APIHelper.getGenericHeaders(),
        requestBody: {
            "Name": domainName,
        }
    });
}

export function getAllDomains() {
    return APIHelper.baseRequest(APIHelper.GET, `${rootUrl}/Domain`, { headers: APIHelper.getGenericHeaders() });
}
export function getDomain(domainReferenceId) {
    return APIHelper.baseRequest(APIHelper.GET, `${domainUrl}/${domainReferenceId}`, { headers: APIHelper.getGenericHeaders() });
}

export function updateDomain(domain) {
    return APIHelper.baseRequest(APIHelper.PUT, `${domainUrl}/${domain.referenceId}`, {
        headers: APIHelper.getGenericHeaders(),
        requestBody: {
            "State": domain.state,
        }
    });
}

export function deleteDomain(domainReferenceId) {
    return APIHelper.baseRequest(APIHelper.DELETE, `${domainUrl}/${domainReferenceId}`, {
        headers: APIHelper.getGenericHeaders()
    });
}

export function getAllCompany() {
    return APIHelper.baseRequest(APIHelper.GET, `${rootUrl}/Tenant/DefaultTenant`, { headers: APIHelper.getGenericHeaders() });
}
export function setCompany(company) {
    return APIHelper.baseRequest(APIHelper.PUT, `${rootUrl}/Tenant/DefaultTenant/${company.value}`, {
        headers: APIHelper.getGenericHeaders(),
        requestBody: {}
    });
}

