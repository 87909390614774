import get from "lodash/get";
import isArray from "lodash/isArray";
const loginPageTemplateTypeId = 7;

export const getCellViewModel = (cellView) => get(
    cellView,
    [
        "model"
    ]
);
export const getSourcePageLabel = (link) => get(
    link,
    [
        "sourceView",
        "model",
        "attributes",
        "pageLabel",
        "label"
    ]
);

export const getsourcePageviewReferenceId = (link) => get(
    link,
    [
        "sourceView",
        "model",
        "attributes",
        "pageViewReferenceId"
    ]
);

export const checkIfSourcePageLoginPage = (link) => get(
    link,
    [
        "sourceView",
        "model",
        "attributes",
        "elementMetaData",
        "templateTypeId"
    ]
) === loginPageTemplateTypeId;


export const getPositionOnScreen = (targetPosition, boundingBox, graphScale = 1) => {
    if (
        targetPosition.x > boundingBox.left
        && targetPosition.x < boundingBox.left + boundingBox.height
        && targetPosition.y > boundingBox.top
        && targetPosition.y < boundingBox.top + boundingBox.width
    ) {
        const x = (targetPosition.x - boundingBox.left) / graphScale;
        const y = (targetPosition.y - boundingBox.top) / graphScale;
        return {
            x,
            y,
        };
    // eslint-disable-next-line no-empty
    } else {
    }
};

export const checkIfPaperIsEmpty = (jointjsPaper) => {
    if (jointjsPaper) {
        const cells = jointjsPaper.getCells();
        const pageElement = cells.find((cell) => {
            return cell.get("type") === "devs.MyImageModel";
        });
        return (pageElement) ? false : true;
    }
};