import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import {
    getSelectedDomainId,
    generateDomainDropdown,
} from "../../utilities/helpers";
import CreateDomainModal from "../../../../settings/create-domain-modal";
import EditDomainModal from "../../../../settings/edit-domain-modal";
import { domainConstants } from "../../../constants";
import {
    triggerNofiticationSuccess,
    triggerNofiticationError,
} from "../../../../commons/notification";
const {
    addYourOwnDomain,
    domainLabel,
    wwwLabel,
    domainNamePlaceholder,
    invalidDomainName,
    addDomainLabel,
    editDomainTitle,
    noteForCnameRecordChange,
    noteForSSLCertGeneration,
    noteToAddCnameRecords,
    domainStatusLabel,
    sslStatusLabel,
} = domainConstants;

export const CustomDomain = ({
    domainList,
    saveDomain,
    funnelReferenceId,
}) => {
    const [domains, setDomains] = useState(domainList);
    const [selectedDomainId, setSelectedDomainId] = useState(null);
    const [showAddDomainModal, setShowAddDomainModal] = useState(false);
    const [newDomainDetails, setNewDomainDetails] = useState(null);

    useEffect(() => {
        const domainId = getSelectedDomainId(domainList) || "defaultDomain";
        setSelectedDomainId(domainId);
    }, []);

    const onChangeDomainSelection = (e) => {
        const newlySelectedDomainId = e.target.value;
        if (newlySelectedDomainId !== "addNewDomain") {
            let domainData = {};
            if (newlySelectedDomainId !== "defaultDomain") {
                domainData = {
                    domainReferenceId: newlySelectedDomainId,
                    funnelReferenceId,
                    selectedDomainId: newlySelectedDomainId,
                };
            } else {
                /*
                 * Weird logic to send previous selected domain id incase of defaultDomain is selected
                 */
                domainData = {
                    domainReferenceId: selectedDomainId,
                    funnelReferenceId: null,
                    selectedDomainId: "defaultDomain",
                };
            }
            setSelectedDomainId(newlySelectedDomainId);
            saveDomain(domainData, "changeDomain")
                .then(() => {
                    triggerNofiticationSuccess({ message: "Domain Updated Successfully" });
                }).catch((err = {}) => {
                    triggerNofiticationError(err.message);
                });
        } else {
            setShowAddDomainModal(true);
        }
    };
    const hideAddDomainModal = () => setShowAddDomainModal(false);
    const onSaveDomain = (domainName) => {
        return saveDomain({
            domainName,
            funnelReferenceId,
        }, "newDomain")
            .then((results) => {
                const newDomainId = results.referenceId;
                hideAddDomainModal();
                const updatedDomainList = domains.map((domain) => {
                    domain.selected = false;
                    return domain;
                });
                setDomains([
                    ...updatedDomainList,
                    {
                        name: results.name,
                        referenceId: newDomainId,
                        selected: true,
                    },
                ]);
                setSelectedDomainId(newDomainId);
                setNewDomainDetails(results);
            }).catch((err = {}) => {
                hideAddDomainModal();
                triggerNofiticationError({ message: err.message });
            });
    };

    return (
        <>
            <div className="input-group header-input-group">
                <div className="input-group-addon header-input-group">Domain URL</div>
                <select className="form-control"
                    value={selectedDomainId}
                    onChange={onChangeDomainSelection}>
                    {
                        generateDomainDropdown(domains)
                    }
                </select>
            </div>
            {
                showAddDomainModal && (
                    <CreateDomainModal
                        showAddNewDomainPopUp
                        closeNewDomainPopup = {hideAddDomainModal}
                        saveNewDomain = {onSaveDomain}
                        addYourOwnDomain = {addYourOwnDomain}
                        domainLabel = {domainLabel}
                        wwwLabel = {wwwLabel}
                        domainNamePlaceholder = {domainNamePlaceholder}
                        invalidDomainName = {invalidDomainName}
                        addDomainLabel = {addDomainLabel}
                        domainList={domainList}
                    />
                )
            }
            {
                newDomainDetails && (
                    <EditDomainModal
                        showEditDomainPopUp
                        closeEditDomainPopup= {() => setNewDomainDetails(null)}
                        editDomainDetails={newDomainDetails}
                        cnameRecords = {newDomainDetails.cnameRecords}
                        editDomainTitle = {editDomainTitle}
                        nameTitle = "Name"
                        typeTitle = "Type"
                        valueTitle = "Value"
                        noteToAddCnameRecords = {noteToAddCnameRecords}
                        noteForCnameRecordChange = {noteForCnameRecordChange}
                        noteForSSLCertGeneration = {noteForSSLCertGeneration}
                        domainStatusLabel = {domainStatusLabel}
                        sslStatusLabel = {sslStatusLabel}
                    />
                )
            }
        </>
    );
};
CustomDomain.propTypes = {
    domainList: PropTypes.array,
    saveDomain: PropTypes.func,
    funnelReferenceId: PropTypes.string,
};
CustomDomain.defaultPros = {
    domainList: [],
};

export default CustomDomain;