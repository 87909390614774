import React, { useState } from "react";
import PropTypes from "prop-types";
import { TextInput } from "../../../../commons";

export const CopyShareId = ({ shareId, isEdited = true }) => {
    const [ copyMessage, setCopyMessage ] = useState("copy-message");
    const copyShareId = () => {
        const text = document.querySelector("#shareId");
        text.select();
        document.execCommand("copy");
        text.blur();
        setCopyMessage("copy-message active");
        setTimeout(() => {setCopyMessage("copy-message");}, 2000);
    };
    return (
        <>
            <div className={copyMessage}>Share Id Copied</div>
            {isEdited ?
                [<TextInput
                    name="shareId"
                    label={"Share Id "}
                    value={shareId}
                    id="shareId"
                    btnText="Copy"
                    note={"Give this Share Id to other FunnelKonnekt users to share this page"}
                />,
                <div className="text-right"><button className="btn btn-primary" onClick={copyShareId}><i class="fa fa-clone m-r-xs" aria-hidden="true"></i> Copy</button></div>]
                : <h3>Please edit the page to share</h3>}
            <hr className="m-b-xs" />
            <hr className="m-t-xs" />
        </>
    );
};
CopyShareId.propTypes = {
    copyMessage: PropTypes.string,
    isEdited: PropTypes.bool,
    shareId: PropTypes.string
};

export default CopyShareId;