import React from "react";
import PropTypes from "prop-types";
import { Route } from "react-router";
import { Provider } from "react-redux";
import { BrowserRouter as Router, Switch } from "react-router-dom";
// TODO: Import these in alphabetical order
// Change to module import instead of default
import { App } from "./../App";
import { Cart } from "./../marketplace/template/cart";
import { CreateFunnel } from "./../funnels/create-funnel";
import { CreateFunnelVisualizer } from "./../funnels/create-funnel-visualizer";
import Visualizer from "../funnels/visualizer/";
import { Dashboard } from "./../dashboard";
import { Designers } from "../designers";
import { DesignerDetails } from "../designers/designer-details";
import { My404Component } from "./../commons/my404component";
import { Funnels } from "./../funnels";
import Screenshot from "./../funnels/create-funnel-visualizer/components/Screenshot";
import { FunnelTypes } from "./../funnels/funnel-types";
import { FunnelDescription } from "../funnels/funnel-types/funnel-description";
import { Login } from "./../login";
import { ManageFunnel } from "./../funnels/manage-funnel";
import { Marketplace } from "./../marketplace";
import { Placeholder } from "./../marketplace";
import { Settings } from "./../settings";
import { SignUp } from "../settings/onBoarding/index";
import { TemplateDetails } from "./../marketplace/template";
import { AppLayoutRoute } from "./../wrapper/layout-route";
import { Diagrams } from "../create-funnel/diagram";
import { PageTemplates } from "../page-templates";
import { FunnelTemplates } from "../funnel-templates";
import { TemplateCreator } from "../webpage-creator";
import { CreateFunnelTemplate } from "./../funnel-templates/create-funnel-template";
import { FunnelTemplateVisualizer } from "../visualizer";
import ScreenshotTemplate from "./../visualizer/components/Screenshot";
import { FunnelTemplateCreator } from "../webpage-creator/funnel-template-web-builder";
import { FunnelPageWebBuilder } from "../webpage-creator/funnel-web-builder";
import "./../App.css";

export const AppRouter = ({ store }) => (
    <Provider store={store}>
        <Router>
            <>
                <App>
                    <Switch>
                        <Route exact path="/login" component={({ history }) => <Login history={history} />} />
                        <AppLayoutRoute exact path="/" component={Dashboard} />
                        <AppLayoutRoute exact path="/dashboard" component={Dashboard} />
                        <AppLayoutRoute exact path="/designers" component={Designers} />
                        <AppLayoutRoute exact path="/designer-details/:id" component={DesignerDetails} />
                        <AppLayoutRoute path="/funnels" component={Funnels} />
                        <AppLayoutRoute path="/funnel" component={CreateFunnel} />
                        <AppLayoutRoute path="/funnel-template" component={CreateFunnelTemplate} />
                        <AppLayoutRoute path="/manage-funnel/:referenceId" component={ManageFunnel} />
                        {/* <AppLayoutRoute path="/editfunnel/:referenceId" component={CreateFunnelVisualizer}/> */}
                        <AppLayoutRoute path="/editfunnel/:referenceId" component={(props) => <Visualizer {...props} visualizerType="funnel" />}/>
                        <AppLayoutRoute path="/edit-funnel-template/:referenceId" component={(props) => <Visualizer {...props} visualizerType="funnelTemplate" />}/>
                        <AppLayoutRoute path="/screenshot/:referenceId" component={Screenshot}/>
                        <AppLayoutRoute path="/screenshot-template/:referenceId" component={ScreenshotTemplate}/>
                        <AppLayoutRoute path="/konnektbook" component={FunnelTypes} />
                        <AppLayoutRoute path="/konnektions" component={FunnelDescription} />
                        <AppLayoutRoute path="/marketplace/:id" component={TemplateDetails} />
                        <AppLayoutRoute path="/settings" component={Settings} />
                        <AppLayoutRoute path="/marketplace" component={Marketplace} />
                        <AppLayoutRoute path="/cart/:id" component={Cart} />
                        <AppLayoutRoute path="/signup" component={SignUp} />
                        <AppLayoutRoute path="/marketplace-products" component={Placeholder} />
                        <AppLayoutRoute path="/diagram" component={Diagrams} />
                        <AppLayoutRoute path="/page-templates" adminRoute component={PageTemplates} />
                        <AppLayoutRoute path="/funnel-templates" adminRoute component={FunnelTemplates} />
                        <AppLayoutRoute path="/404" component={({ history }) => <My404Component history={history} />} />
                        {/* <Route path="/webbuilder" component={({ history }) => <WebBuilder history={history} />} /> */}
                        <Route path="/webbuilder/:funnelId/:pageId" component={({ history, match }) => <FunnelPageWebBuilder match={match} history={history} />} />
                        <Route path="/webpage-creator" component={({ history }) => <TemplateCreator history={history} />} />
                        <Route path="/funnel-template-web-builder/:funnelId/:pageId" component={({ history, match }) => <FunnelTemplateCreator match={match} history={history} />} />
                    </Switch>
                </App>
            </>
        </Router>
    </Provider>
);

AppRouter.propTypes = {
    store: PropTypes.object.isRequired
};