import React, { Component } from "react";
import PropTypes from "prop-types";
import { Tooltip, OverlayTrigger } from "react-bootstrap";

import "./Tooltip.css";

class ToolTip extends Component {
    render() {
        const tooltip = (
            <Tooltip id="tooltip">
                {this.props.title ? (<div className={`${this.props.content ? "tooltip-header" : "pt-6"}`}>
                    {this.props.title}
                </div>) : null}
                <div className="tooltip-content">
                    {this.props.content}
                </div>
                {this.props.note ? (<div className="tooltip-note">
                    {this.props.note}
                </div>) : null}

            </Tooltip>
        );
        return (
            <OverlayTrigger placement={this.props.placement} overlay={tooltip} >
                {this.props.type !== "Button" ? (<div className="tooltip-info-button">i</div>) : (<div className="tooltip-type-button">i</div>)}
            </OverlayTrigger>
        );
    }
}

export default ToolTip;

ToolTip.propTypes = {
    title: PropTypes.string.isRequired,
    content: PropTypes.string.isRequired,
    note: PropTypes.string,
    placement: PropTypes.string.isRequired,
    type: PropTypes.string
};