import React from "react";
import PropTypes from "prop-types";
import VisualizerEmitter from "../../visualizer-emitter";

export const Analytics = () => (
    <button onClick={() => VisualizerEmitter.emit("VISUALIZER:SHOW-ANALYTICS")} className="btn btn-primary m-l-xs pull-right btn-visualizer-analytics">
        <i className="fa fa-chart-bar m-r-xs" aria-hidden="true" />
        Analytics
    </button>
);
Analytics.propTypes = {
    showAnalyticsOverlay: PropTypes.func
};

export default Analytics;