/* eslint-disable no-case-declarations */
import React ,{ createContext, memo, useState, useEffect } from "react";
import PropTypes from "prop-types";
import joint from "jointjs";
import get from "lodash/get";
import isEmpty from "lodash/isEmpty";
import flow from "lodash/flow";
import noop from "lodash/noop";
import partialRight from "lodash/partialRight";
import { connect } from "react-redux";
import VisualizerEmitter from "./visualizer-emitter";
import FunnelVisualizer from "./funnel-visualizer";
import FunnelTemplateVisualizer from "./funnel-template-visualizer";
import "./components/text-element";

import "./visualizer.scss";

import {
    VisualizerFrameDimensions,
} from "../constants";

import {
    createNewPage,
    deletePage,
    updatePage,
    updatePageTemplate,
    createNewLink,
    deleteClickedLink,
    saveButtonsAndLinkDetails,
    getButtonsAndLinks,
    saveUpdatedSplitRatio,
    publishFunnel,
    getFontsApi
} from "./actions/visualizerActions";
import { getTemplates } from "../../templates/actions";
import {
    generatePageInfoForChangeSet,
    generateLinkInfoForChangeSet,
    generateComponentValues,
    generateSplitPageInfoForChangeSet,
    generateDeleteSplitPageInfoForChangeSet,
    setWinnerApiRequestParams,
    generateTextComponentValues,
    generateDottedLineValues,
    updateLinkApiRequestParams,
} from "../../api/generate-parameters-helpers";
import {
    LeftPanel,
} from "./components/left-panel";
import {
    VisualizerFrame,
} from "./components/frame";

import DefaultRightPanel from "./default-right-panel";
import DefaultArrowRightPanel from "./default-arrow-right-panel";
import ChooseTemplatesContainer from "./choose-templates-container";
import SplitRightPanel from "./split-right-panel";
import ComingSoonRightPanel from "./coming-soon-right-panel";
import FunnelTemplateRightPanel from "./funnel-template-right-panel";


import {
    StencilPaperEvents,
    StencilEvents,
    ToolbarEvents,
    registerEvents
} from "./events";

import {
    getCellViewModel,
    getSourcePageLabel,
    getsourcePageviewReferenceId,
    checkIfSourcePageLoginPage,
    checkIfPaperIsEmpty,
} from "../../utilities/jointjs-helper";

import {
    getTemplateTypeId,
    getSelectedPageId,
    getCurrentCell,
    setSplitImageOnPage,
    setSplitEnabledPropertyOnPage,
    setDuplicatePagePropertyOnCurrentPage,
    setOutboundArrowColor,
    updateCurrentPageAttributes,
    createAndSetTemplateSplitProperty,
    getPageViewReferenceId,
    getTargetPageCellViewId,
    setSplitReferenceIdOnPage,
    setSplitValueOnPage,
    setLinkArrowColor,
    updateArrowBasedOnElementsAssignedStatus,
    getSelectedDomainId,
    getPreviewUrl,
    isCurrentPageMembershipPage,
    setPageThumbnailUrl,
    isIcon,
} from "./utilities/helpers";
import { ConfirmationModal } from "../../commons/modals/confirmation";
import {
    funnelConstants,
    publishAlertMessages,
    pageAndArrowBorderColor,
    confirmationModalText
} from "../constants";
import MembershipArrowRightPanel from "./membership-arrow-right-panel";
import SplitPreviewEditModal from "./split-preview-edit-modal";
import {
    triggerNofiticationError,
    triggerNofiticationSuccess
} from "../../commons/notification";
import { showPageAnalytics } from "./utilities/analytics-helper";
import AnalyticsContent from "../create-funnel-visualizer/components/analytics-visualizer-element";

const loginPageTemplateTypeId = 7;
export const VisualizerContext = createContext();


const checkIfLoginAlreadyExists = (templateTypeId, graphCells) => {
    const pages = graphCells.filter(cell => cell.get("type") === "devs.MyImageModel");
    return (templateTypeId === loginPageTemplateTypeId && pages.some((cell) => {
        return (cell.get("elementMetaData").templateTypeId === loginPageTemplateTypeId);
    }));
};

const VISUALIZER_TYPE_FUNNEL = "funnel";
const VISUALIZER_TYPE_FUNNEL_TEMPLATE = "funnelTemplate";

const Visualizer = memo(({
    match,
    history,
    saveButtonsAndLinkDetails,
    getButtonsAndLinks,
    saveUpdatedSplitRatio,
    visualizerType,
    publishFunnel,
    initConfig,
}) => {
    const { referenceId: funnelReferenceId } = match.params;
    const [ dialog, setOpenDialog ] = useState(null);
    const [ currentPage, setCurrentPage ] = useState(null);
    const [ currentCellView, setCurrentCellView ] = useState(null);
    const [ sourcePageLabel, setSourcePageLabel ] = useState(null);
    const [ linkDetails, setLinkDetails ] = useState(null);
    const [ currentLink, setCurrentLink ] = useState(null);
    const [ currentPageViewReferenceId, setCurrentPageViewReferenceId ] = useState(null);
    const visualizerFrameRef = React.createRef();
    const [zoomValue, setZoomValue] = useState(1);
    const [winnerAlphabet, setWinnerAlphabet] = useState(null);
    const [alertMessage, setAlertMessage] = useState(null);
    const [selectedFonts, setSelectedFonts] = useState([]);
    const [fontLoader, setFontLoader] = useState(false);
    let [canvasPaperRef, setCanvasPaperRef] = useState(null);
    let [canvasGraphRef, setCanvasGraphRef] = useState(null);
    let [funnelAnalyticsData, setFunnelAnalyticsData] = useState({ funnelAnalyticsData: null, analyticsOverlayDisplay: false });
    const [isIconPage,setIsIconPage] = useState(false);
    let changeSetApi = null;
    let funnelData = null;
    let onHandleFunnelEmpty = null;

    const ComponentToRender = (visualizerType === "funnelTemplate") ? FunnelTemplateVisualizer : FunnelVisualizer;
    const handleZoomChange = (canvasPaper, zoomValue) => {
        const paperZoom = zoomValue / 100;
        canvasPaper.scale(paperZoom);
        return paperZoom;
    };
    const navigateToBuilder = (pageReferenceId) => {
        hideDialog();
        let pathToNavigate = null;
        if (visualizerType === VISUALIZER_TYPE_FUNNEL) {
            pathToNavigate = "webbuilder";
        } else if (visualizerType === VISUALIZER_TYPE_FUNNEL_TEMPLATE) {
            pathToNavigate = "funnel-template-web-builder";
        }
        if (pathToNavigate) {
            history.push({
                pathname: `/${pathToNavigate}/${funnelReferenceId}/${pageReferenceId}`,
            });
        }
    };
    const navigateToPreviewPage = (pageViewReferenceId) => {
        const previewUrl = getPreviewUrl(pageViewReferenceId, funnelReferenceId);
        window.open(previewUrl, "_blank");
    };
    const getSplitArrowDetails = (pageViewReferenceId) => {
        hideDialog();
        return getArrowSettingsDetails(currentLink, pageViewReferenceId);
    };
    const getArrowSettingsDetails = (link, pageViewReferenceId) => {
        const isSourcePageLoginPage = checkIfSourcePageLoginPage(link);
        const linkId = link.model.get("id");
        const params = {
            sourcePageviewReferenceId: pageViewReferenceId,
            funnelReferenceId: funnelReferenceId,
            linkId: linkId,
            visualizerType: visualizerType
        };
        getButtonsAndLinks(params)
            .then((result) => {
                const linkDetails = result.response;
                const sourcePageLabel = getSourcePageLabel(link);
                const linkViewModel = getCellViewModel(link);
                setSourcePageLabel(sourcePageLabel);
                setCurrentPage(linkViewModel);
                setLinkDetails(linkDetails);
                setCurrentPageViewReferenceId(pageViewReferenceId); //need to find a better way
                openModalOnArrowSelection(isSourcePageLoginPage);
            });
    };

    const initializeCanvasListeners = ({
        canvasPaper,
        canvasGraph
    }) => {
        canvasPaper.emitter = VisualizerEmitter;
        canvasGraph.emitter = VisualizerEmitter;
        setCanvasPaperRef(canvasPaper);
        setCanvasGraphRef(canvasGraph);
        /*
         * Initialize Events
         */
        registerEvents(canvasPaper, StencilPaperEvents);
        registerEvents(canvasGraph, StencilEvents);

        /*
         * [Todo - Use Helpers to get this done. Logic Repeating twice]
         */
        const visualizerJson = get(funnelData, ["visualizerJson"]);
        if (typeof visualizerJson === "string") {
            const visualizerJsonObject = JSON.parse(visualizerJson) || {};
            const zoomValue = visualizerJsonObject && +visualizerJsonObject.zoomValue;
            if (zoomValue) {
                setZoomValue(zoomValue);
                handleZoomChange(canvasPaper, zoomValue * 100);
            }
        }

        /*
        * Custom Event Emitters
        */
        VisualizerEmitter.addListener("RIGHTPANEL:CLOSE", () => {
            hideDialog();
            setSelectedFonts([]);
        });
        VisualizerEmitter.addListener("HEADER-BUTTONS:RESET", () => {
            canvasPaper.blankCanvasComponent = null;
        });
        VisualizerEmitter.addListener("TEXT-ELEMENT:READY", () => {
            canvasPaper.blankCanvasComponent = "textElement";
        });
        VisualizerEmitter.addListener("DOTTED-LINE:READY", () => {
            canvasPaper.blankCanvasComponent = "dottedLine";
        });
        VisualizerEmitter.addListener("TEXT-ELEMENT:CREATE", ({ x, y }) => {
            let yPos = (y > 20) ? y - 20 : y;
            /*
             * [Todo - Fix Issue with Zooming]
             */
            const textElement = new joint.shapes.html.Element({
                position: {
                    x,
                    y: yPos,
                },
            });
            canvasGraph.addCell(textElement);
            const params = {
                action: "create",
                componentType: "text",
                componentId: textElement.get("id"),
                values: generateTextComponentValues(textElement),
            };
            changeSetApi.request(params);
        });
        VisualizerEmitter.addListener("TEXT-ELEMENT:UPDATE", (cellView) => {
            const cellViewModel = getCellViewModel(cellView);
            const params = {
                action: "update",
                componentType: "text",
                componentId: cellViewModel.get("id"),
                values: generateTextComponentValues(cellViewModel),
            };
            changeSetApi.request(params);
        });
        VisualizerEmitter.addListener("TEXT-ELEMENT:DELETE", (cellView) => {
            const cellViewModel = getCellViewModel(cellView);
            const params = {
                action: "delete",
                componentType: "text",
                componentId: cellViewModel.get("id"),
            };
            changeSetApi.request(params);
        });
        VisualizerEmitter.addListener("DOTTED-LINE:ADD", (dottedLine) => {
            canvasGraph.addCell(dottedLine);
        });
        VisualizerEmitter.addListener("DOTTED-LINE:CREATE", (dottedLine) => {
            const dottedLineAttributes = dottedLine.attributes;
            const params = {
                action: "create",
                componentType: "dottedLine",
                componentId: dottedLine.get("id"),
                values: generateDottedLineValues(dottedLineAttributes),
            };
            changeSetApi.request(params);
        });
        VisualizerEmitter.addListener("DOTTED-LINE:UPDATE", (dottedLine) => {
            const dottedLineModel = getCellViewModel(dottedLine);
            const params = {
                action: "update",
                componentType: "dottedLine",
                componentId: dottedLineModel.get("id"),
                values: generateDottedLineValues(dottedLineModel.attributes),
            };
            changeSetApi.request(params);
        });
        VisualizerEmitter.addListener("DOTTED-LINE:DELETE", (dottedLine) => {
            const params = {
                action: "delete",
                componentType: "dottedLine",
                componentId: dottedLine.get("id"),
            };
            changeSetApi.request(params);
        });
        VisualizerEmitter.addListener("ZOOM:EVENT", (zoomValue) => {
            handleZoomChange(canvasPaper, zoomValue);
        });
        VisualizerEmitter.addListener("CELL:SETTINGS", (cellView) => {
            const cellViewModel = getCellViewModel(cellView);
            setCurrentPage(cellViewModel);
            if (!isIcon(cellViewModel)) {
                openModalOnPageSelection(cellViewModel);
            } else {
                setOpenDialog("coming-soon-right-panel");
            }
        });
        VisualizerEmitter.addListener("CELL:SPLIT-COPY-RESTRICT", (cellView) => {
            setOpenDialog("split-copy-alert");
        });
        VisualizerEmitter.addListener("FUNNEL:PUBLISH", (params) => {
            /*
             * [TO-DO] Find a better way to implement it
             *
             */
            const {
                domainList,
                defaultPage,
                callBackLiveSite
            } = params;
            const isSelectedDomainVerified = !!domainList
                .filter((domain) => domain.selected && domain.cNameSet === true)
                .length;
            const isDefaultPageSelected = !!defaultPage.length;
            const isFunnelNotEmpty = !!canvasGraph.getCells().length;
            const pages = canvasGraph.getCells().filter((cell) => cell.get("type") === "devs.MyImageModel" && !isIcon(cell));
            const links = canvasGraph.getCells().filter((cell) => cell.get("type") === "link");
            const allPagesNotEdited = !!pages.filter((page) => {
                if (!page.get("isSplitEnabled")) return !page.get("isEdited");
                else return !page.get("isEdited") || !page.get("splitPageAttributes").isEdited;
            }).length;
            const allPagesTemplateNotSelected = !!pages.filter((page) => !page.get("isTemplateSelected")).length;
            const allArrowsNotLinked = !!links.filter((link) => link.attr(".marker-target/fill") === pageAndArrowBorderColor.red).length;
            if (isDefaultPageSelected &&
                isFunnelNotEmpty &&
                !allPagesNotEdited &&
                !allPagesTemplateNotSelected &&
                !allArrowsNotLinked &&
                isSelectedDomainVerified
            ) {
                triggerNofiticationSuccess({ message: publishAlertMessages.publishInProgress });
                publishFunnel(funnelReferenceId).
                    then(() => {
                        /*
                        *Again not a proper approach, but cannot find a better way
                        */
                        callBackLiveSite();
                        triggerNofiticationSuccess({ message: publishAlertMessages.publishSuccess });
                    });
            } else if (!isFunnelNotEmpty) {
                triggerNofiticationError({ message: publishAlertMessages.emptyFunnelAlert });
                return false;
            } else if (allPagesTemplateNotSelected) {
                triggerNofiticationError({ message: publishAlertMessages.selectAllPagesTemplateAlert });
                return false;
            } else if (allPagesNotEdited || !isDefaultPageSelected) {
                triggerNofiticationError({ message: publishAlertMessages.defaultAndEditAllPagesAlert });
                return false;
            } else if (allArrowsNotLinked) {
                triggerNofiticationError({ message: publishAlertMessages.setPageLinks });
                return false;
            } else if (!isSelectedDomainVerified) {
                triggerNofiticationError({ message: publishAlertMessages.selectedDomainVerifyAlert });
                return false;
            }
        });
        VisualizerEmitter.addListener("CELL:PREVIEW", (cellView) => {
            const cellViewModel = getCellViewModel(cellView);
            setCurrentPage(cellViewModel);
            if (!isIcon(cellViewModel)) {
                if (cellViewModel.get("isTemplateSelected")) {
                    if (cellViewModel.get("isSplitEnabled")) {
                        setOpenDialog("split-preview-alert");
                    } else {
                        const pageViewReferenceId = cellViewModel.get("pageViewReferenceId");
                        navigateToPreviewPage(pageViewReferenceId);
                    }
                } else {
                    setOpenDialog("alertPopup");
                    setAlertMessage(funnelConstants.previewAlert);
                }
            } else {
                setOpenDialog("coming-soon-right-panel");
            }
        });
        VisualizerEmitter.addListener("CELL:COPY", (cell, sourceCellView) => {
            const sourceCell = getCellViewModel(sourceCellView);
            const params = {
                action: "copy",
                componentType: "page",
                componentId: cell.get("id"),
                values: generateComponentValues(cell),
                componentData: {
                    ...generatePageInfoForChangeSet(cell),
                    addOns: {
                        sourcePageReferenceId: sourceCell.get("pageReferenceId"),
                        sourcePageViewReferenceId: sourceCell.get("pageViewReferenceId")
                    }
                }
            };
            changeSetApi.request(params);
        });
        VisualizerEmitter.addListener("ARROW:SETTINGS", (link) => {
            hideDialog();
            const isSourcePageSplitEnabled = link.sourceView.model.get("isSplitEnabled");
            if (isSourcePageSplitEnabled) {
                const sourceSplitPage = link.sourceView.model;
                setCurrentLink(link);
                setCurrentPage(sourceSplitPage);
                setOpenDialog("split-arrow-alert");
            } else {
                const sourcePageviewReferenceId = getsourcePageviewReferenceId(link);
                getArrowSettingsDetails(link, sourcePageviewReferenceId);
            }
        });
        VisualizerEmitter.addListener("CELL:DELETE", (cellView) => {
            setCurrentCellView(cellView);
            setOpenDialog("delete-confirm-alert");
        });
        VisualizerEmitter.addListener("LINK:DELETE", (link) => {
            const params = {
                action: "delete",
                componentType: "link",
                componentId: link.get("id"),
                componentData: generateLinkInfoForChangeSet(link)
            };
            changeSetApi.request(params);
        });
        VisualizerEmitter.addListener("CELL:MOVE", (cellView) => {
            const cell = getCellViewModel(cellView);
            const isSplitEnabled = cell.get("isSplitEnabled");
            const values = isSplitEnabled ?
                {
                    ...generateComponentValues(cell),
                    splitPageAttributes: cell.get("splitPageAttributes"),
                    splitRatio: cell.get("splitRatio"),
                    splitReferenceId: cell.get("splitReferenceId")
                } :
                generateComponentValues(cell);
            const params = {
                action: "move",
                componentType: "page",
                componentId: cell.get("id"),
                values: values,
                componentData: generatePageInfoForChangeSet(cell),
            };
            changeSetApi.request(params);
        });

        VisualizerEmitter.addListener("PAGE:CHANGENAME", (currentPage,currentPageTitle) => {
            const values = { ...generateComponentValues(currentPage), pageLabel: currentPageTitle };
            const pageData = { ...generatePageInfoForChangeSet(currentPage),pageLabel: currentPageTitle };
            const params = {
                action: "update",
                componentType: "page",
                componentId: currentPage.get("id"),
                values: values,
                componentData: pageData,
            };
            changeSetApi.request(params);
        });

        VisualizerEmitter.addListener("PAGE:DROP-ON-VISUALIZER", (cell) => {
            if (canvasGraph) {
                const cellMetaData = cell.get("elementMetaData") || {};
                const cellTemplateTypeId = cellMetaData && cellMetaData.templateTypeId;
                if (cellTemplateTypeId) {
                    if (checkIfLoginAlreadyExists(cellTemplateTypeId, canvasGraph.getCells())) {
                        triggerNofiticationError({ message: "Login page already exits" });
                        return false;
                    }
                    canvasGraph.addCell(cell);
                    const params = {
                        action: "create",
                        componentType: "page",
                        componentId: cell.get("id"),
                        values: generateComponentValues(cell),
                        componentData: generatePageInfoForChangeSet(cell),
                    };
                    changeSetApi.request(params);
                } else {
                    triggerNofiticationError("Cell doesn't have templateTypeId");
                }
            }
            hideDialog();
        });
        VisualizerEmitter.addListener("LINK:CREATE", (link) => {
            const linkData = {
                id: link.get("id"),
                sourcePageCellViewId: link.get("source").id,
                targetPageCellViewId: link.get("target").id,
                type: link.get("type"),
                z: link.get("z")
            };
            const params = {
                action: "create",
                componentType: "link",
                componentId: link.get("id"),
                values: linkData,
                componentData: generateLinkInfoForChangeSet(link)
            };
            changeSetApi.request(params);
        });
        VisualizerEmitter.addListener("DIALOG:OPEN", (dialogName) => {
            if (dialogName === "noTemplateAlert") {
                setOpenDialog("previewAlert");
            }
        });
        if (visualizerType === "funnelTemplate") {
            VisualizerEmitter.addListener("CELL:NAVIGATE-TO-BUILDER", (cellView) => {
                const cellViewModel = getCellViewModel(cellView);
                navigateToBuilder(cellViewModel.get("pageReferenceId"));
            });
        } else if (visualizerType === "funnel") {
            VisualizerEmitter.addListener("CELL:NAVIGATE-TO-BUILDER", (cellView) => {
                const cellViewModel = getCellViewModel(cellView);
                setCurrentPage(cellViewModel);
                if (!isIcon(cellViewModel)) {
                    const isSplitPage = cellViewModel.get("isSplitEnabled");
                    if (isSplitPage) {
                        setOpenDialog("split-edit-alert");
                    } else {
                        navigateToBuilder(cellViewModel.get("pageReferenceId"));
                    }
                } else {
                    setOpenDialog("coming-soon-right-panel");
                }
            });
        }

        VisualizerEmitter.addListener("VISUALIZER:EMPTY", () => {
            onHandleFunnelEmpty(true);
        });
        VisualizerEmitter.addListener("VISUALIZER:HAS-ITEMS", () => {
            onHandleFunnelEmpty(false);
        });
        VisualizerEmitter.addListener("VISUALIZER:SHOW-ANALYTICS", async () => {
            let funnelAnalyticsData = await showPageAnalytics(canvasGraph.getCells().filter((cell) => cell.get("type") === "devs.MyImageModel"), funnelReferenceId);
            showOverley(funnelAnalyticsData);
        });
        VisualizerEmitter.addListener("VISUALIZER:HIDE-ANALYTICS", () => {
            hideOverley();
        });

        if (checkIfPaperIsEmpty(canvasGraph)) {
            VisualizerEmitter.emit("VISUALIZER:EMPTY");
        } else {
            VisualizerEmitter.emit("VISUALIZER:HAS-ITEMS");
        }
    };

    const hideOverley = () => {
        setFunnelAnalyticsData(false);
    };

    const showOverley = (funnelAnalyticsData) => {
        setFunnelAnalyticsData(prevState => ({ ...prevState, funnelAnalyticsData: funnelAnalyticsData, analyticsOverlayDisplay: !prevState.analyticsOverlayDisplay }));
    };

    const renderAnalyticsOnPage = () => {
        /*
         * This function will populate the Analytics Data for every Page in visualizer.
         */
        let pageViewIds = [];
        let analyticElements = [];
        const stencilModel = canvasGraphRef.getCells();
        let analyticsData = funnelAnalyticsData.funnelAnalyticsData;
        stencilModel.forEach(elem => {
            if (elem.get("type") === "devs.MyImageModel") {
                pageViewIds.push(elem.get("id"));
                const matchedPage = funnelData.page.find((page) => page.cellViewId === elem.get("id"));
                let data = [];
                if (!isEmpty(analyticsData) && matchedPage && matchedPage.referenceId && analyticsData[matchedPage.referenceId]) {
                    if (matchedPage.splitEnabled) {
                        const splitPages = funnelData.page.filter((element) => {
                            return (element.cellViewId === elem.get("id"));
                        });
                        splitPages.forEach(splitPage => {
                            data.push(analyticsData[splitPage.referenceId]);
                        });
                    } else {
                        data.push(analyticsData[matchedPage.referenceId]);
                    }
                } else {
                    data.push({});
                }
                analyticElements.push(
                    <AnalyticsContent
                        position={elem.get("position")}
                        data={data}
                        graphScale={zoomValue}
                        isSplitEnabled={matchedPage && matchedPage.splitEnabled}
                    />
                );
            }
        });
        return analyticElements;
    };

    const openModalOnArrowSelection = (isSourcePageLoginPage) => {
        if (isSourcePageLoginPage) setOpenDialog("membership-arrow-settings");
        else setOpenDialog("open-arrow-settings");
    };

    const openModalOnPageSelection = (cellViewModel) => {
        const isSplitEnabled = cellViewModel.get("isSplitEnabled");
        const params = {
            funnelReferenceId,
            pageReferenceId: cellViewModel.get("pageReferenceId")
        };
        let modalToOpen = null;
        if (!cellViewModel.get("isTemplateSelected")) {
            modalToOpen = "choose-templates";
        } else if (isSplitEnabled) {
            modalToOpen = "split-right-panel";
        } else if (visualizerType === "funnelTemplate") {
            getSelectedFonts(params);
            modalToOpen = "funnel-template-right-panel";
        } else {
            getSelectedFonts(params);
            modalToOpen = "default-right-panel";
        }
        setOpenDialog(modalToOpen);
    };

    const getSelectedFonts = (params) => {
        setFontLoader(true);
        getFontsApi(params).then((response) => {
            setSelectedFonts(response.result);
            setFontLoader(false);
        });
    };

    const initializeLeftPanelListeners = ({
        // eslint-disable-next-line no-unused-vars
        toolbarGraph,
        toolbarPaper,
        zoom,
    }) => {
        toolbarPaper.emitter = VisualizerEmitter;
        toolbarPaper.zoom = zoom;
        registerEvents(toolbarPaper, ToolbarEvents);
        VisualizerEmitter.addListener("ZOOM:EVENT", (zoomValue) => {
            const paperZoom = zoomValue / 100;
            setZoomValue(paperZoom);
            toolbarPaper.zoom = paperZoom;
        });
    };

    const hideDialog = () => {
        setOpenDialog(null);
    };
    /******Split Functionality********/

    const confirmWinnerPageAsCurrentPage = (winnerAlphabet) => {
        setWinnerAlphabet(winnerAlphabet);
        setOpenDialog("winner-confirm-alert");
    };

    const resetWinnerAlphabet = () => {
        setWinnerAlphabet(null);
        hideDialog();
    };

    const setWinnerPageAsCurrentPage = () => {
        const requestParams = flow(
            getSelectedPageId,
            partialRight(getCurrentCell, canvasGraphRef),
            partialRight(updateCurrentPageAttributes, winnerAlphabet),
            setConnectedArrowColorsForCurrentPage,
            setWinnerApiRequestParams
        )(currentPage);
        changeSetApi.request(requestParams);
        resetWinnerAlphabet();
    };

    const deleteCurrentPage = () => {
        currentCellView.model.remove();
        const links = canvasGraphRef.getConnectedLinks(currentCellView);
        const cell = getCellViewModel(currentCellView);
        const isSplitPage = cell.get("isSplitEnabled");
        const getPageInfo = () => isSplitPage ?
            generateDeleteSplitPageInfoForChangeSet(cell) :
            generatePageInfoForChangeSet(cell);
        const params = {
            action: isSplitPage ? "deleteSplitPage" : "delete",
            componentType: "page",
            componentId: cell.get("id"),
            componentData: {
                hasLinks: !isEmpty(links) ? true : false,
                ...getPageInfo(),
            }
        };
        changeSetApi.request(params);
        setCurrentCellView(null);
        hideDialog();
    };

    const setArrowColorsForPage = (link, pageViewReferenceId) => {
        const params = {
            sourcePageviewReferenceId: pageViewReferenceId,
            funnelReferenceId: funnelReferenceId,
            linkId: link.get("id")
        };
        getButtonsAndLinks(params).then((result) => {
            updateArrowBasedOnElementsAssignedStatus(link, result.response.elements);
        });
    };

    const setConnectedArrowColorsForCurrentPage = (currentPage) => {
        const connectedLinks = canvasGraphRef.getConnectedLinks(currentPage, { outbound: true });
        const currentPageViewReferenceId = currentPage.get("pageViewReferenceId");
        connectedLinks.map((link) => setArrowColorsForPage(link, currentPageViewReferenceId));
        resetConnectedArrowColor(connectedLinks);
        return currentPage;
    };

    const resetConnectedArrowColor = (links) => {
        links.map((link) => {
            const linkUpdateParams = updateLinkApiRequestParams(link);
            changeSetApi.request(linkUpdateParams);
        });
    };

    const convertPageToSplit = () => {
        flow(
            getSelectedPageId,
            partialRight(getCurrentCell, canvasGraphRef),
            setSplitImageOnPage,
            setSplitEnabledPropertyOnPage,
            setSplitReferenceIdOnPage,
            partialRight(setOutboundArrowColor, canvasGraphRef),
            resetConnectedArrowColor
        )(currentPage);
        hideDialog();
    };
    const createSplitFromCurrentPage = () => {
        flow(
            getSelectedPageId,
            partialRight(getCurrentCell, canvasGraphRef),
            setDuplicatePagePropertyOnCurrentPage
        )(currentPage);
    };
    const createSplitFromSelectedTemplate = (selectedTemplate) => {
        flow(
            getSelectedPageId,
            partialRight(getCurrentCell, canvasGraphRef),
            partialRight(createAndSetTemplateSplitProperty, selectedTemplate)
        )(currentPage);
    };

    const duplicatePage = () => {
        convertPageToSplit();
        createSplitFromCurrentPage();
        const params = {
            action: "createSplitPage",
            componentType: "page",
            componentId: currentPage.get("id"),
            values: {
                ...generateComponentValues(currentPage),
                splitReferenceId: currentPage.get("splitReferenceId"),
                splitPageAttributes: currentPage.get("splitPageAttributes")
            },
            componentData: generateSplitPageInfoForChangeSet(currentPage),
        };
        changeSetApi.request(params);
    };

    const onSplitTemplateSelected = (template) => {
        convertPageToSplit();
        createSplitFromSelectedTemplate(template);
        const params = {
            action: "createSplitPage",
            componentType: "page",
            componentId: currentPage.get("id"),
            values: {
                ...generateComponentValues(currentPage),
                splitReferenceId: currentPage.get("splitReferenceId"),
                splitPageAttributes: currentPage.get("splitPageAttributes")
            },
            componentData: generateSplitPageInfoForChangeSet(currentPage, template),
        };
        changeSetApi.request(params);
    };

    const saveSplitRatio = (params) => {
        const {
            value,
            originalPageReferenceId,
            newPageReferenceId,
            splitReferenceId
        } = params;
        const requestParams = [
            {
                pageReferenceId: originalPageReferenceId,
                splitRatio: 100 - value,
            },
            {
                pageReferenceId: newPageReferenceId,
                splitRatio: value,
            }
        ];
        const stringParams = {
            funnelReferenceId: funnelReferenceId,
            splitReferenceId: splitReferenceId
        };
        const allParams = {
            requestParams,
            stringParams
        };
        setSplitValueOnPage(currentPage, value);
        saveUpdatedSplitRatio(allParams);
    };

    /*********************************End******************************/

    /******************************Arrow settings code*****************/

    const isSplitPageElementsAssigned = () => {
        /**
         * This is to check if the other page in splitpage is linked to that specific arrow to determine arrow color
         * Need to shift this to a helper code
         */
        const sourcePage = currentLink.sourceView.model;
        const unselectedSplitPageViewReferenceId = sourcePage.get("pageViewReferenceId") === currentPageViewReferenceId ?
            sourcePage.get("splitPageAttributes").pageViewReferenceId :
            sourcePage.get("pageViewReferenceId");
        setArrowColorsForPage(currentPage, unselectedSplitPageViewReferenceId);
    };
    const saveUpdatedButtonsAndLinkData = (selectedElements, selectedProducts = []) => {
        const isSourcePageSplit = currentLink && currentLink.model.id === currentPage.get("id") ?
            currentLink.sourceView.model.get("isSplitEnabled") :
            false;
        const selectedElementsIds = selectedElements.map((element) => {
            return element.elementId;
        });
        const selectedProductIds = selectedProducts.map((product) => {
            return product.productId;
        });
        if (selectedElementsIds.length) {
            isSourcePageSplit ?
                isSplitPageElementsAssigned() :
                setLinkArrowColor(currentPage, pageAndArrowBorderColor.green);
        } else {
            setLinkArrowColor(currentPage, pageAndArrowBorderColor.red);
        }
        const sourcePageViewReferenceId = currentPageViewReferenceId;

        const targetPageViewReferenceId = flow (
            getTargetPageCellViewId,
            partialRight(getCurrentCell, canvasGraphRef),
            getPageViewReferenceId
        )(currentPage);
        const params = {
            referenceId: funnelReferenceId,
            sourcePageViewReferenceId: sourcePageViewReferenceId,
            targetPageViewReferenceId: targetPageViewReferenceId,
            linkId: currentPage.get("id"),
            elementIds: selectedElementsIds,
            crmProductIds: selectedProductIds
        };
        saveButtonsAndLinkDetails(params,visualizerType).then((response) => {
            if (response.response.message === "Success") {
                const linkUpdateParams = updateLinkApiRequestParams(currentPage);
                changeSetApi.request(linkUpdateParams);
            }
        });
        hideDialog();

    };

    /*********************************END*****************************/


    const openDialog = () => {
        switch (dialog) {
            case "choose-templates":
                return (
                    <ChooseTemplatesContainer
                        onTemplateSelect={onTemplateSelected}
                        onClose={hideDialog}
                        selectedPageId={currentPage && currentPage.id}
                        templateTypeId={getTemplateTypeId(currentPage)}
                    />
                );
            case "default-right-panel":
                return (
                    <DefaultRightPanel
                        currentPage={currentPage}
                        duplicatePage={duplicatePage}
                        onSplitTemplateSelected={onSplitTemplateSelected}
                        showSplitTest={visualizerType === "funnel" && !isCurrentPageMembershipPage(currentPage)}
                        selectedFonts={selectedFonts}
                        fontLoader={fontLoader}
                    />
                );
            case "funnel-template-right-panel":
                return (
                    <FunnelTemplateRightPanel
                        currentPage={currentPage}
                        selectedFonts={selectedFonts}
                        fontLoader={fontLoader}
                    />
                );
            case "split-right-panel":
                return (
                    <SplitRightPanel
                        currentPage={currentPage}
                        convertWinnerPageToCurrentPage={confirmWinnerPageAsCurrentPage}
                        saveSplitRatio={saveSplitRatio}
                        funnelReferenceId={funnelReferenceId}
                    />
                );
            case "coming-soon-right-panel":
                return (
                    <ComingSoonRightPanel
                        currentPage={currentPage}
                    />
                );
            case "open-arrow-settings":
                return (
                    <DefaultArrowRightPanel
                        sourcePageLabel = {sourcePageLabel}
                        currentPage = {currentPage}
                        linkDetails = {linkDetails}
                        saveUpdatedButtonsAndLinkData = {saveUpdatedButtonsAndLinkData}
                    />
                );
            case "previewAlert":
                return (
                    <ConfirmationModal
                        onSubmit={hideDialog}
                        header= {confirmationModalText.information}
                        title={funnelConstants.previewAlert}
                    />
                );
            case "membership-arrow-settings":
                return (
                    <MembershipArrowRightPanel
                        sourcePageLabel = {sourcePageLabel}
                        currentPage = {currentPage}
                        linkDetails = {linkDetails}
                        saveUpdatedButtonsAndLinkData = {saveUpdatedButtonsAndLinkData}
                    />
                );
            case "split-edit-alert":
                return (
                    <SplitPreviewEditModal
                        closePopup = {hideDialog}
                        action = {0}
                        currentPage = {currentPage}
                        onClick = {navigateToBuilder}
                    />
                );
            case "split-arrow-alert":
                return (
                    <SplitPreviewEditModal
                        closePopup = {hideDialog}
                        action = {2}
                        currentPage = {currentPage}
                        onClick = {getSplitArrowDetails}
                    />
                );
            case "split-preview-alert":
                return (
                    <SplitPreviewEditModal
                        closePopup = {hideDialog}
                        action = {1}
                        currentPage = {currentPage}
                        onClick = {navigateToPreviewPage}
                    />
                );
            case "winner-confirm-alert":
                return (
                    <ConfirmationModal
                        onSubmit={setWinnerPageAsCurrentPage}
                        onCancel={resetWinnerAlphabet}
                        title= {confirmationModalText.declareWinnerConfirmation}
                        header= {confirmationModalText.confirmation}
                        buttonOkText = {confirmationModalText.yes}
                        buttonCancelText = {confirmationModalText.no}
                        className = "winner-alert"
                    />
                );
            case "delete-confirm-alert":
                return (
                    <ConfirmationModal
                        onSubmit={deleteCurrentPage}
                        onCancel={hideDialog}
                        title= {confirmationModalText.deleteConfirmation}
                        header= {confirmationModalText.confirmation}
                        buttonOkText = {confirmationModalText.yes}
                        buttonCancelText = {confirmationModalText.no}
                    />
                );
            case "split-copy-alert":
                return (
                    <ConfirmationModal
                        onSubmit={hideDialog}
                        title= {confirmationModalText.splitPageDuplicateRestrictMessage}
                        header= {confirmationModalText.information}
                    />
                );
            default:
                return null;
        }
    };

    const cleanUpVisualizer = () => {
        VisualizerEmitter.removeAllListeners();
    };

    const setTemplateOnCurrentPage = (selectedTemplate) => {
        const selectedPageId = currentPage.get("id");
        canvasGraphRef
            .getCell(selectedPageId)
            .setImage({
                "xlink:href": selectedTemplate.thumbnailUrl,
            });
        canvasGraphRef
            .getCell(selectedPageId)
            .setProperty("thumbnailUrl", selectedTemplate.thumbnailUrl);
    };

    const updateTemplateSelectedProperty = () => {
        const selectedPageId = currentPage.get("id");
        canvasGraphRef
            .getCell(selectedPageId)
            .setProperty("isTemplateSelected" ,true);
    };

    const onTemplateSelected = (template) => {
        setTemplateOnCurrentPage(template);
        updateTemplateSelectedProperty();
        hideDialog();
        const selectedPageId = currentPage.get("id");
        const selectedCell = canvasGraphRef.getCell(selectedPageId);
        const params = {
            action: "selectPageTemplate",
            componentType: "page",
            componentId: selectedCell.get("id"),
            values: {
                ...generateComponentValues(selectedCell),
                thumbnailUrl: setPageThumbnailUrl(funnelReferenceId, selectedCell.get("pageViewReferenceId"))
            },
            componentData: {
                ...generatePageInfoForChangeSet(selectedCell),
                addOns: {
                    templateId: template.templateId,
                },
            },
        };
        changeSetApi.request(params);
    };

    return (
        <ComponentToRender
            funnelReferenceId={funnelReferenceId}
            initConfig={initConfig}
        >
            {(state, HeaderComponent) => {
                funnelData = get(state, ["funnelData"]) || {};
                changeSetApi = state.changeSetApi;
                onHandleFunnelEmpty = state.onHandleFunnelEmpty || noop;
                return (
                    <div>
                        <VisualizerContext.Provider
                            value={{
                                ...state,
                                isWorking: true
                            }}
                        >
                            {
                                HeaderComponent && (
                                    <HeaderComponent />
                                )
                            }
                            <div className="visualizer-canvas">
                                <LeftPanel
                                    pageTypes={state.pageTypes}
                                    droppableBoundingBox={VisualizerFrameDimensions}
                                    emitter={VisualizerEmitter}
                                    onLeftPanelCreated={({
                                        toolbarGraph,
                                        toolbarPaper,
                                    }) => {
                                        initializeLeftPanelListeners({
                                            toolbarGraph,
                                            toolbarPaper,
                                            zoom: get(state, ["funnelHelpers", "funnelZoomValues", "zoomValue"]),
                                        });
                                    }}
                                />
                                <VisualizerFrame
                                    ref={visualizerFrameRef}
                                    components={funnelData.components}
                                    onVisualizerFrameLoad={initializeCanvasListeners}
                                    funnelPages={funnelData.page}
                                    visualizerCleanUp={cleanUpVisualizer}
                                >
                                    {funnelAnalyticsData.analyticsOverlayDisplay && renderAnalyticsOnPage()}
                                </VisualizerFrame>
                            </div>
                            {
                                dialog && openDialog()
                            }
                        </VisualizerContext.Provider>
                    </div>
                );
            }}
        </ComponentToRender>
    );
});

const mapStateToProps = (state, ownProps) => {
    const initConfig = get(ownProps, ["location", "state"]);
    return {
        templates: state.TEMPLATES.templates,
        userTemplates: state.TEMPLATES.userTemplates,
        premiumTemplates: state.TEMPLATES.premiumTemplates,
        userData: state.LOGIN.userData,
        checkIfAdmin: state.LOGIN.isAdminUser,
        initConfig,
    };
};

const mapDispatchToProps = (dispatch) => ({
    loadTemplates: (templateType) => dispatch(getTemplates({ templateType })),
    createNewPage: (params) => dispatch(createNewPage(params)),
    deletePageAction: (params) => dispatch(deletePage(params)),
    updatePageAction: (params) => dispatch(updatePage(params)),
    updatePageTemplateAction: (params) => dispatch(updatePageTemplate(params)),
    createNewLink: (params) => dispatch(createNewLink(params)),
    deleteClickedLink: (params) => dispatch(deleteClickedLink(params)),
    saveButtonsAndLinkDetails: (params,visualizerType) => dispatch(saveButtonsAndLinkDetails(params,visualizerType)),
    getButtonsAndLinks: (params) => dispatch(getButtonsAndLinks(params)),
    //saveZoomValue: (params) => dispatch(updateZoomValue(params))
    saveUpdatedSplitRatio: (params) => dispatch(saveUpdatedSplitRatio(params)),
    publishFunnel: (params) => dispatch(publishFunnel(params)),
});

Visualizer.propTypes = {
    visualizerInitialize: PropTypes.func.isRequired,
    funnelReferenceId: PropTypes.object,
    match: PropTypes.object,
    getFunnelData: PropTypes.func,
    templates: PropTypes.array,
    userTemplates: PropTypes.array,
    userData: PropTypes.array,
    premiumTemplates: PropTypes.array,
    checkIfAdmin: PropTypes.bool,
    loadTemplates: PropTypes.func,
    changeSetId: PropTypes.number,
    createNewPage: PropTypes.func,
    deletePageAction: PropTypes.func,
    updatePageAction: PropTypes.func,
    updatePageTemplateAction: PropTypes.func,
    createNewLink: PropTypes.func,
    deleteClickedLink: PropTypes.func,
    history: PropTypes.object,
    funnelData: PropTypes.object,
    googleFontsList: PropTypes.object,
    freeTemplates: PropTypes.array,
    saveButtonsAndLinkDetails: PropTypes.func,
    getButtonsAndLinks: PropTypes.func,
    saveUpdatedSplitRatio: PropTypes.func,
    visualizerType: PropTypes.string,
    publishFunnel: PropTypes.func,
    initConfig: PropTypes.object,
    //saveZoomValue: PropTypes.func
};

export default connect(mapStateToProps, mapDispatchToProps)(Visualizer);