import {
    ports,
    pageSize
} from "./default-element-properties";
import {
    getAttributes,
    isPageEdited,
    getPropertyValueFromFunnelData
} from "./json-builder-helper";

export const generatePageModel = (cell, pageToPropertyMap) => {
    return {
        ...getPageAttributes(cell, pageToPropertyMap),
        angle: 0,
        inPorts: [],
        outPorts: [""],
        size: pageSize,
        attrs: getAttributes(
            {
                isTemplateSelected: cell.isTemplateSelected,
                templateTypeId: cell.templateTypeId,
                thumbnailUrl: cell.thumbnailUrl,
                isSplitEnabled: cell.isSplitEnabled,
                pageReferenceId: cell.pageReferenceId,
                pageToPropertyMap,
                currentPage: cell
            }
        ),
        ports,
        type: cell.type
    };
};

const getPageAttributes = (cell, pageToPropertyMap) => {
    const defaultPageAttributes = {
        id: cell.id,
        thumbnailUrl: getPropertyValueFromFunnelData(cell.pageReferenceId, pageToPropertyMap, "thumbnailUrl"),
        elementMetaData: {
            templateTypeId: cell.templateTypeId,
        },
        position: cell.position,
        icon: cell.icon,
        //isDefaultPage: isDefaultPage(cell.pageViewReferenceId, pageToPropertyMap),
        isEdited: isPageEdited(cell.pageReferenceId, pageToPropertyMap),
        isSplitEnabled: cell.isSplitEnabled,
        isTemplateSelected: cell.isTemplateSelected,
        premium: cell.premium,
        z: cell.z,
        pageReferenceId: cell.pageReferenceId,
        pageViewReferenceId: cell.pageViewReferenceId,
        pageLabel: {
            label: getPropertyValueFromFunnelData(cell.pageReferenceId, pageToPropertyMap, "label")
        }
    };
    if (!cell.isSplitEnabled) {
        return defaultPageAttributes;
    } else return {
        ...defaultPageAttributes,
        splitRatio: getPropertyValueFromFunnelData(cell.pageReferenceId, pageToPropertyMap, "splitRatio"),
        splitPageAttributes: {
            ...cell.splitPageAttributes,
            title: getPropertyValueFromFunnelData(cell.splitPageAttributes.pageReferenceId, pageToPropertyMap, "label"),
            isEdited: isPageEdited(cell.splitPageAttributes.pageReferenceId, pageToPropertyMap),
            thumbnailUrl: getPropertyValueFromFunnelData(cell.splitPageAttributes.pageReferenceId, pageToPropertyMap, "thumbnailUrl"),
        },
        splitReferenceId: cell.splitReferenceId,

    };
};