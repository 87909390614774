import { Funnel, FunnelsReducer } from "./models";
import * as actions from "./actions";
export const FUNNELS_REDUCER_KEY = "FUNNELS";
export const FUNNEL_REDUCER_KEY = "FUNNEL";

const initialState = new FunnelsReducer();


export function reducer(state = initialState, action) {
    switch (action.type) {
        case actions.LOAD_FUNNELS_SUCCESS: {
            const funnelArr = action.funnels.map((f) => {
                return new Funnel({ ...f });
            });
            return {
                ...state,
                funnels: funnelArr
            };
        }
        case actions.CREATE_CAMPAIGN_SUCCESS: {
            return {
                ...state,
                campaigns: action.campaigns
            };
        }
        case actions.CREATE_CAMPAIGN_ERROR:
            return {
                ...state,
                error: action.error
            };
        case actions.LOAD_PRODUCTS_SUCCESS: {
            return {
                ...state,
                products: action.products
            };
        }
        case actions.LOAD_MERCHANTS_SUCCESS: {
            return {
                ...state,
                merchants: action.merchants
            };
        }
        case actions.LOAD_CAMPAIGNS_SUCCESS:
            return {
                ...state,
                campaigns: action.campaigns
            };
        case actions.CREATE_FUNNEL_SUCCESS:
            return {
                ...state,
                funnels: [...state.funnels, action.funnel]
            };
        case actions.CREATE_FUNNEL_ERROR:
            return {
                ...state,
                isError: action.isError
            };
        case actions.EDIT_FUNNEL_SUCCESS:
            return {
                ...state,
                funnels: [...state.funnels, action.funnel]
            };
        case actions.EDIT_FUNNEL_ERROR:
            return {
                ...state,
                isError: action.isError
            };
        case actions.UPDATE_FUNNEL_SUCCESS: {
            const updateFunnel = state.map((funnel) => {
                if (funnel.id === action.funnel.id) {
                    return action.funnel;
                }
                return funnel;
            });
            return {
                ...state,
                funnels: updateFunnel,
            };
        }
        case actions.LOAD_PAGE_SUCCESS: {
            return {
                ...state,
                htmlPage: action.htmlPage
            };
        }
        case actions.IS_LOADING: {
            return {
                ...state,
                isLoading: action.status
            };
        }
        case actions.DEFAULT_FUNNELS_SUCCESS_MESSAGE: {
            return {
                ...state,
                defaultFunnelTemplates: action.payload.defaultFunnelTemplates
            };
        }
        case actions.LOAD_PREMIMUMFUNNELTEMPLATES_SUCCESS: {
            return {
                ...state,
                premiumFunnelTemplates: action.payload ? action.payload : []
            };
        }
        default:
            return state;
    }
}
