import {
    ExitConfirmationModal,
} from "../modals";

import CustomPopup from "./menu-commands/custom-popup";
import StickyBar from "./menu-commands/sticky-bar";
import BootstrapMenuCommand from "./menu-commands/bootstrap";
import "../../web-builder/importFromClickFunnels";

import { customElements } from "../legacy/custom-blocks";

export const MenuConfig = {
    "save-database": {
        id: "save-database",
        className: "fa fa-floppy-o",
        command: (editor) => {
            editor.Commands.get("save-database").run(editor, { navigateBack: false });
        },
        attributes: { title: "Save to database" },
    },
    "publish-page-view": {
        id: "publish-page-view",
        className: "fa fa-cloud-upload",
        command: "publish-page-view",
        attributes: { title: "Publish" },
    },
    "open-am": {
        id: "open-am",
        className: "fa fa-folder-open",
        command: "open-am",
        attributes: { title: "Assets" },
    },
    "custom-popup": {
        id: "custom-popup",
        className: "fa fa-object-group",
        command: CustomPopup,
        attributes: {
            title: "Show Popup"
        },
        dragDrop: true
    },
    "custom-stickbar": {
        id: "custom-stickbar",
        className: "fa fa-thumb-tack fa-1x",
        command: StickyBar,
        attributes: {
            title: "Show Stickybar"
        },
        dragDrop: true
    },
    "goback": {
        id: "goback",
        className: "fa fa-chevron-circle-left",
        attributes: { title: "Back" },
        command: (editor = {}) => {
            const container = editor.container;
            if (container && (editor.UndoManager.hasUndo() || container.isTraitChanged)) {
                container.openCloseModal(ExitConfirmationModal);
            } else {
                container.navigateBack();
            }
        },
    },
    "theme-changer": {
        id: "theme-changer",
        className: "fa fa-adjust darkLightTheme",
        command: () => {
            const elementHead = document.getElementsByTagName("head")[0];
            const stylesContent = customElements.lightThemeCss;
            let ligthCss = document.createElement("style");
            ligthCss.id = "lightTheme";
            ligthCss.type = "text/css";
            const styles = stylesContent;
            if (ligthCss.styleSheet) ligthCss.styleSheet.cssText = styles;
            else ligthCss.appendChild(document.createTextNode(styles));
            let themeIconEle = document.getElementsByClassName("darkLightTheme")[0];
            if (elementHead.querySelector("#lightTheme")) {
                document.getElementById("lightTheme").remove();
                themeIconEle.setAttribute("title","Light Theme");
            } else {
                elementHead.appendChild(ligthCss);
                themeIconEle.setAttribute("title","Dark Theme");
            }
        },
        destroy: () => {
            /*
            * Remove lightTheme components
            */
            const elementHead = document.getElementsByTagName("head")[0];
            elementHead.querySelector("#lightTheme") ? document.getElementById("lightTheme").remove() : "";
        }
    }
};