import * as api from "../../../api/funnelApi";
import * as savePageApi from "../../../api/savePageApi";
import { purchaseOrderApi, getPurchasedTemplatesForTenant } from "../../../api/purchaseApi";
import { APIHelper, LocalStorageHelper } from "../../../utilities";
import {
    TOGGLE_LOADING,
    SET_DOMAIN_FOR_FUNNEL_SUCCESS,
    UPDATE_CHANGE_SET_ID,
    GOOGLE_FONT_LOADING_SUCCESS,
    SAVE_BUTTONS_AND_LINKS_SUCCESS,
    LOAD_BUTTONS_AND_LINKS_SUCCESS,
    UPDATE_ZOOM_VALUE_SUCCESS,
    SAVE_SPLIT_RATIO_SUCCESS,
    PUBLISH_FUNNEL_SUCCESS
} from "./visualizerActionsTypes";

const VISUALIZER = "Visualizer/";
export const GET_FUNNEL_SUCCESS = `${VISUALIZER}GET_FUNNEL_SUCCESS`;

export const getFunnelSuccess = (funnelData) => ({ type: GET_FUNNEL_SUCCESS, payload: funnelData });
export const toggleLoading = (status) => ({ type: TOGGLE_LOADING, status });

export const getDomainListForUser = (funnelReferenceId) => {
    return api.getDomainListForUser(funnelReferenceId, { headers: APIHelper.getGenericHeaders() });
};
export function getDefaultDomainId(domainList) {
    const defaultSelectedDomain = domainList && domainList.filter((list) => list.selected === true);
    return function (dispatch) {
        const defaultDomainId = defaultSelectedDomain.length ?
            defaultSelectedDomain[0].referenceId :
            "";
        dispatch(setDomainForFunnelSuccess(defaultDomainId));
    };
}

export const addAndSetDomainForFunnel = (domainData) => {
    return api.addAndSetDomainForFunnel(domainData, { headers: APIHelper.getGenericHeaders() });
};

export const getPurchasedTemplates = () => getPurchasedTemplatesForTenant();
export function setDomainForFunnelSuccess(selectedDomainReferenceId) {
    return {
        type: SET_DOMAIN_FOR_FUNNEL_SUCCESS,
        selectedDomainReferenceId
    };
}

export function loadFonts() {
    return function (dispatch) {
        return api.loadGoogleFonts({ headers: APIHelper.getGenericHeaders() }).then((response) => {
            dispatch({
                type: GOOGLE_FONT_LOADING_SUCCESS,
                payload: response,
            });
        });
    };
}

export function createNewPage(params) {
    return function (dispatch) {
        // dispatch(toggleLoading(true));
        return params.page.pageReferenceId !== "" &&
            params.page.pageViewReferenceId !== "" &&
            params.changeSetId !== undefined &&
            api.createNewPage(params, { headers: APIHelper.getGenericHeaders() })
                .then((response) => {
                    dispatch(updateChangeSetId(response));
                })
                .catch(error => {
                    throw error;
                });
    };
}

export function deletePage(params) {
    return function (dispatch) {
        return params.page.pageReferenceId !== "" &&
        params.page.pageViewReferenceId !== "" &&
        params.changeSetId !== undefined &&
        api.deletePageApi(params, { headers: APIHelper.getGenericHeaders() })
            .then((response) => {
                dispatch(updateChangeSetId(response));
            })
            .catch(error => {
                throw error;
            });
    };
}

export function updatePage(params) {
    return function (dispatch) {
        // dispatch(toggleLoading(true));
        return params.page.pageReferenceId !== "" &&
            params.page.pageViewReferenceId !== "" &&
            params.changeSetId !== undefined &&
            api.updatePageApi(params, { headers: APIHelper.getGenericHeaders() })
                .then((response) => {
                    dispatch(updateChangeSetId(response));
                })
                .catch(error => {
                    throw error;
                });
    };
}

export function updatePageTemplate(params) {
    return function (dispatch) {
        // dispatch(toggleLoading(true));
        return api.updatePageTemplateApi(params, { headers: APIHelper.getGenericHeaders() })
            .then((response) => {
                dispatch(updateChangeSetId(response));
            })
            .catch(error => {
                throw error;
            });
    };
}

export function createNewLink(params) {
    return function (dispatch) {
        // dispatch(toggleLoading(true));
        return params.changeSetId !== undefined && api.createNewLink(params, { headers: APIHelper.getGenericHeaders() })
            .then((response) => {
                dispatch(updateChangeSetId(response));
            })
            .catch(error => {
                throw error;
            }
            );
    };
}

export function deleteClickedLink(params) {
    return function (dispatch) {
        // dispatch(toggleLoading(true));
        return params.changeSetId !== undefined && api.deleteClickedLink(params, { headers: APIHelper.getGenericHeaders() })
            .then((response) => {
                dispatch(updateChangeSetId(response));
            })
            .catch(error => {
                throw error;
            }
            );
    };
}

export function saveButtonsAndLinkDetails(params,visualizerType) {
    return function (dispatch) {
        return api.saveButtonsAndLinkDetails(params, visualizerType ,{ headers: APIHelper.getGenericHeaders() })
            .then((response) => {
                return dispatch({
                    type: SAVE_BUTTONS_AND_LINKS_SUCCESS,
                    response: response
                });
            });
    };
}

export function publishFunnel(funnelReferenceId) {
    return function (dispatch) {
        return api.publishFunnel(funnelReferenceId, { headers: APIHelper.getGenericHeaders(), requestBody: {} })
            .then((response) => {
                return dispatch({
                    type: PUBLISH_FUNNEL_SUCCESS,
                    response: response
                });
            });
    };
}
export function saveButtonsAndLinkDetailsSuccess(response) {
    return {
        type: SAVE_BUTTONS_AND_LINKS_SUCCESS,
        response: response
    };
}

export function getButtonsAndLinks(params) {
    return function (dispatch) {
        return api.getButtonsAndLinksApi(params,{ headers: APIHelper.getGenericHeaders() })
            .then((response) => {
                return dispatch({
                    type: LOAD_BUTTONS_AND_LINKS_SUCCESS,
                    response: response.result
                });
            });
    };
}

export function updateZoomValue(params) {
    //return function (dispatch) {
    return api.updateZoomValue(params,{ headers: APIHelper.getGenericHeaders() });
}

export function saveUpdatedSplitRatio(params) {
    return function (dispatch) {
        return api.saveUpdatedSplitRatio(params, { headers: APIHelper.getGenericHeaders() })
            .then((response) => {
                dispatch(saveUpdatedSplitRatioSuccess(response));
            });
    };
}

export function saveUpdatedSplitRatioSuccess(response) {
    return {
        type: SAVE_SPLIT_RATIO_SUCCESS,
        response
    };
}

export const saveUpdatedPageNameApi = (params) => {
    return api.saveUpdatedPageNameApi(params, { headers: APIHelper.getGenericHeaders() });
};

export const saveFontsApi = (params) => {
    return api.saveFonts(params, { headers: APIHelper.getGenericHeaders() });
};

export const getFontsApi = (params) => {
    return api.getFonts(params,{ headers: APIHelper.getGenericHeaders() });
};

export const changeDefaultPageStatusApi = (params) => {
    return api.changeDefaultPageStatusApi(params, { headers: APIHelper.getGenericHeaders() });
};

export const importShareIdPageOnCurrentPageApi = (params) => {
    return api.importPage(params, { headers: APIHelper.getGenericHeaders() });
};

export const importFunnel = (params) => {
    return api.importFunnel(params, { headers: APIHelper.getGenericHeaders() });
};


export const updateCampaignApi = (params) => {
    return api.updateCampaign(params, { headers: APIHelper.getGenericHeaders() });
};

export const getPageFonts = (params) => {
    return api.getFonts(params,{ headers: APIHelper.getGenericHeaders() });
};

export function updateChangeSetId(changeSetId) {
    return {
        type: UPDATE_CHANGE_SET_ID,
        changeSetId
    };
}

