import * as api from "../api/designersApi";



export const LOAD_DESIGNERS_SUCCESS = "LOAD_DESIGNERS_SUCCESS";

export function loadDesignersSuccess(designers) {
    return { type: LOAD_DESIGNERS_SUCCESS, designers };
}

export function loadDesigners() {
    return function (dispatch) {
        return api.getAllDesigners().then(designers => {
            dispatch(loadDesignersSuccess(designers.result));
        });
    };
}
