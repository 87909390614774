import React, { memo, Fragment } from "react";
import PropTypes from "prop-types";
import get from "lodash/get";

const getStepCss = (currentStep, index) => {
    if (currentStep === index) {
        return "step-active";
    } else if (index < currentStep) {
        return "step-completed";
    }
    return "";
};

const WalkthroughProgress = memo(({ steps, currentStep }) => {
    return (
        <div className="progress-tracker">
            <ol>
                {
                    steps.map((step, index) => {
                        const selectedTile = get(step, ["selectedTile"]);
                        const subItemClass = (selectedTile) ? "show-sub-item" : "";
                        const isStepCompleted = (index < currentStep);
                        return (
                            <Fragment key={step.label}>
                                <li
                                    className={`walkthrough-step ${getStepCss(currentStep, index)}`}
                                    key={step.type}
                                >
                                    <span className="dot" />
                                    <div className="progress-label">
                                        {
                                            (isStepCompleted) && (
                                                <i className="fa fa-check" />
                                            )
                                        }
                                        { step.label }
                                    </div>
                                </li>
                                {
                                    step.hasSubItem && (
                                        <li
                                            className="walkthrough-step step"
                                        >
                                            <div className={`step-sub-item ${subItemClass}`}>
                                                <span className="dot" />
                                                <div className="progress-label">
                                                    { selectedTile && selectedTile.title }
                                                </div>
                                            </div>
                                        </li>
                                    )
                                }
                            </Fragment>
                        );
                    })
                }
            </ol>
        </div>
    );
});

WalkthroughProgress.propTypes = {
    steps: PropTypes.array,
    currentStep: PropTypes.number,
};

export default WalkthroughProgress;