import React, { createContext } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import isEmpty from "lodash/isEmpty";
import floor from "lodash/floor";
import get from "lodash/get";
import isFunction from "lodash/isFunction";
import isNull from "lodash/isNull";
import ProgressBar from "../../commons/progress-bar";
import * as actions from "../../visualizer/actions";
import { Modal } from "react-bootstrap";
import * as funnelActions from "../../funnels/create-funnel-visualizer/actions";
import {
    loadGoogleFonts,
} from "../../core/assets/actions";
import {
    getGoogleFonts,
} from "../../core/assets/selectors";
import {
    getDomainListForUser,
    addAndSetDomainForFunnel,
    saveUpdatedPageNameApi,
    changeDefaultPageStatusApi,
    importShareIdPageOnCurrentPageApi,
    updateCampaignApi,
    importFunnel,
    saveFontsApi,
} from "./actions/visualizerActions";
import {
    getFunnel,
    updateFunnelInfo,
} from "../actions";
import {
    getAllCampaigns,
} from "../../campaign/action";
import { PageTypes } from "../../constants/template-types";

import ChangeSetApi from "../../api/funnelChangeSetApi";
import { getTemplates } from "../../templates/actions";
import {
    DEBUG,
    findCampaignById,
    extractFunnelData,
    getDefaultPage
} from "./utilities/helpers";
import {
    // triggerNofiticationSuccess,
    triggerNofiticationError, triggerNofiticationSuccess,
} from "../../commons/notification";
import FunnelVisualizerHeader from "./funnel-visualizer-header";
import ImportDefaultsFunnelsContainer from "./import-default-funnels-container";
import { ConfirmationModal } from "../../commons/modals/confirmation";
import constants from "../create-funnel-visualizer/utilities/constants";
import { toggleCRMEntryModalInsideVisualizer } from "../../core/app-settings/actions";

export const VisualizerContext = createContext();

class FetchVisualizerData extends React.Component {

    constructor() {
        super();
        this.initializeFunnelVisualizer = this.initializeFunnelVisualizer.bind(this);
        this.trackApiProgess = this.trackApiProgess.bind(this);
        this.updateFunnel = this.updateFunnel.bind(this);
        this.saveDomain = this.saveDomain.bind(this);
        this.saveUpdatedPageName - this.saveUpdatedPageName.bind(this);
        this.changeDefaultPageStatus = this.changeDefaultPageStatus.bind(this);
        this.importShareIdPageOnCurrentPage = this.importShareIdPageOnCurrentPage.bind(this);
        this.updateFunnelDataInState = this.updateFunnelDataInState.bind(this);
        this.setFunnelImportModalDisplay = this.setFunnelImportModalDisplay.bind(this);
        this.handleFunnelImport = this.handleFunnelImport.bind(this);
        this.showChangeSetApiError = this.showChangeSetApiError.bind(this);
        this.onHandleFunnelEmpty = this.onHandleFunnelEmpty.bind(this);
        this.saveFonts = this.saveFonts.bind(this);
        this.getFunnelThumbnail = this.getFunnelThumbnail.bind(this);
    }

    state = {
        isFetchingAllData: true,
        progress: 0,
        changeSetApi: null,
        renderVisualizer: false,
        showImportExistingFunnelButton: false,
        showFunnelImportModal: false,
        isImportingFunnel: false,
        changeSetApiError: null,
        showCRMEntry: true
    };

    componentDidMount() {
        this.initializeFunnelVisualizer();
        this.getFunnelThumbnail().catch(() => {});

    }

    componentWillUnmount() {
        this.getFunnelThumbnail().catch(() => {});
    }

    async initializeFunnelVisualizer(templateImport = false) {
        const {
            campaigns,
            funnelReferenceId,
            loadTemplates,
            getGoogleFonts,
            googleFonts,
        } = this.props;
        try {
            await this.trackApiProgess([
                getFunnel(funnelReferenceId),
                PageTypes.getAllPageTypes(true),
                getDomainListForUser(funnelReferenceId).catch(() => {}),
                !isEmpty(campaigns) ? Promise.resolve(campaigns) : getAllCampaigns(),
                loadTemplates(),
                !isEmpty(googleFonts) ? Promise.resolve(googleFonts) : getGoogleFonts(),
                updateCampaignApi({ funnelReferenceId }),
            ], (p) => {
                this.setState({ progress: p }, () => (this.state.progress === 100 && templateImport) && this.props.showTemplateLoadedSuccessMessage(true));
            });
        // eslint-disable-next-line no-empty
        } catch (err) {}
    }
    setFunnelImportModalDisplay = (status) => this.setState({ showFunnelImportModal: status });

    updateFunnel(funnelData) {
        return this.props.updateFunnelInfo({
            funnel: funnelData
        })
            .then(() => {
                this.setState({
                    funnelData,
                    funnelHelpers: this.setUpFunnelHelpers(funnelData),
                });
            })
            .catch(err => {
                triggerNofiticationError(err.message);
            });
    }

    updateFunnelDataInState(funnelData) {
        this.setState({
            funnelData: {
                ...funnelData,
            },
            funnelHelpers: this.setUpFunnelHelpers(funnelData),
        });
    }

    saveDomain = (domainData = {}, action) => {
        const {
            domainList,
        } = this.state;
        const {
            addAndSetDomainForFunnelApi,
        } = this.props;
        return addAndSetDomainForFunnelApi(domainData)
            .then((results) => {
                let updatedDomains = [
                    ...domainList
                ];
                let currentDomainId = null;
                if (action === "newDomain") {
                    currentDomainId = results.referenceId;
                    updatedDomains = [
                        ...domainList,
                        {
                            name: results.name,
                            referenceId: results.referenceId,
                            selected: true,
                        },
                    ];
                } else if (action === "changeDomain") {
                    currentDomainId = domainData.selectedDomainId;
                }
                if (currentDomainId) {
                    updatedDomains = updatedDomains.map((domain = {}) => {
                        if (domain.referenceId === currentDomainId || (currentDomainId === "defaultDomain" && isNull(domain.referenceId))) {
                            domain.selected = true;
                        } else {
                            domain.selected = false;
                        }
                        return domain;
                    });
                    this.setState({ domainList: updatedDomains });
                }
                return results;
            });
    }

    saveUpdatedPageName = (params) => {
        const {
            pageReferenceId,
            currentPageTitle,
            currentPage,
            chosenPage = ""
        } = params;
        const {
            funnelReferenceId,
            saveUpdatedPageNameApi
        } = this.props;
        return saveUpdatedPageNameApi({ funnelReferenceId, pageReferenceId, currentPageTitle })
            .then(() => {
                if (chosenPage === "B") {
                    const updateSplitPageAttributes = {
                        ...currentPage.get("splitPageAttributes"),
                        title: currentPageTitle
                    };
                    currentPage.setProperty("splitPageAttributes", updateSplitPageAttributes);
                } else currentPage.attr(".label/text", currentPageTitle);
            });
    };

    saveFonts = (params) => {
        const {
            pageReferenceId,
            fontUrlArray
        } = params;
        const {
            funnelReferenceId,
            saveFontsApi
        } = this.props;
        const parameters = {
            pageReferenceId,
            fontUrlArray,
            funnelReferenceId
        };
        return saveFontsApi(parameters);
    };

    getFunnelThumbnail = () => {
        const {
            funnelReferenceId,
            getFunnelThumbnailApi
        } = this.props;
        return getFunnelThumbnailApi(funnelReferenceId);
    };

    changeDefaultPageStatus = (params) => {
        const {
            pageViewReferenceId,
            isSplitEnabled,
            currentPage
        } = params;
        const {
            funnelReferenceId,
            changeDefaultPageStatusApi
        } = this.props;
        return changeDefaultPageStatusApi({ pageViewReferenceId, isSplitEnabled, funnelReferenceId })
            .then(() => {
                const currentDefaultPage = currentPage.get("id");
                this.setState({
                    defaultPage: currentDefaultPage
                });
            }
            );
    };

    importShareIdPageOnCurrentPage = (params) => {
        const {
            shareId,
            pageViewReferenceId,
            currentPage
        } = params;
        const {
            funnelReferenceId,
            importShareIdPageOnCurrentPageApi
        } = this.props;
        return importShareIdPageOnCurrentPageApi({ shareId, pageViewReferenceId, funnelReferenceId })
            .then((response) => {
                currentPage.attr(".label/text", response.result.title);
                currentPage.setImage(
                    {
                        "xlink:href": response.result.thumbnailUrl,
                    }
                );
            });
    };

    onHandleFunnelEmpty = (isFunnelEmpty) => {
        const showImportExistingFunnelButton = isFunnelEmpty ? true : false;
        this.setState({ showImportExistingFunnelButton });
    }

    trackApiProgess(proms, progress_cb) {
        let d = 0;
        progress_cb(0);
        for (const p of proms) {
            if (p && isFunction(p.then)) {
                p.then(() => {
                    d ++;
                    progress_cb(floor((d * 100) / proms.length));
                });
            }
        }
        return Promise.all(proms)
            .then(([funnelData, pageTypes, { result: domainList }, campaigns, freeTemplates, googleFontsList]) => {
                // eslint-disable-next-line no-empty
                if (DEBUG) {
                }
                const {
                    initConfig,
                } = this.props;
                const changeSetApi = new ChangeSetApi(
                    funnelData.changesetId,
                    funnelData.referenceId,
                    "funnel",
                    this.showChangeSetApiError,
                );
                this.setState({
                    isFetchingAllData: false,
                    funnelData,
                    funnelHelpers: this.setUpFunnelHelpers(funnelData),
                    pageTypes,
                    domainList,
                    selectedCampaign: findCampaignById(campaigns, +funnelData.campaign),
                    freeTemplates,
                    allCampaigns: campaigns,
                    googleFontsList,
                    changeSetApi,
                    updateFunnelInfo: this.updateFunnel,
                    saveDomain: this.saveDomain,
                    saveUpdatedPageName: this.saveUpdatedPageName,
                    changeDefaultPageStatus: this.changeDefaultPageStatus,
                    importShareIdPageOnCurrentPage: this.importShareIdPageOnCurrentPage,
                    defaultPage: getDefaultPage(funnelData),
                    renderVisualizer: true,
                    updateFunnelDataInState: this.updateFunnelDataInState,
                    saveFonts: this.saveFonts,
                    getFunnelThumbnail: this.getFunnelThumbnail,
                }, () => {
                    const numberOfFunnelPages = get(funnelData, ["page", "length"]);
                    if (initConfig && initConfig.openFunnelTemplatesOnLoad && !numberOfFunnelPages) {
                        this.setState({
                            showFunnelImportModal: true,
                        });
                    }
                });
                this.props.getFunnelData(funnelData.referenceId);
            })
            .catch((err) => {
                this.setState({ isFetchingAllData: false });
                triggerNofiticationError({ message: err.message });
            });
    }

    showChangeSetApiError(error) {
        this.setState({
            changeSetApiError: error,
        });
    }

    setUpFunnelHelpers(funnelData) {
        const getFunnelValueOf = extractFunnelData(funnelData);
        return {
            getFunnelValueOf,
            funnelZoomValues: function () {
                const visualizerZoom = getFunnelValueOf("visualizerJson");
                return visualizerZoom ? JSON.parse(visualizerZoom) : { zoomValue: 1 };
            }(),
        };
    }

    componentDidUpdate(prevProps, prevState) {
        if (DEBUG) {
            Object.entries(this.props).forEach(([key, val]) =>
                prevProps[key] !== val
            );
            if (this.state) {
                Object.entries(this.state).forEach(([key, val]) =>
                    prevState[key] !== val
                );
            }
        }
    }

    handleFunnelImport(params) {
        const {
            importFunnel,
        } = this.props;
        const {
            funnelData,
        } = this.state;
        const templateImport = true;
        this.setState({
            isImportingFunnel: true,
        });
        return importFunnel({
            ...params,
            referenceId: funnelData.referenceId,
        })
            .then(() => {
                this.setState({
                    renderVisualizer: false,
                    isFetchingAllData: true,
                    isImportingFunnel: false,
                }, () => {
                    this.initializeFunnelVisualizer(templateImport);
                });
                triggerNofiticationSuccess({ message: "Funnel Imported Successfully" });
            })
            .catch(err => {
                this.setState({
                    isImportingFunnel: false,
                });
                triggerNofiticationError({ message: err.message });
            });
    }

    closeSuccessMessage = () => {
        this.props.showTemplateLoadedSuccessMessage(false);
        this.setState({ showCRMEntry: false }, () => this.props.toggleCRMEntryModalInsideVisualizer(true));
    }

    render() {
        const {
            isFetchingAllData,
            progress,
            renderVisualizer,
            funnelData,
            showImportExistingFunnelButton,
            showFunnelImportModal,
            isImportingFunnel,
            changeSetApiError,
            showCRMEntry
        } = this.state;
        const { initConfig } = this.props;
        if (isImportingFunnel) {
            return (
                <div className="import-page-loader">
                    <i className="fa fa-spinner fa-pulse fa-lg m-r-xs" />
                </div>
            );
        } else if (isFetchingAllData && !renderVisualizer) {
            return (
                <ProgressBar
                    progress={progress}
                />
            );
        } else if (renderVisualizer) {
            return (
                <>
                    {
                        this.props.children({
                            ...this.state,
                            onHandleFunnelEmpty: this.onHandleFunnelEmpty,
                        },
                        FunnelVisualizerHeader
                        )
                    }
                    {
                        showImportExistingFunnelButton && (
                            <div className="btn-import-default-templates">
                                <button
                                    type="button"
                                    className="btn btn-primary"
                                    onClick={() => {this.setFunnelImportModalDisplay(true); this.setState({ showCRMEntry: false });}}
                                >
                                    Choose a Template Funnel
                                </button>
                            </div>
                        )
                    }
                    {
                        showFunnelImportModal && (
                            <ImportDefaultsFunnelsContainer
                                onClose={buttonClicked => {
                                    this.setFunnelImportModalDisplay(false);
                                    initConfig &&
                                    buttonClicked &&
                                    showCRMEntry &&
                                    this.props.toggleCRMEntryModalInsideVisualizer(true);
                                }}
                                funnel={funnelData}
                                handleFunnelImport={this.handleFunnelImport}
                                funnelTemplateTypeIdToPrepopulate={initConfig && initConfig.funnelTemplateTypeId}
                            />
                        )
                    }
                    {
                        changeSetApiError && (
                            <ConfirmationModal
                                onSubmit={() => this.showChangeSetApiError(false)}
                                title="Something went wrong. Please try again later"
                                header="Error"
                            />
                        )
                    }
                    { initConfig && initConfig.openFunnelTemplatesOnLoad &&
                        this.props.showTemplateLoadedSuccess && showCRMEntry &&
                            <Modal show backdrop = "static" onHide={this.closeSuccessMessage} bsSize="" aria-labelledby="contained-modal-title-lg">
                                <Modal.Header closeButton style={{ backgroundColor: "rgb(47, 64, 77)" }}>
                                    <Modal.Title><h3 className="text-white">Success</h3></Modal.Title>
                                </Modal.Header>
                                <Modal.Body className="m-n">
                                    <h5 className="text-center">{constants.publishAlertMessage1}</h5>
                                    <h5 className="text-center">{constants.publishAlertMessage2}</h5>
                                </Modal.Body>
                                <Modal.Footer>
                                    <a className="btn btn-primary" onClick={this.closeSuccessMessage}>Ok</a>
                                </Modal.Footer>
                            </Modal>
                    }
                </>
            );
        } else {
            return null;
        }
    }
}

FetchVisualizerData.propTypes = {
    campaigns: PropTypes.object,
    funnelReferenceId: PropTypes.string,
    loadTemplates: PropTypes.func,
    children: PropTypes.element,
    getGoogleFonts: PropTypes.func,
    googleFonts: PropTypes.object,
    updateFunnelInfo: PropTypes.func,
    addAndSetDomainForFunnelApi: PropTypes.func,
    saveUpdatedPageNameApi: PropTypes.func,
    changeDefaultPageStatusApi: PropTypes.func,
    importShareIdPageOnCurrentPageApi: PropTypes.func,
    importFunnel: PropTypes.func,
    initConfig: PropTypes.object,
    showTemplateLoadedSuccessMessage: PropTypes.func,
    toggleCRMEntryModalInsideVisualizer: PropTypes.func,
    getFunnelData: PropTypes.object,
    showTemplateLoadedSuccess: PropTypes.bool,
    saveFontsApi: PropTypes.func,
    getFunnelThumbnailApi: PropTypes.func
};

const mapDispatchToProps = (dispatch) => ({
    loadTemplates: (templateType) => dispatch(getTemplates({ templateType })),
    getGoogleFonts: () => dispatch(loadGoogleFonts()),
    updateFunnelInfo: (funnel) => dispatch(updateFunnelInfo(funnel)),
    addAndSetDomainForFunnelApi: (domainData) => addAndSetDomainForFunnel(domainData),
    saveUpdatedPageNameApi: (params) => saveUpdatedPageNameApi(params),
    changeDefaultPageStatusApi: (params) => changeDefaultPageStatusApi(params),
    importShareIdPageOnCurrentPageApi: (params) => importShareIdPageOnCurrentPageApi(params),
    importFunnel: (params) => importFunnel(params),
    showTemplateLoadedSuccessMessage: (status) => dispatch(actions.commonActions.showTemplateLoadedSuccessMessage(status)),
    toggleCRMEntryModalInsideVisualizer: (status) => dispatch(toggleCRMEntryModalInsideVisualizer(status)),
    getFunnelData: (funnelReferenceId) => dispatch(funnelActions.getFunnel(funnelReferenceId)),
    saveFontsApi: (params) => saveFontsApi(params),
    getFunnelThumbnailApi: (funnelReferenceId) => dispatch(funnelActions.getThumbnailForFunnel(funnelReferenceId)),
});


const mapStateToProps = (state) => {
    return {
        templates: state.TEMPLATES.templates,
        campaignsFromStore: state.CAMPAIGNS,
        googleFonts: getGoogleFonts(state),
        showTemplateLoadedSuccess: state.VISUALIZER.showImportFunnelTemplateSuccessMessage
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(FetchVisualizerData);