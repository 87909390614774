import React from "react";
import PropTypes from "prop-types";

export const CopyImportPagePopup = ({ children, closeSharePopup }) => (
    <>
        {
            <div className={"share-popup active"}>
                <div className="col-xs-12 no-padding">
                    <span className="pointer pull-right" onClick={closeSharePopup}><i class="fa fa-close" aria-hidden="true"></i></span>
                    {
                        children
                    }
                </div>
            </div>
        }
    </>
);

CopyImportPagePopup.propTypes = {
    children: PropTypes.element,
    closeSharePopup: PropTypes.func
};

export default CopyImportPagePopup;