import React from "react";
import PropTypes from "prop-types";
import { modifyLabelText } from "../utilities";

function ExistOrNew({ labelText, selectTemplate, pageTitle, handleSkipButton }) {
    {labelText === "Products" ? handleSkipButton(false) : handleSkipButton(true);}
    return (
        <div
            style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                width: "100%"
            }}
        >
            <div style={{
                display: "flex",
                flexDirection: "column",
            }}>
                {pageTitle && <span className="offer-label">
                    {pageTitle}
                </span>}
                <button
                    className="button_style"
                    style={{ width: 266 }}
                    onClick={() => selectTemplate("EXISTING")}
                >
                    {`Select An Existing ${modifyLabelText(labelText)}`}
                </button>
                <button
                    className="button_style"
                    style={{ width: 266, marginTop: 19 }}
                    onClick={() => selectTemplate("NEW")}
                >
                    {`Create A New ${modifyLabelText(labelText)}`}
                </button>
            </div>
        </div>
    );
}

export default ExistOrNew;

ExistOrNew.propTypes = {
    labelText: PropTypes.string.isRequired,
    selectTemplate: PropTypes.func,
    pageTitle: PropTypes.string,
    handleSkipButton: PropTypes.func
};