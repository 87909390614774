import React from "react";
import PropTypes from "prop-types";
import { Modal } from "react-bootstrap";
import isFunction from "lodash/isFunction";

export const ConfirmationModal = (props) => {
    const {
        onSubmit,
        onCancel,
        title,
        header,
        item,
        buttonOkText,
        buttonCancelText,
        className,
    } = props;
    const showCancelButton = isFunction(onCancel);
    return (
        <Modal show className={className}>
            <Modal.Header>
                <Modal.Title className="text-center"> { header } </Modal.Title>
            </Modal.Header>
            <Modal.Body className="m-n">
                <div className="confirmation"> { title } </div>
            </Modal.Body>
            <Modal.Footer>
                <button className="btn btn-success modal-save-btn" onClick={() => onSubmit(item)}>{ buttonOkText }</button>
                {
                    showCancelButton && (
                        <button className="btn btn-default modal-cancel-btn" onClick={() => onCancel()}> { buttonCancelText }</button>
                    )
                }
            </Modal.Footer>
        </Modal>
    );
};

ConfirmationModal.propTypes = {
    onCancel: PropTypes.func,
    onSubmit: PropTypes.func,
    title: PropTypes.string,
    header: PropTypes.string,
    item: PropTypes.object,
    buttonCancelText: PropTypes.string,
    buttonOkText: PropTypes.string,
    className: PropTypes.string,
};

ConfirmationModal.defaultProps = {
    buttonCancelText: "Cancel",
    buttonOkText: "Ok",
    onSubmit: () => {},
    onCancel: null,
};