import React, { useContext, useState, useEffect } from "react";
import PropTypes from "prop-types";
import get from "lodash/get";
import {
    LiveUrl,
    FontsList,
    SharePage,
    EditPageName,
    SetDefaultPage
} from ".";
import {
    getPageShareId,
    getDuplicatePageShareId,
    getPageType,
    getTemplateTypeId,
    getPageTextName,
    formatSelectedFonts,
    formatSavedFonts
} from "../../utilities/helpers";
import { VisualizerContext } from "../.././";
import {
    getFontsApi
} from "../../actions/visualizerActions";

const SplitRightPanelSettings = ({
    pageLiveUrl,
    fontList,
    currentPage,
    chosenAlphabet = "A",
    funnelReferenceId
}) => {
    const VisualizerContextData = useContext(VisualizerContext);
    const {
        funnelData,
        saveUpdatedPageName,
        defaultPage,
        changeDefaultPageStatus,
        importShareIdPageOnCurrentPage,
        saveFonts,
        domainList
    } = VisualizerContextData;
    const [ fontLoader, setFontLoader ] = useState(false);
    const [ selectedFonts, setSelectedFonts ] = useState([]);

    /*
     *
     *share page variables
     * */
    const shareId = chosenAlphabet === "A" ? getPageShareId(funnelData.page, currentPage) : getDuplicatePageShareId(funnelData.page, currentPage);
    const isCurrentPageEdited = chosenAlphabet === "A" ? currentPage.get("isEdited") : currentPage.get("splitPageAttributes").isEdited;
    const pageViewReferenceId = chosenAlphabet === "A" ? currentPage.get("pageViewReferenceId") : currentPage.get("splitPageAttributes").pageViewReferenceId;
    const importSharedIdPage = (shareIdToBeImported) => importShareIdPageOnCurrentPage({ shareId: shareIdToBeImported, pageViewReferenceId, currentPage });

    /*
     *
     * Edit page name Variables
     */
    const currentPageTemplateTypeId = getTemplateTypeId(currentPage);
    const pageTypeLabel = getPageType(currentPageTemplateTypeId);
    const initialPageTitle = chosenAlphabet === "A" ? currentPage.attr(".label/text") : currentPage.get("splitPageAttributes").title;
    const pageReferenceId = chosenAlphabet === "A" ? currentPage.get("pageReferenceId") : currentPage.get("splitPageAttributes").pageReferenceId;
    const savePageName = (currentPageTitle) => {
        const chosenPage = chosenAlphabet;
        return saveUpdatedPageName({ pageReferenceId, currentPageTitle, currentPage, chosenPage });
    };

    /*
     *
     * DefaultPage Variables
     */
    const isCurrentPageDefaultPage = currentPage.get("id") === defaultPage;
    const isSplitEnabled = currentPage.get("isSplitEnabled");
    const splitReferenceId = currentPage.get("splitReferenceId");
    const updateDefaultPageStatus = () => changeDefaultPageStatus({ pageViewReferenceId: splitReferenceId, isSplitEnabled, currentPage });

    /**
     * Fonts variables
     */
    const getSelectedFontsApi = () => {
        setSelectedFonts([]);
        setFontLoader(true);
        const params = {
            funnelReferenceId,
            pageReferenceId
        };
        getFontsApi(params).then((response) => {
            setFontLoader(false);
            setSelectedFonts(response.result);
        });
    };
    const getSelectedFonts = formatSelectedFonts(selectedFonts);
    const updateFonts = (savedFonts) => {
        const fontUrlArray = formatSavedFonts(savedFonts);
        const params = {
            pageReferenceId,
            fontUrlArray
        };
        saveFonts(params);
    };
    useEffect(() => {
        getSelectedFontsApi();
    },[chosenAlphabet]);
    return (
        <>
            <SharePage
                shareId = {shareId}
                isCurrentPageEdited = {isCurrentPageEdited}
                pageViewReferenceId = {pageViewReferenceId}
                importSharedIdPage = {importSharedIdPage}
            />
            <EditPageName
                pageTypeLabel = {pageTypeLabel}
                initialPageTitle = {initialPageTitle}
                savePageName = {savePageName}
                chosenAlphabet= {chosenAlphabet}
            />
            { funnelData.status && isCurrentPageEdited ?
                <LiveUrl
                    domainList = {domainList}
                    currentPage = {currentPage}
                    chosenAlphabet= {chosenAlphabet}
                />
                : null
            }
            <FontsList
                fontList={fontList}
                selectedFonts={getSelectedFonts}
                isFontLoading={fontLoader}
                saveFontsCall={updateFonts}
                chosenAlphabet= {chosenAlphabet}
            />
            <SetDefaultPage
                updateDefaultPageStatus = {updateDefaultPageStatus}
                isCurrentPageDefaultPage = {isCurrentPageDefaultPage}
            />
        </>
    );
};

SplitRightPanelSettings.propTypes = {
    pageLiveUrl: PropTypes.string,
    fontList: PropTypes.array,
    currentPage: PropTypes.object,
    chosenAlphabet: PropTypes.string,
    funnelReferenceId: PropTypes.string
};

export default SplitRightPanelSettings;