import React from "react";
const modalConstants = {
    previewAlert: {
        type: "Information",
        text: [<h4 className="text-center" key="previewAlert">Please Select the Template before you preview the page</h4>],
    },
    linkAlert: {
        type: "Information",
        text: [<h4 className="text-center" key="linkAlert">Please Select the Template before you preview the page</h4>],
    },
    fontAlert: {
        type: "Information",
        text: [<h4 className="text-center" key="fontAlert">Please Select fonts to save</h4>],
    },
    deletePopup: {
        type: "Confirmation",
        text: [<h4 className="text-center" key="deletePopup">Are you sure to delete the page?</h4>],
    },
    sharePopup: {
        type: "Confirmation",
        text: [<h4 className="text-center" key="sharePopup">Import page will overwrite your existing the page. Do you wish to continue?</h4>],
    },
    editAlert: {
        type: "Information",
        text: [<h4 className="text-center" key="editAlert">Please Select the Template before you edit the page</h4>],
    },
    waitAlert: {
        type: "Information",
        text: [<h4 className="text-center" key="waitAlert">Please wait while the template loads on the page</h4>],
    },
    publishAlert: {
        type: "Information",
        text: [<h4 className="text-center" key="publishAlert">Please Select template for all pages before you publish your funnel</h4>],
    },
    deleteFunnelTemplateAlert: {
        type: "Confirmation",
        text: [<h5 className="text-center" key="deleteFunnelTemplateAlert">Are you sure to delete funnel Template?</h5>],
    },
    publishSuccessAlert: {
        type: "Information",
        text: [<h5 className="text-center" key="publishSuccessAlert1">The funnel has been imported successfully.</h5>,
            <h5 className="text-center" key="publishSuccessAlert2">Please save your pages before you publish your funnel</h5>],
    },
    importFunnelTemplateAlert: {
        type: "Information",
        text: [<h4 className="text-center" key="importFunnelTemplateAlert">The funnel template has been imported successfully.</h4>]
    }

};
export default modalConstants;