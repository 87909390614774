import React, { Component } from "react";
import PropTypes from "prop-types";
import "./styles.css";
export class Step1 extends Component {
    constructor(props) {
        super(props);

        this.state = {
        };

        // this._validateOnDemand = true; // this flag enables onBlur validation as user fills forms
        // this.validationCheck = this.validationCheck.bind(this);
        // this.isValidated = this.isValidated.bind(this);
    }

    componentDidMount() {}

    componentWillUnmount() {}


    render() {
        return (
            <div>
                <div className="row">
                    <div className="col-md-4">
                        <div className="ibox" >
                            <div className="ibox-content goalBox text-center">
                                <div style={{ padding: "10%" }}>
                                    <h3><img style={{ width: "10%", marginRight: "10px" }} src={require("../../../img/icons/email.svg")} alt=""/>Collect Emails</h3>
                                </div>
                                <div>
                                    <p>Build a new email list and start building an engaging email list.</p>
                                </div>
                                <div style={{ paddingRight: "20%", paddingLeft: "20%", paddingTop: "20%", }}>
                                    <button onClick={() => this.props.jumpToStep(2)} className="btn btn-primary"><i className="fa fa-plus" style={{ marginRight: "10%" }}/>Choose</button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-4">
                        <div className="ibox">
                            <div className="ibox-content goalBox text-center">
                                <div style={{ padding: "10%" }}>
                                    <h3><img style={{ width: "10%", marginRight: "10px" }} src={require("../../../img/icons/design.svg")} alt=""/>Sell Your Product</h3>
                                </div>
                                <div>
                                    <p>Sell your products or services with a variety of funnels.</p>
                                </div>
                                <div style={{ paddingRight: "20%", paddingLeft: "20%", paddingTop: "20%", }}>
                                    <button onClick={() => this.props.jumpToStep(1)} className="btn btn-primary"><i className="fa fa-plus" style={{ marginRight: "10%" }}/>Choose</button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-4">
                        <div className="ibox">
                            <div className="ibox-content goalBox text-center">
                                <div style={{ padding: "10%" }}>
                                    <h3><img style={{ width: "10%", marginRight: "10px" }} src={require("../../../img/icons/dns.svg")} alt=""/>Host Webinar</h3>
                                </div>
                                <div>
                                    <p>Get an audience at your next webinar whether it is live or automated replay.</p>
                                </div>
                                <div style={{ paddingRight: "20%", paddingLeft: "20%", paddingTop: "10%", }}>
                                    <button onClick={() => this.props.jumpToStep(2)} className="btn btn-primary"><i className="fa fa-plus" style={{ marginRight: "10%" }}/>Choose</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
Step1.propTypes = {
    updateStore: PropTypes.Object,
    getStore: PropTypes.Object,
    jumpToStep: PropTypes.func
};