
import {
    LOGOUT,
} from "../../login/actions";

import {
    SET_WELCOME_MODAL_SEEN_BY_USER,
    SHOW_WELCOME_MODAL_ON_MENU_CLICK,
    SET_WELCOME_VIDEO_URL,
    SET_WALKTHROUGH_CONFIG,
    SET_WELCOME_VIDEO_URL_FETCH_FAILED,
    SET_WALKTHROUGH_CONFIG_FAILED,
    SHOW_WALKTHROUGH_MODAL,
    SET_WALKTHROUGH_MODAL_ENABLED,
    SET_WALKTHROUGH_PEEKABOO_SEEN_BY_USER,
    SET_CRM_ENTRY_MODAL,
    SET_CRM_ENTRY_MODAL_INSIDE_VISUALIZER,
    SET_ACTIVE_FUNNEL_TITLE,
} from "./constants";

export const APP_SETTINGS_REDUCER_KEY = "APP-SETTINGS";

const initialState = {
    welcomeVideoUrl: null,
    walkthroughTiles: [],
    isWelcomeVideoUrlFetchSuccess: null,
    isWalkthroughConfigFetchSuccess: null,
    welcomeModalSeenByUser: false,
    showWelcomeModalOnMenuClick: false,
    showWalkthroughModal: false,
    walkthroughPeekabooSeenByUser: false,
    walkthroughModalEnabled: false,
    isCRMModalEnabled: false,
    isCRMModalInsideVisualizerEnabled: false,
    activeFunnelTitle: null
};
export function reducer(state = initialState, action) {
    switch (action.type) {
        case SET_WELCOME_MODAL_SEEN_BY_USER:
            return {
                ...state,
                welcomeModalSeenByUser: action.status,
            };
        case SHOW_WELCOME_MODAL_ON_MENU_CLICK:
            return {
                ...state,
                showWelcomeModalOnMenuClick: action.status,
            };
        case SET_WELCOME_VIDEO_URL: {
            return {
                ...state,
                welcomeVideoUrl: action.welcomeVideoUrl,
                isWelcomeVideoUrlFetchSuccess: true,
            };
        }
        case SET_WELCOME_VIDEO_URL_FETCH_FAILED: {
            return {
                ...state,
                isWelcomeVideoUrlFetchSuccess: false,
            };
        }
        case SET_WALKTHROUGH_MODAL_ENABLED:
            return {
                ...state,
                walkthroughModalEnabled: action.status,
            };
        case SET_WALKTHROUGH_CONFIG: {
            return {
                ...state,
                walkthroughTiles: action.walkthroughTiles,
                isWalkthroughConfigFetchSuccess: true,
            };
        }
        case SET_WALKTHROUGH_CONFIG_FAILED: {
            return {
                ...state,
                walkthroughTiles: {},
                isWalkthroughConfigFetchSuccess: false,
            };
        }
        case SHOW_WALKTHROUGH_MODAL:
            return {
                ...state,
                showWalkthroughModal: action.status,
            };
        case SET_WALKTHROUGH_PEEKABOO_SEEN_BY_USER:
            return {
                ...state,
                walkthroughPeekabooSeenByUser: true,
            };
        case SET_CRM_ENTRY_MODAL:
            return {
                ...state,
                isCRMModalEnabled: action.status
            };
        case SET_CRM_ENTRY_MODAL_INSIDE_VISUALIZER:
            return {
                ...state,
                isCRMModalInsideVisualizerEnabled: action.status
            };
        case SET_ACTIVE_FUNNEL_TITLE:
            return {
                ...state,
                activeFunnelTitle: action.value
            };
        case LOGOUT: {
            return initialState;
        }
        default:
            return state;
    }
}
