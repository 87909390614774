import { APIHelper } from "../../utilities";
import {
    loadGoogleFonts as loadGoogleFontsApi,
} from "../../api/assetApi";
import {
    GOOGLE_FONT_LOADING_SUCCESS,
} from "./constants";


export function loadGoogleFonts() {
    return function (dispatch) {
        return loadGoogleFontsApi({ headers: APIHelper.getGenericHeaders() }).then((response) => {
            dispatch({
                type: GOOGLE_FONT_LOADING_SUCCESS,
                payload: response,
            });
            return response;
        });
    };
}