export const assetConfig = (defaultConfig, initConfig) => {
    defaultConfig.assets = initConfig.assets || [];
    defaultConfig.upload = true;
    defaultConfig.uploadText = initConfig.uploadText;
    // Content to add where there is no assets to show
    // eg. 'No <b>assets</b> here, drag to upload'
    defaultConfig.noAssets = initConfig.noAssetsText || "No Assets added";
    // The name used in POST to pass uploaded files
    defaultConfig.uploadName = initConfig.uploadName || "files";
    // Custom headers to pass with the upload request
    defaultConfig.headers = initConfig.headers || {};
    // Custom parameters to pass with the upload request, eg. csrf token
    defaultConfig.params = initConfig.params || {};
    // If true, tries to add automatically uploaded assets.
    // To make it work the server should respond with a JSON containing assets
    // in a data key, eg:
    // {
    //  data: [
    //    'https://.../image.png',
    //    ...
    //    {src: 'https://.../image2.png'},
    //    ...
    //  ]
    // }
    defaultConfig.autoAdd = initConfig.autoAdd || 1;

    // Label for the add button
    defaultConfig.addBtnText = initConfig.addBtnText || "Add image";

    // Custom uploadFile function
    // @example
    // uploadFile: (e) => {
    //   var files = e.dataTransfer ? e.dataTransfer.files : e.target.files;
    //   // ...send somewhere
    // }
    defaultConfig.uploadFile = initConfig.uploadFile || "";

    // Handle the image url submit from the built-in 'Add image' form
    // @example
    // handleAdd: (textFromInput) => {
    //   // some check...
    //   editor.AssetManager.add(textFromInput);
    // }
    defaultConfig.handleAdd = initConfig.handleAdd;
    defaultConfig.remove = initConfig.remove,
    // Enable an upload dropzone on the entire editor (not document) when dragging
    // files over it
    defaultConfig.dropzone = false || 0;

    // Open the asset manager once files are been dropped via the dropzone
    defaultConfig.openAssetsOnDrop = false || 0;

    // Any dropzone content to append inside dropzone element
    // dropzoneContent: initConfig.dropzoneContent || "",
    defaultConfig.dropzoneContent = initConfig.dropzoneContent || "<div class='dropzone-inner'>Drop here your assets</div>";

    // Default title for the asset manager modal
    defaultConfig.modalTitle = initConfig.modalTitle || "Select Image";
};