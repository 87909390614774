import React, { Component } from "react";
import PropTypes from "prop-types";
import pickBy from "lodash/pickBy";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Row, Button } from "react-bootstrap";

import "../styles.css";
import * as funnelActions from "../../funnels/actions";
import { getCampaigns } from "../../api/campaignsApi";
import { modifyLabelText } from "../utilities";
import { NotificationEmitter, NOTIFICATION_EVENT_NAME } from "../../commons/notification";
import { setActiveFunnelTitle } from "../../core/app-settings/actions";
import { getFunnels, createAffiliate, updateFunnelDetails, createCampaignProduct, updateAffiliate } from "../utilities/api";
import { getActiveFunnelName } from "../../core/app-settings/selectors";

class SelectExisting extends Component {

    constructor(props) {
        super(props);
        this.state = {
            optionData: props.optionData,
            isTemplateFetched: false,
            isLoading: false,
            selectedDropdownValue: null,
            hasError: false,
            upSales: [""],
            offers: [""],
            funnelId: "1fd4bafc-e4f9-4c93-a229-3fb246d706c3",
            funnelTitle: "Test08",
            isProductPage: this.props.labelText === "Products"
        };
    }

    componentDidMount() {
        if (this.state.isProductPage) {
            !this.props.fromEditFunnel && this.props.handleSkipButton(false);
        } else {
            this.props.handleSkipButton && this.props.handleSkipButton(true);
        }
    }

    componentWillReceiveProps(nextProps) {
        this.setState({
            optionData: nextProps.selectedAffiliates ? nextProps.selectedAffiliates.length === 0 ? nextProps.optionData : this.getFilteredOptions(nextProps.optionData, nextProps.selectedAffiliates) : nextProps.optionData
        });
    }

    getFilteredOptions = (optionData, selectedAffiliates) => {
        let filteredOptionData = optionData;
        new Promise((resolve) => {
            for (let i = 0; i < selectedAffiliates.length; i++) {
                filteredOptionData = filteredOptionData.filter((option) => option.clientSourceId !== selectedAffiliates[i]);
            }
            resolve();
        }).then(() => {
            this.setState({
                optionData: filteredOptionData
            });
        });
    }

    getProduct = (id) => {
    }

    getMerchant = (id) => {
        const { setMerchantId } = this.props;
        setMerchantId(parseInt(id));
    }

    handleAffiliate = (id) => {
        const { optionData, fromEditFunnel } = this.props;
        let data = Object.assign({}, ...optionData && optionData.filter(list => parseInt(list.sourceId) === parseInt(id)));
        // if (!fromEditFunnel) {
        //     delete data.sourceId;
        //     delete data.sourceValues;
        // }
        this.setState({
            affiliateDatas: data
        });
    }

    getOffersAndUpShell = (id) => {
        const { optionData } = this.state;
        Object.assign({}, ...(optionData.filter(campaign => campaign.campaignId === parseInt(id))));
        const campaign = Object.assign({}, ...(optionData.filter(campaign => campaign.campaignId === parseInt(id))));

        if (campaign) {
            const products = campaign.products;

            let upSales = [];
            let offers = [];

            products.map((product) => {
                if (product.productType === "OFFER") {
                    offers.push(product);
                } else {
                    upSales.push(product);
                }
            });

            offers = offers.map((offer) => {
                return {
                    value: offer.campaignProductId,
                    text: offer.productName,
                    type: offer.productType
                };
            });

            upSales = upSales.map((upSale) => {
                return {
                    value: upSale.campaignProductId,
                    text: upSale.productName,
                    type: upSale.productType
                };
            });

            this.setState({
                upSales,
                offers
            });
        }
    }

    isValid = () => this.state.selectedDropdownValue

    handleSave = () => {
        const { funnelId, funnelTitle, offers, upSales, selectedDropdownValue } = this.state;
        const { activeFunnelName, setActiveFunnelTitle, updateFunnelInfo } = this.props;
        const { funnelList } = this.props;
        if (!this.isValid()) {
            this.setState({
                hasError: true
            });
        } else {
            let data = null;
            if (this.props.labelText === "Campaign") {
                const funnel = Object.assign({}, ...funnelList.filter(funnel => funnel.campaign === selectedDropdownValue));
                data = {
                    Title: funnelTitle,
                    Offer: offers,
                    author: "",
                    campaign: selectedDropdownValue && selectedDropdownValue.toString(),
                    campaignId: "",
                    funnelType: "",
                    offer: offers,
                    offerId: "",
                    status: "",
                    // title: funnel.title ? funnel.title : "New Funnel",
                    title: activeFunnelName ? activeFunnelName : funnel.title ? funnel.title : "New Funnel",
                    upsale: upSales,
                    upsaleId: "",
                };
            }
            switch (this.props.labelText) {
                case "Campaign":
                    this.setState({
                        isLoading: true
                    });
                    if (this.props.isInsideTheVisualizer) {
                        const data = this.props.funnelData;
                        let funnel = {
                            referenceId: data.referenceId,
                            title: data.title,
                            offer: JSON.stringify(offers),
                            upsale: JSON.stringify(upSales),
                            campaign: selectedDropdownValue && selectedDropdownValue.toString(),
                            status: data.status,
                            VisualizerJson: data.visualizerJson,
                            page: data.page,
                        };
                        const filteredPage = funnel.page && funnel.page.map(data => pickBy(data, v => v !== null && v !== undefined));
                        funnel = {
                            ...funnel,
                            ["page"]: filteredPage
                        };
                        // updateFunnelDetails(funnel, data.referenceId)
                        //     .then(res => {
                        //         NotificationEmitter.emit(NOTIFICATION_EVENT_NAME, { type: "success", message: "Funnel updated successfully" });
                        //         this.setState({
                        //             isLoading: false
                        //         });
                        //         this.props.closeWizard();
                        //         this.props.getFunnelData(data.referenceId);
                        //     })
                        //     .catch(err => {
                        //         NotificationEmitter.emit(NOTIFICATION_EVENT_NAME, { type: "error", message: err.response.data.message });
                        //         this.setState({
                        //             isLoading: false
                        //         });
                        //     });
                        updateFunnelInfo({ ...funnel })
                            .then(res => {
                                NotificationEmitter.emit(NOTIFICATION_EVENT_NAME, { type: "success", message: "Funnel updated successfully" });
                                this.setState({
                                    isLoading: false
                                });
                                this.props.closeWizard();
                            })
                            .catch(err => {
                                this.setState({
                                    isLoading: false
                                });
                                NotificationEmitter.emit(NOTIFICATION_EVENT_NAME, { type: "error", message: err.response.data.message });
                            });
                    } else {
                        this.props.actions.createFunnel(data)
                            .then((funnelData) => {
                                this.props.history.push({
                                    pathname: `/editfunnel/${funnelData.referenceId}`
                                });
                                setActiveFunnelTitle && setActiveFunnelTitle(null);
                                new Promise((resolve) => {
                                    NotificationEmitter.emit(NOTIFICATION_EVENT_NAME, { type: "success", message: "Funnel created successfully" });
                                    resolve();
                                }).then(() => this.props.closeWizard());
                            })
                            .catch(err => {
                                NotificationEmitter.emit(NOTIFICATION_EVENT_NAME, { type: "error", message: err.response.data.message });
                                this.props.closeWizard();
                            });
                    }
                    break;
                case "Merchant":
                    this.props.moveStep("NEXT");
                    break;
                default:
                    break;
            }
        }
    }

    handleChange = (e) => {
        this.setState({
            selectedDropdownValue: e.target.value
        });
        switch (this.props.labelText) {
            case "Campaign":
                this.getOffersAndUpShell(e.target.value);
                break;
            case "Products":
                this.getProduct(e.target.value);
                !this.props.fromEditFunnel && this.props.selectedProduct(parseInt(e.target.value) - 1);
                break;
            case "Merchant":
                this.getMerchant(e.target.value);
                break;
            case "Affiliates":
                this.handleAffiliate(e.target.value);
                break;
            default:
                break;
        }
    }

    handleSaveAffiliate = () => {
        const { fromEditFunnel, refreshData, closeModal } = this.props;
        if (!this.isValid()) {
            this.setState({
                hasError: true
            });
        } else {
            this.setState({
                isLoading: true
            });
            let { affiliateDatas } = this.state;
            affiliateDatas = { ...affiliateDatas, campaignId: this.props.campaignId };
            createAffiliate(affiliateDatas)
                .then(res => {
                    this.setState({
                        isLoading: false
                    });
                    NotificationEmitter.emit(NOTIFICATION_EVENT_NAME, { type: "success", message: "Affiliate created successfully" });
                    !fromEditFunnel && this.props.selectTemplate("AFFILIATES_LIST");
                    if (fromEditFunnel) {
                        refreshData(); closeModal();
                    }
                })
                .catch(err => {
                    NotificationEmitter.emit(NOTIFICATION_EVENT_NAME, { type: "error", message: err.response.data.message });
                    this.setState({
                        isLoading: false
                    });
                });
        }
    }

    handleUpdateAffiliate = () => {
        const { fromEditFunnel, refreshData, closeModal } = this.props;
        if (!this.isValid()) {
            this.setState({
                hasError: true
            });
        } else {
            this.setState({
                isLoading: true
            });
            let { affiliateDatas } = this.state;
            affiliateDatas = { ...affiliateDatas, campaignId: this.props.campaignId };
            updateAffiliate(affiliateDatas)
                .then(res => {
                    this.setState({
                        isLoading: false
                    });
                    NotificationEmitter.emit(NOTIFICATION_EVENT_NAME, { type: "success", message: "Affiliate created successfully" });
                    !fromEditFunnel && this.props.selectTemplate("AFFILIATES_LIST");
                    if (fromEditFunnel) {
                        refreshData(); closeModal();
                    }
                })
                .catch(err => {
                    NotificationEmitter.emit(NOTIFICATION_EVENT_NAME, { type: "error", message: err.response.data.message });
                    this.setState({
                        isLoading: false
                    });
                });
        }
    }

    handleSaveProduct = () => {
        const { campaignId } = this.props;
        if (!this.isValid()) {
            this.setState({
                hasError: true
            });
        } else {
            let product = this.state.optionData.filter((option => option.productId === parseInt(this.state.selectedDropdownValue)));
            if (this.props.fromEditFunnel) {
                let type = this.props.productType === "OFFER" ? "CreateEditOffer" : "CreateEditUpsells";
                this.props.setActiveComponent(type, "Edit", Object.assign({}, ...product), true);
            } else {
                this.props.selectTemplate("EDIT_PRODUCT", Object.assign({}, ...product));
            }
        }
    }

    renderOptions = () => {
        const { optionData } = this.state;
        return optionData && optionData.map((option, i) => <option key={i} value={option.campaignId || option.productId || option.billerId || option.sourceId}>{option.campaignName || option.productName || option.title || option.sourceTitle}</option>);
    }

    render() {
        const { selectTemplate, labelText, nextOfferUpShell, pageTitle, fromEditFunnel, optionData } = this.props;
        const { isTemplateFetched, isLoading, hasError } = this.state;
        return (
            <div className="select_existing_container">
                <div>
                    <div className={`select_label ${hasError ? "label_error" : ""}`}>{`Select Existing ${modifyLabelText(labelText)}`}</div>
                    <div className="select_save_area">
                        <select
                            disabled={!optionData}
                            className={`form-control custom_select existing_select_style ${hasError ? "select_error" : ""}`}
                            style={{ width: 641, height: 40 }}
                            placeholder="Select any Campaign"
                            onChange={this.handleChange}
                        >
                            <option selected disabled>{`Select ${modifyLabelText(labelText)}`}</option>
                            {optionData && this.renderOptions()}
                        </select>
                        <button
                            className="button_style"
                            style={{ marginLeft: 10 }}
                            onClick={() =>
                                pageTitle
                                    ? this.handleSaveProduct()
                                    : this.props.labelText === "Affiliates"
                                        ? !fromEditFunnel
                                            ? this.handleUpdateAffiliate()
                                            : this.handleUpdateAffiliate()
                                        : this.handleSave()}
                            disabled={isLoading}
                        >
                            <div className="button_text_area">
                                <span
                                    className={`${isLoading ? "loading_spinner" : ""}`}
                                ></span>
                                <span>Save & Continue</span>
                            </div>
                        </button>
                    </div>
                    { !optionData &&
                        <div className={"loading_box"} style={{ width: "25%" }}>
                            <span
                                className="loading_spinner"
                            ></span>
                            <span>Loading</span>
                        </div>
                    }
                    { fromEditFunnel &&
                        <div className="select_cancel">
                            <Button
                                type="primary"
                                className="button btn-cancel-edit-funnel text-color"
                                onClick={() => {this.props.closeModal();}}
                            >
                                Cancel
                            </Button>
                        </div>
                    }
                    { !fromEditFunnel &&
                        <div
                            className={`create_button ${this.state.isProductPage ? "button_position" : ""}`}
                        >
                            <button
                                className="button_style"
                                onClick={() => {
                                    selectTemplate("NEW");
                                }}
                            >
                                <div className="button_text_area">
                                    <span>{`Create A New ${modifyLabelText(labelText)}`}</span>
                                </div>
                            </button>
                        </div>
                    }
                </div>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        activeFunnelName: getActiveFunnelName(state)
    };
}

function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators(funnelActions, dispatch),
        setActiveFunnelTitle: (value) => dispatch(setActiveFunnelTitle(value)),
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(SelectExisting);

SelectExisting.propTypes = {
    selectTemplate: PropTypes.func,
    optionData: PropTypes.array,
    labelText: PropTypes.string.isRequired,
    moveStep: PropTypes.func,
    nextOfferUpShell: PropTypes.func,
    pageTitle: PropTypes.string,
    closeWizard: PropTypes.func,
    campaigns: PropTypes.array,
    offers: PropTypes.array,
    handleSkipButton: PropTypes.func,
    actions: PropTypes.object.isRequired,
    funnelActions: PropTypes.object.isRequired,
    history: PropTypes.object,
    selectedProduct: PropTypes.func,
    setMerchantId: PropTypes.func,
    funnelList: PropTypes.array,
    campaignId: PropTypes.number,
    isInsideTheVisualizer: PropTypes.bool,
    funnelData: PropTypes.object,
    getFunnelData: PropTypes.func,
    fromEditFunnel: PropTypes.bool,
    refreshData: PropTypes.func,
    closeModal: PropTypes.func,
    setEditData: PropTypes.func,
    setActiveComponent: PropTypes.func,
    productType: PropTypes.string,
    selectedAffiliates: PropTypes.array,
    setActiveFunnelTitle: PropTypes.func,
    activeFunnelName: PropTypes.string,
    updateFunnelInfo: PropTypes.func
};