import React, { Component } from "react";
import { Modal } from "react-bootstrap";
import PropTypes from "prop-types";

import CreateEditFulfillment from "./Fulfillment";
import CreateEditSMTP from "./SMTP";
import CreateEditProduct from "../Products/CreateEditProduct";
import CreateEditAffiliates from "../Affiliates/CreateEditAffiliates";
import CreateEditMerchant from "../Merchant/CreateEditMerchant";
import SelectExistingTemplate from "../SelectExistingTemplate";
import { getAffiliateList } from "../utilities/api";
import { getProducts } from "../../api/campaignsApi";

const config = [
    {
        title: "CreateEditFulfillment",
        component: CreateEditFulfillment,
    },
    {
        title: "CreateEditSMTP",
        component: CreateEditSMTP
    },
    {
        title: "CreateEditOffer",
        component: CreateEditProduct
    },
    {
        title: "CreateEditUpsells",
        component: CreateEditProduct
    },
    {
        title: "CreateEditAffiliates",
        component: CreateEditAffiliates
    },
    {
        title: "CreateEditMerchant",
        component: CreateEditMerchant
    },
    {
        title: "SelectExisting",
        component: SelectExistingTemplate
    },
];

class Forms extends Component {
    constructor(props) {
        super(props);
        this.state = {
            modalVisible: false,
            activeList: null,
            pageType: null,
            offers: this.getCampaignInitialState().offers.slice(),
            productList: null,
            affiliateList: null
        };
    }

    getCampaignInitialState() {
        return {
            campaign: {
                campaignName: "",
                campaignType: "LANDER",
                currency: "USD",
                campaignCategoryName: "FunnelKonnekt",
                bundleFulfillment: 0,
                blockPrepaid: 0,
                blockChargebackBlacklist: 0,
            },
            offers: [{
                productId: "choose",
                productName: "",
                productSku: "",
                billerId: "",
                useOfferMid: 0,
                billingCycleType: "ONE_TIME",
                finalBillingCycle: "",
                minOrderPrice: "",
                maxOrderPrice: "",
                maxOrderQty: "",
                trialEnabled: 0,
                productCategoryName: "",
                trialType: "STANDARD",
                trialAuthType: "",
                showToAffiliates: 0,
                cycle: [
                    {
                        price: "",
                        isShippable: 0,
                        billDelay: 30,
                        productId: "",
                        combinationId: ""
                    },
                    {
                        price: "",
                        isShippable: 0,
                        billDelay: 30,
                        productId: "",
                        combinationId: ""
                    },
                    {
                        price: "",
                        isShippable: 0,
                        billDelay: 30,
                        productId: "",
                        combinationId: ""
                    }
                ],
                acceleratedTrialMinutes: ""
            }],
            upsells: [{
                productId: "choose",
                productName: "",
                productSku: "",
                billerId: "",
                billingCycleType: "ONE_TIME",
                finalBillingCycle: "",
                minOrderPrice: "",
                maxOrderPrice: "",
                maxOrderQty: "",
                productCategoryName: "",
                trialEnabled: 0,
                useOfferMid: 0,
                trialType: "STANDARD",
                trialAuthType: "",
                showToAffiliates: 0,
                cycle: [
                    {
                        price: "",
                        isShippable: 0,
                        billDelay: 30,
                        productId: "",
                        combinationId: ""
                    },
                    {
                        price: "",
                        isShippable: 0,
                        billDelay: 30,
                        productId: "",
                        combinationId: ""
                    },
                    {
                        price: "",
                        isShippable: 0,
                        billDelay: 30,
                        productId: "",
                        combinationId: ""
                    }
                ],
                acceleratedTrialMinutes: ""
            }],
        };
    }

    fetchLists = () => {
        let campaignId = this.props.campaignId;
        // if (this.state.activeList) {

        // }
        getAffiliateList({ campaignId: null })
            .then((res) => {
                this.setState({
                    affiliateList: Array.isArray(res.data.message) ? res.data.message : null
                });
            });
        getProducts().then((res) => {
            let obj;
            let arr = [];
            for (let props in res) {
                obj = res[props];
                arr.push(obj);
            }
            this.setState({
                productList: Object.values(arr[1]),
                isTemplateFetched: true
            });
        });
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.activeComponent) {
            if (nextProps.activeComponent !== this.state.activeList) {
                this.setState({
                    modalVisible: true,
                    activeList: nextProps.activeComponent,
                    pageType: nextProps.pageType
                }, () => this.fetchLists());
            }
        }
    }

    closeModal = () => {
        this.setState({
            modalVisible: false,
            activeList: null
        }, () => {
            this.props.refreshFulfillmentList && this.props.refreshFulfillmentList();
            this.props.clearActiveList();
        });
    }

    showModal = (e, activeList) => {
        e.stopPropagation();
        this.setState({
            modalVisible: true,
            activeList
        });
    }

    renderList = () => {
        if (this.state.activeList) {
            let ListComponent = config.find(component => component.title.toLowerCase().includes(this.state.activeList.toLowerCase()));
            return <ListComponent.component pageTitle={this.props.isProduct} optionData={this.props.isAffiliate ? this.state.affiliateList : this.state.productList} labelText={`${this.props.isAffiliate ? "Affiliates" : "Products"}`} fromEditFunnel {...this.props} campaignId={this.props.campaignId} pageType={this.state.pageType} editData={this.props.editData} closeModal={this.closeModal} offers={this.state.offers} insideEditFunnel upsells={this.state.activeList === "CreateEditUpsells"} />;
        }
    }

    render() {
        const { modalVisible, activeList } = this.state;
        const { title } = this.props;
        return (
            <div>
                {modalVisible && <Modal
                    show
                    style={{ opacity: 1 }}
                    bsSize="lg"
                    className="wizard_modal"
                    aria-labelledby="contained-modal-title-vcenter"
                    dialogClassName={`${activeList === "SelectExisting" || activeList === "CreateEditSMTP" ? "custom_size custom_margin" : ""}`}
                    animation
                    onHide={() => activeList === "SelectExisting" ? this.closeModal() : null}
                >
                    <Modal.Body className="custom_modal_body">
                        <div className="modal-header-funnel-edit">
                            {title}
                        </div>
                        <div className={`modal-body-funnel-edit ${activeList === "SelectExisting" ? "min-height-500" : "min-height-700"}`}>
                            {this.renderList()}
                        </div>
                        <div className="close_button" onClick={(e) => this.closeModal(e)}>
                            <span>&times;</span>
                        </div>
                    </Modal.Body>
                </Modal>}
            </div>
        );
    }
}

export default Forms;

Forms.propTypes = {
    activeComponent: PropTypes.func,
    title: PropTypes.string,
    clearActiveList: PropTypes.func,
    editData: PropTypes.object,
    pageType: PropTypes.string,
    campaignId: PropTypes.number,
    fulfillmentDetails: PropTypes.object,
    refreshFulfillmentList: PropTypes.func,
    isAffiliate: PropTypes.bool,
    isProduct: PropTypes.bool
};