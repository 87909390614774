import React from "react";
import PropTypes from "prop-types";

const ProgressBar = ({
    progress,
}) => {
    const progressLevel = `${progress.toFixed(2)}`;
    return (
        <div className="progress-bar-container text-center" style={{ width: 300, position: "absolute", top: "50%", left: "40%" }}>
            <div className="progress">
                <div
                    className="progress-bar progress-bar-striped"
                    style={{ width: `${progressLevel}%` }}
                    role="progressbar"
                />
            </div>
            <div className="text-center">
                {
                    `Loading ${progress}%`
                }
            </div>
        </div>
    );
};

ProgressBar.propTypes = {
    progress: PropTypes.number,
};
export default ProgressBar;