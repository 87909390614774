import React, { useState, useContext } from "react";
import PropTypes from "prop-types";
import CopyShareId from "./copy-share-id";
import ImportShareId from "./import-share-id";
import CopyImportPagePopup from "./copy-import-page-popup";
import { VisualizerContext } from "../.././";

export const SharePage = ({ shareId, pageViewReferenceId, isCurrentPageEdited, importSharedIdPage }) => {
    const VisualizerContextData = useContext(VisualizerContext);
    // const {
    //     importShareIdPageOnCurrentPage
    // } = VisualizerContextData;
    const [ renderShareIdPopup, setShareIdPopup ] = useState(null);
    //const importSharedIdPage = () => importShareIdPageOnCurrentPage({ shareId, pageViewReferenceId });

    const shareIdPopupIfCurrentPageNotEdited = () => {
        return (
            <CopyImportPagePopup
                closeSharePopup={closeSharePopup}
            >
                <ImportShareId
                    shareId={shareId}
                    importSharedIdPage={importSharedIdPage}
                />
            </CopyImportPagePopup>
        );
    };
    const shareIdPopupIfCurrentPageIsEdited = () => {
        return (
            <CopyImportPagePopup
                closeSharePopup={closeSharePopup}
            >
                <CopyShareId
                    shareId={shareId}
                />
                <ImportShareId
                    shareId={shareId}
                    importSharedIdPage={importSharedIdPage}
                />
            </CopyImportPagePopup>
        );
    };
    const openSharePopup = () => {
        return isCurrentPageEdited ?
            setShareIdPopup(shareIdPopupIfCurrentPageIsEdited) :
            setShareIdPopup(shareIdPopupIfCurrentPageNotEdited);
    };
    const closeSharePopup = () => setShareIdPopup(null);
    return (
        <>
            <div className="share-section">
                <button className= "btn btn-primary" onClick={openSharePopup}>
                    <i
                        className="fa fa-share-square-o m-r-xs"
                        aria-hidden="true">
                    </i>
                    Share
                </button>
            </div>
            {
                renderShareIdPopup
            }
        </>
    );
};
SharePage.propTypes = {
    shareId: PropTypes.string,
    pageViewReferenceId: PropTypes.string,
    isCurrentPageEdited: PropTypes.bool,
    importSharedIdPage: PropTypes.func
};

export default SharePage;