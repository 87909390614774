import React from "react";
import { numberOnly } from "../utilities";
import { FormGroup, ControlLabel, FormControl, InputGroup } from "react-bootstrap";
import PropTypes from "prop-types";

export default function CommissionsInput({ title, defaultValue, name, currencySymbol, handleChange }) {
    return (
        <FormGroup>
            <ControlLabel className="custom-form-label display-flex-tooltip-icon">{title}</ControlLabel>
            <InputGroup className="form-control-addon-width">
                <InputGroup.Addon className="input-group-background">{currencySymbol}</InputGroup.Addon>
                <FormControl type="text" defaultValue={defaultValue} name={name} onChange={e => {numberOnly(e); handleChange(e);}} />
                {/* <FormControl type="text" placeholder={`Enter ${title}`} defaultValue={defaultValue} name={name} onChange={e => {numberOnly(e); handleChange(e);}} /> */}
            </InputGroup>
        </FormGroup>
    );
}

CommissionsInput.propTypes = {
    title: PropTypes.string,
    defaultValue: PropTypes.number,
    name: PropTypes.string,
    handleChange: PropTypes.func,
    currencySymbol: PropTypes.string
};