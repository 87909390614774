import React from "react";
import {
    Button
} from "react-bootstrap";
import PropTypes from "prop-types";

export default function CRMButton({ isLoading, handleClick, text }) {
    return (
        <Button
            type="primary"
            onClick={(e) => handleClick(e)}
            className="button save-button"
            disabled={isLoading}
        >
            <span
                className={`${isLoading ? "loading_spinner" : ""}`}
            ></span>
            <span>{text}</span>
        </Button>
    );
}

CRMButton.propTypes = {
    isLoading: PropTypes.bool,
    handleClick: PropTypes.func,
    text: PropTypes.string,
};
