import { combineReducers } from "redux";
import { APP_SETTINGS_REDUCER_KEY, reducer as AppSettingsReducer } from "../core/app-settings/app-settings-reducer";
import { COMMON_ASSETS_REDUCER_KEY, reducer as CommonAssetsReducer } from "../core/assets/reducer";
import { LOGIN_REDUCER_KEY, reducer as LoginReducer } from "./../login/reducer";
import { AUTHORS_REDUCER_KEY, reducer as AuthorReducer } from "./../author/reducer";
import { TEMPLATES_REDUCER_KEY, reducer as TemplateReducer } from "./../templates/reducer";
import { FUNNELS_REDUCER_KEY, reducer as FunnelsReducer } from "../funnels/reducer";
import { FUNNEL_TYPES_REDUCER_KEY, reducer as FunnelTypeReducer } from "../funnels/funnel-types/reducer";
import { CAMPAIGNS_REDUCER_KEY,reducer as CampaignReducer } from "../campaign/reducer";
import { MARKETPLACE_REDUCER_KEY, reducer as MarketplaceReducer } from "../marketplace/reducer";
import { MANAGEFUNNEL_REDUCER_KEY, reducer as ManageFunnelReducer } from "../funnels/manage-funnel/reducer";
// import { FUNNEL_REDUCER_KEY, reducer as FunnelReducer } from "../funnels/create-funnel-visualizer/reducer";
import { FUNNEL_REDUCER_KEY, reducer as FunnelReducer } from "../funnels/visualizer/reducer/visualizerReducer";
import { DASHBOARD_REDUCER_KEY, reducer as DashboardReducer } from "../dashboard/reducer";
import { DESIGNERS_REDUCER_KEY, reducer as DesignersReducer } from "../designers/reducers";
import { ASSET_REDUCER_KEY, reducer as AssetReducer } from "../webpage-creator/reducer";
import { FUNNEL_TEMPLATES_REDUCER_KEY, reducer as FunnelTemplatesReducer } from "../funnel-templates/reducer";
import { FUNNEL_TEMPLATE_REDUCER_KEY, reducer as FunnelTemplateReducer } from "../visualizer/reducers/funnel-template-reducer";
import { VISUALIZER_REDUCER_KEY, reducer as VisualizerReducer } from "../visualizer/reducers/reducer";

export const rootReducer = combineReducers({
    [APP_SETTINGS_REDUCER_KEY]: AppSettingsReducer,
    [LOGIN_REDUCER_KEY]: LoginReducer,
    [FUNNELS_REDUCER_KEY]: FunnelsReducer,
    [FUNNEL_TYPES_REDUCER_KEY]: FunnelTypeReducer,
    [AUTHORS_REDUCER_KEY]: AuthorReducer,
    [TEMPLATES_REDUCER_KEY]: TemplateReducer,
    [MARKETPLACE_REDUCER_KEY]: MarketplaceReducer,
    [FUNNEL_REDUCER_KEY]: FunnelReducer,
    [MANAGEFUNNEL_REDUCER_KEY]: ManageFunnelReducer,
    [CAMPAIGNS_REDUCER_KEY]: CampaignReducer,
    [DASHBOARD_REDUCER_KEY]: DashboardReducer,
    [DESIGNERS_REDUCER_KEY]: DesignersReducer,
    [ASSET_REDUCER_KEY]: AssetReducer,
    [FUNNEL_TEMPLATES_REDUCER_KEY]: FunnelTemplatesReducer,
    [FUNNEL_TEMPLATE_REDUCER_KEY]: FunnelTemplateReducer,
    [VISUALIZER_REDUCER_KEY]: VisualizerReducer,
    [COMMON_ASSETS_REDUCER_KEY]: CommonAssetsReducer,
});