import React, { Component } from "react";
import PropTypes from "prop-types";
import { Form as FunnelForm, AddCampaign, UpSellField, OfferField } from "../../funnel-form";
// import "../style.css";
import "../../../node_modules/react-widgets/dist/css/react-widgets.css";
import { TextInput } from "../../commons";
import VisualizerFrame from "../components/visualizer-frame";
import constants from "../utilities/constants";
import { VisualizerHeader, VisualizerZoom, VisualizerDomain } from "../components/visualizer-header";
import PopUpModal from "../../pop-up-modal";

export class FunnelVisualizer extends Component {
    static propTypes = {
        actions: PropTypes.object,
        funnel: PropTypes.object,
        campaigns: PropTypes.array,
        clearJsonData: PropTypes.func,
        clearFunnelData: PropTypes.func,
        editFunnel: PropTypes.func,
        deletePage: PropTypes.func,
        errorClose: PropTypes.func,
        resetData: PropTypes.func,
        loadCampaigns: PropTypes.func,
        getFunnelData: PropTypes.func,
        funnelType: PropTypes.object,
        importedPage: PropTypes.object,
        importedFunnel: PropTypes.object,
        authors: PropTypes.array,
        upsales: PropTypes.array,
        offers: PropTypes.array,
        jsonData: PropTypes.string,
        funnels: PropTypes.array,
        funnelReferenceId: PropTypes.string,
        salt: PropTypes.string,
        pageError: PropTypes.bool,
        isLoading: PropTypes.bool,
        isError: PropTypes.bool,
        importError: PropTypes.bool,
        importFunnel: PropTypes.func,
        importFunnelError: PropTypes.bool,
        showSuccessMessage: PropTypes.func,
        showImportFunnelSuccessMessage: PropTypes.bool,
        initializeVisualizerData: PropTypes.func,
        history: PropTypes.object,
    };
    static contextTypes = {
        router: PropTypes.object,
    };
    constructor(props, context) {
        super(props, context);
        this.state = {
            funnel: Object.assign({
                title: "",
                author: "",
                campaign: "",
                fListFunnelTypeId: "",
                offer: [""],
                upsale: [""],
                status: "TestStatus",
            }, props.funnel),
            showSettings: false,
            allOffers: props.offers,
            allUpsales: props.upsales,
            errors: {},
        };
        this.onOfferChange = this.onOfferChange.bind(this);
        this.onUpsaleChange = this.onUpsaleChange.bind(this);
        this.removeOfferField = this.removeOfferField.bind(this);
        this.removeUpsaleField = this.removeUpsaleField.bind(this);
        this.validate = this.validate.bind(this);
        this.onCampaignChange = this.onCampaignChange.bind(this);
        this.navigateToManageFunnel = this.navigateToManageFunnel.bind(this);
        this.props.clearJsonData();
    }
    componentWillMount() {
        this.props.initializeVisualizerData();
    }
    componentDidMount() {
        this.props.getFunnelData(this.props.funnelReferenceId);
        this.props.loadCampaigns();
    }
    componentWillReceiveProps(nextProps) {
        if (nextProps.funnel.referenceId !== this.props.funnel.referenceId) {
            this.setState({
                funnel: nextProps.funnel,
                isError: nextProps.isError,
            });
        }
        if (nextProps.isError !== this.state.isError) {
            this.setState({
                isError: nextProps.isError,
            });
        }

        if ((nextProps.funnel.referenceId !== this.props.funnel.referenceId) && (nextProps.campaigns.length && nextProps.campaigns !== this.props.campaigns)) {
            let offersList = [], upsaleList = [];
            const selectedCampaign = nextProps.campaigns.find((c) => nextProps.funnel.campaign === `${c.value}`);
            if (selectedCampaign) {
                const productOffer = selectedCampaign.products.filter(p => p.productType === "OFFER");
                const productUpsell = selectedCampaign.products.filter(p => p.productType === "UPSALE");
                offersList = productOffer.map(product => {
                    return {
                        value: product.campaignProductId,
                        text: product.productName,
                        type: product.productType
                    };
                });
                upsaleList = productUpsell.map(product => {
                    return {
                        value: product.campaignProductId,
                        text: product.productName,
                        type: product.productType
                    };
                });
            }
            this.setState({ allOffers: offersList, allUpsales: upsaleList });
        }
        {
            this.state.showSettings &&
                !nextProps.isLoading &&
                this.setState({ showSettings: false });
        }
    }
    componentWillUnmount() {
        this.props.clearFunnelData();
    }
    navigateToManageFunnel(referenceId) {
        this.props.history.push(`/manage-funnel/${referenceId}`);
    }
    navigateToError() {
        this.props.history.push("/404");
    }
    updateFunnelState = (event) => {
        const field = event.target.name;
        let funnel = Object.assign({}, this.state.funnel);
        funnel[field] = event.target.value;
        return this.setState({ funnel: funnel });
    }

    validate(event) {
        const funnel = this.state.funnel;
        let offerValues = [];
        let upsaleValues = [];
        (!funnel.title) ? this.setState({ errors: { title: true, isSuccess: false } }) :
            (!funnel.campaign) ? this.setState({ errors: { campaignId: true, isSuccess: false } }) :
                (!funnel.fListFunnelTypeId) ? this.setState({ errors: { funnelType: true, isSuccess: false } }) :
                    (funnel.offer[0] === "") ? this.setState({ errors: { offerId: true, isSuccess: false } }) :
                        (funnel.offer.length > 1) && Array.isArray(funnel.offer) ? funnel.offer.forEach((product, index) => {
                            if (product === "" || Object.keys(product).length === 0 && product.constructor === Object) {
                                funnel.offer.splice(index, 1);
                            }
                            offerValues.push(product.value);
                            if (funnel.offer.length - 1 === index) {
                                offerValues = offerValues.sort();
                                if (offerValues.length > 0) {
                                    offerValues.map((value, index) => {
                                        if (index !== 0 && value === offerValues[index - 1]) {
                                            let removedValue = funnel.offer.find((offer) => {
                                                return offer.value === value;
                                            });
                                            funnel.offer.splice(removedValue, 1);
                                        }
                                    });
                                }
                                this.setState({ isSuccess: true }, this.saveEditedFunnel(event));
                            }
                        }) :
                            (funnel.upsale.length > 1) && Array.isArray(funnel.upsale) ? funnel.upsale.forEach((product, index) => {
                                if (product === "" || Object.keys(product).length === 0 && product.constructor === Object) {
                                    funnel.upsale.splice(index, 1);
                                }
                                upsaleValues.push(product.value);
                                if (funnel.upsale.length - 1 === index) {
                                    upsaleValues = upsaleValues.sort();
                                    if (upsaleValues.length > 0) {
                                        upsaleValues.map((value, index) => {
                                            if (index !== 0 && value === upsaleValues[index - 1]) {
                                                let removedValue = funnel.upsale.find((upsale) => {
                                                    return upsale.value === value;
                                                });
                                                funnel.upsale.splice(removedValue, 1);
                                            }
                                        });
                                    }
                                    this.setState({ isSuccess: true }, this.saveEditedFunnel(event));
                                }
                            }) :
                                this.setState({ isSuccess: true }, this.saveEditedFunnel(event));
    }
    onCampaignChange(event) {
        const field = event.target.name;
        const { funnel } = this.state;
        let offersList = [];
        let upsaleList = [];
        const selectedCampaign = this.props.campaigns.find((c) => event.target.value === `${c.value}`);
        if (selectedCampaign) {
            const productOffer = selectedCampaign.products.filter(p => p.productType === "OFFER");
            const productUpsell = selectedCampaign.products.filter(p => p.productType === "UPSALE");
            offersList = productOffer.map(product => {
                return {
                    value: product.campaignProductId,
                    text: product.productName,
                    type: product.productType
                };
            });
            upsaleList = productUpsell.map(product => {
                return {
                    value: product.campaignProductId,
                    text: product.productName,
                    type: product.productType
                };
            });
        }
        return this.setState({
            funnel: {
                ...funnel,
                [field]: event.target.value,
                offer: [""],
                upsale: [""],
            },
            allOffers: offersList,
            allUpsales: upsaleList
        });
    }
    onOfferChange(event, index) {
        const value = event.target.value;
        const offers = this.state.allOffers.find(o => `${o.value}` === value);
        const { funnel } = this.state;
        const { offer } = funnel;
        let myoffer = offer;
        if (offer && !Array.isArray(offer)) {
            myoffer = JSON.parse(offer);
        }
        if (value === "all") {
            const offers = this.state.allOffers;
            myoffer.splice(index, 1);
            offers.forEach(oneOffer => {
                if (oneOffer.value !== "all" && oneOffer !== "") {
                    myoffer.splice(0, 0, oneOffer);
                }
            });
        } else {
            myoffer.splice(index, 1, offers);
        }
        return this.setState({
            funnel: {
                ...funnel,
                offer: myoffer,
            }, errors: { offerId: false }
        });
    }
    onUpsaleChange(event, index) {
        const value = event.target.value;
        const upsell = this.state.allUpsales.find(o => `${o.value}` === value);
        const { funnel } = this.state;
        const { upsale } = funnel;
        let myupsale = upsale;
        if (upsale && !Array.isArray(upsale)) {
            myupsale = JSON.parse(upsale);
        }
        if (value === "all") {
            const upsells = this.state.allUpsales;
            myupsale.splice(index, 1);
            upsells.forEach(oneUpsell => {
                if (oneUpsell.value !== "all" && oneUpsell !== "") {
                    myupsale.splice(0, 0, oneUpsell);
                }
            });
        } else {
            myupsale.splice(index, 1, upsell);
        }
        if (funnel.upsale[0] !== "") {
            this.setState({ isSuccess: true, errors: { upsaleId: false } });
        } else {this.setState({ isSuccess: false });}
        return this.setState({
            funnel: {
                ...funnel,
                upsale: myupsale
            }
        });
    }
    saveEditedFunnel() {
        const { funnel } = this.state;
        const filteredPageList = this.state.funnel.page.filter((data) => {
            return data.title === "Checkout Page" || data.title === "Upsell Page";
        });
        this.props.resetData({ filteredPageList, funnelReferenceId: funnel.referenceId, salt: this.props.salt }, () => {
            this.props.editFunnel({ funnel });
        });
    }
    cancel = (event) => {
        event.preventDefault();
        this.setState({ showSettings: false });
    }
    addOfferField = () => {
        const { funnel } = this.state;
        const { offer } = funnel;
        let myoffer = offer;
        if (offer && !Array.isArray(offer)) {
            myoffer = JSON.parse(offer);
        }
        myoffer.push({});
        this.setState({
            funnel: {
                ...funnel,
                offer: myoffer
            }
        });
    }
    addUpsaleField = () => {
        const { funnel } = this.state;
        const { upsale } = funnel;
        let myupsale = upsale;
        if (upsale && !Array.isArray(upsale)) {
            myupsale = JSON.parse(upsale);
        }
        myupsale.push({});
        this.setState({
            funnel: {
                ...funnel,
                upsale: myupsale
            }
        });
    }
    removeOfferField = (index) => {
        const { funnel } = this.state;
        const { offer } = funnel;
        let myoffer = offer;
        if (offer && !Array.isArray(offer)) {
            myoffer = JSON.parse(offer);
        }
        myoffer.splice(index, 1);
        this.setState({
            funnel: {
                ...funnel,
                offer: myoffer
            }
        });
    }
    removeUpsaleField = (index) => {
        const { funnel } = this.state;
        const { upsale } = funnel;
        let myupsale = upsale;
        if (upsale && !Array.isArray(upsale)) {
            myupsale = JSON.parse(upsale);
        }
        myupsale.splice(index, 1);
        this.setState({
            funnel: {
                ...funnel,
                upsale: myupsale
            }
        });
    }
    toggleSettingsView = () => {
        this.setState({
            showSettings: !this.state.showSettings
        });
    }

    shareFunnel = () => {
        this.setState(prevState => ({
            isShowShare: !prevState.isShowShare,
        }));
    }

    closeMessage = () => {
        this.props.errorClose();
    }

    copyShareId = () => {
        this.setState({ isCopied: true }, () => {
            const text = document.querySelector("#shareFunnelId");
            text.select();
            document.execCommand("copy");
            text.blur();
            setTimeout(() => {this.setState({ isCopied: false });}, 2000);
        });
    }
    handleImportShareIdChange = (e) => {
        this.setState({ importShareFunnelId: e.target.value });
    }
    importShareId = () => {
        const { funnel } = this.props;
        const referenceId = funnel.referenceId;
        const importId = document.querySelector("#importFunnelId").value;
        if ((importId) && (importId !== funnel.referenceId)) {
            this.props.importFunnel({ referenceId, importId })
                .then(this.props.getFunnelData(this.props.funnelReferenceId));
            this.shareFunnel();
        } else {
            this.setState({ isShareIdError: true });
        }
    }
    closeSuccessMessage = () => {
        this.props.showSuccessMessage(false);
    }


    render() {
        const { isLoading, isError, pageError, importError, funnel, showImportFunnelSuccessMessage } = this.props;
        const { isShowShare, isShareIdError, isCopied, importShareFunnelId,
        } = this.state;
        const settingsToggleClass = this.state.showSettings ? "funnelSettings active" : "funnelSettings";
        const shareClass = isShowShare ? "share-funnel active" : "share-funnel";

        const copyMessage = isCopied ? "copy-message active" : "copy-message";
        const errorShow = pageError || importError ? "page-error-message active" : "page-error-message";
        const { importFunnelIdLabel, shareDiffferentFunnelIdMessage, enterFunnelId, shareFunnelNote, shareIdCopiedMessage, importFunnelLabel, copyLabel } = constants;

        return <>
            <div className={errorShow}>
                <h4 className="m-n error-heading"><i className="fa fa-info-circle fa-lg m-r-xs" /> {importError ? constants.importAlert : constants.pageCreationAlert}</h4>
                <button onClick={this.closeMessage} className="btn btn-outline"><i className="fa fa-close fa-lg m-l-lg" /></button>
            </div>
            {pageError || importError &&
                <div className="layer"></div>
            }
            {isLoading &&
                <div className="loader">
                </div>
            }

            <div className="row dashboard-wrapper relative">
                <div className={shareClass}>
                    <div className={copyMessage}>{shareIdCopiedMessage}</div>
                    <span className="pointer pull-right" onClick={this.shareFunnel}><i className="fa fa-close" aria-hidden="true"></i></span>
                    <TextInput
                        name="shareFunnelId"
                        label={"Share Funnel Id"}
                        value={funnel.referenceId}
                        id="shareFunnelId"
                        note={shareFunnelNote}
                    />
                    <div className="text-right"><button className="btn btn-primary" onClick={this.copyShareId}><i className="fa fa-clone m-r-xs" aria-hidden="true"></i> {copyLabel}</button></div>
                    <hr className="m-b-xs" />
                    <hr className="m-t-xs" />
                    <TextInput
                        name="importFunnelId"
                        label={importFunnelIdLabel ? importFunnelIdLabel : ""}
                        value={importShareFunnelId}
                        onChange={this.handleImportShareIdChange}
                        id="importFunnelId"
                        error={isShareIdError ? true : false}
                        errorMessage={(importShareFunnelId === funnel.referenceId) ? shareDiffferentFunnelIdMessage : enterFunnelId}
                    />
                    <div className="text-right"><button className="btn btn-primary" onClick={this.importShareId}><i className="fa fa-download m-r-xs" aria-hidden="true"></i>{importFunnelLabel}</button></div>
                </div>
                <div className={settingsToggleClass}>
                    <FunnelForm isDefaultOptionEnabled={true}
                        isEdit={false}
                        funnel={this.state.funnel}
                        onChange={this.updateFunnelState}
                        onSave={this.validate}
                        onCancel={this.cancel}
                        errors={this.state.errors}
                        isLoading={isLoading}
                        isError={isError} >
                        <AddCampaign
                            isEdit={false}
                            errors={this.state.errors}
                            funnel={this.state.funnel}
                            allCampaigns={this.props.campaigns}
                            onCampaignChange={this.onCampaignChange}
                            isDefaultOptionEnabled={true}
                        />
                        <OfferField
                            isEdit={false}
                            errors={this.state.errors}
                            isDefaultOptionEnabled={true}
                            funnel={this.state.funnel}
                            allOffers={this.state.allOffers}
                            addOfferField={this.addOfferField}
                            onOfferChange={this.onOfferChange}
                            removeOfferField={this.removeOfferField}
                        />
                        <UpSellField
                            isEdit={false}
                            errors={this.state.errors}
                            isDefaultOptionEnabled={true}
                            funnel={this.state.funnel}
                            allUpsells={this.state.allUpsales}
                            addUpsellField={this.addUpsaleField}
                            onUpsellChange={this.onUpsaleChange}
                            removeUpsField={this.removeUpsaleField}
                        />
                    </FunnelForm>
                </div>
                <VisualizerHeader parent="funnel"
                    editFunnel={this.toggleSettingsView}
                >
                    <VisualizerZoom parent="funnel" />
                    <VisualizerDomain shareFunnel={this.shareFunnel} />
                </VisualizerHeader>
                <VisualizerFrame parent="funnel"
                />
            </div>
            <PopUpModal
                modalToShow={"publishSuccessAlert"}
                showModalWindow={showImportFunnelSuccessMessage}
                handleClose={this.closeSuccessMessage}
            >
            </PopUpModal>
        </>;
    }
}
