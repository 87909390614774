import { Settings as SettingsView } from "./settings";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import * as actions from "../login/actions";
import {
    addAndSetDomainForFunnel
} from "../funnels/visualizer/actions/visualizerActions";

import { getUserData, getSettingsData, getUserSetting } from "../login/selectors";


const mapStateToProps = (state) => {
    return {
        userData: getUserData(state).fTenant,
        settingsData: getSettingsData(state),
        userSetting: getUserSetting(state),
        isLoading: state.LOGIN.isLoading,
        error: state.LOGIN.isAccountError,
        domains: state.LOGIN.domains,
        domain: state.LOGIN.domain,
        editDomainStateMessage: state.LOGIN.response,
        isCreateDomainError: state.LOGIN.isCreateDomainError,
        isLoadingDomain: state.LOGIN.isLoadingDomain
    };
};

function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators(actions, dispatch),
        getAccountInfo: () => dispatch(actions.getAccountInfo()),
        putAccountInfo: (accountStore) => dispatch(actions.putAccountInfo(accountStore)),
        loadDomains: () => dispatch(actions.loadDomains()),
        addAndSetDomainForFunnelApi: (domainData) => addAndSetDomainForFunnel(domainData),
        editDomain: (editedDomain) => dispatch(actions.editDomain(editedDomain)),
        createDomain: (domainName) => dispatch(actions.createDomain(domainName)),
        deleteDomain: (domainReferenceId) => dispatch(actions.deleteDomain(domainReferenceId)),
        getDomainStatus: (domainReferenceId) => dispatch(actions.getDomainStatus(domainReferenceId)),
        resetDomainStateMessage: () => dispatch(actions.resetDomainStateMessage()),
        resetCreateDomainErrorMessage: () => dispatch(actions.resetCreateDomainErrorMessage()),
        loadCompany: () => dispatch(actions.loadCompany())
    };
}
export const Settings = connect(mapStateToProps, mapDispatchToProps)(SettingsView);

