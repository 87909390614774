import React, { Component } from "react";
import { Button, FormGroup, ControlLabel, Col, Row, FormControl, InputGroup } from "react-bootstrap";
import PropTypes from "prop-types";

import LabelBox from "../../label-box";
import ToolTip from "../../ToolTip";
import { createAffiliate, updateAffiliate } from "../../utilities/api";
import { NotificationEmitter, NOTIFICATION_EVENT_NAME } from "../../../commons/notification";
import { numberOnly } from "../../utilities";
import CommissionsInput from "../../CommissionsInput";
import CRMButton from "../../CRMButton";

class CreateEditAffiliates extends Component {

    constructor(props) {
        super(props);
        this.state = {
            isLoading: false,
            affiliateList: null,
            affiliate: Object.assign({}, this.getInitialState(this.props.pageType === "EDIT" || this.props.type === "EDIT")),
            sourcesArray: null
        };
    }

    componentDidMount() {
        const { affiliate: { sourceValue1, sourceValue2, sourceValue3, sourceValue4, sourceValue5, publisher, subAffiliate } } = this.state;
        if (this.props.pageType === "step-wizard") {
            this.props.handleSkipButton(false);
        }
        this.generateArray([sourceValue1, sourceValue2, sourceValue3, sourceValue4, sourceValue5], publisher, subAffiliate);
    }

    generateArray = (sources, publisher, subAffiliate) => {
        let sourcesArray = [];
        for (let i = 1; i <= 5; i++) {
            sourcesArray.push({
                [`sourceValue${i}`]: sources[i - 1],
                isPublisher: (publisher).toString() === i.toString() ? true : false,
                isSubAffiliate: (subAffiliate).toString() === i.toString() ? true : false,
                name: `sourceValue${i}`,
                isPublisherDisabled: i >= parseInt(subAffiliate),
                isSubAffiliateDisabled: i <= parseInt(publisher)
            });
        }
        this.setState({
            sourcesArray
        });
    }

    getInitialState = (value) => {
        let initialValue = {
            clientSourceId: "",
            sourceTitle: "",
            sourceValue1: "",
            sourceValue2: "",
            sourceValue3: "",
            sourceValue4: "",
            sourceValue5: "",
            publisher: "",
            subAffiliate: "",
            step1Commission: 0,
            step2Commission: 0,
            cpcCommission: 0,
            campaignId: this.props.campaignId
        };
        if (!value) {
            return {
                ...initialValue
            };
        } else {
            return {
                ...initialValue,
                ...this.props.affiliate
            };
        }
    }

    editSource = (e, i, name) => {
        let sourcesArray = this.state.sourcesArray;
        if (name) {
            this.setState({
                affiliate: {
                    ...this.state.affiliate,
                    [name]: (i).toString()
                }
            });
        }
        if (e.target.type === "checkbox") {
            let siblingsValue = name === "publisher" ? "isSubAffiliate" : "isPublisher";
            sourcesArray = sourcesArray.map((source, index) => {
                if (name === "subAffiliate") {
                    return source.name === `sourceValue${i}`
                        ? {
                            ...source,
                            [siblingsValue]: false,
                            isPublisherDisabled: e.target.checked ? true : false,
                            [e.target.name]: e.target.checked
                        }
                        : {
                            ...source,
                            isPublisherDisabled: e.target.checked ? (index + 1) >= i : false,
                            [siblingsValue]: (index + 1) >= i ? false : source.isPublisher,
                            [e.target.name]: false
                        };
                } else {
                    return source.name === `sourceValue${i}`
                        ? {
                            ...source,
                            [siblingsValue]: false,
                            isSubAffiliateDisabled: e.target.checked ? true : false,
                            [e.target.name]: e.target.checked
                        }
                        : {
                            ...source,
                            isSubAffiliateDisabled: e.target.checked ? (index + 1) < i : false,
                            [siblingsValue]: (index + 1) < i ? false : source.isSubAffiliate,
                            [e.target.name]: false
                        };
                    // if (e.target.checked) {
                    //     return source.name === `sourceValue${i}`
                    //         ? {
                    //             ...source,
                    //             [siblingsValue]: false,
                    //             isSubAffiliateDisabled: e.target.checked ? true : false,
                    //             [e.target.name]: e.target.checked
                    //         }
                    //         : {
                    //             ...source,
                    //             isSubAffiliateDisabled: e.target.checked ? (index + 1) < i : false,
                    //             [e.target.name]: false
                    //         };
                    // } else {
                    //     return {
                    //         ...source,
                    //         // [siblingsValue]: false,
                    //         isPublisherDisabled: false,
                    //         isSubAffiliateDisabled: false,
                    //         [e.target.name]: false
                    //     };
                    // }
                }
            });
            this.setState({
                sourcesArray
            });
            return;
        }
        sourcesArray = sourcesArray.map((source) => {
            return source.name === `sourceValue${i + 1}`
                ? { ...source, [e.target.name]: e.target.value }
                : source;
        }
        );
        this.setState({
            sourcesArray
        });
    }

    renderRow = () => {
        const { sourcesArray } = this.state;
        return sourcesArray && sourcesArray.map((source, i) => (
            <tr>
                <td>
                    <div className="flex">{i + 1}</div>
                </td>
                <td>
                    <div className="flex">
                        <FormControl
                            type="text"
                            onChange={(e) => this.editSource(e, i)}
                            defaultValue={source[`sourceValue${i + 1}`]}
                            name={`sourceValue${i + 1}`}
                        />
                    </div>
                </td>
                <td>
                    <div className="flex">
                        <div className="checkbox checkbox-success checkbox-md custom-checkbox">
                            <label class="custom-checkbox">
                                <div className="check-box-label"></div>
                                <input
                                    checked={source.isPublisher}
                                    type="checkbox"
                                    disabled={source.isPublisherDisabled}
                                    name="isPublisher"
                                    onChange={e => this.editSource(e, (i + 1), "publisher")}
                                />
                                <span className={`check-box ${source.isPublisherDisabled ? "disabled_checkbox" : ""}`}></span>
                            </label>
                        </div>
                    </div>
                </td>
                <td>
                    <div className="flex">
                        <div className="checkbox checkbox-success checkbox-md custom-checkbox">
                            <label class="custom-checkbox">
                                <div className="check-box-label"></div>
                                <input
                                    checked={source.isSubAffiliate}
                                    type="checkbox"
                                    name="isSubAffiliate"
                                    disabled={source.isSubAffiliateDisabled}
                                    onChange={e => this.editSource(e, (i + 1), "subAffiliate")}
                                />
                                <span className={`check-box ${source.isSubAffiliateDisabled ? "disabled_checkbox" : ""}`}></span>
                            </label>
                        </div>
                    </div>
                </td>
            </tr>
        ));
    }

    handleSubmit = (e) => {
        e.preventDefault();
        const {
            affiliate: {
                sourceTitle,
                clientSourceId,
                campaignId,
                step1Commission,
                step2Commission,
                cpcCommission
            },
            sourcesArray
        } = this.state;
        const { pageType, closeModal, closeWizard } = this.props;
        this.setState({
            isLoading: true
        });
        let data = {
            clientSourceId: clientSourceId,
            sourceTitle: sourceTitle,
            sourceValue1: sourcesArray[0].sourceValue1,
            sourceValue2: sourcesArray[1].sourceValue2,
            sourceValue3: sourcesArray[2].sourceValue3,
            sourceValue4: sourcesArray[3].sourceValue4,
            sourceValue5: sourcesArray[4].sourceValue5,
            publisher: (sourcesArray.findIndex((source => source.isPublisher)) + 1),
            subAffiliate: (sourcesArray.findIndex((source => source.isSubAffiliate)) + 1),
            campaignId: campaignId,
            step1Commission: step1Commission,
            step2Commission: step2Commission,
            cpcCommission: cpcCommission
        };
        if (data.subAffiliate === 0) {
            delete data.subAffiliate;
        }
        createAffiliate(data)
            .then(res => {
                this.setState({
                    isLoading: false
                });
                NotificationEmitter.emit(NOTIFICATION_EVENT_NAME, { type: "success", message: "Affiliate created successfully" });
                pageType === "step-wizard" ? this.props.selectTemplate("AFFILIATES_LIST") : closeModal(e);
                this.props.refreshData && this.props.refreshData();
            })
            .catch(err => {
                this.setState({
                    isLoading: false
                });
                NotificationEmitter.emit(NOTIFICATION_EVENT_NAME, { type: "error", message: err.response.data.message });
            });
    }

    handleUpdate = (e) => {
        e.preventDefault();
        const {
            affiliate: {
                sourceTitle,
                clientSourceId,
                campaignId,
                step1Commission,
                step2Commission,
                cpcCommission,
                sourceId
            },
            sourcesArray
        } = this.state;
        const { pageType, closeModal, closeWizard } = this.props;
        this.setState({
            isLoading: true
        });
        let data = {
            clientSourceId: clientSourceId,
            sourceTitle: sourceTitle,
            sourceValue1: sourcesArray[0].sourceValue1,
            sourceValue2: sourcesArray[1].sourceValue2,
            sourceValue3: sourcesArray[2].sourceValue3,
            sourceValue4: sourcesArray[3].sourceValue4,
            sourceValue5: sourcesArray[4].sourceValue5,
            publisher: (sourcesArray.findIndex((source => source.isPublisher)) + 1),
            subAffiliate: (sourcesArray.findIndex((source => source.isSubAffiliate)) + 1),
            campaignId: campaignId,
            step1Commission: step1Commission,
            step2Commission: step2Commission,
            cpcCommission: cpcCommission,
            sourceId: sourceId
        };
        if (data.subAffiliate === 0) {
            delete data.subAffiliate;
        }

        updateAffiliate(data)
            .then(res => {
                this.setState({
                    isLoading: false
                });
                NotificationEmitter.emit(NOTIFICATION_EVENT_NAME, { type: "success", message: "Affiliate updated successfully" });
                pageType === "step-wizard" ? this.props.selectTemplate("AFFILIATES_LIST") : closeModal(e);
                this.props.refreshData();
            })
            .catch(err => {
                NotificationEmitter.emit(NOTIFICATION_EVENT_NAME, { type: "error", message: err.response.data.message });
                this.setState({
                    isLoading: false
                });
            });
    }

    handleChange = e => {
        this.setState({
            affiliate: {
                ...this.state.affiliate,
                [e.target.name]: e.target.value
            }
        });
    }

    render() {
        const { isLoading,
            sourcesArray,
            affiliate: {
                sourceTitle,
                clientSourceId,
                step1Commission,
                step2Commission,
                cpcCommission
            }
        } = this.state;
        const { selectTemplate, closeModal, pageType, type, closeWizard } = this.props;
        let sourceValue1, sourceValue2, sourceValue3, sourceValue4, sourceValue5;
        if (sourcesArray) {
            sourceValue1 = sourcesArray[0].sourceValue1;
            sourceValue2 = sourcesArray[1].sourceValue2;
            sourceValue3 = sourcesArray[2].sourceValue3;
            sourceValue4 = sourcesArray[3].sourceValue4;
            sourceValue5 = sourcesArray[4].sourceValue5;
        }
        return (
            <div className="affiliate_container">
                <div className="container-fluid page">
                    <div ref="campaign"></div>
                    {
                        pageType === "step-wizard" ? (
                            <h4 className="campaign-heading">
                                {type === "ADD" ? "Create New" : "Edit"} Affiliate
                            </h4>
                        ) : null
                    }
                    <form>
                        <Row style={{ padding: "0 18px" }}>
                            <Col md={6} className="pbc-4">
                                <FormGroup>
                                    <ControlLabel className="custom-form-label">Title</ControlLabel>
                                    <FormControl
                                        type="text"
                                        name="sourceTitle"
                                        defaultValue={sourceTitle}
                                        onChange={e => {this.handleChange(e);}}
                                    />
                                </FormGroup>
                            </Col>

                            <Col md={6} className="pbc-4">
                                <FormGroup>
                                    <ControlLabel className="custom-form-label">Affiliate Id</ControlLabel>
                                    <FormControl
                                        type="text"
                                        name="clientSourceId"
                                        defaultValue={clientSourceId}
                                        onChange={e => {this.handleChange(e);}}
                                    />
                                </FormGroup>
                            </Col>

                            <Col md={12} className="pbc-4 pt-20">
                                <span className="bold fs-15">Tracking String</span> :
                                {
                                    sourcesArray &&
                                    `?affId=${clientSourceId ? clientSourceId : ""}${sourceValue1 ? `&${sourceValue1}=[${sourceValue1}]` : ""}${sourceValue2 ? `&${sourceValue2}=[${sourceValue2}]` : ""}${
                                        sourceValue3 ? `&${sourceValue3}=[${sourceValue3}]` : ""
                                    }${sourceValue4 ? `&${sourceValue4}=[${sourceValue4}]` : ""}${sourceValue5 ? `&${sourceValue5}=[${sourceValue5}]` : ""}`
                                }
                            </Col>

                            <Col md={12} className="pbc-4 pt-20">
                                <LabelBox>
                                    Passed Values
                                    <ToolTip
                                        title="Passed Values"
                                        content="These are values that the affiliate will send with traffic via the tracking string attached to the end of your website URL. The numbered values correspond to the sourceValue1-5 parameters for Import Lead and Import Order API calls. You can change the value names and mark one as the publisher and one as the sub affiliate."
                                        placement="right"
                                    />
                                </LabelBox>
                            </Col>

                            <Col md={12} className="pbc-4 pt-20">
                                <table className="table crm-table">
                                    <thead>
                                        <tr>
                                            <th>#</th>
                                            <th>Value</th>
                                            <th>Is Publisher</th>
                                            <th>Is Sub-Affiliate</th>
                                            <th>{" "}</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {sourcesArray && this.renderRow()}
                                    </tbody>
                                </table>
                            </Col>
                            <Col md={12} className="pbc-4 pt-15">
                                <LabelBox>
                                    Commissions
                                </LabelBox>
                            </Col>
                            <Col md={2} className="pbc-4 pt-20">
                                <CommissionsInput title="CPC" name="cpcCommission" defaultValue={cpcCommission} currencySymbol={this.props.currencySymbol ? this.props.currencySymbol : "$" } handleChange={(e) => this.handleChange(e)}/>
                            </Col>
                            <Col md={2} className="pbc-4 pt-20">
                                <CommissionsInput title="Step 1" name="step1Commission" defaultValue={step1Commission} currencySymbol={this.props.currencySymbol ? this.props.currencySymbol : "$" } handleChange={(e) => this.handleChange(e)}/>
                            </Col>
                            <Col md={2} className="pbc-4 pt-20">
                                <CommissionsInput title="Step 2" name="step2Commission" defaultValue={step2Commission} currencySymbol={this.props.currencySymbol ? this.props.currencySymbol : "$" } handleChange={(e) => this.handleChange(e)}/>
                            </Col>
                        </Row>
                        <Row className="footer" style={{ paddingRight: 15 }}>
                            <div className="pr-0">
                                <Button className="cancelButton" onClick={(e) => {pageType === "step-wizard" ? selectTemplate("AFFILIATES_LIST") : closeModal(e);}}>{pageType === "step-wizard" ? "Cancel" : "Cancel"}</Button>
                            </div>
                            <div className="pl-0">
                                <CRMButton isLoading={isLoading} handleClick={(e) => pageType === "EDIT" || type === "EDIT" ? this.handleUpdate(e) : this.handleSubmit(e)} text="Save & Continue"/>
                            </div>
                        </Row>
                    </form>
                </div>
            </div>
        );
    }
}

export default CreateEditAffiliates;

CreateEditAffiliates.propTypes = {
    handleSkipButton: PropTypes.func,
    closeModal: PropTypes.func,
    selectTemplate: PropTypes.func,
    pageType: PropTypes.string,
    type: PropTypes.string,
    closeWizard: PropTypes.func,
    currencySymbol: PropTypes.string,
    affiliate: PropTypes.object,
    refreshData: PropTypes.func,
    campaignId: PropTypes.number
};
