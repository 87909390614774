import * as api from "../../api/funnelApi";
import * as savePageApi from "../../api/savePageApi";
import { APIHelper, LocalStorageHelper } from "../../utilities";
import * as actions from "./actions";
import { IMPORT_FUNNEL_SUCCESS_MESSAGE, CLEAR_JSON_DATA } from "./type";

export function getFunnel(funnelReferenceId, callback) {
    return function (dispatch) {
        dispatch(actions.toggleLoading(true));
        return api.retrieveFunnel(funnelReferenceId, { headers: APIHelper.getGenericHeaders() }).then(funnelResponse => {
            const localStorage = new LocalStorageHelper();
            api.funnelMetaUrl(funnelResponse.metaJson, { headers: APIHelper.getGenericHeaders() }).then((response) => {
                dispatch(actions.loadMetaJsonSuccess(response));
                dispatch(actions.getFunnelSuccess({ funnelData: { ...funnelResponse, metaJsonUrl: response } }));
                setTimeout (function () {dispatch(actions.toggleLoading(false));}, 1200);
                dispatch(actions.savePageViewSuccess(true));
                setTimeout (function () {dispatch(actions.savePageViewSuccess(false));}, 500);
            });
            callback && callback();
        }).catch(error => {
            dispatch(actions.toggleLoading(false));
            throw (error);
        });
    };
}

export function getPages() {
    return function (dispatch) {
        // dispatch(toggleLoading(true));
        return api.getPages({ headers: APIHelper.getGenericHeaders() }).then(response => {
            dispatch(actions.getPagesSuccess({ pages: response }));
            setTimeout (function () {dispatch(actions.toggleLoading(false));}, 2000);
        }).catch(error => {
            dispatch(actions.toggleLoading(false));
            throw (error);
        });
    };
}
export function getFonts(params) {
    return function (dispatch) {
        dispatch(actions.toggleFontLoading(true));
        return api.getFonts(params,{ headers: APIHelper.getGenericHeaders() }).then(response => {
            dispatch(actions.getFontsSuccess(response.result));
            dispatch(actions.toggleFontLoading(false));
        }).catch(error => {
            dispatch(actions.toggleFontLoading(false));
            throw (error);
        });
    };
}

export function loadMetaJson(funnel) {
    return function (dispatch) {
        return api.funnelMetaUrl(funnel.metaJson, { headers: APIHelper.getGenericHeaders() }).then((response) => {
            dispatch(actions.loadMetaJsonSuccess(response));
            dispatch(actions.toggleLoading(false));
        }).catch(error => {
            throw (error);
        });
    };
}

export function loadFonts() {
    return function (dispatch) {
        return api.loadGoogleFonts({ headers: APIHelper.getGenericHeaders() }).then((response) => {
            dispatch(actions.loadGoogleFontsSuccess(response));
            dispatch(actions.toggleLoading(false));
        }).catch(error => {
            throw (error);
        });
    };
}

export function loadButtons({ metaJsonUrl, isPageDeleted = false }, callback) {
    return function (dispatch) {
        // dispatch(toggleLoading(true));
        return api.funnelMetaUrl(metaJsonUrl, { headers: APIHelper.getGenericHeaders() }).then(response => {
            const parsedRes = JSON.parse(response);
            dispatch(actions.loadButtonsSuccess(parsedRes));
            !isPageDeleted && dispatch(actions.toggleLoading(false));
            callback && callback(parsedRes);
        }).catch(error => {
            dispatch(actions.toggleLoading(false));
            throw (error);
        });
    };
}

export function createPage(params) {
    return function (dispatch) {
        // dispatch(toggleLoading(true));
        return api.editFunnel(params, { headers: APIHelper.getGenericHeaders(), requestBody: params.funnel }).then((response) => {
            dispatch(actions.editFunnelSuccess(response));
        }).catch(error => {
            dispatch(actions.editFunnelError(true));
            dispatch(actions.createPageError(true));
            dispatch(actions.toggleLoading(false));
            throw error;
        });
    };
}
export function errorClose() {
    return function (dispatch) {
        dispatch(actions.createPageError(false));
        dispatch(actions.importPageError(false));
    };
}
export function editFunnel(params) {
    return function (dispatch) {
        // dispatch(toggleLoading(true));
        return api.updateFunnel(params, { headers: APIHelper.getGenericHeaders(), requestBody: params.funnel }).then((response) => {
            dispatch(actions.updateFunnelSuccess(response));
            dispatch(actions.toggleLoading(false));
        }).catch(error => {
            dispatch(actions.updateFunnelError(true));
            dispatch(actions.toggleLoading(false));
            throw error;
        });
    };
}

export function clonePage(params) {
    return function (dispatch) {
        // dispatch(toggleLoading(true));
        return api.clonePage(params, { headers: APIHelper.getGenericHeaders(), requestBody: params.funnel }).then((response) => {
            dispatch(loadFunnel(params.funnelReferenceId));
            dispatch(actions.toggleLoading(false));
        }).catch(error => {
            dispatch(actions.editFunnelError(true));
            dispatch(actions.createPageError(true));
            dispatch(actions.toggleLoading(false));
            throw error;
        });
    };
}
export function editPage(params) {
    return function (dispatch) {
        return api.updatePage(params, { headers: APIHelper.getGenericHeaders(), requestBody: params.funnel }).then((response) => {
            dispatch(actions.updatePageSuccess(response));
        }).catch(error => {
            dispatch(actions.updatePageError(true));
            dispatch(actions.toggleLoading(false));
            throw error;
        });
    };
}
export function saveFonts(params) {
    return function (dispatch) {
        // dispatch(toggleLoading(true));
        return api.saveFonts(params, { headers: APIHelper.getGenericHeaders(), requestBody: params.funnel }).then((response) => {
            // dispatch(saveFontsSuccess(response));
            // dispatch(toggleLoading(false));
        }).catch(error => {
            // dispatch(saveFontsError(true));
            dispatch(actions.toggleLoading(false));
            throw error;
        });
    };
}

export function loadFunnel(params) {
    return function (dispatch) {
        // dispatch(toggleLoading(true));
        return api.retrieveFunnel(params, { headers: APIHelper.getGenericHeaders() }).then((response) => {
            dispatch(actions.loadFunnelSuccess(response));
            dispatch(actions.toggleLoading(false));
        }).catch(error => {
            dispatch(actions.editFunnelError(true));
            dispatch(actions.toggleLoading(false));
            throw error;
        });
    };
}
export function deletePage(params, callback) {
    return function (dispatch) {
        // dispatch(toggleLoading(true));
        return api.deletePage(params, { headers: APIHelper.getGenericHeaders(), requestBody: params.funnel }).then((response) => {
            return api.savefunnel({ jsonFile: params.deleteView, funnelReferenceId: params.funnel.referenceId }, { headers: APIHelper.getGenericHeaders(), requestBody: {} }).then((res) => {
                if (!params.isPageDeleted) {
                    dispatch(actions.editFunnelSuccess(response));
                    dispatch(actions.toggleLoading(false));
                }
                callback && callback();
            });
        }).catch(error => {
            dispatch(actions.editFunnelError(true));
            dispatch(actions.toggleLoading(false));
            throw error;
        });
    };
}

export function getTemplate(params) {
    return function (dispatch) {
        // dispatch(toggleLoading(true));
        return api.getTemplate(params, { headers: APIHelper.getGenericHeaders() }).then((response) => {
            dispatch(actions.getTemplateSuccess(response));
        }).catch(error => {
            dispatch(actions.toggleLoading(false));
            throw error;
        });
    };
}
export function getJsonData(params, callback) {
    return function (dispatch) {
        dispatch(actions.toggleLoading(true));
        return api.getJsonData(params, { headers: APIHelper.getGenericHeaders() }).then((response) => {
            dispatch(loadButtons({ metaJsonUrl: params.metaJsonUrl }, () => {
                dispatch(actions.getJsonSuccess(response));
                dispatch(actions.toggleLoading(false));
                callback && callback(response);
            }));
        }).catch(error => {
            dispatch(actions.toggleLoading(false));
            throw error;
        });
    };
}
export function getHtml(params) {
    return function (dispatch) {
        dispatch(actions.toggleLoading(true));
        return api.getJsonData(params, { headers: APIHelper.getGenericHeaders() }).then((response) => {
            dispatch(actions.getJsonSuccess(response));
            dispatch(actions.toggleLoading(false));
        }).catch(error => {
            dispatch(actions.toggleLoading(false));
            throw error;
        });
    };
}

export function savePageView(params, isLoading = false, callback) {
    return function (dispatch) {
        // if (!isLoading) {
        //     dispatch(toggleLoading(true));
        // }
        const { isDeleteArrow = false ,isArrowChange = false } = params;
        if (params.isWebBuilder) {
            dispatch(actions.toggleLoading(true));
        }
        return savePageApi.savePage(params, { headers: APIHelper.getGenericHeaders(), requestBody: params.pageViewReferenceId }).then((savedPage) => {
            savePageApi.saveJSTemplate(params)
                .then(() => {
                    if (!isDeleteArrow && !isArrowChange) {
                        dispatch(getFunnel(params.funnelReferenceId, callback));
                    }
                });
        }).catch(error => {
            dispatch(actions.toggleLoading(false));
            throw (error);
        });
    };
}

export function saveFunnel(params) {
    return function (dispatch) {
        return api.savefunnel(params, { headers: APIHelper.getGenericHeaders(), requestBody: {} }).then((funnelRes) => {
        }).catch(error => {
            dispatch(actions.toggleLoading(false));
            throw error;
        });
    };
}
export function clearFunnelData() {
    return function (dispatch) {
        dispatch(actions.clearFunnelDataSuccess(true));
    };
}


export function saveTemplatePageView(params) {
    return function (dispatch) {
        // dispatch(toggleLoading(true));
        return api.getTemplate(params, { headers: APIHelper.getGenericHeaders() }).then((response) => {
            dispatch(savePageView({
                html: response,
                css: "",
                pageViewReferenceId: params.pageViewReferenceId,
                pageName: params.pageViewReferenceId,
                templateId: params.templateId,
                cellViewId: params.cellViewId,
                funnelReferenceId: params.funnelReferenceId
            }, true));
        }).catch(error => {
            dispatch(actions.toggleLoading(false));
            throw error;
        });
    };
}

export function saveButtonData(params, callback) {
    return function (dispatch) {
        // GET HTML and CSS Data
        const { funnelReferenceId, jsonFile, isPageDeleted = false, isDeleteArrow = false, isArrowChange = false } = params;
        return api.getJsonData({
            componentJsonUrl: params.sourcePage.pageView[0].componentJson,
            stylesJsonUrl: params.sourcePage.pageView[0].stylesJson,
            htmlUrl: params.sourcePage.pageView[0].url
        }, { headers: APIHelper.getGenericHeaders(), requestBody: {} }).then((response) => {
            return api.getHtmlPage(params.sourcePage.pageView[0].url, { headers: APIHelper.getGenericHeaders(), requestBody: {} }).then((htmlPage) => {
                const { cellViewId } = params.sourcePage;
                const { referenceId, fTemplateId } = params.sourcePage.pageView[0];
                const buttonMetaData = {
                    buttons: [...params.newPageData],
                    links: [...params.newPageLinkData],
                    jsPageData: params.jsPageData
                };
                return api.savefunnel({ funnelReferenceId, jsonFile }, { headers: APIHelper.getGenericHeaders(), requestBody: {} }).then((res) => {
                    // dispatch(saveFunnel({ funnelReferenceId, jsonFile }));
                    // Append data to meta json file - cellview id of the link & pageData object
                    // Call savePageView Api with HTML, CSS, PageData & MetaJSON
                    return api.funnelMetaUrl(params.funnel.metaJson, { headers: APIHelper.getGenericHeaders() }).then((metaresponse) => {
                        dispatch(actions.loadMetaJsonSuccess(metaresponse));
                        dispatch(savePageView({
                            component_json: response.setcomponentData,
                            styles_json: response.setstylesData,
                            pageViewReferenceId: referenceId,
                            pageName: referenceId,
                            templateId: fTemplateId,
                            cellViewId: cellViewId,
                            funnelReferenceId,
                            pageData: params.jsPageData,
                            buttonMetaData,
                            html: htmlPage,
                            isEdited: true,
                            isDeleteArrow,
                            isArrowChange
                        }, true, callback));
                    });
                });
            }).catch(error => {
                dispatch(actions.toggleLoading(false));
                throw error;
            });
        });
    };
}
export function visualizerFunnelEdit(params, cb = null) {
    return function (dispatch) {
        const promiseArray = [];
        params.filteredPageList.forEach((buttonData) => {
            const promise = dispatch(editButtonData({ sourcePage: buttonData, salt: params.salt, funnelReferenceId: params.funnelReferenceId }));
            promiseArray.push(promise);
        });
        Promise.all(promiseArray).then(function (values) {
            cb && cb();
        });
    };
}
export function editButtonData(params, callback) {
    return function (dispatch) {
        // GET HTML and CSS Data
        const { funnelReferenceId, sourcePage, jsonFile, isPageDeleted = false } = params;
        // if (!isPageDeleted) {dispatch(toggleLoading(true));}
        return api.getJsonData({
            componentJsonUrl: sourcePage.pageView[0].componentJson,
            stylesJsonUrl: sourcePage.pageView[0].stylesJson,
            htmlUrl: sourcePage.pageView[0].url
        }, { headers: APIHelper.getGenericHeaders(), requestBody: {} }).then((response) => {
            return api.getHtmlPage(sourcePage.pageView[0].url, { headers: APIHelper.getGenericHeaders(), requestBody: {} }).then((htmlPage) => {
                const { cellViewId } = params;
                const { referenceId, fTemplateId } = sourcePage.pageView[0];
                return api.funnelMetaUrl(sourcePage.pageView[0].metaJson, { headers: APIHelper.getGenericHeaders() }).then(response => {
                    const metaResponse = JSON.parse(response);
                    metaResponse.buttons.map((data) => {
                        data["OFFER"] = null;
                        data["UPSELL"] = null;
                    });
                    metaResponse.jsPageData = {
                        salt: params.salt
                    };
                    const buttonMetaData = metaResponse;
                    return dispatch(savePageView({
                        component_json: response.setcomponentData,
                        styles_json: response.setstylesData,
                        pageViewReferenceId: referenceId,
                        pageName: referenceId,
                        templateId: fTemplateId,
                        cellViewId: cellViewId,
                        funnelReferenceId,
                        pageData: params.jsPageData,
                        buttonMetaData,
                        html: htmlPage,
                        isEdited: true
                    }, true, callback));
                }).catch(error => {
                    dispatch(actions.toggleLoading(false));
                    throw error;
                });
            });
        });
    };
}

export function publishFunnel(funnelReferenceId) {
    return function (dispatch) {
        dispatch(actions.toggleLoading(true));
        return api.publishFunnel(funnelReferenceId, { headers: APIHelper.getGenericHeaders(), requestBody: {} }).then((response) => {
            dispatch(actions.funnelPublishSuccess(response));
            dispatch(actions.toggleLoading(false));
        }).catch(error => {
            dispatch(actions.funnelPublishError(true));
            dispatch(actions.toggleLoading(false));
            throw error;
        });
    };
}
export function importPage(params) {
    return function (dispatch) {
        dispatch(actions.toggleLoading(true));
        return api.importPage(params, { headers: APIHelper.getGenericHeaders(), requestBody: {} }).then((response) => {
            dispatch(actions.importPageSuccess(response.result));
            return api.getMetaUrl(params.funnel, { headers: APIHelper.getGenericHeaders(), requestBody: {} }).then((res) => {
                dispatch(actions.getMetaUrlSuccess(res));
                dispatch(actions.toggleLoading(false));
            });
        }).catch(error => {
            dispatch(actions.importPageError(true));
            dispatch(actions.toggleLoading(false));
            throw error;
        });
    };
}

export function getDomainListForUser(funnelReferenceId) {
    return function (dispatch) {
        dispatch(actions.toggleLoading(true));
        return api.getDomainListForUser(funnelReferenceId, { headers: APIHelper.getGenericHeaders() }).then((response) => {
            dispatch(actions.getDomainListSuccess(response.result));
            dispatch(getDefaultDomainId(response.result));
            dispatch(actions.toggleLoading(false));
        }).catch(error => {
            dispatch(actions.getDomainListError(error));
            dispatch(actions.toggleLoading(false));
            throw error;
        });
    };
}

export function getDefaultDomainId(domainList) {
    const defaultSelectedDomain = domainList && domainList.filter((list) => list.selected === true);
    return function (dispatch) {
        const defaultDomainId = defaultSelectedDomain.length ?
            defaultSelectedDomain[0].referenceId :
            "";
        dispatch(actions.setDomainForFunnelSuccess(defaultDomainId));
    };
}

export function setDomainForFunnel(selectedDomainAndFunnelId) {
    const localStorage = new LocalStorageHelper();
    return function (dispatch) {
        dispatch(actions.toggleLoading(true));
        return api.setDomainForFunnel(selectedDomainAndFunnelId, { headers: APIHelper.getGenericHeaders() }).then((response) => {
            dispatch(actions.setDomainForFunnelSuccess(response.result.referenceId));
            dispatch(getDomainListForUser(localStorage.load("funnelReferenceId")));
            dispatch(actions.toggleLoading(false));
        }).catch(error => {
            dispatch(actions.setDomainForFunnelError(error));
            dispatch(actions.toggleLoading(false));
            throw error;
        });
    };
}

export function clearDomainForFunnelData() {
    return function (dispatch) {
        dispatch(actions.clearDomainForFunnelDataSuccess(true));
    };
}
export function showSuccessMessage(showImportFunnelSuccessMessage = false) {
    return {
        type: IMPORT_FUNNEL_SUCCESS_MESSAGE,
        showImportFunnelSuccessMessage
    };
}

/*********************begin actions for import Funnel********************************/

export function importFunnel(params) {
    return function (dispatch) {
        dispatch(actions.toggleImportFunnelLoading(true));
        return api.importFunnel(params, { headers: APIHelper.getGenericHeaders(), requestBody: {} }).then((response) => {
            dispatch(actions.importFunnelSuccess(response.result));
            return api.getMetaUrl(response.result, { headers: APIHelper.getGenericHeaders(), requestBody: {} }).then((res) => {
                dispatch(actions.getMetaUrlSuccess(res));
                dispatch(actions.toggleImportFunnelLoading(false));
                dispatch(showSuccessMessage(true));
            });
        }).catch(error => {
            dispatch(actions.importFunnelError(true));
            dispatch(actions.toggleImportFunnelLoading(false));
            throw error;
        });
    };
}

/*********************end of actions for import funnel************************ */
export function clearJsonData(status = false) {
    return {
        type: CLEAR_JSON_DATA,
        status
    };
}