import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";

import InputRange from "react-input-range";
import "react-input-range/lib/css/index.css";
import { splitViewUrl } from "../../../api/urls";
import { LocalStorageHelper } from "../../../utilities";
import "./style.css";
// import { templates } from "./../../../api/mockTemplateApi";

export class Page extends React.Component {
    constructor(props, context) {
        super(props, context);
        this.state = {
            marketplace: Object.assign({}, props.marketplace),
            value: 1,
            pageValue1: 1,
            pageValue2: 99,
            applyValue: false,
            clonePage: this.props.clonePage,
            showDuplicatePage: this.props.showDuplicatePage,
            setWinnerPage: false,
        };
    }
    componentDidMount() {
        const { currentPage, splitValue } = this.props;
        const { pageValue1,pageValue2,value } = this.state;
        this.props.getSplitValue(this.props.currentPage.referenceId);
    }
    componentWillReceiveProps(nextProps) {
        const { splitValueChanged, splitValue } = this.props;
        if (nextProps.currentPage.pageView && nextProps.currentPage.pageView.length === 2)
            if (nextProps.splitValue && this.props.splitValue !== nextProps.splitValue)
                this.props.splitViewPut(nextProps.splitValue.pageView[0].splitValue,nextProps.splitValue.pageView[1].splitValue);
        if ((this.props.currentPage.referenceId !== nextProps.currentPage.referenceId) || (this.props.splitValue && !nextProps.splitValue))
            this.setState({ splitValueChanged: false },() => {this.props.getSplitValue(nextProps.currentPage.referenceId);});
        const currentValue = !splitValueChanged ? (nextProps.splitValue ? nextProps.splitValue.pageView[0].splitValue : 1) : nextProps.splitValueChanged ? nextProps.splitValueChanged.pageView[0].splitValue : "";
        const currentSliderValue1 = !splitValueChanged ? (nextProps.splitValue ? nextProps.splitValue.pageView[0].splitValue === null ? 1 : nextProps.splitValue.pageView[0].splitValue : 1) : nextProps.splitValueChanged ? nextProps.splitValueChanged.pageView[0].splitValue : "";
        const currentSliderValue2 = !splitValueChanged ? (nextProps.splitValue ? nextProps.splitValue.pageView.length === 2 ? nextProps.splitValue.pageView[1].splitValue === null ? 99 : nextProps.splitValue.pageView[1].splitValue : null : 99) : nextProps.splitValueChanged ? nextProps.splitValueChanged.pageView[1].splitValue : "";
        this.setState({
            showDuplicatePage: nextProps.showDuplicatePage,
            value: currentValue,
            pageValue1: currentSliderValue1,
            pageValue2: currentSliderValue2,
        });
    }
    applyValue = () => {
        const { currentPage } = this.props;
        const { pageValue1,pageValue2,value } = this.state;
        this.setState({
            value: value,
            pageValue1: pageValue1,
            pageValue2: pageValue2,
            applyValue: false
        },() => this.props.splitViewPut(pageValue1,pageValue2));
    }
    setWinner = (pageViewId) => {
        const { setWinner } = this.props;
        this.setState({ setWinnerPage: true },setWinner(pageViewId));
    }
    handleClick(webPageId) {
        const { currentPage } = this.props;
        // const { currentPageViewId,duplicatePageViewId, currentPage } = this.props;
        // const localStorage = new LocalStorageHelper();
        // localStorage.store("webPageId", currentPageViewId === webPageId ? currentPageViewId : duplicatePageViewId);
    }
    render() {
        const { pageValue1,pageValue2,applyValue,showDuplicatePage } = this.state;
        const { funnel, currentPage, currentPageViewId, currentPageView, pageSelect, isLoading, duplicatePageView, duplicatePageViewId, splitValue, } = this.props;
        let splitUrl = `${ splitViewUrl }/SplitView/funnel/${funnel.referenceId}/page/${currentPage.referenceId}/redirect/true`;
        let htmlUrl = currentPage ? currentPage.pageView[0] ? currentPage.pageView[0].url : null : null;
        let cloneUrl = currentPage ? currentPage.pageView[1] ? currentPage.pageView[1].url : null : null;
        let selectedTemplate;
        const currentPageViewReferenceId1 = currentPage && currentPage.pageView[0] ? currentPage.pageView[0].referenceId : null;
        const currentPageViewReferenceId2 = currentPage && currentPage.pageView[1] ? currentPage.pageView[1].referenceId : null;
        return (
            <>
                { isLoading && <div className="text-center m-t-sm m-b-sm"><i className="fa fa-spinner fa-pulse fa-lg m-r-xs"/> Please wait...</div> }
                <div className="container-fluid text-center" >
                    <div className="ibox">
                        <div className="ibox-content">
                            <div className="input-group">
                                <div className="input-group-addon">Template Url</div>
                                {/* <input className="form-control" type="text" value={`https://s3.us-west-1.amazonaws.com/funnels.funnelkonnekt.com/${referenceId}/${currentPageViewId}.html`} /> */}
                                <input id="splitUrl" className="form-control" type="text" value={splitUrl} readOnly/>
                                <div className="input-group-addon badge-primary"><a className="text-white" href={splitUrl} target="_blank"><i className="fa fa-eye"></i></a></div>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-4">
                            <div className="ibox">
                                <div className="ibox-content template-content">
                                    <div className="page-container overlay">
                                        <iframe src={htmlUrl} align="middle" height="600" width="1180" allowFullScreen=""
                                            frameBorder="0" scrolling="no" marginHeight="0" marginWidth="0"/>
                                        { currentPage.pageView.length === 2 && showDuplicatePage &&
                                            <div className="splitWinner">
                                                <div><i className="winner-trophy"></i></div>
                                                <button className="btn btn-primary btn-lg" onClick={() => this.setWinner(currentPageViewId)}>Declare as Winner</button>
                                                {/* <button className="btn btn-primary btn-lg" onClick={() => setWinner(currentPageViewId)}>Declare as Winner</button> */}
                                            </div>
                                        }
                                    </div>
                                    <div className="product-desc">
                                        {/*<span className="product-name template-name"> {template.title}</span>*/}
                                        <div className="m-t input-group">
                                            <Link to={{ pathname: "/webbuilder", state: { selectedTemplate: currentPageView, funnel: funnel, currentPage: currentPage, funnelReferenceId: funnel.referenceId, pageViewReferenceId: currentPageViewReferenceId1 } }} className="btn btn-block btn-primary">Edit Template</Link>
                                            <div className="input-group-addon badge-outline"><a className="text-primary" href={htmlUrl} target="_blank"><i className="fa fa-external-link"></i></a></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        { splitValue && splitValue.pageView.length === 2 && showDuplicatePage ?
                            <>
                                <div className="col-md-4">
                                    <InputRange
                                        formatLabel={() => `${pageValue1} % of Traffic`}
                                        minValue={1}
                                        maxValue={99}
                                        value={this.state.value ? this.state.value : 1}
                                        onChange={value => {this.setState({ value, pageValue1: value, pageValue2: (99 - value) + 1, applyValue: true });}}/>
                                    <div className="input-range__label pageValue2">{pageValue2} % of Traffic</div>
                                    { applyValue &&
                                        <div className="apply-changes">
                                            <h3>Apply changes!</h3>
                                            <p>You've changed your split test, which will invalidate any previous results. Please confirm that you'd like to apply these changes and start the test over again</p>
                                            <button className="btn btn-primary"
                                                onClick={this.applyValue}
                                            >Apply Changes</button>
                                        </div>
                                    }
                                </div>
                                <div className="col-md-4">
                                    <div className="ibox">
                                        <div className="ibox-content template-content">
                                            <div className="page-container">
                                                <iframe src={cloneUrl} align="middle" height="600" width="1180" allowFullScreen=""
                                                    frameBorder="0" scrolling="no" marginHeight="0" marginWidth="0"/>
                                                { currentPage.pageView.length === 2 && showDuplicatePage &&
                                                    <div className="splitWinner">
                                                        <div><i className="winner-trophy"></i></div>
                                                        <button className="btn btn-primary btn-lg" onClick={() => this.setWinner(duplicatePageViewId)}>Declare as Winner</button>
                                                        {/* <button className="btn btn-primary btn-lg" onClick={() => setWinner(duplicatePageViewId)}>Declare as Winner</button> */}
                                                    </div>
                                                }
                                            </div>
                                            <div className="product-desc">
                                                {/*<span className="product-name template-name"> {template.title}</span>*/}
                                                <div className="m-t input-group">
                                                    <Link to={{ pathname: "/webbuilder", state: { selectedTemplate: currentPageView, funnel: funnel, currentPage: currentPage, funnelReferenceId: funnel.referenceId, pageViewReferenceId: currentPageViewReferenceId2 } }} className="btn btn-block btn-primary">Edit Template</Link>
                                                    <div className="input-group-addon badge-outline"><a className="text-primary" href={cloneUrl} target="_blank"><i className="fa fa-external-link"></i></a></div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </> :
                            <div className="col-md-4">
                                <div className="ibox">
                                    <div className="ibox-content">
                                        <i className="fa fa-filter fa-5x" style={{ color: "#1ab394" }}/>
                                        <h3>Start Split Test</h3>
                                        <div className="container-fluid">
                                            <p>Optimize your lead and sales generation with split tests.</p>
                                        </div>
                                        <button className="btn btn-primary" onClick = {pageSelect} >Create Variation</button>
                                    </div>
                                </div>
                            </div>
                        }
                    </div>
                </div>
            </>
        );
    }
}
Page.propTypes = {
    marketplace: PropTypes.object,
    funnel: PropTypes.object,
    actions: PropTypes.object,
    template: PropTypes.object,
    referenceId: PropTypes.string,
    currentPageViewId: PropTypes.number,
    currentPageView: PropTypes.object,
    currentTemplate: PropTypes.object,
    duplicatePageViewId: PropTypes.number,
    duplicatePageView: PropTypes.object,
    currentPage: PropTypes.object,
    splitValue: PropTypes.object,
    splitValueChanged: PropTypes.object,
    pageSelect: PropTypes.func,
    getSplitValue: PropTypes.func,
    setWinner: PropTypes.func,
    splitViewPut: PropTypes.func,
    deleteRunner: PropTypes.func,
    clonePage: PropTypes.bool,
    isLoading: PropTypes.bool,
    splitValuePageClick: PropTypes.bool,
    showDuplicatePage: PropTypes.bool
};
Page.contextTypes = {
    router: PropTypes.object
};

