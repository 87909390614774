import { getAnalyticsData } from "../../create-funnel-visualizer/actions";

export const showPageAnalytics = async (pages, funnelReferenceId) => {
    let data = null;
    const promiseArrays = [];
    const referenceIdArrays = [];
    const createAnalyticsCall = (funnelReferenceId, pageReferenceId) => {
        return getAnalyticsData({
            funnelReferenceId,
            pageReferenceId,
        });
    };
    pages.forEach((page) => {
        if (page.attributes.isSplitEnabled) {
            const splitPagesIds = [page.attributes.pageReferenceId, page.attributes.splitPageAttributes.pageReferenceId];
            splitPagesIds.forEach(referenceId => {
                promiseArrays.push(createAnalyticsCall(funnelReferenceId, referenceId));
                referenceIdArrays.push(referenceId);
            });
        } else {
            promiseArrays.push(createAnalyticsCall(funnelReferenceId, page.attributes.pageReferenceId));
            referenceIdArrays.push(page.attributes.pageReferenceId);
        }
    });

    await Promise.all(promiseArrays)
        .then((values) => {
            const funnelAnalyticsData = {};
            values.forEach((value, index) => {
                const {
                    result,
                } = value;
                const key = referenceIdArrays[index];
                funnelAnalyticsData[key] = result;
            });
            data = funnelAnalyticsData;
        });
    return data;
};