import { providerUrl, rootUrl } from "../../../api/urls";
import { APIHelper } from "../../../../src/utilities";
import axios from "axios";
import Axios from "axios";

export function getCountriesList() {
    return APIHelper.baseRequest(APIHelper.POST, `${providerUrl}/Campaign/CountryQuery`, {
        headers: APIHelper.getGenericHeaders(),
        requestBody: {
            token_type: "Bearer",
            expires_in: 84600
        }
    });
}

export function getCurrencyList() {
    return APIHelper.baseRequest(APIHelper.POST, `${providerUrl}/Campaign/CurrencyQuery`, {
        headers: APIHelper.getGenericHeaders(),
        requestBody: {
            token_type: "Bearer",
            expires_in: 84600
        }
    });
}

export function getFunnels() {
    return APIHelper.baseRequest(APIHelper.GET, `${rootUrl}/Funnel`, {
        headers: APIHelper.getGenericHeaders()
    });
}

export function getGateWayListQuery() {
    return APIHelper.baseRequest(APIHelper.POST, `${providerUrl}/Campaign/GatewayListQuery`, {
        headers: APIHelper.getGenericHeaders(),
        requestBody: {
            token_type: "Bearer",
            expires_in: 84600
        }
    });
}

export function getFulfillmentHouseList() {
    return APIHelper.baseRequest(APIHelper.POST, `${providerUrl}/Campaign/FulfillmentHouseQuery`, {
        headers: APIHelper.getGenericHeaders(),
        requestBody: {
            token_type: "Bearer",
            expires_in: 84600
        }
    });
}

export function getProductData() {
    return APIHelper.baseRequest(APIHelper.POST, `${providerUrl}/Campaign/ProductQuery`, {
        headers: APIHelper.getGenericHeaders(),
        requestBody: {
            allProducts: 1
        }
    });
}

export function getFulfillmentVendorQuery() {
    return APIHelper.baseRequest(APIHelper.POST, `${providerUrl}/Campaign/FulfillmentVendorQuery`, {
        headers: APIHelper.getGenericHeaders(),
        requestBody: {}
    });
}

export function getMerchantList() {
    return APIHelper.baseRequest(APIHelper.POST, `${providerUrl}/Campaign/MerchantQuery`, {
        headers: APIHelper.getGenericHeaders(),
        requestBody: {
            loadBalancerId: 999
        }
    });
}

export function getAffiliateList(affiliate) {
    return axios.post(`${providerUrl}/Campaign/AffiliateQuery`, affiliate, { headers: APIHelper.getGenericHeaders() });
}

export function getSMTPList(campaignId) {
    return APIHelper.baseRequest(APIHelper.POST, `${providerUrl}/Campaign/SMTPQuery`, {
        headers: APIHelper.getGenericHeaders(),
        requestBody: {
            campaignId
        }
    });
}

export function createCampaign(campaign) {
    const json = JSON.stringify({ campaign });
    let formData = new FormData();
    formData.append("json", json);
    return axios.post(`${providerUrl}/Campaign/CreateCampaignNew`, campaign, { headers: APIHelper.getGenericHeaders() });
}

export function updateCampaign(campaign) {
    return axios.post(`${providerUrl}/Campaign/UpdateCampaign`, campaign, { headers: APIHelper.getGenericHeaders() });
}

export function createProduct(product) {
    const json = JSON.stringify({ product });
    let formData = new FormData();
    formData.append("json", json);
    return axios.post(`${providerUrl}/Campaign/CreateProduct`, product, { headers: APIHelper.getGenericHeaders() });
}

export function updateProduct(product) {
    return axios.post(`${providerUrl}/Campaign/UpdateProduct`, product, { headers: APIHelper.getGenericHeaders() });
}

export function createMerchant(merchant) {
    return axios.post(`${providerUrl}/Campaign/CreateMerchant`, merchant, { headers: APIHelper.getGenericHeaders() });
}

export function createFulfillmentHouse(fulfillment) {
    return axios.post(`${providerUrl}/Campaign/CreateFulfillmentHouse`, fulfillment, { headers: APIHelper.getGenericHeaders() });
}

export function updateFulfillmentHouse(fulfillment) {
    return axios.post(`${providerUrl}/Campaign/UpdateFulfillmentHouse`, fulfillment, { headers: APIHelper.getGenericHeaders() });
}

export function updateMerchant(merchant) {
    return axios.post(`${providerUrl}/Campaign/UpdateMerchant`, merchant, { headers: APIHelper.getGenericHeaders() });
}

export function createCampaignProduct(product) {
    return axios.post(`${providerUrl}/Campaign/CreateCampaignProduct`, product, { headers: APIHelper.getGenericHeaders() });
}

export function updateCampaignProduct(product) {
    return axios.post(`${providerUrl}/Campaign/UpdateCampaignProduct`, product, { headers: APIHelper.getGenericHeaders() });
}

export function createAffiliate(affiliate) {
    return axios.post(`${providerUrl}/Campaign/CreateAffiliate`, affiliate, { headers: APIHelper.getGenericHeaders() });
}

export function updateAffiliate(affiliate) {
    return axios.post(`${providerUrl}/Campaign/UpdateAffiliate`, affiliate, { headers: APIHelper.getGenericHeaders() });
}

export function createSMTP(SMTP) {
    return axios.post(`${providerUrl}/Campaign/CreateSMTP`, SMTP, { headers: APIHelper.getGenericHeaders() });
}

export function updateSMTP(SMTP) {
    return axios.post(`${providerUrl}/Campaign/UpdateSMTP`, SMTP, { headers: APIHelper.getGenericHeaders() });
}

export function updateCountries(data) {
    return axios.post(`${providerUrl}/Campaign/UpdateCountry`, data, { headers: APIHelper.getGenericHeaders() });
}

export function updateFunnelDetails(funnel, referenceId) {
    return axios.put(`${rootUrl}/Funnel/Visualizer/${referenceId}`, funnel, { headers: APIHelper.getGenericHeaders() });
}