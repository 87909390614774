import React, { memo, useReducer } from "react";
import PropTypes from "prop-types";
import { Modal } from "react-bootstrap";
import omit from "lodash/omit";
import get from "lodash/get";
import memoize from "lodash/memoize";
import isEmpty from "lodash/isEmpty";

import WalkthroughProgress from "./walkthrough/walkthrough-progress";

import walkthroughConfig from "./walkthrough/config";

/*
 * State Management
 */
import walkthroughReducer, { RESET_WALKTHROUGH, UPDATE_STEP_PROGRESS } from "./walkthrough/state";

const getInitialState = () => ({
    currentStep: 0,
    progressSteps: walkthroughConfig.map((step) => omit(step, ["component"])),
});
const getGoals = memoize((tiles = []) => tiles.filter((tile = {}) => tile.page === "1"));
const getFunnelTypes = ((tiles, selectedGoal) => {
    return tiles.filter((tile) => {
        return (tile.title !== selectedGoal.title && tile.page === selectedGoal.navigateToPage);
    });
});
const GOAL_STEP = 0;
const FUNNEL_TYPE_STEP = 1;
const BUILD_FUNNEL = 2;
const END_PAGE = "end";

const WalkthroughModal = memo(({ show, onClose, tiles, goToCreateFunnel }) => {
    const [state, dispatch] = useReducer(walkthroughReducer, getInitialState());
    const {
        currentStep,
        progressSteps,
    } = state;
    let items = [];
    if (currentStep === GOAL_STEP) {
        items = getGoals(tiles);
    } else if (currentStep === FUNNEL_TYPE_STEP) {
        const selectedGoal = get(progressSteps, [GOAL_STEP, "selectedTile"]);
        items = getFunnelTypes(tiles, selectedGoal);
    }

    const onCloseModal = () => {
        dispatch({ type: RESET_WALKTHROUGH, initialState: getInitialState() });
        onClose();
    };

    const getSelectedTile = () => {
        return (
            get(progressSteps, [FUNNEL_TYPE_STEP, "selectedTile"]) ||
            get(progressSteps, [GOAL_STEP, "selectedTile"])
        );
    };

    const stepComponentProps = {
        goBack: () => {
            let previousStep = currentStep - 1;
            const previousStepConfig = progressSteps[previousStep];
            /*
             * Move back one step.
             * Check if the previous step has a selected item.
             * If the user has an selected item, cancel that out
             *
             * If the user doesn't have an selected item.
             * It implies that the step doesn't have any tiles to render.
             * and the parent tiles navigateToPage value will be "end".
             * In that case, go back another previous step
             */
            if (previousStepConfig.selectedTile) {
                previousStepConfig.selectedTile = null;
            } else if (previousStep > 0) {
                previousStep -= 1;
                progressSteps[previousStep].selectedTile = null;
            }
            dispatch({
                type: UPDATE_STEP_PROGRESS,
                progressSteps,
                stepToNavigate: previousStep,
            });
        },
        onTileClick: (selectedTile = {}) => {
            let activeStep = progressSteps[currentStep];
            let nextStep = currentStep + 1;
            /*
             * Move forward one step.
             *
             * If the selectedTile's navigateToPage is "end", go to "Build Funnel Step"
             */
            if (!isEmpty(selectedTile)) {
                activeStep.selectedTile = selectedTile;
            }
            if (selectedTile.navigateToPage === END_PAGE) {
                nextStep = BUILD_FUNNEL;
            }
            dispatch({
                type: UPDATE_STEP_PROGRESS,
                progressSteps,
                stepToNavigate: nextStep
            });
        },
        goToCreateFunnelPage: (title = "", isCreateFromMarketPlace) => {
            const selectedTile = getSelectedTile();
            goToCreateFunnel({
                title,
                navigateToVisualizer: true,
                openFunnelTemplates: true,
                funnelTemplateTypeId: selectedTile && +selectedTile.type,
                isCreateFromMarketPlace
            });
            onCloseModal();
        },
        items,
        selectedTile: getSelectedTile(),
        onCloseModal,
    };
    const ActiveStepComponent = get(walkthroughConfig,[currentStep, "component"]);

    return (
        <>
            {
                ActiveStepComponent &&
                (
                    <Modal
                        show={show}
                        bsSize="lg"
                        onHide={onCloseModal}
                        className={"walkthrough-modal"}
                    >
                        <Modal.Header
                            closeButton
                            className="walkthrough-modal-header"
                        />
                        <Modal.Body className="m-n walkthrough-modal-body">
                            <WalkthroughProgress
                                steps={progressSteps}
                                currentStep={currentStep}
                            />
                            <ActiveStepComponent {...stepComponentProps} />
                        </Modal.Body>
                    </Modal>
                )
            }
        </>
    );
});

WalkthroughModal.propTypes = {
    show: PropTypes.bool,
    onClose: PropTypes.func,
    tiles: PropTypes.array,
    goToCreateFunnel: PropTypes.func,
    triggeredBy: PropTypes.string,
};

export default WalkthroughModal;

