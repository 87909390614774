import React from "react";
import PropTypes from "prop-types";

import TriggerTooltip from "../commons/trigger-tooltip";

export const PageTemplateCardToolbar = (props) => {
    const {
        template,
        onToolbarClick,
        isSelected,
    } = props;
    const toolBarItems = [
        {
            label: "Settings",
            title: "settings",
            class: "fa fa-cog fa-lg"
        },
        {
            label: "Edit Template",
            title: "edit",
            class: "fa fa-edit fa-lg"
        },
        {
            label: "Delete Template",
            title: "delete",
            class: "fa fa-trash fa-lg"
        },
    ];
    return (
        <div className={`page-template-card-toolbar ${isSelected ? "selected" : ""}`}>
            {
                toolBarItems.map((item, index) => {
                    return (<TriggerTooltip
                        label={item.label} key={index}
                    >
                        <span
                            className="page-template-card-toolbar-icon"
                            onClick={() => onToolbarClick(item.title)}
                        >
                            <i className={item.class} />
                        </span>
                    </TriggerTooltip>);
                })
            }
            <TriggerTooltip
                label="Preview Template"
            >
                <a
                    href={template.htmlUrl}
                    target="_blank"
                    className="page-template-card-toolbar-icon"
                >
                    <i className="fa fa-eye fa-lg" />
                </a>
            </TriggerTooltip>
        </div>
    );
};

PageTemplateCardToolbar.propTypes = {
    onToolbarClick: PropTypes.func.isRequired,
    template: PropTypes.object,
    isSelected: PropTypes.bool,
};
