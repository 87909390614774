import get from "lodash/get";
import { getEditorContainer } from "../utils/component";
import { getEditorHtml } from "../utils/editor";
import {
    triggerNofiticationSuccess,
    triggerNofiticationError,
} from "../../commons/notification";

const getLoginButtons = (buttons) => ([
    { ...buttons, status: "Active" },
    { ...buttons, status: "Inactive" },
]);

const saveFunnelTemplate = (editor, options) => {
    let saveApiPromise;
    const container = getEditorContainer(editor);
    const { props: containerProps } = container;
    const {
        templateData,
        funnelTemplate,
        savePageView,
    } = containerProps;
    const {
        currentPage,
        salt,
        funnelReferenceId,
        funnelData,
    } = funnelTemplate;
    const {
        templateId,
        templateTypeId,
    } = templateData;
    const currentPageView = get(currentPage, ["pageView","0"]);
    const pageViewReferenceId = currentPageView.referenceId;
    let jsData = container.buttonMetaData.jsPageData ? { ...container.buttonMetaData.jsPageData } : {};
    const jsPageData = {
        ...jsData,
        currentPage,
        funnelData,
        salt,
        templateTypeId,
    };
    const buttons = get(container, ["buttonMetaData", "buttons", "0"]);
    const newButtonMetaData = {
        buttons: templateTypeId === 7 ? getLoginButtons(buttons) : [...container.buttonMetaData.buttons],
        links: [...container.buttonMetaData.links],
        jsPageData,
    };
    if (templateData && templateData.referenceId) {
        saveApiPromise = savePageView({
            ...getEditorHtml(editor),
            referenceId: pageViewReferenceId,
            pageViewReferenceId,
            pageName: pageViewReferenceId, // Check if this is working correct
            templateId,
            pageData: jsPageData,
            buttonMetaData: newButtonMetaData,
            generateThumbnail: true,
            isEdited: true,
            funnelReferenceId,
            isWebBuilder: true
        });
        if (saveApiPromise) {
            container.toggleLoading(true);
            saveApiPromise.then(() => {
                container.toggleLoading(false);
                triggerNofiticationSuccess({ message: "Page saved successfully" });
                editor.UndoManager.clear();
                if (options.navigateBack) {
                    container.navigateBack();
                }
            }).catch((err = {}) => {
                container.toggleLoading(false);
                triggerNofiticationError({ message: err.message });
            });
        }
    }
};

export default {
    "save-database": saveFunnelTemplate,
};