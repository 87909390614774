import React, { memo } from "react";
import PropTypes from "prop-types";
import { Modal } from "react-bootstrap";

const AppIntroModal = memo(({ onClose, show, videoUrl, onGetStartedButtonClick }) => {
    return (
        <Modal
            show={show}
            bsSize="lg"
            onHide={onClose}>
            <Modal.Body className="m-n text-center">
                <h1>
                    Welcome to FunnelKonnekt!
                </h1>
                <h4>
                    It's easy to create your own perfect funnels
                </h4>
                <button
                    className="close-button btn btn-circle"
                    onClick={onClose}
                />
                <figure className="m-t-lg m-b-lg">
                    <iframe
                        src={videoUrl}
                        width="640"
                        height="360"
                        frameborder="0"
                        allow="autoplay; fullscreen"
                        allowFullScreen="true"
                        webkitallowfullscreen="true"
                        mozallowfullscreen="true"
                    />
                </figure>
                <div>
                    <button
                        className="btn btn-lg btn-primary btn-rounded"
                        onClick={onGetStartedButtonClick}
                    >
                        Get Started
                    </button>
                </div>
            </Modal.Body>
        </Modal>
    );
});

AppIntroModal.propTypes = {
    show: PropTypes.bool,
    onClose: PropTypes.func,
    videoUrl: PropTypes.string,
    onGetStartedButtonClick: PropTypes.func,
};

export default AppIntroModal;

