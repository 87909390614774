import React, { useContext, useState, useEffect } from "react";
import PropTypes from "prop-types";
import MultiSelectDropdown from "./multi-select-dropdown";
import {
    VisualizerRightPanel
} from "./components/VisualizerRightPanel";

const DefaultArrowRightPanel = ({
    sourcePageLabel,
    currentPage,
    linkDetails,
    saveUpdatedButtonsAndLinkData
}) => {
    const elementData = linkDetails.elements;
    const defaultValue = elementData.filter((element) => element.isAssigned);
    const [selectedElements, setSelectedElements] = useState(defaultValue);
    const chooseTextField = (element) => {
        if (element.elementType === "button") {
            return element.elementName ? element.elementName : element.elementTitle ? element.elementTitle : "button";
        } else {
            return element.elementName ? element.elementName : element.elementTitle ? element.elementTitle : "link";
        }
    };
    const onChange = (value) => {
        setSelectedElements(value);
    };
    const renderButtonsAndLinksDropDownComponent = () => {
        return (
            <MultiSelectDropdown
                label = {"Buttons / Links of " + sourcePageLabel}
                placeHolder = {"Choose " + sourcePageLabel + " Buttons / Links"}
                data={linkDetails.elements}
                textField= {element => chooseTextField(element)}
                groupBy="elementType"
                valueField="elementId"
                defaultValue={selectedElements}
                onChange={onChange}
            />
        );
    };
    const renderSaveButtonComponent = () => {
        return (
            <button
                onClick={() => saveUpdatedButtonsAndLinkData(selectedElements)}
                className={"btn btn-primary m-l-xs pull-left"}
            >
                <i
                    className="fa fa-cloud-upload"
                    aria-hidden="true">
                </i>
                 Save
            </button>
        );
    };
    return (
        <VisualizerRightPanel>
            {
                renderButtonsAndLinksDropDownComponent()
            }
            {
                renderSaveButtonComponent()
            }
        </VisualizerRightPanel>
    );
};

DefaultArrowRightPanel.propTypes = {
    sourcePageLabel: PropTypes.string,
    currentPage: PropTypes.object,
    linkDetails: PropTypes.object,
    saveUpdatedButtonsAndLinkData: PropTypes.func
};

export default DefaultArrowRightPanel;