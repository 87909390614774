import React, { useContext } from "react";
import PropTypes from "prop-types";
import { VisualizerContext } from ".";
import { EditPageName,VisualizerRightPanel,SetDefaultPage,FontsList } from "./components/VisualizerRightPanel";
import {
    getPageType,
    getTemplateTypeId,
    getPageTextName,
    formatSavedFonts,
    formatSelectedFonts
} from "./utilities/helpers";

const FunnelTemplateRightPanel = ({
    currentPage,
    fontLoader,
    selectedFonts
}) => {
    const VisualizerContextData = useContext(VisualizerContext);
    const {
        saveUpdatedPageName,
        changeDefaultPageStatus,
        defaultPage,
        googleFontsList,
        saveFonts
    } = VisualizerContextData;
    const currentPageTemplateTypeId = getTemplateTypeId(currentPage);
    const pageTypeLabel = getPageType(currentPageTemplateTypeId);
    const initialPageTitle = getPageTextName(currentPage);
    const pageReferenceId = currentPage.get("pageReferenceId");
    const pageViewReferenceId = currentPage.get("pageViewReferenceId");
    const isCurrentPageDefaultPage = currentPage.get("id") === defaultPage;

    const updateDefaultPageStatus = () => changeDefaultPageStatus({ pageViewReferenceId, currentPage });

    const savePageName = (currentPageTitle) => {
        currentPage.attr(".label/text", currentPageTitle);
        return saveUpdatedPageName({ currentPageTitle, currentPage });
    };

    const getSelectedFonts = formatSelectedFonts(selectedFonts);
    const updateFonts = (savedFonts) => {
        const fontUrlArray = formatSavedFonts(savedFonts);
        const params = {
            pageReferenceId,
            fontUrlArray
        };
        saveFonts(params);
    };

    return (
        <VisualizerRightPanel>
            <h2>Funnel Template</h2>
            <EditPageName
                pageTypeLabel={pageTypeLabel}
                initialPageTitle={initialPageTitle}
                savePageName={savePageName}
            />
            <SetDefaultPage
                updateDefaultPageStatus={updateDefaultPageStatus}
                isCurrentPageDefaultPage={isCurrentPageDefaultPage}
            />
            <FontsList
                fontList={googleFontsList}
                selectedFonts={getSelectedFonts}
                isFontLoading={fontLoader}
                saveFontsCall={updateFonts}
            />
        </VisualizerRightPanel>
    );
};

FunnelTemplateRightPanel.propTypes = {
    currentPage: PropTypes.object,
    selectedFonts: PropTypes.array,
    fontLoader: PropTypes.bool
};

export default FunnelTemplateRightPanel;