import UUID from "uuid-js";
import { checkClass } from "../utils/component";
import { fontProperties, borderProperties } from "../consts";

export const productRadioButtons = (editor, traits, { offers }) => {
    const c = {};
    const domc = editor.DomComponents;
    const defaultType = domc.getType("default");
    const defaultModel = defaultType.model;
    const defaultView = defaultType.view;
    const checkbox = domc.getType("input");
    const checkModel = checkbox.model;
    domc.addType("product-radio", {
        model: defaultModel.extend({
            init() {
                const uid = UUID.create(4);
                this.set("attributes", {
                    onclick: "addProduct(event)",
                    type: "radio",
                    name: "product"
                });
                checkClass(this);
                this.addClass(`fkt-button-${uid.toString()}`);
                this.listenTo(this, "change:checked", this.handleChecked);
                this.doStuff();
            },
            doStuff() {
                const categsTrait = this.get("traits").where({ name: "value" })[0];
                let offerProduct = [];
                if (offers && offers[0] !== "") {
                    offerProduct = offers.map(offer => ({ value: offer.value.toString(), name: offer.text }));
                    offerProduct = [
                        { value: "", name: "Choose Product" },
                        ...offerProduct,
                    ];
                }
                if (categsTrait) {
                    categsTrait.set("options", offerProduct);
                }
                editor.on("component:update", (component) => {
                    if (component.changed.value) {
                        component.attributes.attributes.value = component.changed.value;
                    }
                });

                editor.TraitManager.getTraitsViewer().render();
            },
            handleChecked() {
                let checked = this.get("checked");
                let attrs = this.get("attributes");
                const view = this.view;

                if (checked) {
                    attrs.checked = true;
                } else {
                    delete attrs.checked;
                }

                if (view) {
                    view.el.checked = checked;
                }
                this.set("attributes", { ...attrs });
            },
            defaults: {
                ...checkModel.prototype.defaults,
                "custom-name": c.labelButtonName,
                tagName: "input",
                draggable: true,
                unstylable: [...fontProperties, ...borderProperties, "background-color", "background"],
                attributes: { class: "form-check-input", type: "radio" },
                traits: [
                    {
                        type: "select",
                        label: c.labelTraitMethod,
                        name: "name",
                        options: [
                            { value: "product", name: "product" }
                        ],
                        changeProp: 1
                    },
                    {
                        type: "select",
                        name: "value",
                        label: c.labelTraitValue,
                        changeProp: 1
                    },
                    {
                        label: c.labelTraitChecked,
                        type: "checkbox",
                        name: "checked",
                        changeProp: 1
                    }
                ]
            },
        },
        {
            isComponent(el) {
                // eslint-disable-next-line
                if (el.tagName == "INPUT" && el.type == "radio") {
                    return { type: "radio" };
                }
            },
        }),
        view: defaultView.extend({
            events: {
                "click": "handleClick",
            },

            handleClick(e) {
                e.preventDefault();
            },
        }),
    });
};