import { FUNNEL_TEMPLATES_REDUCER_KEY } from "./reducer";

export const getFunnelTemplates = (state) => {
    return state[FUNNEL_TEMPLATES_REDUCER_KEY].funnels;
};
export const getActiveFunnelTemplates = (state) => {
    return state[FUNNEL_TEMPLATES_REDUCER_KEY].activeFunnels;
};

export const gethtmlPage = (state) => {
    return state[FUNNEL_TEMPLATES_REDUCER_KEY].htmlPage;
};

export const getFunnelTemplate = (state, funnelId) => {
    const { funnels } = state[FUNNEL_TEMPLATES_REDUCER_KEY];
    return funnels.find((f) => `${f.referenceId}` === `${funnelId}`);
};
export const getLoader = (state) => {
    return state[FUNNEL_TEMPLATES_REDUCER_KEY].isLoading;
};
export const getError = (state) => {
    return state[FUNNEL_TEMPLATES_REDUCER_KEY].isError;
};
