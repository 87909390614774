import { FunnelVisualizer as Visualizer } from "./funnel-visualizer";
import { connect } from "react-redux";
import { commonActions, visualizerActions, funnelActions } from "../actions";
import * as selectors from "../selectors/funnel-selectors";
import { LocalStorageHelper } from "../../utilities";
import { manageAuthorState } from "../../author/selectors";
import * as campaignSelectors from "../../campaign/selectors";
import * as saltSelectors from "../../login/selectors";
import { loadCampaigns } from "../../campaign/action";

function mapStateToProps(state, ownProps) {
    const importedFunnel = selectors.getImportedFunnel(state);
    const funnel = importedFunnel ? importedFunnel : selectors.getFunnelData(state);
    const localStorage = new LocalStorageHelper();
    localStorage.store("funnelReferenceId", ownProps.match.params.referenceId);
    const isError = selectors.getError(state);
    const pageError = selectors.getPageError(state);
    const importError = selectors.getImportError(state);
    const importFunnelError = selectors.getImportFunnelError(state);
    const campaigns = campaignSelectors.manageCampaignState(state);
    const settingsData = saltSelectors.getSettingsData(state);
    const isLoading = selectors.getLoader(state);
    let offersFormattedForDropdown = [];
    let upsalesFormattedForDropdown = [];
    let selectedCampaign = null;

    const authorsFormattedForDropdown = manageAuthorState(state).map(author => {
        return {
            value: author.id,
            text: author.firstName + " " + author.lastName
        };
    });
    const campaignsFormattedForDropdown = campaignSelectors.manageCampaignState(state).map(campaign => {
        return {
            value: campaign.campaignId,
            text: campaign.campaignName,
            products: campaign.products
        };
    });
    if (campaigns.length && funnel) {
        const currentFunnel = funnel;
        if (currentFunnel) {
            selectedCampaign = campaignSelectors.getCampaign(state, currentFunnel.campaignId);
        }
    }
    if (selectedCampaign) {
        offersFormattedForDropdown = selectedCampaign.products.filter(product =>
            product.productType === "OFFER"
        ).map((o) => ({
            value: o.campaignProductId,
            text: o.productName,
            type: o.productType
        }));

        upsalesFormattedForDropdown = selectedCampaign.products.filter(product =>
            product.productType === "UPSALE"
        ).map((u) => ({
            value: u.campaignProductId,
            text: u.productName,
            type: u.productType
        }));
    }

    return {
        funnelReferenceId: ownProps.match.params.referenceId,
        funnel,
        isLoading,
        importedFunnel,
        authors: authorsFormattedForDropdown,
        upsales: upsalesFormattedForDropdown,
        offers: offersFormattedForDropdown,
        campaigns: campaignsFormattedForDropdown,
        isError,
        importError,
        importFunnelError,
        pageError,
        salt: settingsData ? settingsData.salt : "",
    };
}

function mapDispatchToProps(dispatch) {

    return {
        getFunnelData: (funnelReferenceId) => dispatch(funnelActions.getFunnel(funnelReferenceId)),
        clearJsonData: () => dispatch(commonActions.clearJsonData()),
        clearFunnelData: () => dispatch(funnelActions.clearFunnelData()),
        loadFunnel: (pageId) => dispatch(funnelActions.loadFunnel(pageId)),
        editFunnel: (params) => dispatch(funnelActions.editFunnel(params)),
        loadCampaigns: () => dispatch(loadCampaigns()),
        resetData: (params, cb) => dispatch(funnelActions.visualizerFunnelEdit(params, cb)),
        errorClose: () => dispatch(funnelActions.errorClose()),
        importFunnel: (params) => dispatch(funnelActions.importFunnel(params)),
        showSuccessMessage: () => dispatch(funnelActions.showSuccessMessage()),
        initializeVisualizerData: () => dispatch(visualizerActions.initializeVisualizerData()),
    };
}

export const FunnelVisualizer = connect(mapStateToProps, mapDispatchToProps)(Visualizer);
