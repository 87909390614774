import _ from "underscore";
import { defaultTraits } from "./default-traits";
import { addOnTraits } from "./add-on-traits";
import { linkTraits } from "./link-traits";

const filterTraits = (traits, visibleOn = []) => {
    return traits.filter(trait => {
        if (trait.visibleOn) {
            const matched = _.intersection(trait.visibleOn, visibleOn);
            if (matched && matched.length) {
                return true;
            }
            return false;
        }
        return true;
    });

};

export const getTraits = (visibleOn) => ({
    traits: filterTraits(defaultTraits, visibleOn),
    addOnTraits,
    linkTraits: filterTraits(linkTraits, visibleOn),
});

