import {
    AssetManagerHelper,
} from "../../utilities";
// import { getEditorContainer } from "../utils/component";

const _renderAssetManagerModal = (editor) => {
    const assetManager = new AssetManagerHelper(editor);
    editor.runCommand("open-assets", {
        target: editor.getSelected()
    });
    assetManager.render();
};

const openAssetManager = (editor) => {
    _renderAssetManagerModal(editor);
};

export default {
    "open-am": openAssetManager,
};