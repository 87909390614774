import { showElement } from "./helper";

const minutesDelay = (editor, component) => {
    const minutesDelay = Number(component.changed.minutesDelay);
    if (component.changed.minutesDelay) {
        editor.getSelected().addAttributes(
            { "data-minutesDelay": minutesDelay }
        );
    } else {
        editor.getSelected().addAttributes(
            { "data-minutesDelay": "" }
        );
    }
    showElement(editor, "stickyBarCustom", "stickybar", component);
};

export default minutesDelay;