import { showElement } from "./helper";

const handleAttributesChange = (editor, component = {}) => {
    const changedComponent = component.changed;
    const componentAttributes = changedComponent && changedComponent.attributes;
    const alignBox = componentAttributes && componentAttributes.alignBox;
    if (alignBox) {
        editor.getSelected().addAttributes({ "align": component.changed.attributes.alignBox });
        if (alignBox === "right") {
            editor.getSelected().removeClass("justify-content-start");
            editor.getSelected().removeClass("justify-content-center");
            editor.getSelected().addClass("justify-content-end");
        }
        if (alignBox === "center") {
            editor.getSelected().removeClass("justify-content-end");
            editor.getSelected().removeClass("justify-content-start");
            editor.getSelected().addClass("justify-content-center");
        }
        if (alignBox === "left") {
            editor.getSelected().removeClass("justify-content-center");
            editor.getSelected().removeClass("justify-content-end");
            editor.getSelected().addClass("justify-content-start");
        }
        showElement(editor, "stickyBarCustom","stickybar",component);
        showElement(editor, "customizable_popup","popup",component);
    }
};

export default handleAttributesChange;