import { Campaign } from "./model";
export const CAMPAIGNS_REDUCER_KEY = "CAMPAIGNS";

export const initialState = [];
export const reducer = (state = initialState, action) => {
    switch (action.type) {
        case "LOAD_CAMPAIGNS_SUCCESS":
            return Array.isArray(action.campaigns)
                ? Array.from([...action.campaigns],
                    (c) => new Campaign(c)
                )
                : action.campaigns;
        default:
            return state;
    }
};
export default reducer;