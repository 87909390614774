import get from "lodash/get";
import { APIHelper } from "../utilities";
import { rootUrl } from "./urls";
import { WebpageFormat } from "../webpage-creator";

const TEMPLATE_BASE_URL = `${rootUrl}/Template`;
const PAGE_VIEW_BASE_URL = `${rootUrl}/PageView`;
const updateRoute = "UpdateActiveFlag";

export function templateGet() {
    return APIHelper.baseRequest(APIHelper.GET, TEMPLATE_BASE_URL, { contentType: APIHelper.getMultipartReqHeader(), headers: APIHelper.getGenericHeaders() });
}

export function templateGetAll() {
    return APIHelper.baseRequest(APIHelper.GET, `${TEMPLATE_BASE_URL}/GetAllTemplates`, { contentType: APIHelper.getMultipartReqHeader(), headers: APIHelper.getGenericHeaders() });
}

export function userTemplatesGet() {
    return APIHelper.baseRequest(APIHelper.GET, `${PAGE_VIEW_BASE_URL}/GetPagesForUser`, { contentType: APIHelper.getMultipartReqHeader(), headers: APIHelper.getGenericHeaders() })
        .then((response) => {
            const { result } = response;
            const userTemplates = result.map((page) => {
                return {
                    thumbnailUrl: get(page, ["fPage", "thumbnailUrl"]),
                    htmlUrl: get(page, "url"),
                    templateId: get(page, "fTemplateId"),
                    metaJson: get(page, "metaJson"),
                    templateTypeId: get(page, ["template", "templateTypeId"]),
                };
            });
            return {
                result: userTemplates,
            };
        });
}

export function premiumTemplates() {
    return APIHelper.baseRequest(APIHelper.GET, `${TEMPLATE_BASE_URL}/GetPremiumTemplates`, { contentType: APIHelper.getMultipartReqHeader(), headers: APIHelper.getGenericHeaders() });
}

export function deleteTemplate(referenceId) {
    return APIHelper.baseRequest(APIHelper.DELETE, `${TEMPLATE_BASE_URL}/${referenceId}`, { contentType: APIHelper.getMultipartReqHeader(), headers: APIHelper.getGenericHeaders() });
}

export function saveNewTemplate({
    title,
    html,
    css,
    pageData,
    templateTypeId,
    templateFile,
}, headers = APIHelper.getGenericHeaders()) {
    let templateFormData = new FormData();
    templateFormData.append("MetaJson", new Blob([JSON.stringify("")], { type: "application/json" }), "meta.json");
    templateFormData.append("Title", title);
    templateFormData.append("TemplateTypeId", templateTypeId);
    const wbf = new WebpageFormat(html, css, pageData);
    templateFormData.append("Html", new Blob([wbf.getFormattedWebPage()], { type: "text/html" }), "index.html");
    if (templateFile && templateFile.length) {
        templateFile.forEach((file, index) => {
            templateFormData.append(`TemplateFile[${index}].Url`, file.url);
        });
    }
    // templateFormData.append("styles_json", new Blob([styles_json], { type: "text/css" }),`${pageViewReferenceId}_styles.json`);
    return APIHelper.baseRequest(APIHelper.POST, TEMPLATE_BASE_URL, {
        contentType: APIHelper.getMultipartReqHeader(),
        requestBody: templateFormData,
        headers
    });
}

export function saveExistingTemplate({
    title,
    html,
    css,
    pageData,
    templateTypeId,
    referenceId,
    pageName = "index",
}, headers = APIHelper.getGenericHeaders()) {
    let templateFormData = new FormData();
    templateFormData.append("MetaJson", new Blob([JSON.stringify("")], { type: "application/json" }), "meta.json");
    templateFormData.append("Title", title);
    templateFormData.append("TemplateTypeId", templateTypeId);
    const wbf = new WebpageFormat(html, css, pageData);
    templateFormData.append("Html", new Blob([wbf.getFormattedWebPage()], { type: "text/html" }), `${pageName}.html`);
    return APIHelper.baseRequest(APIHelper.PUT, `${TEMPLATE_BASE_URL}/${referenceId}`, {
        contentType: APIHelper.getMultipartReqHeader(),
        requestBody: templateFormData,
        headers
    });
}
export function updateTemplate({
    referenceId,
    active,
    templateType,
    price
}, headers = APIHelper.getGenericHeaders()) {
    let templateFormData = new FormData();
    templateFormData.append("active", active);
    templateFormData.append("templateType", templateType);
    templateFormData.append("price", price);
    return APIHelper.baseRequest(APIHelper.PUT, `${TEMPLATE_BASE_URL}/${updateRoute}/${referenceId}`, {
        contentType: APIHelper.getMultipartReqHeader(),
        requestBody: templateFormData,
        headers
    });
}