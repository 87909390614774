import React from "react";
import PropTypes from "prop-types";

export default function CRMLoading({ title }) {
    return (
        <div style={{ paddingLeft: 15, paddingRight: 15, paddingBottom: 10 }}>
            <div className={"loading_box"}>
                <span
                    className="loading_spinner"
                ></span>
                <span>{title}</span>
            </div>
        </div>
    );
}

CRMLoading.propTypes = {
    title: PropTypes.string
};
