import * as actions from "./actions";
export const FUNNEL_TYPES_REDUCER_KEY = "FUNNEL_TYPES";

const initialState = [];


export function reducer(state = initialState, action) {
    switch (action.type) {
        case actions.LOAD_FUNNEL_TYPE_SUCCESS: {
            // const funnelArr = action.funnels.map((f) => {
            //     return new Funnel({ ...f });
            // });
            return {
                ...state,
                funnelTypes: action.payload
            };
        }
        // case actions.LOAD_CAMPAIGNS_SUCCESS:
        //     return {
        //         ...state,
        //         campaigns: action.campaigns
        //     };
        // case actions.CREATE_FUNNEL_SUCCESS:
        //     return {
        //         ...state,
        //         funnels: [...state.funnels, action.funnel]
        //     };
        // case actions.CREATE_FUNNEL_ERROR:
        //     return {
        //         ...state,
        //         isError: action.isError
        //     };
        // case actions.EDIT_FUNNEL_SUCCESS:
        //     return {
        //         ...state,
        //         funnels: [...state.funnels, action.funnel]
        //     };
        // case actions.EDIT_FUNNEL_ERROR:
        //     return {
        //         ...state,
        //         isError: action.isError
        //     };
        // case actions.UPDATE_FUNNEL_SUCCESS: {
        //     const updateFunnel = state.map((funnel) => {
        //         if (funnel.id === action.funnel.id) {
        //             return action.funnel;
        //         }
        //         return funnel;
        //     });
        //     return {
        //         ...state,
        //         funnels: updateFunnel,
        //     };
        // }
        // case actions.IS_LOADING: {
        //     return {
        //         ...state,
        //         isLoading: action.status
        //     };
        // }
        default:
            return state;
    }
}
