import React from "react";
import PropTypes from "prop-types";

import { TextInput, SelectInput, funnelTitleError, //funnelAuthorError,
    funnelCampaignIdError, funnelTypeError, funnelOfferError, funnelUpsaleError } from "../commons";
//This is the form where you can edit the title and campaign of the funnel and add offer and upsell products
//The FunnelForm function below is called by the CreateFunnelVisualizer component (src/funnels/create-funnel-visualizer/createFunnelVisualizer.js), and the parameters/functions come from createFunnelVisualizer.js
const FunnelForm = ({
    funnel,// allAuthors,
    isEdit,
    allOffers, allUpsales, funnelType, allCampaigns, onSave, onChange, loading, errors, isDefaultOptionEnabled,
    addOfferField, onOfferChange, addUpsaleField, onUpsaleChange, removeOfferField, removeUpsaleField, onCancel, onCampaignChange, isLoading, isError, campaignReroute }) => {
    //get the current offer and upsell properties of the funnels object from the parameters and set to variables
    const { offer } = funnel;
    const { upsale } = funnel;
    let myoffer = offer;
    let myupsales = upsale;
    //offer and upsale are often still a JSON string at this point, so parse them into objects/arrays
    if (offer && !Array.isArray(offer)) {
        myoffer = JSON.parse(offer);
    }
    if (upsale && !Array.isArray(upsale)) {
        myupsales = JSON.parse(upsale);
    }
    //add all options to the allOffers and allUpsales arrays from the parameters if they don't already exist
    //allOffers = all possible offer campaign products for the funnel's current campaign
    //allUpsales = all possible upsell campaign products for the funnel's current campaign
    const all = { value: "all", text: "ALL OFFERS", type: "OFFER" };
    const all1 = { value: "all", text: "ALL UPSELLS", type: "UPSALES" };
    const valueFound = allOffers.find(element => {
        if (element.value === "all") {
            return element;
        }
    });
    if (!valueFound) {
        allOffers.push(all);
    }
    const valueFound1 = allUpsales.find(element => {
        if (element.value === "all") {
            return element;
        }
    });
    if (!valueFound1) {
        allUpsales.push(all1);
    }
    //add offer selects using the current funnel offers variable (myoffer)
    const offerFields = myoffer.map((value, index) =>
        <div className="offerBox" key={index}>
            { !isEdit && ((index === 0) ?
                <div className="add_btn"><a onClick={() => addOfferField()} className="btn btn-primary"><i className="fa fa-plus" /></a></div> :
                <div className="add_btn"><a onClick={() => removeOfferField(index)} className="btn btn-danger"><i className="fa fa-close" /></a></div>)}
            <SelectInput
                isDefaultOptionEnabled={isDefaultOptionEnabled}
                name="offer"
                disabled={isEdit}
                value={myoffer[0] ? value.value : ""}
                defaultOption="Choose Offer"
                options={allOffers}
                onChange={(e) => onOfferChange(e, index)}
                errorMessage={funnelOfferError}
                error={errors.offerId}/>
        </div>
    );
    //add upsell selects using the current funnel upsells variable (myupsale)
    const upsaleFields = myupsales.map((value, index) =>
        <div className="offerBox" key={funnel.upsaleId}>
            { !isEdit && ((index === 0) ?
                <div className="add_btn"><a onClick={() => addUpsaleField()} className="btn btn-primary"><i className="fa fa-plus" /></a></div> :
                <div className="add_btn"><a onClick={() => removeUpsaleField(index)} className="btn btn-danger"><i className="fa fa-close" /></a></div>)}
            <SelectInput
                isDefaultOptionEnabled={isDefaultOptionEnabled}
                name="upsale"
                disabled={isEdit}
                value={myupsales[0] ? value.value : ""}
                defaultOption="Choose Upsells"
                options={allUpsales}
                onChange={(e) => onUpsaleChange(e, index)}
                errorMessage={funnelUpsaleError}
                error={errors.upsaleId}/>
        </div>
    );
    return (
        <>
            { isError && !isLoading ? <div className="alert alert-danger"><i className="fa fa-close fa-lg m-r-xs"/> Something Went Wrong... </div> : "" }
            { isLoading && !isDefaultOptionEnabled && <div className="alert alert-info"><i className="fa fa-spinner fa-pulse fa-lg m-r-xs"/> Please wait...</div> }
            <form className="funnelForm">
                <TextInput
                    name="title"
                    label="Title"
                    value={funnel.title}
                    onChange={onChange}
                    error={errors.title}
                    errorMessage={funnelTitleError}
                />
                { window.location.pathname === "/funnel" ?
                    <div style={{ float: "right", marginBottom: "1%" }}>
                        <button onClick={campaignReroute} className="btn btn-primary">Add A New Campaign</button>
                    </div>
                    : null
                }
                <SelectInput
                    isDefaultOptionEnabled={isDefaultOptionEnabled}
                    name="campaign"
                    label="Campaign"
                    value={funnel.campaign}
                    defaultOption="Select Campaign"
                    options={allCampaigns}
                    onChange={onCampaignChange}
                    error={errors.campaignId}
                    errorMessage={funnelCampaignIdError}
                    disabled={isEdit}
                />
                {/*<SelectInput*/}
                {/*name="fListFunnelTypeId"*/}
                {/*label="Funnel Type"*/}
                {/*value={funnel.fListFunnelTypeId}*/}
                {/*defaultOption="Choose Funnel Type"*/}
                {/*options={funnelType}*/}
                {/*onChange={onChange}*/}
                {/*error={errors.funnelType}*/}
                {/*errorMessage={funnelTypeError}/>*/}
                <div>
                    <label>Offers</label>
                    { offerFields }
                </div>
                <div>
                    <label>Upsells</label>
                    { upsaleFields }
                </div>
            </form>
            <div>
                <input
                    type="submit"
                    disabled={loading}
                    value={loading ? "Saving..." : "Save"}
                    className="btn btn-primary"
                    onClick={onSave}/>
                <input
                    type="button"
                    value="Cancel"
                    className="btn btn-default m-l-xs"
                    onClick={onCancel} />
            </div>
        </>
    );
};

FunnelForm.propTypes = {
    funnel: PropTypes.object.isRequired,
    //allAuthors: PropTypes.array,
    allCampaigns: PropTypes.array,
    campaigns: PropTypes.array,
    allOffers: PropTypes.array,
    allUpsales: PropTypes.array,
    funnelType: PropTypes.array,
    onSave: PropTypes.func.isRequired,
    onChange: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired,
    loading: PropTypes.bool,
    errors: PropTypes.object,
    filteredCampaigns: PropTypes.array,
    addOfferField: PropTypes.func,
    onOfferChange: PropTypes.func,
    addUpsaleField: PropTypes.func,
    onUpsaleChange: PropTypes.func,
    removeOfferField: PropTypes.func,
    removeUpsaleField: PropTypes.func,
    onCampaignChange: PropTypes.func,
    isLoading: PropTypes.bool,
    isError: PropTypes.bool,
    value: PropTypes.string,
    campaignReroute: PropTypes.func,
    isEdit: PropTypes.bool,
    isDefaultOptionEnabled: PropTypes.bool
};

export default FunnelForm;