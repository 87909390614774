import React from "react";
import PropTypes from "prop-types";

export const VisualizerRightPanel = (props) => {
    return (
        <>
            <div className="visualizer-right-panel active">
                <div className="col-xs-12 panel-content">
                    {
                        props.children
                    }
                </div>
            </div>
        </>
    );
};

VisualizerRightPanel.propTypes = {
    children: PropTypes.element,
};