import React, { Component } from "react";
import {
    func,
    array,
    object,
} from "prop-types";
import { ButtonToolbar, Row, Col, FormGroup, FormControl, ControlLabel, Button, Popover, Overlay } from "react-bootstrap";
import { triggerNofiticationSuccess, triggerNofiticationError } from "../../../../../src/commons/notification";
class FunnelTemplateTitle extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isPopOverEnable: false,
            funnelDetails: props.funnelData,
            funnelName: props.funnelData.title,
            isSubmitting: false,
        };
        this.handleUpdateFunnel = this.handleUpdateFunnel.bind(this);
    }

    async handleUpdateFunnel(e) {
        e.preventDefault();
        e.stopPropagation();
        const {
            funnelName,
        } = this.state;
        this.setState({ isSubmitting: true });
        this.props.updateFunnelTemplate(funnelName)
            .then(() => {
                triggerNofiticationSuccess({ message: "Funnel template name saved successfully." });
                this.setState({ isPopOverEnable: false, isSubmitting: false });
            })
            .catch((err) => {
                triggerNofiticationError({ message: err.message });
                this.setState({ isSubmitting: false });
            });
    }

    render() {
        const { isPopOverEnable, funnelDetails: { title }, isSubmitting } = this.state;
        const popoverClickRootClose = (
            <Popover id="popover-contained" className="popover-edit-funnel">
                <div>
                    <div>
                        <form onSubmit={(e) => {e.preventDefault(); this.handleUpdateFunnel("title");}}>
                            <Row>
                                <Col md={6}>
                                    <FormGroup>
                                        <ControlLabel className="custom-form-label">Title</ControlLabel>
                                        <FormControl
                                            className="form-control-width text-color"
                                            type="text"
                                            defaultValue={title}
                                            onChange={(e) => this.setState({
                                                funnelName: e.target.value
                                            })}
                                            autoFocus
                                        />
                                    </FormGroup>
                                </Col>
                                <Col md={6} className="p-0">
                                    <Button
                                        type="primary"
                                        className={`button btn-save ${isSubmitting ? "disabled" : ""}`}
                                        onClick={this.handleUpdateFunnel}
                                    >
                                        <span
                                            className={`${isSubmitting ? "loading_spinner" : ""}`}
                                        ></span>
                                        Save
                                    </Button>
                                </Col>
                            </Row>
                        </form>
                    </div>
                </div>
            </Popover>
        );
        return (
            <>
                <h3 className="funnel-title">
                    <span>{ title }</span>
                    <ButtonToolbar className="custom_popover">
                        <i onClick={(e) => {this.setState({ target: e.target, isPopOverEnable: !isPopOverEnable });}} className="fa fa-cog fa-spin m-l-sm pointer" />
                        {true &&
                            <Overlay
                                show={isPopOverEnable}
                                target={this.state.target}
                                placement="bottom"
                                container={this}
                                rootClose={false}
                            >
                                {popoverClickRootClose}
                            </Overlay>
                        }
                    </ButtonToolbar>
                </h3>
            </>
        );
    }
}

FunnelTemplateTitle.propTypes = {
    funnelData: object,
    allCampaigns: array,
    updateFunnelInfo: func,
    updateFunnelTemplate: func,
};

export default FunnelTemplateTitle;