// import React from "react";
// import PropTypes from "prop-types";


// export const Step2 = ({ onChange }) => {
//     return (
//         <div>
//             <fieldset>
//                 <div className="row">
//                     <div className="col-lg-8">
//                         <div className="form-group">
//                             <label>Username *</label>
//                             <input onChange={onChange} id="firstName" name="firstName" type="text" className="form-control required"/>
//                         </div>
//                         <div className="form-group">
//                             <label>Password*</label>
//                             <input onChange={onChange} id="password" name="password" type="password" className="form-control required"/>
//                         </div>
//                     </div>
//                 </div>
//             </fieldset>
//         </div>
//     );
// };
// Step2.propTypes = {
//     onChange: PropTypes.func.isRequired,
//     onSave: PropTypes.func.isRequired
// };

import React, { Component } from "react";
import PropTypes from "prop-types";

export class Step3 extends Component {
    constructor(props) {
        super(props);

        this.state = {
            username: props.getStore().username,
            password: props.getStore().password
        };

        this._validateOnDemand = true; // this flag enables onBlur validation as user fills forms
        this.validationCheck = this.validationCheck.bind(this);
        this.isValidated = this.isValidated.bind(this);
    }

    componentDidMount() {}

    componentWillUnmount() {}

    isValidated() {
        const userInput = this._grabUserInput(); // grab user entered vals
        const validateNewInput = this._validateData(userInput); // run the new input against the validator
        let isDataValid = false;

        // if full validation passes then save to store and pass as valid
        if (Object.keys(validateNewInput).every((k) => {return validateNewInput[k] === true;})) {
            if (this.props.getStore().postalCode !== userInput.postalCode || this.props.getStore().gender !== userInput.gender) { // only update store of something changed
                this.props.updateStore({
                    ...userInput,
                    savedToCloud: false // use this to notify step4 that some changes took place and prompt the user to save again
                }); // Update store here (this is just an example, in reality you will do it via redux or flux)
            }

            isDataValid = true;
        } else {
        // if anything fails then update the UI validation state but NOT the UI Data State
            this.setState(Object.assign(userInput, validateNewInput, this._validationErrors(validateNewInput)));
        }

        return isDataValid;
    }

    validationCheck() {
        if (!this._validateOnDemand)
            return;

        const userInput = this._grabUserInput(); // grab user entered vals
        const validateNewInput = this._validateData(userInput); // run the new input against the validator

        this.setState(Object.assign(userInput, validateNewInput, this._validationErrors(validateNewInput)));
    }

    _validateData(data) {
        return {
            usernameVal: /./.test(data.username), // required: regex w3c uses in html5
            passwordVal: /^[a-zA-Z[^0-9.!#$%&'*+/=?^_`{|}~-]+/.test(data.password), // required: regex w3c uses in html5
        };
    }

    _validationErrors(val) {
        const errMsgs = {
            usernameValMsg: val.usernameVal ? "" : "Enter your username",
            passwordValMsg: val.passwordVal ? "" : "Enter your password"
        };
        return errMsgs;
    }

    _grabUserInput() {
        return {
            username: this.refs.username.value,
            password: this.refs.password.value
        };
    }
    onClick = () => {
        this.isValidated();
        this.props.onSave();
    }
    render() {
        // const { username, password } = this.state;
        // explicit class assigning based on validation
        let notValidClasses = {};
        const { accountError } = this.props;
        if (typeof this.state.usernameVal === "undefined" || this.state.usernameVal) {
            notValidClasses.usernameCls = "no-error col-md-8";
        } else {
            notValidClasses.usernameCls = "has-error col-md-8";
            notValidClasses.usernameValGrpCls = "val-err-tooltip";
        }

        if (typeof this.state.passwordVal === "undefined" || this.state.passwordVal) {
            notValidClasses.passwordCls = "no-error col-md-8";
        } else {
            notValidClasses.passwordCls = "has-error col-md-8";
            notValidClasses.passwordValGrpCls = "val-err-tooltip";
        }

        return (
            <div>
                <fieldset>

                    <div className="row">
                        <div className="col-lg-8">
                            <form id="Form" className="form-horizontal">
                                <div className="form-group">
                                    <label className="col-md-12">
                                        <h1>Step 3: API Credentials</h1>
                                    </label>
                                </div>
                                {accountError &&
                                <div className="alert alert-danger">
                                    Enter Valid CRM Credentials
                                </div>}
                                <div className="form-group col-md-12 content form-block-holder">
                                    <label className="control-label col-md-2 col-lg-4">
                                        Username*
                                    </label>
                                    <div className={notValidClasses.usernameCls}>
                                        <input
                                            ref="username"
                                            autoComplete="off"
                                            type="text"
                                            placeholder="username"
                                            className="form-control"
                                            required
                                            defaultValue={this.state.username}
                                            onBlur={this.validationCheck} />
                                        <div className={notValidClasses.usernameValGrpCls}>{this.state.usernameValMsg}</div>
                                    </div>
                                </div>
                                <div className="form-group col-md-12 content form-block-holder">
                                    <label className="control-label col-md-2 col-lg-4">
                                    Password*
                                    </label>
                                    <div className={notValidClasses.passwordCls}>
                                        <input
                                            ref="password"
                                            autoComplete="off"
                                            type="password"
                                            placeholder="Password"
                                            className="form-control"
                                            required
                                            defaultValue={this.state.password}
                                            onBlur={this.validationCheck} />
                                        <div className={notValidClasses.passwordValGrpCls}>{this.state.passwordValMsg}</div>
                                    </div>
                                </div>
                                <div className="form-group col-xs-12">
                                    <div className="col-md-10 col-md-offset-2 col-lg-offset-4">
                                        <input id="acceptTerms" name="acceptTerms" type="checkbox" className="required"/>
                                        <label for="acceptTerms" className="font-normal m-l-xs">I agree with the <a>Terms and Conditions.</a></label>
                                    </div>
                                </div>
                            </form>
                        </div>
                        <div className="col-lg-4 col-md-12 col-xs-12">
                            <div className="text-center">
                                <div style={{ marginTop: "20px" }}>
                                    <i className="fa fa-sign-in" style={{ fontSize: "180px", color: "#e5e5e5" }}/>
                                </div>
                            </div>
                        </div>
                    </div>
                </fieldset>
                <button className="btn btn-next btn-primary btn-lg pull-right" onClick={this.onClick}>Save</button>
            </div>
        );
    }
}
Step3.propTypes = {
    updateStore: PropTypes.Object,
    getStore: PropTypes.func,
    onSave: PropTypes.func.isRequired,
    accountError: PropTypes.bool
};