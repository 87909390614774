export const generatePageInfoForChangeSet = (cell, addOns) => {
    return {
        pageLabel: cell.get("pageLabel").label,
        pageViewReferenceId: cell.get("pageViewReferenceId"),
        pageReferenceId: cell.get("pageReferenceId"),
        addOns,
    };
};

export const generateDeleteSplitPageInfoForChangeSet = (cell) => {
    return {
        splitReferenceId: cell.get("splitReferenceId"),
        splitPageReferenceId: cell.get("splitPageAttributes").pageReferenceId,
        splitPageViewReferenceId: cell.get("splitPageAttributes").pageViewReferenceId
    };
};

export const generateLinkInfoForChangeSet = (link) => {
    return {
        targetPageCellViewId: link.get("target").id,
        sourcePageCellViewId: link.get("source").id
    };
};

export const generateDottedLineValues = (dottedLine) => {
    return {
        type: "dottedLink",
        source: dottedLine.source,
        target: dottedLine.target,
        z: dottedLine.z,
        vertices: dottedLine.vertices,
        id: dottedLine.id,
        subType: "dottedLink"
    };
};

export const generateComponentValues = (cell) => {
    return {
        id: cell.get("id"),
        thumbnailUrl: cell.get("thumbnailUrl"),
        templateTypeId: cell.get("elementMetaData").templateTypeId,
        position: cell.get("position"),
        icon: cell.get("icon"),
        isDefaultPage: cell.get("isDefaultPage"),
        isEdited: cell.get("isEdited"),
        isSplitEnabled: cell.get("isSplitEnabled"),
        isTemplateSelected: cell.get("isTemplateSelected"),
        premium: cell.get("premium"),
        z: cell.get("z"),
        pageLabel: cell.get("pageLabel").label,
        pageViewReferenceId: cell.get("pageViewReferenceId"),
        pageReferenceId: cell.get("pageReferenceId"),
        type: cell.get("type")
    };
};

export const generateTextComponentValues = (cell = {}) => {
    const position = cell.get("position") || {};
    return {
        text: cell.get("input") || "",
        position,
        z: cell.get("z"),
        type: cell.get("type"),
    };
};

export const generateWinnerSplitPageInfoForChangeSet = (page) => {
    return {
        ...defaultSplitPageInfo(page),
        winner: true
    };
};

export const generateSplitPageInfoForChangeSet = (page, template = null) => {
    return {
        ...defaultSplitPageInfo(page),
        ...splitPageArray(page, template)

    };
};

const splitPageArray = (page, template = null) => {
    return {
        splitPages: [
            generateSplitPageProperties(page, template)
        ]
    };
};

export const defaultSplitPageInfo = (page) => {
    return {
        splitPageReferenceId: page.get("pageReferenceId"),
        splitPageViewReferenceId: page.get("pageViewReferenceId"),
        splitReferenceId: page.get("splitReferenceId"),
    };
};

export const generateSplitPageProperties = (page, template = null) => {
    const splitPageProperties = {
        pageReferenceId: page.get("splitPageAttributes").pageReferenceId,
        pageTitle: page.get("pageLabel").label,
        pageViews: {
            pageViewReferenceId: page.get("splitPageAttributes").pageViewReferenceId,
            pageViewTitle: page.get("pageLabel").label
        }
    };
    if (template) {
        return {
            ...splitPageProperties,
            splitNewTemplate: true,
            templateId: template.templateId
        };
    } else {
        return splitPageProperties;
    }
};

export const setWinnerApiRequestParams = (page) => {
    return {
        action: "declareWinner",
        componentType: "page",
        componentId: page.get("id"),
        values: {
            ...generateComponentValues(page),
        },
        componentData: generateWinnerSplitPageInfoForChangeSet(page),
    };
};

export const getLinkArrowColor = (link) => link.attr(".marker-target/fill");

export const updateLinkApiRequestParams = (link) => {
    const linkData = {
        id: link.get("id"),
        sourcePageCellViewId: link.get("source").id,
        targetPageCellViewId: link.get("target").id,
        type: link.get("type"),
        z: link.get("z"),
        color: getLinkArrowColor(link)
    };
    const linkUpdateParams = {
        action: "update",
        componentType: "link",
        componentId: link.get("id"),
        values: linkData,
    };
    return linkUpdateParams;
};