
export const popup = (editor) => {
    const domc = editor.DomComponents;
    var dType = domc.getType("default");
    var dModel = dType.model;
    var dView = dType.view;

    domc.addType("popup", {
        model: dModel.extend({
            defaults: Object.assign({}, dModel.prototype.defaults, {
                traits: [
                    // strings are automatically converted to text types
                    {
                        type: "text",
                        name: "name",
                    }],
            }),
        }, {
            isComponent: function (el) {
                //eslint-disable-next-line
                if (el.tagName === "div") {
                    return { type: "popup" };
                }
            },
        }),

        view: dView.extend({
            events: {
                "click": "handleClick"
            },
            init() {
                this.listenTo(this.model, "change:content", this.updateContent);
            },
            updateContent() {
                this.el.innerHTML = this.model.get("content");
            },
            handleClick(e) {
                e.preventDefault();
            },
        }),
        onValueChange: function () {
            this.target.set("content", this.model.get("value"));
        }
    });
};