import React, { Component } from "react";
import PropTypes from "prop-types";
import {
    Modal,
    Tabs,
    Tab,
} from "react-bootstrap";
import { FunnelThumbnailCard } from "../../../funnels-list/funnel-thumbnail";
import { funnelTemplateWithAllMap } from "../../../constants/funnel-template-types";
import { TemplateTypeSelectDropdown } from "../../../template/template-type-select-dropdown";

export class ImportFunnelTemplateModal extends Component {
    constructor(props) {
        super(props);
        this.onFunnelItemSelected = this.onFunnelItemSelected.bind(this);
        this.loadActiveFunnelTemplates = this.loadActiveFunnelTemplates.bind(this);
        this.onDropdownChange = this.onDropdownChange.bind(this);
        this.state = {
            funnels: [...props.activeFunnels],
            initialTemplateTypeId: 0
        };
    }

    componentDidMount() {
        const {
            loadActiveFunnelTemplates,
        } = this.props;
        loadActiveFunnelTemplates();
    }
    async loadActiveFunnelTemplates() {
        const {
            loadActiveFunnelTemplates,
        } = this.props;
        try {
            await loadActiveFunnelTemplates();
            // eslint-disable-next-line react/no-did-mount-set-state
            this.setState({ funnels: [...this.props.activeFunnels] }, () => {
            });
        } catch (err) {
            // eslint-disable-next-line react/no-did-mount-set-state
            this.setState({
                funnels: []
            });
        }
    }
    closeImportFunnelPopup = () => {
        this.props.updateVisualizerData({ showImportFunnelModal: false });
    }

    onFunnelItemSelected(funnel = {}) {
        const referenceId = funnel.referenceId;
        if (referenceId) {
            this.updateFunnelData(referenceId);
            this.closeImportFunnelPopup();
        }
    }
    updateFunnelData = (importId) => {
        const { funnel } = this.props;
        const referenceId = funnel.referenceId;
        if ((importId) && (importId !== funnel.referenceId)) {
            this.props.importFunnelTemplate({ referenceId, importId })
                .then(this.props.getFunnelData(this.props.funnelReferenceId));
        }
    }
    onDropdownChange(funnelTemplateType) {
        const funnelTemplates = [...this.props.activeFunnels];
        if (!funnelTemplateType.templateTypeId) {
            this.setState({ funnels: [...funnelTemplates] });
        } else {
            let funnelTemplateList = funnelTemplates.filter(function (element) {
                return element.fListFunnelTypeId === funnelTemplateType.templateTypeId;
            });
            this.setState({ funnels: funnelTemplateList, initialTemplateTypeId: funnelTemplateType.templateTypeId, funnelType: funnelTemplateType.label });
        }
    }

    render() {
        const {
            funnels,
            initialTemplateTypeId,
            funnelType
        } = this.state;
        const { showImportFunnelModal } = this.props.visualizerData;
        const showTemplateCard = funnels && (funnels.length > 0);
        return (
            <Modal
                show={showImportFunnelModal}
                backdrop="static"
                onHide={this.closeImportFunnelPopup}
                bsSize=""
                className="existing-funnels-modal"
                aria-labelledby="contained-modal-title-lg"
            >
                <Modal.Header closeButton>
                    <Modal.Title><h4 className="text-white">Import Funnels</h4></Modal.Title>
                </Modal.Header>
                <Modal.Body className="m-n">
                    <Tabs defaultActiveKey={1} id="funnel-template-switch-tab">

                        <Tab eventKey={1} title="Template Funnels">
                            <div className="wrapper border-bottom page-heading">
                                <div className="row m-n">
                                    <div className="col-sm-4 col-sm-offset-8">
                                        <TemplateTypeSelectDropdown
                                            className="inline m-r-md m-t-sm"
                                            label="Filter by Funnel Type:"
                                            onDropdownChange={this.onDropdownChange}
                                            templateTypeMap={funnelTemplateWithAllMap}
                                            initialTemplateTypeId={initialTemplateTypeId}
                                        />
                                    </div>
                                </div>
                                {
                                    !showTemplateCard && (
                                        <div className="col-xs-12 alert alert-danger">
                                            No templates available for {funnelType}
                                        </div>
                                    )
                                }

                                <div className="centerList">
                                    {
                                        funnels.map((funnel,index) => {

                                            const thumbnailUrl = funnel.thumbnailUrl + "?" + new Date().getTime();
                                            return (
                                                <FunnelThumbnailCard key={index}
                                                    funnel={funnel}
                                                    onFunnelItemSelected={this.onFunnelItemSelected}
                                                    thumbnailUrl={thumbnailUrl}
                                                />
                                            );
                                        })
                                    }
                                </div>
                            </div>
                        </Tab>
                    </Tabs>
                </Modal.Body>
            </Modal>
        );
    }
}

ImportFunnelTemplateModal.propTypes = {
    loadActiveFunnelTemplates: PropTypes.func,
    activeFunnels: PropTypes.array,
    getFunnelData: PropTypes.func,
    importFunnelTemplate: PropTypes.func,
    visualizerData: PropTypes.object,
    updateVisualizerData: PropTypes.func,
    funnel: PropTypes.object,
    funnelReferenceId: PropTypes.string,
};

ImportFunnelTemplateModal.defaultProps = {
    show: false,
    defaultFunnelTemplates: [],
};