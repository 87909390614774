import React ,{ Component } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import "./settings.css";
import { Modal } from "react-bootstrap";
import DomainList from "./domain-list";
import CreateDomainModal from "./create-domain-modal";
import EditDomainModal from "./edit-domain-modal";
import DeleteDomainModal from "./delete-domain-modal";
import WarningModal from "./warning-modal";
import InvalidDomainErrorModal from "./invalid-domain-error-modal";
import { constants } from "./constants";
import { domainConstants } from "../funnels/constants";

const {
    addYourOwnDomain,
    domainLabel,
    wwwLabel,
    domainNamePlaceholder,
    invalidDomainName,
    addDomainLabel,
    editDomainTitle,
    noteForCnameRecordChange,
    noteForSSLCertGeneration,
    noteToAddCnameRecords,
    domainStatusLabel,
    sslStatusLabel,
} = domainConstants;


export class Settings extends Component {
    constructor(props, context) {
        super(props, context);
        this.state = {
            userData: Object.assign({}, props.userData),
            settingsData: Object.assign({}, props.settingsData),
            showError: false,
            showAddNewDomainPopUp: false,
            showEditDomainPopUp: false,
            error: "",
            hover: false,
            domainName: "",
            search: "",
            domains: props.domains ? props.domains : [],
            editDomainDetails: {},
            showDeleteDomainModal: false,
            isDomainStateEnabled: true,
            deleteDomainReferenceId: "",
            showWarningModal: false,
            domainStatus: "",
            editDomainStateMessage: true,
            showValidationError: false,
            isCreateDomainError: false,
            isDataLoading: true,
            isLoadingDomain: false,
            accountStore: {
                firstName: "",
                lastName: "",
                username: "",
                password: "",
                address: "",
                city: "",
                state: "",
                postalCode: "",
                phone: "",
                company: "",
                profilePicture: ""
            },
        };
        this.props.loadDomains().catch(() => {});
        this.props.loadCompany().catch(() => {});
        this.updateSettingsState = this.updateSettingsState.bind(this);
        this.updateUserState = this.updateUserState.bind(this);
        this.updateUser = this.updateUser.bind(this);
        this.props.getAccountInfo().catch(() => {});
        this.handleClose = this.handleClose.bind(this);
    }

    componentWillReceiveProps(nextProps) {
        const { editDomainDetails } = this.state;
        if (nextProps.error !== this.props.error) {
            this.setState({ error: Object.assign({}, nextProps.error) });
        }
        if (nextProps.domain && (nextProps.domain !== this.props.domain)) {
            this.setState({ editDomainDetails: nextProps.domain });
        }
        this.setState({ userSetting: Object.assign({}, nextProps.userSetting) ,
            settingsData: Object.assign({}, nextProps.settingsData),
            userData: Object.assign({}, nextProps.userData),
            domains: nextProps.domains,
            editDomainStateMessage: nextProps.editDomainStateMessage,
            isCreateDomainError: nextProps.isCreateDomainError,
            isDataLoading: nextProps.isLoading,
            isLoadingDomain: nextProps.isLoadingDomain
        });
        this.forceUpdate();
    }
    updateUser(event) {
        event.preventDefault();
        const accountStore = this.state.accountStore;
        const settingsData = Object.assign({}, this.state.settingsData);
        const userSetting = Object.assign({}, this.state.userSetting);
        const userData = Object.assign({}, this.state.userData);
        accountStore.firstName = userSetting.firstName;
        accountStore.lastName = userSetting.lastName;
        accountStore.company = userData.name;
        accountStore.username = settingsData.apiUsername;
        accountStore.password = settingsData.apiPassword;
        accountStore.profilePicture = userSetting.profilePicture;
        accountStore.address = userData.addressLine1;
        accountStore.city = userData.city;
        accountStore.state = userData.state;
        accountStore.postalCode = userData.postalCode;
        accountStore.phone = userData.phone;
        this.props.actions.putAccountInfo(accountStore);
        this.setState({ userSetting: userSetting, settingsData: settingsData });
    }
    updateSettingsState(event) {
        const field = event.target.name;
        const userSetting = Object.assign({}, this.state.userSetting);
        userSetting[field] = event.target.value;
        if (userSetting[field] === userSetting.profilePicture) {
            userSetting.profilePicture = event.target.files[0];
        }
        return this.setState({ userSetting: userSetting });
    }
    updateUserState(event) {
        const field = event.target.name;
        const userData = Object.assign({},this.state.userData);
        const userSetting = Object.assign({}, this.state.userSetting);
        userData[field] = event.target.value;
        userSetting[field] = event.target.value;
        return this.setState({ userData: userData, userSetting: userSetting });
    }
    updateDomainNameState = (event) => {
        const name = event.target.value;
        this.setState({ domainName: name, showValidationError: false });
    }
    redirectUser = () => {
        this.props.history.push("/dashboard");
    }
    errorHandler(message) {
        message = Object.values(message);
        if (message) {
            alert(message);
        }
    }
    handleShow() {
        this.setState({ showError: true });
    }
    handleClose() {
        this.setState({ error: "" });
    }
    closeCreateDomainErrorModal = () => {
        this.setState({ isCreateDomainError: false }, () => this.props.resetCreateDomainErrorMessage());
    }
    saveNewDomain = (name) => {
        if (name.indexOf(".") !== -1) {
            this.setState({ showAddNewDomainPopUp: false, domainName: "", showValidationError: false });
            return this.props.createDomain(name);
        }
    }
    closeNewDomainPopup = () => {
        this.setState({ showAddNewDomainPopUp: false, showValidationError: false, domainName: "" });
    }
    addNewDomain = () => this.setState({ showAddNewDomainPopUp: true });
    changeDomainState = (e) => {
        const editDomainDetails = Object.assign({}, this.state.editDomainDetails);
        editDomainDetails.state = (e.target.value === "true");
        this.setState({ editDomainDetails: editDomainDetails });
    }
    saveEditedDomain = (e) => {
        const { editDomainDetails } = this.state;
        this.props.editDomain(editDomainDetails);
        this.setState({ showEditDomainPopUp: false });
    }
    closeEditDomainPopup = () => {
        this.setState({ showEditDomainPopUp: false, editDomainDetails: {} });
    }
    editDomain = (domain) => {
        if (!this.state.isLoadingDomain) {
            this.props.getDomainStatus(domain.referenceId);
            const editDomainDetails = Object.assign({}, this.state.editDomainDetails);
            editDomainDetails.referenceId = domain.referenceId;
            editDomainDetails.https = domain.https;
            editDomainDetails.state = domain.state;
            editDomainDetails.cname = domain.cname;
            this.setState({ showEditDomainPopUp: true, editDomainDetails: editDomainDetails ? editDomainDetails : domain });
        }
    }
    deleteDomain = (domain) => {
        if (!this.state.isLoadingDomain) {
            this.setState({
                deleteDomainReferenceId: domain.referenceId,
                showDeleteDomainModal: true,
                showWarningModal: false,
                domainStatus: domain.status
            });
        }
    }
    isDomainStatusInProgress = (domainStatus) => domainStatus === "InProgress";
    deleteDomainConfirm = () => {
        this.props.deleteDomain(this.state.deleteDomainReferenceId)
            .then(() => {
                const {
                    domains,
                } = this.state;
                const updatedDomains = domains.filter((domain) => domain.referenceId !== this.state.deleteDomainReferenceId);
                this.setState({
                    domains: updatedDomains,
                });
            });
        this.closeDeleteDomainModal();
    }
    closeDeleteDomainModal = () => {
        this.setState({ showDeleteDomainModal: false });
    }
    closeDeleteDomainWarningModal = () => {
        this.setState({ showWarningModal: false, editDomainStateMessage: true },() => this.props.resetDomainStateMessage());
    }
    filterData = (event) => {
        const searchText = event.target.value;
        this.setState({ search: searchText });
    }
    formatText = (text) => {
        if (text) return text
            .replace(/(\B)[^ ]*/g,match => (match.toLowerCase()))
            .replace(/^[^ ]/g,match => (match.toUpperCase()));
    }
    render() {
        const { editDomainDetails,
            userData,
            userSetting,
            showAddNewDomainPopUp,
            showEditDomainPopUp,
            showDeleteDomainModal,
            showWarningModal,
            domainStatus,
            error,
            domainName,
            isCreateDomainError,
            domains,
            search,
            showValidationError,
            editDomainStateMessage,
            isDataLoading,
            isLoadingDomain } = this.state;
        const { myDomains,
            pleaseWait,noResultsFound,
            domainsTitle,actionsTitle,
            cancelEditLabel,
            saveEditlabel,confirmationTitle,sureToDeleteLabel,
            yesLabel,noLabel,okLabel,addNewDomain,warningTitle,
            domainStillActiveMessage, howToChangeDomainStateMessage,
            domainRecentlyUpdatedMessage,setCnameRecordsMessage,
            errorTitle,
        } = constants;
        const { isLoading } = this.props;
        const filteredDomains = domains && domains.filter(
            (domain) => {
                return String(domain.name).toLowerCase().indexOf(String(search).toLowerCase()) !== -1;
            }
        );
        const domainList = filteredDomains &&
         filteredDomains.map(domain =>
             <DomainList domain ={domain}
                 key={domain.referenceId}
                 editDomain = {this.editDomain}
                 deleteDomain ={this.deleteDomain}
             />);
        const createDomainModal = <CreateDomainModal
            showAddNewDomainPopUp = {showAddNewDomainPopUp}
            closeNewDomainPopup = {this.closeNewDomainPopup}
            updateDomainNameState = {this.updateDomainNameState}
            domainName = {domainName}
            saveNewDomain = {this.saveNewDomain}
            domainLabel = {domainLabel}
            wwwLabel = {wwwLabel}
            domainNamePlaceholder = {domainNamePlaceholder}
            invalidDomainName = {invalidDomainName}
            addDomainLabel = {addDomainLabel}
            domainList = {filteredDomains}
        />;
        const editDomainModal = <EditDomainModal
            showEditDomainPopUp = {showEditDomainPopUp}
            closeEditDomainPopup= {this.closeEditDomainPopup}
            editDomainDetails={editDomainDetails}
            cnameRecords = {editDomainDetails.cnameRecords}
            editDomainTitle = {editDomainTitle}
            nameTitle = "Name"
            typeTitle = "Type"
            valueTitle = "Value"
            noteToAddCnameRecords = {noteToAddCnameRecords}
            noteForCnameRecordChange = {noteForCnameRecordChange}
            noteForSSLCertGeneration = {noteForSSLCertGeneration}
            domainStatusLabel = {domainStatusLabel}
            sslStatusLabel = {sslStatusLabel}
        />;
        const deleteDomainModal = <DeleteDomainModal
            showDeleteDomainModal={showDeleteDomainModal}
            closeDeleteDomainModal={this.closeDeleteDomainModal}
            deleteDomainConfirm={this.deleteDomainConfirm}
            confirmationTitle = {confirmationTitle}
            sureToDeleteLabel = {sureToDeleteLabel}
            yesLabel = {yesLabel}
            noLabel = {noLabel}
        />;
        const warningModal = <WarningModal
            showWarningModal={showWarningModal}
            editDomainStateMessage={editDomainStateMessage}
            closeDeleteDomainWarningModal={this.closeDeleteDomainWarningModal}
            domainStatus={domainStatus}
            warningTitle = {warningTitle}
            domainStillActiveMessage = {domainStillActiveMessage}
            howToChangeDomainStateMessage = {howToChangeDomainStateMessage}
            domainRecentlyUpdatedMessage = {domainRecentlyUpdatedMessage}
            setCnameRecordsMessage = {setCnameRecordsMessage}
            okLabel = {okLabel}
        />;
        const invalidDomainErrorModal = <InvalidDomainErrorModal
            isCreateDomainError={isCreateDomainError}
            closeCreateDomainErrorModal={this.closeCreateDomainErrorModal}
            errorTitle = {errorTitle}
            invalidDomainName = {invalidDomainName}
        />;
        return (
            <>
                <div className="row wrapper border-bottom white-bg page-heading" style={{ paddingTop: "1%", paddingBottom: "1%", }}>
                    <div className="col-lg-10">
                        <h2>Account Settings</h2>
                        <ol className="breadcrumb">
                            <li>
                                <Link to="/dashboard">Home</Link>
                            </li>
                            <li className="active">
                                <a>Account Settings</a>
                            </li>
                        </ol>
                    </div>
                    <div className="col-lg-2">
                        <div className="col-sm-4 col-sm-offset-2">
                            <button className="btn btn-primary" onClick={this.updateUser} type="button">Save changes</button>
                        </div>
                    </div>
                </div>
                { (isDataLoading) && <div className="alert alert-info m-t-md"><i className="fa fa-spinner fa-pulse fa-lg m-r-xs"/>{pleaseWait}</div> }
                <div className="row wrapper">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="ibox float-e-margins">
                                <div className="ibox-title">
                                    <h5>Account Information</h5>
                                </div>
                                <div className="ibox-content">
                                    <form method="get" className="form-horizontal">
                                        <div className="form-group"><label className="col-sm-2 control-label">Profile Picture</label>
                                            <div className="col-sm-1"><img style={{ maxWidth: "100%", maxHeight: "100%" }} src={userSetting ? userSetting.profilePicture + `?${Date.now()}` : require("../img/icons/if_male_628288.svg")} alt=""/></div>
                                            <div className="col-sm-6">
                                                <input type="file" onChange={this.updateSettingsState} className="profilePicture" name="profilePicture"/>
                                            </div>
                                        </div>
                                        <div className="hr-line-dashed"/>
                                        <div className="form-group"><label className="col-sm-2 control-label">Email</label>
                                            <div className="col-sm-10"><input type="email" disabled value={userSetting ? userSetting.email : ""} className="form-control" name="email"/></div>
                                        </div>
                                        <div className="hr-line-dashed"/>
                                        <div className="form-group"><label className="col-lg-2 control-label">First Name</label>
                                            <div className="col-lg-10"><input type="text" onChange={this.updateSettingsState} name="firstName" value={userSetting && userSetting.firstName !== null ? `${userSetting.firstName}` : ""} className="form-control"/></div>
                                        </div>
                                        <div className="hr-line-dashed"/>
                                        <div className="form-group"><label className="col-lg-2 control-label">Last Name</label>
                                            <div className="col-lg-10"><input type="text" onChange={this.updateSettingsState} name="lastName" value={userSetting && userSetting.lastName !== null ? `${userSetting.lastName}` : ""} className="form-control"/></div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="ibox float-e-margins">
                                <div className="ibox-title">
                                    <h5>Company Information</h5>
                                </div>
                                <div className="ibox-content">
                                    <form method="get" className="form-horizontal">
                                        <div className="form-group"><label className="col-sm-2 control-label">Company Name</label>
                                            <div className="col-sm-10"><input type="text" onChange={this.updateUserState} value={userData ? userData.name : ""} className="form-control" name="name" readOnly/></div>
                                        </div>
                                        <div className="hr-line-dashed"/>
                                        <div className="form-group"><label className="col-sm-2 control-label">Address Line 1</label>
                                            <div className="col-sm-10"><input type="text" onChange={this.updateUserState} className="form-control" value={userData ? userData.addressLine1 : ""} name="addressLine1"/></div>
                                        </div>
                                        <div className="hr-line-dashed"/>
                                        <div className="form-group"><label className="col-sm-2 control-label">City</label>
                                            <div className="col-sm-10"><input type="text" onChange={this.updateUserState} className="form-control" value={userData ? userData.city : ""} name="city"/></div>
                                        </div>
                                        <div className="hr-line-dashed"/>
                                        <div className="form-group"><label className="col-sm-2 control-label">State</label>
                                            <div className="col-sm-10"><input type="text" onChange={this.updateUserState} className="form-control" value={userData ? userData.state : ""} name="state"/></div>
                                        </div>
                                        <div className="hr-line-dashed"/>
                                        <div className="form-group"><label className="col-sm-2 control-label">Postal Code</label>
                                            <div className="col-sm-10"><input type="text" onChange={this.updateUserState} className="form-control" value={userData ? userData.postalCode : ""} name="postalCode"/></div>
                                        </div>
                                        <div className="hr-line-dashed"/>
                                        <div className="form-group"><label className="col-sm-2 control-label">Phone</label>
                                            <div className="col-sm-10"><input type="text" onChange={this.updateUserState} className="form-control" value={userData ? userData.phone : ""} name="phone"/></div>
                                        </div>
                                        <div className="hr-line-dashed"/>
                                        <div className="form-group">
                                            <div className="col-sm-4 col-sm-offset-2">
                                                <button style={{ marginRight: "2%" }} onClick={this.redirectUser} className="btn btn-white" type="submit">Cancel</button>
                                                <button className="btn btn-primary" onClick={this.updateUser} type="button">Save changes</button>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="ibox float-e-margins">
                                <div className="ibox-title">
                                    <h5>{myDomains}</h5>
                                    <div>
                                        <div className="add-domain">
                                            <div id="createDomain">
                                                <input id="newDomain" ref="newDomain" type="submit"
                                                    value={addNewDomain}
                                                    onClick={this.addNewDomain}
                                                    className="btn btn-primary"/>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="ibox-content">
                                    <div className="row">
                                        <div className="form-group">
                                            <div className="col-sm-1"></div>
                                            <div className="col-sm-6"><input type="text" onChange={this.filterData} placeholder="Search" className="form-control" name="name"/></div>
                                        </div>
                                    </div>
                                    { isLoadingDomain && <div className="alert alert-info m-t-md"><i className="fa fa-spinner fa-pulse fa-lg m-r-xs"/>{pleaseWait}</div> }
                                    {!isLoadingDomain && !filteredDomains ?
                                        <div className="text-dark text-center"><h4>{noResultsFound}</h4></div>
                                        :
                                        <div className="table-responsive domain-table">
                                            <table className="table table-striped table-hover">
                                                <thead>
                                                    <tr>
                                                        <th className="col-sm-1"></th>
                                                        <th className="domain-name">{domainsTitle}</th>
                                                        <th>{actionsTitle}</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    { domainList }
                                                </tbody>
                                            </table>
                                        </div>}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Modal show={this.state.error} backdrop = "static" onHide={this.handleClose} bsSize="large" aria-labelledby="contained-modal-title-lg">
                    <Modal.Header closeButton style={{ backgroundColor: "rgb(47, 64, 77)", color: "white" }} >
                        <h3 className="text-center" style={{ fontWeight: "bold", letterSpacing: "2px", textTransform: "uppercase" }}>Error</h3>
                    </Modal.Header>
                    <Modal.Body>
                        <h3 className="text-center">{Object.values(this.state.error)}</h3>
                        <div className="pull-right">
                            <button className="btn btn-primary" onClick={this.handleClose}>OK</button>
                        </div>
                    </Modal.Body>
                </Modal>
                {createDomainModal}
                {editDomainModal}
                {deleteDomainModal}
                {warningModal}
                {invalidDomainErrorModal}
            </>
        );
    }
}

Settings.propTypes = {
    userData: PropTypes.object.isRequired,
    settingsData: PropTypes.object.isRequired,
    userSetting: PropTypes.object.isRequired,
    domains: PropTypes.array.isRequired,
    domain: PropTypes.object,
    accounts: PropTypes.object,
    putAccountInfo: PropTypes.func,
    getAccountInfo: PropTypes.func,
    isLoading: PropTypes.bool,
    actions: PropTypes.object.isRequired,
    error: PropTypes.string,
    createDomain: PropTypes.func,
    loadDomains: PropTypes.func,
    editDomain: PropTypes.func,
    deleteDomain: PropTypes.func,
    editDomainStateMessage: PropTypes.bool,
    resetDomainStateMessage: PropTypes.func,
    getDomainStatus: PropTypes.func,
    isCreateDomainError: PropTypes.bool,
    resetCreateDomainErrorMessage: PropTypes.func,
    isLoadingDomain: PropTypes.bool,
    loadCompany: PropTypes.func,
    history: PropTypes.object,
    addAndSetDomainForFunnelApi: PropTypes.func,
};

