import React from "react";
import get from "lodash/get";
import range from "lodash/range";
import { create_UUID } from "../utilities/create-unique-id";
import constants from "../utilities/constants";
import { buildFunnelBaseUrl } from "../../../api/urls";
import {
    pageAndArrowBorderColor,
    thumbnailUrlConstants,
    urlConstants
} from "../../constants";

export const DEBUG = false;
const {
    red,
    green
} = pageAndArrowBorderColor;
const {
    forwardSlash,
    png
} = thumbnailUrlConstants;
const membershipPageTemplateTypeIds = range(7,14,1);
const presetUrl = buildFunnelBaseUrl;
/*
 * Funnel Helpers
 */
export const findCampaignById = (campaigns, id) => campaigns.find((campaign) => campaign.campaignId === id);
export const extractFunnelData = (funnel) => (path) => {
    let pathToExtract = Array.isArray(path) ? path : [path];
    return get(funnel, pathToExtract);
};

/*
 * Domain Helpers
 */
export const getSelectedDomain = (domainList = []) => domainList.filter(list => list.selected);
export const getSelectedDomainId = (domainList = []) => get(getSelectedDomain(domainList), [0, "referenceId"]);
export const generateDomainDropdown = (domainList = []) => {
    let domainOptions = [];
    domainOptions = domainList.map((domain) => {
        const { referenceId } = domain;
        const optionValue = referenceId || "defaultDomain";
        return (
            <option
                key = {optionValue}
                value = {optionValue}
                checked = {domain.selected}
            >
                {
                    domain.name
                }
            </option>
        );
    });
    domainOptions.push(
        <option
            key="addNewDomain"
            value="addNewDomain"
        >
            Add New Domain
        </option>
    );
    return domainOptions;
};

/*
*
* Right Panel Helpers
*/
export const getCurrentPageFromFunnelData = (funnelPages, currentPage) => {
    return funnelPages.filter((page) => {
        return page.cellViewId === get(currentPage, ["id"]);
    })[0];
};

export const getDuplicatePageFromFunnelData = (funnelPages, currentPage) => {
    return funnelPages.filter((page) => {
        return page.referenceId === currentPage.get("splitPageAttributes").pageReferenceId;
    })[0];
};

export const getCurrentPagePageView = (funnelPages, currentPage) => {
    return get(
        getCurrentPageFromFunnelData(funnelPages, currentPage),
        ["pageView", 0]
    );
};

export const getPreviewUrl = (pageViewReferenceId, funnelReferenceId) => {
    const previewUrl = presetUrl + "/" + funnelReferenceId + "/" + pageViewReferenceId + ".html";
    return previewUrl;
};

export const getPageLiveurl = (funnelDataPages, currentPage) => {
    return get(
        getCurrentPageFromFunnelData(funnelDataPages, currentPage),
        ["pageView", 0, "publicUrl"]
    );
};

export const getPageTextName = (currentPage) => {
    return get(
        currentPage,
        [
            "attributes",
            "attrs",
            ".label",
            "text"
        ]
    );
};

export const getPageShareId = (funnelDataPages, currentPage) => {
    return get(
        getCurrentPageFromFunnelData(funnelDataPages, currentPage),
        ["pageView", 0, "shareId"]
    );
};
export const getDuplicatePageShareId = (funnelDataPages, currentPage) => {
    return get(
        getDuplicatePageFromFunnelData(funnelDataPages, currentPage),
        ["pageView", 0, "shareId"]
    );
};
export const getTemplateTypeId = (currentPage) => {
    return currentPage.get("elementMetaData").templateTypeId;
};

export const filterByTemplateTypeId = (templates, filterId) => {
    return templates.filter((template) => (
        template.templateTypeId === 6 ||
        template.templateTypeId === filterId
    ));
};

export const isCurrentPageMembershipPage = (currentPage) => membershipPageTemplateTypeIds
    .includes(
        currentPage
            .get("elementMetaData")
            .templateTypeId
    );

export const getPageType = (templateTypeId) => {
    switch (templateTypeId) {
        // Lead page
        case 1:
            return "Presell Page";
        // Lead page
        case 2:
            return "Lead Page";
        // Checkout Page
        case 4:
            return "Checkout Page";
        // Upsell Page
        case 3:
            return "Upsell Page";
        // Thank You Page
        case 5:
            return "Thank You Page";
        case 6:
            return "Global";
        case 7:
            return "Login Page";
        case 9:
            return "Member Area Page";
        case 14:
            return "Generic";
        case 15:
            return "Opt-in";
        case 16:
            return "Download";
        case 17:
            return "Sales Video";
        case 18:
            return "Calendar";
        case 19:
            return "Survey";
        case 20:
            return "Webinar";
        case 21:
            return "Webinar Replay";
        case 22:
            return "Blog Post";
        case 23:
            return "Pop-up";
        default:
            return "Page";
    }
};

export const setPageThumbnailUrl = (
    funnelReferenceId,
    pageViewReferenceId
) => presetUrl + funnelReferenceId + forwardSlash + pageViewReferenceId + png;

/**
 * Split page helpers
 *
 */

export const getSelectedPageId = (page) => page.get("id");
export const getCurrentCell = (selectedPageId, graph) => graph
    .getCell(selectedPageId);
export const setSplitImageOnPage = (page) => {
    page
        .setImage(
            {
                "xlink:href": "data:image/svg+xml;utf8," + encodeURIComponent(constants.splitPageSvg)
            }
        );
    return page;
};
export const updateCurrentPageThumbnail = (page, winnerAlphabet) => {
    if (winnerAlphabet === "A") {
        page.setImage(
            {
                "xlink:href": page.get("thumbnailUrl"),
            }
        );
    } else {
        page.setImage(
            {
                "xlink:href": page.get("splitPageAttributes").thumbnailUrl,
            }
        );
    }
    return page;
};
export const setSplitEnabledPropertyOnPage = (page) => {
    page.setProperty("isSplitEnabled" ,true);
    return page;
};
export const setSplitDisabledPropertyOnPage = (page) => {
    page.setProperty("isSplitEnabled" ,false);
    return page;
};
export const setSplitReferenceIdOnPage = (page) => {
    const splitReferenceId = create_UUID();
    const initialSplitRatio = 50;
    page.setProperty("splitReferenceId", splitReferenceId);
    page.setProperty("splitRatio", initialSplitRatio);
    return page;
};

const commonSplitPageAttributes = (page) => {
    return {
        pageReferenceId: create_UUID(),
        pageViewReferenceId: create_UUID(),
        isEdited: false,
    };
};
const setPageBorderColor = (page, color = "") => {
    const borderColor = color ? color : page.get("isEdited") ? green : red;
    return page.attr(".borderBox/stroke", borderColor);
};
export const setDuplicatePagePropertyOnCurrentPage = (page) => {
    const splitPageAttributes = {
        ...commonSplitPageAttributes(page),
        thumbnailUrl: page.get("thumbnailUrl"),
        title: page.attr(".label/text"),
        isEdited: page.get("isEdited")
    };
    page.setProperty("splitPageAttributes" ,splitPageAttributes);
    setPageBorderColor(page);
    return page;
};

export const setOutboundArrowColor = (page, graph) => {
    const connectedLinks = graph.getConnectedLinks(page, { outbound: true });
    connectedLinks.map((link) => setLinkArrowColor(link, red));
    return connectedLinks;
};

export const updateCurrentPageAttributes = (page, winnerAlphabet) => {
    if (winnerAlphabet === "A") {
        page.setProperty("splitPageAttributes",{});
        page.setImage(
            {
                "xlink:href": page.get("thumbnailUrl"),
            }
        );
    } else {
        page.setProperty("isEdited", page.get("splitPageAttributes").isEdited);
        page.setProperty("pageViewReferenceId", page.get("splitPageAttributes").pageViewReferenceId);
        page.setProperty("pageReferenceId", page.get("splitPageAttributes").pageReferenceId);
        page.setProperty("thumbnailUrl", page.get("splitPageAttributes").thumbnailUrl);
        page.setImage(
            {
                "xlink:href": page.get("splitPageAttributes").thumbnailUrl,
            }
        );
    }
    setPageBorderColor(page);
    page.setProperty("isSplitEnabled" ,false);
    return page;
};
export const createAndSetTemplateSplitProperty = (page, selectedTemplate) => {
    const splitPageAttributes = {
        ...commonSplitPageAttributes(page),
        isTemplateSelected: true,
        thumbnailUrl: selectedTemplate.thumbnailUrl,
        title: selectedTemplate.templateTypeName
    };
    page.setProperty("splitPageAttributes" ,splitPageAttributes);
    setPageBorderColor(page, red);
    return page;
};
export const setSplitValueOnPage = (page, splitValue) => {
    page.setProperty("splitValue", splitValue);
    return page;
};

/**
 *Arrow Settings buttons and links save helpers
 *
 **/

export const getPageViewReferenceId = (page) => page.get("pageViewReferenceId");
export const getSourcePageCellViewId = (currentPage) => currentPage.get("source").id;
export const getTargetPageCellViewId = (currentPage) => currentPage.get("target").id;
export const setLinkArrowColor = (page, color) => {
    page.attr(".marker-target/fill", color);
    page.attr(".marker-target/stroke", color);
    return page;
};
export const updateArrowBasedOnElementsAssignedStatus = (link, elements) => checkIfElementsAssigned(elements) ?
    setLinkArrowColor(link, green) :
    setLinkArrowColor(link, red);

export const checkIfElementsAssigned = (elements) => elements.filter((element) => element.isAssigned).length;
export const getLinkArrowColor = (page) => page.attr(".marker-target/fill");

export const formatSelectedFonts = (fonts) => fonts && fonts.map(font => font.fontFamily);
export const formatSavedFonts = (fonts) => {
    return fonts.map(font => {
        const fontFamily = font.family.split(" ").join("+");
        const fontUrl = `https://fonts.googleapis.com/css?family=${fontFamily}`;
        return { fontUrl, font };
    });
};
const iconPageTypesArray = [6,15,16,17,18,19,20,21,22,23,24,25];

export const isIcon = (model) => {
    return iconPageTypesArray.includes(model.get("elementMetaData").templateTypeId);
};

const getSelectedDomainName = (domainList) => domainList.find((list) => list.selected).name;

export const getPageLiveUrl = (domainList, pageViewReferenceId) => urlConstants.prependHttps.concat(
    getSelectedDomainName(domainList),
    forwardSlash,
    pageViewReferenceId,
    urlConstants.appendHtml
);

export const getDefaultPage = (funnelData) => {
    const pages = get(funnelData, "page") || [];
    const filteredPage = pages.filter((page) => page.pageView[0].defaultPage);
    if (filteredPage && filteredPage.length) {
        return filteredPage[0].cellViewId;
    }
    return "";
};

export const filteredFonts = (multiFontSelectValue, fontList) => {
    return multiFontSelectValue.map(item => {
        return fontList && fontList.items.find(val => {
            return val.family === item;
        });
    });
};
