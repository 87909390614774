import React, { useContext, useState } from "react";
import PropTypes from "prop-types";
import { Multiselect } from "react-widgets";

const MultiSelectDropdown = ({
    label,
    data,
    groupBy,
    placeHolder,
    valueField,
    textField,
    defaultValue,
    note = "",
    onChange,
}) => {
    const noteComponent = () => {
        return (
            <h6
                className = "share-note">
                <i>
                    {note}
                </i>
            </h6>
        );
    };
    const renderNoteComponent = () => {
        return note ? noteComponent : null;
    };
    return (
        <>
            <h3 className="">{label}</h3>
            <div className="col-xs-12 no-padding buttons-list form-group">
                {
                    renderNoteComponent()
                }
                <Multiselect
                    data={data}
                    textField={textField}
                    groupBy={groupBy}
                    valueField={valueField}
                    placeholder={placeHolder}
                    defaultValue={defaultValue}
                    onChange={(value) => onChange(value)}
                />
                <hr className="m-b-xs" />
                <hr className="m-t-xs" />
            </div>
        </>
    );
};

MultiSelectDropdown.propTypes = {
    label: PropTypes.string,
    data: PropTypes.array,
    groupBy: PropTypes.string,
    placeHolder: PropTypes.string,
    valueField: PropTypes.string,
    textField: PropTypes.string,
    onChange: PropTypes.func,
    defaultValue: PropTypes.array,
    note: PropTypes.string,
};

export default MultiSelectDropdown;