import React from "react";
import PropTypes from "prop-types";

export const SplitViewCard = ({ splitTemplates, page, oldfunnel,showSplitSettings, showSplitSetting, onDeclareWinner, pageIndex }) => (
    <div className= {oldfunnel ? "col-md-3 col-xs-12 col-sm-12" : "col-md-6 col-xs-12" }>
        <h2 class="text-center font-bold">{pageIndex && pageIndex}</h2>
        <div className="ibox">
            <div className="ibox-content template-content template-list">
                <div className="template-img" style={{ backgroundImage: `url(${page.thumbnailUrl ? page.thumbnailUrl : splitTemplates && splitTemplates.thumbnailUrl})` }}>
                    <a className="preview-template" onClick= {() => showSplitSettings(page)}><i className="fa fa-cog"></i></a>
                    <a className="choose" onClick= {() => onDeclareWinner(page)}><div className="choose-hand split-winner"></div><div>Declare Winner</div></a>
                </div>
            </div>
        </div>
    </div>
);
SplitViewCard.propTypes = {
    page: PropTypes.object,
    splitTemplates: PropTypes.object,
    thumbnailUrl: PropTypes.object,
    onDeclareWinner: PropTypes.func,
    showSplitSettings: PropTypes.func,
    showSplitSetting: PropTypes.bool,
    oldfunnel: PropTypes.string,
    pageIndex: PropTypes.string,
};