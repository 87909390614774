import React, { Component } from "react";
import PropTypes from "prop-types";
import isFunction from "lodash/isFunction";
import { Popover, OverlayTrigger } from "react-bootstrap";
import DatePicker from "react-datepicker";
import moment from "moment";
import { getConversionRate } from "./analytics-visualizer-element";

import "react-datepicker/dist/react-datepicker.css";

const formatter = new Intl.NumberFormat("en-US", {
    currency: "USD",
    minimumFractionDigits: 0,
});

const PageHitDateSelectorRow = ({ startDate, endDate, type = "START", handleDateChange }) => {
    const date = (type === "START") ? startDate : endDate;
    const datePopOverProps = {
        selected: moment(date).toDate(),
        onChange: (date) => handleDateChange(date, type),
        dateFormat: "MM/dd/yyyy",
        placeholderText: `Please select the ${type === "END" ? "end" : "start" } date!`
    };
    if (type === "START") {
        datePopOverProps.maxDate = moment(endDate).toDate();
    } else if (type === "END") {
        datePopOverProps.minDate = moment(startDate).toDate();
    }
    const datePopOver = (
        <Popover id="popover-basic" title={`Select ${type === "END" ? "end" : "start" } Date`}>
            <DatePicker
                { ...datePopOverProps }
            />
        </Popover>
    );
    return (
        <tr>
            <td className="table-label">
                {`${type === "START" ? "Start" : "End"} date`}
            </td>
            <td className="p-h-13">:</td>
            <td className="table-value">{moment(date).format("MM/DD/YYYY")}</td>
            <td className="table-value">
                <OverlayTrigger rootClose trigger="click" placement="top" overlay={datePopOver}>
                    <span>
                        <i className="fa fa-calendar calender-icon"></i>
                    </span>
                </OverlayTrigger>
            </td>
        </tr>
    );
};

PageHitDateSelectorRow.propTypes = {
    startDate: PropTypes.instanceOf(Date),
    endDate: PropTypes.instanceOf(Date),
    type: PropTypes.string,
    handleDateChange: PropTypes.func,
};

class PageHits extends Component {

    state={
        pageHitCount: 0,
        pageSubmitCount: 0,
        pageConversions: 0,
        isCountFetched: false,
        funnelId: null,
        pageId: null,
        analyticsData: {},
    }

    analyticsFields = [
        { label: "Page Hits", key: "totalPageViews" },
        { label: "Page Submits", key: "totalConversions" },
        { label: "Conversion %", valueFn: getConversionRate },
    ];

    constructor() {
        super();
        this.fetchAnalytics = this.fetchAnalytics.bind(this);
    }

    componentDidMount() {
        if (this.props.pageId)
            this.fetchAnalytics(this.props.pageId, this.props.startDate, this.props.endDate, this.props.funnel);
    }

    componentWillReceiveProps(nextProps) {
        if (this.props.pageId !== nextProps.pageId) {
            nextProps.resetDate();
        } else if (this.props.startDate !== nextProps.startDate || this.props.endDate !== nextProps.endDate) {
            this.fetchAnalytics(nextProps.pageId, nextProps.startDate, nextProps.endDate, nextProps.funnel);
        }
    }

    fetchAnalytics(pageId, startDate, endDate, funnel) {
        const {
            getAnalyticsData,
            funnel: {
                referenceId: funnelReferenceId,
            }
        } = this.props;

        getAnalyticsData({
            funnelReferenceId,
            pageReferenceId: pageId,
            startDate,
            endDate,
            details: true,
        })
            .then(({ result }) => {
                if (result) {
                    this.setState({
                        analyticsData: result,
                        isCountFetched: true,
                    });
                }
            });
    }

    generateAnalyticInfoDisplayRow = () => {
        const analyticsFields = [
            { label: "Page Hits", key: "totalPageViews" },
            { label: "Page Submits", key: "totalConversions" },
            { label: "Conversion %", valueFn: getConversionRate },
        ];
        const {
            isCountFetched,
            analyticsData,
        } = this.state;

        return analyticsFields.map((field) => {
            const value = isFunction(field.valueFn) ? field.valueFn(analyticsData) : analyticsData[field.key];
            return (
                <tr>
                    <td className="table-label"> { field.label } </td>
                    <td className="p-h-13">:</td>
                    <td className="counts">
                        {
                            isCountFetched ? (
                                <span> { formatter.format(value) } </span>
                            ) : <span>0</span>
                        }
                    </td>
                </tr>
            );
        });
    }

    render() {
        const { index } = this.props;
        return (
            <div className={`value-container ${index > 0 ? "pl-26" : ""}`}>
                <table>
                    {
                        this.generateAnalyticInfoDisplayRow()
                    }
                </table>
            </div>
        );
    }
}

export default PageHits;

PageHits.propTypes = {
    funnel: PropTypes.object,
    pageId: PropTypes.string,
    getAnalyticsData: PropTypes.func,
    startDate: PropTypes.instanceOf(Date),
    endDate: PropTypes.instanceOf(Date),
    resetDate: PropTypes.func,
    index: PropTypes.number
};

PageHits.displayName = "PageHits";

class PageAnalytics extends Component {

    state = {
        startDate: moment().subtract(3, "months"),
        endDate: moment(),
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.pages === this.props.pages) {
            return false;
        }
    }

    resetDate = () => {
        this.setState({
            startDate: moment().subtract(3, "months"),
            endDate: moment(),
        });
    }

    handleDateChange = (date, type) => {
        const { startDate, endDate, pageId } = this.state;
        let prevStartDate = startDate;
        let prevEndDate = endDate;
        let key = (type === "START") ? "startDate" : "endDate";
        if (date) {
            this.setState({
                [key]: date,
            }, () => {
                this.renderPageHits(this.props.pages, this.props.funnel, this.props.getAnalyticsData);
            });
            return;
        }
        this.setState({
            startDate: prevStartDate,
            endDate: prevEndDate
        });
    }

    renderPageHits = (pages, funnel, getAnalyticsData) => {
        const { startDate, endDate } = this.state;
        let pageHitTemplate = [];
        pages.map((page, i) => {
            pageHitTemplate.push(<PageHits index={i} resetDate={this.resetDate} getAnalyticsData={getAnalyticsData} funnel={funnel} pageId={page && page.referenceId} startDate={startDate} endDate={endDate}/>);
        });
        return pageHitTemplate;
    }

    render() {
        const {
            startDate,
            endDate,
        } = this.state;
        const { pages, funnel, getAnalyticsData } = this.props;
        return (
            <div className="page-analytics">
                <div className="font-size-13 font-bold">Page Analytics:</div>
                <div className="details-container">
                    <div className="details">
                        <div className="date-filter-table-container">
                            <table>
                                <PageHitDateSelectorRow
                                    type="START"
                                    startDate={startDate}
                                    endDate={endDate}
                                    handleDateChange={this.handleDateChange}
                                />
                                <PageHitDateSelectorRow
                                    type="END"
                                    startDate={startDate}
                                    endDate={endDate}
                                    handleDateChange={this.handleDateChange}
                                />
                            </table>
                        </div>
                        <div className="value">
                            {this.renderPageHits(pages, funnel, getAnalyticsData)}
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

PageAnalytics.propTypes = {
    pages: PropTypes.arrayOf(),
    funnel: PropTypes.object,
    getAnalyticsData: PropTypes.func,
    pageId: PropTypes.string
};

PageAnalytics.displayName = "CustomDatePicker";
export { PageAnalytics };
