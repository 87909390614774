import React from "react";
import PropTypes from "prop-types";

export const LiveUrlSection = ({ publicUrl }) => (
    <div className="form-group">
        <div className="input-group">
            <label className="input-group-addon">Live URL</label>
            <input className="form-control" name="productName" value={publicUrl} type="text"/>
            <div className = "input-group-addon badge-primary" ><a className="text-white" href={publicUrl} target="_blank"><i className="fa fa-external-link" aria-hidden="true"/></a></div>
        </div>
    </div>
);
LiveUrlSection.propTypes = {
    publicUrl: PropTypes.string,
};

export default LiveUrlSection;