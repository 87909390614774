import React, { Component } from "react";
import PropTypes from "prop-types";
import { NavLink } from "react-router-dom";
import { Dropdown } from "react-bootstrap";
import { crmUrl } from "../api/urls";

class NavigationMenuItem extends Component {
    constructor() {
        super();
        this.state = {
            activeClass: ""
        };
        this.checkActiveMenuItem = this.checkActiveMenuItem.bind(this);
    }

    checkActiveMenuItem(match) {
        const menuItemClassName = match ? "active" : "";
        const {
            activeClass,
        } = this.state;
        if (activeClass !== menuItemClassName) {
            this.setState({
                activeClass: menuItemClassName,
            });
        }
    }

    render() {
        const {
            menu,
        } = this.props;
        const {
            activeClass,
        } = this.state;
        const onClickHandler = menu && menu.onClick;
        return (
            <li className={activeClass}>
                {
                    onClickHandler && (
                        <a href="#" onClick={menu.onClick}>
                            <i className={menu.icon} />
                            <span className="nav-label">
                                {menu.label}
                            </span>
                        </a>
                    )
                }
                {
                    !onClickHandler && (
                        <NavLink
                            to={menu.to}
                            isActive={this.checkActiveMenuItem}
                        >
                            <i className={menu.icon} />
                            <span className="nav-label">
                                {menu.label}
                            </span>
                        </NavLink>
                    )
                }
            </li>
        );
    }
}

NavigationMenuItem.propTypes = {
    menu: PropTypes.object.isRequired,
};

class HamburgerMenu extends Component {
    static propTypes = {
        logout: PropTypes.func,
        items: PropTypes.array,
    };

    render() {
        return (
            <>
                <Dropdown role="menuitem" id="hamburgerMenu">
                    <a bsRole="toggle">
                        <i className="fa fa-bars"></i>
                    </a>
                    <Dropdown.Menu style={{ marginTop: 20 }}>
                        <li>
                            <a className="menu-item" href={crmUrl} target="_blank">
                                <i className="fa fa-desktop" /><span className="nav-label"> CRM</span>
                            </a>
                        </li>
                        {
                            this.props.items.map((menu = {}) => (
                                <li role="presentation" key={menu.label}>
                                    {
                                        menu.onClick ?
                                            (
                                                <NavLink to={menu.to} className="menu-item" onClick={menu.onClick}>
                                                    <i className={menu.icon} /><span className="nav-label"> {menu.label}</span>
                                                </NavLink>
                                            ) :
                                            (
                                                <NavLink to={menu.to} className="menu-item">
                                                    <i className={menu.icon} /><span className="nav-label"> {menu.label}</span>
                                                </NavLink>
                                            )
                                    }

                                </li>
                            ))
                        }
                        <li role="presentation">
                            <NavLink to="/" onClick={this.props.logout} className="menu-item">
                                <i className="fa fa-sign-out" /> Log out
                            </NavLink>
                        </li>
                    </Dropdown.Menu>
                </Dropdown>
            </>
        );
    }
}

export class Navigation extends React.Component {

    static propTypes = {
        location: PropTypes.object.isRequired,
        userData: PropTypes.object,
        settingsData: PropTypes.object,
        logout: PropTypes.func,
        isAdminUser: PropTypes.bool,
        setWelcomeModalOnMenuClick: PropTypes.func,
        isWelcomeVideoUrlFetchSuccess: PropTypes.bool,
        canUserAccessTemplates: PropTypes.bool
    };

    constructor() {
        super();
        this.restrictPageTemplateMenu = this.restrictPageTemplateMenu.bind(this);
        this.generateMenuItems = this.generateMenuItems.bind(this);
        this.openHelpVideo = this.openHelpVideo.bind(this);
    }

    menuConfig = [
        {
            icon: "fa fa-th-large",
            label: "Dashboard",
            to: "/dashboard",
        },
        {
            icon: "fa fa-filter fa-lg",
            label: "Funnels",
            to: "/funnels",
        },
    ];

    hamburgerConfig = [
        {
            icon: "fa fa-file",
            label: "Templates",
            to: "/page-templates",
            displayFn: this.restrictPageTemplateMenu.bind(this),
        },
        {
            icon: "fa fa-users fa-lg",
            label: "Designers",
            to: "/designers",
        },
        {
            icon: "fa fa-cogs fa-lg",
            label: "Settings",
            to: "/settings",
        },
        {
            icon: "fa fa-video-camera fa-lg",
            label: "Welcome",
            to: "#",
            onClick: this.openHelpVideo.bind(this),
            displayFn: this.checkIfWelcomeUrlFetchSuccess.bind(this),
        },
    ];

    restrictPageTemplateMenu() {
        return this.props.isAdminUser || this.props.canUserAccessTemplates;
    }

    checkIfWelcomeUrlFetchSuccess() {
        return this.props.isWelcomeVideoUrlFetchSuccess;
    }

    generateMenuItems(type) {
        const itemConfig = type === "hamburger" ? this.hamburgerConfig : this.menuConfig;
        return itemConfig.filter((menu) => {
            const { displayFn } = menu;
            if (typeof displayFn === "function") {
                return displayFn();
            }
            return true;
        });
    }

    openHelpVideo(e) {
        e.preventDefault();
        const {
            setWelcomeModalOnMenuClick,
        } = this.props;
        setWelcomeModalOnMenuClick(true);
    }

    render() {
        const menuItems = this.generateMenuItems("menu");
        const hamburgerItems = this.generateMenuItems("hamburger");
        return (
            <>
                <nav className="navbar-default" role="navigation">
                    <ul className="nav navbar-static-top" ref="menu">
                        {
                            menuItems.map((menu = {}) => (
                                <NavigationMenuItem
                                    key={menu.label}
                                    menu={menu}
                                />
                            ))
                        }
                        <li>
                            <HamburgerMenu items={hamburgerItems} logout={this.props.logout}/>
                        </li>
                    </ul>
                </nav>
            </>
        );
    }
}