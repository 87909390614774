import React, { Component, PureComponent } from "react";
import InputRange from "react-input-range";
import PropTypes from "prop-types";
import SplitPageSettings from "./SplitPageSettings";
import FontsList from "../components/fontsList";
import constants from "../utilities/constants";
import SharePage from "../components/share-page";
import LiveUrlSection from "../components/live-url-section";

export default class SplitTestSettingsView extends Component {
    static propTypes = {
        splitPageList: PropTypes.array,
        splitPages: PropTypes.array,
        setSplitPercent: PropTypes.func,
        showSplitSetting: PropTypes.bool,
        selectedSplitSettingDetails: PropTypes.object,
        pageTypeLabel: PropTypes.string,
        isFunnelPublished: PropTypes.bool,
        fontList: PropTypes.array,
        selectedFonts: PropTypes.array,
        isFontLoading: PropTypes.bool,
        savedFonts: PropTypes.array,
        saveFontsCall: PropTypes.func,
        isDefaultPage: PropTypes.bool,
        changeDefaultPage: PropTypes.func,
        isSelectedSplitPageDefaultPage: PropTypes.bool,
        modifyLabel: PropTypes.func,
        pageNameChange: PropTypes.func,
        splitPageName: PropTypes.string,
        openShare: PropTypes.func,
        shareClass: PropTypes.string,
        copyMessage: PropTypes.string,
        importSharePage: PropTypes.func,
        isEdited: PropTypes.bool,
        copyShareId: PropTypes.func,
        importShareId: PropTypes.string,
        handleImportShareIdChange: PropTypes.func,
        isShareIdError: PropTypes.bool,
        isShareidSame: PropTypes.bool,
    }
    constructor(props, context) {
        super(props, context);
        this.state = {
            pageValue1: 60,
            pageValue2: 40,
            selectedSplitSettingDetails: this.props.selectedSplitSettingDetails,
            showSplitSetting: this.props.showSplitSetting,
            pageTypeLabel: this.props.pageTypeLabel,
            isFunnelPublished: this.props.isFunnelPublished,
            isSelectedSplitPageDefaultPage: this.props.isSelectedSplitPageDefaultPage,
            splitPageName: this.props.selectedSplitSettingDetails.title,
            isShareIdError: this.props.isShareIdError,
            isShareidSame: this.props.isShareidSame
        };
    }
    componentWillReceiveProps(nextProps) {
        const { splitPageList, splitPages, showSplitSetting, selectedSplitSettingDetails, pageTypeLabel,
            isFunnelPublished, fontList, selectedFonts, isFontLoading, savedFonts, saveFontsCall,
            isSelectedSplitPageDefaultPage, splitPageName, importShareId, isShareIdError, isShareidSame } = this.props;
        this.setState({
            pageValue1: splitPages[1] && splitPages[1].splitRatio ? splitPages[1].splitRatio : 50,
            pageValue2: splitPages[0] && splitPages[0].splitRatio ? splitPages[0].splitRatio : 50
        });
        if (selectedSplitSettingDetails !== nextProps.selectedSplitSettingDetails) {
            this.setState({ selectedSplitSettingDetails: nextProps.selectedSplitSettingDetails });
        }
        if (showSplitSetting !== nextProps.showSplitSetting) {
            this.setState({ showSplitSetting: nextProps.showSplitSetting });
        }
        if (pageTypeLabel !== nextProps.pageTypeLabel) {
            this.setState({ pageTypeLabel: nextProps.pageTypeLabel });
        }
        if (isFunnelPublished !== nextProps.isFunnelPublished) {
            this.setState({ isFunnelPublished: nextProps.isFunnelPublished });
        }
        if (isSelectedSplitPageDefaultPage !== nextProps.isSelectedSplitPageDefaultPage) {
            this.setState({ isSelectedSplitPageDefaultPage: nextProps.isSelectedSplitPageDefaultPage });
        }
        if (splitPageName !== nextProps.splitPageName) {
            this.setState({ splitPageName: nextProps.splitPageName });
        }
        if (importShareId !== nextProps.importShareId) {
            this.setState({ importShareId: nextProps.importShareId });
        }
        if (isShareIdError !== nextProps.isShareIdError) {
            this.setState({ isShareIdError: nextProps.isShareIdError });
        }
        if (isShareidSame !== nextProps.isShareidSame) {
            this.setState({ isShareidSame: nextProps.isShareidSame });
        }

    }

    render() {
        const { splitPageList, splitPages, setSplitPercent, fontList, selectedFonts, isFontLoading, savedFonts,
            saveFontsCall, isDefaultPage, changeDefaultPage, pageNameChange, modifyLabel,
            openShare, shareClass, copyMessage, importSharePage, isEdited, copyShareId,
            importShareId, handleImportShareIdChange } = this.props;
        const { pageValue1, pageValue2, selectedSplitSettingDetails, showSplitSetting, pageTypeLabel,
            isFunnelPublished, isSelectedSplitPageDefaultPage, splitPageName, isShareIdError, isShareidSame } = this.state;
        return (
            <div className="col-xs-12">
                <h4>Traffic Split Percentage</h4>
                <div className="slider-section">
                    <InputRange
                        formatLabel={() => `${pageValue1}%`}
                        minValue={1}
                        maxValue={99}
                        value={this.state.pageValue1 ? this.state.pageValue1 : 1}
                        onChange={value => {this.setState({ value, pageValue1: value, pageValue2: (100 - value), applyValue: true });}}
                        onChangeComplete = {() => setSplitPercent({ pageValue1, pageValue2 })}
                    />
                    <div className="input-range__label pageValue2">{pageValue2}%</div>
                </div>
                <div className="col-xs-12 no-padding">
                    {splitPageList.length !== 0 ? <div className="row"> {splitPageList} </div> : <div className="coming-soon"></div>}
                </div>
                { showSplitSetting &&
                    <SharePage
                        openShare= {openShare}
                        shareClass = {shareClass}
                        copyMessage = {copyMessage}
                        isTemplateId = {true}
                        isEdited = {isEdited}
                        currentPage = {selectedSplitSettingDetails && selectedSplitSettingDetails}
                        copyShareId = {copyShareId}
                        importShareId = {importShareId}
                        handleImportShareIdChange = {handleImportShareIdChange}
                        isShareIdError = {isShareIdError}
                        isShareidSame = {isShareidSame}
                        importSharePage = {importSharePage}
                    />
                }
                { showSplitSetting &&
                    <SplitPageSettings
                        pageTypeLabel={pageTypeLabel}
                        pageName={splitPageName}
                        modifyLabel={modifyLabel}
                        pageNameChange={pageNameChange}
                    />
                }
                { showSplitSetting && isFunnelPublished
                    ? <LiveUrlSection
                        publicUrl={selectedSplitSettingDetails && selectedSplitSettingDetails.pageView[0] && selectedSplitSettingDetails.pageView[0].publicUrl}/>
                    : null

                }
                { showSplitSetting &&
                    <FontsList fontList = {fontList && fontList}
                        selectedFonts = {selectedFonts && selectedFonts}
                        isFontLoading = { isFontLoading && isFontLoading }
                        savedFonts = {savedFonts && savedFonts}
                        saveFontsCall = {saveFontsCall}
                    />
                }
                { showSplitSetting &&
                    <div className="checkbox checkbox-success checkbox-md custom-checkBox">
                        <input type="checkbox"
                            className="styled" id="setDefaultPage"
                            checked = {isDefaultPage}
                            onChange={() => changeDefaultPage(selectedSplitSettingDetails && selectedSplitSettingDetails.splitReferenceId, isSelectedSplitPageDefaultPage, selectedSplitSettingDetails.splitEnabled)}
                        />
                        <label for="setDefaultPage">{constants.setDefault} </label>
                    </div>
                }
            </div>
        );
    }
}