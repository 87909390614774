import React, { useState } from "react";
import PropTypes from "prop-types";
import { Form, FormGroup, ControlLabel, FormControl, Button } from "react-bootstrap";
import isEmpty from "lodash/isEmpty";
import { connect } from "react-redux";
import { toggleCRMEntryModal, setActiveFunnelTitle, openWalkthroughModal } from "../../../core/app-settings/actions";

const BuildFunnel = (props) => {
    const isCreateFromMarketPlace = true;
    const [funnelName, setFunnelName] = useState("");
    const [funnelNameValidationSuccess, setFunnelNameValidationSuccess] = useState(true);
    const {
        goBack,
        goToCreateFunnelPage,
        selectedTile,
        toggleCRMEntryModal,
        setActiveFunnelTitle,
        openWalkthroughModal,
        onCloseModal
    } = props;
    const handleChange = (e) => {
        const text = e.target.value;
        setFunnelName(text);
        if (text && text.length && !funnelNameValidationSuccess) {
            setFunnelNameValidationSuccess(true);
        }
    };
    const buildFunnel = (e) => {
        e.preventDefault();
        if (funnelName) {
            goToCreateFunnelPage(funnelName, isCreateFromMarketPlace);
            // setActiveFunnelTitle(funnelName);
            openWalkthroughModal(false);
            // toggleCRMEntryModal(true);
            onCloseModal();
        } else {
            setFunnelNameValidationSuccess(false);
        }
    };
    const {
        note,
        steps,
    } = selectedTile;
    const hasFunnelSteps = !isEmpty(steps);
    return (
        <div className="walkthrough-body row build-funnel no-gutters">
            <div className="col-sm-7">
                <Form onSubmit={buildFunnel}>
                    <FormGroup
                        controlId="build-funnel-name"
                        className={`build-funnel-input-text ${!funnelNameValidationSuccess ? "has-error" : ""}`}
                    >
                        <ControlLabel> Name </ControlLabel>
                        <FormControl
                            type="text"
                            placeholder="Enter Funnel Name..."
                            onChange={handleChange}
                        />
                        {
                            !funnelNameValidationSuccess && (
                                <span className="error-text">
                                    * This field is required
                                </span>
                            )
                        }
                    </FormGroup>
                    <div className="walkthrough-footer">
                        <button
                            type="button"
                            className="btn btn-default btn-sm"
                            onClick={goBack}
                        >
                            <i className="fa fa-caret-left m-r-sm" />
                            Go back
                        </button>
                        <button
                            type="submit"
                            className="btn btn-primary btn-sm"
                        >
                            <i className="fa fa-edit m-r-sm" />
                            Build Funnel
                        </button>
                    </div>
                </Form>
            </div>
            <div className="col-sm-4 col-sm-offset-1 build-funnel-info-section">
                <div>
                    <h4> Funnel Steps: </h4>
                    {
                        hasFunnelSteps && (
                            <>
                                <ul className="list-unstyled">
                                    {
                                        steps.map((step, index) => (
                                            <li className="secondary-text" key={step.title}>
                                                {
                                                    `${index + 1}. ${step.title}`
                                                }
                                            </li>
                                        ))
                                    }
                                </ul>
                            </>
                        )
                    }
                    {
                        !hasFunnelSteps && (
                            <div className="secondary-text">
                                No funnel steps available.
                            </div>
                        )
                    }
                </div>
                {
                    note && (
                        <span className="secondary-text">
                            { note }
                        </span>
                    )
                }
            </div>
        </div>
    );
};

function mapDispatchToProps(dispatch) {
    return {
        toggleCRMEntryModal: (status) => dispatch(toggleCRMEntryModal(status)),
        setActiveFunnelTitle: (value) => dispatch(setActiveFunnelTitle(value)),
        openWalkthroughModal: (value) => dispatch(openWalkthroughModal(value))
    };
}


BuildFunnel.propTypes = {
    goBack: PropTypes.func,
    goToCreateFunnelPage: PropTypes.func,
    notes: PropTypes.string,
    selectedTile: PropTypes.object,
    toggleCRMEntryModal: PropTypes.func,
    setActiveFunnelTitle: PropTypes.func,
    openWalkthroughModal: PropTypes.func,
    onCloseModal: PropTypes.func
};

export default connect(null, mapDispatchToProps)(BuildFunnel);
