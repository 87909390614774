import * as PageApi from "../api/pageApi";
import isEmpty from "lodash/isEmpty";
import constants from "../funnels/visualizer/utilities/constants";

const membershipPageType = {
    thumbnailSvg: constants.memberFolderSvg,
    label: constants.memberFolderLabel,
    templateTypeId: constants.memberFolderTemplateTypeId,
    isFolder: true,
    childPageTypes: [
        constants.loginTemplateTypeId,
        constants.profileTemplateTypeId,
        constants.membersAreaTemplateTypeId,
        constants.cancelMembershipTemplateTypeid,
        constants.restartMembershipTemplateTypeid,
        constants.updateCardTemplateTypeid,
        constants.changeProductTemplateTypeid,
    ],
};


class PageTypesClass {
    constructor() {
        this.pageTypesMap = null;
    }

    getPageTypesWithMembership() {
        const pages = [];
        const membershipPage = {
            ...membershipPageType,
            children: [],
        };
        this.pageTypesMap.forEach((page) => {
            if (membershipPageType.childPageTypes.indexOf(page.templateTypeId) !== -1) {
                membershipPage.children.push(page);
            } else {
                pages.push(page);
            }
        });
        return [
            ...pages,
            membershipPage,
        ];
    }

    getAllPageTypes(withMembershipPages = false) {
        if (!isEmpty(this.pageTypesMap)) {
            const pageTypesMap = withMembershipPages ? this.getPageTypesWithMembership(this.pageTypesMap) : this.pageTypesMap;
            return Promise.resolve(pageTypesMap);
        } else {
            return PageApi.getAllPageTypes().then((response) => {
                this.pageTypesMap = response.result;
                const pageTypesMap = withMembershipPages ? this.getPageTypesWithMembership(this.pageTypesMap) : this.pageTypesMap;
                return pageTypesMap;
            }).catch(error => {
                throw (error);
            });
        }
    }
}

export const PageTypes = new PageTypesClass([]);
export const getTemplateConfig = (templateTypeId) => {
    return PageTypes.pageTypesMap.find(template => template.templateTypeId === templateTypeId);
};