import React from "react";
import PropTypes from "prop-types";
import { Modal } from "react-bootstrap";
import capitalize from "lodash/capitalize";


const EditDomainModal = ({ showEditDomainPopUp, closeEditDomainPopup, editDomainDetails, cnameRecords,
    editDomainTitle, nameTitle, typeTitle, valueTitle, noteToAddCnameRecords, noteForCnameRecordChange, domainStatusLabel, sslStatusLabel, noteForSSLCertGeneration }) => {
    const formatText = (text) => {
        if (text) {
            return text.split("_").map((t) => capitalize(t)).join(" ");
        }
    };
    return (
        <Modal show={showEditDomainPopUp} backdrop = "static" onHide={closeEditDomainPopup} bsSize="large" aria-labelledby="contained-modal-title-lg">
            <Modal.Header closeButton style={{ backgroundColor: "rgb(47, 64, 77)" }}>
                <Modal.Title><h3 className="text-white">{editDomainTitle}</h3></Modal.Title>
            </Modal.Header>
            <Modal.Body className="edit-modal">
                <form className="form-horizontal">
                    <div className= "form-group">
                        <h4 className="text-header">
                            {
                                editDomainDetails.name
                            }
                        </h4>
                        <div className="table-responsive">
                            <table className="table table-striped table-hover table-bordered">
                                <thead>
                                    <tr>
                                        <th>{nameTitle}</th>
                                        <th>{typeTitle}</th>
                                        <th>{valueTitle}</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {cnameRecords && (cnameRecords).map(cnameRecord =>
                                        <tr className={cnameRecord.type === "In Progress" ? "info" : ""}>
                                            <td>{cnameRecord.name}</td>
                                            <td>{cnameRecord.type}</td>
                                            <td>{cnameRecord.value}</td>
                                        </tr>
                                    )}
                                </tbody>
                            </table>
                        </div>
                        <div className="col-sm-12 small share-note">{noteToAddCnameRecords}</div>
                        <div className="col-sm-12 small share-note">{noteForCnameRecordChange}</div>
                    </div>
                    {!editDomainDetails ? <div className="alert alert-info m-t-md"><i className="fa fa-spinner fa-pulse fa-lg m-r-xs"/>loading...</div> : null}
                    <div className="hr-line-dashed"/>
                    <div className="form-group">
                        <label className="col-sm-2 type-label">{domainStatusLabel}</label>
                        <div className="col-sm-2">{editDomainDetails && editDomainDetails.status === "InProgress" && <i className="fa fa-spinner fa-spin" style={{ fontSize: "16px" }} ></i>}<span>{editDomainDetails && editDomainDetails.status}</span></div>
                    </div>
                    <div className="hr-line-dashed"/>
                    <div className="form-group">
                        <label className="col-sm-2 type-label">{sslStatusLabel}</label>
                        <div className="col-sm-2">{editDomainDetails && formatText(editDomainDetails.certificateStatus)}</div>
                        { editDomainDetails && formatText(editDomainDetails.certificateStatus) && formatText(editDomainDetails.certificateStatus) !== "Issued" ?
                            <div className="col-sm-12 small share-note">{noteForSSLCertGeneration}</div>
                            : null
                        }
                    </div>
                </form>
            </Modal.Body>
        </Modal>
    );
};

EditDomainModal.propTypes = {
    showEditDomainPopUp: PropTypes.bool,
    editDomainDetails: PropTypes.object.isRequired,
    closeEditDomainPopup: PropTypes.func,
    cnameRecords: PropTypes.array.isRequired,
    formatText: PropTypes.func,
    editDomainTitle: PropTypes.string,
    nameTitle: PropTypes.string,
    typeTitle: PropTypes.string,
    valueTitle: PropTypes.string,
    noteToAddCnameRecords: PropTypes.string,
    noteForCnameRecordChange: PropTypes.string,
    noteForSSLCertGeneration: PropTypes.string,
    domainStatusLabel: PropTypes.string,
    sslStatusLabel: PropTypes.string,
};

export default EditDomainModal;