import * as api from "../api/loginApi";
import { LocalStorageHelper } from "../utilities";
import { loadFunnels } from "../funnels/actions";
import { loadCampaigns } from "../campaign/action";
import { loadDesigners } from "../designers/actions";
import { pageViews, salesSummary } from "../dashboard/actions";
import { fetchAppSettings, setWalkthroughModalEnabled } from "../core/app-settings/actions";

const LOGIN = "login/";
export const IS_LOADING = `${LOGIN}IS_LOADING`;
export const LOGIN_SUCCESS = `${LOGIN}LOGIN_SUCCESS`;
export const LOGIN_ERROR = `${LOGIN}LOGIN_ERROR`;
export const LOGOUT = `${LOGIN}LOGOUT`;
export const ACCOUNTLOAD = `${LOGIN}ACCOUNTLOAD`;
export const ACCOUNTERROR = `${LOGIN}ACCOUNTERROR`;
export const SETTINGSLOAD = `${LOGIN}SETTINGSLOAD`;
export const MAINTENANCE = `${LOGIN}MAINTENANCE`;
export const FUNNELPAGECOUNTLOAD = `${LOGIN}FUNNELPAGECOUNTLOAD`;
export const LOAD_DOMAINS_SUCCESS = `${LOGIN}LOAD_DOMAINS_SUCCESS`;
export const UPDATE_DOMAIN_SUCCESS = `${LOGIN}UPDATE_DOMAIN_SUCCESS`;
export const CLOSE_WARNING_MODAL_SUCCESS = `${LOGIN}CLOSE_WARNING_MODAL_SUCCESS`;
export const GET_DOMAIN_SUCCESS = `${LOGIN}GET_DOMAIN_SUCCESS`;
export const CREATE_DOMAIN_ERROR = `${LOGIN}CREATE_DOMAIN_ERROR`;
export const CLOSE_CREATE_DOMAIN_ERROR_MODAL = `${LOGIN}CLOSE_CREATE_DOMAIN_ERROR_MODAL`;
export const IS_LOADING_DOMAIN = `${LOGIN}IS_LOADING_DOMAIN`;
export const LOAD_COMPANY_ERROR = `${LOGIN}LOAD_COMPANY_ERROR`;
export const LOAD_COMPANY_SUCCESS = `${LOGIN}LOAD_COMPANY_SUCCESS`;
export const SET_COMPANY_ERROR = `${LOGIN}SET_COMPANY_ERROR`;
export const SET_COMPANY_SUCCESS = `${LOGIN}SET_COMPANY_SUCCESS`;
export const CHECK_COMPANY_ID = `${LOGIN}CHECK_COMPANY_ID`;
export const GET_LATEST_ADDED_DOMAIN = `${LOGIN}GET_LATEST_ADDED_DOMAIN`;

export function loginSuccess() {
    return { type: LOGIN_SUCCESS, login };
}

export function loginError() {
    return { type: LOGIN_ERROR, login };
}

export function toggleLoading(status = false) {
    return { type: IS_LOADING, status };
}
export function toggleLoadingForDomain(status = false) {
    return { type: IS_LOADING_DOMAIN, status };
}

export function logout() {
    history.pushState(null, null, location.href);
    window.onpopstate = function () {
        history.go(1);
    };
    return { type: LOGOUT, logout };
}

export function loadAccountSuccess(payload) {
    return { type: ACCOUNTLOAD, payload };
}

export function checkMaintenace(payload) {
    return { type: MAINTENANCE, payload };
}

export function loadAccountError(isAccountError = false) {
    return { type: ACCOUNTERROR, isAccountError };
}

export function loadSettingsSuccess(payload) {
    return { type: SETTINGSLOAD, payload };
}
export function loadFunnelPageCountSuccess(payload) {
    return { type: FUNNELPAGECOUNTLOAD, payload };
}

export function loadDomainSuccess(domains) {
    return { type: LOAD_DOMAINS_SUCCESS, domains };
}

export function updateDomainSuccess(response = true) {
    return {
        type: UPDATE_DOMAIN_SUCCESS,
        response
    };
}
export function getDomainSuccess(payload) {
    return {
        type: GET_DOMAIN_SUCCESS,
        payload
    };
}

export function closeWarningModal(response) {
    return {
        type: CLOSE_WARNING_MODAL_SUCCESS,
        response
    };
}

export function showCreateDomainErrorModal(showError) {
    return {
        type: CLOSE_CREATE_DOMAIN_ERROR_MODAL,
        showError
    };
}

export function createDomainError(isError = false) {
    return { type: CREATE_DOMAIN_ERROR,
        isError
    };
}
export function checkCompanyId(companyId, isDesignerUser) {
    return {
        type: CHECK_COMPANY_ID,
        companyId,
        isDesignerUser,
    };
}
export function getLatestAddedDomain(savedDomain) {
    return {
        type: GET_LATEST_ADDED_DOMAIN,
        savedDomain
    };
}

const getUserDataFromApiResponse = (response = {}) => {
    return loadAccountSuccess({
        userCrm: response.userCrm[0],
        active: response.active,
        userSetting: response.userSetting[0],
        isFirstTime: response.isFirstTime,
        affiliateId: response.affiliateId,
        isDesignerUser: response.isDesignerUser,
    });
};

const onUserAccountLoadSuccess = (dispatch, response) => {
    dispatch(fetchAppSettings());
    dispatch(getUserDataFromApiResponse(response));
    dispatch(loadSettingsSuccess(response.userSetting[0]));
    dispatch(checkCompanyId(response.userCrm[0].fTenant.companyId, response.isDesignerUser));
};

export function login(username, password) {
    const localstorageManager = new LocalStorageHelper();
    return function (dispatch) {
        dispatch(toggleLoading(true));
        return api.login(username, password).then(response => {
            localstorageManager.store(LocalStorageHelper.keys.ACCESS_TOKEN,response.access_token);
            localstorageManager.store(LocalStorageHelper.keys.EXPIRE_IN,response.expires_in);
            localstorageManager.store(LocalStorageHelper.keys.TOKEN_TYPE,response.token_type);
            localstorageManager.store(LocalStorageHelper.keys.TENANT_ID,response.tenantId);
            onUserAccountLoadSuccess(dispatch, response);
            dispatch(loginSuccess(response.result));
            dispatch(setWalkthroughModalEnabled(true));
            //dispatch(loadCompanySuccess(response.result));
            dispatch(toggleLoading(false));
        },error => {
            dispatch(toggleLoading(true));
            dispatch(loginError(true));
            dispatch(toggleLoading(false));
            throw (error);
        });
    };
}

export function getAccountInfo() {
    return function (dispatch) {
        dispatch(toggleLoading(true));
        dispatch(getFunnelPageCount());
        return api.loadAccountInfo().then(response => {
            onUserAccountLoadSuccess(dispatch, response);
            dispatch(toggleLoading(false));
            /*
             * This load company is used to check if the user is konnective employee.
             * This API will only be fetched only if the user is konnective employee.
             * [Todo] - Check if this is the correct place.
             */
            dispatch(loadCompany());
        },error => {
            dispatch(toggleLoading(true));
            throw (error);
        });
    };
}

export function getFunnelPageCount() {
    return function (dispatch) {
        dispatch(toggleLoading(true));
        return api.loadFunnelPageCount().then(response => {
            dispatch(loadFunnelPageCountSuccess(response.result));
            dispatch(toggleLoading(false));
        }, error => {
            dispatch(toggleLoading(true));
            throw (error);
        });
    };
}


export function putAccountInfo(accountStore) {
    return function (dispatch) {
        dispatch(toggleLoading(true));
        return api.putAccountInfo(accountStore).then(response => {
            response = response.data;
            dispatch(getUserDataFromApiResponse(response));
            dispatch(toggleLoading(false));
        },error => {
            dispatch(loadAccountError(error.response.data));
            dispatch(toggleLoading(false));
            throw (error);
        });
    };
}

export function isUnderMaintenance() {
    return function (dispatch) {
        dispatch(toggleLoading(true));
        return api.underMaintenance().then(response => {
            dispatch(checkMaintenace({ id: response.result.id, maintenanceMessage: response.result.maintenanceMessage, isMaintenanceScheduled: response.result.isMaintenanceScheduled }));
            dispatch(toggleLoading(false));
            dispatch(loadAccountError(false));
        },error => {
            dispatch(loadAccountError(true));
            dispatch(toggleLoading(true));
            throw (error);
        });
    };
}

export function createDomain(domainName) {
    return function (dispatch) {
        dispatch(toggleLoadingForDomain(true));
        return api.createDomain(domainName).then(savedDomain => {
            dispatch(getLatestAddedDomain(savedDomain));
            dispatch(loadDomains());
            dispatch(toggleLoadingForDomain(false));
        }).catch((error) => {
            dispatch(createDomainError(true));
            dispatch(toggleLoadingForDomain(false));
            throw error;
        });
    };
}

export function loadDomains() {
    return function (dispatch) {
        dispatch(toggleLoadingForDomain(true));
        return api.getAllDomains().then((response) => {
            dispatch(loadDomainSuccess(response.result));
            dispatch(toggleLoadingForDomain(false));
        }).catch(error => {
            dispatch(toggleLoadingForDomain(false));
            throw (error);
        });
    };
}

export function editDomain(editedDomain) {
    return function (dispatch) {
        dispatch(toggleLoadingForDomain(true));
        return api.updateDomain(editedDomain).then((response) => {
            dispatch(updateDomainSuccess(response.message));
            //if (response.message !== "Success") dispatch(closeWarningModal);
            dispatch(loadDomains());
            dispatch(toggleLoadingForDomain(false));
        }).catch(error => {
            dispatch(toggleLoadingForDomain(false));
            throw error;
        });
    };
}
export function getDomainStatus(domain) {
    return function (dispatch) {
        dispatch(toggleLoadingForDomain(true));
        return api.getDomain(domain).then((response) => {
            dispatch(getDomainSuccess(response.result));
            dispatch(toggleLoadingForDomain(false));
        }).catch(error => {
            dispatch(toggleLoadingForDomain(false));
            throw error;
        });
    };
}

export function deleteDomain(domainId) {
    return function (dispatch) {
        dispatch(toggleLoadingForDomain(true));
        return api.deleteDomain(domainId).then(() => {
            dispatch(loadDomains());
            dispatch(toggleLoadingForDomain(false));
        }).catch((error) => {
            dispatch(toggleLoadingForDomain(false));
            throw error;
        });
    };
}

export function resetDomainStateMessage() {
    return function (dispatch) {
        dispatch(closeWarningModal(true));
    };
}

export function resetCreateDomainErrorMessage() {
    return function (dispatch) {
        dispatch(showCreateDomainErrorModal(false));
    };
}

/*****************************begin actions for loading company***************/

export function loadCompany() {
    return function (dispatch) {
        dispatch(toggleLoading(true));
        const localstorageManager = new LocalStorageHelper();
        const accessToken = localstorageManager.load(LocalStorageHelper.keys.ACCESS_TOKEN);
        if (accessToken) {
            return api.getAllCompany().then((response) => {
                dispatch(loadCompanySuccess(response.result));
                dispatch(toggleLoading(false));
            }).catch(error => {
                dispatch(loadCompanyError(error));
                dispatch(toggleLoading(false));
                // throw (error);
            });
        }
    };
}

export function loadCompanySuccess(payload) {
    return {
        type: LOAD_COMPANY_SUCCESS,
        payload
    };
}

export function loadCompanyError(loadCompanyError = false) {
    return { type: LOAD_COMPANY_ERROR,
        loadCompanyError
    };
}

export function setCompany(company, location) {
    return function (dispatch) {
        dispatch(toggleLoading(true));
        return api.setCompany(company).then((response) => {
            dispatch(setCompanySuccess(response.result));
            dispatch(loadCompany());
            if (location === "/funnels") {
                dispatch(loadFunnels());
                dispatch(loadCampaigns());
            } else if (location === "/designers") {
                dispatch(loadDesigners());
            } else if (location === "/dashboard") {
                dispatch(pageViews());
                dispatch(salesSummary());
                dispatch(loadFunnels());
                dispatch(loadCampaigns());
                dispatch(getAccountInfo());
                dispatch(getFunnelPageCount());
            } else if (location === "/settings") {
                dispatch(getAccountInfo());
                dispatch(loadDomains());
            }
            dispatch(toggleLoading(false));
        }).catch(error => {
            dispatch(toggleLoading(false));
            throw error;
        });
    };
}

export function setCompanySuccess(payload) {
    return {
        type: SET_COMPANY_SUCCESS,
        payload
    };
}

export function setCompanyError(loadCompanyError = false) {
    return { type: SET_COMPANY_ERROR,
        setCompanyError
    };
}

/***************************************end actions***************************** */


