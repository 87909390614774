import ChooseGoal from "./choose-goal";
import ChooseFunnelType from "./choose-funnel-type";
import BuildFunnel from "./build-funnel";

export default [
    {
        type: "goal",
        label: "Choose Goal",
        component: ChooseGoal,
        hasSubItem: true,
    },
    {
        type: "funnelType",
        label: "Choose Type",
        component: ChooseFunnelType,
        hasSubItem: true,
    },
    {
        type: "buildFunnel",
        label: "Build Funnel",
        component: BuildFunnel,
    }
];