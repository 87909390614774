import React from "react";
import PropTypes from "prop-types";
import { SelectInput, funnelUpsaleError } from "../commons";

const UpSellField = ({
    funnel,
    isEdit,
    allUpsells, errors, isDefaultOptionEnabled,
    addUpsellField, onUpsellChange, removeUpsellField }) => {
    const { upsale } = funnel;
    let myupsells = upsale;
    if (upsale && !Array.isArray(upsale)) {
        myupsells = JSON.parse(upsale);
    }
    const all = { value: "all", text: "ALL UPSELLS", type: "UPSALES" };

    const valueFound = allUpsells.find(element => {
        if (element.value === "all") {
            return element;
        }
    });
    if (!valueFound) {
        allUpsells.push(all);
    }

    const upsellFields = myupsells && myupsells.map((upsell, index) =>
        <div className="offerBox" key={funnel.upsaleId}>
            {!isEdit && ((index === 0) ?
                <div className="add_btn"><a onClick={() => addUpsellField()} className="btn btn-primary"><i className="fa fa-plus" /></a></div> :
                <div className="add_btn"><a onClick={() => removeUpsellField(index)} className="btn btn-danger"><i className="fa fa-close" /></a></div>)}
            <SelectInput
                isDefaultOptionEnabled={isDefaultOptionEnabled}
                name="upsale"
                disabled={isEdit}
                value={myupsells[0] ? upsell.value : ""}
                defaultOption="Choose Upsells"
                options={allUpsells}
                onChange={(e) => onUpsellChange(e, index)}
                errorMessage={funnelUpsaleError}
                error={errors.upsaleId} />
        </div>
    );
    return (
        <div>
            <label>Upsells</label>
            {upsellFields}
        </div>

    );
};

UpSellField.propTypes = {
    funnel: PropTypes.object.isRequired,
    allUpsells: PropTypes.array,
    errors: PropTypes.object,
    addUpsellField: PropTypes.func,
    onUpsellChange: PropTypes.func,
    removeUpsellField: PropTypes.func,
    isEdit: PropTypes.bool,
    isDefaultOptionEnabled: PropTypes.bool
};

export default UpSellField;