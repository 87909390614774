import UUID from "uuid-js";
import { fontProperties, blockRestriction } from "../consts";

export const linkBlocks = (editor, { linkTraits = [] }) => {
    const c = {};
    const domc = editor.DomComponents;
    const container = editor.container;
    let links = domc.getType("link");
    const linkType = domc.getType("link");
    const linkModel = linkType.model;
    domc.addType("link-block", {
        model: linkModel.extend({
            init: function () {
                const uid = UUID.create(4);
                const link = this.attributes.attributes["data-id"] ? this.attributes.attributes["data-id"] : `fkt-link-${uid.toString()}`;
                const title = this.attributes.attributes["title"] && this.attributes.attributes["title"];
                let href = this.attributes.attributes["href"] ? this.attributes.attributes["href"] : null;
                let target = this.attributes.attributes["target"] ? this.attributes.attributes["target"] : "";
                this.set("attributes", {
                    "data-id": link,
                    "title": title,
                    onclick: "linkMethod(event)",
                    "href": href,
                    "target": target
                });
                this.attributes.href = href;
                this.attributes.target = target;
                this.listenTo(this, "change:title", this.updateTraitVlaues);
                this.listenTo(this, "change:href", this.updateTraitVlaues);
                this.listenTo(this, "change:target", this.updateTraitVlaues);
            },
            updateTraitVlaues() {
                var categsTraitTitle = this.get("traits").where({ name: "title" })[0];
                var categsTraitHref = this.get("traits").where({ name: "href" })[0];
                var categsTraitTarget = this.get("traits").where({ name: "target" })[0];
                const element = this.attributes.attributes;
                let customlinkData;
                element["title"] = categsTraitTitle.attributes.value;
                element["href"] = categsTraitHref.attributes.value;
                element["target"] = categsTraitTarget.attributes.value;
                const isLinkIdx = container.buttonMetaData && container.buttonMetaData.links.findIndex((bm) => {
                    return bm["data-id"] === this.attributes.attributes["data-id"];
                });
                if (!customlinkData) {
                    customlinkData = container.buttonMetaData.links[isLinkIdx];
                }
                if (isLinkIdx > -1) {
                    container.buttonMetaData.links.splice(isLinkIdx, 1, {
                        ...container.buttonMetaData.links[isLinkIdx],
                        "data-id": element["data-id"],
                        "title": element["title"] && element["title"] !== "" ? element["title"] : "Link-block",
                        "href": element["href"],
                        "type": "link",
                        "target": element["target"]
                    });
                    container.buttonMetaData = {
                        ...container.buttonMetaData,
                        jsPageData: {
                            [customlinkData["data-id"]]: {
                                ...container.buttonMetaData.jsPageData[customlinkData["data-id"]],
                                href: element["href"],
                                target: element["target"]
                            }
                        }
                    };
                } else if (this.attributes.attributes["data-id"]) {
                    if (element["title"] && element["title"] !== "") {
                        container.buttonMetaData.links.push({
                            "data-id": element["data-id"],
                            "title": element["title"],
                            "href": element["href"],
                            "type": "link",
                            "target": element["target"]
                        });
                    }
                }
                editor.TraitManager.getTraitsViewer().render();
            },
            defaults: {
                ...linkModel.prototype.defaults,
                "custom-name": c.labelButtonName,
                tagName: "a",
                draggable: blockRestriction,
                unstylable: ["width", "height", ...fontProperties],
                traits: linkTraits
            },
        }),
        view: links.view
    });
};