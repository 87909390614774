import { FunnelTemplates as FunnelTemplatesView } from "./funnel-templates";
import { connect } from "react-redux";
import * as funnelTemplateActions from "./actions";
import { getFunnelTemplates, getLoader } from "./selectors";
import { getUserData } from "../login/selectors";

function mapStateToProps(state) {
    return {
        funnels: getFunnelTemplates(state),
        isLoading: getLoader(state),
        userData: getUserData(state)
    };
}
function mapDispatchToProps(dispatch) {
    return {
        loadFunnelTemplates: () => dispatch(funnelTemplateActions.loadFunnelTemplates()),
        deleteFunnelTemplate: (funnelTemplateId) => dispatch(funnelTemplateActions.deleteAction(funnelTemplateId)),
        updateFunnelTemplate: (params) => dispatch(funnelTemplateActions.updateFunnelTemplate(params)),
        updateFunnelError: (params) => dispatch(funnelTemplateActions.updateFunnelError(params)),
        toggleLoading: (params) => dispatch(funnelTemplateActions.toggleLoading(params)),
    };
}
export const FunnelTemplates = connect(mapStateToProps, mapDispatchToProps)(FunnelTemplatesView);