import React, { Component } from "react";
import PropTypes from "prop-types";
import { Form as FunnelForm } from "../../funnel-form";
import { funnelTemplateConstants } from "../../funnel-templates/constants";
// import "../style.css";
import "../../../node_modules/react-widgets/dist/css/react-widgets.css";
import VisualizerFrame from "../components/visualizer-frame";
import { VisualizerHeader, VisualizerZoom } from "../components/visualizer-header";

export class FunnelTemplateVisualizer extends Component {
    static propTypes = {
        actions: PropTypes.object,
        funnel: PropTypes.object,
        clearJsonData: PropTypes.func,
        clearFunnelData: PropTypes.func,
        editFunnel: PropTypes.func,
        deletePage: PropTypes.func,
        errorClose: PropTypes.func,
        resetData: PropTypes.func,
        getFunnelData: PropTypes.func,
        funnelType: PropTypes.object,
        importedPage: PropTypes.object,
        importedFunnel: PropTypes.object,
        jsonData: PropTypes.string,
        funnelReferenceId: PropTypes.string,
        salt: PropTypes.string,
        isLoading: PropTypes.bool,
        isError: PropTypes.bool,
        initializeVisualizerData: PropTypes.func,
        history: PropTypes.object,
        getThumbnailForFunnel: PropTypes.func,
    };
    static contextTypes = {
        router: PropTypes.object,
    };
    constructor(props, context) {
        super(props, context);
        this.state = {
            funnel: Object.assign({
                title: "",
                author: "",
                fListFunnelTypeId: "",
                offer: [""],
                upsale: [""],
                status: "TestStatus",
            }, props.funnel),
            showSettings: false,
            errors: {},
        };
        this.validate = this.validate.bind(this);
        this.props.clearJsonData();
    }
    componentWillMount() {
        this.props.initializeVisualizerData();
    }
    componentDidMount() {
        this.props.getFunnelData(this.props.funnelReferenceId).catch(() => {});
        this.props.getThumbnailForFunnel(this.props.funnelReferenceId).catch(() => {});
    }
    componentWillReceiveProps(nextProps) {
        if (nextProps.funnel.referenceId !== this.props.funnel.referenceId) {
            this.setState({
                funnel: nextProps.funnel,
            });
        }

        if (nextProps.isError !== this.state.isError) {
            this.setState({
                isError: nextProps.isError,
            });
        }
        {
            this.state.showSettings &&
                !nextProps.isLoading &&
                this.setState({ showSettings: false });
        }
    }
    componentWillUnmount() {
        this.props.getThumbnailForFunnel(this.props.funnelReferenceId).catch(() => {});
        this.props.clearFunnelData();
    }
    navigateToError() {
        this.props.history.push("/404");
    }
    updateFunnelState = (event) => {
        const field = event.target.name;
        let funnel = Object.assign({}, this.state.funnel);
        funnel[field] = event.target.value;
        return this.setState({ funnel: funnel });
    }

    validate(event) {
        const funnel = this.state.funnel;
        (!funnel.title) ? this.setState({ errors: { title: true, isSuccess: false } }) :
            this.setState({ isSuccess: true }, this.saveEditedFunnel(event));
    }

    saveEditedFunnel() {
        const { funnel } = this.state;
        const filteredPageList = this.state.funnel.page.filter((data) => {
            return data.title === "Checkout Page" || data.title === "Upsell Page";
        });
        this.props.resetData({ filteredPageList, funnelReferenceId: funnel.referenceId, salt: this.props.salt }, () => {
            this.props.editFunnel({ funnel });
        });
    }
    cancel = (event) => {
        event.preventDefault();
        this.setState({ showSettings: false });
    }

    toggleSettingsView = () => {
        this.setState({
            showSettings: !this.state.showSettings
        });
    }

    closeMessage = () => {
        this.props.errorClose();
    }

    render() {
        const { isLoading, isError, funnel } = this.props;
        const { funnelTemplateTypeLabel, funnelTemplateTypeDefaultOption } = funnelTemplateConstants;
        const settingsToggleClass = this.state.showSettings ? "funnelSettings active" : "funnelSettings";


        return <>

            {isLoading &&
                <div className="loader">
                </div>
            }

            <div className="row dashboard-wrapper relative">
                <div className={settingsToggleClass}>
                    <FunnelForm isDefaultOptionEnabled={true}
                        isEdit={false}
                        funnel={this.state.funnel}
                        onChange={this.updateFunnelState}
                        onSave={this.validate}
                        onCancel={this.cancel}
                        errors={this.state.errors}
                        isLoading={isLoading}
                        isError={isError} >
                        {/* <FunnelTemplateTypeField
                            onChange={this.updateFunnelState}
                            errors={this.state.errors}
                            funnelType={funnelTemplateDropDownOptions}
                            funnelTypeError={funnelTypeError}
                            label={funnelTemplateTypeLabel}
                            defaultOption={funnelTemplateTypeDefaultOption}
                            funnel={funnel}
                        /> */}
                    </FunnelForm>
                </div>
                <VisualizerHeader parent="funnelTemplate"
                    editFunnel={this.toggleSettingsView}
                >
                    <VisualizerZoom parent="funnelTemplate" />
                </VisualizerHeader>
                <VisualizerFrame parent="funnelTemplate"
                />
            </div>
        </>;
    }
}
