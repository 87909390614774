import * as actions from "./actions";
export const ASSET_REDUCER_KEY = "ASSETS";

export function reducer(state = [], action) {
    switch (action.type) {
        case actions.ADD_SUCCESS: {
            return {
                ...state,
            };
        }
        case actions.LOAD_SUCCESS: {
            return {
                ...state,
                assetData: action.assetData
            };
        }
        case actions.DELETE_SUCCESS: {
            return {
                ...state,
            };
        }
        case actions.IS_LOADING: {
            return {
                ...state,
                isLoading: action.status
            };
        }
        default:
            return state;
    }
}
