import * as ACTION_TYPES from "../actions/visualizerActionsTypes";
import * as actions from "../actions/visualizerActions";
import { FunnelReducer } from "../../models";

export const FUNNEL_REDUCER_KEY = "FUNNEL";

export const initialState = new FunnelReducer();


export const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actions.GET_FUNNEL_SUCCESS: {
            return {
                ...state,
                funnelData: action.payload,
                changeSetId: action.payload.changesetId
            };
        }
        case ACTION_TYPES.TOGGLE_LOADING: {
            return {
                ...state,
                isLoading: action.status,
            };
        }
        case ACTION_TYPES.LOAD_DOMAIN_LIST_SUCCESS: {
            return {
                ...state,
                domainList: action.payload,
            };
        }
        case ACTION_TYPES.SET_DOMAIN_FOR_FUNNEL_SUCCESS: {
            return {
                ...state,
                selectedDomainReferenceId: action.selectedDomainReferenceId
            };
        }
        case ACTION_TYPES.UPDATE_CHANGE_SET_ID: {
            return {
                ...state,
                changeSetId: action.changeSetId
            };
        }
        case ACTION_TYPES.DEFAULT_FUNNELS_SUCCESS_MESSAGE: {
            return {
                ...state,
                defaultFunnelTemplates: action.payload.defaultFunnelTemplates
            };
        }
        default: return state;
    }
};
export default reducer;