import { FunnelTemplateVisualizer as Visualizer } from "./funnel-template-visualizer";
import { connect } from "react-redux";
import { commonActions, visualizerActions, funnelTemplateActions } from "../actions";
import * as selectors from "../selectors/funnel-template-selectors";
import { LocalStorageHelper } from "../../utilities";
import * as saltSelectors from "../../login/selectors";

function mapStateToProps(state, ownProps) {
    const importedFunnel = selectors.getImportedFunnel(state);
    const funnel = importedFunnel ? importedFunnel : selectors.getFunnelData(state);
    const localStorage = new LocalStorageHelper();
    localStorage.store("funnelReferenceId", ownProps.match.params.referenceId);
    const isError = selectors.getError(state);
    const settingsData = saltSelectors.getSettingsData(state);
    const isLoading = selectors.getLoader(state);

    return {
        funnelReferenceId: ownProps.match.params.referenceId,
        funnel,
        isLoading,
        importedFunnel,
        isError,
        salt: settingsData ? settingsData.salt : "",
    };
}

function mapDispatchToProps(dispatch) {

    return {
        getFunnelData: (funnelReferenceId) => dispatch(funnelTemplateActions.getFunnel(funnelReferenceId)),
        clearJsonData: () => dispatch(commonActions.clearJsonData()),
        clearFunnelData: () => dispatch(funnelTemplateActions.clearFunnelData()),
        loadFunnel: (pageId) => dispatch(funnelTemplateActions.loadFunnel(pageId)),
        editFunnel: (params) => dispatch(funnelTemplateActions.editFunnel(params)),
        resetData: (params, cb) => dispatch(funnelTemplateActions.visualizerFunnelEdit(params, cb)),
        errorClose: () => dispatch(funnelTemplateActions.errorClose()),
        initializeVisualizerData: () => dispatch(visualizerActions.initializeVisualizerData()),
        getThumbnailForFunnel: (funnelReferenceId) => dispatch(funnelTemplateActions.getThumbnailForFunnel(funnelReferenceId)),
    };
}

export const FunnelTemplateVisualizer = connect(mapStateToProps, mapDispatchToProps)(Visualizer);
