import UUID from "uuid-js";
import get from "lodash/get";
import isArray from "lodash/isArray";
import {
    buttonChange,
} from "../utils/component";

const getProductsFromWebBuilderElements = (container, linkId) => {
    const { webBuilderElements } = container.props;
    let products = [];
    if (webBuilderElements && webBuilderElements.length) {
        const matchedLinkInWebBuilderElements = webBuilderElements.find((element) => {
            return (element.elementId === linkId);
        });
        if (matchedLinkInWebBuilderElements && isArray(matchedLinkInWebBuilderElements.products)) {
            products = matchedLinkInWebBuilderElements.products;
        }
    }
    return products;
};

export const link = (editor, { linkTraits = [] }, { offers = [], upsell = [], pages = [] }) => {
    const c = {};
    const domc = editor.DomComponents;
    let links = domc.getType("link");
    const linkType = domc.getType("link");
    const linkModel = linkType.model;
    const container = editor.container;
    domc.addType("link", {
        model: linkModel.extend({
            init: function () {
                const content = this.get("content");
                const uid = UUID.create(4);
                const link = this.attributes.attributes["data-id"] ? this.attributes.attributes["data-id"] : `fkt-link-${uid.toString()}`;
                const title = this.attributes.attributes["title"] ? this.attributes.attributes["title"] : content && content;
                let href = this.attributes.attributes["href"] ? this.attributes.attributes["href"] : null;
                let target = this.attributes.attributes["target"] ? this.attributes.attributes["target"] : "";

                this.set("attributes", {
                    "data-id": link,
                    "title": title ? title : content,
                    onclick: "",
                    "href": href,
                    "target": target
                });
                // if(this.attributes.attributes.hasOwnProperty("onclick")) {
                //     this.attributes.attributes.onclick = "linkMethod(event)"
                // }
                this.attributes.href = href;
                this.attributes.target = target;
                this.updateTraitValues();
                this.listenTo(this, "change:title", this.updateTraitValues);
                this.listenTo(this, "change:href", this.updateTraitValues);
                this.listenTo(this, "change:target", this.updateTraitValues);
                this.doStuff();
            },
            doStuff() {
                var categsTrait = this.get("traits").where({ name: "redirectUrl" })[0];
                let pageUrls = [];
                pageUrls.push({ value: "", name: "Choose A Value" });
                pageUrls = pages.map(page => ({ value: JSON.stringify(page), name: page.title }));
                pageUrls = [
                    { value: "", name: "Choose A Value" },
                    ...pageUrls,
                ];
                categsTrait && categsTrait.set("options", pageUrls);

                var offerTrait = this.get("traits").where({ name: "OFFER" })[0];
                let offerProduct = [];
                if (offers && offers[0] !== "") {
                    offerProduct = [
                        { value: "", name: "Choose Value" },
                        ...offers.map(offer => ({ value: offer.value.toString(), name: offer.text })),
                    ];
                    if (offerTrait) {
                        offerTrait.set("options", offerProduct);
                        offerTrait.attributes.value = container.buttonMetaData.buttons.length > 0 && !offerTrait.attributes.value ? container.buttonMetaData.buttons[0].OFFER : null;
                    }
                } else
                    offerTrait && offerTrait.set("options", []);

                var upsellTraits = this.get("traits").where({ name: "UPSELL" })[0];
                let productUpsale = [];
                if (upsell && upsell[0] !== "") {
                    productUpsale = upsell.map(upsellItem => ({
                        value: upsellItem.value ? upsellItem.value.toString() : "",
                        name: upsellItem.text
                    }));
                    productUpsale = [
                        { value: "", name: "Choose Value" },
                        ...productUpsale,
                    ];
                    upsellTraits && upsellTraits.set("options", productUpsale);
                } else
                    upsellTraits && upsellTraits.set("options", []);

                editor.trigger("component:toggled");
                editor.on("component:update", function (component) {
                    if (component.changed.redirectUrl) {
                        buttonChange(container, component.changed.redirectUrl, component.attributes.attributes["data-id"], "NEXT_PAGE");
                    } else if (component.changed.OFFER) {
                        buttonChange(container, component.changed.OFFER, component.attributes.attributes["data-id"], "OFFER");
                    } else if (component.changed.UPSELL) {
                        buttonChange(container, component.changed.UPSELL, component.attributes.attributes["data-id"], "UPSELL");
                    } else if (component.changed.animate) {
                        const compCls = component.get("classes");
                        const animatedInx = component._previousAttributes.classes.models.findIndex(model => model.id === "animated");
                        if (animatedInx >= 0) {
                            if (component.changed.animate === "none") {
                                compCls.remove("animated");
                                compCls.remove(component._previousAttributes.classes.models[animatedInx]);
                            } else {
                                compCls.forEach(element => {

                                    const options = component.get("traits").where({ name: "animate" })[0].attributes.options;
                                    options.forEach(option => {
                                        // eslint-disable-next-line
                                        if (element && option.name == element.id) {
                                            compCls.remove(element);
                                        }
                                    });
                                });
                            }
                        }
                        if (animatedInx >= 0 && component.changed.animate !== "none")
                            editor.getSelected().addClass(component.changed.animate);
                        else if (animatedInx < 0 && component.changed.animate !== "none")
                            editor.getSelected().addClass(["animated", component.changed.animate]);

                    } else if (component.changed.delay) {
                        const compCls = component.get("classes");

                        compCls.forEach(element => {
                            if (element && element.id.substring(0, 5) === "delay") {
                                compCls.remove(element);
                            }
                        });
                        if (component.changed.delay !== "none")
                            component.addClass(component.changed.delay);
                    } else if (component.changed.speed) {

                        const compCls = component.get("classes");

                        compCls.forEach(element => {
                            if ((element && element.id.substring(0, 4) === "slow") || (element && element.id.substring(0, 4) === "fast")) {
                                compCls.remove(element);
                            }
                        });
                        if (component.changed.speed !== "none")
                            component.addClass(component.changed.speed);
                    } else if (component.changed.loop) {
                        const compCls = component.get("classes");
                        if (component.changed.loop === "none") {

                            compCls.forEach(element => {
                                if ((element && element.id.substring(0, 8) === "infinite")) {
                                    compCls.remove(element);
                                }
                            });
                        }
                        compCls.forEach(element => {
                            if (element.id === "loop") {
                                compCls.remove(element);
                            }
                        });
                        if (component.changed.loop !== "none")
                            component.addClass(component.changed.loop);
                    }
                });

            },
            updateTraitValues() {
                var categsTraitTitle = this.get("traits").where({ name: "title" })[0];
                var categsTraitHref = this.get("traits").where({ name: "href" })[0];
                var categsTraitTarget = this.get("traits").where({ name: "target" })[0];
                var categsTraitAction = this.get("traits").where({ name: "action" })[0];
                const element = this.attributes.attributes;
                let customlinkData;
                const linkId = get(element, ["data-id"]);
                element["title"] = categsTraitTitle.attributes.value;
                element["href"] = categsTraitHref.attributes.value;
                element["target"] = categsTraitTarget.attributes.value;

                if (categsTraitAction && categsTraitAction.attributes.value === "submit") {
                    this.attributes.attributes.onclick = "linkMethod(event, submit)";
                } else if (categsTraitAction && categsTraitAction.attributes.value === "logout") {
                    this.attributes.attributes.onclick = "clubLogout()";
                } else if (categsTraitAction && categsTraitAction.attributes.value === "none") {
                    this.attributes.attributes.onclick = "";
                } else {
                    this.attributes.attributes.onclick = "linkMethod(event)";
                }
                const isLinkIdx = container.buttonMetaData && container.buttonMetaData.links.findIndex((bm) => {
                    return bm["data-id"] === this.attributes.attributes["data-id"];
                });
                if (!customlinkData) {
                    customlinkData = container.buttonMetaData.links[isLinkIdx];
                }
                if (isLinkIdx > -1) {
                    container.buttonMetaData.links.splice(isLinkIdx, 1, {
                        ...container.buttonMetaData.links[isLinkIdx],
                        "data-id": element["data-id"],
                        "title": element["title"],
                        "href": element["href"],
                        "type": "link",
                        "target": element["target"]
                    });
                    container.buttonMetaData = {
                        ...container.buttonMetaData,
                        jsPageData: {
                            [customlinkData["data-id"]]: {
                                ... container.buttonMetaData.jsPageData[customlinkData["data-id"]],
                                href: element["href"],
                                target: element["target"]
                            }
                        }
                    };
                } else if (this.attributes.attributes["data-id"]) {
                    const products = getProductsFromWebBuilderElements(container, linkId);
                    container.buttonMetaData.links.push({
                        "data-id": element["data-id"],
                        "title": element["title"],
                        "href": element["href"],
                        "type": "link",
                        "target": element["target"],
                        products,
                    });
                }
                editor.TraitManager.getTraitsViewer().render();
            },
            defaults: {
                ...linkModel.prototype.defaults,
                "custom-name": c.labelButtonName,
                tagName: "a",
                draggable: "div:not([data-gjs-type=wrapper]):not([data-gjs-type=form]):not(.form-group)",
                unstylable: ["width", "height"],
                traits: linkTraits,
            },
        }),
        view: links.view.extend({
            init() {
                this.listenTo(this.model, "change:content", this.updateContent);
            },
            updateContent() {
                this.el.innerHTML = this.model.get("content");
                const element = this.model.attributes.attributes;
                const linkId = get(this, ["model", "attributes", "attributes", "data-id"]);
                const isLinkIdx = container.buttonMetaData && container.buttonMetaData.links.findIndex((bm) => {
                    return bm["data-id"] === linkId;
                });
                if (isLinkIdx > -1) {
                    container.buttonMetaData.links.splice(isLinkIdx, 1, {
                        ...container.buttonMetaData.links[isLinkIdx],
                        "data-id": element["data-id"],
                        "title": element["title"] ? element["title"] : this.el.innerHTML,
                        "href": element["href"],
                        "type": "link",
                        "target": element["target"]
                    });
                } else if (linkId) {
                    /*
                     * This block will populate the buttons Initially.
                     */
                    const products = getProductsFromWebBuilderElements(container, linkId);
                    container.buttonMetaData.links.push({
                        "data-id": element["data-id"],
                        "title": element["title"] ? element["title"] : this.el.innerHTML,
                        "href": element["href"],
                        "type": "link",
                        "target": element["target"],
                        products,
                    });
                }
            },
            handleClick(e) {
                e.preventDefault();
            },
        }),
    });
};
