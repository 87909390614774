export const manageCampaignState = (state) => {
    return state.CAMPAIGNS;
};

export const manageProductsState = (state) => {
    return state.CAMPAIGNS.length > 0 ? state.CAMPAIGNS[0].products : [];
};

export const getCampaign = (state, campaignId) => {
    return manageCampaignState(state).find((c) => `${campaignId}` === `${c.campaignId}`);
};