import React from "react";
import { SortableContainer, SortableElement, SortableHandle } from "react-sortable-hoc";

// TODO - Add proptypes validation
const DragHandle = SortableHandle(() => <i className="fa fa-bars" />); // This can be any component you want
const Tab = SortableElement(({ value, isDraggable = true, activeTab = false, onClickHandler, renderPostElements }) =>
    <li id={value.referenceId} className={activeTab ? "active" : ""}>
        <a className="btn btn-primary" onClick={(e) => value.clickHandler ? value.clickHandler(e, value) : onClickHandler(e, value) }>
            <div className="page-name" style={{ color: "white" }}>
                {isDraggable ? <DragHandle /> : null}
                {value.title}
            </div>
            {renderPostElements(value)}
        </a>
    </li>
);

// TODO - Add proptypes validation
export const Tabs = SortableContainer(({ tabs, clickHandler, activeTab, renderPostElements }) => {
    return (
        <ul className="nav nav-pills nav-stacked">
            {tabs.map((value, index) => (
                <Tab
                    key={`item-${index}`}
                    index={index}
                    value={value}
                    isDraggable={value.isDraggable}
                    onClickHandler={clickHandler}
                    activeTab={value.referenceId === activeTab}
                    renderPostElements={renderPostElements}
                />
            ))}
        </ul>
    );
});