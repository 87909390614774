import React, { Component } from "react";
import PropTypes from "prop-types";
import "../../../../node_modules/jointjs/dist/joint.css";
import FontsList from "../fontsList";
import constants from "../../utilities/constants";
import { TextInput } from "../../../commons";
import { Multiselect } from "react-widgets";
import { TemplateCard } from "../../../templates/template-card";
import isEmpty from "lodash/isEmpty";

export class VisualizerRightPanel extends Component {
    static propTypes = {
        funnel: PropTypes.object,
        pageData: PropTypes.object,
        updateVisualizerData: PropTypes.func,
        visualizerData: PropTypes.object,
        onSelectButton: PropTypes.func,
        fontList: PropTypes.object,
        fonts: PropTypes.array,
        isFontLoading: PropTypes.bool,
        saveTemplatePageView: PropTypes.func,
        saveFonts: PropTypes.func,
        closeRightPanel: PropTypes.func,
        pageNameChange: PropTypes.func,
        templateList: PropTypes.array,
        saveGraph: PropTypes.func,
        removeCallBackEvent: PropTypes.func,
        updatedPage: PropTypes.object,
        loadTemplates: PropTypes.func,
        loadFonts: PropTypes.func,
        editPage: PropTypes.func,
        editFunnel: PropTypes.func,
        templates: PropTypes.array,
    }
    constructor() {
        super();
    }
    componentDidMount() {
        this.props.loadFonts();
        this.props.loadTemplates();
    }
    componentDidUpdate() {
        const { callbackEvent } = this.props.visualizerData;
        switch (callbackEvent) {
            case "openShare":
                this.openShareCallback();
                break;
            case "copyShareId":
                this.copyShareIdCallback();
                break;
        }
    }
    openShare = () => {
        const { isShareOpen } = this.props.visualizerData;
        this.props.updateVisualizerData({
            ...this.props.visualizerData,
            isShareOpen: !isShareOpen,
            isShareIdError: false,
            callbackEvent: "openShare"
        });
    }
    openShareCallback = () => {
        document.querySelector("#importId").value = null;
        this.props.removeCallBackEvent();
    }

    copyShareId = () => {
        this.props.updateVisualizerData({ ...this.props.visualizerData, isCopied: true, callbackEvent: "copyShareId" });
    }
    copyShareIdCallback = () => {
        const text = document.querySelector("#shareId");
        text.select();
        document.execCommand("copy");
        text.blur();
        setTimeout(() => {this.props.updateVisualizerData({ ...this.props.visualizerData, isCopied: false });}, 2000);
        this.props.removeCallBackEvent();
    }
    importSharePage = () => {
        this.props.updateVisualizerData({
            ...this.props.visualizerData, showModalWindow: true,
            modalToShow: "sharePopup"
        });
    }
    handleImportShareIdChange = () => {
        const { selectedData } = this.props.visualizerData;
        const { funnel } = this.props;
        // const currentPage = funnel.page.find((element) => {
        //     return element.cellViewId === selectedData.model.id;
        // });
    }
    renderButtonInput = () => {
        const { linkViewId, sourcePageLabel, sourceTemplateTypeId, linkCurrentPage, linkButtonData, multiSelectValue ,targetViewId } = this.props.visualizerData;
        let allButtons = [], allLinks = [];
        const { pageData, funnel } = this.props;
        const targetPage = funnel.page.find((page) => page.cellViewId === targetViewId);
        const pagelabel = "Buttons / Links  of " + sourcePageLabel;
        const selectLabel = "Choose " + sourcePageLabel + " Page Buttons / Links";
        const isLoginPage = sourceTemplateTypeId === 7;
        if ((pageData.buttonData && pageData.buttonData.length)) {
            allButtons = pageData.buttonData.filter((ab) => {
                if (ab.linkViewId && (ab.linkViewId !== linkViewId)) {
                    return true;
                }
                return true;
            }).map((bd) => {
                return { value: JSON.stringify(bd), text: bd.title ? isLoginPage ? bd.title + "-" + bd.status : bd.title : isLoginPage ? bd.name + "-" + bd.status : bd.name, groupLabel: "Buttons" };
            });

        }
        if ((pageData.linkData && pageData.linkData.length)) {

            allLinks = pageData.linkData.filter((ab) => {
                if (ab.linkViewId && (ab.linkViewId !== linkViewId)) {
                    return false;
                }
                return true;
            }).map((bd) => {
                return { value: JSON.stringify(bd), text: bd.title ? bd.title : "Link", groupLabel: "Links" };
            });
        }

        if (!allButtons.length && !allLinks.length) {
            if (linkCurrentPage && linkCurrentPage.isEdited) {
                return (
                    <div className="no-template">
                        <h3>All buttons and Links are linked. Please add a button / Link and try again. </h3>
                    </div>
                );
            }
            return (
                <div className="no-template">
                    <h3>Please edit the page and try again </h3>
                </div>
            );
        }
        const isButtonEnabled = (multiSelectValue && multiSelectValue.length !== 0 || linkButtonData);
        const saveButtonClass = isButtonEnabled
            ? "btn btn-primary m-l-xs pull-left"
            : "btn btn-primary m-l-xs pull-left disabled";
        const selectArray = [...allButtons, ...allLinks];
        let currentValue;
        let checkTargetPage;
        let selectArrayFiltered;
        if (isLoginPage) {
            selectArrayFiltered = selectArray.filter((value) => {
                currentValue = JSON.parse(value.value);
                checkTargetPage = targetPage && currentValue && currentValue.target === targetPage.referenceId;
                return (currentValue.linkViewId === linkViewId);
            });
        } else {
            selectArrayFiltered = selectArray.filter((value) => {
                currentValue = JSON.parse(value.value);
                checkTargetPage = targetPage && currentValue && currentValue.target === targetPage.referenceId;
                return (currentValue.linkViewId) && checkTargetPage;
            });
        }
        let value = [];
        multiSelectValue && multiSelectValue.length !== 0 && (value = multiSelectValue);
        multiSelectValue && multiSelectValue.length === 0 && (value = selectArrayFiltered);

        return (
            <div>
                <h3 className="">{pagelabel}</h3>
                <div className="col-xs-12 no-padding buttons-list form-group">
                    <Multiselect
                        data={selectArray}
                        textField='text'
                        groupBy='groupLabel'
                        value={value}
                        placeholder={selectLabel}
                        onChange={value => this.props.updateVisualizerData({ ...this.props.visualizerData, multiSelectValue: value })}
                    />
                </div>
                <div className="col-xs-12 no-padding buttons-list">
                    <button onClick={isButtonEnabled ? this.props.onSelectButton : () => null} className={saveButtonClass}><i className="fa fa-cloud-upload" aria-hidden="true"></i> Save</button>
                </div>
            </div>
        );
    }
    onTemplateSelected = (template) => {
        const { funnel } = this.props;
        const { selectedView } = this.props.visualizerData;
        const currentPage = funnel.page.find((element) => {
            return element.cellViewId === selectedView.id;
        });
        if (currentPage) {
            const pageViewReferenceId = currentPage.pageView[0].referenceId;

            this.props.saveTemplatePageView({
                template,
                pageViewReferenceId,
                templateId: template.templateId,
                cellViewId: currentPage.cellViewId,
                funnelReferenceId: funnel.referenceId
            });
            this.props.closeRightPanel({ selectedTemplate: template });
        }
    }
    modifylabel = () => {
        const { pageName, selectedData } = this.props.visualizerData;
        this.props.saveGraph();
        this.changeTitle(pageName, selectedData);
    }
    saveFonts = (savedFonts) => {
        if (savedFonts && savedFonts.length) {
            const { selectedData } = this.props.visualizerData;
            const { funnel } = this.props;
            const currentPage = funnel.page.find((element) => {
                return element.cellViewId === selectedData.model.id;
            });
            const funnelReferenceId = funnel && funnel.referenceId;
            const fontUrlArray = savedFonts && savedFonts.map(item => {
                const fontFamily = item.family.split(" ").join("+");
                const fontUrl = `https://fonts.googleapis.com/css?family=${fontFamily}`;
                return { fontUrl, item };
            });
            this.props.saveFonts({ currentPage, fontUrlArray, funnelReferenceId });
            this.props.closeRightPanel();
        } else {
            this.props.updateVisualizerData({ ...this.props.visualizerData, showModalWindow: true, modalToShow: "fontAlert" });
        }
    }
    changeTitle = (pageTitle, selectedData) => {
        const { funnel } = this.props;
        const currentElement = selectedData.model;
        const currentPage = funnel.page.find((element) => {
            return element.cellViewId === currentElement.id;
        });
        this.props.editPage({ funnel, pageTitle, currentPage });
    }
    changeDefaultPage = (currentDefaultPageViewReferenceId, isDefaultPage,currentPage) => {
        const { funnel } = this.props;
        !isDefaultPage && this.props.editFunnel({ funnel, currentDefaultPageViewReferenceId,currentPage });
    }
    pageNameChange = (e) => {
        this.props.pageNameChange(e.target.value);
    }
    render() {
        const { isRightPanelActive, isConfigLink, isCopied, isPageImported, isShareidSame, isShareIdError, selectedData
            , importShareId, pageTypeLabel, pageName, isLoadTemplates, cellData, isShareOpen, templateTypeId,linkCurrentPage } = this.props.visualizerData;
        const { funnel, fontList, fonts, isFontLoading, templates, updatedPage, pageData } = this.props;
        const panelClass = isRightPanelActive ? "rightPanel active" : "rightPanel";
        const copyMessage = isCopied ? "copy-message active" : "copy-message";
        let currentPage;
        if (funnel.page && selectedData.model && !isPageImported) {
            currentPage = funnel.page.find((element) => {
                return element.cellViewId === selectedData.model.id;
            });
        }

        if (isPageImported) {
            currentPage = updatedPage;
        }
        let isTemplateId = isPageImported ? updatedPage && updatedPage.pageView[0].fTemplateId : currentPage && currentPage.pageView[0].fTemplateId;
        let isEdited = currentPage && currentPage.isEdited;
        const isFunnelPublished = funnel.status;
        let pageUrl = currentPage ? currentPage.pageView[0].url : "";
        const isDefaultPage = currentPage ? currentPage.pageView[0].defaultPage : false;
        let pageLiveUrl = currentPage ? currentPage.pageView[0].publicUrl : "";
        const templateList = templates ? templates.map((template) => <TemplateCard key={template.templateId} template={template} onTemplateSelected={this.onTemplateSelected} showPreview={this.showPreview} />)
            : [];
        const listTemplate = templateList.sort((a, b) => {
            return b.props.template.templateTypeId - a.props.template.templateTypeId;
        });
        const filteredTemplateList = listTemplate.filter(
            (template) => {
                if ((template.props.template.templateTypeId === 6 && templateTypeId) || template.props.template.templateTypeId && template.props.template.templateTypeId === templateTypeId) {
                    return true;
                } else {
                    return false;
                }
            });
        const isIcon = cellData.attributes && cellData.attributes.icon;
        const shareClass = isShareOpen ? "share-popup active" : "share-popup";
        const selectedFonts = fonts && fonts.map(item => {
            const filtered = fontList && fontList.items.filter(font => {
                return font.family === item.fontFamily;
            });
            return filtered && filtered[0].family;
        });

        let showRightPanel = isRightPanelActive;
        if (isConfigLink && !isEmpty(pageData.jsPageData) && !isEmpty(linkCurrentPage) && (linkCurrentPage.referenceId !== pageData.jsPageData.currentPage.referenceId)) {
            showRightPanel = false;
        }
        return (
            showRightPanel && <div className={panelClass}>
                {!isConfigLink &&
                    <>
                        <div className="share-section">
                            <button className="btn btn-primary pull-right" onClick={this.openShare}><i className="fa fa-share-square-o m-r-xs" aria-hidden="true"></i> Share</button>
                        </div>
                        <div className={shareClass}>
                            <div className="col-xs-12 no-padding">
                                <span className="pointer pull-right" onClick={this.openShare}><i className="fa fa-close" aria-hidden="true"></i></span>
                                <div className={copyMessage}>Share Id Copied</div>
                                {isTemplateId && isEdited ?
                                    [<TextInput
                                        name="shareId"
                                        label={"Share Id "}
                                        value={currentPage && currentPage.pageView[0].shareId}
                                        id="shareId"
                                        btnText="Copy"
                                        note={"Give this Share Id to other FunnelKonnekt users to share this page"}
                                    />,
                                    <div className="text-right"><button className="btn btn-primary" onClick={this.copyShareId}><i class="fa fa-clone m-r-xs" aria-hidden="true"></i> Copy</button></div>]
                                    : <h3>Please edit the page to share</h3>}
                                <hr className="m-b-xs" />
                                <hr className="m-t-xs" />
                                <TextInput
                                    name="importId"
                                    label={"Import A Page"}
                                    value={importShareId}
                                    placeholder={"Enter Share Id here"}
                                    onChange={this.handleImportShareIdChange}
                                    id="importId"
                                    btnText="Import"
                                    error={isShareIdError ? true : false}
                                    errorMessage={isShareidSame ? "Please enter different Share Id" : "Please enter valid Share Id"}
                                />
                                <div className="text-right"><button className="btn btn-primary" onClick={this.importSharePage}><i className="fa fa-download m-r-xs" aria-hidden="true"></i> Import</button></div>
                            </div>
                        </div>
                    </>
                }
                <div className="col-xs-12 panel-content">
                    {!isConfigLink &&
                        <TextInput
                            name="pageName"
                            label={pageTypeLabel ? pageTypeLabel + " Name " : ""}
                            value={pageName}
                            onChange={this.pageNameChange}
                            maxLength={25}
                            id="pageName"
                            inputAddon={true}
                            onClick={this.modifylabel}
                            btnText="Save"
                        />
                    }
                    {!isConfigLink && isTemplateId
                        ? <div className="form-group">
                            <div className="input-group">
                                <label className="input-group-addon">Test URL</label>
                                <input className="form-control" name="productName" value={pageUrl} type="text" />
                                <div className="input-group-addon badge-primary" ><a className="text-white" href={pageUrl} target="_blank"><i class="fa fa-external-link" aria-hidden="true" /></a></div>
                            </div>
                        </div>
                        : null
                    }
                    {!isConfigLink && isFunnelPublished && isTemplateId
                        ? <div className="form-group">
                            <div className="input-group">
                                <label className="input-group-addon">Live URL</label>
                                <input className="form-control" name="productName" value={pageLiveUrl} type="text" />
                                <div className="input-group-addon badge-primary" ><a className="text-white" href={pageLiveUrl} target="_blank"><i class="fa fa-external-link" aria-hidden="true" /></a></div>
                            </div>
                        </div>
                        : null
                    }
                    {isConfigLink ?
                        this.renderButtonInput()
                        : (isIcon ?
                            <div className="col-xs-12 no-padding full-height">
                                <div className="no-template">
                                    <h3 className="text-center">Please setup auto-responders on your campaign in CRM to activate this page</h3>
                                </div>
                            </div>
                            :
                            (isLoadTemplates && !isPageImported) &&
                            <div className="col-xs-12 no-padding full-height">
                                <h4>Choose {currentPage && currentPage.title} Template</h4>
                                {filteredTemplateList.length !== 0 ? <div className="row"> {filteredTemplateList} </div> : <div className="coming-soon"></div>}
                            </div>)
                    }
                    {!isConfigLink && isTemplateId &&
                        <FontsList fontList={fontList && fontList} selectedFonts={selectedFonts && selectedFonts} isFontLoading={isFontLoading && isFontLoading} savedFonts={fonts && fonts} saveFontsCall={this.saveFonts} />
                    }
                    {!isConfigLink && isTemplateId &&
                        <div className="checkbox checkbox-success checkbox-md custom-checkBox">
                            <input type="checkbox"
                                className="styled" id="setDefaultPage"
                                checked={isDefaultPage}
                                onChange={() => this.changeDefaultPage(currentPage.pageView[0].referenceId, isDefaultPage,currentPage)}
                            />
                            <label for="setDefaultPage">{constants.setDefault} </label>
                        </div>
                    }
                </div>
            </div>
        );
    }
}