import joint from "jointjs";
import $ from "jquery";
import _ from "underscore";
import debounce from "lodash/debounce";
import VisualizerEmitter from "../visualizer-emitter";


//Creating the Custom Text Input
joint.shapes.html = {};
joint.shapes.html.Element = joint.shapes.basic.Rect.extend({
    defaults: joint.util.deepSupplement({
        type: "html.Element",
        attrs: {
            rect: { stroke: "none", "fill-opacity": 0 },
        },
        size: { width: 240, height: 50 }
    }, joint.shapes.basic.Rect.prototype.defaults)
});

// Create a custom view for that element that displays an HTML div above it.
// -------------------------------------------------------------------------

joint.shapes.html.ElementView = joint.dia.ElementView.extend({

    template: `
        <div class="html-element visualizer-graph-text-element">
            <div class="icon-container">
                <span class="icons trash delete">
                    &#xf1f8;
                </span>
            </div>
            <div class="visualizer-graph-input-container">
                <label></label>
                <input type="text" value="" />
            </div>
        </div>
    `,

    initialize: function () {
        _.bindAll(this, ["updateBox", "handleInputBlur"]);
        var self = this;
        joint.dia.ElementView.prototype.initialize.apply(this, arguments);

        this.$box = $(_.template(this.template)());
        // Prevent paper from handling pointerdown.
        this.$box.on("dblclick", _.bind(function () {
            var $box = self.$box;
            $box
                .addClass("focussed")
                .find("input")
                .focus();
        }, this));

        const textContent = this.model.get("input");
        if (textContent) {
            this.$box.find("input").val(textContent);
        }
        /*
         * [Todo - This has to be changed to a different event name like OUTSIDECLICK, etc]
         */
        VisualizerEmitter.addListener("RIGHTPANEL:CLOSE", this.handleInputBlur);

        this.$box.find("input")
            .on("blur", this.handleInputBlur)
            .on("mousedown click", function (evt) {
                evt.stopPropagation();
            })
            .on("keydown keyup", _.bind(function (evt) {
                const value = evt.target.value;
                this.model.set("input", value);
                this.$box.find("label").html(value);
            }, this));

        this.$box.find(".icon-container").on("click", _.bind(function (e) {
            this.model.set("removed", true);
            var removeFunction = _.bind(this.model.remove, this.model);
            removeFunction();
        }, this));
        // Update the box position whenever the underlying model changes.
        this.model.on("change", this.updateBox, this);
        // Remove the box when the model gets removed from the graph.
        this.model.on("remove", this.removeBox, this);

        this.updateBox();
    },
    render: function () {
        joint.dia.ElementView.prototype.render.apply(this, arguments);
        this.paper.$el.prepend(this.$box);
        this.updateBox();
        return this;
    },
    updateBox: function () {
        // Set the position and dimension of the box so that it covers the JointJS element.
        var bbox = this.model.getBBox();
        // Example of updating the HTML with a data stored in the cell model.
        this.$box.find("label").text(this.model.get("input") || "Double Click To Edit");
        this.$box.css({
            width: bbox.width,
            height: bbox.height,
            left: bbox.x,
            top: bbox.y,
            transform: "rotate(" + (this.model.get("angle") || 0) + "deg)"
        });
    },
    debouncedTextUpdate: debounce(function (cell, x, y) {
        if (!cell.model.get("removed")) {
            VisualizerEmitter.emit("TEXT-ELEMENT:UPDATE", cell, {
                x,
                y,
            });
        }
    }, 250),
    handleInputBlur: function () {
        var $box = this.$box;
        $box.removeClass("focussed");
        var bbox = this.model.getBBox();
        this.debouncedTextUpdate(this, bbox.x, bbox.y);
    },
    removeBox: function (evt) {
        this.$box.remove();
        VisualizerEmitter.emit("TEXT-ELEMENT:DELETE", this);
    }
});