import React, { Component } from "react";
import PropTypes from "prop-types";

export class Step2 extends Component {
    constructor(props) {
        super(props);

        // this.state = {
        //     username: props.getStore().username,
        //     password: props.getStore().password
        // };
        //
        // this._validateOnDemand = true; // this flag enables onBlur validation as user fills forms
        // this.validationCheck = this.validationCheck.bind(this);
        // this.isValidated = this.isValidated.bind(this);
    }

    componentDidMount() {}

    componentWillUnmount() {}

    // isValidated() {
    //     const userInput = this._grabUserInput(); // grab user entered vals
    //     const validateNewInput = this._validateData(userInput); // run the new input against the validator
    //     let isDataValid = false;
    //
    //     // if full validation passes then save to store and pass as valid
    //     if (Object.keys(validateNewInput).every((k) => {return validateNewInput[k] === true;})) {
    //         if (this.props.getStore().postalCode !== userInput.postalCode || this.props.getStore().gender !== userInput.gender) { // only update store of something changed
    //             this.props.updateStore({
    //                 ...userInput,
    //                 savedToCloud: false // use this to notify step4 that some changes took place and prompt the user to save again
    //             }); // Update store here (this is just an example, in reality you will do it via redux or flux)
    //         }
    //
    //         isDataValid = true;
    //     } else {
    //     // if anything fails then update the UI validation state but NOT the UI Data State
    //         this.setState(Object.assign(userInput, validateNewInput, this._validationErrors(validateNewInput)));
    //     }
    //
    //     return isDataValid;
    // }
    //
    // validationCheck() {
    //     if (!this._validateOnDemand)
    //         return;
    //
    //     const userInput = this._grabUserInput(); // grab user entered vals
    //     const validateNewInput = this._validateData(userInput); // run the new input against the validator
    //
    //     this.setState(Object.assign(userInput, validateNewInput, this._validationErrors(validateNewInput)));
    // }
    //
    // _validateData(data) {
    //     return {
    //         usernameVal: /./.test(data.username), // required: regex w3c uses in html5
    //         passwordVal: /^[a-zA-Z[^0-9.!#$%&'*+/=?^_`{|}~-]+/.test(data.password), // required: regex w3c uses in html5
    //     };
    // }
    //
    // _validationErrors(val) {
    //     const errMsgs = {
    //         usernameValMsg: val.usernameVal ? "" : "Enter your username",
    //         passwordValMsg: val.passwordVal ? "" : "Enter your password"
    //     };
    //     return errMsgs;
    // }
    //
    // _grabUserInput() {
    //     return {
    //         username: this.refs.username.value,
    //         password: this.refs.password.value
    //     };
    // }
    // onClick = () => {
    //     this.isValidated();
    //     this.props.onSave();
    // }
    render() {
        return (
            <div>
                <div className="row">
                    <div className="col-md-4">
                        <div className="ibox" >
                            <div className="ibox-content goalBox text-center">
                                <div style={{ padding: "10%" }}>
                                    <h3><img style={{ width: "10%", marginRight: "10px" }} src={require("../../../img/icons/design.svg")} alt=""/>Sales Funnel</h3>
                                </div>
                                <div>
                                    <p>Sell your products after collecting leads with a squeeze page.</p>
                                </div>
                                <div style={{ paddingRight: "20%", paddingLeft: "20%", paddingTop: "20%", }}>
                                    <button onClick={() => this.props.jumpToStep(2)} className="btn btn-primary"><i className="fa fa-plus" style={{ marginRight: "10%" }}/>Choose</button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-4">
                        <div className="ibox">
                            <div className="ibox-content goalBox text-center">
                                <div style={{ padding: "10%" }}>
                                    <h3><img style={{ width: "10%", marginRight: "10px" }} src={require("../../../img/icons/launch.svg")} alt=""/>Product Launch</h3>
                                </div>
                                <div>
                                    <p>Sell your product in a launch sequence with video pages.</p>
                                </div>
                                <div style={{ paddingRight: "20%", paddingLeft: "20%", paddingTop: "20%", }}>
                                    <button onClick={() => this.props.jumpToStep(2)} className="btn btn-primary"><i className="fa fa-plus" style={{ marginRight: "10%" }}/>Choose</button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-4">
                        <div className="ibox">
                            <div className="ibox-content goalBox text-center">
                                <div style={{ padding: "10%" }}>
                                    <h3><img style={{ width: "10%", marginRight: "10px" }} src={require("../../../img/icons/id-card.svg")} alt=""/>Membership</h3>
                                </div>
                                <div>
                                    <p>Sell your content with a membership that you can charge for.</p>
                                </div>
                                <div style={{ paddingRight: "20%", paddingLeft: "20%", paddingTop: "10%", }}>
                                    <button onClick={() => this.props.jumpToStep(2)} className="btn btn-primary"><i className="fa fa-plus" style={{ marginRight: "10%" }}/>Choose</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
Step2.propTypes = {
    jumpToStep: PropTypes.func,
    updateStore: PropTypes.Object,
    getStore: PropTypes.func,
    // onSave: PropTypes.func.isRequired,
    accountError: PropTypes.bool
};