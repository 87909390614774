export class Page {
    constructor(pageObj) {
        this.id = pageObj.pageId;
        this.title = pageObj.title;
        this.htmlContent = pageObj.htmlContent;
        this.cssContent = pageObj.cssContent;
        this.templateId = pageObj.templateId;
        this.isDraggable = pageObj.isDraggable;
        this.isDefaultPage = pageObj.isDefaultPage;
        this.pageView = pageObj.pageView; // => [new PageView()]
        this.active = pageObj.active;
        this.createdBy = pageObj.createdBy;
        this.createdOn = pageObj.createdOn;
        this.deleted = pageObj.deleted;
        this.deletedBy = pageObj.deletedBy;
        this.deletedOn = pageObj.deletedOn;
        this.fFunnelId = pageObj.fFunnelId;
        this.fTenant = pageObj.fTenant;
        this.fTenantId = pageObj.fTenantId;
        this.referenceId = pageObj.referenceId;
    }
}
export class PageView {
    constructor(obj) {
        this.active = obj.active;
        this.createdBy = obj.createdBy;
        this.createdOn = obj.createdOn;
        this.cssUrl = obj.cssUrl;
        this.deleted = obj.deleted;
        this.deletedBy = obj.deletedBy;
        this.deletedOn = obj.deletedOn;
        this.fPageId = obj.fPageId;
        this.fTemplate = obj.fTemplate;
        this.fTemplateId = obj.fTemplateId;
        this.fTenant = obj.fTenant;
        this.fTenantId = obj.fTenantId;
        this.fileReferenceId = obj.fileReferenceId;
        this.htmlUrl = obj.url;
        this.name = obj.name;
        this.pageViewId = obj.pageViewId;
        this.updatedBy = obj.updatedBy;
        this.updatedOn = obj.updatedOn;
        this.referenceId = obj.referenceId;
    }
}