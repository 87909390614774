import React from "react";
import { bool, string, func } from "prop-types";

export default function CheckBox({ label, fieldName, name, handleChange }) {
    return (
        <div className="checkbox checkbox-success checkbox-md ">
            <label className="custom-checkbox">
                <div className={`check-box-label ${fieldName ? "text-color" : ""}`}>{label}</div>
                <input
                    type="checkbox"
                    name={`${name}`}
                    onChange={e => handleChange(e, "checkbox")}
                    defaultChecked={fieldName === 1}
                />
                <span className="check-box"></span>
            </label>
        </div>
    );
}

CheckBox.propTypes = {
    label: string,
    fieldName: bool,
    handleChange: func,
    name: string
};
