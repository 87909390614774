import React, { Component } from "react";
import PropTypes from "prop-types";
import isFunction from "lodash/isFunction";
import isEmpty from "lodash/isEmpty";
import noop from "lodash/noop";
import FunnelForm from "../funnels-form";
import joint from "jointjs";
import { Modal } from "react-bootstrap";
import { Link } from "react-router-dom";
import "../../../node_modules/jointjs/dist/joint.css";
// import "./style.scss";
import "../../../node_modules/react-widgets/dist/css/react-widgets.css";
import { TextInput } from "../../commons";
import Visualizer from "./components/visualizer";
import constants from "./utilities/constants";
import { TemplateCard } from "../../templates/template-card";
import { Settings } from "../../settings/settings";
import { SplitViewCard } from "./splitTest//SplitViewCard";
import CreateDomainModal from "../../settings/create-domain-modal";
import EditDomainModal from "../../settings/edit-domain-modal";
import "./style.css";

import { NotificationEmitter, NOTIFICATION_EVENT_NAME } from "../../commons/notification";

import PurchaseConfirmation from "../../commons/modals/purchase-confirmation-modal";
import { getPurchasedTemplates } from "./actions";

export class CreateFunnelVisualizer extends Component {
    static propTypes = {
        history: PropTypes.object,
        actions: PropTypes.object,
        funnel: PropTypes.object,
        campaigns: PropTypes.object,
        clearPageData: PropTypes.func,
        clearJsonData: PropTypes.func,
        saveButtonData: PropTypes.func,
        savePageViewData: PropTypes.func,
        loadButtons: PropTypes.func,
        editPage: PropTypes.func,
        loadMetaJson: PropTypes.func,
        splitDuplicatePage: PropTypes.func,
        splitChooseTemplate: PropTypes.func,
        clonePage: PropTypes.func,
        saveTemplatePageView: PropTypes.func,
        setSplitPercentToPage: PropTypes.func,
        declareWinner: PropTypes.func,
        savefunnelGraph: PropTypes.func,
        createNewPage: PropTypes.func,
        publishFunnel: PropTypes.func,
        clearDomainForFunnelData: PropTypes.func,
        clearFunnelData: PropTypes.func,
        editFunnel: PropTypes.func,
        deletePage: PropTypes.func,
        errorClose: PropTypes.func,
        resetData: PropTypes.func,
        saveFonts: PropTypes.func,
        getSavedfonts: PropTypes.func,
        setDomainForFunnel: PropTypes.func,
        addAndSetDomainForFunnel: PropTypes.func,
        loadCampaigns: PropTypes.func,
        loadTemplates: PropTypes.func,
        getDomainListForUser: PropTypes.func,
        loadFonts: PropTypes.func,
        loadFunnels: PropTypes.func,
        getFunnelData: PropTypes.func,
        pageData: PropTypes.object,
        funnelType: PropTypes.object,
        importedPage: PropTypes.object,
        importedFunnel: PropTypes.object,
        authors: PropTypes.array,
        upsales: PropTypes.array,
        fonts: PropTypes.array,
        offers: PropTypes.array,
        fontList: PropTypes.array,
        domainList: PropTypes.array,
        selectedDomainForFunnel: PropTypes.array,
        jsonData: PropTypes.object,
        importPage: PropTypes.object,
        funnels: PropTypes.object,
        htmlPage: PropTypes.string,
        funnelReferenceId: PropTypes.string,
        metaJson: PropTypes.string,
        salt: PropTypes.string,
        pageError: PropTypes.bool,
        isLoading: PropTypes.bool,
        isFontLoading: PropTypes.bool,
        isError: PropTypes.bool,
        importError: PropTypes.bool,
        importFunnel: PropTypes.object,
        importFunnelFromShareId: PropTypes.func,
        importFunnelError: PropTypes.bool,
        isImportFunnelLoading: PropTypes.bool,
        showSuccessMessage: PropTypes.func,
        showImportFunnelSuccessMessage: PropTypes.bool,
        getDefaultFunnels: PropTypes.func,
        defaultFunnelTemplates: PropTypes.array,
        updateShowSplitSetting: PropTypes.func,
        getSplitPageSettingsViewStatus: PropTypes.bool,
        getThumbnailForFunnel: PropTypes.func,
        openFunnelTemplatesOnLoad: PropTypes.bool,
        getAnalyticsData: PropTypes.func,
        purchasedFunnels: PropTypes.array,
        premiumFunnelTemplates: PropTypes.array,
        getPremiumFunnelTemplates: PropTypes.func,
        funnelTemplateTypeIdToPrepopulate: PropTypes.string,
        templates: PropTypes.object,
        premiumTemplates: PropTypes.array,
        userTemplates: PropTypes.array,
        canAccessPremiumTemplates: PropTypes.bool,
        purchaseOrder: PropTypes.func,
        userData: PropTypes.object,
        createDomain: PropTypes.func,
        getLatestAddedDomain: PropTypes.object,
        isLoadingDomain: PropTypes.bool,
        selectedDomainForFunnelError: PropTypes.string,
        toggleCRMEntryModal: PropTypes.func
    };
    static contextTypes = {
        router: PropTypes.object,
    };
    constructor(props, context) {
        super(props, context);
        this.child = React.createRef();
        this.state = {
            funnel: Object.assign({
                title: "",
                author: "",
                campaign: "",
                fListFunnelTypeId: "",
                offer: [""],
                upsale: [""],
                status: "TestStatus",
            }, props.funnel),
            templates: [""],
            showSettings: false,
            editAlert: false,
            waitAlert: false,
            publishAlert: false,
            closeRightPanel: false,
            showTemplates: false,
            declareWinnerPopup: false,
            showSplitEditPopup: false,
            showShareAlert: false,
            showFunnelShareAlert: false,
            showSplitPreviewPopup: false,
            showSplitSetting: this.props.getSplitPageSettingsViewStatus,
            showSplitArrowPopup: false,
            allOffers: props.offers,
            allUpsales: props.upsales,
            selectedTemplate: {},
            metaJson: this.props.metaJson ? this.props.metaJson : "",
            selectedView: {},
            errors: {},
            domainList: this.props.domainList,
            selectedDomainForFunnel: this.props.selectedDomainForFunnel,
            splitSettingDetails: {},
            isSelectedSplitPageDefaultPage: false,
            splitPageName: "",
            winnerPage: null,
            pageToPurchase: false,
            isDefaultPageSelected: false,
            allPagesEditedCheck: false,
            allPagesTemplateNotSelected: false,
            showAddNewDomainPopup: false,
            showValidationError: false,
            domainName: "",
            showEditDomainPopUp: false,
            editDomainDetails: {},
            isLoadingDomain: false,
            isAnyArrowNotLinked: false,
            isSelectedDomainVerified: false,
            selectedDomainForFunnelError: "",
            isFunnelEmpty: false
        };
        this.updateFunnelState = this.updateFunnelState.bind(this);
        this.onOfferChange = this.onOfferChange.bind(this);
        this.onUpsaleChange = this.onUpsaleChange.bind(this);
        this.removeOfferField = this.removeOfferField.bind(this);
        this.removeUpsaleField = this.removeUpsaleField.bind(this);
        this.validate = this.validate.bind(this);
        this.updateFunnel = this.updateFunnel.bind(this);
        this.onCampaignChange = this.onCampaignChange.bind(this);
        this.navigateToManageFunnel = this.navigateToManageFunnel.bind(this);
        this.updateFunnelData = this.updateFunnelData.bind(this);
        this.generateTemplateSelectionConfig = this.generateTemplateSelectionConfig.bind(this);
        this.purchasePage = this.purchasePage.bind(this);
        this.loadAllTemplatesData = this.loadAllTemplatesData.bind(this);
        this.props.clearJsonData();
        /*
         *  Choose Templates Config
         */
        this.onTemplateSelect = this.onTemplateSelect.bind(this);
        this.generateTemplateSelectionConfig(props.canAccessPremiumTemplates);
        this.purchaseConfirmationCallbackFunction = noop;
    }
    async componentDidMount() {
        try {
            await this.props.getFunnelData(this.props.funnelReferenceId);
        // eslint-disable-next-line no-empty
        } catch (err) {}
        this.props.loadCampaigns();
        this.props.loadFonts();
        // this.props.getDomainListForUser(this.props.funnelReferenceId);
        // this.props.getThumbnailForFunnel(this.props.funnelReferenceId);
        this.props.getFunnelData(this.props.funnelReferenceId).catch(() => {});
        this.props.loadCampaigns().catch(() => {});
        this.props.loadFonts().catch(() => {});
        this.loadAllTemplatesData();
        this.props.getDomainListForUser(this.props.funnelReferenceId).catch(() => {});
        this.props.getThumbnailForFunnel(this.props.funnelReferenceId).catch(() => {});
    }

    componentWillReceiveProps(nextProps) {
        // TODO- Check for nextProps != this.props
        this.setState({
            templates: nextProps.templates,
            funnel: nextProps.funnel,
            metaJson: nextProps.metaJson,
            isError: nextProps.isError,
            updatedPage: nextProps.importedPage,
            updatedFunnel: nextProps.importedFunnel,
            domainList: nextProps.domainList,
            selectedDomainForFunnel: nextProps.selectedDomainForFunnel,
            savedFonts: nextProps.fonts,
            showSplitSetting: nextProps.getSplitPageSettingsViewStatus,
            editDomainDetails: nextProps.getLatestAddedDomain,
            isLoadingDomain: nextProps.isLoadingDomain,
            selectedDomainForFunnelError: nextProps.selectedDomainForFunnelError
        });
        const { isLoading } = this.props;
        if (nextProps.funnel !== this.props.funnel) {
            if (!nextProps.funnel.isVisualizer) {
                this.navigateToManageFunnel(nextProps.funnel.referenceId);
            }
            // this.setState({ funnel: nextProps.funnel });
        } else if ((isLoading === true && nextProps.isLoading === false) && !nextProps.funnel.referenceId) {
            // commenting the code as it throws 404 everytime
            //this.navigateToError();
        }

        if (nextProps.funnel && (nextProps.campaigns !== this.props.campaigns)) {
            let offersList = [], upsaleList = [];
            const selectedCampaign = nextProps.campaigns.find((c) => nextProps.funnel.campaign === `${c.value}`);
            if (selectedCampaign) {
                const productOffer = selectedCampaign.products.filter(p => p.productType === "OFFER");
                const productUpsell = selectedCampaign.products.filter(p => p.productType === "UPSALE");
                offersList = productOffer.map(product => {
                    return {
                        value: product.campaignProductId,
                        text: product.productName,
                        type: product.productType
                    };
                });
                upsaleList = productUpsell.map(product => {
                    return {
                        value: product.campaignProductId,
                        text: product.productName,
                        type: product.productType
                    };
                });
            }
            this.setState({ allOffers: offersList, allUpsales: upsaleList });
        }
        {
            !nextProps.isLoading &&
            this.setState({ showSettings: false });
        }
        if (nextProps.canAccessPremiumTemplates !== this.props.canAccessPremiumTemplates) {
            this.generateTemplateSelectionConfig(nextProps.canAccessPremiumTemplates);
        }
    }
    componentWillUnmount() {
        this.props.getThumbnailForFunnel(this.props.funnelReferenceId).catch(() => {});
        this.props.clearFunnelData();
        this.props.clearDomainForFunnelData();
        this.props.errorClose();
    }
    navigateToManageFunnel(referenceId) {
        this.props.history.push(`/manage-funnel/${referenceId}`);
    }
    navigateToError() {
        this.props.history.push("/404");
    }

    onTemplateSelect(selectedTemplate, callback) {
        const {
            isSplitEnabled,
        } = this.state;
        const templateChooseFunction = (isSplitEnabled) ? this.onSplitTemplateChoose : this.onTemplateSelected;
        if (isFunction(templateChooseFunction)) {
            templateChooseFunction(selectedTemplate, callback);
        }
    }

    loadAllTemplatesData() {
        const {
            loadTemplates,
            userData,
        } = this.props;
        loadTemplates().catch(() => {});
        if (!userData.isDesignerUser) {
            loadTemplates("userTemplates").catch(() => {});
            loadTemplates("premiumTemplates").catch(() => {});
        }
    }

    generateTemplateSelectionConfig(canAccessPremiumTemplates) {
        this.templateSelectionConfig = [
            {
                name: "Template Pages",
                thumbnails: () => this.props.templates,
                thumbnailComponent: TemplateCard,
                fetchData: this.props.loadTemplates,
                onThumbnailSelected: this.onTemplateSelect,
            },
        ];
        if (canAccessPremiumTemplates) {
            this.templateSelectionConfig = [
                {
                    name: "My Pages",
                    thumbnails: () => this.props.userTemplates,
                    thumbnailComponent: TemplateCard,
                    fetchData: () => this.props.loadTemplates("userTemplates"),
                    onThumbnailSelected: this.onTemplateSelect
                },
                ...this.templateSelectionConfig,
                {
                    name: "Premium Pages",
                    thumbnails: () => this.props.premiumTemplates,
                    onBeforeShow: getPurchasedTemplates,
                    thumbnailComponent: TemplateCard,
                    showPrice: true,
                    fetchData: () => this.props.loadTemplates("premiumTemplates"),
                    onThumbnailSelected: (selectedTemplate = {}, callback) => {
                        if (selectedTemplate && selectedTemplate.price && !selectedTemplate.isPurchased) {
                            this.purchaseConfirmationCallbackFunction = () => {
                                this.setState({ pageToPurchase: null });
                                callback();
                                this.purchaseConfirmationCallbackFunction = noop;
                            };
                            this.setState({
                                pageToPurchase: selectedTemplate,
                            });
                        } else {
                            this.onTemplateSelect(selectedTemplate, callback);
                        }
                    }
                }
            ];
        }
    }

    purchasePage() {
        const { selectedView, funnel, pageToPurchase } = this.state;
        const { userData } = this.props;
        const purchaseParams = {
            id: selectedView.id,
            templateId: pageToPurchase.referenceId,
            templateType: "page",
            price: pageToPurchase.price,
            campaignId: funnel.campaign,
            tenantId: userData.fTenantId,
            customerId: userData.customerId,
            affId: userData.affiliateId,
        };
        this.purchaseConfirmationCallbackFunction();
        return this.props.purchaseOrder(purchaseParams)
            .then(() => {
                this.onTemplateSelect(pageToPurchase);
            }).catch((err) => {
                NotificationEmitter.emit(NOTIFICATION_EVENT_NAME, { type: "error", message: err.message });
            });
    }

    updateFunnelState(event) {
        const field = event.target.name;
        let funnel = Object.assign({}, this.state.funnel);
        funnel[field] = event.target.value;
        return this.setState({ funnel: funnel });
    }

    updateFunnel(event) {
        event.preventDefault();
        this.props.actions.updateFunnel(this.state.funnel);
        this.props.history.push("/funnels");
    }

    validate(event) {
        const funnel = this.state.funnel;
        let offerValues = [];
        let upsaleValues = [];
        (!funnel.title) ? this.setState({ errors: { title: true, isSuccess: false } }) :
            (!funnel.campaign) ? this.setState({ errors: { campaignId: true, isSuccess: false } }) :
                (!funnel.fListFunnelTypeId) ? this.setState({ errors: { funnelType: true, isSuccess: false } }) :
                    (funnel.offer[0] === "") ? this.setState({ errors: { offerId: true, isSuccess: false } }) :
                        (funnel.offer.length > 1) && Array.isArray(funnel.offer) ? funnel.offer.forEach((product, index) => {
                            if (product === "" || Object.keys(product).length === 0 && product.constructor === Object) {
                                funnel.offer.splice(index, 1);
                            }
                            offerValues.push(product.value);
                            if (funnel.offer.length - 1 === index) {
                                this.setState({ isSuccess: true }, this.saveEditedFunnel(event));
                            }
                        }) :
                            (funnel.upsale.length > 1) && Array.isArray(funnel.upsale) ? funnel.upsale.forEach((product, index) => {
                                if (product === "" || Object.keys(product).length === 0 && product.constructor === Object) {
                                    funnel.upsale.splice(index, 1);
                                }
                                upsaleValues.push(product.value);
                                if (funnel.upsale.length - 1 === index) {
                                    this.setState({ isSuccess: true }, this.saveEditedFunnel(event));
                                }
                            }) :
                                //(funnel.upsale[0] === "") ? this.setState({ errors: { upsaleId: true, isSuccess: false } }) :// if we need upsale validation on future
                                this.setState({ isSuccess: true }, this.saveEditedFunnel(event));
    }
    onCampaignChange(event) {
        const field = event.target.name;
        const { funnel } = this.state;
        let offersList = [];
        let upsaleList = [];
        const selectedCampaign = this.props.campaigns.find((c) => event.target.value === `${c.value}`);
        if (selectedCampaign) {
            const productOffer = selectedCampaign.products.filter(p => p.productType === "OFFER");
            const productUpsell = selectedCampaign.products.filter(p => p.productType === "UPSALE");
            offersList = productOffer.map(product => {
                return {
                    value: product.campaignProductId,
                    text: product.productName,
                    type: product.productType
                };
            });
            upsaleList = productUpsell.map(product => {
                return {
                    value: product.campaignProductId,
                    text: product.productName,
                    type: product.productType
                };
            });
        }
        return this.setState({
            funnel: {
                ...funnel,
                [field]: event.target.value,
                offer: [""],
                upsale: [""],
            },
            allOffers: offersList,
            allUpsales: upsaleList
        });
    }
    onOfferChange(event, index) {
        //current funnel
        const { funnel } = this.state;
        //current funnel offers
        const { offer } = funnel;
        let updatedOffers = offer;
        //if the funnel's current offer property is a JSON string, parse to be an array
        if (offer && !Array.isArray(offer)) {
            updatedOffers = JSON.parse(offer);
        }
        //value (campaign product ID) of the newly selected offer
        const value = event.target.value;
        //if "all" was selected, add all main offer campaign products in the current funnel campaign
        if (value === "all") {
            //remove the offer currently at the index to be changed from the updatedOffers array
            updatedOffers.splice(index, 1);
            this.state.allOffers.forEach(oneOffer => {
                if (oneOffer.value !== "all" && oneOffer !== "") {
                    //add each campaign offer to the beginning of the updatedOffers array
                    updatedOffers.splice(0, 0, oneOffer);
                }
            });
        } else {
            //fetch the specific selected campaign product info from all main offer campaign products in the current funnel campaign
            const newOffer = this.state.allOffers.find(o => `${o.value}` === value);
            //replace the current offer at the index to be changed with the new offer
            updatedOffers.splice(index, 1, newOffer);
        }
        //remove any duplicates from the updatedOffers array
        updatedOffers = Array.from(new Set(updatedOffers.map(o => o.value))).map(value => {return updatedOffers.find(o => o.value === value);});
        //setState with updated funnel info, which will re-render the CreateFunnelVisualizer and therefore call the FunnelForm function again to display updated main offer product info/inputs
        return this.setState({
            funnel: {
                ...funnel,
                offer: updatedOffers,
            }, errors: { offerId: false }
        });
    }
    onUpsaleChange(event, index) {
        //current funnel
        const { funnel } = this.state;
        //current funnel upsells
        const { upsale } = funnel;
        let updatedUpsells = upsale;
        //if the funnel's current upsale property is a JSON string, parse to be an array
        if (upsale && !Array.isArray(upsale)) {
            updatedUpsells = JSON.parse(upsale);
        }
        //value (campaign product ID) of the newly selected upsell
        const value = event.target.value;
        //if "all" was selected, add all upsell campaign products in the current funnel campaign
        if (value === "all") {
            //remove the upsell currently at the index to be changed from the updatedUpsells array
            updatedUpsells.splice(index, 1);
            this.state.allUpsales.forEach(oneUpsell => {
                if (oneUpsell.value !== "all" && oneUpsell !== "") {
                    //add each campaign upsell to the beginning of the updatedUpsells array
                    updatedUpsells.splice(0, 0, oneUpsell);
                }
            });
        } else {
            //fetch the specific selected campaign product info from all upsell campaign products in the current funnel campaign
            const newUpsell = this.state.allUpsales.find(o => `${o.value}` === value);
            //replace the current upsell at the index to be changed with the new upsell
            updatedUpsells.splice(index, 1, newUpsell);
        }
        //remove any duplicates from the updatedUpsells array
        updatedUpsells = Array.from(new Set(updatedUpsells.map(o => o.value))).map(value => {return updatedUpsells.find(o => o.value === value);});
        //throw an error if no upsells selected
        if (funnel.upsale[0] !== "") {
            this.setState({ isSuccess: true, errors: { upsaleId: false } });
        } else {this.setState({ isSuccess: false });}
        //setState with updated funnel info, which will re-render the CreateFunnelVisualizer and therefore call the FunnelForm function again to display updated upsell product info/inputs
        return this.setState({
            funnel: {
                ...funnel,
                upsale: updatedUpsells
            }
        });
    }
    saveEditedFunnel(event) {
        const { funnel } = this.state;
        const filteredPageList = this.state.funnel.page.filter((data) => {
            return data.title === "Checkout Page" || data.title === "Upsell Page";
        });
        this.props.resetData({ filteredPageList, funnelReferenceId: funnel.referenceId, salt: this.props.salt }, () => {
            this.props.editFunnel({ funnel });
        });
    }
    cancel = (event) => {
        event.preventDefault();
        this.setState({ showSettings: false });
    }
    addOfferField = () => {
        const { funnel } = this.state;
        const { offer } = funnel;
        let myoffer = offer;
        if (offer && !Array.isArray(offer)) {
            myoffer = JSON.parse(offer);
        }
        myoffer.push({});
        this.setState({
            funnel: {
                ...funnel,
                offer: myoffer
            }
        });
    }
    addUpsaleField = () => {
        const { funnel } = this.state;
        const { upsale } = funnel;
        let myupsale = upsale;
        if (upsale && !Array.isArray(upsale)) {
            myupsale = JSON.parse(upsale);
        }
        myupsale.push({});
        this.setState({
            funnel: {
                ...funnel,
                upsale: myupsale
            }
        });
    }
    removeOfferField = (index) => {
        const { funnel } = this.state;
        const { offer } = funnel;
        let myoffer = offer;
        if (offer && !Array.isArray(offer)) {
            myoffer = JSON.parse(offer);
        }
        myoffer.splice(index, 1);
        this.setState({
            funnel: {
                ...funnel,
                offer: myoffer
            }
        });
    }
    removeUpsaleField = (index) => {
        const { funnel } = this.state;
        const { upsale } = funnel;
        let myupsale = upsale;
        if (upsale && !Array.isArray(upsale)) {
            myupsale = JSON.parse(upsale);
        }
        myupsale.splice(index, 1);
        this.setState({
            funnel: {
                ...funnel,
                upsale: myupsale
            }
        });
    }
    toggleSettingsView = () => {
        this.setState({
            showSettings: !this.state.showSettings
        });
    }
    publishFunnel = (isDefaultPageSelected, allPagesEditedCheck) => {
        const { funnel, metaJson } = this.props;
        const { visualizerJson, domainList, selectedDomainForFunnel } = this.state;
        const allPagesTemplateNotSelected = funnel && funnel.page && !!funnel.page.filter((page) => page.active && page.pageView[0].fTemplateId === null).length;
        const updatedMetaJson = visualizerJson ? visualizerJson : metaJson && JSON.parse(metaJson);
        const isAnyArrowNotLinked = updatedMetaJson && updatedMetaJson["cells"] &&
            !!updatedMetaJson["cells"]
                .filter((cells) => cells.type === "link" && cells.attrs[".marker-target"].stroke === "red")
                .length;
        const isSelectedDomainVerified = domainList &&
            !!domainList
                .filter((domain) => domain.referenceId === selectedDomainForFunnel && domain.cnameSet === true)
                .length;
        const isFunnelEmpty = funnel && funnel.page &&
            funnel.page.length === 0 ||
            funnel.page.filter((page) => page.active).length === 0;
        if (isDefaultPageSelected && allPagesEditedCheck && !allPagesTemplateNotSelected && !isAnyArrowNotLinked && isSelectedDomainVerified && !isFunnelEmpty) {
            this.props.publishFunnel(funnel.referenceId);
        } else {
            this.setState({
                publishAlert: true,
                isDefaultPageSelected: isDefaultPageSelected,
                allPagesEditedCheck: allPagesEditedCheck,
                allPagesTemplateNotSelected: allPagesTemplateNotSelected,
                isAnyArrowNotLinked: isAnyArrowNotLinked,
                isSelectedDomainVerified: isSelectedDomainVerified,
                isFunnelEmpty: isFunnelEmpty
            });
        }
    }
    shareFunnel = () => {
        const hasSplitPage = this.checkSplitPageInFunnel();

        const { funnel } = this.props;
        const activePage = funnel.page.filter(page => page.active);
        const noPages = activePage && activePage.length === 0;

        if (hasSplitPage.length) {
            this.setState({
                shareAlertMessage: constants.splitTestFunnelShareAlert
            });
        } else if (noPages) {
            this.setState({
                shareAlertMessage: constants.emptyFunnelShareAlert
            });
        }
        this.setState(prevState => ({
            showFunnelShareAlert: noPages || hasSplitPage.length,
            isShowShare: !prevState.isShowShare,
        }));
    }
    createNewPage = (cellViewDetail) => {
        const { funnel } = this.props;
        const { isError } = this.state;
        this.props.createNewPage({ funnel, cellViewDetail })
            .catch(err => {
                NotificationEmitter.emit(NOTIFICATION_EVENT_NAME, { type: "error", message: err.message, closeButton: true });
            });
    }
    closeMessage = () => {
        this.props.errorClose();
    }
    setCellView = (cellView) => {
        this.setState({ selectedView: cellView });
    }
    onTemplateSelected = (template = {}, callback) => {
        const { selectedView, funnel } = this.state;
        const currentPage = funnel.page.find((element) => {
            return element.cellViewId === selectedView.id;
        });
        const pageViewReferenceId = currentPage.pageView[0].referenceId;
        this.setState({ selectedTemplate: template, closeRightPanel: true }, () => {
            this.props.saveTemplatePageView({
                template,
                pageViewReferenceId,
                templateId: template.templateId,
                cellViewId: currentPage.cellViewId,
                funnelReferenceId: funnel.referenceId
            }).catch ((err) => {
                NotificationEmitter.emit(NOTIFICATION_EVENT_NAME, { type: "error", message: err.message });
            });
            if (isFunction(callback)) {
                callback();
            }
        });
    }
    getSourcePage = (cells, page) => {
        const selectedLink = cells.find(item => (item.type === "link") && (item.target.id === page.cellViewId));
        if (selectedLink) {
            const source = this.getCurrentPage(selectedLink && selectedLink.source);
            return source;
        }
    }
    onDeclareWinnerPopup = (page) => {
        this.setState({
            declareWinnerPopup: true,
            winnerPage: page
        });
    }
    onDeclareWinner = () => {
        const { winnerPage, visualizerJson } = this.state;
        const { funnel } = this.props;
        const sourcePage = this.getSourcePage(visualizerJson.cells, winnerPage);
        this.setState({
            declareWinnerPopup: false },() => this.props.declareWinner({ funnel, currentPage: winnerPage, sourcePage })
        );
    }
    onSplitTemplateChoose = (template, callback) => {
        const { selectedView,funnel, visualizerJson } = this.state;
        const currentPage = this.getCurrentPage(selectedView);
        const sourcePage = this.getSourcePage(visualizerJson.cells,currentPage);
        this.setState({
            showTemplates: false,
        }, async () => {
            this.child.current.closeRightPanel();
            await this.props.splitChooseTemplate({ funnel, currentPage, template, sourcePage })
                .catch(() => {});
            if (isFunction(callback)) {
                callback();
            }
        });
    }
    showPreview = (template) => {
        const win = window.open(template.htmlUrl, "_blank");
        win.focus();
    }
    showSplitSettings = (template) => {
        const splitSettingDetails = template;
        const { funnel } = this.props;
        const pageReferenceId = splitSettingDetails.referenceId;
        const funnelReferenceId = funnel.referenceId;
        this.setState({ showSplitSetting: true,
            splitSettingDetails: splitSettingDetails,
            isSelectedSplitPageDefaultPage: splitSettingDetails.pageView[0].defaultPage,
            splitPageName: splitSettingDetails.title
        }, () => this.props.getSavedfonts({ funnelReferenceId, pageReferenceId }).catch(() => {}));
        this.props.updateShowSplitSetting(true);
    }
    deletePage = (cellView) => {
        const { funnel } = this.state;
        const deleteView = cellView.saved;
        const currentPage = funnel.page.filter((element) => {
            return element.cellViewId === cellView.deleteView.id;
        });
        if (currentPage) {
            let pageReferenceIds = [];
            currentPage.map(item => {
                pageReferenceIds.push(item.referenceId);
            });
            this.props.deletePage({ funnel,pageReferenceIds, deleteView, isPageDeleted: cellView.isPageDeleted });
        }
    }
    savefunnel = (jsonFile) => {
        const { funnelReferenceId } = this.props;
        this.setState ({
            visualizerJson: jsonFile
        }, () => this.props.savefunnelGraph({ funnelReferenceId,jsonFile }));
    }
    getCurrentPage = (cellView) => {
        const { funnel } = this.props;
        const currentPage = funnel.page.find((element) => element.cellViewId === cellView.id);
        return currentPage;
    }

    navigateToBuilder = (cellView,evt, templateTypeId) => {
        const { updatedPage, selectedTemplate } = this.state;
        const { templates, htmlPage, jsonData, funnel, userTemplates, premiumTemplates } = this.props;
        let currentPage = (updatedPage && (updatedPage.cellViewId === cellView.id)) ?
            updatedPage :
            this.getCurrentPage(cellView);
        function search(nameKey, templateArray) {
            for (var i = 0; i < templateArray.length; i++) {
                if (templateArray[i].templateId === nameKey) {
                    return templateArray[i];
                }
            }
        }
        if (currentPage) {
            const tempId = currentPage.pageView[0].fTemplateId;
            if (tempId) {
                if (!currentPage.splitEnabled) {
                    const allTemplates = [
                        ...templates,
                        ...userTemplates,
                        ...premiumTemplates,
                    ];
                    const selectedTemplate = search(tempId, allTemplates);
                    this.props.history.push({
                        pathname: `/webbuilder/${funnel.referenceId}/${currentPage.referenceId}`,
                        state: { selectedTemplate: selectedTemplate, funnel: funnel, currentPage: currentPage, funnelReferenceId: funnel.referenceId, templateTypeId: templateTypeId }
                    });
                } else {
                    this.splitPopup(cellView);
                }
            } else if (!isEmpty(selectedTemplate)) {
                this.setState({ waitAlert: true });
            } else {
                evt.stopPropagation();
                this.setState({ editAlert: true });
            }
        } else {
            evt.stopPropagation();
            alert("This Page got Deleted from some other user you can't edit it, Please try some other Page");
        }
    }
    splitPopup = (selectedView) => {
        this.setState({
            showSplitEditPopup: true,
            selectedView: selectedView
        });
    }
    showSplitPreviewPopup = (selectedView) => {
        this.setState({
            showSplitPreviewPopup: true,
            selectedView: selectedView
        });
    }
    showSplitArrowPopup = (selectedView) => {
        this.setState({
            showSplitArrowPopup: true,
            selectedView: selectedView.sourceView.model,
            cellView: selectedView
        });
    }
    clonePage = (cellViewId) => {
        const { funnelReferenceId, funnel } = this.props;
        const sourcePagecellViewId = cellViewId.sourcePageId;
        const currentPage = funnel.page.find((element) => {
            return element.cellViewId === sourcePagecellViewId;
        });
        const pageReferenceId = currentPage.referenceId;
        const clonedPageCellviewId = cellViewId.clonedPageId;
        this.props.clonePage({ pageReferenceId, clonedPageCellviewId, funnelReferenceId })
            .catch(err => {
                NotificationEmitter.emit(NOTIFICATION_EVENT_NAME, { type: "error", message: err.message, closeButton: true });
            });
    }
    getSavedfonts = (cellView) => {
        const { funnel } = this.props;
        const currentPage = cellView.splitEnabled ? cellView : funnel.page.find((element) => {
            return element.cellViewId === cellView.id;
        });
        const pageReferenceId = currentPage.referenceId;
        const funnelReferenceId = funnel.referenceId;
        this.props.getSavedfonts({ funnelReferenceId, pageReferenceId });
    }
    handleClose = () => {
        this.setState({
            editAlert: false,
            publishAlert: false,
            waitAlert: false,
            showSplitEditPopup: false,
            showSplitPreviewPopup: false,
            showSplitArrowPopup: false,
            showShareAlert: false,
            showFunnelShareAlert: false,
            declareWinnerPopup: false,
            isDefaultPageSelected: false,
            allPagesEditedCheck: false,
            allPagesTemplateNotSelected: false,
            showAddNewDomainPopup: false,
            domainName: "",
            showValidationError: false,
            isAnyArrowNotLinked: false,
            isSelectedDomainVerified: false,
            isFunnelEmpty: false
        });
    }
    closeSuccessMessage = () => {
        this.props.toggleCRMEntryModal(true);
        this.props.showSuccessMessage(false);
    }
    changeTitle = (pageTitle, selectedData) => {
        const { funnel, splitSettingDetails } = this.state;
        if (splitSettingDetails) {
            const currentPage = selectedData;
            this.props.editPage({ funnel,pageTitle, currentPage })
                .catch(() => {});
        } else {
            const currentElement = selectedData.model;
            const currentPage = funnel.page.find((element) => {
                return element.cellViewId === currentElement.id;
            });
            this.props.editPage({ funnel,pageTitle, currentPage })
                .catch(() => {});
        }
    }
    saveZoom = (zoomValue, layoutZoom, graphZoom) => {
        const { funnel } = this.state;
        this.props.editFunnel({ funnel, zoomValue, layoutZoom, graphZoom });
    }
    copyShareId = () => {
        this.setState({ isCopied: true }, () => {
            const text = document.querySelector("#shareFunnelId");
            text.select();
            document.execCommand("copy");
            text.blur();
            setTimeout(() => {this.setState({ isCopied: false });}, 2000);
        });
    }
    handleImportShareIdChange = (e) => {
        this.setState({ importShareFunnelId: e.target.value });
    }
    importShareId = () => {
        const { funnel } = this.props;
        const referenceId = funnel.referenceId;
        const isTempalate = false;
        const isFunnelBucket = true;
        const importId = document.querySelector("#importFunnelId").value;
        if ((importId) && (importId !== funnel.shareId)) {
            this.props.importFunnelFromShareId({ referenceId, importId })
                .then(this.props.getFunnelData(this.props.funnelReferenceId))
                .catch(err => NotificationEmitter.emit(NOTIFICATION_EVENT_NAME, { type: "error", message: err.message }));
        } else {
            this.setState({ isShareIdError: true });
        }
    }
    updateFunnelData = (importId,isFunnelTemplate,isPremium) => {
        const { funnel } = this.props;
        const referenceId = funnel.referenceId;
        if ((importId) && (importId !== funnel.referenceId)) {
            this.props.importFunnel({ referenceId, importId, isFunnelTemplate,isPremium })
                .then(this.props.getFunnelData(this.props.funnelReferenceId))
                .catch(err => {
                    NotificationEmitter.emit(NOTIFICATION_EVENT_NAME, { type: "error", message: err.message });
                });
        } else {
            this.setState({ isShareIdError: true });
        }
    }
    saveFonts = (fontData) => {
        this.props.saveFonts(fontData);
    }
    splitDuplicatePage = (params) => {
        const { funnel, visualizerJson } = this.state;
        const currentElement = params.selectedData.model;
        const pageTitle = params.pageTitle;
        const currentPage = this.getCurrentPage(currentElement);
        const sourcePage = this.getSourcePage(visualizerJson.cells, currentPage);
        this.props.splitDuplicatePage({ funnel,pageTitle, currentPage, sourcePage });
    }
    onChangeDomainSelection = (e) => {
        const selectedDomainForFunnel = String(e.target.value);
        const selectedDomainAndFunnelId = {};
        if (selectedDomainForFunnel !== "defaultDomain" && selectedDomainForFunnel !== "addNewDomain") {
            this.setState({ selectedDomainForFunnel: selectedDomainForFunnel });
            selectedDomainAndFunnelId.domainReferenceId = selectedDomainForFunnel;
            selectedDomainAndFunnelId.funnelReferenceId = this.props.funnelReferenceId;
            this.props.setDomainForFunnel(selectedDomainAndFunnelId);
        } else if (selectedDomainForFunnel === "defaultDomain") {
            selectedDomainAndFunnelId.domainReferenceId = this.state.selectedDomainForFunnel;
            selectedDomainAndFunnelId.funnelReferenceId = null;
            this.props.setDomainForFunnel(selectedDomainAndFunnelId);
        } else if (selectedDomainForFunnel === "addNewDomain") {
            this.setState(prevState => ({ showAddNewDomainPopup: true }));
        }
    }
    changeDefaultPage = (currentDefaultPageViewReferenceId, isDefaultPage, isSplitEnabled) => {
        const { funnel } = this.state;
        !isDefaultPage && this.props.editFunnel({ funnel, currentDefaultPageViewReferenceId, isSplitEnabled });
    }
    showTemplatesList = () => {
        this.setState(prevState => ({
            showTemplates: !prevState.showTemplates,
            isSplitEnabled: true
        }));
    }
    closeTemplatesList = () => {
        this.setState(prevState => ({
            showTemplates: false,
        }));
    }
    splitNavigate = (template) => {
        const { funnel } = this.props;
        const splitPages = this.getSplitPages();
        const currentPage = template.page;
        const selectedTemplate = template.splitTemplates;
        const pageViewRefereceId = currentPage && currentPage.pageView[0].referenceId;
        const componentJsonUrl = currentPage && currentPage.pageView[0].componentJson;
        const stylesJsonUrl = currentPage && currentPage.pageView[0].stylesJson;
        const htmlUrl = currentPage && currentPage.pageView[0].url;
        this.props.history.push({
            pathname: "/webbuilder",
            state: { selectedTemplate: selectedTemplate, funnel: funnel, currentPage: currentPage && currentPage, funnelReferenceId: funnel.referenceId, templateTypeId: template.templateTypeId }
        });

    }
    splitPreview = (template) => {
        const { funnel } = this.props;
        const splitPages = this.getSplitPages();
        const currentPage = template.page;
        const previewUrl = currentPage.pageView[0].url;
        window.open(previewUrl,"_blank");
    }
    splitArrowConfig = (template) => {
        const { funnel } = this.props;
        const { selectedView, cellView } = this.state;
        const splitPages = this.getSplitPages();
        const currentPage = template.page;
        this.setState({
            showSplitArrowPopup: false
        },() => this.child.current.linkSettings(currentPage, cellView));
    }
    getSplitPages = () => {
        const { selectedView } = this.state;
        const { funnel } = this.props;
        return funnel.page && funnel.page.filter((element) => {
            return element.cellViewId === selectedView.id;
        });
    }
    checkSplitPageInFunnel = () => {
        const { funnel } = this.props;
        return funnel.page && funnel.page.filter((page) => page.active && page.splitEnabled);
    }
    setSplitPercent = (params) => {
        const { funnel } = this.props;
        const { selectedView } = this.state;
        const pageValue1 = params.pageValue2;
        const pageValue2 = params.pageValue1;
        const currentPage = this.getSplitPages();
        const firstPage = currentPage && currentPage[0];
        const secondPage = currentPage && currentPage[1];
        this.props.setSplitPercentToPage({ pageValue1, pageValue2, funnel, firstPage, secondPage });
    }
    getAlphabet = (index) => {
        return (index + 10).toString(36).toUpperCase();
    }
    closeNewDomainPopup = () => {
        this.setState({ showAddNewDomainPopup: false, showValidationError: false, domainName: "" });
    }
    updateDomainNameState = (event) => {
        const name = event.target.value;
        this.setState({ domainName: name, showValidationError: false });
    }
    saveNewDomain = () => {
        const name = this.state.domainName;
        const fullDomainName = "www." + name;
        const { domainList } = this.state;
        const isDomainNameAlreadyExist = domainList &&
            !!domainList
                .filter((domain) => domain.name === fullDomainName)
                .length;
        if (name.indexOf(".") !== -1 && !/\s/.test(name) && !isDomainNameAlreadyExist) {
            const selectedDomainAndFunnelId = {};
            selectedDomainAndFunnelId.domainName = name;
            selectedDomainAndFunnelId.funnelReferenceId = this.props.funnelReferenceId;
            this.props.addAndSetDomainForFunnel(selectedDomainAndFunnelId);
            this.setState({ showAddNewDomainPopup: false,
                domainName: "",
                showValidationError: false,
                showEditDomainPopUp: true,
                editDomainDetails: this.props.getLatestAddedDomain,
                selectedDomainForFunnelError: this.props.selectedDomainForFunnelError
            });
        } else {
            this.setState({ showValidationError: true });
        }
    }
    formatText = (text) => {
        if (text) return text
            .replace(/(\B)[^ ]*/g,match => (match.toLowerCase()))
            .replace(/^[^ ]/g,match => (match.toUpperCase()));
    }
    closeEditDomainPopup = () => {
        const editDomainDetails = this.state.editDomainDetails;
        this.setState({ showEditDomainPopUp: false, editDomainDetails: {} });
    }
    render() {
        const { templates,isLoading, funnels, isError, importPage,funnel,fontList, fonts, isFontLoading, isImportFunnelLoading, showImportFunnelSuccessMessage, importFunnel, getDefaultFunnels, pageData, importFunnelError } = this.props;
        const { selectedTemplate,
            publishAlert,
            editAlert,
            editedPage,
            metaJson,
            domainList,
            selectedDomainForFunnel,
            savedFonts,
            isShowShare,
            isShareIdError,
            isCopied,
            importShareFunnelId,
            selectedView,
            showTemplates,
            showSplitEditPopup,
            showSplitPreviewPopup,
            showSplitSetting,
            splitSettingDetails,
            isSelectedSplitPageDefaultPage,
            splitPageName,
            showSplitArrowPopup,
            selectedSplitPage,
            showFunnelShareAlert,
            shareAlertMessage,
            allPagesEditedCheck,
            isDefaultPageSelected,
            allPagesTemplateNotSelected,
            showAddNewDomainPopup,
            domainName,
            showValidationError,
            showEditDomainPopUp,
            editDomainDetails,
            isLoadingDomain,
            isAnyArrowNotLinked,
            isSelectedDomainVerified,
            selectedDomainForFunnelError,
            isFunnelEmpty,
            pageToPurchase,
        } = this.state;
        const { addYourOwnDomain,
            domainLabel,
            wwwLabel,
            domainNamePlaceholder,
            invalidDomainName,
            addDomainLabel,
            editDomainTitle,
            nameTitle,
            typeTitle,
            valueTitle,
            noteToAddCnameRecords,
            noteForCnameRecordChange,
            noteForSSLCertGeneration,
            domainStatusLabel,
            sslStatusLabel } = constants;
        const settingsToggleClass = this.state.showSettings ? "funnelSettings active" : "funnelSettings";
        const checkSplitPageInFunnel = this.checkSplitPageInFunnel();
        const templateList = templates ? templates.map((template) => <TemplateCard key={template.templateId} template={template} onTemplateSelected={ this.state.isSplitEnabled ? this.onSplitTemplateChoose : this.onTemplateSelected} showPreview={this.showPreview}/>)
            : [];
        const splitPages = this.getSplitPages();
        const shareClass = isShowShare ? "share-funnel active" : "share-funnel";
        const splitPageList = splitPages && splitPages ? splitPages.map((page,index) => {
            const splitTemplates = templates.find(item => item.templateId === page.pageView[0].fTemplateId);
            const pageIndex = this.getAlphabet(index);
            return <SplitViewCard splitTemplates = {splitTemplates} pageIndex = {pageIndex} key={page.referenceId} page={page} onDeclareWinner={this.onDeclareWinnerPopup} showSplitSettings={this.showSplitSettings}/>;
        }) : [];
        const splitPagesThumbnail = splitPageList && splitPageList.map((item) => {
            return item.props;

        });
        const copyMessage = isCopied ? "copy-message active" : "copy-message";
        const { importFunnelIdLabel, shareDiffferentFunnelIdMessage, enterFunnelId, shareFunnelNote, shareIdCopiedMessage, importFunnelLabel, copyLabel, emptyFunnelShareAlert } = constants;
        const defaultFontValue = savedFonts && savedFonts.map(item => {
            const filtered = fontList && fontList.items.filter(font => {
                return font.family === item.fontFamily;
            });
            return filtered && filtered[0].family;
        });
        const createDomainModal = <CreateDomainModal
            showAddNewDomainPopUp = {showAddNewDomainPopup}
            closeNewDomainPopup = {this.closeNewDomainPopup}
            updateDomainNameState = {this.updateDomainNameState}
            domainName = {domainName}
            saveNewDomain = {this.saveNewDomain}
            showValidationError={showValidationError}
            addYourOwnDomain = {addYourOwnDomain}
            domainLabel = {domainLabel}
            wwwLabel = {wwwLabel}
            domainNamePlaceholder = {domainNamePlaceholder}
            invalidDomainName = {invalidDomainName}
            addDomainLabel = {addDomainLabel}
        />;
        const editDomainModal = <EditDomainModal
            showEditDomainPopUp = {showEditDomainPopUp}
            closeEditDomainPopup= {this.closeEditDomainPopup}
            editDomainDetails={editDomainDetails && editDomainDetails}
            cnameRecords = {editDomainDetails && editDomainDetails.cnameRecords}
            formatText = {this.formatText}
            editDomainTitle = {editDomainTitle}
            nameTitle = {nameTitle}
            typeTitle = {typeTitle}
            valueTitle = {valueTitle}
            noteToAddCnameRecords = {noteToAddCnameRecords}
            noteForCnameRecordChange = {noteForCnameRecordChange}
            noteForSSLCertGeneration = {noteForSSLCertGeneration}
            domainStatusLabel = {domainStatusLabel}
            sslStatusLabel = {sslStatusLabel}
        />;
        return <>
            {isLoading &&
                <div className="loader">
                    {/* <i className="fa fa-spinner fa-pulse fa-lg m-r-xs" /> */}
                </div>
            }
            {/* {isLoading &&
                <div className="save-message-box"><i className="fa fa-spinner fa-pulse fa-lg m-r-xs pull-left" /><span className="pull-left">Saving ...</span></div>
            } */}
            <div className="row dashboard-wrapper relative">
                <div className={shareClass}>
                    <span className="pointer pull-right" onClick={this.shareFunnel}><i class="fa fa-close" aria-hidden="true"></i></span>
                    {showFunnelShareAlert ? <h3>{shareAlertMessage}</h3> : <><div className={copyMessage}>{shareIdCopiedMessage}</div>
                        <TextInput
                            name="shareFunnelId"
                            label= { "Share Funnel Id"}
                            value={funnel.shareId}
                            id="shareFunnelId"
                            onClick={this.modifylabel}
                            note={shareFunnelNote}
                        />
                        <div className="text-right"><button className="btn btn-primary" onClick={this.copyShareId}><i class="fa fa-clone m-r-xs" aria-hidden="true"></i> {copyLabel}</button></div>
                    </>}
                    <hr className="m-b-xs" />
                    <hr className="m-t-xs" />
                    <TextInput
                        name="importFunnelId"
                        label={importFunnelIdLabel}
                        value={importShareFunnelId}
                        onChange={this.handleImportShareIdChange}
                        id="importFunnelId"
                        placeholder = {"Enter Share Id here"}
                        error = {isShareIdError ? true : false}
                        errorMessage = { (importShareFunnelId === funnel.shareId) ? shareDiffferentFunnelIdMessage : enterFunnelId }
                    />
                    <div className="text-right"><button className="btn btn-primary" onClick={this.importShareId}><i class="fa fa-download m-r-xs" aria-hidden="true"></i>{importFunnelLabel}</button></div>
                </div>
                <div className={settingsToggleClass}>
                    {/* {this.state.showSettings && */}
                    <FunnelForm isDefaultOptionEnabled={true} isEdit={false} funnel={this.state.funnel} onChange={this.updateFunnelState} onSave={this.validate} onCancel={this.cancel} allAuthors={this.props.authors} allCampaigns={this.props.campaigns} funnelType={this.props.funnelType} errors={this.state.errors} allOffers={this.state.allOffers} allUpsales={this.state.allUpsales} addOfferField={this.addOfferField} onOfferChange={this.onOfferChange} addUpsaleField={this.addUpsaleField} onUpsaleChange={this.onUpsaleChange} removeOfferField={this.removeOfferField} removeUpsaleField={this.removeUpsaleField} onCampaignChange={this.onCampaignChange} isLoading={isLoading} isError={isError} />
                    {/* } */}
                </div>
                <Visualizer
                    ref={this.child}
                    metaJson={metaJson}
                    navigate={this.navigateToBuilder}
                    funnel={funnel}
                    funnels={funnels}
                    editFunnel={this.toggleSettingsView}
                    showTemplatesList= {this.showTemplatesList}
                    closeTemplatesList= {this.closeTemplatesList}
                    showTemplates= {showTemplates}
                    createNewPage={this.createNewPage}
                    savefunnel={this.savefunnel}
                    deletePage={this.deletePage}
                    setCellView={this.setCellView}
                    selectedTemplate={selectedTemplate}
                    templateList={templateList}
                    splitPageList ={splitPageList}
                    loadButtons={this.props.loadButtons}
                    pageData={this.props.pageData}
                    saveButtonData={this.props.saveButtonData}
                    savePageViewData={this.props.savePageViewData}
                    clearPageData={this.props.clearPageData}
                    templates={this.props.templates}
                    publishFunnel={this.publishFunnel}
                    shareFunnel={this.shareFunnel}
                    closeRightPanel={this.state.closeRightPanel}
                    isError={this.state.isError}
                    pageError={this.props.pageError}
                    importError={this.props.importError}
                    titleChange = {this.changeTitle}
                    saveFonts = {this.saveFonts}
                    setZoomValue = {this.saveZoom}
                    copyPage = {this.clonePage}
                    getSavedfonts = {this.getSavedfonts}
                    importPage = {importPage}
                    updatedPage = {this.state.updatedPage}
                    thumbnailUrl = {this.state.selectedTemplate.thumbnailUrl}
                    fontList = {fontList}
                    savedFonts = {savedFonts}
                    defaultFontValue = {defaultFontValue && defaultFontValue}
                    domainList = {domainList}
                    isFontLoading = {isFontLoading}
                    isImportFunnelLoading = {isImportFunnelLoading}
                    getDefaultFunnels={getDefaultFunnels}
                    updateFunnelData={this.updateFunnelData}
                    defaultFunnelTemplates={this.props.defaultFunnelTemplates}
                    selectedDomainForFunnel = {selectedDomainForFunnel}
                    onChangeDomainSelection = {this.onChangeDomainSelection}
                    changeDefaultPage = {this.changeDefaultPage}
                    updatedFunnel = {this.state.updatedFunnel}
                    splitDuplicatePage = {this.splitDuplicatePage}
                    onSplitTemplateChoose = {this.onSplitTemplateChoose}
                    showSplitPreviewPopup = {this.showSplitPreviewPopup}
                    showSplitArrowPopup = {this.showSplitArrowPopup}
                    setSplitPercent = {this.setSplitPercent}
                    splitPages = {splitPages}
                    showSplitSetting={showSplitSetting}
                    splitSettingDetails={splitSettingDetails}
                    updateShowSplitSetting={this.props.updateShowSplitSetting}
                    isSelectedSplitPageDefaultPage={isSelectedSplitPageDefaultPage}
                    splitPageName= {splitPageName}
                    splitArrowConfig = {this.splitArrowConfig}
                    selectedSplitPage = {selectedSplitPage}
                    openFunnelTemplatesOnLoad={this.props.openFunnelTemplatesOnLoad}
                    getAnalyticsData={this.props.getAnalyticsData}
                    premiumFunnelTemplates={this.props.premiumFunnelTemplates}
                    getPremiumFunnelTemplates={this.props.getPremiumFunnelTemplates}
                    loadFunnels={this.props.loadFunnels}
                    loadTemplates={this.props.loadTemplates}
                    funnelTemplateTypeIdToPrepopulate={this.props.funnelTemplateTypeIdToPrepopulate}
                    templateSelectionConfig={this.templateSelectionConfig}
                    purchaseOrder={this.props.purchaseOrder}
                    userData={this.props.userData}
                    canAccessPremiumTemplates={this.props.canAccessPremiumTemplates}
                />
            </div>
            <Modal show={this.state.editAlert} backdrop="static" onHide={this.handleClose} bsSize="" aria-labelledby="contained-modal-title-lg">
                <Modal.Header closeButton style={{ backgroundColor: "rgb(47, 64, 77)" }}>
                    <Modal.Title><h3 className="text-white">Information</h3></Modal.Title>
                </Modal.Header>
                <Modal.Body className="m-n">
                    <h4 className="text-center">{constants.editAlert}</h4>
                </Modal.Body>
                <Modal.Footer>
                    <a className="btn btn-primary" onClick={this.handleClose}>Ok</a>
                </Modal.Footer>
            </Modal>
            <Modal show={publishAlert} backdrop="static" onHide={this.handleClose} bsSize="" aria-labelledby="contained-modal-title-lg">
                <Modal.Header closeButton style={{ backgroundColor: "rgb(47, 64, 77)" }}>
                    <Modal.Title><h3 className="text-white">Warning</h3></Modal.Title>
                </Modal.Header>
                <Modal.Body className="m-n">
                    { isFunnelEmpty &&
                        <h4 className="text-center">An empty funnel cannot be published.</h4>
                    }
                    { !isFunnelEmpty && allPagesTemplateNotSelected &&
                        <h4 className="text-center">{constants.selectAllPagesTemplateAlert}</h4>
                    }
                    { !isDefaultPageSelected && allPagesEditedCheck && !allPagesTemplateNotSelected && !isFunnelEmpty &&
                        <h4 className="text-center">{constants.defaultPageSelectAlert}</h4>
                    }
                    { !allPagesEditedCheck && isDefaultPageSelected && !allPagesTemplateNotSelected && !isFunnelEmpty &&
                        <h4 className="text-center">{constants.EditAllPagesAlert}</h4>
                    }
                    { !isDefaultPageSelected && !allPagesEditedCheck && !allPagesTemplateNotSelected && !isFunnelEmpty &&
                        <h4 className="text-center">{constants.EditAllPagesAlert}</h4>
                    }
                    { allPagesEditedCheck && isDefaultPageSelected && !allPagesTemplateNotSelected && isAnyArrowNotLinked && !isFunnelEmpty &&
                        <h4 className="text-center">{constants.setPageLinks}</h4>
                    }
                    { allPagesEditedCheck && isDefaultPageSelected && !allPagesTemplateNotSelected && !isAnyArrowNotLinked && !isSelectedDomainVerified && !isFunnelEmpty &&
                        <h4 className="text-center">{constants.isSelectedDomainVerified}</h4>
                    }
                </Modal.Body>
                <Modal.Footer>
                    <a className="btn btn-primary" onClick={this.handleClose}>Ok</a>
                </Modal.Footer>
            </Modal>
            <Modal show={this.state.declareWinnerPopup} backdrop = "static" onHide={this.handleClose} bsSize="" aria-labelledby="contained-modal-title-lg">
                <Modal.Header closeButton style={{ backgroundColor: "rgb(47, 64, 77)" }}>
                    <Modal.Title><h3 className="text-white">Confirmation</h3></Modal.Title>
                </Modal.Header>
                <Modal.Body className="m-n">
                    <h4 className="text-center">{constants.declareWinnerAlert}</h4>
                </Modal.Body>
                <Modal.Footer>
                    <a className="btn btn-primary" onClick = {this.onDeclareWinner}>Yes</a>
                    <a className="btn btn-default" onClick = {this.handleClose}>No</a>
                </Modal.Footer>
            </Modal>
            <Modal show={this.state.waitAlert} backdrop = "static" onHide={this.handleClose} bsSize="" aria-labelledby="contained-modal-title-lg">
                <Modal.Header closeButton style={{ backgroundColor: "rgb(47, 64, 77)" }}>
                    <Modal.Title><h3 className="text-white">Information</h3></Modal.Title>
                </Modal.Header>
                <Modal.Body className="m-n">
                    <h4 className="text-center">{constants.waitAlert}</h4>
                </Modal.Body>
                <Modal.Footer>
                    <a className="btn btn-primary" onClick={this.handleClose}>Ok</a>
                </Modal.Footer>
            </Modal>
            <Modal show={showImportFunnelSuccessMessage} backdrop = "static" onHide={this.closeSuccessMessage} bsSize="" aria-labelledby="contained-modal-title-lg">
                <Modal.Header closeButton style={{ backgroundColor: "rgb(47, 64, 77)" }}>
                    <Modal.Title><h3 className="text-white">Success</h3></Modal.Title>
                </Modal.Header>
                <Modal.Body className="m-n">
                    <h5 className="text-center">{constants.publishAlertMessage1}</h5>
                    <h5 className="text-center">{constants.publishAlertMessage2}</h5>
                </Modal.Body>
                <Modal.Footer>
                    <a className="btn btn-primary" onClick={this.closeSuccessMessage}>Ok</a>
                </Modal.Footer>
            </Modal>
            <Modal show={showSplitEditPopup || showSplitPreviewPopup || showSplitArrowPopup} backdrop = "static" onHide={this.handleClose} bsSize="" aria-labelledby="contained-modal-title-lg">
                <Modal.Header closeButton style={{ backgroundColor: "rgb(47, 64, 77)" }}>
                    <Modal.Title><h3 className="text-white">{showSplitEditPopup ? "Choose page to Edit" : showSplitArrowPopup ? "Choose page to configure Buttons/Links" : "Choose page to Preview"} </h3></Modal.Title>
                </Modal.Header>
                <Modal.Body className="m-n">
                    <div className="split-thumbnail">
                        {splitPagesThumbnail && splitPagesThumbnail.map((props,index) => {
                            const alphabet = this.getAlphabet(index);
                            return (
                                <div>
                                    <h2 className="text-center font-bold">
                                        {alphabet && alphabet}
                                    </h2>
                                    <a onClick = {() => showSplitEditPopup ?
                                        this.splitNavigate(props) :
                                        showSplitArrowPopup ?
                                            this.splitArrowConfig(props) :
                                            this.splitPreview(props)
                                    }
                                    className={
                                        props.page && props.page.isEdited ?
                                            "split-thumbnail-img" :
                                            "split-thumbnail-img split-unedited"
                                    }
                                    style={{ backgroundImage: `url(${props && props.page && props.page.thumbnailUrl ?
                                        props.page.thumbnailUrl :
                                        props.splitTemplates && props.splitTemplates.thumbnailUrl })`
                                    }}>
                                    </a>
                                </div>
                            );
                        })}
                    </div>
                </Modal.Body>
            </Modal>
            {
                pageToPurchase && (
                    <PurchaseConfirmation
                        onClose={() => {
                            this.setState({ pageToPurchase: null });
                        }}
                        onPurchase={this.purchasePage}
                        price={pageToPurchase.price}
                        type="Page"
                    />
                )
            }
            {createDomainModal}
            {editDomainModal}
        </>;
    }
}
