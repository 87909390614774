import assetEvents from "./asset";
import styleEvents from "./style";
import componentEvents from "./component";

export const events = {
    ...assetEvents,
    ...styleEvents,
    ...componentEvents,
};

