import React,{ useState } from "react";
import PropTypes from "prop-types";
import { ConfirmationModal } from "./confirmation";
import { formatPriceUptoTwoDecimal } from "../../utilities/formatters";

const PurchaseConfirmation = ({
    onClose,
    onPurchase,
    price,
    type
}) => {
    let ModalComponent = null;
    const templatePrice = formatPriceUptoTwoDecimal(price);
    const [activeStep, setActiveStep] = useState(1);
    if (activeStep === 1) {
        ModalComponent = (
            <ConfirmationModal
                className="modal-top-spacer"
                key="purchase-confirm-step-1"
                onCancel={onClose}
                title={`Do you want to purchase this ${type} for ${templatePrice}?`}
                header={`Purchase ${type}`}
                onSubmit={() => setActiveStep(2)}
                buttonOkText="Yes"
                buttonCancelText="No"
            />
        );
    } else if (activeStep === 2) {
        ModalComponent = (
            <ConfirmationModal
                className="modal-top-spacer"
                key="purchase-confirm-step-2"
                onCancel={onClose}
                title={`The card on file with your CRM subscription will be charged ${templatePrice} for this ${type}`}
                header={`Purchase ${type}`}
                onSubmit={onPurchase}
                buttonOkText="Purchase"
                buttonCancelText="Cancel"
            />
        );
    }
    return ModalComponent;
};

PurchaseConfirmation.propTypes = {
    onClose: PropTypes.func,
    onPurchase: PropTypes.func,
    price: PropTypes.number,
    type: PropTypes.string
};

PurchaseConfirmation.defaultProps = {
    onClose: () => {},
};

export default PurchaseConfirmation;
