import React, { Component } from "react";
import PropTypes from "prop-types";
import { isNumber } from "underscore";
import { TemplateTypeSelectDropdown } from "./template-type-select-dropdown";
import { TemplateCard } from "../templates/template-card";
import { PageTypes } from "../constants/template-types";

export class AddNewTemplate extends Component {
    constructor(props) {
        super(props);
        this.generateTemplateList = this.generateTemplateList.bind(this);
        this.onDropdownChange = this.onDropdownChange.bind(this);
        this.onNewTemplateSelected = this.onNewTemplateSelected.bind(this);
        this.generateTemplateCard = this.generateTemplateCard.bind(this);
        this.templateTypeMap = PageTypes.pageTypesMap;
        this.state = {
            selectedTemplateType: this.templateTypeMap[0],
            showRequired: false,
            animationClass: "",
        };
        this.titleText = null;
    }

    componentWillMount() {
        setTimeout(() => {
            this.setState({
                animationClass: "slideInRight active"
            });
        }, 100);
    }

    generateTemplateCard(template) {
        return (
            <TemplateCard
                key={template.templateId}
                template={template}
                onTemplateSelected={this.onNewTemplateSelected}
            />
        );
    }

    generateTemplateList() {
        const {
            templates,
        } = this.props;
        const {
            selectedTemplateType,
        } = this.state;
        const { templateTypeId: selectedTemplateTypeId } = selectedTemplateType;
        let filteredTemplateList = [];
        if (templates && isNumber(selectedTemplateType.templateTypeId)) {
            let blankTemplate = templates.find((template) => template.templateTypeId === 6);
            filteredTemplateList.push(this.generateTemplateCard(blankTemplate));
            templates.forEach((template = {}) => {
                const {
                    templateTypeId,
                    active,
                } = template;
                if (
                    active &&
                    ((selectedTemplateTypeId === 0 && templateTypeId !== 6) || (templateTypeId === selectedTemplateTypeId))
                ) {
                    filteredTemplateList.push(this.generateTemplateCard(template));

                }
            });
        }
        return (filteredTemplateList.length ? filteredTemplateList : <div className="" />);
    }

    onNewTemplateSelected(template) {
        const {
            onNewTemplateSelected,
        } = this.props;
        const {
            selectedTemplateType,
        } = this.state;
        let overrides = {
            referenceId: null,
            templateTypeName: selectedTemplateType.name,
        };
        if (selectedTemplateType && selectedTemplateType.templateTypeId) {
            overrides.templateTypeId = selectedTemplateType.templateTypeId;
            overrides.templateTypeName = selectedTemplateType.templateTypeName;
        }
        const newTemplate = Object.assign({},
            template,
            overrides,
        );
        onNewTemplateSelected(newTemplate);
    }

    onDropdownChange(selectedTemplateType) {
        this.setState({ selectedTemplateType });
    }

    render() {
        const {
            animationClass,
        } = this.state;
        const {
            closeAddNewTemplate,
        } = this.props;
        return (
            <div className={`add-new-template-section rightPanel clearfix ${animationClass}`} id="addrightPanel">
                <div className="pointer text-right page-template-close-icon" onClick={closeAddNewTemplate}>
                    <i className="fa fa-close" aria-hidden="true" />
                </div>
                <div className="col-xs-12 panel-content">
                    <h2 className="m-b-md">Add New Template</h2>
                    <div className="col-xs-12 no-padding full-height">
                        <TemplateTypeSelectDropdown
                            label="Template Type:"
                            onDropdownChange={this.onDropdownChange}
                        />
                        {
                            this.generateTemplateList()
                        }
                    </div>
                </div>
            </div>
        );
    }
}

AddNewTemplate.propTypes = {
    templates: PropTypes.array,
    onNewTemplateSelected: PropTypes.func,
    templateTypeMap: PropTypes.array,
    closeAddNewTemplate: PropTypes.func,
    fromSettings: PropTypes.bool,
    template: PropTypes.object,
    updateStatus: PropTypes.func,
    openInfo: PropTypes.func,
};