import {
    arrowAttributes
} from "./default-element-properties";
import {
    getPageDetails
} from "./json-builder-helper";

export const generateLinkModel = (link) => {
    return {
        id: link.id,
        source: getPageDetails(link.sourcePageCellViewId),
        target: getPageDetails(link.targetPageCellViewId),
        attrs: arrowAttributes(link.color),
        type: link.type,
        z: link.z,
        color: link.color
    };
};