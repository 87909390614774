import React, { Component } from "react";
import { Button, Row, Col } from "react-bootstrap";
import PropTypes from "prop-types";

import Forms from "../../Forms";
import { getFulfillmentHouseList } from "../../utilities/api";

class FulfillmentHouse extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: false,
            activeList: null,
            type: null,
            fulfillmentDetails: null,
            fulfillmentHouseList: null
        };
    }

    componentDidMount() {
        this.loadList();
    }

    loadList = () => {
        this.setState({
            isLoading: true
        });
        getFulfillmentHouseList()
            .then((res) => {
                this.setState({
                    fulfillmentHouseList: Array.isArray(res.message) ? res.message.filter(data => data.uniqueFields !== null) : null,
                    fulfillmentDetails: null,
                    isLoading: false
                });
            })
            .catch((err) => {
                this.setState({
                    isLoading: false
                });
            });
    }

    activeComponent = (status, type, fulfillmentDetails) => {
        this.setState({
            activeList: status,
            type: type,
            fulfillmentDetails
        });
    }

    clearActiveList = () => {
        this.setState({
            activeList: null
        });
    }

    renderRow = (fulfillmentHouseList) => {
        return fulfillmentHouseList && fulfillmentHouseList.map((fulfillmentHouse, i) => (
            <tr className="edit-funnel-divider">
                <td className="edit-funnel-table-data font-weight-normal">{fulfillmentHouse.fulfillmentHouseTitle}</td>
                <td>
                    <Button
                        type="primary btn-height"
                        className="button"
                        disabled={this.state.isLoading}
                        onClick={() => this.activeComponent("CreateEditFulfillment", "Edit", fulfillmentHouse)}
                    >
                        <i class="fa fa-pencil edit-pencil-color"></i>
                    </Button>
                </td>
            </tr>
        ));
    }

    render() {
        const { activeList, fulfillmentHouseList, fulfillmentDetails, isLoading } = this.state;
        return (
            <div>
                <div className="modal-header-funnel-edit">
                    Fulfillment House List
                </div>
                <div className="table-overflow-scroll">
                    <Row className="p-30 pb-0">
                        <Col mdOffset={8} md={4} className="p-0">
                            <Button
                                type="primary"
                                className="button btn-height primary-button-color float-right"
                                onClick={() => this.activeComponent("CreateEditFulfillment", "ADD")}
                            >
                                <div className="button_text_area">
                                    <span>Add New Fulfillment House</span>
                                </div>
                            </Button>
                        </Col>
                    </Row>
                    <Row className="p-30">
                        <table className="edit-funnel-table">
                            <thead>
                                <tr className="edit-funnel-table-head">
                                    <th className="edit-funnel-merchant-table-heading">Title</th>
                                    <th></th>
                                </tr>
                            </thead>

                            <tbody>
                                { isLoading ?
                                    <tr className="position_absolute_flex_center">
                                        <div className={"loading_box"}>
                                            <span
                                                className="loading_spinner"
                                            ></span>
                                            <span>Loading</span>
                                        </div>
                                    </tr> :
                                    fulfillmentHouseList && fulfillmentHouseList.length !== 0 ? this.renderRow(fulfillmentHouseList) :
                                        <tr className="edit-funnel-divider">
                                            <td colSpan={2} className="edit-funnel-table-data">No Fulfillment House Found</td>
                                        </tr>
                                }
                            </tbody>
                        </table>
                    </Row>
                </div>
                <Row className="p-30">
                    <Col md={6} className="pl-0">
                    </Col>
                    <Col md={6} className="pr-0">
                        <Button
                            onClick={(e) => {this.props.closeModal(e);}}
                            type="primary"
                            className="button btn-height float-right"
                        >
                            Cancel
                        </Button>
                    </Col>
                </Row>

                <Forms refreshFulfillmentList={this.loadList} fulfillmentDetails={fulfillmentDetails} activeComponent={activeList} clearActiveList={this.clearActiveList} title={`${this.state.type === "ADD" ? "Create New" : "Edit"} Fulfillment House`} />
            </div>
        );
    }
}

export default FulfillmentHouse;

FulfillmentHouse.propTypes = {
    closeModal: PropTypes.func
};