/*eslint-disable*/
import grapesjs from 'grapesjs';
import { LocalStorageHelper } from "../utilities";
import { webscraperUrl } from "../api/urls";


export default grapesjs.plugins.add('gjs-plugin-import-clickfunnels', (editor, opts) => {
    let c = opts || {};
    let config = editor.getConfig();
    let pfx = config.stylePrefix;


    // Add command

    const modal = editor.Modal;
    const cmdm = editor.Commands;

    const pnm = editor.Panels;
    const container = document.createElement('div');
    const btnImport = document.createElement('button');

    const check = document.createElement("input");
    const div = document.createElement("div");
    const label = document.createElement("label");
    div.appendChild(check);
    label.innerHTML = "import bootstrap";
    label.style.color = "white";
    check.style.visibility = "visible";

    div.setAttribute("class", "checkbox");

    div.appendChild(label);
    check.setAttribute("type", "checkbox");

    container.className = "container-fluid";

    btnImport.innerHTML = 'Import From URL';

    btnImport.className = "btn btn-primary";

    check.onclick = function() {
        if(check.hasAttribute("checked")) {
            check.removeAttribute("checked")
        } else  {
            check.setAttribute("checked", "checked")
        }
    };



    btnImport.onclick = function() {
        const ExternalURL = document.getElementById("cfUrl").value;
        if(!ExternalURL.includes("http")) {
            alert("URL must include http or https");
        } else {
            editor.runCommand("core:canvas-clear");
            let links;
            const localstorageManager = new LocalStorageHelper();
            const accessToken = localstorageManager.load(LocalStorageHelper.keys.ACCESS_TOKEN);

            let QueryURL = `${webscraperUrl}?url=` + encodeURIComponent(ExternalURL);


            $.ajax({
                type: "GET",
                url: QueryURL,
                headers: {
                    authorization: "Bearer " + accessToken
                },
                success: function (result) {
                    const htmlValue = result.result;
                    let $data = $('<div />',{html:htmlValue});
                   $data.find(".order2stepbutton a").removeAttr("href");
                   $data.find(".order2stepbutton a").attr("onClick", "goToStep2()");
                   $data.find(".order2stepbutton").append("<script>function goToStep2() { $('.o2step_step1').hide();  $('.o2step_step2').show() }</script>");

                   $data.find(".order2StepHeader div:eq(5)").attr("onClick", "goToStep2()");
                   $data.find(".order2StepHeader div").first().attr("onClick", "goToStep1()");
                   $data.find(".order2stepbutton").append("<script>function goToStep1() { $('.o2step_step1').show();  $('.o2step_step2').hide() }</script>");

                    editor.DomComponents.getWrapper().set('content', '');
                    editor.setComponents($data.html());
                    const document = editor.Canvas.getDocument().body;

                    if(!check.hasAttribute("checked")) {
                        let $document = $(document);

                        const links = $document.find("link");

                        links.each((index,link) => {
                            const linkAttr = link.getAttribute("href");
                            if(linkAttr === "https://maxcdn.bootstrapcdn.com/bootstrap/4.0.0/css/bootstrap.min.css" ) {
                                link.remove();
                            }

                        });
                    }
                },
                error: function (result) {
                    if(result && result.responseJSON && result.responseJSON.message){
                        editor.runCommand("core:undo");
                        let errorMessage = "ERROR: "+result.responseJSON.message;
                        modal.setTitle('<h1>Import From URL Error</h1>');
                        modal.setContent('<h1 class="text-danger">'+errorMessage+'</h1>');
                        modal.open();
                    }
                }
            });
        }
            // $.getJSON(QueryURL, function(data){
            //     if (data && data != null && typeof data == "object" && data.contents && data.contents != null && typeof data.contents == "string")
            //     {
            //         data = data.contents.replace(`/<script[^>]*>[sS]*?</script>/gi, "`);
            //         if (data.length > 0)
            //         {
            //             let $data = $('<div />',{html:data});
            //             let scriptTags = $data.find('script');
            //             scriptTags.each((i, scriptTag) => {
            //                 scriptTag.remove();
            //             });
            //            $data.find("img").removeAttr(";");
            //            $data.find(".order2stepbutton a").removeAttr("href");
            //            $data.find(".order2stepbutton a").attr("onClick", "goToStep2()");
            //            $data.find(".order2stepbutton").append("<script>function goToStep2() { $('.o2step_step1').hide();  $('.o2step_step2').show() }</script>");
            //
            //            $data.find(".order2StepHeader div:eq(5)").attr("onClick", "goToStep2()");
            //            $data.find(".order2StepHeader div").first().attr("onClick", "goToStep1()");
            //            $data.find(".order2stepbutton").append("<script>function goToStep1() { $('.o2step_step1').show();  $('.o2step_step2').hide() }</script>");
            //
            //
            //             let element = $data.find(".otoloading");
            //             let svgbox = $data.find("svg");
            //             svgbox.remove();
            //             element.remove();
            //             let metadata = $data.find('meta').remove();
            //             metadata.each((meta, index) => {
            //                if(meta.charset !== "utf-8") {
            //                    metadata.remove();
            //                }
            //             });
            //             links = $data.find('link').attr('href');

            //
            //             let QueryURL = 'https://api.allorigins.ml/get?url=' + encodeURIComponent(links) + '&callback=?';
            //             $.getJSON(QueryURL, function(data) {
            //                 if (data && data != null && typeof data == "object" && data.contents && data.contents != null && typeof data.contents == "string") {
            //                     data = data.contents.replace(`/<script[^>]*>[sS]*?</script>/gi, "`);
            //                     if (data.length > 0) {
            //                         editor.setStyle(data);
            //                     }
            //                 }
            //             });
            //
            //
            //             $data.find('link').remove();
            //         }
            //     }
            // });
        modal.close();
    };

    cmdm.add('html-import', {
        run: function(editor, sender) {
            sender && sender.set('active', 0);

            modal.setTitle('Import From URL');

            if(!document.getElementById("cfUrl")) {
                const input = document.createElement('input');
                input.setAttribute("id", "cfUrl");
                input.setAttribute("placeholder", "Please enter Url");
                input.setAttribute("style", "margin-bottom:10px");
                input.className = "form-control";
                container.appendChild(input);
                container.appendChild(div);
                container.appendChild(btnImport);
            }
            container.style.color = "black";
            modal.setContent('');
            modal.setContent(container);

            modal.open();

        }
    });

    pnm.addButton('options',
        [
            {
                id: 'edit',
                className: 'fa fa-arrow-down',
                command: 'html-import',
                attributes: {
                    title: 'Import From URL'
                }
            }
        ]
    );

});