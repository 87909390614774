const customStyleManager = [
    {
        name: "Dimension",
        open: false,
        buildProps: [
            "flex-width",
            "width",
            "height",
            "margin-top",
            "margin-bottom",
            "margin-left",
            "margin-right",
            "padding-top",
            "padding-bottom",
            "padding-left",
            "padding-right"
        ],
        properties: [
            {
                id: "flex-width",
                // type: "integer",
                name: "Width",
                type: "slider",
                units: ["%"],
                property: "flex-basis",
                min: "0px",
                step: 1,
                max: "100",
                toRequire: 1
            },
            {
                property: "width",
                type: "slider",
                units: ["px"],
                step: 1,
                max: 1000,
                min: 0
            },
            {
                property: "height",
                type: "slider",
                units: ["px"],
                step: 1,
                max: 1000,
                min: 0
            },
            {
                property: "margin-top",
                type: "slider",
                defaults: 1,
                units: ["px", "%", "rem", "pt", "pc"],
                step: 1,
                max: 500,
                min: 0
            },
            {
                property: "margin-bottom",
                type: "slider",
                defaults: 1,
                units: ["px", "%", "rem", "pt", "pc"],
                step: 1,
                max: 500,
                min: 0
            },
            {
                property: "margin-left",
                type: "slider",
                defaults: 1,
                units: ["px", "%", "rem", "pt", "pc"],
                step: 1,
                max: 500,
                min: 0
            },
            {
                property: "margin-right",
                type: "slider",
                defaults: 1,
                units: ["px", "%"],
                step: 1,
                max: 500,
                min: 0
            },
            {
                property: "padding-top",
                type: "slider",
                defaults: 1,
                units: ["px", "%"],
                step: 1,
                max: 500,
                min: 0
            },
            {
                property: "padding-bottom",
                type: "slider",
                defaults: 1,
                units: ["px", "%"],
                step: 1,
                max: 500,
                min: 0
            },
            {
                property: "padding-left",
                type: "slider",
                defaults: 1,
                units: ["px", "%"],
                step: 1,
                max: 500,
                min: 0
            },
            {
                property: "padding-right",
                type: "slider",
                defaults: 1,
                units: ["px", "%"],
                step: 1,
                max: 500,
                min: 0
            }
        ]
    },
    {
        name: "Font",
        open: false,
        buildProps: [
            "font-family",
            "font-size",
            "font-weight",
            "letter-spacing",
            "line-height",
            "color",
            "text-align",
            "text-decoration",
            "text-shadow"
        ],
        properties: [
            { name: "Font Size", property: "font-size",type: "slider" },
            { name: "Letter Spacing Size", property: "letter-spacing",type: "slider", defaults: 0 },
            { name: "Line Height Size", property: "line-height",type: "slider" },
            { name: "Font", property: "font-family" },
            { name: "Weight", property: "font-weight",type: "slider",step: 100, max: 900, min: 100 },
            { name: "Font color", property: "color" },
            {
                property: "text-align",
                type: "radio",
                defaults: "left",
                list: [
                    { value: "left", name: "Left", className: "fa fa-align-left" },
                    { value: "center", name: "Center", className: "fa fa-align-center" },
                    { value: "right", name: "Right", className: "fa fa-align-right" },
                    {
                        value: "justify",
                        name: "Justify",
                        className: "fa fa-align-justify"
                    }
                ]
            },
            {
                property: "text-decoration",
                type: "radio",
                defaults: "none",
                list: [
                    { value: "none", name: "None", className: "fa fa-times" },
                    {
                        value: "underline",
                        name: "underline",
                        className: "fa fa-underline"
                    },
                    {
                        value: "line-through",
                        name: "Line-through",
                        className: "fa fa-strikethrough"
                    }
                ]
            }
        ]
    },
    {
        name: "Border",
        open: false,
        buildProps: [
            "border-style",
            "border-color",
            "border-width",
            "border-top-left-radius",
            "border-top-right-radius",
            "border-bottom-left-radius",
            "border-bottom-right-radius"
        ],
        properties: [
            {
                property: "border-width",
                type: "slider",
                defaults: 1,
                units: ["px", "%"],
                step: 1,
                max: 100,
                min: 0
            },
            {
                property: "border-top-left-radius",
                type: "slider",
                defaults: 1,
                units: ["px", "%"],
                step: 1,
                max: 100,
                min: 0
            },
            {
                property: "border-top-right-radius",
                type: "slider",
                defaults: 1,
                units: ["px", "%"],
                step: 1,
                max: 100,
                min: 0
            },
            {
                property: "border-bottom-left-radius",
                type: "slider",
                defaults: 1,
                units: ["px", "%"],
                step: 1,
                max: 100,
                min: 0
            },
            {
                property: "border-bottom-right-radius",
                type: "slider",
                defaults: 1,
                units: ["px", "%"],
                step: 1,
                max: 100,
                min: 0
            }
        ]
    },
    {
        name: "Shadow",
        open: false,
        buildProps: ["box-shadow", "text-shadow"],
        properties: [
            {
                property: "box-shadow",
                properties: [
                    { name: "X position", property: "box-shadow-h" },
                    { name: "Y position", property: "box-shadow-v" },
                    { name: "Blur", property: "box-shadow-blur" },
                    { name: "Spread", property: "box-shadow-spread" },
                    { name: "Color", property: "box-shadow-color" },
                    { name: "Shadow type", property: "box-shadow-type" }
                ]
            },
            {
                property: "text-shadow",
                properties: [
                    { name: "X position", property: "text-shadow-h" },
                    { name: "Y position", property: "text-shadow-v" },
                    { name: "Blur", property: "text-shadow-blur" },
                    { name: "Color", property: "text-shadow-color" }
                ]
            }
        ]
    },
    {
        name: "Background",
        open: false,
        buildProps: ["background-color", "background"],
        properties: [
            {
                property: "background",
                properties: [
                    { name: "Image", property: "background-image" },
                    { name: "Repeat", property: "background-repeat" },
                    { name: "Position", property: "background-position" },
                    { name: "Attachment", property: "background-attachment" },
                    { name: "Size", property: "background-size" }
                ]
            }
        ]
    },
    {
        name: "Extra",
        open: false,
        buildProps: ["opacity"],
        properties: [
            {
                type: "slider",
                property: "opacity",
                defaults: 1,
                step: 0.01,
                max: 1,
                min: 0
            }
        ]
    }
];
export default customStyleManager;
