import { VisualizerHeader as Header } from "./visualizer-header";
import { connect } from "react-redux";
import * as visualizerActions from "../../../actions/visualizer-actions";
import * as visualizerSelectors from "../../../selectors/visualizer-selectors";
import * as commonActions from "../../../actions/actions";

function mapStateToProps(state,ownProps) {
    const selectors = commonActions.getSelector(ownProps.parent);
    const visualizerData = visualizerSelectors.getVisualizerData(state);
    const importedFunnel = selectors.getImportedFunnel(state);
    const funnel = importedFunnel ? importedFunnel : selectors.getFunnelData(state);
    return {
        funnel,
        visualizerData,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        updateVisualizerData: (params) => dispatch(visualizerActions.updateVisualizerData(params)),
    };
}

export const VisualizerHeader = connect(mapStateToProps, mapDispatchToProps)(Header);
