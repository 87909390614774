import * as funnelApi from "../api/funnelApi";
import * as assetApi from "../api/assetApi";

import { APIHelper } from "../utilities";

const WEBBUILDER = "webbuilder/";

export const IS_LOADING = `${WEBBUILDER}IS_LOADING`;
export const ADD_SUCCESS = `${WEBBUILDER}ADD_SUCCESS`;
export const LOAD_SUCCESS = `${WEBBUILDER}LOAD_SUCCESS`;
export const LOAD_PAGE_SUCCESS = `${WEBBUILDER}LOAD_PAGE_SUCCESS`;
export const DELETE_SUCCESS = `${WEBBUILDER}DELETE_SUCCESS`;

export const getWebBuilderData = async (params) => {
    return funnelApi.getJsonData(params, { headers: APIHelper.getGenericHeaders() });
};

const defaultIcons = [
    "https://s3.us-east-2.amazonaws.com/konnektfunnels-assets/Templates/icons/001-box.png",
    "https://s3.us-east-2.amazonaws.com/konnektfunnels-assets/Templates/icons/002-shopping.png",
    "https://s3.us-east-2.amazonaws.com/konnektfunnels-assets/Templates/icons/003-tag.png",
    "https://s3.us-east-2.amazonaws.com/konnektfunnels-assets/Templates/icons/004-shopping-1.png",
    "https://s3.us-east-2.amazonaws.com/konnektfunnels-assets/Templates/icons/005-credit-card.png",
    "https://s3.us-east-2.amazonaws.com/konnektfunnels-assets/Templates/icons/006-hand-bag.png",
    "https://s3.us-east-2.amazonaws.com/konnektfunnels-assets/Templates/icons/007-piggy-bank.png",
    "https://s3.us-east-2.amazonaws.com/konnektfunnels-assets/Templates/icons/008-wallet.png",
    "https://s3.us-east-2.amazonaws.com/konnektfunnels-assets/Templates/icons/009-wishlist.png",
    "https://s3.us-east-2.amazonaws.com/konnektfunnels-assets/Templates/icons/010-hand.png",
    "https://s3.us-east-2.amazonaws.com/konnektfunnels-assets/Templates/icons/011-call-center.png",
    "https://s3.us-east-2.amazonaws.com/konnektfunnels-assets/Templates/icons/012-online-shopping.png",
    "https://s3.us-east-2.amazonaws.com/konnektfunnels-assets/Templates/icons/013-shopping-basket.png",
    "https://s3.us-east-2.amazonaws.com/konnektfunnels-assets/Templates/icons/014-fashion.png",
    "https://s3.us-east-2.amazonaws.com/konnektfunnels-assets/Templates/icons/015-dress.png",
    "https://s3.us-east-2.amazonaws.com/konnektfunnels-assets/Templates/icons/016-manager.png",
    "https://s3.us-east-2.amazonaws.com/konnektfunnels-assets/Templates/icons/017-shopping-bag.png",
    "https://s3.us-east-2.amazonaws.com/konnektfunnels-assets/Templates/icons/018-search.png",
    "https://s3.us-east-2.amazonaws.com/konnektfunnels-assets/Templates/icons/019-guarantee.png",
    "https://s3.us-east-2.amazonaws.com/konnektfunnels-assets/Templates/icons/020-delivery.png",
    "https://s3.us-east-2.amazonaws.com/konnektfunnels-assets/Templates/icons/021-courier.png",
    "https://s3.us-east-2.amazonaws.com/konnektfunnels-assets/Templates/icons/022-favourite.png",
    "https://s3.us-east-2.amazonaws.com/konnektfunnels-assets/Templates/icons/023-phone.png",
    "https://s3.us-east-2.amazonaws.com/konnektfunnels-assets/Templates/icons/024-shop.png",
    "https://s3.us-east-2.amazonaws.com/konnektfunnels-assets/Templates/icons/025-cashbox.png",
    "https://s3.us-east-2.amazonaws.com/konnektfunnels-assets/Templates/icons/026-cargo-ship.png",
    "https://s3.us-east-2.amazonaws.com/konnektfunnels-assets/Templates/icons/027-signboard.png",
    "https://s3.us-east-2.amazonaws.com/konnektfunnels-assets/Templates/icons/028-voucher.png",
    "https://s3.us-east-2.amazonaws.com/konnektfunnels-assets/Templates/icons/029-high-heels.png",
    "https://s3.us-east-2.amazonaws.com/konnektfunnels-assets/Templates/icons/030-info.png",
    "https://s3.us-east-2.amazonaws.com/konnektfunnels-assets/Templates/icons/031-currency.png",
    "https://s3.us-east-2.amazonaws.com/konnektfunnels-assets/Templates/icons/032-location.png",
    "https://s3.us-east-2.amazonaws.com/konnektfunnels-assets/Templates/icons/033-shopping-cart.png",
    "https://s3.us-east-2.amazonaws.com/konnektfunnels-assets/Templates/icons/034-ribbon.png",
    "https://s3.us-east-2.amazonaws.com/konnektfunnels-assets/Templates/icons/035-hanger.png",
    "https://s3.us-east-2.amazonaws.com/konnektfunnels-assets/Templates/icons/036-credit-card-1.png",
    "https://s3.us-east-2.amazonaws.com/konnektfunnels-assets/Templates/icons/037-box-1.png",
    "https://s3.us-east-2.amazonaws.com/konnektfunnels-assets/Templates/icons/038-trolley.png",
    "https://s3.us-east-2.amazonaws.com/konnektfunnels-assets/Templates/icons/039-air-freight.png",
    "https://s3.us-east-2.amazonaws.com/konnektfunnels-assets/Templates/icons/040-delivery-truck.png",
    "https://s3.us-east-2.amazonaws.com/konnektfunnels-assets/Templates/icons/041-shield.png",
    "https://s3.us-east-2.amazonaws.com/konnektfunnels-assets/Templates/icons/042-card-payment.png",
    "https://s3.us-east-2.amazonaws.com/konnektfunnels-assets/Templates/icons/043-locker.png",
    "https://s3.us-east-2.amazonaws.com/konnektfunnels-assets/Templates/icons/044-stopwatch.png",
    "https://s3.us-east-2.amazonaws.com/konnektfunnels-assets/Templates/icons/045-speech-bubble.png",
    "https://s3.us-east-2.amazonaws.com/konnektfunnels-assets/Templates/icons/046-telephone.png",
    "https://s3.us-east-2.amazonaws.com/konnektfunnels-assets/Templates/icons/047-libra.png",
    "https://s3.us-east-2.amazonaws.com/konnektfunnels-assets/Templates/icons/048-hourglass.png",
    "https://s3.us-east-2.amazonaws.com/konnektfunnels-assets/Templates/icons/049-filter.png",
    "https://s3.us-east-2.amazonaws.com/konnektfunnels-assets/Templates/icons/050-money.png"
];

export function toggleLoading(status = false) {
    return { type: IS_LOADING, status };
}

export function loadAssetSuccess(assetData) {
    return { type: LOAD_SUCCESS, assetData };
}


export function addAsset(assetName) {
    return function (dispatch) {
        dispatch(toggleLoading(true));
        return assetApi.assetPost(assetName, { headers: APIHelper.getGenericHeaders() }).then(() => {
            dispatch(loadAsset());
            dispatch(toggleLoading(false));
        }).catch((error) => {
            dispatch(toggleLoading(false));
            throw error;
        });
    };
}

export function loadAsset() {
    return function (dispatch) {
        return assetApi.assetGet({ headers: APIHelper.getGenericHeaders() }).then((assetData) => {
            if (assetData.status === 404) {
                assetData = [];
            } else {
                assetData = assetData.data.result;
            }
            defaultIcons.forEach((icon) => {
                assetData.push(icon);
            });
            dispatch(loadAssetSuccess(assetData));
            return assetData;
        }).catch((error) => {
            dispatch(toggleLoading(false));
            throw error;
        });
    };
}

export function deleteAsset(assetName) {
    return function (dispatch) {
        dispatch(toggleLoading(true));
        return assetApi.assetDelete(assetName, { headers: APIHelper.getGenericHeaders() }).then(() => {
            dispatch(loadAsset());
            dispatch(toggleLoading(false));
        }).catch((error) => {
            dispatch(toggleLoading(false));
            throw error;
        });
    };
}

export function getFunnelWebBuilderElements(params) {
    return funnelApi.getFunnelWebBuilderElements(params, { headers: APIHelper.getGenericHeaders() });
}
