import React from "react";
import PropTypes from "prop-types";
import { Modal } from "react-bootstrap";

const ExitConfirmationModal = (props) => {
    const {
        closeModal,
        navigateBack,
        editor,
    } = props;

    const onSave = () => {
        try {
            editor.Commands.get("save-database").run(editor, { navigateBack: true });
            closeModal();
        // eslint-disable-next-line no-empty
        } catch (err) {
        }
    };

    return (
        <Modal id="exit-modal" show>
            <Modal.Header >
                <Modal.Title>CONFIRMATION!!</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="confirmation">Do you want to save your changes ?</div>
                <div className="row save-cancel-btn text-center">
                    <button className="btn btn-success modal-save-btn" onClick={() => onSave()}>Save</button>
                    <button className="btn btn-danger modal-save-btn" onClick={() => navigateBack()}>Don't Save</button>
                    <button className="btn btn -default modal-cancel-btn" onClick={() => closeModal()}>Cancel</button>
                </div>
            </Modal.Body>
        </Modal>
    );
};

ExitConfirmationModal.propTypes = {
    navigateBack: PropTypes.func,
    editor: PropTypes.object,
    closeModal: PropTypes.func,
};

export default ExitConfirmationModal;