import { ImportFunnelTemplateModal as Modal } from "./import-funnel-template";
import { connect } from "react-redux";
import { funnelTemplateActions,visualizerActions } from "../../actions";
import * as selectors from "../../selectors/funnel-template-selectors";
import * as funnelTemplateSelectors from "../../../funnel-templates/selectors";
import * as visualizerSelectors from "../../selectors/visualizer-selectors";

function mapStateToProps(state) {
    const activeFunnels = funnelTemplateSelectors.getActiveFunnelTemplates(state);
    const importedFunnel = selectors.getImportedFunnel(state);
    const funnel = importedFunnel ? importedFunnel : selectors.getFunnelData(state);
    const visualizerData = visualizerSelectors.getVisualizerData(state);
    const funnelReferenceId = funnel ? funnel.referenceId : "";
    return {
        funnel,
        activeFunnels,
        visualizerData,
        funnelReferenceId
    };
}

function mapDispatchToProps(dispatch) {
    return {
        loadActiveFunnelTemplates: () => dispatch(funnelTemplateActions.loadActiveFunnelTemplates()),
        getFunnelData: (funnelReferenceId) => dispatch(funnelTemplateActions.getFunnel(funnelReferenceId)),
        importFunnelTemplate: (params) => dispatch(funnelTemplateActions.importFunnelTemplate(params)),
        updateVisualizerData: (params) => dispatch(visualizerActions.updateVisualizerData(params)),
    };
}

export const ImportFunnelTemplateModal = connect(mapStateToProps, mapDispatchToProps)(Modal);
