import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
import {
    FormGroup,
    FormControl,
    ControlLabel,
    Button,
    InputGroup,
    Row,
    Col,
    Modal
} from "react-bootstrap";
import { connect } from "react-redux";

import ToolTip from "../../ToolTip";
import { numberOnly } from "../../utilities";
import { NotificationEmitter, NOTIFICATION_EVENT_NAME } from "../../../commons/notification";

import "./CreateProduct.css";
import { createProduct, createCampaignProduct, getFulfillmentHouseList, getMerchantList, getProductData, updateCampaign, updateProduct, updateCampaignProduct } from "../../utilities/api";
import { getProducts } from "../../../api/campaignsApi";
import CreateEditFulfillment from "../../Forms/Fulfillment";
import CreateEditMerchant from "../../Merchant/CreateEditMerchant";
import CRMButton from "../../CRMButton";
import { updateFunnelInfo } from "../../../funnels/actions";

class CreateEditProduct extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: false,
            isQualityAssuranceEnable: null,
            isPinlessDebitEnable: null,
            offerSelected: this.props.pageTitle,
            hasError: false,
            product: Object.assign({}, this.getCampaignInitialState(this.props.pageType === "Edit")),
            campaignId: this.props.campaignId,
            useOfferMid: props.editData && props.editData.useOfferMid === 1 ? 1 : 0,
            productId: null,
            showToAffiliates: props.editData && props.editData.showToAffiliates === 1 ? 1 : 0,
            productList: null,
            fulfillmentHouseList: null,
            merchantList: null,
            offerUpShellList: [],
            formErrors: [],
            isFulfillmentHouseVisible: false,
            fulfillmentType: null,
            fulfillmentDetails: null,
            funnelData: props.wholeFunnelDetails || props.funnelData,
            offerArray: props.offerArray,
            upsaleArray: props.upsaleArray
        };
    }

    getCampaignInitialState(value) {
        const { merchantId } = this.props;
        let initialValue = {
            productType: this.props.upsells ? "UPSALE" : "OFFER",
            productName: null,
            clientProductId: null,
            productSku: null,
            productDescription: null,
            category: null,
            productQty: null,
            fulfillmentDelayHours: null,
            fulfillmentHouseId: this.props.editData ? this.props.editData.fulfillmentHouseId : 0,
            productCost: null,
            shippingCost: null,
            weight: null,
            msrp: null,
            billerId: merchantId ? merchantId : this.props.editData ? this.props.editData.billerId : null,
            billingCycleType: "ONE_TIME",
            cycle1_billDelay: null,
            cycle1_isShippable: 0,
            cycle1_price: 0,
            cycle1_shipPrice: 0,
            cycle1_combinationId: null,
            cycle1_productId: null,
            cycle2_billDelay: null,
            cycle2_isShippable: 0,
            cycle2_price: 0,
            cycle2_productId: null,
            cycle2_combinationId: null,
            cycle2_shipPrice: 0,
            cycle3_billDelay: null,
            cycle3_isShippable: 0,
            cycle3_price: 0,
            cycle3_productId: null,
            cycle3_combinationId: null,
            cycle3_shipPrice: 0
        };
        if (!value) {
            return {
                ...initialValue
            };
        } else {
            return {
                ...initialValue,
                ...this.props.editData,
            };
        }
    }

    componentDidMount() {
        this.getAllList();
        // typeof this.props.handleSkipButton === "function" && this.props.handleSkipButton(false);
        // this.props.handleSkipButton && this.props.handleSkipButton(false);
        if (this.props.selectedProductId) {
            this.fetchDetail();
        }
    }

    fetchDetail = () => {
        getProductData();
    }

    getFulfillmentHouse = () => {
        getFulfillmentHouseList()
            .then((res) => {
                this.setState({
                    isFulfillmentHouseVisible: false,
                    fulfillmentHouseList: res.message.filter(data => data.uniqueFields !== null),
                    fulfillmentDetails: null
                }, () => this.handleFulfillment(this.state.product.fulfillmentHouseId));
            })
            .catch((err) => {
                NotificationEmitter.emit(NOTIFICATION_EVENT_NAME, { type: "error", message: err.response.data.message });
            });
    }

    getMerchantList = () => {
        getMerchantList()
            .then((res) => {
                this.setState({
                    merchantList: Object.values(res.message)
                }, () => this.handleMerchant(this.state.product.billerId));
            })
            .catch((err) => {
                NotificationEmitter.emit(NOTIFICATION_EVENT_NAME, { type: "error", message: err.response.data.message });
            });
    }

    getAllList = () => {
        this.setState({
            offerUpShellList: ["Offer #1", "Upsell #1"]
        });

        this.getFulfillmentHouse();

        getProducts().then((res) => {
            let obj;
            let arr = [];
            for (let props in res) {
                obj = res[props];
                arr.push(obj);
            }
            this.setState({
                productList: Object.values(arr[1]),
                isTemplateFetched: true
            });
        });

        this.getMerchantList();
    }

    isValid = (args) => {
        const { productName, billerId, cycle1_price, cycle1_shipPrice, cycle1_billDelay, cycle2_price, cycle2_shipPrice, cycle2_billDelay, cycle3_price, cycle3_shipPrice, cycle3_billDelay } = args;
        let formErrors = [];
        if (!productName) {
            formErrors.push("productName");
        }
        if (this.state.useOfferMid !== 1 && !billerId) {
            formErrors.push("billerId");
        }
        if (this.state.product.billingCycleType === "ONE_TIME") {
            if (cycle1_price === "") {
                formErrors.push("cycle1_price");
            }
            if (cycle1_shipPrice === "") {
                formErrors.push("cycle1_shipPrice");
            }
        } else {
            if (cycle1_price === "") {
                formErrors.push("cycle1_price");
            }
            if (cycle1_shipPrice === "") {
                formErrors.push("cycle1_shipPrice");
            }
            if (!cycle1_billDelay) {
                formErrors.push("cycle1_billDelay");
            } else if (parseInt(cycle1_billDelay) > 365) {
                formErrors.push("cycle1_billDelay_greater");
            } else if (cycle1_billDelay) {
                if (parseInt(cycle1_price) === 0 && parseInt(cycle1_shipPrice) === 0) {
                    formErrors.push("cycle1_billDelay_must_have_value_greater_than_zero");
                }
            }
            if (cycle2_price === "") {
                formErrors.push("cycle2_price");
            }
            if (cycle2_shipPrice === "") {
                formErrors.push("cycle2_shipPrice");
            }
            if (parseInt(cycle2_billDelay) > 365) {
                formErrors.push("cycle2_billDelay_greater");
            } else if (cycle2_billDelay) {
                if (parseInt(cycle2_price) === 0 && parseInt(cycle2_shipPrice) === 0) {
                    formErrors.push("cycle2_billDelay_must_have_value_greater_than_zero");
                }
            }
            if (cycle3_price === "") {
                formErrors.push("cycle3_price");
            }
            if (cycle3_shipPrice === "") {
                formErrors.push("cycle3_shipPrice");
            }
            if (parseInt(cycle3_billDelay) > 365) {
                formErrors.push("cycle3_billDelay_greater");
            } else if (cycle3_billDelay) {
                if (parseInt(cycle3_price) === 0 && parseInt(cycle3_shipPrice) === 0) {
                    formErrors.push("cycle3_billDelay_must_have_value_greater_than_zero");
                }
            }
        }
        this.setState({
            formErrors
        });
        return formErrors.length === 0;
    }

    handleSubmit = (e) => {
        const { selectTemplate, nextOfferUpShell, pageTitle, moveStep, insideEditFunnel, upsells, editData, refreshData, isEnableLoading, fromSelectTemplate, fromEditFunnel } = this.props;
        const {
            product: {
                productName,
                productCost,
                productType,
                billerId,
                billingCycleType,
                cycle1_billDelay,
                cycle1_isShippable,
                cycle1_price,
                cycle1_shipPrice,
                cycle1_productId,
                cycle1_combinationId,
                cycle2_billDelay,
                cycle2_isShippable,
                cycle2_price,
                cycle2_combinationId,
                cycle2_productId,
                cycle2_shipPrice,
                cycle3_billDelay,
                cycle3_isShippable,
                cycle3_price,
                cycle3_combinationId,
                cycle3_productId,
                cycle3_shipPrice,
                shippingCost,
                productQty,
                productSku,
                fulfillmentDelayHours,
                fulfillmentHouseId,
                clientProductId,
                productDescription,
                weight
            },
            campaignId,
            productId,
            useOfferMid,
            showToAffiliates,
            formErrors
        } = this.state;
        e.preventDefault();
        if (this.isValid({ productName, billerId, cycle1_price, cycle1_shipPrice, cycle1_billDelay, cycle2_price, cycle2_shipPrice, cycle2_billDelay, cycle3_price, cycle3_shipPrice, cycle3_billDelay })) {
            this.setState({
                isLoading: true
            });
            let data = {
                campaignId,
                productId,
                displayName: productName,
                billerId,
                productType,
                productCost,
                billingCycleType,
                shippingCost
            };
            if (billingCycleType === "ONE_TIME") {
                data = {
                    ...data,
                    cycle1_price,
                    cycle1_shipPrice,
                    cycle1_isShippable,
                    cycle1_billDelay,
                    cycle1_productId,
                    // cycle1_combinationId: cycleData.combinationId
                };
            } else {
                data = {
                    ...data,
                    cycle1_price,
                    cycle1_shipPrice,
                    cycle1_isShippable,
                    cycle1_billDelay,
                    cycle1_productId,
                    cycle1_combinationId,
                    cycle2_price,
                    cycle2_shipPrice,
                    cycle2_isShippable,
                    cycle2_billDelay,
                    cycle2_productId,
                    cycle2_combinationId,
                    cycle3_price,
                    cycle3_shipPrice,
                    cycle3_isShippable,
                    cycle3_billDelay,
                    cycle3_productId,
                    cycle3_combinationId
                };
            }
            if (data.cycle1_combinationId === null || data.cycle1_combinationId === 0) {
                delete data.cycle1_combinationId;
            }
            if (data.cycle2_combinationId === null || data.cycle2_combinationId === 0) {
                delete data.cycle2_combinationId;
            }
            if (data.cycle3_combinationId === null || data.cycle3_combinationId === 0) {
                delete data.cycle3_combinationId;
            }
            if (editData) {
                data = {
                    ...data,
                    productId: editData.productId,
                    cycle1_shipPrice: editData.cycle1_shipPrice || cycle1_shipPrice
                };
                let productData = {
                    productId: editData.productId,
                    productName,
                    clientProductId,
                    productSku,
                    productDescription,
                    productQty,
                    fulfillmentDelayHours,
                    fulfillmentHouseId,
                    productCost,
                    weight,
                    shippingCost
                    // msrp: "8",
                    // visible: "1"
                };
                updateProduct(productData)
                    .then(res => {
                        NotificationEmitter.emit(NOTIFICATION_EVENT_NAME, { type: "success", message: "Product updated successfully" });
                    })
                    .catch((err) => {
                        NotificationEmitter.emit(NOTIFICATION_EVENT_NAME, { type: "error", message: err.response.data.message });
                    });
                createCampaignProduct(upsells ? Object.assign({}, data, { useOfferMid, showToAffiliates }) : data)
                    .then((res) => {
                        if (!fromEditFunnel) {
                            NotificationEmitter.emit(NOTIFICATION_EVENT_NAME, { type: "success", message: "Product created successfully" });
                            this.setState({
                                isLoading: false
                            });
                            this.props.pageType === "ADD" ? this.props.closeModal(e) : (
                                this.handleUpdateFunnel(res.data.message.campaignProductId, res.data.message.displayName, e, "Create", !fromEditFunnel ? "StepWizard" : "")
                                // selectTemplate && selectTemplate(null),
                                // nextOfferUpShell && nextOfferUpShell()
                            );
                            if (insideEditFunnel || fromSelectTemplate) {
                                refreshData();
                                isEnableLoading();
                                this.props.closeModal && this.props.closeModal(e);
                            }
                        } else {
                            this.props.closeModal && this.props.closeModal(e);
                            this.handleUpdateFunnel(res.data.message.campaignProductId, res.data.message.displayName, e, "Create");
                            refreshData();
                            isEnableLoading();
                        }
                    })
                    .catch((err) => {
                        NotificationEmitter.emit(NOTIFICATION_EVENT_NAME, { type: "error", message: err });
                        this.setState({
                            isLoading: false
                        });
                    });
            } else {
                if (this.state.product.cycle1_combinationId === null || this.state.product.cycle1_combinationId === 0) {
                    delete this.state.product.cycle1_combinationId;
                }
                if (this.state.product.cycle2_combinationId === null || this.state.product.cycle2_combinationId === 0) {
                    delete this.state.product.cycle2_combinationId;
                }
                if (this.state.product.cycle3_combinationId === null || this.state.product.cycle3_combinationId === 0) {
                    delete this.state.product.cycle3_combinationId;
                }
                createProduct(this.state.product)
                    .then((res) => {
                        let productId = res.data.message.productId;
                        this.setState({
                            productId,
                            cycle1_shipPrice: productId
                        });
                        data = {
                            ...data,
                            productId,
                            cycle1_productId
                        };
                        createCampaignProduct(upsells ? Object.assign({}, data, { useOfferMid, showToAffiliates }) : data)
                            .then((res) => {
                                if (!fromEditFunnel) {
                                    this.setState({
                                        isLoading: false
                                    });
                                    this.props.pageType === "ADD" ? this.props.closeModal(e) : (
                                        this.handleUpdateFunnel(res.data.message.campaignProductId, res.data.message.displayName, e, "Create", !fromEditFunnel ? "StepWizard" : "")
                                        // selectTemplate(null),
                                        // nextOfferUpShell()
                                    );
                                    if (this.props.insideEditFunnel) {
                                        this.props.refreshData();
                                        this.props.isEnableLoading();
                                    }
                                    NotificationEmitter.emit(NOTIFICATION_EVENT_NAME, { type: "success", message: "Product created successfully" });
                                } else {
                                    this.handleUpdateFunnel(res.data.message.campaignProductId, res.data.message.displayName, e, "Create");
                                }
                            })
                            .catch((err) => {
                                NotificationEmitter.emit(NOTIFICATION_EVENT_NAME, { type: "error", message: err.response.data.message });
                                this.setState({
                                    isLoading: false
                                });
                            });
                    })
                    .catch((err) => {
                        NotificationEmitter.emit(NOTIFICATION_EVENT_NAME, { type: "error", message: err.response.data.message });
                        this.setState({
                            isLoading: false
                        });
                    });
            }
        } else {
            if (!productName) {
                this.refs.productName.scrollIntoView({ behavior: "smooth" });
            }
        }
    }

    handleUpdate = (e) => {
        const {
            product: {
                billerId,
                billerName,
                billingCycleType,
                campaignProductId,
                clientProductId,
                clubPluginId,
                cycle1_billDelay,
                cycle1_isShippable,
                cycle1_productId,
                cycle1_shipPrice,
                cycle1_price,
                cycle1_combinationId,
                cycle2_billDelay,
                cycle2_isShippable,
                cycle2_productId,
                cycle2_shipPrice,
                cycle2_price,
                cycle2_combinationId,
                cycle3_billDelay,
                cycle3_isShippable,
                cycle3_productId,
                cycle3_shipPrice,
                cycle3_price,
                cycle3_combinationId,
                externalProductId,
                finalBillingCycle,
                fullfillmellCycleType,
                fulfillmentHouseId,
                productName,
                productSku,
                productDescription,
                category,
                fulfillmentDelayHours,
                productQty,
                productCost,
                weight,
                productId,
                shippingCost
            },
            useOfferMid,
            showToAffiliates,
            formErrors
        } = this.state;
        e.preventDefault();
        if (this.isValid({ productName, billerId, cycle1_price, cycle1_shipPrice, cycle1_billDelay, cycle2_price, cycle2_shipPrice, cycle2_billDelay, cycle3_price, cycle3_shipPrice, cycle3_billDelay })) {
            let productData = {
                productId: productId,
                productName: productName,
                clientProductId: clientProductId,
                productSku: productSku,
                productDescription: productDescription,
                productQty: productQty,
                fulfillmentDelayHours: fulfillmentDelayHours,
                fulfillmentHouseId: fulfillmentHouseId,
                productCost: productCost,
                weight: weight,
                shippingCost: shippingCost
                // msrp: "8",
                // visible: "1"
            };

            let updateCampaignProductData = {
                productId: productId,
                campaignProductId: campaignProductId,
                displayName: productName,
                billerId: billerId,
                productType: this.props.upsells ? "UPSALE" : "OFFER",
                billingCycleType: billingCycleType,
                useOfferMid: useOfferMid,
                showToAffiliates: showToAffiliates,
                productCost: productCost,
            };

            if (billingCycleType === "ONE_TIME") {
                updateCampaignProductData = {
                    ...updateCampaignProductData,
                    cycle1_price,
                    cycle1_shipPrice,
                    cycle1_isShippable,
                    cycle1_billDelay,
                    cycle1_productId,
                    // cycle1_combinationId: cycleData.combinationId
                };
            } else {
                updateCampaignProductData = {
                    ...updateCampaignProductData,
                    cycle1_price,
                    cycle1_shipPrice,
                    cycle1_isShippable,
                    cycle1_billDelay,
                    cycle1_productId,
                    cycle1_combinationId,
                    cycle2_price,
                    cycle2_shipPrice,
                    cycle2_isShippable,
                    cycle2_billDelay,
                    cycle2_productId,
                    cycle2_combinationId,
                    cycle3_price,
                    cycle3_shipPrice,
                    cycle3_isShippable,
                    cycle3_billDelay,
                    cycle3_productId,
                    cycle3_combinationId
                };
            }
            this.setState({
                isLoading: true
            });
            if (updateCampaignProductData.cycle1_combinationId === null || updateCampaignProductData.cycle1_combinationId === 0) {
                delete updateCampaignProductData.cycle1_combinationId;
            }
            if (updateCampaignProductData.cycle2_combinationId === null || updateCampaignProductData.cycle2_combinationId === 0) {
                delete updateCampaignProductData.cycle2_combinationId;
            }
            if (updateCampaignProductData.cycle3_combinationId === null || updateCampaignProductData.cycle3_combinationId === 0) {
                delete updateCampaignProductData.cycle3_combinationId;
            }
            updateProduct(productData)
                .then(res => {
                    updateCampaignProduct(updateCampaignProductData)
                        .then(res => {
                            if (!this.props.fromEditFunnel) {
                                this.setState({
                                    isLoading: false
                                }, () => NotificationEmitter.emit(NOTIFICATION_EVENT_NAME, { type: "success", message: "Product updated successfully" }));
                                this.props.closeModal && this.props.closeModal(e);
                                if (this.props.insideEditFunnel) {
                                    this.props.refreshData();
                                    this.props.isEnableLoading();
                                }
                            } else {
                                this.handleUpdateFunnel(res.data.message.campaignProductId, res.data.message.displayName, e, "Update");
                            }
                        })
                        .catch(err => {
                            this.setState({
                                isLoading: false
                            }, () => NotificationEmitter.emit(NOTIFICATION_EVENT_NAME, { type: "error", message: err.response.data.message }));
                            // this.props.closeModal && this.props.closeModal(e);
                        });
                })
                .catch(err => {
                    this.setState({
                        isLoading: false
                    }, () => NotificationEmitter.emit(NOTIFICATION_EVENT_NAME, { type: "error", message: err.response.data.message }));
                    // this.props.closeModal && this.props.closeModal(e);
                });
        } else {
            if (!productName) {
                this.refs.productName.scrollIntoView({ behavior: "smooth" });
            }
        }
    }

    handleChange = (e, type) => {
        if (type === "checkbox") {
            this.setState({
                product: {
                    ...this.state.product,
                    [e.target.name]: e.target.checked ? 1 : 0
                }
            });
            return;
        }
        this.setState({
            product: {
                ...this.state.product,
                [e.target.name]: e.target.value
            }
        });
    }

    handleFulfillment = (value) => {
        const { fulfillmentHouseList } = this.state;
        this.setState({
            fulfillmentDetails: Object.assign({}, ...fulfillmentHouseList.filter(fulfillment => fulfillment.fulfillmentHouseId === parseInt(value)))
        });
    }

    handleMerchant = (value) => {
        const { merchantList } = this.state;
        this.setState({
            merchantDetails: Object.assign({}, ...merchantList.filter(merchant => merchant.billerId === parseInt(value)))
        });
    }

    handleUpdateFunnel = (campaignProductId, productName, e, type, pageType) => {
        const { funnelData } = this.state;
        const { offerArray, upsaleArray } = this.props;
        const { getFunnelDetails, funnelDetails, productType, selectTemplate, nextOfferUpShell, updateFunnelInfo, pageTitle } = this.props;
        let data = funnelData;
        let products = funnelDetails && funnelDetails.products;
        let offer = [];
        let upsales = [];
        let p = new Promise((resolve) => {
            if (pageType !== "StepWizard") {
                products && products.map(list => {
                    if (list.productType === "OFFER") {
                        offer.push({ value: list.campaignProductId, text: list.productName, type: list.productType });
                    } else {
                        upsales.push({ value: list.campaignProductId, text: list.productName, type: list.productType });
                    }
                });
                if (productType === "OFFER") {
                    if (type === "Update") {
                        let index = offer.findIndex((offer) => offer.value === campaignProductId);
                        offer.splice(index, 1);
                    }
                    offer.push({ value: campaignProductId, text: productName, type: productType });
                } else if (productType === "UPSALE") {
                    if (type === "Update") {
                        let index = upsales.findIndex((upsales) => upsales.value === campaignProductId);
                        upsales.splice(index,1);
                    }
                    upsales.push({ value: campaignProductId, text: productName, type: productType });
                }
                if (type !== "Update") {
                    data.offer = JSON.stringify(offer);
                    data.upsale = JSON.stringify(upsales);
                } else {
                    data.offer = JSON.stringify(offer);
                    data.upsale = JSON.stringify(upsales);
                }
            } else {
                if (this.props.isInsideTheVisualizer) {
                    offer = offerArray;
                    upsales = upsaleArray;
                }
                if (pageTitle.includes("Offer")) {
                    offer.push({ value: campaignProductId, text: productName, type: "OFFER" });
                    this.props.setOfferUpsale("offerArray", offer);
                } else {
                    if (data && data.offer) {
                        offer = JSON.parse(data && data.offer);
                        upsales.push({ value: campaignProductId, text: productName, type: "UPSALE" });
                        this.props.setOfferUpsale("upsaleArray", upsales);
                    }
                }
                data.offer = JSON.stringify(offer);
                data.upsale = JSON.stringify(upsales);
                // this.setState({
                //     offerArray: offer,
                //     upsale: upsaleArray
                // });
            }
            resolve(data);
        }).then((data) => {
            const funnel = {
                funnel: data
            };
            updateFunnelInfo({ ...funnel })
                .then(res => {
                    getFunnelDetails && getFunnelDetails(res);
                    if (type !== "Update") {
                        this.setState({
                            isLoading: false
                        });
                        this.props.pageType === "ADD" ? this.props.closeModal(e) : (
                            selectTemplate(null),
                            nextOfferUpShell(),
                            this.props.refreshData && this.props.refreshData()
                        );
                        if (this.props.insideEditFunnel) {
                            this.props.refreshData();
                            this.props.isEnableLoading();
                            this.props.closeModal && this.props.closeModal(e);
                        }
                        NotificationEmitter.emit(NOTIFICATION_EVENT_NAME, { type: "success", message: "Product created successfully" });
                    } else {
                        this.props.closeModal && this.props.closeModal(e);
                        this.setState({
                            isLoading: false
                        }, () => NotificationEmitter.emit(NOTIFICATION_EVENT_NAME, { type: "success", message: "Product updated successfully" }));
                        this.props.closeModal && this.props.closeModal(e);
                        if (this.props.insideEditFunnel) {
                            this.props.refreshData();
                            this.props.isEnableLoading();
                        }
                    }
                })
                .catch(err => {
                    NotificationEmitter.emit(NOTIFICATION_EVENT_NAME, { type: "error", message: err });
                    this.setState({
                        isLoading: false
                    });
                });
        });
    }

    render() {
        const {
            hasError,
            formErrors,
            showToAffiliates,
            useOfferMid,
            offers,
            isLoading,
            fulfillmentHouseList,
            merchantList,
            productList,
            isFulfillmentHouseVisible,
            componentType,
            fulfillmentType,
            fulfillmentDetails,
            merchantDetails,
            product: {
                baseProductName,
                billingCycleType,
                productName,
                clientProductId,
                productDescription,
                fulfillmentHouseId,
                productQty,
                productCost,
                fulfillmentDelayHours,
                shippingCost,
                billerId,
                productSku,
                weight,
                cycle1_billDelay,
                cycle1_combinationId,
                cycle1_isShippable,
                cycle1_price,
                cycle1_productId,
                cycle1_shipPrice,
                cycle2_billDelay,
                cycle2_combinationId,
                cycle2_isShippable,
                cycle2_price,
                cycle2_productId,
                cycle2_shipPrice,
                cycle3_billDelay,
                cycle3_combinationId,
                cycle3_isShippable,
                cycle3_price,
                cycle3_productId,
                cycle3_shipPrice
            }
        } = this.state;
        const { selectTemplate, pageTitle, insideEditFunnel, closeModal, upsells, isEdit, pageType, merchantId, fromEditFunnel } = this.props;

        const fulfillmentHouseOptions = fulfillmentHouseList && fulfillmentHouseList.map((fulfillmentHouse, i) => <option selected={fulfillmentHouse.fulfillmentHouseId === parseInt(fulfillmentHouseId)} value={fulfillmentHouse.fulfillmentHouseId}>{fulfillmentHouse.fulfillmentHouseTitle}</option>);
        const merchantListOptions = merchantList && merchantList.map((merchant, i) => <option key={i} selected={merchant.billerId === billerId || merchant.billerId === parseInt(merchantId)} value={merchant.billerId}>{merchant.title}</option>);
        let defaultValue = fromEditFunnel ? baseProductName ? baseProductName : productName : productName;
        return (
            <div className="new_product_container">
                {isFulfillmentHouseVisible && <Modal
                    show
                    style={{ opacity: 1 }}
                    bsSize="lg"
                    className="wizard_modal"
                    aria-labelledby="contained-modal-title-vcenter"
                    animation
                >
                    <Modal.Body className="custom_modal_body">
                        <div className="modal-header-funnel-edit">
                            {fulfillmentType === "ADD" ? "Create" : "Edit"}  {componentType === "FulfillmentHouse" ? "Fulfillment House" : "Merchant Account"}
                        </div>
                        <div className="modal-body-funnel-edit min-height-720">
                            {componentType === "FulfillmentHouse" ?
                                (<CreateEditFulfillment closeFulfillmentModal={() => this.setState({ isFulfillmentHouseVisible: false })} fromProduct refreshFulfillmentList={this.getFulfillmentHouse} fulfillmentDetails={fulfillmentType === "EDIT" ? fulfillmentDetails : null}/>) :
                                (<CreateEditMerchant closeFulfillmentModal={() => this.setState({ isFulfillmentHouseVisible: false })} fromProduct merchantDetails={fulfillmentType === "EDIT" ? merchantDetails : null} refreshMerchantList={this.getMerchantList} />)
                            }
                        </div>
                        <div className="close_button" onClick={(e) => this.setState({ isFulfillmentHouseVisible: false })}>
                            <span>&times;</span>
                        </div>
                    </Modal.Body>
                </Modal>}
                <div className="container-fluid page">
                    <div ref="productName"></div>
                    {!insideEditFunnel && <h4 className="campaign-heading">
                        {`${isEdit ? "Edit" : "Create New"} Product`}
                    </h4>}
                    <Row>
                        <Col md={6} className="pbc-4">
                            <FormGroup>
                                <ControlLabel className="custom-form-label">Product Name</ControlLabel>
                                <FormControl
                                    type="text"
                                    defaultValue={defaultValue}
                                    onChange={(e) => this.setState({ product: { ...this.state.product, productName: e.target.value } })}
                                />
                                <div className={`error_box ${formErrors.includes("productName") ? "erractive" : ""}`}>
                                    <span>Product Name is required</span>
                                </div>
                            </FormGroup>
                        </Col>
                        <Col md={6} className="pbc-4">
                            <FormGroup>
                                <ControlLabel className="custom-form-label display-flex-tooltip-icon">
                                    Client ID
                                    <ToolTip
                                        title="Client ID"
                                        content="This ID field can be used to match this product against 3rd party data sources."
                                        placement="right"
                                    />
                                </ControlLabel>
                                <FormControl
                                    type="text"
                                    defaultValue={clientProductId}
                                    onChange={(e) => this.setState({ product: { ...this.state.product, clientProductId: e.target.value } })}
                                />
                            </FormGroup>
                        </Col>
                        <Col md={12} className="pbc-4">
                            <FormGroup>
                                <ControlLabel className="custom-form-label">Product Description</ControlLabel>
                                <FormControl
                                    componentClass="textarea"
                                    defaultValue={productDescription}
                                    className="text-area-height"
                                    onChange={(e) => this.setState({ product: { ...this.state.product, productDescription: e.target.value } })}
                                />
                            </FormGroup>
                        </Col>
                        {upsells && <><Col md={4} className="pbc-4">
                            <div className="checkbox checkbox-success checkbox-md custom-checkbox mt-0">
                                <label className="custom-checkbox mb-10">
                                    <div className={`check-box-label ${showToAffiliates ? "text-color" : ""}`}>Show to Affiliates?</div>
                                    <input type="checkbox" onChange={(e) => this.setState({ showToAffiliates: e.target.checked ? 1 : 0 })} defaultChecked={showToAffiliates === 1} />
                                    <span className="check-box"></span>
                                </label>
                            </div>
                        </Col>
                            <Col md={4} className="pbc-4">
                                <div className="checkbox checkbox-success checkbox-md custom-checkbox mt-0">
                                    <label className="custom-checkbox mb-10">
                                        <div className={`check-box-label ${useOfferMid ? "text-color" : ""}`}>Use Offer Mid</div>
                                        <input type="checkbox" onChange={(e) => this.setState({ useOfferMid: e.target.checked ? 1 : 0 })} defaultChecked={useOfferMid === 1} />
                                        <span className="check-box"></span>
                                    </label>
                                </div>
                            </Col></>}
                        <Col md={6} className="pbc-4">
                            <FormGroup>
                                <ControlLabel className="custom-form-label display-flex-tooltip-icon">
                                    Fulfillment House
                                    <ToolTip
                                        title="Fulfillment House"
                                        content="Fulfillment houses can be set up on the Plugins Page."
                                        placement="right"
                                    />
                                </ControlLabel>
                                <Row>
                                    <Col md={8} className="pl-0 pr-0">
                                        <FormControl
                                            componentClass="select"
                                            className="primary-black hover-primary-color"
                                            onChange={(e) => {this.setState({ product: { ...this.state.product, fulfillmentHouseId: e.target.value } }, () => this.handleFulfillment(this.state.product.fulfillmentHouseId));}}
                                        >
                                            <option value="0" selected>System Fulfillment</option>
                                            {fulfillmentHouseOptions}
                                        </FormControl>
                                    </Col>
                                    <Col md={4} className="pr-0 pl-0">
                                        <Button
                                            type="primary"
                                            className="button add"
                                            onClick={(e) => this.setState({ isFulfillmentHouseVisible: true, componentType: "FulfillmentHouse", fulfillmentType: "ADD" })}
                                        >
                                            <i class="fa fa-plus"></i>
                                            <ToolTip
                                                title="Add A Fulfillment House"
                                                placement="right"
                                                type="Button"
                                            />
                                        </Button>
                                        <Button
                                            type="primary"
                                            className="button add"
                                            disabled={!fulfillmentHouseId}
                                            onClick={(e) => {this.setState({ isFulfillmentHouseVisible: true, componentType: "FulfillmentHouse", fulfillmentType: "EDIT" });}}
                                        >
                                            <i class="fa fa-pencil"></i>
                                            <ToolTip
                                                title="Edit A Fulfillment House"
                                                placement="right"
                                                type="Button"
                                            />
                                        </Button>
                                    </Col>
                                </Row>
                            </FormGroup>
                        </Col>
                        <Col md={6} className="pbc-4">
                            <FormGroup>
                                <ControlLabel className="custom-form-label display-flex-tooltip-icon">
                                    Qty Per Order
                                    <ToolTip
                                        title="Quantity Per Order"
                                        content="The Quantity of the SKU per product purchased for which to request fulfillment."
                                        placement="right"
                                    />
                                </ControlLabel>
                                <FormControl
                                    className="form-control-width"
                                    type="text"
                                    defaultValue={productQty}
                                    onChange={(e) => this.setState({ product: { ...this.state.product, productQty: numberOnly(e) } })}
                                />
                            </FormGroup>
                        </Col>
                        {/* <Col md={6} className="pbc-4">
                            <FormGroup>
                                <ControlLabel className="custom-form-label">Fulfillment Type</ControlLabel>
                                <FormControl componentClass="select" placeholder="Currency" className="primary-black hover-primary-color">
                                    <option value="select">Rocketgate</option>
                                </FormControl>
                            </FormGroup>
                        </Col> */}
                        <Col md={6} className="pbc-4">
                            <FormGroup>
                                <ControlLabel className="custom-form-label display-flex-tooltip-icon">
                                    Product Cost
                                    <ToolTip
                                        title="Product Cost"
                                        content="Your product cost (used to calculate expenses in reporting)"
                                        // note="*Not the customer's shipping price"
                                        placement="right"
                                    />
                                </ControlLabel>
                                <FormControl
                                    className="form-control-width"
                                    type="text"
                                    defaultValue={productCost}
                                    onChange={(e) => this.setState({ product: { ...this.state.product, productCost: numberOnly(e) } })}
                                />
                            </FormGroup>
                        </Col>
                        <Col md={6} className="pbc-4">
                            <FormGroup>
                                <ControlLabel className="custom-form-label display-flex-tooltip-icon">
                                    Fulfillment Delay
                                    <ToolTip
                                        title="Fulfillment Delay"
                                        content="Number of hours to delay fulfillment. A fulfillment delay allows time for same-day cancellations to be taken into account before fulfillment is sent."
                                        placement="right"
                                    />
                                </ControlLabel>
                                <InputGroup className="form-control-addon-width">
                                    <FormControl
                                        type="text"
                                        defaultValue={fulfillmentDelayHours}
                                        onChange={(e) => this.setState({ product: { ...this.state.product, fulfillmentDelayHours: numberOnly(e) } })}
                                    />
                                    <InputGroup.Addon className="input-group-background">Hours</InputGroup.Addon>
                                </InputGroup>
                            </FormGroup>
                        </Col>
                        <Col md={6} className="pbc-4">
                            <FormGroup>
                                <ControlLabel className="custom-form-label display-flex-tooltip-icon">
                                    Shipping Cost
                                    <ToolTip
                                        title="Shipping Cost"
                                        content="Your shipping cost (used to calculate expenses in reporting)."
                                        note="*Not what the customer pays"
                                        placement="right"
                                    />
                                </ControlLabel>
                                <FormControl
                                    className="form-control-width"
                                    type="text"
                                    defaultValue={shippingCost}
                                    onChange={(e) => this.setState({ product: { ...this.state.product, shippingCost: numberOnly(e) } })}
                                />
                            </FormGroup>
                        </Col>
                        <Col md={6} className="pbc-4">
                            <div ref="billerId"></div>
                            <FormGroup>
                                <ControlLabel className="custom-form-label">Merchant Account</ControlLabel>
                                <Row>
                                    <Col md={9} className="pr-0 pl-0">
                                        <FormControl componentClass="select" className={`primary-black hover-primary-color ${formErrors.includes("billerId") ? "select_error" : ""}`} disabled={useOfferMid} onChange={(e) => {this.setState({ product: { ...this.state.product, billerId: e.target.value } }, () => this.handleMerchant(this.state.product.billerId));}}>
                                            <option selected disabled>Select Merchant Account</option>
                                            {merchantListOptions}
                                        </FormControl>
                                    </Col>
                                    <Col md={3} className="pr-0 pl-0">
                                        <Button
                                            type="primary"
                                            className="button add"
                                            onClick={(e) => this.setState({ isFulfillmentHouseVisible: true, componentType: "MerchantAccount", fulfillmentType: "ADD" })}
                                        >
                                            <i class="fa fa-plus"></i>
                                            <ToolTip
                                                title="Add A Merchant Account "
                                                placement="right"
                                                type="Button"
                                            />
                                        </Button>
                                        <Button
                                            type="primary"
                                            disabled={!billerId || useOfferMid}
                                            className="button add"
                                            onClick={(e) => {this.setState({ isFulfillmentHouseVisible: true, componentType: "MerchantAccount", fulfillmentType: "EDIT" });}}
                                        >
                                            <i class="fa fa-pencil"></i>
                                            <ToolTip
                                                title="Edit A Merchant Account "
                                                placement="right"
                                                type="Button"
                                            />
                                        </Button>
                                    </Col>
                                </Row>
                            </FormGroup>
                        </Col>
                        <Col md={6} className="pbc-4">
                            <FormGroup>
                                <ControlLabel className="custom-form-label">Product SKU</ControlLabel>
                                <FormControl
                                    type="text"
                                    defaultValue={productSku}
                                    onChange={(e) => this.setState({ product: { ...this.state.product, productSku: e.target.value } })}
                                />
                            </FormGroup>
                        </Col>
                        <Col md={6} className="pbc-4">
                            <FormGroup>
                                <ControlLabel className="custom-form-label display-flex-tooltip-icon">
                                    Weight (lbs)
                                    <ToolTip
                                        title="Weight"
                                        content="Item weight per SKU in pounds."
                                        placement="right"
                                    />
                                </ControlLabel>
                                <FormControl
                                    type="text"
                                    defaultValue={weight}
                                    onChange={(e) => this.setState({ product: { ...this.state.product, weight: numberOnly(e) } })}
                                />
                            </FormGroup>
                        </Col>
                        <Col md={6} className="pbc-4">
                            <FormGroup>
                                <ControlLabel className="custom-form-label">Billing Type</ControlLabel>
                                <FormControl
                                    componentClass="select"
                                    className="primary-black hover-primary-color"
                                    onChange={(e) => this.setState({ product: { ...this.state.product, billingCycleType: e.target.value } })} >
                                    <option value="ONE_TIME" selected={billingCycleType === "ONE_TIME"}>ONE TIME</option>
                                    <option value="RECURRING" selected={billingCycleType === "RECURRING"}>RECURRING</option>
                                </FormControl>
                            </FormGroup>
                        </Col>
                        <Col md={12}>
                            <div ref="table"></div>
                            <table className="table crm-table">
                                <thead>
                                    <tr>
                                        <th>Cycle</th>
                                        <th>Price</th>
                                        <th>Ship Price</th>
                                        <th>Shippable</th>
                                        <th style={billingCycleType !== "RECURRING" ? { display: "none" } : null}>Bill Delay</th>
                                        <th>Product</th>
                                        <th style={billingCycleType !== "RECURRING" ? { display: "none" } : null}>Combination</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>1</td>
                                        <td>
                                            <div className="flex">
                                                <FormGroup>
                                                    <InputGroup>
                                                        <InputGroup.Addon className="input-group-background">{this.props.currencySymbol ? this.props.currencySymbol : "$" }</InputGroup.Addon>
                                                        <FormControl className={`w-75 ${formErrors.includes("cycle1_price") ? "select_error" : ""}`} type="text" defaultValue={cycle1_price} name={"cycle1_price"} onChange={(e) => {numberOnly(e); this.handleChange(e);}} />
                                                    </InputGroup>
                                                </FormGroup>
                                            </div>
                                        </td>
                                        <td>
                                            <div className="flex">
                                                <FormGroup>
                                                    <InputGroup>
                                                        <InputGroup.Addon className="input-group-background">{this.props.currencySymbol ? this.props.currencySymbol : "$" }</InputGroup.Addon>
                                                        <FormControl className={`w-75 ${formErrors.includes("cycle1_shipPrice") ? "select_error" : ""}`} type="text" defaultValue={cycle1_shipPrice} name={"cycle1_shipPrice"} onChange={(e) => {numberOnly(e); this.handleChange(e);}} />
                                                    </InputGroup>
                                                </FormGroup>
                                            </div>
                                        </td>
                                        <td>
                                            <div className="flex">
                                                <div className="checkbox checkbox-success checkbox-md custom-checkbox">
                                                    <label class="custom-checkbox">
                                                        <div className="check-box-label"></div>
                                                        <input type="checkbox" defaultChecked={cycle1_isShippable} name="isShippable" onChange={(e) => this.handleChange(e, "checkbox")} name={"cycle1_isShippable"} />
                                                        <span class="check-box"></span>
                                                    </label>
                                                </div>
                                            </div>
                                        </td>
                                        <td style={billingCycleType !== "RECURRING" ? { display: "none" } : null}>
                                            <div className="flex">
                                                <FormGroup className="flex-between">
                                                    <div>
                                                        <FormControl
                                                            type="text"
                                                            name="billDelay"
                                                            style={{ width: 60 }}
                                                            defaultValue={cycle1_billDelay}
                                                            className={`${formErrors.includes("cycle1_billDelay") ? "select_error" : ""}`}
                                                            name={"cycle1_billDelay"}
                                                            onChange={(e) => {numberOnly(e); this.handleChange(e);}}
                                                        />
                                                        {formErrors.includes("cycle1_billDelay_greater") && <span className="error_text">should be &lt; 365</span>}
                                                        {formErrors.includes("cycle1_billDelay_must_have_value_greater_than_zero") && <span className="error_text font-11">Price or ship price should be > 0</span>}
                                                    </div>
                                                    <span>Days</span>
                                                </FormGroup>
                                            </div>
                                        </td>
                                        <td>
                                            <div className="flex">
                                                <FormGroup>
                                                    <FormControl style={{ width: 150 }} type="text" value={defaultValue} disabled />
                                                </FormGroup>
                                            </div>
                                        </td>
                                        <td style={billingCycleType !== "RECURRING" ? { display: "none" } : null}>{/*Combinations*/}
                                            <select className="form-control" style={{ width: 150 }} onChange={(e) => this.handleChange(e)} name={"cycle1_combinationId"}>
                                                <option></option>
                                                {
                                                    productList && productList.map(product => (
                                                        <option key={product.productId} value={product.productId} selected={product.productId === cycle1_combinationId} >{product.productName}</option>
                                                    ))
                                                }
                                            </select>
                                        </td>
                                    </tr>
                                    {
                                        billingCycleType === "RECURRING" &&
                                        <Fragment>
                                            <tr>
                                                <td>2</td>
                                                <td>
                                                    <div className="flex">
                                                        <FormGroup>
                                                            <InputGroup>
                                                                <InputGroup.Addon className="input-group-background">{this.props.currencySymbol ? this.props.currencySymbol : "$" }</InputGroup.Addon>
                                                                <FormControl className={`w-75 ${formErrors.includes("cycle2_price") ? "select_error" : ""}`} type="text" defaultValue={cycle2_price === null ? 0 : cycle2_price} name={"cycle2_price"} onChange={(e) => {numberOnly(e); this.handleChange(e);}} />
                                                            </InputGroup>
                                                        </FormGroup>
                                                    </div>
                                                </td>
                                                <td>
                                                    <div className="flex">
                                                        <FormGroup>
                                                            <InputGroup>
                                                                <InputGroup.Addon className="input-group-background">{this.props.currencySymbol ? this.props.currencySymbol : "$" }</InputGroup.Addon>
                                                                <FormControl className={`w-75 ${formErrors.includes("cycle2_shipPrice") ? "select_error" : ""}`} type="text" defaultValue={cycle2_shipPrice === null ? 0 : cycle2_shipPrice} name={"cycle2_shipPrice"} onChange={(e) => {numberOnly(e); this.handleChange(e);}} />
                                                            </InputGroup>
                                                        </FormGroup>
                                                    </div>
                                                </td>
                                                <td>
                                                    <div className="flex">
                                                        <div className="checkbox checkbox-success checkbox-md custom-checkbox">
                                                            <label class="custom-checkbox">
                                                                <div className="check-box-label"></div>
                                                                <input type="checkbox" defaultChecked={cycle2_isShippable} name="isShippable" onChange={(e) => this.handleChange(e, "checkbox")} name={"cycle2_isShippable"} />
                                                                <span class="check-box"></span>
                                                            </label>
                                                        </div>
                                                    </div>
                                                </td>
                                                <td style={billingCycleType !== "RECURRING" ? { display: "none" } : null}>
                                                    <div className="flex">
                                                        <FormGroup className="flex-between">
                                                            <div>
                                                                <FormControl
                                                                    type="text"
                                                                    defaultValue={cycle2_billDelay}
                                                                    name="billDelay"
                                                                    style={{ width: 60 }}
                                                                    className={`${formErrors.includes("cycle2_billDelay") ? "select_error" : ""}`}
                                                                    name={"cycle2_billDelay"}
                                                                    onChange={(e) => {numberOnly(e); this.handleChange(e);}}
                                                                />
                                                                {formErrors.includes("cycle2_billDelay_greater") && <span className="error_text">should be &lt; 365</span>}
                                                                {formErrors.includes("cycle2_billDelay_must_have_value_greater_than_zero") && <span className="error_text font-11">Price or ship price should be > 0</span>}
                                                            </div>
                                                            <span>Days</span>
                                                        </FormGroup>
                                                    </div>
                                                </td>
                                                <td>
                                                    <select className="form-control" name={"cycle2_productId"} style={{ width: 150 }} onChange={(e) => this.handleChange(e)}>
                                                        <option></option>
                                                        {
                                                            productList && productList.map(product => (
                                                                <option key={product.productId} value={product.productId} selected={product.productId === cycle2_productId}>{product.productName}</option>
                                                            ))
                                                        }
                                                    </select>
                                                </td>
                                                <td style={billingCycleType !== "RECURRING" ? { display: "none" } : null}>{/*Combinations*/}
                                                    <select className="form-control" style={{ width: 150 }} onChange={(e) => this.handleChange(e)} name={"cycle2_combinationId"}>
                                                        <option></option>
                                                        {
                                                            productList && productList.map(product => (
                                                                <option key={product.productId} value={product.productId} selected={product.productId === cycle2_combinationId}>{product.productName}</option>
                                                            ))
                                                        }
                                                    </select>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>3</td>
                                                <td>
                                                    <div className="flex">
                                                        <FormGroup>
                                                            <InputGroup>
                                                                <InputGroup.Addon className="input-group-background">{this.props.currencySymbol ? this.props.currencySymbol : "$" }</InputGroup.Addon>
                                                                <FormControl className={`w-75 ${formErrors.includes("cycle3_price") ? "select_error" : ""}`} type="text" defaultValue={cycle3_price === null ? "0" : cycle3_price} name={"cycle3_price"} onChange={(e) => {numberOnly(e); this.handleChange(e);}} />
                                                            </InputGroup>
                                                        </FormGroup>
                                                    </div>
                                                </td>
                                                <td>
                                                    <div className="flex">
                                                        <FormGroup>
                                                            <InputGroup>
                                                                <InputGroup.Addon className="input-group-background">{this.props.currencySymbol ? this.props.currencySymbol : "$" }</InputGroup.Addon>
                                                                <FormControl className={`w-75 ${formErrors.includes("cycle3_shipPrice") ? "select_error" : ""}`} type="text" defaultValue={cycle3_shipPrice === null ? 0 : cycle3_shipPrice} name={"cycle3_shipPrice"} onChange={(e) => {numberOnly(e); this.handleChange(e);}} />
                                                            </InputGroup>
                                                        </FormGroup>
                                                    </div>
                                                </td>
                                                <td>
                                                    <div className="flex">
                                                        <div className="checkbox checkbox-success checkbox-md custom-checkbox">
                                                            <label class="custom-checkbox">
                                                                <div className="check-box-label"></div>
                                                                <input type="checkbox" defaultChecked={cycle3_isShippable} name="isShippable" onChange={(e) => this.handleChange(e, "checkbox")} name={"cycle3_isShippable"} />
                                                                <span class="check-box"></span>
                                                            </label>
                                                        </div>
                                                    </div>
                                                </td>
                                                <td style={billingCycleType !== "RECURRING" ? { display: "none" } : null}>
                                                    <div className="flex">
                                                        <FormGroup className="flex-between">
                                                            <div>
                                                                <FormControl
                                                                    type="text"
                                                                    className={`${formErrors.includes("cycle3_billDelay") ? "select_error" : ""}`}
                                                                    defaultValue={cycle3_billDelay}
                                                                    name="billDelay"
                                                                    style={{ width: 60 }}
                                                                    name={"cycle3_billDelay"}
                                                                    onChange={(e) => {numberOnly(e); this.handleChange(e);}}
                                                                />
                                                                {formErrors.includes("cycle3_billDelay_greater") && <span className="error_text">should be &lt; 365</span>}
                                                                {formErrors.includes("cycle3_billDelay_must_have_value_greater_than_zero") && <span className="error_text font-11">Price or ship price should be > 0</span>}
                                                            </div>
                                                            <span>Days</span>
                                                        </FormGroup>
                                                    </div>
                                                </td>
                                                <td>
                                                    <select className="form-control" name={"cycle3_productId"} style={{ width: 150 }} onChange={(e) => this.handleChange(e)}>
                                                        <option></option>
                                                        {
                                                            productList && productList.map(product => (
                                                                <option key={product.productId} value={product.productId} selected={product.productId === cycle3_productId}>{product.productName}</option>
                                                            ))
                                                        }
                                                    </select>
                                                </td>
                                                <td style={billingCycleType !== "RECURRING" ? { display: "none" } : null}>{/*Combinations*/}
                                                    <select className="form-control" style={{ width: 150 }} onChange={(e) => this.handleChange(e)} name={"cycle3_combinationId"}>
                                                        <option></option>
                                                        {
                                                            productList && productList.map(product => (
                                                                <option key={product.productId} value={product.productId} selected={product.productId === cycle3_combinationId}>{product.productName}</option>
                                                            ))
                                                        }
                                                    </select>
                                                </td>
                                            </tr>
                                        </Fragment>
                                    }
                                </tbody>
                            </table>
                        </Col>
                    </Row>

                    <Row className="footer" style={{ paddingRight: 15 }}>
                        <div className="pr-0">
                            <Button className="cancelButton" onClick={(e) => {insideEditFunnel ? closeModal(e) : selectTemplate(null);}}>Cancel</Button>
                        </div>
                        <div className="pl-0">
                            <CRMButton isLoading={isLoading} handleClick={(e) => pageType === "Edit" && !this.props.fromStepwizard && !this.props.fromSelectTemplate ? this.handleUpdate(e) : this.handleSubmit(e)} text="Save & Continue"/>
                        </div>
                    </Row>
                </div>
            </div>
        );
    }
}

const mapDispatchToProps = (dispatch) => ({
    updateFunnelInfo: (funnel) => dispatch(updateFunnelInfo(funnel))
});

export default connect(null, mapDispatchToProps)(CreateEditProduct);

CreateEditProduct.propTypes = {
    selectTemplate: PropTypes.func,
    pageTitle: PropTypes.string,
    nextOfferUpShell: PropTypes.func,
    moveStep: PropTypes.func,
    offers: PropTypes.array,
    handleSkipButton: PropTypes.func,
    insideEditFunnel: PropTypes.bool,
    closeModal: PropTypes.func,
    upsells: PropTypes.bool,
    isEdit: PropTypes.bool,
    campaignId: PropTypes.number,
    selectedProductId: PropTypes.number,
    pageType: PropTypes.string,
    editData: PropTypes.object,
    refreshData: PropTypes.func,
    merchantId: PropTypes.number,
    currencySymbol: PropTypes.string,
    fromStepwizard: PropTypes.bool,
    isEnableLoading: PropTypes.func,
    fromSelectTemplate: PropTypes.bool,
    fromEditFunnel: PropTypes.bool,
    wholeFunnelDetails: PropTypes.object,
    funnelDetails: PropTypes.object,
    productType: PropTypes.string,
    updateFunnelInfo: PropTypes.func,
    getFunnelDetails: PropTypes.func,
    funnelData: PropTypes.object,
    offerArray: PropTypes.array,
    upsaleArray: PropTypes.array,
    setOfferUpsale: PropTypes.func,
    isInsideTheVisualizer: PropTypes.bool
};