import React from "react";
import PropTypes from "prop-types";
import isObject from "lodash/isObject";
import isFunction from "lodash/isFunction";
import isNumber from "lodash/isNumber";

/*
 * Utility function to generate conversion rate in percentage from total page views
 */
export const getConversionRate = (data = {}) => {
    const conversionPercentage = ((data.totalConversions / data.totalPageViews) * 100);
    return (isNumber(conversionPercentage) && conversionPercentage > 0) ? conversionPercentage === 100 ? 100 : conversionPercentage.toFixed(1) : 0;
};

/*
 * Values to be displayed in component AnalyticsContent
 */
const analyticsFields = [
    { label: "Page Hits", key: "totalPageViews" },
    { label: "Page Submits", key: "totalConversions" },
    { label: "Conversion %", valueFn: getConversionRate },
];

const getAlphabet = (index) => {
    return (index + 10).toString(36).toUpperCase();
};


/*
 * AnalyticsContent
 * type - @component
 *
 * Display the Analytical Information of the individual pages in visualizer
 */

const AnalyticsContent = ({ position, data = [], isSplitEnabled, graphScale = 1 }) => {
    let style = {};
    const getScaledPositions = (value) => value * graphScale;

    if (isObject(position) && position.x) {
        if (isNumber(graphScale)) {
            style = {
                left: getScaledPositions(position.x + 1),
                top: getScaledPositions(position.y + 1),
                height: getScaledPositions(160),
                width: getScaledPositions(118),
                fontSize: getScaledPositions(10),
            };
        }
    }
    return (
        <div style={style} className="visualizer-pageview-element-analytics">
            {
                data.map((page, index) => (
                    <div>
                        <div className="analytics-content-header">
                            {
                                isSplitEnabled && getAlphabet(index)
                            }
                        </div>
                        {
                            analyticsFields.map((field) => {
                                const value = isFunction(field.valueFn) ? field.valueFn(page) : page[field.key];
                                return (
                                    <div className="row analytics-row" key={field.label}>
                                        <div className="col-sm-9">
                                            { field.label }
                                        </div>
                                        <div className="col-sm-2 text-left analytics-field-value">
                                            { value || 0 }
                                        </div>
                                    </div>
                                );
                            })
                        }
                    </div>
                ))
            }
        </div>
    );
};

AnalyticsContent.propTypes = {
    position: PropTypes.object,
    data: PropTypes.array,
    isSplitEnabled: PropTypes.bool,
    graphScale: PropTypes.number,
};

export default AnalyticsContent;
