const handleBackgroundImageChange = (editor, component) => {
    if (component.changed.backgroundImage) {
        var sm = editor.SelectorManager;
        var bacImage = sm.add("backgroundImage");
        var rule = editor.CssComposer.add([bacImage]);
        rule.set("style", {
            "background-image": "url(' + component.changed.backgroundImage + ') !important"
        });
        editor.getSelected().addClass("backgroundImage");
    } else {
        editor.getSelected().removeClass("backgroundImage");
    }
};

export default handleBackgroundImageChange;