import { FUNNEL_REDUCER_KEY } from "../reducers/funnel-reducer";

export const getFunnelState = (state) => {
    return state[FUNNEL_REDUCER_KEY];
};

export const getFunnelData = (state) => {
    return state[FUNNEL_REDUCER_KEY].funnelData;
};
export const editedFunnelData = (state) => {
    return state[FUNNEL_REDUCER_KEY].funnel;
};
export const getMetaData = (state) => {
    return state[FUNNEL_REDUCER_KEY].metaUrl;
};

export const getTemplateHtml = (state) => {
    return state[FUNNEL_REDUCER_KEY].htmlPage;
};
export const getJsonData = (state) => {
    return state[FUNNEL_REDUCER_KEY].jsonData;
};

export const getPages = (state) => {
    return state[FUNNEL_REDUCER_KEY].pages.length ? state[FUNNEL_REDUCER_KEY].pages.filter(p => !p.deleted) : [];
};

export const getCurrentPageId = (state) => {
    return state[FUNNEL_REDUCER_KEY].currentPageId;
};

export const getLoader = (state) => {
    return state[FUNNEL_REDUCER_KEY].isLoading;
};
export const getLinkStatus = (state) => {
    return state[FUNNEL_REDUCER_KEY].isLink;
};
export const getCreated = (state) => {
    return state[FUNNEL_REDUCER_KEY].isCreated;
};
export const getSplitPage = (state) => {
    return state[FUNNEL_REDUCER_KEY].splitViewPage;
};
export const getSplitValue = (state) => {
    return state[FUNNEL_REDUCER_KEY].splitData;
};
export const getIsSaved = (state) => {
    return state[FUNNEL_REDUCER_KEY].isSaved;
};
export const getIsSaveLoading = (state) => {
    return state[FUNNEL_REDUCER_KEY].isSaveLoading;
};
export const getPageData = (state) => {
    return state[FUNNEL_REDUCER_KEY].pageData;
};
export const getGoogleFonts = (state) => {
    return state[FUNNEL_REDUCER_KEY].googleFonts;
};
export const getSavedFonts = (state) => {
    return state[FUNNEL_REDUCER_KEY].fonts;
};
export const getFontsLoading = (state) => {
    return state[FUNNEL_REDUCER_KEY].isFontLoading;
};
export const getError = (state) => {
    return state[FUNNEL_REDUCER_KEY].isError;
};
export const getPageError = (state) => {
    return state[FUNNEL_REDUCER_KEY].pageError;
};
export const getImportError = (state) => {
    return state[FUNNEL_REDUCER_KEY].importError;
};
export const getImportedPage = (state) => {
    return state[FUNNEL_REDUCER_KEY].importedPage;
};
export const getDomainList = (state) => {
    return state[FUNNEL_REDUCER_KEY].domainList;
};
export const getSelectedDomainForFunnel = (state) => {
    return state[FUNNEL_REDUCER_KEY].selectedDomainForFunnel;
};
export const getImportFunnelError = (state) => {
    return state[FUNNEL_REDUCER_KEY].importFunnelError;
};
export const getImportedFunnel = (state) => {
    return state[FUNNEL_REDUCER_KEY].importedFunnel;
};
export const getImportFunnelLoadingStatus = (state) => {
    return state[FUNNEL_REDUCER_KEY].isImportFunnelLoading;
};

export const getVisualizerData = (state) => {
    return state[FUNNEL_REDUCER_KEY].visualizer;
};
