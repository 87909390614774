import React, { Component } from "react";
import { Button, Row, Col } from "react-bootstrap";
import PropTypes from "prop-types";

import Forms from "../../Forms";
import { getSMTPList } from "../../utilities/api";

class SMTP extends Component {

    constructor(props) {
        super(props);
        this.state = {
            isLoading: false,
            activeList: null,
            type: null,
            campaignId: props.funnelDetails && props.funnelDetails.campaignId,
            SMTPList: null,
            SMTPDetails: null
        };
    }

    componentDidMount() {
        this.loadList();
    }

    loadList = () => {
        const { campaignId } = this.state;
        this.setState({
            isLoading: true
        });
        getSMTPList(campaignId)
            .then((res) => {
                this.setState({
                    SMTPList: Array.isArray(res.message) ? res.message : null,
                    SMTPDetails: null,
                    isLoading: false
                });
            })
            .catch((err) => {
                this.setState({
                    isLoading: false
                });
            });
    }

    activeComponent = (status, type, SMTPDetails) => {
        this.setState({
            activeList: status,
            type: type,
            SMTPDetails
        });
    }

    clearActiveList = () => {
        this.setState({
            activeList: null
        });
    }

    renderRow = (SMTPList) => {
        const { pageType, selectTemplate } = this.props;
        return SMTPList && SMTPList.map((SMTP, i) => (
            <tr className="edit-funnel-divider">
                <td className="edit-funnel-table-data font-weight-normal">{SMTP.smtpName}</td>
                <td>
                    <Button
                        type="primary btn-height"
                        className="button"
                        onClick={() => this.activeComponent("CreateEditSMTP", "Edit", SMTP)}
                    >
                        <i class="fa fa-pencil edit-pencil-color"></i>
                    </Button>
                </td>
            </tr>
        ));
    }

    render() {
        const { activeList, SMTPList, SMTPDetails, isLoading } = this.state;
        return (
            <div>
                <div className="modal-header-funnel-edit">
                    SMTP Accounts
                </div>
                <div className="table-overflow-scroll">
                    <Row className="p-30 pb-0">
                        <Col mdOffset={8} md={4} className="p-0">
                            <Button
                                type="primary"
                                className="button btn-height primary-button-color float-right"
                                onClick={() => this.activeComponent("CreateEditSMTP", "ADD")}
                            >
                                <div className="button_text_area">
                                    {/* <span
                                        className={`${this.state.isLoading ? "loading_spinner" : ""}`}
                                    ></span> */}
                                    <span>Add New Account</span>
                                </div>
                            </Button>
                        </Col>
                    </Row>
                    <Row className="p-30">
                        <table className="edit-funnel-table">
                            <thead>
                                <tr className="edit-funnel-table-head">
                                    <th className="edit-funnel-merchant-table-heading">Name</th>
                                    <th></th>
                                </tr>
                            </thead>

                            <tbody>
                                { isLoading ?
                                    <tr className="position_absolute_flex_center">
                                        <div className={"loading_box"}>
                                            <span
                                                className="loading_spinner"
                                            ></span>
                                            <span>Loading</span>
                                        </div>
                                    </tr> :
                                    SMTPList && SMTPList.length !== 0 ? this.renderRow(SMTPList) :
                                        <tr className="edit-funnel-divider">
                                            <td colSpan={2} className="edit-funnel-table-data">No SMTP Found</td>
                                        </tr>
                                }
                            </tbody>
                        </table>
                    </Row>
                </div>
                <Row className="p-30">
                    <Col md={6} className="pl-0">
                    </Col>
                    <Col md={6} className="pr-0">
                        <Button
                            onClick={(e) => {this.props.closeModal(e);}}
                            type="primary"
                            className="button btn-height float-right"
                        >
                            Cancel
                        </Button>
                    </Col>
                </Row>

                <Forms refreshList={this.loadList} SMTPDetails={SMTPDetails} activeComponent={activeList} clearActiveList={this.clearActiveList} title={`${this.state.type === "ADD" ? "Add" : "Edit"} SMTP Account`}/>
            </div>
        );
    }
}

export default SMTP;

SMTP.propTypes = {
    closeModal: PropTypes.func,
    pageType: PropTypes.string,
    selectTemplate: PropTypes.func,
    campaignId: PropTypes.number,
    funnelDetails: PropTypes.array
};