export const UPDATE_STEP_PROGRESS = "walkthrough/UPDATE_STEP_PROGRESS";
export const RESET_WALKTHROUGH = "walkthrough/RESET_WALKTHROUGH";

const reducer = (state, action) => {
    switch (action.type) {
        case UPDATE_STEP_PROGRESS:
            return {
                ...state,
                progressSteps: [...action.progressSteps],
                currentStep: action.stepToNavigate,
            };
        case RESET_WALKTHROUGH:
            return action.initialState;
        default:
            return state;
    }
};

export default reducer;