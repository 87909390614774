export const addOnTraits = [
    {
        type: "select",
        label: "Corner Radius",
        changeProp: 1,
        name: "borderRadius",
        options: [
            { value: "5px", name: "5px" },
            { value: "10px", name: "10px" },
            { value: "15px", name: "15px" },
            { value: "20px", name: "20px" },
            { value: "25px", name: "25px" },
            { value: "50px", name: "50px" },
            { value: "75px", name: "75px" },
            { value: "150px", name: "150px" },
            { value: "100%", name: "100%" }]
    },
    {
        type: "select",
        label: "Edges",
        changeProp: 1,
        name: "borderRadiusEdges",
        options: [
            { value: "All", name: "All" },
            { value: "borderRadiusTop", name: "Top Only" },
            { value: "borderRadiusBottom", name: "Bottom Only" },
            { value: "None", name: "None" }
        ]

    },
    {
        type: "select",
        label: "Animate",
        name: "animate",
        changeProp: 1,
        options: [
            { value: "none", name: "None" },
            { value: "bounce", name: "bounce" },
            { value: "flash", name: "flash" },
            { value: "pulse", name: "pulse" },
            { value: "rubberband", name: "rubberband" },
            { value: "shake", name: "shake" },
            { value: "swing", name: "swing" },
            { value: "tada", name: "tada" },
            { value: "wobble", name: "wobble" },
            { value: "jello", name: "jello" },
            { value: "bounceIn", name: "bounceIn" },
            { value: "bounceInLeft", name: "bounceInLeft" },
            { value: "bounceInRight", name: "bounceInRight" },
            { value: "bounceInUp", name: "bounceInUp" },
            { value: "fadeIn", name: "fadeIn" },
            { value: "fadeInDown", name: "fadeInDown" },
            { value: "fadeInDownBig", name: "fadeInDownBig" },
            { value: "fadeInLeft", name: "fadeInLeft" },
            { value: "fadeInLeftBig", name: "fadeInLeftBig" },
            { value: "fadeInRight", name: "fadeInRight" },
            { value: "fadeInRightBig", name: "fadeInRightBig" },
            { value: "fadeInUp", name: "fadeInUp" },
            { value: "fadeInUpBig", name: "fadeInUpBig" },
            { value: "flipInX", name: "flipInX" },
            { value: "flipInY", name: "flipInY" },
            { value: "lightSpeedIn", name: "lightSpeedIn" },
            { value: "rotateIn", name: "rotateIn" },
            { value: "rollIn", name: "rollIn" },
            { value: "zoomIn", name: "zoomIn" },
            { value: "slideInDown", name: "slideInDown" },
        ]
    },
    {
        type: "input",
        label: "Animation Duration (Sec)",
        name: "animationDuration",
        changeProp: 1,
    },
];