import React, { Component } from "react";
import { Button, Row, Col } from "react-bootstrap";
import PropTypes from "prop-types";
import Forms from "../../Forms";
import { getMerchantList } from "../../utilities/api";

class MerchantAccounts extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: false,
            activeList: null,
            type: null,
            merchantDetails: null,
            merchantList: null
        };
    }

    componentDidMount() {
        this.loadList();
    }

    loadList = () => {
        this.setState({
            isLoading: true
        });
        getMerchantList()
            .then((res) => {
                this.setState({
                    merchantList: Array.isArray(res.message) ? res.message.filter(data => data.gatewayName !== null) : null,
                    merchantDetails: null,
                    isLoading: false
                });
            })
            .catch((err) => {
                this.setState({
                    isLoading: false
                });
            });
    }

    activeComponent = (status, type, merchantDetails) => {
        this.setState({
            activeList: status,
            type: type,
            merchantDetails
        });
    }

    clearActiveList = () => {
        this.setState({
            activeList: null
        });
    }

    renderRow = (merchantList) => {
        return merchantList && merchantList.map((merchant, i) => (
            <tr className="edit-funnel-divider">
                <td className="edit-funnel-table-data font-weight-normal">{merchant.title}</td>
                <td className="edit-funnel-table-data font-weight-normal">{merchant.gatewayName}</td>
                <td>
                    <Button
                        type="primary btn-height"
                        className="button"
                        disabled={this.state.isLoading}
                        onClick={() => this.activeComponent("CreateEditMerchant", "EDIT", merchant)}
                    >
                        <i class="fa fa-pencil edit-pencil-color"></i>
                    </Button>
                </td>
            </tr>
        ));
    }

    render() {
        const { activeList, merchantList, merchantDetails, isLoading } = this.state;
        return (
            <div>
                <div className="modal-header-funnel-edit">
                    Merchant Accounts
                </div>
                <div className="table-overflow-scroll">
                    <Row className="plr-50 pt-20">
                        <Col mdOffset={8} md={4} className="pr-0 pb-20">
                            <Button
                                type="primary"
                                className="button btn-height primary-button-color float-right"
                                onClick={() => this.activeComponent("CreateEditMerchant", "ADD")}
                            >
                                Add New Merchant Account
                            </Button>
                        </Col>
                        <table className="edit-funnel-table">
                            <thead>
                                <tr className="edit-funnel-table-head">
                                    <th className="width-35 edit-funnel-merchant-table-heading">Name</th>
                                    <th className="width-35 edit-funnel-merchant-table-heading">Gateway</th>
                                    <th></th>
                                </tr>
                            </thead>

                            <tbody>
                                { isLoading ?
                                    <tr className="position_absolute_flex_center">
                                        <div className={"loading_box"}>
                                            <span
                                                className="loading_spinner"
                                            ></span>
                                            <span>Loading</span>
                                        </div>
                                    </tr> :
                                    merchantList && merchantList.length !== 0 ? this.renderRow(merchantList) :
                                        <tr className="edit-funnel-divider">
                                            <td colSpan={3} className="edit-funnel-table-data">No Merchant Found</td>
                                        </tr>
                                }
                            </tbody>
                        </table>
                    </Row>
                </div>
                <Row className="plr-50 pt-20 pb-10">
                    <Col mdOffset={6} md={6} className="pr-0">
                        <Button
                            onClick={(e) => {this.props.closeModal(e);}}
                            type="primary"
                            className="button btn-height float-right"
                        >
                            Cancel
                        </Button>
                    </Col>
                </Row>

                <Forms {...this.props} refreshFulfillmentList={this.loadList} merchantDetails={merchantDetails} activeComponent={activeList} clearActiveList={this.clearActiveList} title={`${this.state.type === "ADD" ? "Add" : "Edit"} Merchant`} />
            </div>
        );
    }
}

export default MerchantAccounts;

MerchantAccounts.propTypes = {
    closeModal: PropTypes.func
};