import React, { Component } from "react";
import { Button, FormGroup, ControlLabel, Col, Row, FormControl, InputGroup } from "react-bootstrap";
import PropTypes from "prop-types";

import InfoCircle from "../../info-circle";
import LabelBox from "../../label-box";
import ToolTip from "../../ToolTip";
import { NotificationEmitter, NOTIFICATION_EVENT_NAME } from "../../../commons/notification";
import { getGateWayListQuery, createMerchant, updateMerchant } from "../../utilities/api";
import { numberOnly } from "../../utilities";
import CRMButton from "../../CRMButton";
import CRMCheckbox from "../../CRMCheckbox";
import CRMLoading from "../../CRMLoading";

class CreateEditMerchant extends Component {

    constructor(props) {
        super(props);
        this.state = {
            hasError: false,
            isLoading: false,
            merchant: Object.assign({}, this.getMerchantInitialState()),
            gatewayList: null,
            inputFields: props.merchantDetails ? this.getInputfields() : null,
            gatewayData: null,
            formErrors: [],
            resetUniqueField: false
        };
    }

    componentDidMount() {
        if (this.props.pageType === "step-wizard") {
            this.props.handleSkipButton(false);
        }
        this.fetchList();
    }

    fetchList = () => {
        getGateWayListQuery()
            .then((res) => {
                this.setState({
                    gatewayList: res.message.filter(data => data.gatewayName !== null)
                });
            });
    }

    isValid = ({ title, midNumber, descriptor, gatewayId }) => {
        let formErrors = [];
        if (!title) {
            formErrors.push("title");
        }
        if (!gatewayId) {
            formErrors.push("gatewayId");
        }
        if (!midNumber) {
            formErrors.push("midNumber");
        }
        if (!descriptor) {
            formErrors.push("descriptor");
        }
        this.setState({
            formErrors
        });
        return formErrors.length === 0;
    }

    handleSubmit = (e) => {
        e.preventDefault();
        const { merchant } = this.state;
        const { merchantDetails, closeModal, setMerchantId, fromProduct, closeFulfillmentModal, refreshMerchantList } = this.props;
        this.setState({
            isLoading: true
        });
        if (this.isValid(merchant)) {
            let data = {
                ...merchant,
                gatewayData: JSON.stringify(merchant.gatewayData)
            };
            createMerchant(data)
                .then((res) => {
                    this.setState({
                        isLoading: false
                    });
                    fromProduct ? closeFulfillmentModal() : closeModal && closeModal(e);
                    NotificationEmitter.emit(NOTIFICATION_EVENT_NAME, { type: "success", message: "Merchant created successfully" });
                    if (!merchantDetails) {
                        setMerchantId && setMerchantId(res.data.message.billerId);
                        this.props.moveStep && this.props.moveStep("NEXT");
                        refreshMerchantList && refreshMerchantList();
                    } else {
                        if (fromProduct) {
                            refreshMerchantList();
                            closeFulfillmentModal();
                        } else {
                            closeModal(e);
                        }
                    }
                })
                .catch((err) => {
                    this.setState({
                        isLoading: false
                    });
                    NotificationEmitter.emit(NOTIFICATION_EVENT_NAME, { type: "error", message: err.response.data.message });
                });
        } else {
            this.setState({
                isLoading: false
            });
            if (!this.state.merchant.title) {
                this.refs.title.scrollIntoView({ behavior: "smooth" });
            }
        }
    }

    handleGateway = (e) => {
        new Promise(resolve => {
            this.setState({
                resetUniqueField: true,
                inputFields: null,
                merchant: { ...this.state.merchant, gatewayId: parseInt(e.target.value) }
            });
            let gateway = this.state.gatewayList.filter(gateway => gateway.gatewayId === parseInt(e.target.value));
            if (gateway[0].uniqueFields) {
                let uniqueField = gateway[0].uniqueFields.split("\n");
                let uniqueFields = uniqueField.filter(uniqueFields => uniqueFields !== "");
                let inputFields = uniqueFields.map(data => {
                    let splittedData = data.split(":");
                    let typeSplittedData = splittedData[1].split("|");
                    if (typeSplittedData.length === 1) {
                        return {
                            labelName: splittedData[0],
                            type: typeSplittedData[0]
                        };
                    }
                    if (typeSplittedData.length === 2) {
                        return {
                            labelName: splittedData[0],
                            type: typeSplittedData[0],
                            options: typeSplittedData[1].split(";")
                        };
                    }
                });
                let inputGatewayData = inputFields.map((input, i) => {
                    return {
                        [input.labelName]: null
                    };
                });
                this.setState({
                    inputFields,
                    merchant: { ...this.state.merchant, gatewayData: Object.assign({}, ...inputGatewayData), gatewayId: parseInt(e.target.value) },
                });
            }
            resolve();
        }).then(() => {
            this.setState({
                resetUniqueField: false
            });
        });
    }

    renderInputs = () => {
        const { inputFields, merchant: { gatewayData } } = this.state;

        return inputFields.map((input, i) => {
            return (<Col md={6} className="pbc-4 pr-40">
                <Row>
                    <FormGroup>
                        <ControlLabel className="custom-form-label">{input.type.includes("BIT") ? null : input.labelName}</ControlLabel>
                        {
                            input.type.includes("STR") || input.type.includes("TEXT") ?
                                <FormControl
                                    // type={`${input.labelName.toLowerCase().includes("secret") || input.labelName.toLowerCase().includes("password") ? "password" : "text"}`}
                                    type="text"
                                    name={input.labelName}
                                    onChange={(e) => this.setState({
                                        merchant: { ...this.state.merchant, gatewayData: {
                                            ...gatewayData,
                                            [input.labelName]: e.target.value
                                        } }
                                    })}
                                    defaultValue={gatewayData && gatewayData[input.labelName] || null}
                                />
                                : input.type.includes("BIT") ?
                                    <div className="checkbox checkbox-success checkbox-md ">
                                        <label className="custom-checkbox pt-14">
                                            <div className="check-box-label">{input.labelName}</div>
                                            <input type="checkbox" defaultChecked={gatewayData && (parseInt(gatewayData[input.labelName]) === 1 || gatewayData[input.labelName] === true)}
                                                name={input.labelName}
                                                onChange={(e) => this.setState({
                                                    merchant: { ...this.state.merchant, gatewayData: {
                                                        ...gatewayData,
                                                        [input.labelName]: e.target.checked ? 1 : 0
                                                    } }
                                                })}
                                            />
                                            <span className="check-box t-14"></span>
                                        </label>
                                    </div> : input.type.includes("ENUM") ?
                                        <FormControl
                                            componentClass="select"
                                            name={input.labelName}
                                            onChange={(e) => this.setState({
                                                merchant: { ...this.state.merchant, gatewayData: {
                                                    ...gatewayData,
                                                    [input.labelName]: e.target.value
                                                } }
                                            })}
                                        >
                                            <option disabled selected>Select</option>
                                            {gatewayData && input.options.map(option => <option value={option} selected={option === gatewayData[input.labelName]}>{option}</option>)}
                                        </FormControl> : null
                        }
                    </FormGroup>
                </Row>
            </Col>);
        });
    }

    getInputfields = () => {
        const { merchantDetails } = this.props;
        if (merchantDetails) {
            if (merchantDetails.uniqueFields) {
                let merchantUniqueFields = merchantDetails.uniqueFields.split("\n");
                let inputFields = merchantUniqueFields.map(data => {
                    let splittedData = data.split(":");
                    let typeSplittedData = splittedData[1].split("|");
                    if (typeSplittedData.length === 1) {
                        return {
                            labelName: splittedData[0],
                            type: typeSplittedData[0]
                        };
                    }
                    if (typeSplittedData.length === 2) {
                        return {
                            labelName: splittedData[0],
                            type: typeSplittedData[0],
                            options: typeSplittedData[1].split(";")
                        };
                    }
                });
                // let fieldData = inputFields.map((input, i) => {
                //     return {
                //         [input.labelName]: null
                //     };
                // });
                return inputFields;
            }
        }
    }

    getMerchantInitialState() {
        const { merchantDetails } = this.props;
        if (merchantDetails) {
            return {
                ...merchantDetails,
                gatewayData: merchantDetails.gatewayData ? JSON.parse(merchantDetails.gatewayData) : null
            };
        } else {
            return {
                title: "",
                gatewayId: "",
                descriptor: "",
                midNumber: null,
                processorName: null,
                bankName: null,
                categoryCode: null,
                preAuthAmount: "1.00",
                blockPrepaid: 0,
                blockDebit: 0,
                mastercard: 1,
                visa: 1,
                amex: 1,
                discover: 1,
                monthlyLimit: "0.00",
                monthlyLimitHasRefunds: 0,
                discountRate: "0.00",
                reserveAccountRate: "0.00",
                txnFee: "0.00",
                chargebackRatio: "0.00",
                directDebit: 0,
                acceptACH: 0
            };
        }
    }

    handleChange = (e, type) => {
        if (type === "checkbox") {
            this.setState({
                merchant: {
                    ...this.state.merchant,
                    [e.target.name]: e.target.checked ? 1 : 0
                }
            });
            return;
        }
        this.setState({
            merchant: {
                ...this.state.merchant,
                [e.target.name]: e.target.value
            }
        });
    }

    handleUpdate = (e) => {
        e.preventDefault();
        const { merchant } = this.state;
        const { closeModal, fromProduct, closeFulfillmentModal, refreshMerchantList } = this.props;
        if (this.isValid(merchant)) {
            this.setState({
                isLoading: true
            });
            let data = {
                ...merchant,
                gatewayData: JSON.stringify(merchant.gatewayData)
            };
            updateMerchant(data)
                .then((res) => {
                    NotificationEmitter.emit(NOTIFICATION_EVENT_NAME, { type: "success", message: "Merchant updated successfully" });
                    // this.props.moveStep("NEXT");
                    this.setState({
                        isLoading: false
                    });
                    if (fromProduct) {
                        refreshMerchantList();
                        closeFulfillmentModal();
                    } else {
                        closeModal(e);
                    }
                })
                .catch((err) => {
                    this.setState({
                        isLoading: false
                    });
                    NotificationEmitter.emit(NOTIFICATION_EVENT_NAME, { type: "error", message: err.response.data.message });
                });
        } else {
            if (!this.state.merchant.title) {
                this.refs.title.scrollIntoView({ behavior: "smooth" });
            }
        }
    }

    render() {
        const {
            hasError,
            isLoading,
            gatewayList,
            resetUniqueField,
            inputFields,
            merchant: {
                title,
                gatewayId,
                descriptor,
                midNumber,
                processorName,
                bankName,
                categoryCode,
                preAuthAmount,
                blockDebit,
                blockPrepaid,
                mastercard,
                visa,
                amex,
                discover,
                monthlyLimit,
                monthlyLimitHasRefunds,
                discountRate,
                reserveAccountRate,
                txnFee,
                chargebackRatio,
                acceptACH,
                directDebit,
            },
            formErrors
        } = this.state;
        const { selectTemplate, pageType, closeModal, merchantDetails, fromProduct, closeFulfillmentModal } = this.props;
        const options = gatewayList && gatewayList.map((option, i) => <option value={option.gatewayId} selected={option.gatewayId === gatewayId}>{option.gatewayName}</option>);
        return (
            <div className="merchant_container">
                <div className="container-fluid page">
                    <div ref="title"></div>
                    <div ref="campaign"></div>
                    {
                        pageType === "step-wizard" ?
                            (
                                <h4 className="campaign-heading">
                                    Create New Merchant
                                </h4>
                            ) : null
                    }
                    <Row className="p-0-30">
                        { !gatewayList &&
                            <CRMLoading title="Fetching Gateway..."/>
                        }
                        <Col md={6} className="pbc-4 pr-40">
                            <Row>
                                <FormGroup>
                                    <ControlLabel className="custom-form-label">MID Title</ControlLabel>
                                    <FormControl
                                        type="text"
                                        className={`${hasError ? "select_error" : ""}`}
                                        onChange={this.handleChange}
                                        name="title"
                                        defaultValue={title}
                                    />
                                    <div className={`error_box ${formErrors.includes("title") ? "erractive" : ""}`}>
                                        <span>MID Title is required</span>
                                    </div>
                                </FormGroup>
                            </Row>
                        </Col>
                        <Col md={6} className="pbc-4 pr-40">
                            <Row>
                                <FormGroup>
                                    <ControlLabel className="custom-form-label">Gateway</ControlLabel>
                                    <FormControl componentClass="select" className={`${formErrors.includes("gatewayId") ? "select_error" : ""}`} onChange={this.handleGateway} name={"gatewayId"}>
                                        <option disabled selected>Select Gateway</option>
                                        {options}
                                    </FormControl>
                                </FormGroup>
                            </Row>
                        </Col>
                    </Row>
                    <Row className="p-0-30">
                        {!resetUniqueField && gatewayList && inputFields && this.renderInputs()}
                    </Row>
                    <Row>
                        <Col md={3} className="check-box-whole-area">
                            <h5 className="check-box-heading">
                                Accepted Payments
                                <ToolTip
                                    title="Accepted Payments"
                                    content="Disabling a payment type will prevent new sales. It will not affect existing rebills."
                                    placement="right"
                                />
                            </h5>
                            <Row className="pb-31 pl-35">
                                <CRMCheckbox label="eCheck" name={"acceptACH"} defaultChecked={acceptACH} onChange={(e) => this.handleChange(e, "checkbox")}/>
                            </Row>
                            <Row className="pb-31 pt-31 pl-35">
                                <CRMCheckbox label="Direct Debit" name={"directDebit"} defaultChecked={directDebit} onChange={(e) => this.handleChange(e, "checkbox")}/>
                            </Row>
                            <Row className="pb-31 pt-31 pl-35">
                                <CRMCheckbox label="MasterCard" name={"mastercard"} defaultChecked={mastercard} onChange={(e) => this.handleChange(e, "checkbox")}/>
                            </Row>
                            <Row className="pb-31 pt-31 pl-35">
                                <CRMCheckbox label="Visa" name={"visa"} defaultChecked={visa} onChange={(e) => this.handleChange(e, "checkbox")}/>
                            </Row>
                            <Row className="pb-31 pt-31 pl-35">
                                <CRMCheckbox label="AmEx" name={"amex"} defaultChecked={amex} onChange={(e) => this.handleChange(e, "checkbox")}/>
                            </Row>
                            <Row className="pb-31 pt-31 pl-35">
                                <CRMCheckbox label="Discover" name={"discover"} defaultChecked={discover} onChange={(e) => this.handleChange(e, "checkbox")}/>
                            </Row>
                        </Col>
                        <Col md={5} className="check-box-whole-area">
                            <h5 className="check-box-heading m-auto">Limits</h5>
                            <Row className="vertical-center-merchant">
                                <Col mdOffset={1} md={6} className="pt-16">
                                    <FormGroup>
                                        <ControlLabel className="custom-form-label font-14">Monthly Cap</ControlLabel>
                                        <InputGroup className="form-control-addon-width w-91-per">
                                            <InputGroup.Addon className="input-group-background">{this.props.currencySymbol ? this.props.currencySymbol : "$" }</InputGroup.Addon>
                                            <FormControl type="text" className="text-center" onChange={(e) => {numberOnly(e); this.handleChange(e);}} name="monthlyLimit" defaultValue={monthlyLimit}/>
                                        </InputGroup>
                                    </FormGroup>
                                </Col>
                                <Col md={5} className="p-0">
                                    <CRMCheckbox label="Include Refunds" name={"monthlyLimitHasRefunds"} defaultChecked={monthlyLimitHasRefunds} onChange={(e) => this.handleChange(e, "checkbox")}/>
                                </Col>
                            </Row>
                            <Row className="vertical-center-merchant">
                                <Col md={5}>
                                    <FormGroup>
                                        <ControlLabel className="custom-form-label font-14">Discount Rate</ControlLabel>
                                        <InputGroup className="form-control-addon-width w-85">
                                            <FormControl type="text" className="text-center" onChange={(e) => {numberOnly(e); this.handleChange(e);}} name="discountRate" defaultValue={discountRate}/>
                                            <InputGroup.Addon className="input-group-background">%</InputGroup.Addon>
                                        </InputGroup>
                                    </FormGroup>
                                </Col>
                                <Col md={5}>
                                    <FormGroup>
                                        <ControlLabel className="custom-form-label font-14">Reserve Rate</ControlLabel>
                                        <InputGroup className="form-control-addon-width w-85">
                                            <FormControl type="text" className="text-center" onChange={(e) => {numberOnly(e); this.handleChange(e);}} name="reserveAccountRate" defaultValue={reserveAccountRate}/>
                                            <InputGroup.Addon className="input-group-background">%</InputGroup.Addon>
                                        </InputGroup>
                                    </FormGroup>
                                </Col>
                            </Row>
                            <Row className="vertical-center-merchant">
                                <Col md={5}>
                                    <FormGroup>
                                        <ControlLabel className="custom-form-label font-14">Transaction Fee</ControlLabel>
                                        <InputGroup className="form-control-addon-width w-81">
                                            <InputGroup.Addon className="input-group-background">{this.props.currencySymbol ? this.props.currencySymbol : "$" }</InputGroup.Addon>
                                            <FormControl type="text" className="text-center" onChange={(e) => {numberOnly(e); this.handleChange(e);}} name="txnFee" defaultValue={txnFee}/>
                                        </InputGroup>
                                    </FormGroup>
                                </Col>
                                <Col md={5}>
                                    <FormGroup>
                                        <ControlLabel className="custom-form-label font-14">ChargeBack Ratio</ControlLabel>
                                        <InputGroup className="form-control-addon-width w-85">
                                            <FormControl type="text" className="text-center" onChange={(e) => {numberOnly(e); this.handleChange(e);}} name="chargebackRatio" defaultValue={chargebackRatio && parseFloat(chargebackRatio).toFixed(1)}/>
                                            <InputGroup.Addon className="input-group-background">%</InputGroup.Addon>
                                        </InputGroup>
                                    </FormGroup>
                                </Col>
                            </Row>
                        </Col>
                        <Col md={4} className="check-box-whole-area" style={{ paddingLeft: 4 }}>
                            <h5 className="check-box-heading">Additional Options</h5>
                            <Row className="pb-31 pl-35">
                                <CRMCheckbox label="Block Prepaid Cards" name={"blockPrepaid"} defaultChecked={blockPrepaid} onChange={(e) => this.handleChange(e, "checkbox")}/>
                            </Row>
                            <Row className="pb-31 pt-31 pl-35">
                                <CRMCheckbox label="Block Debit Cards" name={"blockDebit"} defaultChecked={blockDebit} onChange={(e) => this.handleChange(e, "checkbox")}/>
                            </Row>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={12} className="p-0-30">
                            <h5 className="check-box-heading">Merchant Details</h5>
                            <Row className="p-0-15">
                                <Col md={6} className="pbc-4 pr-40">
                                    <Row>
                                        <FormGroup>
                                            <ControlLabel className="custom-form-label">Descriptor</ControlLabel>
                                            <FormControl
                                                type="text"
                                                name={"descriptor"}
                                                onChange={this.handleChange}
                                                defaultValue={descriptor}
                                            />
                                            <div className={`error_box ${formErrors.includes("descriptor") ? "erractive" : ""}`}>
                                                <span>Descriptor is required</span>
                                            </div>
                                        </FormGroup>
                                    </Row>
                                    <Row className="pt-6">
                                        <FormGroup>
                                            <ControlLabel className="custom-form-label">Processor</ControlLabel>
                                            <FormControl
                                                type="text"
                                                name={"processorName"}
                                                onChange={this.handleChange}
                                                defaultValue={processorName}
                                            />
                                        </FormGroup>
                                    </Row>
                                    <Row className="pt-6">
                                        <FormGroup>
                                            <ControlLabel className="custom-form-label">Category Code</ControlLabel>
                                            <FormControl
                                                type="text"
                                                name={"categoryCode"}
                                                onChange={(e) => {numberOnly(e); this.handleChange(e);}}
                                                defaultValue={categoryCode}
                                            />
                                        </FormGroup>
                                    </Row>
                                </Col>
                                <Col md={6} className="pbc-4 pr-40">
                                    <Row>
                                        <FormGroup>
                                            <ControlLabel className="custom-form-label">Mid #</ControlLabel>
                                            <FormControl
                                                type="text"
                                                name={"midNumber"}
                                                onChange={this.handleChange}
                                                defaultValue={midNumber}
                                            />
                                            <div className={`error_box ${formErrors.includes("midNumber") ? "erractive" : ""}`}>
                                                <span>MidNumber is required</span>
                                            </div>
                                        </FormGroup>
                                    </Row>
                                    <Row className="pt-6">
                                        <FormGroup>
                                            <ControlLabel className="custom-form-label">Bank Name</ControlLabel>
                                            <FormControl
                                                type="text"
                                                name={"bankName"}
                                                onChange={this.handleChange}
                                                defaultValue={bankName}
                                            />
                                        </FormGroup>
                                    </Row>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                    <Row className="pt-10">
                        <Col md={12} className="p-0-30">
                            <h5 className="check-box-heading">Trial Limits</h5>
                            <FormGroup>
                                <ControlLabel className="custom-form-label font-14 display-flex-tooltip-icon">
                                    Validate Card Amount
                                    <ToolTip
                                        title="Validate Card Amount"
                                        content="Enter a custom amount used to validate the customer's card. You can enter any value less than $15. This value will be used when Validating a Credit Card against this MID"
                                        placement="right"
                                    />
                                </ControlLabel>
                                <InputGroup className="form-control-addon-width">
                                    <InputGroup.Addon className="input-group-background">{this.props.currencySymbol ? this.props.currencySymbol : "$" }</InputGroup.Addon>
                                    <FormControl type="text" name={"preAuthAmount"} onChange={(e) => {numberOnly(e); this.handleChange(e);}} className="w-90 text-center" defaultValue={preAuthAmount}/>
                                </InputGroup>
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row className="footer" style={{ paddingRight: 15 }}>
                        <div className="pr-0">
                            <Button className="cancelButton" onClick={(e) => {pageType === "step-wizard" ? selectTemplate(null) : fromProduct ? closeFulfillmentModal() : closeModal(e);}}>Cancel</Button>
                        </div>
                        <div className="pl-0">
                            <CRMButton isLoading={isLoading} handleClick={(e) => merchantDetails ? this.handleUpdate(e) : this.handleSubmit(e)} text="Save & Continue"/>
                        </div>
                    </Row>
                </div>
            </div>
        );
    }
}

export default CreateEditMerchant;

CreateEditMerchant.propTypes = {
    selectTemplate: PropTypes.func,
    handleSkipButton: PropTypes.func,
    pageType: PropTypes.string,
    closeModal: PropTypes.func,
    moveStep: PropTypes.func,
    merchantDetails: PropTypes.object,
    setMerchantId: PropTypes.func,
    fromProduct: PropTypes.bool,
    closeFulfillmentModal: PropTypes.func,
    fulfillmentDetails: PropTypes.array,
    refreshMerchantList: PropTypes.func,
    currencySymbol: PropTypes.string
};