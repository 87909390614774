import React, { useState } from "react";
import PropTypes from "prop-types";

const Zoom = ({
    btnClass,
    values,
    updateZoomValue,
}) => {
    const percentageLabel = " %";
    const initialZoomValue = ~~(values.zoomValue * 100);
    const [zoomValue, setZoomValue] = useState(initialZoomValue);

    const zoomInVisualizer = () => {
        if (zoomValue < 150) {
            const zoomInValue = zoomValue + 0.1 * 100;
            setZoomValue(zoomInValue);
            updateZoomValue(zoomInValue);
        }
    };
    const zoomOutVisualizer = () => {
        if (zoomValue > 50) {
            const zoomOutValue = zoomValue - 0.1 * 100;
            setZoomValue(zoomOutValue);
            updateZoomValue(zoomOutValue);
        }
    };
    return (
        <div className="input-group zoomBox m-l-xs">
            <div className={btnClass} onClick={zoomOutVisualizer} title="Zoom out"><i className="fa fa-search-minus" aria-hidden="true"></i></div>
            <input className="form-control zoom-level" type="text" disabled value= { zoomValue + percentageLabel } readOnly title = "Zoom" />
            <div className={btnClass} onClick={zoomInVisualizer} title="Zoom in"><i className="fa fa-search-plus" aria-hidden="true"></i></div>
        </div>
    );
};
Zoom.propTypes = {
    btnClass: PropTypes.string,
    values: PropTypes.object,
    updateZoomValue: PropTypes.func,
};
Zoom.defaultProps = {
    btnClass: "input-group-addon"
};

export default Zoom;