export const TOGGLE_LOADING = "TOGGLE_LOADING";
export const GET_FUNNEL_SUCCESS = "GET_FUNNEL_SUCCESS";
export const GET_FUNNEL_ERROR = "GET_FUNNEL_ERROR";
export const LOAD_DOMAIN_LIST_SUCCESS = "LOAD_DOMAIN_LIST_SUCCESS";
export const SET_DOMAIN_FOR_FUNNEL_SUCCESS = "SET_DOMAIN_FOR_FUNNEL_SUCCESS";
export const LOAD_CAMPAIGNS_SUCCESS = "LOAD_CAMPAIGNS_SUCCESS";
export const UPDATE_CHANGE_SET_ID = "UPDATE_CHANGE_SET_ID";
export const GET_FONTS_SUCCESS = "GET_FONTS_SUCCESS";
export const GOOGLE_FONT_LOADING_SUCCESS = "GOOGLE_FONT_LOADING_SUCCESS";
export const SAVE_BUTTONS_AND_LINKS_SUCCESS = "SAVE_BUTTONS_AND_LINKS_SUCCESS";
export const LOAD_BUTTONS_AND_LINKS_SUCCESS = "LOAD_BUTTONS_AND_LINKS_SUCCESS";
export const UPDATE_ZOOM_VALUE_SUCCESS = "UPDATE_ZOOM_VALUE_SUCCESS";
export const SAVE_SPLIT_RATIO_SUCCESS = "SAVE_SPLIT_RATIO_SUCCESS";
export const PUBLISH_FUNNEL_SUCCESS = "PUBLISH_FUNNEL_SUCCESS";
export const DEFAULT_FUNNELS_SUCCESS_MESSAGE = "DEFAULT_FUNNELS_SUCCESS_MESSAGE";