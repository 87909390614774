import React from "react";
import PropTypes from "prop-types";

import {
    Tooltip,
    OverlayTrigger,
} from "react-bootstrap";

const TriggerTooltip = (props) => {
    const {
        label,
        placement,
    } = props;
    const tooltip = (
        <Tooltip id="tooltip">
            <span dangerouslySetInnerHTML={ { __html: label }} />
        </Tooltip>
    );
    return (
        <OverlayTrigger
            placement={placement}
            overlay={tooltip}
            trigger={["hover"]}
        >
            {
                props.children
            }
        </OverlayTrigger>
    );
};

TriggerTooltip.propTypes = {
    label: PropTypes.string,
    children: PropTypes.element,
    placement: PropTypes.string,
};

TriggerTooltip.defaultProps = {
    placement: "bottom",
};

export default TriggerTooltip;
