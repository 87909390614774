import React, { Component, PropTypes } from "react";
import {
    func,
    array,
    object,
    string
} from "prop-types";
import { Modal, ButtonToolbar, Row, Col, FormGroup, FormControl, ControlLabel, Button, Popover, Overlay } from "react-bootstrap";
import pickBy from "lodash/pickBy";

import { getCampaigns } from "../../../../api/campaignsApi";
import { getCurrencyList } from "../../../../crm-entry/utilities/api";
import Offer from "../../../../crm-entry/EditFunnel/Offers";
import Upsells from "../../../../crm-entry/EditFunnel/Upsells";
import MerchantAccounts from "../../../../crm-entry/EditFunnel/Merchant Accounts";
import FulfillmentHouse from "../../../../crm-entry/EditFunnel/FulfillmentHouses";
import Affliates from "../../../../crm-entry/EditFunnel/Affliates";
import SMTP from "../../../../crm-entry/EditFunnel/SMTP";
import CreateEditCampaign from "../../../../crm-entry/Campaign/CreateEditCampaign";
import { NotificationEmitter,NOTIFICATION_EVENT_NAME } from "../../../../../src/commons/notification";
import CRMEntry from "../../../../crm-entry";


const config = [
    {
        title: "Offers",
        component: Offer
    },
    {
        title: "UpSells",
        component: Upsells
    },
    {
        title: "MerchantAccounts",
        component: MerchantAccounts
    },
    {
        title: "FulfillmentHouse",
        component: FulfillmentHouse
    },
    {
        title: "Affiliates",
        component: Affliates
    },
    {
        title: "SMTP",
        component: SMTP
    },
    {
        title: "EDIT_CAMPAIGN",
        component: CreateEditCampaign
    }
];

const BreadCrumb = ({ label, title, showModal }) => {
    return (
        <Row>
            <Col md={8} className="edit-funnel-breadcrumb" onClick={() => showModal(title)}>
                {label}
            </Col>
            <Col md={4}>
                <i class="fa fa-chevron-right right-arrow"></i>
            </Col>
        </Row>
    );
};

BreadCrumb.propTypes = {
    label: string,
    title: string,
    showModal: func
};

class FunnelTitle extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isPopOverEnable: false,
            funnelDetails: props.funnelData,
            campaignDetails: null,
            funnelName: null,
            campaignList: props.allCampaigns,
            offers: [],
            upSales: [],
            isCampaignFetched: false
        };
    }

    componentDidMount() {
        this.getCampaignList();
    }

    componentWillReceiveProps(nextProps) {
        const { funnelData } = nextProps;
        const { funnelDetails } = this.state;
        if (funnelDetails !== funnelData) {
            this.setState({
                funnelDetails: funnelData
            }, () => this.getCampaignList());
        }
    }

    resetCampaign = () => {
        this.setState({
            isPopOverEnable: false,
            isCampaignChangeEnabled: false,
            campaignDetails: Object.assign({}, ...this.state.campaignList.filter(item => item.campaignId === parseInt(this.state.funnelDetails.campaign)))
        });
    }

    getCampaignList = () => {
        this.setState({
            isCampaignFetched: false
        });
        getCampaigns()
            .then((res) => {
                this.setState({
                    isCampaignFetched: true
                });
                let campaign = Object.values(res.message.data);
                this.setState({
                    campaignList: Object.values(res.message.data),
                    campaignDetails: Object.assign({}, ...campaign.filter(item => item.campaignId === parseInt(this.state.funnelDetails.campaign)))
                }, () => this.getSelectedCurrencySymbol(this.state.campaignDetails));
            })
            .catch((err) => {
                NotificationEmitter.emit(NOTIFICATION_EVENT_NAME, { type: "error", message: err.response.data.message });
                this.setState({
                    isCampaignFetched: true
                });
            });
    }

    getSelectedCurrencySymbol = (campaignDetails) => {
        getCurrencyList()
            .then((res) => {
                if (campaignDetails) {
                    let selectedCurrency = Object.assign({}, ...res.message.filter(currency => currency.currencyCode === campaignDetails.currency));
                    this.setState({
                        currencySymbol: selectedCurrency.currencySymbol
                    });
                }
            });
    }

    renderList = () => {
        const { activeList, campaignDetails, currencySymbol, funnelDetails } = this.state;
        if (activeList) {
            let ListComponent = config.find(component => component.title.toLowerCase().includes(this.state.activeList.toLowerCase()));
            return <ListComponent.component refreshData={this.getCampaignList} fromEditFunnel closeModal={this.closeModal} currencySymbol={currencySymbol} wholeFunnelDetails={funnelDetails} funnelDetails={campaignDetails} pageType="EDIT" />;
        }
    }

    showModal = (activeList) => {
        this.setState({
            modalVisible: true,
            activeList
        });
    }

    closeModal = () => {
        this.setState({
            modalVisible: false
        });
    }

    handleCampaign = (e) => {
        const { campaignList } = this.state;
        let campaignDetails = Object.assign({}, ...campaignList.filter(item => item.campaignId === parseInt(e.target.value)));
        if (campaignDetails) {
            const products = campaignDetails.products;
            let upSales = [];
            let offers = [];
            products && products.map(list => {
                if (list.productType === "OFFER") {
                    offers.push(list);
                } else {
                    upSales.push(list);
                }
            });
            this.setState({ funnelCampaignId: e.target.value, offers, upSales, campaignDetails });
        }
    }

    handleUpdateFunnel = (label) => {
        const { funnelDetails, offers, upSales } = this.state;
        const { updateFunnelInfo } = this.props;
        if (label === "title") {
            this.setState({
                isFunnelUpdateLoading: true
            });
        } else {
            this.setState({
                isFunnelCampaignUpdateLoading: true
            });
        }
        let data = funnelDetails;
        if (label === "title") {
            if (this.state.funnelName) {
                data.title = this.state.funnelName;
            }
        } else if (label === "campaignName") {
            if (this.state.funnelCampaignId) {
                data.campaign = this.state.funnelCampaignId;
            }
        }
        let funnel = {
            ...data
        };
        const filteredPage = funnel.page && funnel.page.map(data => pickBy(data, v => v !== null && v !== undefined));
        funnel = {
            ...funnel,
            ["page"]: filteredPage
        };
        let Offers = offers.map((offer) => {
            return {
                value: offer.campaignProductId,
                text: offer.productName,
                type: offer.productType
            };
        });

        let UpSales = upSales.map((upSale) => {
            return {
                value: upSale.campaignProductId,
                text: upSale.productName,
                type: upSale.productType
            };
        });
        if (label === "campaignName") {
            funnel.offer = JSON.stringify(Offers);
            funnel.upsale = JSON.stringify(UpSales);
        }
        updateFunnelInfo({ ...funnel })
            .then(res => {
                NotificationEmitter.emit(NOTIFICATION_EVENT_NAME, { type: "success", message: `Funnel ${label === "title" ? "title" : "campaign"} updated successfully` });
                if (label === "title") {
                    this.setState({
                        isFunnelUpdateLoading: false
                    });
                } else {
                    this.setState({
                        isFunnelCampaignUpdateLoading: false
                    });
                }
            })
            .catch(err => {
                NotificationEmitter.emit(NOTIFICATION_EVENT_NAME, { type: "error", message: err.response.data.message });
                if (label === "title") {
                    this.setState({
                        isFunnelUpdateLoading: false
                    });
                } else {
                    this.setState({
                        isFunnelCampaignUpdateLoading: false
                    });
                }
            });
    }

    render() {
        const { isPopOverEnable, funnelDetails: { title }, modalVisible, isCampaignFetched, isCampaignChangeEnabled, isFunnelCampaignUpdateLoading } = this.state;
        const popoverClickRootClose = (
            <Popover id="popover-contained" className="popover-edit-funnel">
                <div>
                    <div>
                        <form onSubmit={(e) => {e.preventDefault(); this.handleUpdateFunnel("title");}}>
                            <Row>
                                <Col md={6}>
                                    <FormGroup>
                                        <ControlLabel className="custom-form-label">Title</ControlLabel>
                                        <FormControl
                                            className="form-control-width text-color"
                                            type="text"
                                            defaultValue={title}
                                            onChange={(e) => this.setState({
                                                funnelName: e.target.value
                                            })}
                                            autoFocus
                                        />
                                    </FormGroup>
                                </Col>
                                <Col md={6} className="p-0">
                                    <Button
                                        type="primary"
                                        className="button btn-save"
                                        onClick={() => this.handleUpdateFunnel("title")}
                                    >
                                        <span
                                            className={`${this.state.isFunnelUpdateLoading ? "loading_spinner" : ""}`}
                                        ></span>
                                        Save
                                    </Button>
                                </Col>
                            </Row>
                        </form>
                        <Row>
                            <Col md={6}>
                                <ControlLabel className="custom-form-label">Campaign</ControlLabel>
                                {!this.state.isCampaignChangeEnabled ?
                                    <FormGroup>
                                        <FormControl
                                            className="form-control-width text-color"
                                            type="text"
                                            value={this.state.campaignDetails ? this.state.campaignDetails.campaignName : "Loading Campaign..."}
                                            disabled
                                        />
                                    </FormGroup> :
                                    <div style={{ marginBottom: 15 }}>
                                        <select
                                            className={"form-control custom_select text-color"}
                                            placeholder="Select any Campaign"
                                            onChange={(e) => this.handleCampaign(e)}
                                        >
                                            {this.state.campaignList && this.state.campaignList.map((campaign, i) => <option value={campaign.campaignId} selected={campaign.campaignId === parseInt(this.state.funnelDetails.campaign)}>{campaign.campaignName}</option>)}
                                        </select>
                                    </div>
                                }
                            </Col>
                            <Col md={6} className="pl-0 pt-24">
                                <Button
                                    type="primary"
                                    className="button edit-button"
                                    onClick={() => this.showModal("EDIT_CAMPAIGN")}
                                    disabled={!isCampaignFetched || isCampaignChangeEnabled || isFunnelCampaignUpdateLoading}
                                >
                                    <i class="fa fa-pencil"></i>
                                </Button>
                                <Button
                                    type="primary"
                                    className="button btn-change-edit-funnel"
                                    onClick={() => this.setState({
                                        isCampaignChangeEnabled: !this.state.isCampaignChangeEnabled
                                    }, () => {!this.state.isCampaignChangeEnabled ? this.handleUpdateFunnel("campaignName") : null;})}
                                >
                                    <span
                                        className={`${isFunnelCampaignUpdateLoading ? "loading_spinner" : ""}`}
                                        style={{ borderTopColor: "#13b495" }}
                                    ></span>
                                    {!this.state.isCampaignChangeEnabled ? "Change" : "Save"}
                                </Button>
                            </Col>
                        </Row>
                        <Row className="pt-30">
                            <Col md={3}>
                                <BreadCrumb label="Offers" title="OFFERS" showModal={this.showModal}/>
                            </Col>
                            <Col md={4}>
                                <BreadCrumb label="Merchant Accounts" title="MerchantAccounts" showModal={this.showModal}/>
                            </Col>
                            <Col md={4}>
                                <BreadCrumb label="SMTP Accounts" title="SMTP" showModal={this.showModal}/>
                            </Col>
                        </Row>
                        <Row className="pt-20 pb-30">
                            <Col md={3}>
                                <BreadCrumb label="Upsells" title="UPSELLS" showModal={this.showModal}/>
                            </Col>
                            <Col md={4}>
                                <BreadCrumb label="Affiliates" title="Affiliates" showModal={this.showModal}/>
                            </Col>
                            <Col md={4}>
                                <BreadCrumb label="Fulfillment Houses" title="FulfillmentHouse" showModal={this.showModal}/>
                            </Col>
                        </Row>
                        <Row>
                            <Button
                                type="primary"
                                className="button btn-cancel-edit-funnel text-color"
                                onClick={() => {this.resetCampaign();}}
                            >
                                Cancel
                            </Button>
                        </Row>
                    </div>
                    {modalVisible && <Modal
                        show
                        style={{ opacity: 1 }}
                        bsSize={`${this.state.activeList === "EDIT_CAMPAIGN" ? "lg" : "medium"}`}
                        className="wizard_modal edit_funnel_modal"
                        aria-labelledby="contained-modal-title-vcenter"
                        dialogClassName={`${this.state.activeList === "EDIT_CAMPAIGN" ? "" : "custom_size"}`}
                        animation
                        onHide={() => this.closeModal()}
                    >
                        <Modal.Body className="custom_modal_body">
                            <div className="modal-body-funnel-edit">
                                {this.state.activeList === "EDIT_CAMPAIGN" ? (
                                    <div className="modal-header-funnel-edit">
                                        Edit Campaign
                                    </div>
                                ) : null
                                }
                                {this.renderList()}
                            </div>
                            <div className="close_button" onClick={() => this.closeModal()}>
                                <span>&times;</span>
                            </div>
                        </Modal.Body>
                    </Modal>}
                </div>
            </Popover>
        );
        return (
            <>
                <CRMEntry {...this.props} isInsideTheVisualizer refreshData={this.getCampaignList}/>
                <h3 className="funnel-title">
                    <span>{ title }</span>
                    <ButtonToolbar className="custom_popover">
                        <i onClick={(e) => {this.setState({ target: e.target, isPopOverEnable: !isPopOverEnable });}} className="fa fa-cog fa-spin m-l-sm pointer" />
                        {true &&
                            <Overlay
                                show={isPopOverEnable}
                                target={this.state.target}
                                placement="bottom"
                                container={this}
                                rootClose={false}
                            >
                                {popoverClickRootClose}
                            </Overlay>}
                    </ButtonToolbar>
                </h3>
            </>
        );
    }
}

FunnelTitle.propTypes = {
    funnelData: object,
    allCampaigns: array,
    updateFunnelInfo: func,
};

export default FunnelTitle;