import { FUNNEL_REDUCER_KEY } from "../create-funnel-visualizer/reducer";
import { MANAGEFUNNEL_REDUCER_KEY } from "../manage-funnel/reducer";

export const getFunnelState = (state) => {
    return state[MANAGEFUNNEL_REDUCER_KEY];
};

export const getFunnelData = (state) => {
    return state[MANAGEFUNNEL_REDUCER_KEY].funnelData;
};

export const getTemplateHtmlState = (state) => {
    return state[MANAGEFUNNEL_REDUCER_KEY].templateHtml;
};

export const getPages = (state) => {
    return state[MANAGEFUNNEL_REDUCER_KEY].funnelData.page ? state[MANAGEFUNNEL_REDUCER_KEY].funnelData.page.filter(p => !p.deleted) : [];
};

export const getCurrentPageId = (state) => {
    return state[MANAGEFUNNEL_REDUCER_KEY].currentPageReferenceId;
};

export const getLoader = (state) => {
    return state[FUNNEL_REDUCER_KEY].isLoading;
};
export const getCreated = (state) => {
    return state[FUNNEL_REDUCER_KEY].isCreated;
};
export const getSplitPage = (state) => {
    return state[MANAGEFUNNEL_REDUCER_KEY].splitViewPage;
};
export const getSplitValue = (state) => {
    return state[MANAGEFUNNEL_REDUCER_KEY].splitData;
};
export const getIsSaved = (state) => {
    return state[FUNNEL_REDUCER_KEY].isSaved;
};