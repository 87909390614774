const onStyleChange = (editor, args) => {
    const model = args[0];
    const property = args[1];
    if ((property === "width" && editor.getSelected() && editor.getSelected().attributes.tagName === "img") || property === "color") {
        var propertyValue = model.getStyle()[property];
        if (propertyValue) {
            let styleImportant = propertyValue.indexOf("!important");
            if (styleImportant === -1) {
                model.addStyle({ [property]: propertyValue + " !important" });
            }
        }
    }
};

export default {
    "styleable:change": onStyleChange,
};

