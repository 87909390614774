import get from "lodash/get";
import { ProductType } from "../../constants/funnel-constants";

export const getEditorContainer = (editor) => {
    return (editor && editor.container);
};

export const getDomWrapper = (editor) => {
    return (editor && editor.DomComponents.getWrapper());
};

export const buttonChange = (container, e, bid, key) => {
    const buttonMetaData = get(container, ["buttonMetaData"]);
    const buttons = get(buttonMetaData, ["buttons"]) || [];
    const links = get(buttonMetaData, ["links"]) || [];
    let matchedNavItem = buttons.find((button) => button["data-id"] === bid);
    if (!matchedNavItem) {
        matchedNavItem = links.find(link => link["data-id"] === bid);
    }
    if (matchedNavItem && e && key) {
        const productTypeName = key.toLowerCase();
        matchedNavItem.products = [
            {
                productType: ProductType[productTypeName],
                productId: e,
            },
        ];
    }
};

// To check for fkt classes in the element
export const checkClass = (component) => {
    component.attributes.classes.models.forEach((elements) => {
        const className = elements.id;
        const classExists = className.indexOf("fkt-button") !== -1;
        if (classExists) {
            component.removeClass(className);
        }
    });
};