const DraggableElement = (cellView, isIcon) => {
    const draggableElement = cellView.model.clone();
    draggableElement.position(0, 0);
    draggableElement.size({
        width: 120,
        height: 160
    });
    draggableElement.attr({
        image: {
            width: isIcon ? 60 : 120,
            height: isIcon ? 60 : 160,
            x: isIcon ? 30 : null,
            y: isIcon ? 30 : null,
        },
        ".label": {
            y: -17.5,
            x: 0
        },
        ".borderBox": {
            width: 126,
            height: 166,
            x: -3,
            y: -3
        }
    });
    return draggableElement;
};
export default DraggableElement;