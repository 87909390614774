export const constants = {
    myDomains: "My Domains",
    pleaseWait: "Please wait...",
    noResultsFound: "No Results Found !",
    domainsTitle: "Domains",
    actionsTitle: "Actions",
    addNewDomain: "Add New Domain",
    addYourOwnDomain: "Add Your Own Domain",
    domainLabel: "Domain",
    wwwLabel: "www.",
    domainNamePlaceholder: "Enter Domain Name..",
    invalidDomainName: "Invalid Domain Name",
    addDomainLabel: "Add Domain",
    editDomainTitle: "Domain details",
    nameTitle: "Name",
    typeTitle: "Type",
    valueTitle: "Value",
    noteToAddCnameRecords: "**For your custom domain to work, you will need to log in to where you bought your domain and add both CNAME records mentioned above.",
    noteForCnameRecordChange: "**CNAME record changes can take up to 72 hours to go into effect, but typically they happen much sooner.",
    noteForSSLCertGeneration: "**Your SSL certificate will be generated automatically",
    domainStateLabel: "Domain State:",
    enableDomainStateLabel: "Enable",
    disableDomainStateLabel: "Disable",
    domainStatusLabel: "Domain Status:",
    sslStatusLabel: "SSL Status:",
    cancelEditLabel: "Cancel",
    saveEditlabel: "Save Changes",
    confirmationTitle: "Confirmation",
    sureToDeleteLabel: "Are you sure to delete Domain?",
    yesLabel: "Yes",
    noLabel: "No",
    okLabel: "OK",
    warningTitle: "Warning",
    domainStillActiveMessage: "This domain cannot be deleted as the state of the domain is still active",
    howToChangeDomainStateMessage: "Click on the edit icon and change the domain state to",
    domainRecentlyUpdatedMessage: "The domain has been recently updated. Kindly try to delete it after some time",
    setCnameRecordsMessage: "Set the CNAME records to enable domain.",
    errorTitle: "Error",
    GlobalBlankTemplateTypeId: 6,
};
