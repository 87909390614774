import React from "react";

export class Footer extends React.Component {
    render() {
        return (
            <div className="footer">
                <div className="pull-right">
                     Konnektive, LLC &copy; 2019
                </div>
            </div>
        );
    }
}