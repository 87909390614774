import React, { Component } from "react";
import PropTypes from "prop-types";
import { Button } from "react-bootstrap";

import "./AddAffiliates.css";

export default function AddAffiliates({ selectTemplate, closeWizard, handleSkipButton }) {
    handleSkipButton(false);
    return (
        <div className="addAffiliateContainer">
            <div className="add-affiliate-content">
                Do you want to add affiliates now?
            </div>
            <div className="button-area">
                <div>
                    <Button
                        className="cancelButton"
                        onClick={closeWizard}
                    >
                        No
                    </Button>
                </div>
                <div>
                    <Button
                        type="primary"
                        className="button save-button"
                        onClick={() => selectTemplate("AFFILIATES_LIST")}
                    >
                        Yes
                    </Button>
                </div>
            </div>
        </div>
    );
}

AddAffiliates.propTypes = {
    selectTemplate: PropTypes.func,
    handleSkipButton: PropTypes.func,
    closeWizard: PropTypes.func
};