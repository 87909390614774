import React, { Component } from "react";
import { Row, Col, FormGroup, FormControl, ControlLabel, Button, Modal, Popover, Overlay, ButtonToolbar } from "react-bootstrap";
import PropTypes from "prop-types";

import Offer from "./Offers";
import Upsells from "./Upsells";
import MerchantAccounts from "./Merchant Accounts";
import Affliates from "./Affliates";
import FulfillmentHouse from "./FulfillmentHouses";
import SMTP from "./SMTP";

import "./EditFunnel.css";

const config = [
    {
        title: "Offers",
        component: Offer
    },
    {
        title: "UpSells",
        component: Upsells
    },
    {
        title: "MerchantAccounts",
        component: MerchantAccounts
    },
    {
        title: "FulfillmentHouse",
        component: FulfillmentHouse
    },
    {
        title: "Affiliates",
        component: Affliates
    },
    {
        title: "SMTP",
        component: SMTP
    }
];

class EditFunnel extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isPopOverEnable: false,
            modalVisible: false,
            activeList: null
        };
        this.handleClick = e => {
            this.setState({ target: e.target, isPopOverEnable: !this.state.isPopOverEnable });
        };
    }

    closeModal = (e) => {
        e.stopPropagation();
        this.setState({
            modalVisible: false
        });
    }

    showModal = (e, activeList) => {
        e.stopPropagation();
        this.setState({
            modalVisible: true,
            activeList
        });
    }

    renderList = () => {
        if (this.state.activeList) {
            let ListComponent = config.find(component => component.title.toLowerCase().includes(this.state.activeList.toLowerCase()));
            return <ListComponent.component closeModal={this.closeModal} />;
        }
    }

    closePopover = () => {
        this.setState({
            isPopOverEnable: false
        });
    }

    render() {
        const { isPopOverEnable, modalVisible, activeList } = this.state;
        const popoverClickRootClose = (
            <Popover id="popover-contained" className="popover-edit-funnel">
                <div>
                    <div>
                        <Row>
                            <Col md={6}>
                                <FormGroup>
                                    <ControlLabel className="custom-form-label">Title</ControlLabel>
                                    <FormControl
                                        className="form-control-width"
                                        type="text"
                                        placeholder="Title"
                                    />
                                </FormGroup>
                            </Col>
                            <Col md={6} className="p-0">
                                <Button
                                    type="primary"
                                    className="button btn-save"
                                >
                                    Save
                                </Button>
                            </Col>
                        </Row>
                        <Row>
                            <Col md={6}>
                                <FormGroup>
                                    <ControlLabel className="custom-form-label">Campaign</ControlLabel>
                                    <FormControl
                                        className="form-control-width"
                                        type="text"
                                        defaultValue="Mid 434"
                                        disabled
                                    />
                                </FormGroup>
                            </Col>
                            <Col md={6} className="pl-0 pt-24">
                                <Button
                                    type="primary"
                                    className="button edit-button"
                                >
                                    <i class="fa fa-pencil"></i>
                                </Button>
                                <Button
                                    type="primary"
                                    className="button btn-change-edit-funnel"
                                >
                                    Change
                                </Button>
                            </Col>
                        </Row>
                        <Row className="pt-30">
                            <Col md={3}>
                                <Row>
                                    <Col md={8} className="edit-funnel-breadcrumb" onClick={(e) => this.showModal(e, "OFFERS")}>
                                        Offers
                                    </Col>
                                    <Col md={4}>
                                        <i class="fa fa-chevron-right right-arrow"></i>
                                    </Col>
                                </Row>
                            </Col>
                            <Col md={4}>
                                <Row>
                                    <Col md={8}
                                        className="edit-funnel-breadcrumb"
                                        onClick={(e) => this.showModal(e, "FulfillmentHouse")}
                                    >
                                        Fulfillment Houses
                                    </Col>
                                    <Col md={1}>
                                        <i class="fa fa-chevron-right right-arrow"></i>
                                    </Col>
                                </Row>
                            </Col>
                            <Col md={4}>
                                <Row>
                                    <Col md={8}
                                        className="edit-funnel-breadcrumb"
                                        onClick={(e) => this.showModal(e, "SMTP")}
                                    >
                                        SMTP
                                    </Col>
                                    <Col md={2}>
                                        <i class="fa fa-chevron-right right-arrow"></i>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                        <Row className="pt-20 pb-30">
                            <Col md={3}>
                                <Row>
                                    <Col md={8} className="edit-funnel-breadcrumb" onClick={(e) => this.showModal(e, "UPSELLS")}>
                                        Upsells
                                    </Col>
                                    <Col md={4}>
                                        <i class="fa fa-chevron-right right-arrow"></i>
                                    </Col>
                                </Row>
                            </Col>
                            <Col md={4}>
                                <Row>
                                    <Col md={8}
                                        className="edit-funnel-breadcrumb"
                                        onClick={(e) => this.showModal(e, "Affiliates")}
                                    >
                                        Affiliates
                                    </Col>
                                    <Col md={2}>
                                        <i class="fa fa-chevron-right right-arrow"></i>
                                    </Col>
                                </Row>
                            </Col>
                            <Col md={4}>
                                <Row>
                                    <Col md={8}
                                        className="edit-funnel-breadcrumb"
                                        onClick={(e) => this.showModal(e, "MerchantAccounts")}
                                    >
                                        Merchant Accounts
                                    </Col>
                                    <Col md={2}>
                                        <i class="fa fa-chevron-right right-arrow"></i>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                        <Row>
                            <Button
                                type="primary"
                                className="button btn-cancel-edit-funnel"
                                onClick={() => {this.closePopover();}}
                            >
                                Cancel
                            </Button>
                        </Row>
                    </div>

                    {this.state.modalVisible && <Modal
                        show
                        style={{ opacity: 1 }}
                        bsSize="lg"
                        className="wizard_modal"
                        aria-labelledby="contained-modal-title-vcenter"
                        animation
                    >
                        <Modal.Body className="custom_modal_body">
                            <div className="modal-body-funnel-edit">
                                {this.renderList()}
                            </div>
                            <div className="close_button" onClick={(e) => this.closeModal(e)}>
                                <span>&times;</span>
                            </div>
                        </Modal.Body>
                    </Modal>}
                </div>
            </Popover>
        );

        return (
            <ButtonToolbar className="custom_popover">
                Test<i class="fa fa-cog" aria-hidden="true" style={{ cursor: "pointer" }} onClick={this.handleClick}></i>
                {isPopOverEnable &&
                    <Overlay
                        show={isPopOverEnable}
                        target={this.state.target}
                        placement="bottom"
                        container={this}
                        rootClose={false}
                    >
                        {popoverClickRootClose}
                    </Overlay>}
            </ButtonToolbar>
        );
    }
}

export default EditFunnel;

EditFunnel.propTypes = {
    isPopOverEnable: PropTypes.bool,
    closePopover: PropTypes.func
};