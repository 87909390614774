const VISUALIZER = "visualizer/";
export const MODIFY_TEMPLATE = VISUALIZER + "MODIFY_TEMPLATE";
export const IS_LOADING = `${VISUALIZER}IS_LOADING`;
export const IS_FONT_LOADING = `${VISUALIZER}IS_FONT_LOADING`;
export const GET_FUNNEL_SUCCESS = `${VISUALIZER}GET_FUNNEL_SUCCESS`;
export const GET_FONTS_SUCCESS = `${VISUALIZER}GET_FONTS_SUCCESS`;
export const UPDATE_FUNNEL_SUCCESS = `${VISUALIZER}UPDATE_FUNNEL_SUCCESS`;
export const UPDATE_PAGE_SUCCESS = `${VISUALIZER}UPDATE_PAGE_SUCCESS`;
export const FUNNEL_PUBLISH_SUCCESS = `${VISUALIZER}UPDATE_FUNNEL_SUCCESS`;
export const LOAD_FUNNEL_SUCCESS = `${VISUALIZER}LOAD_FUNNEL_SUCCESS`;
export const LOAD_METAJSON_SUCCESS = `${VISUALIZER}LOAD_METAJSON_SUCCESS`;
export const LOAD_BUTTONS_SUCCESS = `${VISUALIZER}LOAD_BUTTONS_SUCCESS`;
export const EDIT_FUNNEL_SUCCESS = `${VISUALIZER}EDIT_FUNNEL_SUCCESS`;
export const SET_TEMPLATE_SUCCESS = `${VISUALIZER}SET_TEMPLATE_SUCCESS`;
export const SET_CURRENT_PAGE_ID = "SET_CURRENT_PAGE_ID";
export const LOAD_PAGE_VIEW_SUCCESS = `${VISUALIZER}LOAD_PAGE_VIEW_SUCCESS`;
export const SAVE_PAGE_VIEW_SUCCESS = `${VISUALIZER}SAVE_PAGE_VIEW_SUCCESS`;
export const GET_TEMPLATE_SUCCESS = `${VISUALIZER}GET_TEMPLATE_SUCCESS`;
export const IMPORT_PAGE_SUCCESS = `${VISUALIZER}IMPORT_PAGE_SUCCESS`;
export const GET_META_URL_SUCCESS = `${VISUALIZER}GET_META_URL_SUCCESS`;
export const GET_JSON_SUCCESS = `${VISUALIZER}GET_JSON_SUCCESS`;
export const GET_PAGE_SUCCESS = `${VISUALIZER}GET_PAGE_SUCCESS`;
export const CLEAR_FUNNEL_SUCCESS = `${VISUALIZER}CLEAR_FUNNEL_SUCCESS`;
export const LOAD_GOOGLE_FONT_SUCCESS = `${VISUALIZER}LOAD_GOOGLE_FONT_SUCCESS`;
export const EDIT_FUNNEL_ERROR = `${VISUALIZER}EDIT_FUNNEL_ERROR`;
export const CREATE_PAGE_ERROR = `${VISUALIZER}CREATE_PAGE_ERROR`;
export const UPDATE_FUNNEL_ERROR = `${VISUALIZER}UPDATE_FUNNEL_ERROR`;
export const UPDATE_PAGE_ERROR = `${VISUALIZER}UPDATE_PAGE_ERROR`;
export const CLEAR_PAGE_DATA = `${VISUALIZER}CLEAR_PAGE_DATA`;
export const CLEAR_JSON_DATA = `${VISUALIZER}CLEAR_JSON_DATA`;
export const FUNNEL_PUBLISH_ERROR = `${VISUALIZER}FUNNEL_PUBLISH_ERROR`;
export const IMPORT_PAGE_ERROR = `${VISUALIZER}IMPORT_PAGE_ERROR`;
export const LOAD_DOMAIN_LIST_SUCCESS = `${VISUALIZER}LOAD_DOMAIN_LIST_SUCCESS`;
export const LOAD_DOMAIN_LIST_ERROR = `${VISUALIZER}LOAD_DOMAIN_LIST_ERROR`;
export const SET_DOMAIN_FOR_FUNNEL_SUCCESS = `${VISUALIZER}SET_DOMAIN_FOR_FUNNEL_SUCCESS`;
export const SET_DOMAIN_FOR_FUNNEL_ERROR = `${VISUALIZER}SET_DOMAIN_FOR_FUNNEL_ERROR`;
export const CLEAR_DOMAIN_FOR_FUNNEL_SUCCESS = `${VISUALIZER}CLEAR_DOMAIN_FOR_FUNNEL_SUCCESS`;
export const IMPORT_FUNNEL_SUCCESS = `${VISUALIZER}IMPORT_FUNNEL_SUCCESS`;
export const IMPORT_FUNNEL_ERROR = `${VISUALIZER}IMPORT_FUNNEL_ERROR`;
export const IS_IMPORT_FUNNEL_LOADING = `${VISUALIZER}IS_IMPORT_FUNNEL_LOADING`;
export const INITIALIZE_VISUALIZER_DATA = `${VISUALIZER}INITIALIZE_VISUALIZER_DATA`;
export const UPDATE_VISUALIZER_DATA = `${VISUALIZER}UPDATE_VISUALIZER_DATA`;
export const REMOVE_CALLBACK_EVENT = `${VISUALIZER}REMOVE_CALLBACK_EVENT`;
export const IMPORT_FUNNEL_SUCCESS_MESSAGE = `${VISUALIZER}IMPORT_FUNNEL_SUCCESS_MESSAGE`;
export const IMPORT_FUNNEL_TEMPLATE_SUCCESS_MESSAGE = `${VISUALIZER}IMPORT_FUNNEL_TEMPLATE_SUCCESS_MESSAGE`;
export const GET_THUMBNAIL_SUCCESS = `${VISUALIZER}GET_THUMBNAIL_SUCCESS`;
export const GET_THUMBNAIL_ERROR = `${VISUALIZER}GET_THUMBNAIL_ERROR`;
