export class Visualizer {
    constructor(obj = {}) {
        this.isRightPanelActive = false;
        this.isLoadTemplates = false;
        this.isConfigLink = false;
        this.isPreviewEnable = false;
        this.showModalWindow = false;
        this.modalToShow = "";
        this.isZoom = false;
        this.templateTypeId = "";
        this.currentPageId = "";
        this.pageName = "";
        this.cellData = {};
        this.selectedData = {};
        this.isArrowSelected = false;
        this.selectedCellView = null;
        this.addedElementArray = {};
        this.linkButtonData = null;
        this.linkData = null;
        this.linkOfferData = null;
        this.linkUpsellData = null;
        this.linkCurrentPage = null;
        this.isPageDeleted = false;
        this.isDotted = false;
        this.isShareOpen = false;
        this.isText = false;
        this.renderChild = true;
        this.graphScale = 1;
        this.scrollerHeight = 0;
        this.scrollerWidth = 0;
        this.visualizerHeight = 0;
        this.visualizerWidth = 0;
        this.innerZoom = 25;
        this.outerZoom = 25.5;
        this.scrollLeftValue = 0;
        this.scrollHeightValue = 0;
        this.innerHeight = 0;
        this.innerLeft = 0;
        this.selectedDomainForFunnel = undefined;
        this.selectedFonts = [];
        this.callbackEvent = "";
        this.selectedView = "";
        this.deleteView = {};
        this.selectedTemplate = {};
        this.currentPage = {};
        this.funnelReferenceId = "";
        this.funnel = {};
        this.showImportFunnelModal = false;
        this.showImportFunnelTemplateButton = false;
        this.positionX = "";
        this.positionY = "";
        this.sourcePageLabel = "";
        this.linkViewId = "";
        this.multiSelectValue = [];
        this.targetViewId = "";
        this.showImportFunnelTemplateSuccessMessage = false;
    }
}