import React, { forwardRef, memo } from "react";
import PropTypes from "prop-types";

const GrapesJSComponent = forwardRef((props, ref) => {
    return (
        <div id={props.id} ref={ref} />
    );
});

GrapesJSComponent.propTypes = {
    id: PropTypes.string,
};

export default memo(GrapesJSComponent);
