import React from "react";
import PropTypes from "prop-types";
import { TextInput } from "../../../commons";

export const SplitPageSettings = ({ pageTypeLabel, pageName, pageNameChange, modifyLabel }) => (
    <TextInput
        name="pageName"
        label= { pageTypeLabel && pageTypeLabel + " Name " }
        value={pageName}
        onChange={pageNameChange}
        maxLength="25"
        id="pageName"
        inputAddon={true}
        onClick = {modifyLabel}
        btnText = "Save"
    />
);
SplitPageSettings.propTypes = {
    pageTypeLabel: PropTypes.string,
    pageName: PropTypes.string,
    pageNameChange: PropTypes.func,
    modifyLabel: PropTypes.func,
};
export default SplitPageSettings;