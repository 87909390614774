import { WBAsset } from "./models";
import { assetConfig } from "./asset-config-helper";

/*
    Asset Manager helper for grapejs
*/
export class AssetManagerHelper {

    static defaultAssets = [
        "https://s3.us-east-2.amazonaws.com/konnektfunnels-assets/Templates/arrow2.png",
        "https://s3.us-east-2.amazonaws.com/konnektfunnels-assets/Templates/arrow.png",
        "https://s3.us-east-2.amazonaws.com/konnektfunnels-assets/Templates/card.png",
        "https://s3.us-east-2.amazonaws.com/konnektfunnels-assets/Templates/funnelkonnekt-6.png",
        "https://s3.us-east-2.amazonaws.com/konnektfunnels-assets/Templates/page_background.jpg",
        "https://s3.us-east-2.amazonaws.com/konnektfunnels-assets/Templates/product.jpg",
        "https://s3.us-east-2.amazonaws.com/konnektfunnels-assets/Templates/sale_page_background.jpg",

    ];

    constructor(editor) {
        this._am = editor.AssetManager;
        this.default = this._am.getConfig();
        assetConfig(this.default, this._initializeConfig({}));
    }

    _initializeConfig(config = {}) {
        return {
            upload: 0,
            uploadText: config.uploadText || "Click or Drag and Drop files to upload",
            assets: config.assets,
            // Content to add where there is no assets to show
            // eg. 'No <b>assets</b> here, drag to upload'
            noAssets: config.noAssetsText || "No Assets added",
            // The name used in POST to pass uploaded files
            uploadName: config.uploadName || "files",
            // Custom headers to pass with the upload request
            headers: config.headers || {},
            // Custom parameters to pass with the upload request, eg. csrf token
            params: config.params || {},
            // If true, tries to add automatically uploaded assets.
            // To make it work the server should respond with a JSON containing assets
            // in a data key, eg:
            // {
            //  data: [
            //    'https://.../image.png',
            //    ...
            //    {src: 'https://.../image2.png'},
            //    ...
            //  ]
            // }
            autoAdd: config.autoAdd || 1,

            // Label for the add button
            addBtnText: config.addBtnText || "Add image",

            // Custom uploadFile function
            // @example
            uploadFile: (e) => {
                var files = e.dataTransfer ? e.dataTransfer.files : e.target.files;
                // ...send somewhere
            },
            // uploadFile: config.uploadFile || "",

            // Handle the image url submit from the built-in 'Add image' form
            // @example
            // handleAdd: (textFromInput) => {
            //   // some check...
            //   editor.AssetManager.add(textFromInput);
            // }
            // handleAdd: this._handleAdd,

            // Enable an upload dropzone on the entire editor (not document) when dragging
            // files over it
            dropzone: config.dropzone || 1,

            // Open the asset manager once files are been dropped via the dropzone
            openAssetsOnDrop: config.openAssetsOnDrop || 1,

            // Any dropzone content to append inside dropzone element
            // dropzoneContent: config.dropzoneContent || "",
            dropzoneContent: config.dropzoneContent || "Drop here your assets",

            // Default title for the asset manager modal
            modalTitle: config.modalTitle || "<div><p>Select Image</p> Need Icons? Try <a target='_blank' href='http://www.flaticon.com'>FlatIcon</a></div>"
        };
    }
    add(inputFiles = []) {
        return new Promise((resolve, reject) => {
            try {
                const isValidInputFileFmt = inputFiles.every((i) => i instanceof WBAsset);
                if (this._am && isValidInputFileFmt) {
                    this._am.add(inputFiles);
                    resolve();
                } else {
                    reject("Unable to resolve asset manager reference");
                }
            } catch (error) {
                console.error("Asset Manager :: ", error);
                reject(error);
            }
        });
    }

    get(url = "") {
        return new Promise((resolve, reject) => {
            try {
                if (this._am) {
                    const assetFile = this._am.get(url);
                    resolve(assetFile);
                } else {
                    reject("Unable to resolve asset manager reference");
                }
            } catch (error) {
                console.error("Asset Manager :: ", error);
                reject(error);
            }
        });
    }

    getAll() {
        return new Promise((resolve, reject) => {
            try {
                if (this._am) {
                    const assetFiles = this._am.getAll();
                    resolve(assetFiles);
                } else {
                    reject("Unable to resolve asset manager reference");
                }
            } catch (error) {
                console.error("Asset Manager :: ", error);
                reject(error);
            }
        });
    }

    getAllVisible() {
        return new Promise((resolve, reject) => {
            try {
                if (this._am) {
                    const assetFiles = this._am.getAllVisible();
                    resolve(assetFiles);
                } else {
                    reject("Unable to resolve asset manager reference");
                }
            } catch (error) {
                console.error("Asset Manager :: ", error);
                reject(error);
            }
        });
    }

    remove(url = "") {
        return new Promise((resolve, reject) => {
            try {
                if (this._am) {
                    this._am.remove(url);
                    resolve();
                } else {
                    reject("Unable to resolve asset manager reference");
                }
            } catch (error) {
                console.error("Asset Manager :: ", error);
                reject(error);
            }
        });
    }

    store() {
        return new Promise((resolve, reject) => {
            try {
                if (this._am) {
                    const store = this._am.store();
                    resolve(store);
                } else {
                    reject("Unable to resolve asset manager reference");
                }
            } catch (error) {
                console.error("Asset Manager :: ", error);
                reject(error);
            }
        });
    }

    load() {
        return new Promise((resolve, reject) => {
            try {
                if (this._am) {
                    const assets = this._am.load();
                    resolve(assets);
                } else {
                    reject("Unable to resolve asset manager reference");
                }
            } catch (error) {
                console.error("Asset Manager :: ", error);
                reject(error);
            }
        });
    }

    getContainer() {
        return this._am.getContainer();
    }

    render() {
        this._am.render();
        const am = document.getElementById("gjs-am-uploadFile");
        am.removeAttribute("disabled");
    }
}