
import joint from "jointjs";
import CreateLink from "./link";

const Paper = (element, model, width, height, gridSize, isStencil) => {
    const paper = new joint.dia.Paper({
        el: element,
        model,
        width,
        height,
        gridSize,
        linkPinning: false,
        background: {
            color: "#fff"
        },
        interactive: isStencil ? (function (cellView) {
            if (cellView.model instanceof joint.dia.Link) {
                // Disable the default vertex add functionality on pointerdown.
                return { vertexAdd: false };
            }
            return true;
        }) : false,
        validateConnection: function (cellViewS, magnetS, cellViewT) {
            const isLinkAlreadyExistBetweenSourceAndTarget = !!model
                .getConnectedLinks(cellViewT.model)
                .filter((link) => link.get("source").id === cellViewS.model.id)
                .length;
            return cellViewS === cellViewT || isLinkAlreadyExistBetweenSourceAndTarget ? false : true;
        },
        drawGrid: true,
        defaultLink: CreateLink(isStencil),
        restrictTranslate: true,
    });
    return paper;
};
export default Paper;