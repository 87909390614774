import React from "react";
import PropTypes from "prop-types";

const WalkthroughTile = ({ tile, onTileClick }) => (
    <div className="ibox walkthrough-tile">
        <div className="ibox-content goalBox">
            <div>
                <h3>
                    <img
                        src={tile.icon ? tile.icon : require("../../../img/icons/email.svg")}
                        alt=""
                    />
                    { tile.title }
                </h3>
            </div>
            <div className="description">
                { tile.description }
            </div>
            <div>
                <button
                    className="btn btn-default"
                    onClick={() => onTileClick(tile)}
                >
                    <i className="fa fa-plus m-r-sm"/>
                    Choose
                </button>
            </div>
        </div>
    </div>
);

WalkthroughTile.propTypes = {
    tile: PropTypes.object,
    onTileClick: PropTypes.func,
};

export default WalkthroughTile;