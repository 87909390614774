import {
    create_UUID
} from "../../utilities/create-unique-id";
import {
    generateSVGImage
} from "../../utilities/generateModel/generate-svg-image";

export const copyAndCreatePage = (cellView, x, y) => {
    const copyCellView = cellView.model.clone();
    copyCellView.position(x + 50, y + 50);
    copyCellView.attr(".borderBox/stroke", setPageBorderColor(cellView.model.get("isEdited")));
    Object.entries(setInitialPropertiesForClonePage)
        .forEach(
            ([key, value]) => copyCellView.setProperty(key, value)
        );
    copyCellView.setProperty("pageReferenceId", create_UUID());
    copyCellView.setProperty("pageViewReferenceId", create_UUID());
    if (!copyCellView.get("isTemplateSelected")) {
        copyCellView.setImage({
            "xlink:href": generateSVGImage(copyCellView.attributes.elementMetaData.templateTypeId)
        });
    }
    return copyCellView;
};

const setInitialPropertiesForClonePage = {
    isDefaultPage: false
};

const setPageBorderColor = (isOriginalPageEdited) => {
    return isOriginalPageEdited ? "green" : "red";
};