export const AssetManager = (assets, addAsset) => {
    return {
        assets,
        uploadFile: (e) => {
            var files = e.dataTransfer ? e.dataTransfer.files : e.target.files;
            addAsset(files);
            // ...send somewhere
        }
    };
};
