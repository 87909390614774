import React, { useContext, useState, useEffect } from "react";
import PropTypes from "prop-types";
import InputRange from "react-input-range";
import { VisualizerContext } from "./";
import { SplitPageCardView } from "./split-page-card-view";
import {
    getFontsApi
} from "./actions/visualizerActions";

import {
    VisualizerRightPanel,
    SplitRightPanelSettings
} from "./components/VisualizerRightPanel";


import {
    getPageLiveurl
} from "./utilities/helpers";
import { PageAnalytics } from "../create-funnel-visualizer/components/page-hits-and-counts";
import { getAnalyticsData } from "../create-funnel-visualizer/actions";


const SplitRightPanel = ({
    currentPage,
    convertWinnerPageToCurrentPage,
    saveSplitRatio,
    funnelReferenceId
}) => {

    const originalPageReferenceId = currentPage.get("pageReferenceId");
    const newPageReferenceId = currentPage.get("splitPageAttributes").pageReferenceId;
    const splitReferenceId = currentPage.get("splitReferenceId");
    const VisualizerContextData = useContext(VisualizerContext);
    const {
        funnelData,
        googleFontsList,
    } = VisualizerContextData;
    const pageSplitValue = 100 - currentPage.get("splitRatio");
    const [ sliderValue, setSliderValue ] = useState(pageSplitValue);
    const [ splitRightPanelCommonComponents, setSplitRightPanelCommonComponents ] = useState(null);

    const clickSplitSettings = (chosenAlphabet) => {
        const pageLiveUrl = getPageLiveurl(funnelData.page, currentPage);
        setSplitRightPanelCommonComponents(
            renderSplitRightPanelCommonComponents(
                {
                    pageLiveUrl,
                    chosenAlphabet,
                }
            )
        );
    };
    const renderSplitRightPanelCommonComponents = (params) => {
        const {
            pageLiveUrl,
            chosenAlphabet,
        } = params;
        return (
            <SplitRightPanelSettings
                pageLiveUrl={pageLiveUrl}
                fontList={googleFontsList}
                currentPage={currentPage}
                chosenAlphabet={chosenAlphabet}
                funnelReferenceId = {funnelReferenceId}
            />
        );
    };
    const onDeclareWinner = (winnerAlphabet) => {
        convertWinnerPageToCurrentPage(winnerAlphabet, currentPage);
    };
    const renderSplitCardView = (thumbnailUrl, alphabetToDisplay) => {
        return (
            <SplitPageCardView
                thumbnailUrl={thumbnailUrl}
                alphabet={alphabetToDisplay}
                clickSplitSettings={clickSplitSettings}
                onDeclareWinner={onDeclareWinner}
            />
        );
    };
    const updateSplitRatio = (value) => {
        currentPage.setProperty("splitRatio", 100 - value);
        saveSplitRatio({ value, originalPageReferenceId, newPageReferenceId, splitReferenceId });
    };
    const pages = [
        {
            referenceId: currentPage.get("pageReferenceId")
        },
        {
            referenceId: currentPage.get("splitPageAttributes").pageReferenceId
        }
    ];
    const getPageAnalytics = ({ funnelReferenceId, pageReferenceId, startDate, endDate }) => {
        return getAnalyticsData({
            funnelReferenceId,
            pageReferenceId,
            startDate,
            endDate,
            details: true
        });
    };
    return (
        <VisualizerRightPanel>
            <h4>Traffic Split Percentage</h4>
            <div className="slider-section">
                <InputRange
                    formatLabel={() => `${sliderValue}%`}
                    minValue={1}
                    maxValue={99}
                    value = {sliderValue}
                    onChange = {(value) => setSliderValue(value)}
                    onChangeComplete = {(value) => updateSplitRatio(value)}
                />
                <div className="input-range__label pageValue2">{100 - sliderValue}%</div>
            </div>
            <div className="col-xs-12 no-padding">
                {
                    <div className="row">
                        {
                            renderSplitCardView(currentPage.get("thumbnailUrl"), "A")
                        }
                        {
                            renderSplitCardView(currentPage.get("splitPageAttributes").thumbnailUrl, "B")
                        }
                    </div>
                }
            </div>
            {
                splitRightPanelCommonComponents
            }
            <div className="settings-analytics-wrapper">
                <PageAnalytics splitView getAnalyticsData={getPageAnalytics} funnel={funnelData} pages={pages}/>
            </div>
        </VisualizerRightPanel>
    );
};

SplitRightPanel.propTypes = {
    currentPage: PropTypes.object,
    funnelData: PropTypes.object,
    googleFontsList: PropTypes.object,
    freeTemplates: PropTypes.array,
    convertWinnerPageToCurrentPage: PropTypes.func,
    saveSplitRatio: PropTypes.func,
    funnelReferenceId: PropTypes.string
};

export default SplitRightPanel;