import { Templates } from "./models";
import * as actions from "./actions";

export const TEMPLATES_REDUCER_KEY = "TEMPLATES";

const initialState = {
    templates: [],
    premiumTemplates: [],
    userTemplates: [],
    purchasedTemplates: [],
    selectedPageTemplate: null,
};

export function reducer(state = initialState, action) {
    switch (action.type) {
        case actions.SET_SELECTED_PAGE_TEMPLATE:
            return Object.assign({}, state, { selectedPageTemplate: action.selectedPageTemplate });
        case actions.LOAD_TEMPLATES_SUCCESS: {
            const templateTypeKey = action.templateType || "templates";
            return {
                ...state,
                [templateTypeKey]: action.templates,
            };
        }
        default:
            return state;
    }
}