import React from "react";
import PropTypes from "prop-types";
import "./styles.css";
import { signUpUrl } from "../api/urls";

export class Login extends React.Component {
    static propTypes = {
        actions: PropTypes.object,
        isLoading: PropTypes.bool,
        isAuthentcated: PropTypes.bool,
        isLoginError: PropTypes.bool,
        history: PropTypes.object,
        userData: PropTypes.object,
        settingsData: PropTypes.object,
        maintenanceData: PropTypes.object,
        login: PropTypes.func
    }
    constructor(props,context) {
        super(props,context);
        this.state = {
            userName: "",
            passWord: "",
            isShowlogin: false,
            interval: ""
        };
        this.userNameCheck = this.userNameCheck.bind(this);
        this.passwordCheck = this.passwordCheck.bind(this);
    }
    componentWillReceiveProps() {
        if (this.props.isAuthentcated && !this.props.isLoginError) this.props.history.push("/dashboard");
    }
    userNameCheck = (event) => {
        this.setState({ userName: event.target.value },this.showLogin);
    }
    passwordCheck = (event) => {
        this.setState({ passWord: event.target.value },this.showLogin);
    }
    showLogin = () => {
        const { userName, passWord } = this.state;
        if (userName.length > 0 && passWord.length > 0) this.setState({ isShowlogin: true });
        else this.setState({ isShowlogin: false });
    }
    enterLogin = (e) => {
        e.preventDefault();
        const { userName, passWord } = this.state;
        this.setState({
            isShowlogin: false
        },() => {
            this.props.login(userName,passWord)
                .catch(() => {});
        });
    }
    render() {
        let { isLoading, isLoginError, maintenanceData } = this.props;
        const loginBtn = this.state.isShowlogin ? "form-group loginBtn active" : "form-group loginBtn";
        const maintenanceMessage = maintenanceData && maintenanceData.maintenanceMessage;
        const isMaintenanceScheduled = maintenanceData && maintenanceData.isMaintenanceScheduled;
        return (
            <>
                <div className="text-center login-container animated fadeInDown border-size-md ">
                    {isMaintenanceScheduled === 1 &&
                        <div className="alert alert-danger"><i className="fa fa-cog fa-spin m-r-xs fa-lg"/>{maintenanceMessage}</div>
                    }
                    <div className="login-screen gray-bg p-m b-r-xl">
                        <div className="text-center p-xs border-size-xs login-logo">
                            <img className="img-responsive" src={require("../img/funnelkonnekt-6_preview (1).png")} alt=""/>
                        </div>
                        <div className="p-m">
                            <h3>Welcome to Funnels</h3>
                            <p>Please enter your username and password to log in.</p>
                            <div className="m-t relative">
                                { isLoading && <div className="alert alert-info"><i className="fa fa-spinner fa-pulse fa-lg m-r-xs"/> Please wait...</div> }
                                {/* { isAuthentcated && <div className="alert alert-success"><i className="fa fa-check m-r-xs fa-lg"/> Successfully logging in</div>} */}
                                { isLoginError && !isLoading && <div className="alert alert-danger"><i className="fa fa-close m-r-xs fa-lg"/> Please enter valid Username / Password</div> }
                                <form onSubmit={this.enterLogin}>
                                    <div className="form-group">
                                        <input type="text" onChange={this.userNameCheck} className="form-control" placeholder="Username"/>
                                    </div>
                                    <div className="form-group">
                                        <input type="password" onChange={this.passwordCheck} className="form-control" placeholder="Password"/>
                                    </div>
                                    <div className={loginBtn}>
                                        <button type="submit" className="dim btn btn-primary block full-width m-b">Login</button>
                                    </div>
                                </form>
                                {/* <div className="form-group text-right">
                                    <NavLink className="forgotPassword" to="forgot_password"><small>Forgot password?</small></NavLink>
                                </div> */}
                                <hr className="hr-line-solid bg-muted" />
                                <p className="text-muted text-center"><small>Need an account?</small></p>
                                <a className="text-muted text-center" href={signUpUrl} target="_blank">Click Here to Sign Up</a>
                            </div>

                            <p className="m-t"> <small>Konnektive, LLC. &copy; 2019</small> </p>

                        </div>
                    </div>
                </div>
            </>
        );
    }
}

Login.contextTypes = {
    router: PropTypes.object
};