import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import isEmpty from "lodash/isEmpty";
import get from "lodash/get";

import { BaseWebpageCreator } from "./base-webpage-creator";
import FullPageLoader from "../commons/full-page-loader";
import {
    triggerNofiticationError,
} from "../commons/notification";

import { funnelTemplateCommands } from "./commands";

import { initButtonMetaData } from "./button-metadata";
import { getSavedFonts } from "../visualizer/selectors/funnel-template-selectors";
import { funnelTemplateActions } from "../visualizer/actions";
import { loadFunnelTemplateItem } from "../funnel-templates/actions";
import { getFunnelTemplateJsonData } from "../visualizer/actions/funnel-template-actions";
import * as saltSelectors from "../login/selectors";

import { getDefaults } from "./defaults";

import { PageTypes } from "../constants/template-types";
import { getTemplates } from "../templates/actions";
import {
    getFontsApi
} from "../funnels/visualizer/actions/visualizerActions";

const localStorageId = "gjs-funnel-template-";

class FunnelTemplateWebBuilder extends React.Component {
    constructor() {
        super();
        this.state = {
            pageData: null,
            isLoading: false,
            renderWebBuilder: false,
            templateData: null,
        };
        this.onEditorLoad = this.onEditorLoad.bind(this);
    }

    async componentDidMount() {
        let {
            templates,
            loadTemplates,
            salt,
        } = this.props;
        let newState = {};
        let params = null;
        const {
            funnelId: funnelTemplateReferenceId,
            pageId: pageReferenceId,
        } = this.props.match.params;
        const fontParams = { funnelReferenceId: funnelTemplateReferenceId, pageReferenceId };

        if (funnelTemplateReferenceId) {
            const funnelTemplateData = await loadFunnelTemplateItem(funnelTemplateReferenceId);
            const currentPage = funnelTemplateData.page.find((p) => p.referenceId === pageReferenceId);
            const currentPageView = get(currentPage, "pageView[0]");
            const currentPageTemplateId = get(currentPageView, "fTemplateId");
            if (currentPageView) {
                params = {
                    isEdited: currentPage.isEdited,
                    componentJsonUrl: currentPageView.componentJson,
                    stylesJsonUrl: currentPageView.stylesJson,
                    htmlUrl: currentPageView.url,
                    metaJsonUrl: currentPageView.metaJson || "",
                };
                try {
                    await Promise.all([
                        !isEmpty(templates) ? Promise.resolve(templates) : loadTemplates(),
                        getFontsApi(fontParams),
                        getFunnelTemplateJsonData(params, true),
                        PageTypes.getAllPageTypes(),
                    ])
                        .then(([templates, { result: fonts }, pageData]) => {
                            const selectedTemplate = templates.find((template) => template.templateId === currentPageTemplateId);
                            if (pageData) {
                                const {
                                    templateTypeId,
                                    templateId,
                                } = selectedTemplate;
                                if (pageData.setcomponentData) {
                                    pageData = {
                                        ...pageData,
                                        setcomponentData: JSON.parse(pageData.setcomponentData),
                                        setstylesData: JSON.parse(pageData.setstylesData),
                                    };
                                }
                                newState = {
                                    pageData,
                                    isLoading: false,
                                    templateTypeId,
                                    templateId,
                                    renderWebBuilder: true,
                                    templateData: selectedTemplate,
                                    funnelTemplateReferenceId,
                                    fonts,
                                    funnelTemplate: {
                                        funnelData: funnelTemplateData,
                                        currentPage,
                                        funnelReferenceId: funnelTemplateReferenceId,
                                        salt,
                                    },
                                    pageViewReferenceId: currentPageView.referenceId,
                                };
                                this.setState(newState);
                            }
                        })
                        .catch(err => {
                            triggerNofiticationError({ message: err.message });
                        });
                } catch (err) {
                    triggerNofiticationError({ message: err.message });
                }

            }
        }
    }

    onEditorLoad(editor) {
        initButtonMetaData(editor, null);
    }

    render() {
        const {
            history,
            savePageView,
        } = this.props;
        const {
            pageData,
            isLoading,
            templateTypeId,
            renderWebBuilder,
            templateData,
            funnelTemplate,
            funnelTemplateReferenceId,
            fonts
        } = this.state;

        const defaults = {
            localStorageId,
            templateTypeId,
            history,
            commands: funnelTemplateCommands,
        };
        return (
            <>
                <FullPageLoader showLoading={!renderWebBuilder} />
                {
                    renderWebBuilder && (
                        <BaseWebpageCreator
                            pageData={pageData}
                            { ...getDefaults(defaults) }
                            fonts={fonts}
                            onEditorLoad={this.onEditorLoad}
                            isLoading={isLoading}
                            previousUrl={`/edit-funnel-template/${funnelTemplateReferenceId}`}
                            templateData={templateData}
                            savePageView={savePageView}
                            funnelTemplate={funnelTemplate}
                        />
                    )
                }
            </>
        );
    }
}

FunnelTemplateWebBuilder.propTypes = {
    onEditorLoad: PropTypes.func,
    history: PropTypes.object,
    currentPage: PropTypes.object,
    funnelReferenceId: PropTypes.string,
    savePageView: PropTypes.func,
    fonts: PropTypes.array,
    funnelData: PropTypes.object,
    salt: PropTypes.string,
    selectedTemplateDataFromStore: PropTypes.object,
    funnelTemplate: PropTypes.object,
    match: PropTypes.object,
    templates: PropTypes.array,
    loadTemplates: PropTypes.func,
};

const mapStateToProps = (state) => {
    const settingsData = saltSelectors.getSettingsData(state);
    const salt = settingsData ? settingsData.salt : "";
    return {
        salt,
        templates: state.TEMPLATES.templates,
    };
};

const mapDispatchToProps = (dispatch) => ({
    getFunnelData: (funnelTemplateReferenceId) => dispatch(funnelTemplateActions.getFunnel(funnelTemplateReferenceId)),
    savePageView: (params) => dispatch(funnelTemplateActions.savePageView(params)),
    loadTemplates: (templateType) => dispatch(getTemplates({ templateType })),
});

export const FunnelTemplateCreator = connect(mapStateToProps, mapDispatchToProps)(FunnelTemplateWebBuilder);