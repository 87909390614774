import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
import isEmpty from "lodash/isEmpty";
import { withRouter } from "react-router";
import { connect } from "react-redux";
import { Modal } from "react-bootstrap";
import { bindActionCreators } from "redux";
import { getUserData, getAuthenticated, getSettingsData } from "./login/selectors";
import * as actions from "./login/actions";
import { LocalStorageHelper } from "./utilities";
import UserDataInjector from "./core/user/user-data";
import AppIntroModal from "./commons/modals/app-intro-modal";
import NotificationContainer from "./commons/notification";
import {
    getWelcomeModalSeenByUser,
    getWelcomeModalOnMenuClick,
    getWelcomeVideoUrl,
    getWelcomeVideoUrlFetchStatus,
    checkWalkthroughModalDisplay,
} from "./core/app-settings/selectors";
import {
    setWelcomeModalSeenByUser,
    setWelcomeModalOnMenuClick,
    openWalkthroughModal,
} from "./core/app-settings/actions";
import WalkthroughModal from "./commons/modals/walkthrough-modal";
import {
    getWalkthroughTiles,
} from "./core/app-settings/selectors";
import { NotificationEmitter, NOTIFICATION_EVENT_NAME } from "./commons/notification";
import * as funnelAction from "./funnels/actions";
import * as funnelActions from "./funnels/create-funnel-visualizer/actions";

import "./App.css";
import "./styles/app.scss";
import CRMEntry from "./crm-entry";
import Olark from "react-olark-plugin";
const MINUTES_UNITL_AUTO_LOGOUT = 30; // in mins
//timeout changed to 30 minutes to match CRM timeout time
const CHECK_INTERVAL = 30000; // in ms
const STORE_KEY = "lastAction";
export class AppView extends Component {

    static propTypes = {
        children: PropTypes.object.isRequired,
        history: PropTypes.object,
        actions: PropTypes.object,
        userData: PropTypes.object,
        SettingsData: PropTypes.object,
        match: PropTypes.object,
        active: PropTypes.bool,
        isAuthentcated: PropTypes.bool,
        isUnderMaintenance: PropTypes.func,
        showWelcomeModalOnMenuClick: PropTypes.bool,
        setWelcomeModalOnMenuClick: PropTypes.func,
        welcomeVideoUrl: PropTypes.string,
        isWelcomeVideoUrlFetchSuccess: PropTypes.bool,
        welcomeModalSeenByUser: PropTypes.bool,
        setWelcomeModalSeenByUser: PropTypes.func,
        walkthroughTiles: PropTypes.array,
        walkthroughModalDisplayStatus: PropTypes.bool,
        openWalkthroughModal: PropTypes.bool,
        walkthroughModalSeenByUser: PropTypes.func,
        showWalkthroughModalOnButtonClick: PropTypes.bool,
        walkthroughTilesFetchSuccess: PropTypes.bool,
        walkthroughModalEnabled: PropTypes.bool,
        walkthroughModalDisplayState: PropTypes.bool,
        funnelActions: PropTypes.object
    };
    constructor(props, context) {
        super(props, context);
        this.state = {
            modalShow: false,
            showWalkthroughModal: false,
        };
        this.checkAppIntroModalDisplay = this.checkAppIntroModalDisplay.bind(this);
        this.hideAppIntroModal = this.hideAppIntroModal.bind(this);
        this.goToCreateFunnel = this.goToCreateFunnel.bind(this);
        this.onWelcomeModalGetStartedButtonClick = this.onWelcomeModalGetStartedButtonClick.bind(this);
        this.localstorageManager = new LocalStorageHelper();
        this.check();
        this.initListener();
        this.initInterval();
        /*
         * Binding Funnel Walkthrough Functions
         */
        this.openWalkthroughModal = this.openWalkthroughModal.bind(this);
        this.closeWalkthroughModal = this.closeWalkthroughModal.bind(this);
        this.toggleWalkthroughModal = this.toggleWalkthroughModal.bind(this);
        /*
         * End of - Binding Funnel Walkthrough Functions
         */
    }
    componentWillMount() {
        const localstorageManager = new LocalStorageHelper();
        const accessToken = localstorageManager.load(LocalStorageHelper.keys.ACCESS_TOKEN);
        const expireIn = localstorageManager.load(LocalStorageHelper.keys.EXPIRE_IN);
        const tokenType = localstorageManager.load(LocalStorageHelper.keys.TOKEN_TYPE);
        const screenshot = (window.location.href.indexOf("screenshot") !== -1);
        if (!accessToken && !expireIn && !tokenType && !screenshot) {
            this.props.isUnderMaintenance().catch(() => {});
            this.props.history.push("/login");
        } else {
            this.props.actions.getAccountInfo();
        }
    }
    componentWillReceiveProps(nextProps) {
        if (nextProps.walkthroughModalDisplayStatus !== this.state.showWalkthroughModal) {
            this.setState({
                showWalkthroughModal: nextProps.walkthroughModalDisplayStatus
            });
        }
    }

    getLastAction() {
        return parseInt(localStorage.getItem(STORE_KEY));
    }

    setLastAction(lastAction) {
        localStorage.setItem(STORE_KEY, lastAction.toString());
    }

    initListener() {
        document.body.addEventListener("click", () => this.reset());
        document.body.addEventListener("mouseover",() => this.reset());
        document.body.addEventListener("mouseout",() => this.reset());
        document.body.addEventListener("keydown",() => this.reset());
        document.body.addEventListener("keyup",() => this.reset());
        document.body.addEventListener("keypress",() => this.reset());
    }

    reset() {
        this.setLastAction(Date.now());
    }

    initInterval() {
        setInterval(() => {
            this.check();
        }, CHECK_INTERVAL);
    }

    check() {
        const now = Date.now();
        const timeleft = this.getLastAction() + MINUTES_UNITL_AUTO_LOGOUT * 60 * 1000;
        const diff = timeleft - now;
        const isTimeout = diff < 0;
        if (isTimeout) {
            localStorage.clear();
            this.setState({
                modalShow: true,
            });
            this.props.history.push("/login");
        }
    }
    handleClose() {
        this.setState({ modalShow: false });
    }

    handleModal = (e) => {
        e.stopPropagation();
        this.setState({
            modalShow: false,
        });
    }


    /*
     * Welcome Modal Related Functions
     */
    checkAppIntroModalDisplay(isFirstLogin) {
        const accessToken = this.localstorageManager.load(LocalStorageHelper.keys.ACCESS_TOKEN);
        const {
            welcomeModalSeenByUser,
            showWelcomeModalOnMenuClick,
            isWelcomeVideoUrlFetchSuccess,
        } = this.props;
        /*
         * Show the Welcome Modal plugin when
         * 1. The user is logged in successfully.
         * 2. When we have received the config file from S3
         * 3. Either of
         *      a. the user clicked on Navigation Help Video
         *      b. or if the user has logged in for the first time in the app
         */
        return (
            accessToken &&
            isWelcomeVideoUrlFetchSuccess &&
            (showWelcomeModalOnMenuClick || (isFirstLogin && !welcomeModalSeenByUser))
        );
    }

    hideAppIntroModal() {
        this.props.setWelcomeModalOnMenuClick(false);
        this.props.setWelcomeModalSeenByUser(true);
    }

    goToCreateFunnel(params = {}) {
        let funnel = {
            title: params.title === "" ? "Custom Funnel" : params.title,
            offer: [],
            upsale: [],
            campaign: "1"
        };
        const {
            history,
        } = this.props;
        let nextPageNavigation = {
            url: "/editfunnel/",
            openFunnelTemplatesOnLoad: true,
            isNavigateToVisualizer: true,
            funnelTemplateTypeId: params.funnelTemplateTypeId
        };
        if (params.isCreateFromMarketPlace) {
            this.props.funnelActions.createFunnel(funnel)
                .then((funnelData) => {
                    NotificationEmitter.emit(NOTIFICATION_EVENT_NAME, { type: "success", message: "Funnel created successfully" });
                    let urlToNavigate = nextPageNavigation.url;
                    if (nextPageNavigation.isNavigateToVisualizer) {
                        urlToNavigate += funnelData.referenceId;
                    }
                    history.push({
                        pathname: urlToNavigate,
                        state: {
                            openFunnelTemplatesOnLoad: nextPageNavigation.openFunnelTemplatesOnLoad,
                            funnelTemplateTypeId: nextPageNavigation.funnelTemplateTypeId,
                        }
                    });
                })
                .catch(err => {
                    NotificationEmitter.emit(NOTIFICATION_EVENT_NAME, { type: "error", message: err.message });
                });
        } else {
            history.push({
                pathname: "/funnel",
                state: params,
            });
        }
        this.closeWalkthroughModal();
    }

    /*
     * Handling Funnel Walkthrough Modals
     */
    openWalkthroughModal() {
        this.toggleWalkthroughModal(true);
    }
    closeWalkthroughModal() {
        this.toggleWalkthroughModal(false);
    }
    toggleWalkthroughModal(status) {
        this.props.openWalkthroughModal(status);
    }
    onWelcomeModalGetStartedButtonClick() {
        this.hideAppIntroModal();
        setTimeout(() => {
            this.openWalkthroughModal();
        }, 250);
    }

    render() {
        const {
            welcomeVideoUrl,
            walkthroughTiles,
            walkthroughModalDisplayState,
        } = this.props;
        //only show the Olark support chat box on specified pages
        const olarkAllowedPages = ["dashboard","funnels","editfunnel"];
        const showOlarkChatbox = (pathname) => olarkAllowedPages.filter((page) => pathname.includes(page)).length;
        if (typeof olark === "function") {
            if (!showOlarkChatbox(location.pathname)) {
                // eslint-disable-next-line no-undef
                olark("api.box.hide");
            } else {
                // eslint-disable-next-line no-undef
                olark("api.box.show");
            }
        }
        return (
            <Fragment>
                {this.props.children}
                <NotificationContainer />
                <UserDataInjector>
                    {
                        (userData) => {
                            const showAppIntroModal = this.checkAppIntroModalDisplay(userData.isFirstLogin);
                            return (
                                <>
                                    <AppIntroModal
                                        videoUrl={welcomeVideoUrl}
                                        show={showAppIntroModal}
                                        onClose={this.hideAppIntroModal}
                                        onGetStartedButtonClick={this.onWelcomeModalGetStartedButtonClick}
                                    />
                                </>
                            );
                        }
                    }
                </UserDataInjector>
                {
                    !isEmpty(walkthroughTiles) && (
                        <WalkthroughModal
                            show={walkthroughModalDisplayState}
                            onClose={this.closeWalkthroughModal}
                            tiles={walkthroughTiles}
                            goToCreateFunnel={this.goToCreateFunnel}
                        />
                    )
                }
                <Modal show={this.state.modalShow} backdrop = "static" onHide={this.handleClose} aria-labelledby="contained-modal-title-lg">
                    <Modal.Body>
                        <h4 className="text-center">Session has expired please login again</h4>
                    </Modal.Body>
                    <Modal.Footer>
                        <a className="btn btn-primary btn-lg" onClick = {this.handleModal}>Ok</a>
                    </Modal.Footer>
                </Modal>
                <CRMEntry {...this.props}/>
                {
                    !location.pathname.endsWith("/login") && (
                        <Olark
                            siteId={"4643-243-10-5420"}
                            systemConfig={ { hb_primary_color: "#1ab394" } }
                        />
                    )
                }
            </Fragment>
        );
    }
}
function mapStateToProps(state) {
    return {
        userData: getUserData(state),
        settingsData: getSettingsData(state),
        isAuthentcated: getAuthenticated(state),
        showWelcomeModalOnNavigationItemClick: getWelcomeModalOnMenuClick(state),
        welcomeModalSeenByUser: getWelcomeModalSeenByUser(state),
        showWelcomeModalOnMenuClick: getWelcomeModalOnMenuClick(state),
        welcomeVideoUrl: getWelcomeVideoUrl(state),
        isWelcomeVideoUrlFetchSuccess: getWelcomeVideoUrlFetchStatus(state),
        walkthroughTiles: getWalkthroughTiles(state),
        walkthroughModalDisplayState: checkWalkthroughModalDisplay(state),
        funnelData: state.FUNNEL.funnelData
    };
}
function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators(actions, dispatch),
        funnelActions: bindActionCreators(funnelAction, dispatch),
        getAccountInfo: () => dispatch(actions.getAccountInfo()),
        isUnderMaintenance: () => dispatch(actions.isUnderMaintenance()),
        setWelcomeModalSeenByUser: (status) => dispatch(setWelcomeModalSeenByUser(status)),
        setWelcomeModalOnMenuClick: (status) => dispatch(setWelcomeModalOnMenuClick(status)),
        openWalkthroughModal: (status) => dispatch(openWalkthroughModal(status)),
        getFunnelData: (funnelReferenceId) => dispatch(funnelActions.getFunnel(funnelReferenceId)),
    };
}

export const App = withRouter(connect(mapStateToProps, mapDispatchToProps)(AppView));