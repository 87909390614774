import React from "react";
import PropTypes from "prop-types";

const LabelBox = ({ children }) => {
    return (
        <div className="label_box">
            {children}
        </div>
    );
};

export default LabelBox;

LabelBox.propTypes = {
    children: PropTypes.element
};