import * as TemplatesApi from "../api/templatesApi";
import { APIHelper, LocalStorageHelper } from "../utilities";
import { purchaseOrderApi, getPurchasedTemplatesForTenant } from "../api/purchaseApi";

export const TEMPLATES = "templates/";
export const IS_LOADING = `${TEMPLATES}IS_LOADING`;
export const SET_TEMPLATES = TEMPLATES + "SET_TEMPLATES";
export const SET_SELECTED_PAGE_TEMPLATE = TEMPLATES + "SET_SELECTED_PAGE_TEMPLATE";
export const LOAD_TEMPLATES_SUCCESS = `${TEMPLATES}LOAD_TEMPLATES_SUCCESS`;


export function setTemplates(templates) {
    return {
        type: SET_TEMPLATES,
        templates
    };
}

export function setSelectedPageTemplate(selectedPageTemplate) {
    return {
        type: SET_SELECTED_PAGE_TEMPLATE,
        selectedPageTemplate,
    };
}

export function loadTemplateSuccess(templates, templateType) {
    return {
        type: LOAD_TEMPLATES_SUCCESS,
        templates,
        templateType,
    };
}
export function toggleLoading(status = false) {
    return { type: IS_LOADING, status };
}

const TemplatesApiMap = {
    "userTemplates": TemplatesApi.userTemplatesGet,
    "premiumTemplates": TemplatesApi.premiumTemplates,
    "templates": TemplatesApi.templateGet,
};

export function getTemplates({ ...params }) {
    const includeAllResults = params.includeAllResults || false;
    const templateType = params.templateType || "templates";
    const getTemplatesApi = (includeAllResults) ? TemplatesApi.templateGetAll : TemplatesApiMap[templateType];
    return function (dispatch) {
        const localstorageManager = new LocalStorageHelper();
        const accessToken = localstorageManager.load(LocalStorageHelper.keys.ACCESS_TOKEN);
        if (accessToken) {
            dispatch(toggleLoading(true));
            return getTemplatesApi({ headers: APIHelper.getGenericHeaders() }).then((response) => {
                dispatch(loadTemplateSuccess(response.result, templateType));
                dispatch(toggleLoading(false));
                return response.result;
            }).catch(error => {
                // TODO - Funnel Get should return empty array in case of 404
                dispatch(loadTemplateSuccess([], templateType));
                dispatch(toggleLoading(false));
                throw (error);
            });
        }
    };
}


export function deleteTemplate(templateReferenceId) {
    return function (dispatch) {
        const localstorageManager = new LocalStorageHelper();
        const accessToken = localstorageManager.load(LocalStorageHelper.keys.ACCESS_TOKEN);
        if (accessToken) {
            dispatch(toggleLoading(true));
            return TemplatesApi.deleteTemplate(templateReferenceId).then(() => {
                dispatch(toggleLoading(false));
            }).catch(error => {
                dispatch(toggleLoading(false));
                throw (error);
            });
        }
    };
}

export const saveNewTemplate = (formData) => {
    return TemplatesApi.saveNewTemplate(formData);
};

export const saveExistingTemplate = (formData) => {
    return TemplatesApi.saveExistingTemplate(formData);
};
export function updateTemplate(status) {
    return function (dispatch) {
        const localstorageManager = new LocalStorageHelper();
        const accessToken = localstorageManager.load(LocalStorageHelper.keys.ACCESS_TOKEN);
        if (accessToken) {
            dispatch(toggleLoading(true));
            return TemplatesApi.updateTemplate(status).then(() => {
                dispatch(toggleLoading(false));
            }).catch(error => {
                dispatch(toggleLoading(false));
                throw (error);
            });
        }
    };
}

export const purchaseOrder = purchaseOrderApi;

export const getPurchasedTemplates = getPurchasedTemplatesForTenant;