import React from "react";
import PropTypes from "prop-types";
import WalkthroughSlider from "./walkthrough-slider";

const ChooseFunnelType = (props) => {
    const {
        items,
        onTileClick,
        goBack,
    } = props;
    return (
        <div className="walkthrough-body">
            <WalkthroughSlider
                items={items}
                onTileClick={onTileClick}
            />
            <div className="walkthrough-footer">
                <button
                    className="btn btn-default btn-sm"
                    onClick={goBack}
                >
                    <i className="fa fa-caret-left m-r-sm" />
                    Go back
                </button>
            </div>
        </div>
    );
};

ChooseFunnelType.propTypes = {
    items: PropTypes.array,
    onTileClick: PropTypes.func,
    goBack: PropTypes.func,
};

export default ChooseFunnelType;