import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import moment from "moment";


export class DesignerDetails extends React.Component {
    constructor(props, context) {
        super(props, context);
        this.props.loadDesigners();
    }
    render() {
        const { designer } = this.props;
        return (
            <div>
                <div className="row wrapper border-bottom white-bg page-heading">
                    <div className="col-lg-9">
                        <h2>Designer Details</h2>
                        <ol className="breadcrumb">
                            <li>
                                <Link to="/dashabord">Home</Link>
                            </li>
                            <li>
                                <Link to="/designers">Designers</Link>
                            </li>
                            <li className="active">
                                <strong>{designer ? designer.name : "designer details"}</strong>
                            </li>
                        </ol>
                    </div>
                </div>


                <div className="wrapper wrapper-content">
                    <div className="row animated fadeInRight">
                        <div className="col-md-4">
                            <div className="ibox float-e-margins">
                                <div className="ibox-title">
                                    <h5>Profile Detail</h5>
                                </div>
                                <div>
                                    <div className="ibox-content no-padding border-left-right">
                                        {/*<img alt="image" className="img-responsive" src={designer ? designer.img : null}/>*/}
                                        <img alt="image" className="img-responsive" src={designer ? designer.companyLogo : null}/>
                                    </div>
                                    <div className="ibox-content profile-content">
                                        <h4><strong>{designer ? designer.name : null}</strong></h4>
                                        <p><i className="fa fa-briefcase"/> {designer ? designer.profession : null}</p>
                                        <h5>
                                            About Us
                                        </h5>
                                        <p>
                                            {designer ? designer.description : null}
                                        </p>
                                        <a target="_blank" href={designer ? designer.work : ""}>Check Out Our Work!</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-8">
                            <div className="ibox float-e-margins">
                                <div className="ibox-title">
                                    <h5>Activites</h5>
                                    <div className="ibox-tools">
                                        <a className="collapse-link">
                                            <i className="fa fa-chevron-up"/>
                                        </a>
                                        <a className="dropdown-toggle" data-toggle="dropdown" href="#">
                                            <i className="fa fa-wrench"/>
                                        </a>
                                        <ul className="dropdown-menu dropdown-user">
                                            <li><a href="#">Config option 1</a>
                                            </li>
                                            <li><a href="#">Config option 2</a>
                                            </li>
                                        </ul>
                                        <a className="close-link">
                                            <i className="fa fa-times"/>
                                        </a>
                                    </div>
                                </div>
                                <div className="ibox-content">

                                    <div>
                                        <div className="feed-activity-list">

                                            <div className="feed-element">
                                                <a href="#" className="pull-left">
                                                    <img alt="image" className="img-circle" src={designer ? designer.profilePicture : null}/>
                                                </a>
                                                <div className="media-body ">
                                                    <small className="pull-right text-navy">{designer ? moment(designer.dateCreated).format("MM/DD/YYYY") : null}</small>
                                                    <strong>{designer ? designer.name : ""}</strong> joined <strong>Funnel Konnekt</strong>. <br/>
                                                    <small className="text-muted">{designer ? moment(designer.dateCreated).format("MM/DD/YYYY") : null}</small>
                                                </div>
                                            </div>


                                        </div>

                                        <button className="btn btn-primary btn-block m"><i
                                            className="fa fa-arrow-down"/> Show More
                                        </button>

                                    </div>

                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

DesignerDetails.propTypes = {
    designer: PropTypes.object,
    loadDesigners: PropTypes.func
};