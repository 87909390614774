import React from "react";
import {
    func,
    object,
    array,
    bool,
    number,
} from "prop-types";
import { connect } from "react-redux";
import ImportDefaultFunnelsModal from "./components/Modals/ImportDefaultFunnel/ImportDefaultFunnelModal";
import {
    defaultFunnelTemplates,
    premiumFunnelTemplates,
    getFunnels,
} from "../selectors";
import * as saltSelectors from "../../login/selectors";
import {
    getDefaultFunnels as getDefaultFunnelsApi,
    getPremiumFunnelTemplates,
    purchaseOrder,
} from "../create-funnel-visualizer/actions";
import {
    loadFunnels,
} from "../actions";
import {
    getTemplates,
} from "../../templates/actions";

const ImportDefaultFunnelsContainer = ({
    funnel,
    onClose,
    funnels,
    defaultFunnelTemplates,
    premiumFunnelTemplates,
    getPremiumFunnelTemplates,
    openFunnelTemplatesOnLoad,
    funnelTemplateTypeIdToPrepopulate,
    purchaseOrder,
    userData,
    loadFunnels,
    loadTemplates,
    getDefaultFunnels,
    handleFunnelImport,
}) => {
    return (
        <ImportDefaultFunnelsModal
            handleClose={onClose}
            show
            currentFunnelId={funnel.referenceId}
            funnels={funnels}
            getDefaultFunnels={getDefaultFunnels}
            defaultFunnelTemplates={defaultFunnelTemplates}
            premiumFunnelTemplates={premiumFunnelTemplates}
            getPremiumFunnelTemplates={getPremiumFunnelTemplates}
            openFunnelTemplatesOnLoad={openFunnelTemplatesOnLoad}
            funnelTemplateTypeIdToPrepopulate={funnelTemplateTypeIdToPrepopulate}
            purchaseOrder={purchaseOrder}
            userData={userData}
            funnel={funnel}
            loadFunnels={loadFunnels}
            loadTemplates={loadTemplates}
            onFunnelImport={handleFunnelImport}
        />
    );
};

ImportDefaultFunnelsContainer.propTypes = {
    funnels: array,
    funnel: object,
    onClose: func,
    defaultFunnelTemplates: array,
    premiumFunnelTemplates: array,
    getPremiumFunnelTemplates: func,
    openFunnelTemplatesOnLoad: bool,
    funnelTemplateTypeIdToPrepopulate: number,
    purchaseOrder: func,
    userData: object,
    loadFunnels: func,
    loadTemplates: func,
    getDefaultFunnels: func,
    handleFunnelImport: func,
};

ImportDefaultFunnelsContainer.defaultProps = {
    openFunnelTemplatesOnLoad: false,
};

const mapStateToProps = (state) => {
    return {
        funnels: getFunnels(state),
        defaultFunnelTemplates: defaultFunnelTemplates(state),
        premiumFunnelTemplates: premiumFunnelTemplates(state),
        userData: saltSelectors.getUserData(state),
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        getDefaultFunnels: () => dispatch(getDefaultFunnelsApi()),
        getPremiumFunnelTemplates: (purchasedTemplates) => dispatch(getPremiumFunnelTemplates(purchasedTemplates)),
        purchaseOrder: (params) => (purchaseOrder(params)),
        loadFunnels: (funnel) => dispatch(loadFunnels(funnel)),
        loadTemplates: (templateType) => dispatch(getTemplates({ templateType })),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(ImportDefaultFunnelsContainer);