import React, { useContext, useState, useEffect } from "react";
import PropTypes from "prop-types";
import MultiSelectDropdown from "./multi-select-dropdown";
import {
    VisualizerRightPanel
} from "./components/VisualizerRightPanel";
import { VisualizerContext } from "./";

const convertToFormat = (obj, selectedProducts) => {
    return {
        productId: obj.value,
        productName: obj.text,
        productStatus: null,
        productTitle: obj.text,
        productType: obj.type,
        isAssigned: selectedProducts.includes(obj.value) ? true : false,
    };
};

const MembershipArrowRightPanel = ({
    sourcePageLabel,
    currentPage,
    linkDetails,
    saveUpdatedButtonsAndLinkData,
    showProducts
}) => {
    const VisualizerContextData = useContext(VisualizerContext);
    const {
        funnelData
    } = VisualizerContextData;
    const offers = funnelData.offer || [];
    const productData = offers.length ? JSON.parse(offers).map((offer) => convertToFormat(offer, linkDetails.products)) : [];
    const elementData = linkDetails.elements;
    const defaultValue = elementData.filter((element) => element.isAssigned);
    const defaultProducts = productData.filter((product) => product.isAssigned);
    const [ selectedElements, setSelectedElements ] = useState(defaultValue);
    const [ selectedProducts, setSelectedProducts ] = useState(defaultProducts);
    const chooseTextField = (element) => element.elementName ? element.elementName : element.elementTitle;
    const chooseProductTextField = (product) => product.productName ? product.productName : product.productTitle;
    const onElementChange = (value) => {
        setSelectedElements(value);
    };
    const onProductChange = (value) => {
        setSelectedProducts(value);
    };
    const renderButtonsAndLinksDropDownComponent = () => {
        return (
            <MultiSelectDropdown
                label = {"Buttons / Links of " + sourcePageLabel}
                placeHolder = {"Choose " + sourcePageLabel + " Buttons / Links"}
                data={linkDetails.elements}
                textField= {element => chooseTextField(element)}
                groupBy="elementType"
                valueField="elementId"
                defaultValue={selectedElements}
                onChange={onElementChange}
            />
        );
    };

    const renderProductsDropDownComponent = () => {
        return (
            <MultiSelectDropdown
                label = {"Products"}
                placeHolder = {"Choose Products"}
                data={productData}
                textField= {product => chooseProductTextField(product)}
                groupBy="productType"
                valueField="productId"
                defaultValue={selectedProducts}
                onChange={onProductChange}
                note = {"Choose the products that go along with the selected button(s) or link(s)"}
            />
        );
    };

    const renderSaveButtonComponent = () => {
        return (
            <button
                onClick={() => saveUpdatedButtonsAndLinkData(selectedElements, selectedProducts)}
                className={"btn btn-primary m-l-xs pull-left"}
            >
                <i
                    className="fa fa-cloud-upload"
                    aria-hidden="true">
                </i>
                 Save
            </button>
        );
    };
    return (
        <VisualizerRightPanel>
            {
                renderButtonsAndLinksDropDownComponent()
            }
            {showProducts &&
                renderProductsDropDownComponent()
            }
            {
                renderSaveButtonComponent()
            }
        </VisualizerRightPanel>
    );
};

MembershipArrowRightPanel.propTypes = {
    sourcePageLabel: PropTypes.string,
    currentPage: PropTypes.object,
    linkDetails: PropTypes.object,
    saveUpdatedButtonsAndLinkData: PropTypes.func,
    showProducts: PropTypes.bool
};

export default MembershipArrowRightPanel;