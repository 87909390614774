import UUID from "uuid-js";
import get from "lodash/get";
import isArray from "lodash/isArray";
import {
    buttonChange,
    checkClass
} from "../utils/component";

const membershipPageTypes = [7, 8, 9, 10, 11, 12, 13];
export const button = (editor, { traits = [] }, { offers = [], upsell = [], pages = [], templateTypeId }) => {
    const c = {};
    const domc = editor.DomComponents;
    const defaultType = domc.getType("default");
    const defaultModel = defaultType.model;
    const defaultView = defaultType.view;
    const inputType = domc.getType("button");
    const inputModel = inputType.model;
    const container = editor.container;
    domc.addType("button", {
        model: defaultModel.extend({
            init: function () {
                const uid = UUID.create(4);
                const button = this.attributes.attributes["data-id"] ? this.attributes.attributes["data-id"] : `fkt-button-${uid.toString()}`;

                // To check for fkt classes in the element
                checkClass(this);
                this.addClass(`fkt-button-${uid.toString()}`);
                this.attributes.attributes["data-id"] = button;
                if (!this.attributes.attributes.hasOwnProperty("onclick")) {
                    this.attributes.attributes.onclick = "callMethod(event)";
                }
                this.doStuff();
            },
            doStuff() {
                var categsAction = this.get("traits").where({ name: "action" })[0];

                const isMemeberPage = membershipPageTypes && membershipPageTypes.find(page => page === templateTypeId);
                const isLogout = categsAction.attributes ? categsAction.attributes.options.find(func => func.value === "logout") : null;
                if (isMemeberPage && !isLogout) {
                    categsAction.attributes.options.push({ value: "logout", name: "Logout" });
                }

                const redirectTrait = this.get("traits").where({ name: "redirectUrl" })[0];
                let pageUrls = [];
                pageUrls = pages.map(page => ({ value: JSON.stringify(page), name: page.title }));
                pageUrls = [
                    { value: "", name: "Choose A Value" },
                    ...pageUrls,
                ];
                redirectTrait && redirectTrait.set("options", pageUrls);

                var offerTrait = this.get("traits").where({ name: "OFFER" })[0];
                let offerProduct = [];
                if (offers && offers[0] !== "") {
                    offerProduct.push({ value: "", name: "Choose Value" });
                    offers.forEach((offer, index) => {
                        if (offer.value === "all") {
                            offers.splice(index, 1);
                        } else {
                            offerProduct.push({ value: offer.value.toString(), name: offer.text });
                        }
                    });
                    offerTrait && offerTrait.set("options", offerProduct);
                    if (offerTrait) {
                        offerTrait.attributes.value = container.buttonMetaData.buttons.length > 0 && !offerTrait.attributes.value ? container.buttonMetaData.buttons[0].OFFER : null;
                    }
                } else
                    offerTrait && offerTrait.set("options", []);


                var upsellTrait = this.get("traits").where({ name: "UPSELL" })[0];
                let productUpsale = [];
                if (upsell && upsell[0] !== "") {
                    productUpsale.push();
                    productUpsale = upsell.map(upsellItem => (
                        { value: upsellItem.value ? upsellItem.value.toString() : "", name: upsellItem.text }
                    ));
                    productUpsale = [
                        { value: "", name: "Choose Value" },
                        ...productUpsale,
                    ];
                    upsellTrait && upsellTrait.set("options", productUpsale);
                } else
                    upsellTrait && upsellTrait.set("options", []);

                editor.trigger("component:toggled");
                editor.on("component:update", function (component) {
                    if (component.changed.redirectUrl) {
                        buttonChange(container, component.changed.redirectUrl, component.attributes.attributes["data-id"], "NEXT_PAGE");
                    } else if (component.changed.OFFER) {
                        buttonChange(container, component.changed.OFFER, component.attributes.attributes["data-id"], "OFFER");
                    } else if (component.changed.UPSELL) {
                        buttonChange(container, component.changed.UPSELL, component.attributes.attributes["data-id"], "UPSELL");
                    } else if (component.changed.animate) {
                        const compCls = component.get("classes");
                        const animatedInx = component._previousAttributes.classes.models.findIndex(model => model.id === "animated");
                        if (animatedInx >= 0) {
                            if (component.changed.animate === "none") {
                                compCls.remove("animated");
                                compCls.remove(component._previousAttributes.classes.models[animatedInx]);
                            } else {
                                compCls.forEach(element => {

                                    const options = component.get("traits").where({ name: "animate" })[0].attributes.options;
                                    options.forEach(option => {
                                        // eslint-disable-next-line
                                        if (element && option.name == element.id) {
                                            compCls.remove(element);
                                        }
                                    });
                                });
                            }
                        }
                        if (animatedInx >= 0 && component.changed.animate !== "none")
                            editor.getSelected().addClass(component.changed.animate);
                        else if (animatedInx < 0 && component.changed.animate !== "none")
                            editor.getSelected().addClass(["animated", component.changed.animate]);

                    } else if (component.changed.action) {
                        if (component.changed.action === "submit") {
                            component.attributes.attributes.onclick = "callMethod(event)";
                        } else if (component.changed.action === "route") {
                            component.attributes.attributes.onclick = "route(event)";
                        } else {
                            component.attributes.attributes.onclick = "";
                        }
                    } else if (component.changed.delay) {
                        const compCls = component.get("classes");

                        compCls.forEach(element => {
                            if (element && element.id.substring(0, 5) === "delay") {
                                compCls.remove(element);
                            }
                        });
                        if (component.changed.delay !== "none")
                            component.addClass(component.changed.delay);
                    } else if (component.changed.speed) {

                        const compCls = component.get("classes");

                        compCls.forEach(element => {
                            if ((element && element.id.substring(0, 4) === "slow") || (element && element.id.substring(0, 4) === "fast")) {
                                compCls.remove(element);
                            }
                        });
                        if (component.changed.speed !== "none")
                            component.addClass(component.changed.speed);
                    } else if (component.changed.loop) {
                        const compCls = component.get("classes");
                        if (component.changed.loop === "none") {

                            compCls.forEach(element => {
                                if ((element && element.id.substring(0, 8) === "infinite")) {
                                    compCls.remove(element);
                                }
                            });
                        }
                        compCls.forEach(element => {
                            if (element.id === "loop") {
                                compCls.remove(element);
                            }
                        });
                        if (component.changed.loop !== "none")
                            component.addClass(component.changed.loop);
                    }
                });

                editor.TraitManager.getTraitsViewer().render();
            },
            defaults: {
                ...inputModel.prototype.defaults,
                "custom-name": c.labelButtonName,
                tagName: "button",
                style: { width: "auto", height: "auto" },
                draggable: "div:not([data-gjs-type=wrapper])",
                unstylable: ["width", "height"],
                traits,
            },
        }, {
            isComponent: function (el) {
                if (el.tagName === "BUTTON" && !el.attributes["data-toggle"]) {
                    return { type: "button" };
                }
            },
        }),
        view: defaultView.extend({
            events: {
                "click": "handleClick"
            },

            init() {
                this.listenTo(this.model, "change:content", this.updateContent);
                this.listenTo(this.model, "change:title", this.updateContent);
            },

            updateContent() {
                this.el.innerHTML = this.model.get("content");
                const title = this.model.get("title");
                const buttonId = get(this, ["model", "attributes", "attributes", "data-id"]);
                const isButtonIdx = container.buttonMetaData.buttons.findIndex((bm) => {
                    return bm["data-id"] === buttonId;
                });
                if (isButtonIdx > -1) {
                    container.buttonMetaData.buttons.splice(isButtonIdx, 1, {
                        ...container.buttonMetaData.buttons[isButtonIdx],
                        "name": this.model.attributes.content,
                        "title": this.model.attributes.title,
                    });
                } else if (this.model.attributes.attributes["data-id"]) {
                    /*
                     * This block will populate the buttons Initially.
                     */
                    const { webBuilderElements } = container.props;
                    let products = [];
                    if (webBuilderElements && webBuilderElements.length) {
                        const matchedButtonInWebBuilderElements = webBuilderElements.find((element) => {
                            return (element.elementId === buttonId);
                        });
                        if (matchedButtonInWebBuilderElements && isArray(matchedButtonInWebBuilderElements.products)) {
                            products = matchedButtonInWebBuilderElements.products;
                        }
                    }
                    container.buttonMetaData.buttons.push({
                        "data-id": this.model.attributes.attributes["data-id"],
                        "name": this.model.attributes.content,
                        "title": this.model.attributes.title,
                        "target": "",
                        products,
                    });
                }
            },
            handleClick(e) {
                e.preventDefault();
            },
        }),
    });
};