import React, { useState, useEffect, useContext } from "react";
import PropTypes from "prop-types";
import {
    TextInput
} from "../../../../commons";

const EditPageName = ({ initialPageTitle, pageTypeLabel, savePageName, chosenAlphabet = "A" }) => {
    const [ currentPageTitle, setCurrentPageTitle ] = useState("");
    const pageNameChange = (value) => setCurrentPageTitle(value.target.value);
    const updatePageName = () => {
        return savePageName(currentPageTitle);
    };
    useEffect(() => {
        setCurrentPageTitle(initialPageTitle);
    }, [chosenAlphabet]);
    return (
        <>
            <TextInput
                name="pageName"
                label={pageTypeLabel + " Name "}
                value={currentPageTitle}
                onChange={pageNameChange}
                maxLength={25}
                id="pageName"
                inputAddon={true}
                onClick={updatePageName}
                btnText="Save"
                //className={className}
            />
        </>
    );
};
EditPageName.propTypes = {
    initialPageTitle: PropTypes.string,
    pageTypeLabel: PropTypes.string,
    savePageName: PropTypes.func,
    chosenAlphabet: PropTypes.string
};

export default EditPageName;