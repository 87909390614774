import $ from "jquery";

export const loadFontsCss = (html, fonts = []) => {
    let updatedHtml = fonts && fonts.map(font => {
        const fontFamily = font.fontFamily.split(" ").join("+");
        return (
            `<link href="https://fonts.googleapis.com/css?family=${fontFamily}" rel="stylesheet">`
        );
    });
    updatedHtml = updatedHtml.join("") + html;
    return updatedHtml;
};

export const getEditorHtml = (editor) => {
    const defaults = {
        addExportBtn: 1,
        btnLabel: "Save",
        preHtml: "<meta charSet='utf-8'>",
        postHtml: "",
    };
    if (editor) {
        const html = editor.getHtml();
        const component = JSON.stringify(editor.getComponents());
        const styles = JSON.stringify(editor.getStyle());
        const $html = $("<html />", { html: html });
        const bodyTag = $html.find("body");
        $html.prepend(defaults.preHtml);
        $(bodyTag).append(defaults.postHtml);
        const indexHtml = $html.html();
        const mainCss = editor.getCss();
        const updatedHtml = loadFontsCss(indexHtml);
        return {
            html: updatedHtml ? updatedHtml : indexHtml,
            css: mainCss,
            component_json: component,
            styles_json: styles,
        };
    }
    return null;
};