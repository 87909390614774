import { getUserData } from "../../login/selectors";
import {
    APP_SETTINGS_REDUCER_KEY,
} from "./app-settings-reducer";


import { localStorageUtilities } from "../../utilities";

const getValueFromStore = (state = {}, property) => state[APP_SETTINGS_REDUCER_KEY][property];

export const getWelcomeVideoUrl = (state = {}) => getValueFromStore(state, "welcomeVideoUrl");
export const getWelcomeModalSeenByUser = (state = {}) => getValueFromStore(state, "welcomeModalSeenByUser");
export const getWelcomeVideoUrlFetchStatus = (state = {}) => getValueFromStore(state, "isWelcomeVideoUrlFetchSuccess");
export const getWelcomeModalOnMenuClick = (state = {}) => getValueFromStore(state, "showWelcomeModalOnMenuClick");
/*
 * Walkthrough Funnel Data
 */
export const getWalkthroughModalEnabled = (state = {}) => getValueFromStore(state, "walkthroughModalEnabled");
export const getWalkthroughModalDisplay = (state = {}) => getValueFromStore(state, "showWalkthroughModal");
export const getWalkthroughTiles = (state = {}) => getValueFromStore(state, "walkthroughTiles");
export const getWalkthroughTilesFetchStatus = (state = {}) => getValueFromStore(state, "isWalkthroughConfigFetchSuccess");
export const getWalkthroughPeekabooSeenByUser = (state = {}) => getValueFromStore(state, "walkthroughPeekabooSeenByUser");

export const checkWalkthroughModalDisplay = (state = {}) => {
    return (localStorageUtilities.getAccessToken() && getWalkthroughModalDisplay(state) && getWalkthroughTilesFetchStatus(state));
};

export const checkWalkthroughPeekabooDisplay = (state = {}) => {
    const accessToken = localStorageUtilities.getAccessToken();
    const userData = getUserData(state);
    const { isFirstLogin } = userData;
    return (
        (accessToken && getWalkthroughTilesFetchStatus(state)) &&
        (!isFirstLogin && !getWalkthroughPeekabooSeenByUser(state) && getWalkthroughModalEnabled(state))
    );
};

export const getCRMModalState = (state = {}) => getValueFromStore(state, "isCRMModalEnabled");
export const getCRMModalStateInsideVisualizer = (state = {}) => getValueFromStore(state, "isCRMModalInsideVisualizerEnabled");
export const getActiveFunnelName = (state = {}) => getValueFromStore(state, "activeFunnelTitle");
