export const SET_WELCOME_MODAL_SEEN_BY_USER = "SET_WELCOME_MODAL_SEEN_BY_USER";
export const SHOW_WELCOME_MODAL_ON_MENU_CLICK = "SHOW_WELCOME_MODAL_ON_MENU_CLICK";
export const SET_WELCOME_VIDEO_URL = "app/SET_WELCOME_VIDEO_URL";
export const SET_WALKTHROUGH_CONFIG = "app/SET_WALKTHROUGH_CONFIG";
export const SET_WELCOME_VIDEO_URL_FETCH_FAILED = "app/SET_WELCOME_VIDEO_URL_FETCH_FAILED";
export const SET_WALKTHROUGH_CONFIG_FAILED = "app/SET_WALKTHROUGH_CONFIG_FAILED";
export const SHOW_WALKTHROUGH_MODAL = "app/SHOW_WALKTHROUGH_MODAL";
export const SET_WALKTHROUGH_MODAL_ENABLED = "app/SET_WALKTHROUGH_MODAL_ENABLED";
export const SET_WALKTHROUGH_PEEKABOO_SEEN_BY_USER = "app/SET_WALKTHROUGH_PEEKABOO_SEEN_BY_USER";
export const SET_CRM_ENTRY_MODAL = "SET_CRM_ENTRY_MODAL";
export const SET_CRM_ENTRY_MODAL_INSIDE_VISUALIZER = "SET_CRM_ENTRY_MODAL_INSIDE_VISUALIZER";
export const SET_ACTIVE_FUNNEL_TITLE = "SET_ACTIVE_FUNNEL_TITLE";
