export const attributes = {
    ".": { fill: "#ffffff", magnet: false,	stroke: "none" },
    ".body": { stroke: "#ffffff" },
    ".borderBox":
    {
        height: 166,
        width: 126,
        x: -3,
        y: -3,
        rx: "6"
    },
    ".copy": {
        event: "element:copy",
    },
    ".edit": {
        event: "element:edit",
    },
    ".label": { fontSize: "16", "text-anchor": "middle", x: 0, y: -17.5 },
    ".preview": {
        event: "element:preview"
    },
    ".settings": {
        event: "element:settings"
    },
    ".split": {
        event: "element:split"
    },
    ".trash": {
        event: "element:delete"
    },
    image: { height: 160, preserveAspectRatio: "none", width: 120, x: null, y: null },
};


const groups = {
    "out": {
        attrs: {
            ".port-body": {
                fill: "#ffffff",
                width: 120,
                height: 160,
                stroke: "#ffffff00",
                "stroke-width": 1,
                x: -120,
                y: -80,
                magnet: true,
                r: 10
            },
            ".linkicon": {
                x: -10,
                y: -100
            },
            ".port-label": {
                fill: "black"
            },
        },
        position: {
            name: "right"
        },
        label: {
            position: {
                name: "right",
                args: {
                    y: 10
                }
            }
        }
    },
    ".icons": {
        fill: "#1ab394",
        magnet: true

    },
    "in": {
        attrs: {
            ".port-body": {
                fill: "#fff",
                magnet: true,
                r: 10,
                stroke: "#000"
            },
            ".port-label": {
                fill: "#000"
            },
            "label": {
                position: {
                    args: {
                        y: 10
                    },
                    name: "left",
                },
            },
            "position": {
                name: "left"
            }
        }
    }
};

export const ports = {
    groups,
    items: [
        {
            attrs: {
                ".port-label": {
                    text: "",
                },
                group: "out",
                id: "",
            },
            group: "out",
            id: "",
        }
    ],

};

export const pageSize = {
    width: 120,
    height: 160
};

export const arrowAttributes = (color = "red") => {
    const defaultAttributes = {
        ".connection": {
            d: "M 220 234 L 691 189"
        },
        ".connection-wrap": {
            d: "M 220 234 L 691 189"
        },
        ".marker-target": {
            d: "M 10 0 L 0 5 L 10 10 z",
            fill: color,
            stroke: color
        }
    };
    return defaultAttributes;
};

export const textSize = {
    height: 50,
    width: 240
};

export const defaultTextAttributes = {
    ".": {
        fill: "#ffffff",
        stroke: "none"
    },
};

export const dottedLinkAttributes = () => {
    const defaultDottedLinkAttributes = {
        ".connection": {
            "stroke-dasharray": "2,5"
        },
        "stroke-dasharray": "2,5",
        ".marker-arrowhead": {
            display: "none"
        },
        display: "none"
    };
    return defaultDottedLinkAttributes;
};

