import { FUNNELS_REDUCER_KEY, FUNNEL_REDUCER_KEY } from "./reducer";

export const getFunnels = (state) => {
    return state[FUNNELS_REDUCER_KEY].funnels;
};

export const gethtmlPage = (state) => {
    return state[FUNNELS_REDUCER_KEY].htmlPage;
};

export const getFunnel = (state, funnelId) => {
    const { funnels } = state[FUNNELS_REDUCER_KEY];
    return funnels.find((f) => f.referenceId === funnelId);
};

export const defaultFunnelTemplates = (state) => {
    return state[FUNNEL_REDUCER_KEY].defaultFunnelTemplates;
};

export const defaultFunnelTemplate = (state) => {
    return state[FUNNELS_REDUCER_KEY].defaultFunnelTemplate;
};

export const premiumFunnelTemplates = (state) => {
    return state[FUNNELS_REDUCER_KEY].premiumFunnelTemplates;
};