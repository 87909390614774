import { fontProperties, blockRestriction } from "../consts";
import { checkClass } from "../utils/component";
import UUID from "uuid-js";

// File to add customizations to each block
export const configuration = (editor) => {
    const domc = editor.DomComponents;
    const dType = domc.getType("default");
    const dModel = dType.model;
    const dView = dType.view;

    // To get the text type
    const textType = domc.getType("text");
    const textModel = textType.model;

    // To get the image type
    const imageType = domc.getType("image");
    const imageModel = imageType.model;

    // To get the video type
    const videoType = domc.getType("video");
    const videoModel = videoType.model;

    // To get the label type
    const labelType = domc.getType("label");
    const labelModel = labelType.model;

    // Row
    domc.addType("row", {
        model: dModel.extend({
            defaults: Object.assign({}, dModel.prototype.defaults, {
                "custom-name": "Row",
                tagName: "div",
                droppable: "[data-gjs-type=column]",
                draggable: "[data-gjs-type=wrapper]",
                unstylable: ["width", "height", ...fontProperties]
            })
        },
        {
            isComponent: function (element) {
                if (element && element.classList && element.classList.contains("fk-row")) {
                    return { type: "row" };
                }
            },
        }),
        view: dView,
    });

    // Column
    domc.addType("column", {
        model: dModel.extend({
            defaults: Object.assign({}, dModel.prototype.defaults, {
                tagName: "div",
            })
        },
        {
            isComponent: function (element) {
                if (element && element.classList && element.classList.contains("fk-col")) {
                    return { type: "column" };
                }
            },
        }),
        view: dView,
    });

    // Text
    domc.addType("text", {
        model: textModel.extend({
            defaults: {
                ...textModel.prototype.defaults,
                tagName: "div",
                draggable: blockRestriction,
                unstylable: ["width", "height"]
            }
        }),
        view: textType.view,
    });

    // Image
    domc.addType("image", {
        model: imageModel.extend({
            defaults: {
                ...imageModel.prototype.defaults,
                tagName: "img",
                draggable: blockRestriction,
                unstylable: fontProperties
            }
        }),
        view: imageType.view,
    });

    // Video
    domc.addType("video", {
        model: videoModel.extend({
            defaults: {
                ...videoModel.prototype.defaults,
                tagName: "img",
                draggable: blockRestriction,
                unstylable: fontProperties
            }
        }),
        view: videoType.view,
    });

    // Product elements
    domc.addType("product-elements", {
        model: dModel.extend({
            init() {
                const uid = UUID.create(4);
                checkClass(this);
                this.addClass(`fkt-button-${uid.toString()}`);
            },
            defaults: Object.assign({}, dModel.prototype.defaults, {
                tagName: "div",
                draggable: "div:not([data-gjs-type=wrapper])",
                unstylable: ["width", "height", ...fontProperties]
            })
        },
        {
            isComponent: function (element) {
                if (element && element.classList && element.classList.contains("form-check") && element.tagName === "div") {
                    return { type: "product-elements" };
                }
            },
        }),
        view: dView,
    });

    // Label
    domc.addType("label", {
        model: labelModel.extend({
            init() {
                const uid = UUID.create(4);
                checkClass(this);
                this.addClass(`fkt-button-${uid.toString()}`);
            },
            defaults: {
                ...labelModel.prototype.defaults,
                tagName: "label",
                draggable: "div:not([data-gjs-type=wrapper])",
                unstylable: ["width", "height"]
            }
        }),
        view: labelType.view,
    });
};
