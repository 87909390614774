import moment from "moment";
import { APIHelper } from "../utilities";
import { providerUrl } from "./urls";



export function getSalesSummary() {
    return APIHelper.baseRequest(APIHelper.POST, `${providerUrl}/Dashboard/Conversions`, {
        headers: APIHelper.getGenericHeaders(),
        requestBody: {
            "dateRangeType": "dateCreated",
            "startDate": moment().subtract(1, "months"),
            "startTime": "12:00 AM",
            "endDate": moment(),
            "endTime": "11:59 PM"
        }
    });
}

export function getPageViews() {
    return APIHelper.baseRequest(APIHelper.GET, `${providerUrl}/Dashboard/PageViews`, {
        headers: APIHelper.getGenericHeaders()
    });
}

