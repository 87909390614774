import savePageTemplate from "./save-page-template";
import saveFunnelTemplate from "./save-funnel-template";
import saveFunnel from "./save-funnel";
import assetCommands from "./open-asset-manager";

const defaultCommands = {
    ...assetCommands,
};

const pageTemplateCommands = {
    ...defaultCommands,
    ...savePageTemplate,
};

const funnelTemplateCommands = {
    ...saveFunnelTemplate,
    ...defaultCommands,
};

const funnelCommands = {
    ...defaultCommands,
    ...saveFunnel,
};

export {
    pageTemplateCommands,
    funnelTemplateCommands,
    funnelCommands,
};