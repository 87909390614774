import React from "react";
import PropTypes from "prop-types";
import { List as FunnelTemplatesList } from "../funnels-list/list";
import { funnelTemplateConstants, funnelTemplateListConstants } from "./constants";
import PopUpModal from "../pop-up-modal";
import { TemplateTypeSelectDropdown, TemplateSettings } from "../template";
import { funnelTemplateTypeMap, funnelTemplateWithAllMap } from "../constants/funnel-template-types";
import "./styles.css";
import { TemplateTabs } from "../template";
import { toggleLoading } from "./actions";
import { NotificationEmitter, NOTIFICATION_EVENT_NAME } from "../commons/notification";

export class FunnelTemplates extends React.Component {
    constructor(props, context) {
        super(props, context);
        this.filterDropdown = React.createRef();
        this.redirectToAddCoursePage = this.redirectToAddCoursePage.bind(this);
        this.deleteRow = this.deleteRow.bind(this);
        this.handleDeleteRow = this.handleDeleteRow.bind(this);
        this.state = {
            funnels: [...props.funnels],
            search: "",
            field: "title",
            modalShow: false,
            deleteFunnelTemplateId: "",
            openSettings: false,
            selectedFunnelTemplate: {}
        };
        this.onDropdownChange = this.onDropdownChange.bind(this);
        this.updateFunnelStatus = this.updateFunnelStatus.bind(this);
        this.deleteRow = this.deleteRow.bind(this);
        this.loadFunnelTemplates = this.loadFunnelTemplates.bind(this);
    }

    componentDidMount() {
        this.loadFunnelTemplates();
    }

    async loadFunnelTemplates() {
        const {
            loadFunnelTemplates,
        } = this.props;
        try {
            await loadFunnelTemplates();
            // eslint-disable-next-line react/no-did-mount-set-state
            this.setState({ funnels: [...this.props.funnels] }, () => {
                const templateTypeId = 0;
                this.filterDropdown.current.setTemplateFromId(templateTypeId);
            });
        } catch (err) {
            // eslint-disable-next-line react/no-did-mount-set-state
            this.setState({
                funnels: []
            });
        }
    }

    redirectToAddCoursePage() {
        const {
            history,
        } = this.props;
        history.push(funnelTemplateConstants.funnelTemplatePath);
    }

    deleteRow(referenceId) {
        this.setState({ modalShow: true, deleteFunnelTemplateId: referenceId });
    }
    handleDeleteRow() {
        const { deleteFunnelTemplate,
            toggleLoading
        } = this.props;
        deleteFunnelTemplate(this.state.deleteFunnelTemplateId)
            .then(() => {
                this.loadFunnelTemplates();
                toggleLoading(false);
                NotificationEmitter.emit(NOTIFICATION_EVENT_NAME, { type: "success", message: "Funnel Template has been deleted successfully." });
            })
            .catch(() => {
                toggleLoading(false);
                NotificationEmitter.emit(NOTIFICATION_EVENT_NAME, { type: "error", message: "There was a problem in deleting the template." });
            });
        this.setState({ modalShow: false });


    }
    handleModal = () => {
        this.setState({ modalShow: false });
    }
    funnelDetails = (referenceId) => {
        const {
            history,
        } = this.props;
        const { editFunnelTemplatePath } = funnelTemplateConstants;
        var found = [...this.props.funnels].find(function (element) {
            return element.referenceId === referenceId;
        });
        if (!found) {
            history.push("/404");
        } else {
            history.push({ pathname: `${editFunnelTemplatePath}${referenceId}` });
        }
    }
    onDropdownChange(funnelTemplateType) {
        const funnelTemplates = [...this.props.funnels];
        if (!funnelTemplateType.templateTypeId) {
            this.setState({ funnels: [...funnelTemplates] });
        } else {
            let funnelTemplateList = funnelTemplates.filter(function (element) {
                return element.fListFunnelTypeId === funnelTemplateType.templateTypeId;
            });
            this.setState({ funnels: funnelTemplateList, initialTemplateTypeId: funnelTemplateType.templateTypeId });
        }
    }
    updateFunnelStatus(funnelDetails) {
        const {
            funnels,
            updateFunnelTemplate,
            updateFunnelError,
        } = this.props;
        let funnelTemplate = [...funnels].find(function (element) {
            return element.referenceId === funnelDetails.referenceId;
        });
        let funnel = { ...funnelTemplate };
        funnel.active = funnelDetails.active;
        funnel.fListFunnelTypeId = funnelDetails.templateType.templateTypeId;
        funnel.price = funnelDetails.price;
        updateFunnelTemplate({ funnel })
            .then(() => {
                this.setRightPanel(false);
                this.loadFunnelTemplates();
                toggleLoading(false);
                NotificationEmitter.emit(NOTIFICATION_EVENT_NAME, { type: "success", message: "Funnel Template has been updated successfully." });
            })
            .catch(() => {
                updateFunnelError(true);
                toggleLoading(false);
                NotificationEmitter.emit(NOTIFICATION_EVENT_NAME, { type: "error", message: "There was a problem in updating the template." });
            });
    }
    handleOutsideClick = (e) => {
        let editrightPanel = document.getElementById("editrightPanel");
        if (editrightPanel !== null && editrightPanel.contains(e.target)) {
            return;
        }
        if (this.state.openSettings && (e.target.id !== "editrightPanel")) {
            this.closeRightPanel();
        }
    }
    closeRightPanel = () => {
        this.setRightPanel(false);
        document.removeEventListener("click", this.handleOutsideClick, false);
    }

    setRightPanel = (status, funnelTemplate = {}) => {
        this.setState({ openSettings: status, selectedFunnelTemplate: funnelTemplate, initialTemplateTypeId: funnelTemplate.fListFunnelTypeId });
        document.addEventListener("click", this.handleOutsideClick, false);
    }
    render() {
        const { field, openSettings, selectedFunnelTemplate, funnels, initialTemplateTypeId } = this.state;
        const { isLoading,userData } = this.props;
        const funnelTemplates = [...funnels].filter(
            (funnel) => {
                return String(funnel[field]).toLowerCase().indexOf(String(this.state.search).toLowerCase()) !== -1;
            }
        );
        const page = funnelTemplates &&
            <FunnelTemplatesList
                isLoading={isLoading}
                details={this.funnelDetails}
                list={funnelTemplates}
                deleteRow={this.deleteRow}
                createItem={this.redirectToAddCoursePage}
                constants={funnelTemplateListConstants}
                parent="funnel-template"
                openSetting={this.setRightPanel}
                userData={userData}
            />;
        return (
            <>
                <div className="row wrapper border-bottom white-bg page-heading p-h-sm">
                    <div className="col-lg-9">
                        <h2>Templates</h2>
                        <ol className="breadcrumb">
                            <li><a href="/dashboard">Home</a></li>
                            <li className="active"><a>Funnel Templates</a></li>
                        </ol>
                    </div>
                </div>
                <div className="page-templates-wrapper row wrapper wrapper-content">
                    <div className="row white-bg p-xs">
                        <TemplateTabs history={this.props.history}>
                            <div className="m-t-md row">
                                <div className="col-xs-12 text-right m-b-md ">
                                    <div className="col-xs-6"></div>
                                    <div className="col-xs-6 text-center">
                                        <TemplateTypeSelectDropdown
                                            ref={this.filterDropdown}
                                            className="inline m-r-md"
                                            label="Filter by Funnel Type:"
                                            onDropdownChange={this.onDropdownChange}
                                            templateTypeMap={funnelTemplateWithAllMap}
                                            initialTemplateTypeId={initialTemplateTypeId}
                                        />
                                    </div>
                                </div>
                            </div>

                            {page}
                            {
                                openSettings && (<TemplateSettings
                                    closeAddNewTemplate={this.closeRightPanel}
                                    template={selectedFunnelTemplate}
                                    updateStatus={this.updateFunnelStatus}
                                    templateTypeMap={funnelTemplateTypeMap}
                                    initialTemplateTypeId={selectedFunnelTemplate && parseInt(selectedFunnelTemplate.fListFunnelTypeId)}
                                />)
                            }
                            <PopUpModal
                                modalToShow="deleteFunnelTemplateAlert"
                                showModalWindow={this.state.modalShow}
                                handleClose={this.handleModal}
                                handleConfirmation={this.handleDeleteRow}>
                            </PopUpModal>
                        </TemplateTabs>
                    </div>
                </div>
            </>
        );
    }
}
FunnelTemplates.propTypes = {
    funnels: PropTypes.array,
    loadFunnelTemplates: PropTypes.func,
    deleteRow: PropTypes.func,
    deleteFunnelTemplate: PropTypes.func,
    isLoading: PropTypes.bool,
    updateFunnelTemplate: PropTypes.func,
    loadTemplates: PropTypes.func,
    showNotification: PropTypes.func,
    history: PropTypes.object,
    updateFunnelError: PropTypes.func,
    toggleLoading: PropTypes.func,
    userData: PropTypes.object
};
FunnelTemplates.contextTypes = {
    router: PropTypes.object
};
