import React, { Component } from "react";
import PropTypes from "prop-types";
import joint from "jointjs";
import isEmpty from "lodash/isEmpty";
import isBoolean from "lodash/isBoolean";
import "../../../../node_modules/jointjs/dist/joint.css";
import $ from "jquery";
import get from "lodash/get";
import Elements from "./elements";
import FontsList from "./fontsList";
import MembershipProductList from "./membershipProductList";
import TextElement from "./textelement";
import constants from "../utilities/constants";
import { Multiselect } from "react-widgets";
import { Modal, ButtonToolbar, Row, Col, FormGroup, FormControl, ControlLabel, Button, Popover, Overlay } from "react-bootstrap";
import { ImportDefaultFunnelsModal } from "./import-default-funnels";
import {
    TextInput, SelectInput, funnelTitleError, //funnelAuthorError,
    funnelCampaignIdError, funnelTypeError, funnelOfferError, funnelUpsaleError
} from "../../../commons";
import SplitTestSettingsView from "../splitTest/SplitTestSettingsView";
import SplitTest from "../splitTest/splitTest";
import SharePage from "./share-page";
import PageHits from "./page-hits-and-counts";

import ChooseTemplates from "./choose-templates-modal";

import UserDataInjector from "../../../core/user/user-data";
import { PageAnalytics } from "./page-hits-and-counts";
import { NotificationEmitter,NOTIFICATION_EVENT_NAME } from "../../../../src/commons/notification";

/*
 * Analytics Display
 */
import AnalyticsContent from "./analytics-visualizer-element";

export default class Visualizer extends Component {
    static propTypes = {
        clearPageData: PropTypes.func,
        loadTemplates: PropTypes.func,
        loadButtons: PropTypes.func,
        pageData: PropTypes.object,
        deletePage: PropTypes.func,
        savefunnel: PropTypes.func,
        copyPage: PropTypes.func,
        publishFunnel: PropTypes.func,
        showSplitPreviewPopup: PropTypes.func,
        showSplitArrowPopup: PropTypes.func,
        setCellView: PropTypes.func,
        createNewPage: PropTypes.func,
        splitDuplicatePage: PropTypes.func,
        showTemplatesList: PropTypes.func,
        closeTemplatesList: PropTypes.func,
        splitChooseTemplate: PropTypes.func,
        splitArrowConfig: PropTypes.func,
        editFunnel: PropTypes.func,
        titleChange: PropTypes.func,
        setZoomValue: PropTypes.func,
        getSavedfonts: PropTypes.func,
        navigate: PropTypes.func,
        templateList: PropTypes.array,
        splitPageList: PropTypes.array,
        savedFonts: PropTypes.array,
        template: PropTypes.array,
        history: PropTypes.object,
        defaultFontValue: PropTypes.array,
        splitPages: PropTypes.array,
        funnel: PropTypes.object,
        funnelEdited: PropTypes.object,
        updatedPage: PropTypes.object,
        selectedTemplate: PropTypes.string,
        thumbnailUrl: PropTypes.string,
        saveButtonData: PropTypes.func,
        savePageViewData: PropTypes.func,
        setSplitPercent: PropTypes.func,
        metaJson: PropTypes.string,
        isTemplateSelected: PropTypes.bool,
        isError: PropTypes.bool,
        pageError: PropTypes.bool,
        showTemplates: PropTypes.bool,
        isLink: PropTypes.bool,
        templates: PropTypes.array,
        domainList: PropTypes.array,
        selectedDomainForFunnel: PropTypes.string,
        onChangeDomainSelection: PropTypes.func,
        changeDefaultPage: PropTypes.func,
        updatedFunnel: PropTypes.object,
        funnelTemplates: PropTypes.array,
        defaultFunnelTemplate: PropTypes.func,
        updateFunnelData: PropTypes.func,
        defaultFunnelTemplates: PropTypes.array,
        showSplitSetting: PropTypes.bool,
        splitSettingDetails: PropTypes.object,
        updateShowSplitSetting: PropTypes.func,
        isSelectedSplitPageDefaultPage: PropTypes.bool,
        splitPageName: PropTypes.string,
        selectedSplitPage: PropTypes.object,
        premiumFunnelTemplates: PropTypes.array,
        openFunnelTemplatesOnLoad: PropTypes.bool,
        getDefaultFunnels: PropTypes.func,
        templateSelectionConfig: PropTypes.object,
        purchaseOrder: PropTypes.func,
        userData: PropTypes.object,
        showAddNewDomainPopup: PropTypes.bool,
        getPremiumFunnelTemplates: PropTypes.func,
        loadFunnels: PropTypes.func,
        canAccessPremiumTemplates: PropTypes.bool,
    }
    constructor(props, context) {
        super(props, context);
        this.stencil = new joint.dia.Graph();
        this.Toolbar = new joint.dia.Graph();
        this.StencilPaper;
        this.deletedPageLinks = [];
        this.state = {
            modalVisible: false,
            activeList: null,
            isPopOverEnable: false,
            isRightPanelActive: false,
            isLoadTemplates: false,
            isConfigLink: false,
            isPreviewEnable: false,
            previewAlert: false,
            splitCopyAlert: false,
            deletePopup: false,
            sharePopup: false,
            isSplitThumbnail: false,
            linkAlert: false,
            fontAlert: false,
            buttonStatus: "",
            isButtonSelected: false,
            splitOtionsPopup: false,
            showTemplates: false,
            isZoom: false,
            templateTypeId: "",
            currentPageId: "",
            pageName: "",
            cellData: {},
            selectedData: {},
            isTemplateSelected: this.props.isTemplateSelected,
            isArrowSelected: false,
            leadPage: [constants.leadPageSvg].join(""),
            presellPage: [constants.presellPageSvg].join(""),
            checkoutPage: [constants.checkoutPageSvg].join(""),
            upsellPage: [constants.upsellPageSvg].join(""),
            thankyouPage: [constants.thankyouPageSvg].join(""),
            blankPage: [constants.blankPageSvg].join(""),
            optInPage: [constants.optInPageSvg].join(""),
            downloadPage: [constants.downloadPageSvg].join(""),
            salesVideoPage: [constants.salesVideoPageSvg].join(""),
            calendarPage: [constants.calendarPageSvg].join(""),
            surveyPage: [constants.surveyPageSvg].join(""),
            webinarPage: [constants.webinarPageSvg].join(""),
            webinarReplayPage: [constants.webinarReplayPageSvg].join(""),
            blogPostPage: [constants.blogPostPageSvg].join(""),
            membersAreaPage: [constants.membersAreaPageSvg].join(""),
            popUpPage: [constants.popUpPageSvg].join(""),
            emailPage: [constants.emailPageSvg].join(""),
            textPage: [constants.textPageSvg].join(""),
            genericPage: [constants.membersAreaPageSvg].join(""),
            membershipFolderPage: [constants.memberFolderSvg].join(""),
            selectedCellView: null,
            addedElementArray: (this.props.metaJson && (Object.keys(this.props.metaJson).length !== 2)) ? JSON.parse(this.props.metaJson) : { cells: [] },
            linkButtonData: null,
            linkData: null,
            linkOfferData: null,
            linkUpsellData: null,
            linkCurrentPage: null,
            isPageDeleted: false,
            isDotted: false,
            isShareOpen: false,
            isText: false,
            renderChild: true,
            graphScale: 1,
            scrollerHeight: 0,
            scrollerWidth: 0,
            visualizerHeight: 0,
            visualizerWidth: 0,
            innerZoom: constants.innerZoom,
            outerZoom: constants.outerZoom,
            scrollLeftValue: 0,
            scrollHeightValue: 0,
            innerHeight: 0,
            innerLeft: 0,
            selectedDomainForFunnel: this.props.selectedDomainForFunnel,
            selectedFonts: [],
            showImportExistingFunnelButton: false,
            showImportFunnelModal: props.openFunnelTemplatesOnLoad || false,
            selectedSplitSettingDetails: this.props.splitSettingDetails,
            showSplitSetting: this.props.showSplitSetting,
            isSelectedSplitPageDefaultPage: this.props.isSelectedSplitPageDefaultPage,
            splitPageName: this.props.splitPageName,
            ModalComponent: null,
            showAddNewDomainPopup: this.props.showAddNewDomainPopup,
            showValidationError: false,
            domainName: "",
            openFunnelTemplatesOnLoad: props.openFunnelTemplatesOnLoad,
            funnelDetails: null,
            campaignDetails: [],
            funnelName: null,
            isCampaignChangeEnabled: false,
            campaignList: null,
            isFunnelUpdateLoading: false,
        };
        /*
         * Analytics Data
         */
        this.state = {
            ...this.state,
            isAnalyticsDataFetched: false
        };
        this.checkIfEmptyVisualizer = this.checkIfEmptyVisualizer.bind(this);
        this.openImportFunnelPopup = this.openImportFunnelPopup.bind(this);
        if (props.openFunnelTemplatesOnLoad) {
            props.getDefaultFunnels();
        }
        this.showAnalyticsOverlay = this.showAnalyticsOverlay.bind(this);
        this.renderAnalyticsOnPage = this.renderAnalyticsOnPage.bind(this);
        this.handleOutsideClick = this.handleOutsideClick.bind(this);
        this.renderList = this.renderList.bind(this);
        this.closeModal = this.closeModal.bind(this);
        this.showModal = this.showModal.bind(this);
    }
    componentWillMount() {
        const { funnel } = this.props;
        const zoomLimit = funnel.visualizerJson && JSON.parse(funnel.visualizerJson);
        const zoomValue = parseFloat(zoomLimit ? zoomLimit.zoomValue : 1);
        const graphValue = parseFloat(zoomLimit ? zoomLimit.graphZoom : constants.innerZoom);
        const layoutValue = parseFloat(zoomLimit ? zoomLimit.layoutZoom : constants.outerZoom);
        this.setState({
            innerHeight: (window.innerHeight - 155.49),
            innerLeft: (window.innerWidth - 130),
            scrollerHeight: (((window.innerHeight - 155.49) * 100) / (window.innerHeight - 155.49)),
            scrollerWidth: (((window.innerWidth - 130) * 100) / (window.innerWidth - 130)),
            visualizerWidth: (((window.innerWidth - 130) * 100) / (window.innerWidth - 130)),
            visualizerHeight: (((window.innerHeight - 155.49) * 100) / (window.innerHeight - 155.49)),
            graphScale: zoomValue ? zoomValue : 1,
            innerZoom: graphValue ? graphValue : constants.innerZoom,
            outerZoom: layoutValue ? layoutValue : constants.outerZoom
        });
    }
    componentDidMount() {
        this.visualizerInitialize();
        if (this.props.metaJson) {
            this.getGraph(this.state.addedElementArray);
        }
        var outerLeft = document.getElementById("visualizer").offsetWidth;
        var innerLeft = document.getElementById("page-scroller").offsetWidth;
        var outerHeight = document.getElementById("visualizer").offsetHeight;
        var innerHeight = document.getElementById("page-scroller").offsetHeight;
        var graphHeight = document.querySelector(".visualizer-graph").offsetHeight;
        var graphWidth = document.querySelector(".visualizer-graph").offsetWidth;
        const scrollLeftValue = (innerLeft - outerLeft) / 2;
        const scrollHeightValue = (innerHeight - outerHeight) / 2;
        document.getElementsByClassName("joint-viewport")[1].style.transform = `matrix(${this.state.graphScale},0,0,${this.state.graphScale},0,1)`;
        document.addEventListener("click", this.handleOutsideClick, false);
    }
    componentWillUnmount() {
        document.removeEventListener("click", this.handleOutsideClick, false);
    }
    componentWillReceiveProps(nextProps) {
        const { selectedData, addedElementArray, isArrowChange, isDeleteArrow, cellData, selectedDomainForFunnel, isSplitThumbnail, showAddNewDomainPopup, funnelDetails } = this.state;
        const { selectedTemplate, metaJson, funnel, pageError, pageData, updatedPage, updatedFunnel, templates, thumbnailUrl,
            savedFonts, defaultFontValue, splitSettingDetails, showSplitSetting, isSelectedSplitPageDefaultPage, splitPageName } = nextProps;
        if (this.props.defaultFontValue !== defaultFontValue) {
            this.setState({
                selectedFonts: defaultFontValue
            });
        }
        if (this.props.splitSettingDetails !== splitSettingDetails) {
            this.setState({ selectedSplitSettingDetails: splitSettingDetails });
        }
        if (this.props.showSplitSetting !== showSplitSetting) {
            this.setState({ showSplitSetting: showSplitSetting });
        }
        if (selectedTemplate !== this.props.selectedTemplate) {
            const thumbnailUrl = isSplitThumbnail ? constants.splitPageSvg : selectedTemplate.thumbnailUrl;
            const cellId = cellData.id;
            this.updateTemplateThumbnail(cellId, thumbnailUrl);
        } else if (!isSplitThumbnail) {
            this.updateThumbnail(funnel, selectedTemplate);
        }
        this.setState({ selectedDomainForFunnel: nextProps.selectedDomainForFunnel });
        this.setState({ showAddNewDomainPopup: nextProps.showAddNewDomainPopup });
        if (updatedPage && !selectedTemplate) {
            const updatedTemplate = templates.find(item => {
                return item.templateId === updatedPage.pageView[0].fTemplateId;
            });
            this.setState({ selectedTemplate: updatedTemplate });
        }
        if (this.props.isSelectedSplitPageDefaultPage !== isSelectedSplitPageDefaultPage) {
            this.setState({ isSelectedSplitPageDefaultPage: isSelectedSplitPageDefaultPage });
        }
        if (this.props.splitPageName !== splitPageName) {
            this.setState({ splitPageName: splitPageName });
        }
        const zoomLimit = funnel.visualizerJson && JSON.parse(funnel.visualizerJson);
        const zoomValue = parseFloat(zoomLimit ? zoomLimit.zoomValue : 1);
        const graphValue = parseFloat(zoomLimit ? zoomLimit.graphZoom : constants.innerZoom);
        const layoutValue = parseFloat(zoomLimit ? zoomLimit.layoutZoom : constants.outerZoom);
        if (metaJson && (metaJson !== this.props.metaJson) && (typeof (metaJson) === "string")) {
            const loadedArray = JSON.parse(metaJson);
            const newLoadedArray = ((Object.keys(metaJson).length > 2) && (Object.keys(loadedArray).length === 1)) ? loadedArray : { cells: [] };
            if ((newLoadedArray !== addedElementArray)) {
                this.setPosition(nextProps.funnel, newLoadedArray);
            }
        }
        if (metaJson && (metaJson !== this.props.metaJson) && (typeof (metaJson) === "string")) {
            const loadedArray = JSON.parse(metaJson);
            // const { page } = funnel;
            const newLoadedArray = ((Object.keys(metaJson).length > 2) && (Object.keys(loadedArray).length === 1)) ? loadedArray : { cells: [] };
            this.setState({
                addedElementArray: newLoadedArray,
                graphScale: zoomValue,
                innerHeight: (window.innerHeight - 155.49),
                innerLeft: (window.innerWidth - 130),
                scrollerHeight: (((window.innerHeight - 155.49) * 100) / (window.innerHeight - 155.49)),
                scrollerWidth: (((window.innerWidth - 130) * 100) / (window.innerWidth - 130)),
                visualizerWidth: (((window.innerWidth - 130) * 100) / (window.innerWidth - 130)),
                visualizerHeight: (((window.innerHeight - 155.49) * 100) / (window.innerHeight - 155.49)),
                innerZoom: graphValue,
                outerZoom: layoutValue
            }, () => {
                document.getElementsByClassName("joint-viewport")[1].style.transform = `matrix(${this.state.graphScale},0,0,${this.state.graphScale},0,1)`;
                this.getGraph(newLoadedArray);
                this.setLinkColor(newLoadedArray);
                this.updateThumbnail(funnel, selectedTemplate);
                this.checkIfEmptyVisualizer();
            });
        }
        if (updatedPage && (updatedPage !== this.props.updatedPage)) {
            const loadedArray = metaJson && JSON.parse(metaJson);
            const newLoadedArray = metaJson && ((Object.keys(metaJson).length > 2) && (Object.keys(loadedArray).length === 1)) ? loadedArray : { cells: [] };
            this.setLinkColor(newLoadedArray);
            this.updateThumbnail(funnel, selectedTemplate);
        }
        this.removehtmlElement();
        if (!this.props.pageError && pageError) {
            this.removeUnwantedCell();
        }

        if (funnel !== funnelDetails) {
            this.setState({
                funnelDetails: funnel
            }, () => this.getCampaignList());
        }
    }

    handleOutsideClick(e) {
        /*
         * Give consistent class names
         */
        if (!e.target.classList.contains("rotatable") && !e.target.classList.contains("btn-visualizer-analytics")) {
            this.showAnalyticsOverlay(false);
        }
    }

    setPosition = (funnel, loadedArray) => {
        loadedArray.cells.forEach((aea) => {
            const newCell = this.stencil.getCell(aea.id);
            const currentPage = funnel.page && funnel.page.find((element) => {
                return element.cellViewId === aea.id;
            });
            newCell && newCell.attr({
                ".label": {
                    text: currentPage && currentPage.title,
                    y: -17.5,
                    x: 0
                },
            });
        });
    }
    setLinkColor = (loadedArray, updatedPage) => {
        loadedArray.cells.forEach((aea) => {
            const newCell = this.stencil.getCell(aea.id);
            if (newCell && newCell.attributes.isButtonAssigned) {
                if (newCell.attributes && newCell.attributes.isLinkAssigned) {
                    newCell.attr(".marker-target", {
                        ...newCell.attributes.attrs[".marker-target"],
                        stroke: "#31d0c6",
                        fill: "#31d0c6"
                    });
                } else {
                    newCell && newCell.attr(".marker-target", {
                        ...newCell.attributes.attrs[".marker-target"],
                        stroke: "green",
                        fill: "green"
                    });
                }
            } else {
                newCell && newCell.attr(".marker-target", {
                    ...newCell.attributes.attrs[".marker-target"],
                    stroke: "red",
                    fill: "red"
                });
            }
        });
    }
    updateTemplateThumbnail = (cellId, thumbnailUrl) => {
        const { isSplitThumbnail } = this.state;
        var newCell = this.stencil.getCell(cellId);
        if (newCell && thumbnailUrl) {
            newCell.attr("image/xlink:href", thumbnailUrl);
            newCell.attr({
                ".borderBox": {
                    width: 126,
                    height: 166,
                    x: -3,
                    y: -3,
                    stroke: (!isSplitThumbnail) ? "#979797" : "#ffffff",
                    rx: "6"
                }
            });
        }
    }
    updateThumbnail = (updatedFunnelData) => {
        const { addedElementArray } = this.state;
        addedElementArray.cells && addedElementArray.cells.forEach((element) => {
            var newCell = this.stencil.getCell(element.id);
            if (newCell && newCell.isElement()) {
                const page = updatedFunnelData.page && updatedFunnelData.page.find((uf) => uf.active && uf.cellViewId === element.id);
                if ((page && page.pageView[0].fTemplateId)) {
                    const pageView = page && page.pageView[0];
                    if (page.isEdited && !page.splitEnabled) {
                        newCell.attr("image/xlink:href", `${page.thumbnailUrl}?` + new Date().getTime());
                        newCell.attr({
                            ".borderBox": {
                                width: 126,
                                height: 166,
                                x: -3,
                                y: -3,
                                stroke: "#979797",
                                rx: "6"
                            }
                        });
                    } else if (page && page.splitEnabled) {
                        const splitRefId = page.splitReferenceId;
                        const allSplitPagesEdited = updatedFunnelData &&
                            updatedFunnelData.page
                                .filter(
                                    (page) => page.splitReferenceId &&
                                        page.active &&
                                        page.splitReferenceId === splitRefId && page.isEdited
                                )
                                .length === 2;
                        const splitImge = "data:image/svg+xml;utf8," + encodeURIComponent(constants.splitPageSvg);
                        const splitPageType = this.getPageType(newCell && newCell.attributes.elementMetaData.templateTypeId);
                        var index = splitPageType.indexOf(" ");
                        const splitPageTitle = splitPageType.substring(0, index);
                        newCell.attr("image/xlink:href", `${splitImge}`);
                        newCell.attr({
                            ".label": {
                                text: splitPageTitle,
                            },
                            ".borderBox": {
                                width: 126,
                                height: 166,
                                x: -3,
                                y: -3,
                                stroke: !allSplitPagesEdited ? "#FF0000" : "#ffffff",
                                rx: "6"
                            }
                        });
                    } else {
                        const template = this.props.templates.find((t) => t.templateId === pageView.fTemplateId);
                        if (page.thumbnailUrl) {
                            newCell.attr("image/xlink:href", page.thumbnailUrl);
                            newCell.attr({
                                ".borderBox": {
                                    width: 126,
                                    height: 166,
                                    x: -3,
                                    y: -3,
                                    stroke: !page.isEdited ? "#FF0000" : "#979797",
                                    rx: "6"
                                }
                            });
                        } else {
                            if (template) {
                                newCell.attr("image/xlink:href", template.thumbnailUrl);
                                newCell.attr({
                                    ".borderBox": {
                                        width: 126,
                                        height: 166,
                                        x: -3,
                                        y: -3,
                                        stroke: !page.isEdited ? "#FF0000" : "#979797",
                                        rx: "6"
                                    }
                                });
                            }
                        }
                    }
                }
            }
        });
    }
    visualizerInitialize = () => {
        const addedElement = { cells: [] };
        const { openRightPanel, closeRightPanel, getZoomValue, adjustVertices, blankSave, clonePage, changeSource, getPageType, removeText, addTextblock, textChange, removehtmlElement, editableText, endDottedLine, startMove, setTemplateTypeId, setCellData, innerZoom, setSelectedData, modifyCell, addElement, isLoginPageAdded, addCells, navigateToBuilder, removeCell, createNewPage, saveGraph, linkSettings, showTemplate, showPagePreview, deleteLinkCallback, deletePopup, startDottedLine, getSavedfonts, splitPanel, checkSplitCopy, checkSplitArrow, getPageData } = this;
        const { settings } = this.refs;
        const { selectedTemplate, navigate, deletePage, savefunnel, metaJson, setCellView } = this.props;
        const { visualizerWidth, visualizerHeight, templateTypeId, cellData, leadPage, isDotted, presellPage, membershipFolderPage, checkoutPage, upsellPage, thankyouPage, selectedData, isDelete, isError, blankPage, optInPage, downloadPage, isRightPanelActive, salesVideoPage, calendarPage, surveyPage, webinarPage, webinarReplayPage, blogPostPage, membersAreaPage, popUpPage, emailPage, textPage, genericPage, selectedSplitSettingDetails } = this.state;
        const { openTemplateSelection } = this;
        var canvas = $("#canvas");
        var el1;
        var gridSize = 20;
        var width = visualizerWidth * innerZoom;
        var height = visualizerHeight * innerZoom;
        this.StencilPaper = new joint.dia.Paper({
            el: this.refs.visualizer,
            model: this.stencil,
            width: width,
            height: height,
            gridSize: gridSize,
            linkPinning: false,
            background: {
                color: "#fff"
            },
            interactive: function (cellView) {
                if (cellView.model instanceof joint.dia.Link) {
                    // Disable the default vertex add functionality on pointerdown.
                    return { vertexAdd: false };
                }
                return true;
            },
            drawGrid: true,
            defaultLink: new joint.dia.Link({
                attrs: {
                    ".connection": {
                        d: "M 220 234 L 691 189"
                    },
                    ".connection-wrap": {
                        d: "M 220 234 L 691 189"
                    },
                    ".marker-target": {
                        d: "M 10 0 L 0 5 L 10 10 z",
                        stroke: "red",
                        fill: "red"
                    },
                },
            }),
        });
        const ToolbarPaper = new joint.dia.Paper({
            el: this.refs.stencil,
            height: 3800,
            model: this.Toolbar,
            interactive: false,
            linkPinning: false,
            defaultLink: new joint.dia.Link({
                attrs: {
                    ".marker-target": { d: "M 10 0 L 0 5 L 10 10 z" }
                }
            }),
        });
        this.StencilPaper.on("blank:pointerdown", function (evt, x, y) {
            // DottedLine
            startDottedLine(evt, x, y);
            addTextblock(evt, x, y);
            removehtmlElement();

        });
        this.StencilPaper.on("cell:pointerdblclick", function (cellView, evt, x, y) {
            const cell = cellView.model;
            if (cell.attributes.type === "standard.Rectangle") {
                editableText(cell, evt, x, y);
            }
        });
        this.StencilPaper.on("blank:pointermove", function (evt, x, y) {
            startMove(evt, x, y);
        });
        this.StencilPaper.on("blank:pointerup", function (evt, x, y) {
            blankSave();
        });
        this.stencil.on("change:source change:target", function (cellView) {
            if (cellView.isLink() && cellView.hasLoop()) {
                cellView.remove();
            }
        });
        this.stencil.on("change:vertices", function (cellView) {
            if (cellView.isLink()) {
                changeSource(cellView);
            }
        });
        this.stencil.on("change:position", function (evt, x, y) {
        });
        this.StencilPaper.on("link:options", function (cellView, evt) {
            evt.stopPropagation();
            checkSplitArrow(cellView);
        });
        this.stencil
            .on("add", this.checkIfEmptyVisualizer)
            .on("remove", this.checkIfEmptyVisualizer);

        this.stencil.on("remove", function (cellView) {
            if (cellView.isLink()) {
                var newcell = this.getCell(cellView.attributes.target.id);
                deleteLinkCallback(cellView);
            }
        });

        this.StencilPaper.on("cell:pointerup", function (cellView, e, x, y) {
            var groupElement = document.querySelectorAll(".joint-cell");
            for (var i = 0; i < groupElement.length; i++) {
                groupElement[i].classList.remove("port-enable");
            }
            saveGraph();
        });
        this.StencilPaper.on("cell:pointerdown", function (cellView, e, x, y) {
            var groupElement = document.querySelectorAll(".joint-cell");
            for (var j = 0; j < groupElement.length; j++) {
                groupElement[j].classList.remove("active");
                groupElement[j].classList.add("port-enable");
            }
            if (cellView.model.attributes.type === "devs.MyImageModel") {
                setCellData(cellView.model);
            }
            if (!cellView.sourcePoint) {
                cellView.highlight();
            }
            var selectedElement = document.querySelector(`g[model-id="${cellView.model.id}"]`);
            for (var i = 0; i < groupElement.length; i++) {
                if (selectedElement.id === groupElement[i].id) {
                    groupElement[i].classList.add("active");
                    groupElement[i].classList.remove("port-enable");
                } else {
                    groupElement[i].classList.remove("active");
                    groupElement[i].classList.add("port-enable");
                }
            }
            closeRightPanel();
            splitPanel();
        });
        const { StencilPaper } = this;
        this.StencilPaper.on("blank:pointerdown", function (cellView, e, x, y) {
            var groupElement = document.querySelectorAll(".joint-cell");
            for (var i = 0; i < groupElement.length; i++) {
                groupElement[i].classList.remove("active");
                groupElement[i].classList.remove("port-enable");
            }
            var inputBox = document.getElementById("pageName");
            document.addEventListener("click", function (element) {
                if (!("path" in Event.prototype)) {
                    Object.defineProperty(Event.prototype, "path", {
                        get: function () {
                            var path = [];
                            var currentElem = this.target;
                            while (currentElem) {
                                path.push(currentElem);
                                currentElem = currentElem.parentElement;
                            }
                            if (path.indexOf(window) === -1 && path.indexOf(document) === -1)
                                path.push(document);
                            if (path.indexOf(window) === -1)
                                path.push(window);
                            return path;
                        }
                    });
                }
                if (element.path[0] !== inputBox)
                    inputBox && inputBox.blur();
            });
            closeRightPanel();
        });
        this.StencilPaper.on("element:settings", (cellView, evt) => {
            evt.stopPropagation();
            const page = this.props.funnel.page.find((page) => {
                return page.cellViewId === cellView.model.id;
            });
            const doesPageHasTemplate = get(page, ["pageView", 0, "fTemplateId"]);
            if (doesPageHasTemplate) {
                openRightPanel();
            } else {
                openTemplateSelection();
            }
            getPageType(cellView.model.attributes.elementMetaData.templateTypeId);
            showTemplate(cellView.model);
            setSelectedData(cellView);
            //checkSplitArrow(cellView);
            getSavedfonts(cellView.model);
            getPageData(cellView.model);
        });
        this.StencilPaper.on("element:edit", function (cellView, evt) {
            navigateToBuilder(cellView.model, evt);
        });
        this.StencilPaper.on("element:delete", function (cellView, evt) {
            evt.stopPropagation();
            deletePopup(cellView, addedElement);
        });
        this.StencilPaper.on("textelement:delete", function (cellView, evt) {
            evt.stopPropagation();
            var currentCell = stencil.attributes.cells.models.find((ele) => {
                return ele.id === cellView.model.attributes.attrs[".trash"].linkCellId;
            });
            currentCell.remove();
            cellView.remove();
        });
        this.StencilPaper.on("textelement:copy", function (cellView, evt, x, y) {
            const graphScale = getZoomValue();
            evt.stopPropagation();
            var currentCell = stencil.attributes.cells.models.find((ele) => {
                return ele.id === cellView.model.attributes.attrs[".copy"].linkCellId;
            });
            var textCopy = currentCell.clone();
            textCopy.position((x / graphScale) + 50, (y / graphScale) + 50);
            addCells(textCopy);
        });
        this.StencilPaper.on("element:preview", function (cellView, evt) {
            evt.stopPropagation();
            showPagePreview(cellView.model);
        });
        this.StencilPaper.on("element:copy", function (cellView, evt, x, y) {
            evt.stopPropagation();
            const graphScale = getZoomValue();
            var pageCopy = cellView.model.clone();
            var paper = document.getElementById("page-scroller");
            var paperWidth = (paper.offsetWidth) - 200;
            var paperHeight = (paper.offsetHeight) - 200;
            var isChrome = /Chrome/.test(navigator.userAgent) && /Google Inc/.test(navigator.vendor);
            const zoomX = (!isChrome) ? x / graphScale : x;
            const zoomY = (!isChrome) ? y / graphScale : y;
            if (((zoomX) >= paperWidth) && ((zoomY) >= paperHeight)) {
                pageCopy.position((zoomX) - 250, (zoomY) - 250);
            } else if ((zoomX) >= paperWidth) {
                pageCopy.position((zoomX) - 250, (zoomY) - 20);
            } else if ((zoomY) >= paperHeight) {
                pageCopy.position((zoomX) + 20, (zoomY) - 250);
            } else {
                pageCopy.position((zoomX) + 20, (zoomY) + 20);
            }
            const sourcePageId = cellView.model.id;
            const clonedPageId = pageCopy.id;
            checkSplitCopy({ addedElement, pageCopy, sourcePageId, clonedPageId, cellView });
        });
        this.StencilPaper.on("element:split", function (cellView, evt) {
            evt.stopPropagation();
            alert("Split!!!");
        });
        this.StencilPaper.on("cell:pointermove", function (cellView, evt, x, y) {
            const graphScale = getZoomValue();
            var bbox = cellView.getBBox();
            var constrained = false;
            var paper = document.getElementById("page-scroller");
            if (paper) {
                var width = (paper.offsetWidth - (paper.offsetWidth / constants.outerZoom)) / graphScale;
                var height = (paper.offsetHeight - (paper.offsetHeight / constants.outerZoom) - 20) / graphScale;
                var constrainedX = x;
                if (bbox.x <= 0) {constrainedX = x + gridSize; constrained = true;}
                if (bbox.x + bbox.width >= width) {constrainedX = x - gridSize; constrained = true;}
                var constrainedY = y;
                if (bbox.y <= 0) {constrainedY = y + gridSize; constrained = true;}
                if (bbox.y + bbox.height >= height) {constrainedY = y - gridSize; constrained = true;}
                //if you fire the event all the time you get a stack overflow
                if (constrained) {cellView.pointermove(evt, constrainedX, (constrainedY));}
            }
        });
        const { stencil } = this;
        ToolbarPaper.on("cell:pointerdown", function (cellView, e, x, y) {
            if (cellView.model.attributes.elementMetaData.templateTypeId !== constants.memberFolderTemplateTypeId &&
                !isLoginPageAdded(cellView, constants.loginTemplateTypeId, stencil)) {
                const isIcon = cellView.model.attributes.icon;
                e.stopPropagation();
                closeRightPanel();
                const graphScale = getZoomValue();
                $("body").append("<div id=\"flyPaper\" style=\"position:fixed;z-index:100;opacity:.7;pointer-event:none;\"></div>");
                var flyGraph = new joint.dia.Graph,
                    flyPaper = new joint.dia.Paper({
                        el: $("#flyPaper"),
                        model: flyGraph,
                        interactive: false,
                        width: 120,
                        height: 160
                    }),
                    flyShape = cellView.model.clone(),
                    pos = cellView.model.position(),
                    offset = {
                        x: x - pos.x,
                        y: y - pos.y
                    };

                flyShape.position(0, 0);
                flyShape.size({
                    width: 120,
                    height: 160
                }),
                flyShape.attr({
                    image: {
                        width: isIcon ? 60 : 120,
                        height: isIcon ? 60 : 160,
                        x: isIcon ? 30 : null,
                        y: isIcon ? 30 : null,
                    },
                    ".label": {
                        y: -17.5,
                        x: 0
                    },
                    ".borderBox": {
                        width: 126,
                        height: 166,
                        x: -3,
                        y: -3
                    }
                }),
                flyGraph.addCell(flyShape);
                $("#flyPaper").offset({
                    left: e.pageX - offset.x,
                    top: e.pageY - offset.y
                });
                $("body").on("mousemove.fly", function (e) {
                    $("#flyPaper").offset({
                        left: e.pageX - offset.x,
                        top: e.pageY - offset.y
                    });
                });
                $("body").on("mouseup.fly", function (e) {
                    var x = e.pageX,
                        y = e.pageY,
                        target = StencilPaper.$el.offset();
                    // Dropped over StencilPaper
                    if (x > target.left && x < target.left + StencilPaper.$el.width() && y > target.top && y < target.top + StencilPaper.$el.height()) {
                        var s = flyShape.clone();
                        s.position((x - target.left - offset.x) / graphScale, (y - target.top - offset.y) / graphScale);
                        const draggedElement = s.attributes;
                        const cellViewId = draggedElement.id;
                        const label = draggedElement.pageLabel.label;
                        addedElement.cells.push(draggedElement);
                        addElement(addedElement);
                        addCells(s);
                        saveGraph();
                        createNewPage({ cellViewId, label });
                    } else {
                        // Dropped over toolbar
                    }
                    $("body").off("mousemove.fly").off("mouseup.fly");
                    flyShape.remove();
                    $("#flyPaper").remove();
                });
            } else if (cellView.model.attributes.elementMetaData.templateTypeId === constants.memberFolderTemplateTypeId) {
                memberFolder.get("hidden")
                    ? ToolbarPaper.model.addCell([login, profile, membersArea, cancelMembership, restartMembership, updateCard, changeProduct])
                    : ToolbarPaper.model.removeCells([login, profile, membersArea, cancelMembership, restartMembership, updateCard, changeProduct]);
                memberFolder.set("hidden", !memberFolder.get("hidden"));
            }
        });
        const presell = Elements(presellPage, constants.presellPagePositionX, constants.presellPagePositionY, constants.presellPageLabel, 1, false);
        const lead = Elements(leadPage, constants.leadPagePositionX, constants.leadPagePositionY, constants.leadPageLabel, 2, false);
        const checkout = Elements(checkoutPage, constants.checkoutPagePositionX, constants.checkoutPagePositionY, constants.checkoutPageLabel, 4, false);
        const upsell = Elements(upsellPage, constants.upsellPagePositionX, constants.upsellPagePositionY, constants.upsellPageLabel, 3, false);
        const thankyou = Elements(thankyouPage, constants.thankyouPagePositionX, constants.thankyouPagePositionY, constants.thankyouPageLabel, 5, false);
        const blank = Elements(blankPage, constants.blankPagePositionX, constants.blankPagePositionY, constants.blankPageLabel, null, false);
        const optIn = Elements(optInPage, constants.optInPagePositionX, constants.optInPagePositionY, constants.optInPageLabel, 15, false);
        const download = Elements(downloadPage, constants.downloadPagePositionX, constants.downloadPagePositionY, constants.downloadPageLabel, 16, false);
        const salesVideo = Elements(salesVideoPage, constants.salesVideoPagePositionX, constants.salesVideoPagePositionY, constants.salesVideoPageLabel, 17, false);
        const calendar = Elements(calendarPage, constants.calendarPagePositionX, constants.calendarPagePositionY, constants.calendarPageLabel, 18, false);
        const survey = Elements(surveyPage, constants.surveyPagePositionX, constants.surveyPagePositionY, constants.surveyPageLabel, 19, false);
        const webinar = Elements(webinarPage, constants.webinarPagePositionX, constants.webinarPagePositionY, constants.webinarPageLabel, 20, false);
        const webinarReplay = Elements(webinarReplayPage, constants.webinarReplayPagePositionX, constants.webinarReplayPagePositionY, constants.webinarReplayPageLabel, 21, false);
        const blogPost = Elements(blogPostPage, constants.blogPostPagePositionX, constants.blogPostPagePositionY, constants.blogPostPageLabel, 22, false);
        const membersArea = Elements(membersAreaPage, constants.membersAreaPagePositionX, constants.membersAreaPagePositionY, constants.membersAreaPageLabel, 9, false);
        const popUp = Elements(popUpPage, constants.popUpPagePositionX, constants.popUpPagePositionY, constants.popUpPageLabel, 23, false);
        const email = Elements(emailPage, constants.emailPagePositionX, constants.emailPagePositionY, constants.emailPageLabel, 24, true);
        const text = Elements(textPage, constants.textPagePositionX, constants.textPagePositionY, constants.textPageLabel, 25, true);
        const generic = Elements(genericPage, constants.genericPagePositionX, constants.genericPagePositionY, constants.genericPageLabel, 14, true);
        const textComponent = TextElement(textChange, removeText, saveGraph);
        const profile = Elements(membersAreaPage, constants.profilePagePositionX, constants.profilePagePositionY, constants.profilePageLabel, constants.profileTemplateTypeId, false);
        const cancelMembership = Elements(membersAreaPage, constants.cancelMembershipPagePositionX, constants.cancelMembershipPagePositionY, constants.cancelMembershipPageLabel, constants.cancelMembershipTemplateTypeid, false);
        const restartMembership = Elements(membersAreaPage, constants.restartMembershipPagePositionX, constants.restartMembershipPagePositionY, constants.restartMembershipPageLabel, constants.restartMembershipTemplateTypeid, false);
        const updateCard = Elements(membersAreaPage, constants.updateCardPagePositionX, constants.updateCardPagePositionY, constants.updateCardPageLabel, constants.updateCardTemplateTypeid, false);
        const changeProduct = Elements(membersAreaPage, constants.changeProductPagePositionX, constants.changeProductPagePositionY, constants.changeProductPageLabel, constants.changeProductTemplateTypeid, false);
        const memberFolder = Elements(membershipFolderPage, constants.memberFolderPositionX, constants.memberFolderPositionY, constants.memberFolderLabel, constants.memberFolderTemplateTypeId, false);
        const login = Elements(membersAreaPage, constants.loginPagePositionX, constants.loginPagePositionY, constants.loginPageLabel, constants.loginTemplateTypeId, false);
        memberFolder.set("hidden", true);
        this.Toolbar.addCell([
            presell,
            lead,
            checkout,
            upsell,
            thankyou,
            blank,
            optIn,
            download,
            salesVideo,
            calendar,
            survey,
            webinar,
            webinarReplay,
            blogPost,
            popUp,
            email,
            text,
            generic,
            memberFolder,
        ]);
    }
    getCurrentPage = (cellView) => {
        const { funnel } = this.props;
        const currentPage = funnel.page && funnel.page.find(item => item.cellViewId === cellView.id);
        return currentPage;
    }
    checkSplitCopy = (params) => {
        const { funnel } = this.props;
        const { getCurrentPage, addElement, addCells, saveGraph, clonePage } = this;
        const currentPage = getCurrentPage(params.cellView.model);
        if (currentPage.splitEnabled) {
            this.setState({
                splitCopyAlert: true
            });
        } else {
            params.addedElement.cells.push(params.pageCopy);
            addElement(params.addedElement);
            addCells(params.pageCopy);
            saveGraph();
            const sourcePageId = params.sourcePageId;
            const clonedPageId = params.clonedPageId;
            clonePage({ sourcePageId, clonedPageId });
        }
    }
    navigateToBuilder = (cellView, evt) => {
        const { templateTypeId } = this.state;
        this.props.navigate(cellView, evt, templateTypeId);
    }
    getZoomValue = () => {
        const { graphScale } = this.state;
        return graphScale;
    }
    getPageType = (templateTypeId) => {
        let pageType = "";
        switch (templateTypeId) {
            // Lead page
            case 1:
                pageType = "Presell Page";
                break;
            // Lead page
            case 2:
                pageType = "Lead Page";
                break;
            // Checkout Page
            case 4:
                pageType = "Checkout Page";
                break;
            // Upsell Page
            case 3:
                pageType = "Upsell Page";
                break;
            // Thank You Page
            case 5:
                pageType = "Thank You Page";
                break;
            case 6:
                pageType = "Global";
                break;
            case 7:
                pageType = "Login Page";
                break;
            case 9:
                pageType = "Member Area Page";
                break;
            case 14:
                pageType = "Generic";
                break;
            case 15:
                pageType = "Opt-in";
                break;
            case 16:
                pageType = "Download";
                break;
            case 17:
                pageType = "Sales Video";
                break;
            case 18:
                pageType = "Calendar";
                break;
            case 19:
                pageType = "Survey";
                break;
            case 20:
                pageType = "Webinar";
                break;
            case 21:
                pageType = "Webinar Replay";
                break;
            case 22:
                pageType = "Blog Post";
                break;
            case 23:
                pageType = "Pop-up";
                break;
            default:
                pageType = "Page";
        }
        this.setState({
            pageTypeLabel: pageType
        });
        return pageType;
    }
    textChange = (value) => {
        const { currentCell, tempCell } = this.state;
        currentCell.attr("label/text", value);
        this.setState({
            currentText: value,
        }, () => {
            tempCell.remove();
        });
    }
    changeSource = (cellView) => {
        var newSource = cellView.get("vertices");
        cellView.source({
            x: newSource[0].x,
            y: newSource[0].y
        });
        cellView.target({
            x: newSource[1].x && newSource[1].x,
            y: newSource[1].y && newSource[1].y
        });
    }
    splitPanel = () => {
        this.props.closeTemplatesList();
    }
    blankSave = () => {
        const saved = this.stencil.toJSON();
        if (saved.cells.length !== 0) {
            this.saveGraph();
            this.props.closeTemplatesList();
        }
    }
    startDottedLine = (evt, x, y) => {
        const { isDotted } = this.state;
        if (isDotted) {
            var line = new joint.dia.Link({
                source: { x: x, y: y },
                attrs: {
                    ".marker-arrowhead": {
                        display: "none"
                    },
                },
            });
            evt.data = {
                lineCoords: {
                    x1: x,
                    y1: y
                },
                line: line
            };
        }
    }
    startMove = (evt, x, y) => {
        const { isDotted } = this.state;
        if (isDotted) {
            var lineCoords = evt.data.lineCoords;
            lineCoords.x2 = x;
            lineCoords.y2 = y;
            evt.data.line.target({ x: x, y: y });
            evt.data.line.attr(".connection/stroke-dasharray", "2,5");
            evt.data.line.prop("vertices", [
                {
                    x: lineCoords.x1, y: lineCoords.y1
                }, {
                    x: lineCoords.x2, y: lineCoords.y2
                }]);
            this.addCells(evt.data.line);
        }
    }
    addTextblock = (evt, x, y) => {
        const { isText } = this.state;
        if (isText) {
            this.setState({ isText: false }, () => {
                var textBlock = new joint.shapes.standard.Rectangle({
                });
                var toolBlock = new joint.shapes.standard.Rectangle({
                    markup: "<rect class=\"textelement iconbar\" width=\"0px\" height=\"0px\" /><text class=\"icons trash text-delete\" x=\"0\" y=\"-65\">" + `${constants.trash}` + "<title>Delete</title></text>",
                    attrs: {
                        ".trash": {
                            event: "textelement:delete",
                            linkCellId: textBlock.id
                        },
                        ".copy": {
                            event: "textelement:copy",
                            linkCellId: textBlock.id
                        },
                    },
                });
                var type = "Double Click to Edit";
                textBlock.resize(240, 60);
                textBlock.position(x, y);
                toolBlock.position(x, y + 62);
                textBlock.attr("root/title", "TextBlock");
                textBlock.attr("label/text", type);
                textBlock.attr("label/fill", "#1ab394");
                textBlock.attr("label/fontSize", "18");
                textBlock.attr("label/fontWeight", "700");
                textBlock.attr("body/strokeWidth", "0");
                textBlock.attr("body/fill", "#ffffff00");
                this.stencil.addCells([textBlock, toolBlock]);
                textBlock.embed(toolBlock);
            });
        }
    }
    editableText = (cell, evt, x, y) => {
        const { isText, graphScale } = this.state;
        const currentCell = cell;
        var el1 = new joint.shapes.html.Element({
            position: { x: cell.attributes.position.x * graphScale, y: cell.attributes.position.y * graphScale },
            size: { width: 240, height: 60 },
        });
        this.setState({ currentCell: currentCell, tempCell: el1, isText: false }, () => {
            this.stencil.addCells([el1]);
            var textBox = document.querySelector(".text-view");
            textBox.value = currentCell.attributes.attrs.label.text;
            textBox.focus();
            currentCell.attr("label/fontSize", "0");
        });
    }
    removehtmlElement = () => {
        const { isText, currentText, currentCell } = this.state;
        var text = document.querySelectorAll(".html-element");
        currentCell && currentCell.attr("label/fontSize", "18");
        var emptyBox = document.querySelectorAll(".v-line.v-empty-line");
        for (var i = 0; i < text.length; i++) text[i].remove();
        for (var j = 0; j < emptyBox.length; j++) {
            var elem = emptyBox[j].parentNode.parentNode.classList.contains("joint-port");
            if (!elem) {
                emptyBox[j].parentNode.parentNode.remove();
            }
        }
    }
    saveGraph = () => {
        const { savefunnel } = this.props;
        const saved = this.stencil.toJSON();
        savefunnel(saved);
    }
    clonePage = (cellViewId) => {
        this.props.copyPage(cellViewId);
    }
    getSavedfonts = (cellViewId) => {
        this.props.getSavedfonts(cellViewId);
    }
    getPageData = (cellViewId) => {
        const { pageTypeLabel } = this.state;
        const currentPage = this.getCurrentPage(cellViewId);
        if (pageTypeLabel === "Member Area Page") {
            this.props.loadButtons({ metaJsonUrl: currentPage.pageView[0].metaJson });
        }
    }
    zoomIn = () => {
        var scrollTop = document.getElementById("visualizer").scrollTop;
        var scrollLeft = document.getElementById("visualizer").scrollLeft;
        var outerLeft = document.getElementById("visualizer").offsetWidth;
        var innerLeft = document.getElementById("page-scroller").offsetWidth;
        var outerHeight = document.getElementById("visualizer").offsetHeight;
        var innerHeight = document.getElementById("page-scroller").offsetHeight;
        var graphHeight = document.querySelector(".visualizer-graph").offsetHeight;
        var graphWidth = document.querySelector(".visualizer-graph").offsetWidth;
        var viewport = document.getElementsByClassName("joint-viewport")[1] && document.getElementsByClassName("joint-viewport")[1].getBBox();
        const scrollLeftValue = (this.state.innerLeft - outerLeft) / 2;
        const scrollHeightValue = (this.state.innerHeight - outerHeight) / 2;
        const matrixValue = (this.state.graphScale, 0, 0, this.state.graphScale, graphWidth / 2, graphHeight / 2);
        this.setState({
            graphScale: this.state.graphScale < 1.5 ? this.state.graphScale + 0.1 : this.state.graphScale,
            scrollerWidth: this.state.graphScale < 1.5 ? this.state.scrollerWidth : this.state.scrollerWidth,
            scrollerHeight: this.state.graphScale < 1.5 ? this.state.scrollerHeight : this.state.scrollerHeight,
            visualizerHeight: this.state.graphScale < 1.5 ? this.state.visualizerHeight : this.state.visualizerHeight,
            visualizerWidth: this.state.graphScale < 1.5 ? this.state.visualizerWidth : this.state.visualizerWidth,
            innerZoom: this.state.graphScale < 1.5 ? this.state.innerZoom + 2.7 : this.state.innerZoom,
            outerZoom: this.state.graphScale < 1.5 ? this.state.outerZoom + 2.7 : this.state.outerZoom,
            scrollLeftValue: scrollLeftValue,
            viewportHeight: viewport.height,
            viewportWidth: viewport.width,
            scrollHeightValue: scrollHeightValue,
            innerHeight: innerHeight,
            innerLeft: innerLeft,
            isZoom: true
        }, () => {
            document.getElementsByClassName("joint-viewport")[1].style.transform = `matrix(${this.state.graphScale},0,0,${this.state.graphScale},0,1)`;
            this.hideZoomValue();
            this.props.setZoomValue(this.state.graphScale, this.state.outerZoom, this.state.innerZoom);
        });
    };
    zoomOut = () => {
        var scrollTop = document.getElementById("visualizer").scrollTop;
        var scrollLeft = document.getElementById("visualizer").scrollLeft;
        var outerLeft = document.getElementById("visualizer").offsetWidth;
        var innerLeft = document.getElementById("page-scroller").offsetWidth;
        var outerHeight = document.getElementById("visualizer").offsetHeight;
        var innerHeight = document.getElementById("page-scroller").offsetHeight;
        var graphHeight = document.querySelector(".visualizer-graph").offsetHeight;
        var graphWidth = document.querySelector(".visualizer-graph").offsetWidth;
        var viewport = document.getElementsByClassName("joint-viewport")[1] && document.getElementsByClassName("joint-viewport")[1].getBBox();
        const scrollLeftValue = (this.state.innerLeft - outerLeft) / 2;
        const scrollHeightValue = (this.state.innerHeight - outerHeight) / 2;
        this.setState({
            graphScale: this.state.graphScale <= 0.30 ? this.state.graphScale : this.state.graphScale - 0.1,
            scrollerWidth: this.state.graphScale <= 0.30 ? this.state.scrollerWidth : this.state.scrollerWidth,
            scrollerHeight: this.state.graphScale <= 0.30 ? this.state.scrollerHeight : this.state.scrollerHeight,
            visualizerWidth: this.state.graphScale <= 0.30 ? this.state.visualizerWidth : this.state.visualizerWidth,
            visualizerHeight: this.state.graphScale <= 0.30 ? this.state.visualizerHeight : this.state.visualizerHeight,
            innerZoom: this.state.graphScale <= 0.30 ? this.state.innerZoom : this.state.innerZoom - 2.7,
            outerZoom: this.state.graphScale <= 0.30 ? this.state.outerZoom : this.state.outerZoom - 2.7,
            scrollLeftValue: scrollLeftValue,
            scrollHeightValue: scrollHeightValue,
            viewportHeight: viewport.height,
            viewportWidth: viewport.width,
            innerHeight: innerHeight,
            innerLeft: innerLeft,
            isZoom: true
        }, () => {
            document.getElementsByClassName("joint-viewport")[1].style.transform = `matrix(${this.state.graphScale},0,0,${this.state.graphScale},0,1)`;
            this.hideZoomValue();
            this.props.setZoomValue(this.state.graphScale, this.state.outerZoom, this.state.innerZoom);
        });
    };
    hideZoomValue = () => {
        setTimeout(() => {
            this.setState({
                isZoom: false
            });
        }, 1200);
    }
    resetZoom = () => {
        this.setState({
            graphScale: 1
        }, () => this.paperScale(this.state.graphScale, this.state.graphScale));
    };
    createNewPage = (cellViewDetail) => {
        const saved = this.stencil.toJSON();
        this.props.createNewPage({ saved, cellViewDetail });
    }
    getGraph = (metaJsonVisualizer) => {
        const { loadElement } = this.state;
        const finalData = metaJsonVisualizer;
        // const picked = finalData.cells.map(item => {
        //     if (item.type === "link") {
        //         return ({
        //             attrs: item.attrs,
        //             id: item.id,
        //             source: item.source,
        //             target: item.target,
        //             type: item.type,
        //             z: item.z,
        //             vertices: item.vertices
        //         });
        //     } else {
        //         return ({
        //             attrs: { image: item.attrs.image, ".label": { text: item.pageLabel.label, y: -17.5, x: 0 } },
        //             elementMetaData: item.elementMetaData,
        //             id: item.id,
        //             pageLabel: item.pageLabel,
        //             position: item.position,
        //             type: item.type,
        //             ports: {
        //                 groups: {
        //                     "out": {
        //                         attrs: {
        //                             ".port-body": { fill: "#ffffff", width: 120, height: 160, stroke: "#ffffff00", "stroke-width": 1, x: -120, y: -80, magnet: true },
        //                             ".linkicon": { x: -10, y: -100 },
        //                         }
        //                     },
        //                     ".icons": {
        //                         fill: "#1ab394",
        //                         magnet: true
        //                     }
        //                 }
        //             },
        //             size: { width: 120, height: 160 },
        //         });
        //     }
        // });
        // const finalResult = { cells: picked };
        this.stencil.fromJSON(metaJsonVisualizer);
        var viewport = document.getElementsByClassName("joint-viewport")[1] && document.getElementsByClassName("joint-viewport")[1].getBBox();
        this.setState({
            viewportHeight: viewport.height,
            viewportWidth: viewport.width
        });
    }
    addCells = (cell) => {
        this.stencil.addCell(cell);
    }
    addElement = (addedElement) => {
        this.setState({ addedElementArray: addedElement });
    }
    isLoginPageAdded = (cellView, loginTemplateTypeId, stencil) => {
        if (cellView.model.attributes.elementMetaData.templateTypeId !== loginTemplateTypeId)
            return false;
        else {
            return !!stencil.attributes.cells.models.find((cell) =>
                cell.attributes.elementMetaData && cell.attributes.elementMetaData.templateTypeId === loginTemplateTypeId
            );
        }
    }
    deletePopup = (cellView, addedElement) => {
        this.setState({
            deletePopup: true,
            deleteItem: cellView,
            addedElementList: addedElement
        });
    }
    removeUnwantedCell = () => {
        const { addedElementArray } = this.state;
        const cellView = addedElementArray.cells[0];
        const current = this.StencilPaper.model.attributes.cells.models.find((element) => {
            return element.id === cellView.id;
        });
        current.remove();
        this.setState({ addedElementArray: addedElementArray.cells.pop() });
    }
    handleDelete = () => {
        const { deleteItem, addedElementList } = this.state;
        const { deletePage } = this.props;
        const deleteView = deleteItem.model;
        this.setState({
            deletePopup: false
        }, () => {
            this.removeCell(deleteView, addedElementList);
            this.closeRightPanel();
        });
    }
    removeCell = (cellView, addedElement) => {
        const { deleteItem, addedElementList } = this.state;
        const { deletePage } = this.props;
        const deleteView = cellView;
        var links = this.stencil.getConnectedLinks(cellView, { inbound: true });
        // Get only the links which are assigned to a button
        this.deletedPageLinks = links.filter((l) => l.attributes.isButtonAssigned).map((l) => l.id);
        const isPageDeleted = (this.deletedPageLinks.length > 0) ? true : false;
        this.setState({ addedElementArray: addedElement, isPageDeleted }, () => {
            cellView.remove();
            addedElement.cells.pop(cellView);
            const saved = this.stencil.toJSON();
            deletePage({ saved, deleteView, isPageDeleted });
        });
    }
    closeRightPanel = () => {
        this.setState({
            isRightPanelActive: false,
            isArrowSelected: false,
            targetViewId: null,
            sourceViewId: null,
            linkViewId: null,
            sourcePageLabel: null,
            linkButtonData: null,
            linkData: null,
            linkOfferData: null,
            linkUpsellData: null,
            linkCurrentPage: null,
            importShareId: null,
            isShareOpen: false,
            splitOtionsPopup: false,
            showTemplates: false,
            selectedFonts: [],
            selectedSplitSettingDetails: {},
            showSplitSetting: false
        }, () => {
            this.props.clearPageData();
        });
        this.setState(() => this.props.updateShowSplitSetting(false));
    }
    openRightPanel = () => {
        this.setState({ isRightPanelActive: true, importShareId: null, showTemplates: this.props.showTemplates, splitPageName: this.props.splitPageName });
    }
    openTemplateSelection = () => {
        this.setState({
            ModalComponent: ChooseTemplates,
        });
    }
    hideModalComponent = () => this.setState({ ModalComponent: null });
    /******************** Split Test Functions ***********************************/
    openSplitOptions = () => {
        this.setState({
            splitOtionsPopup: true
        });
    }
    closeSplitOption = () => {
        this.setState({
            splitOtionsPopup: false,
            showTemplates: false,
        });
    }
    duplicatePage = () => {
        const { pageName, selectedData, cellData } = this.state;
        const { splitDuplicatePage } = this.props;
        const { updateTemplateThumbnail } = this;
        const splitImge = "data:image/svg+xml;utf8," + encodeURIComponent(constants.splitPageSvg);
        this.setState({
            isRightPanelActive: false, isSplitThumbnail: true, splitOtionsPopup: false
        }, () => {splitDuplicatePage({ pageName, selectedData }); updateTemplateThumbnail(cellData.id, splitImge);});
    }
    /********************** Split Test Functions End *****************************/
    showPagePreview = (cellView) => {
        const { funnel } = this.props;
        const currentPage = funnel.page.find((element) => {
            return element.cellViewId === cellView.id;
        });
        const templateId = currentPage.pageView[0].fTemplateId;
        const previewUrl = currentPage.pageView[0].url;
        if (currentPage.splitEnabled) {
            this.props.showSplitPreviewPopup(cellView);
        } else {
            if (templateId) {
                window.open(previewUrl, "_blank");
            } else {
                // alert("Please select the template before you preview page");
                this.setState({ previewAlert: true });
            }
        }
    }
    showTemplate = (cellView) => {
        const { funnel } = this.props;
        const currentPage = funnel.page.find((element) => {
            return element.cellViewId === cellView.id;
        });
        const rightPanelScroll = document.querySelector(".rightPanel");
        rightPanelScroll.scrollTop = 0;
        const selectedTempId = currentPage ? currentPage.pageView[0].fTemplateId : null;
        this.setState({ isConfigLink: false, isLoadTemplates: selectedTempId !== null ? false : true });
    }
    setCellData = (cellData) => {
        this.setState({ cellData: cellData, templateTypeId: cellData.attributes.elementMetaData.templateTypeId });
    }
    setSelectedData = (SelectedData) => {
        const { selectedData, showSplitSetting, selectedSplitSettingDetails, isSplitEnabled } = this.state;
        this.props.setCellView(SelectedData.model);
        // this.props.showTemplates();
        this.setState({ selectedData: SelectedData, pageName: SelectedData.model.attributes.attrs[".label"].text });
    }
    modifyCell = (selectedElement, modifyElement, selectedTemplateThumbnailUrl) => {
        const currentPageId = modifyElement.cells.find((element) => {
            return element.id === selectedElement.model.id;
        });
        this.setState({ currentPageId: currentPageId, selectedTemplate: null }, () => {
            var newcell = this.stencil.getCell(currentPageId.id);
            if (newcell.isElement()) {
                newcell.attr("image/xlink:href", selectedTemplateThumbnailUrl);
                newcell.attr("templateSelcted", true);
            }
            this.closeRightPanel();
            this.saveGraph();
        });
    };
    checkSplitArrow = (cellView) => {
        this.closeRightPanel();
        const { funnel, showSplitArrowPopup } = this.props;
        let linkCurrentPage = funnel && funnel.page.find((p) => {
            return p.cellViewId === cellView.sourceView.model.id;
        });
        if (linkCurrentPage.splitEnabled) {
            showSplitArrowPopup(cellView);
            this.closeRightPanel();
        } else {
            this.linkSettings(linkCurrentPage, cellView);
        }
    }
    linkSettings = (linkCurrentPage, cellView) => {
        if (cellView.model.attributes.type === constants.cellTypeLink) {
            // Find the selected page using funnel and grab the metaJson
            // let linkCurrentPage = this.props.funnel.page.find((p) => {
            //     return p.cellViewId === cellView.sourceView.model.id;
            // });
            // TODO: Move to single setState
            this.setState({
                isArrowSelected: true,
                targetViewId: cellView.targetView.model.id,
                sourceViewId: cellView.sourceView.model.id,
                sourceTemplateTypeId: cellView.sourceView.model.attributes.elementMetaData.templateTypeId,
                linkViewId: cellView.model.id,
                sourcePageLabel: cellView.sourceView.model.attributes.pageLabel.label,
                linkCurrentPage
            }, () => {
                if (linkCurrentPage) {
                    this.props.loadButtons({ metaJsonUrl: linkCurrentPage.pageView[0].metaJson });
                }
                this.setState({ isConfigLink: true, isLoadTemplates: false }, () => {
                    this.openRightPanel();
                });
            });
        } else {
            const page = this.props.funnel.page.find((page) => {
                return page.cellViewId === cellView.model.id;
            });
            const isLoadTemplates = page ? (page.pageView[0].fTemplateId ? false : true) : true;
            if (page) {
                this.setState({ isConfigLink: false, isLoadTemplates: page.pageView[0].fTemplateId ? false : true }, () => {
                    this.openRightPanel();
                });
            }
        }
    }
    handleClose = () => {
        this.setState({ previewAlert: false, deletePopup: false, linkAlert: false, sharePopup: false, fontAlert: false, showImportFunnelModal: false, splitCopyAlert: false, openFunnelTemplatesOnLoad: false });
    }
    onUpsellChange = (evt, key) => {
        this.setState({
            [key]: evt.target.value
        });
    }
    onButtonChange = (evt) => {
        this.setState({
            linkButtonData: JSON.parse(evt.target.value)
        });
    }
    deleteLinkCallback = (cellView) => {
        const { funnel } = this.props;
        const { isPageDeleted } = this.state;
        const jsonFile = this.stencil.toJSON();
        const page = funnel.page.find((p) => {
            return p.cellViewId === cellView.attributes.source.id;
        });
        const sourceTemplateTypeId = (jsonFile.cells.find(element => element.id === page.cellViewId)).elementMetaData.templateTypeId;
        const isMemberShipPage = (sourceTemplateTypeId === constants.loginTemplateTypeId) || (sourceTemplateTypeId === constants.membersAreaTemplateTypeId);
        let deleteElementArray, buttonValue;
        this.saveGraph();
        if (page) {
            const sourceViewId = cellView.attributes.source.id;
            this.props.loadButtons({ metaJsonUrl: page.pageView[0].metaJson, isPageDeleted }, (pageData) => {
                if (pageData.buttons) {
                    let buttons = [...pageData.buttons];
                    let links = [...pageData.links];
                    const newJsPageData = { ...pageData.jsPageData };
                    const componentArray = [...buttons, ...links];
                    if (isMemberShipPage) {
                        deleteElementArray = componentArray.filter((element) => {
                            let buttonId = null;
                            buttonValue = newJsPageData[element["data-id"]] && newJsPageData[element["data-id"]].NEXT_PAGE.productTargetDetails.find(products => products.find(product => (product.linkViewId === cellView.id) ? buttonId = element["data-id"] : null));
                            const data = element["data-id"] === buttonId;
                            return data;
                        });
                    } else {
                        deleteElementArray = componentArray.filter((element) => {
                            return ((element.target !== null) && (element.linkViewId === cellView.id));
                        });
                    }
                    let idx, updatedArray;
                    deleteElementArray.map((ele, index) => {
                        idx = componentArray.findIndex((item) => {
                            return item["data-id"] === ele["data-id"];
                        });
                        if (!isMemberShipPage) {
                            componentArray[idx] = {
                                ...componentArray[idx],
                                target: null,
                                linkViewId: null
                            };
                        }
                        // componentArray[idx].linkViewId = null;
                        updatedArray = componentArray.map((ele, index) => {
                            if (index === idx) {
                                if (ele["data-id"] in newJsPageData) {
                                    if (isMemberShipPage) {
                                        if (ele.type === "link") {
                                            delete newJsPageData[ele["data-id"]];
                                        } else {
                                            const jsFilteredData = newJsPageData[ele["data-id"]].NEXT_PAGE.productTargetDetails && newJsPageData[ele["data-id"]].NEXT_PAGE.productTargetDetails.filter((link) => Array.isArray(link) ? link.find((item) => item.linkViewId !== cellView.id) : (link.linkViewId !== cellView.id));
                                            newJsPageData[ele["data-id"]] = {
                                                ...newJsPageData[ele["data-id"]],
                                                NEXT_PAGE: {
                                                    productTargetDetails: jsFilteredData
                                                }
                                            };
                                        }
                                    } else {
                                        delete newJsPageData[ele["data-id"]];
                                    }
                                }
                                if (isMemberShipPage) {
                                    return { ...ele };
                                } else {
                                    return {
                                        ...ele,
                                        linkViewId: null,
                                        target: null
                                    };
                                }
                            }
                            return ele;
                        });
                    });
                    if (updatedArray) {
                        buttons = updatedArray && updatedArray.filter((btn) => {
                            return btn.type !== "link";
                        });
                        links = updatedArray.filter((link) => {
                            return link.type === "link";
                        });
                    }
                    const jsPageData = { ...newJsPageData };
                    const jsonFile = this.stencil.toJSON();
                    // Get the target page reference id here and add it to the pageData in the state
                    const sourcePage = funnel.page.find((p) => p.cellViewId === sourceViewId);
                    this.setState({ isDeleteArrow: true }, () => {
                        this.props.saveButtonData({
                            jsonFile,
                            jsPageData,
                            newPageData: buttons,
                            newPageLinkData: links,
                            sourcePage,
                            funnel,
                            funnelReferenceId: funnel.referenceId,
                            isDeleteArrow: true
                        });
                    });
                }
            });
        }
    }
    getSplitUrl = (funnel = null) => {
        if (funnel && funnel.status && funnel.page && funnel.page.length > 0) {
            const fFunnel = funnel.page.filter(fp => fp.active);
            return fFunnel.length > 0 ? fFunnel[0].pageView[0].publicUrl : "";
        }
        return "";
    }
    onSelectButton = () => {
        const { targetViewId,
            sourceViewId,
            linkViewId,
            linkOfferData,
            linkUpsellData,
            sourceTemplateTypeId, muliSelectValue, linkCurrentPage, productValue, muliSelectProductValue, originalButtonLinkValues } = this.state;
        const { funnel, pageData } = this.props;
        const isMemberShipPage = (sourceTemplateTypeId === constants.loginTemplateTypeId) || (sourceTemplateTypeId === constants.membersAreaTemplateTypeId);
        let newStencilJson = { cells: [] }, jsPageData = {}, newPageData = {}, newPageLinkData = {}, newLinkData = {};
        // Get the target page reference id here and add it to the pageData in the state
        const targetPage = funnel.page.find((page) => page.cellViewId === targetViewId);
        const sourcePage = funnel.page.find((page) => (page.cellViewId === sourceViewId) && (page.referenceId === linkCurrentPage.referenceId));
        let buttonStatus;
        const linkButtonData = muliSelectValue.map((item) => {
            buttonStatus = item.Status && item.Status;
            return JSON.parse(item.value);
        });
        const productData = muliSelectProductValue && muliSelectProductValue.map((item) => {
            return JSON.parse(item.value);
        });
        let componentData;
        let linkData;
        let buttonData;
        const tempStencil = this.stencil.toJSON();
        const newCells = tempStencil.cells.map((cell) => {
            if ((cell.type === constants.cellTypeLink) && (cell.id === linkViewId)) {
                const newCell = this.stencil.getCell(cell.id);
                newCell && newCell.attr(".marker-target", {
                    ...newCell.attributes.attrs[".marker-target"],
                    stroke: "#31d0c6",
                    fill: "#31d0c6"
                });
                cell = newCell && newCell.attributes;
                this.saveGraph();
                return {
                    ...cell,
                    isButtonAssigned: true,
                    isLinkAssigned: true
                };
            }
            return cell;
        });
        newStencilJson = {
            cells: newCells
        };
        if (originalButtonLinkValues && muliSelectValue && originalButtonLinkValues.length > muliSelectValue.length) {
            const removedItems = originalButtonLinkValues.filter(item1 => {
                return !muliSelectValue.some(item2 => (item1.Status && item2.Status ? item2.Status === item1.Status : item2.value === item1.value));
            });
            removedItems.map((item) => {
                const removedItem = JSON.parse(item["value"]);
                if ((removedItem["data-id"] in pageData.jsPageData)) {
                    if (removedItem && removedItem.type === "link") {
                        jsPageData[removedItem["data-id"]] = {
                            NEXT_PAGE: {
                                referenceId: null,
                                targetPage: null,
                            },
                            href: removedItem.href ? removedItem.href : "",
                            OFFER: linkOfferData,
                            UPSELL: linkUpsellData,
                            linkViewId: null
                        };
                    } else {
                        jsPageData[removedItem["data-id"]] = {
                            NEXT_PAGE: {
                                referenceId: null,
                                targetPage: null,
                            },
                            OFFER: linkOfferData,
                            UPSELL: linkUpsellData,
                            linkViewId: null
                        };
                    }
                    jsPageData = {
                        ...jsPageData,
                        currentPage: sourcePage,
                        OFFER: linkOfferData,
                        funnelData: funnel,
                        templateTypeId: sourceTemplateTypeId,
                        salt: pageData.jsPageData.salt,
                        selectedCampaign: pageData.jsPageData.selectedCampaign
                    };
                }
            });
        }
        linkButtonData.map((item) => {
            componentData = item;
            if ((componentData["data-id"] in pageData.jsPageData)) {
                if (componentData && componentData.type === "link") {
                    if (!componentData) {
                        const bdIdx = pageData.linkData.findIndex((bd) => {
                            return bd.linkViewId === linkViewId;
                        });
                        componentData = pageData.linkData[bdIdx];
                    }
                    jsPageData[componentData["data-id"]] = {
                        NEXT_PAGE: {
                            referenceId: targetPage.referenceId,
                            targetPage: targetPage.pageView[0].referenceId,
                        },
                        href: componentData.href ? componentData.href : "",
                        OFFER: linkOfferData,
                        UPSELL: linkUpsellData,
                        linkViewId
                    };
                    jsPageData = {
                        ...pageData.jsPageData,
                        ...jsPageData,
                        currentPage: sourcePage,
                        OFFER: linkOfferData,
                        funnelData: funnel,
                        templateTypeId: sourceTemplateTypeId,
                        salt: pageData.jsPageData.salt,
                        selectedCampaign: pageData.jsPageData.selectedCampaign
                    };
                } else {
                    const prevProductTargetDetails = pageData.jsPageData[componentData["data-id"]] && pageData.jsPageData[componentData["data-id"]].NEXT_PAGE && pageData.jsPageData[componentData["data-id"]].NEXT_PAGE.productTargetDetails;
                    //TO-DO Convert productdetails into objects and handle duplication in case we are editing the object with same product id and target page
                    let productDetails;
                    if (productData && productData.length) {
                        productDetails = productData && productData.map((product, index) => {
                            return {
                                productId: productData ? productData[index].value : "",
                                status: buttonStatus,
                                targetPage: targetPage && targetPage.pageView[0].referenceId,
                                linkViewId
                            };
                        });
                    } else {
                        productDetails = [{ productId: "", status: buttonStatus, targetPage: targetPage && targetPage.pageView[0].referenceId, linkViewId }];
                    }
                    const finalized = prevProductTargetDetails && prevProductTargetDetails.indexOf(productDetails && productDetails) > -1;
                    if (isMemberShipPage) {
                        jsPageData[componentData["data-id"]] = {
                            NEXT_PAGE: {
                                referenceId: targetPage.referenceId,
                                productTargetDetails: (!finalized) ? [
                                    ...prevProductTargetDetails || {},
                                    productDetails
                                ] : [...prevProductTargetDetails || {}]
                            },
                            OFFER: linkOfferData,
                            UPSELL: linkUpsellData,
                        };
                    } else {
                        jsPageData[componentData["data-id"]] = {
                            NEXT_PAGE: {
                                referenceId: targetPage.referenceId,
                                targetPage: targetPage.pageView[0].referenceId,
                            },
                            OFFER: linkOfferData,
                            UPSELL: linkUpsellData,
                            linkViewId,
                        };
                    }
                    jsPageData = {
                        ...pageData.jsPageData,
                        ...jsPageData,
                        currentPage: sourcePage,
                        OFFER: linkOfferData,
                        funnelData: funnel,
                        templateTypeId: sourceTemplateTypeId,
                        salt: pageData.jsPageData.salt,
                        selectedCampaign: pageData.jsPageData.selectedCampaign
                    };
                }
            } else {
                if (componentData && componentData.type === "link") {
                    if (!componentData) {
                        const bdIdx = pageData.linkData.findIndex((bd) => {
                            return bd.linkViewId === linkViewId;
                        });
                        componentData = pageData.linkData[bdIdx];
                    }
                    jsPageData[componentData["data-id"]] = {
                        NEXT_PAGE: {
                            referenceId: targetPage.referenceId,
                            targetPage: targetPage.pageView[0].referenceId,
                        },
                        href: componentData.href ? componentData.href : "",
                        OFFER: linkOfferData,
                        UPSELL: linkUpsellData,
                        linkViewId
                    };
                    jsPageData = {
                        ...jsPageData,
                        ...pageData.jsPageData,
                        currentPage: sourcePage,
                        OFFER: linkOfferData,
                        funnelData: funnel,
                        templateTypeId: sourceTemplateTypeId,
                        salt: pageData.jsPageData.salt,
                        selectedCampaign: pageData.jsPageData.selectedCampaign
                    };
                } else {
                    const prevProductTargetDetails = pageData.jsPageData[componentData["data-id"]] && pageData.jsPageData[componentData["data-id"]].NEXT_PAGE && pageData.jsPageData[componentData["data-id"]].NEXT_PAGE.productTargetDetails;
                    //TO-DO Convert productdetails into objects and handle duplication in case we are editing the object with same product id and target page
                    let productDetails;
                    if (productData && productData.length) {
                        productDetails = productData && productData.map((product, index) => {
                            return {
                                productId: productData ? productData[index].value : "",
                                status: buttonStatus,
                                targetPage: targetPage && targetPage.pageView[0].referenceId,
                                linkViewId
                            };
                        });
                    } else {
                        productDetails = [{ productId: "", status: buttonStatus, targetPage: targetPage && targetPage.pageView[0].referenceId, linkViewId }];
                    }
                    const finalized = prevProductTargetDetails && prevProductTargetDetails.indexOf(productDetails && productDetails) > -1;
                    if (!componentData) {
                        const bdIdx = pageData.buttonData.findIndex((bd) => {
                            return bd.linkViewId === linkViewId;
                        });
                        componentData = pageData.buttonData[bdIdx];
                    }
                    if (isMemberShipPage) {
                        jsPageData[componentData["data-id"]] = {
                            NEXT_PAGE: {
                                referenceId: targetPage.referenceId,
                                productTargetDetails: (!finalized) ? [
                                    ...prevProductTargetDetails || {},
                                    productDetails
                                ] : [...prevProductTargetDetails || {}]
                            },
                            OFFER: linkOfferData,
                            UPSELL: linkUpsellData,
                        };
                    } else {
                        jsPageData[componentData["data-id"]] = {
                            NEXT_PAGE: {
                                referenceId: targetPage.referenceId,
                                targetPage: targetPage.pageView[0].referenceId,
                            },
                            OFFER: linkOfferData,
                            UPSELL: linkUpsellData,
                            linkViewId,
                        };
                    }
                    jsPageData = {
                        ...pageData.jsPageData,
                        ...jsPageData,
                        currentPage: sourcePage,
                        OFFER: linkOfferData,
                        funnelData: funnel,
                        templateTypeId: sourceTemplateTypeId,
                        salt: pageData.jsPageData.salt,
                        selectedCampaign: pageData.jsPageData.selectedCampaign
                    };
                }
            }
            newPageLinkData = pageData.linkData.map((pg) => {
                if ((pg["data-id"] in jsPageData)) {
                    return {
                        ...pg,
                        target: jsPageData[pg["data-id"]].linkViewId === null ? null : targetPage.referenceId,
                        linkViewId: jsPageData[pg["data-id"]].linkViewId === null ? null : pg["linkViewId"] ? pg["linkViewId"] : linkViewId
                    };
                } else if (!(pg["data-id"] in jsPageData)) {
                    return {
                        ...pg,
                        target: null,
                        linkViewId: null
                    };
                }
                return pg;
            });
            newPageData = pageData.buttonData.map((pg) => {
                if ((pg["data-id"] in jsPageData)) {
                    return {
                        ...pg,
                        target: jsPageData[pg["data-id"]].linkViewId === null ? null : targetPage.referenceId,
                        linkViewId: jsPageData[pg["data-id"]].linkViewId === null ? null : pg["linkViewId"] ? pg["linkViewId"] : linkViewId,
                    };
                } else if (!(pg["data-id"] in jsPageData)) {
                    return {
                        ...pg,
                        target: null,
                        linkViewId: null,
                    };
                }
                return pg;
            });
        });
        this.setState({
            linkOfferData: null,
            linkUpsellData: null,
            linkCurrentPage: null,
            isArrowChange: true
        }, () => {
            this.closeRightPanel();
            this.props.saveButtonData({
                jsonFile: newStencilJson,
                jsPageData,
                newPageData,
                newPageLinkData,
                sourcePage,
                funnelReferenceId: funnel.referenceId,
                funnel,
                isLink: false,
                isArrowChange: true
            });
        });
    }
    onButtonClear = (evt, key) => {
        this.setState({
            [key]: "DELETED"
        });
    }
    pageNameChange = (e) => {
        const { selectedData, addedElementArray, pageName, showSplitSetting } = this.state;
        showSplitSetting ? this.setState({ splitPageName: e.target.value }) :
            this.setState({ pageName: e.target.value }, () => {
                var newcell = this.stencil.getCell(selectedData.model.id);
                newcell.prop("attrs/.label/text", this.state.pageName);
            });
    }
    modifylabel = (event) => {
        const { pageName, selectedData, showSplitSetting, selectedSplitSettingDetails, splitPageName } = this.state;
        if (showSplitSetting) {
            this.props.titleChange(splitPageName, selectedSplitSettingDetails);
        } else {
            this.saveGraph();
            const tempStencil = this.stencil.toJSON();
            this.props.titleChange(this.state.pageName, selectedData);
        }
    }
    renderButtonInput = () => {
        let options = [];
        const { linkViewId, sourcePageLabel, sourceTemplateTypeId, linkCurrentPage, isLink, targetViewId, isButtonSelected, selectedData } = this.state;
        const { pageData, funnel } = this.props;
        const targetPage = funnel.page.find((page) => page.cellViewId === targetViewId);
        const offers = funnel.offer ? JSON.parse(funnel.offer) : "";
        const upsells = funnel.upsale ? JSON.parse(funnel.upsale) : "";
        const pagelabel = "Buttons / Links  of " + sourcePageLabel;
        const productLabel = "Products";
        const selectLabel = "Choose " + sourcePageLabel + " Page Buttons / Links";
        const isCheckoutPage = sourceTemplateTypeId === 4;
        const isUpsellPage = sourceTemplateTypeId === 3;
        const isLoginPage = sourceTemplateTypeId === 7;
        const isMemberAreaPage = sourceTemplateTypeId === 9;
        const isMemberShipPage = (sourceTemplateTypeId === 7) || (sourceTemplateTypeId === 9);
        let allButtons = [], allLinks = [], currentButtonValue = "", currentLinkValue = "", currentUpsellValue = "", currentOfferValue = "";
        if ((pageData.buttonData && pageData.buttonData.length)) {
            currentButtonValue = pageData.buttonData.find((bd) => {
                return bd.linkViewId === linkViewId;
            });
            let isLogin = false;
            let loginBtn;
            allButtons = pageData.buttonData.filter((ab) => {
                if (ab.linkViewId && (ab.linkViewId !== linkViewId)) {
                    return true;
                }
                return true;
            }).map((buttonData) => {
                let buttons;
                if (isLoginPage) {
                    isLogin = true;
                    loginBtn = buttonData;
                    return;
                } else {
                    buttons = { value: JSON.stringify(buttonData), text: buttonData.title ? buttonData.title : buttonData.name, groupLabel: "Buttons" };
                    return buttons;
                }
            });
            if (isLogin) {
                allButtons = [];
                allButtons.push({ value: JSON.stringify(loginBtn), text: loginBtn.title ? loginBtn.title + "-Active" : loginBtn.name + "-Active", groupLabel: "Buttons", Status: "ACTIVE" });
                allButtons.push({ value: JSON.stringify(loginBtn), text: loginBtn.title ? loginBtn.title + "-Inactive" : loginBtn.name + "-Inactive", groupLabel: "Buttons", Status: "INACTIVE" });
                isLogin = false;
                loginBtn = null;
            }
            if (currentButtonValue) {
                currentUpsellValue = currentButtonValue.UPSELL ? parseInt(currentButtonValue.UPSELL) : "";
                currentOfferValue = currentButtonValue.OFFER ? parseInt(currentButtonValue.OFFER) : "";
            }
        }
        if ((pageData.linkData && pageData.linkData.length)) {
            currentLinkValue = pageData.linkData.find((bd) => {
                return bd.linkViewId === linkViewId;
            });
            allLinks = pageData.linkData.filter((ab) => {
                if (ab.linkViewId && (ab.linkViewId !== linkViewId)) {
                    return false;
                }
                return true;
            }).map((bd) => {
                return { value: JSON.stringify(bd), text: bd.title ? bd.title : "Link", groupLabel: "Links" };
            });
        }

        if (!allButtons.length && !allLinks.length) {
            if (linkCurrentPage && linkCurrentPage.isEdited) {
                return (
                    <div className="no-template">
                        <h3>All buttons and Links are linked. Please add a button / Link and try again. </h3>
                    </div>
                );
            }

            return (
                <div className="no-template">
                    <h3>Please edit the page and try again </h3>
                </div>
            );
        }
        const isButtonEnabled = (this.state.muliSelectValue && this.state.muliSelectValue.length !== 0 || this.state.muliSelectProductValue && this.state.muliSelectProductValue.length !== 0 || this.state.linkButtonData);

        const buttonValue = this.state.linkButtonData ? JSON.stringify(this.state.linkButtonData) : currentButtonValue ? JSON.stringify(currentButtonValue) : currentLinkValue && JSON.stringify(currentLinkValue);
        const saveButtonClass = isButtonEnabled
            ? "btn btn-primary m-l-xs pull-left"
            : "btn btn-primary m-l-xs pull-left disabled";
        const selectValue = (this.state.linkButtonData === "DELETED") ? "" : buttonValue;
        const selectArray = [...allButtons, ...allLinks];
        let currentValue;
        let checkTargetPage;
        let selectArrayFiltered, selectProductArrayFiltered;
        const productOptions = offers && offers.map((product) => {
            return { value: JSON.stringify(product), text: product.text };
        });
        if (isMemberShipPage) {
            // const newButtonValue = pageData && pageData.buttonData.find(btn => btn.linkViewId === linkViewId);
            let buttonId = null;
            let newButtonValue = pageData && pageData.buttonData.find(btn => {
                pageData.jsPageData[btn["data-id"]] && pageData.jsPageData[btn["data-id"]].NEXT_PAGE.productTargetDetails.find(products => products.find(product => (product.linkViewId === linkViewId) ? buttonId = btn["data-id"] : null));
                return btn["data-id"] === buttonId;
            });
            const newLinkValue = pageData && pageData.linkData.find(btn => btn.linkViewId === linkViewId);
            if (newButtonValue) {
                if (newButtonValue["data-id"] in pageData.jsPageData) {
                    const currentPageDataBtn = pageData.jsPageData[newButtonValue["data-id"]].NEXT_PAGE.productTargetDetails;
                    const currentButtonList = currentPageDataBtn && currentPageDataBtn.map(button => (typeof button === "string") ? JSON.parse(button) : button);
                    const currentButtonData = currentButtonList && currentButtonList.filter(item => {
                        if (Array.isArray(item)) {
                            return item.find(index => index.linkViewId);
                        } else {
                            return item.linkViewId;
                        }
                    });
                    if (currentButtonData) {
                        if (isLoginPage) {
                            selectArrayFiltered = allButtons.filter(button => currentButtonData.find(currentBtn => Array.isArray(currentBtn) ? currentBtn.find(btn => {const parseValue = JSON.parse(button.value); return (btn.status === button.Status) && (linkViewId === btn.linkViewId);}) : (currentBtn.status === button.Status) && (currentBtn.linkViewId === linkViewId)));
                        }
                        if (isMemberAreaPage) {
                            let buttonId = null;
                            const buttonValue = pageData.jsPageData[newButtonValue["data-id"]].NEXT_PAGE.productTargetDetails.find((products) => products.find(product => {product.linkViewId === linkViewId ? buttonId = newButtonValue["data-id"] : null;}));
                            selectArrayFiltered = allButtons.filter(button => {const parseValue = JSON.parse(button.value); return parseValue["data-id"] === buttonId;});
                        }
                        const productList = pageData.jsPageData.selectedCampaign.products;
                        if (productList) {
                            const selectedproduct = productOptions.filter(items => {
                                let filteredCurrentButtonData = currentButtonData.filter(item => {return item[0].linkViewId === linkViewId && item[0].targetPage === targetPage.pageView[0].referenceId;});
                                return filteredCurrentButtonData[filteredCurrentButtonData.length - 1].find(index => (Array.isArray(index)) ? (index.find(ind => (ind.productId === JSON.parse(items.value).value) && (ind.linkViewId === linkViewId))) : (index.productId === JSON.parse(items.value).value) && (index.linkViewId === linkViewId));
                            });
                            selectProductArrayFiltered = selectedproduct;
                        }
                    }
                }
            }
            if (newLinkValue) {
                selectArrayFiltered = selectArray.filter((value) => {
                    currentValue = JSON.parse(value.value);
                    return (currentValue.linkViewId === linkViewId);
                });
            }
        } else {
            selectArrayFiltered = selectArray.filter((value) => {
                currentValue = JSON.parse(value.value);
                return (currentValue.linkViewId === linkViewId);
            });
        }
        const groupLabels = ["Buttons", "Links"];
        const currentProductValue = pageData && pageData.buttonData && pageData.buttonData[0].linkedProduct;
        const checkTarget = pageData && pageData.buttonData && pageData.buttonData[0].target;
        if (isMemberShipPage) {
            const selectProductArrayFiltered = checkTarget && targetPage && checkTarget === targetPage.referenceId ? currentProductValue && currentProductValue.filter((product) => {
                return { value: JSON.stringify(product), text: product.name };
            }) : null;
        }
        return (
            <div>
                <h3 className="">{pagelabel}</h3>
                <div className="col-xs-12 no-padding buttons-list form-group">
                    <Multiselect
                        data={selectArray}
                        textField='text'
                        groupBy='groupLabel'
                        defaultValue={selectArrayFiltered}
                        placeholder={selectLabel}
                        onChange={value => this.setState({ muliSelectValue: value, isButtonSelected: true, originalButtonLinkValues: selectArrayFiltered })}
                    />
                    <hr className="m-b-xs" />
                    <hr className="m-t-xs" />
                </div>
                {isMemberShipPage &&
                        [<h3 className="">{productLabel}</h3>,
                            <div className="col-xs-12 no-padding buttons-list form-group">
                                <h6 class="share-note"><i>Choose the products that go along with the selected button(s) or link(s)</i></h6>
                                <Multiselect
                                    data={productOptions}
                                    textField='text'
                                    defaultValue={selectProductArrayFiltered}
                                    placeholder={constants.productsPlaceholder}
                                    onChange={value => this.setState({ muliSelectProductValue: value, muliSelectValue: !isButtonSelected ? selectArrayFiltered : this.state.muliSelectValue })}
                                />
                                <hr className="m-b-xs" />
                                <hr className="m-t-xs" />
                            </div>]
                }
                {/*eslint-disable*/}
                <div className="col-xs-12 no-padding buttons-list">
                    <button onClick={isButtonEnabled ? this.onSelectButton : () => null} className={saveButtonClass}><i class="fa fa-cloud-upload" aria-hidden="true"></i> Save</button>
                </div>
            </div>
        );
    }
    dottedLine = () => {
        this.setState(prevState => ({
            isDotted: !prevState.isDotted,
            isText: false
        }));
    }
    addText = () => {
        this.setState(prevState => ({
            isText: !prevState.isText,
            isDotted: false
        }));
    }
    copyShareId = () => {
        this.setState({ isCopied: true }, () => {
            const text = document.querySelector('#shareId');
            text.select();
            document.execCommand('copy');
            text.blur();
            setTimeout(() => { this.setState({ isCopied: false }) }, 2000);
        });
    }
    handleImportShareIdChange = (e) => {
        const { selectedData } = this.state;
        const { funnel } = this.props;
        const currentPage = funnel.page.find((element) => {
            return element.cellViewId === selectedData.model.id;
        });
    }

    importSharePage = () => {
        const { selectedData, selectedSplitSettingDetails, showSplitSetting } = this.state;
        const { funnel, funnels } = this.props;
        const funnelReferenceId = funnel.referenceId;
        const currentPage = showSplitSetting ? selectedSplitSettingDetails
            : funnel.page.find((element) => {
                return element.cellViewId === selectedData.model.id;
            });
        const currentPageView = currentPage && currentPage.pageView[0].shareId;
        let importId = document.querySelector('#importId').value;
        if (importId && currentPageView && (importId !== currentPageView)) {
            this.setState({ isShareIdError: false, sharePopup: true, isShareidSame: false });
        } else if (currentPageView === importId) {
            this.setState({ isShareIdError: true, sharePopup: false, isShareidSame: true });
        } else {
            this.setState({ isShareIdError: true, sharePopup: false, isShareidSame: false });
        }

    }
    handleImport = () => {
        const { selectedData, selectedSplitSettingDetails, showSplitSetting } = this.state;
        const { funnel, funnels } = this.props;
        const funnelReferenceId = funnel.referenceId;
        const currentPage = showSplitSetting ? selectedSplitSettingDetails
            : funnel.page.find((element) => {
                return element.cellViewId === selectedData.model.id;
            });
        const currentPageView = currentPage && currentPage.pageView[0].referenceId;
        let importId = document.querySelector('#importId').value;
        const jsonFile = this.stencil.toJSON();
        this.setState(prevState => ({ sharePopup: false, isShareIdError: false, isShareOpen: false, isRightPanelActive: false, isPageImported: true, isLoadTemplates: false }), () => {
            this.hideModalComponent();
            this.props.importPage({ importId, currentPageView, funnelReferenceId, funnel })
            .then(() => { 
                NotificationEmitter.emit(NOTIFICATION_EVENT_NAME, { type: "success", message: "Page has been successfully imported" });
            })
            .catch(err => {
                NotificationEmitter.emit(NOTIFICATION_EVENT_NAME, { type: "error", message: err.message, closeButton: true });
            });
        });
    }
    openShare = () => {
        this.setState(prevState => ({
            isShareOpen: !prevState.isShareOpen,
            isShareIdError: false,
        }));
    }
    saveFonts = (savedFonts) => {
        if (savedFonts && savedFonts.length) {
            const { selectedData, selectedSplitSettingDetails, showSplitSetting } = this.state;
            const { funnel } = this.props;
            const currentPage = showSplitSetting ? selectedSplitSettingDetails :
                funnel.page.find((element) => {
                    return element.cellViewId === selectedData.model.id;
                });
            const funnelReferenceId = funnel && funnel.referenceId;
            const fontUrlArray = savedFonts && savedFonts.map(item => {
                const fontFamily = item.family.split(' ').join('+');
                const fontUrl = `https://fonts.googleapis.com/css?family=${fontFamily}`;
                return { fontUrl, item };
            });
            this.props.saveFonts({ currentPage, fontUrlArray, funnelReferenceId });
            this.closeRightPanel();
        } else {
            this.setState({ fontAlert: true })
        }
    }
    handleChildUnmount = () => {
        this.setState({ renderChild: false });
    }

    /*
     * On Cell Add / Remove,
     * Check if its a blank visualizer
     */

    checkIfEmptyVisualizer() {
        if (this.stencil) {
            const numberOfElementsInVisualizer = this.stencil.attributes.cells.length;
            const showImportExistingFunnelButton = (numberOfElementsInVisualizer) ? false : true;
            this.setState({ showImportExistingFunnelButton });
        }
    }

    openImportFunnelPopup() {
        this.setState({ showImportFunnelModal: true });
    }

    /*
     * START OF ANALYTICS DISPLAY
     */

    showAnalyticsOverlay(status) {
        /*
         * Show Analytics On Top of every Page 
         */
        const {
            funnel,
        } = this.props;
        if (!isEmpty(funnel) && funnel.referenceId) {
            let analyticsOverlayDisplay = isBoolean(status) ? status : null;
            if (!isBoolean(analyticsOverlayDisplay)) {
                analyticsOverlayDisplay = !this.state.analyticsOverlayDisplay;
            }
            this.setState({ analyticsOverlayDisplay });
        }
    }

    renderAnalyticsOnPage() {
        /*
         * This function will populate the Analytics Data for every Page in visualizer.
         */
        let pageViewIds = [];
        let analyticElements = [];
        const {
            isAnalyticsDataFetched,
            funnelAnalyticsData,
            graphScale,
        } = this.state;
        const {
            funnel,
        } = this.props;
        const stencilModel = this.stencil.toJSON();

        stencilModel.cells.forEach(elem => {
            if (elem.type === "devs.MyImageModel") {
                pageViewIds.push(elem.id);
                const matchedPage = funnel.page.find((page) => page.cellViewId === elem.id);
                let data = [];
                if (!isEmpty(funnelAnalyticsData) && matchedPage && matchedPage.referenceId && funnelAnalyticsData[matchedPage.referenceId]) {
                    if (matchedPage.splitEnabled) {
                        const splitPages = funnel.page.filter((element) => {
                            return (element.cellViewId === elem.id);
                        });
                        splitPages.forEach(splitPage => {
                            data.push(funnelAnalyticsData[splitPage.referenceId]);
                        });
                    } else {
                        data.push(funnelAnalyticsData[matchedPage.referenceId]);
                    }
                } else {
                    data.push({});
                }
                analyticElements.push(
                    <AnalyticsContent
                        position={elem.position}
                        data={data}
                        graphScale={graphScale}
                        isSplitEnabled={matchedPage && matchedPage.splitEnabled}
                    />
                );
            }
        });
        if (!isAnalyticsDataFetched) {
            this.fetchAnalyticsData(pageViewIds);
        }
        return analyticElements;
    }

    fetchAnalyticsData(pageViewIds = []) {
        const { funnel } = this.props;
        const promiseArrays = [];
        const referenceIdArrays = [];
        const createAnalyticsCall = (funnelReferenceId, pageReferenceId) => {
            return this.props.getAnalyticsData({
                funnelReferenceId,
                pageReferenceId,
            });
        };
        funnel.page.forEach((page) => {
            if (page.splitEnabled) {
                const splitPages = funnel.page.filter((element) => {
                    return (element.cellViewId === page.cellViewId);
                });
                splitPages.forEach(splitPage => {
                    promiseArrays.push(createAnalyticsCall(funnel.referenceId, splitPage.referenceId));
                    referenceIdArrays.push(splitPage.referenceId);
                });
            } else {
                promiseArrays.push(createAnalyticsCall(funnel.referenceId, page.referenceId));
                referenceIdArrays.push(page.referenceId);
            }
        });
        /*
         * Fetch the data for all the page reference ids.
         * [TODO] - Currently different calls are being rendered for different page Reference Ids
         * This should be made into a single ajax call which returns all the data
         */
        Promise.all(promiseArrays).then((values) => {
            const funnelAnalyticsData = {};
            values.forEach((value, index) => {
                const {
                    result,
                } = value;
                const key = referenceIdArrays[index];
                funnelAnalyticsData[key] = result;
            });
            this.setState({ isAnalyticsDataFetched: true, funnelAnalyticsData });
        })
            .catch(err => {
                this.setState({ isAnalyticsDataFetched: true });
            });
    }
    /*
     * END OF ANALYTICS DISPLAY 
     */

    render() {
        const { isRightPanelActive, viewportWidth, viewportHeight, templateTypeId, isShareidSame,
            isPageImported, isShareIdError, isConfigLink, isCopied, pageTypeLabel, cellData,
            scrollerHeight, isDotted, isText, scrollerWidth, innerZoom, outerZoom, pageName,
            visualizerHeight, visualizerWidth, left, top, isLoadTemplates, currentPageId,
            selectedData, addedElementArray, graphScale, isTemplateSelected, isPreviewEnable,
            isZoom, renderChild, selectedFonts, isSplitThumbnail, selectedSplitSettingDetails, showSplitSetting, isSelectedSplitPageDefaultPage, splitPageName,
            showImportExistingFunnelButton, showImportFunnelModal, analyticsOverlayDisplay, domainName, showValidationError, ModalComponent,
            isPopOverEnable
        } = this.state;
        const { templateList, selectedTemplate, funnel, splitPageList,
            editFunnel, publishFunnel, updatedPage, domainList, onChangeDomainSelection,
            selectedDomainForFunnel, changeDefaultPage, fontList, savedFonts, isFontLoading,
            isImportFunnelLoading, shareFunnel, showTemplatesList, showTemplates, splitPages, setSplitPercent,
            pageData, funnels, updateFunnelData, getDefaultFunnels, defaultFunnelTemplates, funnelTemplates, savePageViewData, loadButtons, showAddNewDomainPopup,
        } = this.props;
        const panelClass = isRightPanelActive ? "rightPanel active" : "rightPanel";
        const dottedLine = isDotted ? "m-l-xs m-r-xs pull-right dottedLine active" : "m-l-xs m-r-xs pull-right dottedLine";
        const textelement = isText ? "m-l-xs pull-right text-element active" : "m-l-xs pull-right text-element";
        const copyMessage = isCopied ? "copy-message active" : "copy-message";
        const isFunnelPublished = funnel.status;
        var zoom = (graphScale * 100).toFixed(0) + " %";
        let currentPage;
        if (funnel.page && selectedData.model && !isPageImported) {
            currentPage = funnel.page.find((element) => {
                return element.cellViewId === selectedData.model.id;
            });
        }
        if (isPageImported) {
            currentPage = updatedPage;
            this.setState({ isPageImported: !isPageImported });
        }
        if (selectedSplitSettingDetails && selectedSplitSettingDetails.length) {
            currentPage = selectedSplitSettingDetails;
        }
        // const selectedFonts = savedFonts && savedFonts.map(item => {
        //     return item.fontName;
        // });
        let isTemplateId = isPageImported ? updatedPage && updatedPage.pageView[0].fTemplateId : currentPage && currentPage.pageView[0].fTemplateId;
        let isEdited = selectedSplitSettingDetails && selectedSplitSettingDetails.length ? selectedSplitSettingDetails.isEdited
            : currentPage && currentPage.isEdited;
        const splitUrl = this.getSplitUrl(funnel);
        let pageUrl = currentPage ? currentPage.pageView[0].url : "";
        const isSplitEnabled = currentPage && currentPage.splitEnabled;
        const isDefaultPage = currentPage ? currentPage.pageView[0].defaultPage : false;
        let pageLiveUrl = currentPage ? currentPage.pageView[0].publicUrl : "";
        const previewBtn = funnel.status ? "btn btn-primary m-l-xs pull-right" : "btn btn-primary m-l-xs pull-right disabled disable-pointer";
        const listTemplate = templateList.sort((a, b) => {
            return b.props.template.templateTypeId < constants.loginTemplateTypeId && a.props.template.templateTypeId < constants.loginTemplateTypeId ?
                b.props.template.templateTypeId - a.props.template.templateTypeId
                : a.props.template.templateTypeId - b.props.template.templateTypeId;
        });
        const filteredTemplateList = listTemplate.filter(
            (template) => {
                return (
                    (template.props.template.templateTypeId === 6 && this.state.templateTypeId) ||
                    (template.props.template.templateTypeId && template.props.template.templateTypeId === this.state.templateTypeId)
                );
            }
        );
        const pageLabel = cellData.attributes && cellData.attributes.pageLabel;
        const isIcon = cellData.attributes && cellData.attributes.icon;
        const zoomBtn = this.graphScale < 0 ? "input-group-addon disable" : "input-group-addon pointer";
        const shareClass = this.state.isShareOpen ? "share-popup active" : "share-popup";
        const zoomValue = graphScale;
        const addNewDomainOption =
            <option
                key={"addNewDomain"}
                value={"addNewDomain"}
            >
                {"Add new Domain"}
            </option>;
        const domainListDropDown = domainList &&
            domainList.map((domain) =>
                <option
                    key={domain.referenceId ? domain.referenceId : "defaultDomain"}
                    value={domain.referenceId ? domain.referenceId : "defaultDomain"}
                >
                    {domain.name}
                </option>
            )
        domainListDropDown && domainListDropDown.push(addNewDomainOption);
        const getActivePages = funnel && funnel.page && funnel.page.length && funnel.page.filter((page) => page.active);
        const isDefaultPageSelected = getActivePages && getActivePages.find((activePage) => activePage.pageView[0].defaultPage) ?
            true :
            false;
        const allPagesEditedCheck = getActivePages && getActivePages.length ?
            getActivePages.find((activePage) => !activePage.isEdited) ?
                false :
                true :
            false;
        const appendHttps = "https://";
        const selectedDomainDetails = domainList && domainList.find((list) => list.selected);
        const selectedDomainName = selectedDomainDetails && selectedDomainDetails.name;
        const liveSiteUrl = selectedDomainName && appendHttps.concat(selectedDomainName);
        const publishFunnelClass = "btn btn-primary m-l-xs pull-right";
        const isMembershipPage = constants.membershipPageTemplateTypeid.find((id) => id === this.state.templateTypeId);
        const isMemberAreaPage = constants.membersAreaTemplateTypeId === this.state.templateTypeId;
        const renderSharePageComponent = () => {
            if (!isConfigLink && !isSplitEnabled) {
                return (
                    <UserDataInjector>
                        {
                            (userData) => {
                                return (
                                    <>
                                        {
                                            !userData.isDesignerUser && (
                                                <SharePage
                                                    openShare={this.openShare}
                                                    shareClass={shareClass}
                                                    copyMessage={copyMessage}
                                                    isTemplateId={isTemplateId}
                                                    isEdited={isEdited}
                                                    currentPage={currentPage && currentPage}
                                                    copyShareId={this.copyShareId}
                                                    importShareId={this.state.importShareId}
                                                    handleImportShareIdChange={this.handleImportShareIdChange}
                                                    isShareIdError={isShareIdError}
                                                    isShareidSame={isShareidSame}
                                                    importSharePage={this.importSharePage}
                                                />
                                            )
                                        }
                                    </>
                                );
                            }
                        }
                    </UserDataInjector>
                );
            }
            return null;   
        };
        const renderPageNameComponent = (className="") => {
            if (!isConfigLink && !isSplitEnabled) {
                return (
                    <TextInput
                        name="pageName"
                        label={pageTypeLabel && pageTypeLabel + " Name "}
                        value={pageName}
                        onChange={this.pageNameChange}
                        maxLength="25"
                        id="pageName"
                        inputAddon={true}
                        onClick={this.modifylabel}
                        btnText="Save"
                        className={className}
                    />
                );
            }
            return null;
        }
        return (
            <>
                <div className="save-funnel">
                    <h3 className="funnel-title"><span>{funnel.title}</span> <i onClick={editFunnel} className="fa fa-cog fa-spin m-l-sm pointer" /></h3>
                    <UserDataInjector>
                        {
                            (userData) => {
                                return (
                                    <div className="col-xs-10">
                                        {/* //Need to work on Graph Zoom functionality do not remove */}
                                        <button className = {previewBtn}>
                                            <a className="text-white" href={liveSiteUrl} target="_blank">
                                                <i className="fa fa-globe" aria-hidden="true"/> Live site
                                            </a>
                                        </button>
                                        <div className="input-group zoomBox m-l-xs">
                                            <div className={zoomBtn} onClick={this.zoomOut} title="Zoom out"><i className="fa fa-search-minus" aria-hidden="true"></i></div>
                                            <input className="form-control zoom-level" type="text" disabled value= { zoom } readOnly title = "Zoom" />
                                            <div className={zoomBtn} onClick={this.zoomIn} title="Zoom in"><i className="fa fa-search-plus" aria-hidden="true"></i></div>
                                        </div>
                                        {
                                            <button onClick={shareFunnel} className="btn btn-primary m-l-xs pull-right"><i className="fa fa-share-square-o m-r-xs" aria-hidden="true"></i> Share</button>
                                            
                                        }
                                        <button onClick={this.showAnalyticsOverlay} className="btn btn-primary m-l-xs pull-right btn-visualizer-analytics">
                                            <i className="fa fa-chart-bar m-r-xs" aria-hidden="true" />
                                            Analytics
                                        </button>
                                        {
                                            !userData.isDesignerUser && (
                                                <button
                                                    onClick={() => {publishFunnel(isDefaultPageSelected, allPagesEditedCheck);}}
                                                    className= {publishFunnelClass}>
                                                    <i
                                                        className="fa fa-cloud-upload"
                                                        aria-hidden="true">
                                                    </i> Publish
                                                </button>
                                            )
                                        }
                                        <div className="input-group col-xs-6 pull-right">
                                            <div className="input-group-addon">Domain URL</div>
                                            <select className="form-control"
                                                value={selectedDomainForFunnel ? selectedDomainForFunnel : "defaultDomain"}
                                                onChange={onChangeDomainSelection}>
                                                {domainListDropDown}
                                            </select>
                                        </div>
                                        <span onClick={this.dottedLine} className={dottedLine}></span>
                                        <span onClick={this.addText} className={textelement}>A</span>
                                    </div>
                                )
                            }
                        }
                    </UserDataInjector>
                </div>                
                <div className="canvas" id="canvas" ref="canvas">
                    <div className="pageList">
                        <div id="stencil" ref="stencil"></div>
                    </div>
                    <div className="visualizer" id="visualizer">
                        <div className="page-scroller" id="page-scroller"
                            style={{
                                width: (((viewportWidth * graphScale)) > (this.state.scrollerWidth * this.state.outerZoom)) ? ((viewportWidth * graphScale) + 220) : (this.state.scrollerWidth * this.state.outerZoom),
                                height: (((viewportHeight * graphScale)) > (this.state.scrollerHeight * this.state.outerZoom)) ? ((viewportHeight * graphScale) + 220) : (this.state.scrollerHeight * this.state.outerZoom)
                            }}
                        >
                            <div ref="visualizer" className="visualizer-graph"
                                style={{
                                    minWidth: 1600, minHeight: 800,
                                    marginLeft: - (((viewportWidth * graphScale) > (visualizerWidth * innerZoom)) ? (((viewportWidth * graphScale) + 100) / 2) : ((visualizerWidth * innerZoom) / 2)),
                                    marginTop: - ((viewportHeight * graphScale) > (visualizerHeight * innerZoom) ? (((viewportHeight * graphScale) + 200) / 2) : ((visualizerHeight * innerZoom) / 2)),
                                    width: ((viewportWidth * graphScale) > (visualizerWidth * innerZoom)) ? ((viewportWidth * graphScale) + 100) : (visualizerWidth * innerZoom),
                                    height: ((viewportHeight * graphScale) > (visualizerHeight * innerZoom)) ? ((viewportHeight * graphScale) + 200) : (visualizerHeight * innerZoom)
                                }}
                            >
                                {
                                    analyticsOverlayDisplay && (
                                        this.renderAnalyticsOnPage()
                                    )
                                }
                            </div>
                            {isImportFunnelLoading &&
                                <div className="import-page-loader"><i className="fa fa-spinner fa-pulse fa-lg m-r-xs" /></div>
                            }
                            {
                                showImportExistingFunnelButton && (
                                    <div className="btn-import-default-templates">
                                        <button
                                            type="button"
                                            className="btn btn-primary"
                                            onClick={this.openImportFunnelPopup}
                                        >
                                            Choose a Template Funnel
                                        </button>
                                    </div>
                                )
                            }
                        </div>
                    </div>
                    <div className={panelClass}>
                        {
                            isRightPanelActive && renderSharePageComponent()
                        }
                        {!isConfigLink && isTemplateId && isSplitEnabled &&
                            <SplitTestSettingsView
                                setSplitPercent={setSplitPercent}
                                splitPages={splitPages && splitPages}
                                splitPageList={splitPageList}
                                showSplitSetting={showSplitSetting}
                                selectedSplitSettingDetails={selectedSplitSettingDetails}
                                pageTypeLabel={pageTypeLabel}
                                splitPageName={splitPageName}
                                pageNameChange={this.pageNameChange}
                                modifyLabel={this.modifylabel}
                                isFunnelPublished={isFunnelPublished}
                                fontList={fontList && fontList}
                                selectedFonts={selectedFonts && selectedFonts}
                                isFontLoading={isFontLoading && isFontLoading}
                                savedFonts={savedFonts && savedFonts}
                                saveFontsCall={this.saveFonts}
                                isDefaultPage={isDefaultPage}
                                changeDefaultPage={changeDefaultPage}
                                isSelectedSplitPageDefaultPage={isSelectedSplitPageDefaultPage}
                                openShare={this.openShare}
                                copyShareId={this.copyShareId}
                                shareClass={shareClass}
                                copyMessage={copyMessage}
                                importSharePage={this.importSharePage}
                                isEdited={isEdited}
                                importShareId={this.state.importShareId}
                                handleImportShareIdChange={this.handleImportShareIdChange}
                                isShareIdError={isShareIdError}
                                isShareidSame={isShareidSame} />
                        }
                        <div className="col-xs-12 panel-content">
                            {
                                renderPageNameComponent()
                            }
                            {!isConfigLink && isFunnelPublished && isTemplateId && !isSplitEnabled
                                ? <div className="form-group">
                                    <div className="input-group">
                                        <label className="input-group-addon">Live URL</label>
                                        <input className="form-control" name="productName" value={pageLiveUrl} type="text" />
                                        <div className="input-group-addon badge-primary" ><a className="text-white" href={pageLiveUrl} target="_blank"><i class="fa fa-external-link" aria-hidden="true" /></a></div>
                                    </div>
                                </div>
                                : null
                            }
                            {
                                isConfigLink ?
                                    this.renderButtonInput()
                                    : (isIcon ? (
                                        <div className="col-xs-12 no-padding full-height">
                                            <div className="no-template">
                                                <h3 className="text-center">Please setup auto-responders on your campaign in CRM to activate this page</h3>
                                            </div>
                                        </div>
                                    ) : null
                                    )
                            }
                            {!isConfigLink && isTemplateId &&
                                <>
                                    {!isSplitEnabled &&
                                        <FontsList fontList={fontList && fontList} selectedFonts={selectedFonts && selectedFonts} isFontLoading={isFontLoading && isFontLoading} savedFonts={savedFonts && savedFonts} saveFontsCall={this.saveFonts} />
                                    }
                                    {!isMembershipPage &&
                                        <SplitTest showTemplates={showTemplates} isSplitEnabled={isSplitEnabled} filteredTemplateList={filteredTemplateList} duplicatePage={this.duplicatePage} openSplitOptions={this.openSplitOptions} closeSplitOption={this.closeSplitOption} showTemplatesList={showTemplatesList} splitOtionsPopup={this.state.splitOtionsPopup} />
                                    }
                                    {isMemberAreaPage &&
                                        <MembershipProductList funnel={funnel && funnel} savePageViewData={savePageViewData} currentPage={currentPage} loadButtons={loadButtons} pageData={pageData && pageData} closeRightPanel={this.closeRightPanel} />
                                    }
                                    <div className="settings-analytics-wrapper"><PageAnalytics getAnalyticsData={this.props.getAnalyticsData} funnel={this.props.funnel} pages={splitPages} /></div>
                                </>
                            }
                            {!isConfigLink && isTemplateId && !isSplitEnabled &&
                                <div className="checkbox checkbox-success checkbox-md custom-checkBox">
                                    <input type="checkbox"
                                        className="styled" id="setDefaultPage"
                                        checked={isDefaultPage}
                                        onChange={() => changeDefaultPage(currentPage.pageView[0].referenceId, isDefaultPage, currentPage.splitEnabled)}
                                    />
                                    <label for="setDefaultPage">{constants.setDefault} </label>
                                </div>
                            }
                        </div>
                    </div>
                </div>
                {
                    ModalComponent && (
                        <ModalComponent
                            onClose={this.hideModalComponent}
                            templateSelectionConfig={this.props.templateSelectionConfig}
                            templateTypeIdToFilter={this.state.templateTypeId}
                        >
                            <>
                                {
                                    renderPageNameComponent("page-name-right-panel")
                                }
                                {
                                    renderSharePageComponent()
                                }
                            </>
                        </ModalComponent>
                    )
                }
                <Modal show={this.state.previewAlert} backdrop="static" onHide={this.handleClose} bsSize="" aria-labelledby="contained-modal-title-lg">
                    <Modal.Header closeButton style={{ backgroundColor: "rgb(47, 64, 77)" }}>
                        <Modal.Title><h3 className="text-white">Information</h3></Modal.Title>
                    </Modal.Header>
                    <Modal.Body className="m-n">
                        <h4 className="text-center">{constants.previewAlert}</h4>
                    </Modal.Body>
                    <Modal.Footer>
                        <a className="btn btn-primary" onClick={this.handleClose}>Ok</a>
                    </Modal.Footer>
                </Modal>
                <Modal show={this.state.linkAlert} backdrop="static" onHide={this.handleClose} bsSize="" aria-labelledby="contained-modal-title-lg">
                    <Modal.Header closeButton style={{ backgroundColor: "rgb(47, 64, 77)" }}>
                        <Modal.Title><h3 className="text-white">Information</h3></Modal.Title>
                    </Modal.Header>
                    <Modal.Body className="m-n">
                        <h4 className="text-center">{constants.previewAlert}</h4>
                    </Modal.Body>
                    <Modal.Footer>
                        <a className="btn btn-primary" onClick={this.handleClose}>Ok</a>
                    </Modal.Footer>
                </Modal>
                <Modal show={this.state.fontAlert} backdrop="static" onHide={this.handleClose} bsSize="" aria-labelledby="contained-modal-title-lg">
                    <Modal.Header closeButton style={{ backgroundColor: "rgb(47, 64, 77)" }}>
                        <Modal.Title><h3 className="text-white">Information</h3></Modal.Title>
                    </Modal.Header>
                    <Modal.Body className="m-n">
                        <h4 className="text-center">{constants.fontAlert}</h4>
                    </Modal.Body>
                    <Modal.Footer>
                        <a className="btn btn-primary" onClick={this.handleClose}>Ok</a>
                    </Modal.Footer>
                </Modal>
                <Modal show={this.state.splitCopyAlert} backdrop="static" onHide={this.handleClose} bsSize="" aria-labelledby="contained-modal-title-lg">
                    <Modal.Header closeButton style={{ backgroundColor: "rgb(47, 64, 77)" }}>
                        <Modal.Title><h3 className="text-white">Information</h3></Modal.Title>
                    </Modal.Header>
                    <Modal.Body className="m-n">
                        <h4 className="text-center">{constants.splitCopyAlert}</h4>
                    </Modal.Body>
                    <Modal.Footer>
                        <a className="btn btn-primary" onClick={this.handleClose}>Ok</a>
                    </Modal.Footer>
                </Modal>
                <Modal show={this.state.deletePopup} backdrop="static" onHide={this.handleClose} bsSize="" aria-labelledby="contained-modal-title-lg">
                    <Modal.Header >
                        <Modal.Title><h3 className="text-white">Confirmation</h3></Modal.Title>
                    </Modal.Header>
                    <Modal.Body className="m-n">
                        <h4 className="text-center">Are you sure to delete the page?</h4>
                    </Modal.Body>
                    <Modal.Footer>
                        <a className="btn btn-primary" onClick={this.handleDelete}>Yes</a>
                        <a className="btn btn-default" onClick={this.handleClose}>No</a>
                    </Modal.Footer>
                </Modal>
                <Modal show={this.state.sharePopup} backdrop="static" onHide={this.handleClose} bsSize="" aria-labelledby="contained-modal-title-lg">
                    <Modal.Header >
                        <Modal.Title><h3 className="text-white">Confirmation</h3></Modal.Title>
                    </Modal.Header>
                    <Modal.Body className="m-n">
                        <h4 className="text-center">Import page will overwrite your existing the page. Do you wish to continue?</h4>
                    </Modal.Body>
                    <Modal.Footer>
                        <a className="btn btn-primary" onClick={this.handleImport}>Yes</a>
                        <a className="btn btn-default" onClick={this.handleClose}>No</a>
                    </Modal.Footer>
                </Modal>
                {
                    showImportFunnelModal && (
                        <ImportDefaultFunnelsModal
                            handleClose={this.handleClose}
                            show
                            currentFunnelId={funnel.referenceId}
                            funnels={funnels}
                            getDefaultFunnels={getDefaultFunnels}
                            updateFunnelData={updateFunnelData}
                            defaultFunnelTemplates={this.props.defaultFunnelTemplates}
                            premiumFunnelTemplates={this.props.premiumFunnelTemplates}
                            getPremiumFunnelTemplates={this.props.getPremiumFunnelTemplates}
                            openFunnelTemplatesOnLoad={this.state.openFunnelTemplatesOnLoad}
                            funnelTemplateTypeIdToPrepopulate={this.props.funnelTemplateTypeIdToPrepopulate}
                            purchaseOrder={this.props.purchaseOrder}
                            userData={this.props.userData}
                            funnel={funnel}
                            loadFunnels={this.props.loadFunnels}
                            loadTemplates={this.props.loadTemplates}
                        />
                    )
                }
            </>
        );
    }
}
