export const showElement = (editor, id, type, component) => {
    if (component && component.attributes && component.attributes.type === type) {
        let wrapper = editor.DomComponents.getWrapper();
        wrapper.view.$el.find("#" + id)[0].setAttribute("style", "display:block");
    }
};

export const destroyComponent = (editor, id) => {
    var wrapper = editor.DomComponents.getWrapper();
    if (wrapper.view.$el.find(`#${id}`).length > 0) {
        var listOfComponents = editor.getComponents();
        var destroyComp = listOfComponents.models.find(com => com.attributes.attributes.id === id);
        destroyComp.destroy();
    }
};