import axios from "axios";
import values from "lodash/values";
import {
    welcomeVideoConfigUrl,
    walkthroughConfigUrl,
} from "../../api/urls";
import {
    SET_WELCOME_VIDEO_URL,
    SET_WALKTHROUGH_CONFIG,
    SET_WELCOME_VIDEO_URL_FETCH_FAILED,
    SET_WALKTHROUGH_CONFIG_FAILED,
    SET_WELCOME_MODAL_SEEN_BY_USER,
    SHOW_WELCOME_MODAL_ON_MENU_CLICK,
    SHOW_WALKTHROUGH_MODAL,
    SET_WALKTHROUGH_MODAL_ENABLED,
    SET_WALKTHROUGH_PEEKABOO_SEEN_BY_USER,
    SET_CRM_ENTRY_MODAL,
    SET_ACTIVE_FUNNEL_TITLE,
    SET_CRM_ENTRY_MODAL_INSIDE_VISUALIZER,
} from "./constants";

/**
 * Fetch app settings from S3 config JSON file.
 * This JSON config will store the video url, that is shown to first time users.
 * This JSON config will also have the steps defined for "CREATE FUNNEL" widget flow.
 *
 * This API doesn't required API token validation
 *
 *
 * @export fetchAppSettings
 * @requires axios
 * @requires REACT_APP_APP_SETTINGS_JSON Environment variable to store the S3 json file path.
 * @returns { function } The dispatch function which sets the settings data to APP_SETTINGS store.
 */
export function fetchAppSettings() {
    const getWelcomeVideoConfig = () => axios.get(welcomeVideoConfigUrl);
    const getWalkthroughConfig = () => axios.get(walkthroughConfigUrl);
    return function (dispatch) {
        getWelcomeVideoConfig()
            .then(({ data = {} }) => {
                const { welcomeVideoUrl } = data;
                if (welcomeVideoUrl) {
                    dispatch({
                        type: SET_WELCOME_VIDEO_URL,
                        welcomeVideoUrl,
                    });
                } else {
                    dispatch({
                        type: SET_WELCOME_VIDEO_URL_FETCH_FAILED,
                    });
                }
            })
            .catch(() => {
                dispatch({
                    type: SET_WELCOME_VIDEO_URL_FETCH_FAILED,
                });
            });

        getWalkthroughConfig()
            .then(({ data }) => {
                dispatch({
                    type: SET_WALKTHROUGH_CONFIG,
                    walkthroughTiles: values(data.tiles),
                });
            })
            .catch(() => {
                dispatch({
                    type: SET_WALKTHROUGH_CONFIG_FAILED,
                });
            });
    };
}

export function setWelcomeModalSeenByUser(status) {
    return { type: SET_WELCOME_MODAL_SEEN_BY_USER, status };
}

export function setWelcomeModalOnMenuClick(status) {
    return { type: SHOW_WELCOME_MODAL_ON_MENU_CLICK, status };
}

export const setWalkthroughModalEnabled = (status) => ({ type: SET_WALKTHROUGH_MODAL_ENABLED, status });

export const openWalkthroughModal = (status) => ({ type: SHOW_WALKTHROUGH_MODAL, status });

export const setWalkthroughModalSeenByUser = () => ({ type: SET_WALKTHROUGH_PEEKABOO_SEEN_BY_USER });

export const toggleCRMEntryModal = (status) => ({ type: SET_CRM_ENTRY_MODAL, status });

export const toggleCRMEntryModalInsideVisualizer = (status) => ({ type: SET_CRM_ENTRY_MODAL_INSIDE_VISUALIZER, status });

export const setActiveFunnelTitle = (value) => ({ type: SET_ACTIVE_FUNNEL_TITLE, value });
