const handleStickyBottomGapUpdate = (editor, component) => {
    const sm = editor.SelectorManager;
    const posBottom = sm.add("positionBottomGap");
    const rule = editor.CssComposer.add([posBottom]);
    rule.set("style", {
        "bottom": component.changed.stickyBottomGap,
        "top": "inherit"
    });
    component.removeClass("positionTopGap");
    component.removeClass("placeBottom");
    component.addClass("positionBottomGap");
};

export default handleStickyBottomGapUpdate;