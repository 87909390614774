import React from "react";
import PropTypes from "prop-types";

const DomainList = ({ domain, editDomain, deleteDomain }) => {
    return (
        <tr key={domain.referenceId}>
            <td className="col-sm-1"></td>
            <td><i data-toggle="tooltip" title={domain.https ? "This domain is SSL secured" : "This domain is not SSL secured"} className={domain.https ? "fa fa-shield shield-green" : "fa fa-shield shield-red"}/>{domain.name}</td>
            <td>
                <button
                    className="btn btn-outline btn-primary edit-domain"
                    onClick={() => editDomain(domain)}>
                    <i
                        className="fa fa-edit">
                    </i>
                </button>
                <button
                    className="btn btn-outline btn-danger"
                    onClick={() => deleteDomain(domain)}>
                    <i
                        className="fa fa-trash danger">
                    </i>
                </button>
            </td>
        </tr>
    );
};

DomainList.propTypes = {
    domain: PropTypes.object.isRequired,
    editDomain: PropTypes.func,
    deleteDomain: PropTypes.func
};

export default DomainList;