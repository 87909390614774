import React, { useContext } from "react";
import PropTypes from "prop-types";
import { VisualizerContext } from ".";
import { EditPageName,VisualizerRightPanel } from "./components/VisualizerRightPanel";
import {
    getPageType,
    getTemplateTypeId,
    getPageTextName
} from "./utilities/helpers";

const ComingSoonRightPanel = ({
    currentPage,
}) => {
    const VisualizerContextData = useContext(VisualizerContext);
    const {
        saveUpdatedPageName,
    } = VisualizerContextData;
    const currentPageTemplateTypeId = getTemplateTypeId(currentPage);
    const pageTypeLabel = getPageType(currentPageTemplateTypeId);
    const initialPageTitle = getPageTextName(currentPage);
    const pageReferenceId = currentPage.get("pageReferenceId");
    const savePageName = (currentPageTitle) => {
        return saveUpdatedPageName({ pageReferenceId, currentPageTitle, currentPage });
    };
    return (
        <VisualizerRightPanel>
            <h2>Coming Soon</h2>
            <EditPageName
                pageTypeLabel={pageTypeLabel}
                initialPageTitle={initialPageTitle}
                savePageName={savePageName}
            />
            <div className="mt-20">
                <div className="coming-soon"></div>
            </div>
        </VisualizerRightPanel>
    );
};

ComingSoonRightPanel.propTypes = {
    currentPage: PropTypes.object,
};

export default ComingSoonRightPanel;