/*eslint-disable*/
import grapesjs from 'grapesjs';
import JSZip from 'jszip';
import FileSaver from 'file-saver';

export default grapesjs.plugins.add('gjs-plugin-export', (editor, opts) => {
    let c = opts || {};
    let config = editor.getConfig();
    let pfx = config.stylePrefix;
    let btnExp = document.createElement("BUTTON");
    let commandName = 'gjs-export-zip';

    let defaults = {
        addExportBtn: 1,
        btnLabel: 'Export to ZIP',
        preHtml: '<!doctype html><html lang="en"><head><meta charset="utf-8"><link rel="stylesheet" href="./css/style.css"></head><body>',
        postHtml: '</body><html>',
        preCss: '',
        postCss: ''
    };

    for (let name in defaults) {
        if (!(name in c))
            c[name] = defaults[name];
    }

    btnExp.innerHTML = c.btnLabel;
    btnExp.className = pfx + 'btn-prim';

    // Add command
    editor.Commands.add(commandName, {
        run() {
            let zip = new JSZip();
            let cssDir = zip.folder("css");
            let fn = 'FunnelKonnekt_template_' + Date.now() + '.zip';
            zip.file('index.html', c.preHtml + editor.getHtml() + c.postHtml);
            cssDir.file('style.css', c.preCss + editor.getCss() + c.postCss);
            zip.generateAsync({type:"blob"})
                .then((content) => {
                    FileSaver.saveAs(content, fn);
                });
        }
    });

    // Add button inside export dialog
    if (c.addExportBtn) {
        editor.on('run:export-template', () => {
            editor.Modal.getContentEl().appendChild(btnExp);
            btnExp.onclick = () => {
                editor.runCommand(commandName);
            };
        });
    }

    const modal = editor.Modal;
    const cmdm = editor.Commands;
    const codeViewer = editor.CodeManager.getViewer('CodeMirror').clone();
    const pnm = editor.Panels;
    const container = document.createElement('div');
    const editBtnParent2 = document.createElement('div');
    editBtnParent2.className = 'text-right';
    const btnEdit2 = document.createElement('button');
    editBtnParent2.appendChild(btnEdit2);

    codeViewer.set({
        codeName: 'htmlmixed',
        readOnly: 0,
        theme: 'hopscotch',
        autoBeautify: true,
        autoCloseTags: true,
        autoCloseBrackets: true,
        lineWrapping: true,
        styleActiveLine: true,
        smartIndent: true,
        indentWithTabs: true
    });

    btnEdit2.innerHTML = 'Edit';
    btnEdit2.className = pfx + 'btn-prim float-right ' + pfx + 'btn-import';
    btnEdit2.onclick = function() {
        const code = codeViewer.editor.getValue();
        editor.DomComponents.getWrapper().set('content', '');
        editor.setComponents(code.trim());
        modal.close();
    };

    cmdm.add('html-edit', {
        run: function(editor, sender) {
            sender && sender.set('active', 0);
            let viewer = codeViewer.editor;
            modal.setTitle('Edit code');
            if (!viewer) {
                const txtarea = document.createElement('textarea');
                container.appendChild(txtarea);
                container.appendChild(editBtnParent2);
                
                codeViewer.init(txtarea);
                viewer = codeViewer.editor;
            }
            const InnerHtml = editor.getHtml();
            const Css = editor.getCss();
            modal.setContent('');
            modal.setContent(container);
            codeViewer.setContent(InnerHtml + "<style>" + Css + '</style>');
            modal.open();
            viewer.refresh();
        }
    });

    pnm.addButton('options',
        [
            {
                id: 'edit',
                className: 'fa fa-edit',
                command: 'html-edit',
                attributes: {
                    title: 'Edit'
                }
            }
        ]
    );

});