import React, { useState } from "react";
import PropTypes from "prop-types";
import styled, { keyframes } from "styled-components";

/*
 * @name - WalkthroughPeekaboo
 * @type - Component
 *
 * This modal will be shown for a brief amount of time,
 * when the user login.
 *
 * This modal will not be shown when user is first logged in.
 */
const PeekabooImageWidth = 900;
const WalkthroughPeekaboo = ({ show, relativeElement, onClose, delay = 0 }) => {
    const [showPeekaboo, setPeekabooDisplay] = useState(false);
    let isPeekabooAnimating = false;
    let top = 0;
    let left = 0;
    if (relativeElement) {
        const position = relativeElement.getBoundingClientRect();
        top = position.top + 20;
        left = position.left + relativeElement.offsetWidth / 2;
    }
    var viewportWidth = Math.max(document.documentElement.clientWidth, window.innerWidth || 0);
    /*
     * Calculate x position with relative to the viewport.
     * Since it is an image, we already know the width of it to be 900px
     */
    let xPosition = parseInt(viewportWidth / 2) - (PeekabooImageWidth / 2);
    const peekabooAnimation = keyframes`
        0%   {top: ${top}px; left: ${left}px; transform:scale(0); opacity: 0;}
        5%   {top: ${top}px; left: ${left}px; transform: scale(0.1); opacity: 0.5;}
        20%  {top: 20%; left: ${xPosition}px; transform: scale(1.25); opacity: 1;}
        80%  {top: 20%; left: ${xPosition}px; transform: scale(1.25); opacity: 1;}
        95%  {top: ${top}px; left: ${left}px; transform: scale(0.1); opacity: 0.5;}
        100% {top: ${top}px; left: ${left}px; transform:scale(0); opacity: 0;}
    `;
    const WalkthroughContainer = styled.div`
        position: fixed;
        animation: ${peekabooAnimation} 2.5s 1;
        animation-timing-function: ease-in-out;
        animation-delay: 1s;
        transform-origin: top left;
        z-index: 10000;
    `;
    if (show) {
        isPeekabooAnimating = true;
        setTimeout(() => {
            setPeekabooDisplay(true);
            onClose();
        }, delay);
    }
    return (
        <>
            {
                showPeekaboo && !isPeekabooAnimating && (
                    <div className="walkthrough-peekaboo">
                        <WalkthroughContainer id="walkthrough-animation-container">
                            <img
                                alt="walkthrough funnel creation"
                                className="img-responsive"
                                src={require("../../../img/walkthrough-funnel-creation.png")}
                            />
                        </WalkthroughContainer>
                    </div>
                )
            }
        </>
    );
};

WalkthroughPeekaboo.propTypes = {
    show: PropTypes.bool,
    onClose: PropTypes.func,
    relativeElement: PropTypes.element,
    delay: PropTypes.number,
};

export default WalkthroughPeekaboo;