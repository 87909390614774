const onComponentAdd = (editor, args) => {
    const component = args[0] || {};
    if (component.attributes.type === "link-block") {
        const linkImage = component.components();

        // To open the asset dialog
        linkImage.models[0].trigger("active");
    }
};

export default {
    "component:add": onComponentAdd,
};

