import joint from "jointjs";
const CreateLink = (isStencil) => {
    const link = isStencil ? new joint.dia.Link({
        attrs: {
            ".connection": {
                d: "M 220 234 L 691 189"
            },
            ".connection-wrap": {
                d: "M 220 234 L 691 189"
            },
            ".marker-target": {
                d: "M 10 0 L 0 5 L 10 10 z",
                stroke: "red",
                fill: "red"
            },
        },
    }) :
        new joint.dia.Link({
            attrs: {
                ".marker-target": { d: "M 10 0 L 0 5 L 10 10 z" }
            }
        });
    return (
        link
    );
};
export default CreateLink;