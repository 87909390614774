import { VisualizerZoom as Zoom } from "./visualizer-zoom";
import { connect } from "react-redux";
import { commonActions, visualizerActions } from "../../../actions";
import * as visualizerSelectors from "../../../selectors/visualizer-selectors";

function mapStateToProps(state, ownProps) {
    const selectors = commonActions.getSelector(ownProps.parent);
    const importedFunnel = selectors.getImportedFunnel(state);
    const funnel = importedFunnel ? importedFunnel : selectors.getFunnelData(state);
    const visualizerData = visualizerSelectors.getVisualizerData(state);
    return {
        funnel,
        visualizerData
    };
}

function mapDispatchToProps(dispatch, ownProps) {
    //Getting the ActionCreators Based on Paren
    const actions = commonActions.getActions(ownProps.parent);
    return {
        updateVisualizerData: (params) => dispatch(visualizerActions.updateVisualizerData(params)),
        editFunnel: (params) => dispatch(actions.editFunnel(params)),
        removeCallBackEvent: (params) => dispatch(visualizerActions.removeCallBackEvent(params)),
    };
}

export const VisualizerZoom = connect(mapStateToProps, mapDispatchToProps)(Zoom);
