import { CreateFunnelTemplate as CreateFunnelTemplateView } from "./create-funnel-template";
import { connect } from "react-redux";
import * as funnelTemplatesActions from "../actions";
import * as selectors from "../selectors";
import { funnelTemplateDropDownOptions } from "../../constants/funnel-template-types";

function mapStateToProps(state) {
    const isError = selectors.getError(state);
    const isLoading = selectors.getLoader(state);
    const funnelType = funnelTemplateDropDownOptions;
    return {
        funnelType,
        isError,
        isLoading,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        createFunnelTemplate: (params) => dispatch(funnelTemplatesActions.createFunnelTemplate(params)),
        createFunnelTemplateError: (params) => dispatch(funnelTemplatesActions.createFunnelTemplateError(params)),
        toggleLoading: (params) => dispatch(funnelTemplatesActions.toggleLoading(params)),
    };
}

export const CreateFunnelTemplate = connect(mapStateToProps, mapDispatchToProps)(CreateFunnelTemplateView);