import {
    attributes,
    defaultTextAttributes
} from "./default-element-properties";
import {
    generateSVGImage,
    generateSplitImage
} from "./generate-svg-image";

const appendCurrentTimeStamp = () => "?" + Date.now();

export const getAttributes = (params) => {
    const {
        label,
        isTemplateSelected,
        templateTypeId,
        thumbnailUrl,
        isSplitEnabled,
        pageReferenceId,
        pageToPropertyMap,
        currentPage
    } = params;

    const isElementIcon = (templateTypeId === 24 || templateTypeId === 25);
    attributes.image.height = isElementIcon ? 60 : 160;
    attributes.image.width = isElementIcon ? 60 : 120;
    attributes.image.x = isElementIcon ? 30 : null;
    attributes.image.y = isElementIcon ? 30 : null;
    attributes[".borderBox"] = isElementIcon ? null : {
        height: 166,
        width: 126,
        x: -3,
        y: -3,
        rx: "6"
    };
    return {
        ...attributes,
        ".borderBox": {
            ...attributes[".borderBox"],
            stroke: isPageEdited(pageReferenceId, pageToPropertyMap, currentPage, isSplitEnabled) ? "green" : "red"
        },
        ".label": {
            ...attributes[".label"],
            text: getPropertyValueFromFunnelData(pageReferenceId, pageToPropertyMap, "label")
        },
        image: {
            ...attributes.image,
            "xlink:href": !isTemplateSelected ?
                generateSVGImage(templateTypeId) :
                isSplitEnabled ?
                    generateSplitImage() :
                    getPropertyValueFromFunnelData(pageReferenceId, pageToPropertyMap, "thumbnailUrl")
        }
    };
};

export const getPageDetails = (id) => {
    return {
        id: id,
        port: ""
    };
};

export const getTextAttributes = (content) => {
    return {
        ...defaultTextAttributes,
        input: content,
        // ...defaultTextAttributes,
        // text: {
        //     ...defaultTextAttributes.text,
        //     text: content,
        // },
    };
};

export const getSourceCoord = (sourceDetails) => {
    return sourceDetails;
};
export const getTargetCoord = (targetDetails) => {
    return targetDetails;
};

export const isPageEdited = (pageReferenceId, pageToPropertyMap, currentPage, isSplitEnabled) => {
    if (isSplitEnabled) {
        const splitPageReferenceIds = [currentPage.pageReferenceId, currentPage.splitPageAttributes.pageReferenceId];
        return pageToPropertyMap
            .filter((page) => splitPageReferenceIds.includes(page.pageReferenceId) && page.isEdited)
            .length === splitPageReferenceIds.length;
    } else {
        return pageToPropertyMap
            .filter((page) => page.pageReferenceId === pageReferenceId && page.isEdited)
            .length;
    }
};

const getCurrentPage = (pageReferenceId, pageToPropertyMap) => pageToPropertyMap
    .filter((page) => page.pageReferenceId === pageReferenceId)[0];

export const getPropertyValueFromFunnelData = (
    pageReferenceId,
    pageToPropertyMap,
    property
) => {
    return property !== "thumbnailUrl" ?
        getCurrentPage(pageReferenceId, pageToPropertyMap)[property] :
        getCurrentPage(pageReferenceId, pageToPropertyMap)[property] + appendCurrentTimeStamp();
};

export const generatePropertyMapFromFunnelPages = (funnelPages = []) => funnelPages.map((page) => {
    return {
        pageReferenceId: page.referenceId,
        isEdited: page.isEdited,
        label: page.title,
        isDefaultPage: page.pageView[0].defaultPage,
        pageViewReferenceId: page.pageView[0].referenceId,
        splitRatio: page.splitRatio,
        thumbnailUrl: page.thumbnailUrl
    };
});