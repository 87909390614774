//Staging
// export const rootUrl = process.env.REACT_APP_WEB_API_URL || "https://staging-api.funnelkonnekt.com/api/V1";
// export const providerUrl = process.env.REACT_APP_PROVIDER_API_URL || "https://staging-api.funnelkonnekt.com/providersApi/V1";
// export const authenticationUrl = process.env.REACT_APP_AUTHENTICATION_API_URL || "https://staging-api.funnelkonnekt.com/connect/token";
// export const splitViewUrl = process.env.REACT_APP_SPLITVIEW_API_URL || "https://staging-api.funnelkonnekt.com/splitViewApi/V1";
//export const webscraperUrl = process.env.REACT_APP_WEBSCRAPER_API_URL || "https://7yj30owwzh.execute-api.us-east-1.amazonaws.com/Staging/api/V1/WebScraper/GetHtml";
// export const crmUrl = process.env.REACT_APP_CRM_URL || "https://crm.funnelkonnekt.com";

//dev
export const rootUrl = process.env.REACT_APP_WEB_API_URL || "https://dev-api.funnelkonnekt.com/api/V1";
export const funnelTemplateRootUrl = process.env.REACT_APP_FUNNEL_TEMPLATE_API_URL || "https://5lx4zas9b3.execute-api.us-east-1.amazonaws.com/Development/api/V1";
export const providerUrl = process.env.REACT_APP_PROVIDER_API_URL || "https://dev-api.funnelkonnekt.com/providersApi/V1";//"http://192.168.28.191:7001";
export const authenticationUrl = process.env.REACT_APP_AUTHENTICATION_API_URL || "https://dev-api.funnelkonnekt.com/connect/token";//"http://192.168.28.191:7002/connect/token";
export const splitViewUrl = process.env.REACT_APP_SPLITVIEW_API_URL || "https://dev-api.funnelkonnekt.com/splitViewApi/V1";
export const webscraperUrl = process.env.REACT_APP_WEBSCRAPER_API_URL || "https://vsi6j5cxhb.execute-api.us-east-1.amazonaws.com/Development/api/V1/WebScraper/GetHtml";
export const webscraperFileDownloadUrl = process.env.REACT_APP_WEBSCRAPER_FILEDOWNLOAD_API_URL || "https://vsi6j5cxhb.execute-api.us-east-1.amazonaws.com/Development/api/V1/WebScraper/GetFile";
export const domainUrl = process.env.REACT_APP_CUSTOM_DOMAIN_API_URL || "https://zz4k6qhgtd.execute-api.us-east-1.amazonaws.com/Develop/api/V1/Domain";
export const importPageUrl = process.env.REACT_APP_IMPORTPAGE_API_URL || "https://roebo4u654.execute-api.us-east-1.amazonaws.com/Development/api/V1";
export const googleFontsUrl = process.env.REACT_APP_GOOGLE_FONTS_API_URL || "https://www.googleapis.com/webfonts/v1/webfonts?key=AIzaSyBpTiXpZyoPCRzw2KCX_34zoPmR3q8gKyU";
export const saveFontsUrl = process.env.REACT_APP_FONTS_API_URL || "https://qyskmlqhxf.execute-api.us-east-1.amazonaws.com/Development/api/v1/PageFont";
export const domainFunnelUrl = process.env.REACT_APP_CUSTOM_DOMAIN_FUNNEL_API_URL || "https://zz4k6qhgtd.execute-api.us-east-1.amazonaws.com/Develop/api/V1/DomainFunnel";
export const funnelTemplate = process.env.REACT_APP_FUNNEL_TEMPLATE_FUNNEL_API_URL || "https://5lx4zas9b3.execute-api.us-east-1.amazonaws.com/Development/api/V1/FunnelTemplate";
export const funnelThumbnailUrl = process.env.REACT_APP_FUNNEL_THUMBNAIL_API_URL || "https://rv24qr2go5.execute-api.us-east-1.amazonaws.com/sandbox";
export const metaJsonBaseUrl = "https://dev-funnels-build.funnelkonnekt.com";
export const buildFunnelBaseUrl = process.env.REACT_APP_BUILD_FUNNEL_URL || "https://dev-funnels-build.funnelkonnekt.com";
export const buildFunnelTemplateBaseUrl = process.env.REACT_APP_BUILD_FUNNEL_TEMPLATE_URL || "https://dev-funneltemplates.funnelkonnekt.com";
export const maximumFunnels = process.env.REACT_APP_MAXIMUM_FUNNELS || 20;
export const welcomeVideoConfigUrl = process.env.REACT_APP_WELCOME_VIDEO_CONFIG_JSON || "https://s3.amazonaws.com/config.funnelkonnekt.com/development/welcomeVideo.json";
export const walkthroughConfigUrl = process.env.REACT_APP_WALKTHROUGH_CONFIG_JSON || "https://s3.amazonaws.com/config.funnelkonnekt.com/development/walkthrough.json";
export const maximumPagesPerTenant = process.env.REACT_APP_MAXIMUM_PAGES_PER_TENANT || 200;
export const getCompanyIdAccessForTemplates = parseInt(process.env.REACT_APP_COMPANY_ID_ACCESS_FOR_TEMPLATE);
export const getCompanyIdAccessForProdTemplates = parseInt(process.env.REACT_APP_COMPANY_ID_ACCESS_FOR_PROD_TEMPLATE);
export const crmUrl = process.env.REACT_APP_CRM_URL || "https://vacrm2.konnektive.com";
export const signUpUrl = process.env.REACT_APP_SIGNUP_URL || "https://www.funnelkonnekt.com/checkout.php";
//The below constant "funnelLimitEnabled"  is used to enable the progress bar to show the total number of Pages/funnels in the dashboard.
//This variable is always populated from build environment which stores the values in text.
//So the variable value is always populated as string instead of boolean
export const funnelLimitEnabled = process.env.REACT_APP_FUNNEL_LIMIT_ENABLED === "true" || false;

export const updateVisualizerZoom = "http://192.168.28.191:9000/api/V1/VisualiserZoom/";

//export const funnelThumbnailUrl = process.env.REACT_APP_FUNNEL_THUMBNAIL_API_URL || "https://aogf70aslk.execute-api.us-east-1.amazonaws.com/dev";

//production

// export const rootUrl = process.env.REACT_APP_WEB_API_URL || "https://api.funnelkonnekt.com/api/V1";
// export const providerUrl = process.env.REACT_APP_PROVIDER_API_URL || "https://api.funnelkonnekt.com/providersApi/V1";
// export const authenticationUrl = process.env.REACT_APP_AUTHENTICATION_API_URL || "https://api.funnelkonnekt.com/connect/token";
// export const splitViewUrl = process.env.REACT_APP_SPLITVIEW_API_URL || "https://api.funnelkonnekt.com/splitViewApi/V1";
// export const webscraperUrl = process.env.REACT_APP_WEBSCRAPER_API_URL || "https://vsi6j5cxhb.execute-api.us-east-1.amazonaws.com/Development/api/V1/WebScraper/GetHtml";
// export const domainUrl = process.env.REACT_APP_CUSTOM_DOMAIN_API_URL || "https://6d0wyq1sdh.execute-api.us-east-1.amazonaws.com/Sandbox/api/V1/Domain";
// export const importPageUrl = process.env.REACT_APP_IMPORTPAGE_API_URL || "https://roebo4u654.execute-api.us-east-1.amazonaws.com/Development/api/V1";
// export const googleFontsUrl = process.env.REACT_APP_GOOGLE_FONTS_API_URL || "https://www.googleapis.com/webfonts/v1/webfonts?key=AIzaSyBpTiXpZyoPCRzw2KCX_34zoPmR3q8gKyU";
// export const saveFontsUrl = process.env.REACT_APP_FONTS_API_URL || "https://qyskmlqhxf.execute-api.us-east-1.amazonaws.com/Development/api/v1/PageFont";
// export const domainFunnelUrl = process.env.REACT_APP_CUSTOM_DOMAIN_FUNNEL_API_URL || "https://6d0wyq1sdh.execute-api.us-east-1.amazonaws.com/Sandbox/api/V1/DomainFunnel";

// export const metaJsonBaseUrl = "https://dev-funnels-build.funnelkonnekt.com";
// export const buildFunnelBaseUrl = process.env.REACT_APP_BUILD_FUNNEL_URL || "https://dev-funnels-build.funnelkonnekt.com";
// export const crmUrl = process.env.REACT_APP_CRM_URL || "https://crm.funnelkonnekt.com";

