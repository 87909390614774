import React from "react";
import PropTypes from "prop-types";
import { funnelListConstants } from "../constants";
import { funnelTypeError } from "../../commons";
import { formatPriceUptoTwoDecimal } from "../../utilities/formatters";
import { getTemplateConfig } from "../../constants/funnel-template-types";
import isFunction from "lodash/isFunction";

const {
    deleteFunnel,
    chooseFunnel,
    DEV,
} = funnelListConstants;

export const FunnelThumbnailCard = (props) => {
    const {
        thumbnailUrl,
        onFunnelItemSelected,
        funnel,
        onFunnelItemDelete,
        showFunnelType,
        showPrice,
        showDelete = true,
        showPreview
    } = props;
    const {
        referenceId,
        title,
        userName,
        price,
        fListFunnelTypeId: funnelTypeId,
        isPurchased
    } = funnel;
    const funnelType = showFunnelType && (funnelTypeId && getTemplateConfig(funnelTypeId));
    const isDev = (process.env.NODE_ENV === DEV) || false;
    const showDeleteMode = isDev && (typeof isFunction(onFunnelItemDelete)) && showDelete;
    const funnelTemplatePrice = !!isPurchased && isPurchased ? "Purchased" : price ? formatPriceUptoTwoDecimal(price) : "Free";
    return (
        <div className="funnelThumbnail import-funnel-card" >
            {
                funnelType && (
                    <div className="funnel-template-type">
                        {funnelType.label}
                    </div>
                )
            }
            <div
                className="thumbnail-template-img"
                style={{ backgroundImage: `url(${thumbnailUrl ? thumbnailUrl : ""})` }}
            >
                <a
                    className="choose"
                    onClick={() => onFunnelItemSelected(funnel)}
                >
                    <div className="choose-hand" />
                    <div>{chooseFunnel}</div>
                </a>
                {showDeleteMode && (
                    <a className="delete" onClick={() => onFunnelItemDelete(referenceId)}>
                        <div>{deleteFunnel}</div>
                    </a>
                )}
            </div>
            <div className="thumbnail-content">
                <div className="parent-content">
                    <div className="width-100">
                        <div className="font-16">
                            <div className="thumbnail-name abbrtitle" title={title}>{title}</div>
                            {showPrice && (
                                <div className="thumbnail-price">
                                    {
                                        funnelTemplatePrice
                                    }
                                </div>
                            )}
                        </div>
                        <div>
                            {
                                userName && (
                                    <div> {userName} </div>
                                )
                            }
                        </div>
                    </div>
                    <div>
                        <a className="preview-template" onClick={() => showPreview(funnel)}><i className="fa fa-eye"></i></a>
                    </div>
                </div>
            </div>
        </div>
    );
};

FunnelThumbnailCard.propTypes = {
    thumbnailUrl: PropTypes.string,
    onFunnelItemSelected: PropTypes.func,
    funnel: PropTypes.object,
    onFunnelItemDelete: PropTypes.func,
    showFunnelType: PropTypes.bool,
    showPrice: PropTypes.bool,
    purchasedTemplates: PropTypes.array,
    showDelete: PropTypes.bool,
    showPreview: PropTypes.func
};

FunnelThumbnailCard.defaultProps = {
    showFunnelType: true,
    showPreview: (funnel) => {
        const route = !funnel.isFunnelTemplate ? "/screenshot/" : "/screenshot-template/";
        const win = window.open(window.location.origin + route + funnel.referenceId, "_blank");
        win.focus();
    },
};