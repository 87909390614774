import { DesignerDetails as DesignerDetailsView } from "./designer-details";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import * as designersActions from "../actions";
import { loadDesigners } from "../actions";

function mapStateToProps(state, ownProps) {
    const designerId = ownProps.match.params.id;
    const designer = state.DESIGNERS.filter(d => {
        //eslint-disable-next-line
        return d.name == designerId;
    });
    return {
        designer: designer[0]
    };
}

function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators(designersActions, dispatch),
        loadDesigners: () => dispatch(loadDesigners())
    };
}

export const DesignerDetails = connect(mapStateToProps, mapDispatchToProps)(DesignerDetailsView);