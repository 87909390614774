import React from "react";
import { Progress, Navigation, TopHeader, Footer } from "./../commons";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as actions from "../login/actions";
import { getUserData, getSettingsData, getCompanyData, getCompanyError, getDefaultCompany, checkIfAdmin, checkIfUserCanAccessTemplates } from "../login/selectors";
import { setWelcomeModalOnMenuClick } from "../core/app-settings/actions";
import { getWelcomeVideoUrlFetchStatus } from "../core/app-settings/selectors";

const AppLayout = ({
    children,
    location,
    history,
    logout,
    userData,
    isAdminUser,
    settingsData,
    companyData,
    defaultCompany,
    setCompany,
    companyError,
    canUserAccessTemplates,
    setWelcomeModalOnMenuClick,
    isWelcomeVideoUrlFetchSuccess,
}) => {
    let wrapperClass = "gray-bg " + location.pathname;
    const userLogout = (e) => {
        e.preventDefault();
        logout();
        localStorage.clear();
        history.push("/login");
    };
    const changeCompany = (newCompany) => {
        setCompany(newCompany, location.pathname);
    };
    // The screenshot url  is not to be verified, since in server side they directly hit screenshot url
    const screenshot = (window.location.href.indexOf("screenshot") !== -1);
    return (
        <div id="wrapper" className={wrapperClass}>
            <Progress />
            {/*<Navigation userData={userData} settingsData={settingsData} location={location}/>*/}
            <div id="page-wrapper" className={wrapperClass}>
                {!screenshot && (
                    <TopHeader
                        userData={userData}
                        isAdminUser={isAdminUser}
                        canUserAccessTemplates = {canUserAccessTemplates}
                        settingsData={settingsData}
                        location={location}
                        logout = {userLogout}
                        companyData= {companyData}
                        defaultCompany= {defaultCompany}
                        setCompany= {changeCompany}
                        companyError= {companyError}
                        isWelcomeVideoUrlFetchSuccess={isWelcomeVideoUrlFetchSuccess}
                        setWelcomeModalOnMenuClick={setWelcomeModalOnMenuClick}
                    />
                )}
                {children}
            </div>
            {!screenshot && <Footer />}
        </div>
    );
};

AppLayout.propTypes = {
    children: PropTypes.object.isRequired,
    location: PropTypes.object,
    history: PropTypes.object,
    logout: PropTypes.func,
    userData: PropTypes.object,
    settingsData: PropTypes.object,
    companyData: PropTypes.object,
    defaultCompany: PropTypes.object,
    setCompany: PropTypes.func,
    companyError: PropTypes.string,
    isAdminUser: PropTypes.bool,
    setWelcomeModalOnMenuClick: PropTypes.func,
    isWelcomeVideoUrlFetchSuccess: PropTypes.bool,
    canUserAccessTemplates: PropTypes.bool
};
function mapStateToProps(state) {
    return {
        userData: getUserData(state),
        settingsData: getSettingsData(state),
        companyData: getCompanyData(state),
        defaultCompany: getDefaultCompany(state),
        companyError: getCompanyError(state),
        isAdminUser: checkIfAdmin(state),
        isWelcomeVideoUrlFetchSuccess: getWelcomeVideoUrlFetchStatus(state),
        canUserAccessTemplates: checkIfUserCanAccessTemplates(state)
    };
}
function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators(actions, dispatch),
        logout: () => dispatch(actions.logout()),
        loadCompany: () => dispatch(actions.loadCompany()),
        setCompany: (company, location) => dispatch(actions.setCompany(company, location)),
        setWelcomeModalOnMenuClick: (status) => dispatch(setWelcomeModalOnMenuClick(status)),
    };
}

export const AppLayoutView = connect(mapStateToProps, mapDispatchToProps)(AppLayout);
