import React from "react";
import PropTypes from "prop-types";
import { NavLink } from "react-router-dom";
import { connect } from "react-redux";

import WalkthroughSlider from "./walkthrough-slider";
import { toggleCRMEntryModal } from "../../../core/app-settings/actions";

const ChooseGoal = (props) => {
    const isCreateFromMarketPlace = true;
    const {
        items,
        onTileClick,
        onCloseModal,
        goToCreateFunnelPage,
        toggleCRMEntryModal
    } = props;
    const onBrowserFunnelClick = (e) => {
        e.preventDefault();
        goToCreateFunnelPage("", isCreateFromMarketPlace);
    };
    return (
        <div className="walkthrough-body">
            <WalkthroughSlider
                items={items}
                onTileClick={onTileClick}
            />
            <div className="walkthrough-footer">
                <NavLink
                    // to="/funnel"
                    className="btn btn-default btn-sm"
                    onClick={() => {onCloseModal(); toggleCRMEntryModal(true);}}
                    // onClick={() => onCloseModal()}
                >
                    Create a Custom Funnel
                </NavLink>
                <a
                    href="#"
                    // onClick={() => {onCloseModal(); toggleCRMEntryModal(true);}}
                    onClick={onBrowserFunnelClick}
                >
                    Browse Funnel Marketplace
                </a>
            </div>
        </div>
    );
};
ChooseGoal.propTypes = {
    items: PropTypes.array,
    onTileClick: PropTypes.func,
    onCloseModal: PropTypes.func,
    goToCreateFunnelPage: PropTypes.func,
    toggleCRMEntryModal: PropTypes.func
};

function mapDispatchToProps(dispatch) {
    return {
        toggleCRMEntryModal: (status) => dispatch(toggleCRMEntryModal(status)),
    };
}

export default connect(null,mapDispatchToProps)(ChooseGoal);