import { APIHelper } from "../utilities";
import { providerUrl, rootUrl } from "./urls";

const getPurchaseOrderDefaults = (templateType) => {
    return {
        product1_id: (templateType === "page") ? 88 : 87,
        templateType: (templateType === "page") ? "Page" : "Funnel",
        paySource: "ACCTONFILE",
        customerId: 155,
    };
};

export const purchaseOrderApi = ({
    id,
    templateId,
    tenantId,
    templateType,
    price,
    customerId,
    campaignId,
    affId,
}) => {
    const requestBody = {
        id,
        templateId,
        tenantId,
        customerId,
        campaignId,
        affId,
        product1_price: price,
        ...getPurchaseOrderDefaults(templateType),
    };
    return APIHelper.baseRequest(APIHelper.POST, `${providerUrl}/Import/DesignerOrder`, {
        requestBody,
        headers: APIHelper.getGenericHeaders()
    });
};

export const getPurchasedTemplatesForTenant = () => {
    return APIHelper.baseRequest(APIHelper.GET, `${rootUrl}/PurchasedTemplate/GetTemplatesForTenant` , {
        headers: APIHelper.getGenericHeaders(),
    });
};