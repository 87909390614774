import React from "react";
import PropTypes from "prop-types";
import { NavLink } from "react-router-dom";

export const TemplateTabs = (props) => {
    const {
        history,
        children,
    } = props;
    const { pathname = "" } = history.location;
    const getActiveClass = (tab) => {
        return (pathname === tab) ? "active" : "";
    };
    return (
        <div className="container m-t-lg" >
            <ul className="nav nav-tabs">
                <li role="presentation" className={getActiveClass("/page-templates")}>
                    <NavLink to="/page-templates">
                        Page Templates
                    </NavLink>
                </li>
                <li role="presentation" className={getActiveClass("/funnel-templates")}>
                    <NavLink to="/funnel-templates">
                        Funnel Templates
                    </NavLink>
                </li>
            </ul>
            {
                children
            }
        </div>
    );
};

TemplateTabs.propTypes = {
    history: PropTypes.object,
    children: PropTypes.func,
};
