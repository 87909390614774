import React from "react";
import {
    bool,
    array,
} from "prop-types";

const LiveSite = ({
    isEnabled,
    domainList
}) => {
    const liveSiteClassNames = isEnabled ? "" : "disabled disable-pointer";
    const appendHttps = "https://";
    const selectedDomainDetails = domainList.find((list) => list.selected);
    const selectedDomainName = selectedDomainDetails && selectedDomainDetails.name;
    const liveSiteUrl = selectedDomainName && appendHttps.concat(selectedDomainName);
    return (
        <button className={`btn btn-primary m-l-xs pull-right ${liveSiteClassNames}`}>
            <a className="text-white" href={liveSiteUrl} target="_blank">
                <i className="fa fa-globe" aria-hidden="true"/>  Live site
            </a>
        </button>
    );
};

LiveSite.propTypes = {
    isEnabled: bool,
    domainList: array
};

LiveSite.defaultProps = {
    isDisabled: true,
    domainList: []
};

export default LiveSite;