import React, { useState, useEffect, useContext } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import { TemplateCard } from "../../templates/template-card";
import ChooseTemplates from "./components/ChooseTemplates/choose-templates";

import { getTemplates, getPurchasedTemplates, purchaseOrder } from "../../templates/actions";
import { VisualizerContext } from "./";

const generateTemplateSelectionConfig = ({
    canAccessPremiumTemplates,
    templates,
    loadTemplates,
    userTemplates,
    premiumTemplates,
    onTemplateSelect,
    selectedPageId,
}) => {
    let templateSelectionConfig = [
        {
            name: "Free Pages",
            thumbnails: () => templates,
            thumbnailComponent: TemplateCard,
            fetchData: loadTemplates,
            onThumbnailSelected: onTemplateSelect,
        },
    ];
    if (canAccessPremiumTemplates) {
        templateSelectionConfig = [
            {
                name: "My Pages",
                thumbnails: () => userTemplates,
                thumbnailComponent: TemplateCard,
                fetchData: () => loadTemplates("userTemplates"),
                onThumbnailSelected: onTemplateSelect
            },
            ...templateSelectionConfig,
            {
                name: "Premium Pages",
                thumbnails: () => premiumTemplates,
                onBeforeShow: getPurchasedTemplates,
                thumbnailComponent: TemplateCard,
                showPrice: true,
                purchaseOrderApi: purchaseOrder,
                fetchData: () => loadTemplates("premiumTemplates"),
                selectedPageId,
                onThumbnailSelected: onTemplateSelect,
            }
        ];
    }
    return templateSelectionConfig;
};

const ChooseTemplatesContainer = ({
    onTemplateSelect,
    onClose,
    templateTypeId,
    templates,
    userTemplates,
    premiumTemplates,
    canAccessPremiumTemplates,
    loadTemplates,
    userData,
    selectedPageId,
}) => {
    const VisualizerContextData = useContext(VisualizerContext);
    const [templateSelectionConfig, setTemplateSelectionConfig] = useState([]);
    const {
        funnelData,
    } = VisualizerContextData;
    useEffect(() => {
        setTemplateSelectionConfig(generateTemplateSelectionConfig({
            canAccessPremiumTemplates,
            templates,
            onTemplateSelect,
            loadTemplates,
            userTemplates,
            selectedPageId,
            premiumTemplates,
        }));
    }, [templates, userTemplates, premiumTemplates]);
    return (
        <ChooseTemplates
            onClose={onClose}
            templateSelectionConfig={templateSelectionConfig}
            templateTypeIdToFilter={templateTypeId}
            userData={userData}
            funnel={funnelData}
            selectedPageId={selectedPageId}
        />
    );
};

ChooseTemplatesContainer.propTypes = {
    onTemplateSelect: PropTypes.func,
    onClose: PropTypes.func,
    templateTypeId: PropTypes.number,
    templates: PropTypes.array,
    userTemplates: PropTypes.array,
    premiumTemplates: PropTypes.array,
    canAccessPremiumTemplates: PropTypes.bool,
    loadTemplates: PropTypes.func,
    userData: PropTypes.object,
    selectedPageId: PropTypes.string,
};

const mapStateToProps = (state) => {
    const userData = state.LOGIN.userData;
    // const isAdminUser = state.LOGIN.isAdminUser;
    return {
        templates: state.TEMPLATES.templates,
        userTemplates: state.TEMPLATES.userTemplates,
        premiumTemplates: state.TEMPLATES.premiumTemplates,
        userData: state.LOGIN.userData,
        canAccessPremiumTemplates: (!userData.isDesignerUser),
    };
};

const mapDispatchToProps = (dispatch) => ({
    loadTemplates: (templateType) => dispatch(getTemplates({ templateType })),
});

export default connect(mapStateToProps, mapDispatchToProps)(ChooseTemplatesContainer);