import React from "react";
import PropTypes from "prop-types";
import { Modal } from "react-bootstrap";

const DeleteDomainModal = ({ showDeleteDomainModal, closeDeleteDomainModal, deleteDomainConfirm,
    confirmationTitle, sureToDeleteLabel, yesLabel, noLabel }) => {
    return (
        <Modal show={showDeleteDomainModal} backdrop = "static" onHide={closeDeleteDomainModal} bsSize="small" aria-labelledby="contained-modal-title-lg">
            <Modal.Header >
                <Modal.Title><h3 className="text-white">{confirmationTitle}</h3></Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <h3 className="text-center">{sureToDeleteLabel}</h3>
            </Modal.Body>
            <Modal.Footer>
                <a className="btn btn-primary" onClick = {deleteDomainConfirm}>{yesLabel}</a>
                <a className="btn btn-default" onClick = {closeDeleteDomainModal}>{noLabel}</a>
            </Modal.Footer>
        </Modal>
    );
};

DeleteDomainModal.propTypes = {
    showDeleteDomainModal: PropTypes.bool,
    closeDeleteDomainModal: PropTypes.func,
    deleteDomainConfirm: PropTypes.func,
    confirmationTitle: PropTypes.string,
    sureToDeleteLabel: PropTypes.string,
    yesLabel: PropTypes.string,
    noLabel: PropTypes.string
};

export default DeleteDomainModal;