import React, { Component } from "react";
import PropTypes from "prop-types";
import {
    Modal,
    Tabs,
} from "react-bootstrap";
import isEmpty from "lodash/isEmpty";
import TabBar from "./TabBar";
import PurchaseConfirmation from "../../../../../commons/modals/purchase-confirmation-modal";
import { NotificationEmitter, NOTIFICATION_EVENT_NAME } from "../../../../../commons/notification";
import { getPurchasedTemplates } from "../../../actions/visualizerActions";


export default class ImportDefaultFunnelsModal extends Component {
    constructor(props) {
        super(props);
        this.onFunnelItemSelected = this.onFunnelItemSelected.bind(this);
        this.onDropdownChange = this.onDropdownChange.bind(this);
        this.getFunnelsToDisplay = this.getFunnelsToDisplay.bind(this);
        this.state = {
            defaultFunnelTemplates: props.defaultFunnelTemplates,
            funnelTemplateType: {},
            premiumTemplateType: {},
            isLoadingDefaultFunnelTemplates: false,
            funnelToPurchase: {},
            funnelPrice: false,
            isLoadingTemplates: false,
            templateType: ""
        };
        this.initialTab = 2;
        this.purchaseFunnel = this.purchaseFunnel.bind(this);
        this.onTemplateSelect = this.onTemplateSelect.bind(this);
        this.onPremiumDropdownChange = this.onPremiumDropdownChange.bind(this);
        this.tabBarClick = this.tabBarClick.bind(this);
        this.generateTabList = this.generateTabList.bind(this);
        this.tabList = this.generateTabList(props.funnels, props.userData);
        if (props.openFunnelTemplatesOnLoad && !isEmpty(this.tabList)) {
            const templateFunnelTab = this.tabList.find(tab => tab.name === "templateFunnels");
            this.initialTab = (templateFunnelTab) ? templateFunnelTab.eventKey : 2;
        }
        this.noDataIndicator = this.noDataIndicator.bind(this);
    }

    componentDidMount() {
        this.tabBarClick(this.initialTab, this.generateTabList());
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        if (nextProps.defaultFunnelTemplates !== prevState.defaultFunnelTemplates) {
            return {
                defaultFunnelTemplates: nextProps.defaultFunnelTemplates,
            };
        }
    }

    noDataIndicator = () => (
        <div className="col-xs-12 alert alert-danger">
            {`There are no ${this.state.templateType} Funnel templates available`}
        </div>
    );

    generateTabList(funnels = this.props.funnels, userData = this.props.userData) {
        const { isDesignerUser } = userData;
        const filteredDefaultFunnels = this.getFilteredDefaultFunnels();
        const funnelsToDisplay = this.getFunnelsToDisplay(funnels);
        const filterdPremiumFunnelTemplates = this.getFilteredPremiumFunnels();
        const templateFunnels = {
            eventKey: 1,
            title: "Template Funnels",
            funnels: filteredDefaultFunnels,
            showFunnelType: true,
            displayDropDown: true,
            showPrice: false,
            onDropdownChange: this.onDropdownChange,
            name: "templateFunnels",
        };
        let tabList = [];
        if (!isDesignerUser) {
            templateFunnels.eventKey = 2;
            tabList = [
                {
                    eventKey: 1,
                    title: "My Funnels",
                    funnels: funnelsToDisplay,
                    showFunnelType: false,
                    showPrice: false,
                    displayDropDown: false,
                    onDropdownChange: this.onDropdownChange,
                    name: "myFunnels",
                },
                templateFunnels,
                {
                    eventKey: 3,
                    title: "Premium Funnels",
                    funnels: filterdPremiumFunnelTemplates,
                    showFunnelType: true,
                    displayDropDown: true,
                    showPrice: true,
                    name: "premiumFunnels",
                    onDropdownChange: this.onPremiumDropdownChange
                }
            ];
        } else {
            tabList = [templateFunnels];
        }
        return tabList;
    }

    setLoadingTemplates(isLoadingTemplates) {
        this.setState({
            isLoadingTemplates,
        });
    }
    getFunnelsToDisplay(funnels = []) {
        const {
            currentFunnelId,
        } = this.props;
        return funnels.filter(funnel => funnel.referenceId !== currentFunnelId);
    }
    onTemplateSelect() {
        const { funnelToPurchase } = this.state;
        const isFunnelTemplate = !!funnelToPurchase.isFunnelTemplate;
        const isPremium = !!funnelToPurchase.premium || !!funnelToPurchase.designer;
        const referenceId = funnelToPurchase.referenceId;
        const {
            onFunnelImport,
            handleClose,
        } = this.props;
        onFunnelImport({
            importId: referenceId,
            isFunnelTemplate,
            isPremium
        });
        this.setState({ funnelToPurchase: {} , funnelPrice: false });
        handleClose();
    }

    onFunnelItemSelected(funnel = {}) {
        this.setState({ funnelToPurchase: funnel },() => {
            if (funnel.price && !funnel.isPurchased) {
                this.setState({ funnelPrice: funnel.price });
            } else {
                this.onTemplateSelect();
            }
        });
    }

    purchaseFunnel() {
        const {
            funnelToPurchase,
        } = this.state;
        const {
            userData,
            funnel,
        } = this.props;
        const purchaseParams = {
            id: funnel.referenceId,
            templateId: funnelToPurchase.referenceId,
            templateType: "funnel",
            price: funnelToPurchase.price,
            campaignId: funnel.campaign,
            tenantId: userData.fTenantId,
            customerId: userData.customerId,
            affId: userData.affiliateId,
        };
        this.props.purchaseOrder(purchaseParams)
            .then(() => {
                this.onTemplateSelect();
            }).catch((error) => {
                NotificationEmitter.emit(NOTIFICATION_EVENT_NAME, {
                    type: "error",
                    message: error.message,
                    closeButton: true
                });
            });
    }

    filterFunnelTemplates(templates,templateType) {
        if (templateType && (!templateType.templateTypeId)) {
            return [...templates];
        } else {
            return templates.filter(function (element) {
                return element.fListFunnelTypeId === templateType.templateTypeId;
            });
        }
    }

    getFilteredDefaultFunnels() {
        const {
            defaultFunnelTemplates,
            funnelTemplateType
        } = this.state;
        const funnelTemplates = defaultFunnelTemplates || [];
        return this.filterFunnelTemplates(funnelTemplates,funnelTemplateType);
    }
    getFilteredPremiumFunnels() {
        const {
            premiumTemplateType
        } = this.state;
        const { premiumFunnelTemplates } = this.props;
        const funnelTemplates = premiumFunnelTemplates || [];
        return this.filterFunnelTemplates(funnelTemplates,premiumTemplateType);
    }

    onDropdownChange(funnelTemplateType) {
        this.setState({
            funnelTemplateType,templateType: funnelTemplateType.label
        });
    }
    onPremiumDropdownChange(premiumTemplateType) {
        this.setState({
            premiumTemplateType,templateType: premiumTemplateType.label
        });
    }
    tabBarClick(key, tabList) {
        const selectedTab = tabList[key - 1];
        const { name: tabName } = selectedTab;
        this.setLoadingTemplates(true);
        if (tabName === "myFunnels") {
            this.props.loadFunnels().then(() => {
                this.setLoadingTemplates(false);
            }).catch((error) => {
                this.setLoadingTemplates(false);
                NotificationEmitter.emit(NOTIFICATION_EVENT_NAME, { type: "error", message: error });
            });
        } else if (tabName === "templateFunnels") {
            this.props.getDefaultFunnels().then(() => {
                this.setLoadingTemplates(false);
            }).catch((error) => {
                this.setLoadingTemplates(false);
                NotificationEmitter.emit(NOTIFICATION_EVENT_NAME, { type: "error", message: error });
            });
        } else if (tabName === "premiumFunnels") {
            getPurchasedTemplates().then((responce) => {
                this.props.getPremiumFunnelTemplates(responce.result).then(() => {
                    this.setLoadingTemplates(false);
                }).catch((error) => {
                    this.setLoadingTemplates(false);
                    NotificationEmitter.emit(NOTIFICATION_EVENT_NAME, { type: "error", message: error });
                });
            }).catch((error) => {
                this.setLoadingTemplates(false);
                NotificationEmitter.emit(NOTIFICATION_EVENT_NAME, { type: "error", message: error });
            });
        }
    }

    render() {
        const {
            handleClose,
            show,
            funnelTemplateTypeIdToPrepopulate,
        } = this.props;
        const {
            funnelToPurchase,
            funnelPrice,
            isLoadingTemplates
        } = this.state;
        const tabList = this.generateTabList();
        return (
            <>
                <Modal
                    show={show}
                    backdrop="static"
                    onHide={() => handleClose(true)}
                    bsSize="lg"
                    className="existing-funnels-modal"
                    aria-labelledby="contained-modal-title-lg"
                >
                    <Modal.Header closeButton>
                        <Modal.Title><h4 className="text-white">Select A Funnel Template</h4></Modal.Title>
                    </Modal.Header>
                    <Modal.Body className="m-n">
                        <Tabs defaultActiveKey={this.initialTab} id="funnel-switch-tab" onSelect={(key) => this.tabBarClick(key, tabList)}>
                            {
                                tabList.map((tab, key) => {
                                    return (<TabBar key={key} {...tab}
                                        noDataIndicator={this.noDataIndicator}
                                        onFunnelItemSelected={this.onFunnelItemSelected}
                                        isLoadingTemplates={isLoadingTemplates}
                                        initialTemplateTypeId={funnelTemplateTypeIdToPrepopulate}
                                    />);
                                })
                            }
                        </Tabs>
                    </Modal.Body>
                </Modal>
                {
                    funnelPrice && (
                        <PurchaseConfirmation
                            onClose={() => this.setState({ funnelToPurchase: null,funnelPrice: false })}
                            onPurchase={this.purchaseFunnel}
                            price={funnelToPurchase.price}
                            type={"Funnel"}
                        />
                    )
                }
            </>
        );
    }
}

ImportDefaultFunnelsModal.propTypes = {
    handleClose: PropTypes.func,
    show: PropTypes.bool,
    funnels: PropTypes.array,
    funnelTemplates: PropTypes.array,
    defaultFunnelTemplate: PropTypes.func,
    onFunnelImport: PropTypes.func,
    getDefaultFunnels: PropTypes.func,
    defaultFunnelTemplates: PropTypes.array,
    currentFunnelId: PropTypes.string,
    premiumFunnelTemplates: PropTypes.array,
    getPremiumFunnelTemplates: PropTypes.func,
    funnelTemplateTypeIdToPrepopulate: PropTypes.string,
    purchaseOrder: PropTypes.func,
    userData: PropTypes.object,
    funnel: PropTypes.object,
    loadFunnels: PropTypes.func,
    loadTemplates: PropTypes.func,
    openFunnelTemplatesOnLoad: PropTypes.bool,
};

ImportDefaultFunnelsModal.defaultProps = {
    show: false,
    defaultFunnelTemplates: [],
    initialTab: 1,
};