import { getPageViews, getSalesSummary } from "../api/dashboardApi";
import api from "../api/adertisementApi";
import { LocalStorageHelper } from "../utilities";

export const LOAD_PAGEVIEWS_SUCCESS = "LOAD_PAGEVIEWS_SUCCESS";
export const LOAD_MONTHLYORDERS_SUCCESS = "LOAD_MONTHLYORDERS_SUCCESS";
export const LOAD_MONTHLYORDERSBYCOUNTRY_SUCCESS = "LOAD_MONTHLYORDERSBYCOUNTRY_SUCCESS";
export const LOAD_SALESSUMMARY_SUCCESS = "LOAD_SALESSUMMARY_SUCCESS";
export const LOAD_CAMPAIGNS_SUCCESS = "LOAD_CAMPAIGNS_SUCCESS";
export const LOAD_ADS_SUCCESS = "LOAD_ADS_SUCCESS";

export function loadPageViewsSuccess(pageViews) {
    return { type: LOAD_PAGEVIEWS_SUCCESS, pageViews };
}

export function loadAdsSuccess(ads) {
    return { type: LOAD_ADS_SUCCESS, ads };
}

export function loadMonthlyOrdersSuccess(orders) {
    return { type: LOAD_MONTHLYORDERS_SUCCESS, orders };
}

export function loadMonthlyOrdersByCountrySuccess(countries) {
    return { type: LOAD_MONTHLYORDERSBYCOUNTRY_SUCCESS, countries };
}


export function loadSalesSummarySuccess(sales) {
    return { type: LOAD_SALESSUMMARY_SUCCESS, sales };
}

export function pageViews() {
    return function (dispatch) {
        const localstorageManager = new LocalStorageHelper();
        const accessToken = localstorageManager.load(LocalStorageHelper.keys.ACCESS_TOKEN);
        if (accessToken) {
            return getPageViews().then(views => {
                views = views.message.data;
                dispatch(loadPageViewsSuccess(views));
            }).catch(error => {
                throw (error);
            });
        }
    };
}

export function salesSummary() {
    return function (dispatch) {
        const localstorageManager = new LocalStorageHelper();
        const accessToken = localstorageManager.load(LocalStorageHelper.keys.ACCESS_TOKEN);
        if (accessToken) {
            return getSalesSummary().then((sales) => {
                sales = sales.message;
                dispatch(loadSalesSummarySuccess(sales));
            }).catch((error) => {
                throw error;
            });
        }
    };
}

export function getAds() {
    return function (dispatch) {
        return api.getAlladvertisements().then(ads => {
            dispatch(loadAdsSuccess(ads));
        }).catch(error => {
            throw error;
        });
    };
}