import { Login as LoginPage } from "./login";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import * as actions from "../login/actions";
import { getUserData, getSettingsData, getAccountData } from "./selectors";

const mapStateToProps = (state) => {
    return {
        isLoading: state.LOGIN.isLoading,
        isAuthentcated: state.LOGIN.isAuthentcated,
        isAccountError: getAccountData(state),
        isLoginError: state.LOGIN.isLoginError,
        userData: getUserData(state),
        settingsData: getSettingsData(state),
        userSetting: getSettingsData(state),
        maintenanceData: state.LOGIN.maintenanceData
    };
};

function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators(actions, dispatch),
        login: (userName,passWord) => dispatch(actions.login(userName,passWord))
    };
}
export const Login = connect(mapStateToProps, mapDispatchToProps)(LoginPage);