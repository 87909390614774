export const getUserData = (state) => {
    return state.LOGIN.userData;
};
export const getSettingsData = (state) => {
    return state.LOGIN.settingsData;
};
export const getUserSetting = (state) => {
    return state.LOGIN.userSetting;
};

export const getAuthenticated = (state) => {
    return state.LOGIN.isAuthentcated;
};

export const getAccountData = (state) => {
    return state.LOGIN.isAccountError;
};

export const getMaintenanceData = (state) => {
    return state.LOGIN.maintenanceData;
};
export const getCompanyData = (state) => {
    return state.LOGIN.companyDetails;
};
export const getCompanyError = (state) => {
    return state.LOGIN.companyDetailsError;
};
export const getDefaultCompany = (state) => {
    return state.LOGIN.companyDetails && state.LOGIN.companyDetails
        .filter((company) => company.selected)
        .map((option) => ({ "value": option.referenceId, "label": option.name,"isSelected": option.selected,"isDefault": option.userDefault }));
};
export const checkIfAdmin = (state) => {
    return state.LOGIN.isAdminUser;
};
export const checkIfUserCanAccessTemplates = (state) => {
    return state.LOGIN.canUserAccessTemplates;
};
export const getLatestAddedDomain = (state) => {
    return state.LOGIN.getLatestAddedDomain;
};
export const isLoadingDomain = (state) => {
    return state.LOGIN.isLoadingDomain;
};