import React, { forwardRef, useEffect, useState } from "react";
import PropTypes from "prop-types";
import Paper from "../JointjsConfig/paper";
import Graph from "../JointjsConfig/graph";
import { generateVisualizerModel } from "../../utilities/generateModel/generate-visualizer-model";

const VisualizerFrame = forwardRef(({
    elementId,
    components,
    onVisualizerFrameLoad,
    funnelPages,
    visualizerCleanUp,
    children
}, ref) => {
    const [elementData, setElementData] = useState(null);
    const loadInitialComponents = (
        components = [],
        funnelPages = []
    ) => generateVisualizerModel(components, funnelPages);

    const visualizerFrameInitialize = () => {
        const canvasGraph = Graph();
        const StencilHeight = 2000;
        const StencilWidth = 2000;
        const canvasPaper = Paper(ref.current, canvasGraph, StencilWidth, StencilHeight, 20, true);
        components && canvasGraph.fromJSON(loadInitialComponents(components, funnelPages));
        setElementData(canvasGraph.toJSON()["cells"]);

        onVisualizerFrameLoad({
            canvasPaper,
            canvasGraph
        });
    };
    useEffect(() => {
        visualizerFrameInitialize();
        return () => {
            visualizerCleanUp();
        };
    }, []);
    return (
        <div className="visualizer-frame" id="visualizer-frame">
            <div className="canvas-container" id="canvas-container">
                <div
                    ref={ref}
                    id={elementId}
                    className="visualizer-graph"
                ></div>
                {children}
            </div>
        </div>
    );
});

VisualizerFrame.propTypes = {
    elementId: PropTypes.string,
    components: PropTypes.array,
    onVisualizerFrameLoad: PropTypes.func,
    funnelPages: PropTypes.array,
    visualizerCleanUp: PropTypes.func,
    children: PropTypes.element
};

export default VisualizerFrame;