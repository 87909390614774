import React, { Component } from "react";
import PropTypes from "prop-types";
import { TemplateCard } from "../../../../templates/template-card";

export default class SplitTest extends Component {
    static propTypes = {
        templateList: PropTypes.array,
        duplicatePage: PropTypes.func,
        onSplitTemplateSelected: PropTypes.func,
    }
    constructor(props) {
        super(props);
        this.state = {
            showSplitOptions: false,
            showTemplates: false
        };
    }
    render() {
        const {
            onSplitTemplateSelected,
            duplicatePage,
            templateList,
        } = this.props;
        const {
            showSplitOptions,
            showTemplates
        } = this.state;
        const openSplitOptions = () => {
            this.setState({
                showSplitOptions: true,
                showTemplates: false,
            });
        };
        const closeSplitOption = () => {
            this.setState({
                showSplitOptions: false,
                showTemplates: false,
            });
        };
        const showTemplatesList = () => {
            this.setState({
                showTemplates: true,
            });
        };
        return (
            <div className= "split-section">
                <h3>Split Test</h3>
                <button
                    onClick = {openSplitOptions}
                    className="btn btn-primary btn-block"
                >
                    Create split variation
                </button>
                <div className={`optionsList ${showSplitOptions ? "active" : ""}`}>
                    <div className="options-header">
                        <h3> Choose Split option </h3>
                        <span className= "pointer" onClick={closeSplitOption}>
                            <i class="fa fa-close" aria-hidden="true" />
                        </span>
                    </div>
                    <div className="options-content">
                        <button onClick = {duplicatePage} className = "btn btn-primary">Create Duplicate Page</button>
                        <button onClick = {showTemplatesList} className = "btn btn-primary">Choose A Template</button>
                    </div>
                </div>
                <div className={`row showTemplatesList m-t-md ${showTemplates ? "active" : ""}`}>
                    {
                        templateList.map((template) => (
                            <TemplateCard
                                key={template.templateId}
                                template={template}
                                onTemplateSelected={onSplitTemplateSelected}
                            />
                        ))
                    }
                </div>
            </div>
        );
    }
}