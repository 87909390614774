import { SignUp as SignUpView } from "./SignUp";
import { connect } from "react-redux";
import * as loginActions from "../../login/actions";
import { getAccountData } from "../../login/selectors";

function mapStateToProps(state) {
    return {
        state: state.ACCOUNTS,
        isAccountError: getAccountData(state),
    };
}

function mapDispatchToProps(dispatch) {
    return {
        putAccountInfo: (accountStore) => dispatch(loginActions.putAccountInfo(accountStore))
    };
}

export const SignUp = connect(mapStateToProps, mapDispatchToProps)(SignUpView);