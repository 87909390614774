import { Funnels as FunnelsView } from "./funnels";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import * as funnelActions from "./actions";
import { getFunnels } from "./selectors";
import { loadCampaigns } from "../campaign/action";
import { loadCompany } from "../login/actions";
import {
    openWalkthroughModal,
} from "../core/app-settings/actions";
import {
    getWalkthroughTiles,
} from "../core/app-settings/selectors";
function mapStateToProps(state) {
    return {
        funnels: getFunnels(state),
        isLoading: state.FUNNELS.isLoading,
        walkthroughModalTiles: getWalkthroughTiles(state),
    };
}
function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators(funnelActions, dispatch),
        loadFunnels: () => dispatch(funnelActions.loadFunnels()),
        deleteFunnel: (funnelId) => dispatch(funnelActions.deleteAction(funnelId)),
        loadCampaigns: () => dispatch(loadCampaigns()),
        loadCompany: () => dispatch(loadCompany()),
        openWalkthroughModal: () => dispatch(openWalkthroughModal(true)),
    };
}
export const Funnels = connect(mapStateToProps, mapDispatchToProps)(FunnelsView);