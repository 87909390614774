export const downloadPreviewURL = (editor) => {
    const domc = editor.DomComponents;
    var dType = domc.getType("default");
    var dModel = dType.model;
    var dView = dType.view;

    domc.addType("preview-url", {
        model: dModel.extend({
            defaults: Object.assign({}, dModel.prototype.defaults, {
                traits: [{
                    type: "input",
                    label: "Preview URL",
                    name: "preview_url",
                    changeProp: 1,
                }],
            }),
        },
        {
            isComponent: function (el) {
                if (el.tagName === "button") {
                    return { type: "preview-url" };
                }
            },
        }),
        view: dView,
    });

    domc.addType("download-url", {
        model: dModel.extend({
            defaults: Object.assign({}, dModel.prototype.defaults, {
                traits: [{
                    type: "input",
                    label: "Download URL",
                    name: "download_url",
                    changeProp: 1,
                }],
            }),
        },
        {
            isComponent: function (el) {
                if (el.tagName === "button") {
                    return { type: "download-url" };
                }
            },
        }),
        view: dView,
    });
};