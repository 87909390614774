import api from "../../api/funnelTypeApi";
import { LOAD_FUNNEL_SUCCESS, loadFunnelSuccess, toggleLoading } from "../manage-funnel/actions";
import { APIHelper, LocalStorageHelper } from "../../utilities";

export const FUNNEL_TYPE = "funnelType/";

export const LOAD_FUNNEL_TYPE_SUCCESS = "LOAD_FUNNEL_TYPE_SUCCESS";


export function loadFunnelTypeSuccess(payload) {
    return {
        type: LOAD_FUNNEL_TYPE_SUCCESS,
        payload
    };
}

export function loadFunnelTypes() {
    return function (dispatch) {
        dispatch(toggleLoading(true));
        return api.getAllFunnelTypes().then(response => {
            dispatch(loadFunnelTypeSuccess(response));
        }).catch(error => {
            dispatch(toggleLoading(false));
            throw (error);
        });
    };
}