import React from "react";

export class TemplateNotification extends React.Component {

    constructor(props) {
        super(props);
        this.hideNotifications = this.hideNotifications.bind(this);
        this.showNotification = this.showNotification.bind(this);
        this.state = {
            notification: {
                show: false,
                type: null,
                message: null,
            },
        };
    }

    showNotification(type, message) {
        this.setState({
            notification: {
                show: true,
                type,
                message,
            },
        }, this.hideNotifications);
    }

    hideNotifications() {
        setTimeout(() => {
            this.setState({
                notification: {
                    show: false,
                    type: null,
                    message: null,
                },
            });
        }, 3000);
    }

    render() {
        const {
            notification: {
                show: showNotification,
                type: notificationType,
                message: notificationMessage,
            },
        } = this.state;
        const notificationClass = (notificationType === "success") ? "notification-success" : "notification-error";
        return (
            <div >
                {
                    showNotification && (
                        <div className={`notifications ${notificationClass} active`}>
                            <i className="fa fa-exclamation-circle m-r-sm fa-2x check-icon" />
                            <h4 className="danger-message">{notificationMessage}</h4>
                        </div>
                    )
                }
            </div>
        );
    }
}



