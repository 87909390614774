import { Trait, TraitTypeAttribute } from "./traits-manager";

export class ButtonTrait extends Trait {
    constructor(editor, traitName, options, callbacks) {
        super({ editor, traitName, callbacks });
        this._options = options;
        this._initializeAttributes();
    }

    _initializeAttributes() {
        const { _options, _cb, traitName } = this;
        this.traitTypeAttribute = new TraitTypeAttribute({
            events: {
                "keyup": "onChange", // trigger parent onChange method on keyup
            },
            getInputEl: function () {
                // TODO - A good scenario for testing
                if (!this.target.view.attr["data-id"] && !this.target.view.attr["data-toggle"]) {
                    alert(`Error:: ${traitName} cannot proceed without an button id`);
                    return;
                }
                if (!this.inputEl) {
                    var input = document.createElement("select");
                    let firstOption = document.createElement("option");
                    firstOption.value = null;
                    firstOption.text = "Select An Option";
                    input.appendChild(firstOption);
                    _options.forEach((o) => {
                        if (o) {
                            var option = document.createElement("option");
                            option.value = `${JSON.stringify(o.value)}`;
                            option.text = `${o.text}`;
                            input.appendChild(option);
                        }
                    });
                    input.value = this.target.get(traitName); // _options[0].id;
                    input.onchange = (e) => _cb.onChangeCallback(e, this.target.view.attr["data-id"]);
                    this.inputEl = input;
                }
                return this.inputEl;
            },
            onValueChange: function () {
                this.target.set(traitName, this.model.get("value"));
            }
        });
    }
}
