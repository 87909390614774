import React from "react";
import PropTypes from "prop-types";
import { Tabs, Tab } from "react-bootstrap";



export class Step2 extends React.Component {
    constructor(context, props) {
        super(context, props);
    }
    render() {
        let { offer, products, upsells, error } = this.props;
        if (typeof error === "object") {
            for (let props in error) {
                error = <div style={{ color: "red" }}>{`${props} : ${error[props]}`}</div>;
            }
        } else {
            error = <div style={{ color: "red" }}>{error}</div>;
        }
        return (
            <div className="ibox">
                <div className="ibox-content">
                    <Tabs defaultActiveKey={1} id="Offers">
                        {offer.map((item, index) => (
                            <Tab key={index} eventKey={index + 1} title={`Offer ${index + 1}`}>
                                <div key={index}>
                                    <h2 className="text-center">Create Offer</h2>
                                    <div>
                                        { error ? error : null}
                                        <form action="">
                                            <div className="row">
                                                <div className={ item.billingCycleType !== "RECURRING" ? "col-md-12" : "col-md-6"}>
                                                    <div className="form-group">
                                                        <label>Product Name</label>
                                                        <input className="form-control" name="productName" value={item ? item.productName : ""} disabled={item.productId ? "disabled" : null} onChange={item.productId ? null : (e) => this.props.editOffer(e, index)} type="text"/>
                                                    </div>
                                                    <div className="form-group">
                                                        <label>Product Sku</label>
                                                        <input type="text" name="productSku" value={item ? item.productSku : ""} disabled={item.productId ? "disabled" : null} onChange={item.productId ? null : (e) => this.props.editOffer(e, index)} className="form-control"/>
                                                    </div>
                                                    <div className="form-group">
                                                        <label>Merchant Account</label>
                                                        <select onChange={(e) => this.props.editOffer(e, index)} value={item ? item.billerId : ""} className="form-control" name="billerId" >
                                                            <option value="">Choose Merchant Account</option>
                                                            { this.props.merchants.map(merchant => (
                                                                <option key={merchant.billerId} value={merchant.billerId}>{merchant.title}</option>
                                                            ))}
                                                        </select>
                                                    </div>
                                                    <div className="form-group">
                                                        <label>Billing Type</label>
                                                        <select onChange={(e) => this.props.editOffer(e, index)} value={item ? item.billingCycleType : ""} className="form-control" name="billingCycleType">
                                                            <option value="ONE_TIME">ONE TIME</option>
                                                            <option value="RECURRING">RECURRING</option>
                                                        </select>
                                                    </div>
                                                </div>
                                                <div className="col-md-6">
                                                    { item.billingCycleType === "RECURRING" ?
                                                        <div>
                                                            <div className="checkbox">
                                                                <label>
                                                                    <input name="trialEnabled" onChange={(e) => this.props.editOffer(e, index)} checked={item && item.trialEnabled === 1 ? "checked" : null} type="checkbox"/>
                                                                    Trial Enabled
                                                                </label>
                                                            </div>
                                                            { item.trialEnabled === 1 ?
                                                                <div>
                                                                    <div className="form-group">
                                                                        <label>Trial Type</label>
                                                                        <select onChange={(e) => this.props.editOffer(e, index)} value={item ? item.trialType : ""} className="form-control" name="trialType" id="">
                                                                            <option value="STANDARD">Standard</option>
                                                                            <option value="ACCELERATED">Accelerated</option>
                                                                        </select>
                                                                    </div>
                                                                    { item.trialType === "ACCELERATED" ?
                                                                        <div className="form-group">
                                                                            <label>Accelerated Trial Minutes</label>
                                                                            <input name="acceleratedTrialMinutes" onChange={(e) => this.props.editOffer(e, index)} value={item ? item.acceleratedTrialMinutes : ""} type="text" className="form-control"/>
                                                                        </div>
                                                                        : null}
                                                                    <div className="form-group">
                                                                        <label>Trial Auth Type</label>
                                                                        <select onChange={(e) => this.props.editOffer(e, index)} className="form-control" value={item ? item.trialAuthType : ""} name="trialAuthType" id="">
                                                                            <option value="">None</option>
                                                                            <option value="HOLDTRIALCHARGE">HOLD TRIAL CHARGE</option>
                                                                            <option value="FULLAUTHVOID">AUTHORIZE & VOID</option>
                                                                            <option value="PREAUTH">PRE-AUTH</option>
                                                                        </select>
                                                                    </div>
                                                                </div>
                                                                : null}
                                                            <div className="form-group">
                                                                <label>Final Billing Cycle</label>
                                                                <input type="text" className="form-control" name="finalBillingCycle" onChange={(e) => this.props.editOffer(e, index)} value={item ? item.finalBillingCycle : ""}/>
                                                            </div>
                                                        </div>
                                                        : null
                                                    }
                                                </div>
                                            </div>
                                            <div>
                                                <div className="form-group">
                                                    <table className="table table-striped">
                                                        <thead>
                                                            <tr>
                                                                <th>Cycle #</th>
                                                                <th>Price</th>
                                                                <th>Shippable</th>
                                                                <th style={ item.billingCycleType !== "RECURRING" ? { display: "none" } : null}>Bill Delay</th>
                                                                <th>Product</th>
                                                                <th style={ item.billingCycleType !== "RECURRING" ? { display: "none" } : null}>Combination</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            { item.cycle ? item.cycle.map((value, i) => (
                                                                <tr key={i}>
                                                                    <td style={ item.billingCycleType !== "RECURRING" && i !== 0 ? { display: "none" } : null}>{i + 1 === 3 ? `${i + 1}+` : i + 1}</td>{/*Cycle Number*/}
                                                                    <td style={ item.billingCycleType !== "RECURRING" && i !== 0 ? { display: "none" } : null}>{/*Price*/}
                                                                        <div className="input-group">
                                                                            <div className="input-group-addon">$</div>
                                                                            <input name="price" size={3} onChange={(e) => this.props.editPrice(e, index, i)} type="text" value={value ? value.price : ""} className="form-control"/>
                                                                        </div>
                                                                    </td>

                                                                    <td style={ item.billingCycleType !== "RECURRING" && i !== 0 ? { display: "none" } : null}><input name="isShippable" onChange={(e) => this.props.editPrice(e, index, i)} checked={value && value.isShippable === 1 ? "checked" : null} type="checkbox"/></td>

                                                                    {/*bill delay defaults to 30 days to speed up entry, not set by crm*/}
                                                                    <td style={ item.billingCycleType !== "RECURRING" ? { display: "none" } : null}><input name="billDelay" size={3} onChange={(e) => this.props.editPrice(e, index, i)} value={value ? value.billDelay : ""} type="text"/> Days</td>{/*Products*/}
                                                                    <td style={ item.billingCycleType !== "RECURRING" && i !== 0 ? { display: "none" } : null}>{/*Products*/}
                                                                        { i < 1 ? <input className="form-control" name="productName" value={item ? item.productName : ""} onChange={(e) => this.props.editPrice(e, index, i)} readOnly={true} type="text" style= {{ width: 180 }}/>
                                                                            : <select onChange={(e) => this.props.editPrice(e, index, i)} value={value ? value.productId : 0} className="form-control" name="productId" style= {{ width: 180 }}>
                                                                                <option value={item.productId}>{item.productName}</option>
                                                                                {
                                                                                    i > 0 ? products.map(product => (
                                                                                        <option key={product.productId} value={product.productId}>{product.productName}</option>
                                                                                    ))
                                                                                        :
                                                                                        ""
                                                                                }
                                                                            </select>
                                                                        }
                                                                    </td>
                                                                    <td style={ item.billingCycleType !== "RECURRING" ? { display: "none" } : null}>{/*Combinations*/}
                                                                        <select onChange={(e) => this.props.editPrice(e, index, i)} value={value ? value.combinationId : ""} className="form-control" name="combinationId" style= {{ width: 180 }}>
                                                                            <option key={value.combinationId} value=''></option>
                                                                            {
                                                                                products.map(product => (
                                                                                    <option key={product.productId} value={product.productId} >{product.productName}</option>
                                                                                ))
                                                                            }
                                                                        </select>
                                                                    </td>
                                                                </tr>
                                                            )) : null}
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                            <div style={{ float: "right" }}>
                                                { upsells && upsells[0] && upsells[0].productId === "choose" || !upsells[0] ?
                                                    <button className="btn btn-primary" onClick={(e) => this.props.saveCampaign(e, this.props.jumpToStep, 4)}>Submit</button>
                                                    :
                                                    <button className="btn btn-primary" onClick={(e) => this.props.validateCampaign(e, this.props.jumpToStep, 2)}>Next</button>
                                                }
                                            </div>
                                            <div>
                                                <button className="btn btn-default" onClick={(event) => {event.preventDefault(); this.props.jumpToStep(0);}}>Previous</button>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </Tab>
                        ))}
                    </Tabs>
                </div>
            </div>
        );
    }
}
Step2.propTypes = {
    jumpToStep: PropTypes.func,
    offer: PropTypes.array,
    products: PropTypes.array,
    onOfferChangeCampaign: PropTypes.func,
    editOffer: PropTypes.func,
    merchants: PropTypes.array,
    editPrice: PropTypes.func,
    validateCampaign: PropTypes.func,
    upsells: PropTypes.array,
    saveCampaign: PropTypes.func,
    error: PropTypes.object
};