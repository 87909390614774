import {
    dottedLinkAttributes
} from "./default-element-properties";
import {
    getSourceCoord,
    getTargetCoord
} from "./json-builder-helper";

export const generateDottedLinkModel = (dottedLink) => {
    return {
        id: dottedLink.id,
        source: getSourceCoord(dottedLink.source),
        target: getTargetCoord(dottedLink.target),
        attrs: dottedLinkAttributes(),
        type: "link",
        vertices: dottedLink.vertices,
        z: dottedLink.z,
        subType: dottedLink.subType
    };
};