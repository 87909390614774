import React, { Component } from "react";
import PropTypes from "prop-types";
import "./style.css";

export default class SplitTest extends Component {
    static propTypes = {
        filteredTemplateList: PropTypes.array,
        splitOtionsPopup: PropTypes.bool,
        showTemplates: PropTypes.bool,
        isSplitEnabled: PropTypes.bool,
        openSplitOptions: PropTypes.func,
        closeSplitOption: PropTypes.func,
        showTemplatesList: PropTypes.func,
        duplicatePage: PropTypes.func,
        chooseTemplate: PropTypes.func,
    }
    constructor(props) {
        super(props);
    }
    render() {
        const { splitOtionsPopup, showTemplates, filteredTemplateList, isSplitEnabled, chooseTemplate, duplicatePage, openSplitOptions, closeSplitOption, showTemplatesList } = this.props;
        const optionsList = splitOtionsPopup ? "optionsList active" : "optionsList";
        const varientButton = splitOtionsPopup ? "btn btn-primary btn-block" : "btn btn-primary btn-block";
        const templatesList = showTemplates ? "row showTemplatesList active" : "row showTemplatesList";
        return (
            <div className= "split-section">
                {!isSplitEnabled &&
                    <>
                        <h3>Split Test</h3>
                        <button onClick = {openSplitOptions} className = {varientButton}>Create split variation</button>
                    </>
                }
                <div className = {optionsList}>
                    <div className="options-header">
                        <h3> Choose Split option </h3>
                        <span className= "pointer" onClick={closeSplitOption}><i class="fa fa-close" aria-hidden="true"></i></span>
                    </div>
                    <div className="options-content">
                        <button onClick = {duplicatePage} className = "btn btn-primary">Create Duplicate Page</button>
                        <button onClick = {showTemplatesList} className = "btn btn-primary">Choose A Template</button>
                    </div>
                </div>
                <div className={templatesList}>
                    <div className="m-t-md">
                        {filteredTemplateList}
                    </div>
                </div>
            </div>
        );
    }
}