import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

const AdminRoute = ({ component: Component, ...rest }) => (
    <>
        {
            rest.isAdminUser || rest.canUserAccessTemplates ? (
                <Component {...rest} />
            ) : (
                <div className="alert alert-danger m-lg"> You are not allowed to view this page </div>
            )
        }
    </>
);

export default connect(
    state => ({
        isAdminUser: state.LOGIN.isAdminUser,
        canUserAccessTemplates: state.LOGIN.canUserAccessTemplates
    })
)(AdminRoute);

AdminRoute.propTypes = {
    component: PropTypes.func,
};
