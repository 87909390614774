import React from "react";
import PropTypes from "prop-types";
import { FunnelThumbnailCard } from "../../funnels-list/funnel-thumbnail";
import { funnelTemplateWithAllMap } from "../../../constants/funnel-template-types";
import { TemplateTypeSelectDropdown } from "../../../template/template-type-select-dropdown";
import { Tab } from "react-bootstrap";
import isEmpty from "lodash/isEmpty";

function TabBar(props) {
    const {
        eventKey,
        title,
        funnels,
        showFunnelType,
        noDataIndicator,
        onFunnelItemSelected,
        onDropdownChange,
        displayDropDown = false,
        showPrice,
        isLoadingTemplates,
        initialTemplateTypeId,
    } = props;
    return (
        <Tab eventKey={eventKey} title={title}>
            <div className="wrapper border-bottom page-heading">
                { displayDropDown &&
                    <div className="row m-n">
                        <div className="col-sm-4 col-sm-offset-8">
                            <TemplateTypeSelectDropdown
                                className="inline m-r-md m-t-sm"
                                label="Filter by Funnel Type:"
                                onDropdownChange={onDropdownChange}
                                templateTypeMap={funnelTemplateWithAllMap}
                                initialTemplateTypeId={initialTemplateTypeId || funnelTemplateWithAllMap[0].templateTypeId}
                            />
                        </div>
                    </div>
                }
                <div>
                    {
                        isLoadingTemplates && (
                            <div className="api-fetch-loader">
                                <i className="fa fa-spinner fa-pulse fa-lg m-r-xs" />
                                Loading Templates
                            </div>
                        )
                    }
                    {
                        !isLoadingTemplates && isEmpty(funnels) && noDataIndicator(title)
                    }
                </div>
                <div className="centerList default-funnels-tab-container">
                    {
                        !isLoadingTemplates && !isEmpty(funnels) && funnels.map((funnel) => {
                            const thumbnailUrl = funnel.thumbnailUrl + "?" + new Date().getTime();
                            return (
                                <FunnelThumbnailCard
                                    funnel={funnel}
                                    onFunnelItemSelected={onFunnelItemSelected}
                                    thumbnailUrl={thumbnailUrl}
                                    showFunnelType={showFunnelType}
                                    showPrice={showPrice}
                                    showDelete={false}
                                />
                            );
                        })
                    }
                </div>
            </div>
        </Tab>
    );
}

TabBar.propTypes = {
    eventKey: PropTypes.number,
    title: PropTypes.string,
    funnels: PropTypes.array,
    showFunnelType: PropTypes.bool,
    noDataIndicator: PropTypes.func,
    onFunnelItemSelected: PropTypes.func,
    onDropdownChange: PropTypes.func,
    displayDropDown: PropTypes.func,
    showPrice: PropTypes.bool,
    isLoadingTemplates: PropTypes.bool,
    initialTemplateTypeId: PropTypes.string,
};

export default TabBar;

