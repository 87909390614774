import * as api from "../../api/funnelApi";
import * as pageApi from "../../api/pageApi";
import * as savePageApi from "../../api/savePageApi";
import * as splitViewApi from "../../api/splitViewApi";
import { APIHelper, LocalStorageHelper } from "../../utilities";

const MANAGE_FUNNEL = "manageFunnel/";
const FUNNELS = "funnels/";
export const IS_LOADING = `${MANAGE_FUNNEL}IS_LOADING`;
export const LOAD_FUNNEL_SUCCESS = "LOAD_FUNNEL_SUCCESS";
export const LOAD_PAGE_VIEW_SUCCESS = MANAGE_FUNNEL + "LOAD_PAGE_VIEW_SUCCESS";
export const SAVE_PAGE_VIEW_SUCCESS = MANAGE_FUNNEL + "SAVE_PAGE_VIEW_SUCCESS";
export const SPLIT_VIEW_SUCCESS = MANAGE_FUNNEL + "SPLIT_VIEW_SUCCESS";
export const LOAD_FUNNEL = "LOAD_FUNNEL";
export const SET_TEMPLATE_HTML_SUCCESS = "SET_TEMPLATE";
export const ADD_NEW_PAGE = MANAGE_FUNNEL + "ADD_NEW_PAGE";
export const SET_PAGE_TEMPLATE_ID = MANAGE_FUNNEL + "SET_PAGE_TEMPLATE_ID";
export const SET_CURRENT_PAGE_ID = MANAGE_FUNNEL + "SET_CURRENT_PAGE_ID";
export const EDIT_PAGE = MANAGE_FUNNEL + "EDIT_PAGE";
export const DELETE_PAGE = MANAGE_FUNNEL + "DELETE_PAGE";
export const ADD_NEW_PAGE_SUCCESS = MANAGE_FUNNEL + "ADD_NEW_PAGE_SUCCESS";
export const GET_PAGE_SUCCESS = MANAGE_FUNNEL + "GET_PAGE_SUCCESS";
export const EDIT_FUNNEL_SUCCESS = `${FUNNELS}EDIT_FUNNEL_SUCCESS`;
export const EDIT_FUNNEL_ERROR = `${FUNNELS}EDIT_FUNNEL_ERROR`;
export const EDIT_PAGE_SUCCESS = MANAGE_FUNNEL + "EDIT_PAGE_SUCCESS";

export function toggleLoading(status = false) {
    return { type: IS_LOADING, status };
}
export function loadFunnelSuccess(payload) {
    return {
        type: LOAD_FUNNEL_SUCCESS,
        payload
    };
}
export function editFunnelSuccess(funnel) {
    return { type: EDIT_FUNNEL_SUCCESS, funnel };
}
export function editFunnelError(isError = false) {
    return { type: EDIT_FUNNEL_ERROR, isError };
}
export function loadPageViewSuccess(pageData) {
    return {
        type: LOAD_PAGE_VIEW_SUCCESS,
        pageData
    };
}
export function savePageViewSuccess(status = false) {
    return {
        type: SAVE_PAGE_VIEW_SUCCESS,
        status
    };
}

export function splitViewSuccess(splitViewPage) {
    return {
        type: SPLIT_VIEW_SUCCESS,
        splitViewPage
    };
}

export function addNewPageSuccess(pageData) {
    return {
        type: ADD_NEW_PAGE_SUCCESS,
        pageData
    };
}

export function getPageSuccess(splitData) {
    return {
        type: GET_PAGE_SUCCESS,
        splitData
    };
}


export function setTemplateHtml(templateHtml) {
    return {
        type: SET_TEMPLATE_HTML_SUCCESS,
        templateHtml
    };
}

export function addNewPage(funnelReferenceId, title = "New Page") {
    return function (dispatch) {
        dispatch(toggleLoading(true));
        return api.createPage(funnelReferenceId, title, { headers: APIHelper.getGenericHeaders() }).then(response => {
            dispatch(addNewPageSuccess(response));
            dispatch(setCurrentPageId(response.referenceId));
            dispatch(loadFunnel({ funnelReferenceId: funnelReferenceId, currentPageId: response.referenceId }));
            dispatch(toggleLoading(false));
        }).catch(error => {
            dispatch(toggleLoading(false));
            throw (error);
        });
    };
}

export function editPageSuccess(pageData) {
    return {
        type: EDIT_PAGE_SUCCESS,
        pageData
    };
}

export function setPageTemplateId(templateId) {
    return {
        type: SET_PAGE_TEMPLATE_ID,
        templateId
    };
}

export function setCurrentPageId(currentPageReferenceId) {
    return {
        type: SET_CURRENT_PAGE_ID,
        currentPageReferenceId
    };
}

export function editPageTitle(params) {
    return function (dispatch) {
        dispatch(toggleLoading(true));
        return pageApi.editPage(params.pageId, { "Title": params.title }).then(response => {
            dispatch(editPageSuccess(response));
            dispatch(toggleLoading(false));
        }).catch(error => {
            dispatch(toggleLoading(false));
            throw (error);
        });
    };
}

export function deletePage(params) {
    return function (dispatch) {
        dispatch(toggleLoading(true));
        return pageApi.deletePage(params.pageReferenceId).then(() => {
            dispatch(loadFunnel(params));
            dispatch(toggleLoading(false));
        }).catch(error => {
            dispatch(toggleLoading(false));
            throw (error);
        });
    };
}

export function getPage(pageId) {
    return function (dispatch) {
        dispatch(toggleLoading(true));
        return pageApi.getPage(pageId).then((response) => {
            dispatch(getPageSuccess(response));
            dispatch(toggleLoading(false));
        }).catch(error => {
            dispatch(toggleLoading(false));
            throw (error);
        });
    };
}




export function loadFunnel(params) {
    return function (dispatch) {
        dispatch(toggleLoading(true));
        return api.retrieveFunnel(params.funnelReferenceId, { headers: APIHelper.getGenericHeaders() }).then(response => {
            const localStorage = new LocalStorageHelper();
            const currentId = params.currentPageId ? params.currentPageId : localStorage.load("currentPageReferenceId");
            localStorage.store("currentPageReferenceId", currentId ? currentId : response.page[0] && response.page[0].referenceId);
            dispatch(setCurrentPageId(currentId));
            dispatch(loadFunnelSuccess({ funnelData: response, currentPageId: currentId }));
            dispatch(toggleLoading(false));
        }).catch(error => {
            dispatch(toggleLoading(false));
            throw (error);
        });
    };
}

// TODo- Rename to PageView
export function savePageView(params) {
    return function (dispatch) {
        dispatch(toggleLoading(true));
        return savePageApi.savePage(params, { headers: APIHelper.getGenericHeaders(), requestBody: params.referenceId }).then((savedPage) => {
            savePageApi.saveJSTemplate(params, { headers: APIHelper.getGenericHeaders(), requestBody: params.referenceId }).then((savedPage) => {
                dispatch(loadFunnel({ funnelReferenceId: params.funnelReferenceId, currentPageId: params.currentPageId }));
                dispatch(toggleLoading(false));
            });
        }).catch(error => {
            dispatch(toggleLoading(false));
            throw (error);
        });
    };
}

export function publishPageView(params) {
    return function (dispatch) {
        dispatch(toggleLoading(true));
        return savePageApi.publishPageView(params, { headers: APIHelper.getGenericHeaders() }).then(() => {
            dispatch(toggleLoading(false));
        }).catch(error => {
            dispatch(toggleLoading(false));
            throw (error);
        });
    };
}

export function duplicatePageView(params) {
    return function (dispatch) {
        dispatch(toggleLoading(true));
        return savePageApi.createNewPageView(params, { headers: APIHelper.getGenericHeaders() }).then((savedPage) => {
            savePageApi.savePage(
                {
                    html: params.html,
                    pageViewReferenceId: savedPage.referenceId,
                    pageReferenceId: params.cReferenceId,
                    templateId: params.templateId
                }, { headers: APIHelper.getGenericHeaders(), requestBody: savedPage.pageViewId }).then((clonedPage) => {
                dispatch(loadPageViewSuccess(clonedPage));
                dispatch(getPage(clonedPage.fPage.referenceId));
                dispatch(loadFunnel({ funnelReferenceId: params.funnelReferenceId, currentPageId: clonedPage.fPage.referenceId }));
            }).catch(error => {
                dispatch(toggleLoading(false));
                throw (error);
            });
        });
    };
}
export function splitViewPut(params) {
    return function (dispatch) {
        dispatch(toggleLoading(true));
        return splitViewApi.applySplitView(params, { headers: APIHelper.getGenericHeaders() }).then((response) => {
            dispatch(splitViewSuccess(response.result));
            dispatch(toggleLoading(false));
        }).catch(error => {
            dispatch(toggleLoading(false));
            throw (error);
        });
    };
}

export function updateFunnel(funnel) {
    return function (dispatch) {
        dispatch(toggleLoading(true));
        return api.manageEditFunnel(funnel.referenceId, { headers: APIHelper.getGenericHeaders(), requestBody: funnel }).then(() => {
            dispatch(editFunnelSuccess(funnel));
            dispatch(toggleLoading(false));
        }).catch(error => {
            dispatch(editFunnelError(true));
            dispatch(toggleLoading(false));
            throw error;
        });
    };
}

export function deleteRunner(pageViewId) {
    return function (dispatch) {
        dispatch(toggleLoading(true));
        return api.deletePageView(pageViewId, { headers: APIHelper.getGenericHeaders() }).then(() => {
            window.location.reload(true);
            dispatch(toggleLoading(false));
        }).catch(error => {
            dispatch(toggleLoading(false));
            throw error;
        });
    };
}