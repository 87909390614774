import { defaultComponents } from "./components";
import { pluginConfig } from "./config/plugins";
import { StorageManager } from "./config/storage-manager";
import { AssetManager } from "./config/asset-manager";
import { getCustomBlocks } from "./blocks";
import { getMenuOptions } from "./panel-menu-options";
import { getTraits } from "./traits";
import { events } from "./events";
import { getTemplateConfig } from "../constants/template-types";

const panelMenu = {
    options: [
        "save-database",
        "open-am",
        "custom-popup",
        "custom-stickbar",
        "theme-changer",
    ],
    "devices-c": [
        "goback",
    ]
};

// [ Todo ] - Rename additonalConfig to additionGrapesJsConfig
// [ Todo ] - Move addAsset Inside this data.

export const getDefaults = ({
    localStorageId,
    templateTypeId,
    assetData,
    addAsset,
    history,
    commands,
}) => {
    const templateType = getTemplateConfig(templateTypeId);
    const traitsFilter = templateType ? [templateType.name] : [];
    return {
        localStorageId,
        components: defaultComponents,
        plugins: pluginConfig.plugins,
        pluginsOpts: pluginConfig.pluginOpts,
        storageManager: { StorageManager, ...{ id: localStorageId } },
        blocks: getCustomBlocks(templateTypeId),
        panelButtons: getMenuOptions(panelMenu),
        traits: getTraits(traitsFilter),
        history,
        events,
        commands,
        additionalConfig: {
            assetManager: AssetManager(assetData, addAsset),
            canvas: {
                scripts: [
                    "https://cdnjs.cloudflare.com/ajax/libs/jquery/3.2.1/jquery.min.js",
                ],
                styles: [
                    "https://cdnjs.cloudflare.com/ajax/libs/twitter-bootstrap/4.3.1/css/bootstrap.min.css",
                    "https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css",
                    "https://cdnjs.cloudflare.com/ajax/libs/animate.css/3.7.2/animate.css"
                ]
            }
        },

    };
};