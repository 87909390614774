/* eslint-disable */
export const
  cmdImport = 'gjs-open-import-webpage',
  cmdDeviceDesktop = 'set-device-desktop',
  cmdDeviceTablet = 'set-device-tablet',
  cmdDeviceMobile = 'set-device-mobile',
  cmdClear = 'canvas-clear';


// To remove the font option from Style Manager
export const fontProperties = ["font-family", "font-size", "font-weight", "letter-spacing", "line-height", "color", "text-align", "text-decoration", "text-shadow"];

// To remove the border option from Style Manager
export const borderProperties = ["border-style", "border-color", "border-width", "border-top-left-radius", "border-top-right-radius", "border-bottom-left-radius", "border-bottom-right-radius"];

// To set the restriction for the components
export const blockRestriction = "div:not([data-gjs-type=wrapper]):not([data-gjs-type=form]):not(.form-group)";
