import React, { Component } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import "./desinger.css";


export class Designers extends Component {
    constructor(props, context) {
        super(props, context);
        this.props.loadDesigners();
        this.props.loadCompany();
    }
    render() {
        const { designers } = this.props;
        return (
            <>
                <div className="row wrapper border-bottom white-bg page-heading" style={{ paddingTop: "1%", paddingBottom: "1%", }}>
                    <div className="col-lg-9">
                        <h2>Designers</h2>
                        <ol className="breadcrumb">
                            <li>
                                <Link to="/dashboard">Home</Link>
                            </li>
                            <li className="active">
                                <strong>Designers</strong>
                            </li>
                        </ol>
                    </div>
                </div>
                <div className="row wrapper wrapper-content animated fadeInRight">
                    <div className="row">
                        {designers ? designers.map((designer, index) => (
                            <div key={index} className="col-md-4">
                                <div className="contact-box">
                                    <Link to={"/designer-details/" + designer.name}>
                                        <div key={index}>
                                            <div className="col-md-4">
                                                <div className="text-center">
                                                    <div className="designers-profile" style={{ backgroundImage: `url(${designer.profilePicture})` }}></div>
                                                    <div className="m-t-xs font-bold profession">{designer.profession}</div>
                                                </div>
                                            </div>
                                            <div className="col-sm-8">
                                                <address>
                                                    <h2><strong>{designer.name}</strong></h2>
                                                    { designer && designer.name === "Waynard" ? <img src={require("../img/online.gif")} width={100} height={35} alt=""/> : null }
                                                    <strong><img className="m-t-xs img-responsive" src={designer ? designer.logo : null} alt=""/></strong>
                                                    <div className="info-details"><i className="fa fa-phone"/>{designer.phone}</div>
                                                    <div className="info-details"><i
                                                        className="fa fa-envelope"/> {designer.email}</div>
                                                    <div className="info-details"><i
                                                        className="fa fa-skype"/> {designer.skype}</div>
                                                </address>
                                            </div>
                                            <div className="clearfix"/>
                                        </div>
                                    </Link>
                                </div>
                            </div>
                        )) : <div>Designers Page is Unavailable</div>
                        }
                    </div>
                </div>
            </>
        );
    }
}

Designers.propTypes = {
    loadDesigners: PropTypes.func,
    designers: PropTypes.array,
    loadCompany: PropTypes.func
};