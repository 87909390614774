import React, { Component } from "react";
import PropTypes from "prop-types";
import { CreateFunnel } from "../../create-funnel";

export class Step3 extends Component {
    constructor(props) {
        super(props);
    }

    componentDidMount() {}

    componentWillUnmount() {}

    render() {
        return (
            <div>
                <CreateFunnel/>
            </div>
        );
    }
}
Step3.propTypes = {
    jumpToStep: PropTypes.func,
    onChange: PropTypes.func.isRequired,
    // onSave: PropTypes.func.isRequired,
    updateStore: PropTypes.Object,
    getStore: PropTypes.Object,
};