import React from "react";
import "../styles/errors.css";

export const My404Component = () => {
    return (
        <div className="errorPage">
            <div className="errorContainer">
                <img className="img-responsive center-block errorImg" style={{ height: 50 }} src={require("../img/funnelkonnekt-6_preview (1).png")} alt=""/>
                <span className="errorNo text-muted">404</span>
                <span className="errorContent text-muted">page not found</span>
            </div>
        </div>
    );
};