import React from "react";
import PropTypes from "prop-types";
import {
    SelectInput,
} from "../commons";

const TypeField = ({
    funnel,
    funnelType, errors, onChange,
    funnelTypeError, label, defaultOption }) => {

    return (
        <SelectInput
            name="fListFunnelTypeId"
            label={label}
            value={funnel.fListFunnelTypeId}
            defaultOption={defaultOption}
            options={funnelType}
            onChange={onChange}
            error={errors.fListFunnelTypeId}
            errorMessage={funnelTypeError} />

    );
};

TypeField.propTypes = {
    funnel: PropTypes.object.isRequired,
    funnelType: PropTypes.array,
    errors: PropTypes.object,
    onChange: PropTypes.func,
    funnelTypeError: PropTypes.string,
    label: PropTypes.string,
    defaultOption: PropTypes.string,
};

export default TypeField;