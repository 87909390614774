import { destroyComponent } from "./helper";

const addTriggerOnLoadScript = (editor) => {
    var wrapper = editor.DomComponents.getWrapper();
    if (wrapper.view.$el.find("#triggerOnDelay").length === 0) {
        editor.DomComponents.addComponent({
            tagName: "script",
            content: ` var isVisitedOnLoad;
        if(isVisitedOnLoad){
            var  popupBlock = document.getElementById('customizable_popup');
            var minutesDelay = popupBlock.querySelector('[data-minutesdelay]') ?  Number(popupBlock.querySelector('[data-minutesdelay]').getAttribute('data-minutesdelay')) : 0;
            var  secondsDelay = popupBlock.querySelector('[data-secondsdelay]')  ?  Number(popupBlock.querySelector('[data-secondsdelay]').getAttribute('data-secondsdelay')) : 0;
        var delay = minutesDelay > 0 ? ((minutesDelay * 60)+secondsDelay)+'000' : secondsDelay+'000' ;
        popupBlock.setAttribute('style','display:none');
        setTimeout((delay) => {
            isVisitedOnLoad = false
            popupBlock.setAttribute('style','display:block');
        },delay);
        }`,
            attributes: { id: "triggerOnDelay" },
            removable: true,
            copyable: false,
        });
    }
};

const handleTriggerOnUpdate = (editor, component) => {
    const wrapper = editor.DomComponents.getWrapper();
    if (component.changed.trigger_on) {
        if (component.changed.trigger_on === "trigger_on_load") {
            addTriggerOnLoadScript(editor);
            destroyComponent(editor, "triggerOnScroll");
            editor.getSelected().addAttributes(
                { "data-showOnscroll": "false" }
            );
        } else if (component.changed.trigger_on === "trigger_on_scroll") {
            editor.getSelected().addAttributes(
                { "data-showOnscroll": "true" }
            );
            destroyComponent(editor, "triggerOnDelay");
        } else if (component.changed.trigger_on === "trigger_on_exit") {
            if (wrapper.view.$el.find("#triggerOnExit").length === 0) {
                editor.DomComponents.addComponent({
                    tagName: "script",
                    content: `var isVisited;
                    var onLeaveOf = document.getElementsByTagName('body')[0];
                    onLeaveOf.addEventListener("mouseleave", () => {
                    if(isVisited){
                        document.getElementById('customizable_popup').setAttribute('style','display:block');
                        isVisited= false;
                    }
                });`,
                    attributes: { id: "triggerOnExit" },
                    removable: true,
                    copyable: false,
                });
            }
            editor.getSelected().addAttributes(
                { "data-showOnscroll": "false" }
            );
            destroyComponent(editor, "triggerOnScroll");
        } else if (component.changed.trigger_on === "none") {
            destroyComponent(editor, "triggerOnDelay");
            editor.getSelected().addAttributes(
                { "data-showOnscroll": "false" }
            );
        }
    }
};

export default handleTriggerOnUpdate;