import joint from "jointjs";
import constants from "../utilities/constants";

const Elements = (imageSvg, posX, posY, label, templateTypeId, isIcon) => {
    joint.shapes.devs.MyImageModel = joint.shapes.devs.Model.extend({
        markup: "<g class=\"rotatable\"><g class=\"scalable\"><rect class=\"body\"/></g><image/><rect class=\"borderBox\"/><text class=\"label\"/><rect x=\"-25\" y=\"165\" class=\"iconbar\" width=\"170px\" height=\"50px\" /><text id=\"settings\" class=\"icons settings\" x=\"-15\" y=\"200\">" + `${constants.settings}` + "<title>Settings</title></text><text class=\"icons edit\" x=\"15\" y=\"200\">" + `${constants.edit}` + "<title>Edit</title></text><text class=\"icons trash\" x=\"45\" y=\"200\">" + `${constants.trash}` + "<title>Delete</title></text><text class=\"icons copy\" x=\"75\" y=\"200\">" + `${constants.split}` + "<title>Duplicate this Element</title></text><text class=\"icons plus copy\" x=\"80\" y=\"202\">" + `${constants.plus}` + "<title>Duplicate this Element</title></text><text class=\"icons preview\" x=\"110\" y=\"200\">" + `${constants.preview}` + "<title>Preview</title></text></g>",
        // portMarkup: "<g><path class=\"port-body\"/><text class=\"port-label\"/></g>",
        portMarkup: "<g><rect class=\"port-body\"></rect><text x=\"-10\" y=\"-100\" class=\"linkicon\">" + `${constants.link}` + "<title>Move</title></text></g>",
        defaults: joint.util.deepSupplement({
            type: "devs.MyImageModel",
            position: {
                x: posX,
                y: posY
            },
            attrs: {
                ".settings": {
                    event: "element:settings"
                },
                ".edit": {
                    event: "element:edit"
                },
                ".trash": {
                    event: "element:delete"
                },
                ".preview": {
                    event: "element:preview"
                },
                ".copy": {
                    event: "element:copy"
                },
                ".split": {
                    event: "element:split"
                },
            },
            outPorts: [""]
        }, joint.shapes.devs.Model.prototype.defaults)
    });
    // Usage:
    var generalModel = new joint.shapes.devs.MyImageModel({
        size: {
            width: 60,
            height: 70,
        },
        attrs: {
            image: {
                width: isIcon ? 60 : 60,
                height: isIcon ? 60 : 70,
                "xlink:href": "data:image/svg+xml;utf8," + encodeURIComponent(imageSvg),
                // "xlink:href": "https://s3.amazonaws.com/templates.funnelkonnekt.com/d05fc401-4108-414d-8fcd-330a620bb5d5/thumbnail_new.png",
                preserveAspectRatio: "none"
            },
            ".label": {
                text: label,
                y: -30,
                x: 0
            }
        },
        position: {
            x: posX,
            y: posY
        },
        elementMetaData: {
            templateTypeId
        },
        pageLabel: {
            label
        },
        ports: {
            groups: {
                "out": {
                    attrs: {
                        ".port-body": { fill: "#ffffff", width: 120, height: 160, stroke: "#ffffff00", "stroke-width": 1, x: -120, y: -80, magnet: true },
                        ".linkicon": { x: -10, y: -100 },
                    }
                },
                ".icons": {
                    fill: "#1ab394",
                    magnet: true

                }
            }
        },
        outPorts: [""],
        icon: isIcon ? isIcon : false
    });
    return (
        generalModel
    );
};
export default Elements;