export class UserData {
    constructor(userObj = {}, active, userSetting, isFirstTime, affiliateId, isDesignerUser) {
        this.active = active;
        this.fCrm = userObj.fCrm;
        this.fCrmId = userObj.fCrmId;
        this.fTenant = new TenantData(userObj.fTenant || {});
        this.fTenantId = userObj.fTenantId;
        this.fUserId = userObj.fUserId;
        this.userCrmId = userObj.userCrmId;
        this.userId = userObj.userId;
        this.userSetting = new UserSetting(userObj.userSetting || {});
        this.isFirstLogin = !!isFirstTime;
        this.affiliateId = affiliateId;
        this.isDesignerUser = isDesignerUser;
    }
}


export class TenantData {
    constructor(tenantObj) {
        this.active = tenantObj.active;
        this.addressLine1 = tenantObj.addressLine1;
        this.city = tenantObj.city;
        this.companyId = tenantObj.companyId;
        this.name = tenantObj.name;
        this.funnel = tenantObj.funnel;
        this.page = tenantObj.page;
        this.pageView = tenantObj.pageView;
        this.phone = tenantObj.phone;
        this.postalCode = tenantObj.postalCode;
        this.referenceId = tenantObj.referenceId;
        this.state = tenantObj.state;
        this.tenantId = tenantObj.tenantId;
        this.userCrm = tenantObj.userCrm;
    }
}

export class UserSetting {
    constructor(settingObj) {
        this.apiPassword = settingObj.apiPassword;
        this.apiUsername = settingObj.apiUsername;
        this.email = settingObj.email;
        this.fBelt = settingObj.fBelt;
        this.fBeltId = settingObj.fBeltId;
        this.fUserId = settingObj.fUserId;
        this.firstName = settingObj.firstName;
        this.lastName = settingObj.lastName;
        this.userSettingId = settingObj.userSettingId;
        this.profilePicture = settingObj.profilePicture;
        this.username = settingObj.username;
        this.salt = settingObj.salt;
    }
}