import React, { Component, PureComponent } from "react";
import PropTypes from "prop-types";
import { Multiselect } from "react-widgets";
import constants from "../utilities/constants";
export default class FontsList extends Component {
    static propTypes = {
        fontList: PropTypes.object,
        savedFonts: PropTypes.array,
        selectedFonts: PropTypes.array,
        saveFontsCall: PropTypes.func,
        isFontLoading: PropTypes.bool,
    }
    constructor(props, context) {
        super(props, context);
        this.state = {
            multiFontSelectValue: [],
        };
    }
    componentWillReceiveProps(nextProps) {
        const { fontList, savedFonts, selectedFonts } = this.props;
        if (this.props.selectedFonts !== nextProps.selectedFonts) {
            this.setState({ multiFontSelectValue: nextProps.selectedFonts });
        }
    }
    joinWhiteSpace = (item) => item.family.split(" ").join("+");
    render() {
        const { fontList, saveFontsCall, savedFonts, selectedFonts, isFontLoading } = this.props;
        const { multiFontSelectValue } = this.state;
        let multiSelectRender;
        let addLinktag = fontList && fontList.items.map(item => {
            const fontFamily = this.joinWhiteSpace(item);
            var linkTag = `<link href="https://fonts.googleapis.com/css?family=${fontFamily}" rel="stylesheet">`;
            return linkTag;
        });
        // Add Links to Head tag to make google font preview on selectbox list
        // addLinktag = addLinktag.join("");
        // const updatedHeadTag = document.head;
        const ShowList = fontList && fontList.items.map(item => {
            return item.family;
        });
        const filteredArray = multiFontSelectValue && multiFontSelectValue.map(item => {
            return fontList && fontList.items.find(val => {
                return val.family === item;
            });
        });
        multiSelectRender = <Multiselect
            busy = { isFontLoading ? true : false }
            data={ShowList}
            value={multiFontSelectValue}
            placeholder={constants.fontPlaceholder}
            onChange={value => this.setState({ multiFontSelectValue: value })}
        />;
        return (
            <>
                <div className="form-group font-list">
                    <label>{constants.fontLable}</label>
                    {multiSelectRender}
                    <div className="text-right">
                        <button className="btn btn-primary m-t-sm" onClick={() => {saveFontsCall(filteredArray);}}>{constants.fontSave}</button>
                    </div>
                </div>
            </>
        );
    }
}