import { Dashboard as DashboardView } from "./dashboard";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import * as funnelActions from "../funnels/actions";
import { loadCampaigns } from "../campaign/action";
import * as dashboardActions from "./actions";
import * as loginActions from "../login/actions";
import {
    openWalkthroughModal,
    setWalkthroughModalSeenByUser,
} from "../core/app-settings/actions";
import {
    checkWalkthroughPeekabooDisplay,
} from "../core/app-settings/selectors";

function mapStateToProps(state) {
    return {
        funnels: state.FUNNELS.funnels,
        sales: state.DASHBOARD.sales,
        pageViews: state.DASHBOARD.pageViews,
        settingsData: state.LOGIN.settingsData,
        userData: state.LOGIN.userData,
        funnelPageCount: state.LOGIN.funnelPageCount,
        ads: state.DASHBOARD.ads,
        showWalkthroughPeekaboo: checkWalkthroughPeekabooDisplay(state),
    };
}
function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators({ funnelActions, dashboardActions, loginActions }, dispatch),
        getPageViews: () => dispatch(dashboardActions.pageViews()),
        getSalesSummary: () => dispatch(dashboardActions.salesSummary()),
        loadFunnels: () => dispatch(funnelActions.loadFunnels()),
        loadCampaigns: () => dispatch(loadCampaigns()),
        getAccountInfo: () => dispatch(loginActions.getAccountInfo()),
        loadPageCounts: () => dispatch(loginActions.getFunnelPageCount()),
        getAds: () => dispatch(dashboardActions.getAds()),
        loadCompany: () => dispatch(loginActions.loadCompany()),
        openWalkthroughModal: () => dispatch(openWalkthroughModal(true)),
        onWalkthroughPeekabooSeenByUser: () => dispatch(setWalkthroughModalSeenByUser(false)),
    };
}
export const Dashboard = connect(mapStateToProps, mapDispatchToProps)(DashboardView);