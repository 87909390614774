import React, { Component } from "react";
import PropTypes from "prop-types";

export class Step2 extends Component {
    constructor(props) {
        super(props);

        this.state = {
            address: props.getStore().address,
            city: props.getStore().city,
            state: props.getStore().state,
            postalCode: props.getStore().postalCode,
            phone: props.getStore().phone,
        };

        this._validateOnDemand = true; // this flag enables onBlur validation as user fills forms
        this.validationCheck = this.validationCheck.bind(this);
        this.isValidated = this.isValidated.bind(this);
    }

    componentDidMount() {}

    componentWillUnmount() {}

    isValidated() {
        const userInput = this._grabUserInput(); // grab user entered vals
        const validateNewInput = this._validateData(userInput); // run the new input against the validator
        let isDataValid = false;

        // if full validation passes then save to store and pass as valid
        if (Object.keys(validateNewInput).every((k) => {return validateNewInput[k] === true;})) {
            if (this.props.getStore().postalCode !== userInput.postalCode || this.props.getStore().gender !== userInput.gender) { // only update store of something changed
                this.props.updateStore({
                    ...userInput,
                    savedToCloud: false // use this to notify step4 that some changes took place and prompt the user to save again
                }); // Update store here (this is just an example, in reality you will do it via redux or flux)
            }

            isDataValid = true;
        } else {
        // if anything fails then update the UI validation state but NOT the UI Data State
            this.setState(Object.assign(userInput, validateNewInput, this._validationErrors(validateNewInput)));
        }

        return isDataValid;
    }

    validationCheck() {
        if (!this._validateOnDemand)
            return;

        const userInput = this._grabUserInput(); // grab user entered vals
        const validateNewInput = this._validateData(userInput); // run the new input against the validator

        this.setState(Object.assign(userInput, validateNewInput, this._validationErrors(validateNewInput)));
    }

    _validateData(data) {
        return {
            addressVal: /^[a-zA-Z[^0-9.!#$%&'*+/=?^_`{|}~-]+/.test(data.address), // required: regex w3c uses in html5
            cityVal: /^[a-zA-Z[^0-9.!#$%&'*+/=?^_`{|}~-]+/.test(data.city), // required: regex w3c uses in html5
            stateVal: /^[a-zA-Z][^0-9.!#$%&'*+/=?^_`{|}~-]+/.test(data.state), // required: regex w3c uses in html5
            postalCodeVal: /./.test(data.postalCode), // required: regex w3c uses in html5
            phoneVal: /^\d+$/.test(data.phone), // required: regex w3c uses in html5
        };
    }

    _validationErrors(val) {
        const errMsgs = {
            addressValMsg: val.addressVal ? "" : "Enter your Address",
            cityValMsg: val.cityVal ? "" : "Enter your City",
            stateValMsg: val.stateVal ? "" : "Enter your State",
            postalCodeValMsg: val.postalCodeVal ? "" : "Enter your postal code",
            phoneValMsg: val.phoneVal ? "" : "Enter your phone number"
        };
        return errMsgs;
    }

    _grabUserInput() {
        return {
            address: this.refs.address.value,
            city: this.refs.city.value,
            state: this.refs.state.value,
            postalCode: this.refs.postalCode.value,
            phone: this.refs.phone.value,
        };
    }

    render() {
        // explicit class assigning based on validation
        let notValidClasses = {};

        if (typeof this.state.postalCodeVal === "undefined" || this.state.postalCodeVal) {
            notValidClasses.postalCodeCls = "no-error col-md-8";
        } else {
            notValidClasses.postalCodeCls = "has-error col-md-8";
            notValidClasses.postalCodeValGrpCls = "val-err-tooltip";
        }

        if (typeof this.state.phoneVal === "undefined" || this.state.phoneVal) {
            notValidClasses.phoneCls = "no-error col-md-8";
        } else {
            notValidClasses.phoneCls = "has-error col-md-8";
            notValidClasses.phoneValGrpCls = "val-err-tooltip";
        }

        if (typeof this.state.addressVal === "undefined" || this.state.addressVal) {
            notValidClasses.addressCls = "no-error col-md-8";
        } else {
            notValidClasses.addressCls = "has-error col-md-8";
            notValidClasses.addressValGrpCls = "val-err-tooltip";
        }

        if (typeof this.state.cityVal === "undefined" || this.state.cityVal) {
            notValidClasses.cityCls = "no-error col-md-8";
        } else {
            notValidClasses.cityCls = "has-error col-md-8";
            notValidClasses.cityValGrpCls = "val-err-tooltip";
        }

        if (typeof this.state.stateVal === "undefined" || this.state.stateVal) {
            notValidClasses.stateCls = "no-error col-md-8";
        } else {
            notValidClasses.stateCls = "has-error col-md-8";
            notValidClasses.stateValGrpCls = "val-err-tooltip";
        }

        return (
            <div>
                <fieldset>
                    <div className="row">
                        <div className="col-lg-8">
                            <form id="Form" className="form-horizontal">
                                <div className="form-group">
                                    <label className="col-md-12">
                                        <h1>Step 1: Account Information</h1>
                                    </label>
                                </div>
                                <div className="form-group col-md-12 content form-block-holder">
                                    <label className="control-label col-md-2 col-lg-4">
                                        Address*
                                    </label>
                                    <div className={notValidClasses.addressCls}>
                                        <input
                                            ref="address"
                                            autoComplete="off"
                                            type="text"
                                            placeholder="Address"
                                            className="form-control"
                                            required
                                            defaultValue={this.state.address}
                                            onBlur={this.validationCheck} />
                                        <div className={notValidClasses.addressValGrpCls}>{this.state.addressValMsg}</div>
                                    </div>
                                </div>
                                <div className="form-group col-md-12 content form-block-holder">
                                    <label className="control-label col-md-2 col-lg-4">
                                    City*
                                    </label>
                                    <div className={notValidClasses.cityCls}>
                                        <input
                                            ref="city"
                                            autoComplete="off"
                                            type="text"
                                            placeholder="City"
                                            className="form-control"
                                            required
                                            defaultValue={this.state.city}
                                            onBlur={this.validationCheck} />
                                        <div className={notValidClasses.cityValGrpCls}>{this.state.cityValMsg}</div>
                                    </div>
                                </div>
                                <div className="form-group col-md-12 content form-block-holder">
                                    <label className="control-label col-md-2 col-lg-4">
                                        State*
                                    </label>
                                    <div className={notValidClasses.stateCls}>
                                        <input
                                            ref="state"
                                            autoComplete="off"
                                            type="text"
                                            placeholder="State"
                                            className="form-control"
                                            required
                                            defaultValue={this.state.state}
                                            onBlur={this.validationCheck} />
                                        <div className={notValidClasses.stateValGrpCls}>{this.state.stateValMsg}</div>
                                    </div>
                                </div>
                                <div className="form-group col-md-12 content form-block-holder">
                                    <label className="control-label col-md-2 col-lg-4">
                                        postalCode*
                                    </label>
                                    <div className={notValidClasses.postalCodeCls}>
                                        <input
                                            ref="postalCode"
                                            autoComplete="off"
                                            type="text"
                                            placeholder="Postal Code"
                                            className="form-control"
                                            maxlength="6"
                                            required
                                            defaultValue={this.state.postalCode}
                                            onBlur={this.validationCheck} />
                                        <div className={notValidClasses.postalCodeValGrpCls}>{this.state.postalCodeValMsg}</div>
                                    </div>
                                </div>
                                <div className="form-group col-md-12 content form-block-holder">
                                    <label className="control-label col-md-2 col-lg-4">
                                        Phone*
                                    </label>
                                    <div className={notValidClasses.phoneCls}>
                                        <input
                                            ref="phone"
                                            autoComplete="off"
                                            type="text"
                                            placeholder="Phone Number"
                                            className="form-control"
                                            maxlength="15"
                                            required
                                            defaultValue={this.state.phone}
                                            onBlur={this.validationCheck} />
                                        <div className={notValidClasses.phoneValGrpCls}>{this.state.phoneValMsg}</div>
                                    </div>
                                </div>
                            </form>
                        </div>
                        <div className="col-lg-4 col-md-12">
                            <div className="text-center">
                                <div style={{ marginTop: "20px" }}>
                                    <i className="fa fa-sign-in" style={{ fontSize: "180px", color: "#e5e5e5" }}/>
                                </div>
                            </div>
                        </div>
                    </div>
                </fieldset>
            </div>
        );
    }
}
Step2.propTypes = {
    onChange: PropTypes.func.isRequired,
    onSave: PropTypes.func.isRequired,
    updateStore: PropTypes.Object,
    getStore: PropTypes.func,
};