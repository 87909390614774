import React, { useContext, useState, useEffect } from "react";
import { VisualizerContext } from "./";
import Zoom from "./components/header/zoom";
import FunnelTemplateTitle from "./components/header/funnel-template-title";
import VisualizerEmitter from "./visualizer-emitter";
import {
    updateFunnelTemplate,
} from "./actions/funnelTemplateActions";
import {
    triggerNofiticationError,
} from "../../commons/notification";

const FunnelTemplateVisualizerHeader = () => {
    const VisualizerContextData = useContext(VisualizerContext);
    const [activeButton, setActiveButton] = useState(false);
    const {
        funnelHelpers: {
            funnelZoomValues,
        },
        funnelData,
        funnelHelpers,
    } = VisualizerContextData;
    const [zoom, setZoom] = useState(funnelZoomValues.zoomValue);
    useEffect(() => {
        VisualizerEmitter.addListener("HEADER-BUTTONS:RESET", () => setActiveButton(null));
        VisualizerEmitter.addListener("RIGHTPANEL:CLOSE", () => setActiveButton(null));
    }, []);
    /*
     *
     * Very weird way to track a button state
     * [Todo] - Fix this in a more ideal way. Currently not able to think anything else
     *
     *
     */
    const emitDottedLine = () => {
        setActiveButton("dottedLine");
        VisualizerEmitter.emit("DOTTED-LINE:READY");
    };
    const emitTextElement = () => {
        setActiveButton("textButton");
        VisualizerEmitter.emit("TEXT-ELEMENT:READY");
    };
    const handleZoomUpdate = (zoomValue) => {
        const params = {
            zoomValue: zoomValue / 100,
            fListFunnelTypeId: funnelHelpers.getFunnelValueOf("fListFunnelTypeId"),
            funnel: funnelData,
        };
        setZoom(zoomValue / 100);
        VisualizerEmitter.emit("ZOOM:EVENT", zoomValue);
        updateFunnelTemplate(params)
            .catch(err => {
                triggerNofiticationError({ message: err.message });
            });
    };
    const onFunnelTemplateNameUpdate = (title) => {
        const params = {
            zoomValue: +zoom,
            fListFunnelTypeId: funnelHelpers.getFunnelValueOf("fListFunnelTypeId"),
            funnel: {
                ...funnelData,
                title,
            }
        };
        return updateFunnelTemplate(params)
            .catch(err => {
                triggerNofiticationError({ message: err.message });
            });
    };

    return (
        <div className="visualizer-header">
            <div className="header-content row">
                <div className="col-xs-6">
                    <FunnelTemplateTitle
                        funnelData={funnelData}
                        updateFunnelTemplate={onFunnelTemplateNameUpdate}
                    />
                </div>
                <div className="col-xs-6 header-content">
                    <span
                        onClick={emitTextElement}
                        className={`m-l-xs text-element ${activeButton === "textButton" ? "active" : ""}`}
                    >
                        A
                    </span>
                    <span
                        onClick={emitDottedLine}
                        className={`m-l-xs m-r-xs dottedLine ${activeButton === "dottedLine" ? "active" : ""}`}
                    />
                    <Zoom
                        values={funnelZoomValues}
                        updateZoomValue={handleZoomUpdate}
                    />
                </div>
            </div>
        </div>
    );
};



export default FunnelTemplateVisualizerHeader;
