import React, { useContext, useState } from "react";
import PropTypes from "prop-types";
import { Modal } from "react-bootstrap";

const SplitPreviewEditModal = ({
    closePopup,
    action,
    currentPage,
    onClick
}) => {
    const titleMap = {
        0: "Choose page to Edit",
        1: "Choose page to Preview",
        2: "Choose page to configure Buttons/Links"
    };
    const [ popupStatus, setPopupStatus ] = useState(true);
    const renderSplitPageSelectView = ({
        alphabet,
        thumbnailUrl,
        isEdited,
        pageReferenceId,
        pageViewReferenceId
    }) => {
        const argumentMap = {
            0: pageReferenceId,
            1: pageViewReferenceId,
            2: pageViewReferenceId
        };
        return (
            <div>
                <h2 className="text-center font-bold">
                    {alphabet}
                </h2>
                <a
                    onClick = {
                        () => onClick(argumentMap[action])
                    }
                    className={
                        isEdited ?
                            "split-thumbnail-img split-edited" :
                            "split-thumbnail-img split-unedited"
                    }
                    style={{ backgroundImage: `url(${thumbnailUrl})`
                    }}>
                </a>
            </div>
        );
    };
    return (
        <>
            <Modal
                backdrop = "static"
                show = {popupStatus}
                onHide={closePopup}
                bsSize="lg"
                aria-labelledby="contained-modal-title-lg"
            >
                <Modal.Header
                    closeButton
                    styleName={{ backgroundColor: "rgb(47, 64, 77)" }}
                >
                    <Modal.Title>
                        <h3 className="text-white">
                            {titleMap[action]}
                        </h3>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body
                    className="m-n"
                >
                    <div
                        className="split-thumbnail"
                    >
                        {
                            renderSplitPageSelectView({
                                alphabet: "A",
                                thumbnailUrl: currentPage.get("thumbnailUrl"),
                                isEdited: currentPage.get("isEdited"),
                                pageReferenceId: currentPage.get("pageReferenceId"),
                                pageViewReferenceId: currentPage.get("pageViewReferenceId")
                            })
                        }
                        {
                            renderSplitPageSelectView({
                                alphabet: "B",
                                thumbnailUrl: currentPage.get("splitPageAttributes").thumbnailUrl,
                                isEdited: currentPage.get("splitPageAttributes").isEdited,
                                pageReferenceId: currentPage.get("splitPageAttributes").pageReferenceId,
                                pageViewReferenceId: currentPage.get("splitPageAttributes").pageViewReferenceId
                            })
                        }
                    </div>
                </Modal.Body>
            </Modal>
        </>
    );
};

SplitPreviewEditModal.propTypes = {
    closePopup: PropTypes.bool,
    action: PropTypes.number,
    currentPage: PropTypes.object,
    alphabet: PropTypes.string,
    thumbnailUrl: PropTypes.string,
    isEdited: PropTypes.bool,
    pageReferenceId: PropTypes.string,
    pageViewReferenceId: PropTypes.string,
    onClick: PropTypes.func
};

export default SplitPreviewEditModal;

