import React from "react";
import PropTypes from "prop-types";

export default function CRMCheckbox({ label, name, defaultChecked, onChange }) {
    return (
        <div className="checkbox-area checkbox-success checkbox-md ">
            <label className="custom-checkbox">
                <div className="check-box-label">{label}</div>
                <input type="checkbox" name={name} onChange={(e) => onChange(e)} defaultChecked={defaultChecked}/>
                <span className="check-box"></span>
            </label>
        </div>
    );
}

CRMCheckbox.propTypes = {
    label: PropTypes.string,
    defaultChecked: PropTypes.bool,
    name: PropTypes.string,
    onChange: PropTypes.func
};