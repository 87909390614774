import { APIHelper } from "../utilities";
import { splitViewUrl } from "./urls";

export function applySplitView(params) {
    return APIHelper.baseRequest(APIHelper.PUT, `${splitViewUrl}/SplitView/funnel/${params.funnelReferenceId}/page/${params.pageReferenceId}`, {
        headers: APIHelper.getGenericHeaders(),
        requestBody: {
            [params.pageViewId1]: params.value1 ? params.value1 : 1,
            [params.pageViewId2]: params.value2 ? params.value2 : 99
        }
    });
}
