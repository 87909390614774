import constants from "../constants";
const { presellPageSvg,
    leadPageSvg,
    checkoutPageSvg,
    upsellPageSvg,
    thankyouPageSvg,
    membersAreaPageSvg,
    optInPageSvg,
    downloadPageSvg,
    salesVideoPageSvg,
    calendarPageSvg,
    surveyPageSvg,
    webinarPageSvg,
    webinarReplayPageSvg,
    blogPostPageSvg,
    popUpPageSvg,
    splitPageSvg,
    emailPageSvg,
    textPageSvg
} = constants;
const svgDataImagePrefix = "data:image/svg+xml;utf8,";
export const generateSplitImage = () => getSVGImage(splitPageSvg);
const getSVGImage = (svg) => {return svgDataImagePrefix + encodeURIComponent(svg);};
export const generateSVGImage = (templateTypeId) => {
    switch (templateTypeId) {
        case 1:
            return getSVGImage(presellPageSvg);
        // Lead page
        case 2:
            return getSVGImage(leadPageSvg);
        // Checkout Page
        case 4:
            return getSVGImage(checkoutPageSvg);
        // Upsell Page
        case 3:
            return getSVGImage(upsellPageSvg);
        // Thank You Page
        case 5:
            return getSVGImage(thankyouPageSvg);
        /*case 6:
            pageType = "Global";
            break;*/
        case 7:
            return getSVGImage(membersAreaPageSvg);
        case 8:
            return getSVGImage(membersAreaPageSvg);
        case 9:
            return getSVGImage(membersAreaPageSvg);
        case 10:
            return getSVGImage(membersAreaPageSvg);
        case 11:
            return getSVGImage(membersAreaPageSvg);
        case 12:
            return getSVGImage(membersAreaPageSvg);
        case 13:
            return getSVGImage(membersAreaPageSvg);
        /*case 14:
            pageType = "Generic";
            break;*/
        case 15:
            return getSVGImage(optInPageSvg);
        case 16:
            return getSVGImage(downloadPageSvg);
        case 17:
            return getSVGImage(salesVideoPageSvg);
        case 18:
            return getSVGImage(calendarPageSvg);
        case 19:
            return getSVGImage(surveyPageSvg);
        case 20:
            return getSVGImage(webinarPageSvg);
        case 21:
            return getSVGImage(webinarReplayPageSvg);
        case 22:
            return getSVGImage(blogPostPageSvg);
        case 23:
            return getSVGImage(popUpPageSvg);
        case 24:
            return getSVGImage(emailPageSvg);
        case 25:
            return getSVGImage(textPageSvg);
        default:
            return "Page";//pageType = "Page";
    }
};