/* eslint-disable */
import grapesjs from "grapesjs";
import pluginNavbar from "grapesjs-navbar";
import pluginCountdown from "grapesjs-component-countdown";
import pluginForms from "grapesjs-plugin-forms";
import pluginAviary from "grapesjs-aviary";
import pluginFilestack from "grapesjs-plugin-filestack";
import pluginExport from "./exportTemplate";
import "./legacy/grapes-plugins/ckeditor";

import pluginBlocks from "./legacy/basic-blocks/src";
import commands from "./legacy/commands";
import blocks from "./legacy/blocks";
import components from "./legacy/components";
import panels from "./legacy/panels";
import styles from "./legacy/styles";
import customStyleManager from "./legacy/custom-style-manager";

// export default
export const grapeplugin = grapesjs.plugins.add('gjs-preset-webpage', (editor, opts = {}) => {
  let config = opts;
 
  let defaults = {
    // Which blocks to add

    // Removed some elements as per FUN2-1492
    blocks: ['link-block', 'add_to_cart1', 'add_to_cart2', 'add_to_cart3', 'add_to_cart4', 'add_to_cart5', 'add_to_cart6', 'banner1', 'banner2',
        'banner3', 'banner4', 'banner5', 'banner6', 'banner7', 'banner8', 'button1', 'button2', 'feature1', 'feature2', 'feature3', 'feature3', 'feature4', 'feature5', "feature6",
        'footer1', 'footer2', 'footer3', 'footer4', 'footer5', 'footer6', 'navigation1', 'navigation2', 'navigation3', 'navigation4', 'navigation5', "navigation6", 'pricing1',
        'pricing2', 'pricing3', 'pricing4', 'pricing5', 'pricing6', 'team1', 'team2', 'team3', 'team4', 'team5', 'team6', 'testimonial1', 'testimonial2', 'testimonial3',
        'testimonial4', 'testimonial5', 'testimonial6', 'testimonial7', 'productCheckbox', 'productRadioButtons'
    ],
  
    // Modal import title
    modalImportTitle: 'Import',

    // Modal import button text
    modalImportButton: 'Import',

    // Import description inside import modal
    modalImportLabel: '',

    // Default content to setup on import model open.
    // Could also be a function with a dynamic content return (must be a string)
    // eg. modalImportContent: editor => editor.getHtml(),
    modalImportContent: '',

    // Code viewer (eg. CodeMirror) options
    importViewerOptions: false,

    // Confirm text before cleaning the canvas
    textCleanCanvas: 'Are you sure to clean the canvas?',

    // Show the Style Manager on component change
    showStylesOnChange: 1,

    // Text for General sector in Style Manager
    textGeneral: 'General',

    // Text for Layout sector in Style Manager
    textLayout: 'Layout',

    // Text for Typography sector in Style Manager
    textTypography: 'Font',

    // Text for Decorations sector in Style Manager
    textDecorations: 'Decorations',

    // Text for Extra sector in Style Manager
    textExtra: 'Extra',

    // Use custom set of sectors for the Style Manager
    customStyleManager: customStyleManager,

    // `grapesjs-blocks-basic` plugin options
    // By setting this option to `false` will avoid loading the plugin
    blocksBasicOpts: {blocksBasicOpts: { blocks: ['link-block', 'quote', 'image', 'video', 'text', 'column1', 'column2', 'column3'], stylePrefix: 'fk-' }},

    // `grapesjs-navbar` plugin options
    // By setting this option to `false` will avoid loading the plugin
    navbarOpts: {},

    // `grapesjs-component-countdown` plugin options
    // By setting this option to `false` will avoid loading the plugin
    countdownOpts: {},

    // `grapesjs-plugin-forms` plugin options
    // By setting this option to `false` will avoid loading the plugin
    formsOpts: {},

    // `grapesjs-plugin-export` plugin options
    // By setting this option to `false` will avoid loading the plugin
    exportOpts: false,

    // `grapesjs-aviary` plugin options, disabled by default
    // Aviary library should be included manually
    // By setting this option to `false` will avoid loading the plugin
    aviaryOpts: false,

    // `grapesjs-plugin-filestack` plugin options, disabled by default
    // Filestack library should be included manually
    // By setting this option to `false` will avoid loading the plugin
    filestackOpts: false,
  };

  // Load defaults
  for (let name in defaults) {
    if (!(name in config))
      config[name] = defaults[name];
  }

  const {
    blocksBasicOpts,
    navbarOpts,
    countdownOpts,
    formsOpts,
    exportOpts,
    aviaryOpts,
    filestackOpts
  } = config;

  // Load plugins
  blocksBasicOpts && pluginBlocks(editor, blocksBasicOpts);
  navbarOpts && pluginNavbar(editor, navbarOpts);
  countdownOpts && pluginCountdown(editor, countdownOpts);
  formsOpts && pluginForms(editor, formsOpts);
  exportOpts && pluginExport(editor, exportOpts);
  aviaryOpts && pluginAviary(editor, aviaryOpts);
  filestackOpts && pluginFilestack(editor, filestackOpts);

  // Load components
  components(editor, config);

  // Load blocks
  blocks(editor, config);

  // Load commands
  commands(editor, config);

  // Load panels
  panels(editor, config);

  // Load styles
  styles(editor, config);

});