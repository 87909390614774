import { CreateFunnel as CreateFunnelView } from "./create-funnel";
import { bindActionCreators } from "redux";
import get from "lodash/get";
import { connect } from "react-redux";
import * as funnelActions from "../actions";
import { getFunnels } from "./../selectors";
import { manageAuthorState } from "./../../author/selectors";
import { loadCampaigns } from "./../../campaign/action";
import { manageCampaignState, manageProductsState } from "./../../campaign/selectors";

function getFunnelById(funnels, id) {
    //eslint-disable-next-line
    const funnel = funnels.filter(funnel => funnel.id == id);
    if (funnel.length) return funnel[0];
    return null;
}

function mapStateToProps(state, ownProps) {
    const funnelId = 0;
    const valuesFromPreviousPage = get(ownProps, ["location", "state"]);
    let funnelTitle = "";
    let nextPageNavigation = {
        url: "/funnels",
        openFunnelTemplatesOnLoad: false,
    };
    if (valuesFromPreviousPage) {
        funnelTitle = get(valuesFromPreviousPage, ["title"]) || "";
        const isNavigateToVisualizer = get(valuesFromPreviousPage, ["navigateToVisualizer"]);
        nextPageNavigation = {
            url: (isNavigateToVisualizer) ? "/editfunnel/" : "/funnels",
            isNavigateToVisualizer,
            openFunnelTemplatesOnLoad: get(valuesFromPreviousPage, ["openFunnelTemplates"]),
            funnelTemplateTypeId: get(valuesFromPreviousPage, ["funnelTemplateTypeId"]),
        };
    }
    let funnel = { title: funnelTitle, author: "", status: "", campaignId: "", funnelType: "", offerId: "", upsaleId: "" };
    if (funnelId && getFunnels(state).length > 0) {
        funnel = getFunnelById(getFunnels(state), funnelId);
    }
    const authorsFormattedForDropdown = manageAuthorState(state).map(author => {
        return {
            value: author.id,
            text: author.firstName + " " + author.lastName
        };
    });
    const campaignsFormattedForDropdown = manageCampaignState(state).map(campaigns => {
        return {
            value: campaigns.campaignId,
            text: campaigns.campaignName,
            products: campaigns.products,
        };
    });
    let offersFormattedForDropdown = [];
    let upsalesFormattedForDropdown = [];
    manageProductsState(state, funnel).filter(products => {
        if (products.productType === "OFFER") {
            offersFormattedForDropdown.push(products);
        } else {
            upsalesFormattedForDropdown.push(products);
        }
    });
    offersFormattedForDropdown = offersFormattedForDropdown.map(product => {
        return {
            value: product.campaignProductId,
            text: product.productName,
            type: product.productType

        };
    });
    upsalesFormattedForDropdown = upsalesFormattedForDropdown.map(product => {
        return {
            value: product.campaignProductId,
            text: product.productName,
            type: product.productType
        };
    });
    const funnelType = [{ value: "singleProduct", text: "Single Product" }, { value: "multiProduct", text: "Multi Product" }];
    return {
        funnel: funnel,
        authors: authorsFormattedForDropdown,
        campaigns: campaignsFormattedForDropdown,
        offers: offersFormattedForDropdown,
        funnelType: funnelType,
        error: state.FUNNELS.error,
        products: state.FUNNELS.products,
        merchants: state.FUNNELS.merchants,
        isError: state.FUNNELS.isError,
        isLoading: state.FUNNELS.isLoading,
        upsales: upsalesFormattedForDropdown,
        funnels: state.FUNNELS.funnels,
        nextPageNavigation,
    };
}


function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators(funnelActions, dispatch),
        loadCampaigns: () => dispatch(loadCampaigns()),
        loadProducts: () => dispatch(funnelActions.loadProducts()),
        loadMerchants: () => dispatch(funnelActions.loadMerchants()),
        createCampaign: (campaign, upsell, offer) => dispatch(funnelActions.createCampaign(campaign, upsell, offer))
    };
}

export const CreateFunnel = connect(mapStateToProps, mapDispatchToProps)(CreateFunnelView);