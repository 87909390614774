import { APIHelper } from "../utilities";
import {
    rootUrl,
    googleFontsUrl,
} from "./urls";
import axios from "axios";

export function assetPost(assetName ,{ headers = {} }) {
    let values = Object.values(assetName);
    let api;
    values.forEach(asset => {
        const assetFile = asset;
        var allowedFileTypes = ["image/png", "image/jpeg", "image/gif"];
        const fileAsBlob = new Blob([assetFile], { type: allowedFileTypes });
        let funnelFormData = new FormData();
        funnelFormData.append("name", assetFile.name);
        funnelFormData.append(`${asset.name}`, fileAsBlob, `${asset.name}`);
        api = APIHelper.baseRequest(APIHelper.POST, `${rootUrl}/Asset`, {
            contentType: APIHelper.getMultipartReqHeader(),
            requestBody: funnelFormData,
            headers
        });
    });
    return api;
}

export function assetGet() {
    // return APIHelper.baseRequest(APIHelper.GET, `${rootUrl}/Asset`, { contentType: APIHelper.getMultipartReqHeader(), headers: APIHelper.getGenericHeaders() });
    return axios.get(`${rootUrl}/Asset`, {
        params: {},
        headers: APIHelper.getGenericHeaders()
    }).then(function (data) {
        return data;
    }).catch(error => {
        return error.response;
    });
}


export function assetDelete(assetName ,{ headers = {} }) {
    const assetFile = assetName.attributes.src;
    const n = assetName.attributes.src.lastIndexOf("/");
    const deleteFile = assetFile.substring(n + 1);
    return APIHelper.baseRequest(APIHelper.DELETE, `${rootUrl}/Asset/${deleteFile}`, { headers });
}

export function loadGoogleFonts({ headers = {} }) {
    return APIHelper.baseRequest(APIHelper.GET, `${googleFontsUrl}`, { headers });
}