import React from "react";



export class Placeholder extends React.Component {
    render() {
        return (
            <div>
                <h1>Coming Soon</h1>
            </div>
        );
    }
}