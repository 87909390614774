import { VisualizerDomain as Domain } from "./visualizer-domain";
import { connect } from "react-redux";
import { funnelActions, visualizerActions } from "../../../actions";
import * as selectors from "../../../selectors/funnel-selectors";
import * as visualizerSelectors from "../../../selectors/visualizer-selectors";

function mapStateToProps(state) {
    const importedFunnel = selectors.getImportedFunnel(state);
    const funnel = importedFunnel ? importedFunnel : selectors.getFunnelData(state);
    const visualizerData = visualizerSelectors.getVisualizerData(state);
    const domainList = selectors.getDomainList(state);
    const selectedDomainForFunnel = selectors.getSelectedDomainForFunnel(state);
    return {
        funnel,
        visualizerData,
        domainList,
        selectedDomainForFunnel,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        publishFunnel: (funnelReferenceId) => dispatch(funnelActions.publishFunnel(funnelReferenceId)),
        getDomainListForUser: (funnelReferenceId) => dispatch(funnelActions.getDomainListForUser(funnelReferenceId)),
        setDomainForFunnel: (selectedDomainAndFunnelId) => dispatch(funnelActions.setDomainForFunnel(selectedDomainAndFunnelId)),
        clearDomainForFunnelData: () => dispatch(funnelActions.clearDomainForFunnelData()),
        updateVisualizerData: (params) => dispatch(visualizerActions.updateVisualizerData(params)),
    };
}

export const VisualizerDomain = connect(mapStateToProps, mapDispatchToProps)(Domain);
