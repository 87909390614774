import {
    GOOGLE_FONT_LOADING_SUCCESS,
} from "./constants";

const initialState = {
    googleFonts: [],
};

export const COMMON_ASSETS_REDUCER_KEY = "COMMON_ASSETS";

export function reducer(state = initialState, action) {
    switch (action.type) {
        case GOOGLE_FONT_LOADING_SUCCESS:
            return {
                ...state,
                googleFonts: action.payload ? action.payload : [],
            };
        default:
            return state;
    }
}