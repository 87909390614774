export class Campaign {
    constructor(obj = {}) {
        this.campaignId = obj.campaignId;
        this.campaignName = obj.campaignName;
        this.campaignTOS = obj.campaignTOS;
        this.campaignType = obj.campaignType;
        this.captureOnShipment = obj.captureOnShipment;
        this.countries = Array.from([...obj.countries], (country) => new Country(country));
        this.coupons = obj.coupons;
        this.currency = obj.currency;
        this.eSignature = obj.eSignature;
        this.enableFraudPlugin = obj.enableFraudPlugin;
        this.externalCampaignId = obj.externalCampaignId;
        this.insureShipBillerId = obj.insureShipBillerId;
        this.insureShipLoadBalancerId = obj.insureShipLoadBalancerId;
        this.isLocked = obj.isLocked;
        this.listSalesEntry = obj.listSalesEntry;
        this.preauthOnly = obj.preauthOnly;
        this.products = Array.from([...obj.products], (product) => new Product(product));
        this.requireQA = obj.requireQA;
        this.requireTOS = obj.requireTOS;
        this.taxes = obj.taxes;
        this.visible = obj.visible;
    }

    toString = () => {
        return JSON.stringify(this);
    }
}

export class Country {
    constructor(obj = {}) {
        this.countryCode = obj.countryCode;
        this.countryName = obj.countryName;
    }
}

export class Product {
    constructor(obj = {}) {
        this.billerId = obj.billerId;
        this.billerName = obj.billerName;
        this.billingCycleType = obj.billingCycleType;
        this.campaignProductId = obj.campaignProductId;
        this.clientProductId = obj.clientProductId;
        this.clubPluginId = obj.clubPluginId;
        this.cycle1_billDelay = obj.cycle1_billDelay;
        this.cycle1_isShippable = obj.cycle1_isShippable;
        this.cycle1_price = obj.cycle1_price;
        this.cycle1_shipPrice = obj.cycle1_shipPrice;
        this.cycle1_productId = obj.campaignProductId;
        this.cycle1_combinationId = obj.cycle1_combinationId;
        this.cycle2_billDelay = obj.cycle2_billDelay;
        this.cycle2_isShippable = obj.cycle2_isShippable;
        this.cycle2_price = obj.cycle2_price;
        this.cycle2_shipPrice = obj.cycle2_shipPrice;
        this.cycle2_productId = obj.cycle2_productId;
        this.cycle2_combinationId = obj.cycle2_combinationId;
        this.cycle3_billDelay = obj.cycle3_billDelay;
        this.cycle3_isShippable = obj.cycle3_isShippable;
        this.cycle3_price = obj.cycle3_price;
        this.cycle3_shipPrice = obj.cycle3_shipPrice;
        this.cycle3_productId = obj.cycle3_productId;
        this.cycle3_combinationId = obj.cycle3_combinationId;
        this.externalProductId = obj.externalProductId;
        this.finalBillingCycle = obj.finalBillingCycle;
        this.fulfillmentCycleType = obj.fulfillmentCycleType;
        this.fulfillmentHouseId = obj.fulfillmentHouseId;
        this.isProductCombo = obj.isProductCombo;
        this.loadBalancerId = obj.loadBalancerId;
        this.loadBalancerName = obj.loadBalancerName;
        this.maxOrderPrice = obj.maxOrderPrice;
        this.maxOrderQty = obj.maxOrderQty;
        this.minOrderPrice = obj.minOrderPrice;
        this.price = obj.price;
        this.productCost = obj.productCost;
        this.productDescription = obj.productDescription;
        this.productId = obj.productId;
        this.productName = obj.productName;
        this.productSku = obj.productSku;
        this.productType = obj.productType;
        this.shippingCost = obj.shippingCost;
        this.shippingPrice = obj.shippingPrice;
        this.staggerFulfillments = obj.staggerFulfillments;
        this.staggerIntervalCycles = obj.staggerIntervalCycles;
        this.trialAuthType = obj.trialAuthType;
        this.trialBillerId = obj.trialBillerId;
        this.trialBillerName = obj.trialBillerName;
        this.trialEnabled = obj.trialEnabled;
        this.trialLoadBalancerId = obj.trialLoadBalancerId;
        this.trialLoadBalancerName = obj.trialLoadBalancerName;
        this.trialType = obj.trialType;
        this.weight = obj.weight;
    }
}