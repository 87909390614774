import React from "react";
import pace from "../vendors/pace/pace";

export class Progress extends React.Component {
    componentDidMount() {
        pace.start();
    }

    render() {
        return (null);
    }
}