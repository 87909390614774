import React, { Component } from "react";
import PropTypes from "prop-types";
import { TextInput, funnelTitleError } from "../commons";

export class Form extends Component {

    constructor(props) {
        super(props);
    }
    render() {
        const {
            isError,
            isLoading,
            funnel,
            onChange,
            onCancel,
            onSave,
            isDefaultOptionEnabled,
            errors } = this.props;
        return (
            <>
                {isError && !isLoading ? <div className="alert alert-danger"><i className="fa fa-close fa-lg m-r-xs" /> Something Went Wrong... </div> : ""}
                {isLoading && !isDefaultOptionEnabled && <div className="alert alert-info"><i className="fa fa-spinner fa-pulse fa-lg m-r-xs" /> Please wait...</div>}
                <form className="funnelForm">
                    <TextInput
                        name="title"
                        label="Title"
                        value={funnel.title}
                        onChange={onChange}
                        error={errors.title}
                        errorMessage={funnelTitleError}
                    />
                    {this.props.children}
                </form>
                <div>
                    <input
                        type="submit"
                        value={"Save"}
                        className="btn btn-primary"
                        onClick={onSave} />
                    <input
                        type="button"
                        value="Cancel"
                        className="btn btn-default m-l-xs"
                        onClick={onCancel} />
                </div>
            </>
        );
    }
}

Form.propTypes = {
    funnel: PropTypes.object.isRequired,
    onSave: PropTypes.func.isRequired,
    onChange: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired,
    loading: PropTypes.bool,
    errors: PropTypes.object,
    isLoading: PropTypes.bool,
    isError: PropTypes.bool,
    isEdit: PropTypes.bool,
    isDefaultOptionEnabled: PropTypes.bool,
    children: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.node),
        PropTypes.node
    ])
};
