import React, { useContext, useState, useEffect } from "react";
import { VisualizerContext } from "./";
import Zoom from "./components/header/zoom";
import { CustomDomain } from "./components/header/custom-domain";
import { PublishFunnel } from "./components/VisualizerHeader/publish-funnel";
import LiveSite from "./components/header/live-site";
import FunnelTitle from "./components/header/funnel-title";
import VisualizerEmitter from "./visualizer-emitter";
import {
    updateZoomValue,
} from "./actions/visualizerActions";
import {
    triggerNofiticationError,
} from "../../commons/notification";
import Analytics from "./components/VisualizerHeader/analytics";

const FunnelVisualizerHeader = () => {
    const VisualizerContextData = useContext(VisualizerContext);
    const [activeButton, setActiveButton] = useState(false);
    const {
        funnelHelpers: {
            funnelZoomValues,
        },
        domainList,
        funnelData,
        allCampaigns,
        updateFunnelInfo,
        saveDomain,
        defaultPage,
        funnelHelpers,
        isWorking
    } = VisualizerContextData;
    const [enableLiveSite, setLiveSite] = useState(funnelData.status);
    useEffect(() => {
        VisualizerEmitter.addListener("HEADER-BUTTONS:RESET", () => setActiveButton(null));
        VisualizerEmitter.addListener("RIGHTPANEL:CLOSE", () => setActiveButton(null));
    }, []);
    /*
     *
     * Very weird way to track a button state
     * [Todo] - Fix this in a more ideal way. Currently not able to think anything else
     *
     *
     */
    const emitDottedLine = () => {
        setActiveButton("dottedLine");
        VisualizerEmitter.emit("DOTTED-LINE:READY");
    };
    const emitTextElement = () => {
        setActiveButton("textButton");
        VisualizerEmitter.emit("TEXT-ELEMENT:READY");
    };
    const callBackLiveSite = () => {
        setLiveSite(true);
    };
    const publish = () => {
        VisualizerEmitter.emit("FUNNEL:PUBLISH", { defaultPage: defaultPage, domainList: domainList, callBackLiveSite });
    };
    const handleZoomUpdate = (zoomValue) => {
        const zoomParams = {
            zoomValue: zoomValue / 100,
            layoutZoom: 1,
            graphZoom: 1,
        };
        const params = {
            zoomParams,
            funnelReferenceId: funnelHelpers.getFunnelValueOf("referenceId"),
        };
        VisualizerEmitter.emit("ZOOM:EVENT", zoomValue);
        updateZoomValue(params)
            .catch(err => {
                triggerNofiticationError({ message: err.message });
            });
    };
    return (
        <div className="visualizer-header">
            <div className="header-content row">
                <div className="col-xs-4">
                    <FunnelTitle
                        funnelData={funnelData}
                        allCampaigns={allCampaigns}
                        updateFunnelInfo={updateFunnelInfo}
                    />
                </div>
                <div className="col-xs-8 header-content">
                    <span
                        onClick={emitTextElement}
                        className={`m-l-xs text-element ${activeButton === "textButton" ? "active" : ""}`}
                    >
                        A
                    </span>
                    <span
                        onClick={emitDottedLine}
                        className={`m-l-xs m-r-xs dottedLine ${activeButton === "dottedLine" ? "active" : ""}`}
                    />
                    <CustomDomain
                        funnelReferenceId={funnelData.referenceId}
                        domainList={domainList}
                        saveDomain={saveDomain}
                    />
                    <PublishFunnel
                        onClick = {publish}
                    />
                    <Analytics/>
                    <Zoom
                        values={funnelZoomValues}
                        updateZoomValue={handleZoomUpdate}
                    />
                    <LiveSite
                        isEnabled={enableLiveSite}
                        domainList={domainList}
                    />
                </div>
            </div>
        </div>
    );
};



export default FunnelVisualizerHeader;
