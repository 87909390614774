import React from "react";
import PropTypes from "prop-types";
import { formatPriceUptoTwoDecimal } from "../utilities/formatters";

export const TemplateCard = ({ template, onTemplateSelected, oldfunnel,showPreview, classNames, showPrice }) => {
    let cardContainerClass = (oldfunnel ? "col-md-3 col-xs-12 col-sm-12" : classNames);
    const { isPurchased } = template;
    return (
        <div className= {`${cardContainerClass} thumbnail-card-item`}>
            <div className="ibox">
                <div className="ibox-content template-content template-list">
                    <div className="template-img" style={{ backgroundImage: `url(${template.thumbnailUrl})` }}>
                        <a className="preview-template" onClick= {() => showPreview(template)}><i className="fa fa-eye"></i></a>
                        <a className="choose" onClick= {() => onTemplateSelected(template)}><div className="choose-hand"></div><div>Choose Template</div></a>
                    </div>
                    {
                        isPurchased && (
                            <div className="template-card-price">
                                Purchased
                            </div>
                        )
                    }
                    {
                        !isPurchased && showPrice && (
                            <div className="template-card-price">
                                { formatPriceUptoTwoDecimal(template.price) }
                            </div>
                        )
                    }
                </div>
            </div>
        </div>
    );
};
TemplateCard.propTypes = {
    template: PropTypes.object,
    onTemplateSelected: PropTypes.func,
    showPreview: PropTypes.func,
    splitTemplateSelected: PropTypes.func,
    actions: PropTypes.object,
    oldfunnel: PropTypes.string,
    classNames: PropTypes.string,
    showPrice: PropTypes.bool,
};
TemplateCard.defaultProps = {
    showPreview: (template) => {
        const win = window.open(template.htmlUrl, "_blank");
        win.focus();
    },
    classNames: "col-md-6 col-xs-12",
    showPrice: false,
};