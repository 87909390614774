import React from "react";
import PropTypes from "prop-types";

export const SelectInput = ({ isGroupEnabled, isDefaultOptionEnabled, name, disabled = false, label, onChange, defaultOption, value, error, errorMessage, options = [], groupLabels = [], onClear = null }) => {
    const selectClass = "form-control";
    return (
        <div className="form-group">
            {label &&
                <label htmlFor={name}>{label}</label>
            }
            <div className="field relative">
                {/* Note, value is set here rather than on the option - docs: https://facebook.github.io/react/docs/forms.html */}
                <select
                    disabled={disabled}
                    name={name}
                    value={value}
                    onChange={onChange}
                    className={`${selectClass} search-box`}>
                    {isDefaultOptionEnabled ?
                        <option value="">{defaultOption}</option> :
                        <option value="" disabled selected>{defaultOption}</option>
                    }
                    {isGroupEnabled ?
                        groupLabels.map(gpLabel =>
                            <optgroup label={gpLabel}>
                                {options.filter(op => {
                                    return op.groupLabel === gpLabel;
                                }).map((option) => {
                                    return <option key={option.value} value={option.value}>{option.text}</option>;
                                })}
                            </optgroup>
                        )
                        :
                        options.map((option) => {
                            return <option key={option.value} value={option.value}>{option.text}</option>;
                        })
                    }
                </select>
                {onClear ? <div class="close-icon" onClick={onClear}><i class="fa fa-times" aria-hidden="true"></i></div> : null}
                {error && <div className="alert alert-danger m-t-xs">{errorMessage}</div>}
            </div>
        </div>
    );
};

SelectInput.propTypes = {
    name: PropTypes.string.isRequired,
    label: PropTypes.string,
    onChange: PropTypes.func.isRequired,
    defaultOption: PropTypes.string,
    value: PropTypes.string,
    groupLabels: PropTypes.array,
    error: PropTypes.bool,
    className: PropTypes.string,
    errorMessage: PropTypes.string,
    options: PropTypes.arrayOf(PropTypes.object),
    onClear: PropTypes.func,
    disabled: PropTypes.bool,
    isDefaultOptionEnabled: PropTypes.bool,
    isGroupEnabled: PropTypes.bool
};