import * as api from "../../api/funnelApi";
import * as savePageApi from "../../api/savePageApi";
import { purchaseOrderApi, getPurchasedTemplatesForTenant } from "../../api/purchaseApi";
import { APIHelper, LocalStorageHelper } from "../../utilities";

import {
    DEFAULT_FUNNELS_SUCCESS_MESSAGE,
    LOAD_PREMIMUMFUNNELTEMPLATES_SUCCESS,
} from "../actions";


const VISUALIZER = "create-funnel-visualizer/";
export const MODIFY_TEMPLATE = VISUALIZER + "MODIFY_TEMPLATE";
export const IS_LOADING = `${VISUALIZER}IS_LOADING`;
export const IS_FONT_LOADING = `${VISUALIZER}IS_FONT_LOADING`;
export const GET_FUNNEL_SUCCESS = `${VISUALIZER}GET_FUNNEL_SUCCESS`;
export const GET_FONTS_SUCCESS = `${VISUALIZER}GET_FONTS_SUCCESS`;
export const UPDATE_FUNNEL_SUCCESS = `${VISUALIZER}UPDATE_FUNNEL_SUCCESS`;
export const UPDATE_PAGE_SUCCESS = `${VISUALIZER}UPDATE_PAGE_SUCCESS`;
export const FUNNEL_PUBLISH_SUCCESS = `${VISUALIZER}UPDATE_FUNNEL_SUCCESS`;
export const LOAD_FUNNEL_SUCCESS = `${VISUALIZER}LOAD_FUNNEL_SUCCESS`;
export const LOAD_METAJSON_SUCCESS = `${VISUALIZER}LOAD_METAJSON_SUCCESS`;
export const LOAD_BUTTONS_SUCCESS = `${VISUALIZER}LOAD_BUTTONS_SUCCESS`;
export const EDIT_FUNNEL_SUCCESS = `${VISUALIZER}EDIT_FUNNEL_SUCCESS`;
export const SET_TEMPLATE_SUCCESS = `${VISUALIZER}SET_TEMPLATE_SUCCESS`;
export const SET_CURRENT_PAGE_ID = "SET_CURRENT_PAGE_ID";
export const LOAD_PAGE_VIEW_SUCCESS = `${VISUALIZER}LOAD_PAGE_VIEW_SUCCESS`;
export const SAVE_PAGE_VIEW_SUCCESS = `${VISUALIZER}SAVE_PAGE_VIEW_SUCCESS`;
export const GET_TEMPLATE_SUCCESS = `${VISUALIZER}GET_TEMPLATE_SUCCESS`;
export const IMPORT_PAGE_SUCCESS = `${VISUALIZER}IMPORT_PAGE_SUCCESS`;
export const GET_META_URL_SUCCESS = `${VISUALIZER}GET_META_URL_SUCCESS`;
export const GET_JSON_SUCCESS = `${VISUALIZER}GET_JSON_SUCCESS`;
export const GET_PAGE_SUCCESS = `${VISUALIZER}GET_PAGE_SUCCESS`;
export const CLEAR_FUNNEL_SUCCESS = `${VISUALIZER}CLEAR_FUNNEL_SUCCESS`;
export const LOAD_GOOGLE_FONT_SUCCESS = `${VISUALIZER}LOAD_GOOGLE_FONT_SUCCESS`;
export const EDIT_FUNNEL_ERROR = `${VISUALIZER}EDIT_FUNNEL_ERROR`;
export const CREATE_PAGE_ERROR = `${VISUALIZER}CREATE_PAGE_ERROR`;
export const UPDATE_FUNNEL_ERROR = `${VISUALIZER}UPDATE_FUNNEL_ERROR`;
export const UPDATE_PAGE_ERROR = `${VISUALIZER}UPDATE_PAGE_ERROR`;
export const CLEAR_PAGE_DATA = `${VISUALIZER}CLEAR_PAGE_DATA`;
export const CLEAR_JSON_DATA = `${VISUALIZER}CLEAR_JSON_DATA`;
export const FUNNEL_PUBLISH_ERROR = `${VISUALIZER}FUNNEL_PUBLISH_ERROR`;
export const IMPORT_PAGE_ERROR = `${VISUALIZER}IMPORT_PAGE_ERROR`;
export const LOAD_DOMAIN_LIST_SUCCESS = `${VISUALIZER}LOAD_DOMAIN_LIST_SUCCESS`;
export const LOAD_DOMAIN_LIST_ERROR = `${VISUALIZER}LOAD_DOMAIN_LIST_ERROR`;
export const SET_DOMAIN_FOR_FUNNEL_SUCCESS = `${VISUALIZER}SET_DOMAIN_FOR_FUNNEL_SUCCESS`;
export const ADD_SET_DOMAIN_FOR_FUNNEL_SUCCESS = `${VISUALIZER}ADD_SET_DOMAIN_FOR_FUNNEL_SUCCESS`;
export const SPLIT_DUPLICATE_SUCCESS = `${VISUALIZER}SPLIT_DUPLICATE_SUCCESS`;
export const SPLIT_CHOOSE_TEMPLATE_SUCCESS = `${VISUALIZER}SPLIT_CHOOSE_TEMPLATE_SUCCESS`;
export const SET_DOMAIN_FOR_FUNNEL_ERROR = `${VISUALIZER}SET_DOMAIN_FOR_FUNNEL_ERROR`;
export const CLEAR_DOMAIN_FOR_FUNNEL_SUCCESS = `${VISUALIZER}CLEAR_DOMAIN_FOR_FUNNEL_SUCCESS`;
export const IMPORT_FUNNEL_SUCCESS = `${VISUALIZER}IMPORT_FUNNEL_SUCCESS`;
export const IMPORT_FUNNEL_ERROR = `${VISUALIZER}IMPORT_FUNNEL_ERROR`;
export const SPLIT_DUPLICATE_PAGE_ERROR = `${VISUALIZER}SPLIT_DUPLICATE_PAGE_ERROR`;
export const SPLIT_CHOOSE_TEMPLATE_ERROR = `${VISUALIZER}SPLIT_CHOOSE_TEMPLATE_ERROR`;
export const IS_IMPORT_FUNNEL_LOADING = `${VISUALIZER}IS_IMPORT_FUNNEL_LOADING`;
export const IMPORT_FUNNEL_SUCCESS_MESSAGE = `${VISUALIZER}IMPORT_FUNNEL_SUCCESS_MESSAGE`;
export const DEFAULT_FUNNEL_SUCCESS_MESSAGE = `${VISUALIZER}DEFAULT_FUNNEL_SUCCESS_MESSAGE`;
export const SET_SPLIT_SETTINGS_STATUS = `${VISUALIZER}SET_SPLIT_SETTINGS_STATUS`;
export const GET_THUMBNAIL_SUCCESS = `${VISUALIZER}GET_THUMBNAIL_SUCCESS`;
export const GET_THUMBNAIL_ERROR = `${VISUALIZER}GET_THUMBNAIL_ERROR`;

export function toggleLoading(status = false) {
    return { type: IS_LOADING, status };
}
export function toggleFontLoading(status = false) {
    return { type: IS_FONT_LOADING, status };
}
export function getFunnelSuccess(payload) {
    return {
        type: GET_FUNNEL_SUCCESS,
        payload
    };
}
export function getFontsSuccess(payload) {
    return {
        type: GET_FONTS_SUCCESS,
        payload
    };
}
export function updateFunnelSuccess(funnel) {
    return {
        type: UPDATE_FUNNEL_SUCCESS,
        funnel
    };
}
export function updatePageSuccess(funnel) {
    return {
        type: UPDATE_PAGE_SUCCESS,
        funnel
    };
}
export function splitDuplicatePageSuccess(funnel) {
    return {
        type: SPLIT_DUPLICATE_SUCCESS,
        funnel
    };
}
export function splitChooseTemplateSuccess(funnel) {
    return {
        type: SPLIT_CHOOSE_TEMPLATE_SUCCESS,
        funnel
    };
}
export function funnelPublishSuccess(funnel) {
    return {
        type: FUNNEL_PUBLISH_SUCCESS,
        funnel
    };
}
export function getPagesSuccess(payload) {
    return {
        type: GET_PAGE_SUCCESS,
        payload
    };
}
export function clearFunnelDataSuccess(payload) {
    return {
        type: CLEAR_FUNNEL_SUCCESS,
        payload
    };
}

export function getTemplateSuccess(htmlPage) {
    return {
        type: GET_TEMPLATE_SUCCESS,
        htmlPage
    };
}
export function getJsonSuccess(payload) {
    return {
        type: GET_JSON_SUCCESS,
        payload
    };
}
export function importPageSuccess(payload) {
    return {
        type: IMPORT_PAGE_SUCCESS,
        payload
    };
}
export function getMetaUrlSuccess(payload) {
    return {
        type: GET_META_URL_SUCCESS,
        payload
    };
}
export function loadMetaJsonSuccess(payload) {
    return {
        type: LOAD_METAJSON_SUCCESS,
        payload
    };
}
export function loadButtonsSuccess(payload) {
    return {
        type: LOAD_BUTTONS_SUCCESS,
        payload
    };
}
export function loadFunnelSuccess(payload) {
    return {
        type: LOAD_FUNNEL_SUCCESS,
        payload
    };
}
export function setTemplateSuccess(payload) {
    return {
        type: SET_TEMPLATE_SUCCESS,
        payload
    };
}
export function loadPageViewSuccess(pageData) {
    return {
        type: LOAD_PAGE_VIEW_SUCCESS,
        pageData
    };
}
export function savePageViewSuccess(status = false) {
    return {
        type: SAVE_PAGE_VIEW_SUCCESS,
        status
    };
}
export function clearPageData(status = false) {
    return {
        type: CLEAR_PAGE_DATA,
        status
    };
}
export function clearJsonData(status = false) {
    return {
        type: CLEAR_JSON_DATA,
        status
    };
}
export function editFunnelSuccess(funnel) {
    return { type: EDIT_FUNNEL_SUCCESS, funnel };
}
export function loadGoogleFontsSuccess(payload) {
    return { type: LOAD_GOOGLE_FONT_SUCCESS, payload };
}
export function editFunnelError(isError = false) {
    return { type: EDIT_FUNNEL_ERROR, isError };
}
export function createPageError(pageError = false) {
    return { type: CREATE_PAGE_ERROR, pageError };
}
export function updateFunnelError(isError = false) {
    return { type: UPDATE_FUNNEL_ERROR, isError };
}
export function updatePageError(isError = false) {
    return { type: UPDATE_PAGE_ERROR, isError };
}
export function funnelPublishError(isError = false) {
    return { type: FUNNEL_PUBLISH_ERROR, isError };
}
export function importPageError(importError = false) {
    return { type: IMPORT_PAGE_ERROR, importError };
}
export function splitDuplicatePageError(duplicateError = false) {
    return { type: SPLIT_DUPLICATE_PAGE_ERROR, duplicateError };
}
export function splitChooseTemplateError(chooseTemplateError = false) {
    return { type: SPLIT_CHOOSE_TEMPLATE_ERROR, chooseTemplateError };
}
export function getDomainListSuccess(domainList) {
    return {
        type: LOAD_DOMAIN_LIST_SUCCESS,
        domainList
    };
}
export function getDomainListError(loadDomainError = false) {
    return {
        type: LOAD_DOMAIN_LIST_ERROR,
        loadDomainError
    };
}

export function setDomainForFunnelSuccess(selectedDomainReferenceId) {
    return {
        type: SET_DOMAIN_FOR_FUNNEL_SUCCESS,
        selectedDomainReferenceId
    };
}

export function addAndSetDomainForFunnelSuccess(addedSelectedDomainReferenceId) {
    return {
        type: ADD_SET_DOMAIN_FOR_FUNNEL_SUCCESS,
        addedSelectedDomainReferenceId
    };
}


export function setDomainForFunnelError(selectedDomainForFunnelError) {
    return {
        type: SET_DOMAIN_FOR_FUNNEL_ERROR,
        selectedDomainForFunnelError
    };
}

export function getDefaultFunnelTemplatesSuccess(payload) {
    return {
        type: DEFAULT_FUNNELS_SUCCESS_MESSAGE,
        payload
    };
}

export function getDefaultFunnelTemplateSuccess(payload) {
    return {
        type: DEFAULT_FUNNEL_SUCCESS_MESSAGE,
        payload
    };
}

export function loadPremiumFunnelTemplatesSuccess(payload) {
    return { type: LOAD_PREMIMUMFUNNELTEMPLATES_SUCCESS, payload };
}
export function getPremiumFunnelTemplates(purchasedTemplates) {
    return function (dispatch) {
        const localstorageManager = new LocalStorageHelper();
        const accessToken = localstorageManager.load(LocalStorageHelper.keys.ACCESS_TOKEN);
        if (accessToken) {
            dispatch(toggleLoading(true));
            return api.getPremiumFunnelTemplates({ headers: APIHelper.getGenericHeaders() }).then((response) => {
                let { result: premiumTemplates } = response;
                if (purchasedTemplates) {
                    premiumTemplates.forEach((template) => {
                        const isPurchasedTemplated = purchasedTemplates.find((purchasedTemplate) => template.referenceId === purchasedTemplate.templateReferenceId);
                        if (isPurchasedTemplated) {
                            template.isPurchased = true;
                        }
                    });
                }
                dispatch(loadPremiumFunnelTemplatesSuccess(premiumTemplates));
                dispatch(toggleLoading(false));
            }).catch(error => {
                // TODO - Funnel Get should return empty array in case of 404
                dispatch(loadPremiumFunnelTemplatesSuccess([]));
                dispatch(toggleLoading(false));
                throw (error);
            });
        }
    };
}

export function getFunnel(funnelReferenceId, callback) {
    return function (dispatch) {
        dispatch(toggleLoading(true));
        return api.retrieveFunnel(funnelReferenceId, { headers: APIHelper.getGenericHeaders() }).then(funnelResponse => {
            const localStorage = new LocalStorageHelper();
            const currentId = localStorage.load("currentPageId");
            api.funnelMetaUrl(funnelResponse.metaJson, { headers: APIHelper.getGenericHeaders() }).then((response) => {
                dispatch(loadMetaJsonSuccess(response));
                dispatch(getFunnelSuccess({ funnelData: { ...funnelResponse, metaJsonUrl: response } }));
                setTimeout (function () {dispatch(toggleLoading(false));}, 1200);
                dispatch(savePageViewSuccess(true));
                setTimeout (function () {dispatch(savePageViewSuccess(false));}, 500);
            });
            callback && callback();
            return funnelResponse;
        }).catch(error => {
            dispatch(toggleLoading(false));
            throw (error);
        });
    };
}

export function getPages() {
    return function (dispatch) {
        // dispatch(toggleLoading(true));
        return api.getPages({ headers: APIHelper.getGenericHeaders() }).then(response => {
            dispatch(getPagesSuccess({ pages: response }));
            setTimeout (function () {dispatch(toggleLoading(false));}, 2000);
        }).catch(error => {
            dispatch(toggleLoading(false));
            throw (error);
        });
    };
}
export function getFonts(params) {
    return function (dispatch) {
        dispatch(toggleFontLoading(true));
        return api.getFonts(params,{ headers: APIHelper.getGenericHeaders() }).then(response => {
            dispatch(getFontsSuccess(response.result));
            dispatch(toggleFontLoading(false));
        }).catch(error => {
            dispatch(toggleFontLoading(false));
            throw (error);
        });
    };
}

export function loadMetaJson(funnel) {
    return function (dispatch) {
        return api.funnelMetaUrl(funnel.metaJson, { headers: APIHelper.getGenericHeaders() }).then((response) => {
            dispatch(loadMetaJsonSuccess(response));
            dispatch(toggleLoading(false));
        }).catch(error => {
            throw (error);
        });
    };
}
export function loadFonts() {
    return function (dispatch) {
        return api.loadGoogleFonts({ headers: APIHelper.getGenericHeaders() }).then((response) => {
            dispatch(loadGoogleFontsSuccess(response));
            dispatch(toggleLoading(false));
        }).catch(error => {
            throw (error);
        });
    };
}

export function loadButtons({ metaJsonUrl, isPageDeleted = false }, callback) {
    return function (dispatch) {
        // dispatch(toggleLoading(true));
        return api.funnelMetaUrl(metaJsonUrl, { headers: APIHelper.getGenericHeaders() }).then(response => {
            const parsedRes = JSON.parse(response);
            dispatch(loadButtonsSuccess(parsedRes));
            !isPageDeleted && dispatch(toggleLoading(false));
            callback && callback(parsedRes);
        }).catch(error => {
            dispatch(toggleLoading(false));
            throw (error);
        });
    };
}

export function createPage(params) {
    return function (dispatch) {
        // dispatch(toggleLoading(true));
        return api.editFunnel(params, { headers: APIHelper.getGenericHeaders(), requestBody: params.funnel }).then((response) => {
            dispatch(editFunnelSuccess(response));
        }).catch(error => {
            dispatch(editFunnelError(true));
            dispatch(createPageError(true));
            dispatch(toggleLoading(false));
            throw error;
        });
    };
}
export function errorClose() {
    return function (dispatch) {
        dispatch(createPageError(false));
        dispatch(importPageError(false));
        dispatch(importFunnelError(false));
    };
}
export function editFunnel(params) {
    return function (dispatch) {
        // dispatch(toggleLoading(true));
        return api.updateFunnel(params, { headers: APIHelper.getGenericHeaders(), requestBody: params.funnel }).then((response) => {
            dispatch(updateFunnelSuccess(response));
            dispatch(toggleLoading(false));
        }).catch(error => {
            dispatch(updateFunnelError(true));
            dispatch(toggleLoading(false));
            throw error;
        });
    };
}
export function setSplitPercentToPage(params) {
    return function (dispatch) {
        // dispatch(toggleLoading(true));
        return api.setSplitPercent(params, { headers: APIHelper.getGenericHeaders(), requestBody: params.funnel }).then((response) => {
            dispatch(updatePageSuccess(response));
            dispatch(toggleLoading(false));
        }).catch(error => {
            dispatch(updatePageError(true));
            dispatch(toggleLoading(false));
            throw error;
        });
    };
}
export function SplitPageDataUpdate(params) {
    const { funnel, sourcePage, currentPage, res } = params;
    return function (dispatch) {
        return api.getJsonData({
            componentJsonUrl: sourcePage.pageView[0].componentJson,
            stylesJsonUrl: sourcePage.pageView[0].stylesJson,
            htmlUrl: sourcePage.pageView[0].url
        }, { headers: APIHelper.getGenericHeaders(), requestBody: {} }).then((response) => {
            return api.getHtmlPage(sourcePage.pageView[0].url, { headers: APIHelper.getGenericHeaders(), requestBody: {} }).then((htmlPage) => {
                return api.funnelMetaUrl(sourcePage.pageView[0].metaJson, { headers: APIHelper.getGenericHeaders(), requestBody: {} }).then((pageData) => {
                    let jsPageData = {}, jsPageDataBefore = {}, newPageData = {}, newPageLinkData = {}, newLinkData = {},componentData,linkData,buttonData;
                    jsPageDataBefore = JSON.parse(pageData).jsPageData;
                    jsPageData = JSON.parse(pageData).jsPageData;
                    buttonData = JSON.parse(pageData).buttons;
                    linkData = JSON.parse(pageData).links;
                    const linkButtonData = [...buttonData,...linkData];
                    const buttonMetaData = {
                        buttons: [...buttonData],
                        links: [...linkData],
                        jsPageData
                    };
                    linkButtonData.map(item => {
                        componentData = item;
                        if ((componentData["data-id"] in jsPageData)) {
                            jsPageData[componentData["data-id"]] = {
                                ...jsPageData[componentData["data-id"]],
                                NEXT_PAGE: {
                                    referenceId: currentPage.referenceId,
                                    targetPage: currentPage.pageView[0].referenceId
                                },
                            };
                            jsPageData = {
                                ...jsPageData,
                                funnelData: res,
                            };
                        }
                    });
                    const { referenceId, fTemplateId } = sourcePage.pageView[0];
                    dispatch(savePageView({
                        component_json: response.setcomponentData,
                        styles_json: response.setstylesData,
                        pageViewReferenceId: referenceId,
                        pageName: referenceId,
                        templateId: fTemplateId,
                        funnelReferenceId: funnel.referenceId,
                        pageData: jsPageData,
                        buttonMetaData,
                        html: htmlPage,
                        isEdited: true,
                    }, true));
                });
            });
        });
    };
}
export function splitDuplicatePage(params) {
    const { funnel, sourcePage, currentPage } = params;
    return function (dispatch) {
        // dispatch(toggleLoading(true));
        return api.splitDuplicatePage(params, { headers: APIHelper.getGenericHeaders(), requestBody: params.funnel }).then((res) => {
            dispatch(splitDuplicatePageSuccess(res));
            if (sourcePage) {
                dispatch(SplitPageDataUpdate({ funnel, sourcePage, currentPage,res }));
            }
            dispatch(toggleLoading(false));
        }).catch(error => {
            dispatch(splitDuplicatePageError(true));
            dispatch(toggleLoading(false));
            throw error;
        });
    };
}
export function declareWinner(params, callback) {
    const { funnel, sourcePage, currentPage } = params;
    return function (dispatch) {
        // dispatch(toggleLoading(true));
        return api.declareWinner(params, { headers: APIHelper.getGenericHeaders(), requestBody: params.funnel }).then((res) => {
            dispatch(updatePageSuccess(res));
            if (sourcePage) {
                dispatch(SplitPageDataUpdate({ funnel, sourcePage, currentPage,res }));
            }
        });
    };
}
export function splitChooseTemplate(params) {
    const { funnel, sourcePage, currentPage } = params;
    return function (dispatch) {
        // dispatch(toggleLoading(true));
        return api.splitChooseTemplate(params, { headers: APIHelper.getGenericHeaders(), requestBody: params.funnel }).then((res) => {
            const newPage = res.page.filter(item => item.cellViewId === params.currentPage.cellViewId);
            return api.getTemplate(params, { headers: APIHelper.getGenericHeaders() }).then((response) => {
                if (sourcePage) {
                    dispatch(SplitPageDataUpdate({ funnel, sourcePage, currentPage,res }));
                }
                dispatch(savePageView({
                    html: response,
                    css: "",
                    pageViewReferenceId: newPage && newPage[1].pageView[0].referenceId,
                    pageName: params.pageViewReferenceId,
                    templateId: params.template.templateId,
                    cellViewId: params.currentPage.cellViewId,
                    funnelReferenceId: params.funnel.referenceId
                }, true));
                dispatch(splitChooseTemplateSuccess(response));
                dispatch(toggleLoading(false));
            });
        }).catch(error => {
            dispatch(splitChooseTemplateError(true));
            dispatch(toggleLoading(false));
            throw error;
        });
    };
}

export function updateShowSplitSetting(status) {
    return function (dispatch) {
        dispatch(setSplitSettingViewStatus(status));
    };
}
export function setSplitSettingViewStatus(status = false) {
    return {
        type: SET_SPLIT_SETTINGS_STATUS,
        status
    };
}

export function clonePage(params) {
    return function (dispatch) {
        // dispatch(toggleLoading(true));
        return api.clonePage(params, { headers: APIHelper.getGenericHeaders(), requestBody: params.funnel }).then((response) => {
            dispatch(loadFunnel(params.funnelReferenceId));
            dispatch(toggleLoading(false));
        }).catch(error => {
            dispatch(editFunnelError(true));
            dispatch(createPageError(true));
            dispatch(toggleLoading(false));
            throw error;
        });
    };
}
export function editPage(params) {
    return function (dispatch) {
        // dispatch(toggleLoading(true));
        return api.updatePage(params, { headers: APIHelper.getGenericHeaders(), requestBody: params.funnel }).then((response) => {
            dispatch(updatePageSuccess(response));
            // dispatch(toggleLoading(false));
        }).catch(error => {
            dispatch(updatePageError(true));
            dispatch(toggleLoading(false));
            throw error;
        });
    };
}
export function saveFonts(params) {
    return function (dispatch) {
        // dispatch(toggleLoading(true));
        return api.saveFonts(params, { headers: APIHelper.getGenericHeaders(), requestBody: params.funnel }).then((response) => {
            // dispatch(saveFontsSuccess(response));
            // dispatch(toggleLoading(false));
        }).catch(error => {
            // dispatch(saveFontsError(true));
            dispatch(toggleLoading(false));
            throw error;
        });
    };
}

export function loadFunnel(params) {
    return function (dispatch) {
        // dispatch(toggleLoading(true));
        return api.retrieveFunnel(params, { headers: APIHelper.getGenericHeaders() }).then((response) => {
            dispatch(loadFunnelSuccess(response));
            dispatch(toggleLoading(false));
        }).catch(error => {
            dispatch(editFunnelError(true));
            dispatch(toggleLoading(false));
            throw error;
        });
    };
}
export function deletePage(params, callback) {
    return function (dispatch) {
        // dispatch(toggleLoading(true));
        return api.deletePages(params, { headers: APIHelper.getGenericHeaders(), requestBody: params.funnel }).then((response) => {
            dispatch(editFunnelSuccess(response));
            return api.savefunnel({ jsonFile: params.deleteView, funnelReferenceId: params.funnel.referenceId }, { headers: APIHelper.getGenericHeaders(), requestBody: {} }).then((res) => {
                if (!params.isPageDeleted) {
                    dispatch(toggleLoading(false));
                }
                callback && callback();
            });
        }).catch(error => {
            dispatch(editFunnelError(true));
            dispatch(toggleLoading(false));
            throw error;
        });
    };
}

export function getTemplate(params) {
    return function (dispatch) {
        // dispatch(toggleLoading(true));
        return api.getTemplate(params, { headers: APIHelper.getGenericHeaders() }).then((response) => {
            dispatch(getTemplateSuccess(response));
        }).catch(error => {
            dispatch(toggleLoading(false));
            throw error;
        });
    };
}
export function getJsonData(params, callback) {
    return function (dispatch) {
        dispatch(toggleLoading(true));
        return api.getJsonData(params, { headers: APIHelper.getGenericHeaders() }).then((response) => {
            dispatch(loadButtons({ metaJsonUrl: params.metaJsonUrl }, () => {
                dispatch(getJsonSuccess(response));
                dispatch(toggleLoading(false));
                callback && callback(response);
            }));
        }).catch(error => {
            dispatch(toggleLoading(false));
            throw error;
        });
    };
}

export function getHtml(params) {
    return function (dispatch) {
        dispatch(toggleLoading(true));
        return api.getJsonData(params, { headers: APIHelper.getGenericHeaders() }).then((response) => {
            dispatch(getJsonSuccess(response));
            dispatch(toggleLoading(false));
        }).catch(error => {
            dispatch(toggleLoading(false));
            throw error;
        });
    };
}

export function savePageView(params, isLoading = false, callback) {
    return function (dispatch) {
        return savePageApi.savePage(params, { headers: APIHelper.getGenericHeaders(), requestBody: params.pageViewReferenceId })
            .then(() => {
                savePageApi.saveJSTemplate(params)
                    .then(() => {
                    });
                savePageApi.updateWebBuilderElements({
                    buttonMetaData: params.buttonMetaData,
                    funnelReferenceId: params.funnelReferenceId,
                    pageViewReferenceId: params.pageViewReferenceId,
                })
                    .then(() => {
                    });
            }).catch(error => {
                dispatch(toggleLoading(false));
                throw (error);
            });
    };
}

export function savePageViewHtml(params, callback) {
    return function (dispatch) {
        if (params.isWebBuilder) {
            dispatch(toggleLoading(true));
        }
        return savePageApi.saveHtmlPage(params, { headers: APIHelper.getGenericHeaders(), requestBody: params.pageViewReferenceId }).catch(error => {
            dispatch(toggleLoading(false));
            dispatch(getFunnel(params.funnelReferenceId, callback));
            throw (error);
        });
    };
}

export function savePageViewData(params, callback) {
    return function (dispatch) {
        return api.getHtmlPage(params.currentPage.pageView[0].url, { headers: APIHelper.getGenericHeaders(), requestBody: {} }).then((htmlPage) => {
            const { referenceId, fTemplateId } = params.currentPage.pageView[0];
            return api.funnelMetaUrl(params.currentPage.pageView[0].metaJson, { headers: APIHelper.getGenericHeaders(), requestBody: {} }).then((pageData) => {
                let jsPageData = {}, linkData, buttonData, muliSelectProductValueData;
                jsPageData = JSON.parse(pageData).jsPageData;
                muliSelectProductValueData = params.muliSelectProductValueData;
                buttonData = JSON.parse(pageData).buttons;
                linkData = JSON.parse(pageData).links;
                jsPageData = {
                    ...jsPageData,
                    muliSelectProductValueData
                };
                const buttonMetaData = {
                    buttons: [...buttonData],
                    links: [...linkData],
                    jsPageData
                };
                dispatch(savePageViewHtml({
                    pageViewReferenceId: referenceId,
                    pageName: referenceId,
                    templateId: fTemplateId,
                    funnelReferenceId: params.funnel.referenceId,
                    pageData: jsPageData,
                    buttonMetaData,
                    html: htmlPage,
                    isEdited: true
                }, true, callback));
            });
        }).catch(error => {
            dispatch(toggleLoading(false));
            throw error;
        });
    };
}

export function saveFunnel(params) {
    return function (dispatch) {
        return api.savefunnel(params, { headers: APIHelper.getGenericHeaders(), requestBody: {} }).then((funnelRes) => {
            dispatch(loadFunnelSuccess(funnelRes));
        }).catch(error => {
            dispatch(toggleLoading(false));
            throw error;
        });
    };
}
export function clearFunnelData() {
    return function (dispatch) {
        dispatch(clearFunnelDataSuccess(true));
    };
}
export function clearDomainForFunnelDataSuccess(payload) {
    return {
        type: CLEAR_DOMAIN_FOR_FUNNEL_SUCCESS,
        payload
    };
}

export function saveTemplatePageView(params) {
    return function (dispatch) {
        // dispatch(toggleLoading(true));
        const { funnelReferenceId, jsonFile, template } = params;
        return api.getTemplate(params, { headers: APIHelper.getGenericHeaders() }).then((response) => {
            dispatch(savePageView({
                html: response,
                css: "",
                pageViewReferenceId: params.pageViewReferenceId,
                pageName: params.pageViewReferenceId,
                templateId: params.templateId,
                cellViewId: params.cellViewId,
                funnelReferenceId: params.funnelReferenceId,
                isPremium: template.designer,
            }, true));
        }).catch(error => {
            dispatch(toggleLoading(false));
            throw error;
        });
    };
}

export function saveButtonData(params, callback) {
    return function (dispatch) {
        // GET HTML and CSS Data
        const { funnelReferenceId, jsonFile, isPageDeleted = false, isDeleteArrow = false, isArrowChange = false } = params;
        return api.getJsonData({
            componentJsonUrl: params.sourcePage.pageView[0].componentJson,
            stylesJsonUrl: params.sourcePage.pageView[0].stylesJson,
            htmlUrl: params.sourcePage.pageView[0].url
        }, { headers: APIHelper.getGenericHeaders(), requestBody: {} }).then((response) => {
            return api.getHtmlPage(params.sourcePage.pageView[0].url, { headers: APIHelper.getGenericHeaders(), requestBody: {} }).then((htmlPage) => {
                const { cellViewId } = params.sourcePage;
                const { referenceId, fTemplateId } = params.sourcePage.pageView[0];
                const buttonMetaData = {
                    buttons: [...params.newPageData],
                    links: [...params.newPageLinkData],
                    jsPageData: params.jsPageData
                };
                return api.savefunnel({ funnelReferenceId, jsonFile }, { headers: APIHelper.getGenericHeaders(), requestBody: {} }).then((res) => {
                    // dispatch(saveFunnel({ funnelReferenceId, jsonFile }));
                    // Append data to meta json file - cellview id of the link & pageData object
                    // Call savePageView Api with HTML, CSS, PageData & MetaJSON
                    return api.funnelMetaUrl(params.funnel.metaJson, { headers: APIHelper.getGenericHeaders() }).then((metaresponse) => {
                        dispatch(loadMetaJsonSuccess(metaresponse));
                        dispatch(savePageView({
                            component_json: response.setcomponentData,
                            styles_json: response.setstylesData,
                            pageViewReferenceId: referenceId,
                            pageName: referenceId,
                            templateId: fTemplateId,
                            cellViewId: cellViewId,
                            funnelReferenceId,
                            pageData: params.jsPageData,
                            buttonMetaData,
                            html: htmlPage,
                            isEdited: true,
                            isDeleteArrow,
                            isArrowChange
                        }, true, callback));
                    });
                });
            }).catch(error => {
                dispatch(toggleLoading(false));
                throw error;
            });
        });
    };
}
export function visualizerFunnelEdit(params, cb = null) {
    return function (dispatch) {
        const promiseArray = [];
        params.filteredPageList.forEach((buttonData) => {
            const promise = dispatch(editButtonData({ sourcePage: buttonData, salt: params.salt, funnelReferenceId: params.funnelReferenceId }));
            promiseArray.push(promise);
        });
        Promise.all(promiseArray).then(function (values) {
            cb && cb();
        });
    };
}
export function editButtonData(params, callback) {
    return function (dispatch) {
        // GET HTML and CSS Data
        const { funnelReferenceId, sourcePage, jsonFile, isPageDeleted = false } = params;
        // if (!isPageDeleted) {dispatch(toggleLoading(true));}
        return api.getJsonData({
            componentJsonUrl: sourcePage.pageView[0].componentJson,
            stylesJsonUrl: sourcePage.pageView[0].stylesJson,
            htmlUrl: sourcePage.pageView[0].url
        }, { headers: APIHelper.getGenericHeaders(), requestBody: {} }).then((response) => {
            return api.getHtmlPage(sourcePage.pageView[0].url, { headers: APIHelper.getGenericHeaders(), requestBody: {} }).then((htmlPage) => {
                const { cellViewId } = params;
                const { referenceId, fTemplateId } = sourcePage.pageView[0];
                return api.funnelMetaUrl(sourcePage.pageView[0].metaJson, { headers: APIHelper.getGenericHeaders() }).then(response => {
                    const metaResponse = JSON.parse(response);
                    metaResponse.buttons.map((data) => {
                        data["OFFER"] = null;
                        data["UPSELL"] = null;
                    });
                    metaResponse.jsPageData = {
                        salt: params.salt
                    };
                    const buttonMetaData = metaResponse;
                    return dispatch(savePageView({
                        component_json: response.setcomponentData,
                        styles_json: response.setstylesData,
                        pageViewReferenceId: referenceId,
                        pageName: referenceId,
                        templateId: fTemplateId,
                        cellViewId: cellViewId,
                        funnelReferenceId,
                        pageData: params.jsPageData,
                        buttonMetaData,
                        html: htmlPage,
                        isEdited: true
                    }, true, callback));
                }).catch(error => {
                    dispatch(toggleLoading(false));
                    throw error;
                });
            });
        });
    };
}
export function setCurrentPageId(currentPageId) {
    localStorage.setItem("currentPageId", currentPageId);
    return {
        type: SET_CURRENT_PAGE_ID,
        currentPageId
    };
}
export function publishFunnel(funnelReferenceId) {
    return function (dispatch) {
        dispatch(toggleLoading(true));
        return api.publishFunnel(funnelReferenceId, { headers: APIHelper.getGenericHeaders(), requestBody: {} }).then((response) => {
            dispatch(funnelPublishSuccess(response));
            dispatch(toggleLoading(false));
        }).catch(error => {
            dispatch(funnelPublishError(true));
            dispatch(toggleLoading(false));
            throw error;
        });
    };
}
export function importPage(params) {
    return function (dispatch) {
        dispatch(toggleLoading(true));
        return api.importPage(params, { headers: APIHelper.getGenericHeaders(), requestBody: {} }).then((response) => {
            dispatch(importPageSuccess(response.result));
            return api.getMetaUrl(params.funnel, { headers: APIHelper.getGenericHeaders(), requestBody: {} }).then((res) => {
                dispatch(getMetaUrlSuccess(res));
                dispatch(toggleLoading(false));
            });
        }).catch(error => {
            dispatch(importPageError(true));
            dispatch(toggleLoading(false));
            throw error;
        });
    };
}

export function getDomainListForUser(funnelReferenceId) {
    return function (dispatch) {
        dispatch(toggleLoading(true));
        return api.getDomainListForUser(funnelReferenceId, { headers: APIHelper.getGenericHeaders() }).then((response) => {
            dispatch(getDomainListSuccess(response.result));
            dispatch(getDefaultDomainId(response.result));
            dispatch(toggleLoading(false));
        }).catch(error => {
            dispatch(getDomainListError(error));
            dispatch(toggleLoading(false));
            throw error;
        });
    };
}

export function getDefaultDomainId(domainList) {
    const defaultSelectedDomain = domainList && domainList.filter((list) => list.selected === true);
    return function (dispatch) {
        const defaultDomainId = defaultSelectedDomain.length ?
            defaultSelectedDomain[0].referenceId :
            "";
        dispatch(setDomainForFunnelSuccess(defaultDomainId));
    };
}

export function setDomainForFunnel(selectedDomainAndFunnelId) {
    const localStorage = new LocalStorageHelper();
    return function (dispatch) {
        dispatch(toggleLoading(true));
        return api.setDomainForFunnel(selectedDomainAndFunnelId, { headers: APIHelper.getGenericHeaders() }).then((response) => {
            dispatch(setDomainForFunnelSuccess(response.result.referenceId));
            dispatch(getDomainListForUser(localStorage.load("funnelReferenceId")));
            dispatch(toggleLoading(false));
        }).catch(error => {
            dispatch(setDomainForFunnelError(error));
            dispatch(toggleLoading(false));
            throw error;
        });
    };
}

export function addAndSetDomainForFunnel(selectedDomainAndFunnelId) {
    const localStorage = new LocalStorageHelper();
    return function (dispatch) {
        dispatch(toggleLoading(true));
        return api.addAndSetDomainForFunnel(selectedDomainAndFunnelId, { headers: APIHelper.getGenericHeaders() }).then((response) => {
            dispatch(addAndSetDomainForFunnelSuccess(response));
            dispatch(getDomainListForUser(localStorage.load("funnelReferenceId")));
            dispatch(toggleLoading(false));
        }).catch(error => {
            dispatch(setDomainForFunnelError(error));
            dispatch(toggleLoading(false));
            throw error;
        });
    };
}

export function clearDomainForFunnelData() {
    return function (dispatch) {
        dispatch(clearDomainForFunnelDataSuccess(true));
    };
}

/*********************begin actions for import Funnel********************************/

export function toggleImportFunnelLoading(status = false) {
    return { type: IS_IMPORT_FUNNEL_LOADING, status };
}

export function importFunnelSuccess(payload) {
    return {
        type: IMPORT_FUNNEL_SUCCESS,
        payload
    };
}

export function importFunnelError(importFunnelError = false) {
    return {
        type: IMPORT_FUNNEL_ERROR,
        importFunnelError
    };
}

export function showSuccessMessage(showImportFunnelSuccessMessage = false) {
    return {
        type: IMPORT_FUNNEL_SUCCESS_MESSAGE,
        showImportFunnelSuccessMessage
    };
}

export function importFunnel(params) {
    return function (dispatch) {
        dispatch(toggleImportFunnelLoading(true));
        return api.importFunnel(params, { headers: APIHelper.getGenericHeaders(), requestBody: {} }).then((response) => {
            dispatch(importFunnelSuccess(response.result));
            return api.getMetaUrl(response.result, { headers: APIHelper.getGenericHeaders(), requestBody: {} }).then((res) => {
                dispatch(getMetaUrlSuccess(res));
                dispatch(toggleImportFunnelLoading(false));
                dispatch(showSuccessMessage(true));
            });
        }).catch(error => {
            // dispatch(importFunnelError(true));
            dispatch(toggleImportFunnelLoading(false));
            throw error;
        });
    };
}

/*********************end of actions for import funnel************************ */
export function getDefaultFunnels() {
    return function (dispatch) {
        const localstorageManager = new LocalStorageHelper();
        const accessToken = localstorageManager.load(LocalStorageHelper.keys.ACCESS_TOKEN);
        if (accessToken) {
            dispatch(toggleLoading(true));
            return api.getDefaultFunnels().then((response) => {
                dispatch(getDefaultFunnelTemplatesSuccess({ defaultFunnelTemplates: response.result }));
                dispatch(toggleLoading(false));
            }).catch(error => {
                dispatch(toggleLoading(false));
                throw (error);
            });
        }
    };
}
export function importFunnelFromShareId(params) {
    return function (dispatch) {
        dispatch(toggleImportFunnelLoading(true));
        return api.importFunnelFromShareId(params, { headers: APIHelper.getGenericHeaders(), requestBody: {} }).then((response) => {
            dispatch(importFunnelSuccess(response.result));
            return api.getMetaUrl(response.result, { headers: APIHelper.getGenericHeaders(), requestBody: {} }).then((res) => {
                dispatch(getMetaUrlSuccess(res));
                dispatch(toggleImportFunnelLoading(false));
                dispatch(showSuccessMessage(true));
            });
        }).catch(error => {
            dispatch(importFunnelError(true));
            dispatch(toggleImportFunnelLoading(false));
            throw error;
        });
    };
}

/*********************end of actions for import funnel************************ */

/*****************beginning of actions for thumbnail call************************************/
export function getThumbnailForFunnelSuccess(thumbnailUrlResponse) {
    return {
        type: GET_THUMBNAIL_SUCCESS,
        thumbnailUrlResponse
    };
}

export function getThumbnailForFunnelError(error) {
    return {
        type: GET_THUMBNAIL_ERROR,
        error
    };
}

export function getThumbnailForFunnel(funnelReferenceId) {
    return function (dispatch) {
        return api.getThumbnailForFunnel(funnelReferenceId, { headers: APIHelper.getGenericHeaders() }).then((response) => {
            dispatch(getThumbnailForFunnelSuccess(response));
        }).catch(error => {
            dispatch(getThumbnailForFunnelError(error));
            throw (error);
        });
    };
}
/*****************end of actions for thumbnail call************************************/

export const purchaseOrder = (params) => {
    return purchaseOrderApi(params);
};

export const getPurchasedTemplates = getPurchasedTemplatesForTenant;

/*
 * Analytics Data actions
 */
export function getAnalyticsData(paramsObject) {
    return api.getAnalyticsData(paramsObject, { headers: APIHelper.getGenericHeaders() })
        .then((response) => response)
        .catch(err => {console.error("analytics err", err);});
}