import * as type from "./type";
import * as funnelActions from "./funnel-actions";
import * as funnelTemplateActions from "./funnel-template-actions";
import * as funnelSelectors from "../selectors/funnel-selectors";
import * as funnelTemplateSelectors from "../selectors/funnel-template-selectors";

export function toggleLoading(status = false) {
    return { type: type.IS_LOADING, status };
}
export function toggleFontLoading(status = false) {
    return { type: type.IS_FONT_LOADING, status };
}
export function getFunnelSuccess(payload) {
    return {
        type: type.GET_FUNNEL_SUCCESS,
        payload
    };
}
export function getFontsSuccess(payload) {
    return {
        type: type.GET_FONTS_SUCCESS,
        payload
    };
}
export function updateFunnelSuccess(funnel) {
    return {
        type: type.UPDATE_FUNNEL_SUCCESS,
        funnel
    };
}
export function updatePageSuccess(funnel) {
    return {
        type: type.UPDATE_PAGE_SUCCESS,
        funnel
    };
}
export function funnelPublishSuccess(funnel) {
    return {
        type: type.FUNNEL_PUBLISH_SUCCESS,
        funnel
    };
}
export function getPagesSuccess(payload) {
    return {
        type: type.GET_PAGE_SUCCESS,
        payload
    };
}
export function clearFunnelDataSuccess(payload) {
    return {
        type: type.CLEAR_FUNNEL_SUCCESS,
        payload
    };
}

export function getTemplateSuccess(htmlPage) {
    return {
        type: type.GET_TEMPLATE_SUCCESS,
        htmlPage
    };
}
export function getJsonSuccess(payload) {
    return {
        type: type.GET_JSON_SUCCESS,
        payload
    };
}
export function importPageSuccess(payload) {
    return {
        type: type.IMPORT_PAGE_SUCCESS,
        payload
    };
}
export function getMetaUrlSuccess(payload) {
    return {
        type: type.GET_META_URL_SUCCESS,
        payload
    };
}
export function loadMetaJsonSuccess(payload) {
    return {
        type: type.LOAD_METAJSON_SUCCESS,
        payload
    };
}
export function loadButtonsSuccess(payload) {
    return {
        type: type.LOAD_BUTTONS_SUCCESS,
        payload
    };
}
export function loadFunnelSuccess(payload) {
    return {
        type: type.LOAD_FUNNEL_SUCCESS,
        payload
    };
}
export function setTemplateSuccess(payload) {
    return {
        type: type.SET_TEMPLATE_SUCCESS,
        payload
    };
}
export function loadPageViewSuccess(pageData) {
    return {
        type: type.LOAD_PAGE_VIEW_SUCCESS,
        pageData
    };
}
export function savePageViewSuccess(status = false) {
    return {
        type: type.SAVE_PAGE_VIEW_SUCCESS,
        status
    };
}
export function clearPageData(status = false) {
    return {
        type: type.CLEAR_PAGE_DATA,
        status
    };
}
export function clearJsonData(status = false) {
    return {
        type: type.CLEAR_JSON_DATA,
        status
    };
}

export function editFunnelSuccess(funnel) {
    return { type: type.EDIT_FUNNEL_SUCCESS, funnel };
}
export function loadGoogleFontsSuccess(payload) {
    return { type: type.LOAD_GOOGLE_FONT_SUCCESS, payload };
}
export function editFunnelError(isError = false) {
    return { type: type.EDIT_FUNNEL_ERROR, isError };
}
export function createPageError(pageError = false) {
    return { type: type.CREATE_PAGE_ERROR, pageError };
}
export function updateFunnelError(isError = false) {
    return { type: type.UPDATE_FUNNEL_ERROR, isError };
}
export function updatePageError(isError = false) {
    return { type: type.UPDATE_PAGE_ERROR, isError };
}
export function funnelPublishError(isError = false) {
    return { type: type.FUNNEL_PUBLISH_ERROR, isError };
}
export function importPageError(importError = false) {
    return { type: type.IMPORT_PAGE_ERROR, importError };
}
export function getDomainListSuccess(domainList) {
    return {
        type: type.LOAD_DOMAIN_LIST_SUCCESS,
        domainList
    };
}
export function getDomainListError(loadDomainError = false) {
    return {
        type: type.LOAD_DOMAIN_LIST_ERROR,
        loadDomainError
    };
}

export function setDomainForFunnelSuccess(selectedDomainReferenceId) {
    return {
        type: type.SET_DOMAIN_FOR_FUNNEL_SUCCESS,
        selectedDomainReferenceId
    };
}

export function setDomainForFunnelError(selectedDomainForFunnelError) {
    return {
        type: type.SET_DOMAIN_FOR_FUNNEL_ERROR,
        selectedDomainForFunnelError
    };
}
export function clearDomainForFunnelDataSuccess(payload) {
    return {
        type: type.CLEAR_DOMAIN_FOR_FUNNEL_SUCCESS,
        payload
    };
}


export function setCurrentPageId(currentPageId) {
    localStorage.setItem("currentPageId", currentPageId);
    return {
        type: type.SET_CURRENT_PAGE_ID,
        currentPageId
    };
}

export function clearDomainForFunnelData() {
    return function (dispatch) {
        dispatch(clearDomainForFunnelDataSuccess(true));
    };
}

export function loadActiveFunnelTemplatesSuccess(activeFunnelTemplates) {
    return { type: "funnelTemplates/LOAD_ACTIVE_FUNNEL_TEMPLATES_SUCCESS", activeFunnels: activeFunnelTemplates };
}

/*********************begin actions for import Funnel********************************/

export function toggleImportFunnelLoading(status = false) {
    return { type: type.IS_IMPORT_FUNNEL_LOADING, status };
}

export function importFunnelSuccess(payload) {
    return {
        type: type.IMPORT_FUNNEL_SUCCESS,
        payload
    };
}
export function showSuccessMessage() {
    return {
        type: type.IMPORT_FUNNEL_SUCCESS_MESSAGE,
        payload: {
            showModalWindow: true,
            modalToShow: "importFunnelTemplateAlert"
        }
    };
}

export function showTemplateLoadedSuccessMessage(showImportFunnelTemplateSuccessMessage = false) {
    return {
        type: type.IMPORT_FUNNEL_TEMPLATE_SUCCESS_MESSAGE,
        payload: {
            showImportFunnelTemplateSuccessMessage
        }
    };
}

export function importFunnelError(importFunnelError = false) {
    return {
        type: type.IMPORT_FUNNEL_ERROR,
        importFunnelError
    };
}

export function getActions(parent) {
    switch (parent) {
        case "funnel": {
            return funnelActions;
        }
        case "funnelTemplate": {
            return funnelTemplateActions;
        }
    }
}
export function getSelector(parent) {
    switch (parent) {
        case "funnel": {
            return funnelSelectors;
        }
        case "funnelTemplate": {
            return funnelTemplateSelectors;
        }
    }
}

/*********************end of actions for import funnel************************ */

/*****************beginning of actions for thumbnail call************************************/
export function getThumbnailForFunnelSuccess(thumbnailUrlResponse) {
    return {
        type: type.GET_THUMBNAIL_SUCCESS,
        thumbnailUrlResponse
    };
}

export function getThumbnailForFunnelError(error) {
    return {
        type: type.GET_THUMBNAIL_ERROR,
        error
    };
}
/*****************end of actions for thumbnail call************************************/