export const input = (editor) => {
    var domComps = editor.DomComponents;
    var dType = domComps.getType("input");
    var dModel = dType.model;
    var dView = dType.view;
    var c = {};
    domComps.addType("input", {
        model: dModel.extend({
            init: function () {
                this.updateCustomName();
            },
            updateCustomName() {
                editor.on("component:update", function (component) {
                    if (component.changed.attributes && component.changed.attributes.customVal && component.changed.attributes.name === "notListed") {
                        component.attributes.attributes.name = component.attributes.attributes.customVal;
                        delete component.attributes.attributes.customVal;
                    }
                });

                editor.TraitManager.getTraitsViewer().render();
            },
            defaults: Object.assign({}, dModel.prototype.defaults, {
                traits: [
                    // strings are automatically converted to text types
                    {
                        type: "select",
                        label: c.labelTraitMethod,
                        name: "name",
                        options: [
                            { value: "firstName", name: "First Name" },
                            { value: "lastName", name: "Last Name" },
                            { value: "companyName", name: "Company Name" },
                            { value: "address1", name: "Street Address" },
                            { value: "address2", name: "Address 2" },
                            { value: "postalCode", name: "Postal Code" },
                            { value: "city", name: "city" },
                            { value: "state", name: "state" },
                            { value: "country", name: "country" },
                            { value: "emailAddress", name: "Email Address" },
                            { value: "phoneNumber", name: "Phone" },
                            { value: "shipFirstName", name: "Ship First Name" },
                            { value: "shipLastName", name: "Ship Last Name" },
                            { value: "shipCompanyName", name: "Ship Company Name" },
                            { value: "shipAddress1", name: "Ship Street Address" },
                            { value: "shipAddress2", name: "Ship Address 2" },
                            { value: "shipPostalCode", name: "Ship Postal Code" },
                            { value: "shipCity", name: "Ship City" },
                            { value: "shipState", name: "Ship State" },
                            { value: "shipCountry", name: "Ship Country" },
                            { value: "cardNumber", name: "Card Number" },
                            { value: "cardMonth", name: "Expiration Month" },
                            { value: "cardYear", name: "Expiration Year" },
                            { value: "achAccountType", name: "Account Type" },
                            { value: "achRoutingNumber", name: "Routing Number" },
                            { value: "achAccountNumber", name: "Account Number" },
                            { value: "cardSecurityCode", name: "CVV" },
                            { value: "couponCode", name: "Coupon Code" },
                            { value: "custom1", name: "custom field 1" },
                            { value: "custom2", name: "custom field 2" },
                            { value: "custom3", name: "custom field 3" },
                            { value: "custom4", name: "custom field 4" },
                            { value: "custom5", name: "custom field 5" },
                            { value: "notListed", name: "Not Listed" }
                        ],
                    },
                    {
                        type: "text",
                        label: "Custom Field",
                        name: "customVal",
                    },
                    "placeholder",
                    {
                        type: "select",
                        label: "Type",
                        name: "type",
                        options: [
                            { value: "text", name: "Text" },
                            { value: "email", name: "Email" },
                            { value: "password", name: "Password" },
                            { value: "number", name: "Number" },
                        ]
                    }, {
                        type: "checkbox",
                        label: "Required",
                        name: "required",
                    }],
            }),
        }, {
            isComponent: function (el) {
                //eslint-disable-next-line
                if (el.tagName == "INPUT") {
                    return { type: "input" };
                }
            },
        }),

        view: dView.extend({
            events: {
                "click": "handleClick"
            },

            init() {
                this.listenTo(this.model, "change:content", this.updateContent);
            },

            updateContent() {
                this.el.innerHTML = this.model.get("content");
            },

            handleClick(e) {
                e.preventDefault();
            },
        }),
        onValueChange: function () {
            this.target.set("content", this.model.get("value"));
        }
    });
};