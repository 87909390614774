import { productCheckbox } from "./product-checkbox";
import { link } from "./link";
import { productRadioButtons } from "./product-radio-buttons";
import { linkBlocks } from "./link-blocks";
import { button } from "./button";
import { input } from "./input";
import { popup } from "./popup";
import { stickybar } from "./sticky-bar";
import { popUpBody } from "./popup-body";
import { resetPassword } from "./reset-password";
import { downloadPreviewURL } from "./download-preview-url";
import { configuration } from "./configuration";

export const defaultComponents = [
    configuration,
    productCheckbox,
    link,
    productRadioButtons,
    linkBlocks,
    button,
    input,
    popup,
    stickybar,
    popUpBody,
    resetPassword,
    downloadPreviewURL
];