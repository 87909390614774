import joint, { V } from "jointjs";
import $ from "jquery";
import isFunction from "lodash/isFunction";
import Graph from "../../components/JointjsConfig/graph";
import draggableElement from "../../components/JointjsConfig/draggable-element";
import { create_UUID } from "../../utilities/create-unique-id";
import { VisualizerFrameDimensions } from "../../../constants";

const toolbarEvents = {
    "cell:pointerdown": function (...args) {
        const [paper, cellView, e] = args;
        e.stopPropagation();
        const model = cellView.model;
        if (model && !model.get("isFolder")) {
            const {
                left: frameLeftOffset,
                top: frameTopOffset,
                height: frameHeight,
                width: frameWidth,
            } = VisualizerFrameDimensions;
            if (model && isFunction(model.get)) {
                const isIcon = model.get("icon");
                const graphScale = paper.zoom || 1;
                $("body").append("<div id=\"flyPaper\" style=\"position:fixed;z-index:100;opacity:.7;pointer-event:none;\"></div>");
                const flyGraph = Graph();
                new joint.dia.Paper({
                    el: $("#flyPaper"),
                    model: flyGraph,
                    interactive: false,
                    width: 120,
                    height: 160
                });
                const flyShape = draggableElement(cellView, isIcon);
                flyGraph.addCell(flyShape);
                $("#flyPaper").offset({
                    left: e.pageX,
                    top: e.pageY
                });
                $("body")
                    .on("mousemove.fly", function (e) {
                        $("#flyPaper").offset({
                            left: e.pageX,
                            top: e.pageY
                        });
                    })
                    .on("mouseup.fly", function (e) {
                        var x = e.pageX,
                            y = e.pageY,
                            target = { left: frameLeftOffset, top: frameTopOffset }; //StencilPaper.$el.offset();
                        // Dropped over StencilPaper
                        var s = flyShape.clone();
                        if (x > target.left && x < target.left + frameHeight && y > target.top && y < target.top + frameWidth) {
                            const calcX = (x - frameLeftOffset) / graphScale;
                            const calcY = (y - frameTopOffset) / graphScale;
                            s.position(calcX, calcY);
                            const templateTypeId = s.attributes.elementMetaData.templateTypeId;
                            if (templateTypeId === 24 || templateTypeId === 25) {
                                s.attributes.icon = true;
                                s.attributes.attrs.image.height = 60;
                                s.attributes.attrs.image.width = 60;
                                s.attributes.attrs.image.x = 30;
                                s.attributes.attrs.image.y = 30;
                                s.attributes.attrs[".borderBox"] = null;
                            }
                            s.attributes.pageReferenceId = create_UUID();
                            s.attributes.pageViewReferenceId = create_UUID();
                            if (paper && paper.emitter) {
                                paper.emitter.emit("PAGE:DROP-ON-VISUALIZER", s);
                            }
                        } else {
                            // Dropped over toolbar
                        }
                        $("body").off("mousemove.fly").off("mouseup.fly");
                        flyShape.remove();
                        $("#flyPaper").remove();
                    });
            }
        }
    },
};

export default toolbarEvents;