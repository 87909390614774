import * as actions from "./actions";
import { FunnelReducer, Page } from "../models";

export const MANAGEFUNNEL_REDUCER_KEY = "MANAGEFUNNEL";

const initialPages = [
    // TODO - Fix the Lead page pre population
    // new Page({
    //     id: null,
    //     pageId: "lead-page",
    //     title: constants.leadPageTitle,
    //     isTemplateSelected: false,
    //     htmlContent: null,
    //     cssContent: null,
    //     clickHandler: () => {},
    //     isDefaultPage: true,
    //     templateId: null,
    //     isDraggable: false,
    //     pageView: [new PageView({})],
    //     active: true,
    //     createdBy: null,
    //     createdOn: null,
    //     deleted: null,
    //     deletedBy: null,
    //     deletedOn: null,
    //     fFunnelId: null,
    //     fTenant: null,
    //     fTenantId: null
    // })
];

const initialState = new FunnelReducer(initialPages);

export function reducer(state = initialState, action) {
    switch (action.type) {
        case actions.ADD_NEW_PAGE_SUCCESS: {
            const { pageData } = action;
            const newPageReferenceId = pageData.referenceId;
            const page = new Page({
                ...pageData
            });
            const newPages = [...state.funnelData.page];
            newPages.push(page);
            return new FunnelReducer(state.funnelData, null, newPageReferenceId, newPages);
        }
        case actions.LOAD_PAGE_VIEW_SUCCESS: {
            const { pageData } = action;
            let funnelData = new FunnelReducer(state.funnelData, state.currentPageId, [...state.pages]);
            funnelData = Object.assign({}, funnelData.funnelData);
            const pages = [...funnelData.page];
            let currentPageIdx = pages.findIndex((f) => pageData.fPage.referenceId === f.referenceId);
            const newPageData = new Page({
                ...pages[currentPageIdx],
                pageView: pages[currentPageIdx].pageView.length === 1 ? [ { ...pageData } ] : [ { ...pageData },{ } ],
                pageId: pageData.fPage.referenceId
            });
            pages.splice(currentPageIdx, 1, newPageData);
            const newFunnelData = new FunnelReducer({ ...state.funnelData, page: pages }, funnelData.currentPageId, pages);
            return newFunnelData;
        }
        case actions.EDIT_PAGE_SUCCESS: {
            const { pageData } = action;
            let funnelData = new FunnelReducer(state.funnelData, state.currentPageId,[...state.pages]);
            funnelData = Object.assign({}, funnelData.funnelData);
            let pages = [...funnelData.page];
            let currentPageIdx = pages.findIndex((f) => pageData.referenceId === f.referenceId);
            const currentPage = pages[currentPageIdx];
            pages.splice(currentPageIdx, 1, { ...currentPage, referenceId: pageData.referenceId, title: pageData.title });
            const newFunnelData = new FunnelReducer({ ...state.funnelData, page: pages },state.funnelData, pageData.referenceId, pages);
            return newFunnelData;
        }
        case actions.SET_PAGE_TEMPLATE_ID: {
            // const { templateId } = action;
            // const funnelData = new FunnelReducer([...state.pages], state.currentPageId);
            // const currentPageIdx = funnelData.pages.findIndex((f) => f.id === funnelData.currentPageId);
            // const newPage = new Page({ ...funnelData.pages[currentPageIdx], templateId, pageId: state.currentPageId });
            // funnelData.pages.splice(currentPageIdx, 1, newPage);
            // const newFunnelData = new FunnelReducer(funnelData.pages, funnelData.currentPageId);
            // return newFunnelData;
            return {
                ... state
            };
        }
        case actions.SET_CURRENT_PAGE_ID: {
            const { currentPageReferenceId } = action;
            return {
                ... state,
                currentPageReferenceId
            };
        }
        case actions.LOAD_FUNNEL_SUCCESS: {
            const tempPages = action.payload.funnelData.page.map((i) => {
                return new Page(i);
            });
            const pages = [ ...initialPages, ...tempPages];
            const storedPageReferenceId = pages.find(function (element) {
                return element.referenceId === action.payload.currentPageId;
            });
            const currentPageReferenceId = storedPageReferenceId ? storedPageReferenceId.referenceId : pages[0].referenceId;
            return new FunnelReducer(action.payload.funnelData, null, currentPageReferenceId, pages.length ? pages : initialPages);
        }
        case actions.SPLIT_VIEW_SUCCESS: {
            return {
                ...state,
                splitViewPage: action.splitViewPage
            };
        }
        case actions.SET_TEMPLATE_HTML_SUCCESS:
            return {
                ...state,
                templateHtml: action.templateHtml
            };
        case actions.EDIT_FUNNEL_SUCCESS:
            return {
                ...state,
                funnelData: action.funnel,
                isSuccess: true
            };
        case actions.GET_PAGE_SUCCESS:
            return {
                ...state,
                splitData: action.splitData
            };
        case actions.IS_LOADING:
            return {
                ...state,
                isLoading: action.status
            };
        case actions.SAVE_PAGE_VIEW_SUCCESS:
            return {
                ...state,
                isSaved: action.status
            };
        case actions.EDIT_FUNNEL_ERROR:
            return {
                ...state,
                isError: action.isError
            };
        default:
            return state;
    }
}