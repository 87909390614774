import React from "react";
import PropTypes from "prop-types";
import { formatPriceUptoTwoDecimal } from "../utilities/formatters";

import { PageTemplateCardToolbar } from "./page-template-card-toolbar";

export const PageTemplateCard = ({ template, templateType, onTemplateSelected, isSelected, onToolbarClick, userData }) => {
    return (
        <div
            className="display-flex page-template-card-container"
            onClick={() => onTemplateSelected(template)}
        >
            <div className={`ibox page-template-card ${isSelected ? "selected-template" : ""}`}>
                <div className="ibox-content page-template-card-content">
                    <div className={`template-status-indicator ${template.active ? "active-template" : ""}`} />
                    {
                        templateType && (
                            <div className="page-template-type">
                                {templateType.label}
                            </div>
                        )
                    }
                    <div className="page-template-card-image" style={{ backgroundImage: `url(${template.thumbnailUrl})` }}>
                        {
                            userData.isDesignerUser && <div class="ribbon-price">
                                { formatPriceUptoTwoDecimal(template.price) }
                            </div>
                        }
                    </div>
                    <PageTemplateCardToolbar
                        template={template}
                        isSelected={isSelected}
                        onToolbarClick={(type) => onToolbarClick(type, template)}
                    />
                </div>
            </div>
        </div>
    );
};
PageTemplateCard.propTypes = {
    template: PropTypes.object,
    onTemplateSelected: PropTypes.func,
    isSelected: PropTypes.bool,
    onToolbarClick: PropTypes.func,
    templateType: PropTypes.object,
    userData: PropTypes.object
};