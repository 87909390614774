import { VisualizerFrame as VisualizerFrameContainer } from "./visualizer-frame";
import { connect } from "react-redux";
import { commonActions, visualizerActions } from "../../actions";
import * as visualizerSelectors from "../../selectors/visualizer-selectors";
import { selectors as templateSelectors } from "../../../templates";
import * as templateActions from "../../../templates/actions";

function mapStateToProps(state, ownProps) {
    const selectors = commonActions.getSelector(ownProps.parent);
    const importedFunnel = selectors.getImportedFunnel(state);
    const funnel = importedFunnel ? importedFunnel : selectors.getFunnelData(state);
    const visualizer = visualizerSelectors.getVisualizerData(state);
    const pageData = selectors.getPageData(state);
    const pageError = selectors.getPageError(state);
    const isImportFunnelLoading = selectors.getImportFunnelLoadingStatus(state);
    const metaJson = selectors.getMetaData(state);
    const selectedDomainForFunnel = selectors.getSelectedDomainForFunnel(state);
    const templates = templateSelectors.getTemplates(state);
    const updatedPage = selectors.getImportedPage(state);
    let visualizerData = {};
    if (visualizer) {
        let addedElementArray = (metaJson && (Object.keys(metaJson).length !== 2)) ? JSON.parse(metaJson) : { cells: [] };
        visualizerData = { ...visualizer, addedElementArray: addedElementArray, selectedDomainForFunnel: selectedDomainForFunnel };
    }
    return {
        funnel,
        importedFunnel,
        updatedPage,
        visualizerData,
        pageData,
        pageError,
        isImportFunnelLoading,
        metaJson,
        templates,
    };
}

function mapDispatchToProps(dispatch, ownProps) {
    //Getting the ActionCreators Based on Paren
    const actions = commonActions.getActions(ownProps.parent);
    return {
        updateVisualizerData: (params) => dispatch(visualizerActions.updateVisualizerData(params)),
        clearPageData: () => dispatch(commonActions.clearPageData()),
        loadButtons: (params, cb) => dispatch(actions.loadButtons(params, cb)),
        savefunnelGraph: (params) => dispatch(actions.saveFunnel(params)),
        clonePage: (params) => dispatch(actions.clonePage(params)),
        saveButtonData: (params, cb) => dispatch(actions.saveButtonData(params, cb)),
        createNewPage: (params) => dispatch(actions.createPage(params)),
        getSavedfonts: (params) => dispatch(actions.getFonts(params)),
        importPage: (params) => dispatch(actions.importPage(params)),
        closeRightPanel: (params) => dispatch(visualizerActions.closeRightPanel(params)),
        removeCallBackEvent: (params) => dispatch(visualizerActions.removeCallBackEvent(params)),
        setCellData: (params) => dispatch(visualizerActions.setCellData(params)),
        deletePage: (params, cb) => dispatch(actions.deletePage(params, cb)),
        setSelectedPageTemplate: (template) => dispatch(templateActions.setSelectedPageTemplate(template)),
    };
}

export const VisualizerFrame = connect(mapStateToProps, mapDispatchToProps)(VisualizerFrameContainer);
