import React, { Component } from "react";
import { Button, Row, Col } from "react-bootstrap";
import PropTypes from "prop-types";

import "./OffersTable.css";
import Forms from "../../Forms";

class Offer extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: false,
            activeList: null,
            type: null,
            products: this.getProducts(props),
            editData: null,
            fromSelectTemplate: false
        };
    }

    componentWillReceiveProps(nextProps) {
        this.setState({
            products: this.getProducts(nextProps),
            isLoading: false
        });
    }

    isEnableLoading = () => {
        this.setState({
            isLoading: true
        });
    }

    getProducts = (props) => {
        return props.funnelDetails && props.funnelDetails.products && props.funnelDetails.products.filter((product, i) => product.productType === "OFFER");
    }

    activeComponent = (status, type, value, fromSelectTemplate = false) => {
        this.setState({
            activeList: status,
            type: type,
            editData: value,
            fromSelectTemplate
        });
    }

    clearActiveList = () => {
        this.setState({
            activeList: null
        });
    }

    renderList = (products) => {
        return products.map((list, i) => (
            <tr className="edit-funnel-divider">
                <td className="edit-funnel-table-data">{list.productName}</td>
                <td>
                    <Button
                        type="primary btn-height"
                        className="button"
                        disabled={this.state.isLoading}
                        onClick={() => this.activeComponent("CreateEditOffer", "Edit", list)}
                    >
                        <i class="fa fa-pencil edit-pencil-color"></i>
                    </Button>
                </td>
            </tr>
        ));
    }

    render() {
        const { activeList, products, editData, type, isLoading, fromSelectTemplate } = this.state;
        return (
            <div>
                <div className="modal-header-funnel-edit">
                    Offers
                </div>
                <div className="table-overflow-scroll">
                    <Row className="p-30 pb-0">
                        <Button
                            type="primary"
                            className="button btn-height primary-button-color float-right"
                            onClick={() => this.activeComponent("CreateEditOffer", "ADD")}
                        >
                            Add New Product
                        </Button>
                        <Button
                            type="primary"
                            className="button btn-height primary-button-color float-right"
                            onClick={() => this.activeComponent("SelectExisting", "Existing")}
                        >
                            Add Existing Product
                        </Button>
                    </Row>
                    <Row className="p-30">
                        <table className="edit-funnel-table">
                            <tr className="edit-funnel-table-head">
                                <th className="edit-funnel-table-heading">Product</th>
                                <th></th>
                            </tr>
                            {isLoading ?
                                <tr className="position_absolute_flex_center">
                                    <div className={"loading_box"}>
                                        <span
                                            className="loading_spinner"
                                        ></span>
                                        <span>Loading</span>
                                    </div>
                                </tr> :
                                products && products.length !== 0 ? this.renderList(products) :
                                    <tr className="edit-funnel-divider">
                                        <td colSpan={2} className="edit-funnel-table-data">No Product Found</td>
                                    </tr>
                            }
                        </table>
                    </Row>
                </div>
                <Row className="p-30">
                    <Col mdOffset={6} md={6} className="pr-0">
                        <Button
                            onClick={(e) => {this.props.closeModal(e);}}
                            type="primary"
                            className="button btn-height float-right"
                        >
                            Cancel
                        </Button>
                    </Col>
                </Row>
                <Forms isProduct productType="OFFER" setActiveComponent={this.activeComponent} setEditData={(editData) => this.setState({ editData })} fromSelectTemplate={fromSelectTemplate} {...this.props} isEnableLoading={this.isEnableLoading} campaignId={ this.props.funnelDetails && this.props.funnelDetails.campaignId} pageType={type} activeComponent={activeList} editData={editData} clearActiveList={this.clearActiveList} title={`${type === "ADD" ? "Create New" : `${this.state.type === "Existing" ? "Select Existing" : "Edit" }`} Offer`} />
            </div>
        );
    }
}

export default Offer;

Offer.propTypes = {
    closeModal: PropTypes.func,
    funnelDetails: PropTypes.array
};