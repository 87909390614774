import React from "react";
import PropTypes from "prop-types";
import {
    SelectInput,
    funnelCampaignIdError
} from "../commons";

const AddCampaign = ({
    funnel,
    isEdit,
    allCampaigns,
    errors,
    isDefaultOptionEnabled,
    onCampaignChange,
    campaignReroute
}) => {
    return (
        <>
            {window.location.pathname === "/funnel" ?
                <div className="add-campagin-btn">
                    <button onClick={campaignReroute} className="btn btn-primary">Add A New Campaign</button>
                </div>
                : null
            }
            <SelectInput
                isDefaultOptionEnabled={isDefaultOptionEnabled}
                name="campaign"
                label="Campaign"
                value={funnel.campaign}
                defaultOption="Select Campaign"
                options={allCampaigns}
                onChange={onCampaignChange}
                error={errors.campaignId}
                errorMessage={funnelCampaignIdError}
                disabled={isEdit}
            />
        </>
    );
};

AddCampaign.propTypes = {
    funnel: PropTypes.object.isRequired,
    allCampaigns: PropTypes.array,
    campaigns: PropTypes.array,
    errors: PropTypes.object,
    filteredCampaigns: PropTypes.array,
    onCampaignChange: PropTypes.func,
    campaignReroute: PropTypes.func,
    isEdit: PropTypes.bool,
    isDefaultOptionEnabled: PropTypes.bool
};

export default AddCampaign;