import delay from "./delay";

// This file mocks a web API by working with the hard-coded data below.
// It uses setTimeout to simulate the delay of an AJAX call.
// All calls return promises.
const funnelTypes = [
    {
        id: 1,
        title: "Sales Letter Funnel",
        description: "Use a traditional sales letter to sell your products or services",
        dateCreated: "2017-01-25",
        dateUpdated: "2017-03-17",
        img: require("../img/funnelTypes/standard.png"),
        directions: "This funnel uses a long form sales letter to sell the product or " +
        "service up front. It gets its name because of the script we use for our sales " +
        "letters in the DotComSecrets book called “Star, Story, Solution.” The sales letter " +
        "sells your front end product, and then you have upsells and downsells immediately " +
        "after the main offer to increase the average cart value.",
        pageNumbers: 4,
        pages: ["Sales Letter Order Form", "OTO Page", "Down-sell", "Offer Wall"],
        type: "Buyer",
        time: "1h",
        video: ""
    },
    {
        id: 2,
        title: "Lead Magnet Funnel",
        description: "Give people an ethical bribe in exchange for their email address",
        dateCreated: "2018-01-25",
        dateUpdated: "2018-04-10",
        img: require("../img/funnelTypes/leadFunnel.png"),
        direction: "A lead magnet funnel is similar to the other optin funnels, except you are actually " +
        "giving away something in exchange for their email address. It may be a report or video, but it’s typically " +
        "something tangible that they are exchanging for their email address.",
        pageNumbers: 2,
        pages: ["Lead Magnet", "Thank You Page"],
        type: "Lead",
        time: "30m",
        video: ""
    },
    {
        id: 3,
        title: "Membership Funnel",
        description: "Create a membership site and sell access to it through this funnel",
        dateCreated: "2018-01-25",
        dateUpdated: "2018-04-10",
        img: require("../img/funnelTypes/leadFunnel.png"),
        direction: "Membership Funnels were created to sell people into membership sites," +
        " paid newsletters and more. There are different ways you could sell a membership " +
        "site (for example, with a webinar or a sales letter), but in this template we will " +
        "be using a video sales letter. On the first page, someone will typically signup for " +
        "some type of trial. On the Thank You Page they get a link to create their account in " +
        "the member’s area. Users create their own unique username and password and content " +
        "unlocks based on the user’s specific purchases.",
        pageNumbers: 4,
        pages: ["VSL + Order Form", "Offer Wall", "Membership Access Page", "Membership Page"],
        type: "Buyer",
        time: "1h",
        video: ""
    },
    {
        id: 4,
        title: "Product Launch Funnel",
        description: "Built anticipation for your new product with our product launch funnels",
        dateCreated: "2018-01-25",
        dateUpdated: "2018-04-10",
        img: require("../img/funnelTypes/webinarFunnel.png"),
        direction: "Much like Hollywood giving previews before a movie release, the Product Launch " +
        "Funnel lets an entrepreneur build anticipation before a customer is allowed to buy a product." +
        " Small marketing “previews” about the product are sent, usually at least a day apart, to build" +
        " scarcity and urgency for the final day when the “cart opens” and customers may buy for a limited" +
        " time. A defining feature of the Product Launch Funnel is closing the cart after a few days." +
        " This concept was pioneered by Jeff Walker and helped thousands of entrepreneurs to launch new " +
        "companies overnight. You can learn more about what to put in the product launch videos in both the" +
        " “Dot Com Secrets” book as well as the “Expert Secrets” book.",
        pageNumbers: 4,
        pages: ["Product Launch", "Sales Page x 3", "Product Launch + Order Page"],
        type: "Event",
        time: "1h",
        video: ""
    },
    {
        id: 5,
        title: "Hero Funnel",
        description: "Let people know who you are and how to connect with you",
        dateCreated: "2018-01-25",
        dateUpdated: "2018-04-10",
        img: require("../img/funnelTypes/surveyFunnel.png"),
        direction: "Use a Hero Funnel to showcase you as the entrepreneur, your story, and " +
        "build a list of followers while you do so. This funnel starts by sharing some of " +
        "your bio, getting people to join your email newsletter, and then asking them to " +
        "follow you on your social media accounts.",
        pageNumbers: 2,
        pages: ["Hero Page", "Thank You Page"],
        type: "Other",
        time: "30m",
        video: ""
    },
    {
        id: 6,
        title: "Live Demo Funnel",
        description: "Demonstrate your product through your own live demo funnel",
        dateCreated: "2018-01-25",
        dateUpdated: "2018-04-10",
        img: require("../img/funnelTypes/standard.png"),
        direction: "The Live Demo Funnel is a great way to show off your product" +
        " by you simply using it in front of your customers. This gives the " +
        "entrepreneur a way to answer live questions and demonstrate their product." +
        " Do this by hosting a periodic live show that others can also attend live." +
        " Remind viewers where they can get the product you’re showing and keep" +
        " additional episodes below. The best known example of this type of" +
        " funnel is at www.FunnelFridays.com.",
        pageNumbers: 2,
        pages: ["Live Demo Page", "Thank You Page"],
        type: "Other",
        time: "30m",
        video: ""
    }
];

function replaceAll(str, find, replace) {
    return str.replace(new RegExp(find, "g"), replace);
}

//This would be performed on the server in a real app. Just stubbing in.
const generateId = (funnelType) => {
    return replaceAll(funnelType.title, " ", "-");
};

class api {
    static getAllFunnelTypes() {
        return new Promise((resolve) => {
            setTimeout(() => {
                resolve(Object.assign([], funnelTypes));
            }, delay);
        });
    }

    static saveFunnel(funnelType) {
        funnelType = Object.assign({}, funnelType); // to avoid manipulating object passed in.
        return new Promise((resolve, reject) => {
            setTimeout(() => {
                // Simulate server-side validation
                const minFunnelTypeTitleLength = 1;
                if (funnelType.title.length < minFunnelTypeTitleLength) {
                    reject(`Title must be at least ${minFunnelTypeTitleLength} characters.`);
                }

                if (funnelType.id) {
                    //eslint-disable-next-line
                    const existingFunnelTypeIndex = funnelTypes.findIndex(a => a.id == funnelType.id);
                    funnelTypes.splice(existingFunnelTypeIndex, 1, funnelType);
                } else {
                    //Just simulating creation here.
                    //The server would generate ids and watchHref"s for new funnel in a real app.
                    //Cloning so copy returned is passed by value rather than by reference.
                    funnelType.id = generateId(funnelType);
                    //funnel.watchHref = `http://www.pluralsight.com/courses/${course.id}`;
                    funnelTypes.push(funnelType);
                }

                resolve(funnelType);
            }, delay);
        });
    }

    static deleteFunnel(funnelTypeId) {
        return new Promise((resolve) => {
            setTimeout(() => {
                const indexOfFunnelTypeToDelete = funnelTypes.findIndex(funnelType => {
                    //eslint-disable-next-line
                    funnelType.id == funnelTypeId;
                });
                funnelTypes.splice(indexOfFunnelTypeToDelete, 1);
                resolve();
            }, delay);
        });
    }
}

export default api;