import React, { Component } from "react";
import PropTypes from "prop-types";

import SelectExisting from "../SelectExistingTemplate";
import ExistOrNew from "../SelectExistOrNew";
import CreateEditAffiliates from "./CreateEditAffiliates";
import AddAffiliates from "./AddAffiliates";
import Affliates from "../EditFunnel/Affliates";
import { getAffiliateList } from "../utilities/api";

class Affiliates extends Component {
    state = {
        isLoading: false,
        activeComponent: "YES_NO",
        optionData: null,
        selectedAffiliates: []
    };

    componentDidMount() {
        this.getAffiliateData();
    }

    getAffiliateData = () => {
        let data = {
            campaignId: null
        };

        getAffiliateList(data)
            .then((res) => {
                this.setState({
                    optionData: res.data.message,
                });
            });
    }

    setSelectedAffiliates = (selectedAffiliates) => {
        this.setState({
            selectedAffiliates
        });
    }

    renderTemplate = () => {
        const { activeComponent, optionData, affiliateData, selectedAffiliates } = this.state;
        const { closeWizard, handleSkipButton } = this.props;
        switch (activeComponent) {
            case "NEW":
                return <CreateEditAffiliates {...this.props} closeWizard={closeWizard} handleSkipButton={handleSkipButton} selectTemplate={this.selectTemplate} pageType="step-wizard" type="ADD" />;
            case "EDIT":
                return <CreateEditAffiliates {...this.props} refreshData={this.getAffiliateData} affiliate={affiliateData} closeWizard={closeWizard} handleSkipButton={handleSkipButton} selectTemplate={this.selectTemplate} pageType="step-wizard" type="EDIT" />;
            case "AFFILIATES_LIST":
                return <Affliates {...this.props} setSelectedAffiliates={this.setSelectedAffiliates} handleSkipButton={handleSkipButton} selectTemplate={this.selectTemplate} pageType="step-wizard"/>;
            case "EXISTING":
                return <SelectExisting {...this.props} selectedAffiliates={selectedAffiliates} closeWizard={closeWizard} optionData={optionData} handleSkipButton={handleSkipButton} selectTemplate={this.selectTemplate} labelText="Affiliates"/>;
            case "YES_NO":
                return <AddAffiliates handleSkipButton={handleSkipButton} closeWizard={closeWizard} selectTemplate={this.selectTemplate} />;
        }
    };

    selectTemplate = (value, data) => {
        this.setState({
            activeComponent: value,
            affiliateData: data
        });
    };

    render() {
        const { activeComponent } = this.state;
        const { handleSkipButton, closeWizard } = this.props;
        return (
            <div
                className="template_Area_container"
            >
                {activeComponent ? (
                    <div className="template_Area">{this.renderTemplate()}</div>
                ) : (
                    <ExistOrNew labelText={"Affiliates"} selectTemplate={this.selectTemplate} handleSkipButton={handleSkipButton}/>
                )}
                {activeComponent === "AFFILIATES_LIST" && <div className="affiliate-skip" onClick={() => closeWizard()}>Skip this step</div>}
            </div>
        );
    }
}

export default Affiliates;

Affiliates.propTypes = {
    moveStep: PropTypes.func,
    closeWizard: PropTypes.func,
    handleSkipButton: PropTypes.func
};