import { destroyComponent } from "./helper";

const addStickyTriggerOnLoadScript = (editor) => {
    var wrapper = editor.DomComponents.getWrapper();
    if (wrapper.view.$el.find("#triggerStickyOnLoad").length === 0) {
        editor.DomComponents.addComponent({
            tagName: "script",
            content: ` var isVisitedStickyOnLoad;
            if(isVisitedStickyOnLoad){
                var  stickyBarCustomEle = document.getElementById('stickyBarCustom');
                var minutesDelay = stickyBarCustomEle.getAttribute('data-minutesdelay') ?  Number(stickyBarCustomEle.getAttribute('data-minutesdelay')) : 0;
                var  secondsDelay = stickyBarCustomEle.getAttribute('data-secondsdelay')  ?  Number(stickyBarCustomEle.getAttribute('data-secondsdelay')) : 0;
            var delay = minutesDelay > 0 ? ((minutesDelay * 60)+secondsDelay)+'000' : secondsDelay+'000' ;
            stickyBarCustomEle.setAttribute('style','display:none');
            setTimeout((delay) => {
                isVisitedStickyOnLoad = false
                stickyBarCustomEle.setAttribute('style','display:block');
            },delay);
            }`,
            attributes: { id: "triggerStickyOnLoad" },
            removable: true,
            copyable: false,
        });
    }
};

const addStickyOnExitScript = (editor) => {
    var wrapper = editor.DomComponents.getWrapper();
    if (wrapper.view.$el.find("#triggerStickyExit").length === 0) {
        editor.DomComponents.addComponent({
            tagName: "script",
            content: `
            if(isStickyBar){
                var onLeaveOf = document.getElementsByTagName('body')[0];
                onLeaveOf.addEventListener("mouseleave", () => {
                    document.getElementById('stickyBarCustom').setAttribute('style','display:block');
                });
            }`,
            attributes: { id: "triggerStickyExit" },
            removable: true,
            copyable: false,
        });
    }
};

const handleTriggerStickybarOn = (editor, component) => {
    if (component.changed.trigger_stickybar_on) {
        var wrapper = editor.DomComponents.getWrapper();
        if (component.changed.trigger_stickybar_on === "trigger_on_exit") {
            editor.getSelected().addAttributes(
                { "data-showOnScroll": "no" }
            );
            destroyComponent(editor, "triggerStickyOnLoad");
            wrapper.view.$el.find("#stickyBarCustom")[0].setAttribute("style", "display:block");
            addStickyOnExitScript(editor);
        } else if (component.changed.trigger_stickybar_on === "triggerStickyScroll") {
            editor.getSelected().addAttributes(
                { "data-showOnScroll": "yes" }
            );
            destroyComponent(editor, "triggerStickyExit");
            destroyComponent(editor, "triggerStickyOnLoad");
            wrapper.view.$el.find("#stickyBarCustom")[0].setAttribute("style", "display:block");
        } else if (component.changed.trigger_stickybar_on === "triggerStickyOnLoad") {
            addStickyTriggerOnLoadScript(editor);
            destroyComponent(editor, "triggerStickyExit");
            editor.getSelected().addAttributes(
                { "data-showOnScroll": "no" }
            );
            wrapper.view.$el.find("#stickyBarCustom")[0].setAttribute("style", "display:block");
        } else if (component.changed.trigger_stickybar_on === "none") {
            destroyComponent(editor, "triggerStickyExit");
            destroyComponent(editor, "triggerStickyOnLoad");
            editor.getSelected().addAttributes(
                { "data-showOnScroll": "no" }
            );
            wrapper.view.$el.find("#stickyBarCustom")[0].setAttribute("style", "display:block");
        }
    }
};

export default handleTriggerStickybarOn;