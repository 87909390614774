import React, { Component } from "react";
import PropTypes from "prop-types";
import * as _ from "lodash";

import { NotificationEmitter, NOTIFICATION_EVENT_NAME } from "../../commons/notification";
import SelectExisting from "../SelectExistingTemplate";
import ExistOrNew from "../SelectExistOrNew";
import CreateEditProduct from "../Products/CreateEditProduct";
import { getProducts, getCampaigns } from "../../api/campaignsApi";
import { getFulfillmentHouseList, getMerchantList, updateFunnelDetails } from "../utilities/api";

class Products extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: false,
            activeComponent: null,
            offerUpShellList: [],
            activeIndex: 0,
            selectedProductId: null,
            activeProduct: null,
            offerArray: [],
            upsaleArray: []
        };
    }

    componentDidMount() {
        this.getAllList(this.props.offerCount, this.props.upsellCount);
    }

    setOfferUpsale = (key, value) => {
        this.setState({
            [key]: value
        });
    }

    getAllList = (offerCount, upsellCount) => {
        // this.props.isInsideTheVisualizer ? 0 : 1
        let oCount = offerCount === 0 || !offerCount ? 1 : offerCount;
        let uCount = upsellCount === 0 || !upsellCount ? 1 : upsellCount;
        let offerUpShellList = [];
        for (let i = 1; i <= oCount; i++) {
            offerUpShellList.push(`Offer #${i}`);
        }
        for (let i = 1; i <= uCount; i++) {
            offerUpShellList.push(`Upsell #${i}`);
        }
        this.setState({
            offerUpShellList: offerUpShellList
        });

        getProducts().then((res) => {
            let obj;
            let arr = [];
            for (let props in res) {
                obj = res[props];
                arr.push(obj);
            }
            this.setState({
                optionData: Object.values(arr[1]),
                isTemplateFetched: true
            });
        });
    }

    nextOfferUpShell = () => {
        const { campaignId, updateFunnelInfo } = this.props;
        const totalOffers = this.state.offerUpShellList.length;
        if (this.state.activeIndex + 1 < totalOffers) {
            this.setState({
                activeIndex: this.state.activeIndex + 1
            });
        } else {
            this.props.moveStep("NEXT");
            if (this.props.isInsideTheVisualizer) {
                let p = new Promise((resolve) => {
                    getCampaigns().then((res) => {
                        this.setState({
                            optionData: Object.values(res.message.data)
                        });
                        resolve(Object.values(res.message.data));
                    });
                });
                p.then((optionData) => {
                    const campaign = Object.assign({}, ...(optionData.filter(campaign => campaign.campaignId === parseInt(campaignId))));
                    if (!_.isEmpty(campaign)) {
                        const products = campaign.products;

                        let upSales = [];
                        let offers = [];

                        products.map((product) => {
                            if (product.productType === "OFFER") {
                                offers.push(product);
                            } else {
                                upSales.push(product);
                            }
                        });

                        offers = offers.map((offer) => {
                            return {
                                value: offer.campaignProductId,
                                text: offer.productName,
                                type: offer.productType
                            };
                        });

                        upSales = upSales.map((upSale) => {
                            return {
                                value: upSale.campaignProductId,
                                text: upSale.productName,
                                type: upSale.productType
                            };
                        });

                        const data = this.props.funnelData;
                        let funnel = {
                            Title: data.title,
                            Offer: JSON.stringify(offers),
                            Upsale: JSON.stringify(upSales),
                            Campaign: campaignId.toString(),
                            Status: data.status,
                            VisualizerJson: data.visualizerJson,
                            page: data.page,
                        };
                        const filteredPage = funnel.page && funnel.page.map(data => _.pickBy(data, v => v !== null && v !== undefined));
                        funnel = {
                            ...funnel,
                            ["page"]: filteredPage
                        };
                        // updateFunnelDetails(funnel, data.referenceId)
                        //     .then(res => {
                        //         NotificationEmitter.emit(NOTIFICATION_EVENT_NAME, { type: "success", message: "Funnel updated successfully" });
                        //         this.props.getFunnelData(data.referenceId);
                        //     })
                        //     .catch(err => {
                        //         NotificationEmitter.emit(NOTIFICATION_EVENT_NAME, { type: "error", message: err.response.data.message });
                        //     });
                        updateFunnelInfo({ ...funnel })
                            .then(res => {
                                NotificationEmitter.emit(NOTIFICATION_EVENT_NAME, { type: "success", message: "Funnel updated successfully" });
                            })
                            .catch(err => {
                                NotificationEmitter.emit(NOTIFICATION_EVENT_NAME, { type: "error", message: err.response.data.message });
                            });
                    }
                });
            }
        }
    }

    renderTemplate = () => {
        const { activeComponent, activeIndex, offerUpShellList, offers, optionData, selectedProductId, activeProduct, offerArray, upsaleArray } = this.state;
        const { moveStep, closeWizard, handleSkipButton, campaignId } = this.props;
        switch (activeComponent) {
            case "EDIT_PRODUCT":
                return <CreateEditProduct offerArray={offerArray} upsaleArray={upsaleArray} setOfferUpsale={this.setOfferUpsale} {...this.props} editData={activeProduct} pageType="Edit" fromStepwizard selectedProductId={selectedProductId} isEdit upsells={(offerUpShellList[activeIndex]).toLowerCase().includes("upsell")} pageTitle={offerUpShellList[activeIndex]} selectTemplate={this.selectTemplate} moveStep={moveStep} nextOfferUpShell={this.nextOfferUpShell}/>;
            case "NEW":
                return <CreateEditProduct offerArray={offerArray} upsaleArray={upsaleArray} setOfferUpsale={this.setOfferUpsale} {...this.props} campaignId={campaignId} upsells={offerUpShellList.length !== 0 && (offerUpShellList[activeIndex]).toLowerCase().includes("upsell")} pageTitle={offerUpShellList[activeIndex]} handleSkipButton={handleSkipButton} editOffer={this.editOffer} offers={offers} selectTemplate={this.selectTemplate} moveStep={moveStep} nextOfferUpShell={this.nextOfferUpShell}/>;
            case "EXISTING":
                return <SelectExisting selectedProduct={(selectedProductId) => this.setState({ selectedProductId })} handleSkipButton={handleSkipButton} optionData={optionData} closeWizard={closeWizard} pageTitle={offerUpShellList[activeIndex]} nextOfferUpShell={this.nextOfferUpShell} selectTemplate={this.selectTemplate} labelText="Products" moveStep={moveStep}/>;
        }
    };

    selectTemplate = (value, activeProduct) => {
        this.setState({
            activeComponent: value,
            activeProduct
        });
    };

    render() {
        const { activeComponent, activeIndex, offerUpShellList, offers, selectedProductId } = this.state;
        const { handleSkipButton, campaignId } = this.props;
        return (
            <div
                className="template_Area_container"
            >
                {activeComponent ? (
                    <div className="template_Area">{this.renderTemplate()}</div>
                ) : (
                    <ExistOrNew handleSkipButton={handleSkipButton} pageTitle={offerUpShellList[activeIndex]} labelText={"Products"} selectTemplate={this.selectTemplate}/>
                )}
                {
                    (!activeComponent || activeComponent === "EXISTING") && <button
                        className="product-skip-button"
                        onClick={() => {this.nextOfferUpShell(); this.setState({ activeComponent: null });}}
                    >
                        Skip this step
                    </button>
                }
            </div>
        );
    }
}

export default Products;

Products.propTypes = {
    moveStep: PropTypes.func,
    nextOfferUpShell: PropTypes.func,
    closeWizard: PropTypes.func,
    handleSkipButton: PropTypes.func,
    campaignId: PropTypes.number,
    isInsideTheVisualizer: PropTypes.bool,
    offerCount: PropTypes.number,
    upsellCount: PropTypes.number,
    funnelData: PropTypes.object,
    getFunnelData: PropTypes.func,
    updateFunnelInfo: PropTypes.func
};