import React, { useState } from "react";
import PropTypes from "prop-types";
import { TextInput } from "../../../../commons";

export const ImportShareId = ({ shareId, importSharedIdPage }) => {
    const [ isShareidSame, setShareIdSameCheck ] = useState(false);
    const [ isShareIdError, setShareIdErrorCheck ] = useState(false);
    const [ importShareId, setImportShareId ] = useState("");
    const handleImportShareIdChange = (id) => {
        setImportShareId(id);
    };
    const importSharePage = () => {
        if (shareId === importShareId) {
            setShareIdErrorCheck(true);
            setShareIdSameCheck(true);
        } else if (!importShareId.length) {
            setShareIdErrorCheck(true);
            setShareIdSameCheck(false);
        } else {
            setShareIdErrorCheck(false);
            setShareIdSameCheck(false);
            importSharedIdPage(importShareId);
        }
    };
    return (
        <>
            <TextInput
                name="importId"
                label={"Import A Page"}
                value={importShareId}
                placeholder={"Enter Share Id here"}
                onChange={(e) => handleImportShareIdChange(e.target.value)}
                id="importId"
                btnText="Import"
                error={isShareIdError ? true : false}
                errorMessage={isShareidSame ? "Please enter different Share Id" : "Please enter valid Share Id"}
            />
            <div className="text-right">
                <button className="btn btn-primary" onClick={importSharePage}>
                    <i class="fa fa-download m-r-xs" aria-hidden="true"></i> Import
                </button>
            </div>
        </>
    );
};
ImportShareId.propTypes = {
    shareId: PropTypes.string,
    importSharedIdPage: PropTypes.func,
};

export default ImportShareId;