export const funnelTemplateTypeMap = [
    {
        templateTypeId: 1,
        label: "Single Product",
        name: "singleproduct",
    },
    {
        templateTypeId: 2,
        label: "Multiple Product",
        name: "multipleproduct",
    },
    {
        templateTypeId: 3,
        label: "Lead Magnet",
        name: "leadmagnet",
    },
    {
        templateTypeId: 4,
        label: "Application",
        name: "application",
    },
    {
        templateTypeId: 5,
        label: "Membership",
        name: "membership",
    },
    {
        templateTypeId: 6,
        label: "Live Demo",
        name: "livedemo",
    },
    {
        templateTypeId: 7,
        label: "Product Launch ",
        name: "productlaunch ",
    },
    {
        templateTypeId: 8,
        label: "Squeeze Page ",
        name: "squeezepage ",
    },
    {
        templateTypeId: 9,
        label: "Invisible",
        name: "invisible ",
    },
    {
        templateTypeId: 10,
        label: "Hero",
        name: "hero",
    },
    {
        templateTypeId: 11,
        label: "2-Step Tripwire",
        name: "2-steptripwire",
    },
    {
        templateTypeId: 12,
        label: "Bridge",
        name: "bridge "
    },
    {
        templateTypeId: 13,
        label: "Sales Letter ",
        name: "salesletter ",
    },
    {
        templateTypeId: 14,
        label: "Storefront",
        name: "storefront",
    },
    {
        templateTypeId: 15,
        label: "Summit",
        name: "summit",
    },
    {
        templateTypeId: 16,
        label: "Home Page",
        name: "homepage",
    },
    {
        templateTypeId: 17,
        label: "Ask Campaign",
        name: "askcampaign",
    },
    {
        templateTypeId: 18,
        label: "Auto Webinar",
        name: "autowebinar",
    },
    {
        templateTypeId: 19,
        label: "Webinar",
        name: "webinar ",
    },
    {
        templateTypeId: 20,
        label: "Cancellation",
        name: "cancellation",
    },
    {
        templateTypeId: 21,
        label: "Survey",
        name: "survey",
    },
    {
        templateTypeId: 22,
        label: "Reverse Squeeze Page ",
        name: "reversesqueezepage ",
    },
    {
        templateTypeId: 23,
        label: "Video Sales Letter",
        name: "videosalesletter ",
    },
];

export const funnelTemplateWithAllMap = [
    {
        templateTypeId: 0,
        label: "All",
        name: "",
    },
    ...funnelTemplateTypeMap
];



export const getTemplateConfig = (templateTypeId) => {
    return funnelTemplateTypeMap.find(template => template.templateTypeId === templateTypeId);
};

export const funnelTemplateDropDownOptions = funnelTemplateTypeMap.map((type) => {
    return { value: type.templateTypeId, text: type.label };
});