import React from "react";
import PropTypes from "prop-types";
import FunnelForm from "../funnels-form";
import { Modal } from "react-bootstrap";
import { Page } from "./components/page";
import { Templates } from "./../../templates";
import { Tabs } from "../../commons/draggable-tabs";
import { LocalStorageHelper } from "../../utilities";
import { arrayMove } from "react-sortable-hoc";
import { NavLink } from "react-router-dom";
import "./style.css";
// import { CLIENT_RENEG_LIMIT } from "tls";
// import { constants } from "../constants";
// import { Link } from "react-router-dom";
export class ManageFunnel extends React.Component {

    static propTypes = {
        setTemplateHtml: PropTypes.func,
        funnel: PropTypes.object.isRequired,
        getFunnelData: PropTypes.func,
        funnelReferenceId: PropTypes.string,
        authors: PropTypes.array.isRequired,
        offers: PropTypes.array.isRequired,
        funnelType: PropTypes.array.isRequired,
        campaigns: PropTypes.array.isRequired,
        currentPage: PropTypes.object,
        actions: PropTypes.object.isRequired,
        getTemplates: PropTypes.func,
        loadTemplates: PropTypes.func,
        currentTemplate: PropTypes.object,
        pages: PropTypes.array,
        templates: PropTypes.array,
        selectedCampaign: PropTypes.object,
        splitViewPage: PropTypes.object,
        template: PropTypes.object,
        loadFunnels: PropTypes.func,
        deleteRunner: PropTypes.func,
        createPageView: PropTypes.func,
        clearJsonData: PropTypes.func,
        applySplitView: PropTypes.func,
        manageSavePageView: PropTypes.func,
        getPage: PropTypes.func,
        upsales: PropTypes.array.isRequired,
        editFunnel: PropTypes.func.isRequired,
        isLoading: PropTypes.bool,
        isError: PropTypes.bool,
        clonePage: PropTypes.bool,
        splitValue: PropTypes.object,
        splitValueChanged: PropTypes.string,
        oldfunnel: PropTypes.string,
        splitValuePageClick: PropTypes.bool,
        history: PropTypes.object,
    };

    static defaultProps = {
        currentPage: null
    };
    constructor(props, context) {
        super(props, context);
        this.state = {
            funnel: Object.assign({
                title: "",
                author: "",
                campaign: "",
                fListFunnelTypeId: "",
                offer: [""],
                upsale: [""],
                status: "TestStatus"
            }, props.funnel),
            allOffers: props.offers,
            allUpsales: props.upsales,
            showSettings: false,
            showRenameBox: false,
            errors: {},
            // currentPage: this.props.pages[0],
            tabs: [...this.props.pages],
            renameValue: "",
            isSuccess: true,
            modalShow: false,
            templateShow: false,
            pageSelect: false,
            showDuplicatePage: true,
            copiedPage: false,
            deletedPageId: "",
            htmlContent: "",
            cssContent: "",
            splitValuePageClick: false,
            pages: ""
        };
        this.updateFunnelState = this.updateFunnelState.bind(this);
        this.onOfferChange = this.onOfferChange.bind(this);
        this.onUpsaleChange = this.onUpsaleChange.bind(this);
        this.removeOfferField = this.removeOfferField.bind(this);
        this.removeUpsaleField = this.removeUpsaleField.bind(this);
        this.validate = this.validate.bind(this);
        this.renamepage = this.renamepage.bind(this);
        this.renameHandler = this.renameHandler.bind(this);
        this.updateFunnel = this.updateFunnel.bind(this);
        this.onCampaignChange = this.onCampaignChange.bind(this);
        this.setInitialPage = this.setInitialPage.bind(this);
        const { funnelReferenceId } = this.props;
        this.props.clearJsonData();
        this.props.getFunnelData({ funnelReferenceId });
        this.props.loadFunnels();
        this.props.loadTemplates();
    }
    componentWillReceiveProps(nextProps) {
        const { isLoading } = this.props;
        // if (this.props.pages !== nextProps.pages) {
        //     this.setState({ pages: nextProps.pages });
        // }
        if (nextProps.funnel !== this.props.funnel) {
            this.setState({ funnel: nextProps.funnel });
        } else if ((isLoading === true && nextProps.isLoading === false) && !nextProps.funnel.referenceId) {
            this.navigateToError();
        }

        if (nextProps.funnel && (nextProps.campaigns !== this.props.campaigns)) {
            let offersList = [], upsaleList = [];
            const selectedCampaign = nextProps.campaigns.find ((c) => nextProps.funnel.campaign === `${c.value}`);
            if (selectedCampaign) {
                const productOffer = selectedCampaign.products.filter(p => p.productType === "OFFER");
                const productUpsell = selectedCampaign.products.filter(p => p.productType === "UPSALE");
                offersList = productOffer.map(product => {
                    return {
                        value: product.campaignProductId,
                        text: product.productName,
                        type: product.productType
                    };
                });
                upsaleList = productUpsell.map(product => {
                    return {
                        value: product.campaignProductId,
                        text: product.productName,
                        type: product.productType
                    };
                });
            }
            // this.props.actions.setCurrentPageId(nextProps.pages[0].referenceId);
            this.setState({ allOffers: offersList, allUpsales: upsaleList });
        }
        {!nextProps.isLoading &&
            this.setState({ showSettings: false });
        }
    }
    navigateToError() {
        this.props.history.push("/404");
    }
    updateFunnelState(event) {
        const field = event.target.name;
        let funnel = Object.assign({}, this.state.funnel);
        funnel[field] = event.target.value;
        return this.setState({ funnel: funnel });
    }

    updateFunnel(event) {
        event.preventDefault();
        this.props.actions.updateFunnel(this.state.funnel);
        this.props.history.push("/funnels");
    }

    handleTabClick = (e, tabData) => {
        const localStorage = new LocalStorageHelper();
        localStorage.store("currentPageReferenceId", tabData.referenceId);
        let currentPageReferenceId = localStorage.load("currentPageReferenceId");
        this.props.actions.setCurrentPageId(currentPageReferenceId);
        this.setState({
            showSettings: false,
        },() => this.props.getPage(currentPageReferenceId));
    };
    templateShow = () => {
        this.setState({
            templateShow: true,
            pageSelect: false
        });
    };
    pageSelect = () => {
        this.setState({
            pageSelect: true
        });
    };

    handleTabAddButtonClick = () => {
        this.props.actions.addNewPage(this.props.funnelReferenceId);
    };

    onSortEnd = ({ oldIndex, newIndex }) => {
        this.setState({
            tabs: arrayMove(this.state.tabs, oldIndex, newIndex),
        });
    };

    onTemplateSelected = (template) => {
        const { selectedCampaign, currentPage, funnel } = this.props;
        this.setState({
            templateShow: false
        });
        this.props.actions.setTemplateHtml(template.htmlUrl);
        this.props.actions.setPageTemplateId(template.templateId);
        // TODO - Remove this after the demo
        if (currentPage) {
            this.setState({
                referenceId: this.props.currentPage.pageView[0].referenceId,
                currentPageViewId: this.props.currentPage.pageView[0].referenceId
            });
            const { referenceId } = this.props.currentPage.pageView[0];
            fetch(template.htmlUrl).
                then((resp) => {return resp.text();}).
                then((htmlContent) => {
                    this.props.manageSavePageView({
                        html: htmlContent,
                        referenceId,
                        pageName: referenceId,
                        pageViewReferenceId: referenceId,
                        funnelReferenceId: funnel.referenceId,
                        templateId: template.templateId,
                        selectedCampaign,
                        currentPageId: currentPage.referenceId
                    });
                });
        } else {
            alert("Please Select the page before selecting the template");
        }
    }
    splitTemplateSelected = (template) => {
        const { selectedCampaign, currentPage, funnel } = this.props;
        this.setState({
            templateShow: false,
            showDuplicatePage: true
        });
        this.props.actions.setTemplateHtml(template.htmlUrl);
        this.props.actions.setPageTemplateId(template.templateId);
        if (currentPage) {
            this.setState({
                referenceId: this.props.currentPage.pageView[0].referenceId,
                currentPageViewId: this.props.currentPage.pageView[0].referenceId
            });
            const { referenceId } = this.props.currentPage;
            fetch(template.htmlUrl).
                then((resp) => {return resp.text();}).
                then((htmlContent) => {
                    this.props.actions.duplicatePageView({
                        title: template.title,
                        FTemplateId: "",
                        html: htmlContent,
                        referenceId,
                        pageName: referenceId,
                        templateId: template.templateId,
                        selectedCampaign,
                        funnelReferenceId: funnel.referenceId
                    });
                });
        }
    }
    splitViewApply = (value1,value2) => {
        const { currentPage, funnel, splitValue } = this.props;
        const pageViewId1 = splitValue ? splitValue.pageView[0].referenceId : null;
        const pageViewId2 = splitValue ? splitValue.pageView[1].referenceId : null;
        this.props.applySplitView({ funnelReferenceId: funnel.referenceId ,pageReferenceId: currentPage.referenceId,value1,value2,pageViewId1,pageViewId2 });
    }
    getSplitValue = (currentPageReferenceId) => {
        this.props.getPage(currentPageReferenceId);
    }
    setWinner = (winnerId) => {
        const { currentPage } = this.props;
        const splitwinner = currentPage.pageView.find(function (element) {
            return element.referenceId !== winnerId;
        });
        this.props.deleteRunner(splitwinner.referenceId);
    }
    clonePage = () => {
        const { currentTemplate , currentPage, selectedCampaign } = this.props;
        fetch(currentTemplate.htmlUrl).
            then((resp) => {return resp.text();}).
            then((htmlContent) => {
                fetch(currentTemplate.cssUrl).
                    then((resp) => {return resp.text();}).
                    then((cssContent) => {
                        this.props.createPageView({
                            title: currentTemplate.title,
                            FTemplateId: "",
                            referenceId: currentPage.referenceId,
                            html: htmlContent,
                            css: cssContent,
                            templateId: currentTemplate.templateId,
                            selectedCampaign
                        });
                    });
            });
    }
    duplicatePage = () => {
        this.setState({
            pageSelect: false,
            copiedPage: true,
        },this.clonePage());
    }
    setInitialPage(pages) {
        this.props.actions.setCurrentPageId(pages[0].referenceId);
    }
    validate(event) {
        const funnel = this.state.funnel;
        (!funnel.title) ? this.setState({ errors: { title: true, isSuccess: false } }) :
            (!funnel.campaign) ? this.setState({ errors: { campaignId: true, isSuccess: false } }) :
                (!funnel.fListFunnelTypeId) ? this.setState({ errors: { funnelType: true, isSuccess: false } }) :
                    (funnel.offer[0] === "") ? this.setState({ errors: { offerId: true, isSuccess: false } }) :
                        //(funnel.upsale[0] === "") ? this.setState({ errors: { upsaleId: true, isSuccess: false } }) :// if we need upsale validation on future
                        this.setState({ isSuccess: true }, this.saveFunnel(event));
    }
    onCampaignChange(event) {
        const field = event.target.name;
        const { funnel } = this.state;
        let offersList = [];
        let upsaleList = [];
        const selectedCampaign = this.props.campaigns.find ((c) => event.target.value === `${c.value}`);
        if (selectedCampaign) {
            const productOffer = selectedCampaign.products.filter(p => p.productType === "OFFER");
            const productUpsell = selectedCampaign.products.filter(p => p.productType === "UPSALE");
            offersList = productOffer.map(product => {
                return {
                    value: product.campaignProductId,
                    text: product.productName,
                    type: product.productType
                };
            });
            upsaleList = productUpsell.map(product => {
                return {
                    value: product.campaignProductId,
                    text: product.productName,
                    type: product.productType
                };
            });
        }
        return this.setState({
            funnel: {
                ...funnel,
                [field]: event.target.value
            },
            allOffers: offersList, allUpsales: upsaleList
        });
    }
    onOfferChange(event, index) {
        const value = event.target.value;
        const offers = this.state.allOffers.find(o => `${o.value}` === value);
        const { funnel } = this.state;
        const { offer } = funnel;
        let myoffer = offer;
        if (offer && !Array.isArray(offer)) {
            myoffer = JSON.parse(offer);
        }
        myoffer.splice(index, 1, offers);
        return this.setState({ funnel: {
            ...funnel,
            offer: myoffer
        } });
    }
    onUpsaleChange(event, index) {
        const value = event.target.value;
        const upsell = this.state.allUpsales.find(o => `${o.value}` === value);
        const { funnel } = this.state;
        const { upsale } = funnel;
        let myupsale = upsale;
        if (upsale && !Array.isArray(upsale)) {
            myupsale = JSON.parse(upsale);
        }
        myupsale.splice(index, 1, upsell);
        if (funnel.upsale[0] !== "") {
            this.setState({ isSuccess: true, errors: { upsaleId: false } });
        } else {this.setState({ isSuccess: false });}
        return this.setState({ funnel: {
            ...funnel,
            upsale: myupsale
        } });
    }
    saveFunnel(event) {
        event.preventDefault();
        this.props.editFunnel(this.state.funnel);
    }
    cancel = (event) => {
        event.preventDefault();
        this.setState({ showSettings: false });
    }
    addOfferField = () => {
        const { funnel } = this.state;
        const { offer } = funnel;
        let myoffer = offer;
        if (offer && !Array.isArray(offer)) {
            myoffer = JSON.parse(offer);
        }
        myoffer.push({});
        this.setState({
            funnel: {
                ...funnel,
                offer: myoffer
            }
        });
    }
    addUpsaleField = () => {
        const { funnel } = this.state;
        const { upsale } = funnel;
        let myupsale = upsale;
        if (upsale && !Array.isArray(upsale)) {
            myupsale = JSON.parse(upsale);
        }
        myupsale.push({});
        this.setState({
            funnel: {
                ...funnel,
                upsale: myupsale
            }
        });
    }
    removeOfferField = (index) => {
        const { funnel } = this.state;
        const { offer } = funnel;
        let myoffer = offer;
        if (offer && !Array.isArray(offer)) {
            myoffer = JSON.parse(offer);
        }
        myoffer.splice(index,1);
        this.setState({
            funnel: {
                ...funnel,
                offer: myoffer
            }
        });
    }
    removeUpsaleField = (index) => {
        const { funnel } = this.state;
        const { upsale } = funnel;
        let myupsale = upsale;
        if (upsale && !Array.isArray(upsale)) {
            myupsale = JSON.parse(upsale);
        }
        myupsale.splice(index,1);
        this.setState({
            funnel: {
                ...funnel,
                upsale: myupsale
            }
        });
    }
    editPageName = (e, tabData) => {
        e.stopPropagation();
        this.props.actions.setCurrentPageId(tabData.referenceId);
        this.setState({
            renameValue: "",
            showRenameBox: true
        });
    }

    deletePage = (e, pageReferenceId) => {
        e.stopPropagation();
        this.setState({
            modalShow: true, deletedPageId: pageReferenceId
        });
    }
    handleModal = (e) => {
        e.stopPropagation();
        this.setState({
            modalShow: false,
        });
    }
    handleClose = (e) => {
        e.stopPropagation();
        this.setState({
            templateShow: false,
            pageSelect: false,
        });
    }
    handleDelete = (e) => {
        const { deletedPageId } = this.state;
        e.stopPropagation();
        this.props.actions.deletePage({
            pageReferenceId: deletedPageId,
            funnelReferenceId: this.state.funnel.referenceId
        });
        this.setState({
            modalShow: false
        });
    }
    toggleSettingsView = () => {
        this.setState({
            showSettings: !this.state.showSettings
        });
    }
    renderTabPostElements = (value) => {
        return (
            <div className="tab-post-elements">
                <button onClick={(e) => this.editPageName(e, value)} className="actionBtn primary">
                    <i className="fa fa-edit" />
                </button>
                {!value.isDefaultPage &&
                    <button onClick={(e) => this.deletePage(e, value.referenceId)} className="actionBtn danger">
                        <i className="fa fa-close" />
                    </button>
                }
                { this.props.currentPage && this.props.currentPage.referenceId === value.referenceId && this.state.showRenameBox &&
                    <div className="form-group renameBox">
                        <div className="input-group">
                            <select onChange = {(e) => this.renamepage(e)} type="text" placeholder="Enter page name" className="form-control input-lg text-muted">
                                <option className="form-control input-lg text-muted" value="Select Page">-- Select Page --</option>
                                <option className="form-control input-lg text-muted" value="leadPage">Lead Page</option>
                                <option className="form-control input-lg text-muted" value="checkoutPage">Checkout Page</option>
                                <option className="form-control input-lg text-muted" value="presellPage">Presell Page</option>
                                <option className="form-control input-lg text-muted" value="upsellPage1">Upsell Page 1</option>
                                <option className="form-control input-lg text-muted" value="upsellPage2">Upsell Page 2</option>
                                <option className="form-control input-lg text-muted" value="upsellPage3">Upsell Page 3</option>
                                <option className="form-control input-lg text-muted" value="upsellPage4">Upsell Page 4</option>
                                <option className="form-control input-lg text-muted" value="thankYouPage">Thank You Page</option>
                            </select>
                            <div className="input-group-addon bg-primary" onClick={() => this.renameHandler(value)}><i className="fa fa-check" /></div>
                        </div>
                    </div>
                }
            </div>
        );
    }
    renamepage(event) {
        this.setState({ renameValue: event.target.value });
    }
    renameHandler(oldData) {
        this.setState({
            title: this.state.renameValue ? this.state.renameValue : oldData.title,
            showRenameBox: !this.state.showRenameBox
        });
        if (this.state.renameValue) {
            this.props.actions.editPageTitle({
                pageId: oldData.referenceId,
                title: this.state.renameValue
            });
        }
    }
    render() {

        let { isLoading, isError, funnel } = this.props;
        const { pages } = this.props;
        // this.props.pages.length > 0 ? this.setInitialPage(this.props.pages) : null;
        const isVisualizer = funnel.isVisualizer;
        // const settingsButtonClass = this.state.showSettings ? "btn btn-default active" : "btn btn-default";
        const settingsToggleClass = this.state.showSettings ? "funnelSettings active" : "funnelSettings";
        const managesettingsToggleClass = this.state.showSettings ? "funnelSettings manangeFunnel active" : "funnelSettings manangeFunnel";
        const addPageBtn = pages && pages.length < 10 ? "btn btn-primary btn-sm " : "btn btn-primary btn-sm loginBtn disabled";
        const localStorage = new LocalStorageHelper();
        return (
            <div>
                <div className="row wrapper border-bottom white-bg page-heading" style={{ paddingTop: "1%", paddingBottom: "1%" }}>
                    <div className="col-sm-6">
                        <h2>Funnels</h2>
                        <ol className="breadcrumb">
                            <li><NavLink to="/">Home</NavLink></li>
                            <li><NavLink to="/funnels">Funnels</NavLink></li>
                            <li>{ funnel.title }</li>
                        </ol>
                    </div>
                    <div className="col-sm-6">
                        <div className="title-action">
                            <a className={addPageBtn} onClick={this.handleTabAddButtonClick}><i className='fa fa-plus'/> Add new page</a>
                        </div>
                    </div>
                </div>
                <div className="wrapper wrapper-content row ">
                    <div className="settingsBox">
                        <div className="col-lg-12">
                            <div className={ !isVisualizer ? managesettingsToggleClass : settingsToggleClass}>
                                {this.state.showSettings &&
                                    (
                                        <FunnelForm funnel={this.state.funnel}
                                            onChange={this.updateFunnelState}
                                            onSave={this.validate}
                                            onCancel={this.cancel}
                                            allAuthors={this.props.authors}
                                            allCampaigns={this.props.campaigns}
                                            funnelType={this.props.funnelType}
                                            errors={this.state.errors}
                                            allOffers={this.state.allOffers}
                                            allUpsales={this.state.allUpsales}
                                            addOfferField={this.addOfferField}
                                            onOfferChange={this.onOfferChange}
                                            addUpsaleField={this.addUpsaleField}
                                            onUpsaleChange={this.onUpsaleChange}
                                            removeOfferField={this.removeOfferField}
                                            removeUpsaleField={this.removeUpsaleField}
                                            onCampaignChange={this.onCampaignChange}
                                            isLoading={isLoading}
                                            isError={isError}
                                        />
                                    )
                                }
                            </div>
                            <div onClick={this.toggleSettingsView} className="spin-icon">
                                <i className="fa fa-cog fa-spin" />
                            </div>
                        </div>
                    </div>
                    <div className="col-sm-3 col-xs-12">
                        <div>
                            {/* <button type="button" className={settingsButtonClass} onClick={this.toggleSettingsView}>
                                 Settings
                            </button> */}
                        </div>
                        <div>
                            <Tabs
                                tabs={this.props.pages}
                                useDragHandle={true}
                                activeTab={(this.state.showSettings || !this.props.currentPage) ? false : this.props.currentPage.referenceId }
                                onSortEnd={this.onSortEnd}
                                clickHandler={this.handleTabClick}
                                renderPostElements={this.renderTabPostElements}
                                renamepage ={this.renamepage}
                                renameHandler ={this.renameHandler}
                            />
                        </div>
                    </div>
                    <div className="col-sm-9 col-xs-12 manageBox">
                        { this.props.currentPage && this.props.currentPage.pageView[0] && this.props.currentPage.pageView[0].fTemplateId
                            ? <Page
                                template={this.props.currentTemplate}
                                funnel={this.props.funnel}
                                currentPage={this.props.currentPage}
                                referenceId={this.props.currentPage.pageView[0].fileReferenceId}
                                currentPageViewId={this.props.currentPage.pageView[0].referenceId}
                                currentPageView={this.props.currentPage.pageView[0]}
                                duplicatePageViewId={this.props.currentPage.pageView[1] ? this.props.currentPage.pageView[1].referenceId : null}
                                duplicatePageView={this.props.currentPage.pageView[1] ? this.props.currentPage.pageView[1] : null}
                                pageSelect= {this.pageSelect}
                                clonePage= {this.state.copiedPage}
                                splitViewPut={this.splitViewApply}
                                getSplitValue={this.getSplitValue}
                                splitValue={this.props.splitValue}
                                splitValueChanged={this.props.splitValueChanged}
                                splitViewPage={this.props.splitViewPage}
                                setWinner={this.setWinner}
                                showDuplicatePage= {this.state.showDuplicatePage}
                                isLoading = {this.props.isLoading}
                            /> :
                            <Templates onTemplateSelected={this.onTemplateSelected} oldfunnel = {this.props.oldfunnel} />
                        }
                    </div>
                </div>
                <Modal show={this.state.modalShow} backdrop = "static" onHide={this.handleClose} bsSize="small" aria-labelledby="contained-modal-title-lg">
                    <Modal.Header >
                        <Modal.Title><h3 className="text-white">Confirmation</h3></Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <h3 className="text-center">Are you sure to delete page?</h3>
                    </Modal.Body>
                    <Modal.Footer>
                        <a className="btn btn-primary" onClick = {this.handleDelete}>Yes</a>
                        <a className="btn btn-default" onClick = {this.handleModal}>No</a>
                    </Modal.Footer>
                </Modal>
                <Modal show={this.state.pageSelect} backdrop = "static" onHide={this.handleClose} bsSize="lg" aria-labelledby="contained-modal-title-lg">
                    <Modal.Header closeButton>
                    </Modal.Header>
                    <Modal.Body>
                        {/*<button className="btn btn-lg btn-primary btn-block" onClick = {this.duplicatePage}>Create Duplicate Page</button>*/}
                        <button className="btn btn-lg btn-primary btn-block" onClick = {this.templateShow} >Create From Templates</button>
                    </Modal.Body>
                </Modal>
                <Modal show={this.state.templateShow} backdrop = "static" onHide={this.handleClose} bsSize="lg" aria-labelledby="contained-modal-title-lg">
                    <Modal.Header closeButton>
                        <Modal.Title><h3 className="text-white">Choose Template</h3></Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Templates className="templateModal" onTemplateSelected={this.splitTemplateSelected} />
                    </Modal.Body>
                </Modal>
            </div>
        );
    }
}

ManageFunnel.contextTypes = {
    router: PropTypes.object
};