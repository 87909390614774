import { VisualizerRightPanel as RightPanel } from "./visualizer-right-panel";
import { connect } from "react-redux";
import * as visualizerSelectors from "../../selectors/visualizer-selectors";
import * as templateActions from "../../../templates/actions";
import { commonActions, visualizerActions } from "../../actions";
import { selectors as templateSelectors } from "../../../templates";

function mapStateToProps(state, ownProps) {
    const selectors = commonActions.getSelector(ownProps.parent);
    const importedFunnel = selectors.getImportedFunnel(state);
    const funnel = importedFunnel ? importedFunnel : selectors.getFunnelData(state);
    const visualizerData = visualizerSelectors.getVisualizerData(state);
    const isFontLoading = selectors.getFontsLoading(state);
    const pageData = selectors.getPageData(state);
    const fontList = selectors.getGoogleFonts(state);
    const templates = templateSelectors.getTemplates(state);
    const fonts = selectors.getSavedFonts(state);
    return {
        funnel,
        visualizerData,
        isFontLoading,
        pageData,
        fontList,
        templates,
        fonts,
    };
}

function mapDispatchToProps(dispatch, ownProps) {
    const actions = commonActions.getActions(ownProps.parent);
    return {
        updateVisualizerData: (params) => dispatch(visualizerActions.updateVisualizerData(params)),
        saveFonts: (params) => dispatch(actions.saveFonts(params)),
        removeCallBackEvent: (params) => dispatch(visualizerActions.removeCallBackEvent(params)),
        closeRightPanel: (params) => dispatch(visualizerActions.closeRightPanel(params)),
        saveTemplatePageView: (params) => dispatch(actions.saveTemplatePageView(params)),
        loadTemplates: () => dispatch(templateActions.getTemplates()),
        loadFonts: () => dispatch(actions.loadFonts()),
        editPage: (params) => dispatch(actions.editPage(params)),
        editFunnel: (params) => dispatch(actions.editFunnel(params)),
    };
}

export const VisualizerRightPanel = connect(mapStateToProps, mapDispatchToProps)(RightPanel);
