export const closeRightPanel = "closeRightPanel";
export const textChange = "textChange";
export const addTextblock = "addTextblock";
export const editableText = "editableText";
export const removeCell = "removeCell";
export const modifyCell = "modifyCell";
export const openRightPanel = "openRightPanel";
export const loadButtonCallBack = "loadButtonCallBack";
export const handleDelete = "handleDelete";
export const importPageCallback = "importPageCallback";
export const newLoadedCallback = "newLoadedCallback";
export const pageNameChange = "pageNameChange";
