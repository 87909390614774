import React from "react";
import PropTypes from "prop-types";
import { SelectInput, funnelOfferError } from "../commons";

const OfferField = ({
    funnel, isEdit,
    allOffers, errors, isDefaultOptionEnabled,
    addOfferField, onOfferChange, removeOfferField }) => {
    const { offer } = funnel;
    let myoffer = offer;
    // if (offer && !Array.isArray(offer)) {
    //     myoffer = JSON.parse(offer);
    // }
    const all = { value: "all", text: "ALL OFFERS", type: "OFFER" };
    const valueFound = allOffers.find(element => {
        if (element.value === "all") {
            return element;
        }
    });
    if (!valueFound) {
        allOffers.push(all);
    }

    const offerFields = myoffer && myoffer.map((offer, index) =>
        <div className="offerBox" key={index}>
            {!isEdit && ((index === 0) ?
                <div className="add_btn"><a onClick={() => addOfferField()} className="btn btn-primary"><i className="fa fa-plus" /></a></div> :
                <div className="add_btn"><a onClick={() => removeOfferField(index)} className="btn btn-danger"><i className="fa fa-close" /></a></div>)}
            <SelectInput
                isDefaultOptionEnabled={isDefaultOptionEnabled}
                name="offer"
                disabled={isEdit}
                value={myoffer[0] ? offer.value : ""}
                defaultOption="Choose Offer"
                options={allOffers}
                onChange={(e) => onOfferChange(e, index)}
                errorMessage={funnelOfferError}
                error={errors.offerId} />
        </div>
    );

    return (
        <div>
            <label>Offers</label>
            {offerFields}
        </div>
    );
};

OfferField.propTypes = {
    funnel: PropTypes.object.isRequired,
    allOffers: PropTypes.array,
    errors: PropTypes.object,
    addOfferField: PropTypes.func,
    onOfferChange: PropTypes.func,
    removeOfferField: PropTypes.func,
    isEdit: PropTypes.bool,
    isDefaultOptionEnabled: PropTypes.bool
};

export default OfferField;