const handleBorderRadiusEdgesChange = (editor, component) => {
    editor.getSelected().removeClass("borderRadiusBottom");
    editor.getSelected().removeClass("borderRadiusTop");
    if (component.changed.borderRadiusEdges !== "All" && component.changed.borderRadiusEdges !== "None") {
        editor.getSelected().addClass(component.changed.borderRadiusEdges);
    } else if (component.changed.borderRadiusEdges === "None") {
        editor.getSelected().addClass("borderRadiusBottom");
        editor.getSelected().addClass("borderRadiusTop");
    }
};

export default handleBorderRadiusEdgesChange;