export const stickybar = (editor, { addOnTraits = [] }) => {
    const domc = editor.DomComponents;
    var dType = domc.getType("default");
    var dModel = dType.model;
    var dView = dType.view;
    domc.addType("stickybar", {
        model: dModel.extend({
            defaults: Object.assign({}, dModel.prototype.defaults, {
                traits: [
                    {
                        type: "select",
                        label: "Trigger On",
                        name: "trigger_stickybar_on",
                        changeProp: 1,
                        options: [
                            { value: "none", name: "None" },
                            { value: "trigger_on_exit", name: "Trigger on Exit" },
                            { value: "triggerStickyOnLoad", name: "Trigger on Load(Delay)" },
                            { value: "triggerStickyScroll", name: "Trigger on Scroll" }
                        ]
                    },{
                        type: "input",
                        label: "Delay (Min)",
                        changeProp: 1,
                        name: "minutesDelay",
                    },
                    {
                        type: "input",
                        label: "Delay (Sec)",
                        changeProp: 1,
                        name: "secondsDelay",
                    },{
                        type: "select",
                        label: "Stickybar",
                        name: "stickyBarPosition",
                        changeProp: 1,
                        options: [
                            { name: "Default", value: "default" },
                            { name: "Top", value: "top" },
                            { name: "Center", value: "center" },
                            { name: "Bottom", value: "bottom" },
                            { name: "Left", value: "left" },
                            { name: "Right", value: "right" },
                        ]
                    },
                    {
                        type: "number",
                        label: "Bottom gap",
                        name: "stickyBottomGap",
                        changeProp: 1,
                    },{
                        type: "number",
                        label: "Top gap",
                        name: "stickyTopGap",
                        changeProp: 1,
                    },
                    ...addOnTraits,
                ],
            }),
        },
        {
            isComponent: function (el) {
                //eslint-disable-next-line
                if (el.tagName === "div") {
                    return { type: "popup" };
                }
            },
        }),

        view: dView.extend({
            events: {
                "click": "handleClick"
            },
            init() {
                this.listenTo(this.model, "change:content", this.updateContent);
            },
            updateContent() {
                this.el.innerHTML = this.model.get("content");
            },
            handleClick(e) {
                e.preventDefault();
            },
        }),
        onValueChange: function () {
            this.target.set("content", this.model.get("value"));
        }
    });
};