import React, { useState } from "react";
import PropTypes from "prop-types";

function StepWizard(props) {
    // const isInsideTheVisualizer = props.history.location.state ? true : false;
    const isInsideTheVisualizer = props.isInsideTheVisualizer ? true : false;
    const { steps, closeWizard } = props;
    const [activeIndex, setActiveIndex] = useState(0);
    const [campaignId, setCampaignId] = useState(null);
    const [merchantId, setMerchantId] = useState(null);
    const [currencySymbol, setCurrencySymbol] = useState(null);
    const [isSkipButtonVisible , setSkipButtonVisible] = useState(true);
    const totalSteps = steps.length;
    const _renderNumber = () => {
        return steps.map((step, i) => (
            <div
                className={`separate_number ${i <= activeIndex ? "custom-active" : ""}`}
                key={i}
            >
                <div className={`number ${i <= activeIndex ? "custom-active" : ""}`}>
                    {i + 1}
                </div>
                <span className="number-text">{step.title}</span>
            </div>
        ));
    };

    const _handleSkipButton = (value) => {
        setSkipButtonVisible(value);
    };

    const _setCampaignId = (id) => {
        setCampaignId(id);
    };

    const _setMerchantId = (id) => {
        setMerchantId(id);
    };

    const _renderStep = (props) => {
        const StepComponent = steps[activeIndex].component;
        return <StepComponent isInsideTheVisualizer={isInsideTheVisualizer} merchantId={merchantId} setMerchantId={_setMerchantId} campaignId={campaignId} currencySymbol={currencySymbol} setCurrencySymbol={(symbol) => setCurrencySymbol(symbol)} {...props} setCampaignId={_setCampaignId} moveStep={type => _moveStep(type)} handleSkipButton={value => _handleSkipButton(value)} closeWizard={closeWizard}/>;
    };

    const _moveStep = (type, shouldClose = false) => {
        const closable = activeIndex !== 0 || !shouldClose;
        if (type === "NEXT") {
            if (closable && activeIndex + 1 !== totalSteps) {
                setActiveIndex(activeIndex + 1);
            } else {
                closeWizard();
            }
        } else {
            if (activeIndex - 1 !== -1) {
                setActiveIndex(activeIndex - 1);
            }
        }
    };

    return (
        <div className="main_container">
            <div className="header">
                <div className="number_area">{_renderNumber(props)}</div>
            </div>
            <div className={`body_container ${!isSkipButtonVisible ? "maxHeight" : ""}`}>{_renderStep(props)}</div>
            { isSkipButtonVisible &&
                <div className="footer">
                    <button
                        className="skip-button"
                        onClick={() => _moveStep("NEXT", activeIndex === 0)}
                    >
                        Skip this step
                    </button>
                </div>
            }
        </div>
    );
}

export default StepWizard;

StepWizard.propTypes = {
    steps: PropTypes.array,
    closeWizard: PropTypes.func,
    // history: PropTypes.object,
    isInsideTheVisualizer: PropTypes.bool
};
