import React, { useContext, useState, useEffect } from "react";
import PropTypes from "prop-types";

export const SetDefaultPage = ({
    updateDefaultPageStatus,
    isCurrentPageDefaultPage
}) => {
    const [ isDefaultPage, setDefaultPage ] = useState(isCurrentPageDefaultPage);
    const checkDefaultPageStatus = () => {
        if (!isDefaultPage) {
            updateDefaultPageStatus();
            setDefaultPage(!isDefaultPage);
        }
    };
    return (
        <div className="checkbox checkbox-success checkbox-md custom-checkBox">
            <input
                type="checkbox"
                className="styled"
                id="setDefaultPage"
                checked={isDefaultPage}
                onChange={checkDefaultPageStatus}
            />
            <label for="setDefaultPage">Set as Default Page </label>
        </div>
    );
};
SetDefaultPage.propTypes = {
    updateDefaultPageStatus: PropTypes.func,
    isCurrentPageDefaultPage: PropTypes.bool
};