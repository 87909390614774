import { showElement } from "./helper";

const secondsDelay = (editor, component) => {
    if (component.changed.secondsDelay || component.changed.secondsDelay === "") {
        var secondsDelay = Number(component.changed.secondsDelay);
        if (component.changed.secondsDelay) {
            editor.getSelected().addAttributes(
                { "data-secondsDelay": secondsDelay }
            );
        } else {
            editor.getSelected().addAttributes(
                { "data-secondsDelay": "" }
            );
        }
        showElement(editor, "stickyBarCustom","stickybar",component);
    }
};

export default secondsDelay;