import { FunnelTemplate, FunnelTemplatesReducer } from "../models";
import * as actions from "./actions";
export const FUNNEL_TEMPLATES_REDUCER_KEY = "FUNNEL_TEMPLATES";
export const FUNNEL_TEMPLATE_REDUCER_KEY = "FUNNEL_TEMPLATE";

const initialState = new FunnelTemplatesReducer();


export function reducer(state = initialState, action) {
    switch (action.type) {
        case actions.LOAD_FUNNEL_TEMPLATES_SUCCESS: {
            const funnels = action.funnels.map((f) => {
                return new FunnelTemplate({ ...f });
            });
            return {
                ...state,
                funnels: [...funnels], isError: false
            };
        }
        case actions.LOAD_ACTIVE_FUNNEL_TEMPLATES_SUCCESS: {
            const activeFunnels = action.activeFunnels.map((f) => {
                return new FunnelTemplate({ ...f });
            });
            return {
                ...state,
                activeFunnels: [...activeFunnels],
                isError: false
            };
        }
        case actions.CREATE_FUNNEL_TEMPLATE_SUCCESS:
            return {
                ...state,
                funnels: [...state.funnels, action.funnel]
            };
        case actions.CREATE_FUNNEL_TEMPLATE_ERROR:
            return {
                ...state,
                isError: action.isError
            };
        case actions.EDIT_FUNNEL_TEMPLATE_SUCCESS:
            return {
                ...state,
                funnels: [...state.funnels, action.funnel]
            };
        case actions.EDIT_FUNNEL_TEMPLATE_ERROR:
            return {
                ...state,
                isError: action.isError
            };
        case actions.UPDATE_FUNNEL_TEMPLATE_SUCCESS: {
            const updateFunnel = state.map((funnel) => {
                if (funnel.id === action.funnel.id) {
                    return action.funnel;
                }
                return funnel;
            });
            return {
                ...state,
                funnels: [...updateFunnel],
            };
        }
        case actions.LOAD_PAGE_SUCCESS: {
            return {
                ...state,
                htmlPage: action.htmlPage
            };
        }
        case actions.IS_LOADING: {
            return {
                ...state,
                isLoading: action.status
            };
        }

        case actions.UPDATE_FUNNEL_TEMPLATE_ERROR: {
            return {
                ...state,
                isError: action.isError
            };
        }
        default:
            return state;
    }
}
