import delay from "./delay";

// This file mocks a web API by working with the hard-coded data below.
// It uses setTimeout to simulate the delay of an AJAX call.
// All calls return promises.
const advertisements = [
    {
        id: 1,
        name: "",
        dateCreated: "2018-04-25",
        dateUpdated: "2018-07-07",
        description: "",
        img: "https://resources.konnektive.com/images/carousel/aventus-banner.png",
        link: "http://aventus.com/konnektive.html",
    },
    {
        id: 2,
        name: "",
        dateCreated: "2018-04-25",
        dateUpdated: "2018-07-07",
        description: "",
        img: "https://resources.konnektive.com/images/carousel/midigatorROI.png",
        link: "https://midigator.com/roi/#/",
    }
];

function replaceAll(str, find, replace) {
    return str.replace(new RegExp(find, "g"), replace);
}

//This would be performed on the server in a real app. Just stubbing in.
const generateId = (advertisement) => {
    return replaceAll(advertisement.name, " ", "-");
};

class api {
    static getAlladvertisements() {
        return new Promise((resolve) => {
            setTimeout(() => {
                resolve(Object.assign([], advertisements));
            }, delay);
        });
    }

    static saveadvertisement(advertisement) {
        advertisement = Object.assign({}, advertisement); // to avoid manipulating object passed in.
        return new Promise((resolve, reject) => {
            setTimeout(() => {
                // Simulate server-side validation
                const minadvertisementNameLength = 1;
                if (advertisement.name.length < minadvertisementNameLength) {
                    reject(`Name must be at least ${minadvertisementNameLength} characters.`);
                }

                if (advertisement.id) {
                    //eslint-disable-next-line
                    const existingadvertisementIndex = advertisement.findIndex(a => a.id == advertisement.id);
                    advertisements.splice(existingadvertisementIndex, 1, advertisement);
                } else {
                    //Just simulating creation here.
                    //The server would generate ids and watchHref"s for new funnel in a real app.
                    //Cloning so copy returned is passed by value rather than by reference.
                    advertisement.id = generateId(advertisement);
                    //funnel.watchHref = `http://www.pluralsight.com/courses/${course.id}`;
                    advertisements.push(advertisement);
                }

                resolve(advertisement);
            }, delay);
        });
    }

    static deleteadvertisement(advertisementId) {
        return new Promise((resolve) => {
            setTimeout(() => {
                const indexOfadvertisementToDelete = advertisements.findIndex(advertisement => {
                    //eslint-disable-next-line
                    advertisement.id == advertisementId;
                });
                advertisements.splice(indexOfadvertisementToDelete, 1);
                resolve();
            }, delay);
        });
    }
}

export default api;