import React, { useState } from "react";
import isString from "lodash/isString";
import { EventEmitter } from "fbemitter";

export const NotificationEmitter = new EventEmitter();
export const NOTIFICATION_EVENT_NAME = "NOTIFICATION::EVENT";

const NOTIFICATION_TIMEOUT_INTERVAL = 3000;

const NotificationContainer = () => {
    const [type, setType] = useState(null);
    const [message, setMessage] = useState([]);
    const [closeButton, setCloseButton] = useState(false);
    const reset = () => {
        setType(null);
        setMessage(null);
        setCloseButton(false);
    };
    const clearMessageAfterTimeout = () => {
        setTimeout(() => {
            reset();
        }, NOTIFICATION_TIMEOUT_INTERVAL);
    };
    NotificationEmitter.addListener("NOTIFICATION::EVENT", ({ type = "success", message, closeButton = false, }) => {
        if (message) {
            setMessage(message);
            setType(type);
            setCloseButton(closeButton);
            if (!closeButton) {
                clearMessageAfterTimeout();
            }
        }
    });
    if (isString(message)) {
        const notificationClass = (type === "success") ? "notification-success" : "notification-error";
        return (
            <div className="notifications-container">
                <div className={`notifications ${notificationClass} active`}>
                    <i className="fa fa-exclamation-circle m-r-sm fa-2x check-icon" />
                    <h4>{message}</h4>
                    {
                        closeButton && (
                            <button onClick={() => reset()} className="btn btn-outline notification-close-button"><i className="fa fa-close fa-lg m-l-lg" /></button>
                        )
                    }
                </div>
            </div>
        );
    }
    return null;
};

export const triggerNofiticationSuccess = (options) => {
    NotificationEmitter.emit(NOTIFICATION_EVENT_NAME, { type: "success", ...options });
};

export const triggerNofiticationError = (options) => {
    NotificationEmitter.emit(NOTIFICATION_EVENT_NAME, { type: "error", ...options });
};


export default NotificationContainer;