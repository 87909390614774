const handleAnimationDurationChange = (editor, component) => {
    var ele = editor.DomComponents.getWrapper();
    if (ele.view.$el.find("#customizable_popup").length > 0) {
        var sm = editor.SelectorManager;
        var bacImage = sm.add("animationDuration");
        var rule = editor.CssComposer.add([bacImage]);
        rule.set("style", {
            "animation-duration": component.changed.animationDuration + "s !important"
        });
        editor.getSelected().addClass("animationDuration");
    }
};

export default handleAnimationDurationChange;