import React from "react";
import { smoothlyMenu } from "./helpers";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { Navigation } from "./navigation";
import Select from "react-select";

export class TopHeader extends React.Component {
    static propTypes = {
        history: PropTypes.object,
        isLoading: PropTypes.object,
        isAuthentcated: PropTypes.object,
        isLoginError: PropTypes.object,
        logout: PropTypes.func,
        userData: PropTypes.object,
        settingsData: PropTypes.object,
        companyData: PropTypes.object,
        defaultCompany: PropTypes.object,
        setCompany: PropTypes.func,
        location: PropTypes.object,
        companyError: PropTypes.string,
        isAdminUser: PropTypes.bool,
        setWelcomeModalOnMenuClick: PropTypes.func,
        isWelcomeVideoUrlFetchSuccess: PropTypes.bool,
        canUserAccessTemplates: PropTypes.bool
    }
    constructor(props) {
        super(props);
        this.toggleNavigation = this.toggleNavigation.bind(this);
        this.state = {
            selectedCompany: {},
            defaultSelectedCompany: []
        };
    }

    componentWillReceiveProps(nextProps) {
        const { defaultCompany } = nextProps;
        if (this.props.defaultCompany !== defaultCompany) {
            this.setState({ defaultSelectedCompany: defaultCompany });
        }
    }
    toggleNavigation = (e) => {
        e.preventDefault();
        // TODO- Remove $
        // eslint-disable-next-line
        $("body").toggleClass("mini-navbar");
        smoothlyMenu();
    }
    getSelectedCompany = (selectedCompany) => {
        this.setState({ selectedCompany: selectedCompany });
    }
    changeCompany = (updatedCompany) => {
        this.props.setCompany(updatedCompany);
    }
    render() {
        const {
            isAdminUser,
            canUserAccessTemplates
        } = this.props;
        const options = [];
        const isCompanyError = this.props.companyError && this.props.companyError;
        const { defaultSelectedCompany } = this.state;
        const locationToShow = ["/dashboard", "/funnels", "/designers", "/settings"];
        const showCompanyPopup = location && locationToShow && locationToShow.filter((loc) => loc === location.pathname);
        //to work on this -don't remove
        /*const companyData = this.props.companyData &&
            this.props.companyData.map(company => {
                return ({ "value": company.referenceId, "label": company.name,"isSelected": company.selected,"isDefault": company.userDefault });
            });*/

        const companyOptions = this.props.companyData &&
        this.props.companyData.map((company) => {
            const option = { "value": company.referenceId,
                "label": company.name,
                "isSelected": company.selected,
                "isDefault": company.userDefault
            };
            options.push(option);
        });
        const companyStyle = !isCompanyError && defaultSelectedCompany.length ? { paddingTop: 10, paddingLeft: 35, width: 400 }
            : { paddingTop: 10, paddingLeft: 35, width: 400, display: "none" };
        const buttonStyle = !isCompanyError && defaultSelectedCompany.length ? { paddingTop: 12, paddingLeft: 15 }
            : { paddingTop: 12, paddingLeft: 15, display: "none" };
        return (
            <div className="row border-bottom">
                <nav className="navbar display-flex" role="navigation" style={{ marginBottom: 0 }}>
                    {/*<div className="navbar-header">*/}
                    {/*<a className="navbar-minimalize minimalize-styl-2 btn btn-primary " onClick={this.toggleNavigation} href="#"><i className="fa fa-bars"/></a>*/}
                    {/*</div>*/}
                    <div className="nav navbar-brand">
                        <Link to="/">
                            <img className="img-responsive" style={{ height: 50 }} src={require("../img/funnelkonnekt-6_preview (1).png")} alt=""/>
                        </Link>
                    </div>
                    <div className="margin-auto">
                        {!isCompanyError && defaultSelectedCompany.length && showCompanyPopup.length ? <div>
                            <div className="input-group pull-left col-xs-4" style={companyStyle}>
                                <div className="input-group-addon">Company</div>
                                <Select
                                    defaultValue= {defaultSelectedCompany[0]}
                                    isSearchable={true}
                                    name="company"
                                    options={options}
                                    onChange= {this.getSelectedCompany}
                                />
                            </div>
                            <div className="col-xs-2" style={buttonStyle}>
                                <button className="btn btn-primary" onClick={() => this.changeCompany(this.state.selectedCompany)} >Login</button>
                            </div>
                        </div> : null}
                    </div>
                    <ul className="nav navbar-top-links navbar-right">
                        <Navigation
                            userData={this.props.userData}
                            isAdminUser={isAdminUser}
                            logout={this.props.logout}
                            settingsData={this.props.settingsData}
                            location={location}
                            isWelcomeVideoUrlFetchSuccess={this.props.isWelcomeVideoUrlFetchSuccess}
                            setWelcomeModalOnMenuClick={this.props.setWelcomeModalOnMenuClick}
                            canUserAccessTemplates = {canUserAccessTemplates}
                        />
                    </ul>
                </nav>
            </div>
        );
    }
}