
import React, { Component } from "react";
import PropTypes from "prop-types";
import "../../../../../node_modules/jointjs/dist/joint.css";

export class VisualizerZoom extends Component {
    static propTypes = {
        editFunnel: PropTypes.func,
        visualizerData: PropTypes.object,
        updateVisualizerData: PropTypes.func,
        removeCallBackEvent: PropTypes.func,
        funnel: PropTypes.object,
    }
    constructor() {
        super();
    }
    componentDidUpdate() {
        const { callbackEvent } = this.props.visualizerData;
        switch (callbackEvent) {
            case "zoomCallBack":
                this.zoomCallBack();
                break;
        }

    }
    zoomIn = () => {
        const { graphScale, innerLeft, innerHeight, innerZoom, outerZoom } = this.props.visualizerData;
        let outerLeft = document.getElementById("visualizer").offsetWidth;
        let innerLeftValue = document.getElementById("page-scroller").offsetWidth;
        let outerHeight = document.getElementById("visualizer").offsetHeight;
        let innerHeightValue = document.getElementById("page-scroller").offsetHeight;
        let viewport = document.getElementsByClassName("joint-viewport")[1] && document.getElementsByClassName("joint-viewport")[1].getBBox();
        const scrollLeftValue = (innerLeft - outerLeft) / 2;
        const scrollHeightValue = (innerHeight - outerHeight) / 2;
        this.props.updateVisualizerData({
            ...this.props.visualizerData,
            graphScale: graphScale < 1.5 ? graphScale + 0.1 : graphScale,
            innerZoom: graphScale < 1.5 ? innerZoom + 2.7 : innerZoom,
            outerZoom: graphScale < 1.5 ? outerZoom + 2.7 : outerZoom,
            scrollLeftValue: scrollLeftValue,
            viewportHeight: viewport.height,
            viewportWidth: viewport.width,
            scrollHeightValue: scrollHeightValue,
            innerHeight: innerHeightValue,
            innerLeft: innerLeftValue,
            isZoom: true,
            callbackEvent: "zoomCallBack",
        });

    };

    zoomOut = () => {
        const { graphScale, innerLeft, innerHeight, innerZoom, outerZoom } = this.props.visualizerData;
        var outerLeft = document.getElementById("visualizer").offsetWidth;
        var innerLeftValue = document.getElementById("page-scroller").offsetWidth;
        var outerHeight = document.getElementById("visualizer").offsetHeight;
        var innerHeightValue = document.getElementById("page-scroller").offsetHeight;
        var viewport = document.getElementsByClassName("joint-viewport")[1] && document.getElementsByClassName("joint-viewport")[1].getBBox();
        const scrollLeftValue = (innerLeft - outerLeft) / 2;
        const scrollHeightValue = (innerHeight - outerHeight) / 2;
        this.props.updateVisualizerData({
            ...this.props.visualizerData,
            graphScale: graphScale <= 0.30 ? graphScale : graphScale - 0.1,
            innerZoom: graphScale <= 0.30 ? innerZoom : innerZoom - 2.7,
            outerZoom: graphScale <= 0.30 ? outerZoom : outerZoom - 2.7,
            scrollLeftValue: scrollLeftValue,
            scrollHeightValue: scrollHeightValue,
            viewportHeight: viewport.height,
            viewportWidth: viewport.width,
            innerHeight: innerHeightValue,
            innerLeft: innerLeftValue,
            isZoom: true,
            callbackEvent: "zoomCallBack",
        });
    };
    zoomCallBack = () => {
        const { graphScale, outerZoom, innerZoom } = this.props.visualizerData;
        const { funnel } = this.props;
        document.getElementsByClassName("joint-viewport")[1].style.transform = `matrix(${graphScale},0,0,${graphScale},0,1)`;
        this.hideZoomValue();
        this.props.editFunnel({ funnel: funnel, zoomValue: graphScale, layoutZoom: outerZoom, graphZoom: innerZoom });
        this.props.removeCallBackEvent();

    }

    hideZoomValue = () => {
        setTimeout(() => {
            this.props.updateVisualizerData({
                ...this.props.visualizerData,
                isZoom: false
            });
        }, 1200);
    }

    render() {
        const { graphScale } = this.props.visualizerData;
        let zoom = (graphScale * 100).toFixed(0) + " %";
        const zoomBtn = graphScale < 0 ? "input-group-addon disable" : "input-group-addon pointer";
        return (
            <div className="input-group zoomBox m-l-xs">
                <div className={zoomBtn} onClick={this.zoomOut} title="Zoom out"><i className="fa fa-search-minus" aria-hidden="true"></i></div>
                <input className="form-control zoom-level" type="text" disabled value={zoom} readOnly title="Zoom" />
                <div className={zoomBtn} onClick={this.zoomIn} title="Zoom in"><i className="fa fa-search-plus" aria-hidden="true"></i></div>
            </div>
        );
    }
}