import { FunnelDescription as FunnelDescriptionView } from "./funnel-description";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import * as funnelTypesActions from "../actions";
//import { getFunnels } from "./selectors";

function mapStateToProps(state) {
    return {
        templates: state.TEMPLATES.templates,
    };
}
function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators(funnelTypesActions, dispatch),
        //deleteFunnel: (funnelId) => dispatch(funnelActions.deleteAction(funnelId))
    };
}
export const FunnelDescription = connect(mapStateToProps, mapDispatchToProps)(FunnelDescriptionView);