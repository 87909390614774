import React from "react";
import PropTypes from "prop-types";

export const PublishFunnel = ({ onClick }) => (
    <button
        onClick={() => onClick()}
        className= "btn btn-primary m-l-xs pull-right">
        <i
            className="fa fa-cloud-upload"
            aria-hidden="true">
        </i> Publish
    </button>
);
PublishFunnel.propTypes = {
    onClick: PropTypes.func
};

export default PublishFunnel;