import delay from "./delay";

// This file mocks a web API by working with the hard-coded data below.
// It uses setTimeout to simulate the delay of an AJAX call.
// All calls return promises.
const marketplaceTemplates = [
    {
        id: 1,
        title: "Garcinia Trial Offer",
        price: 45,
        dateCreated: "2017-01-25",
        dateUpdated: "2017-03-17",
        image: "https://cdn.colorlib.com/wp/wp-content/uploads/sites/2/alotan-free-template.jpg",
        live: true,
        author: "Amanda Thompson",
        category: "Ecommerce",
        description: "This theme is catered to trial offers that result in recurred billings"
    },
    {
        id: 2,
        title: "Super T Booster - Trial",
        price: 55,
        dateCreated: "2018-01-25",
        dateUpdated: "2018-04-10",
        image: "https://cdn.colorlib.com/wp/wp-content/uploads/sites/2/charity2-free-template.jpg",
        live: true,
        author: "Salim Ali",
        category: "Single Product",
        description: "This theme is catered to trial offer that result in recurred billings Customers love a page that has style to it. This theme shows that you have a big organization even if you are running a two man shop"
    },
    {
        id: 3,
        title: "Super T Booster - Straight Sale",
        price: 25,
        dateCreated: "2017-01-25",
        dateUpdated: "2017-03-17",
        image: "https://cdn.colorlib.com/wp/wp-content/uploads/sites/2/advent-free-template.jpg",
        live: true,
        author: "Geoff Clapp",
        category: "Ecommerce",
        description: "This template is catered to a sales that occur one time. Customers love a page that has style to it. This theme shows that you have a big organization even if you are running a two man shop"
    },
    {
        id: 4,
        title: "Fancy Rugs Campaign",
        price: 30,
        dateCreated: "2017-01-25",
        dateUpdated: "2017-03-17",
        image: "https://cdn.colorlib.com/wp/wp-content/uploads/sites/2/transcend-free-template-1.jpg",
        live: false,
        author: "Alice Xu",
        category: "Single Product",
        description: "This template is catered to customer that need to sell one product but wants to offer an upsale after purchase. Customers love a page that has style to it. This theme shows that you have a big organization even if you are running a two man shop"
    },
    {
        id: 5,
        title: "Skin Care",
        price: 25,
        dateCreated: "2017-01-25",
        dateUpdated: "2017-03-17",
        image: "https://cdn.colorlib.com/wp/wp-content/uploads/sites/2/skwela-free-template.jpg",
        live: true,
        author: "Aaron Turgeman",
        category: "MultiProduct",
        description: "This template is catered to customer that need to sell one product but wants to offer an upsale after purchase. Customers love a page that has style to it. This theme shows that you have a big organization even if you are running a two man shop"
    },
    {
        id: 6,
        title: "Skin Care",
        price: 35,
        dateCreated: "2017-01-25",
        dateUpdated: "2017-03-17",
        image: "https://cdn.colorlib.com/wp/wp-content/uploads/sites/2/charity2-free-template.jpg",
        live: false,
        author: "Dario Sumlin",
        category: "MultiProduct",
        description: "\"This template is catered to customer that need to sell one product but wants to offer an upsale after purchase. Customers love a page that has style to it. This theme shows that you have a big organization even if you are running a two man shop\""
    },
    {
        id: 7,
        title: "Garcinia Trial Offer",
        price: 45,
        dateCreated: "2017-01-25",
        dateUpdated: "2017-03-17",
        image: "https://cdn.colorlib.com/wp/wp-content/uploads/sites/2/advent-free-template.jpg",
        live: true,
        author: "Amanda Thompson",
        category: "Ecommerce",
        description: "This template is catered to customer that need to sell one product but wants to offer an upsale after purchase. Customers love a page that has style to it. This theme shows that you have a big organization even if you are running a two man shop"
    },
    {
        id: 8,
        title: "Super T Booster - Trial",
        price: 55,
        dateCreated: "2018-01-25",
        dateUpdated: "2018-04-10",
        image: "https://cdn.colorlib.com/wp/wp-content/uploads/sites/2/travelix-free-template.jpg",
        live: true,
        author: "Salim Ali",
        category: "Single Product",
        description: "This template is catered to customer that need to sell one product but wants to offer an upsale after purchase. Customers love a page that has style to it. This theme shows that you have a big organization even if you are running a two man shop"
    },
    {
        id: 9,
        title: "Super T Booster - Straight Sale",
        price: 25,
        dateCreated: "2017-01-25",
        dateUpdated: "2017-03-17",
        image: "https://cdn.colorlib.com/wp/wp-content/uploads/sites/2/luxehotel-free-template.jpg",
        live: true,
        author: "Geoff Clapp",
        category: "Ecommerce",
        description: "This template is catered to customer that need to sell one product but wants to offer an upsale after purchase. Customers love a page that has style to it. This theme shows that you have a big organization even if you are running a two man shop"
    },
    {
        id: 10,
        title: "Fancy Rugs Campaign",
        price: 30,
        dateCreated: "2017-01-25",
        dateUpdated: "2017-03-17",
        image: "https://cdn.colorlib.com/wp/wp-content/uploads/sites/2/charity2-free-template.jpg",
        live: false,
        author: "Alice Xu",
        category: "Single Product",
        description: "This template is catered to customer that need to sell one product but wants to offer an upsale after purchase. Customers love a page that has style to it. This theme shows that you have a big organization even if you are running a two man shop"
    },
    {
        id: 11,
        title: "Skin Care",
        price: 25,
        dateCreated: "2017-01-25",
        dateUpdated: "2017-03-17",
        image: "https://cdn.colorlib.com/wp/wp-content/uploads/sites/2/alotan-free-template.jpg",
        live: true,
        author: "Aaron Turgeman",
        category: "MultiProduct",
        description: "This template is catered to customer that need to sell one product but wants to offer an upsale after purchase. Customers love a page that has style to it. This theme shows that you have a big organization even if you are running a two man shop"
    },
    {
        id: 12,
        title: "Skin Care",
        price: 35,
        dateCreated: "2017-01-25",
        dateUpdated: "2017-03-17",
        image: "https://cdn.colorlib.com/wp/wp-content/uploads/sites/2/advent-free-template.jpg",
        live: false,
        author: "Dario Sumlin",
        category: "MultiProduct",
        description: "This template is catered to customer that need to sell one product but wants to offer an upsale after purchase. Customers love a page that has style to it. This theme shows that you have a big organization even if you are running a two man shop"
    },
    {
        id: 13,
        title: "Garcinia Trial Offer",
        price: 45,
        dateCreated: "2017-01-25",
        dateUpdated: "2017-03-17",
        image: "https://cdn.colorlib.com/wp/wp-content/uploads/sites/2/travelix-free-template.jpg",
        live: true,
        author: "Amanda Thompson",
        category: "Ecommerce",
        description: "This template is catered to customer that need to sell one product but wants to offer an upsale after purchase. Customers love a page that has style to it. This theme shows that you have a big organization even if you are running a two man shop"
    },
    {
        id: 14,
        title: "Super T Booster - Trial",
        price: 55,
        dateCreated: "2018-01-25",
        dateUpdated: "2018-04-10",
        image: "https://cdn.colorlib.com/wp/wp-content/uploads/sites/2/skwela-free-template.jpg",
        live: true,
        author: "Salim Ali",
        category: "Single Product",
        description: "This template is catered to customer that need to sell one product but wants to offer an upsale after purchase. Customers love a page that has style to it. This theme shows that you have a big organization even if you are running a two man shop"
    },
    {
        id: 15,
        title: "Super T Booster - Straight Sale",
        price: 25,
        dateCreated: "2017-01-25",
        dateUpdated: "2017-03-17",
        image: "https://cdn.colorlib.com/wp/wp-content/uploads/sites/2/horseclub-free-template.jpg",
        live: true,
        author: "Geoff Clapp",
        category: "Ecommerce",
        description: "This template is catered to customer that need to sell one product but wants to offer an upsale after purchase. Customers love a page that has style to it. This theme shows that you have a big organization even if you are running a two man shop"
    },
    {
        id: 16,
        title: "Fancy Rugs Campaign",
        price: 30,
        dateCreated: "2017-01-25",
        dateUpdated: "2017-03-17",
        image: "https://cdn.colorlib.com/wp/wp-content/uploads/sites/2/advent-free-template.jpg",
        live: false,
        author: "Alice Xu",
        category: "Single Product",
        description: "This template is catered to customer that need to sell one product but wants to offer an upsale after purchase. Customers love a page that has style to it. This theme shows that you have a big organization even if you are running a two man shop"
    },
    {
        id: 17,
        title: "Skin Care",
        price: 25,
        dateCreated: "2017-01-25",
        dateUpdated: "2017-03-17",
        image: "https://cdn.colorlib.com/wp/wp-content/uploads/sites/2/alotan-free-template.jpg",
        live: true,
        author: "Aaron Turgeman",
        category: "MultiProduct",
        description: "This template is catered to customer that need to sell one product but wants to offer an upsale after purchase. Customers love a page that has style to it. This theme shows that you have a big organization even if you are running a two man shop"
    },
    {
        id: 18,
        title: "Skin Care",
        price: 35,
        dateCreated: "2017-01-25",
        dateUpdated: "2017-03-17",
        image: "https://cdn.colorlib.com/wp/wp-content/uploads/sites/2/skwela-free-template.jpg",
        live: false,
        author: "Dario Sumlin",
        category: "MultiProduct",
        description: "This template is catered to customer that need to sell one product but wants to offer an upsale after purchase. Customers love a page that has style to it. This theme shows that you have a big organization even if you are running a two man shop"
    }

];

function replaceAll(str, find, replace) {
    return str.replace(new RegExp(find, "g"), replace);
}

//This would be performed on the server in a real app. Just stubbing in.
const generateId = (marketplaceTemplate) => {
    return replaceAll(marketplaceTemplate.title, " ", "-");
};

class MarketplaceTemplateApi {
    static getAllMarketplaceTemplates() {
        return new Promise((resolve) => {
            setTimeout(() => {
                resolve(Object.assign([], marketplaceTemplates));
            }, delay);
        });
    }

    static saveMarketplaceTemplate(marketplaceTemplate) {
        marketplaceTemplate = Object.assign({}, marketplaceTemplate); // to avoid manipulating object passed in.
        return new Promise((resolve, reject) => {
            setTimeout(() => {
                // Simulate server-side validation
                const minMarketplaceTemplateTitleLength = 1;
                if (marketplaceTemplate.title.length < minMarketplaceTemplateTitleLength) {
                    reject(`Title must be at least ${minMarketplaceTemplateTitleLength} characters.`);
                }

                if (marketplaceTemplate.id) {
                    // eslint-disable-next-line
                    const existingMarketplaceTemplateIndex = marketplaceTemplates.findIndex(a => a.id == marketplaceTemplate.id);
                    marketplaceTemplates.splice(existingMarketplaceTemplateIndex, 1, marketplaceTemplate);
                } else {
                    //Just simulating creation here.
                    //The server would generate ids and watchHref"s for new funnel in a real app.
                    //Cloning so copy returned is passed by value rather than by reference.
                    marketplaceTemplate.id = generateId(marketplaceTemplate);
                    //funnel.watchHref = `http://www.pluralsight.com/courses/${course.id}`;
                    marketplaceTemplates.push(marketplaceTemplate);
                }

                resolve(marketplaceTemplate);
            }, delay);
        });
    }

    static deleteMarketplaceTemplate(marketplaceTemplateId) {
        return new Promise((resolve) => {
            setTimeout(() => {
                const indexOfMarketplaceTemplateToDelete = marketplaceTemplates.findIndex(marketplaceTemplate => {
                    // eslint-disable-next-line
                    marketplaceTemplate.id == marketplaceTemplateId;
                });
                marketplaceTemplates.splice(indexOfMarketplaceTemplateToDelete, 1);
                resolve();
            }, delay);
        });
    }
}

export default MarketplaceTemplateApi;