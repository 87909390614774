import React, { Component } from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router";
import joint from "jointjs";
import isEmpty from "lodash/isEmpty";
import "../../../../node_modules/jointjs/dist/joint.css";
import $ from "jquery";
import Elements from "../elements";
import constants from "../../utilities/constants";
import PopUpModal from "../../../pop-up-modal";
import VisualizerRightPanel from "../visualizer-right-panel";
import * as callbackEvents from "../../utilities/callback-constants";
import ImportFunnelTemplateModal from "../import-funnel-template/";
import TextElement from "../textelement";

class VisualizerFrameComponent extends Component {
    static propTypes = {
        clearPageData: PropTypes.func,
        loadButtons: PropTypes.func,
        pageData: PropTypes.object,
        deletePage: PropTypes.func,
        savefunnelGraph: PropTypes.func,
        clonePage: PropTypes.func,
        createNewPage: PropTypes.func,
        getSavedfonts: PropTypes.func,
        template: PropTypes.array,
        funnel: PropTypes.object,
        updatedPage: PropTypes.object,
        selectedTemplate: PropTypes.string,
        thumbnailUrl: PropTypes.string,
        saveButtonData: PropTypes.func,
        metaJson: PropTypes.string,
        pageError: PropTypes.bool,
        templates: PropTypes.array,
        visualizerData: PropTypes.object,
        updateVisualizerData: PropTypes.func,
        importPage: PropTypes.func,
        isImportFunnelLoading: PropTypes.bool,
        removeCallBackEvent: PropTypes.func,
        setCellData: PropTypes.func,
        setSelectedPageTemplate: PropTypes.func,
        closeRightPanel: PropTypes.func,
        parent: PropTypes.string,
        history: PropTypes.object,
    }
    constructor(props, context) {
        super(props, context);
        this.stencil = new joint.dia.Graph();
        this.Toolbar = new joint.dia.Graph();
        this.StencilPaper;
        this.deletedPageLinks = [];
        this.state = {
            leadPage: [constants.leadPageSvg].join(""),
            presellPage: [constants.presellPageSvg].join(""),
            checkoutPage: [constants.checkoutPageSvg].join(""),
            upsellPage: [constants.upsellPageSvg].join(""),
            thankyouPage: [constants.thankyouPageSvg].join(""),
            blankPage: [constants.blankPageSvg].join(""),
            optInPage: [constants.optInPageSvg].join(""),
            downloadPage: [constants.downloadPageSvg].join(""),
            salesVideoPage: [constants.salesVideoPageSvg].join(""),
            calendarPage: [constants.calendarPageSvg].join(""),
            surveyPage: [constants.surveyPageSvg].join(""),
            webinarPage: [constants.webinarPageSvg].join(""),
            webinarReplayPage: [constants.webinarReplayPageSvg].join(""),
            blogPostPage: [constants.blogPostPageSvg].join(""),
            membersAreaPage: [constants.membersAreaPageSvg].join(""),
            popUpPage: [constants.popUpPageSvg].join(""),
            emailPage: [constants.emailPageSvg].join(""),
            textPage: [constants.textPageSvg].join(""),
            genericPage: [constants.membersAreaPageSvg].join(""),
            membershipFolderPage: [constants.memberFolderSvg].join(""),
        };
    }
    componentWillMount() {
        const { funnel } = this.props;
        const zoomLimit = funnel.visualizerJson && JSON.parse(funnel.visualizerJson);
        const zoomValue = parseFloat(zoomLimit ? zoomLimit.zoomValue : 1);
        const graphValue = parseFloat(zoomLimit ? zoomLimit.graphZoom : constants.innerZoom);
        const layoutValue = parseFloat(zoomLimit ? zoomLimit.layoutZoom : constants.outerZoom);
        this.props.updateVisualizerData({
            innerHeight: (window.innerHeight - 155.49),
            innerLeft: (window.innerWidth - 130),
            scrollerHeight: (((window.innerHeight - 155.49) * 100) / (window.innerHeight - 155.49)),
            scrollerWidth: (((window.innerWidth - 130) * 100) / (window.innerWidth - 130)),
            visualizerWidth: (((window.innerWidth - 130) * 100) / (window.innerWidth - 130)),
            visualizerHeight: (((window.innerHeight - 155.49) * 100) / (window.innerHeight - 155.49)),
            graphScale: zoomValue ? zoomValue : 1,
            innerZoom: graphValue ? graphValue : constants.innerZoom,
            outerZoom: layoutValue ? layoutValue : constants.outerZoom
        });
    }
    componentDidMount() {
        const { addedElementArray } = this.props.visualizerData;
        this.visualizerInitialize();
        if (this.props.metaJson) {
            this.getGraph(addedElementArray);
        }
        document.getElementsByClassName("joint-viewport")[1].style.transform = `matrix(${this.props.visualizerData.graphScale},0,0,${this.props.visualizerData.graphScale},0,1)`;
    }
    checkForCallback = (visualizerData) => {
        const { callbackEvent } = visualizerData;

        switch (callbackEvent) {
            case callbackEvents.closeRightPanel: {
                // this.props.clearPageData();
                this.props.updateVisualizerData({ callbackEvent: "" });
                break;
            }
            case callbackEvents.addTextblock: {
                this.addTextblockCallBack(visualizerData);
                break;
            }
            case callbackEvents.removeCell: {
                this.removeCellCallback(visualizerData);
                break;
            }
            case callbackEvents.modifyCell: {
                this.modifyCellCallback();
                break;
            }
            case callbackEvents.openRightPanel: {
                this.openRightPanel();
                break;
            }
            case callbackEvents.loadButtonCallBack: {
                this.loadButtonCallBack(visualizerData);
                break;
            }
            case callbackEvents.handleDelete: {
                this.handleDeleteCallBack(visualizerData);
                break;
            }
            case callbackEvents.importPageCallback: {
                this.importPageCallback(visualizerData);
                break;
            }
            case callbackEvents.newLoadedCallback: {
                this.newLoadedCallback(visualizerData);
                break;
            }
        }
    }

    componentWillReceiveProps(nextProps) {
        const { addedElementArray, cellData, selectedTemplate } = this.props.visualizerData;
        const { metaJson, funnel, pageError, updatedPage, templates } = nextProps;
        if (nextProps.visualizerData.callbackEvent !== "") {
            this.checkForCallback(nextProps.visualizerData);
        }
        if (selectedTemplate !== nextProps.visualizerData.selectedTemplate) {
            if (nextProps.visualizerData.selectedTemplate) {
                const thumbnailUrl = nextProps.visualizerData.selectedTemplate.thumbnailUrl;
                const cellId = cellData.id;
                this.updateTemplateThumbnail(cellId, thumbnailUrl);
            }
        } else {
            this.updateThumbnail(funnel, selectedTemplate);
        }
        if (updatedPage && !selectedTemplate) {
            const updatedTemplate = templates.find(item => {
                return item.templateId === updatedPage.pageView[0].fTemplateId;
            });
            this.props.updateVisualizerData({
                selectedTemplate: updatedTemplate
            });
        }
        const zoomLimit = funnel.visualizerJson && JSON.parse(funnel.visualizerJson);
        const zoomValue = parseFloat(zoomLimit ? zoomLimit.zoomValue : 1);
        const graphValue = parseFloat(zoomLimit ? zoomLimit.graphZoom : constants.innerZoom);
        const layoutValue = parseFloat(zoomLimit ? zoomLimit.layoutZoom : constants.outerZoom);
        if (metaJson && (metaJson !== this.props.metaJson) && (typeof (metaJson) === "string")) {
            const loadedArray = JSON.parse(metaJson);
            const newLoadedArray = ((Object.keys(metaJson).length > 2) && (Object.keys(loadedArray).length === 1)) ? loadedArray : { cells: [] };
            if ((newLoadedArray !== addedElementArray)) {
                this.setPosition(nextProps.funnel, newLoadedArray);
            }
        }
        if (metaJson && (metaJson !== this.props.metaJson) && (typeof (metaJson) === "string")) {
            const loadedArray = JSON.parse(metaJson);
            const newLoadedArray = ((Object.keys(metaJson).length > 2) && (Object.keys(loadedArray).length === 1)) ? loadedArray : { cells: [] };
            this.props.updateVisualizerData({
                addedElementArray: newLoadedArray,
                graphScale: zoomValue,
                innerHeight: (window.innerHeight - 155.49),
                innerLeft: (window.innerWidth - 130),
                scrollerHeight: (((window.innerHeight - 155.49) * 100) / (window.innerHeight - 155.49)),
                scrollerWidth: (((window.innerWidth - 130) * 100) / (window.innerWidth - 130)),
                visualizerWidth: (((window.innerWidth - 130) * 100) / (window.innerWidth - 130)),
                visualizerHeight: (((window.innerHeight - 155.49) * 100) / (window.innerHeight - 155.49)),
                innerZoom: graphValue,
                outerZoom: layoutValue,
                callbackEvent: "newLoadedCallback"
            });
        }
        if (updatedPage && (updatedPage !== this.props.updatedPage)) {
            const loadedArray = metaJson && JSON.parse(metaJson);
            const newLoadedArray = metaJson && ((Object.keys(metaJson).length > 2) && (Object.keys(loadedArray).length === 1)) ? loadedArray : { cells: [] };
            this.setLinkColor(newLoadedArray);
            this.updateThumbnail(funnel, selectedTemplate);
        }
        this.removehtmlElement();
        if (!this.props.pageError && pageError) {
            this.removeUnwantedCell();
        }
    }
    newLoadedCallback = (visualizerData) => {
        const { graphScale, addedElementArray, selectedTemplate } = visualizerData;
        const { funnel } = this.props;
        document.getElementsByClassName("joint-viewport")[1].style.transform = `matrix(${graphScale},0,0,${graphScale},0,1)`;
        this.getGraph(addedElementArray);
        this.setLinkColor(addedElementArray);
        this.updateThumbnail(funnel, selectedTemplate);
        this.checkIfEmptyVisualizer();
        this.props.updateVisualizerData({ callbackEvent: "" });
    }

    setPosition = (funnel, loadedArray) => {
        loadedArray.cells.forEach((aea) => {
            const newCell = this.stencil.getCell(aea.id);
            const currentPage = funnel.page && funnel.page.find((element) => {
                return element.cellViewId === aea.id;
            });
            newCell && newCell.attr({
                ".label": {
                    text: currentPage && currentPage.title,
                    y: -17.5,
                    x: 0
                },
            });
        });
    }
    setLinkColor = (loadedArray, updatedPage) => {
        loadedArray.cells.forEach((aea) => {
            const newCell = this.stencil.getCell(aea.id);
            if (newCell && newCell.attributes.isButtonAssigned) {
                if (newCell.attributes && newCell.attributes.isLinkAssigned) {
                    newCell.attr(".marker-target", {
                        ...newCell.attributes.attrs[".marker-target"],
                        stroke: "#31d0c6",
                        fill: "#31d0c6"
                    });
                } else {
                    newCell && newCell.attr(".marker-target", {
                        ...newCell.attributes.attrs[".marker-target"],
                        stroke: "green",
                        fill: "green"
                    });
                }
            } else {
                newCell && newCell.attr(".marker-target", {
                    ...newCell.attributes.attrs[".marker-target"],
                    stroke: "red",
                    fill: "red"
                });
            }
        });
    }
    updateTemplateThumbnail = (cellId, thumbnailUrl) => {
        var newCell = this.stencil.getCell(cellId);
        if (newCell && thumbnailUrl) {
            newCell.attr("image/xlink:href", thumbnailUrl);
            newCell.attr({
                ".borderBox": {
                    width: 126,
                    height: 166,
                    x: -3,
                    y: -3,
                    stroke: "#979797",
                    rx: "6"
                }
            });
        }
    }
    updateThumbnail = (updatedFunnelData) => {
        const { addedElementArray } = this.props.visualizerData;
        addedElementArray.cells.forEach((element) => {
            var newCell = this.stencil.getCell(element.id);
            if (newCell && newCell.isElement()) {
                const page = updatedFunnelData.page && updatedFunnelData.page.find((uf) => uf.cellViewId === element.id);
                if ((page && page.pageView[0].fTemplateId)) {
                    const pageView = page && page.pageView[0];
                    if (page.isEdited) {
                        newCell.attr("image/xlink:href", `${page.thumbnailUrl}?` + new Date().getTime());
                        newCell.attr({
                            ".borderBox": {
                                width: 126,
                                height: 166,
                                x: -3,
                                y: -3,
                                stroke: "#979797",
                                rx: "6"
                            }
                        });
                    } else {
                        const template = this.props.templates.find((t) => t.templateId === pageView.fTemplateId);
                        if (page.thumbnailUrl) {
                            newCell.attr("image/xlink:href", page.thumbnailUrl);
                            newCell.attr({
                                ".borderBox": {
                                    width: 126,
                                    height: 166,
                                    x: -3,
                                    y: -3,
                                    stroke: "#979797",
                                    rx: "6"
                                }
                            });
                        } else {
                            if (template) {
                                newCell.attr("image/xlink:href", template.thumbnailUrl);
                                newCell.attr({
                                    ".borderBox": {
                                        width: 126,
                                        height: 166,
                                        x: -3,
                                        y: -3,
                                        stroke: "#979797",
                                        rx: "6"
                                    }
                                });
                            }
                        }
                    }
                }
            }
        });
    }

    visualizerInitialize = () => {
        const addedElement = { cells: [] };
        const { openRightPanel, closeRightPanel, getZoomValue, blankSave, clonePage, changeSource, getPageType, addTextblock, removehtmlElement, editableText,removeText, textChange, startMove, setCellData, innerZoom, setSelectedData, addElement, addCells, createNewPage, saveGraph, linkSettings, showTemplate, showPagePreview,
            deleteLinkCallback, deletePopup, startDottedLine, getSavedfonts, navigateToBuilder, checkIfEmptyVisualizer,isLoginPageAdded } = this;
        const { leadPage, presellPage, checkoutPage, upsellPage, thankyouPage, blankPage, optInPage, downloadPage, salesVideoPage, calendarPage, surveyPage, webinarPage, webinarReplayPage, blogPostPage, membersAreaPage, popUpPage, emailPage, textPage, genericPage,membershipFolderPage } = this.state;
        const { visualizerWidth, visualizerHeight } = this.props.visualizerData;
        var gridSize = 20;
        var width = visualizerWidth * innerZoom;
        var height = visualizerHeight * innerZoom;
        this.StencilPaper = new joint.dia.Paper({
            el: this.refs.visualizer,
            model: this.stencil,
            width: width,
            height: height,
            gridSize: gridSize,
            linkPinning: false,
            background: {
                color: "#fff"
            },
            interactive: function (cellView) {
                if (cellView.model instanceof joint.dia.Link) {
                    // Disable the default vertex add functionality on pointerdown.
                    return { vertexAdd: false };
                }
                return true;
            },
            drawGrid: true,
            defaultLink: new joint.dia.Link({
                attrs: {
                    ".connection": {
                        d: "M 220 234 L 691 189"
                    },
                    ".connection-wrap": {
                        d: "M 220 234 L 691 189"
                    },
                    ".marker-target": {
                        d: "M 10 0 L 0 5 L 10 10 z",
                        stroke: "red",
                        fill: "red"
                    },
                },
            }),
        });
        const ToolbarPaper = new joint.dia.Paper({
            el: this.refs.stencil,
            height: 3800,
            model: this.Toolbar,
            interactive: false,
            linkPinning: false,
            defaultLink: new joint.dia.Link({
                attrs: {
                    ".marker-target": { d: "M 10 0 L 0 5 L 10 10 z" }
                }
            }),
        });
        this.StencilPaper.on("blank:pointerdown", function (evt, x, y) {
            // DottedLine
            startDottedLine(evt, x, y);
            addTextblock(evt, x, y);
            removehtmlElement();

        });
        this.StencilPaper.on("cell:pointerdblclick", function (cellView, evt, x, y) {
            const cell = cellView.model;
            if (cell.attributes.type === "standard.Rectangle") {
                editableText(cell, evt, x, y);
            }
        });
        this.StencilPaper.on("blank:pointermove", function (evt, x, y) {
            startMove(evt, x, y);
        });
        this.StencilPaper.on("blank:pointerup", function (evt, x, y) {
            blankSave();
        });
        this.stencil.on("change:source change:target", function (cellView) {
            if (cellView.isLink() && cellView.hasLoop()) {
                cellView.remove();
            }
        });
        this.stencil.on("change:vertices", function (cellView) {
            if (cellView.isLink()) {
                changeSource(cellView);
            }
        });
        this.StencilPaper.on("link:options", function (cellView, evt) {
            evt.stopPropagation();
            closeRightPanel();
            linkSettings(cellView);
        });
        this.stencil
            .on("add", checkIfEmptyVisualizer)
            .on("remove", checkIfEmptyVisualizer);

        this.stencil.on("remove", function (cellView) {
            if (cellView.isLink()) {
                deleteLinkCallback(cellView);
            }
        });
        this.StencilPaper.on("cell:pointerup", function (cellView, e, x, y) {
            var groupElement = document.querySelectorAll(".joint-cell");
            for (var i = 0; i < groupElement.length; i++) {
                groupElement[i].classList.remove("port-enable");
            }
            saveGraph();
        });
        this.StencilPaper.on("cell:pointerdown", function (cellView, e, x, y) {
            var groupElement = document.querySelectorAll(".joint-cell");
            for (var j = 0; j < groupElement.length; j++) {
                groupElement[j].classList.remove("active");
                groupElement[j].classList.add("port-enable");
            }

            if (!cellView.sourcePoint) {
                cellView.highlight();
            }
            var selectedElement = document.querySelector(`g[model-id="${cellView.model.id}"]`);
            for (var i = 0; i < groupElement.length; i++) {
                if (selectedElement.id === groupElement[i].id) {
                    groupElement[i].classList.add("active");
                    groupElement[i].classList.remove("port-enable");
                } else {
                    groupElement[i].classList.remove("active");
                    groupElement[i].classList.add("port-enable");
                }
            }
            if (cellView.model.attributes.type === "devs.MyImageModel") {
                setCellData(cellView.model);
            }
            closeRightPanel();
        });
        const { StencilPaper } = this;
        this.StencilPaper.on("blank:pointerdown", function (cellView, e, x, y) {
            var groupElement = document.querySelectorAll(".joint-cell");
            for (var i = 0; i < groupElement.length; i++) {
                groupElement[i].classList.remove("active");
                groupElement[i].classList.remove("port-enable");
            }
            var inputBox = document.getElementById("pageName");
            document.addEventListener("click", function (element) {
                if (!("path" in Event.prototype)) {
                    Object.defineProperty(Event.prototype, "path", {
                        get: function () {
                            var path = [];
                            var currentElem = this.target;
                            while (currentElem) {
                                path.push(currentElem);
                                currentElem = currentElem.parentElement;
                            }
                            if (path.indexOf(window) === -1 && path.indexOf(document) === -1)
                                path.push(document);
                            if (path.indexOf(window) === -1)
                                path.push(window);
                            return path;
                        }
                    });
                }
                if (element.path[0] !== inputBox)
                    inputBox && inputBox.blur();
            });
            closeRightPanel();
        });
        this.StencilPaper.on("element:settings", function (cellView, evt) {
            evt.stopPropagation();
            openRightPanel();
            getPageType(cellView.model.attributes.elementMetaData.templateTypeId);
            showTemplate(cellView.model);
            setSelectedData(cellView);
            getSavedfonts(cellView.model);
            linkSettings(cellView);
        });
        this.StencilPaper.on("element:edit", function (cellView, evt) {
            /*
             * [Todo - Dont set if the fonts has been added already]
             */
            getSavedfonts(cellView.model);
            navigateToBuilder(cellView.model, evt);
        });
        this.StencilPaper.on("element:delete", function (cellView, evt) {
            evt.stopPropagation();
            deletePopup(cellView, addedElement);
        });
        this.StencilPaper.on("textelement:delete", function (cellView, evt) {
            evt.stopPropagation();
            var currentCell = stencil.attributes.cells.models.find((ele) => {
                return ele.id === cellView.model.attributes.attrs[".trash"].linkCellId;
            });
            currentCell.remove();
            cellView.remove();
        });
        this.StencilPaper.on("textelement:copy", function (cellView, evt, x, y) {
            const graphScale = getZoomValue();
            evt.stopPropagation();
            var currentCell = stencil.attributes.cells.models.find((ele) => {
                return ele.id === cellView.model.attributes.attrs[".copy"].linkCellId;
            });
            var textCopy = currentCell.clone();
            textCopy.position((x / graphScale) + 50, (y / graphScale) + 50);
            addCells(textCopy);
        });
        this.StencilPaper.on("element:preview", function (cellView, evt) {
            evt.stopPropagation();
            showPagePreview(cellView.model);
        });
        this.StencilPaper.on("element:copy", function (cellView, evt, x, y) {
            evt.stopPropagation();
            const graphScale = getZoomValue();
            var pageCopy = cellView.model.clone();
            var paper = document.getElementById("page-scroller");
            var paperWidth = (paper.offsetWidth) - 200;
            var paperHeight = (paper.offsetHeight) - 200;
            var isChrome = /Chrome/.test(navigator.userAgent) && /Google Inc/.test(navigator.vendor);
            const zoomX = (!isChrome) ? x / graphScale : x;
            const zoomY = (!isChrome) ? y / graphScale : y;
            if (((zoomX) >= paperWidth) && ((zoomY) >= paperHeight)) {
                pageCopy.position((zoomX) - 250, (zoomY) - 250);
            } else if ((zoomX) >= paperWidth) {
                pageCopy.position((zoomX) - 250, (zoomY) - 20);
            } else if ((zoomY) >= paperHeight) {
                pageCopy.position((zoomX) + 20, (zoomY) - 250);
            } else {
                pageCopy.position((zoomX) + 20, (zoomY) + 20);
            }
            const sourcePageId = cellView.model.id;
            const clonedPageId = pageCopy.id;
            addedElement.cells.push(pageCopy);

            addCells(pageCopy);
            saveGraph();
            clonePage({ sourcePageId, clonedPageId });
            addElement(addedElement);
        });
        this.StencilPaper.on("element:split", function (cellView, evt) {
            evt.stopPropagation();
            alert("Split!!!");
        });
        this.StencilPaper.on("cell:pointermove", function (cellView, evt, x, y) {
            const graphScale = getZoomValue();
            var bbox = cellView.getBBox();
            var constrained = false;
            var paper = document.getElementById("page-scroller");
            if (paper) {
                var width = (paper.offsetWidth - (paper.offsetWidth / constants.outerZoom)) / graphScale;
                var height = (paper.offsetHeight - (paper.offsetHeight / constants.outerZoom) - 20) / graphScale;
                var constrainedX = x;
                if (bbox.x <= 0) {constrainedX = x + gridSize; constrained = true;}
                if (bbox.x + bbox.width >= width) {constrainedX = x - gridSize; constrained = true;}
                var constrainedY = y;
                if (bbox.y <= 0) {constrainedY = y + gridSize; constrained = true;}
                if (bbox.y + bbox.height >= height) {constrainedY = y - gridSize; constrained = true;}
                //if you fire the event all the time you get a stack overflow
                if (constrained) {cellView.pointermove(evt, constrainedX, (constrainedY));}
            }
        });
        const { stencil } = this;
        ToolbarPaper.on("cell:pointerdown", function (cellView, e, x, y) {
            if (cellView.model.attributes.elementMetaData.templateTypeId !== constants.memberFolderTemplateTypeId &&
                !isLoginPageAdded(cellView,constants.loginTemplateTypeId, stencil)) {
                const isIcon = cellView.model.attributes.icon;
                e.stopPropagation();
                closeRightPanel();
                const graphScale = getZoomValue();
                $("body").append("<div id=\"flyPaper\" style=\"position:fixed;z-index:100;opacity:.7;pointer-event:none;\"></div>");
                var flyGraph = new joint.dia.Graph,
                    flyPaper = new joint.dia.Paper({
                        el: $("#flyPaper"),
                        model: flyGraph,
                        interactive: false,
                        width: 120,
                        height: 160
                    }),
                    flyShape = cellView.model.clone(),
                    pos = cellView.model.position(),
                    offset = {
                        x: x - pos.x,
                        y: y - pos.y
                    };

                flyShape.position(0, 0);
                flyShape.size({
                    width: 120,
                    height: 160
                }),
                flyShape.attr({
                    image: {
                        width: isIcon ? 60 : 120,
                        height: isIcon ? 60 : 160,
                        x: isIcon ? 30 : null,
                        y: isIcon ? 30 : null,
                    },
                    ".label": {
                        y: -17.5,
                        x: 0
                    },
                    ".borderBox": {
                        width: 126,
                        height: 166,
                        x: -3,
                        y: -3
                    }
                }),
                flyGraph.addCell(flyShape);
                $("#flyPaper").offset({
                    left: e.pageX - offset.x,
                    top: e.pageY - offset.y
                });
                $("body").on("mousemove.fly", function (e) {
                    $("#flyPaper").offset({
                        left: e.pageX - offset.x,
                        top: e.pageY - offset.y
                    });
                });
                $("body").on("mouseup.fly", function (e) {
                    var x = e.pageX,
                        y = e.pageY,
                        target = StencilPaper.$el.offset();
                    // Dropped over StencilPaper
                    if (x > target.left && x < target.left + StencilPaper.$el.width() && y > target.top && y < target.top + StencilPaper.$el.height()) {
                        var s = flyShape.clone();
                        s.position((x - target.left - offset.x) / graphScale, (y - target.top - offset.y) / graphScale);
                        const draggedElement = s.attributes;
                        draggedElement.key = draggedElement.id;
                        const cellViewId = draggedElement.id;
                        const label = draggedElement.pageLabel.label;
                        addedElement.cells.push(draggedElement);
                        addCells(s);
                        saveGraph();
                        createNewPage({ cellViewId, label });
                        addElement(addedElement);
                    } else {
                        // Dropped over toolbar
                    }
                    $("body").off("mousemove.fly").off("mouseup.fly");
                    flyShape.remove();
                    $("#flyPaper").remove();
                });
            } else if (cellView.model.attributes.elementMetaData.templateTypeId === constants.memberFolderTemplateTypeId) {
                memberFolder.get("hidden")
                    ? ToolbarPaper.model.addCell([login, profile,membersArea,cancelMembership, restartMembership, updateCard, changeProduct])
                    : ToolbarPaper.model.removeCells([login, profile, membersArea,cancelMembership, restartMembership, updateCard, changeProduct]);
                memberFolder.set("hidden", !memberFolder.get("hidden"));
            }
        });
        const { parent } = this.props;
        const presell = Elements(presellPage, constants.presellPagePositionX, constants.presellPagePositionY, constants.presellPageLabel, 1, false, parent);
        const lead = Elements(leadPage, constants.leadPagePositionX, constants.leadPagePositionY, constants.leadPageLabel, 2, false, parent);
        const checkout = Elements(checkoutPage, constants.checkoutPagePositionX, constants.checkoutPagePositionY, constants.checkoutPageLabel, 4, false, parent);
        const upsell = Elements(upsellPage, constants.upsellPagePositionX, constants.upsellPagePositionY, constants.upsellPageLabel, 3, false, parent);
        const thankyou = Elements(thankyouPage, constants.thankyouPagePositionX, constants.thankyouPagePositionY, constants.thankyouPageLabel, 5, false, parent);
        const blank = Elements(blankPage, constants.blankPagePositionX, constants.blankPagePositionY, constants.blankPageLabel, null, false, parent);
        const optIn = Elements(optInPage, constants.optInPagePositionX, constants.optInPagePositionY, constants.optInPageLabel, 15, false, parent);
        const download = Elements(downloadPage, constants.downloadPagePositionX, constants.downloadPagePositionY, constants.downloadPageLabel, 16, false, parent);
        const salesVideo = Elements(salesVideoPage, constants.salesVideoPagePositionX, constants.salesVideoPagePositionY, constants.salesVideoPageLabel, 17, false, parent);
        const calendar = Elements(calendarPage, constants.calendarPagePositionX, constants.calendarPagePositionY, constants.calendarPageLabel, 18, false, parent);
        const survey = Elements(surveyPage, constants.surveyPagePositionX, constants.surveyPagePositionY, constants.surveyPageLabel, 19, false, parent);
        const webinar = Elements(webinarPage, constants.webinarPagePositionX, constants.webinarPagePositionY, constants.webinarPageLabel, 20, false, parent);
        const webinarReplay = Elements(webinarReplayPage, constants.webinarReplayPagePositionX, constants.webinarReplayPagePositionY, constants.webinarReplayPageLabel, 21, false, parent);
        const blogPost = Elements(blogPostPage, constants.blogPostPagePositionX, constants.blogPostPagePositionY, constants.blogPostPageLabel, 22, false, parent);
        const membersArea = Elements(membersAreaPage, constants.membersAreaPagePositionX, constants.membersAreaPagePositionY, constants.membersAreaPageLabel, 9, false, parent);
        const popUp = Elements(popUpPage, constants.popUpPagePositionX, constants.popUpPagePositionY, constants.popUpPageLabel, 23, false, parent);
        const email = Elements(emailPage, constants.emailPagePositionX, constants.emailPagePositionY, constants.emailPageLabel, 24, true, parent);
        const text = Elements(textPage, constants.textPagePositionX, constants.textPagePositionY, constants.textPageLabel, 25, true, parent);
        const generic = Elements(genericPage, constants.genericPagePositionX, constants.genericPagePositionY, constants.genericPageLabel, 14, true, parent);
        const profile = Elements(membersAreaPage, constants.profilePagePositionX, constants.profilePagePositionY, constants.profilePageLabel, constants.profileTemplateTypeId, false, parent);
        const cancelMembership = Elements(membersAreaPage, constants.cancelMembershipPagePositionX, constants.cancelMembershipPagePositionY, constants.cancelMembershipPageLabel, constants.cancelMembershipTemplateTypeid, false, parent);
        const restartMembership = Elements(membersAreaPage, constants.restartMembershipPagePositionX, constants.restartMembershipPagePositionY, constants.restartMembershipPageLabel, constants.restartMembershipTemplateTypeid, false, parent);
        const updateCard = Elements(membersAreaPage, constants.updateCardPagePositionX, constants.updateCardPagePositionY, constants.updateCardPageLabel, constants.updateCardTemplateTypeid, false, parent);
        const changeProduct = Elements(membersAreaPage, constants.changeProductPagePositionX, constants.changeProductPagePositionY, constants.changeProductPageLabel, constants.changeProductTemplateTypeid, false, parent);
        const memberFolder = Elements(membershipFolderPage, constants.memberFolderPositionX, constants.memberFolderPositionY, constants.memberFolderLabel, constants.memberFolderTemplateTypeId, false, parent);
        const login = Elements(membersAreaPage, constants.loginPagePositionX, constants.loginPagePositionY, constants.loginPageLabel, constants.loginTemplateTypeId, false, parent);
        const textComponent = TextElement(textChange, removeText, saveGraph);
        memberFolder.set("hidden", true);
        this.Toolbar.addCell([
            presell,
            lead,
            checkout,
            upsell,
            thankyou,
            blank,
            optIn,
            download,
            salesVideo,
            calendar,
            survey,
            webinar,
            webinarReplay,
            blogPost,
            popUp,
            email,
            text,
            generic,
            memberFolder
        ]);
    }
    getZoomValue = () => {
        const { graphScale } = this.props.visualizerData;
        return graphScale;
    }
    getCurrentPage = (cellView) => {
        const { funnel } = this.props;
        const currentPage = funnel.page.find((element) => element.cellViewId === cellView.id);
        return currentPage;
    }
    navigateToBuilder = (cellView, evt) => {
        const { selectedTemplate } = this.props.visualizerData;
        const { templates, updatedPage, updateVisualizerData, funnel, history } = this.props;
        let currentPage = (updatedPage && (updatedPage.cellViewId === cellView.id)) ?
            updatedPage :
            this.getCurrentPage(cellView);
        function search(nameKey, templateArray) {
            let template = {};
            for (var i = 0; i < templateArray.length; i++) {
                if (templateArray[i].templateId === nameKey) {
                    template = templateArray[i];
                    break;
                }
            }
            return template;
        }
        if (currentPage) {
            const tempId = currentPage.pageView[0].fTemplateId;
            if (tempId) {
                const selectedTemplate = search(tempId, templates);
                if (!isEmpty(selectedTemplate)) {
                    updateVisualizerData({ selectedTemplate, funnel, currentPage, funnelReferenceId: funnel.referenceId });
                    history.push("/funnel-template-web-builder");
                } else {
                    evt.stopPropagation();
                    this.props.updateVisualizerData({
                        modalToShow: "editAlert",
                        showModalWindow: true
                    });
                }
            } else if (!isEmpty(selectedTemplate)) {
                this.props.updateVisualizerData({
                    modalToShow: "waitAlert",
                    showModalWindow: true
                });
            } else {
                evt.stopPropagation();
                this.props.updateVisualizerData({
                    modalToShow: "editAlert",
                    showModalWindow: true
                });
            }
        } else {
            alert("something went wrong");
        }
    }


    getPageType = (templateTypeId) => {
        let pageType = "";
        switch (templateTypeId) {
            case 1:
                pageType = "Presell Page";
                break;
            case 2:
                pageType = "Lead Page";
                break;
            case 4:
                pageType = "Checkout Page";
                break;
            case 3:
                pageType = "Upsell Page";
                break;
            case 5:
                pageType = "Thank You Page";
                break;
            case 6:
                pageType = "Global";
                break;
            case 14:
                pageType = "Generic";
                break;
            case 15:
                pageType = "Opt-in";
                break;
            case 16:
                pageType = "Download";
                break;
            case 17:
                pageType = "Sales Video";
                break;
            case 18:
                pageType = "Calendar";
                break;
            case 19:
                pageType = "Survey";
                break;
            case 20:
                pageType = "Webinar";
                break;
            case 21:
                pageType = "Webinar Replay";
                break;
            case 22:
                pageType = "Blog Post";
                break;
            case 23:
                pageType = "Pop-up";
                break;
            default:
                pageType = "Page";
        }
        this.props.updateVisualizerData({


            pageTypeLabel: pageType
        });
        return pageType;
    }

    changeSource = (cellView) => {
        var newSource = cellView.get("vertices");
        cellView.source({
            x: newSource[0].x,
            y: newSource[0].y
        });
        cellView.target({
            x: newSource[1].x && newSource[1].x,
            y: newSource[1].y && newSource[1].y
        });
    }
    blankSave = () => {
        const saved = this.stencil.toJSON();
        if (saved.cells.length !== 0) {
            this.saveGraph();
        }
    }
    startDottedLine = (evt, x, y) => {
        const { isDotted } = this.props.visualizerData;
        if (isDotted) {
            var line = new joint.dia.Link({
                source: { x: x, y: y },
                attrs: {
                    ".marker-arrowhead": {
                        display: "none"
                    },
                },
            });
            evt.data = {
                lineCoords: {
                    x1: x,
                    y1: y
                },
                line: line
            };
        }
    }
    startMove = (evt, x, y) => {
        const { isDotted } = this.props.visualizerData;
        if (isDotted) {
            var lineCoords = evt.data.lineCoords;
            lineCoords.x2 = x;
            lineCoords.y2 = y;
            evt.data.line.target({ x: x, y: y });
            evt.data.line.attr(".connection/stroke-dasharray", "2,5");
            evt.data.line.prop("vertices", [
                {
                    x: lineCoords.x1, y: lineCoords.y1
                }, {
                    x: lineCoords.x2, y: lineCoords.y2
                }]);
            this.addCells(evt.data.line);
        }
    }
    addTextblock = (evt, x, y) => {
        const { isText } = this.props.visualizerData;
        if (isText) {
            this.props.updateVisualizerData({
                isText: false, positionX: x, positionY: y, callbackEvent: "addTextblock"
            });
        }
    }
    addTextblockCallBack = (visualizerData) => {
        const { positionX, positionY } = visualizerData;
        var textBlock = new joint.shapes.standard.Rectangle({
        });
        var toolBlock = new joint.shapes.standard.Rectangle({
            markup: "<rect class=\"textelement iconbar\" width=\"0px\" height=\"0px\" /><text class=\"icons trash text-delete\" x=\"0\" y=\"-65\">" + `${constants.trash}` + "<title>Delete</title></text>",
            attrs: {
                ".trash": {
                    event: "textelement:delete",
                    linkCellId: textBlock.id
                },
                ".copy": {
                    event: "textelement:copy",
                    linkCellId: textBlock.id
                },
            },
        });
        var type = "Double Click to Edit";
        textBlock.resize(240, 60);
        textBlock.position(positionX, positionY);
        toolBlock.position(positionX, positionY + 62);
        textBlock.attr("root/title", "TextBlock");
        textBlock.attr("label/text", type);
        textBlock.attr("label/fill", "#1ab394");
        textBlock.attr("label/fontSize", "18");
        textBlock.attr("label/fontWeight", "700");
        textBlock.attr("body/strokeWidth", "0");
        textBlock.attr("body/fill", "#ffffff00");
        this.stencil.addCells([textBlock, toolBlock]);
        textBlock.embed(toolBlock);
        this.removehtmlElement();
        this.props.updateVisualizerData({ callbackEvent: "" });
    }
    editableText = (cell,evt,x,y) => {
        const { graphScale } = this.props.visualizerData;
        const currentCell = cell;
        var el1 = new joint.shapes.html.Element({
            position: { x: cell.attributes.position.x * graphScale , y: cell.attributes.position.y * graphScale },
            size: { width: 240, height: 60 },
        });
        this.setState ({ currentCell: currentCell, tempCell: el1 } ,() => {
            this.stencil.addCells([el1]);
            var textBox = document.querySelector(".text-view");
            textBox.value = currentCell.attributes.attrs.label.text;
            textBox.focus();
            currentCell.attr("label/fontSize", "0");
        });
    }

    removehtmlElement = () => {
        const { currentCell } = this.state;
        var text = document.querySelectorAll(".html-element");
        currentCell && currentCell.attr("label/fontSize", "18");
        var emptyBox = document.querySelectorAll(".v-line.v-empty-line");
        for (var i = 0; i < text.length; i++) text[i].remove();
        for (var j = 0; j < emptyBox.length; j++) {
            var elem = emptyBox[j].parentNode.parentNode.classList.contains("joint-port");
            if (!elem) {
                emptyBox[j].parentNode.parentNode.remove();
            }
        }
    }
    saveGraph = () => {
        const { funnel } = this.props;
        const jsonFile = this.stencil.toJSON();
        let funnelReferenceId = funnel.referenceId;
        this.props.savefunnelGraph({ funnelReferenceId, jsonFile });
    }
    clonePage = (cellViewId) => {
        const { funnel } = this.props;
        let funnelReferenceId = funnel.referenceId;
        const sourcePagecellViewId = cellViewId.sourcePageId;
        const currentPage = funnel.page.find((element) => {
            return element.cellViewId === sourcePagecellViewId;
        });
        const pageReferenceId = currentPage.referenceId;
        const clonedPageCellviewId = cellViewId.clonedPageId;
        this.props.clonePage({ pageReferenceId, clonedPageCellviewId, funnelReferenceId });
    }

    getSavedfonts = (cellView) => {
        const { funnel } = this.props;
        const currentPage = funnel.page.find((element) => {
            return element.cellViewId === cellView.id;
        });
        if (currentPage) {
            const pageReferenceId = currentPage.referenceId;
            const funnelReferenceId = funnel.referenceId;
            this.props.getSavedfonts({ funnelReferenceId, pageReferenceId }).catch(() => {});
        }
    }
    createNewPage = (cellViewId) => {
        const { funnel } = this.props;
        const saved = this.stencil.toJSON();
        let cellViewDetail = { saved, cellViewDetail: cellViewId };
        this.props.createNewPage({ funnel, cellViewDetail });
    }
    getGraph = (metaJsonVisualizer) => {
        this.stencil.fromJSON(metaJsonVisualizer);
        var viewport = document.getElementsByClassName("joint-viewport")[1] && document.getElementsByClassName("joint-viewport")[1].getBBox();
        this.props.updateVisualizerData({

            viewportHeight: viewport.height,
            viewportWidth: viewport.width
        });
    }
    addCells = (cell) => {
        this.stencil.addCell(cell);
    }
    textChange = (value) => {
        const { currentCell,tempCell } = this.state;
        currentCell.attr("label/text", value);
        this.setState({
            currentText: value,
        },() => {
            tempCell.remove();
        });
    }
    addElement = (addedElement) => {
        this.props.updateVisualizerData({ addedElementArray: { cells: [...addedElement.cells] } });
    }
    isLoginPageAdded = (cellView, loginTemplateTypeId, stencil) => {
        if (cellView.model.attributes.elementMetaData.templateTypeId !== loginTemplateTypeId)
            return false;
        else {
            return !!stencil.attributes.cells.models.find((cell) =>
                cell.attributes.elementMetaData && cell.attributes.elementMetaData.templateTypeId === loginTemplateTypeId
            );
        }
    }
    deletePopup = (cellView, addedElement) => {
        this.props.updateVisualizerData({

            showModalWindow: true, modalToShow: "deletePopup",
            deleteItem: cellView,
            addedElementList: { cells: addedElement.cells }
        });
    }
    removeUnwantedCell = () => {
        const { addedElementArray } = this.props.visualizerData;
        const cellView = addedElementArray.cells[0];
        if (cellView) {
            const current = this.StencilPaper.model.attributes.cells.models.find((element) => {
                return element.id === cellView.id;
            });
            if (current) {
                current.remove();
            }
            this.props.updateVisualizerData({ addedElementArray: addedElementArray.cells.pop() });
        }
    }

    closeRightPanel = () => {
        const { isRightPanelActive } = this.props.visualizerData;
        isRightPanelActive && this.props.closeRightPanel();
    }
    openRightPanel = () => {
        this.props.updateVisualizerData({
            isRightPanelActive: true, importShareId: null, callbackEvent: ""
        });
    }
    showPagePreview = (cellView) => {
        const { funnel } = this.props;
        const currentPage = funnel.page.find((element) => {
            return element.cellViewId === cellView.id;
        });
        const templateId = currentPage.pageView[0].fTemplateId;
        const previewUrl = currentPage.pageView[0].url;
        if (templateId) {
            window.open(previewUrl, "_blank");
        } else {
            this.props.updateVisualizerData({
                showModalWindow: true, modalToShow: "previewAlert"
            });
        }
    }
    showTemplate = (cellView) => {
        const { funnel } = this.props;
        const currentPage = funnel.page.find((element) => {
            return element.cellViewId === cellView.id;
        });
        if (currentPage) {
            const selectedTempId = currentPage ? currentPage.pageView[0].fTemplateId : null;
            this.props.updateVisualizerData({

                isConfigLink: false, isLoadTemplates: selectedTempId !== null ? false : true
            });
        }
    }
    setCellData = (cellData) => {
        this.props.setCellData({
            cellData: { ...cellData }, templateTypeId: cellData.attributes.elementMetaData.templateTypeId
        });
    }
    setSelectedData = (selectedData) => {
        this.props.updateVisualizerData({
            selectedView: selectedData.model,
            selectedData: selectedData, pageName: selectedData.model.attributes.attrs[".label"].text
        });
    }

    pageNameChange = (pageName) => {
        const { selectedData } = this.props.visualizerData;
        var newcell = this.stencil.getCell(selectedData.model.id);
        newcell.prop("attrs/.label/text", pageName);
        this.props.updateVisualizerData({ pageName: pageName });
    }


    modifyCell = (selectedElement, modifyElement, selectedTemplateThumbnailUrl) => {
        const currentPageId = modifyElement.cells.find((element) => {
            return element.id === selectedElement.model.id;
        });
        this.props.updateVisualizerData({
            currentPageId: currentPageId, selectedTemplate: null, selectedTemplateThumbnailUrl, callbackEvent: "modifyCell"
        });
    };
    modifyCellCallback = () => {
        const { currentPageId, selectedTemplateThumbnailUrl } = this.props.visualizerData;
        var newcell = this.stencil.getCell(currentPageId.id);
        if (newcell.isElement()) {
            newcell.attr("image/xlink:href", selectedTemplateThumbnailUrl);
            newcell.attr("templateSelcted", true);
        }
        this.saveGraph();
        this.closeRightPanel();
        this.props.updateVisualizerData({ callbackEvent: "" });
    }
    linkSettings = (cellView) => {
        if (cellView.model.attributes.type === constants.cellTypeLink) {
            // Find the selected page using funnel and grab the metaJson
            const linkOfCurrentPage = this.props.funnel.page.find((p) => {
                return p.cellViewId === cellView.sourceView.model.id;
            });
            const { linkCurrentPage, multiSelectValue } = this.props.visualizerData;
            let multiSelectVal = [...multiSelectValue];
            if (isEmpty(linkCurrentPage) || (!isEmpty(linkCurrentPage) && linkCurrentPage.referenceId !== linkOfCurrentPage.referenceId)) {
                multiSelectVal = [];
            }
            this.props.updateVisualizerData({
                isArrowSelected: true,
                targetViewId: cellView.targetView.model.id,
                sourceViewId: cellView.sourceView.model.id,
                sourceTemplateTypeId: cellView.sourceView.model.attributes.elementMetaData.templateTypeId,
                linkViewId: cellView.model.id,
                sourcePageLabel: cellView.sourceView.model.attributes.pageLabel.label,
                linkCurrentPage: linkOfCurrentPage, callbackEvent: "loadButtonCallBack",
                multiSelectValue: multiSelectVal
            });

        } else {
            const page = this.props.funnel.page.find((p) => {
                return p.cellViewId === cellView.model.id;
            });
            if (page) {
                this.props.updateVisualizerData({
                    isConfigLink: false, isLoadTemplates: page.pageView[0].fTemplateId ? false : true,
                    callbackEvent: "openRightPanel"
                });
            }
        }
    }

    loadButtonCallBack = (visualizerData) => {
        const { linkCurrentPage } = visualizerData;
        if (linkCurrentPage) {
            this.props.loadButtons({ metaJsonUrl: linkCurrentPage.pageView[0].metaJson });
        }
        this.props.updateVisualizerData({
            isConfigLink: true, isLoadTemplates: false, callbackEvent: "openRightPanel"
        });
    }

    onUpsellChange = (evt, key) => {
        this.props.updateVisualizerData({


            [key]: evt.target.value
        });
    }
    onButtonChange = (evt) => {
        this.props.updateVisualizerData({

            linkButtonData: JSON.parse(evt.target.value)
        });
    }
    deleteLinkCallback = (cellView) => {
        const { funnel } = this.props;
        const { isPageDeleted } = this.props.visualizerData;
        const page = funnel.page.find((p) => {
            return p.cellViewId === cellView.attributes.source.id;
        });
        this.saveGraph();
        if (page) {
            const sourceViewId = cellView.attributes.source.id;
            this.props.loadButtons({ metaJsonUrl: page.pageView[0].metaJson, isPageDeleted }, (pageData) => {
                if (pageData.buttons) {
                    let buttons = pageData.buttons && [...pageData.buttons];
                    let links = pageData.links && [...pageData.links];
                    const newJsPageData = pageData.jsPageData && { ...pageData.jsPageData };
                    const componentArray = [buttons && [...buttons], links && [...links]];
                    const deleteElementArray = componentArray.filter((element) => {
                        return ((element.target !== null) && (element.linkViewId === cellView.id));
                    });
                    let idx, updatedArray;
                    deleteElementArray.map((ele, index) => {
                        idx = componentArray.findIndex((item) => {
                            return item["data-id"] === ele["data-id"];
                        });
                        componentArray[idx] = {
                            ...componentArray[idx],
                            target: null,
                            linkViewId: null
                        };
                        updatedArray = componentArray.map((ele, index) => {
                            if (index === idx) {
                                if (ele["data-id"] in newJsPageData) {
                                    delete newJsPageData[ele["data-id"]];
                                }
                                return {
                                    ...ele,
                                    linkViewId: null,
                                    target: null
                                };
                            }
                            return ele;
                        });
                    });
                    if (updatedArray) {
                        buttons = updatedArray && updatedArray.filter((btn) => {
                            return btn.type !== "link";
                        });
                        links = updatedArray.filter((link) => {
                            return link.type === "link";
                        });
                    }
                    const jsPageData = { ...newJsPageData };
                    const jsonFile = this.stencil.toJSON();
                    // Get the target page reference id here and add it to the pageData in the state
                    const sourcePage = funnel.page.find((p) => p.cellViewId === sourceViewId);
                    this.props.saveButtonData({
                        jsonFile,
                        jsPageData,
                        newPageData: buttons,
                        newPageLinkData: links,
                        sourcePage,
                        funnel,
                        funnelReferenceId: funnel.referenceId,
                        isDeleteArrow: true
                    });

                }
            });
        }
    }
    getSplitUrl = (funnel = null) => {
        if (funnel && funnel.status && funnel.page && funnel.page.length > 0) {
            const fFunnel = funnel.page.filter(fp => fp.active);
            return fFunnel.length > 0 ? fFunnel[0].pageView[0].publicUrl : "";
        }
        return "";
    }
    onSelectButton = () => {
        const { targetViewId,
            sourceViewId,
            linkViewId,
            linkOfferData,
            linkUpsellData,
            sourceTemplateTypeId, multiSelectValue } = this.props.visualizerData;
        const { funnel, pageData } = this.props;
        const isLoginPage = (sourceTemplateTypeId === constants.loginTemplateTypeId);
        let newStencilJson = { cells: [] }, arrowConfig = {}, jsPageData = {}, newPageData = {}, newPageLinkData = {}, newLinkData = {};
        // Get the target page reference id here and add it to the pageData in the state
        const targetPage = funnel.page.find((p) => p.cellViewId === targetViewId);
        const sourcePage = funnel.page.find((p) => p.cellViewId === sourceViewId);
        const linkButtonData = multiSelectValue.map((item) => {
            return JSON.parse(item.value);
        });
        let componentData;
        const tempStencil = this.stencil.toJSON();
        const newCells = tempStencil.cells.map((cell) => {
            if ((cell.type === constants.cellTypeLink) && (cell.id === linkViewId)) {
                const newCell = this.stencil.getCell(cell.id);
                newCell && newCell.attr(".marker-target", {
                    ...newCell.attributes.attrs[".marker-target"],
                    stroke: "#31d0c6",
                    fill: "#31d0c6"
                });
                cell = newCell && newCell.attributes;
                this.saveGraph();
                return {
                    ...cell,
                    isButtonAssigned: true,
                    isLinkAssigned: true
                };
            }
            return cell;
        });
        newStencilJson = {
            cells: newCells
        };
        linkButtonData.map((item) => {
            componentData = item;
            if ((componentData["data-id"] in pageData.jsPageData)) {
                if (isLoginPage) {
                    jsPageData[componentData["data-id"]] = {
                        ...pageData.jsPageData[componentData["data-id"]],
                        [componentData.status]: {
                            NEXT_PAGE: {
                                referenceId: targetPage.referenceId,
                                targetPage: targetPage.pageView[0].referenceId
                            },
                            OFFER: linkOfferData,
                            UPSELL: linkUpsellData,
                            linkViewId
                        }
                    };
                } else {
                    jsPageData[componentData["data-id"]] = {
                        NEXT_PAGE: {
                            referenceId: targetPage.referenceId,
                            targetPage: targetPage.pageView[0].referenceId
                        },
                        OFFER: linkOfferData,
                        UPSELL: linkUpsellData,
                        linkViewId
                    };
                }
                jsPageData = {
                    ...jsPageData,
                    currentPage: sourcePage,
                    OFFER: linkOfferData,
                    funnelData: funnel,
                    templateTypeId: sourceTemplateTypeId,
                    salt: pageData.jsPageData.salt,
                    selectedCampaign: pageData.jsPageData.selectedCampaign
                };
            } else {
                if (componentData && componentData.type === "link") {
                    if (!componentData) {
                        const bdIdx = pageData.linkData.findIndex((bd) => {
                            return bd.linkViewId === linkViewId;
                        });
                        componentData = pageData.linkData[bdIdx];
                    }
                    jsPageData[componentData["data-id"]] = {
                        NEXT_PAGE: {
                            referenceId: targetPage.referenceId,
                            targetPage: targetPage.pageView[0].referenceId
                        },
                        OFFER: linkOfferData,
                        UPSELL: linkUpsellData,
                        linkViewId
                    };
                    jsPageData = {
                        ...jsPageData,
                        ...pageData.jsPageData,
                        currentPage: sourcePage,
                        OFFER: linkOfferData,
                        funnelData: funnel,
                        templateTypeId: sourceTemplateTypeId,
                        salt: pageData.jsPageData.salt,
                        selectedCampaign: pageData.jsPageData.selectedCampaign
                    };
                } else {
                    if (!componentData) {
                        const bdIdx = pageData.buttonData.findIndex((bd) => {
                            return bd.linkViewId === linkViewId;
                        });
                        componentData = pageData.buttonData[bdIdx];
                    }
                    if (isLoginPage) {
                        jsPageData[componentData["data-id"]] = {
                            ...pageData.jsPageData[componentData["data-id"]],
                            [componentData.status]: {
                                NEXT_PAGE: {
                                    referenceId: targetPage.referenceId,
                                    targetPage: targetPage.pageView[0].referenceId
                                },
                                OFFER: linkOfferData,
                                UPSELL: linkUpsellData,
                                linkViewId
                            }
                        };
                    } else {
                        jsPageData[componentData["data-id"]] = {
                            NEXT_PAGE: {
                                referenceId: targetPage.referenceId,
                                targetPage: targetPage.pageView[0].referenceId
                            },
                            OFFER: linkOfferData,
                            UPSELL: linkUpsellData,
                            linkViewId
                        };
                    }
                    jsPageData = {
                        ...jsPageData,
                        ...pageData.jsPageData,
                        currentPage: sourcePage,
                        OFFER: linkOfferData,
                        funnelData: funnel,
                        templateTypeId: sourceTemplateTypeId,
                        salt: pageData.jsPageData.salt,
                        selectedCampaign: pageData.jsPageData.selectedCampaign
                    };
                }
            }
            newPageLinkData = pageData.linkData.map((pg) => {
                if ((pg["data-id"] in jsPageData)) {
                    return {
                        ...pg,
                        target: targetPage.referenceId,
                        linkViewId: pg["linkViewId"] ? pg["linkViewId"] : linkViewId
                    };
                } else if (!(pg["data-id"] in jsPageData)) {
                    return {
                        ...pg,
                        target: null,
                        linkViewId: null
                    };
                }
                return pg;
            });
            newPageData = pageData.buttonData.map((pg) => {
                if ((pg["data-id"] in jsPageData)) {
                    if (isLoginPage) {
                        if (componentData.status === pg.status) {
                            return {
                                ...pg,
                                target: targetPage.referenceId,
                                linkViewId
                            };
                        }
                    } else {
                        return {
                            ...pg,
                            target: targetPage.referenceId,
                            linkViewId: pg["linkViewId"] ? pg["linkViewId"] : linkViewId
                        };
                    }
                } else if (!(pg["data-id"] in jsPageData)) {
                    return {
                        ...pg,
                        target: null,
                        linkViewId: null
                    };
                }
                return pg;
            });
        });
        this.props.saveButtonData({
            jsonFile: newStencilJson,
            jsPageData,
            newPageData,
            newPageLinkData,
            sourcePage,
            funnelReferenceId: funnel.referenceId,
            funnel,
            isLink: false,
            isArrowChange: true
        });
        this.props.closeRightPanel({
            linkOfferData: null,
            linkUpsellData: null,
            linkCurrentPage: null,
            isArrowChange: true
        });
    }

    importSharePage = () => {
        this.props.updateVisualizerData({ showModalWindow: true, modalToShow: "sharePopup" });
    }
    handleImport = () => {
        const { selectedData } = this.props.visualizerData;
        const { funnel } = this.props;
        const currentPage = funnel.page.find((element) => {
            return element.cellViewId === selectedData.model.id;
        });
        const currentPageView = currentPage && currentPage.pageView[0].referenceId;
        let importId = document.querySelector("#importId").value;
        const validPage = funnel.page.find((element) => {
            return element.pageView[0].referenceId === importId;
        });
        if (validPage && (validPage.pageView[0].referenceId === currentPageView)) {
            this.props.updateVisualizerData({
                isShareIdError: true, showModalWindow: false, modalToShow: "", isShareidSame: true
            });
        } else {
            this.props.updateVisualizerData({
                showModalWindow: false, modalToShow: "", isShareIdError: false, isShareOpen: false, isRightPanelActive: false,
                isPageImported: true, isLoadTemplates: false,
                callbackEvent: "importPageCallback"
            });
        }
    }
    importPageCallback = (visualizerData) => {
        const { selectedData } = visualizerData;
        const { funnel } = this.props;
        const funnelReferenceId = funnel.referenceId;
        const currentPage = funnel.page.find((element) => {
            return element.cellViewId === selectedData.model.id;
        });
        const currentPageView = currentPage && currentPage.pageView[0].referenceId;
        let importId = document.querySelector("#importId").value;
        this.props.importPage({ importId, currentPageView, funnelReferenceId, funnel });
        this.props.updateVisualizerData({ callbackEvent: "" });
    }
    handleChildUnmount = () => {
        this.props.updateVisualizerData({
            renderChild: false
        });
    }
    handleClose = () => {
        this.props.updateVisualizerData({ showModalWindow: false, modalToShow: "" });
    }
    handleDeleteCallBack = (visualizerData) => {
        const { deleteItem, addedElementList } = visualizerData;
        const deleteView = deleteItem.model;
        this.removeCell(deleteView, addedElementList);
    }
    removeCell = (cellView, addedElement) => {
        var links = this.stencil.getConnectedLinks(cellView, { inbound: true });
        // Get only the links which are assigned to a button
        this.deletedPageLinks = links.filter((l) => l.attributes.isButtonAssigned).map((l) => l.id);
        const isPageDeleted = (this.deletedPageLinks.length > 0) ? true : false;
        this.props.updateVisualizerData({
            addedElementArray: addedElement, isPageDeleted, callbackEvent: "removeCell"
        });
    }
    removeCellCallback = (visualizerData) => {
        const { deletePage, funnel } = this.props;
        const { deleteItem, addedElementArray, isPageDeleted } = visualizerData;
        const deleteView = deleteItem.model;
        const cellView = deleteView;
        cellView.remove();
        addedElementArray.cells.pop(cellView);
        const saved = this.stencil.toJSON();
        const currentPage = funnel.page.find((element) => {
            return element.cellViewId === cellView.id;
        });
        if (currentPage) {
            const pageReferenceId = currentPage.referenceId;
            deletePage({ funnel, pageReferenceId, deleteView: saved, isPageDeleted: isPageDeleted, currentPage });
        }
        this.props.updateVisualizerData({ callbackEvent: "" });
    }


    handleConfirmation = () => {
        const { modalToShow } = this.props.visualizerData;
        if (modalToShow === "deletePopup") {
            this.props.updateVisualizerData({
                showModalWindow: false, modalToShow: "", callbackEvent: "handleDelete"
            });
        } else if (modalToShow === "sharePopup") {
            this.handleImport();
        }
    }
    /*
 * On Cell Add / Remove,
 * Check if its a blank visualizer
 */

    checkIfEmptyVisualizer = () => {
        if (this.stencil && this.stencil instanceof window.Backbone.Model) {
            const numberOfElementsInVisualizer = this.stencil.attributes.cells.length;
            const showImportFunnelTemplateBtn = (numberOfElementsInVisualizer) ? false : true;
            const { showImportFunnelTemplateButton } = this.props.visualizerData;
            (showImportFunnelTemplateButton !== showImportFunnelTemplateBtn) &&
                this.props.updateVisualizerData({ showImportFunnelTemplateButton: showImportFunnelTemplateBtn, callbackEvent: "" });
        }
    }

    openImportFunnelPopup = () => {
        this.props.updateVisualizerData({ showImportFunnelModal: true });
    }

    render() {
        const { viewportWidth, viewportHeight, modalToShow, showModalWindow, outerZoom,
            innerZoom, visualizerHeight, visualizerWidth, graphScale, scrollerWidth, scrollerHeight,showImportFunnelTemplateButton } = this.props.visualizerData;
        const { updatedPage,
            isImportFunnelLoading } = this.props;

        return (
            <>

                <div className="canvas" id="canvas" ref="canvas">
                    <div className="pageList">
                        <div id="stencil" ref="stencil"></div>
                    </div>
                    <div className="visualizer" id="visualizer">
                        <div className="page-scroller" id="page-scroller"
                            style={{
                                width: (((viewportWidth * graphScale)) > (scrollerWidth * outerZoom)) ? ((viewportWidth * graphScale) + 220) : (scrollerWidth * outerZoom),
                                height: (((viewportHeight * graphScale)) > (scrollerHeight * outerZoom)) ? ((viewportHeight * graphScale) + 220) : (scrollerHeight * outerZoom)
                            }}
                        >
                            <div ref="visualizer" className="visualizer-graph"
                                style={{
                                    minWidth: 1600, minHeight: 800,
                                    marginLeft: - (((viewportWidth * graphScale) > (visualizerWidth * innerZoom)) ? (((viewportWidth * graphScale) + 100) / 2) : ((visualizerWidth * innerZoom) / 2)),
                                    marginTop: - ((viewportHeight * graphScale) > (visualizerHeight * innerZoom) ? (((viewportHeight * graphScale) + 200) / 2) : ((visualizerHeight * innerZoom) / 2)),
                                    width: ((viewportWidth * graphScale) > (visualizerWidth * innerZoom)) ? ((viewportWidth * graphScale) + 100) : (visualizerWidth * innerZoom),
                                    height: ((viewportHeight * graphScale) > (visualizerHeight * innerZoom)) ? ((viewportHeight * graphScale) + 200) : (visualizerHeight * innerZoom)
                                }}
                            >
                            </div>
                            {isImportFunnelLoading &&
                                <div className="import-page-loader"><i className="fa fa-spinner fa-pulse fa-lg m-r-xs" /></div>
                            }
                            {
                                showImportFunnelTemplateButton && (
                                    <div className="btn-import-default-templates">
                                        <button
                                            type="button"
                                            className="btn btn-primary"
                                            onClick={this.openImportFunnelPopup}
                                        >
                                            Choose a Template Funnel
                                        </button>
                                    </div>
                                )
                            }
                        </div>
                    </div>
                    <VisualizerRightPanel parent={this.props.parent}
                        saveGraph={this.saveGraph}
                        onSelectButton={this.onSelectButton}
                        updatedPage={updatedPage}
                        pageNameChange={this.pageNameChange}
                    ></VisualizerRightPanel>
                </div>
                <PopUpModal
                    modalToShow={modalToShow}
                    showModalWindow={showModalWindow}
                    handleClose={this.handleClose}
                    handleConfirmation={this.handleConfirmation}>
                </PopUpModal>
                <ImportFunnelTemplateModal
                />
            </>
        );
    }
}

export const VisualizerFrame = withRouter(VisualizerFrameComponent);