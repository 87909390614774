import handleStickyBottomGapUpdate from "./handle-update/sticky-bottom-gap";
import handleStickTopGapUpdate from "./handle-update/sticky-top-gap";
import handleStickyBarPositionUpdate from "./handle-update/sticky-bar-position";
import minutesDelay from "./handle-update/minutes-delay";
import secondsDelay from "./handle-update/seconds-delay";
import handleTriggerOnUpdate from "./handle-update/trigger-on";
import handleTriggerStickybarOn from "./handle-update/trigger-stickybar-on";
import handleBackgroundImageChange from "./handle-update/background-image-change";
import handleBackgroundImageStyleChange from "./handle-update/background-image-style-change";
import handleBorderRadiusChange from "./handle-update/border-radius";
import handleBorderRadiusEdgesChange from "./handle-update/border-radius-edges";
import handleModalWidthChange from "./handle-update/modal-width";
import handleAnimationDurationChange from "./handle-update/animation-duration";
import handleAttributesChange from "./handle-update/attributes";

const onComponentUpdate = (editor, args) => {
    const component = args[0] || {};
    const changedPropsArray = component.changed && Object.keys(component.changed);
    const changedProps = changedPropsArray.length && changedPropsArray[0];
    switch (changedProps) {
        case "stickyBottomGap":
            handleStickyBottomGapUpdate(editor, component);
            break;
        case "stickyTopGap":
            handleStickTopGapUpdate(editor, component);
            break;
        case "stickyBarPosition":
            handleStickyBarPositionUpdate(editor, component);
            break;
        case "minutesDelay":
            minutesDelay(editor, component);
            break;
        case "secondsDelay":
            secondsDelay(editor, component);
            break;
        case "trigger_on":
            handleTriggerOnUpdate(editor, component);
            break;
        case "trigger_stickybar_on":
            handleTriggerStickybarOn(editor, component);
            break;
        case "backgroundImage":
            handleBackgroundImageChange(editor, component);
            break;
        case "backgroundImageStyle":
            handleBackgroundImageStyleChange(editor, component);
            break;
        case "borderRadius":
            handleBorderRadiusChange(editor, component);
            break;
        case "borderRadiusEdges":
            handleBorderRadiusEdgesChange(editor, component);
            break;
        case "modal_width":
            handleModalWidthChange(editor, component);
            break;
        case "animationDuration":
            handleAnimationDurationChange(editor, component);
            break;
        case "attributes":
            handleAttributesChange(editor, component);
            break;
    }
};

export default {
    "component:update": onComponentUpdate,
};

