import React, { Component, Fragment } from "react";
import { Button, Row, Col, FormGroup, ControlLabel, FormControl } from "react-bootstrap";
import PropTypes from "prop-types";
import ReactDOM from "react-dom";

import { NotificationEmitter, NOTIFICATION_EVENT_NAME } from "../../../commons/notification";
import { getFulfillmentVendorQuery, createFulfillmentHouse, updateFulfillmentHouse } from "../../utilities/api";
import { numberOnly } from "../../utilities";
import CRMButton from "../../CRMButton";
import CRMLoading from "../../CRMLoading";

class CreateEditFulfillment extends Component {
    constructor(props) {
        super(props);
        this.state = {
            hasError: false,
            isLoading: false,
            formErrors: [],
            fulfillment: Object.assign({}, this.getFulfillmentInitialState()),
            fulfillmentVendorList: null,
            // fieldData: props.fulfillmentDetails && props.fulfillmentDetails.fieldData,
            inputFields: props.fulfillmentDetails ? this.getInputfields() : null,
            fulfillmentVendorId: props.fulfillmentDetails && props.fulfillmentDetails.fulfillmentVendorId
        };
    }

    componentDidMount() {
        getFulfillmentVendorQuery()
            .then((res) => {
                this.setState({
                    fulfillmentVendorList: res.message.filter(data => data.vendorName !== null && data.uniqueFields !== null)
                });
            });
    }

    getInputfields = () => {
        const { fulfillmentDetails } = this.props;
        if (fulfillmentDetails.uniqueFields) {
            let inputFields = fulfillmentDetails.uniqueFields.map(data => {
                let splittedData = data.split(":");
                let typeSplittedData = splittedData[1].split("|");
                if (typeSplittedData.length === 1) {
                    return {
                        labelName: splittedData[0],
                        type: typeSplittedData[0]
                    };
                }
                if (typeSplittedData.length === 2) {
                    return {
                        labelName: splittedData[0],
                        type: typeSplittedData[0],
                        options: typeSplittedData[1].split(";")
                    };
                }
            });
            // let fieldData = inputFields.map((input, i) => {
            //     return {
            //         [input.labelName]: null
            //     };
            // });
            return inputFields;
        }
    }

    getFulfillmentInitialState() {
        const { fulfillmentDetails } = this.props;
        if (fulfillmentDetails) {
            if (fulfillmentDetails.uniqueFields) {
                let inputFields = fulfillmentDetails.uniqueFields.map(data => {
                    let splittedData = data.split(":");
                    let typeSplittedData = splittedData[1].split("|");
                    if (typeSplittedData.length === 1) {
                        return {
                            labelName: splittedData[0],
                            type: typeSplittedData[0]
                        };
                    }
                    if (typeSplittedData.length === 2) {
                        return {
                            labelName: splittedData[0],
                            type: typeSplittedData[0],
                            options: typeSplittedData[1].split(";")
                        };
                    }
                });
                this.setState({
                    inputFields,
                    // fieldData: Object.assign({}, ...fieldData)
                });
                return {
                    ...fulfillmentDetails
                };
            }
        } else {
            return {
                fulfillmentHouseTitle: null,
                returnAddress: null,
                fulfillmentVendorId: null,
                fieldData: null,
            };
        }
    }

    handleChange = (e) => {
        this.setState({
            fulfillment: {
                ...this.state.fulfillment,
                [e.target.name]: e.target.value
            }
        });
    }

    handleVendorList = (e) => {
        const { fulfillment: { fieldData } } = this.state;
        let vendor = this.state.fulfillmentVendorList.filter(vendor => vendor.fulfillmentVendorId === parseInt(e.target.value));
        let uniqueField = vendor[0].uniqueFields.split("\n");
        let uniqueFields = uniqueField.filter(uniqueFields => uniqueFields !== "");
        let inputFields = uniqueFields.map(data => {
            let splittedData = data.split(":");
            let typeSplittedData = splittedData[1].split("|");
            if (typeSplittedData.length === 1) {
                return {
                    labelName: splittedData[0],
                    type: typeSplittedData[0]
                };
            }
            if (typeSplittedData.length === 2) {
                return {
                    labelName: splittedData[0],
                    type: typeSplittedData[0],
                    options: typeSplittedData[1].split(";")
                };
            }
        });
        let inputFieldData = inputFields.map((input, i) => {
            return {
                [input.labelName]: null
            };
        });
        this.setState({
            fulfillment: { ...this.state.fulfillment, fieldData: {
                ...fieldData,
                [e.target.name]: e.target.value
            } }
        });
        this.setState({
            inputFields,
            fulfillment: { ...this.state.fulfillment, fieldData: Object.assign({}, ...inputFieldData), fulfillmentVendorId: parseInt(e.target.value) }
        });
    }

    renderInputs = (inputFields) => {
        const { fulfillment: { fieldData } } = this.state;
        return inputFields && inputFields.map((input, i) => {
            return (
                <Fragment>
                    {
                        input.type.includes("STR") || input.type.includes("INT") ?
                            <Row className="plr-50 pt-20 m-0">
                                <Col md={6}>
                                    <FormGroup>
                                        <ControlLabel className="custom-form-label">{input.labelName}</ControlLabel>
                                        <FormControl
                                            // type={`${input.labelName.toLowerCase().includes("secret") || input.labelName.toLowerCase().includes("password") ? "password" : "text"}`}
                                            type="text"
                                            onChange={e => {
                                                input.type.includes("INT") && numberOnly(e);
                                                this.setState({
                                                    fulfillment: { ...this.state.fulfillment, fieldData: {
                                                        ...fieldData,
                                                        [input.labelName]: e.target.value
                                                    } }
                                                });
                                            }}
                                            defaultValue={ fieldData && fieldData[input.labelName] || null}
                                        />
                                    </FormGroup>
                                </Col>
                            </Row>
                            : input.type.includes("BIT") ?
                                <Row className="plr-50 pt-20 m-0 pl" style={{ paddingLeft: 75 }}>
                                    <Col md={6} className="check-box-whole-area">
                                        <Row className="pb-31 pt-31 vertical-center pl-72">
                                            <div className="checkbox checkbox-success checkbox-md custom-checkbox">
                                                <label class="custom-checkbox">
                                                    <div className="check-box-label">{input.labelName}</div>
                                                    <input type="checkbox"
                                                        onChange={e => {
                                                            this.setState({
                                                                fulfillment: { ...this.state.fulfillment, fieldData: {
                                                                    ...fieldData,
                                                                    [input.labelName]: e.target.checked ? 1 : 0
                                                                } }
                                                            });
                                                        }}
                                                        defaultChecked={ fieldData && parseInt(fieldData[input.labelName]) === 1}
                                                    />
                                                    <span class="check-box"></span>
                                                </label>
                                            </div>
                                        </Row>
                                    </Col>
                                </Row> : input.type.includes("ENUM") ?
                                    <Row className="plr-50 pt-20 m-0">
                                        <Col md={6}>
                                            <FormGroup>
                                                <ControlLabel className="custom-form-label">{input.labelName}</ControlLabel>
                                                <FormControl
                                                    componentClass="select"
                                                    name={input.labelName}
                                                    onChange={(e) =>
                                                        this.setState({
                                                            fulfillment: { ...this.state.fulfillment, fieldData: {
                                                                ...fieldData,
                                                                [input.labelName]: e.target.value
                                                            } }
                                                        })}
                                                >
                                                    <option disabled selected>Select</option>
                                                    {fieldData && input.options.map(option => <option value={option} selected={option === fieldData[input.labelName]}>{option}</option>)}
                                                </FormControl>
                                            </FormGroup>
                                        </Col>
                                    </Row> : null
                    }
                </Fragment>
            );
        });
    }

    isValid = ({ fulfillmentHouseTitle }) => {
        let formErrors = [];
        if (!fulfillmentHouseTitle) {
            formErrors.push("fulfillmentHouseTitle");
        }
        this.setState({
            formErrors
        });
        return formErrors.length === 0;
    }

    handleSubmit = e => {
        e.preventDefault();
        const { fulfillment } = this.state;
        const { fromProduct, refreshFulfillmentList } = this.props;
        console.clear();
        let data = {
            ...fulfillment,
            fieldData: JSON.stringify(fulfillment.fieldData)
        };
        if (this.isValid(fulfillment)) {
            this.setState({
                isLoading: true
            });
            createFulfillmentHouse(data)
                .then((res) => {
                    NotificationEmitter.emit(NOTIFICATION_EVENT_NAME, { type: "success", message: "Fulfillment created successfully" });
                    this.setState({
                        isLoading: false
                    });
                    if (fromProduct) {
                        refreshFulfillmentList();
                    } else {
                        this.props.closeModal(e);
                    }
                })
                .catch((err) => {
                    this.setState({
                        isLoading: false
                    });
                    NotificationEmitter.emit(NOTIFICATION_EVENT_NAME, { type: "error", message: err.response.data.message });
                });
        } else {
            this.refs.title.scrollIntoView({ behavior: "smooth" });
            setTimeout(() => {
                ReactDOM.findDOMNode(this.refs.titleInput).focus();
            }, 1000);
        }
    }

    handleUpdate = (e) => {
        e.preventDefault();
        const { fromProduct, refreshFulfillmentList } = this.props;
        const { fulfillment: { fulfillmentHouseId, returnAddress, fulfillmentVendorId, fieldData, fulfillmentHouseTitle } } = this.state;
        let data = {
            fulfillmentHouseId,
            fulfillmentHouseTitle,
            returnAddress,
            fulfillmentVendorId,
            fieldData: JSON.stringify(fieldData)
        };
        if (this.isValid(this.state.fulfillment)) {
            this.setState({
                isLoading: true
            });
            updateFulfillmentHouse(data)
                .then((res) => {
                    NotificationEmitter.emit(NOTIFICATION_EVENT_NAME, { type: "success", message: "Fulfillment updated successfully" });
                    this.setState({
                        isLoading: false
                    });
                    if (fromProduct) {
                        refreshFulfillmentList();
                    } else {
                        this.props.closeModal(e);
                    }
                })
                .catch((err) => {
                    this.setState({
                        isLoading: false
                    });
                    NotificationEmitter.emit(NOTIFICATION_EVENT_NAME, { type: "error", message: err.response.data.message });
                });
        } else {
            this.refs.title.scrollIntoView({ behavior: "smooth" });
            setTimeout(() => {
                ReactDOM.findDOMNode(this.refs.titleInput).focus();
            }, 1000);
        }
    }

    render() {
        const {
            fulfillmentVendorList,
            inputFields,
            isLoading,
            formErrors,
            fulfillment: {
                fulfillmentHouseTitle,
                returnAddress,
                fulfillmentVendorId,
                fieldData
            }
        } = this.state;
        const { closeFulfillmentModal, fromProduct, fulfillmentDetails } = this.props;
        return (
            <div>
                <Row className="plr-50 pt-20 m-0">
                    { !fulfillmentVendorList &&
                        <CRMLoading title="Fetching Fulfillment Company..."/>
                    }
                    <div ref="title"></div>
                    <Col md={6}>
                        <FormGroup>
                            <ControlLabel className="custom-form-label">Title</ControlLabel>
                            <FormControl
                                type="text"
                                onChange={(e) => this.handleChange(e)}
                                name={"fulfillmentHouseTitle"}
                                defaultValue={fulfillmentHouseTitle}
                                ref="titleInput"
                            />
                            <div className={`error_box ${formErrors.includes("fulfillmentHouseTitle") ? "erractive" : ""}`}>
                                <span>Title is required</span>
                            </div>
                        </FormGroup>
                    </Col>
                </Row>
                {fulfillmentVendorList &&
                    <Row className="plr-50 pt-20 m-0">
                        <Col md={6}>
                            <FormGroup>
                                <ControlLabel className="custom-form-label">Fulfillment Company</ControlLabel>
                                <FormControl
                                    componentClass="select"
                                    className="primary-black hover-primary-black"
                                    name={"fulfillmentVendorId"}
                                    onChange={(e) => {this.handleChange(e); this.handleVendorList(e);}}
                                >
                                    <option disabled selected>Select Vendor</option>
                                    {fulfillmentVendorList.map((vendor, i) =>
                                        <option value={vendor.fulfillmentVendorId} selected={vendor.fulfillmentVendorId === parseInt(this.state.fulfillmentVendorId)}>{vendor.vendorName}</option>
                                    )}
                                </FormControl>
                            </FormGroup>
                        </Col>
                    </Row>
                }

                { fulfillmentVendorList && inputFields && this.renderInputs(inputFields)}

                <Row className="plr-50 pt-20 m-0">
                    <Col md={6}>
                        <FormGroup>
                            <ControlLabel className="custom-form-label">Return Address</ControlLabel>
                            <FormControl
                                type="text"
                                componentClass="textarea"
                                style={{ height: 100 }}
                                onChange={(e) => this.handleChange(e)}
                                name={"returnAddress"}
                                defaultValue={returnAddress}
                            />
                        </FormGroup>
                    </Col>
                </Row>

                <Row className="m-0 pt-20">
                    <Col md={4} mdOffset={8} style={{ textAlign: "right", paddingRight: 30, paddingBottom: 15 }}>
                        <Button
                            className="cancelButton"
                            style={{ marginRight: 20 }}
                            onClick={(e) => fromProduct ? closeFulfillmentModal() : this.props.closeModal(e)}
                        >
                            Cancel
                        </Button>
                        <CRMButton isLoading={isLoading} handleClick={(e) => fulfillmentDetails ? this.handleUpdate(e) : this.handleSubmit(e)} text="Save & Continue"/>
                    </Col>
                </Row>
            </div>
        );
    }
}

export default CreateEditFulfillment;

CreateEditFulfillment.propTypes = {
    closeModal: PropTypes.func,
    fulfillmentDetails: PropTypes.array,
    fromProduct: PropTypes.bool,
    refreshFulfillmentList: PropTypes.func,
    closeFulfillmentModal: PropTypes.func
};
