import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import get from "lodash/get";
import { PageTemplateCard } from "./page-template-card";
import { TemplateActionCard } from "./page-action-template-card";
import { ConfirmationModal } from "../commons/modals/confirmation";
import { AddNewTemplate } from "./add-new-template";
import { PageTemplateSettings } from "./page-template-settings";
import { TemplateTypeSelectDropdown } from "./template-type-select-dropdown";

import * as TemplateSelectors from "../templates/selectors";
import * as TemplateActions from "../templates/actions";
import { PageTypes } from "../constants/template-types";
import { TemplateNotification,TemplateTabs } from "../template";
import { templateTypeMap } from "../constants/template-types";
import { getUserData } from "../login/selectors";
import { constants } from "../settings/constants";
import { NotificationEmitter, NOTIFICATION_EVENT_NAME } from "../commons/notification";
import "./page-templates.css";

class PageTemplates extends React.Component {

    static propTypes = {
        loadTemplates: PropTypes.func.isRequired,
        pageTemplates: PropTypes.array,
        history: PropTypes.object,
        setSelectedPageTemplate: PropTypes.func.isRequired,
        deleteTemplate: PropTypes.func,
        updateTemplate: PropTypes.func,
        userData: PropTypes.object
    };

    constructor(props) {
        super(props);
        this.onDropdownChange = this.onDropdownChange.bind(this);
        this.setActiveTemplate = this.setActiveTemplate.bind(this);
        this.onToolbarClick = this.onToolbarClick.bind(this);
        this.getTemplateType = this.getTemplateType.bind(this);
        this.onDelete = this.onDelete.bind(this);
        this.cancelModal = this.cancelModal.bind(this);
        this.setRightPanel = this.setRightPanel.bind(this);
        this.openAddNewTemplate = this.openAddNewTemplate.bind(this);
        this.closeRightPanel = this.closeRightPanel.bind(this);
        this.navigateToWebBuilder = this.navigateToWebBuilder.bind(this);
        this.changeTemplateType = this.changeTemplateType.bind(this);
        this.onUpdateTemplate = this.onUpdateTemplate.bind(this);
        this.getFilteredPageTemplates = this.getFilteredPageTemplates.bind(this);
        this.handleOutsideClick = this.handleOutsideClick.bind(this);
        this.templateTypeMap = PageTypes.pageTypesMap;
        this.state = {
            activeTemplate: null,
            showDeleteModal: false,
            rightPanelSection: "",
            filteredPageTemplates: [],
            isLoading: false,
            notification: {
                show: false,
                type: null,
                message: null,
            },
        };
        this.selectedTemplate = {};
    }

    async componentDidMount() {
        await PageTypes.getAllPageTypes()
            .then((pageTypes) => this.templateTypeMap = pageTypes);
        const {
            loadTemplates,
        } = this.props;
        try {
            // eslint-disable-next-line react/no-did-mount-set-state
            this.setState({
                isLoading: true
            });
            await loadTemplates();
            // eslint-disable-next-line react/no-did-mount-set-state
            this.setState({ isLoading: false });
        } catch (err) {
            // eslint-disable-next-line react/no-did-mount-set-state
            this.setState({
                loadTemplatesFailure: true,
                isLoading: false,
            });
        }

    }
    getFilteredPageTemplates() {
        if (this.templateTypeMap) {
            const {
                pageTemplates,
            } = this.props;
            let {
                selectedTemplateType,
            } = this.state;
            if (!selectedTemplateType) {
                selectedTemplateType = this.templateTypeMap[0];
            }
            const pageTemplatesWithoutGlobal = pageTemplates.filter((template) => {
                return (template.templateTypeId !== constants.GlobalBlankTemplateTypeId);
            });
            if (selectedTemplateType.templateTypeId === 0) {
                return pageTemplatesWithoutGlobal;
            }
            return pageTemplatesWithoutGlobal.filter((template) => {
                return (template.templateTypeId === selectedTemplateType.templateTypeId);
            });
        }
    }

    onDropdownChange(selectedTemplateType) {
        this.setState({ selectedTemplateType });
    }

    setActiveTemplate(activeTemplate) {
        this.setState({
            activeTemplate,
        });
    }

    checkIfSelectedTemplate(templateId) {
        const {
            activeTemplate,
        } = this.state;
        return activeTemplate && (templateId === activeTemplate.templateId);
    }

    getTemplateType(templateTypeId) {
        return this.templateTypeMap.find(templateType => templateType.templateTypeId === templateTypeId);
    }

    onToolbarClick(type, template) {
        if (type === "settings") {
            this.selectedTemplate = template;
            this.setRightPanel("edit");
        } else if (type === "edit") {
            this.navigateToWebBuilder(template);
        } else if (type === "delete") {
            this.setState({ showDeleteModal: true });
        }
    }

    navigateToWebBuilder(template) {
        const {
            history,
            setSelectedPageTemplate,
        } = this.props;
        setSelectedPageTemplate(template);
        history.push("/webpage-creator");
    }

    cancelModal() {
        this.setState({ showDeleteModal: false });
    }
    handleOutsideClick(e) {
        let addrightPanel = document.getElementById("addrightPanel");
        let editrightPanel = document.getElementById("editrightPanel");
        if ((addrightPanel !== null && addrightPanel.contains(e.target)) || (editrightPanel !== null && editrightPanel.contains(e.target))) {
            return;
        }
        if (this.state.rightPanelSection && (e.target.id !== "addrightPanel" || e.target.id !== "editrightPanel")) {
            this.closeRightPanel();
        }
    }

    onDelete(template) {
        if (template) {
            const { referenceId } = template;
            const {
                loadTemplates,
            } = this.props;
            const {
                deleteTemplate,
            } = this.props;
            deleteTemplate(referenceId)
                .then(() => {
                    loadTemplates();
                    this.cancelModal();
                    NotificationEmitter.emit(NOTIFICATION_EVENT_NAME, { type: "success", message: "Template has been deleted successfully." });
                })
                .catch(() => {
                    this.cancelModal();
                    NotificationEmitter.emit(NOTIFICATION_EVENT_NAME, { type: "error", message: "There was a problem in deleting the template." });
                });
        }
    }

    openAddNewTemplate() {
        this.setRightPanel("new");
        this.selectedTemplate = {
            active: true,
            referenceId: "",
            title: ""
        };
    }

    closeRightPanel() {
        this.setRightPanel("");
        document.removeEventListener("click", this.handleOutsideClick, false);
    }

    setRightPanel(status) {
        this.setState({ rightPanelSection: status });
        document.addEventListener("click", this.handleOutsideClick, false);
    }

    changeTemplateType(key) {
        // TODO - chenge container based template type
        if (this.state.rightPanelSection) {
            this.setRightPanel("");
        }
    }

    onUpdateTemplate(status) {
        const {
            loadTemplates,
            updateTemplate
        } = this.props;
        updateTemplate(status)
            .then(() => {
                this.setRightPanel("");
                loadTemplates();
                NotificationEmitter.emit(NOTIFICATION_EVENT_NAME, { type: "success", message: "Template has been updated successfully." });
            })
            .catch(() => {
                NotificationEmitter.emit(NOTIFICATION_EVENT_NAME, { type: "error", message: "There was a problem in updating the template." });
            });
    }

    render() {
        const {
            showDeleteModal,
            rightPanelSection,
            activeTemplate,
            selectedTemplateType,
            isLoading,
            loadTemplatesFailure,
        } = this.state;
        const {
            pageTemplates,
            userData
        } = this.props;
        const filteredPageTemplates = this.getFilteredPageTemplates();
        const hasPageTypes = get(this, ["templateTypeMap", 0]);
        const showTemplateCard = filteredPageTemplates && (filteredPageTemplates.length > 0);
        return (
            <>
                <div className="row wrapper border-bottom white-bg page-heading p-h-sm">
                    <div className="col-lg-9">
                        <h2>Templates</h2>
                        <ol className="breadcrumb">
                            <li><a href="/dashboard">Home</a></li>
                            <li className="active"><a>Page Templates</a></li>
                        </ol>
                    </div>
                </div>
                {
                    hasPageTypes && (
                        <div className="page-templates-wrapper row wrapper wrapper-content">
                            <div className="row white-bg p-xs">
                                <TemplateTabs history={this.props.history}>
                                    <div className="container">
                                        <div className="m-t-md row">
                                            <div className="col-xs-12 text-right m-b-md ">
                                                <div className="col-xs-6"></div>
                                                <div className="col-xs-6 text-right">
                                                    <TemplateTypeSelectDropdown
                                                        className="inline m-r-md"
                                                        label="Filter by Page Type:"
                                                        hasAllOption
                                                        onDropdownChange={this.onDropdownChange}
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-xs-12 page-template-card-wrapper">
                                                {
                                                    <TemplateActionCard
                                                        text="Add new template"
                                                        onSelect={this.openAddNewTemplate}
                                                    />
                                                }
                                                {
                                                    showTemplateCard && filteredPageTemplates.map((template) => (
                                                        <PageTemplateCard
                                                            key={`${template.templateId}`}
                                                            template={template}
                                                            templateType={this.getTemplateType(template.templateTypeId)}
                                                            onTemplateSelected={this.setActiveTemplate}
                                                            onToolbarClick={this.onToolbarClick}
                                                            isSelected={this.checkIfSelectedTemplate(template.templateId)}
                                                            userData={userData}
                                                        />
                                                    ))
                                                }
                                                {
                                                    loadTemplatesFailure && !isLoading && (
                                                        <div className="col-xs-12 alert alert-danger">
                                                            Sorry there is some problem loading page templates.
                                                        </div>
                                                    )
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </TemplateTabs>
                            </div>
                            {
                                showDeleteModal && (
                                    <ConfirmationModal
                                        header="CONFIRMATION"
                                        title="Do you want to delete the template?"
                                        item={activeTemplate}
                                        buttonOkText="Yes"
                                        onSubmit={this.onDelete}
                                        onCancel={this.cancelModal}
                                    />
                                )
                            }
                            {
                                rightPanelSection === "new" && (<AddNewTemplate
                                    closeAddNewTemplate={this.closeRightPanel}
                                    templates={pageTemplates}
                                    onNewTemplateSelected={this.navigateToWebBuilder}
                                    template={this.selectedTemplate}
                                />)
                            }
                            {
                                rightPanelSection === "edit" && (<PageTemplateSettings
                                    closeAddNewTemplate={this.closeRightPanel}
                                    template={this.selectedTemplate}
                                    updateStatus={this.onUpdateTemplate}
                                />)
                            }
                        </div>
                    )
                }
            </>
        );
    }
}

const mapStateToProps = (state) => ({
    pageTemplates: TemplateSelectors.getTemplates(state),
    userData: getUserData(state)
});

const mapDispatchToProps = (dispatch) => ({
    loadTemplates: () => dispatch(TemplateActions.getTemplates({ includeAllResults: true })), // Passing true will fetch results with active flag also
    setSelectedPageTemplate: (template) => dispatch(TemplateActions.setSelectedPageTemplate(template)),
    deleteTemplate: (templateReferenceId) => dispatch(TemplateActions.deleteTemplate(templateReferenceId)),
    updateTemplate: (status) => dispatch(TemplateActions.updateTemplate(status)),
});

export default connect(mapStateToProps, mapDispatchToProps)(PageTemplates);