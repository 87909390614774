import { NavigationElementType } from "../constants/funnel-constants";

const generateWebBuilderElement = (type, elementData = {}) => {
    const elementModel = {
        "elementId": elementData["data-id"],
        "elementType": type,
        "elementName": elementData.name,
        "elementTitle": elementData.title || "",
        "href": elementData.target,
        "elementStatus": elementData.status,
        "products": elementData.products || [],
    };
    return elementModel;
};

const generateWebBuilderButton = (buttonData) => generateWebBuilderElement(NavigationElementType.button, buttonData);
const generateWebBuilderLink = (linkData) => generateWebBuilderElement(NavigationElementType.link, linkData);

export function generateWebBuilderElements(buttonMetaData) {
    const {
        buttons,
        links,
    } = buttonMetaData;
    const webBuilderButtons = buttons.map(generateWebBuilderButton);
    const webBuilderLinks = links.map(generateWebBuilderLink);
    return [
        ...webBuilderButtons,
        ...webBuilderLinks,
    ];
}
