import React, { Component } from "react";
import PropTypes from "prop-types";
import { TemplateCard } from "./template-card";

export class Templates extends Component {
    static propTypes = {
        onTemplateSelected: PropTypes.func
    };

    constructor(props) {
        super(props);
        this.state = {
            search: "",
            field: ""
        };
        this.filteredList = this.filteredList.bind(this);
    }
    filteredList(event) {
        this.setState({ search: event.target.value, field: event.target.name });
    }

    render() {
        const templates = this.props.templates.filter(
            (template) => {
                if (String(template.templateTypeName).toLowerCase().replace(/ /g,"") === String(this.state.search).toLowerCase().replace(/ /g,"") || String(this.state.search).toLowerCase() === "") {
                    return true;
                } else {
                    return false;
                }
            }
        );
        const templateList = templates ? templates.map((template) => <TemplateCard oldfunnel={this.props.oldfunnel} key={template.templateId} template={template} onTemplateSelected={this.props.onTemplateSelected}/>)
            : [];
        return (
            <>
                <div className="row">
                    <div className="col-xs-12">
                        <div className="input-group">
                            <div className="input-group-addon">Page Type</div>
                            <select onChange={this.filteredList} className="form-control m-b" name="pageType">
                                <option value="">--Please Select Page Type--</option>
                                <option value="checkoutPage">Checkout Page</option>
                                <option value="leadPage">Lead Page</option>
                                <option value="upsellPage">Upsell Page</option>
                                <option value="thankYouPage">Thank You/ Confirmation Page</option>
                                <option value="presellPage">Presell Page</option>
                            </select>
                        </div>
                    </div>
                </div>
                <div className="template-container m-t-md row">
                    <div className="col-xs-12 p-n">
                        {templateList}
                    </div>
                </div>
            </>

        );
    }
}
Templates.propTypes = {
    templates: PropTypes.array.isRequired,
    getTemplates: PropTypes.func,
    actions: PropTypes.object.isRequired,
    oldfunnel: PropTypes.string,
};