import React, { createContext } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import isEmpty from "lodash/isEmpty";
import floor from "lodash/floor";

import ProgressBar from "../../commons/progress-bar";

import {
    loadGoogleFonts,
} from "../../core/assets/actions";
import {
    getGoogleFonts,
} from "../../core/assets/selectors";
import FunnelTemplateVisualizerHeader from "./funnel-template-visualizer-header";
import {
    getFunnelTemplate,
    updateFunnelTemplate,
} from "./actions/funnelTemplateActions";
import {
    getThumbnailForFunnel
} from "../../visualizer/actions/funnel-template-actions";

import { PageTypes } from "../../constants/template-types";
import ChangeSetApi from "../../api/funnelChangeSetApi";
import { getTemplates } from "../../templates/actions";
import { changeDefaultPageStatusApi } from "../../funnel-templates/actions";
import {
    DEBUG,
    extractFunnelData,
    getDefaultPage
} from "./utilities/helpers";
import {
    triggerNofiticationError,
} from "../../commons/notification";
import { ConfirmationModal } from "../../commons/modals/confirmation";
import VisualizerEmitter from "./visualizer-emitter";
import { saveFontsApi } from "./actions/visualizerActions";

export const FunnelTemplateVisualizerContext = createContext();

class FunnelTemplateVisualizer extends React.Component {

    constructor() {
        super();
        this.trackApiProgess = this.trackApiProgess.bind(this);
        this.updateFunnel = this.updateFunnel.bind(this);
        this.showChangeSetApiError = this.showChangeSetApiError.bind(this);
        this.getFunnelThumbnail = this.getFunnelThumbnail.bind(this);
        this.changeDefaultPageStatus = this.changeDefaultPageStatus.bind(this);
        this.saveUpdatedPageName = this.saveUpdatedPageName.bind(this);
        this.saveFonts = this.saveFonts.bind(this);
    }

    state = {
        isLoading: true,
        progress: 0,
        changeSetApi: null,
    };

    async componentDidMount() {
        const {
            funnelReferenceId,
            loadTemplates,
            getGoogleFonts,
            googleFonts,
        } = this.props;
        await this.trackApiProgess([
            getFunnelTemplate(funnelReferenceId),
            PageTypes.getAllPageTypes(true),
            loadTemplates(),
            !isEmpty(googleFonts) ? Promise.resolve(googleFonts) : getGoogleFonts(),
        ], (p) => {
            this.setState({ progress: p });
        });
        this.getFunnelThumbnail().catch(() => {});
    }

    componentWillUnmount() {
        this.getFunnelThumbnail().catch(() => {});
    }

    updateFunnel(funnelData) {
        return this.props.updateFunnelTemplate({
            funnel: funnelData
        })
            .then(() => {
                this.setState({
                    funnelData,
                    funnelHelpers: this.setUpFunnelHelpers(funnelData),
                });
            })
            .catch(err => {
                triggerNofiticationError(err.message);
            });
    }

    getFunnelThumbnail = () => {
        const {
            funnelReferenceId,
            getFunnelThumbnailApi
        } = this.props;
        return getFunnelThumbnailApi(funnelReferenceId);
    }

    saveUpdatedPageName(params) {
        const {
            currentPageTitle,
            currentPage
        } = params;
        VisualizerEmitter.emit("PAGE:CHANGENAME",currentPage,currentPageTitle);

    }
    saveFonts = (params) => {
        const {
            pageReferenceId,
            fontUrlArray
        } = params;
        const {
            funnelReferenceId,
            saveFontsApi
        } = this.props;
        const parameters = {
            pageReferenceId,
            fontUrlArray,
            funnelReferenceId
        };
        return saveFontsApi(parameters);
    };

    changeDefaultPageStatus = (params) => {
        const {
            pageViewReferenceId,
            currentPage
        } = params;
        const {
            funnelReferenceId,
            changeDefaultPageStatusApi
        } = this.props;
        return changeDefaultPageStatusApi({ pageViewReferenceId, funnelReferenceId })
            .then(() => {
                const currentDefaultPage = currentPage.get("id");
                this.setState({
                    defaultPage: currentDefaultPage
                });
            }
            );
    };

    trackApiProgess(proms, progress_cb) {
        let d = 0;
        progress_cb(0);
        for (const p of proms) {
            p.then(() => {
                d ++;
                progress_cb(floor((d * 100) / proms.length));
            });
        }
        return Promise.all(proms)
            .then(([funnelData, pageTypes, freeTemplates, googleFontsList]) => {
                // eslint-disable-next-line no-empty
                if (DEBUG) {
                }
                const changeSetApi = new ChangeSetApi(
                    funnelData.changesetId,
                    funnelData.referenceId,
                    "funnelTemplate",
                    this.showChangeSetApiError,
                );
                this.setState({
                    isLoading: false,
                    funnelData,
                    funnelHelpers: this.setUpFunnelHelpers(funnelData),
                    pageTypes,
                    freeTemplates,
                    googleFontsList,
                    changeSetApi,
                    updateFunnelInfo: this.updateFunnel,
                    getFunnelThumbnail: this.getFunnelThumbnail,
                    changeDefaultPageStatus: this.changeDefaultPageStatus,
                    saveUpdatedPageName: this.saveUpdatedPageName,
                    saveFonts: this.saveFonts,
                    defaultPage: getDefaultPage(funnelData),
                });
            })
            .catch(() => {
                this.setState({ isLoading: false });
            });
    }

    showChangeSetApiError(error) {
        this.setState({
            changeSetApiError: error,
        });
    }

    setUpFunnelHelpers(funnelData) {
        const getFunnelValueOf = extractFunnelData(funnelData);
        return {
            getFunnelValueOf,
            funnelZoomValues: function () {
                const visualizerZoom = getFunnelValueOf("visualizerJson");
                return visualizerZoom ? JSON.parse(visualizerZoom) : { zoomValue: 1 };
            }(),
        };
    }

    render() {
        const {
            isLoading,
            progress,
            changeSetApiError,
        } = this.state;
        if (isLoading) {
            return (
                <ProgressBar
                    progress={progress}
                />
            );
        } else {
            return (
                <>
                    {
                        this.props.children(this.state, FunnelTemplateVisualizerHeader)
                    }
                    {
                        changeSetApiError && (
                            <ConfirmationModal
                                onSubmit={() => this.showChangeSetApiError(false)}
                                title="Something went wrong. Please try again later"
                                header="Error"
                            />
                        )
                    }
                </>
            );
        }
    }
}

FunnelTemplateVisualizer.propTypes = {
    campaigns: PropTypes.object,
    funnelReferenceId: PropTypes.string,
    loadTemplates: PropTypes.func,
    children: PropTypes.element,
    getGoogleFonts: PropTypes.func,
    googleFonts: PropTypes.object,
    updateFunnelTemplate: PropTypes.func,
    addAndSetDomainForFunnelApi: PropTypes.func,
    getFunnelThumbnailApi: PropTypes.func,
    changeDefaultPageStatusApi: PropTypes.func,
    saveFontsApi: PropTypes.func,
};

const mapDispatchToProps = (dispatch) => ({
    loadTemplates: (templateType) => dispatch(getTemplates({ templateType })),
    getGoogleFonts: () => dispatch(loadGoogleFonts()),
    updateFunnelTemplate: (funnel) => dispatch(updateFunnelTemplate(funnel)),
    getFunnelThumbnailApi: (funnelReferenceId) => dispatch(getThumbnailForFunnel(funnelReferenceId)),
    changeDefaultPageStatusApi: (params) => changeDefaultPageStatusApi(params),
    saveFontsApi: (params) => saveFontsApi(params),
});


const mapStateToProps = (state) => {
    return {
        templates: state.TEMPLATES.templates,
        campaignsFromStore: state.CAMPAIGNS,
        googleFonts: getGoogleFonts(state),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(FunnelTemplateVisualizer);