import { providerUrl, splitViewUrl, rootUrl } from "../../api/urls";
export class JavascriptTemplate {
    static getFileContent() {
        return `
var isVisited = true;
var isVisitedOnScroll = true;
var isVisitedOnLoad  = true;
var isStickyBar = true;
var isVisitedStickyOnLoad = true;
let products = [];
let orderId;
let result = true;
//let productId;
let cartArray = [];
var price = 0;



                
                
           
function httpMethod(url, data, headerParams) {
        let response;
        var loading = document.getElementById("loading");
        loading ? loading.style.display = "block !important" : null;
        return fetch(url, {
            //     async: false,
        method: "POST",
        mode: "cors",
        headers: headerParams ? headerParams : {
            "Content-Type": "application/json; charset=utf-8",
            "Cache-Control": "no-cache",
            "Access-Control-Allow-Origin": "*",
            companyToken: pageData.salt
        },
          body: data
        }).then(function(response) {              
                return response.json();

        })
        return;
    }


//TODO write test data function
function getIpAddress() {
    let response;
    $.ajax({
        async: false,
        type: "GET",
        // cors: true,
        // headers: {
        //     "Cache-Control": "no-cache",
        //     "Access-Control-Allow-Origin": "*"
        // },
        url: "https://api.ipify.org",
        success: function (result) {
            response = result;
        },
        error: function (xhr) {
            return xhr.status;
        }
    });
    return response;

}

function getPageType(templateTypeId) {
    let pageType = "";
    switch (templateTypeId) {
        // Lead page
        case 2:
            pageType = "leadPage";
            break;
        // Checkout Page
        case 4:
            pageType = "checkoutPage";
            break;
        // Upsell Page
        case 3:
            pageType = "upsellPage1";
            break;
        // Thank You Page
        case 5:
            pageType = "thankyouPage";
            break;
        default:
            pageType = "presellPage";    
    }
    return pageType;
}

function importClick(pageType) {
    let obj = {};
    obj.pageType = typeof pageType !== "string" ? getPageType(pageType) : pageType;
    obj.ipAddress = getIpAddress();
    obj.userAgent = navigator.userAgent;
    obj.campaignId = parseInt(pageData.funnelData.campaign);
    obj.requestUri = window.location.href;
    let data = JSON.stringify(obj);

    let response = httpMethod("${providerUrl}/ImportClick/CreateSession", data);
    if(obj.pageType === 5 || obj.pageType === "thankyouPage"){
        confirmation();
    }
    return response;
}

function useSession(session, pageType) {
    let obj = {};
    
    obj.pageType = typeof pageType !== "string" ? getPageType(pageType) : pageType;
    obj.sessionId = session;
    obj.userAgent = navigator.userAgent;
   
    let data = JSON.stringify(obj);
    
    let response = httpMethod("${providerUrl}/ImportClick/UseSession", data);
    if(obj.pageType === 5 || obj.pageType === "thankyouPage"){
        confirmation();
    }
    
    return response;
}
//memberships
function clubQuery(clubId, memberId, productData, route) {
    let obj = {};
    // obj.clubId = clubId
    obj.memberId = memberId;
    let data = JSON.stringify(obj);
    let loginUrl = window.location.href;
    sessionStorage.setItem("loginUrl", loginUrl);
    let accessToken = localStorage.getItem("access_token");
    let tokenType = localStorage.getItem("token_type");
    let headers = {           
        "Content-Type": "application/json; charset=utf-8",
        "Cache-Control": "no-cache",
        "Access-Control-Allow-Origin": "*",
        "CompanyToken": pageData.salt,
        "Authorization": tokenType + " " + accessToken
    };
    let result;
    
    let response = httpMethod("${providerUrl}/ClubMembership/Query", data ,headers).then(function (data) {
        if(data.result === "SUCCESS") {
           
            let jsonStr = JSON.stringify(data);
            sessionStorage.setItem("membership", jsonStr);
            result = data.message;
            
            if(result && result.data[0].status === "ACTIVE") {
            
                let currentProduct;
                const filteredData = productData.filter(products => products.find(product => {
                    if((product.productId === result.data[0].productId) && (product.status === "ACTIVE")) {
                        return product;
                    }
                }));
                if(filteredData) {
                    currentProduct = filteredData[filteredData.length - 1].find(prod => prod.productId === result.data[0].productId);
                    let targetPage = currentProduct && currentProduct.targetPageViewReferenceId;
                    
                    route = targetPage + ".html?"+Date.now();
                    window.location.href = route;
                } else {
                    alert("There are not products listed on route. Please Contact Customer Service.")
                }
                
            } else  {
            window.location.href = route;
            }
        } 
    });
}

function validateSession() {
    let expireIn = localStorage.getItem("expireIn");
    let token_type = localStorage.getItem("token_type");
    let access_token = localStorage.getItem("access_token");
    let loginPage = localStorage.getItem("loginUrl");
    let url = window.location.href;
    let clubId = sessionStorage.getItem("clubId");
    clubId = parseInt(clubId);
    let pageClubId = "";
   try {
        pageData.selectedCampaign && pageData.selectedCampaign.products && pageData.selectedCampaign.products.forEach(function (product) {
            if (product.clubPluginId) {
                clubId = product.clubPluginId;
                throw BreakException;
            }
        })
    } catch (e) {
        if (e !== BreakException) throw e;
    }
    if(!url.includes("funnels-build") && !url.includes("funneltemplates")) {
        if(!expireIn || !token_type || !access_token) {
                if(clubId !== pageClubId) {
                    if(loginPage)
                        location.href = loginPage;
                    else {
                        let login = pageData.funnelData.pages.find(page => page.title === "Login");            
                        loginPage = login.pageView[0].referenceId;
                       
                        window.location.href = loginPage + ".html?"+Date.now();
                    }
                }
            } else  {
                return;
        }
    }
    
}

function clubLogout() {
    let loginUrl = sessionStorage.getItem("loginUrl");
    
    window.location.href = loginUrl; //login page
    sessionStorage.clear();
    localStorage.clear();
}

function clubLogin(buttonId, clubId, productData) {
     let obj = {};
     obj.clubUsername = document.getElementsByName("userName")[0] ? document.getElementsByName("userName")[0].value : null;
     obj.clubPassword = document.getElementsByName("password")[0] ? document.getElementsByName("password")[0].value : null;
     obj.clubId = clubId;
     
     
     let data = JSON.stringify(obj);
     
     let accessToken = localStorage.getItem("access_token");
    let tokenType = localStorage.getItem("token_type");
    let headers = {           
        "Content-Type": "application/json; charset=utf-8",
        "Cache-Control": "no-cache",
        "Access-Control-Allow-Origin": "*",
        "CompanyToken": pageData.salt,
        "Authorization": tokenType + " " + accessToken
    };
     
     let response = httpMethod("${providerUrl}/ClubMembership/Login/", data).then(function (data) {
            let count = sessionStorage.getItem("count");
            
            if(count === null || count === undefined) {
            
            count = 0;
            sessionStorage.setItem("count", count);
            } else if (performance.navigation.type == 1) {
                count = sessionStorage.getItem("count");
            }
        if(data.result === "SUCCESS") {
            let route = null;
            count = 0;
            
            sessionStorage.setItem("count", count);
            
            
            
            const results = data.message;
            sessionStorage.setItem("count", count);
            sessionStorage.setItem("username", obj.clubUsername);
            sessionStorage.setItem("clubId", clubId);
            sessionStorage.setItem("memberId", results.memberId);
            localStorage.setItem("expireIn", results.expires_in);
           

            localStorage.setItem("token_type", "MembershipBearer");
            localStorage.setItem("access_token", results.access_token);


            if(data.message.status === "ACTIVE") {
                
                clubQuery(obj.clubId, results.memberId, productData)
            } else {
                let currentProduct = productData.find(products => products.find(product => product.status !== "ACTIVE"));
                let targetPage;
                if(currentProduct) {
                    targetPage = currentProduct && currentProduct.find(product => product.status !== "ACTIVE").targetPageViewReferenceId;
                    route = targetPage + ".html?"+Date.now();
                }
                clubQuery(obj.clubId, results.memberId, productData, route);
            }
        
        } else {
            alert("Username and Password is invalid");
            count++;
            sessionStorage.setItem("count", count);
            
            if(count >= 3) {
            document.getElementsByName("userName")[0].disabled = true;
            document.getElementsByName("password")[0].disabled = true;
            document.getElementsByTagName("button")[0].disabled = true
            alert("Too many failed attempts, Account will be disabled for 3 minutes");
            setTimeout(function() {
                document.getElementsByName("userName")[0].disabled = false;
                document.getElementsByName("password")[0].disabled = false;
                document.getElementsByTagName("button")[0].disabled = false;
                count = 0;
            
                sessionStorage.setItem("count", count);
                
            }, 180000)
            }
        }
     });
}
function restartMembership(route, clubId) {
    let membership = JSON.parse(sessionStorage.getItem("membership"));
    let obj = {};
    obj.purchaseId = membership.message.data[0].purchaseId;
    obj.reactivate = true;
    
    let data = JSON.stringify(obj);
    
    let accessToken = localStorage.getItem("access_token");
    let tokenType = localStorage.getItem("token_type");
    let headers = {           
        "Content-Type": "application/json; charset=utf-8",
        "Cache-Control": "no-cache",
        "Access-Control-Allow-Origin": "*",
        "CompanyToken": pageData.salt,
        "Authorization": tokenType + " " + accessToken
    };
    
    let response = httpMethod("${providerUrl}/Purchases/Update", data, headers).then(function(data) {
        if(data.result === "SUCCESS") {
            registerClick(1);
            membership.message.data[0].status = 'ACTIVE';
            sessionStorage.setItem("membership",JSON.stringify(membership));
            alert("Membership Successfully restarted");
            location.href = route;
            
        }
        else {
            alert("There was an error restarting your membership, please contact customer service.");
        }
    })
}
function resetPW(route, clubId, memberId) {
    let obj = {};
    obj.clubId = clubId;
    obj.clubUsername = document.getElementsByName("userName")[0] ? document.getElementsByName("userName")[0].value : null;
    obj.clubPassword = document.getElementsByName("password")[0] ? document.getElementsByName("password")[0].value : null;
    if(obj.clubPassword !== document.getElementsByName("confirmPassword")[0]) {
        alert("Passwords do not match");
    }
    
    let data = JSON.stringify(obj)
    let response = httpMethod("${providerUrl}/ClubMembership/Reset", data).then(function(data) {
        if(data.result === "SUCCESS") {
           alert("Password has successfully been changed");
        }
    })
}
function cancelMembership(route, clubId, memberId) {

    let obj = {};

    let membership = JSON.parse(sessionStorage.getItem("membership"));
    
    obj.purchaseId = membership.message.data[0].purchaseId;

    let data = JSON.stringify(obj)
    
    let accessToken = localStorage.getItem("access_token");
    let tokenType = localStorage.getItem("token_type");
    let headers = {           
        "Content-Type": "application/json; charset=utf-8",
        "Cache-Control": "no-cache",
        "Access-Control-Allow-Origin": "*",
        "CompanyToken": pageData.salt,
        "Authorization": tokenType + " " + accessToken
    };
    
    let response = httpMethod("${providerUrl}/Purchases/Cancel", data, headers).then(function(data) {
        if(data.result === "SUCCESS") {
          registerClick(1);
          membership.message.data[0].status = 'CANCELLED';
          sessionStorage.setItem("membership",JSON.stringify(membership));
          alert("Membership has successfully been cancelled");
          window.location = route;
        }
    })
}
function changeProduct(route, clubId, memberId) {
    let product_value;
   let newProduct = document.getElementsByName("product");
   newProduct.forEach(function(product) {
    if(product.checked) {
        product_value = product.value;
    }
   })
   let memberData = JSON.parse(sessionStorage.getItem("membership"));   
   let purchaseId = memberData.message.data[0].purchaseId;
   memberData.message.data[0].productId = product_value;
   
   let obj = {};
   obj.productId = product_value;
   obj.purchaseId = purchaseId;
   
   let data = JSON.stringify(obj);
   
   let accessToken = localStorage.getItem("access_token");
    let tokenType = localStorage.getItem("token_type");
    let headers = {           
        "Content-Type": "application/json; charset=utf-8",
        "Cache-Control": "no-cache",
        "Access-Control-Allow-Origin": "*",
        "CompanyToken": pageData.salt,
        "Authorization": tokenType + " " + accessToken
    };
    
   let response = httpMethod("${providerUrl}/Purchases/Update", data, headers).then(function(data) {
        if(data.response === "SUCCESS") {
          registerClick(1);
        
          sessionStorage.setItem("membership", memberData);
          alert("Your Product has been successfully updated.");
          window.location.href = route;
        } else {
            alert("There was an error processing your request, if problems persist please contact customer service");
        }
    })
    
}
function updateMember(route, clubId, productData) {
     let obj = {};
    obj.clubId = clubId;
    obj.memberId = sessionStorage.getItem("memberId");

    if(document.getElementsByName("newPassword")[0].value !== document.getElementsByName("confirmPassword")[0].value) {
        $("#validationMessage").length ? $("#validationMessage").show() : alert("Passwords do not match");
    } else  {
        $("#validationMessage").hide();
        obj.clubPassword = document.getElementsByName("newPassword")[0] ? document.getElementsByName("newPassword")[0].value :null;
        obj.address1 = document.getElementsByName("address1")[0] ? document.getElementsByName("address1")[0].value :null;
        obj.address2 = document.getElementsByName("address2")[0] ? document.getElementsByName("address2")[0].value : null;
        obj.postalCode = document.getElementsByName("postalCode")[0] ? document.getElementsByName("postalCode")[0].value : null;
        obj.city = document.getElementsByName("city")[0] ? document.getElementsByName("city")[0].value : null;
        obj.state = document.getElementsByName("state")[0] ? document.getElementsByName("state")[0].value : null;
        obj.country = document.getElementsByName("country")[0] ? document.getElementsByName("country")[0].value : "US";
        obj.emailAddress = document.getElementsByName("emailAddress")[0] ?  document.getElementsByName("emailAddress")[0].value :  null;
        obj.phoneNumber = document.getElementsByName("phoneNumber")[0] ? document.getElementsByName("phoneNumber")[0].value : null;

        let data = JSON.stringify(obj)

        let accessToken = localStorage.getItem("access_token");
        let tokenType = localStorage.getItem("token_type");
        let headers = {           
            "Content-Type": "application/json; charset=utf-8",
            "Cache-Control": "no-cache",
            "Access-Control-Allow-Origin": "*",
            "CompanyToken": pageData.salt,
            "Authorization": tokenType + " " + accessToken
        };
        let response = httpMethod("${providerUrl}/ClubMembership/Update", data, headers).then(function(data) {
            if(data.result === "SUCCESS") {
                registerClick(1);
                alert("Your Profile has been successfully updated");
                location.href= route;
            } else {
                alert("error " + data.message);
            }
        })
    }
}

function importLead(buttonId, route) {
        let obj = {};
        obj.firstName = document.getElementsByName("firstName")[0] ? document.getElementsByName("firstName")[0].value : null;
        obj.lastName = document.getElementsByName("lastName")[0] ? document.getElementsByName("lastName")[0].value : null;
        obj.companyName =  document.getElementsByName("companyName")[0] ? document.getElementsByName("companyName")[0].value : null;
        obj.address1 = document.getElementsByName("address1")[0] ? document.getElementsByName("address1")[0].value :null;
        obj.address2 = document.getElementsByName("address2")[0] ? document.getElementsByName("address2")[0].value : null;
        obj.postalCode = document.getElementsByName("postalCode")[0] ? document.getElementsByName("postalCode")[0].value : null;
        obj.city = document.getElementsByName("city")[0] ? document.getElementsByName("city")[0].value : null;
        obj.state = document.getElementsByName("state")[0] ? document.getElementsByName("state")[0].value : null;
        obj.country = document.getElementsByName("country")[0] ? document.getElementsByName("country")[0].value : "US";
        obj.emailAddress = document.getElementsByName("emailAddress")[0] ?  document.getElementsByName("emailAddress")[0].value :  null;
        obj.phoneNumber = document.getElementsByName("phoneNumber")[0] ? document.getElementsByName("phoneNumber")[0].value : null;
        obj.gender = document.getElementsByName("gender")[0] ? document.getElementsByName("gender")[0].value : null; 
        obj.dob = document.getElementsByName("dob")[0] ? document.getElementsByName("dob")[0].value : null;
        obj.custom1 = document.getElementsByName("custom1")[0] ? document.getElementsByName("custom1")[0].value : null;
        obj.custom2 = document.getElementsByName("custom2")[0] ? document.getElementsByName("custom2")[0].value : null;
        obj.custom3 = document.getElementsByName("custom3")[0] ? document.getElementsByName("custom3")[0].value : null;
        obj.custom4 = document.getElementsByName("custom4")[0] ? document.getElementsByName("custom4")[0].value : null;
        obj.custom5 = document.getElementsByName("custom5")[0] ? document.getElementsByName("custom5")[0].value : null;
        
        var inputs =$("input");
    
        
        inputs.each(function(index, input) {
        if(input.name.indexOf("custom_") >= 0 ) {
            obj[input.name] = input.value
        }
        })
        
        obj.billShipSame = document.getElementsByName("billShipSame")[0] && document.getElementsByName("billShipSame")[0].checked ? 1 : 0;
        obj.campaignId = parseInt(pageData.funnelData.campaign);
        fetchLeadDetails(obj);
        let data = JSON.stringify(obj);
        sessionStorage.setItem("userDetails",data);
        let response = httpMethod("${providerUrl}/Import/Lead", data).then(function (data) {
            if(data.result === "SUCCESS") {
                registerClick(1);
                //setting orderId to be used globally
                orderId = data.message.orderId;
                let jsonStr = JSON.stringify(orderId);
                sessionStorage.setItem("orderId", jsonStr);
                window.location.href = route;
            } else {
                let tag = document.getElementById("error-" + buttonId);

                if (tag === null) {
                    tag = document.createElement("p");
                    tag.setAttribute("id", "error-" + buttonId);
                } else {
                    tag.innerHTML = '';
                }
                tag.innerHTML = data.message;

                const color = tag.style.color = "red";
                const form = $("button[data-id='" + buttonId +"']").length ? $("button[data-id='" + buttonId +"']") : $("a[data-id='" + buttonId +"']");

                $(tag).insertAfter(form);
                return result = false;
            }
        });
    }

var ProductType = {
    "offer": 0,
    "upsell": 1,
};
        
function getNavigationItemFromPageData(buttonId) {
    // {
    //     "elementId": "fkt-button-d312fcad-5eae-45f6-9e8d-4db6cd414ce0",
    //     "href": "",
    //     "status": null,
    //     "pageViewReferenceId": "e4c34dc7-be70-4be4-8db7-6588ce552ec6",
    //     "linkDetails": null,
    //     "products": []
    // },
    const matchedItem = $.grep(pageData.navigationItems, function(item) {
        return (item.elementId === buttonId);
    });
    if (matchedItem && matchedItem.length) {
        return matchedItem[0];
    }
    return null;
}

function getProductInfoFromNavigationItem(type, buttonId) {
    const item = getNavigationItemFromPageData(buttonId);
    if (item && item.products && item.products.length) {
        return item.products[0];
    }
    return null; 
}

function getOfferFromNavigationItem(buttonId) {
    return getProductInfoFromNavigationItem("offer", buttonId);
}

function getUpsellFromNavigationItem(buttonId) {
    return getProductInfoFromNavigationItem("upsell", buttonId);
}

function importOrder(buttonId, route) {
    let orderId = sessionStorage.getItem("orderId");
    orderId = JSON.parse(orderId);
    let obj = {};
    obj.firstName = document.getElementsByName("firstName")[0] ? document.getElementsByName("firstName")[0].value : null;
    obj.lastName = document.getElementsByName("lastName")[0] ? document.getElementsByName("lastName")[0].value : null;
    obj.companyName = document.getElementsByName("companyName")[0] ? document.getElementsByName("companyName")[0].value : null;
    obj.address1 = document.getElementsByName("address1")[0] ? document.getElementsByName("address1")[0].value : null;
    obj.address2 = document.getElementsByName("address2")[0] ? document.getElementsByName("address2")[0].value : null;
    obj.postalCode = document.getElementsByName("postalCode")[0] ? document.getElementsByName("postalCode")[0].value : null;
    obj.city = document.getElementsByName("city")[0] ? document.getElementsByName("city")[0].value : null;
    obj.state = document.getElementsByName("state")[0] ? document.getElementsByName("state")[0].value : null;
    obj.country = document.getElementsByName("country")[0] ? document.getElementsByName("country")[0].value : "US";
    obj.emailAddress = document.getElementsByName("emailAddress")[0] ?  document.getElementsByName("emailAddress")[0].value :  null;
    obj.phoneNumber = document.getElementsByName("phoneNumber")[0] ? document.getElementsByName("phoneNumber")[0].value : null;
    obj.gender = document.getElementsByName("gender")[0] ? document.getElementsByName("gender")[0].value : null; 
    obj.dob = document.getElementsByName("dob")[0] ? document.getElementsByName("dob")[0].value : null;
    obj.custom1 = document.getElementsByName("custom1")[0] ? document.getElementsByName("custom1")[0].value : null;
    obj.custom2 = document.getElementsByName("custom2")[0] ? document.getElementsByName("custom2")[0].value : null;
    obj.custom3 = document.getElementsByName("custom3")[0] ? document.getElementsByName("custom3")[0].value : null;
    obj.custom4 = document.getElementsByName("custom4")[0] ? document.getElementsByName("custom4")[0].value : null;
    obj.custom5 = document.getElementsByName("custom5")[0] ? document.getElementsByName("custom5")[0].value : null;
    
    var inputs =$("input");
        

        
        inputs.each(function(index, input) {
        if(input.name.indexOf("custom_") >= 0 ) {
            obj[input.name] = input.value
        }
        })
        
    obj.billShipSame =  document.getElementsByName("billShipSame")[0] && document.getElementsByName("billShipSame")[0].checked ? 1 : 0;
    obj.campaignId = parseInt(pageData.funnelData.campaign);
    // let offer = pageData[buttonId].OFFER;
    cartArray = [];
    var offer = getOfferFromNavigationItem(buttonId);
    offer ? cartArray.push(offer) : null;
    var products = document.getElementsByName("product");
    if(products) {
        products.forEach(function(product) {
            if(product.checked) {
                cartArray.push(product.value);
            }
        })
    }
    if(cartArray.length > 0) {
        cartArray.forEach(function(productId, index) {
            obj["product" + (index + 1) + "_id"] = parseInt(productId);
        });
    } else {
        obj.product1_id = offer;
    }
    if(orderId){
        obj.orderId = orderId;
    }
    fetchLeadDetails(obj);    
    obj.paySource = "CREDITCARD";
    obj.cardNumber = document.getElementsByName("cardNumber")[0] ? document.getElementsByName("cardNumber")[0].value : null;
    obj.cardMonth = document.getElementsByName("cardMonth")[0] ? parseInt(document.getElementsByName("cardMonth")[0].value) : null;
    obj.cardYear = document.getElementsByName("cardYear"[0]) ? parseInt(document.getElementsByName("cardYear")[0].value) : null;
    obj.cardSecurityCode = document.getElementsByName("cardSecurityCode")[0] ? parseInt(document.getElementsByName("cardSecurityCode")[0].value) : null;
        let session = sessionStorage.getItem("sessionId");
        session = JSON.parse(session);

        
        if(session) {
            obj.sessionId = session.message.sessionId;
        }

        let data = JSON.stringify(obj);

        sessionStorage.setItem("userDetails",data);
        let response = httpMethod("${providerUrl}/Import/Order", data).then(function (data) {
            
            if(data.result === "SUCCESS") {
                registerClick(1)
                let salesProduct = [];
               
                orderId = data.message.orderId;
                
                salesProduct.push(data.message.items);
                let jsonStr = JSON.stringify(orderId);
                sessionStorage.setItem("orderId", jsonStr);
                let jsonStr1 = JSON.stringify(salesProduct[0]);
                sessionStorage.setItem("products", jsonStr1);
                window.location.href = route;
            } else {
                let tag = document.getElementById("error-" + buttonId);
                

                if (tag === null) {
                    tag = document.createElement("p");
                    tag.setAttribute("id", "error-" + buttonId);
                } else {
                    tag.innerHTML = '';
                }

                tag.innerHTML = data.message;
                const color = tag.style.color = "red";
                const form = $("button[data-id='" + buttonId +"']").length ? $("button[data-id='" + buttonId +"']") : $("a[data-id='" + buttonId +"']");
                
                $(tag).insertAfter(form);
                return result = false;
                // TODO - Check if something was different
                // const tag = document.createElement("p");
                // const message = document.createTextNode(data.message);

                // const color = tag.style.color = "red";
                // const width = tag.style.width = "80%";
                // tag.appendChild(message);
                // const form = $(".byclickingbelowi");
                // $(".byclickingbelowi .span1").html(tag)
                // $("button").css({ top: '590px' });
                // // form.prepend(tag);
                // // $(tag).append(form);
                // return result = false;
            }
        });
}


function importUpsale(buttonId, route) {
    let orderId = sessionStorage.getItem("orderId");
    orderId = JSON.parse(orderId);
    let obj = {};
    obj.orderId = orderId;
    // let upsell = pageData[buttonId].UPSELL;
    var upsell = getUpsellFromNavigationItem(buttonId);
    if(upsell)
        obj.productId = upsell;
    else
        window.location.href = route;
    let data = JSON.stringify(obj);

        let response = httpMethod("${providerUrl}/Import/Upsale", data).then(function (data) {
            if(data.result === "SUCCESS") {
                registerClick(1);
                sessionStorage.removeItem("products");
                let products = data.message.items;
                products = JSON.stringify(products);
                sessionStorage.setItem("products", products);
                window.location.href = route;
            } else {
                let tag = document.getElementById("error-" + buttonId);

                if (tag === null) {
                    tag = document.createElement("p");
                    tag.setAttribute("id", "error-" + buttonId);
                } else {
                    tag.innerHTML = '';
                }
                tag.innerHTML = data.message;

                const color = tag.style.color = "red";
                const form = $("button[data-id='" + buttonId +"']").length ? $("button[data-id='" + buttonId +"']") : $("a[data-id='" + buttonId +"']");

                $(tag).insertAfter(form);
                return result = false;
                // TODO - Check if it's still required
                // const tag = document.createElement("p");
                // const message = data.message;
                // const color = message.fontcolor("red");
                // tag.innerHTML = color;
                // const form = document.getElementById("button");
                // form.appendChild(tag);
                // return result = false;
            }
        });
}

function confirmation(buttonId, route) {
    var userDetails = JSON.parse(sessionStorage.getItem("userDetails"));   
    var listOfItem = '';
    var subTotal = 0.00;
    var totalShipping = 0.00;
    for (var key in userDetails) {
        var element = document.getElementById(key);
        if(key === 'state' || key === 'shipState' ||  key === 'country' ||  key === 'shipCountry'){
            let element = document.getElementById(key+'-field');
            element ? element.innerHTML = userDetails[key] : '';
        }else{
            let element = document.getElementById(key);
            element ? element.innerHTML = userDetails[key] : '';
        }
    }
    let products = sessionStorage.getItem("products");
    products = JSON.parse(products);
    products && products.length > 0 && products.forEach(product => {
        subTotal += parseFloat(product.price);
        totalShipping += parseFloat(product.shipping);
        listOfItem += '<tr><td>' + product.name + '</td><td>' + product.qty + '</td><td style="text-align:right;"> $' + product.price + '</td></tr>';
    });
    $('#items-list').append(listOfItem);
    let paid = parseFloat(subTotal);
    var invoiceFinal = '<tr><td colspan="3"><div class="total-prices"><div class="each-price"><label>Sub Total :</label> <span id="subTotal">$'+paid+'</span></div><div class="each-price"><label>Shipping :</label> <span id="sAndHFee"> $'+ totalShipping +'</span></div><div class="each-price"><label>Grand Total: </label><span id="grandTotal"> $'+ parseFloat(paid + totalShipping)+'</span></div></div></td></tr><style>.total-prices .each-price label { margin: 0;font-weight: 700;font-size: 18px;}.total-prices{margin-top:15px;padding-top:15px;text-align: right;border-top: 1px solid #277098;}</style>';
    $('#items-list').append(invoiceFinal);
    let productsContainer = document.getElementById("productsContainer");

    let elem = document.getElementById("products");
    elem && elem.remove();

    let productsEL = document.createElement("tbody");
    productsEL.setAttribute("id", "products");

    productsContainer && productsContainer.appendChild(productsEL);


    let total = 0;

    products && products.length > 0 && products.map(function(product, i) {
        let tr = document.createElement("tr");
        let th = document.createElement("th");
        let td = document.createElement("td");
        let td1 = document.createElement("td");
        th.innerHTML = i + 1;
        td.innerHTML = product.name;
        td1.innerHTML = product.price;
        total = total + parseInt(products[i].price);
        tr.appendChild(th);
        tr.appendChild(td);
        tr.appendChild(td1);
        productsEL.appendChild(tr);
    });


    let tr = document.createElement("tr");
    let th = document.createElement("th");
    let td = document.createElement("td");
    let td1 = document.createElement("td");
    td.innerHTML = "Total";
    td1.innerHTML = total.toFixed(2);
    tr.appendChild(th);
    tr.appendChild(td);
    tr.appendChild(td1);
    productsEL.appendChild(tr);
    registerClick(1);
}

function UpdateProduct(id) {
    productId = id;
}

function addToCart(event) {
    const value = event.target.value;
    cartArray.push(value);
}

function addProduct(event) {    
        const checked = event.target.checked;
        
        const value = event.target.value;
    if (event.target.type === "checkbox") {
        if (checked) {
            cartArray.push(value);
        } else {
        
            const productIndex = cartArray.findIndex(item => item === value);
            cartArray.splice(productIndex, 1);
        }
    } 
    else {
        if(checked) {
            cartArray = [];
            cartArray.push(value);
        }
    }
    let totalAmmountEle = document.getElementById('totalAmountIs');
    let shippingPriceEle = document.getElementById('shippingCharges')
    if(totalAmmountEle){
        price = 0;
        calcTheProductInvoiceAmount(totalAmmountEle,"cycle1_price")
    }
    if(shippingPriceEle){
        price = 0;
        calcTheProductInvoiceAmount(shippingPriceEle,"cycle1_shipPrice");
    }
}

function removeFromCart(event) {
    const value = event.target.value;
    const productIndex = cartArray.find(id);
    cartArray.splice(productIndex, 1);
}

function closePopup(elem,type){
    if(type === 'id'){
        document.getElementById(elem).setAttribute("style", "display:none;");
    }
    else if(type === 'tag'){
        document.getElementsByTagName(elem)[0].setAttribute("style", "display:none;");
    }
    
}
function memberRoute(route, productData) {
        const membership = JSON.parse(sessionStorage.getItem("membership"));
        const productId = membership.message.data[0].productId;
        const currentProduct = productData.find(products => (products.find(product => product.productId === productId)));
        
        let targetPage = currentProduct && currentProduct.find(product => product.productId === productId).targetPage;
        route = targetPage + ".html?"+Date.now();
        window.location.href = route;
   
   
}
function memberShipRoute(route, clubId, status) {
    window.location.href = route;
}

function callMethod(e) {
    e.preventDefault();

    let clubId = "";
    let BreakException = {};
    try {
        pageData.selectedCampaign && pageData.selectedCampaign.products && pageData.selectedCampaign.products.forEach(function (product) {
            if (product.clubPluginId) {
                clubId = product.clubPluginId;
                throw BreakException;
            }
        })
    } catch (e) {
        if (e !== BreakException) throw e;
    }
    let route = null;
    let pageType = pageData.funnelData.isVisualizer ? pageData.templateTypeId : pageData.currentPage.title;
    const buttonId = e.target.dataset.id;
    let buttonData;
    // if (pageData.funnelData && pageData.funnelData.isFunnelTemplate) {
    //     buttonData = pageData.funnelData.isVisualizer ? (pageType === 7) ? pageData[buttonId].Active.NEXT_PAGE  : pageData[buttonId].NEXT_PAGE : JSON.parse(pageData[buttonId].NEXT_PAGE);
    // } else {
    //     const buttonItem = getNavigationItemFromPageData(buttonId);
    //     buttonData = pageData.funnelData.isVisualizer ? buttonItem.NEXT_PAGE : JSON.parse(pageData[buttonId].NEXT_PAGE);
    // }
    var funnelData = pageData.funnelData;
    var buttonItem = getNavigationItemFromPageData(buttonId);
    buttonData = (buttonItem && buttonItem.linkDetails) || {};
    if (funnelData && funnelData.isFunnelTemplate && funnelData.isVisualizer) {
        if (pageType === 7) {
            buttonData = buttonItem && buttonItem.ACTIVE && buttonItem.ACTIVE.linkDetails;
        }
    }
    if (funnelData && funnelData.isFunnelTemplate) {
        route = buttonData.targetPageViewReferenceId + ".html?"+Date.now();
        switch (pageType) {
            // Lead page
            case 2:
            case "leadPage":
                importLead(buttonId, route);
                break;
            // Checkout Page
            case 4:
            case "checkoutPage":
                importOrder(buttonId, route);
                break;
            // Upsell Page
            case 3:
            case "upsellPage":
                importUpsale(buttonId, route);
                break;
            // Thank You Page
            case 5:
            case "thankYouPage":
                confirmation(buttonId, route);
                break;
            case 7:
            case "login":
                memberShipRoute(route, clubId);
                break;
            case 8:
            case "Profile":
                memberShipRoute(route, clubId);
                break;
            case 9:
            case "memberAreaPage":
                memberShipRoute(route);
                break;
            case 10:
            case "cancelMembership":
                memberShipRoute(route, clubId);
                break;
            case 11:
            case "restartMembership":
                memberShipRoute(route, clubId);
                break;
            case 12:
            case "updateCard":
                memberShipRoute(buttonId, route, clubId);
                break;
            case 13:
            case "changeProduct":
                memberShipRoute(buttonId, route, clubId);
                break;

            default:
                window.location.href = route;
        }
    } else {
        let currentUrl = window.location.href;
        let envUrl = currentUrl.substr(0, currentUrl.lastIndexOf("/")) + "/funnelJson.json";
        fetch(envUrl).then(res => res.json()).then(data => {
            let pageReferenceId = pageData.funnelData.isVisualizer ? pageData.currentPage.referenceId : buttonData.targetPageReferenceId; //element.targetPageReferenceId or referenceId
            let targetData = pageData.funnelData.pages.find(item => item.pageView[0].referenceId === buttonData.targetPageViewReferenceId); //element.targetPageViewReferenceId or targetPage
            let targetUrl;
            const membershipPageTypes = [7, 8, 9, 10, 11, 12, 13];
            const isMemeberPage = membershipPageTypes && membershipPageTypes.find(page => page === pageType)
            if (isMemeberPage) {
                productData = buttonData ? buttonData.products : [];
                targetUrl = buttonData.targetPageViewReferenceId + ".html?"+Date.now();
            } else {
                let splitReferenceId = (targetData && targetData.splitReferenceId) || "";
                let funnelReferenceId = pageData.funnelData ? pageData.funnelData.referenceId : null;
                if (!pageData.funnelData.isVisualizer) {
                    targetUrl = "${splitViewUrl}/SplitView/funnel/" + funnelReferenceId + "/page/" + pageReferenceId + "/redirect/true";
                } else if (targetData && targetData.splitEnabled) {
                    targetUrl = "${rootUrl}/SplitTest/funnel/" + funnelReferenceId + "/split/" + splitReferenceId + "/redirect/true/funnelEnvironment/" + data.FunnelEnvironment;
                } else {
                    targetUrl = buttonData.targetPageViewReferenceId + ".html";
                }
            }

            // let route = pageData[buttonId].NEXT_PAGE.value.pageView[0].url;
            route = targetUrl;
            switch (pageType) {
                // Lead page
                case 2:
                case "leadPage":
                    importLead(buttonId, route);
                    break;
                // Checkout Page
                case 4:
                case "checkoutPage":
                    importOrder(buttonId, route);
                    break;
                // Upsell Page
                case 3:
                case "upsellPage":
                    importUpsale(buttonId, route);
                    break;
                // Thank You Page
                case 5:
                case "thankYouPage":
                    confirmation(buttonId, route);
                    break;
                case 7:
                case "login":
                    clubLogin(buttonId, clubId, productData);
                    break;
                case 8:
                case "Profile":
                    updateMember(route, clubId, productData);
                    break;
                case 9:
                case "memberAreaPage":
                    memberRoute(route, productData);
                    break;
                case 10:
                case "cancelMembership":
                    cancelMembership(route, clubId, productData);
                    break;
                case 11:
                case "restartMembership":
                    restartMembership(route, clubId, productData);
                    break;
                case 12:
                case "updateCard":
                    updateMember(buttonId, route, clubId, productData);
                    break;
                case 13:
                case "changeProduct":
                    changeProduct(buttonId, route, clubId);
                    break;

                default:
                    window.location.href = route;
            }
        });
    }


}
function route(e) {
    e.preventDefault();
    var funnelData = pageData && pageData.funnelData;
    let pageType = pageData.funnelData.isVisualizer ? pageData.templateTypeId : pageData.currentPage.title;
    const buttonId = e.target.dataset.id;
    let buttonData;
    var buttonItem = getNavigationItemFromPageData(buttonId);
    buttonData = (buttonItem && buttonItem.linkDetails) || {};
    // if (pageData.funnelData && pageData.funnelData.isFunnelTemplate) {
    //     buttonData = pageData.funnelData.isVisualizer ? (pageType === 7) ? pageData[buttonId].Active.NEXT_PAGE  : pageData[buttonId].NEXT_PAGE : JSON.parse(pageData[buttonId].NEXT_PAGE);
    // } else {
    //     buttonData = pageData.funnelData.isVisualizer ? pageData[buttonId].NEXT_PAGE : JSON.parse(pageData[buttonId].NEXT_PAGE);
    // }
    if (funnelData && funnelData.isFunnelTemplate && funnelData.isVisualizer) {
        if (pageType === 7) {
            buttonData = (buttonItem && buttonItem.ACTIVE && buttonItem.ACTIVE.linkDetails) || {};
        }
    }
    if (pageData.funnelData && pageData.funnelData.isFunnelTemplate) {
        route = buttonData.targetPageViewReferenceId + ".html?"+Date.now();
        window.location.href = route;
    } else {

        let currentUrl = window.location.href;
        let envUrl = currentUrl.substr(0, currentUrl.lastIndexOf("/")) + "/funnelJson.json";
        fetch(envUrl).then(res => res.json()).then(data => {
            let response;

            let pageReferenceId = pageData.funnelData.isVisualizer ? pageData.currentPage.referenceId : buttonData.targetPageReferenceId;
            let targetData = pageData.funnelData.pages.find(item => item.pageView[0].referenceId === buttonData.targetPageViewReferenceId);
            let splitReferenceId = targetData.splitReferenceId;


            let funnelReferenceId = pageData.funnelData ? pageData.funnelData.referenceId : null;
            let splitUrl = !pageData.funnelData.isVisualizer ? "${splitViewUrl}/SplitView/funnel/" + funnelReferenceId + "/page/" + pageReferenceId + "/redirect/true" : (targetData.splitEnabled ? "${rootUrl}/SplitTest/funnel/" + funnelReferenceId + "/split/" + splitReferenceId + "/redirect/true/funnelEnvironment/" + data.FunnelEnvironment : buttonData.targetPageViewReferenceId + ".html");

            // let route = pageData[buttonId].NEXT_PAGE.value.pageView[0].url;
            let route = splitUrl;
            window.location.href = route;
        });
    }
}
function linkMethod(e, submit) {
    e.preventDefault();
    let pageType = pageData.funnelData.isVisualizer ? pageData.templateTypeId : pageData.currentPage.title;


    const buttonId = e.target.dataset.id;
    // let buttonData = pageData.funnelData.isVisualizer ? pageData[buttonId].NEXT_PAGE : JSON.parse(pageData[buttonId].NEXT_PAGE);
    // const linkId = e.target.dataset.id ? e.target.dataset.id : e.target.parentNode.getAttribute("data-id");
    // let linkData = pageData.funnelData.isVisualizer ? pageData[linkId].NEXT_PAGE : JSON.parse(pageData[linkId].NEXT_PAGE);
    var buttonItem = getNavigationItemFromPageData(buttonId);
    var buttonData = (buttonItem && buttonItem.linkDetails) || {};
    var linkId = buttonId ? buttonId : e.target.parentNode.getAttribute("data-id");
    var linkObj = getNavigationItemFromPageData(linkId);
    var linkData = linkObj && linkObj.linkDetails;
    if (pageData.funnelData && pageData.funnelData.isFunnelTemplate) {
        if (submit) {
            switch (pageType) {
                // Lead page
                case 2:
                case "leadPage":
                    importLead(linkId, route);
                    break;
                // Checkout Page
                case 4:
                case "checkoutPage":
                    importOrder(linkId, route);
                    break;
                // Upsell Page
                case 3:
                case "upsellPage":
                    importUpsale(linkId, route);
                    break;
                // Thank You Page
                case 5:
                case "thankYouPage":
                    confirmation(linkId, route);
                    break;
                default:
                    window.location.href = route;
            }
        }
        if (linkObj.href) {
            linkObj.target === "_blank" ? window.open(linkObj.href, '') : window.location.href = linkObj.href;
        } else {

            let route = buttonData.targetPageViewReferenceId + ".html?"+Date.now();
            linkObj.target === "_blank" ? window.open(route, '') : window.location.href = route;
        }
    } else {

        let currentUrl = window.location.href;
        let envUrl = currentUrl.substr(0, currentUrl.lastIndexOf("/")) + "/funnelJson.json";
        fetch(envUrl).then(res => res.json()).then(data => {
            let response;
            let pageType = pageData.funnelData.isVisualizer ? pageData.templateTypeId : pageData.currentPage.title;

            let pageReferenceId = pageData.funnelData.isVisualizer ? pageData.currentPage.referenceId : linkData.targetPageReferenceId;
            let targetData = pageData.funnelData.pages.find(item => item.pageView[0].referenceId === linkData.targetPageViewReferenceId);
            let splitReferenceId = targetData.splitReferenceId;

            if (submit) {
                switch (pageType) {
                    // Lead page
                    case 2:
                    case "leadPage":
                        importLead(linkId, route);
                        break;
                    // Checkout Page
                    case 4:
                    case "checkoutPage":
                        importOrder(linkId, route);
                        break;
                    // Upsell Page
                    case 3:
                    case "upsellPage":
                        importUpsale(linkId, route);
                        break;
                    // Thank You Page
                    case 5:
                    case "thankYouPage":
                        confirmation(linkId, route);
                        break;
                    default:
                        window.location.href = route;
                }
            }
            if (linkObj.href) {
                linkObj.target === "_blank" ? window.open(linkObj.href, '') : window.location.href = linkObj.href;
            } else {
                let funnelReferenceId = pageData.funnelData ? pageData.funnelData.referenceId : null;
                let splitUrl = !pageData.funnelData.isVisualizer ? "${splitViewUrl}/SplitView/funnel/" + funnelReferenceId + "/page/" + pageReferenceId + "/redirect/true" : (targetData.splitEnabled ? "${rootUrl}/SplitTest/funnel/" + funnelReferenceId + "/split/" + splitReferenceId + "/redirect/true/funnelEnvironment/" + data.FunnelEnvironment : linkData.targetPageViewReferenceId + ".html");
                // let route = pageData[linkId].NEXT_PAGE.value.pageView[0].url;
                let route = splitUrl;
                linkObj.target === "_blank" ? window.open(route, '') : window.location.href = route;
            }
        });
    }
}
    function fetchLeadDetails(obj){
        if(obj.billShipSame === 1) {
            obj.shipFirstName = document.getElementsByName("firstName")[0] ? document.getElementsByName("firstName")[0].value : null;
            obj.shipLastName = document.getElementsByName("lastName")[0] ? document.getElementsByName("lastName")[0].value : null;
            obj.shipAddress1 = document.getElementsByName("address1")[0] ? document.getElementsByName("address1")[0].value : null;
            obj.shipCity = document.getElementsByName("city")[0] ? document.getElementsByName("city")[0].value : null;
            obj.shipState = document.getElementsByName("state")[0] ? document.getElementsByName("state")[0].value : null;
            obj.shipPostalCode = document.getElementsByName("postalCode")[0] ? document.getElementsByName("postalCode")[0].value : null;
            obj.shipCountry = document.getElementsByName("country")[0] ? document.getElementsByName("country")[0].value : null;
        }
        else{
            obj.shipFirstName = document.getElementsByName("shipFirstName")[0] ? document.getElementsByName("shipFirstName")[0].value : null;
            obj.shipLastName = document.getElementsByName("shipLastName")[0] ? document.getElementsByName("shipLastName")[0].value : null;
            obj.shipAddress1 = document.getElementsByName("shipAddress1")[0] ? document.getElementsByName("shipAddress1")[0].value : null;
            obj.shipCity = document.getElementsByName("shipCity")[0] ? document.getElementsByName("shipCity")[0].value : null;
            obj.shipState = document.getElementsByName("shipState")[0] ? document.getElementsByName("shipState")[0].value : null;
            obj.shipPostalCode = document.getElementsByName("shipPostalCode")[0] ? document.getElementsByName("shipPostalCode")[0].value : null;
            obj.shipCountry = document.getElementsByName("country")[0] ? document.getElementsByName("country")[0].value : "US";
        }
    }
    window.onscroll = function(){
        var  stickyBar = document.getElementById('stickyBarCustom');
        var  popupBlock = document.getElementById('customizable_popup');
        var toShowOnScroll =  stickyBar && stickyBar.getAttribute('data-showonscroll') === 'yes' ? true : false;
        
        if(toShowOnScroll){
            if(window.scrollY > 100){
                document.getElementById('stickyBarCustom').setAttribute('style','display:block');
            }
            else{
                document.getElementById('stickyBarCustom').setAttribute('style','display:none');
            }
        }
       if( popupBlock && popupBlock.querySelector('[data-showOnscroll]') && ( popupBlock && popupBlock.querySelector('[data-showOnscroll]').getAttribute('data-showOnscroll') === "true")){
        if(isVisitedOnScroll){
        var minutesDelay =  popupBlock.querySelector('[data-minutesdelay]') ? Number(popupBlock.querySelector('[data-minutesdelay]').getAttribute('data-minutesdelay')) : 0;
        var  secondsDelay =  popupBlock.querySelector('[data-secondsdelay]') ?  popupBlock.querySelector('[data-secondsdelay]').getAttribute('data-secondsdelay') : 0;
        var delay = minutesDelay > 0 ? ((minutesDelay * 60)+secondsDelay)+'000' : secondsDelay+'000' ;
        document.getElementById('customizable_popup').setAttribute('style','display:none');
        setTimeout((delay) => {
            isVisitedOnScroll = false;
            document.getElementById('customizable_popup').setAttribute('style','display:block');
        },delay);
    }
    }
    }
    var calcTheProductInvoiceAmount = function(ele,key){
        $('input[name^="product"]').each(function(){
            if($(this).is(':checked')){
                let id = $(this).val();
                var product = pageData.selectedCampaign.products.filter(data => data.campaignProductId == id )
                if(product.length === 1 && product.length > 0){
                    price += product[0][key] != null ? parseFloat(product[0][key]) : 0;
                }
                else{
                    $.each(product,function(i,product){
                        price += product[key] != null  ? parseFloat(product[0][key]) : 0;
                    });
                }
               
            }
        });
        ele.innerText = parseFloat(price).toFixed(2);
    }
    function downloadURL(event){
        const  URL = $(event).attr('data-downloadurl');
        if(URL){
            let linkElement = document.createElement('a');
            linkElement.href = URL;
            linkElement.download = true;
            linkElement.target="blank";
            document.body.appendChild(linkElement);
            linkElement.click();
            document.body.removeChild(linkElement);
            }
            else{
                alert('No URL found');
            }  
    }
    function registerClick(clickType) {
    var funnelReferenceId = pageData.funnelData ? pageData.funnelData.referenceId : null;
    var pageReferenceId = pageData.funnelData.isVisualizer ? pageData.currentPage.referenceId : buttonData.targetPageReferenceId;
    if(!document.URL.includes("funnels-build")) {
        let response = httpMethod("${rootUrl}/SplitTest/clicks/" + funnelReferenceId + "/" + pageReferenceId + "/" + clickType)
    }
    return;
}  
// $(document).ready(function() {
//     if (window && window.performance && window.performance.navigation && window.performance.navigation.type === 0)
//     registerClick(0);
//     if (pageType === "checkoutPage"){
//         displayPrices();
//     }
// })
    function previewURL(event){
        const  URL = $(event).attr('data-previewurl');
        window.open(URL,"_blank"); 
    }
    function displayPrices() {
        let priceDisplay = document.getElementById("totalAmountIs");
        let shippingPriceDisplay = document.getElementById("shippingCharges");
        if(priceDisplay){
            price = 0;
            calcTheProductInvoiceAmount(priceDisplay,"cycle1_price")
        }
        if(shippingPriceDisplay){
            price = 0;
            calcTheProductInvoiceAmount(shippingPriceDisplay,"cycle1_shipPrice");
        }
    }
    `;
    }
}
