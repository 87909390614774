import get from "lodash/get";
import { getEditorContainer } from "../utils/component";
import { getEditorHtml } from "../utils/editor";
import {
    triggerNofiticationSuccess,
    triggerNofiticationError,
} from "../../commons/notification";

const saveFunnel = (editor, options) => {
    const container = getEditorContainer(editor);
    const { props: containerProps } = container;
    const {
        templateData,
        metadata,
        savePageView,
        selectedCampaign,
    } = containerProps;
    const {
        funnelData,
        currentPage,
        salt,
        funnelReferenceId,
    } = metadata;
    let jsData = container.buttonMetaData.jsPageData ? { ...container.buttonMetaData.jsPageData } : {};
    const { templateTypeId } = templateData;
    const jsPageData = {
        ...jsData,
        currentPage,
        funnelData,
        salt,
        templateTypeId,
        selectedCampaign,
    };
    if (templateTypeId === 7 && container.buttonMetaData.buttons.length === 1) {
        container.buttonMetaData.buttons[0] = {
            ...container.buttonMetaData.buttons[0],
            status: "ACTIVE",
            name: "Login-Active"
        };
        const id = container.buttonMetaData.buttons[0]["data-id"];
        const loginButtonForInactiveStatus = {
            ...container.buttonMetaData.buttons[0],
            "data-id": id + "-inactive",
            status: "INACTIVE",
            name: "Login-Inactive"
        };
        container.buttonMetaData.buttons.push(loginButtonForInactiveStatus);
    }
    const newButtonMetaData = {
        buttons: [...container.buttonMetaData.buttons],
        links: [...container.buttonMetaData.links],
        jsPageData,
    };
    const currentPageView = get(currentPage, ["pageView", "0"]);
    const {
        fTemplateId,
        referenceId: pageViewReferenceId,
    } = currentPageView;
    container.toggleLoading(true);
    savePageView({
        ...getEditorHtml(editor),
        referenceId: pageViewReferenceId,
        pageName: pageViewReferenceId,
        pageViewReferenceId,
        templateId: fTemplateId,
        pageData: jsPageData,
        buttonMetaData: newButtonMetaData,
        generateThumbnail: true,
        isEdited: true,
        funnelReferenceId: funnelReferenceId,
        isWebBuilder: true,
        isPremium: currentPage.premium,
    })
        .then(() => {
            container.toggleLoading(false);
            triggerNofiticationSuccess({ message: "Page saved successfully" });
            editor.UndoManager.clear();
            if (options.navigateBack) {
                container.navigateBack();
            }
        })
        .catch(err => {
            container.toggleLoading(false);
            triggerNofiticationError({ message: err.message });
        });
};

export default {
    "save-database": saveFunnel,
};