import React, { useState, useEffect, useContext } from "react";
import PropTypes from "prop-types";
import { Multiselect } from "react-widgets";
import constants from "../../utilities/constants";
import {
    filteredFonts
} from "../../utilities/helpers";

const FontsList = ({
    fontList,
    savedFonts,
    selectedFonts,
    saveFontsCall,
    isFontLoading,
    chosenAlphabet = "A"
}) => {
    const [ multiFontSelectValue, setMultiFontSelectValue ] = useState([]);
    const showList = fontList.items.map(item => {
        return item.family;
    });
    const saveFonts = () => {
        return saveFontsCall(filteredFonts(multiFontSelectValue, fontList));
    };
    const updateSelection = (value) => setMultiFontSelectValue(value);
    const multiSelectRender = () => {
        return (
            <Multiselect
                busy = { isFontLoading ? true : false }
                data={showList}
                value={multiFontSelectValue}
                placeholder={constants.fontPlaceholder}
                onChange={value => updateSelection(value)}
            />
        );
    };
    useEffect(() => {
        return setMultiFontSelectValue(selectedFonts);
    }, [isFontLoading, chosenAlphabet, selectedFonts]);
    return (
        <>
            <div className="form-group font-list">
                <label>{constants.fontLabel}</label>
                {multiSelectRender()}
                <div className="text-right">
                    <button className="btn btn-primary m-t-sm" onClick={saveFonts}>{constants.fontSave}</button>
                </div>
            </div>
        </>
    );
};

FontsList.propTypes = {
    fontList: PropTypes.object,
    savedFonts: PropTypes.array,
    selectedFonts: PropTypes.array,
    saveFontsCall: PropTypes.func,
    isFontLoading: PropTypes.bool,
    chosenAlphabet: PropTypes.string
};

export default FontsList;