import axios from "axios";

export class AssetEvents {
    constructor(editor, { deleteAsset }) {
        this.editor = editor;
        this.eventPrefix = "asset:";
        this.listenAssetEvents();
        this.deleteAsset = deleteAsset;
    }

    listenAssetEvents() {
        this.editor.on(`${this.eventPrefix}remove`, (assetName) => {
            this.deleteAsset(assetName);
        });
    }

}

const searchForIcon = (editor) => {
    const val = document.getElementById("searchVal").value;
    axios.get("https://api.iconfinder.com/v3/icons/search?query=" + val + "&license=commercial-nonattribution&grant_type=jwt_bearer&client_id=oaqGIL5jviTpivvG6S6xHTSvqsp5Hm0pS8YQsbejz5F6e87duXFifH754gHzcdFq&client_secret=Zk8mrxqBG5jupikDJIZU7mgyBBeTDO7QAPqVRsfFqgDkrYyFPfpUHku36c1vSkaR").then(res => {
        const iconsList = res.data.icons;
        document.getElementById("imageContainer").innerHTML = "";
        document.getElementById("imageContainer").style.display = "block";
        document.querySelector(".gjs-am-assets").style.height = "0";
        let container = document.getElementById("imageContainer");
        if (res.data.icons.length) {
            iconsList.forEach(icons => {
                let img = icons.raster_sizes.filter(img => img.size === 512);
                if (img.length) {
                    let imageObject = img[0].formats[0];
                    let parentElement = document.createElement("div");
                    parentElement.className = "heading";
                    parentElement.style = "padding:15px;border-bottom: 1px solid #2f404f;cursor:pointer;";
                    parentElement.style.padding = "15px";
                    let imageParent = document.createElement("div");
                    let imgElement = document.createElement("img");
                    imgElement.style = "width:100px;";
                    imgElement.setAttribute("src",imageObject.preview_url);
                    imageParent.appendChild(imgElement);
                    let splitUrl = imageObject.preview_url.split("/");
                    let imageName = document.createElement("span");
                    imageName.style = "margin-left:10px;";
                    imageName.innerText = splitUrl[splitUrl.length - 1];
                    imageParent.appendChild(imageName);
                    parentElement.appendChild(imageParent);
                    let imageUrl = imageObject.preview_url;
                    parentElement.addEventListener("click",() => {
                        updateImageInTemplate(imageUrl, editor);
                    });
                    parentElement.addEventListener("dblclick",() => {
                        updateImageInTemplate(imageUrl, editor);
                        editor.Modal.close();
                    });
                    container.appendChild(parentElement);
                }
            });
        } else {
            container.append("There are no images for your search criteria");
        }
    });
};

const updateImageInTemplate = (imageUrl, editor = {}) => {
    if (editor.getSelected() && editor.getSelected().attributes.tagName === "img") {
        editor.getSelected().set("src", imageUrl);
    }
};

const openAssets = (editor) => {
    const modalTitle = editor.Modal.getContent().offsetParent.querySelector(".gjs-mdl-title").firstElementChild;
    const modalContent = editor.Modal.getContent().querySelector(".gjs-am-assets-cont");
    //Parent element search-group
    let searchIcons = document.createElement("div");
    searchIcons.style = "position: absolute;right: 100px;top: 15px;";
    //ICONS CONTAINER
    let imageContainer = document.createElement("div");
    imageContainer.style = "background-color:rgba(0,0,0,0.1);max-height:300px;overflow:auto;display:none;";
    imageContainer.id = "imageContainer";
    //INPUT ELEMENT
    let input = document.createElement("input");
    input.id = "searchVal";
    input.placeholder = "Search Icons";
    input.style = "height: 30px;width: 200px;font-size: 14px;background: #202b3780;color: #fff;border: 0;outline: none;padding-left: 5px;padding-right: 30px;";
    input.addEventListener("keydown",(e) => {
        e.which === 13 ? searchForIcon(editor) : "";
    });
    //SEARCH ICON
    let searchIcon = document.createElement("span");
    searchIcon.className = "fa fa-search fa-2x";
    searchIcon.title = "Search";
    searchIcon.style = "cursor:pointer;color:rgba(255, 255, 255, 0.3);border:0px;position: absolute;right: 30px;margin-top: 7px;";
    searchIcon.addEventListener("click",() => {
        searchForIcon(editor);
    });
    //CLEAR ICON - CLOSE
    let clearBtn = document.createElement("span");
    clearBtn.className = "fa fa-times fa-2x";
    clearBtn.title = "Clear";
    clearBtn.style = "cursor:pointer;color: rgba(255, 255, 255, 0.3);border: 0px;position: relative;top: 2px;background: rgb(42, 57, 71);padding: 5px;margin-top: 0px;";
    clearBtn.addEventListener("click",() => {
        let imgContainer = document.getElementById("imageContainer");
        imgContainer.innerHTML = "";
        imgContainer.style.display = "none";
        document.querySelector(".gjs-am-assets").style.height = "290px";
        document.querySelector("#searchVal").value = "";
    });
    //Adding created elements to Parent element
    if (!modalContent.querySelector("#imageContainer")) {
        searchIcons.appendChild(input);
        searchIcons.appendChild(searchIcon);
        searchIcons.appendChild(clearBtn);
        modalContent.appendChild(imageContainer);
        modalTitle && modalTitle.appendChild(searchIcons);
    }
};

export default {
    "run:open-assets": openAssets,
};