import joint from "jointjs";
import get from "lodash/get";
import {
    removeClass,
    addClass,
} from "../../../../utilities/dom-utils";
import {
    pointerDownOnBlank,
    pointerDownOnPage
} from "../../utilities/VisualizerEvents/page-events";
import {
    getCellViewModel,
} from "../../../../utilities/jointjs-helper";
import { copyAndCreatePage } from "./helpers";

let isCellMoving = false;
let isTextMoving = false;
let isDottedLineMoving = false;
const stencilPaperEvents = {
    "cell:pointerdown": function (StencilPaper, cellView) {
        isCellMoving = false;
        isTextMoving = false;
        isDottedLineMoving = false;
        StencilPaper.emitter.emit("RIGHTPANEL:CLOSE");
        var groupElement = document.querySelectorAll(".joint-cell");
        for (var j = 0; j < groupElement.length; j++) {
            groupElement[j].classList.remove("active");
            groupElement[j].classList.add("port-enable");
        }
        if (cellView.model.attributes.type === "devs.MyImageModel") {
            // [Arun - TODO]
            // setCellData(cellView.model);
        }
        if (!cellView.sourcePoint) {
            cellView.highlight();
        }
        var selectedElement = document.querySelector(`g[model-id="${cellView.model.id}"]`);
        for (var i = 0; i < groupElement.length; i++) {
            if (selectedElement.id === groupElement[i].id) {
                groupElement[i].classList.add("active");
                groupElement[i].classList.remove("port-enable");
            } else {
                groupElement[i].classList.remove("active");
                groupElement[i].classList.add("port-enable");
            }
        }
        // return pointerDownOnPage(StencilPaper, cellView);
    },
    "cell:pointerup": function (StencilPaper, cellView) {
        var groupElement = document.querySelectorAll(".joint-cell");
        for (var i = 0; i < groupElement.length; i++) {
            groupElement[i].classList.remove("port-enable");
        }
        if (isCellMoving) {
            StencilPaper.emitter.emit("CELL:MOVE", cellView);
            isCellMoving = false;
        } else if (isTextMoving) {
            StencilPaper.emitter.emit("TEXT-ELEMENT:UPDATE", cellView);
            isTextMoving = false;
        } else if (isDottedLineMoving) {
            StencilPaper.emitter.emit("DOTTED-LINE:UPDATE", cellView);
            isDottedLineMoving = false;
        }
    },
    "cell:pointermove": function (StencilPaper, cellView, evt) {
        if (cellView.model.get("type") === "devs.MyImageModel") {
            isCellMoving = true;
        } else if (cellView.model.get("type") === "html.Element") {
            isTextMoving = true;
        } else if (cellView.model.get("subType") === "dottedLink") {
            isDottedLineMoving = true;
        }
    },
    "blank:pointerdown": function (StencilPaper, e, x, y) {
        StencilPaper.emitter.emit("RIGHTPANEL:CLOSE");
        StencilPaper.emitter.emit("VISUALIZER:HIDE-ANALYTICS");
        const elementToDraw = StencilPaper.blankCanvasComponent;
        var groupElement = document.querySelectorAll(".joint-cell");
        groupElement.forEach((element) => element.classList.remove("active"));
        StencilPaper
            .findViewsInArea(StencilPaper.getArea())
            .forEach(cell => cell.unhighlight());
        let resetHeaderButton = true;
        if (elementToDraw) {
            if (elementToDraw === "textElement") {
                StencilPaper.emitter.emit("TEXT-ELEMENT:CREATE", {
                    x, y,
                });
            } else if (elementToDraw === "dottedLine") {
                resetHeaderButton = false;
                const line = new joint.dia.Link({
                    source: { x: x, y: y },
                    subType: "dottedLink",
                    attrs: {
                        ".marker-arrowhead": {
                            display: "none"
                        },
                    },
                });
                e.data = {
                    lineCoords: {
                        x1: x,
                        y1: y
                    },
                    line: line
                };
            }
        }
        if (resetHeaderButton) {
            StencilPaper.emitter.emit("HEADER-BUTTONS:RESET");
        }
        return pointerDownOnBlank(StencilPaper);
    },
    "blank:pointermove": function (StencilPaper, evt, x, y) {
        const elementToDraw = StencilPaper.blankCanvasComponent;
        if (elementToDraw === "dottedLine") {
            const lineCoords = evt.data.lineCoords;
            lineCoords.x2 = x;
            lineCoords.y2 = y;
            evt.data.line.target({ x: x, y: y });
            evt.data.line.attr(".connection/stroke-dasharray", "2,5");
            evt.data.line.prop("vertices", [
                {
                    x: lineCoords.x1, y: lineCoords.y1
                }, {
                    x: lineCoords.x2, y: lineCoords.y2
                }]);
            StencilPaper.emitter.emit("DOTTED-LINE:ADD", evt.data.line);
        }
    },
    "blank:pointerup": function (StencilPaper, evt) {
        const elementToDraw = StencilPaper.blankCanvasComponent;
        if (elementToDraw === "dottedLine") {
            const lineComponent = get(evt, ["data", "line"]);
            if (lineComponent) {
                StencilPaper.emitter.emit("DOTTED-LINE:CREATE", evt.data.line);
            }
            StencilPaper.emitter.emit("HEADER-BUTTONS:RESET");
        }
    },
    "link:options": function (StencilPaper, link, evt) {
        evt.stopPropagation();
        StencilPaper.emitter.emit("ARROW:SETTINGS", link);
    },
    "element:settings": function (StencilPaper, cellView, evt) {
        evt.stopPropagation();
        StencilPaper.emitter.emit("CELL:SETTINGS", cellView);
    },
    "element:edit": function (StencilPaper, cellView, evt) {
        evt.stopPropagation();
        const cellViewModel = getCellViewModel(cellView);
        if (cellViewModel.get("isTemplateSelected")) {
            StencilPaper.emitter.emit("CELL:NAVIGATE-TO-BUILDER", cellView);
        } else {
            StencilPaper.emitter.emit("DIALOG:OPEN", "noTemplateAlert");
        }
    },
    "element:delete": function (StencilPaper, cellView, evt) {
        evt.stopPropagation();
        StencilPaper.emitter.emit("CELL:DELETE", cellView);
    },
    "element:preview": function (StencilPaper, cellView, evt) {
        evt.stopPropagation();
        StencilPaper.emitter.emit("CELL:PREVIEW", cellView);
    },
    "element:copy": function (StencilPaper, cellView, evt, x, y) {
        evt.stopPropagation();
        if (!cellView.model.get("isSplitEnabled")) {
            const clonedCell = copyAndCreatePage(cellView, x, y);
            StencilPaper.model.addCell(clonedCell);
            StencilPaper.emitter.emit("CELL:COPY", clonedCell, cellView);
        } else {
            StencilPaper.emitter.emit("CELL:SPLIT-COPY-RESTRICT");
        }
    },
};

export default stencilPaperEvents;