import * as api from "../../../api/funnelTemplateApi";
import { APIHelper } from "../../../utilities";

export function getFunnelTemplate(funnelReferenceId) {
    return api.retrieveFunnelTemplate(funnelReferenceId, { headers: APIHelper.getGenericHeaders() }).then(response => {
        return response.result;
    }).catch(error => {
        throw (error);
    });
}

export function updateFunnelTemplate(params) {
    return api.updateFunnelTemplate(params, { headers: APIHelper.getGenericHeaders(), requestBody: params.funnel })
        .then(() => {
        }).catch(error => {
            throw error;
        });
}

export function loadActiveFunnelTemplates() {
    return api.getActiveFunnelTemplates({ headers: APIHelper.getGenericHeaders() }).then((response) => {
        return response.result;
    }).catch(error => {
        throw (error);
    });
}

