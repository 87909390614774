import { Designers as DesignersView } from "./designers";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import * as designersActions from "./actions";
import { loadDesigners } from "./actions";
import { loadCompany } from "../login/actions";

function mapStateToProps(state) {
    return {
        designers: state.DESIGNERS
    };
}

function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators(designersActions, dispatch),
        loadDesigners: () => dispatch(loadDesigners()),
        loadCompany: () => dispatch(loadCompany())
    };
}

export const Designers = connect(mapStateToProps, mapDispatchToProps)(DesignersView);