import React from "react";
import PropTypes from "prop-types";
import { getTemplateConfig } from "../constants/funnel-template-types";

export const FunnelThumbnailCard = (props) => {
    const {
        thumbnailUrl,
        onFunnelItemSelected,
        funnel,
    } = props;
    const {
        title,
        userName,
        fListFunnelTypeId: funnelTypeId,
    } = funnel;
    const funnelType = funnelTypeId && getTemplateConfig(funnelTypeId);
    return (
        <div className="funnelThumbnail">
            {
                funnelType && (
                    <div className="funnel-template-type">
                        {funnelType.label}
                    </div>
                )
            }
            <div
                className="thumbnail-template-img"
                style={{ backgroundImage: `url(${thumbnailUrl ? thumbnailUrl : ""})` }}
            >
                <a
                    className="choose"
                    onClick={() => onFunnelItemSelected(funnel)}
                >
                    <div className="choose-hand" />
                    <div>To Choose Funnel Template</div>
                </a>
            </div>
            <div className="thumbnail-content" onClick={() => onFunnelItemSelected(funnel)}>
                <abbr title={title} className="abbrtitle">
                    <div className="thumbnailTitle" >{title}</div>
                </abbr>
                {
                    userName && (
                        <div> {userName} </div>
                    )
                }
            </div>
        </div>
    );
};

FunnelThumbnailCard.propTypes = {
    thumbnailUrl: PropTypes.string,
    onFunnelItemSelected: PropTypes.func,
    funnel: PropTypes.object,
    onFunnelItemDelete: PropTypes.func,
};