import React from "react";
import PropTypes from "prop-types";
import { Form as FunnelTemplateForm, TypeField as FunnelTemplateTypeField } from "../../funnel-form";
import { funnelTypeError } from "../../commons";
import { funnelTemplateConstants } from "../constants";

export class CreateFunnelTemplate extends React.Component {
    constructor(props, context) {
        super(props, context);
        this.state = {
            show: false,
            funnel: Object.assign({
                title: "",
                fListFunnelTypeId: "",
            }),
            error: "",
            errors: {},
            selectedFunnelType: "",
        };
    }

    validate = (event) => {
        const funnel = this.state.funnel;
        let isSuccess = false;
        (!funnel.title) ? this.setState({ errors: { title: true } }) :
            (!funnel.fListFunnelTypeId) ? this.setState({ errors: { fListFunnelTypeId: true } }) :
                (isSuccess = true && this.saveFunnelTemplate(event));
        return isSuccess;
    }

    updateFunnelState = (event) => {
        const field = event.target.name;
        const funnelTemplate = this.state.funnel;
        funnelTemplate[field] = event.target.value;
        if (event.target.value.length >= 200) {
            alert("Funnel Template title should not exceed 200 characters");
        }
        return this.setState({ funnel: funnelTemplate });
    }
    saveFunnelTemplate = (event) => {
        let { funnel } = this.state;
        const {
            createFunnelTemplateError,
            toggleLoading,
            history,
        } = this.props;
        funnel.active = false;
        event.preventDefault();
        this.props.createFunnelTemplate(funnel).then(() => {
            toggleLoading(false);
            history.push("/funnel-templates");
        }).catch(() => {
            createFunnelTemplateError(true);
            toggleLoading(false);
        });
    }

    cancel = (event) => {
        event.preventDefault();
        this.props.history.push("/funnel-templates");
    }

    handleClose = () => {
        const error = "";
        this.setState({ show: false, error });
    }

    render() {
        const { isLoading, isError, funnelType } = this.props;
        const { errors, funnel } = this.state;
        const { funnelTemplateTypeLabel, funnelTemplateTypeDefaultOption } = funnelTemplateConstants;

        return (
            <div className="row border-bottom page-heading ">
                <div className="col-lg-12 m-t-md">
                    <div className="ibox">
                        <div className="ibox-content">
                            <h1 className="text-center" style={{ letterSpacing: "2px" }}>CREATE A FUNNEL TEMPLATE</h1>
                            <div className="container-fluid">
                                <FunnelTemplateForm funnel={this.state.funnel}
                                    onChange={this.updateFunnelState}
                                    onSave={this.validate}
                                    onCancel={this.cancel}
                                    isLoading={isLoading}
                                    isDefaultOptionEnabled={true}
                                    isEdit={false}
                                    isError={isError}
                                    errors={this.state.errors}
                                >
                                    <FunnelTemplateTypeField
                                        onChange={this.updateFunnelState}
                                        errors={errors}
                                        funnelType={funnelType}
                                        funnelTypeError={funnelTypeError}
                                        label={funnelTemplateTypeLabel}
                                        defaultOption={funnelTemplateTypeDefaultOption}
                                        funnel={funnel}
                                    />
                                </FunnelTemplateForm>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

CreateFunnelTemplate.propTypes = {
    funnelType: PropTypes.array.isRequired,
    isError: PropTypes.bool,
    isLoading: PropTypes.bool,
    createFunnelTemplate: PropTypes.func,
    createFunnelTemplateError: PropTypes.func,
    toggleLoading: PropTypes.func,
    history: PropTypes.func,
};
