import React from "react";
import PropTypes from "prop-types";

export const TextInput = ({ name, id, label, inputAddon, onChange, onClick, onBlur, onKeyPress, placeholder, value, error, errorMessage, maxLength = 200, btnText, note, className }) => {
    let wrapperClass = `form-group ${className}`;
    if (error || (error && error.length > 0)) {
        wrapperClass += " " + "has-error";
    }
    return (
        <div className={wrapperClass}>
            <label htmlFor={name}>{label}</label>
            {note &&
                <h6 className="share-note"><i>{note}</i></h6>
            }
            <div className="input-group full-width">
                <input
                    type="text"
                    name={name}
                    className="form-control"
                    placeholder={placeholder}
                    maxLength ={maxLength}
                    value={value}
                    onChange={onChange}
                    onBlur={onBlur}
                    onKeyPress={onKeyPress}
                    id={id ? id : ""}
                />
                {inputAddon &&
                    <a className="input-group-addon btn btn-primary save-btn" onClick={onClick}>{btnText}</a>
                }
                {error && <div className="alert alert-danger m-t-xs col-xs-12">{errorMessage}</div>}
            </div>
        </div>
    );
};

TextInput.propTypes = {
    name: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
    onBlur: PropTypes.func,
    onKeyPress: PropTypes.func,
    onClick: PropTypes.func,
    placeholder: PropTypes.string,
    value: PropTypes.string,
    btnText: PropTypes.string,
    note: PropTypes.string,
    id: PropTypes.string,
    error: PropTypes.bool,
    maxLength: PropTypes.number,
    inputAddon: PropTypes.bool,
    errorMessage: PropTypes.string,
    className: PropTypes.string,
};