import React, { Component } from "react";
import PropTypes from "prop-types";

import SelectExisting from "../SelectExistingTemplate";
import ExistOrNew from "../SelectExistOrNew";
import CreateEditMerchant from "./CreateEditMerchant";
import { getMerchantList } from "../utilities/api";

class Merchant extends Component {
    state = {
        isLoading: false,
        activeComponent: null
    };

    componentDidMount() {
        this.fetchMerchantList();
    }

    fetchMerchantList = () => {
        getMerchantList()
            .then((res) => {
                this.setState({
                    optionData: res.message
                });
            });
    }

    renderTemplate = () => {
        const { activeComponent, optionData } = this.state;
        const { moveStep, closeWizard, handleSkipButton } = this.props;
        switch (activeComponent) {
            case "NEW":
                return <CreateEditMerchant {...this.props} handleSkipButton={handleSkipButton} selectTemplate={this.selectTemplate} pageType="step-wizard" moveStep={moveStep}/>;
            case "EXISTING":
                return <SelectExisting {...this.props} closeWizard={closeWizard} optionData={optionData} selectTemplate={this.selectTemplate} labelText="Merchant" moveStep={moveStep} handleSkipButton={handleSkipButton}/>;
        }
    };

    selectTemplate = value => {
        this.setState({
            activeComponent: value
        });
    };

    render() {
        const { activeComponent } = this.state;
        const { handleSkipButton } = this.props;
        return (
            <div
                className="template_Area_container"
            >
                {activeComponent ? (
                    <div className="template_Area">{this.renderTemplate()}</div>
                ) : (
                    <ExistOrNew labelText={"Merchant"} selectTemplate={this.selectTemplate} handleSkipButton={handleSkipButton} />
                )}
            </div>
        );
    }
}

export default Merchant;

Merchant.propTypes = {
    moveStep: PropTypes.func,
    closeWizard: PropTypes.func,
    handleSkipButton: PropTypes.func
};