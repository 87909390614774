import React, { Component } from "react";
import { Button, Row, Col } from "react-bootstrap";
import PropTypes from "prop-types";

import Forms from "../../Forms";
import { getAffiliateList } from "../../utilities/api";

class Affliates extends Component {
    constructor(props) {
        super(props);
        this.state = {
            activeList: null,
            isLoading: false,
            type: null,
            affiliateList: null,
            affiliateDetails: null,
            campaignId: this.props.campaignId || this.props.funnelDetails && this.props.funnelDetails.campaignId,
            selectedAffiliates: []
        };
    }

    componentDidMount() {
        this.loadList();
        this.props.handleSkipButton && this.props.handleSkipButton(false);
    }

    loadList = () => {
        const { setSelectedAffiliates } = this.props;
        this.setState({
            isLoading: true
        });
        const data = {
            campaignId: this.state.campaignId
        };
        getAffiliateList(data)
            .then((res) => {
                let selectedAffiliates = Array.isArray(res.data.message) ? res.data.message.map((value) => value.clientSourceId) : [];
                this.setState({
                    affiliateList: Array.isArray(res.data.message) ? res.data.message : null,
                    affiliateDetails: null,
                    isLoading: false,
                    selectedAffiliates
                }, () => setSelectedAffiliates && setSelectedAffiliates(this.state.selectedAffiliates));
            })
            .catch((err) => {
                this.setState({
                    isLoading: false
                });
            });
    }

    activeComponent = (status, type, affiliate) => {
        this.setState({
            activeList: status,
            type: type,
            affiliate: affiliate
        });
    }

    clearActiveList = () => {
        this.setState({
            activeList: null
        });
    }

    renderRow = (affiliateList) => {
        const { pageType, selectTemplate } = this.props;
        return affiliateList && (affiliateList instanceof Array) && affiliateList.map((affiliate, i) => (
            <tr className="edit-funnel-divider">
                <td className="edit-funnel-table-data font-weight-normal">{affiliate.clientSourceId}</td>
                <td className="edit-funnel-table-data font-weight-normal">{affiliate.sourceTitle}</td>
                <td>
                    <Button
                        type="primary btn-height"
                        className="button"
                        disabled={this.state.isLoading}
                        onClick={() => {pageType === "step-wizard" ? selectTemplate("EDIT", affiliate) : this.activeComponent("CreateEditAffiliates", "EDIT", affiliate);}}
                    >
                        <i class="fa fa-pencil edit-pencil-color"></i>
                    </Button>
                </td>
            </tr>
        ));
    }

    render() {
        const { pageType, selectTemplate } = this.props;
        const { activeList, affiliateList, isLoading, affiliate, type, campaignId, selectedAffiliates } = this.state;
        return (
            <div>
                {
                    pageType !== "step-wizard" ? (
                        <div className="modal-header-funnel-edit">
                            Affiliates
                        </div>
                    ) : null
                }
                <div className={`table-overflow-scroll ${pageType === "step-wizard" ? "max-height-100" : ""}`}>
                    <Row className={`plr-50 pt-20${pageType === "step-wizard" ? " m-0" : ""}`}>
                        <Col md={2} className="affliliate-heading">
                            {pageType === "step-wizard" ? "Affiliate" : null}
                        </Col>
                        <Col md={10} className="pr-0 pb-20">
                            {pageType === "step-wizard" ? (
                                <div>
                                    <Button
                                        type="primary"
                                        className="button btn-height primary-button-color float-right"
                                        onClick={() => selectTemplate("NEW")}
                                    >
                                        Add New Affiliate
                                    </Button>
                                    <Button
                                        type="primary"
                                        className="button btn-height primary-button-color float-right"
                                        onClick={() => selectTemplate("EXISTING")}
                                    >
                                        Add Existing Affiliate
                                    </Button>
                                </div>
                            ) : (
                                <div>
                                    <Button
                                        type="primary"
                                        className="button btn-height primary-button-color float-right"
                                        onClick={() => this.activeComponent("CreateEditAffiliates", "ADD")}
                                    >
                                            Add New Affiliate
                                    </Button>
                                    <Button
                                        type="primary"
                                        className="button btn-height primary-button-color float-right"
                                        onClick={() => this.activeComponent("SelectExisting", "Existing")}
                                    >
                                        Add Existing Affiliate
                                    </Button>
                                </div>
                            )
                            }
                        </Col>
                        <table className="edit-funnel-table">
                            <thead>
                                <tr className="edit-funnel-table-head">
                                    <th className={`width-35 edit-funnel-merchant-table-heading ${pageType === "step-wizard" ? " text-left" : null}`}>Id</th>
                                    <th className={`width-35 edit-funnel-merchant-table-heading ${pageType === "step-wizard" ? " text-left" : null}`}>Title</th>
                                    <th></th>
                                </tr>
                            </thead>

                            <tbody>
                                { isLoading ?
                                    <tr className="position_absolute_flex_center">
                                        <div className={"loading_box"}>
                                            <span
                                                className="loading_spinner"
                                            ></span>
                                            <span>Loading</span>
                                        </div>
                                    </tr> :
                                    affiliateList && affiliateList.length !== 0 ? this.renderRow(affiliateList) :
                                        <tr className="edit-funnel-divider">
                                            <td colSpan={3} className="edit-funnel-table-data">No Affiliate Found</td>
                                        </tr>
                                }
                            </tbody>
                        </table>
                    </Row>
                </div>

                <Row className={`plr-50 pt-20${pageType === "step-wizard" ? " m-0" : ""}`}>
                    <Col md={6} className="pl-0">
                    </Col>
                    <Col md={6} className="pr-0 pb-10">
                        {
                            pageType === "step-wizard" ? (
                                <Button
                                    onClick={() => this.props.moveStep("NEXT")}
                                    type="primary"
                                    className="button btn-height float-right"
                                >
                                    Continue
                                </Button>
                            ) : (
                                <Button
                                    onClick={(e) => this.props.closeModal(e)}
                                    type="primary"
                                    className="button btn-height float-right"
                                >
                                    Cancel
                                </Button>
                            )
                        }
                    </Col>
                </Row>

                {type !== "step-wizard" && <Forms selectedAffiliates={selectedAffiliates} isAffiliate refreshData={this.loadList} {...this.props} pageType={type} campaignId={campaignId} refreshData={this.loadList} activeComponent={activeList} affiliate={affiliate} clearActiveList={this.clearActiveList} title={`${this.state.type === "ADD" ? "Create New" : `${this.state.type === "Existing" ? "Select Existing" : "Edit" }`} Affiliate`} />}
            </div>
        );
    }
}

export default Affliates;

Affliates.propTypes = {
    closeModal: PropTypes.func,
    pageType: PropTypes.string,
    selectTemplate: PropTypes.func,
    funnelDetails: PropTypes.array,
    campaignId: PropTypes.number,
    handleSkipButton: PropTypes.func,
    moveStep: PropTypes.func,
    setSelectedAffiliates: PropTypes.func
};