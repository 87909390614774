import React, { Component } from "react";
import PropTypes from "prop-types";
import "../../../../../node_modules/jointjs/dist/joint.css";

export class VisualizerDomain extends Component {
    static propTypes = {
        getDomainListForUser: PropTypes.func,
        domainList: PropTypes.array,
        selectedDomainForFunnel: PropTypes.string,
        publishFunnel: PropTypes.func,
        funnel: PropTypes.object,
        setDomainForFunnel: PropTypes.func,
        shareFunnel: PropTypes.func,
        updateVisualizerData: PropTypes.func,
        visualizerData: PropTypes.object,
        clearDomainForFunnelData: PropTypes.func,
    }
    constructor() {
        super();
    }
    componentDidMount() {
        const { funnel } = this.props;
        funnel
            && funnel.referenceId
            && this.props.getDomainListForUser(funnel.referenceId);
    }

    componentWillUnmount() {
        this.props.clearDomainForFunnelData();
    }

    onChangeDomainSelection = (e) => {
        const selectedDomainForFunnel = String(e.target.value);
        const selectedDomainAndFunnelId = {};
        const { funnel } = this.props;
        if (selectedDomainForFunnel !== "defaultDomain") {
            selectedDomainAndFunnelId.domainReferenceId = selectedDomainForFunnel;
            selectedDomainAndFunnelId.funnelReferenceId = funnel.referenceId;
            this.props.setDomainForFunnel(selectedDomainAndFunnelId);
            this.props.updateVisualizerData({
                selectedDomainForFunnel: selectedDomainForFunnel
            });
        } else if (selectedDomainForFunnel === "defaultDomain") {
            const {
                selectedDomainForFunnel
            } = this.props.visualizerData;
            selectedDomainAndFunnelId.domainReferenceId = selectedDomainForFunnel;
            selectedDomainAndFunnelId.funnelReferenceId = null;
            this.props.setDomainForFunnel(selectedDomainAndFunnelId);
        }
    }
    publishFunnel = () => {
        const { funnel,publishFunnel } = this.props;
        funnel
            && funnel.referenceId
            && publishFunnel(funnel.referenceId);
    }
    render() {

        const {
            shareFunnel,
            domainList,
            funnel
        } = this.props;
        const {
            selectedDomainForFunnel
        } = this.props.visualizerData;
        const domainListDropDown = domainList &&
            domainList.map((domain) =>
                <option
                    key={domain.referenceId ? domain.referenceId : "defaultDomain"}
                    value={domain.referenceId ? domain.referenceId : "defaultDomain"}
                >
                    {domain.name}
                </option>
            );

        const isDefaultPageSelected = funnel && funnel.page &&
            funnel.page.find((page) => page.pageView[0].defaultPage === true) ?
            true :
            false;
        const allPagesEditedCheck = funnel && funnel.page &&
            funnel.page.find((page) => page.isEdited === false) ?
            false :
            true;
        const appendHttps = "https://";
        const selectedDomainDetails = domainList && domainList.find((list) => list.selected);
        const selectedDomainName = selectedDomainDetails && selectedDomainDetails.name;
        const liveSiteUrl = selectedDomainName && appendHttps.concat(selectedDomainName);
        const publishFunnelClass = !isDefaultPageSelected || !allPagesEditedCheck ? "btn btn-primary m-l-xs pull-right disabled" : "btn btn-primary m-l-xs pull-right";
        const shareFunnelClass = funnel && funnel.page && funnel.page.length ? "btn btn-primary m-l-xs pull-right" : "btn btn-primary m-l-xs pull-right disabled";
        const isFunnelPublished = funnel.status;
        const previewBtn = funnel.status ? "input-group-addon badge-primary" : "input-group-addon badge-primary disabled";
        return (
            <>
                <button onClick={shareFunnel} className={shareFunnelClass}><i className="fa fa-share-square-o m-r-xs" aria-hidden="true"></i> Share</button>
                <button onClick={this.publishFunnel} className={publishFunnelClass}><i class="fa fa-cloud-upload" aria-hidden="true"></i> Publish</button>
                <div className="input-group col-xs-6 pull-right">
                    <div className="input-group-addon">Domain URL</div>
                    <select className="form-control"
                        value={selectedDomainForFunnel ? selectedDomainForFunnel : "defaultDomain"}
                        onChange={this.onChangeDomainSelection}
                        disabled={!isFunnelPublished}>
                        {domainListDropDown}
                    </select>
                    <div className={previewBtn} ><a className="text-white" href={liveSiteUrl} target="_blank"><i class="fa fa-globe" aria-hidden="true" /> Live site</a></div>
                </div>
            </>
        );
    }
}