import * as actions from "./actions";
import { FunnelReducer, Page } from "../models";

export const FUNNEL_REDUCER_KEY = "FUNNEL";

const initialPages = [];

const initialState = new FunnelReducer();

export function reducer(state = initialState, action) {
    switch (action.type) {
        case actions.IS_LOADING:
            return {
                ...state,
                isLoading: action.status
            };
        case actions.IS_FONT_LOADING:
            return {
                ...state,
                isFontLoading: action.status
            };
        case actions.SAVE_PAGE_VIEW_SUCCESS:
            return {
                ...state,
                isSaveLoading: action.status
            };
        case actions.GET_FUNNEL_SUCCESS: {
            return {
                ...state,
                funnelData: action.payload.funnelData
            };
        }
        case actions.UPDATE_FUNNEL_SUCCESS: {
            return {
                ...state,
                funnelData: (state.funnelData.referenceId === action.funnel.referenceId) ? action.funnel : ""
            };
        }
        case actions.SPLIT_DUPLICATE_SUCCESS: {
            return {
                ...state,
                funnelData: (state.funnelData.referenceId === action.funnel.referenceId) ? action.funnel : ""
            };
        }
        case actions.UPDATE_PAGE_SUCCESS: {
            return {
                ...state,
                funnelData: (state.funnelData.referenceId === action.funnel.referenceId) ? action.funnel : ""
            };
        }
        case actions.LOAD_GOOGLE_FONT_SUCCESS: {
            return {
                ...state,
                googleFonts: action.payload ? action.payload : ""
            };
        }
        case actions.LOAD_METAJSON_SUCCESS: {
            return {
                ...state,
                metaUrl: action.payload ? action.payload : ""
            };
        }
        case actions.LOAD_BUTTONS_SUCCESS: {
            return {
                ...state,
                pageData: {
                    ...state.pageData,
                    buttonData: action.payload.buttons,
                    linkData: action.payload.links,
                    jsPageData: action.payload.jsPageData ? action.payload.jsPageData : []
                }
            };
        }
        case actions.CLEAR_PAGE_DATA: {
            return {
                ...state,
                pageData: {}
            };
        }
        case actions.CLEAR_FUNNEL_SUCCESS: {
            return {
                ...state,
                metaUrl: null
            };
        }
        case actions.CLEAR_JSON_DATA: {
            return {
                ...state,
                jsonData: ""
            };
        }
        case actions.LOAD_FUNNEL_SUCCESS: {
            return {
                ...state,
                funnelData: action.payload ? action.payload : "",
            };
        }
        case actions.SET_CURRENT_PAGE_ID: {
            const { currentPageId } = action;
            const newFunnelData = new FunnelReducer([...state.pages], currentPageId, state.funnelData);
            return newFunnelData;
        }
        case actions.EDIT_FUNNEL_SUCCESS: {
            return {
                ...state,
                funnelData: action.funnel
            };
        }
        case actions.IMPORT_PAGE_SUCCESS: {
            return {
                ...state,
                importedPage: action.payload
            };
        }
        case actions.GET_META_URL_SUCCESS: {
            return {
                ...state,
                metaUrl: action.payload
            };
        }
        case actions.EDIT_FUNNEL_ERROR: {
            return {
                ...state,
                isError: action.isError
            };
        }
        case actions.CREATE_PAGE_ERROR: {
            return {
                ...state,
                pageError: action.pageError
            };
        }
        case actions.IMPORT_PAGE_ERROR: {
            return {
                ...state,
                importError: action.importError
            };
        }
        case actions.FUNNEL_PUBLISH_ERROR: {
            return {
                ...state,
                funnelData: action.funnel
            };
        }
        case actions.GET_TEMPLATE_SUCCESS: {
            return {
                ...state,
                htmlPage: action.htmlPage
            };
        }
        case actions.LOAD_PAGE_VIEW_SUCCESS: {
            return {
                ...state,
                pageData: action.pageData,
            };
        }
        case actions.GET_JSON_SUCCESS: {
            return {
                ...state,
                jsonData: action.payload
            };
        }
        case actions.GET_PAGE_SUCCESS: {
            return {
                ...state,
                pages: action.payload.pages
            };
        }
        case actions.GET_FONTS_SUCCESS: {
            return {
                ...state,
                fonts: action.payload
            };
        }
        case actions.LOAD_DOMAIN_LIST_SUCCESS: {
            return {
                ...state,
                domainList: action.domainList
            };
        }
        case actions.SET_DOMAIN_FOR_FUNNEL_SUCCESS: {
            return {
                ...state,
                selectedDomainForFunnel: action.selectedDomainReferenceId
            };
        }
        case actions.ADD_SET_DOMAIN_FOR_FUNNEL_SUCCESS: {
            return {
                ...state,
                addedSelectedDomainReferenceId: action.addedSelectedDomainReferenceId
            };
        }
        case actions.SET_DOMAIN_FOR_FUNNEL_ERROR: {
            return {
                ...state,
                selectedDomainForFunnelError: action.selectedDomainForFunnelError
            };
        }
        case actions.CLEAR_DOMAIN_FOR_FUNNEL_SUCCESS: {
            return {
                ...state,
                selectedDomainForFunnel: "",
                domainList: []
            };
        }
        case actions.IMPORT_FUNNEL_SUCCESS: {
            return {
                ...state,
                importedFunnel: action.payload,
                funnelData: action.payload
            };
        }
        case actions.IMPORT_FUNNEL_ERROR: {
            return {
                ...state,
                importFunnelError: action.importFunnelError
            };
        }
        case actions.IS_IMPORT_FUNNEL_LOADING: {
            return {
                ...state,
                isImportFunnelLoading: action.status
            };
        }
        case actions.IMPORT_FUNNEL_SUCCESS_MESSAGE: {
            return {
                ...state,
                showImportFunnelSuccessMessage: action.showImportFunnelSuccessMessage
            };
        }
        case actions.DEFAULT_FUNNEL_SUCCESS_MESSAGE: {
            return {
                ...state,
                defaultFunnelTemplate: action.payload.defaultFunnelTemplate
            };
        }
        case actions.SET_SPLIT_SETTINGS_STATUS: {
            return {
                ...state,
                setSplitSettingsView: action.status
            };
        }
        default:
            return state;
    }
}