import { providerUrl } from "./urls";
import { APIHelper } from "../utilities";
import axios from "axios";

export function getCampaigns() {
    return APIHelper.baseRequest(APIHelper.POST, `${providerUrl}/Dashboard/CampaignQuery`, {
        headers: APIHelper.getGenericHeaders(),
        requestBody: {
            resultsPerPage: 1000
        }
    });
}
export function getProducts() {
    return APIHelper.baseRequest(APIHelper.POST, `${providerUrl}/Campaign/ProductQuery`, {
        headers: APIHelper.getGenericHeaders(),
        requestBody: {
            allProducts: 1
        }
    });
}
export function getMerchants() {
    return APIHelper.baseRequest(APIHelper.POST, `${providerUrl}/Campaign/MerchantList`, {
        headers: APIHelper.getGenericHeaders(),
        requestBody: {
            loadBalancerId: 999
        }
    });
}
export function saveCampaign(campaign, upsells, offers) {
    for (let props in campaign) {
        if (campaign[props] === "") {
            campaign[props] = null;
        }
    }
    upsells.forEach((upsell, index) => {
        const cycles = upsell.cycle;
        cycles.forEach((value, index) => {
            if (value.price === "") {
                delete cycles[index];
            }
            if (value.productId === "") {
                delete value.productId;
            }
            if (value.combinationId === "") {
                delete value.combinationId;
            }
        });
        for (let props in upsell) {
            if (upsell[props] === "") {
                if (upsell.hasOwnProperty(props))
                    upsell[props] = null;
            } else if (upsell.productId === "choose") {
                upsells.splice(upsell, 1);
            }
        }
    });
    offers.forEach(offer => {
        const cycles = offer.cycle;
        cycles.forEach((value, index) => {
            if (value.price === "") {
                delete cycles[index];
            }
            if (value.productId === "") {
                delete value.productId;
            }
            if (value.combinationId === "") {
                delete value.combinationId;
            }
        });
        for (let props in offer) {
            if (offer.hasOwnProperty(props)) {
                if (offer[props] === "") {
                    offer[props] = null;
                }
            }
        }
    });
    const json = JSON.stringify({ campaign, upsells, offers });
    let formData = new FormData();
    formData.append("json", json);

    return axios.post(`${providerUrl}/Campaign/CreateCampaign`, formData, { headers: APIHelper.getGenericHeaders() });
    // return APIHelper.baseRequest(APIHelper.POST, `${providerUrl}/Campaign/CreateCampaign`, {
    //     headers: APIHelper.getGenericHeaders(),
    //     contentType: APIHelper.getMultipartReqHeader(),
    //     requestBody: formData
    // });
}
