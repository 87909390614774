import isFunction from "lodash/isFunction";

const registerEvents = (element, events = {}) => {
    Object.keys(events).forEach(eventName => {
        const callbackFn = events[eventName];
        if (isFunction(callbackFn)) {
            element.on(eventName, function (...args) {
                callbackFn(element, ...args);
            });
        }
    });
};

export default registerEvents;