import React, { useContext, useState } from "react";
import PropTypes from "prop-types";

import { VisualizerContext } from "./";

import {
    VisualizerRightPanel,
    SplitTest,
    RightPanelCommonComponents,
} from "./components/VisualizerRightPanel";

import {
    getPageLiveurl,
    getTemplateTypeId,
    filterByTemplateTypeId,
    getCurrentPageFromFunnelData,
} from "./utilities/helpers";
import { PageAnalytics } from "../create-funnel-visualizer/components/page-hits-and-counts";
import { getAnalyticsData } from "../create-funnel-visualizer/actions";

const DefaultRightPanel = ({
    currentPage,
    duplicatePage,
    onSplitTemplateSelected,
    showSplitTest,
    selectedFonts,
    fontLoader
}) => {
    const VisualizerContextData = useContext(VisualizerContext);
    const {
        funnelData,
        googleFontsList,
        freeTemplates,
    } = VisualizerContextData;
    const currentPageTemplateTypeId = getTemplateTypeId(currentPage);
    const templateList = filterByTemplateTypeId(freeTemplates, currentPageTemplateTypeId);
    const pageLiveUrl = getPageLiveurl(funnelData.page, currentPage);
    const pages = [getCurrentPageFromFunnelData(funnelData.page, currentPage)];
    const getPageAnalytics = ({ funnelReferenceId, pageReferenceId, startDate, endDate }) => {
        return getAnalyticsData({
            funnelReferenceId,
            pageReferenceId,
            startDate,
            endDate,
            details: true
        });
    };
    return (
        <VisualizerRightPanel>
            <RightPanelCommonComponents
                fontList={googleFontsList}
                currentPage = {currentPage}
                selectedFonts = {selectedFonts}
                fontLoader = {fontLoader}
            />
            {
                showSplitTest && (
                    <SplitTest
                        duplicatePage = {duplicatePage}
                        templateList={templateList}
                        onSplitTemplateSelected={onSplitTemplateSelected}
                    />
                )
            }
            <div className="settings-analytics-wrapper">
                <PageAnalytics getAnalyticsData={getPageAnalytics} funnel={funnelData} pages={pages} />
            </div>
        </VisualizerRightPanel>
    );
};

DefaultRightPanel.propTypes = {
    currentPage: PropTypes.object,
    funnelData: PropTypes.object,
    googleFontsList: PropTypes.object,
    duplicatePage: PropTypes.func,
    onSplitTemplateSelected: PropTypes.func,
    freeTemplates: PropTypes.array,
    showSplitTest: PropTypes.bool,
    selectedFonts: PropTypes.array,
    fontLoader: PropTypes.bool
};

export default DefaultRightPanel;