import React from "react";
import PropTypes from "prop-types";
import "./style.css";
import { Modal } from "react-bootstrap";
import Stepzilla from "react-stepzilla";
import "../../../node_modules/react-stepzilla/src/css/main.css";
import { Step1 } from "./steps/Step1";
import { Step3 } from "./steps/Step3";
import { Step2 } from "./steps/Step2";

export class FunnelTypes extends React.Component {
    constructor(props, context) {
        super(props, context);
        this.state = {
            funnelType: Object.assign({}, props.funnelTypes),
            show: true
        };
        this.props.loadFunnelTypes();
        this.handleClose = this.handleClose.bind(this);
        this.handleShow = this.handleShow.bind(this);
        this.konnektions = this.konnektions.bind(this);
    }
    componentWillReceiveProps(nextProps) {
        if (this.props.funnelTypes !== nextProps.funnelTypes) {
            this.setState({ funnelTypes: Object.assign({}, nextProps.funnelTypes) });
        }
    }
    konnektions() {
        this.props.history.push("/konnektions");
    }
    handleClose() {
        this.setState({ show: false });
    }
    handleShow() {
        setTimeout(() => {
            this.setState({ show: true });
        }, 3000);
    }
    render() {
        const steps = [
            { name: "Choose Goal", component: <Step1/> },
            { name: "Choose Type", component: <Step2/> },
            { name: "Build Funnel", component: <Step3/> }
        ];

        const { funnelTypes } = this.props;
        return (
            <div className="container" style={{ backgroundColor: "white", marginTop: "2%", borderRadius: "1%" }}>
                <div className="text-center" style={{ padding: "2%" }}>
                    <h3>Step 1 of 2</h3>
                    <h4>Select a funnel type</h4>
                    <hr/>
                </div>
                <div className="row" style={{ padding: "2%" }}>
                    <div className="col-md-2 ">
                        <form className="form ui">
                            <div className="grouped inline fields">
                                <h4 className="ui header">Industry</h4>
                                <div className="field">
                                    <div className="ui checkbox">
                                        <input type="checkbox"/>
                                        <label>Author /Speaker /Coach /Consultant</label>
                                    </div>
                                </div>
                                <div className="field">
                                    <div className="ui checkbox">
                                        <input type="checkbox"/>
                                        <label>Professional Services</label>
                                    </div>
                                </div>
                                <div className="field">
                                    <div className="ui checkbox">
                                        <input type="checkbox"/>
                                        <label>Retail</label>
                                    </div>
                                </div>
                                <div className="field">
                                    <div className="ui checkbox">
                                        <input type="checkbox"/>
                                        <label>Ecommerce</label>
                                    </div>
                                </div>
                                <div className="field">
                                    <div className="ui checkbox">
                                        <input type="checkbox"/>
                                        <label>B2B</label>
                                    </div>
                                </div>
                                <div className="field">
                                    <div className="ui checkbox">
                                        <input type="checkbox"/>
                                        <label>Network Marketing</label>
                                    </div>
                                </div>
                                <div className="field">
                                    <div className="ui checkbox">
                                        <input type="checkbox"/>
                                        <label>Other</label>
                                    </div>
                                </div>
                            </div>
                            {/*type of funnel*/}
                            <div className="grouped inline fields">
                                <h4 className="ui header">Type of Funnel</h4>
                                <div className="field">
                                    <div className="ui checkbox">
                                        <input type="checkbox"/>
                                        <label>Generate Lead</label>
                                    </div>
                                </div>
                                <div className="field">
                                    <div className="ui checkbox">
                                        <input type="checkbox"/>
                                        <label>Sell A Product</label>
                                    </div>
                                </div>
                                <div className="field">
                                    <div className="ui checkbox">
                                        <input type="checkbox"/>
                                        <label>Create An Event</label>
                                    </div>
                                </div>
                                <div className="field">
                                    <div className="ui checkbox">
                                        <input type="checkbox"/>
                                        <label>Other</label>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                    {/*begin funnel type thumbnails*/}
                    <div className="col-md-10">
                        { funnelTypes ? funnelTypes.map(funnelType => (
                            <div key={funnelType.id} className="col-md-4 col-xs-12">
                                <div className="ibox box">
                                    <img src={funnelType.img} alt="this is an image" height="100%" width="100%" />
                                    <div className="ibox-content">
                                        <div>
                                            <div >
                                                <p className="product-name">{funnelType.title}</p>
                                                <div className="m-t">
                                                    <p>{funnelType.description}</p>
                                                    {/* <Link to={"marketplace/" + template} onClick={this.props.onTemplateSelected} className="btn btn-xs btn-outline btn-primary">Choose Template</Link> */}
                                                    <button className="btn btn-xs btn-outline btn-primary" onClick={this.konnektions}>Choose Funnel</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )) : <p>No Funnel Types</p>
                        }
                    </div>
                </div>
                <div className="animated fadeIn">
                    <Modal show={this.state.show} backdrop = "static" onHide={this.handleClose} bsSize="large" aria-labelledby="contained-modal-title-lg">
                        <Modal.Header closeButton style={{ backgroundColor: "rgb(47, 64, 77)" }} />
                        <Modal.Body>
                            <div className="step-progress">
                                <Stepzilla steps={steps} prevBtnOnLastStep={false} showNavigation={false} />
                            </div>
                        </Modal.Body>
                    </Modal>
                </div>
            </div>
        );
    }
}
FunnelTypes.propTypes = {
    loadFunnelTypes: PropTypes.func,
    funnelTypes: PropTypes.object,
    history: PropTypes.object,
};
