import React from "react";
import PropTypes from "prop-types";
import {
    getPageLiveUrl
} from "../../utilities/helpers";

export const LiveUrl = ({
    domainList,
    currentPage,
    chosenAlphabet = "A",
}) => {
    const pageViewReferenceId = chosenAlphabet === "A" ?
        currentPage.get("pageViewReferenceId") :
        currentPage.get("splitPageAttributes").pageViewReferenceId;
    const pageLiveUrl = getPageLiveUrl(domainList, pageViewReferenceId);
    return (
        <>
            <div className="form-group">
                <div className="input-group">
                    <label className="input-group-addon">Live URL</label>
                    <input className="form-control" name="productName" value={pageLiveUrl} type="text" />
                    <div className="input-group-addon badge-primary" ><a className="text-white" href={pageLiveUrl} target="_blank"><i className="fa fa-external-link" aria-hidden="true" /></a></div>
                </div>
            </div>
        </>
    );
};
LiveUrl.propTypes = {
    currentPage: PropTypes.object,
    domainList: PropTypes.array,
    chosenAlphabet: PropTypes.string
};