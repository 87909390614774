
export class WebpageFormat {
    constructor(html = "", css = "", pageData = {}, userData = {}) {
        this._html = html;
        this._css = css;
        this._pageData = pageData;
        this._userData = userData;
    }

    get html() {
        return this._html;
    }
    set html(html) {
        this._html = html;
    }

    get css() {
        return this._css;
    }
    set css(css) {
        this._css = css;
    }

    get pageData() {
        return this._pageData;
    }
    set pageData(data) {
        this._pageData = data;
    }

    getPageData() {
        let pageData = Object.keys(this.pageData).length > 0 && `var pageData = ${JSON.stringify(this.pageData)}`;
        return pageData;
    }

    getFormattedWebPage() {
        let pageHtml = new DOMParser().parseFromString(this._html, "text/html");
        let scriptElement = document.createElement("script");
        scriptElement.id = "scriptData";
        scriptElement.innerHTML = `$(document).ready(function(){
            $("#loading").hide();
        });
        var country = document.getElementById("country");
        var shipCountry = document.getElementById("shipCountry");
        var newArr = [];
        var newArr2 = [];
        var countryArr = [];
        var countryArr2 = [];
        $.getJSON('https://s3.us-east-2.amazonaws.com/konnektfunnels-assets/countries.json', function (data) {
            data.forEach(function(value) {

                if(value.countryCode === "US") {
                    countryArr.unshift(value);
                    $("#state").append($('<option>', {value: value.stateCode, text: value.stateName}));
                } else {
                    countryArr.push(value);
                }
            
            });
            countryArr.forEach(function(cities, i) {
                if(countryArr[i + 1] && cities.countryName !== countryArr[i + 1].countryName) {
                    $("#country").append($('<option>', {value: cities.countryCode, text: cities.countryName}));
                }
            })
        });
        $.getJSON('https://s3.us-east-2.amazonaws.com/konnektfunnels-assets/countries.json', function (data) {
            data.forEach(function(value) {
                if(value.countryCode === "US") {
                    countryArr2.unshift(value);
                    $("#shipState").append($('<option>', {value: value.stateCode, text: value.stateName}));
                } else {
                    countryArr2.push(value);
                }
            });
            countryArr2.forEach(function(cities, i) {
                if(countryArr2[i + 1] && cities.countryName !== countryArr2[i + 1].countryName) {
                    $("#shipCountry").append($('<option>', {value: cities.countryCode, text: cities.countryName}));
                }
            })
        
        });
        function changeCountry() {
            const countries = $.getJSON('https://s3.us-east-2.amazonaws.com/konnektfunnels-assets/countries.json', function (data) {
                var select =$('#state');


                newArr = [];
                newArr2 = [];
                data.forEach(function(value) {
                    if(value.countryCode === country.value) {
                        select.empty();
                        newArr.push(value);
                    }
                });
                newArr.forEach(function(cities) {
                    $("#state").append($('<option>', {value: cities.stateCode, text: cities.stateName}));

                });

            });
        
        }
        function changeCountry2() {
            const countries = $.getJSON('https://s3.us-east-2.amazonaws.com/konnektfunnels-assets/countries.json', function (data) {

                var select2 = $('#shipState');

                newArr = [];
                newArr2 = [];
                data.forEach(function(value) {
                    if (value.countryCode === shipCountry.value) {
                        select2.empty();
                        newArr2.push(value);
                    }
                });
                newArr2.forEach(function(cities) {

                    $("#shipState").append($('<option>', {value: cities.stateCode, text: cities.stateName}));
                })
            });
        
        }
        function getCurrentPage(funnelData) {
            var funnelPages = funnelData.pages;
            if (funnelPages && funnelPages.length) {
                const matchedPage = $.grep(funnelPages, function(page) {
                    const currentPageView = page && page.pageView && page.pageView[0];
                    if (currentPageView) {
                        return currentPageView.referenceId === pageData.pageViewReferenceId;
                    }
                });
                return matchedPage && matchedPage[0];

            }
            return null;
        }

        (function fetchPageData() {
            const funnelReferenceId = pageData.funnelReferenceId;
            $.ajax({
                url: 'funnel.json?'+Date.now(),
                method: 'get',
                success: function (results) {
                    var funnelData = results.funnelData;
                    pageData.funnelData = funnelData;
                    pageData.funnelData.isVisualizer = funnelData.isVisualizer || false;
                    pageData.currentPage = getCurrentPage(funnelData);
                    pageData.templateTypeId = pageData.currentPage && pageData.currentPage.pageView[0].templateTypeId;
                    pageData.salt = funnelData.salt;
                    pageData.navigationItems = results.elements;
                    $.ajax({
                        url: 'campaign.json?'+Date.now(),
                        success: function(campaignResults) {
                            pageData.selectedCampaign = campaignResults.selectedCampaign;
                            initializePage();
                        }
                    }); 
                }
            });
        }());

        function initializePage() {
            if (pageData) {
                //import click need to be called on the load of every page, so I added this function to the template
                var pageType =  pageData.templateTypeId;
                switch (pageType) {
                    // Lead page
                    case 2:
                    case "Lead":
                        pageType = "leadPage"
                        break;
                    // Checkout Page
                    case 4:
                    case "Checkout":
                        pageType = "checkoutPage"
                        break;
                    // Upsell Page
                    case 3: 
                    case "Upsell":
                        pageType = "upsellPage"
                        break;
                    // Thank You Page
                    case 5: 
                    case "Thank You":
                        pageType = "thankyouPage"
                        break;
                    default:
                        pageType = "presellPage"   
                }
                
                var session = sessionStorage.getItem("sessionId");
                if (session) {
                    session = JSON.parse(session);
                    useSession(session.message.sessionId, pageType).then(function(data) {
                        if(data.result === "ERROR") {
                            $("body").append(useSession.message);
                        }
                    });
                } else {
                    importClick(pageType).then(function(data) {
                        if(data.result === "SUCCESS") {
                            var jsonStr = JSON.stringify(data);
                            sessionStorage.setItem("sessionId", jsonStr);
                        } else {
                            $("body").append(data.message);
                        }
                        response = data;
                    });
                }
            
            }
            let actualPageType = pageData.funnelData.isVisualizer ? pageData.templateTypeId : pageData.currentPage.title;
            const membershipPageTypes = [8,9,10,11,12,13];
            const isMemberPageType = membershipPageTypes && membershipPageTypes.find(page => page === actualPageType);
            if(isMemberPageType) {
                validateSession();
            }
            if (window && window.performance && window.performance.navigation && window.performance.navigation.type === 0) {
                registerClick(0);
            }
            if (pageType === "checkoutPage"){
                displayPrices();
            }
        }
        `;
        const pageDataScript = pageHtml.getElementById("pageDataScript");
        if (pageHtml && pageDataScript) {
            pageDataScript.innerHTML = `${this.getPageData()}`;
            // This is a temporary fix, code responsible for adding script data should be identified within the builder and fixed
            if (pageHtml && pageHtml.getElementById("scriptData")) {
                pageHtml.getElementById("scriptData").innerHTML = scriptElement.innerHTML;
            }
        } else {
            let pageDataElement = document.createElement("script");
            pageDataElement.id = "pageDataScript";
            pageDataElement.innerHTML = `${this.getPageData()};`;
            pageHtml.getElementsByTagName("body")[0].append(pageDataElement);
        }
        let htmlheadElement = `<meta charSet='utf-8'>
        <meta http-equiv="Cache-Control" content="no-cache, no-store, must-revalidate">
        <meta http-equiv="Pragma" content="no-cache">
        <meta http-equiv="Expires" content="0">
        <meta name='viewport' content='width=device-width, initial-scale=1.0' charSet='utf-8'>
        <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/animate.css/3.7.0/animate.css">
        <script src="https://cdnjs.cloudflare.com/ajax/libs/jquery/3.2.1/jquery.min.js"></script>
        <link rel='stylesheet' href='https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.css'/>
        <script src="index.js"></script>`;
        if (pageHtml && !(pageHtml.getElementById("scriptData"))) {
            pageHtml.getElementsByTagName("head")[0].innerHTML += htmlheadElement;
        }
        let styleElement = document.createElement("style");
        styleElement.id = "styleElement";
        styleElement.innerHTML = `${this._css}`;
        if (styleElement.innerHTML) {
            if (pageHtml.getElementById("styleElement")) {
                pageHtml.getElementById("styleElement").remove();
                pageHtml.getElementsByTagName("head")[0].append(styleElement);
            } else {
                pageHtml.getElementsByTagName("head")[0].append(styleElement);
            }
        }
        pageHtml && !(pageHtml.getElementById("scriptData")) ? pageHtml.getElementsByTagName("body")[0].append(scriptElement) : "";
        const webTemplate = pageHtml.getElementsByTagName("html")[0].outerHTML;
        return webTemplate;
    }
}
