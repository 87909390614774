import * as api from "../api/funnelApi";
import { getCampaigns } from "../api/campaignsApi";
import { APIHelper, LocalStorageHelper } from "../utilities";
import * as campaignApi from "../api/campaignsApi";
// import { loginError } from "../login/actions";

const FUNNELS = "funnels/";
export const IS_LOADING = `${FUNNELS}IS_LOADING`;
export const LOAD_FUNNELS_SUCCESS = `${FUNNELS}LOAD_FUNNELS_SUCCESS`;
export const CREATE_FUNNEL_SUCCESS = `${FUNNELS}CREATE_FUNNEL_SUCCESS`;
export const CREATE_FUNNEL_ERROR = `${FUNNELS}CREATE_FUNNEL_ERROR`;
export const EDIT_FUNNEL_SUCCESS = `${FUNNELS}EDIT_FUNNEL_SUCCESS`;
export const EDIT_FUNNEL_ERROR = `${FUNNELS}EDIT_FUNNEL_ERROR`;
export const UPDATE_FUNNEL_SUCCESS = `${FUNNELS}UPDATE_FUNNEL_SUCCESS`;
export const LOAD_CAMPAIGNS_SUCCESS = `${FUNNELS}LOAD_CAMPAIGNS_SUCCESS`;
export const LOAD_PAGE_SUCCESS = `${FUNNELS}LOAD_PAGE_SUCCESS`;
export const LOAD_PRODUCTS_SUCCESS = "LOAD_PRODUCTS_SUCCESS";
export const LOAD_MERCHANTS_SUCCESS = "LOAD_MERCHANTS_SUCCESS";
export const CREATE_CAMPAIGN_SUCCESS = "CREATE_CAMPAIGN_SUCCESS";
export const CREATE_CAMPAIGN_ERROR = "CREATE_CAMPAIGN_ERROR";
export const DEFAULT_FUNNELS_SUCCESS_MESSAGE = "DEFAULT_FUNNELS_SUCCESS_MESSAGE";
export const LOAD_PREMIMUMFUNNELTEMPLATES_SUCCESS = "LOAD_PREMIMUMFUNNELTEMPLATES_SUCCESS";

export function loadFunnelsSuccess(funnels) {
    return { type: LOAD_FUNNELS_SUCCESS, funnels };
}

export function createFunnelSuccess(funnel) {
    return { type: CREATE_FUNNEL_SUCCESS, funnel };
}
export function createFunnelError(isError = false) {
    return { type: CREATE_FUNNEL_ERROR, isError };
}
export function editFunnelSuccess(funnel) {
    return { type: EDIT_FUNNEL_SUCCESS, funnel };
}
export function editFunnelError(isError = false) {
    return { type: EDIT_FUNNEL_ERROR, isError };
}
export function updateFunnelSuccess(funnel) {
    return { type: UPDATE_FUNNEL_SUCCESS, funnel };
}

export function toggleLoading(status = false) {
    return { type: IS_LOADING, status };
}

export function loadCampaignsSuccess(campaigns) {
    return { type: LOAD_CAMPAIGNS_SUCCESS, campaigns };
}
export function loadProductsSuccess(products) {
    return { type: LOAD_PRODUCTS_SUCCESS, products };
}

export function loadPageSuccess(htmlPage) {
    return { type: LOAD_PAGE_SUCCESS, htmlPage };
}

export function loadMerchantsSuccess(merchants) {
    return { type: LOAD_MERCHANTS_SUCCESS, merchants };
}

export function createCampaignSuccess(campaign) {
    return { type: CREATE_CAMPAIGN_SUCCESS, campaign };
}
export function createCampaignError(error) {
    return { type: CREATE_CAMPAIGN_ERROR, error };
}

export function loadProducts() {
    return function (dispatch) {
        dispatch(toggleLoading(true));
        return campaignApi.getProducts().then(response => {
            response = response.message;
            let obj;
            let arr = [];
            for (let props in response) {
                obj = response[props];
                arr.push(obj);
            }
            dispatch(loadProductsSuccess(arr));
            dispatch(toggleLoading(false));
        }).catch(error => {
            dispatch(toggleLoading(false));
            throw error;
        });
    };
}

export function loadMerchants() {
    return function (dispatch) {
        dispatch(toggleLoading(true));
        return campaignApi.getMerchants().then(response => {
            response = response.message[0];
            let obj;
            let arr = [];
            for (let props in response) {
                obj = response[props];
                arr.push(obj);
            }
            dispatch(loadMerchantsSuccess(arr));
            dispatch(toggleLoading(false));
        }).catch(error => {
            dispatch(toggleLoading(false));
            throw error;
        });
    };
}

export function createCampaign(campaign, upsell, offer) {
    return function (dispatch) {
        dispatch(toggleLoading(true));
        return campaignApi.saveCampaign(campaign, upsell, offer).then(response => {
            if (response.data.result === "SUCCESS") {
                response = response.data.message;
                dispatch(createCampaignSuccess(response));
                let newOffers = [];
                let newUpsells = [];
                response.offers.forEach(offer => {
                    let product = {};
                    product.value = offer.campaignProductId;
                    product.text = offer.productName;
                    product.type = "OFFER";
                    newOffers.push(product);
                });
                response.upsells.forEach(upsell => {
                    let product = {};
                    product.value = upsell.campaignProductId;
                    product.text = upsell.productName;
                    product.type = "UPSELL";
                    newUpsells.push(product);
                });
                if (!newUpsells[0]) {
                    newUpsells.push({});
                }
                dispatch(createFunnel({
                    title: response.campaignName,
                    offer: newOffers,
                    upsale: newUpsells,
                    campaign: response.campaignId,
                }));
                dispatch(loadCampaigns());
            }
            dispatch(toggleLoading(false));
        },error => {
            dispatch(createCampaignError(error.response.data.message));
            dispatch(toggleLoading(false));
            throw error;
        });
    };
}
export function deleteAction(funnelId) {
    return function (dispatch) {
        dispatch(toggleLoading(true));
        return api.deleteFunnel(funnelId, { headers: APIHelper.getGenericHeaders() }).then(() => {
            dispatch(loadFunnels());
            dispatch(toggleLoading(false));
        }).catch((error) => {
            dispatch(toggleLoading(false));
            throw error;
        });
    };
}

export function loadFunnels() {
    return function (dispatch) {
        const localstorageManager = new LocalStorageHelper();
        const accessToken = localstorageManager.load(LocalStorageHelper.keys.ACCESS_TOKEN);
        if (accessToken) {
            dispatch(toggleLoading(true));
            return api.getAllFunnels({ headers: APIHelper.getGenericHeaders() }).then((response) => {
                dispatch(loadFunnelsSuccess(response.result));
                dispatch(toggleLoading(false));
            }).catch(error => {
                // TODO - Funnel Get should return empty array in case of 404
                dispatch(loadFunnelsSuccess([]));
                dispatch(toggleLoading(false));
                throw (error);
            });
        }
    };
}

export const getFunnel = (funnelReferenceId) => {
    return api.retrieveFunnel(funnelReferenceId, { headers: APIHelper.getGenericHeaders() });
};

export function createFunnel(newFunnel) {
    return function (dispatch) {
        const localstorageManager = new LocalStorageHelper();
        const accessToken = localstorageManager.load(LocalStorageHelper.keys.ACCESS_TOKEN);
        if (accessToken) {
            dispatch(toggleLoading(true));
            return api.createFunnel({ headers: APIHelper.getGenericHeaders(), requestBody: newFunnel }).then((savedFunnel) => {
                dispatch(loadFunnels());
                dispatch(createFunnelSuccess(savedFunnel));
                dispatch(toggleLoading(false));
                return savedFunnel;
            }).catch((error) => {
                dispatch(createFunnelError(true));
                dispatch(toggleLoading(false));
                throw error;
            });
        }
    };
}

export function updateFunnel(funnel) {
    return function (dispatch) {
        dispatch(toggleLoading(true));
        return api.editFunnel({ funnel }, { headers: APIHelper.getGenericHeaders(), requestBody: funnel }).then((savedFunnel) => {
            dispatch(editFunnelSuccess(funnel));
            dispatch(toggleLoading(false));
        }).catch(error => {
            dispatch(editFunnelError(true));
            dispatch(toggleLoading(false));
            throw error;
        });
    };
}

export function updateFunnelInfo(params) {
    return function () {
        return api.updateFunnel(
            params,
            { headers: APIHelper.getGenericHeaders(), requestBody: params.funnel }
        );
    };
}

export function loadCampaigns() {
    return function (dispatch) {
        return getCampaigns().then(campaigns => {
            campaigns = [campaigns.message.data];
            let newArr = [];

            campaigns.forEach(function (campaign) {
                //let campaign = campaigns[i]
                for (let prop in campaign) {
                    if (campaign.hasOwnProperty(prop)) {
                        let campaignInfo = {};
                        campaignInfo.campaignId = campaign[prop].campaignId;
                        campaignInfo.campaignName = campaign[prop].campaignName;
                        campaignInfo.campaignTOS = campaign[prop].campaignTOS;
                        campaignInfo.campaignType = campaign[prop].campaignType;
                        campaignInfo.captureOnShipment = campaign[prop].captureOnShipment;
                        campaignInfo.countries = campaign[prop].countries;
                        campaignInfo.coupons = campaign[prop].coupons;
                        campaignInfo.currency = campaign[prop].currency;
                        campaignInfo.eSignature = campaign[prop].eSignature;
                        campaignInfo.enableFraudPlugin = campaign[prop].enableFraudPlugin;
                        campaignInfo.externalCampaignId = campaign[prop].externalCampaignId;
                        campaignInfo.insureShipBillerId = campaign[prop].insureShipBillerId;
                        campaignInfo.insureShipLoadBalancerId = campaign[prop].insureShipLoadBalancerId;
                        campaignInfo.isLocked = campaign[prop].isLocked;
                        campaignInfo.listSalesEntry = campaign[prop].listSalesEntry;
                        campaignInfo.preauthOnly = campaign[prop].preauthOnly;
                        campaignInfo.products = campaign[prop].products;
                        campaignInfo.requireQA = campaign[prop].requireQA;
                        campaignInfo.requireTOS = campaign[prop].requireTOS;
                        campaignInfo.taxes = campaign[prop].taxes;
                        campaignInfo.visible = campaign[prop].visible;

                        newArr.push(campaignInfo);
                    }
                }
            });
            dispatch(loadCampaignsSuccess(newArr));
        }).catch(error => {
            throw (error);
        });
    };
}
