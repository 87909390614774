import React from "react";
import PropTypes from "prop-types";

const FullPageLoader = ({
    showLoading,
}) => {
    return (
        <>
            {
                showLoading && (
                    <div className="builder-loading-layer">
                        <i className="fa fa-spinner fa-pulse fa-lg m-r-xs" />
                    </div>
                )
            }
        </>
    );
};

FullPageLoader.propTypes = {
    showLoading: PropTypes.bool,
};

export default FullPageLoader;