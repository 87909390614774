import React, { Component } from "react";
import { Button, Row, Col, FormGroup, ControlLabel, FormControl } from "react-bootstrap";
import PropTypes from "prop-types";

import { NotificationEmitter, NOTIFICATION_EVENT_NAME } from "../../../commons/notification";
import { numberOnly } from "../../utilities";

import "./SMTP.css";
import { createSMTP, updateSMTP } from "../../utilities/api";
import CRMButton from "../../CRMButton";

class CreateEditSMTP extends Component {

    constructor(props) {
        super(props);
        this.state = {
            hasError: false,
            isLoading: false,
            SMTP: this.getSMTPInitialState(props),
            formErrors: []
        };
    }

    getSMTPInitialState({ SMTPDetails }) {
        if (SMTPDetails) {
            return {
                ...SMTPDetails
            };
        }
        return {
            smtpName: "",
            hostName: "",
            smtpUsername: "",
            senderEmailAddress: "",
            smtpPassword: "",
            portNumber: "",
            bccAddress: "",
            sslType: "",
            useSsl: 0,
            useRelay: 0
        };
    }

    isValid = ({ smtpName, senderEmailAddress, bccAddress }) => {
        let formErrors = [];
        if (!smtpName) {
            formErrors.push("smtpName");
        }
        if (senderEmailAddress) {
            if (!(senderEmailAddress.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i))) {
                formErrors.push("senderEmailAddress");
            }
        }
        if (bccAddress) {
            if (!(bccAddress.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i))) {
                formErrors.push("bccAddress");
            }
        }
        this.setState({
            formErrors
        });
        return formErrors.length === 0;
    }

    handleSubmit = e => {
        e.preventDefault();
        const { SMTP } = this.state;
        if (this.isValid(SMTP)) {
            this.setState({
                isLoading: true
            });
            console.clear();
            createSMTP(SMTP)
                .then((res) => {
                    NotificationEmitter.emit(NOTIFICATION_EVENT_NAME, { type: "success", message: "SMTP created successfully" });
                    this.setState({
                        isLoading: false
                    });
                    this.props.refreshList();
                    this.props.closeModal(e);
                })
                .catch((err) => {
                    this.setState({
                        isLoading: false
                    });
                    NotificationEmitter.emit(NOTIFICATION_EVENT_NAME, { type: "error", message: err.response.data.message });
                });
        } else {
            this.setState({
                hasError: true
            });
        }
    }

    handleUpdate = (e) => {
        e.preventDefault();
        const { SMTP } = this.state;
        if (this.isValid(SMTP)) {
            this.setState({
                isLoading: true
            });
            console.clear();
            updateSMTP(SMTP)
                .then((res) => {
                    NotificationEmitter.emit(NOTIFICATION_EVENT_NAME, { type: "success", message: "SMTP updated successfully" });
                    this.setState({
                        isLoading: false
                    });
                    this.props.refreshList();
                    this.props.closeModal(e);
                })
                .catch((err) => {
                    this.setState({
                        isLoading: false
                    });
                    NotificationEmitter.emit(NOTIFICATION_EVENT_NAME, { type: "error", message: err.response.data.message });
                });
        } else {
            this.setState({
                hasError: true
            });
        }
    }

    handleChange = (e) => {
        if (e.target.type === "checkbox") {
            this.setState({
                SMTP: {
                    ...this.state.SMTP,
                    [e.target.name]: e.target.checked ? 1 : 0
                }
            });
            return;
        }
        this.setState({
            SMTP: {
                ...this.state.SMTP,
                [e.target.name]: e.target.value
            }
        });
    }

    render() {
        const { hasError, isLoading, formErrors, SMTP: {
            smtpName,
            hostName,
            smtpUsername,
            senderEmailAddress,
            smtpPassword,
            portNumber,
            bccAddress,
            sslType,
            useSsl,
            useRelay
        }
        } = this.state;
        const { closeModal, SMTPDetails } = this.props;
        const options = [{ value: "TLS", text: "STARTTLS" }, { value: "SSL", text: "SSL" }];
        return (
            <div className="create_edit_smtp">
                <Row className="plr-50 pt-20 m-0">
                    <Col md={12} className="plr-50 pt-10">
                        <Row className="m-0">
                            <FormGroup className="m-0">
                                <ControlLabel className="custom-form-label-smtp">Profile Name</ControlLabel>
                                <FormControl
                                    type="text"
                                    onChange={this.handleChange}
                                    name={"smtpName"}
                                    defaultValue={smtpName}
                                />
                            </FormGroup>
                        </Row>
                        <Row className="m-0">
                            <div className={`error_box m-0 ${formErrors.includes("smtpName") ? "erractive mt-5" : ""}`}>
                                <span>SMTP Name is required</span>
                            </div>
                        </Row>
                        <Row className="m-0 mt-15">
                            <FormGroup className="m-0">
                                <ControlLabel className="custom-form-label-smtp">Host Name</ControlLabel>
                                <FormControl
                                    type="text"
                                    onChange={this.handleChange}
                                    defaultValue={hostName}
                                    name={"hostName"}
                                />
                            </FormGroup>
                        </Row>
                        <Row className="m-0 mt-15">
                            <FormGroup className="m-0">
                                <ControlLabel className="custom-form-label-smtp">Username</ControlLabel>
                                <FormControl
                                    type="text"
                                    onChange={this.handleChange}
                                    name={"smtpUsername"}
                                    defaultValue={smtpUsername}
                                />
                            </FormGroup>
                        </Row>
                        <Row className="m-0 mt-15">
                            <FormGroup className="m-0">
                                <ControlLabel className="custom-form-label-smtp">From Email</ControlLabel>
                                <FormControl
                                    type="email"
                                    onChange={this.handleChange}
                                    defaultValue={senderEmailAddress}
                                    name={"senderEmailAddress"}
                                />
                            </FormGroup>
                            <Row className="m-0">
                                <div className={`error_box m-0 ${formErrors.includes("senderEmailAddress") ? "erractive mt-5" : ""}`}>
                                    <span>Email address is invalid</span>
                                </div>
                            </Row>
                        </Row>
                        <Row className="m-0 mt-15">
                            <FormGroup className="m-0">
                                <ControlLabel className="custom-form-label-smtp">Password</ControlLabel>
                                <FormControl
                                    type="text"
                                    onChange={this.handleChange}
                                    defaultValue={smtpPassword}
                                    name={"smtpPassword"}
                                />
                            </FormGroup>
                        </Row>
                        <Row className="m-0 mt-15">
                            <FormGroup className="m-0">
                                <ControlLabel className="custom-form-label-smtp">Port Number</ControlLabel>
                                <FormControl
                                    type="text"
                                    className="w-21"
                                    onChange={(e) => {numberOnly(e); this.handleChange(e);}}
                                    defaultValue={portNumber}
                                    name={"portNumber"}
                                />
                            </FormGroup>
                        </Row>
                        <Row className="m-0 mt-15">
                            <FormGroup className="m-0">
                                <ControlLabel className="custom-form-label-smtp">BCC Address</ControlLabel>
                                <FormControl
                                    type="text"
                                    onChange={this.handleChange}
                                    defaultValue={bccAddress}
                                    name={"bccAddress"}
                                />
                            </FormGroup>
                            <Row className="m-0">
                                <div className={`error_box m-0 ${formErrors.includes("bccAddress") ? "erractive mt-5" : ""}`}>
                                    <span>Bcc address is invalid</span>
                                </div>
                            </Row>
                        </Row>
                        <Row className="m-0 mt-15">
                            <Col md={5} className="p-0">
                                <div className="checkbox checkbox-success checkbox-md custom-checkbox">
                                    <label className="custom-checkbox">
                                        <div className="check-box-label pl-10">Use SSL</div>
                                        <input
                                            type="checkbox"
                                            onChange={this.handleChange}
                                            name={"useSsl"}
                                            defaultChecked={useSsl}
                                        />
                                        <span className="check-box"></span>
                                    </label>
                                </div>
                            </Col>
                            <Col md={6}>
                                <div className="checkbox checkbox-success checkbox-md custom-checkbox">
                                    <label className="custom-checkbox">
                                        <div className="check-box-label pl-10">Use Relay</div>
                                        <input
                                            type="checkbox"
                                            onChange={this.handleChange}
                                            name={"useRelay"}
                                            defaultChecked={useRelay}
                                        />
                                        <span className="check-box"></span>
                                    </label>
                                </div>
                            </Col>
                        </Row>
                        <Row className="m-0">
                            { useSsl ?
                                <FormGroup className="m-0 mt-15">
                                    <ControlLabel className="custom-form-label-smtp">Type</ControlLabel>
                                    <FormControl
                                        componentClass="select"
                                        onChange={this.handleChange}
                                        name={"sslType"}
                                    >
                                        <option disabled selected>Select type</option>
                                        {options.map((option, i) =>
                                            <option value={option.value} selected={option.value === sslType}>{option.text}</option>
                                        )}
                                    </FormControl>
                                </FormGroup> :
                                null
                            }
                        </Row>
                    </Col>
                </Row>

                <Row className="m-0 pt-20 mt-15">
                    <Col md={12} style={{ textAlign: "right", paddingRight: 30, paddingBottom: 15 }}>
                        <Button
                            className="cancelButton"
                            style={{ marginRight: 20 }}
                            onClick={(e) => {closeModal(e);}}
                        >
                            Cancel
                        </Button>
                        <CRMButton isLoading={isLoading} handleClick={(e) => SMTPDetails ? this.handleUpdate(e) : this.handleSubmit(e)} text="Save & Continue"/>
                    </Col>
                </Row>
            </div>
        );
    }
}

export default CreateEditSMTP;

CreateEditSMTP.propTypes = {
    closeModal: PropTypes.func,
    SMTPDetails: PropTypes.object,
    refreshList: PropTypes.func
};
