import { CreateFunnelVisualizer as VisualizerView } from "./createFunnelVisualizer";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import get from "lodash/get";
import * as actions from "./actions";
import * as selectors from "./selectors";
import * as funnelActions from "../actions";
import * as manageActions from "../../funnels/manage-funnel/actions";
import * as templateActions from "./../../templates/actions";
import * as domainActions from "../../login/actions";
import { selectors as templateSelectors } from "../../templates";
import { LocalStorageHelper } from "../../utilities";
import { manageAuthorState } from "./../../author/selectors";
import * as campaignSelectors from "../../campaign/selectors";
import { manageCampaignState } from "./../../campaign/selectors";
import * as funnelSelectors from "../selectors";
import * as saltSelectors from "../../login/selectors";
import { loadCampaigns } from "../../campaign/action";
import { toggleCRMEntryModal } from "../../core/app-settings/actions";

function mapStateToProps(state, ownProps) {
    const importedFunnel = selectors.getImportedFunnel(state);
    const funnel = selectors.getFunnelData(state);
    const funnelEdited = selectors.editedFunnelData(state);
    const funnels = funnelSelectors.getFunnels(state);
    const localStorage = new LocalStorageHelper();
    localStorage.store("funnelReferenceId", ownProps.match.params.referenceId);
    const htmlPage = selectors.getTemplateHtml(state);
    const metaJson = selectors.getMetaData(state);
    const pageData = selectors.getPageData(state);
    const fontList = selectors.getGoogleFonts(state);
    const fonts = selectors.getSavedFonts(state);
    const jsonData = selectors.getJsonData(state);
    const isError = selectors.getError(state);
    const pageError = selectors.getPageError(state);
    const importedPage = selectors.getImportedPage(state);
    const importError = selectors.getImportError(state);
    const importFunnelError = selectors.getImportFunnelError(state);
    const campaigns = campaignSelectors.manageCampaignState(state);
    const settingsData = saltSelectors.getSettingsData(state);
    const isFontLoading = selectors.getFontsLoading(state);
    const isLoading = selectors.getLoader(state);
    let offersFormattedForDropdown = [];
    let upsalesFormattedForDropdown = [];
    let currentTemplate = {}, selectedCampaign = null;
    const domainList = selectors.getDomainList(state);
    const selectedDomainForFunnel = selectors.getSelectedDomainForFunnel(state);
    const selectedDomainForFunnelError = selectors.selectedDomainForFunnelError(state);
    const isImportFunnelLoading = selectors.getImportFunnelLoadingStatus(state);
    const showImportFunnelSuccessMessage = selectors.showImportFunnelSuccessStatus(state);
    const defaultFunnelTemplates = selectors.defaultFunnelTemplates(state);
    const defaultFunnelTemplate = selectors.defaultFunnelTemplate(state);
    const getSplitPageSettingsViewStatus = selectors.getSplitPageSettingsViewStatus(state);
    const premiumFunnelTemplates = selectors.premiumFunnelTemplates(state);
    const getLatestAddedDomain = selectors.getLatestAddedDomain(state);
    const authorsFormattedForDropdown = manageAuthorState(state).map(author => {
        return {
            value: author.id,
            text: author.firstName + " " + author.lastName
        };
    });
    const campaignsFormattedForDropdown = manageCampaignState(state).map(campaign => {
        return {
            value: campaign.campaignId,
            text: campaign.campaignName,
            products: campaign.products
        };

    });
    if (campaigns.length && funnels.length) {
        const currentFunnel = funnelSelectors.getFunnel(state, ownProps.match.params.referenceId);
        selectedCampaign = campaignSelectors.getCampaign(state, currentFunnel && currentFunnel.campaignId);
    }
    if (selectedCampaign) {
        offersFormattedForDropdown = selectedCampaign.products.filter(product =>
            product.productType === "OFFER"
        ).map((o) => ({
            value: o.campaignProductId,
            text: o.productName,
            type: o.productType
        }));

        upsalesFormattedForDropdown = selectedCampaign.products.filter(product =>
            product.productType === "UPSALE"
        ).map((u) => ({
            value: u.campaignProductId,
            text: u.productName,
            type: u.productType
        }));
    }
    const valuesFromPreviousPage = get(ownProps, ["location", "state"]);
    const openFunnelTemplatesOnLoad = get(valuesFromPreviousPage, ["openFunnelTemplatesOnLoad"]);
    const funnelTemplateTypeIdToPrepopulate = get(valuesFromPreviousPage, ["funnelTemplateTypeId"]);
    /*
     * Templates
     */
    const templates = templateSelectors.getTemplates(state);
    const userTemplates = templateSelectors.getUserTemplates(state);
    const premiumTemplates = templateSelectors.getPremiumTemplates(state);
    const userData = saltSelectors.getUserData(state);
    const canAccessPremiumTemplates = !userData.isDesignerUser;
    return {
        funnelReferenceId: ownProps.match.params.referenceId,
        templates,
        fontList,
        funnels,
        fonts,
        funnel,
        metaJson,
        funnelEdited,
        isLoading,
        isFontLoading,
        htmlPage,
        pageData,
        jsonData,
        importedPage,
        importedFunnel,
        authors: authorsFormattedForDropdown,
        upsales: upsalesFormattedForDropdown,
        offers: offersFormattedForDropdown,
        campaigns: campaignsFormattedForDropdown,
        selectedCampaign,
        isError,
        importError,
        importFunnelError,
        pageError,
        salt: settingsData ? settingsData.salt : "",
        domainList,
        selectedDomainForFunnel,
        isImportFunnelLoading,
        showImportFunnelSuccessMessage,
        defaultFunnelTemplates,
        getSplitPageSettingsViewStatus,
        premiumFunnelTemplates,
        openFunnelTemplatesOnLoad,
        funnelTemplateTypeIdToPrepopulate,
        userTemplates,
        premiumTemplates,
        canAccessPremiumTemplates,
        userData: saltSelectors.getUserData(state),
        getLatestAddedDomain,
        selectedDomainForFunnelError
    };
}

function mapDispatchToProps(dispatch, ownProps) {
    return {
        actions: bindActionCreators(actions, dispatch),
        getFunnelData: (funnelReferenceId) => dispatch(actions.getFunnel(funnelReferenceId)),
        clearPageData: () => dispatch(actions.clearPageData()),
        clearJsonData: () => dispatch(actions.clearJsonData()),
        clearFunnelData: () => dispatch(actions.clearFunnelData()),
        savefunnelGraph: (params) => dispatch(actions.saveFunnel(params)),
        loadFunnels: (funnel) => dispatch(funnelActions.loadFunnels(funnel)),
        loadFunnel: (pageId) => dispatch(actions.loadFunnel(pageId)),
        loadFonts: () => dispatch(actions.loadFonts()),
        loadMetaJson: (funnel) => dispatch(actions.loadMetaJson(funnel)),
        createNewPage: (params) => dispatch(actions.createPage(params)),
        editFunnel: (params) => dispatch(actions.editFunnel(params)),
        editPage: (params) => dispatch(actions.editPage(params)),
        saveFonts: (params) => dispatch(actions.saveFonts(params)),
        loadTemplates: (templateType) => dispatch(templateActions.getTemplates({ templateType })),
        deletePage: (params, cb) => dispatch(actions.deletePage(params, cb)),
        saveTemplatePageView: (params) => dispatch(actions.saveTemplatePageView(params)),
        savePageView: (params) => dispatch(actions.savePageView(params)),
        clonePage: (params) => dispatch(actions.clonePage(params)),
        importPage: (params) => dispatch(actions.importPage(params)),
        getJson: (params, cb) => dispatch(actions.getJsonData(params, cb)),
        loadCampaigns: () => dispatch(loadCampaigns()),
        loadButtons: (params, cb) => dispatch(actions.loadButtons(params, cb)),
        saveButtonData: (params, cb) => dispatch(actions.saveButtonData(params, cb)),
        savePageViewData: (params, cb) => dispatch(actions.savePageViewData(params, cb)),
        resetData: (params, cb) => dispatch(actions.visualizerFunnelEdit(params, cb)),
        publishFunnel: (funnelReferenceId) => dispatch(actions.publishFunnel(funnelReferenceId)),
        errorClose: () => dispatch(actions.errorClose()),
        getSavedfonts: (params) => dispatch(actions.getFonts(params)),
        getDomainListForUser: (funnelReferenceId) => dispatch(actions.getDomainListForUser(funnelReferenceId)),
        setDomainForFunnel: (selectedDomainAndFunnelId) => dispatch(actions.setDomainForFunnel(selectedDomainAndFunnelId)),
        addAndSetDomainForFunnel: (selectedDomainAndFunnelId) => dispatch(actions.addAndSetDomainForFunnel(selectedDomainAndFunnelId)),
        clearDomainForFunnelData: () => dispatch(actions.clearDomainForFunnelData()),
        importFunnel: (params) => dispatch(actions.importFunnel(params)),
        importFunnelFromShareId: (params) => dispatch(actions.importFunnelFromShareId(params)),
        showSuccessMessage: (status) => dispatch(actions.showSuccessMessage()),
        getDefaultFunnels: () => dispatch(actions.getDefaultFunnels()),
        splitDuplicatePage: (params) => dispatch(actions.splitDuplicatePage(params)),
        splitChooseTemplate: (params) => dispatch(actions.splitChooseTemplate(params)),
        setSplitPercentToPage: (params) => dispatch(actions.setSplitPercentToPage(params)),
        updateShowSplitSetting: (status) => dispatch(actions.updateShowSplitSetting(status)),
        declareWinner: (params) => dispatch(actions.declareWinner(params)),
        getThumbnailForFunnel: (funnelReferenceId) => dispatch(actions.getThumbnailForFunnel(funnelReferenceId)),
        getAnalyticsData: (params) => (actions.getAnalyticsData(params)),
        getPremiumFunnelTemplates: (purchasedTemplates) => dispatch(actions.getPremiumFunnelTemplates(purchasedTemplates)),
        purchaseOrder: (params) => (actions.purchaseOrder(params)),
        createDomain: (domainName) => dispatch(domainActions.createDomain(domainName)),
        toggleCRMEntryModal: (status) => dispatch(toggleCRMEntryModal(status)),
    };
}

export const CreateFunnelVisualizer = connect(mapStateToProps, mapDispatchToProps)(VisualizerView);
