import React, { Component } from "react";
import PropTypes from "prop-types";
import "../../node_modules/jointjs/dist/joint.css";
import modalConstants from "../constants/pop-up-modal-constants";
import { Modal } from "react-bootstrap";

const PopUpModal = ({ modalToShow, showModalWindow, handleClose, handleConfirmation }) => {

    let modalDetails = modalToShow ? modalConstants[modalToShow] : "";
    if (modalDetails) {
        return (
            <Modal show={showModalWindow} backdrop="static" onHide={handleClose} aria-labelledby="contained-modal-title-lg">

                {(modalDetails.type === "Information" ?
                    <Modal.Header closeButton style={{ backgroundColor: "rgb(47, 64, 77)" }}>
                        <Modal.Title><h3 className="text-white">{modalDetails.type}</h3></Modal.Title>
                    </Modal.Header> :
                    <Modal.Header>
                        <Modal.Title><h3 className="text-white">{modalDetails.type}</h3></Modal.Title>
                    </Modal.Header>)}
                <Modal.Body className="m-n">
                    <h4 className="text-center">{modalDetails.text.map((text) => {return text;})}</h4>
                </Modal.Body>

                {(modalDetails.type === "Information" ?
                    <Modal.Footer>
                        <a className="btn btn-primary" onClick={handleClose}>Ok</a>
                    </Modal.Footer>
                    :
                    <Modal.Footer><a className="btn btn-primary" onClick={handleConfirmation}>Yes</a>
                        <a className="btn btn-default" onClick={handleClose}>No</a>
                    </Modal.Footer>)}
            </Modal>
        );
    } else {
        return null;
    }

};
PopUpModal.propTypes = {
    modalToShow: PropTypes.string,
    showModalWindow: PropTypes.bool,
    handleConfirmation: PropTypes.func,
    handleClose: PropTypes.func,
};
export default PopUpModal;